import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import css from './VendorListings.css';

const VendorShowListings = props => {
  const { categoryListings, categoryType, services } = props;
  return (
    // <div className={css.VendorShowListingsWrapper}>
    //   <div className={css.vendorListingsButtonWrapper}>
    // {categoryListings.length > 0 ? (
    //   categoryListings.length === 1 ? (
    //     props.GoToListing(categoryListings[0])
    //   ) : (
    //     categoryListings?.map((vendor, index) => (
    //           <div
    //             className={css.vendorListingsButton}
    //             onClick={() => props.GoToListing(vendor)}
    //             key={index}
    //           >
    //             {vendor.attributes.title}
    //           </div>
    //         ))
    //       )
    //     ) : (
    // <div className={css.noVendorListings}>
    //   <FormattedMessage id="VendorListings.noVendorListings" />
    // </div>
    //     )}
    //   </div>
    // <div className={css.vendorListingsButtonWrapper}>
    //   <div className={css.vendorListingsButton} onClick={() => props.setShowListings(false)}>
    //     <FormattedMessage id="VendorListings.BackButtonText" />
    //   </div>
    // </div>
    // </div>
    <div className={css.serviceCard}>
      {categoryListings && categoryListings.length > 0 ? (
        categoryListings.length === 1 && categoryType !== 'LINKS' ? (
          props.GoToListing(categoryListings[0])
        ) : (
          categoryListings?.map((vendor, index) => {
            return (
              <div
                key={index}
                className={css.sreviceCardWrap}
                onClick={() => props.GoToListing(vendor)}
              >
                {categoryType !== 'LINKS' ? (
                  <>
                    <img
                      className={css.serviceImage}
                      src={vendor?.images[0].attributes?.variants?.default?.url}
                      alt="name"
                    />
                    <h3 className={css.serviceText}>{vendor.attributes.title}</h3>
                    <div className={css.btnView} key={index}>
                      View
                    </div>
                  </>
                ) : (
                  <div className={css.sreviceCardWrap}>
                    <img className={css.serviceImage} src={vendor?.asset?.url} alt="name" />
                    <h3 className={css.serviceText}>{vendor?.text}</h3>
                    <a href={vendor?.url} target="_blank" className={css.btnView}>
                      View
                    </a>
                  </div>
                )}
              </div>
            );
          })
        )
      ) : (
        <div className={css.noVendorListings}>
          {categoryType !== 'LINKS' ? (
            <FormattedMessage id="VendorListings.noVendorListings" />
          ) : (
            <FormattedMessage id="VendorListings.noVendorLinks" />
          )}
        </div>
      )}
      {/* <div className={css.vendorListingsButtonWrapper}>
        <div className={css.vendorListingsButton} >
          
        </div>
      </div> */}

      {/* <div className={css.sreviceCardWrap}>
        <img className={css.serviceImage} src={undoImage} alt="name" />
        <h3 className={css.serviceText}>Return To Profile</h3>
        <div className={css.btnView} onClick={() => props.setShowListings(false)}>
          <FormattedMessage id="VendorListings.BackButtonText" />
        </div>
      </div> */}
    </div>
  );
};

export default VendorShowListings;
