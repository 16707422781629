export const yogaData = {
  title: 'Yoga',
  page: 'AllSportsPage',
  params: { slug: 'yoga' },
  imgSrc:
    'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1672290686/probuddy/static/images/CategoriesPage/Yoga/yoga_category_xwpolq.png',
};

// export const massagesData = {
//   title: 'Massage & Spa',
//   imgSrc:
//     'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1672289041/probuddy/static/images/CategoriesPage/Massage%20and%20Spa/messages_category_ighjun.png',

//   page: 'AllSportsPage',
//   params: { slug: 'massage-spa' },
// };

export const kickboxingData = {
  title: 'Kickboxing',
  imgSrc:
    'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1672289166/probuddy/static/images/CategoriesPage/Kickboxing/kickboxing_category_zzl2rq.png',

  page: 'AllSportsPage',
  params: { slug: 'kickboxing' },
};

export const tennisData = {
  title: 'Tennis',
  imgSrc:
    'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1672289708/probuddy/static/images/CategoriesPage/Tennis/tennis_category_lc1jcp.png',

  page: 'AllSportsPage',
  params: { slug: 'tennis' },
};

export const boxingData = {
  title: 'Boxing',
  imgSrc:
    'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1672289558/probuddy/static/images/CategoriesPage/Boxing/boxing_category_mjf6z5.png',

  page: 'AllSportsPage',
  params: { slug: 'boxing' },
};

// export const indoreCyclingData = {
//   title: 'Indoor Cycling',
//   imgSrc:
//     'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1672289465/probuddy/static/images/CategoriesPage/Indoor%20Cycling/indorecycling_category_kjeeou.png',

//   page: 'AllSportsPage',
//   params: { slug: 'indoor-cycling' },
// };

// export const poleDancingData = {
//   title: 'Pole Dancing',
//   imgSrc:
//     'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1672289380/probuddy/static/images/CategoriesPage/Pole%20Dancing/poledancing_category_ia6n5w.png',

//   page: 'AllSportsPage',
//   params: { slug: 'pole-fitness' },
// };

export const pilatesData = {
  title: 'Pilates',
  imgSrc:
    'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1672289917/probuddy/static/images/CategoriesPage/Pilates/pilates_category_orzbl0.png',

  page: 'AllSportsPage',
  params: { slug: 'pilates' },
};

// export const vollyBallData = {
//   title: 'Volleyball',
//   imgSrc:
//     'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1672289843/probuddy/static/images/CategoriesPage/Volleyball/vallyball_category_sics3i.png',

//   page: 'AllSportsPage',
//   params: { slug: 'volleyball' },
// };

export const swimmimgData = {
  title: 'Swimming',
  imgSrc:
    'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1672290581/probuddy/static/images/CategoriesPage/Swimming/swiming_category_nz0txi.png',

  page: 'AllSportsPage',
  params: { slug: 'swimming' },
};

export const surfingData = {
  title: 'Surfing',
  imgSrc:
    'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1672290031/probuddy/static/images/CategoriesPage/Surfing/surfing_category_cwjdiw.png',

  page: 'AllSportsPage',
  params: { slug: 'surfing' },
};

export const danceData = {
  title: 'Dance',
  imgSrc:
    'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1672290339/probuddy/static/images/CategoriesPage/Dance/dancing_category_ukzr2l.png',
  page: 'AllSportsPage',
  params: { slug: 'dance' },
};

// export const rockClimbingData = {
//   title: 'Rock Climbing',
//   imgSrc:
//     'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1672290474/probuddy/static/images/CategoriesPage/Rock%20Climbing/rockclimbing_category_hjbgel.png',

//   page: 'AllSportsPage',
//   params: { slug: 'rock-climbing' },
// };

export const personalFitnessData = {
  title: 'Personal Training',
  imgSrc:
    'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1672290235/probuddy/static/images/CategoriesPage/Personal%20Trainer/personaltraining_category_rnbu5r.png',

  page: 'AllSportsPage',
  params: { slug: 'personal-training' },
};

export const padelData = {
  title: 'Padel Tennis',
  imgSrc:
    'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1692765422/probuddy/static/images/CategoriesPage/Padel/man-playing-padel_c8xuyv.jpg',

  page: 'AllSportsPage',
  params: { slug: 'padel-tennis' },
};

export const pickleballData = {
  title: 'Pickleball',
  imgSrc:
    'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1696927963/probuddy/static/images/CategoriesPage/Pickleball/16_i1fobk.png',

  page: 'AllSportsPage',
  params: { slug: 'pickle-ball' },
};

// export const golfData = {
//   title: 'Golf',
//   imgSrc:
//     'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1672290143/probuddy/static/images/CategoriesPage/Golf/golf_category_mf2by5.png',

//   page: 'AllSportsPage',
//   params: { slug: 'golf' },
// };

export const mobileTennisPilatedData = [tennisData, pilatesData];
export const personalGolfData = [personalFitnessData, yogaData];
// export const personalGolfData = [golfData, personalFitnessData];
// export const yogaBoxingData = [boxingData, surfingData];
// export const massageDancingData = [kickboxingData];
// export const vollyballMountainData = [kickboxingData];
// export const swimmingSurffingData = [danceData, swimmimgData];
// export const boxingWrapperColumn = [indoreCyclingData, massagesData];
// export const boxingWrapperColumn = [indoreCyclingData, poleDancingData, kickboxingData];

export const getCategoryImage = key => {
  switch (key) {
    case 'math':
      return 'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675422863/probuddy/static/NewEducation/CategoryPage/math_cxnoy4.png';
    case 'english':
      return 'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675422875/probuddy/static/NewEducation/CategoryPage/english_fgyaau.png';
    case 'chemistry':
      return 'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675422859/probuddy/static/NewEducation/CategoryPage/chemistry_i7i9eh.png';
    case 'physics':
      return 'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675683671/probuddy/static/NewEducation/CategoryPage/physics_image_cr5c03.png';
    case 'biology':
      return 'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675422973/probuddy/static/NewEducation/CategoryPage/Biology_x8zpay.png';
    case 'french':
      return 'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675422839/probuddy/static/NewEducation/CategoryPage/French_ji34wt.jpg';
    case 'german':
      return 'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675422869/probuddy/static/NewEducation/CategoryPage/German_zfp5ot.png';
    case 'geography':
      return 'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675422896/probuddy/static/NewEducation/CategoryPage/Geography_lnguim.png';
    case 'engineering':
      return 'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675422887/probuddy/static/NewEducation/CategoryPage/Engineering_umjw4d.png';
    case 'academic writing':
      return 'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675422721/probuddy/static/NewEducation/CategoryPage/academic_writing_wmzxvu.png';
    case 'development':
      return 'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675422735/probuddy/static/NewEducation/CategoryPage/Development_vrndzi.png';
    case 'business':
      return 'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675422644/probuddy/static/NewEducation/CategoryPage/business_kzhgbv.png';
    case 'language learning':
      return 'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675404121/probuddy/static/NewEducation/CategoryPage/Language%20Learning/pexels-pixabay-247819_di7gvq.jpg';
    case 'finance and accounting':
      return 'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675422560/probuddy/static/NewEducation/CategoryPage/Finance_Accounting_am7kcb.png';
    case 'it and software':
      return 'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675422574/probuddy/static/NewEducation/CategoryPage/IT_and_Software_guaqbj.png';
    case 'office productivity':
      return 'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675422726/probuddy/static/NewEducation/CategoryPage/Office_productivity_mpzwxt.png';
    case 'business development':
      return 'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675422464/probuddy/static/NewEducation/CategoryPage/business_development_je9g1q.png';
    case 'design':
      return 'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675422454/probuddy/static/NewEducation/CategoryPage/design_tx2ekf.png';
    case 'marketing':
      return 'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675422883/probuddy/static/NewEducation/CategoryPage/marketing_dlear0.png';
    case 'lifestyle':
      return 'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675422853/probuddy/static/NewEducation/CategoryPage/lifestyle_wqclo6.png';
    case 'photography and video':
      return 'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675422457/probuddy/static/NewEducation/CategoryPage/photography_hh9y6l.png';
    case 'health and fitness':
      return 'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675422442/probuddy/static/NewEducation/CategoryPage/Health_and_Fitness_fzqbqa.png';
    case 'music':
      return 'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675422449/probuddy/static/NewEducation/CategoryPage/Music_i6rnhk.png';
    case 'science':
      return 'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1676874810/probuddy/static/NewEducation/CategoryPage/science/science3_kr1fw6.jpg';
    case 'history':
      return 'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1676876271/probuddy/static/NewEducation/CategoryPage/history/history_zglhoc.jpg';

    default:
      return null;
  }
};

export const educationCategory = [
  {
    title: 'math',
    imgSrc: getCategoryImage('math'),
    page: 'AllAcademicsPage',
    params: { slug: 'math' },
  },
  {
    title: 'english',
    imgSrc: getCategoryImage('english'),
    page: 'AllAcademicsPage',
    params: { slug: 'english' },
  },
  {
    title: 'chemistry',
    imgSrc: getCategoryImage('chemistry'),
    page: 'AllAcademicsPage',
    params: { slug: 'chemistry' },
  },
  {
    title: 'physics',
    imgSrc: getCategoryImage('physics'),
    page: 'AllAcademicsPage',
    params: { slug: 'physics' },
  },
  {
    title: 'biology',
    imgSrc: getCategoryImage('biology'),
    page: 'AllAcademicsPage',
    params: { slug: 'biology' },
  },
  {
    title: 'french',
    imgSrc: getCategoryImage('french'),
    page: 'AllAcademicsPage',
    params: { slug: 'french' },
  },
  {
    title: 'german',
    imgSrc: getCategoryImage('german'),
    page: 'AllAcademicsPage',
    params: { slug: 'german' },
  },
  {
    title: 'geography',
    imgSrc: getCategoryImage('geography'),
    page: 'AllAcademicsPage',
    params: { slug: 'geography' },
  },
  {
    title: 'engineering',
    imgSrc: getCategoryImage('engineering'),
    page: 'AllAcademicsPage',
    params: { slug: 'engineering' },
  },
  {
    title: 'academic writing',
    imgSrc: getCategoryImage('academic writing'),
    page: 'AllAcademicsPage',
    params: { slug: 'academic-writing' },
  },
  {
    title: 'development',
    imgSrc: getCategoryImage('development'),
    page: 'AllAcademicsPage',
    params: { slug: 'development' },
  },
  {
    title: 'business',
    imgSrc: getCategoryImage('business'),
    page: 'AllAcademicsPage',
    params: { slug: 'business' },
  },
  {
    title: 'language learning',
    imgSrc: getCategoryImage('language learning'),
    page: 'AllAcademicsPage',
    params: { slug: 'language-learning' },
  },
  {
    title: 'finance and accounting',
    imgSrc: getCategoryImage('finance and accounting'),
    page: 'AllAcademicsPage',
    params: { slug: 'finance-and-accounting' },
  },
  {
    title: 'it & software',
    imgSrc: getCategoryImage('it and software'),
    page: 'AllAcademicsPage',
    params: { slug: 'it-and-software' },
  },
  {
    title: 'office productivity',
    imgSrc: getCategoryImage('office productivity'),
    page: 'AllAcademicsPage',
    params: { slug: 'office-productivity' },
  },
  {
    title: 'business development',
    imgSrc: getCategoryImage('business development'),
    page: 'AllAcademicsPage',
    params: { slug: 'business-development' },
  },
  {
    title: 'design',
    imgSrc: getCategoryImage('design'),
    page: 'AllAcademicsPage',
    params: { slug: 'design' },
  },
  {
    title: 'marketing',
    imgSrc: getCategoryImage('marketing'),
    page: 'AllAcademicsPage',
    params: { slug: 'marketing' },
  },
  {
    title: 'lifestyle',
    imgSrc: getCategoryImage('lifestyle'),
    page: 'AllAcademicsPage',
    params: { slug: 'lifestyle' },
  },
  {
    title: 'photography and video',
    imgSrc: getCategoryImage('photography and video'),
    page: 'AllAcademicsPage',
    params: { slug: 'photography-and-video' },
  },
  {
    title: 'health and fitness',
    imgSrc: getCategoryImage('health and fitness'),
    page: 'AllAcademicsPage',
    params: { slug: 'health-and-fitness' },
  },
  {
    title: 'music',
    imgSrc: getCategoryImage('music'),
    page: 'AllAcademicsPage',
    params: { slug: 'music' },
  },
  {
    title: 'science',
    imgSrc: getCategoryImage('science'),
    page: 'AllAcademicsPage',
    params: { slug: 'science' },
  },
  {
    title: 'history',
    imgSrc: getCategoryImage('history'),
    page: 'AllAcademicsPage',
    params: { slug: 'history' },
  },
];
