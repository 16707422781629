import React, { Component, useState, useRef } from 'react';
import { string, func } from 'prop-types';
import { intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import Slider from 'react-slick';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { propTypes } from '../../util/types';
import { ensureListing } from '../../util/data';
import { createSlug } from '../../util/urlHelpers';
import { NamedLink, ResponsiveImage, Avatar, SectionLevel } from '../../components';
import IconWhatsapp from '../../components/IconWhatsapp/IconWhatsapp';
import { priceData } from 'util/currency';
import StarRatings from 'react-star-ratings';
import { getRatingFromReviews } from 'containers/ListingPage/helper'
import css from './ListingCard.css';

class ListingImage extends Component {
  render() {
    return <ResponsiveImage {...this.props} />;
  }
}

const LazyImage = lazyLoadWithDimensions(ListingImage, { loadAfterInitialRendering: 3000 });

export const ListingCardComponent = props => {
  const { intl, listing, renderSizes, setActiveListing, reviews = [] } = props;
  const currentListing = ensureListing(listing);
  const [isZoomIn, setZoomIn] = useState(false);
  const sliderRef = useRef(null);
  const sliderSettings = {
    fade: true,
    infinite: true,
    speed: 0,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    swipe: false,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2100,

    responsive: [
      {
        breakpoint: 550,
        settings: {
          slidesToScroll: 0,
          arrows: false,
        },
      },
    ],
  };

  const id = currentListing.id.uuid;
  const { title = '', price, metadata, description } = currentListing.attributes;
  const slug = createSlug(title);
  const images =
    currentListing.images ? currentListing.images : [];
  const { formattedPrice} = priceData(price, intl);


  const author = currentListing.author;
  const rating = getRatingFromReviews(reviews);
  const reviewcount = reviews ? reviews.length : 0;

  const authorPublic = author ? author.attributes.profile.publicData : {};
  const contactNumber = authorPublic.phoneNumber || '';

  return (
    <NamedLink name="ListingPage" params={{ id, slug }} className={css.listingLink}>
      <div className={css.popular}
        onMouseOver={() => {
          sliderRef.current.slickPlay()
          setZoomIn(true)
        }}
        onMouseOut={() => {
          sliderRef.current.slickPause()
          setZoomIn(false)
        }}
      >
        <div
          className={css.threeToTwoWrapper}
          onMouseEnter={() => setActiveListing(currentListing.id)}
          onMouseLeave={() => setActiveListing(null)}
        >
          {images ? (
            <Slider ref={sliderRef} {...sliderSettings}
              autoplay={isZoomIn}
              className={css.slider}>
              {images.map(image => (
                <div key={image.id.uuid} className={css.imageWrapper}>
                  <div className={css.aspectWrapper}>
                    <LazyImage
                      rootClassName={css.rootForImage}
                      alt={title}
                      className={classNames(css.popularImage, { [css.popularImageHover]: isZoomIn })}
                      image={image}
                      variants={['scaled-small', 'square-small2x']}
                      sizes={renderSizes}
                    />
                    <div className={css.startingAt}>
                      from <span>{formattedPrice}</span>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          ) : null}
        </div>
        <div className={css.info}>
          <div className={css.linkText}>
            <div className={css.avatarWrapper}>
              <Avatar className={css.avatarImg} user={author} disableProfileLink={true} />
              <NamedLink name="ProfilePage" params={{ id: author.id.uuid }}>
                <span className={css.name}>{author.attributes.profile.displayName}</span>
              </NamedLink>

              {/* <div className={css.levelContainer}>
                <SectionLevel metadata={metadata} size={50} />
              </div> */}
            </div>
            <p className={css.headline}>{title}</p>
            <div className={css.star_Wrapper}>
              <div className={css.starWrapper}>
                <span className={css.rating}>
                  <StarRatings
                    rating={rating}
                    starRatedColor="#00bfe6"
                    numberOfStars={5}
                    starDimension="15px"
                    starSpacing="1px"
                  />
                </span>
                <span className={css.reviewCount}>{reviewcount}</span>
              </div>
            </div>
            <p className={css.description}>{description}</p>
            <div className={css.whatsappSection}>
              {metadata && <SectionLevel metadata={metadata} size={100} />}
              {
                contactNumber ?
                  <IconWhatsapp className={css.iconWhatsapp} />
                  : null
              }
            </div>
          </div>
        </div>
      </div>
    </NamedLink>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: () => null,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ListingCardComponent);
