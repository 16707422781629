import React from 'react';
import moment from 'moment';
import Collapsible from 'react-collapsible';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { loadData, buyerPackageRequests } from './WalletPage.duck';
import { denormalisedEntities } from '../../util/data';
import {
  LayoutSingleColumn,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  LayoutWrapperFooter,
  Footer,
  Page,
  SecondaryButton,
  PrimaryButton,
  NamedLink,
  Avatar,
  IconArrowDown,
  WalletListingCard,
  IconSpinner,
} from '../../components';
import { TopbarContainer } from '../../containers';
import { createSlug, stringify } from '../../util/urlHelpers';
import { types as sdkTypes } from '../../util/sdkLoader';
import { ensureCurrentUser } from '../../util/data';

import css from './WalletPage.css';

const { UUID } = sdkTypes;

const createListingLink = (listing, otherUser, searchParams = {}, className = '') => {
  const listingId = listing.id && listing.id.uuid;
  const label = listing.attributes.title;
  const listingDeleted = listing.attributes.deleted;

  if (!listingDeleted) {
    const params = { id: listingId, slug: createSlug(label) };
    const to = { search: stringify(searchParams) };
    return (
      <NamedLink className={className} name="ListingPage" params={params} to={to}>
        <Avatar user={otherUser} disableProfileLink />
      </NamedLink>
    );
  } else {
    return <FormattedMessage id="TransactionPanel.deletedListingOrderTitle" />;
  }
};

const STATUS_COLOR_CLASS = {
  pending: '#ffaa00',
  accepted: '#2ecc71',
  cancelled: '#b2b2b2',
};

class WalletPageComponent extends React.Component {
  render() {
    const {
      scrollingDisabled,
      intl,
      currentUser,
      walletListings,
      walletRequestInProgress,
      onBuyerPackageRequests,
      buyerPackageRequests,
      packages,
      isEducationSite,
      isTennisSite,
    } = this.props;
    if (buyerPackageRequests.length === 0 && currentUser)
      onBuyerPackageRequests(currentUser.id.uuid, '');

    const buyerListingRefs = buyerPackageRequests.map(pr => {
      return {
        id: new UUID(pr.listing_id),
        type: 'listing',
      };
    });
    const packageListing = denormalisedEntities(packages, buyerListingRefs, false);
    const title = intl.formatMessage({ id: 'WalletPage.title' });
    const panelWidth = 62.5;
    const renderSizes = [
      `(max-width: 767px) 100vw`,
      `(max-width: 1920px) ${panelWidth / 2}vw`,
      `${panelWidth / 3}vw`,
    ].join(', ');

    const toPackageRequestItem = pr => {
      const slug = createSlug(pr.listing_title);
      const ensuredCurrentUser = ensureCurrentUser(currentUser);
      const listing = packageListing.filter(listing => listing.id.uuid === pr.listing_id)[0];
      const otherUser = listing ? listing.author : null;
      const listingLink = listing && otherUser ? createListingLink(listing, otherUser) : null;
      const color = STATUS_COLOR_CLASS[pr.status];
      const paymentStatusColor = pr.payment_status === 'paid' ? '#2ecc71' : '#b2b2b2';
      const totalPrAmount = (pr.amount / 100.0).toFixed(2) + ` ${pr.currency}`;
      const usedPrAmount =
        ((pr.amount / 100.0 / pr.credits) * pr.used_credits).toFixed(2) + ` ${pr.currency}`;
      const remainingPrAmount =
        ((pr.amount / 100.0 / pr.credits) * (pr.credits - pr.used_credits)).toFixed(2) +
        ` ${pr.currency}`;
      const trigger = (
        <div className={css.trigger}>
          <div className={css.triggerSectionLeft}>
            <div>{listingLink}</div>
            <div className={css.userAndListingName}>
              {otherUser ? (
                <div className={css.userName}>
                  <NamedLink name={'ProfilePage'} params={{ id: otherUser.id.uuid }}>
                    {otherUser.attributes.profile.displayName}
                  </NamedLink>
                </div>
              ) : null}
              <div className={css.listingTitle}>
                <NamedLink name={'ListingPage'} params={{ slug: slug, id: pr.listing_id }}>
                  {pr.listing_title}
                </NamedLink>
              </div>
              <div className={css.createdAt}>
                {moment(pr.created_at).format('MMM Do YY, h:mm:ss a')}
              </div>
            </div>
            <div className={css.sessions}>
              {pr.credits} <FormattedMessage id="WalletPage.sessions" />
              <span className={css.prAmount}>{totalPrAmount}</span>
            </div>
            <div className={css.usedSessions}>
              {pr.credits - pr.used_credits} <FormattedMessage id="WalletPage.sessions" />
              <span className={css.prAmount}>{remainingPrAmount}</span>
            </div>
          </div>
          <div className={css.triggerSectionRight}>
            <div style={{ color: color }} className={css.status}>
              {pr.status.toUpperCase()}
            </div>
            {pr.payment_type === 'online_payment' ? (
              <div className={css.paymentInfoContainer}>
                <div className={css.payment_type}>
                  <FormattedMessage id="WalletPage.onlinePayment" />
                </div>
                <div style={{ color: paymentStatusColor }} className={css.payment_status}>
                  {pr.payment_status && pr.payment_status.toUpperCase()}
                </div>
              </div>
            ) : null}
            {pr.payment_type !== 'online_payment' &&
            pr.status === 'pending' &&
            ensuredCurrentUser.id.uuid === pr.seller_id ? (
              <div className={css.packageRequestActions}>
                <PrimaryButton
                  data-id={pr.id}
                  className={`${css.acceptPackageRequest} ${'avoidTrigger'}`}
                  spinnerClassName={css.spinner}
                  //disabled={acceptPackageRequestInProgress}
                  onClick={this.acceptPackageRequest}
                >
                  <FormattedMessage id="WalletPage.acceptPackageRequest" />
                </PrimaryButton>

                <SecondaryButton
                  data-id={pr.id}
                  className={`${css.rejectPackageRequest} ${'avoidTrigger'}`}
                  spinnerClassName={css.spinner}
                  //disabled={cancelPackageRequestInProgress}
                  onClick={this.rejectPackageRequest}
                >
                  <FormattedMessage id="WalletPage.rejectPackageRequest" />
                </SecondaryButton>
              </div>
            ) : null}
            <div className={css.iconArrowDown}>
              <IconArrowDown selected={false} />
            </div>
          </div>
        </div>
      );
      return pr ? (
        <div>
          <li className={css.listItem}>
            <Collapsible
              trigger={trigger}
              className={css.collapsible}
              openedClassName={css.collapsible}
            >
              <div>
                <h3>Total amount of package</h3>
                <div>{totalPrAmount}</div>

                <h3>Total amount of package sold</h3>
                <div>{usedPrAmount}</div>

                <h3>Remaining amount of package</h3>
                <div>{remainingPrAmount}</div>
              </div>

              <div className={css.paymentProofsContainer}>
                {pr.payment_proof_ss_urls.length > 0 ? (
                  <div>
                    <h3>
                      <FormattedMessage id="WalletPage.paymentScreenshots" />
                    </h3>
                    <div className={css.paymentProofSsContainer}>
                      {pr.payment_proof_ss_urls.map(ss => {
                        return (
                          <a
                            className={css.paymentProofSs}
                            // eslint-disable-next-line
                            target="_blank"
                            href={ss}
                          >
                            <img src={ss} alt="paymentProofSs" />
                          </a>
                        );
                      })}
                    </div>
                  </div>
                ) : null}
                {pr.membership_card_number ? (
                  <div>
                    <h3>
                      <FormattedMessage id="WalletPage.membershipCardNumber" />
                    </h3>
                    {pr.membership_card_number}
                  </div>
                ) : null}
              </div>
              <div className={css.transactions}>
                {pr.transactions && pr.transactions.length > 0 ? <h3>Delivered bookings</h3> : null}
                {pr.transactions &&
                  pr.transactions.length > 0 &&
                  pr.transactions.map(function(transaction, i) {
                    const refunds =
                      pr.refunds &&
                      pr.refunds.length > 0 &&
                      pr.refunds.map(refund => refund.transaction_id);
                    const refunded =
                      refunds && refunds.length > 0 && refunds.includes(transaction.transaction_id);
                    const user =
                      ensuredCurrentUser.id.uuid === pr.seller_id
                        ? otherUser
                          ? otherUser.attributes.profile.displayName
                          : 'Buyer'
                        : 'You';
                    return refunded ? null : (
                      <p>
                        {user} booked {transaction.credits_used} hour(s) on{' '}
                        {moment(transaction.created_at).format('MMM Do YY, h:mm:ss a')} for amount{' '}
                        {(transaction.amountPaid / 100.0).toFixed(2)} {pr.currency}
                      </p>
                    );
                  })}
              </div>
            </Collapsible>
          </li>
        </div>
      ) : null;
    };

    const wallet =
      currentUser && currentUser.attributes && currentUser.attributes.profile.privateData.sessions
        ? currentUser.attributes.profile.privateData.sessions
        : {};
    return (
      <Page title={title} scrollingDisabled={scrollingDisabled}>
        <LayoutSingleColumn>
          <LayoutWrapperTopbar rootClassName={css.layoutTopbar}>
            <TopbarContainer
              currentPage="WalletPage"
              desktopClassName={css.desktopTopbar}
              mobileClassName={css.mobileTopbar}
              isEducationSite={isEducationSite}
              // isTennisSite={isTennisSite}
            />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain className={css.staticPageWrapper}>
            <div className={css.listingPanel}>
              <h1 className={css.title}>
                <FormattedMessage id="WalletPage.heading" />
              </h1>
              <div className={css.listingCards}>
                {walletRequestInProgress ? (
                  <div className={css.listingsLoading}>
                    <IconSpinner />
                  </div>
                ) : walletListings && walletListings.length > 0 ? (
                  walletListings.map(l =>
                    wallet[l.id.uuid] ? (
                      <WalletListingCard
                        className={css.listingCard}
                        key={l.id.uuid}
                        listing={l}
                        renderSizes={renderSizes}
                        sessionsRemaining={wallet[l.id.uuid]}
                      />
                    ) : null
                  )
                ) : (
                  <FormattedMessage id="WalletPage.noCredits" />
                )}
              </div>
              <div>
                {buyerPackageRequests && buyerPackageRequests.length > 0 ? (
                  <>
                    <span className={css.allPackages}>
                      <FormattedMessage id="WalletPage.allPackages" />
                    </span>
                    {buyerPackageRequests.map(toPackageRequestItem)}
                  </>
                ) : (
                  <FormattedMessage id="WalletPage.noPackageRequestsAsBuyer" />
                )}
              </div>
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter rootClassName={css.layoutFooter}>
            <Footer isEducationSite={isEducationSite} />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

WalletPageComponent.defaultProps = {};

WalletPageComponent.propTypes = {
  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  // Topbar needs user info.
  const {
    walletRequestError,
    walletRequestInProgress,
    walletListings,
    buyerPackageRequests,
  } = state.WalletPage;

  const { currentUser } = state.user;
  const { entities } = state.marketplaceData;
  return {
    walletRequestError,
    walletRequestInProgress,
    currentUser,
    walletListings,
    buyerPackageRequests,
    packages: entities,
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const mapDispatchToProps = dispatch => ({
  onBuyerPackageRequests: id => dispatch(buyerPackageRequests(id)),
});

const WalletPage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(WalletPageComponent);

WalletPage.loadData = loadData;

export default WalletPage;
