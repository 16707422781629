import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './PrivacyPolicy.css';

const PrivacyPolicy = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <p className={css.lastUpdated}>Last updated: November 23, 2020</p>

      <p>
        This Privacy Policy describes how we manage Personal Data in compliance with the Singapore
        Personal Data Protection Act 2012 (No. 26 of 2012) (“Act”). We encourage you to read this
        Privacy Policy so that you know and understand the purposes for which we collect, use and
        disclose your Personal Data.
      </p>

      <p>
        We may update this Privacy Policy from time to time. Subject to your rights at law, you
        agree to be bound by the prevailing terms of this Privacy Policy as updated from time to
        time. We encourage you to check the latest version of this Privacy Policy regularly.
      </p>

      <p>
        "Personal Data" refers to data, whether true or not, about an individual who can be
        identified from that data, or from that data in combination with other information to which
        the organisation may have access and includes the meaning otherwise as defined in the Act as
        amended from time to time.
      </p>

      <p>
        By signing up for or using any products, services or campaigns offered by Probuddy.io or
        submitting information to or otherwise communicating with Probuddy.io, you agree and consent
        to Probuddy.io and our related entities, affiliates and subsidiaries (individually and
        collectively, "Companies"), as well as their respective representatives and/or agents
        (collectively referred to herein as " Probuddy.io", "us", "we" or "our") collecting, using
        and disclosing your Personal Data in accordance with this Privacy Policy. “You” or “your”
        means any individual to whom the Act applies and includes an individual actual or
        prospective customer but excludes any corporate entity (including corporate customers) and
        any other entity that is excluded under the Act.
      </p>

      <p>
        This Privacy Policy does not supersede or replace any other consents you may have previously
        or separately provided to us in respect of your Personal Data, and your consent to this
        Privacy Policy is in addition to any other rights which any of the Companies may have at law
        to collect, use or disclose your Personal Data.
      </p>

      <p>
        This Privacy Policy and your use of this website shall be governed in all respects by the
        laws of Singapore.
      </p>

      <h2>Collection & Consent</h2>
      <p>
        Personal Data may be collected from you in one or more of the following ways:
        <ul>
          <li>when you order or purchase products or services from us;</li>
          <li>
            when you post a feedback or interact with our customer service officers, eg. via
            meetings, emails or telephone calls (which may be recorded for training, quality
            control, business and/or other lawful purposes);
          </li>
          <li>
            when you use some of our services including establishing any online accounts with us;
          </li>
          <li>
            when you request that we contact you, be included in an email or other mailing list;
          </li>
          <li>
            when you respond to our promotions, campaigns or other initiatives or attend our events;
          </li>
          <li>
            when we receive references from business partners and third parties, for example, where
            you have been referred by them;
          </li>
          <li>when you visit our websites;</li>
          <li>
            when we receive information about you from third party social networking services when
            you choose to connect with those services;
          </li>
          <li>when you provide or contemplate providing security to us for a transaction;</li>
          <li>when you submit your Personal Data to us for any other reasons; and/or</li>
          <li>when we collect your Personal Data by other lawful means.</li>
        </ul>
      </p>

      <p>
        Unless permitted by applicable laws, we will not collect Personal Data without your consent.
        You warrant and represent to us that (a) Personal Data which you disclose to us is accurate
        and complete; and (b) where you volunteer Personal Data of another person to us, that you
        are authorized by such other person to disclose such Personal Data to us, and that such
        Personal Data is accurate and complete. You shall consult your parent or guardian before
        giving us your Personal Data if you are under the age of eighteen.
      </p>

      <h2>Purposes for which we collect, use and disclose your Personal Data</h2>

      <p>
        Your Personal Data may be collected, used and/or disclosed for the following purposes:
        <ul>
          <li>to verify and process your personal particulars and payments;</li>
          <li>to provide goods and services to you;</li>
          <li>
            to respond and deal with enquiries, complaints and other customer-care matters or
            otherwise communicate with you;
          </li>
          <li>
            to monitor or record phone calls and customer-facing interactions for quality assurance,
            employee training and performance evaluation and identity verification purposes;
          </li>
          <li>to manage payment, billing, account, credit checks and debt-recovery matters;</li>
          <li>
            to send you information, promotions, updates, and marketing and advertising materials in
            relation to our goods and services and that of our group companies;
          </li>
          <li>to manage, develop and improve our business and operations to serve you better;</li>
          <li>
            to carry out marketing promotions and campaigns, contests and lucky draws and
            personalising your experience at our customer touchpoints;
          </li>
          <li>to carry out market research and customer surveys;</li>
          <li>
            to conduct investigations or audits or carry out crime and fraud prevention and risk
            management activities;
          </li>
          <li>to comply with legal and regulatory requirements;</li>
          <li>to enforce our legal rights and obligations;</li>
          <li>
            to facilitate business asset transactions (which may extend to any mergers, acquisitions
            or asset sales);
          </li>
          <li>for other purposes for which we have obtained your consent;</li>
          <li>
            for any other purposes reasonably necessary, ancillary or related to the above specified
            purposes.
          </li>
        </ul>
        Your Personal Data may be disclosed for the purposes indicated above to our officers and
        employees, third parties, affiliates, service providers, advisors, without limitation.
      </p>

      <p>
        If you have provided us with your Singapore telephone number(s) and have indicated that you
        consent to receiving marketing or promotional information via your Singapore telephone
        number(s), then from time to time, we may contact you using such Singapore telephone
        number(s) (including via voice calls, text, fax or other means) with information about our
        products and services.
      </p>

      <p>
        Your Personal Data may be transferred, stored and/or processed in a country or territory
        outside Singapore and you consent to any such transfer, storage and/or processing of your
        Personal Data outside Singapore. We will however ensure that any party to whom we transfer
        your Personal Data outside Singapore provides to such Personal Data a standard of protection
        at least comparable to the protection under the Act.
      </p>
      <p>
        Rest assured, your data will remain confidential and will not be shared with external
        parties beyond our platform.
      </p>

      <h2>Withdrawal of Consent & Access</h2>
      <p>
        You may withdraw your consent to our continued use and disclosure of your Personal Data or
        seek access to your Personal Data (to a reasonable extent and as permitted by the Act) at
        any time by writing to Contact Us. You may also withdraw your consent for specific forms of
        communication and on specific communications via the unsubscribe options as stated on our
        email or other marketing messages. 
      </p>

      <h2>Retention of Personal Data</h2>
      <p>
        We will retain your Personal Data for as long as you use our products or services and it is
        necessarily required or relevant for business or legal purposes.
      </p>

      <h2>Cookies</h2>
      <p>
        We gather Information on our website activity, such as data on the number of visitors, the
        pages they visit, the duration of their stay, etc. Such information is collected on an
        aggregate, anonymous basis, which means no Personal Data is associated with this data and
        gathered through the use of web server logs and cookies. We do not at our website
        automatically collect Personal Data unless you provide such information or login with your
        account credentials. Cookies are small bits of data automatically stored in the hard drive
        of the end user and are commonly used to track preferences in relation to the subject of
        such website. If you enable these cookies, then your web browser adds the text in a small
        file. You may wish to set your web browser to notify you of a cookie placement request or
        refuse to accept cookies by modifying relevant internet options or browsing preferences of
        your computer system, but to do so you may not be able to utilize or activate certain
        available functions on our website.
      </p>

      <p>
        By accessing and using our website and services, you consent to the storage of cookies,
        other local storage technologies, beacons and other information on your devices. You also
        consent to the access of such cookies, local storage technologies, beacons and information
        by us or our representatives or agents.
      </p>

      <h2>Third-Party Links and Products on Our Services</h2>
      <p>
        Our website and/or services may link to third-party websites and services that are outside
        our control. For example, we may enable you to share certain materials on the services with
        others through social networking services such as Facebook. We are not responsible for the
        security or privacy of any information collected by websites or other services. You should
        exercise caution, and review the privacy statements applicable to the third-party websites
        and services you use. To the fullest extent permitted under laws, we cannot be responsible
        for a third party's acts, omissions, data policies or their use of cookies nor the content
        or security of any third party websites, even if linked to our website. Any such liability
        is expressly disclaimed and excluded.
      </p>
    </div>
  );
};

PrivacyPolicy.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

PrivacyPolicy.propTypes = {
  rootClassName: string,
  className: string,
};

export default PrivacyPolicy;
