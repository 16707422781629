import React from 'react';
import PropTypes from 'prop-types';
import { uid } from 'react-uid';
import classNames from 'classnames';
import range from 'lodash/range';
import css from './PaginationButtons.css';

const getPageNumbersArray = (page, totalPages) => {
  // Create array of numbers: [1, 2, 3, 4, ..., totalPages]
  const numbersFrom1ToTotalPages = range(1, totalPages + 1);
  return numbersFrom1ToTotalPages
    .filter(v => {
      // Filter numbers that are next to current page and pick also first and last page
      // E.g. [1, 4, 5, 6, 9], where current page = 5 and totalPages = 9.
      return v === 1 || Math.abs(v - page) <= 1 || v === totalPages;
    })
    .reduce((newArray, p) => {
      // Create a new array where gaps between consecutive numbers is filled with ellipsis character
      // E.g. [1, '…', 4, 5, 6, '…', 9], where current page = 5 and totalPages = 9.
      const isFirstPageOrNextToCurrentPage = p === 1 || newArray[newArray.length - 1] + 1 === p;
      return isFirstPageOrNextToCurrentPage ? newArray.concat([p]) : newArray.concat(['\u2026', p]);
    }, []);
};

const PaginationButtons = ({ page, totalPages, onPageClick }) => {
  const pages = getPageNumbersArray(page, totalPages);
  return (
    <div className={css.pagination}>
      {pages.map((i, ind) => {
        const isCurrentPage = i === page;
        const pageClassNames = classNames(css.paginationLink, { [css.current]: isCurrentPage });
        return typeof i === 'number' ? (
          <span key={i} onClick={() => onPageClick && onPageClick(i)} className={pageClassNames}>
            {i}
          </span>
        ) : (
          <span key={`pagination_gap_${uid(ind)}`} className={css.paginationLink}>
            {i}
          </span>
        );
      })}
    </div>
  );
};

PaginationButtons.propTypes = {
  page: PropTypes.number,
  totalPages: PropTypes.number,
  onPageClick: PropTypes.func,
};

export default PaginationButtons;
