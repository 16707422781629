import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { createResourceLocatorString } from 'util/routes';
import routeConfiguration from '../../routeConfiguration';
import css from './ThankYouPage.css';
import thankyousvg from '../../assets/ThankYouPage/thankyousvg.svg';

const ThankYouPageComponent = props => {
  const { history } = props;

  const GoToHome = () =>
    history.push(createResourceLocatorString('HomePage', routeConfiguration(), {}));

  return (
    <div className={css.mainWrapper}>
      <div className={css.thankyouWrapper}>
        <img src={thankyousvg} alt={" "}/>
        <h1>
          <FormattedMessage id="ThankYouPage.message" />
        </h1>
        <button className={css.btnDone} onClick={GoToHome}>
          <FormattedMessage id="ThankYouPage.done" />
        </button>
      </div>
    </div>
  );
};

const ThankYouPage = compose(withRouter)(injectIntl(ThankYouPageComponent));

export default ThankYouPage;
