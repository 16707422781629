import React, { Component } from 'react';
import { bool, func, number, shape, string, array } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import pickBy from 'lodash/pickBy';
import omit from 'lodash/omit';
import classNames from 'classnames';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { withViewport } from '../../util/contextHelpers';
import { parse, stringify } from '../../util/urlHelpers';
import { createResourceLocatorString, pathByRouteName } from '../../util/routes';
import { propTypes } from '../../util/types';
import {
  Button,
  Logo,
  Modal,
  ModalMissingInformation,
  NamedLink,
  TopbarDesktop,
  TopbarMobileMenu,
  IconSearch,
} from '../../components';
import { default as CompanyTopbar } from '../TopbarDesktopNew/TopbarDesktop';
import { TopbarSearchForm } from '../../forms';

import MenuIcon from './MenuIcon';
import SearchIcon from './SearchIcon';
import css from './Topbar.css';
import { LISTING_TYPES } from 'util/constants';

const MAX_MOBILE_SCREEN_WIDTH = 1024;

const redirectToURLWithModalState = (props, modalStateParam) => {
  const { history, location } = props;
  const { pathname, search, state } = location;
  const searchString = `?${stringify({ [modalStateParam]: 'open', ...parse(search) })}`;
  history.push(`${pathname}${searchString}`, state);
};

const redirectToURLWithoutModalState = (props, modalStateParam) => {
  const { history, location } = props;
  const { pathname, search, state } = location;
  const queryParams = pickBy(parse(search), (v, k) => {
    return k !== modalStateParam;
  });
  const stringified = stringify(queryParams);
  const searchString = stringified ? `?${stringified}` : '';
  history.push(`${pathname}${searchString}`, state);
};

const GenericError = props => {
  const { show } = props;
  const classes = classNames(css.genericError, {
    [css.genericErrorVisible]: show,
  });
  return (
    <div className={classes}>
      <div className={css.genericErrorContent}>
        <p className={css.genericErrorText}>
          <FormattedMessage id="Topbar.genericError" />
        </p>
      </div>
    </div>
  );
};

GenericError.propTypes = {
  show: bool.isRequired,
};

class TopbarComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keyWordValue: '',
    };
    this.handleMobileMenuOpen = this.handleMobileMenuOpen.bind(this);
    this.handleMobileMenuClose = this.handleMobileMenuClose.bind(this);
    this.handleMobileSearchOpen = this.handleMobileSearchOpen.bind(this);
    this.handleMobileSearchClose = this.handleMobileSearchClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
  }

  componentDidMount() {
    const urlQueryParams = parse(this.props.location.search);
    if (urlQueryParams && urlQueryParams.keywords && this.state.keyWordValue === '') {
      this.setState({ keyWordValue: urlQueryParams.keywords });
    }
  }

  handleMobileMenuOpen() {
    redirectToURLWithModalState(this.props, 'mobilemenu');
  }

  handleMobileMenuClose() {
    redirectToURLWithoutModalState(this.props, 'mobilemenu');
  }

  handleMobileSearchOpen() {
    redirectToURLWithModalState(this.props, 'mobilesearch');
  }

  handleMobileSearchClose() {
    redirectToURLWithoutModalState(this.props, 'mobilesearch');
  }

  handleSubmit(values) {
    const { currentSearchParams } = this.props;
    const { search, selectedPlace } = values.location;
    const { history } = this.props;
    const { origin, bounds } = selectedPlace;
    const originMaybe = config.sortSearchByDistance ? { origin } : {};
    const searchParams = {
      ...currentSearchParams,
      ...originMaybe,
      pub_category: currentSearchParams?.pub_category || 'sports',
      pub_listing_mode: currentSearchParams?.pub_listing_mode || 'in_person',
      pub_listing_type: currentSearchParams?.pub_listing_type || LISTING_TYPES.COMPANY,
      address: search,
      sort: currentSearchParams?.sort || 'pub_total_ratings',
      bounds,
    };
    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchParams));
  }

  handleLogout() {
    const { onLogout, history, setUserWasFetched } = this.props;
    onLogout().then(() => {
      let path = pathByRouteName('LandingPage', routeConfiguration());
      let isPrivateSite = false;
      if (typeof window !== 'undefined' && !!window.sessionStorage) {
        isPrivateSite = window.sessionStorage.getItem('privateSiteUrl') ? true : false;
      }
      if (isPrivateSite) {
        path = window.sessionStorage.getItem('privateSiteUrl');
      }

      /**
       * During logout the whole redux state is reset,
       * Action is needed to set user.currentUserWasFetched to true after logout
       * in order to show BottomMenu after user logged out
       * **/
      setUserWasFetched();
      if (typeof window !== 'undefined') {
        // In production we ensure that data is really lost,
        // but in development mode we use stored values for debugging
        if (config.dev) {
          history.push(path);
        } else if (typeof window !== 'undefined') {
          window.location = path;
        }
      }
    });
  }

  render() {
    const {
      className,
      rootClassName,
      desktopClassName,
      mobileRootClassName,
      mobileClassName,
      isAuthenticated,
      authInProgress,
      currentUser,
      currentUserHasListings,
      currentUserListing,
      currentUserListingFetched,
      currentUserHasOrders,
      currentPage,
      notificationCount,
      viewport,
      intl,
      location,
      menuList,
      history,
      onManageDisableScrolling,
      onResendVerificationEmail,
      sendVerificationEmailInProgress,
      sendVerificationEmailError,
      showGenericError,
      lastCompleteTransaction,
      lastBookingReviewDone,
      onSendReview,
      onSkipReview,
      onResetReviewState,
      sendReviewInProgress,
      sendReviewError,
      onSetLastBookingReviewed,
      lastAcceptedPackage,
      lastAcceptedPackageNoteSeen,
      onSetAcceptedPackageNoteSeen,
      isDarkMode,
      keywordSearchTopbar,
      isCompanyPage,
      match,
      onMainWrapperBlur,
      isMainWrapperBlur,
      searchActivity,
      OnQueryPromotedListings,
    } = this.props;
    const { mobilemenu, mobilesearch, address, origin, bounds } = parse(location.search, {
      latlng: ['origin'],
      latlngBounds: ['bounds'],
    });

    const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;
    let isPrivateSite = false;
    if (typeof window !== 'undefined' && !!window.sessionStorage) {
      isPrivateSite = window.sessionStorage.getItem('privateSiteUrl') ? true : false;
    }
    const isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;
    const isMobileMenuOpen = isMobileLayout && mobilemenu === 'open';
    const isMobileSearchOpen = isMobileLayout && mobilesearch === 'open';

    const mobileMenu = (
      <TopbarMobileMenu
        isAuthenticated={isAuthenticated}
        currentUserHasListings={currentUserHasListings}
        currentUserListing={currentUserListing}
        currentUserListingFetched={currentUserListingFetched}
        currentUser={currentUser}
        onLogout={this.handleLogout}
        notificationCount={notificationCount}
        currentPage={currentPage}
        location={location}
      />
    );

    const keywordPlaceholder = intl.formatMessage({ id: 'SectionSearch.search' });
    const handleKeyword = (urlParam, values) => {
      const urlQueryParams = parse(location.search);
      const queryParams = values
        ? { ...urlQueryParams, [urlParam]: values }
        : omit(urlQueryParams, urlParam);

      history.push(createResourceLocatorString(currentPage, routeConfiguration(), {}, queryParams));
    };
    const filterKeywordContent = (
      <div className={css.filterKeywordWrap}>
        <div className={css.filterWrap}>
          <div className={css.inputWrap}>
            <input
              id={'SearchFilters.keywordFilter'}
              value={this.state.keyWordValue}
              placeholder={keywordPlaceholder}
              onChange={e => this.setState({ keyWordValue: e.target.value })}
            />
          </div>
          <div
            className={css.btnapply}
            onClick={() => handleKeyword('keywords', this.state.keyWordValue)}
          >
            <IconSearch />
          </div>
        </div>
      </div>
    );

    // Only render current search if full place object is available in the URL params
    const locationFieldsPresent = config.sortSearchByDistance
      ? address && origin && bounds
      : address && bounds;
    const initialSearchFormValues = {
      location: locationFieldsPresent
        ? {
            search: address,
            selectedPlace: { address, origin, bounds },
          }
        : null,
    };

    const mobileTopbarContent = !keywordSearchTopbar ? (
      <div
        className={classNames(mobileRootClassName || css.container, mobileClassName, {
          [css.darkContainer]: isDarkMode,
        })}
      >
        {isPrivateSite ? (
          <a
            title={intl.formatMessage({ id: 'Topbar.logoIcon' })}
            href={window.sessionStorage.getItem('privateSiteUrl')}
          >
            <Logo isPrivateSite={isPrivateSite} format="mobile" />
          </a>
        ) : (
          <NamedLink name="LandingPage" title={intl.formatMessage({ id: 'Topbar.logoIcon' })}>
            <Logo
              format="mobile"
              className={css.mobileLogo}
              isPrivateSite={isPrivateSite}
              isDarkMode={isDarkMode}
            />
          </NamedLink>
        )}
        <div className={css.topRight}>
          {!isPrivateSite && (
            <Button
              rootClassName={css.searchMenu}
              onClick={this.handleMobileSearchOpen}
              title={intl.formatMessage({ id: 'Topbar.searchIcon' })}
            >
              <SearchIcon className={css.searchMenuIcon} />
            </Button>
          )}
          <Button
            rootClassName={css.menu}
            onClick={this.handleMobileMenuOpen}
            title={intl.formatMessage({ id: 'Topbar.menuIcon' })}
          >
            <MenuIcon className={css.menuIcon} />
            {notificationDot}
          </Button>
        </div>
      </div>
    ) : (
      <div className={css.keywordMobileTopbarContainer}>
        <svg
          onClick={() => history.push('/')}
          className={css.goBackArrow}
          xmlns="http://www.w3.org/2000/svg"
          width="13"
          height="22"
          viewBox="0 0 13 22"
          fill="none"
        >
          <path
            d="M10.8594 2.3457L2.50057 10.7045L10.8594 19.0633"
            stroke="#2D2D2D"
            strokeWidth="4.1794"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>

        {filterKeywordContent}

        <Button
          rootClassName={css.topbarMenu}
          onClick={this.handleMobileMenuOpen}
          title={intl.formatMessage({ id: 'Topbar.menuIcon' })}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="17"
            viewBox="0 0 22 17"
            fill="none"
          >
            <path d="M2 1.54297H20" stroke="#233045" strokeWidth="2.5" strokeLinecap="round" />
            <path d="M2 8.58789H20" stroke="#233045" strokeWidth="2.5" strokeLinecap="round" />
            <path d="M2 15.543H12" stroke="#233045" strokeWidth="2.5" strokeLinecap="round" />
          </svg>
          {notificationDot}
        </Button>
      </div>
    );

    const classes = classNames(rootClassName || css.root, className);

    return (
      <div className={classes}>
        {!isCompanyPage ? mobileTopbarContent : null}
        {!isCompanyPage ? (
          <div className={css.desktop}>
            <TopbarDesktop
              className={desktopClassName}
              currentUserHasListings={currentUserHasListings}
              currentUserListing={currentUserListing}
              currentUserListingFetched={currentUserListingFetched}
              currentUser={currentUser}
              currentPage={currentPage}
              OnQueryPromotedListings={OnQueryPromotedListings}
              searchActivity={searchActivity}
              initialSearchFormValues={initialSearchFormValues}
              intl={intl}
              isAuthenticated={isAuthenticated}
              notificationCount={notificationCount}
              onLogout={this.handleLogout}
              onSearchSubmit={this.handleSubmit}
              isDarkMode={isDarkMode}
            />
          </div>
        ) : (
          <CompanyTopbar
            className={desktopClassName}
            currentUserHasListings={currentUserHasListings}
            currentUserListing={currentUserListing}
            currentUserListingFetched={currentUserListingFetched}
            currentUser={currentUser}
            currentPage={currentPage}
            initialSearchFormValues={initialSearchFormValues}
            intl={intl}
            isAuthenticated={isAuthenticated}
            menuList={menuList}
            OnQueryPromotedListings={OnQueryPromotedListings}
            notificationCount={notificationCount}
            onLogout={this.handleLogout}
            onSearchSubmit={this.handleSubmit}
            isDarkMode={isDarkMode}
            params={match.params}
            onMainWrapperBlur={onMainWrapperBlur}
            onManageDisableScrolling={onManageDisableScrolling}
            viewport={viewport}
            history={history}
            isMainWrapperBlur={isMainWrapperBlur}
          />
        )}

        <Modal
          id="TopbarMobileMenu"
          isOpen={isMobileMenuOpen}
          onClose={this.handleMobileMenuClose}
          closeButtonClassName={css.mobileCloseBtn}
          containerClassName={css.mobileMenuModalContainer}
          closeButtonMessage={''}
          onManageDisableScrolling={onManageDisableScrolling}
        >
          <div>
            <div className={css.mobileModalRoot}>
              <div className={classNames(mobileRootClassName || css.container, mobileClassName)}>
                <svg
                  onClick={() => history.push('/')}
                  className={css.goBackArrow}
                  xmlns="http://www.w3.org/2000/svg"
                  width="13"
                  height="22"
                  viewBox="0 0 13 22"
                  fill="none"
                >
                  <path
                    d="M10.8594 2.3457L2.50057 10.7045L10.8594 19.0633"
                    stroke="#2D2D2D"
                    strokeWidth="4.1794"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>

                {isPrivateSite ? (
                  <a
                    title={intl.formatMessage({ id: 'Topbar.logoIcon' })}
                    href={window.sessionStorage.getItem('privateSiteUrl')}
                  >
                    <Logo isPrivateSite={isPrivateSite} format="mobile" />
                  </a>
                ) : (
                  <NamedLink
                    name="LandingPage"
                    title={intl.formatMessage({ id: 'Topbar.logoIcon' })}
                  >
                    <Logo className={css.mobileLogo} format="mobile" />
                  </NamedLink>
                )}
                <div className={css.topRight}>
                  {!isPrivateSite && (
                    <Button
                      rootClassName={css.searchMenu}
                      onClick={this.handleMobileSearchOpen}
                      title={intl.formatMessage({ id: 'Topbar.searchIcon' })}
                    >
                      <SearchIcon className={css.searchMenuIcon} />
                    </Button>
                  )}
                </div>
              </div>
            </div>

            {authInProgress ? null : mobileMenu}
          </div>
        </Modal>
        <Modal
          id="TopbarMobileSearch"
          containerClassName={css.modalContainer}
          isOpen={isMobileSearchOpen}
          onClose={this.handleMobileSearchClose}
          onManageDisableScrolling={onManageDisableScrolling}
        >
          <div className={css.searchContainer}>
            <TopbarSearchForm
              onSubmit={this.handleSubmit}
              initialValues={initialSearchFormValues}
              isMobile
            />
            <p className={css.mobileHelp}>
              <FormattedMessage id="Topbar.mobileSearchHelp" />
            </p>
          </div>
        </Modal>
        <ModalMissingInformation
          id="MissingInformationReminder"
          containerClassName={css.missingInformationModal}
          currentUser={currentUser}
          currentUserHasListings={currentUserHasListings}
          currentUserHasOrders={currentUserHasOrders}
          location={location}
          onManageDisableScrolling={onManageDisableScrolling}
          onResendVerificationEmail={onResendVerificationEmail}
          sendVerificationEmailInProgress={sendVerificationEmailInProgress}
          sendVerificationEmailError={sendVerificationEmailError}
          lastCompleteTransaction={lastCompleteTransaction}
          lastBookingReviewDone={lastBookingReviewDone}
          onSendReview={onSendReview}
          onSkipReview={onSkipReview}
          onResetReviewState={onResetReviewState}
          onSetLastBookingReviewed={onSetLastBookingReviewed}
          sendReviewInProgress={sendReviewInProgress}
          sendReviewError={sendReviewError}
          lastAcceptedPackage={lastAcceptedPackage}
          lastAcceptedPackageNoteSeen={lastAcceptedPackageNoteSeen}
          onSetAcceptedPackageNoteSeen={onSetAcceptedPackageNoteSeen}
          history={history}
        />

        <GenericError show={showGenericError} />
      </div>
    );
  }
}

TopbarComponent.defaultProps = {
  className: null,
  rootClassName: null,
  desktopClassName: null,
  mobileRootClassName: null,
  mobileClassName: null,
  notificationCount: 0,
  currentUser: null,
  currentUserHasOrders: null,
  currentPage: null,
  priceFilterConfig: config.custom.priceFilterConfig,
  languageConfig: config.custom.languageFilterConfig,
  sendVerificationEmailError: null,
  isDarkMode: false,
};

TopbarComponent.propTypes = {
  className: string,
  rootClassName: string,
  desktopClassName: string,
  mobileRootClassName: string,
  mobileClassName: string,
  isAuthenticated: bool.isRequired,
  authInProgress: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentUserHasListings: bool.isRequired,
  currentUserHasOrders: bool,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onResendVerificationEmail: func.isRequired,
  sendVerificationEmailInProgress: bool.isRequired,
  sendVerificationEmailError: propTypes.error,
  showGenericError: bool.isRequired,
  onSetLastBookingReviewed: func,
  isDarkMode: bool,
  languageConfig: array,
  priceFilterConfig: shape({
    min: number.isRequired,
    max: number.isRequired,
    step: number.isRequired,
  }),

  // These are passed from Page to keep Topbar rendering aware of location changes
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string.isRequired,
  }).isRequired,

  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const Topbar = compose(withViewport, injectIntl)(TopbarComponent);

Topbar.displayName = 'Topbar';

export default React.memo(Topbar);
