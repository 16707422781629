import React, { memo } from 'react';

import { useSelector } from 'react-redux';
import { Field, Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';
import { subCategoriesFilterOptions } from 'marketplace-custom-config';
import {
  autocompleteSearchRequired,
  autocompletePlaceSelected,
  composeValidators,
} from '../../../util/validators';
import { LocationAutocompleteInputField, Form, CustomFieldTextInput } from '../../../components';
import EnquiryCard from '../../../containers/EditListingPage/EditEnquiryPage/EnquiryCard';

import { FormattedMessage } from 'react-intl';
import css from '../HomePage.css';

import { identity } from '../HomePage.data';

const EnquiryFormHome = props => {
  const {
    promotedListings = [],
    intl,
    prop,
    currentUser,
    filterSearch,
    handleOnChange,
    handleOnCheck,
    isAuthenticated,
    onSendEnquiry,
    history,
    isEducationSite,
  } = props;

  const { sendEnquiryError, sendEnquiryInProgress } = useSelector(state => state.HomePage);

  const addressPlaceholderMessage = intl.formatMessage({
    id: 'EditListingLocationForm.addressPlaceholder',
  });

  const addressRequiredMessage = intl.formatMessage({
    id: 'EditListingLocationForm.addressRequired',
  });

  const addressNotRecognizedMessage = intl.formatMessage({
    id: 'EditListingLocationForm.addressNotRecognized',
  });

  return (
    <FinalForm
      {...prop}
      onSubmit={() => {}}
      mutators={{ ...arrayMutators }}
      render={fieldRenderProps => {
        const { rootClassName, className, values, form } = fieldRenderProps;
        let { searchInputData } = fieldRenderProps;
        const classes = classNames(rootClassName || css.root, className);

        return (
          <Form className={classes} onSubmit={e => e.preventDefault()}>
            <div className={css.enquiryCardWrapper}>
              <h2 className={css.enquiryCardTitle}>Recent Customer Request</h2>
              <div className={css.enquiryCard}>
                {promotedListings.length > 0 ? (
                  <EnquiryCard
                    rootClassName={css.enquiryCardRoot}
                    listingType={'enquiry'}
                    enquiryListings={promotedListings}
                    currentUser={currentUser}
                    showDate={true}
                    searchInput={searchInputData && searchInputData.value}
                    isAuthenticated={isAuthenticated}
                    onSendEnquiry={onSendEnquiry}
                    sendEnquiryError={sendEnquiryError}
                    sendEnquiryInProgress={sendEnquiryInProgress}
                    intl={intl}
                    history={history}
                    isEducationSite={isEducationSite}
                  />
                ) : (
                  <FormattedMessage id="HomePage.NoListingsFound" />
                )}
                <div className={css.formatWrapper}>
                  <div className={css.searchWrapper}>
                    <div className={css.filterWrapper}>
                      <div className={css.formatTitle}>Filter</div>
                      <div className={css.formatBody}>
                        <CustomFieldTextInput
                          type="text"
                          id="search"
                          name="search"
                          placeholder="Search"
                          inputRootClass={css.filterInputBody}
                          value={filterSearch}
                          onChange={event => {
                            const value = event.target.value;
                            form.change('search', value);
                            handleOnChange(value);
                          }}
                        />
                      </div>
                    </div>
                    <div className={css.categoryWrapper}>
                      <div className={css.formatTitle}>Category</div>
                      <div className={css.categoryBody}>
                        <ul>
                          {subCategoriesFilterOptions?.slice(4).map(data => (
                            <li key={data.key}>
                              <input
                                className={css.styledCheckbox}
                                type="checkbox"
                                id={data.key}
                                value={data.key}
                                onChange={event => {
                                  const value = event.target.value;
                                  form.change('check', value);
                                  handleOnCheck(event);
                                }}
                              />
                              <label className={css.labelText} htmlFor={data.key}>
                                {data.label}
                              </label>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                    <div className={css.locationWrapper}>
                      <div className={css.formatTitle}>Location</div>
                      <div className={css.formatBody}>
                        <Field
                          name="filter_location"
                          format={identity}
                          render={({ input, meta }) => {
                            const { onChange, ...restInput } = input;

                            const searchOnChange = value => {
                              onChange(value);
                              props.onChange(value);
                            };

                            const searchInput = {
                              ...restInput,
                              onChange: searchOnChange,
                            };
                            return (
                              <LocationAutocompleteInputField
                                className={css.locationAddress}
                                rootClassName={css.rootClass}
                                inputClassName={css.locationInput}
                                iconClassName={css.locationAutocompleteInputIcon}
                                predictionsClassName={css.predictionsRoot}
                                autoFocus
                                name="location"
                                placeholder={addressPlaceholderMessage}
                                useDefaultPredictions={false}
                                valueFromForm={values.location}
                                // format={identity}
                                validate={composeValidators(
                                  autocompleteSearchRequired(addressRequiredMessage),
                                  autocompletePlaceSelected(addressNotRecognizedMessage)
                                )}
                                inputRef={node => {
                                  searchInputData = node;
                                }}
                                input={searchInput}
                                meta={meta}
                              />
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    />
  );
};

export default memo(EnquiryFormHome);
