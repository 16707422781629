import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { storableError } from '../../util/errors';

import { setInitialState as clearFilter } from '../HeaderContainer/HeaderContainer.duck';
// ================ Action types ================ //

export const SET_INITIAL_STATE = 'app/AllSportsPage/SET_INITIAL_STATE';

export const QUERY_PROMOTED_LISTINGS_REQUEST = 'app/AllSportsPage/QUERY_PROMOTED_LISTINGS_REQUEST';
export const QUERY_PROMOTED_LISTINGS_SUCCESS = 'app/AllSportsPage/QUERY_PROMOTED_LISTINGS_SUCCESS';
export const QUERY_PROMOTED_LISTINGS_ERROR = 'app/AllSportsPage/QUERY_PROMOTED_LISTINGS_ERROR';

export const QUERY_PROMOTED_LISTINGS_REQUEST_ONLINE_TUTOR =
  'app/AllSportsPage/QUERY_PROMOTED_LISTINGS_REQUEST_ONLINE_TUTOR';
export const QUERY_PROMOTED_LISTINGS_SUCCESS_ONLINE_TUTOR =
  'app/AllSportsPage/QUERY_PROMOTED_LISTINGS_SUCCESS_ONLINE_TUTOR';
export const QUERY_PROMOTED_LISTINGS_ERROR_ONLINE_TUTOR =
  'app/AllSportsPage/QUERY_PROMOTED_LISTINGS_ERROR_ONLINE_TUTOR';

export const COMPANY_FREELANCER_REQUEST = 'app/AllSportsPage/COMPANY_FREELANCER_REQUEST';
export const COMPANY_FREELANCER_SUCCESS = 'app/AllSportsPage/COMPANY_FREELANCER_SUCCESS';
export const COMPANY_FREELANCER_ERROR = 'app/AllSportsPage/COMPANY_FREELANCER_ERROR';

export const SEARCH_LISTINGS_REQUEST = 'app/AllSportsPage/SEARCH_LISTINGS_REQUEST';
export const SEARCH_LISTINGS_SUCCESS = 'app/AllSportsPage/SEARCH_LISTINGS_SUCCESS';
export const SEARCH_LISTINGS_ERROR = 'app/AllSportsPage/SEARCH_LISTINGS_ERROR';

export const QUERY_PROMOTED_LISTING_REVIEWS_REQUEST =
  'app/AllSportsPage/QUERY_PROMOTED_LISTING_REVIEWS_REQUEST';
export const QUERY_PROMOTED_LISTING_REVIEWS_SUCCESS =
  'app/AllSportsPage/QUERY_PROMOTED_LISTING_REVIEWS_SUCCESS';
export const QUERY_PROMOTED_LISTING_REVIEWS_ERROR =
  'app/AllSportsPage/QUERY_PROMOTED_LISTING_REVIEWS_ERROR';

export const FETCH_COMPANIES_REQUEST = 'app/AllSportsPage/FETCH_COMPANIES_REQUEST';
export const FETCH_COMPANIES_SUCCESS = 'app/AllSportsPage/FETCH_COMPANIES_SUCCESS';
export const FETCH_COMPANIES_ERROR = 'app/AllSportsPage/FETCH_COMPANIES_ERROR';

export const FETCH_ALL_LISTING_REVIEWS = 'app/AllSportsPage/FETCH_ALL_LISTING_REVIEWS';

//author classes
export const FETCH_AUTHOR_CLASSES_REQUEST = 'app/AllSportsPage/FETCH_AUTHOR_CLASSES_REQUEST';
export const FETCH_AUTHOR_CLASSES_SUCCESS = 'app/AllSportsPage/FETCH_AUTHOR_CLASSES_SUCCESS';
export const FETCH_AUTHOR_CLASSES_ERROR = 'app/AllSportsPage/FETCH_AUTHOR_CLASSES_ERROR';

//Author other listing
export const SET_AUTHORS_LISTINGS_REFS = 'app/AllSportsPage/SET_AUTHORS_LISTINGS_REFS';
export const SHOW_LISTING_REQUEST = 'app/AllSportsPage/SHOW_LISTING_REQUEST';
export const SHOW_LISTING_ERROR = 'app/AllSportsPage/SHOW_LISTING_ERROR';

//Enquiry
export const SEND_ENQUIRY_REQUEST = 'app/AllSportsPage/SEND_ENQUIRY_REQUEST';
export const SEND_ENQUIRY_SUCCESS = 'app/AllSportsPage/SEND_ENQUIRY_SUCCESS';
export const SEND_ENQUIRY_ERROR = 'app/AllSportsPage/SEND_ENQUIRY_ERROR';

// ================ Reducer ================ //

const initialState = {
  queryPromotedListingsError: null,
  promotedListingRefs: [],
  // promotedListingRefsNew: [],
  promotedListingReviews: [],
  suggestedListings: [],
  companiesRefs: [],
  companiesError: null,
  companiesLoading: false,
  listingAllReviews: {},
  authorsListingsRefs: [],
  showListingError: null,
  gettingListings: false,
  Pagination: null,
  companyFreelancerListings: [],
  promotedListingRefsOnlineTutor: [],

  //enquiry
  sendEnquiryError: null,
  sendEnquiryInProgress: false,
};

export default function allSportsPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_STATE:
      return { ...initialState };
    case COMPANY_FREELANCER_REQUEST:
      return {
        ...state,
        companyFreelancerListings: state.companyFreelancerListings,
      };
    case COMPANY_FREELANCER_SUCCESS:
      return { ...state, companyFreelancerListings: payload.listingRefs };
    case COMPANY_FREELANCER_ERROR:
      return {
        ...state,
        companyFreelancerListings: [],
      };
    case QUERY_PROMOTED_LISTINGS_REQUEST:
      return {
        ...state,
        promotedListingRefs: state.promotedListingRefs,
        queryPromotedListingsError: null,
        gettingListings: true,
        Pagination: null,
      };
    // case QUERY_PROMOTED_LISTINGS_SUCCESS:
    //   return {
    //     ...state,
    //     promotedListingRefs: [...state.promotedListingRefs, ...payload.listingRefs],
    //     gettingListings: false,
    //   };
    case QUERY_PROMOTED_LISTINGS_SUCCESS:
      return {
        ...state,
        promotedListingRefs: { ...state.promotedListingRefs, ...payload.listingData },
        gettingListings: false,
        Pagination: { ...state.Pagination, ...payload.pages },
      };
    case QUERY_PROMOTED_LISTINGS_ERROR:
      return {
        ...state,
        promotedListingRefs: [],
        queryPromotedListingsError: payload,
        gettingListings: false,
        Pagination: null,
      };
    case QUERY_PROMOTED_LISTINGS_REQUEST_ONLINE_TUTOR:
      return {
        ...state,
        promotedListingRefsOnlineTutor: state.promotedListingRefs,
        queryPromotedListingsError: null,
        gettingListings: true,
      };
    case QUERY_PROMOTED_LISTINGS_SUCCESS_ONLINE_TUTOR:
      return {
        ...state,
        promotedListingRefsOnlineTutor: payload.listingRefs,
        gettingListings: false,
      };
    case QUERY_PROMOTED_LISTINGS_ERROR_ONLINE_TUTOR:
      return {
        ...state,
        promotedListingRefsOnlineTutor: [],
        queryPromotedListingsErrorOnlineTutor: payload,
        gettingListings: false,
      };
    case SEARCH_LISTINGS_REQUEST:
      return {
        ...state,
        suggestedListings: state.suggestedListings,
        searchListingsError: null,
      };
    case SEARCH_LISTINGS_SUCCESS:
      return { ...state, suggestedListings: payload.listings };
    case SEARCH_LISTINGS_ERROR:
      return { ...state, suggestedListings: [], searchListingsError: payload };
    case QUERY_PROMOTED_LISTING_REVIEWS_REQUEST:
      return { ...state, queryPromotedListingReviewsError: null };
    case QUERY_PROMOTED_LISTING_REVIEWS_SUCCESS:
      return { ...state, promotedListingReviews: { ...state.promotedListingReviews, payload } };
    case QUERY_PROMOTED_LISTING_REVIEWS_ERROR:
      return { ...state, reviews: [], queryPromotedListingReviewsError: payload };

    //Companies
    case FETCH_COMPANIES_REQUEST:
      return { ...state, companiesError: null, companiesLoading: true };
    case FETCH_COMPANIES_SUCCESS:
      return { ...state, companiesRefs: payload, companiesLoading: false };
    case FETCH_COMPANIES_ERROR:
      return { ...state, companiesError: payload, companiesLoading: false };

    // reviews
    case FETCH_ALL_LISTING_REVIEWS:
      return { ...state, listingAllReviews: payload };
    default:
      return state;

    //author classes
    case FETCH_AUTHOR_CLASSES_REQUEST:
      return { ...state, authorClassesIsLoading: true };
    case FETCH_AUTHOR_CLASSES_SUCCESS:
      return { ...state, authorClassesIsLoading: false, authorClassesRefs: payload };
    case FETCH_AUTHOR_CLASSES_ERROR:
      return { ...state, authorClassesIsLoading: false, authorClassesError: payload };

    //Author other listing
    case SET_AUTHORS_LISTINGS_REFS: {
      return { ...state, authorsListingsRefs: payload };
    }
    case SHOW_LISTING_REQUEST:
      return { ...state, id: payload.id, showListingError: null };
    case SHOW_LISTING_ERROR:
      return { ...state, showListingError: payload };

    //enquiry
    case SEND_ENQUIRY_REQUEST:
      return { ...state, sendEnquiryInProgress: true, sendEnquiryError: null };
    case SEND_ENQUIRY_SUCCESS:
      return { ...state, sendEnquiryInProgress: false };
    case SEND_ENQUIRY_ERROR:
      return { ...state, sendEnquiryInProgress: false, sendEnquiryError: payload };
  }
}

// ================ Action creators ================ //

export const setInitialState = () => ({
  type: SET_INITIAL_STATE,
});

export const companyFreelancerRequest = () => ({
  type: COMPANY_FREELANCER_REQUEST,
});

export const companyFreelancerSuccess = listingRefs => ({
  type: COMPANY_FREELANCER_SUCCESS,
  payload: { listingRefs },
});

export const companyFreelancerError = e => ({
  type: COMPANY_FREELANCER_ERROR,
  error: true,
  payload: e,
});

export const queryPromotedListingsRequest = () => ({
  type: QUERY_PROMOTED_LISTINGS_REQUEST,
});

export const queryPromotedListingsRequestOnlineTutor = () => ({
  type: QUERY_PROMOTED_LISTINGS_REQUEST_ONLINE_TUTOR,
});

// export const queryPromotedListingsSuccess = listingRefs => ({
//   type: QUERY_PROMOTED_LISTINGS_SUCCESS,
//   payload: { listingRefs },
// });

export const queryPromotedListingsSuccess = listingRefs => ({
  type: QUERY_PROMOTED_LISTINGS_SUCCESS,
  payload: listingRefs,
});

export const queryPromotedListingsError = e => ({
  type: QUERY_PROMOTED_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const queryPromotedListingsSuccessOnlineTutor = listingRefs => ({
  type: QUERY_PROMOTED_LISTINGS_SUCCESS_ONLINE_TUTOR,
  payload: { listingRefs },
});

export const queryPromotedListingsErrorOnlineTutor = e => ({
  type: QUERY_PROMOTED_LISTINGS_ERROR_ONLINE_TUTOR,
  error: true,
  payload: e,
});

export const searchListingsRequest = () => ({
  type: SEARCH_LISTINGS_REQUEST,
});

export const searchListingsSuccess = listings => ({
  type: SEARCH_LISTINGS_SUCCESS,
  payload: { listings },
});

export const searchListingsError = e => ({
  type: SEARCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const queryPromotedListingReviewsRequest = () => ({
  type: QUERY_PROMOTED_LISTING_REVIEWS_REQUEST,
});

export const allListingReviews = reviewData => ({
  type: FETCH_ALL_LISTING_REVIEWS,
  payload: reviewData,
});

export const queryPromotedListingReviewsSuccess = reviews => ({
  type: QUERY_PROMOTED_LISTING_REVIEWS_SUCCESS,
  payload: reviews,
});

export const queryPromotedListingReviewsError = e => ({
  type: QUERY_PROMOTED_LISTING_REVIEWS_ERROR,
  error: true,
  payload: e,
});

export const fetchCompaniesRequest = () => ({
  type: FETCH_COMPANIES_REQUEST,
});
export const fetchCompaniesSuccess = payload => ({
  type: FETCH_COMPANIES_SUCCESS,
  payload,
});
export const fetchCompaniesError = payload => ({
  type: FETCH_COMPANIES_ERROR,
  payload,
});

export const fetchAuthorClassesRequest = () => ({ type: FETCH_AUTHOR_CLASSES_REQUEST });
export const fetchAuthorClassesSuccess = reviews => ({
  type: FETCH_AUTHOR_CLASSES_SUCCESS,
  payload: reviews,
});
export const fetchAuthorClassesError = error => ({
  type: FETCH_AUTHOR_CLASSES_ERROR,
  error: true,
  payload: error,
});

//Author other listing
export const setAuthorsListingsRefs = author => ({
  type: SET_AUTHORS_LISTINGS_REFS,
  payload: author,
});

//enquiry
export const sendEnquiryRequest = () => ({ type: SEND_ENQUIRY_REQUEST });
export const sendEnquirySuccess = () => ({ type: SEND_ENQUIRY_SUCCESS });
export const sendEnquiryError = e => ({ type: SEND_ENQUIRY_ERROR, error: true, payload: e });

// ================ Thunks ================ //

export const queryPromotedListingsAllSportsPage = searchquery => (dispatch, getState, sdk) => {
  dispatch(queryPromotedListingsRequest());
  if (!searchquery) {
    dispatch(clearFilter());
  } else {
    const { pub_listing_mode, page, pub_listing_type, ...rest } = searchquery;
    const listingModeMaybe = pub_listing_mode ? { pub_listing_mode } : {};
    const pages = { page: page, perPage: 20 };
    return (
      sdk &&
      sdk.listings
        .query({
          ...rest,
          pub_listing_type,
          pub_sub_category:
            searchquery.pub_sub_category && `has_any:${searchquery.pub_sub_category}`,
          ...listingModeMaybe,
          include: ['author', 'author.profileImage', 'images'],
          'fields.image': [
            'variants.scaled-small',
            'variants.square-small2x',
            // Avatars
            'variants.square-small',
            'variants.square-small2x',
            'variants.company-background-crop',
          ],
          ...pages,
          sort: 'meta_promoted,createdAt',
        })
        .then(response => {
          const listingRefs = response.data.data.filter(
            ({ id, type, attributes }) => !attributes?.metadata?.hidelisting && { id, type }
          );
          const pages = response?.data?.meta;
          dispatch(addMarketplaceEntities(response));
          dispatch(
            queryPromotedListingsSuccess({
              listingData: { [pub_listing_type]: listingRefs },
              pages: { [pub_listing_type]: pages },
            })
          );
        })
        .catch(e => {
          console.log(e, 'error');
          dispatch(queryPromotedListingsError(storableError(e)));
        })
    );
  }
};

export const showListingAllSportsPage = listingId => (dispatch, getState, sdk) => {
  return sdk.listings
    .show({
      id: listingId,
      include: ['author', 'author.profileImage', 'images'],
      'fields.image': [
        'variants.scaled-small',
        'variants.square-small2x',

        // Avatars
        'variants.square-small',
        'variants.square-small2x',
      ],
      'limit.images': 6,
      'page.limit': 50,
      sort: 'meta_promoted,createdAt',
    })
    .then(response => {
      const res = [response.data];
      const listingRefs = res.filter(
        ({ id, type, attributes }) => !attributes?.metadata?.hidelisting && { id, type }
      );
      dispatch(addMarketplaceEntities(response));
      dispatch(queryPromotedListingsSuccess(listingRefs));

      return response;
    })
    .catch(e => dispatch(queryPromotedListingsError(storableError(e))));
};

export const searchListingsAllSportsPage = searchParams => (dispatch, getState, sdk) => {
  dispatch(searchListingsRequest());
  return sdk.listings
    .query({
      ...searchParams,
      mapSearch: true,
      include: ['images'],
      'fields.image': ['variants.scaled-small', 'variants.scaled-small2x'],
    })
    .then(response => {
      const listingRefs = response.data.data.filter(
        ({ id, type, attributes }) =>
          !attributes?.metadata?.hidelisting && { id, type, title: attributes.title }
      );
      dispatch(addMarketplaceEntities(response));
      dispatch(searchListingsSuccess(listingRefs));
      return response;
    })
    .catch(e => {
      dispatch(searchListingsError(e));
      throw e;
    });
};

export const loadData = () => (dispatch, getState, sdk) => {
  const currentState = getState().HeaderSection;
  if (
    currentState.searchLocation?.search ||
    currentState.searchActivity ||
    currentState.searchSelectedRating
  )
    return Promise.resolve();

  dispatch(setInitialState());
  return;
  // return Promise.all([dispatch(queryPromotedListingsAllSportsPage())]);
};
export const queryPromotedListingsOnlineTutor = searchquery => (dispatch, getState, sdk) => {
  dispatch(queryPromotedListingsRequestOnlineTutor());
  return (
    sdk &&
    sdk.listings
      .query({
        ...searchquery,
        include: ['author', 'author.profileImage', 'images'],
        'fields.image': [
          'variants.scaled-small',
          'variants.square-small2x',
          // Avatars
          'variants.square-small',
          'variants.square-small2x',
          'variants.company-background-crop',
        ],
        'limit.images': 6,
        'page.limit': 50,
        sort: 'meta_promoted,createdAt',
      })
      .then(response => {
        const listingRefs = response.data.data.filter(
          ({ id, type, attributes }) => !attributes?.metadata?.hidelisting && { id, type }
        );
        dispatch(addMarketplaceEntities(response));
        dispatch(queryPromotedListingsSuccessOnlineTutor(listingRefs));
      })
      .catch(e => {
        console.log(e, 'error');
        dispatch(queryPromotedListingsErrorOnlineTutor(storableError(e)));
      })
  );
};
