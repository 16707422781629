import { types as sdkTypes } from './util/sdkLoader';

const { LatLng, LatLngBounds } = sdkTypes;

// An array of locations to show in the LocationAutocompleteInput when
// the input is in focus but the user hasn't typed in any search yet.
//
// Each item in the array should be an object with a unique `id` (String) and a
// `predictionPlace` (util.types.place) properties.
export default [
  {
    id: 'default-singapore',
    predictionPlace: {
      address: 'Singapore',
      bounds: new LatLngBounds(
        new LatLng(1.4110994, 103.9577613),
        new LatLng(1.2642103, 103.6055395)
      ),
    },
  },
  // {
  //   id: 'default-bangkok',
  //   predictionPlace: {
  //     address: 'Bangkok, Thailand',
  //     bounds: new LatLngBounds(
  //       new LatLng(13.8655169, 100.7012364),
  //       new LatLng(13.6308189, 100.4141944)
  //     ),
  //   },
  // },
  // {
  //   id: 'default-hong-kong',
  //   predictionPlace: {
  //     address: 'Hong-Kong, China',
  //     bounds: new LatLngBounds(
  //       new LatLng(22.3472941, 114.224484),
  //       new LatLng(22.2776183, 114.123264)
  //     ),
  //   },
  // },
  // {
  //   id: 'default-jakarta',
  //   predictionPlace: {
  //     address: 'Jakarta, Indonesia',
  //     bounds: new LatLngBounds(
  //       new LatLng(-6.0887627, 106.9715223),
  //       new LatLng(-6.326409, 106.7436153)
  //     ),
  //   },
  // },
  // {
  //   id: 'default-CapeTown',
  //   predictionPlace: {
  //     address: 'Cape Town, South Africa',
  //     bounds: new LatLngBounds(new LatLng(-33.614968, 18.92245), new LatLng(-34.322235, 18.206965)),
  //   },
  // },
  {
    id: 'london',
    predictionPlace: {
      address: 'London',
      bounds: new LatLngBounds(new LatLng(51.669993, 0.153177), new LatLng(51.384527, 0.351708)),
    },
  },
  {
    id: 'sydney',
    predictionPlace: {
      address: 'Sydney',
      bounds: new LatLngBounds(
        new LatLng(-33.578140996, 151.343020992),
        new LatLng(-34.118344992, 150.520928608)
      ),
    },
  },
  {
    id: 'new-york',
    predictionPlace: {
      address: 'New York',
      bounds: new LatLngBounds(
        new LatLng(45.0239286969073, -71.7564918092633),
        new LatLng(40.4771391062446, -79.8578350999901)
      ),
    },
  },
  {
    id: 'los-angeles',
    predictionPlace: {
      address: 'Los Angeles',
      bounds: new LatLngBounds(
        new LatLng(34.161439, -118.126728),
        new LatLng(33.900014, -118.521447)
      ),
    },
  },
  {
    id: 'default-melbourne',
    predictionPlace: {
      address: 'Melbourne',
      bounds: new LatLngBounds(
        new LatLng(-37.535924, 145.433455),
        new LatLng(-38.154894, 144.568752)
      ),
    },
  },
];
