import React from 'react';
import PropTypes from 'prop-types';
import { EnquirySearchCard } from 'components';
import { propTypes } from 'util/types';
import css from './EnquiryResults.css';


function EnquiryResults({ listings, setActiveListing }) {
  return (
    <div className={css.listingCards}>
      {listings.map(l => (
        <EnquirySearchCard listing={l} setActiveListing={setActiveListing} />
      ))}
    </div>
  );
}

EnquiryResults.propTypes = {
  listings: PropTypes.arrayOf(propTypes.listing),
  setActiveListing: PropTypes.func,
};

export default EnquiryResults;
