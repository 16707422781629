import React from 'react';
import { bool, node } from 'prop-types';
import cns from 'classnames';
import { Button, IconClose } from 'components';
import { injectIntl } from '../../util/reactIntl';
import css from './GenericMessage.css';

const GenericMessage = props => {
  const { show, message, onClose, intl } = props;
  const classes = cns(css.genericMessage, {
    [css.genericMessageVisible]: show,
  });

  const classesWithButton = cns(css.genericMessageWithButton, {
    [css.genericMessageVisible]: show,
  });

  const closeModalMessage = intl.formatMessage({ id: 'Modal.closeModal' });

  const closeBtn = (
    <Button onClick={onClose} rootClassName={css.close} title={closeModalMessage}>
      <span className={css.closeText}>
        {/* {closeButtonMessage || <FormattedMessage id="Modal.close" />} */}
      </span>
      <span className={css.closeBtnWrap}>
        <IconClose rootClassName={css.closeIcon} />
      </span>
    </Button>
  );

  return !!show ? (
    !onClose ? (
      <div className={classes}>
        <div className={css.genericMessageContent}>
          <p className={css.genericMessageText}>{message}</p>
        </div>
      </div>
    ) : (
      <div className={classesWithButton}>
        <div className={css.genericMessageContent}>
          {closeBtn}
          <p className={css.genericMessageText}>{message}</p>
        </div>
      </div>
    )
  ) : null;
};

GenericMessage.propTypes = {
  show: bool,
  message: node,
};

export default injectIntl(GenericMessage);
