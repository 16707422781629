const IMG_BASE_URL = 'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/';

export const data = {
  'for-students': {
    title: 'For Students',
    description:
      'From the moment you enroll in our program, we provide you with the resources and support you need to succeed.',
    children: [
      {
        title: 'Tennis is a sport that can be played at any age, from young children to seniors...',
        img: `${IMG_BASE_URL}v1684128864/probuddy/static/images/BlogPage/students_blog_2_wvhwar.png`,
        blogUrl: [
          `${IMG_BASE_URL}v1684128876/probuddy/static/images/BlogPage/Blogs/3_main_reasons_why_tennis_is_great_sport_ixceau.png`,
        ],
      },
      {
        title:
          'Finding the best tennis coach for you can be a challenge, but here are some steps...',
        slug: 'the-ultimate-guide-to-choosing-a-tennis-coach',
        img: `${IMG_BASE_URL}v1684923249/probuddy/static/images/BlogPage/Blogs/probuddy-blog_the-ultimate-guide-to-choosing-a-tennis-coach_title_ly1uab.png`,
        blogUrl: [
          `${IMG_BASE_URL}v1684132200/probuddy/static/images/BlogPage/Blogs/ultimate_guide_to_choose_tenis_coach_t8ahcs.png`,
        ],
      },
      {
        title:
          'Swimming is a fantastic form of exercise that provides a full-body workout, improves cardiovascular health, and can be enjoyed by people of all ages.',
        slug: 'the-ultimate-guide-to-Refine-swimming-technique',
        img: `${IMG_BASE_URL}v1684923249/probuddy/static/images/BlogPage/Blogs/image_rgmxur.png`,
      },
    ],
  },
  students: {
    title: 'For Students',
    description:
      'From the moment you enroll in our program, we provide you with the resources and support you need to succeed.',
    children: [
      {
        title:
          'Exam anxiety is a common experience for many students, but there are several strategies that can help overcome it.',
        img: `${IMG_BASE_URL}v1683794443/probuddy/static/images/BlogPage/student_blog_hq1qa1.png`,
        blogUrl: [
          `${IMG_BASE_URL}v1683797477/probuddy/static/images/BlogPage/Blogs/blog_edu_1_xdytx9.png`,
        ],
      },
    ],
  },
  'solo-tutors': {
    title: 'Solo Tutors',
    description: '',
    children: [
      {
        title: 'There are several ways to attract more clients as an online tutor...',
        img: `${IMG_BASE_URL}v1683867793/probuddy/static/images/BlogPage/blog_solo_tutor_gega79.png`,
        blogUrl: [
          `${IMG_BASE_URL}v1683868516/probuddy/static/images/BlogPage/Blogs/Unlocking_Success_yyftpl.png`,
        ],
      },
    ],
  },
  parents: {
    title: 'For Parents',
    description: '',
    children: [
      {
        title:
          'Parents may choose to enroll their students in online tutorials for various reasons...',
        img: `${IMG_BASE_URL}v1683868767/probuddy/static/images/BlogPage/Parents_may_choose_mojjkk.png`,
        blogUrl: [
          `${IMG_BASE_URL}v1683868652/probuddy/static/images/BlogPage/Blogs/Unlocking_the_benefits_yb2mzt.png`,
        ],
      },
    ],
  },
  sports: {
    title: 'Sports',
    description: '',
    children: [
      {
        title:
          "Tennis is a beloved sport played by millions of people around the world. It's a sport that can be enjoyed....",
        slug: 'why-tennis-is-a-great-sport-in-singapore',
        img: `${IMG_BASE_URL}v1684923249/probuddy/static/images/BlogPage/Blogs/why-tennis-is-a-great-sport_blog_nkzi1p.png`,
      },
      {
        title: '5 Main Reason why Tennis is a great Sport',
        slug: 'why-tennis-is-a-great-sport',
        img: `${IMG_BASE_URL}/v1688711128/probuddy/static/images/BlogPage/Blogs/image_445_n0j5ej.png`,
      },
      {
        title: 'Mastering Tennis Techniques: Tips and Tricks for Improving Your Game',
        slug: 'mastering-tennis-techniques-tips',
        img: `${IMG_BASE_URL}v1688711123/probuddy/static/images/BlogPage/Blogs/image_446_lzpcwn.png`,
      },
      {
        title: 'Tennis: the Perfect Combination of Physical Ability and Technical Skill',
        slug: 'the-Perfect-combination-of-Physical-ability-and-technical-skill',
        img: `${IMG_BASE_URL}v1688711120/probuddy/static/images/BlogPage/Blogs/Untitled_design_-_2023-06-26T171158_1_hap3b6.png`,
      },
      {
        title: 'tennis: An In-Demand Sport',
        slug: 'tennis-an-in-demand-sport',
        img: `${IMG_BASE_URL}v1688724696/probuddy/static/images/BlogPage/Blogs/image_2_hjsnaj.png`,
      },
      {
        title: 'How to Improve Your Tennis Skills with These 5 Tip',
        slug: 'improve-your-tennis-skills-with-these-5-tip',
        img: `${IMG_BASE_URL}v1688724390/probuddy/static/images/BlogPage/Blogs/image_1_zd8txb.png`,
      },
    ],
  },
  'for-personal-training': {
    title: 'For Personal Training',
    description: '',
    children: [
      {
        title:
          'Are you looking to get fit and healthy in Singapore? Personal training might be just what you need to achieve your fitness goals. ',
        slug: 'to-get-fit-and-healthy',
        img: `${IMG_BASE_URL}v1687429775/probuddy/static/images/BlogPage/Blogs/to-get-fit-and-healthy_x8agym.png`,
      },
      {
        title: 'How to Find the Best Personal Trainer for Your Fitness Goals',
        slug: 'find-the-best-personal-trainer',
        img: `${IMG_BASE_URL}v1689772299/probuddy/static/images/BlogPage/Blogs/personal%20training/Rectangle_6990_1_k5t6e4.png`,
      },
      // {
      //   title: 'Mastering Tennis Techniques: Tips and Tricks for Improving Your Game',
      //   slug: 'tips-and-tricks-for-improving-your-game',
      //   img: `${IMG_BASE_URL}v1689772299/probuddy/static/images/BlogPage/Blogs/personal%20training/Rectangle_6990_1_k5t6e4.png`,
      // },
    ],
  },
};

export const getBlogDetails = blogSlug => allBlogsData.find(blog => blog.id === blogSlug);

const allBlogsData = [
  {
    id: 'the-ultimate-guide-to-choosing-a-tennis-coach',
    heroImgUrl: `${IMG_BASE_URL}v1684923249/probuddy/static/images/BlogPage/Blogs/probuddy-blog_the-ultimate-guide-to-choosing-a-tennis-coach_title_ly1uab.png`,
    title: 'The Ultimate Guide to Choosing a Tennis Coach',
    subTitle:
      'Finding the best tennis coach for you can be a challenge, but here are some steps you can take to help you find the right one:',
    layout: 'layout-1',
    content: [
      {
        className: 'blogTextWrap',
        data: [
          { h3: '1. Define your goals' },
          {
            p:
              "Before you start your search, it's important to define your goals and what you hope to achieve through coaching. This will help you find a coach who can help you meet your specific needs.",
          },
          { h3: '2. Research online' },
          {
            p:
              'You can also research online for tennis coaches in your area. Check out their websites, social media pages, and read reviews from past clients. You can also look for coaches on tennis-specific website such as Probuddy.io',
          },
          { h3: '3. Check their credentials' },
          {
            p:
              'Look for coaches who have certifications from reputable organizations. This ensures that they have the necessary training and experience to provide quality coaching.',
          },
        ],
      },
      {
        className: 'blogImageWrap',
        data: [
          {
            img: {
              src: `${IMG_BASE_URL}v1684923249/probuddy/static/images/BlogPage/Blogs/probuddy-blogs_the-ultimate-guide-to-choosing-a-tennis-coach_1_vumrzi.png`,
              alt: 'probuddy-blogs_the-ultimate-guide-to-choosing-a-tennis-coach_1',
            },
          },
        ],
      },
      {
        className: 'blogImageWrap',
        data: [
          {
            img: {
              src: `${IMG_BASE_URL}v1684923249/probuddy/static/images/BlogPage/Blogs/probuddy-blogs_the-ultimate-guide-to-choosing-a-tennis-coach_2_ubsyie.png`,
              alt: 'probuddy-blogs_the-ultimate-guide-to-choosing-a-tennis-coach_2',
            },
          },
        ],
      },
      {
        className: 'blogTextWrap',
        data: [
          { h3: '4. Schedule a Trial Lesson' },
          {
            p:
              'Before committing to a coach, schedule a trial lesson to see if their coaching style and personality work well with you.',
          },
          {
            h3: '5. Attend local tennis events',
          },
          {
            p:
              'Attend local tennis tournaments or matches, such as those hosted by the Singapore Tennis Club, and observe the coaches in action. This can give you a good sense of their coaching style and whether they might be a good fit for you.',
          },
        ],
      },
      {
        className: 'summaryWrap',
        data: [
          {
            span: 'REMEMBER...',
          },
          {
            p:
              "Tennis is a fun sport to play, enjoyed by people around the world, including in Singapore. Whether you're playing competitively or just hitting the ball back and forth for fun, tennis is a great way to enjoy the outdoors and get some exercise.",
          },
        ],
      },
    ],
  },
  {
    id: 'the-ultimate-guide-to-Refine-swimming-technique',

    heroImgUrl: `${IMG_BASE_URL}v1684923249/probuddy/static/images/BlogPage/Blogs/image_rgmxur.png`,
    title: 'Refine your swimming technique',
    subTitle: 'with the help of our skilled professionals',
    layout: 'layout-1',
    content: [
      {
        className: 'FullWidthText',
        data: [
          {
            p: 'Personal training might be just what you need to achieve your fitness goals. ',
          },
        ],
      },
      {
        className: 'blogImageWrap',
        data: [
          {
            img: {
              src: `${IMG_BASE_URL}v1684923249/probuddy/static/images/BlogPage/Blogs/swmming_eojqgn.png`,
              alt: 'probuddy-blogs_the-ultimate-guide-to-Refine-swimming-technique',
            },
          },
        ],
      },

      {
        className: 'blogTextWrap',
        data: [
          { h3: '1. Stroke analysis' },
          {
            p:
              'Our coaches can analyze your stroke technique and provide personalized feedback on areas where you can improve. They can identify areas where you may be wasting energy, help you correct your form, and suggest drills to help you perfect your technique.',
          },
          { h3: '2. Personalized feedback' },
          {
            p:
              'Our coaches provide personalized feedback and suggestions for improvement based on your individual needs and goals.',
          },
        ],
      },
      {
        className: 'blogTextWrap',
        data: [
          { h3: '3. Mental preparation' },
          {
            p:
              'Swimming can be a mentally challenging sport. Our coaches can help you develop mental strategies to help you stay focused, calm, and confident during your swim.',
          },
        ],
      },
      {
        className: 'blogTextWrap',
        data: [
          { h3: '4. Endurance training' },
          {
            p:
              'Swimming requires a combination of strength and endurance. Our coaches can help you build your endurance through targeted training programs that gradually increase in difficulty.',
          },
        ],
      },
      {
        className: 'summaryWrap',
        data: [
          {
            span: 'REMEMBER...',
          },
          {
            p:
              "of your current level of swimming ability, our pros canhelp you improve your technique and achieve your goals in the water. Whether you're a competitive swimmer looking to shave seconds off your time or a beginner looking to improve your form and build endurance, our coaches have the expertise to help you succeed.",
          },
        ],
      },
      {
        className: 'blogTextWrap',
        data: [
          {
            p: '',
          },
          {
            p:
              'In addition to personalized coaching, our coaches offer group classes for swimmers of all ages and skill levels. Our classes provide a fun and supportive environment where swimmers can learn new techniques, build endurance, and make new friends.',
          },
          {
            p: '',
          },
          {
            p:
              "So, whether you're looking to improve your technique for fitness or competition, our swimming pros are here to help. ",
          },
          {
            p: '',
          },
          {
            p: 'Contact us today to schedule a session and start refining your swimming technique!',
          },
        ],
      },
      {
        className: 'blogImageWrap',
        data: [
          {
            img: {
              src: `${IMG_BASE_URL}v1687421159/probuddy/static/images/BlogPage/Blogs/swmming_2_su9nzn.png`,
              alt: 'probuddy-blogs_the-ultimate-guide-to-Refine-swimming-technique',
            },
          },
        ],
      },
    ],
  },
  {
    id: 'why-tennis-is-a-great-sport-in-singapore',
    heroImgUrl: `${IMG_BASE_URL}v1684923249/probuddy/static/images/BlogPage/Blogs/why-tennis-is-a-great-sport_blog_nkzi1p.png`,
    title: '5 Main Reason',
    subTitle: 'why Tennis is a great sport in Singapore',
    layout: 'layout-1',
    content: [
      {
        className: 'FullWidthText',
        data: [
          {
            p:
              "Tennis is a beloved sport played by millions of people around the world, including in Singapore. It's a sport that can be enjoyed by people of all ages and skill levels and provides a range of physical and mental benefits. ",
          },
        ],
      },
      {
        className: 'FullWidthText',
        data: [
          {
            p:
              'In this context, we will explore five reasons why tennis is considered a great sport.',
          },
        ],
      },
      {
        className: 'blogTextWrap',
        data: [
          { h3: '1. It can be played at any age' },
          {
            p:
              "Tennis is a sport that can be played by people of all ages and skill levels. Whether you're a young child or an older adult, there is a place for you on the tennis court.",
          },
          { h3: "2. It's a lifelong sport" },
          {
            p:
              "Tennis is a sport that can be played at any age, from young children to seniors. It's also a sport that you can continue to play for many years, making it a great way to stay active and healthy throughout your life.",
          },
          { h3: '3. Strength and Flexibility' },
          {
            p:
              'Tennis involves a lot of running, jumping, and twisting, which can help build strength in your legs, core, and upper body. Additionally, the quick movements required in tennis can help increase your flexibility.',
          },
        ],
      },
      {
        // first Img
        className: 'blogImageWrap',
        data: [
          {
            img: {
              src: `${IMG_BASE_URL}v1684923249/probuddy/static/images/BlogPage/Blogs/why-tennis-is-a-great-sport_blog_1_cqmasr.png`,
              alt: 'probuddy-blogs_the-ultimate-guide-to-Refine-swimming-technique',
            },
          },
        ],
      },
      {
        className: 'blogImageWrap',
        data: [
          {
            img: {
              src: `${IMG_BASE_URL}v1687421159/probuddy/static/images/BlogPage/Blogs/why-tennis-is-a-great-sport_blog_2_qcxn05.png`,
              alt: 'probuddy-blogs_the-ultimate-guide-to-Refine-swimming-technique',
            },
          },
        ],
      },
      {
        className: 'blogTextWrap',
        data: [
          { h3: "4. It's a Social Sport" },
          {
            p:
              'Tennis can be played with friends, family, or in organized leagues and tournaments, providing a great opportunity to meet new people and build social connections, including in Singapore.',
          },
          { h3: '5. It teaches valuable life skills' },
          {
            p:
              'Tennis teaches important life skills such as discipline, perseverance, and sportsmanship. These skills can be applied to many areas of life, including work, school, and personal relationships.',
          },
        ],
      },
      {
        className: 'summaryWrap',
        data: [
          {
            span: 'ABOVE ALL',
          },
          {
            p:
              "Tennis is a fun sport to play, enjoyed by people around the world, including in Singapore. Whether you're playing competitively or just hitting the ball back and forth for fun, tennis is a great way to enjoy the outdoors and get some exercise.",
          },
        ],
      },
    ],
  },
  {
    id: 'why-tennis-is-a-great-sport',
    heroImgUrl: `${IMG_BASE_URL}/v1688711128/probuddy/static/images/BlogPage/Blogs/image_445_n0j5ej.png`,
    title: '5 Main Reason why Tennis is a great Sport',
    // subTitle: '',
    layout: 'layout-1',
    content: [
      {
        className: 'FullWidthText',
        data: [
          {
            p:
              "Tennis is a beloved sport played by millions of people around the world, including in Singapore. It's a sport that can be enjoyed by people of all ages and skill levels and provides a range of physical and mental benefits.",
          },
        ],
      },
      {
        className: 'FullWidthText',
        data: [
          {
            p:
              'In this context, we will explore five reasons why tennis is considered a great sport.',
          },
        ],
      },
      {
        className: 'blogTextWrap',
        data: [
          { h3: '1. It can be played at any age' },
          {
            p:
              "Tennis is a sport that can be played by people of all ages and skill levels. Whether you're a young child or an older adult, there is a place for you on the tennis court.",
          },
          { h3: "2. It's a lifelong sport" },
          {
            p:
              "Tennis is a sport that can be played at any age, from young children to seniors. It's also a sport that you can continue to play for many years, making it a great way to stay active and healthy throughout your life.",
          },
          { h3: '3. Strength and Flexibility' },
          {
            p:
              'Tennis involves a lot of running, jumping, and twisting, which can help build strength in your legs, core, and upper body. Additionally, the quick movements required in tennis can help increase your flexibility.',
          },
        ],
      },
      {
        // first Img
        className: 'blogImageWrap',
        data: [
          {
            img: {
              src: `${IMG_BASE_URL}v1688711123/probuddy/static/images/BlogPage/Blogs/image_446_lzpcwn.png`,
              alt: 'probuddy-blogs_the-ultimate-guide-to-Refine-swimming-technique',
            },
          },
        ],
      },
      {
        className: 'blogImageWrap',
        data: [
          {
            img: {
              src: `${IMG_BASE_URL}v1688711120/probuddy/static/images/BlogPage/Blogs/Untitled_design_-_2023-06-26T171158_1_hap3b6.png`,
              alt: 'probuddy-blogs why Tennis is a great Sport',
            },
          },
        ],
      },
      {
        className: 'blogTextWrap',
        data: [
          { h3: "4. It's a Social Sport" },
          {
            p:
              'Tennis can be played with friends, family, or in organized leagues and tournaments, providing a great opportunity to meet new people and build social connections, including in Singapore.',
          },
          { h3: '5. It teaches valuable life skills' },
          {
            p:
              'Tennis teaches important life skills such as discipline, perseverance, and sportsmanship. These skills can be applied to many areas of life, including work, school, and personal relationships.',
          },
          {
            span: 'ABOVE ALL...',
          },
          {
            p:
              "Tennis is a fun sport to play, enjoyed by people around the world, including in Singapore. Whether you're playing competitively or just hitting the ball back and forth for fun, tennis is a great way to enjoy the outdoors and get some exercise.",
          },
        ],
      },
    ],
  },
  {
    id: 'mastering-tennis-techniques-tips',
    heroImgUrl: `${IMG_BASE_URL}v1688724933/probuddy/static/images/BlogPage/Blogs/image_447_kfkowe.png`,
    title: 'Mastering Tennis Techniques Tips and Tricks for Improving Your Game',
    layout: 'layout-1',
    content: [
      {
        className: 'FullWidthText',
        data: [
          {
            p: `Tennis is a game that requires a combination of physical prowess and technical skill. Whether you're a beginner or an experienced player, improving your tennis techniques can help you take your game to the next level.`,
          },
        ],
      },
      {
        className: 'FullWidthText',
        data: [
          {
            p:
              'In this article, we will explore some tips and tricks for mastering tennis techniques.',
          },
        ],
      },
      {
        className: 'FullWidthText',
        data: [
          {
            p:
              'There are several techniques that are essential to master in order to become a great tennis player. These include the forehand, backhand, serve, volley, and overhead smash. Each of these techniques requires a combination of proper footwork, grip, and stroke mechanics.',
          },
        ],
      },
      {
        className: 'blogTextWrap',
        data: [
          { h3: 'Forehand Technique' },
          {
            p:
              'The forehand is one of the most important shots in tennis. To execute a proper forehand, start by positioning yourself correctly and using a continental grip. As you swing, rotate your hips and shoulders and follow through with your arm to generate power and accuracy.',
          },
          { h3: 'Backhand Technique' },
          {
            p:
              'The backhand is another key shot in tennis. To execute a proper backhand, use an eastern or semi-western grip and position yourself correctly. As you swing, rotate your shoulders and follow through with your arm to generate power and accuracy.',
          },
          {
            img: {
              src: `${IMG_BASE_URL}v1688724861/probuddy/static/images/BlogPage/Blogs/Untitled_design_-_2023-06-26T171158_2_odgigs.png`,
              alt:
                'probuddy-blogs Mastering Tennis Techniques Tips and Tricks for Improving Your Game',
            },
          },
        ],
      },
      {
        className: 'blogTextWrap',
        data: [
          { h3: 'Serve Technique' },
          {
            p:
              'TThe serve is one of the most challenging shots in tennis. To execute a proper serve, start by positioning yourself correctly and using a continental grip. As you swing, use your legs and torso to generate power and accuracy.',
          },
          { h3: 'Volley Technique' },
          {
            p:
              'The volley is a shot that requires quick reflexes and precise timing. To execute a proper volley, position yourself correctly and use a continental grip. As the ball approaches, step forward and use a short punch-like swing to hit the ball back over the net.',
          },
          {
            h3: 'Overhead Smash Technique',
          },
          {
            p:
              'The overhead smash is a powerful shot that can be used to finish off points. To execute a proper overhead smash, position yourself correctly and use a continental grip. As the ball approaches, jump and swing your racket over your head to generate power and accuracy.',
          },
        ],
      },
      {
        className: 'FullWidthText',
        data: [
          {
            p: `Improving your tennis techniques requires practice and dedication. By focusing on proper footwork, grip, and stroke mechanics, you can improve your accuracy, power, and consistency on the court.`,
          },
        ],
      },
      {
        className: 'summaryWrap',
        data: [
          {
            span: 'In Conclusion',
          },
          {
            p:
              'Mastering tennis techniques is an essential part of becoming a great tennis player. By focusing on proper footwork, grip, and stroke mechanics, you can improve your forehand, backhand, serve, volley, and overhead smash. With practice and dedication, you can take your game to the next level and enjoy all the benefits that tennis has to offer.',
          },
        ],
      },
    ],
  },
  {
    id: 'improve-your-tennis-skills-with-these-5-tip',
    heroImgUrl: `${IMG_BASE_URL}v1688711123/probuddy/static/images/BlogPage/Blogs/image_446_lzpcwn.png`,
    title: 'How to Improve Your Tennis Skills with These 5 Tip',
    // subTitle: '',
    layout: 'layout-1',
    content: [
      {
        className: 'FullWidthText',
        data: [
          {
            p:
              'Tennis is a popular sport that requires a lot of physical and mental skills. Whether you are a beginner or an advanced player, you can always improve your game and enjoy it more.',
          },
        ],
      },
      {
        className: 'FullWidthText',
        data: [
          {
            p: 'Here are five tips that will help you take your tennis skills to the next level.',
          },
        ],
      },
      {
        className: 'blogTextWrap',
        data: [
          { h3: '1. Work on your footwork' },
          {
            p:
              'Footwork is the foundation of tennis. It allows you to move efficiently and position yourself for every shot. Good footwork can also help you prevent injuries and fatigue. To improve your footwork, you can practice drills that involve agility, balance, speed, and coordination. For example, you can do ladder drills, cone drills, skipping, sprinting, and side-stepping.',
          },
          { h3: '2. Master the basics' },
          {
            p:
              'Before you can play like a pro, you need to master the basic strokes and techniques of tennis. These include the forehand, backhand, serve, volley, overhead, and lob. You should also learn how to grip the racket correctly, how to swing smoothly, and how to hit with different spins and angles. To master the basics, you can take lessons from a coach, watch online videos, or read books and articles.',
          },
          { h3: '3.  Develop a strategy' },
          {
            p:
              "Tennis is not only about hitting the ball hard and fast. It is also about playing smart and adapting to different situations. You should develop a strategy that suits your strengths and weaknesses, as well as your opponent's style and level. A good strategy can help you win more points and games by exploiting your opponent's weaknesses, creating opportunities, and controlling the pace and direction of the game.",
          },
          {
            img: {
              src: `${IMG_BASE_URL}v1688719648/probuddy/static/images/BlogPage/Blogs/Untitled_design_-_2023-06-28T162434_1_eql0my.png`,
              alt: 'probuddy-blogs How to Improve Your Tennis Skills with These 5 Tip',
            },
          },
        ],
      },
      {
        className: 'blogTextWrap',
        data: [
          {
            img: {
              src: `${IMG_BASE_URL}v1688719649/probuddy/static/images/BlogPage/Blogs/Untitled_design_-_2023-06-26T171158_4_o21c68.png`,
              alt: 'probuddy-blogs How to Improve Your Tennis Skills with These 5 Tip',
            },
          },
          { h3: '4. Train your mental game' },
          {
            p:
              'Tennis is a mental game as much as it is a physical one. You need to have confidence, focus, concentration, motivation, and resilience to perform well on the court. You also need to deal with pressure, stress, emotions, and distractions that can affect your game. To train your mental game, you can practice visualization, meditation, breathing exercises, positive self-talk, and goal-setting.',
          },
          { h3: '5. Have fun' },
          {
            p:
              'The most important tip for improving your tennis skills is to have fun. Tennis is a game that can bring you joy, satisfaction, challenge, and growth. You should enjoy every moment of playing tennis, whether it is a practice session or a match. You should also celebrate your achievements, learn from your mistakes, and appreciate your progress.',
          },
          {
            span: 'ABOVE ALL...',
          },
          {
            p:
              'By following these five tips, you can improve your tennis skills and become a better player. You can also find more tips and advice on tennis websites like tennis.com, tennisworldusa.org, or tennismagazine.com. Remember to practice regularly, play with passion, and have fun!',
          },
        ],
      },
    ],
  },
  {
    id: 'the-Perfect-combination-of-Physical-ability-and-technical-skill',
    heroImgUrl: `${IMG_BASE_URL}v1688722346/probuddy/static/images/BlogPage/Blogs/image_448_p7pzui.png`,
    title: 'Tennis: The Perfect Combination of Physical Ability and Technical Skill',
    // subTitle: '',
    layout: 'layout-1',
    content: [
      {
        className: 'FullWidthText',
        data: [
          {
            p:
              "Tennis is a sport that requires a unique combination of physical ability and technical skill. Whether you're a beginner or an experienced player, mastering tennis requires a dedication to both aspects of the game. In this article, we will explore the importance of physical ability and technical skill in tennis.",
          },
        ],
      },
      {
        className: 'blogTextWrap',
        data: [
          { h3: 'Physical Ability' },
          {
            p:
              "Tennis requires a significant amount of physical ability, including strength, endurance, speed, agility, and coordination. To improve your physical ability for tennis, it's important to engage in a variety of exercises and activities that target these areas. Cardiovascular exercises like running and cycling can improve endurance, while strength training exercises like weight lifting and resistance training can improve strength and power.",
          },
          {
            p:
              'Additionally, tennis-specific exercises like footwork drills, agility drills, and plyometric exercises can improve your speed, agility, and coordination on the court. Improving your physical ability can help you move more efficiently on the court, hit the ball with more power and precision, and improve your overall performance.',
          },
        ],
      },
      {
        className: 'blogTextWrap',
        data: [
          { h3: 'Technical Skill' },
          {
            p:
              'In addition to physical ability, tennis also requires a high level of technical skill. Technical skill includes the ability to execute strokes like the forehand, backhand, serve, volley, and overhead smash with proper form, footwork, and timing.',
          },
          {
            p:
              "To improve your technical skill in tennis, it's important to focus on proper stroke mechanics, footwork, and grip. Working with a coach or instructor can help you identify areas where you need to improve and develop a plan for improving your technique.",
          },
          {
            p:
              'Additionally, practicing specific drills and exercises that target specific strokes can help you develop muscle memory and improve your ability to execute those strokes under pressure. Improving your technical skill can help you hit the ball with more accuracy and consistency, and make better shot selections during matches.',
          },
        ],
      },
      {
        className: 'FullWidthText',
        data: [
          {
            img: {
              src: `${IMG_BASE_URL}v1688722343/probuddy/static/images/BlogPage/Blogs/Untitled_design_-_2023-06-26T171158_3_hdt3nu.png`,
              alt:
                'probuddy-blogs Tennis: The Perfect Combination of Physical Ability and Technical Skill',
            },
          },
        ],
      },
      {
        className: 'FullWidthText',
        data: [
          {
            p:
              'In conclusion, tennis is a sport that requires a unique combination of physical ability and technical skill. Improving your physical ability through exercises and activities that target strength, endurance, speed, agility, and coordination can help you move more efficiently on the court and hit the ball with more power and precision',
          },
        ],
      },
      {
        className: 'summaryWrap',
        data: [
          {
            span: 'In Conclusion',
          },
          {
            p:
              'Improving your technical skill through proper stroke mechanics, footwork, and grip can help you hit the ball with more accuracy and consistency, and make better shot selections during matches. By focusing on both aspects of the game, you can become a skilled and confident tennis player.',
          },
        ],
      },
    ],
  },
  {
    id: 'tennis-an-in-demand-sport',
    heroImgUrl: `${IMG_BASE_URL}v1688723433/probuddy/static/images/BlogPage/Blogs/image_451_bje5bi.png`,
    title: 'Tennis: An In-Demand Sport',
    // subTitle: '',
    layout: 'layout-1',
    content: [
      {
        className: 'FullWidthText',
        data: [
          {
            p:
              'Tennis is a sport that has been enjoyed by millions of people around the world for centuries. It is a game that requires skill, strategy, fitness and mental toughness. Tennis can also offer many benefits for physical and mental health, as well as social and intellectual engagement. ',
          },
        ],
      },
      {
        className: 'FullWidthText',
        data: [
          {
            h3: 'Tennis Popularity Statistic',
          },
        ],
      },
      {
        className: 'FullWidthText',
        data: [
          {
            p:
              "According to Tennis Creativehttps://tenniscreative.com/tennis-popularity-statistics/, tennis is one of the most popular individual/non-team sports in the world, with approximately 87 million people playing tennis globally. That's 1.17% of the world's population that plays tennis. Tennis is also one of the most watched sports on TV, attracting millions of viewers for major tournaments such as Wimbledon, the US Open, the French Open and the Australian Open",
          },
        ],
      },
      {
        className: 'FullWidthText',
        data: [
          {
            p:
              'Tennis popularity has increased even more during the Covid-19 pandemic, as people sought outdoor activities that allowed for social distancing and physical activity. According to Forbeshttps://www.forbes.com/sites/brucelee/2022/03/20/tennis-participation-bounced-even-higher-in-2021-up-279-since-2019/, tennis participation in the U.S. increased by 22% in 2020, with 21.64 million people hitting the courts. Tennis racket sales also soared by 22.7% in 2021 compared to 2020, indicating a growing interest and demand for the sport.',
          },
        ],
      },
      {
        className: 'blogTextWrap',
        data: [
          {
            p:
              'Tennis is most popular in Bulgaria, with just over 5080 searches per million people for the sport. Other countries that show high interest in tennis include Australia, Austria, France, Romania and Italyhttps://tenniscreative.com/tennis-popularity-statistics/. Within the U.S., most interest in tennis is shown in New York, New Jersey, Connecticut, Washington DC and Floridahttps://tenniscreative.com/tennis-popularity-statistics/',
          },
          {
            img: {
              src: `${IMG_BASE_URL}v1684923249/probuddy/static/images/BlogPage/Blogs/probuddy-blog_the-ultimate-guide-to-choosing-a-tennis-coach_title_ly1uab.png`,
              alt: 'probuddy-blogs Tennis: An In-Demand Sport',
            },
          },
        ],
      },
      {
        className: 'blogTextWrap',
        data: [
          { h3: 'Tennis History and Culture' },
          {
            p:
              'Tennis has a long and rich history that dates back to the 12th century, when it was played by monks in France using their hands. The modern game of tennis evolved from a game called "real tennis" or "royal tennis", which was played by nobles and royalty in Europe since the 16th century. Real tennis used wooden rackets and balls made of cork or leather, and was played on indoor courts with walls and galleries',
          },
          {
            p:
              'An important milestone in the history of tennis was the decision of the All England Croquet Club to set aside one of its lawns at Wimbledon for tennis, which soon proved so popular that the club changed its name to the All England Croquet and Lawn Tennis Clubhttps://www.britannica.com/sports/tennis. The first Wimbledon Championships were held in 1877, and are still considered the most prestigious event in tennis today.',
          },
          {
            p:
              'Tennis has also produced many legendary players who have inspired generations of fans and players alike. Some of the most famous names in tennis history include Roger Federer, Serena Williams, Rafael Nadal, Novak Djokovic, Martina Navratilova, Steffi Graf, Rod Laver, Billie Jean King and Arthur Ashe. These players have not only displayed exceptional skill and talent on the court, but also have contributed to the development and promotion of the sport through their achievements, personalities and philanthropy.',
          },
        ],
      },
      {
        className: 'FullWidthText',
        data: [
          {
            p:
              "Tennis is an in-demand sport that has many advantages for people who play or watch it. It is a sport that combines physical activity, mental challenge, social connection and cultural heritage. it is a sport that can be enjoyed by anyone regardless of age, gender or background. It is a sport that can enrich one's life in many ways.",
          },
        ],
      },
    ],
  },
  {
    id: 'to-get-fit-and-healthy',
    heroImgUrl: `${IMG_BASE_URL}v1687429775/probuddy/static/images/BlogPage/Blogs/to-get-fit-and-healthy_x8agym.png`,
    title: 'Are you looking',
    subTitle: 'to get fit and healthy in Singapore?',
    layout: 'layout-1',
    content: [
      {
        className: 'FullWidthText',
        data: [
          {
            p: 'Personal training might be just what you need to achieve your fitness goals. ',
          },
        ],
      },
      {
        className: 'FullWidthText',
        data: [
          {
            p:
              "In this blog post, we'll explore the benefits of personal training and provide tips on how to find the right personal trainer for you.",
          },
        ],
      },
      {
        className: 'blogTextWrap',
        data: [
          { h3: 'Firstly' },
          {
            p:
              'Personal Training provides you with a customized fitness program that is tailored to your individual needs and goals. A personal trainer in Singapore will work with you to create a plan that takes into account your fitness level, medical history, and any other factors that may impact your workout. This means that your workouts will be safe, effective, and targeted towards achieving your specific goals.',
          },
          { h3: 'Secondly' },
          {
            p:
              'Personal Training can help you stay motivated and accountable. A personal trainer will push you to work harder and help you stay on track towards achieving your goals. They will also provide you with feedback and encouragement, which can be incredibly motivating.',
          },
          { h3: 'Thirdly' },
          {
            p:
              ' Personal Training can help you avoid injury. A personal trainer will ensure that you are using proper form and technique during your workouts, which can help prevent injury. They can also modify exercises or workouts to accommodate any injuries or limitations you may have.',
          },
          {
            p:
              "When looking for a personal trainer in Singapore, it's important to consider their qualifications, experience, and personality. Look for a trainer who is certified by a reputable fitness organization.",
          },
        ],
      },
      {
        // first Img
        className: 'blogImageWrap',
        data: [
          {
            img: {
              src: `${IMG_BASE_URL}v1687429775/probuddy/static/images/BlogPage/Blogs/to-get-fit-and-healthy_1_bxscrq.png`,
              alt: 'probuddy-blogs why Tennis is a great Sport',
            },
          },
          {
            img: {
              src: `${IMG_BASE_URL}v1687429775/probuddy/static/images/BlogPage/Blogs/to-get-fit-and-healthy_2_onjxzq.png`,
              alt: 'probuddy-blogs_to get fit and healthy in Singapore?',
            },
          },
        ],
      },
      {
        className: 'FullWidthText',
        data: [
          {
            p:
              "It's also important to find a trainer whose personality and coaching style you feel comfortable with. You will be spending a lot of time with your trainer, so it's important to find someone who motivates and inspires you.",
          },
        ],
      },
      {
        className: 'FullWidthText',
        data: [
          {
            p:
              "The cost of personal training in Singapore can vary, but it's important to remember that investing in your health and fitness is a worthwhile investment. When discussing pricing with a personal trainer, make sure to ask about their packages or group rates, as this can often help reduce the cost per session.",
          },
        ],
      },
      {
        className: 'FullWidthText',
        data: [
          {
            p:
              'In conclusion, personal training is a great way to achieve your fitness goals in Singapore. It provides you with a customized workout program, helps you stay motivated and accountable, and can help prevent injury. When looking for a personal trainer, make sure to consider their qualifications, experience, personality, and pricing options. With the right trainer by your side, you can achieve your fitness goals and become the healthiest version of yourself.',
          },
        ],
      },
    ],
  },
  {
    id: 'find-the-best-personal-trainer',
    heroImgUrl: `${IMG_BASE_URL}v1689773214/probuddy/static/images/BlogPage/Blogs/personal%20training/image_452_jmmrba.png`,
    title: 'How to Find the Best Personal Trainer for Your Fitness Goals',
    // subTitle: '',
    layout: 'layout-1',
    content: [
      {
        className: 'FullWidthText',
        data: [
          {
            p:
              'Personal training is a great way to get fit, healthy, and motivated. Whether you want to lose weight, build muscle, improve your performance, or just feel better, a personal trainer can help you achieve your fitness goals.',
          },
        ],
      },
      {
        className: 'FullWidthText',
        data: [
          {
            p: 'But how do you find the best personal trainer for your needs? ',
          },
        ],
      },
      {
        className: 'FullWidthText',
        data: [
          {
            p: 'Here are some tips to help you choose the right personal trainer for you.',
          },
        ],
      },
      {
        className: 'FullWidthText',
        data: [{ h3: '1. Determine Your Goals' }],
      },
      {
        className: 'FullWidthText',
        data: [
          {
            p:
              'The first step in finding the best personal trainer for your fitness goals is to determine what your goals are. Do you want to lose weight, build muscle, or improve your overall fitness? Once you have a clear idea of your goals, you can look for a personal trainer who specializes in the area you want to focus on.',
          },
        ],
      },
      {
        className: 'FullWidthText',
        data: [{ h3: '2. Check Their Credentials' }],
      },
      {
        className: 'FullWidthText',
        data: [
          {
            p:
              "When it comes to hiring a personal trainer, it's important to check their credentials. Look for a personal trainer who is certified by a reputable organization such as the National Academy of Sports Medicine (NASM), the American Council on Exercise (ACE), or the International Sports Sciences Association (ISSA). A certified personal trainer has completed the necessary training and education to provide you with safe and effective exercise programs.",
          },
        ],
      },
      {
        className: 'FullWidthText',
        data: [{ h3: '3. Consider Their Experience' }],
      },
      {
        className: 'FullWidthText',
        data: [
          {
            p:
              'Experience is also an important factor to consider when looking for a personal trainer. Look for a trainer who has experience working with clients who have similar goals to yours. For example, if you want to build muscle, look for a trainer who has experience working with clients who have successfully built muscle in the past.',
          },
        ],
      },
      {
        className: 'FullWidthText',
        data: [{ h3: '4. Evaluate Their Personality' }],
      },
      {
        className: 'FullWidthText',
        data: [
          {
            p:
              'The personality of your personal trainer is also important. Look for a trainer who is positive, supportive, and encouraging. You want someone who will push you to do your best but also make the experience enjoyable. A personal trainer who is negative or overly critical can be demotivating and make your fitness journey less enjoyable.',
          },
        ],
      },
      {
        className: 'FullWidthText',
        data: [{ h3: '5. Ask for References' }],
      },
      {
        className: 'FullWidthText',
        data: [
          {
            p:
              "Before hiring a personal trainer, ask for references from previous clients. This will give you an idea of what it's like to work with the trainer and whether they were able to help their clients achieve their goals. You can also check online reviews to see what others are saying about the trainer.",
          },
        ],
      },
      {
        className: 'FullWidthText',
        data: [{ h3: '6. Evaluate Their Communication Skills' }],
      },
      {
        className: 'FullWidthText',
        data: [
          {
            p:
              'Communication is key when working with a personal trainer. Look for a trainer who communicates clearly and effectively. They should be able to explain exercises and concepts in a way that you can understand. They should also be responsive to your questions and concerns.',
          },
        ],
      },
      {
        className: 'FullWidthText',
        data: [{ h3: '7. Consider Their Availability' }],
      },
      {
        className: 'FullWidthText',
        data: [
          {
            p:
              'Finally, consider the availability of the personal trainer. Look for a trainer who can work with your schedule and is able to commit to a regular training schedule. You want someone who is reliable and committed to helping you achieve your goals.',
          },
        ],
      },
      {
        className: 'FullWidthText',
        data: [
          {
            p:
              'In conclusion, finding the best personal trainer for your fitness goals requires a bit of research and consideration. By taking the time to evaluate their credentials, experience, personality, communication skills, and availability, you can find a personal trainer who can help you achieve your fitness goals and support you along the way.',
          },
        ],
      },
    ],
  },
];
