import React, { useEffect, useState } from 'react';
import css from './MyReward.css';
import { Button } from 'components';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import {
  getClientTransactions,
  getRecommendationData,
  onAcceptRequestAdmin,
  onAcceptRequestRecommender,
  onRewardRequestAdmin,
  onRewardRequestRecommender,
} from 'containers/HomePage/HomePage.duck';

const MyRewardList = props => {
  const { currentUser } = useSelector(state => state.user);

  const { recommendedData } = props;

  const [count, setCount] = useState(null);
  const [totalPayinAmount, setTotalPayinAmount] = useState(null);
  const [tenPercent, setTenPercent] = useState(null);

  const clientId = recommendedData.clientId;
  const startDate = recommendedData.startDate;
  const status = recommendedData.status;
  const currentUserId = currentUser.id.uuid;

  const recommendedDate = new Date(recommendedData.startDate);

  const options = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  };
  const formattedRecommendedDate = new Intl.DateTimeFormat('en-US', options).format(
    recommendedDate
  );

  const today = new Date();

  const optionsPresent = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  };
  const presentDate = new Intl.DateTimeFormat('en-US', optionsPresent).format(today);

  // Get UTC hours for Singapore time zone to get transaction data
  const singaporeTimeOffset = 8; // Singapore is UTC+8
  const singaporeTimeHours = today.getUTCHours() + singaporeTimeOffset;

  // Adjust the UTC hours for Singapore time zone
  today.setUTCHours(singaporeTimeHours);

  // Format for today's date in "2023-12-08T18:00:10.857Z" in Asia/Singapore time zone for query transaction
  const formattedPresentDate = today.toISOString();

  const getListingData = async () => {
    try {
      const { count, totalPayinAmount } = await dispatch(
        getClientTransactions(clientId, startDate, formattedPresentDate)
      );
      // Now you can use the count value here
      setCount(count);
      setTotalPayinAmount(totalPayinAmount);

      const calculatedFivePercent = (totalPayinAmount * 0.1) / 100;
      // Set decimal value to first two digits
      const roundedFivePercent = +calculatedFivePercent.toFixed(2);
      setTenPercent(roundedFivePercent);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    getListingData();
  }, []);

  const handleRequestClick = async () => {
    // Dispatch an action to indicate that the API request is in progress
    await dispatch(onRewardRequestAdmin(clientId, tenPercent, formattedPresentDate));
    await dispatch(
      onRewardRequestRecommender(currentUserId, clientId, tenPercent, formattedPresentDate)
    );
    await dispatch(getRecommendationData());
    await getListingData();
  };

  const handleAcceptClick = async () => {
    // Dispatch an action to indicate that the API request is in progress
    await dispatch(onAcceptRequestAdmin(clientId));
    await dispatch(onAcceptRequestRecommender(currentUserId, clientId));
    await dispatch(getRecommendationData());
    await getListingData();
  };

  const dispatch = useDispatch();

  return (
    // <div className={css.myRewardSection}>

    <div className={css.myRewardWrap}>
      <div className={css.myRewardDetail}>
        <img className={css.matchCardImg} src={recommendedData.clientImage} />
        <div className={css.myRewardDetailWrap}>
          <h5 className={css.myRewardHead}>{recommendedData.clientName}</h5>
          {/* <h5 className={css.myRewardSubHead}>{recommendedData.clientEmail}</h5> */}
          <h6 className={css.myRewardSubHead}>Your Rewards: SGD {tenPercent}</h6>
        </div>
      </div>
      <div className={css.myRewardTransactions}>
        <p className={css.myRewardText}>Transactions From:</p>
        <p className={css.myRewardValue}>
          {formattedRecommendedDate} to {presentDate}
        </p>
      </div>
      <div className={css.myRewardTransactions}>
        <p className={css.myRewardValue}>
          <span>Total transactions:</span> {count}
        </p>
        <p className={css.myRewardValue}>
          <span>Total amount:</span> SGD {totalPayinAmount / 100}
        </p>
      </div>
      <div className={css.myRewardDetailButton}>
        {status !== 'pending' && (
          <Button className={css.myRewardRequest} onClick={handleRequestClick}>
            Request
          </Button>
        )}
        {status === 'pending' && (
          <p className={css.detailLocation}>Your requested reward is in process. Accepted?</p>
        )}
        {status === 'pending' && (
          <Button className={css.myRewardAccept} onClick={handleAcceptClick}>
            Accepted
          </Button>
        )}
      </div>
    </div>
  );
};

export default MyRewardList;
