import React, { useState } from 'react';
import { uid } from 'react-uid';
import { Menu, MenuLabel, MenuContent, MenuItem } from 'components/index';
import PropTypes from 'prop-types';
import { IconSpinner } from 'components/index';
import { IconArrowDown } from 'components/index';

import css from './DropdownButton.css';

function DropdownButton(props) {
    const [isOpen, setIsOpen] = useState(false);
    const {selectedOption, direction = 'left' } = props
    const labelClassName = props.labelClassName || css.label;

    return (
        <Menu isOpen={isOpen} onToggleActive={setIsOpen} className={css.menuRoot}  contentPosition={direction}>
            <MenuLabel className={labelClassName}>
                {props.inProgress ? (
                    <IconSpinner />
                ) : (<>
                        {props.label} <IconArrowDown />
                        </>)}
            </MenuLabel>
            <MenuContent className={css.menuContent}>
                {props.actions.map(action => (
                    <MenuItem key={uid(action.label)}>
                        <button
                            className={`${css.menuItem}`}
                            onClick={() => {
                                setIsOpen(false);
                                action.handler();
                            }}
                        >
                            <span className={css.menuItemBorder} />
                            {action.label}
                            {selectedOption.includes(action.key) && (<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                <circle cx="8.94531" cy="8.62793" r="8.5" fill="#10D6EB" />
                                <path d="M5.44531 9.09931L7.59304 11.1279L12.4453 6.12793" stroke="white" strokeWidth="2" />
                            </svg>)}
                        </button>
                    </MenuItem>
                ))}
            </MenuContent>
        </Menu>
    );
}

DropdownButton.propTypes = {
    actions: PropTypes.arrayOf(
        PropTypes.shape({
            handler: PropTypes.func,
        })
    ),
    inProgress: PropTypes.bool,
    labelClassName: PropTypes.string
};

export default DropdownButton;
