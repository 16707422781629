import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { propTypes } from 'util/types';
import css from './ClassResults.css';
import { ClassListingCard } from 'components/index';

const getSelectedDate = searchParams => {
  const dates = searchParams.dates.split(',');
  return dates[0] ? moment(dates[0]) : moment();
};

function ClassResults({ listings, setActiveListing, searchParams, allClassListingReviews = {} }) {
  const selectedDate = getSelectedDate(searchParams);
  return (
    <div className={css.classCards}>
      {listings.map(l => (
        <ClassListingCard
          key={l.id.uuid}
          listing={l}
          setActiveListing={setActiveListing}
          selectedDate={selectedDate}
          reviews={allClassListingReviews ? allClassListingReviews[l.id.uuid] : []}
        />
      ))}
    </div>
  );
}

ClassResults.propTypes = {
  listings: PropTypes.arrayOf(propTypes.listing),
  setActiveListing: PropTypes.func,
  searchParams: PropTypes.object,
};

export default ClassResults;
