import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import IconWhatsapp from 'components/IconWhatsapp/IconWhatsapp';
import css from 'components/WhatsappButton/WhatsappButton.css';

const URL = 'https://wa.me';

function WhatsappButton(props) {
  const { className, user, isButton=false } = props;
  const classes = classnames(css.root, className || '');
  const publicData = user.attributes.profile.publicData || {};
  const contactNumber = publicData.phoneNumber || ''
  const number = contactNumber && contactNumber.replace(/[^\w\s]/gi, '').replace(/ /g, '');
  const url = `${URL}/${number}`;
  return contactNumber ? (
    <div className={classes}>
    	{!isButton ? <a href={url}>
    		<IconWhatsapp />
    	</a>:
      <a className={css.iconWhatsappLink} href={url}>
      <IconWhatsapp invert={true}  rootClassName={css.invertIconWhatsappButton} />
       Start Chat
      </a>}
    </div>
  ) : null;
}

WhatsappButton.propTypes = {
  className: PropTypes.string,
};

export default WhatsappButton;
