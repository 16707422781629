import React, { useState } from 'react';
import css from './MatchActivityCard.css';
import { Button } from 'components';
import emailjs, { init } from '@emailjs/browser';
import classNames from 'classnames';
import { onGetJoinRequest } from '../MatchActivity.duck';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import MatchJoinedUsers from './MatchJoinedUsers';

const MatchActivityCard = props => {
  const { currentUser } = useSelector(state => state.user);
  const { jobsBoardData, history } = props;
  const dispatch = useDispatch();
  const { publicData, title, createdAt, id, description } = jobsBoardData;
  const {
    que_ans,
    location,
    ownerEmail,
    allowedPlayers,
    price,
    ownerName,
    joinRequest,
    detailLocation,
    date,
    userImage,
    time,
  } = publicData;
  const requesterMail = currentUser?.attributes?.email;
  const requestName = currentUser?.attributes?.profile?.displayName;
  const matchOwnerEmail = ownerEmail;

  const [showDesc, setShowDesc] = useState(false);

  // Convert allowedPlayers?.max_player from string to number
  const MaxPlayers = allowedPlayers?.max_player;
  const numberMaxPlayers = parseInt(MaxPlayers, 10);

  // const time = createdAt
  //   ? moment(createdAt)
  //       .startOf('day')
  //       .fromNow()
  //   : moment(createdAt).format('DD/MM/YYYY');

  const sendJoinRequestMailUser = ({ email, ...values }) => {
    const templateParams = {
      to_name: requestName,
      to_mail: requesterMail,
      from_mail: email,
    };
    if (process.env.REACT_APP_ENV === 'production') {
      emailjs
        .send('service_tn96fm5', 'template_k3vqvdo', templateParams, 'p656r9_5pKAHF0ioU')
        .then()
        .catch(e => console.log('e-->', e));
    }
  };

  const sendJoinRequestMail = ({ email, ...values }) => {
    const url = 'https://www.probuddy.io/inbox/myMatch';

    const templateParams = {
      to_name: ownerName,
      to_mail: matchOwnerEmail,
      from_mail: email,
      matchlink: url,
    };
    if (process.env.REACT_APP_ENV === 'production') {
      emailjs
        .send('service_tn96fm5', 'template_fwklitr', templateParams, 'p656r9_5pKAHF0ioU')
        .then()
        .catch(e => console.log('e-->', e));
    }
  };

  const handleJoinClick = () => {
    const searchParams = {};

    // Dispatch an action to indicate that the API request is in progress
    dispatch(onGetJoinRequest(id, searchParams));
    sendJoinRequestMail(ownerEmail);
    sendJoinRequestMailUser(requesterMail);
  };

  return (
    // <>
    //   <div className={css.jobCardWrap}>
    //     <div className={css.jobCardWrapBox}>
    //       <div className={css.jobCardHeadMain}>
    //         <div className={css.jobCardHead}>
    //           <img
    //             className={css.jobCardImg}
    //             src={
    //               'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1689403886/probuddy/static/avatar/companyLogo_cbv71g.png'
    //             }
    //           />
    //           <Button className={css.jobCardHeadButton}>{title}</Button>
    //         </div>
    //         {/* <Button className={css.jobCardHeadButton}>Submit</Button> */}
    //       </div>
    //       <div className={css.jobCardBoday}>
    //         {queAnsMatchActivity?.map((val, i) => {
    //           return (
    //             <p key={i} className={css.jobCardText}>
    //               <span key={i}>{val.question}</span>: {que_ans[0][i]}
    //             </p>
    //           );
    //         })}
    //       </div>
    //     </div>
    //     <div className={css.jobsBoardReply}>
    //       <p className={css.jobsBoardTime}>
    //         Match Posted <strong>{time}</strong>
    //       </p>
    //     </div>
    //   </div>
    // </>
    <div className={css.jobCardWrap}>
      <div className={css.jobCardHead}>
        <div>
          <img className={css.matchCardImg} src={userImage} />
          <h5 className={css.cardHead}>{que_ans[0][0]} Meetup</h5>
        </div>
        <div className={css.levelTitleWrap}>
          <div className={css.cardLavel}>
            <span className={css.cardLavelHead}>
              Level Range{' '}
              <img src="https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1699508156/probuddy/static/images/Icons/levelDiamond_xwgzv0.png" />
            </span>
            <span className={css.cardLavelText}>{que_ans[0][1]}</span>
          </div>
        </div>
      </div>
      <div className={css.hostedByMain}>
        <img
          className={css.hostbyImg}
          src="https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1699508088/probuddy/static/images/Icons/hostedby_zg1th1.png"
          alt="host-by"
        />
        <div className={css.hostbyWrap}>
          <h6 className={css.hostedBy}>Hosted By: </h6>
          <h6 className={css.hostedByName}>{ownerName}</h6>
        </div>
      </div>
      <p
        onClick={() => setShowDesc(!showDesc)}
        className={classNames(css.detailDesc, !showDesc && css.detailDescHide)}
      >
        {description}
      </p>
      <div className={css.playersDetailWrap}>
        <div className={css.playingDetail}>{que_ans[0][3]} </div>
        <div className={css.dote}></div>
        <div className={css.playingStratTo}>Min {allowedPlayers?.min_player} players to start</div>
        {price && <div className={css.dote}></div>}
        {price && <div className={css.playerPrice}>{price}</div>}
      </div>
      <div className={css.playerTime}>
        <img src="https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1699507988/probuddy/static/images/Icons/clock_hji7g5.png" />
        <div className={css.timewrap}>
          <h6 className={css.timetext}>
            {date?.day}, {date?.month} - {date?.week}
          </h6>
          <span className={css.timeLabel}>
            {time?.start} to {time?.end}
          </span>
        </div>
      </div>
      <div className={css.playerLocation}>
        <img src="https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1699508023/probuddy/static/images/Icons/location_v6gx6w.png" />
        <div className={css.locationText}>
          <h6 className={css.locationTextHead}>Court Booked</h6>
          <span className={css.locationValue}>{location?.prefered_area}</span>
        </div>
      </div>

      <h6 className={css.detailLocation}>{detailLocation}</h6>

      <div className={css.playersMember}>
        <MatchJoinedUsers joinRequest={joinRequest} numberMaxPlayers={numberMaxPlayers} />
      </div>
      <div className={css.cardButtons}>
        <Button className={css.cardBtn}>{que_ans[0][2]} Players</Button>
        <Button className={css.joinBtn} onClick={handleJoinClick}>
          Join
        </Button>
      </div>
    </div>
  );
};

export default MatchActivityCard;
