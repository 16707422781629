import React, { useEffect, useRef, useState } from 'react';

import { arrayOf, func, string } from 'prop-types';
import { compose } from 'redux';
import { injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { ensureListing } from '../../util/data';
import { MapCards } from '../../components';

import css from './SearchMapInfoCard.css';

// ListingCard is the listing info without overlayview or carousel controls
const ListingCard = props => {
  const { className, clickHandler, intl, isInCarousel, listing, urlToListing } = props;
  const { title } = listing.attributes;

  // listing card anchor needs sometimes inherited border radius.
  const classes = classNames(
    css.anchor,
    css.borderRadiusInheritTop,
    { [css.borderRadiusInheritBottom]: !isInCarousel },
    className
  );

  // const isClassCard = listing.attributes.publicData.listing_type === 'class';

  return (
    <a
      alt={title}
      className={classes}
      href={urlToListing}
      onClick={e => {
        e.preventDefault();
        // Use clickHandler from props to call internal router
        clickHandler(listing);
      }}
    >
      <MapCards key={`${listing.id.uuid}-1`} pl={listing} intl={intl} isHorizontalLayout={true} />
    </a>
  );
};

const SearchMapInfoCard = props => {
  const {
    className,
    rootClassName,
    intl,
    listings,
    createURLToListing,
    onListingInfoCardClicked,
    allListingReviews,
    isMobileLayout,
  } = props;
  const [currentListingIndex, setcurrentListingIndex] = useState(0);
  const [calTop, setCalTop] = useState('50px');
  const [calLeft, setCalLeft] = useState('-120px');
  const [calRight, setCalRight] = useState('unset');
  const cardRef = useRef();

  useEffect(() => {
    if (!cardRef) return;
    if (typeof window === 'undefined') return;
    if (window?.innerHeight - cardRef?.current?.getBoundingClientRect()?.top < 320) {
      setCalTop('-300px');
    }
    if (
      cardRef?.current?.getBoundingClientRect()?.top <= 540 &&
      window?.innerHeight - cardRef?.current?.getBoundingClientRect()?.top < 390
    ) {
      setCalTop('-137px');
      setCalLeft('unset');
      setCalRight('90px');
      if (cardRef?.current?.getBoundingClientRect()?.top <= 280) {
        setCalTop('-180px');
      }
      if (window?.innerHeight - cardRef?.current?.getBoundingClientRect()?.top <= 160) {
        setCalTop('-170px');
      }
      if (cardRef?.current?.getBoundingClientRect()?.left < 605) {
        setCalLeft('90px');
        setCalRight('unset');
      }
    }
    // if (window?.innerHeight - cardRef?.current?.getBoundingClientRect()?.top < 612)
    //   setCalTop('50px');
    if (cardRef?.current?.getBoundingClientRect()?.left < 410) {
      setCalLeft('90px');
      setCalRight('unset');
    }
    if (window?.innerWidth - cardRef?.current?.getBoundingClientRect()?.left < 320) {
      setCalLeft('unset');
      setCalRight('80px');
    }
  }, [cardRef]);

  const hasCarousel = listings.length > 1;
  const currentListing = ensureListing(listings[currentListingIndex]);
  const pagination = hasCarousel && (
    <div className={classNames(css.paginationInfo, css.borderRadiusInheritBottom)}>
      <button
        className={css.paginationPrev}
        onClick={e => {
          e.preventDefault();
          setcurrentListingIndex((currentListingIndex + listings.length - 1) % listings.length);
        }}
      />
      <div className={css.paginationPage}>{`${currentListingIndex + 1}/${listings.length}`}</div>
      <button
        className={css.paginationNext}
        onClick={e => {
          e.preventDefault();
          setcurrentListingIndex((currentListingIndex + listings.length + 1) % listings.length);
        }}
      />
    </div>
  );

  const classes = classNames(rootClassName || css.root, className, {
    [css.singleListingRoot]: !hasCarousel,
    [css.setToTop]: calTop,
  });

  return (
    <div
      className={classes}
      ref={cardRef}
      style={
        isMobileLayout
          ? { top: 'unset', bottom: '90px', left: '10%', width: '80%' }
          : { top: calTop, left: calLeft, right: calRight }
      }
    >
      {/* <div className={css.caretShadow} /> */}
      <ListingCard
        clickHandler={onListingInfoCardClicked}
        urlToListing={createURLToListing(currentListing)}
        listing={currentListing}
        intl={intl}
        isInCarousel={hasCarousel}
        allListingReviews={allListingReviews}
      />
      {pagination}
      <div className={css.caretClass} />
    </div>
  );
};

SearchMapInfoCard.defaultProps = {
  className: null,
  rootClassName: null,
};

SearchMapInfoCard.propTypes = {
  className: string,
  rootClassName: string,
  listings: arrayOf(propTypes.listing).isRequired,
  onListingInfoCardClicked: func.isRequired,
  createURLToListing: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default compose(injectIntl)(SearchMapInfoCard);
