import React, { useState, useMemo, useEffect } from 'react';
import { IconSpinner, SecondaryButton, PaginationLinks, TransactionStateButton } from 'components';
import classNames from 'classnames';
import { FormattedMessage } from '../../../../util/reactIntl';
import { getData } from 'containers/BookingPlanPage/helpers';
import Table from '../Table/Table';
import RecentTable from '../RecentTable/RecentTable';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import SortByPopup from '../../../../components/SortBy/SortByPopup';
import routeConfiguration from '../../../../routeConfiguration';
import { createResourceLocatorString } from '../../../../util/routes';
import { parse } from '../../../../util/urlHelpers';
import css from './SchedulePageContent.css';

function SchedulePageContent({
  intl,
  transactions = [],
  recentTransactions = [],
  isLoading,
  params,
  onSelect,
  history,
  openModalSendMessage,
  selectedBookings,
  onAllSelectedSchedule,
  pagination,
  isMobileLayout,
  myClientsData,
  gettingAllClientData,
}) {
  /* eslint-disable-next-line */
  const [isCollapsedAll, setCollapsedAll] = useState(false);
  const [keyWordValue, setKeyWordValue] = useState('');
  const { tab, state = '1week' } = params;
  const urlQueryParams = parse(history.location.search);

  useEffect(() => {
    if (urlQueryParams && urlQueryParams.keywords && keyWordValue === '') {
      setKeyWordValue(urlQueryParams.keywords);
    }
    /* eslint-disable-next-line */
  }, []);

  const dateSelectedWeekCompare = ScheduleDate => {
    if (state === '2weeks') {
      return moment(ScheduleDate).isBetween(
        moment().startOf('isoweek'),
        moment()
          .startOf('isoweek')
          .add(2, 'weeks')
      );
    } else if (state === '4weeks') {
      return moment(ScheduleDate).isBetween(
        moment().startOf('isoweek'),
        moment()
          .startOf('isoweek')
          .add(4, 'weeks')
      );
    } else if (state === '6weeks') {
      return moment(ScheduleDate).isBetween(
        moment().startOf('isoweek'),
        moment()
          .startOf('isoweek')
          .add(6, 'weeks')
      );
    } else if (state === '8weeks') {
      return moment(ScheduleDate).isBetween(
        moment().startOf('isoweek'),
        moment()
          .startOf('isoweek')
          .add(8, 'weeks')
      );
    } else {
      return moment(ScheduleDate).isBetween(
        moment().startOf('isoweek'),
        moment()
          .startOf('isoweek')
          .add(1, 'weeks')
      );
    }
  };
  const data = useMemo(() => {
    if (tab === 'schedule' || tab === 'schedule-client') {
      const filterTransactions = transactions.filter(sd => {
        return dateSelectedWeekCompare(sd.booking ? sd.booking.attributes.start : null);
      });
      return getData(filterTransactions);
    }
    return getData(transactions);
    // eslint-disable-next-line
  }, [transactions]);

  const getWeekDayInNumber = value => {
    switch (value) {
      case 'mon':
        return 1;
      case 'tue':
        return 2;
      case 'wed':
        return 3;
      case 'thu':
        return 4;
      case 'fri':
        return 5;
      case 'sat':
        return 6;
      case 'sun':
        return 7;
      default:
        return null;
    }
  };

  const content = useMemo(() => {
    if (tab === 'schedule' && !data.length)
      return <div className={css.tablePlaceholder}>No Results</div>;
    if (tab === 'schedule-client' && !data.length)
      return <div className={css.tablePlaceholder}>No Results</div>;
    if (tab === 'myClients' && !recentTransactions.length)
      return <div className={css.tablePlaceholder}>No Results</div>;

    const selectedWeekday = urlQueryParams && urlQueryParams.dayOfWeek;
    const weekDayValue = getWeekDayInNumber(selectedWeekday);
    const weekDayFilterData = weekDayValue && data.filter(entry => entry.weekday === weekDayValue);

    if (weekDayValue && weekDayFilterData.length === 0) {
      return <div className={css.tablePlaceholder}>No Results</div>;
    }

    switch (tab) {
      case 'schedule':
      case 'schedule-client':
        return (
          <div className={css.tableWrapper}>
            <Table
              data={weekDayValue ? weekDayFilterData : data}
              className={css.scheduleTable}
              urlQueryParams={urlQueryParams}
              onAllSelectedSchedule={onAllSelectedSchedule}
              onSelect={onSelect}
              selectedWeek={state}
              selectedBookings={selectedBookings}
              isSaleSchedule={tab === 'schedule'}
              isCollapsedAll={isCollapsedAll}
              isMobileLayout={isMobileLayout}
            />
          </div>
        );

      case 'myClients':
        return (
          <>
            <RecentTable
              data={recentTransactions}
              urlQueryParams={urlQueryParams}
              selectedBookings={selectedBookings}
              onSelect={onSelect}
              onAllSelectedClient={onAllSelectedSchedule}
              myClientsData={myClientsData}
            />
            {urlQueryParams?.keywords && gettingAllClientData && (
              <IconSpinner className={css.spinner} />
            )}
          </>
        );
      default:
        return null;
    }
    // eslint-disable-next-line
  }, [transactions, recentTransactions, isCollapsedAll, tab]);

  const sendMessageText = intl.formatMessage({ id: 'SchedulePageContent.sendMessage' });
  // const handleKeyword = (urlParam, values) => {
  //   const queryParams = values
  //     ? { ...urlQueryParams, [urlParam]: values }
  //     : omit(urlQueryParams, urlParam);

  //   history.push(
  //     createResourceLocatorString(
  //       'InboxPage',
  //       routeConfiguration(),
  //       { tab: 'myClients' },
  //       queryParams
  //     )
  //   );
  // };
  // const filterKeywordContent = (
  //   <div className={css.filterKeywordWrap}>
  //     <div className={css.filterWrap}>
  //       <div className={css.buttonWrap}>
  //         <IconSearch />
  //       </div>
  //       <div className={css.inputWrap}>
  //         <input
  //           id={'SearchFilters.keywordFilter'}
  //           value={keyWordValue}
  //           placeholder={keywordPlaceholder}
  //           onChange={e => setKeyWordValue(e.target.value)}
  //         />
  //       </div>
  //       <Button className={css.btnapply} onClick={() => handleKeyword('keywords', keyWordValue)}>
  //         {applyButtonText}
  //       </Button>
  //     </div>
  //   </div>
  // );

  const onClickWeekDayButton = value => {
    const queryParams = { ...urlQueryParams, dayOfWeek: value };
    onAllSelectedSchedule([]);
    history.push(
      createResourceLocatorString('InboxPage', routeConfiguration(), { ...params }, queryParams)
    );
  };

  const WeekDayButtons = ({ onClick }) => {
    const dayOfWeekBtns = [
      {
        key: 'all',
        label: <FormattedMessage id={'InboxPage.WeekDayAll'} />,
      },
      {
        key: 'mon',
        label: <FormattedMessage id={'InboxPage.WeekDayMon'} />,
      },
      {
        key: 'tue',
        label: <FormattedMessage id={'InboxPage.WeekDayTue'} />,
      },
      {
        key: 'wed',
        label: <FormattedMessage id={'InboxPage.WeekDayWed'} />,
      },
      {
        key: 'thu',
        label: <FormattedMessage id={'InboxPage.WeekDayThu'} />,
      },
      {
        key: 'fri',
        label: <FormattedMessage id={'InboxPage.WeekDayFri'} />,
      },
      {
        key: 'sat',
        label: <FormattedMessage id={'InboxPage.WeekDaySat'} />,
      },
      {
        key: 'sun',
        label: <FormattedMessage id={'InboxPage.WeekDaySun'} />,
      },
    ];
    return (
      <div className={css.WeekDayBtns}>
        {dayOfWeekBtns.map(btn => (
          <TransactionStateButton
            key={btn.key}
            isSchedule={true}
            onClick={() => onClick(btn.key)}
            isActive={
              urlQueryParams && urlQueryParams.dayOfWeek
                ? urlQueryParams.dayOfWeek === btn.key
                : 'all' === btn.key
            }
            {...btn}
          />
        ))}
      </div>
    );
  };

  const handleSortBy = (urlParam, values) => {
    const queryParams = { ...urlQueryParams, [urlParam]: values };
    switch (tab) {
      case 'schedule':
        return history.push(
          createResourceLocatorString(
            'InboxPage',
            routeConfiguration(),
            { tab: 'schedule', state },
            queryParams
          )
        );
      case 'schedule-client':
        return history.push(
          createResourceLocatorString(
            'InboxPage',
            routeConfiguration(),
            { tab: 'schedule-client', state },
            queryParams
          )
        );
      case 'myClients':
        return history.push(
          createResourceLocatorString(
            'InboxPage',
            routeConfiguration(),
            { tab: 'myClients' },
            queryParams
          )
        );
      default:
        return null;
    }
  };

  const sortProps = {
    urlParam: 'sort',
    label: intl.formatMessage({ id: 'SchedulePageContent.sortByHeading' }),
    options: [
      { key: 'default', label: 'Default' },
      { key: 'asc', label: 'Ascending' },
      { key: 'desc', label: 'Descending' },
    ],
    initialValue: urlQueryParams && urlQueryParams.sort ? urlQueryParams.sort : 'default',
    onSelect: handleSortBy,
  };

  const subHeading = useMemo(() => {
    switch (tab) {
      case 'schedule':
      case 'schedule-client':
        return 'Active bookings';
      case 'myClients':
        return 'Recent bookings';
      default:
        return '';
    }
  }, [tab]);
  return (
    <>
      {tab === 'myClients' && !isMobileLayout && <div className={css.tabDivider} />}
      <div
        className={classNames(css.heading, {
          [css.headingSchedule]: tab === 'schedule' || tab === 'schedule-client',
        })}
      >
        <div className={css.subHeadingWrapper}>
          {tab !== 'schedule' && tab !== 'schedule-client' && (
            <h3 className={css.subHeading}>{subHeading}</h3>
          )}
          {tab !== 'myClients' && <WeekDayButtons onClick={onClickWeekDayButton} />}
          {/* {tab === 'myClients' && filterKeywordContent} */}
        </div>

        {!tab === 'myClients' && (
          <div className={css.helpers}>
            {selectedBookings.length > 0 && (
              <>
                <SecondaryButton className={css.sendMessageButton} onClick={openModalSendMessage}>
                  {sendMessageText}
                </SecondaryButton>
                <SecondaryButton
                  className={css.sendMessageButton}
                  onClick={() => onAllSelectedSchedule([])}
                >
                  <FormattedMessage id={'SchedulePageContent.clearSelection'} />
                </SecondaryButton>
              </>
            )}
            <SortByPopup {...sortProps} />
          </div>
        )}
      </div>
      {/* {tab !== 'schedule' && isLoading ? <IconSpinner className={css.spinner} /> : content} */}
      {tab === 'schedule' || tab === 'schedule-client' ? (
        <div className={css.headingContentContainer}>
          {/* {!isMobileLayout && <div className={css.tabDivider} />} */}
          <h3 className={css.subHeading}>{subHeading}</h3>
          {isLoading ? <IconSpinner className={css.spinner} /> : content}
        </div>
      ) : (
        content
      )}
      {/*) : isLoading || (urlQueryParams?.keywords && gettingAllClientData) ? ( 
      <IconSpinner className={css.spinner} />
      ) */}
      {tab === 'myClients' &&
      !urlQueryParams.keywords &&
      pagination &&
      pagination.totalPages > 1 ? (
        <PaginationLinks
          className={css.myClientPagination}
          pageName="InboxPage"
          pagePathParams={params}
          pagination={pagination}
        />
      ) : null}
    </>
  );
}

SchedulePageContent.propTypes = {};

export default SchedulePageContent;
