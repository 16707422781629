import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import cns from 'classnames';
import { propTypes } from 'util/types';
import css from './ListingPicker.css';

import { Form as FinalForm } from 'react-final-form';
import { FieldSelect, Form } from 'components';
import config from 'config';

const allCategories = config.custom.categories;
const makeSelectOptions = listings => {
  if (!listings.length) return {};

  return listings
    .map(listing => {
      const providerName = listing.attributes.publicData.provider_name;
      const category = listing.attributes.publicData.category;
      const providerNameString = !!providerName ? `${providerName} | ` : '';
      return {
        label: `${providerNameString}${listing.attributes.title}`,
        value: listing.id.uuid,
        category,
      };
    })
    .reduce((acc, item) => {
      const categoryData = allCategories && allCategories.find(c => c.key === item.category);
      const categoryLabel = categoryData ? categoryData.label : 'In-Person Sessions';
      acc[categoryLabel] = acc[categoryLabel] ? [...acc[categoryLabel], item] : [item];
      return acc;
    }, {});
};

const deconstructOptions = options =>
  Object.entries(options).reduce((acc, [key, value]) => {
    acc = [...acc, ...value];
    return acc;
  }, []);

function ListingPicker(props) {
  const { listings, companyListings, onListingChange, className, defaultValue } = props;
  const options = useMemo(() => makeSelectOptions(listings), [listings]);
  const companyOptions = useMemo(() => makeSelectOptions(companyListings), [companyListings]);
  const containerClass = cns({
    [css.listingPicker]: true,
    [className]: !!className,
  });

  const handleFormSubmit = e => {
    const opts = deconstructOptions(options);
    const companyOpts = deconstructOptions(companyOptions);
    const option = [...opts, ...companyOpts].find(o => o.value === e);
    onListingChange(option);
  };

  return (
    <div className={containerClass}>
      <FinalForm onSubmit={handleFormSubmit} initialValues={{ listing: defaultValue.value }}>
        {formProps => {
          return (
            <Form>
              <FieldSelect
                rootClassName={css.selectFieldRoot}
                selectClassName={css.selectFieldInput}
                name={'listing'}
                id={'listingPicker'}
                onChange={handleFormSubmit}
              >
                <optgroup label="Your Listings" />
                {Object.entries(options).map(([category, subOptions]) => (
                  <>
                    <optgroup key={category} label={category} />
                    {subOptions.map(o => (
                      <option key={o.value} value={o.value}>
                        {o.label}
                      </option>
                    ))}
                  </>
                ))}
                {Object.entries(companyOptions).length ? (
                  <>
                    <optgroup label="Company Listings" />
                    {Object.entries(companyOptions).map(([category, subOptions]) => (
                      <>
                        <optgroup key={`${category}askdfgh`} label={category} />
                        {subOptions.map(o => (
                          <option key={o.value} value={o.value}>
                            {o.label}
                          </option>
                        ))}
                      </>
                    ))}
                  </>
                ) : null}
              </FieldSelect>
            </Form>
          );
        }}
      </FinalForm>
    </div>
  );
}

ListingPicker.propTypes = {
  className: PropTypes.string,
  listings: PropTypes.arrayOf(propTypes.ownListing),
  onListingChange: PropTypes.func,
  defaultValue: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
};

export default memo(ListingPicker);
