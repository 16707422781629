import React from 'react';
import { compose } from 'redux';
import { ResponsiveImage } from '../../components';
import { formatMoney } from '../../util/currency';

import { ensurePrice } from 'util/data';
import { withViewport } from '../../util/contextHelpers';
import { defaultListingCurrency } from '../../util/types';
import css from './MapCard.css';

const priceData = (price, intl) => {
  if (price && defaultListingCurrency.includes(price.currency)) {
    const formattedPrice = formatMoney(intl, price, 0, 0);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};
// const MapCards = ({ pl, intl, reviews, isHorizontalLayout, viewport }) => {
const MapCards = ({ pl, intl }) => {
  // const [isRedirect, setRedirect] = useState(false);
  const title = pl.attributes.title;
  const description = pl.attributes.description;
  // const author = pl.author;
  // const providerName = pl.attributes.publicData.provider_name;
  const images = pl?.images || [];
  // const id = pl.id.uuid;
  // const slug = createSlug(pl.attributes.title);
  // const rating = getRatingFromReviews(reviews);
  // const totalBooking = pl.attributes.publicData?.total_bookings || 0;
  const address = pl.attributes.publicData.location.address;
  const price = pl.attributes.price;

  const currentPrice = ensurePrice(price);
  const { formattedPrice } = priceData(currentPrice, intl);

  return (
    <div className={css.cardListingBox}>
      <div className={css.imageWrap}>
        <ResponsiveImage
          src={images[0]}
          alt={title}
          image={images[0]}
          variants={['scaled-small', 'square-small2x']}
        />
      </div>
      <div className={css.informationWrapper}>
        <div className={css.cardTitle}>{title}</div>
        <div className={css.cardLocation}>
          <svg
            width="10"
            height="10"
            viewBox="0 0 8 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.68532 9.77716C3.02599 9.21276 2.41485 8.59401 1.85828 7.92738C1.02307 6.92627 0.0312505 5.43534 0.0312505 4.01462C0.0308882 3.28904 0.245018 2.57966 0.64654 1.97626C1.04806 1.37286 1.61893 0.902558 2.28689 0.624876C2.95485 0.347194 3.68989 0.274614 4.39897 0.416321C5.10805 0.558028 5.7593 0.907652 6.27031 1.42095C6.61053 1.76086 6.8802 2.16512 7.06371 2.61032C7.24721 3.05552 7.34091 3.53283 7.33939 4.01462C7.33939 5.43534 6.34757 6.92627 5.51235 7.92738C4.95579 8.59401 4.34465 9.21276 3.68532 9.77716ZM3.68532 2.44301C3.26998 2.44301 2.87166 2.60859 2.57797 2.90332C2.28428 3.19806 2.11929 3.5978 2.11929 4.01462C2.11929 4.43143 2.28428 4.83117 2.57797 5.12591C2.87166 5.42064 3.26998 5.58622 3.68532 5.58622C4.10066 5.58622 4.49898 5.42064 4.79267 5.12591C5.08636 4.83117 5.25135 4.43143 5.25135 4.01462C5.25135 3.5978 5.08636 3.19806 4.79267 2.90332C4.49898 2.60859 4.10066 2.44301 3.68532 2.44301Z"
              fill="#0058FA"
            />
          </svg>
          <span className={css.cardAddress}>{address}</span>
        </div>
        <div className={css.description}>{description}</div>
        <div className={css.price}>{formattedPrice}</div>
      </div>
    </div>
  );
};

export default compose(withViewport)(MapCards);
