import React from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { ListingLink } from '../../components';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { EditListingDescriptionForm } from '../../forms';
import config from '../../config';
import { CurrentUserContext } from 'contexts/currentUserContext';

import css from './EditListingDescriptionPanel.css';
import { LISTING_TYPES } from 'util/constants';

const getCustomFieldValues = (category, values) => {
  const customFields = config.custom.categoryBasedFields
    .find(cc => cc.key === category)
    ?.fields.map(i => i.key);
  const customFieldsValues = {};
  customFields &&
    customFields.forEach(item => {
      customFieldsValues[item] = values[item];
    });

  return customFieldsValues;
};

const titles = {
  [LISTING_TYPES.LISTING]: 'EditListingDescriptionPanel.createListingTitle',
  [LISTING_TYPES.ENQUIRY]: 'EditListingDescriptionPanel.createEnquiryTitle',
  [LISTING_TYPES.COMPANY]: 'EditListingDescriptionPanel.createCompanyTitle',
  [LISTING_TYPES.CLASS]: 'EditListingDescriptionPanel.createClassTitle',
  [LISTING_TYPES.PRODUCT]: 'EditListingDescriptionPanel.createProductTitle',
  [LISTING_TYPES.EVENT]: 'EditListingDescriptionPanel.createEventTitle',
  [LISTING_TYPES.MEMBERSHIP]: 'EditListingDescriptionPanel.createMembership',
  [LISTING_TYPES.FACILITY]: 'EditListingDescriptionPanel.createFacilityTitle',
  [LISTING_TYPES.FREELANCER]: 'EditListingDescriptionPanel.createFreelancerTitle',
  [LISTING_TYPES.ACADEMY]: 'EditListingDescriptionPanel.createAcademicTitle',
  [LISTING_TYPES.COURSES]: 'EditListingDescriptionPanel.createCoursesTitle',
  [LISTING_TYPES.EDUCATION_COURSES]: 'EditListingDescriptionPanel.createCoursesTitle',
  [LISTING_TYPES.HOURLY_SESSION]: 'EditListingDescriptionPanel.createListingTitle',
  [LISTING_TYPES.TUTOR]: 'EditListingDescriptionPanel.createTutorTitle',
  [LISTING_TYPES.PLAYER_PROFILE]: 'EditListingDescriptionPanel.createPlayerProfile',
  [LISTING_TYPES.REMOTE_TUTOR]: 'EditListingDescriptionPanel.createAcademicTitle',
  [LISTING_TYPES.PLAYER_PROFILE]: 'EditListingDescriptionPanel.createProfileTitle',
};

const EditListingDescriptionPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    listingType,
    isEducationSite,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { price } = currentListing.attributes;
  const { description, title, publicData } = currentListing.attributes;
  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const {
    kids_promotions,
    category,
    sub_category,
    // provider_name,
    duration,
    group_size,
    activity_levels,
    hosted_in,
    treatment_room,
    time_zone,
    video_chat_available,
    max_capacity,
    delivery_options,
    new_product,
    warranty,
    transferable,
    companyName,
    bankDetails,
    payment_method,
    week_day_available,
    times_of_day,
    times_per_week,
    add_time_days,
    job_request_type,
    listing_mode,
    level_mode,
    level_group,
    age_group,
    // tennis_category,
    companyEmail,
    facebookLink,
    // twitterLink,
    instagramLink,
    webLink,
    linkedinLink,
    // whatsappNumber,
    unique_features,
    qualifications,
    why_choose_us,
    about_location,
    subjects_offered,
    wallpaper_text,
    work_experience,
    has_subscription,
    has_premium_membership,
    currency,
    membershipPrice,
    subscriptionDescription,
  } = publicData;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingDescriptionPanel.title"
      values={{
        listingTitle: <ListingLink listing={listing}>{title}</ListingLink>,
      }}
    />
  ) : (
    <FormattedMessage id={titles[listingType]} />
  );

  return (
    <div className={classes}>
      <div>
        <div>
          <h1 className={css.title}>{panelTitle} </h1>
        </div>
      </div>
      <CurrentUserContext.Consumer>
        {currentUser => {
          return (
            <EditListingDescriptionForm
              className={css.form}
              initialValues={{
                title,
                description,
                unique_features,
                qualifications,
                why_choose_us,
                about_location,
                subjects_offered,
                wallpaper_text,
                has_subscription,
                has_premium_membership,
                kids_promotions,
                category,
                sub_category:
                  listingType === 'company'
                    ? Array.isArray(sub_category)
                      ? sub_category
                      : [sub_category]
                    : sub_category,
                // provider_name,
                duration,
                group_size,
                activity_levels,
                hosted_in,
                treatment_room,
                time_zone,
                video_chat_available,
                max_capacity,
                delivery_options,
                new_product,
                warranty,
                transferable,
                companyName,
                bankDetails,
                payment_method,
                week_day_available,
                times_of_day,
                times_per_week,
                add_time_days,
                listing_mode,
                level_mode,
                level_group,
                age_group,
                // tennis_category,
                job_request_type,
                companyEmail,
                facebookLink,
                // twitterLink,
                instagramLink,
                webLink,
                linkedinLink,
                // whatsappNumber,
                price,
                currency,
                membershipPrice,
                subscriptionDescription,
                work_experience,
              }}
              saveActionMsg={submitButtonText}
              onSubmit={values => {
                const {
                  title,
                  description,
                  unique_features,
                  qualifications,
                  why_choose_us,
                  about_location,
                  subjects_offered,
                  wallpaper_text,
                  has_subscription,
                  has_premium_membership,
                  kids_promotions,
                  category,
                  sub_category,
                  // provider_name,
                  companyName,
                  bankDetails,
                  payment_method,
                  week_day_available,
                  times_of_day,
                  times_per_week,
                  add_time_days,
                  listing_mode,
                  level_mode,
                  level_group,
                  age_group,
                  // tennis_category,
                  job_request_type,
                  companyEmail,
                  facebookLink,
                  // twitterLink,
                  instagramLink,
                  webLink,
                  linkedinLink,
                  // whatsappNumber,
                  price,
                  currency,
                  work_experience,
                  membershipPrice,
                  subscriptionDescription,
                } = values;
                const priceMaybe = price ? { price } : {};
                const {
                  duration,
                  group_size,
                  activity_levels,
                  hosted_in,
                  treatment_room,
                  time_zone,
                  video_chat_available,
                  max_capacity,
                  delivery_options,
                  new_product,
                  warranty,
                  transferable,
                } = getCustomFieldValues(category, values);

                const updateValues = {
                  title: title.trim(),
                  ...priceMaybe,
                  description,
                  publicData: {
                    companyName,
                    bankDetails,
                    kids_promotions,
                    category,
                    sub_category,
                    // provider_name,
                    duration,
                    group_size,
                    activity_levels,
                    hosted_in,
                    treatment_room,
                    time_zone,
                    video_chat_available,
                    max_capacity,
                    delivery_options,
                    new_product,
                    warranty,
                    transferable,
                    payment_method,
                    week_day_available,
                    times_of_day,
                    times_per_week,
                    add_time_days,
                    listing_mode,
                    level_mode,
                    age_group,
                    level_group,
                    // tennis_category,
                    job_request_type,
                    companyEmail,
                    facebookLink,
                    // twitterLink,
                    instagramLink,
                    webLink,
                    linkedinLink,
                    // whatsappNumber,
                    about_location,
                    subjects_offered,
                    unique_features,
                    qualifications,
                    why_choose_us,
                    wallpaper_text,
                    work_experience,
                    has_subscription,
                    has_premium_membership,
                    currency,
                    membershipPrice,
                    subscriptionDescription,
                  },
                };
                onSubmit(updateValues);
              }}
              onChange={onChange}
              disabled={disabled}
              ready={ready}
              updated={panelUpdated}
              updateInProgress={updateInProgress}
              fetchErrors={errors}
              category={publicData.category}
              subCategory={publicData.subCategory}
              activityLevel={config.custom.activityLevel}
              currentUser={currentUser}
              listingType={listingType}
              isEducationSite={isEducationSite}
            />
          );
        }}
      </CurrentUserContext.Consumer>
    </div>
  );
};

EditListingDescriptionPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditListingDescriptionPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
  listingType: string,
};

export default EditListingDescriptionPanel;
