import React from 'react';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import moment from 'moment';
import { LazyLoadingImages, NamedLink } from '../../components';
import { Form as FinalForm } from 'react-final-form';
import { Form, IconSearch } from '../../components';
import { compose } from 'redux';
import config from '../../config';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { withViewport } from '../../util/contextHelpers';
import Autosuggest from 'react-autosuggest';
import { Tabs, TabList, TabPanel, Tab } from 'react-re-super-tabs';
import css from './SectionSearch.css';
import debounce from 'lodash/debounce';
import IconImage1 from '../../assets/landingPage/icons/golf.png';
import IconImage2 from '../../assets/landingPage/icons/therapy.png';
import IconImage3 from '../../assets/landingPage/icons/surfing.png';
import { LISTING_TYPES } from '../../util/constants';

const categories = config.custom.categories.filter(c => !c.hideFromFilters);
const subCategories = config.custom.subCategoriesFilterOptions.filter(c => !c.hideFromFilters);

const CustomTab = ({ children, isActive, className, borderLightClass, borderDarkClass }) => (
  <div className={css.tabs}>
    <div className={isActive ? css.activeTab : css.inactiveTab}>{children}</div>
  </div>
);

// Date range

// const initialDateRangeValue = (queryParams, paramName) => {
//   const dates = queryParams[paramName];
//   const rawValuesFromParams = !!dates ? dates.split(',') : [];
//   const valuesFromParams = rawValuesFromParams.map(v => parseDateFromISO8601(v));
//   const initialValues =
//     !!dates && valuesFromParams.length === 2
//       ? {
//         dates: { startDate: valuesFromParams[0], endDate: valuesFromParams[1] },
//       }
//       : { dates: null };

//   return initialValues;
// };

// const initialValue = (queryParams, paramName) => {
//   return queryParams[paramName];
// };

class SectionSearchComponent extends React.Component {
  constructor(props) {
    super(props);
    this.handleQueryFocus = this.handleQueryFocus.bind(this);
    this.handleQueryFocusout = this.handleQueryFocusout.bind(this);
    this.onSuggestionSelected = this.onSuggestionSelected.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.state = {
      value: '',
      availabilityQueryParams: {},
      urlQueryParams: '',
    };
  }

  componentDidUpdate() {
    if (this.searchInput) {
      if (this.focusedSearchInput) {
        this.searchInput.focus();
      }
    }
  }

  getSuggestionValue(suggestion) {
    return suggestion.keyword
      ? suggestion.label.split(' | ')[0]
      : suggestion.label
      ? suggestion.label
      : suggestion.title;
  }

  renderSuggestion(suggestion) {
    return <span>{suggestion.label ? suggestion.label : suggestion.title}</span>;
  }

  shouldRenderSuggestions() {
    return true;
  }

  renderSectionTitle(section) {
    return <h3 className={css.suggestionSectionTitle}>{section.title}</h3>;
  }

  getSectionSuggestions(section) {
    return section.options;
  }

  onSuggestionSelected(
    event,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
  ) {
    if (suggestion.keyword) {
      const searchParams = { keywords: suggestionValue, pub_listing_type: 'listing' };
      this.props.history.push(
        createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchParams)
      );
    }

    if (suggestion.explore) {
      this.props.history.push(
        createResourceLocatorString(
          'SearchPage',
          routeConfiguration(),
          {},
          { pub_listing_type: 'listing' }
        )
      );
    }
  }

  searchListings = debounce(value => {
    this.props.handleProviderQueryChange(value);
  }, 900);

  handleProviderQueryChange = (event, { newValue, method }) => {
    this.setState({
      value: newValue,
    });
    this.searchListings(newValue);
  };

  onSuggestionsFetchRequested = ({ value, reason }) => {
    return false;
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  handleQueryFocus(e) {
    this.focusedSearchInput = e.target;
    e.target.focus();
    if (e && e.target) {
      e.target.closest("div[class*='searchQuery']").classList.add('active-search-query');
    }
  }

  handleQueryFocusout(e) {
    this.focusedSearchInput = null;
    if (e && e.target) {
      e.target.blur();
      e.target.closest("div[class*='searchQuery']").classList.remove('active-search-query');
    } else if (document.querySelector("div[class*='active-search-query']")) {
      document
        .querySelector("div[class*='active-search-query']")
        .classList.remove('active-search-query');
    }
  }

  onSubmit(e) {
    e.preventDefault();
    var nameOrCategory = document.getElementById('name-category-query').value;
    const { history, currentSearchParams } = this.props;
    let searchParams = {
      ...currentSearchParams,
      pub_listing_type: 'listing',
    };

    if (nameOrCategory) {
      if (nameOrCategory === 'Wellness') {
        searchParams = {
          ...currentSearchParams,
          pub_listing_type: 'listing',
          pub_category: 'wellness_and_beauty',
        };
      } else if (nameOrCategory === 'Adventure') {
        const adventerSubcategory = [
          'cycling',
          'running',
          'biking',
          'rock climbing',
          'bootcamp',
          'wake boarding',
          'obstacle sports',
          'surfing',
          'kite surfing',
          'skateboarding',
        ];
        searchParams = {
          ...currentSearchParams,
          pub_listing_type: LISTING_TYPES.LISTING,
          pub_sub_category: adventerSubcategory,
        };
      } else if (nameOrCategory === 'Classes') {
        const date = moment().format('YYYY-MM-DD');
        searchParams = {
          ...currentSearchParams,
          pub_listing_type: LISTING_TYPES.CLASS,
          dates: `${date},${date}`,
          times: '00:00,23:59',
        };
      } else if (nameOrCategory === 'Rent Space') {
        searchParams = {
          ...currentSearchParams,
          pub_listing_type: LISTING_TYPES.FACILITY,
        };
      } else if (nameOrCategory === 'Pro Listing') {
        searchParams = {
          ...currentSearchParams,
          pub_listing_type: LISTING_TYPES.LISTING,
        };
      } else {
        searchParams = {
          ...currentSearchParams,
          pub_listing_type: nameOrCategory.toLowerCase(),
        };
      }

      return history.push(
        createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchParams)
      );
    }
    // var categoryMaybe = categories.filter(c => c.label === nameOrCategory);
    // categoryMaybe = categoryMaybe.length > 0 ? categoryMaybe[0].key : null;

    // var subCategoryMaybe = subCategories.filter(c => c.label === nameOrCategory);
    // subCategoryMaybe = subCategoryMaybe.length > 0 ? subCategoryMaybe[0].key : null;

    // nameOrCategory = categoryMaybe
    //   ? { pub_category: categoryMaybe }
    //   : subCategoryMaybe
    //     ? { pub_sub_category: subCategoryMaybe }
    //     : { keywords: nameOrCategory };
    // const { availabilityQueryParams } = this.state;
    // const { currentSearchParams } = this.props;
    // const searchParams = {
    //   ...currentSearchParams,
    //   pub_listing_type: 'listing',
    //   ...nameOrCategory,
    //   ...availabilityQueryParams,
    // };
    // history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchParams));
  }

  getSuggestions(value, suggestedListings) {
    const escapeRegexCharacters = str => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const escapedValue = escapeRegexCharacters(value.trim());

    const regex = new RegExp('^' + escapedValue, 'i');

    const matchedCategories = categories.filter(c => regex.test(c.label));
    const matchedSubcategories = subCategories.filter(sc => regex.test(sc.label));

    const categoryData = [
      {
        key: LISTING_TYPES.LISTING,
        label: 'Pro Listing',
      },
      {
        key: 'adventure',
        label: 'Adventure',
      },
      {
        key: LISTING_TYPES.FACILITY,
        label: 'Rent Space',
      },
      {
        key: LISTING_TYPES.CLASS,
        label: 'Classes',
      },
      {
        key: 'wellness',
        label: 'Wellness',
      },
      {
        key: LISTING_TYPES.COMPANY,
        label: 'Company',
      },
    ];

    const suggestions = [];
    if (value !== '') {
      suggestions.push({
        title: '',
        options: [{ keyword: true, label: value + ' | Search' }],
      });

      if (suggestedListings && suggestedListings.length > 0) {
        suggestions.push({
          title: 'Listings',
          options: suggestedListings,
        });
      }

      if (matchedCategories && matchedCategories.length > 0) {
        suggestions.push({
          title: 'Category',
          options: matchedCategories,
        });
      }

      if (matchedSubcategories && matchedSubcategories.length > 0) {
        suggestions.push({
          title: 'Subcategory',
          options: matchedSubcategories,
        });
      }
    } else if (value === '') {
      if (categories && categories.length > 0) {
        suggestions.push({
          title: '',
          options: [{ explore: true, label: 'Explore ProBuddy' }],
        });

        suggestions.push({
          title: 'Category',
          options: categoryData,
        });
      }
    }

    return suggestions;
  }

  render() {
    const { value } = this.state;
    const { intl, suggestedListings } = this.props;
    const suggestions = this.getSuggestions(value, suggestedListings);
    // const isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;
    // const FILTER_DROPDOWN_OFFSET = isMobileLayout ? -35 : -14;
    const nameOrCategoryPlaceholder = intl.formatMessage({
      id: 'SectionSearch.nameOrCategoryQueryPlaceholder',
    });
    const inputProps = {
      placeholder: nameOrCategoryPlaceholder,
      value,
      onChange: this.handleProviderQueryChange,
      onClick: this.handleQueryFocus,
      onBlur: this.handleQueryFocusout,
      autoComplete: 'off',
      id: 'name-category-query',
      ref: function(inputEl) {
        this.searchInput = inputEl;
      }.bind(this),
    };

    // const handleDateRangeLength = values => {
    //   const hasDates = values && values[dateRangeLengthFilter.paramName];
    //   const { startDate, endDate } = hasDates ? values[dateRangeLengthFilter.paramName] : {};
    //   const start = startDate ? stringifyDateToISO8601(startDate) : null;
    //   const end = endDate ? stringifyDateToISO8601(endDate) : null;
    //   const minDuration =
    //     hasDates && values && values[dateRangeLengthFilter.minDurationParamName]
    //       ? values[dateRangeLengthFilter.minDurationParamName]
    //       : null;
    //   const startTime =
    //     hasDates && values && values['startTime'] ? values && values['startTime'] : null;
    //   const endTime = hasDates && values && values['endTime'] ? values['endTime'] : null;

    //   const restParams = omit(
    //     urlQueryParams,
    //     dateRangeLengthFilter.paramName,
    //     dateRangeLengthFilter.minDurationParamName,
    //     dateRangeLengthFilter.minDurationParamName,
    //     dateRangeLengthFilter.timesParamName
    //   );

    //   const datesMaybe =
    //     start != null && end != null
    //       ? { [dateRangeLengthFilter.paramName]: `${start},${end}` }
    //       : {};
    //   const minDurationMaybe = minDuration
    //     ? { [dateRangeLengthFilter.minDurationParamName]: minDuration }
    //     : {};

    //   const timesMaybe =
    //     startTime && endTime
    //       ? { [dateRangeLengthFilter.timesParamName]: `${startTime},${endTime}` }
    //       : {};

    //   const queryParams = {
    //     ...datesMaybe,
    //     ...minDurationMaybe,
    //     ...timesMaybe,
    //     ...restParams,
    //   };

    //   this.setState({
    //     availabilityQueryParams: queryParams,
    //     urlQueryParams: queryParams,
    //   });
    // };

    // const dateRangeLengthFilter = {
    //   paramName: 'dates',
    //   minDurationParamName: 'minDuration',
    //   timesParamName: 'times',
    //   config: config.custom.dateRangeLengthFilterConfig,
    // };

    // const initialDates = dateRangeLengthFilter
    //   ? initialDateRangeValue(urlQueryParams, dateRangeLengthFilter.paramName)
    //   : null;

    // const initialMinDuration = dateRangeLengthFilter
    //   ? initialValue(urlQueryParams, dateRangeLengthFilter.minDurationParamName)
    //   : null;

    // const initialTimes = dateRangeLengthFilter
    //   ? initialValue(urlQueryParams, dateRangeLengthFilter.timesParamName)
    //   : null;

    // const dateRangeLengthFilterElement =
    //   dateRangeLengthFilter && dateRangeLengthFilter.config.active ? (
    //     <BookingDateRangeLengthFilter
    //       id="SearchFilters.dateRangeLengthFilter"
    //       dateRangeLengthFilter={dateRangeLengthFilter}
    //       datesUrlParam={dateRangeLengthFilter.paramName}
    //       durationUrlParam={dateRangeLengthFilter.minDurationParamName}
    //       onSubmit={handleDateRangeLength}
    //       showAsPopup
    //       contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
    //       initialDateValues={initialDates}
    //       initialDurationValue={initialMinDuration}
    //       initialTimeValues={initialTimes}
    //       isInput={true}
    //       className={css.dateRangeLengthFilter}
    //       onClick={this.handleQueryFocus}
    //     />
    //   ) : null;

    return (
      <div className={css.searchContainer}>
        <div className={css.tabsContainer}>
          <Tabs activeTab="trainers-healthcare">
            <TabList className={css.searchTabs}>
              <Tab component={CustomTab} label="Hire a Pro" id="trainers-healthcare" />
              <Tab component={CustomTab} label="Find Clients" id="find-manage-customers" />
            </TabList>

            <TabList>
              <TabPanel
                component={() => (
                  <div>
                    <h1 className={css.findProvidersTitle}>
                      {/* <FormattedMessage id="SectionSearch.hireProTitle" /> */}
                      Your <span>Sports</span>, <span>Events</span> and <span>Adventure</span>{' '}
                      Marketplace
                    </h1>
                    <h2 className={css.findProvidersInfo}>
                      Book Pro’s and Classes near you or while on the move
                    </h2>

                    <FinalForm
                      onSubmit={this.onSubmit}
                      render={formRenderProps => {
                        const preventFormSubmit = e => e.preventDefault();
                        return (
                          <Form onSubmit={preventFormSubmit} autoComplete="off">
                            <div className={css.search}>
                              <div className={css.searchQuery}>
                                <Autosuggest
                                  suggestions={suggestions}
                                  shouldRenderSuggestions={this.shouldRenderSuggestions}
                                  onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                  onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                  getSuggestionValue={this.getSuggestionValue}
                                  renderSuggestion={this.renderSuggestion}
                                  renderSectionTitle={this.renderSectionTitle}
                                  multiSection={true}
                                  getSectionSuggestions={this.getSectionSuggestions}
                                  onSuggestionSelected={this.onSuggestionSelected}
                                  focusInputOnSuggestionClick={false}
                                  inputProps={inputProps}
                                />
                              </div>

                              <div className={css.searchButton}>
                                <button type="submit" onClick={this.onSubmit}>
                                  <IconSearch />
                                </button>
                              </div>
                            </div>
                          </Form>
                        );
                      }}
                    />
                  </div>
                )}
                id="trainers-healthcare"
              />

              <TabPanel
                component={() => (
                  <div className={css.findCustomers}>
                    <h1 className={css.findCustomersTitle}>
                      <FormattedMessage id="SectionSearch.findProvidersTitle" />
                    </h1>
                    <p>
                      <FormattedMessage id="SectionSearch.createProbuddyListingAttractClients" />
                    </p>
                    <NamedLink name="BecomeVendorPage" className={css.addListingButton}>
                      <FormattedMessage id="SectionAddListingBanner.readyToGetStarted" />
                    </NamedLink>
                  </div>
                )}
                id="find-manage-customers"
              />
            </TabList>
          </Tabs>
        </div>

        <section className={css.ImageSection}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className={css.leftSvg}
            width="355"
            height="295"
            viewBox="0 0 355 295"
            fill="none"
          >
            <path
              d="M168.188 33.0178C112.919 -40.1652 -40.6068 18.1764 11.0804 120.53C62.7676 222.884 -64.1469 297.603 104.22 294.532C272.586 291.461 364.702 245.914 353.955 172.731C343.208 99.5472 223.458 106.201 168.188 33.0178Z"
              fill="#F0F8FA"
            />
          </svg>
          <div className={css.iconImage1}>
            <LazyLoadingImages src={IconImage1} alt="IconImage1" />
          </div>
          <div>
            <LazyLoadingImages src={IconImage2} alt="IconImage2" />
          </div>
          <div>
            <LazyLoadingImages src={IconImage3} alt="IconImage2" />
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className={css.rightSvg}
            width="329"
            height="391"
            viewBox="0 0 329 391"
            fill="none"
          >
            <path
              d="M288.311 188.442C368.808 130.794 310.043 -36.4982 198.161 16.4849C86.2798 69.4681 9.11157 -69.5516 7.81835 112.245C6.5251 294.042 53.1609 394.706 132.44 385.11C211.718 375.514 207.815 246.089 288.311 188.442Z"
              fill="#F0F8FA"
            />
          </svg>
        </section>
      </div>
    );
  }
}

SectionSearchComponent.defaultProps = {};

SectionSearchComponent.propTypes = {};

const SectionSearch = compose(withViewport, injectIntl)(SectionSearchComponent);

export default React.memo(SectionSearch);
