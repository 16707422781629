import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import EditListingExpectationsForm from 'forms/EditListingExpectationsForm/EditListingExpectationsForm';

import css from './EditListingExpectationsPanel.css';

const EditListingExpectationsPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  // const currentListing = ensureOwnListing(listing);

  // const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  // const panelTitle = isPublished ? (
  //   <FormattedMessage
  //     id="EditListingExpectationsPanel.title"
  //     values={{
  //       listingTitle: (
  //         <ListingLink listing={listing}>
  //           <FormattedMessage id="EditListingExpectationsPanel.listingTitle" />
  //         </ListingLink>
  //       ),
  //     }}
  //   />
  // ) : (
  //   <FormattedMessage id="EditListingExpectationsPanel.createListingTitle" />
  // );

  const form = (
    <EditListingExpectationsForm
      className={css.form}
      initialValues={{ expectations: listing.attributes.publicData.expectations }}
      onSubmit={onSubmit}
      onChange={onChange}
      saveActionMsg={submitButtonText}
      disabled={disabled}
      ready={ready}
      updated={panelUpdated}
      updateInProgress={updateInProgress}
      fetchErrors={errors}
    />
  );

  return (
    <div className={classes}>
      <h1 className={css.title}>Expectations</h1>
      {form}
    </div>
  );
};

const { func, object, string, bool } = PropTypes;

EditListingExpectationsPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditListingExpectationsPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingExpectationsPanel;
