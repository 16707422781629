import React from 'react';
import PropTypes from 'prop-types';

import css from './SectionVideo.css';
import Video from './video-compressed.mp4';

const SectionVideo = () => {

    return (
        <div className={css.videoContainer}>
            <video className={css.video} controls playsinline autoPlay muted>
                <source src={Video} />
            </video>
        </div>

    );
};

SectionVideo.defaultProps = { rootClassName: null, className: null};

const { string } = PropTypes;

SectionVideo.propTypes = {
    rootClassName: string,
    className: string,
};

export default SectionVideo;