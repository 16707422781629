import React from 'react';
import { OutSideCloseCard, Modal } from 'components';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { LISTING_TYPES } from 'util/constants';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';
import css from './HeaderContainer.css';
import HeaderSearchIcon from '../../assets/HomePage/HeaderSearchIcon.svg';
import { HomePageLocationSearchForm } from '../../forms';
import config from '../../config';
import { FormattedMessage, injectIntl } from 'react-intl';
import { searchListings } from '../HomePage/HomePage.duck';
import { onSearchFilter, onSearchSubCategory } from './HeaderContainer.duck';
import HeaderTabs from './HeaderTabs';
import classNames from 'classnames';
import { withViewport } from '../../util/contextHelpers';
import ProbuddyLogo from '../../assets/HomePage/ProbuddylogoWhite.svg';
import filterIcon from '../../assets/HomePage/filterIcon.svg';
import closeIcon from '../../assets/HomePage/closeIcon.svg';
import { subCategoriesFilterOptionsTennis } from 'marketplace-custom-config';

const MAX_MOBILE_SCREEN_WIDTH = 1024;
class HeaderSearchComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeSearchTab: false,
      showSearch: false,
      showFilter: false,
      min: 0,
      max: 1000,
      activeFilterTab: LISTING_TYPES.LISTING,
      selectedSubCategory: props.searchActivity,
      selectedLocation: props.searchLocation,
      showTopCategoryPopup: false,
      showLocationPopup: false,
    };
    this.handleProviderQueryChange = this.handleProviderQueryChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onSelectSearchTab = this.onSelectSearchTab.bind(this);
    this.resetPage = this.resetPage.bind(this);
    this.getQueryParameters = this.getQueryParameters.bind(this);
  }

  onSelectSearchTab(searchTab) {
    this.setState({ activeSearchTab: searchTab, showTopCategoryPopup: true, showSearch: true });
    this.props.handleHeaderSearchBar(true);
    this.props.handleOpacityChange(true);
  }

  handleSubmit = values => {
    const { searchActivity, searchLocation } = values;
    this.setState({
      activeSearchTab: false,
      showSearch: false,
      showTopCategoryPopup: false,
      selectedSubCategory: searchActivity.id ? null : searchActivity,
      selectedLocation: searchLocation,
    });
    this.props.onSearchFilter(values);
    this.props.onChangeActivity(searchActivity);
    const params = this.getQueryParameters();
    const { search, selectedPlace } = values.searchLocation
      ? searchLocation
      : {
          predictions: [],
          search: '',
          selectedPlace: {
            address: '',
          },
        };
    const { origin, bounds } = selectedPlace ? selectedPlace : {};
    const originMaybe = config.sortSearchByDistance ? { origin } : {};
    const searchParams = {
      ...params,
      ...originMaybe,
      pub_sub_category:
        searchActivity && searchActivity.key
          ? searchActivity.key
          : searchActivity.title
          ? null
          : searchActivity,
      keywords: searchActivity && searchActivity?.id ? searchActivity.title : null,
      address: search ? search : null,
      bounds: bounds ? bounds : null,
    };
    this.props.onSearch(searchParams);
    this.props.handleHeaderSearchBar(false);
  };

  getQueryParameters = () => {
    const { currentSearchParams } = this.props;
    const { search, selectedPlace } = this.props.searchLocation || {
      predictions: [],
      search: '',
      selectedPlace: {
        address: '',
      },
    };
    const { origin, bounds } = selectedPlace ? selectedPlace : {};
    const originMaybe = config.sortSearchByDistance ? { origin } : {};
    const searchParams = {
      ...currentSearchParams,
      ...originMaybe,
      pub_listing_type: this.state.activeFilterTab,
      pub_sub_category:
        (this.state.selectedSubCategory && this.state.selectedSubCategory.key) ||
        (this.props.searchActivity && this.props.searchActivity.key) ||
        null,
      address: search ? search : null,
      bounds: bounds ? bounds : null,
    };
    return searchParams;
  };

  onTabChangeSearch = tab => {
    const params = this.getQueryParameters();
    const searchParams = {
      ...params,
      pub_listing_type: tab,
    };
    this.props.onSearch(searchParams);
  };

  handleProviderQueryChange(providerQueryValue) {}

  resetPage() {
    this.props.history.push(
      createResourceLocatorString(
        this.props.isEducationSite
          ? 'EducationPage'
          : this.props.isTennisSite
          ? 'TennisPage'
          : 'HomePage',
        routeConfiguration(),
        {}
      )
    );
  }
  render() {
    const { showTopCategoryPopup, activeSearchTab } = this.state;
    const {
      searchActivity,
      searchLocation,
      searchSelectedListing,
      currentTab,
      viewport,
      rootClassName,
      hideOverlay,
      isEducationSite,
      isTennisSite,
    } = this.props;

    return (
      <div className={css.HeaderSearchComponentRoot}>
        {viewport.width > MAX_MOBILE_SCREEN_WIDTH && showTopCategoryPopup ? (
          <div className={css.overlayHeader} />
        ) : null}
        {viewport.width > MAX_MOBILE_SCREEN_WIDTH && activeSearchTab ? (
          <div className={css.tabWrapper}>
            <HeaderTabs
              className={css.headerTab}
              initialTab={this.state.activeFilterTab}
              showTopCategoryPopup={showTopCategoryPopup}
              onChangeTab={tab => {
                currentTab(tab);
                this.onTabChangeSearch(tab.id);
                this.setState({
                  activeFilterTab: tab.id,
                  showTopCategoryPopup: false,
                  showLocationPopup: true,
                });
                this.props.handleOpacityChange(false);
              }}
            />
            <div className={css.topCategoryWrapper}>
              <OutSideCloseCard
                show={showTopCategoryPopup}
                onClickOutside={() => {
                  this.setState({ showTopCategoryPopup: false });
                  this.props.handleOpacityChange(false);
                }}
                className={css.showTopCategoryPopup}
              >
                <div className={css.tabPopup}>
                  <FormattedMessage id="HomePage.categorySelection" />
                </div>
              </OutSideCloseCard>
            </div>
          </div>
        ) : null}
        <div
          className={classNames(css.searchComponent, {
            [css.searchComponentActive]: activeSearchTab !== '',
            [css.subSectionActive]: activeSearchTab === '',
            [css.topHeaderSearchContainerActive]: activeSearchTab !== '',
          })}
        >
          {viewport.width < MAX_MOBILE_SCREEN_WIDTH && activeSearchTab ? (
            <div
              className={classNames(rootClassName || css.mobileTopHeader, {
                [css.activeSearchCard]: this.state.showSearch,
                [css.mobileTopHeaderEdu]: isEducationSite,
                [css.mobileTopHeaderTen]: isTennisSite,
              })}
            >
              {showTopCategoryPopup && (
                <div className={hideOverlay ? css.hideTabOverlay : css.tabOverlay} />
              )}
              <div
                className={css.logoContainer}
                onClick={() => {
                  this.setState({
                    activeSearchTab: false,
                    showSearch: false,
                    showTopCategoryPopup: false,
                  });
                }}
              >
                <img src={ProbuddyLogo} onClick={() => this.resetPage()} alt="ProbuddyLogo" />
              </div>

              {!activeSearchTab && (
                <div
                  className={classNames(css.mobileTopHeaderSearchContainer, {
                    // [css.topHeaderSearchContainerActive]: activeSearchTab,
                  })}
                >
                  <div
                    className={css.mobileSearchWrapper}
                    onClick={() => {
                      this.onSelectSearchTab('anyWhere');
                    }}
                  >
                    <div>
                      <img src={HeaderSearchIcon} alt="HeaderSearchIcon" />
                    </div>
                    <div className={css.mobileSearchContent}>
                      <div className={css.toDoText}>
                        <FormattedMessage id="HomePageHeaderSection.whatToDo" />
                      </div>
                      <div className={css.searchLinkContainer}>
                        <div
                          className={css.mobileSearchLink}
                          onClick={() => {
                            this.onSelectSearchTab('anyWhere');
                          }}
                        >
                          <FormattedMessage id="HomePageHeaderSection.AnyWhere" />
                        </div>
                        {/* <span className={css.divider} /> */}
                        <div
                          className={css.mobileSearchLink}
                          onClick={() => {
                            this.onSelectSearchTab('anyActivity');
                          }}
                        >
                          <FormattedMessage id="HomePageHeaderSection.AnyActivity" />
                        </div>
                        {/* <span className={css.divider} /> */}
                        <div
                          className={css.mobileSearchLink}
                          onClick={() => this.onSelectSearchTab({ activeSearchTab: 'anyRating' })}
                        >
                          <FormattedMessage id="HomePageHeaderSection.AnyListing" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div onClick={this.props.OpenFilterPopup}>
                    <img src={filterIcon} alt={' '} />
                  </div>
                </div>
              )}
              {activeSearchTab && (
                <div className={css.mobileTabContainer}>
                  <div
                    className={css.closeIcon}
                    onClick={() => {
                      this.setState({
                        activeSearchTab: false,
                        showSearch: false,
                        showTopCategoryPopup: false,
                      });
                    }}
                  >
                    <img src={closeIcon} alt={'close'} />
                  </div>
                  <HeaderTabs
                    initialTab={this.state.activeFilterTab}
                    onChangeTab={tab => {
                      currentTab(tab);
                      this.onTabChangeSearch(tab);
                      this.setState({
                        activeFilterTab: tab,
                        showSearch: true,
                      });
                      this.setState({ showTopCategoryPopup: false });
                      // this.props.searchModalOpen(true);
                      this.setState({ showTopCategoryPopup: false, showSearch: true });
                    }}
                  />
                  <div className={css.topCategoryWrapper}>
                    <OutSideCloseCard
                      show={showTopCategoryPopup}
                      onClickOutside={() => {
                        this.props.handleOpacityChange(true);
                        this.setState({ showTopCategoryPopup: false, showSearch: true });
                        // this.props.searchModalOpen(true);
                      }}
                      className={css.showTopCategoryPopup}
                    >
                      <div className={css.tabPopup}>
                        <FormattedMessage id="HomePage.categorySelection" />
                      </div>
                    </OutSideCloseCard>
                  </div>
                </div>
              )}
              <Modal
                isOpen={this.state.showSearch}
                onClose={() => {
                  this.setState({
                    showSearch: false,
                    showTopCategoryPopup: false,
                    // activeSearchTab: false,
                  });
                  this.props.handleOpacityChange(false);
                  // this.props.searchModalOpen(false);
                }}
                id="filterModal"
                onManageDisableScrolling={() => {}}
                hideCloseBtn={true}
                overlayTab={showTopCategoryPopup}
                className={css.modalSearchBar}
              >
                <HomePageLocationSearchForm
                  className={classNames(css.searchLink)}
                  changeOpacity={value => {
                    this.props.handleOpacityChange(value);
                  }}
                  currentOfSet={
                    this.formContainer?.current && this.formContainer.current.offsetLeft
                  }
                  isMobileLayout={true}
                  showLocationPopup={this.state.showLocationPopup}
                  desktopInputRoot={classNames(css.headerSearchWithLeftPadding)}
                  getSuggestedListing={this.props.searchListings}
                  onSubmit={this.handleSubmit}
                  initialValues={{
                    searchActivity: searchActivity,
                    searchLocation: this.state.selectedLocation,
                    selectedListing: searchSelectedListing,
                  }}
                  intl={this.props.intl}
                  history={this.props.history}
                  suggestedListings={this.props.suggestedListings}
                  handleProviderQueryChange={this.handleProviderQueryChange}
                  PromoCategories={
                    isEducationSite
                      ? config.custom.subCategoriesFilterOptionsEducational
                      : isTennisSite
                      ? subCategoriesFilterOptionsTennis
                      : config.custom.PromoCategories
                  }
                  isEducationSite={isEducationSite}
                  // headerConatinerRef={this.formContainer}
                />
              </Modal>
            </div>
          ) : viewport.width > MAX_MOBILE_SCREEN_WIDTH && activeSearchTab ? (
            <div
              className={classNames({
                [css.searchRatingCardActive]: activeSearchTab !== '',
              })}
            >
              <HomePageLocationSearchForm
                className={classNames(css.searchLink)}
                changeOpacity={value => {
                  this.props.handleOpacityChange(value);
                }}
                showLocationPopup={this.state.showLocationPopup}
                desktopInputRoot={classNames(css.headerSearchWithLeftPadding)}
                onSubmit={this.handleSubmit}
                getSuggestedListing={this.props.searchListings}
                initialValues={{
                  searchActivity: searchActivity,
                  searchLocation: searchLocation ? searchLocation : null,
                  selectedListing: searchSelectedListing,
                }}
                intl={this.props.intl}
                history={this.props.history}
                suggestedListings={this.props.suggestedListings}
                handleProviderQueryChange={this.handleProviderQueryChange}
                PromoCategories={
                  isEducationSite
                    ? config.custom.subCategoriesFilterOptionsEducational
                    : isTennisSite
                    ? subCategoriesFilterOptionsTennis
                    : config.custom.PromoCategories
                }
                isEducationSite={isEducationSite}
              />
            </div>
          ) : (
            <div
              className={css.filterSearchWrapper}
              onClick={() => {
                this.onSelectSearchTab('anyWhere');
                this.props.handleActiveSearchTab('anywhere');
              }}
            >
              <div className={css.searchTitleText}>
                <FormattedMessage id="HomePageHeaderSection.startYourSearch" />
              </div>
              <div className={css.searchIconLink}>
                <img src={HeaderSearchIcon} alt="HeaderSearchIcon" />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    suggestedListings,
    searchActivity,
    searchLocation,
    searchSelectedListing,
  } = state.HeaderSection;
  return {
    suggestedListings,
    searchActivity,
    searchLocation,
    searchSelectedListing,
  };
};

const mapDispatchToProps = dispatch => ({
  searchListings: searchParams => dispatch(searchListings(searchParams)),
  onSearchFilter: searchFilters => dispatch(onSearchFilter(searchFilters)),
  onSearchSubCategory: searchSubCategory => dispatch(onSearchSubCategory(searchSubCategory)),
});

const HeaderSearch = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
  withViewport
)(HeaderSearchComponent);

export default HeaderSearch;
