import React from 'react';
import PropTypes from 'prop-types';
import { propTypes } from 'util/types';
import { ensureListing } from 'util/data';
import { createSlug } from 'util/urlHelpers';
import { categories } from 'marketplace-custom-config';
import { AvatarMedium, NamedLink } from 'components/index';
import { IconMessage } from 'components';
import IconWhatsapp from 'components/IconWhatsapp/IconWhatsapp';
import css from './EnquirySearchCard.css';

function EnquirySearchCard({ listing, setActiveListing }) {
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', publicData = {}, description } = currentListing.attributes;
  const slug = createSlug(title);
  const { category, sub_category } = publicData;
  const customCategory = categories.find(c => c.key === category);
  const customSubCategory = customCategory && customCategory.subCategories.find(subc => subc.key === sub_category);
  const authorPublic = listing.author.attributes.profile.publicData || {};
  const contactNumber = authorPublic.phoneNumber || ''

  return (
    <NamedLink name="ListingPage" params={{ id, slug }} className={css.listingLink}>
      <div
        className={css.main}
        onMouseEnter={() => setActiveListing(currentListing.id)}
        onMouseLeave={() => setActiveListing(null)}
      >
        <div className={css.authourDetail}>
          <AvatarMedium className={css.avatar} user={currentListing.author} disableProfileLink />
        </div>
        <h3 className={css.title}>{title}</h3>
        <div className={css.categories}>
          <div className={`${css.category} ${css.sub}`}>{customSubCategory && customSubCategory.label}</div>
          <div className={`${css.category}`}>{customSubCategory && customCategory.label}</div>
        </div>
        <div className={css.description}>{description}</div>
      </div>
      <div className={css.messageWrap}>
        <div className={css.message}>
          <IconMessage /> <span className={css.messageLabel}>Message</span>
        </div>
        {
          contactNumber ? <div className={css.IconWhatsapp}>
            <IconWhatsapp />
          </div> : null
        }

      </div>

    </NamedLink>
  );
}

EnquirySearchCard.propTypes = {
  listing: propTypes.listing,
  setActiveListing: PropTypes.func,
};

export default EnquirySearchCard;
