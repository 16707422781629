import React from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import {
  maxLength,
  required,
  composeValidators,
  moneySubUnitAmountAtLeast,
} from '../../util/validators';
import {
  Form,
  Button,
  FieldMultipleSelection,
  FieldCurrencyInput,
  CustomFieldTextInput,
  FieldSelectOutline,
} from '../../components';
import config from '../../config';
import Switch from 'react-switch';

import css from './EditListingDescriptionForm.css';
import { formatMoney } from '../../util/currency';
import { LISTING_TYPES } from 'util/constants';
import { withViewport } from '../../util/contextHelpers';
import { activityLevel, educationCategoryField } from 'marketplace-custom-config';
import { languageFilterConfig } from 'marketplace-custom-config';
import {
  TITLE_MAX_LENGTH,
  WALLPAPER_TITLE_MAX_LENGTH,
  descriptionMessages,
  descriptionPlaceholderMessages,
  listingMode,
  levelMode,
  tennisCategories,
  currencyType,
  minPrice,
  editListingSvg,
  checkMark,
  ageGroup,
  levelModeClass,
} from './EditListingDescriptionForm.data';

class EditListingDescriptionFormComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      category: props.category,
      subCategories: [],
      subLevel: [],
      subLevelData: [],
      categoryBasedCustomFields: [],
      selectedListingType: '',
      selectedKidsPromotion: '',
      selectedSubCategories: [],
      selectedSubLevels: [],
      showAllSubCategories: false,
      showAllLevels: false,
      // selectedListingMode: [],
      selectedListingMode: '',
      selectedLevelMode: '',
      selectedAge: '',
      selectedTennisCategory: [],
      selectedActivityLevel: '',
      selectedHostedIn: '',
      currencies: config.currency,
      hasSubscription: false,
      listingCategories: [],
      ageCategories: [],
      isEducationSite: false,
      subcategoriesData: [],
      filteredSubLevelOptions: [],
    };

    this.setSelectedListingType = this.setSelectedListingType.bind(this);
    this.setselectedKidsPromotion = this.setSelectedKidsPromotion.bind(this);
    this.setSelectedSubCategories = this.setSelectedSubCategories.bind(this);
    this.setSelectedSubLevels = this.setSelectedSubLevels.bind(this);
    this.setSelectedListingMode = this.setSelectedListingMode.bind(this);
    this.setSelectedLevelMode = this.setSelectedLevelMode.bind(this);
    this.setSelectedAge = this.setSelectedAge.bind(this);
    this.setSelectedActivityLevel = this.setSelectedActivityLevel.bind(this);
    this.setSelectedHostedIn = this.setSelectedHostedIn.bind(this);
    this.handleListingChange = this.handleListingChange.bind(this);
    this.errorMessage = false;
  }

  setSelectedListingType = key => this.setState({ ...this.state, selectedListingType: key });
  setSelectedAge = key => this.setState({ ...this.state, selectedAge: key });

  setSelectedKidsPromotion = key => this.setState({ ...this.state, selectedKidsPromotion: key });

  setSelectedSubCategories(subCategory) {
    let updatedVal = [...this.state.selectedSubCategories, subCategory];
    const selectedType = this.state.selectedSubCategories?.includes(subCategory)
      ? updatedVal.filter(e => e !== subCategory)
      : [...updatedVal];
    this.setState(prev => ({ ...prev, selectedSubCategories: selectedType }));
    return selectedType;
  }
  setSelectedSubLevels(subCategory) {
    let updatedVal = [...this.state.selectedSubLevels, subCategory];
    const selectedType = this.state.selectedSubLevels?.includes(subCategory)
      ? updatedVal.filter(e => e !== subCategory)
      : [...updatedVal];
    this.setState(prev => ({ ...prev, selectedSubLevels: selectedType }));
    return selectedType;
  }

  setSelectedListingMode = mode => {
    this.setState({ selectedListingMode: mode });
    return mode;
  };

  setSelectedLevelMode = mode => {
    this.setState({ selectedLevelMode: mode });
    return mode;
  };

  setSelectedAge = mode => {
    this.setState({ selectedAge: mode });
    return mode;
  };

  setSelectedTennisCategory = mode => {
    this.setState({ selectedTennisCategory: mode });
    return mode;
  };
  // setSelectedListingMode = mode => {
  //   let updatedVal = [...this.state.selectedListingMode, mode];

  //   const selectedType = this.state.selectedListingMode?.includes(mode)
  //     ? updatedVal.filter(e => e !== mode)
  //     : [...updatedVal];

  //   this.setState(prev => ({ ...prev, selectedListingMode: selectedType }));

  //   return selectedType;
  // };

  setSelectedActivityLevel = activityLevel =>
    this.setState({ selectedActivityLevel: activityLevel });

  setSelectedHostedIn = HostedIn => this.setState({ selectedHostedIn: HostedIn });

  setErrorMessage = error => (this.errorMessage = error);

  componentDidMount() {
    const isEducationSite = [
      LISTING_TYPES.ACADEMY,
      LISTING_TYPES.COURSES,
      LISTING_TYPES.HOURLY_SESSION,
      LISTING_TYPES.TUTOR,
      LISTING_TYPES.REMOTE_TUTOR,
      LISTING_TYPES.EDUCATION_COURSES,
    ].includes(this.props.listingType);

    this.setState(values => ({
      ...values,
      selectedListingMode: this.props.initialValues.listing_mode,
      selectedLevelMode: this.props.initialValues.level_mode,
      selectedAge: this.props.initialValues.age_group,
      selectedTennisCategory: this.props.initialValues.sub_category,
      selectedActivityLevel: this.props.initialValues.activity_levels,
      selectedHostedIn: this.props.initialValues.hosted_in,
      selectedSubCategories: this.props.initialValues.sub_category,
      selectedSubLevels: this.props.initialValues.level_group,
      selectedListingType: this.props.initialValues.category,
      selectedKidsPromotion: this.props.initialValues.kids_promotions,
      currencies: this.props.initialValues.currency || config.currency,
      hasSubscription: this.props.initialValues.has_subscription || false,
      listingCategories: isEducationSite
        ? config.custom.educationCategories
        : config.custom.categories?.slice(1),
      isEducationSite,
      ageCategories: config.custom.ageGroup,
    }));
    this.handleCategoryChange(this.props.category);
  }

  handleCategoryChange = category => {
    const categoriesConfig = this.state.isEducationSite
      ? config.custom.educationCategories
      : config.custom.categories;
    const categoryBasedCustomFieldsConfig = config.custom.categoryBasedFields;
    const subCategoryConfig = category ? categoriesConfig.find(cc => cc.key === category) : [];
    const subCategories = (subCategoryConfig && subCategoryConfig.subCategories) || [];
    const CustomFieldsConfig = this.state.isEducationSite
      ? educationCategoryField
      : category !== undefined && category !== '' && subCategories.length
      ? categoryBasedCustomFieldsConfig.find(cc => cc.key === category).fields
      : [];
    this.setState({
      category: category,
      subCategories: subCategories,
      categoryBasedCustomFields: CustomFieldsConfig,
      selectedListingType: category,
    });
  };

  handleAgeChange = age => {
    const categoriesConfig = config.custom.ageGroup;
    // const categoryBasedCustomFieldsConfig = config.custom.categoryBasedFields;
    const subCategoryConfig = age ? categoriesConfig.find(cc => cc.key === age) : [];
    const subLevel = (subCategoryConfig && subCategoryConfig.subLevel) || [];
    // const CustomFieldsConfig = this.state.isEducationSite
    //   ? educationCategoryField
    //   : age !== undefined && age !== '' && subCategories.length
    //   ? categoryBasedCustomFieldsConfig.find(cc => cc.key === age).fields
    //   : [];
    this.setState({
      age_group: age,
      subLevel: subLevel,
      // categoryBasedCustomFields: CustomFieldsConfig,
      selectedAge: age,
    });
  };

  handleListingChange = subtest => {
    this.setState({
      ...this.state,
      hasSubscription: !subtest,
    });
  };

  render() {
    const { listingType, viewport, isEducationSite } = this.props;
    const showCompanyName = [LISTING_TYPES.LISTING].some(type => listingType === type);
    const showRequestType = [LISTING_TYPES.ENQUIRY].some(type => listingType === type);
    const showBankDetails = [
      LISTING_TYPES.LISTING,
      LISTING_TYPES.CLASS,
      LISTING_TYPES.EVENT,
      LISTING_TYPES.FACILITY,
      LISTING_TYPES.MEMBERSHIP,
      LISTING_TYPES.COURSES,
      LISTING_TYPES.HOURLY_SESSION,
      LISTING_TYPES.EDUCATION_COURSES,
    ].some(type => listingType === type);

    const optionPaymentMethod = isEducationSite
      ? [
          { key: 'card', label: 'Card payment' },
          { key: 'member', label: 'Membership Number' },
          { key: 'requestOnly', label: 'Request Only' },
        ]
      : !!(listingType === LISTING_TYPES.MEMBERSHIP)
      ? [
          { key: 'card', label: 'Card payment' },
          { key: 'requestOnly', label: 'Request Only' },
        ]
      : [
          { key: 'card', label: 'Card payment' },
          { key: 'bank', label: 'Bank transfer' },
          { key: 'member', label: 'Membership Number' },
          { key: 'cash', label: 'Cash' },
          { key: 'hitpay', label: 'HitPay' },
          { key: 'requestOnly', label: 'Request Only' },
        ];

    const listingTitleLabel =
      listingType === LISTING_TYPES.COMPANY
        ? 'TITLE OF YOUR COMPANY'
        : listingType === LISTING_TYPES.FACILITY
        ? 'TITLE OF YOUR FACILITY'
        : `TITLE OF YOUR ${listingType}`;

    const getNumberOfSubCategoryListings = () => {
      const width = viewport.width;
      if (width <= 550) {
        return 1;
      } else if (width >= 551 && width <= 1199) {
        return 4;
      } else if (width >= 1200 && width <= 1550) {
        return 6;
      } else {
        return 8;
      }
    };

    const subcategoriesData = [];
    this.state.subCategories.map(sc => sc.options.map(type => subcategoriesData.push(type)));

    const subLevelData = [];
    this.state.subLevel.map(sc => sc.options.map(type => subLevelData.push(type)));
    return (
      <FinalForm
        {...this.props}
        render={formRenderProps => {
          const {
            form,
            className,
            disabled,
            ready,
            handleSubmit,
            intl,
            invalid,
            pristine,
            saveActionMsg,
            updated,
            updateInProgress,
            fetchErrors,
          } = formRenderProps;

          const titleRequiredMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.titleRequired',
          });
          const maxLengthMessage = intl.formatMessage(
            { id: 'EditListingDescriptionForm.maxLength' },
            {
              maxLength: TITLE_MAX_LENGTH,
            }
          );

          const maxLengthWallpaperMessage = intl.formatMessage(
            { id: 'EditListingDescriptionForm.maxLength' },
            {
              maxLength: WALLPAPER_TITLE_MAX_LENGTH,
            }
          );

          const descriptionMessage = this.state.isEducationSite
            ? `DESCRIBE YOUR ${listingType} DETAIL`
            : intl.formatMessage({
                id: descriptionMessages[listingType],
              });

          const addTimeDateMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.addTimeDays',
          });

          const numberOfTimesPerWeekMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.numberOfTimesPerWeek',
          });

          const addTimeDatePlaceholderMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.addTimeDatePlaceholderMessage',
          });

          const numberOfTimesPerWeekPlaceholderMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.howManyHoursPlaceholderMessage',
          });

          const addTimeDateRequiredMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.addTimeDateRequired',
          });

          const numberOfTimesPerWeekRequiredMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.numberOfTimesPerWeekRequired',
          });

          const descriptionPlaceholderMessage = this.state.isEducationSite
            ? `Describe your ${listingType}'s detail`
            : intl.formatMessage({
                id: descriptionPlaceholderMessages[listingType],
              });
          const maxLength60Message = maxLength(maxLengthMessage, TITLE_MAX_LENGTH);
          const maxLength40Message = maxLength(
            maxLengthWallpaperMessage,
            WALLPAPER_TITLE_MAX_LENGTH
          );
          const descriptionRequiredMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.descriptionRequired',
          });

          const uniqueFeatureRequiredMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.uniqueFeatureRequiredMessage',
          });

          const qualificationsRequiredMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.qualificationsRequiredMessage',
          });

          const whyChooseUsRequiredMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.whyChooseUsRequiredMessage',
          });

          const aboutLocationRequiredMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.aboutLocationRequiredMessage',
          });

          const sessionsRequiredMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.subjectsOfferedSessionMessage',
          });

          const selectDayOfWeekAvailableRequiredMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.selectDayOfWeekAvailableRequired',
          });

          const selectTimesOfDayRequiredMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.selectTimesOfDayRequired',
          });

          const selectJobRequestTypeRequiredMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.selectJobRequestTypeRequired',
          });

          const { updateListingError, createListingDraftError, showListingsError } =
            fetchErrors || {};
          const errorMessageUpdateListing = updateListingError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingDescriptionForm.updateFailed" />
            </p>
          ) : null;

          // This error happens only on first tab (of EditListingWizard)
          const errorMessageCreateListingDraft = createListingDraftError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingDescriptionForm.createListingDraftError" />
            </p>
          ) : null;

          const errorMessageShowListing = showListingsError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingDescriptionForm.showListingFailed" />
            </p>
          ) : null;

          const classes = classNames(css.root, className);
          const submitReady = (updated && pristine) || ready;
          const submitInProgress = updateInProgress;
          const submitDisabled = invalid || disabled || submitInProgress;
          const linkToCompanyLabel = intl.formatMessage({
            id: 'EditListingDescriptionForm.linkToCompanyLabel',
          });

          const companyNamePlaceholder = intl.formatMessage({
            id: 'EditListingDescriptionForm.companyNamePlaceholder',
          });

          const bankDetailsLabel = intl.formatMessage({
            id: 'EditListingDescriptionForm.bankDetailsLabel',
          });
          const bankDetailsPlaceholder = intl.formatMessage({
            id: 'EditListingDescriptionForm.bankDetailsPlaceholder',
          });

          const selectPaymentmethodLabel = intl.formatMessage({
            id: 'EditListingDescriptionForm.selectPaymentmethodLabel',
          });

          const dayOfWeekAvailableLabel = intl.formatMessage({
            id: 'EditListingDescriptionForm.dayOfWeekAvailable',
          });

          const timesOfDayLabel = intl.formatMessage({
            id: 'EditListingDescriptionForm.timesOfDay',
          });

          //Price
          const priceRequired = required(
            intl.formatMessage({
              id: 'EditListingPricingForm.priceRequired',
            })
          );
          const minPriceRequired = moneySubUnitAmountAtLeast(
            intl.formatMessage(
              {
                id: 'EditListingPricingForm.priceTooLow',
              },
              {
                minPrice: formatMoney(intl, minPrice),
              }
            ),
            config.listingMinimumPriceSubUnits
          );
          const priceValidators = config.listingMinimumPriceSubUnits
            ? composeValidators(priceRequired, minPriceRequired)
            : priceRequired;

          return (
            <Form className={classes} onSubmit={handleSubmit}>
              {errorMessageCreateListingDraft}
              {errorMessageUpdateListing}
              {errorMessageShowListing}
              {/* {listingType !== LISTING_TYPES.PLAYER_PROFILE && (
                <div className={css.editListingTypeWrap}>
                  <label className={css.editListingLabel}>
                    WANT TO FEATURE IN OUR SPECIAL CATEGORY
                  </label>
                  <div className={css.editListingCardWrap}>
                    {config.custom.kidsPromotions?.map(type => (
                      <div
                        className={classNames(
                          css.buttonMain,
                          this.state.selectedKidsPromotion === type.key && css.selected
                        )}
                      >
                        <button
                          id="kids_promotions"
                          name="kids_promotions"
                          type="button"
                          onClick={() => {
                            this.setSelectedKidsPromotion(type.key);
                            form.change('kids_promotions', type.key);
                            this.handleCategoryChange(type.key);
                            this.setState({ selectedSubCategories: [] });
                            // this.setErrorMessage(false);
                          }}
                          // onChange={this.handleCategoryChange}
                          className={css.buttonWrapper}
                        >
                          <div className={css.editListingTypeText}>{type.label}</div>
                          <div className={css.svgWrapper}>{editListingSvg}</div>
                        </button>
                        <div className={css.checkMark}>{checkMark}</div>
                      </div>
                    ))}
                  </div>
                </div>
              )} */}
              {listingType !== LISTING_TYPES.PLAYER_PROFILE && (
                <div className={css.editListingTypeWrap}>
                  <div className={css.editListingTypeHead}>
                    <span className={css.editListingTypeIcon}>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.66669 5H16.6667"
                          stroke="#0058FA"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M3.33331 5.00828L3.34165 4.99902"
                          stroke="#0058FA"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M3.33331 10.0083L3.34165 9.99902"
                          stroke="#0058FA"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M3.33331 15.0083L3.34165 14.999"
                          stroke="#0058FA"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M6.66669 10H16.6667"
                          stroke="#0058FA"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M6.66669 15H16.6667"
                          stroke="#0058FA"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                    <h5 className={css.editListingLabel}>SELECT CATEGORY</h5>
                  </div>
                  <div className={css.editListingCardWrap}>
                    {this.state.listingCategories.map(type => (
                      <div
                        className={classNames(
                          css.buttonMain,
                          this.state.selectedListingType === type.key && css.selected
                        )}
                      >
                        <button
                          id="category"
                          name="category"
                          type="button"
                          onClick={() => {
                            this.setSelectedListingType(type.key);
                            form.change('category', type.key);
                            this.handleCategoryChange(type.key);
                            this.setState({ selectedSubCategories: [] });
                            // this.setErrorMessage(false);
                          }}
                          // onChange={this.handleCategoryChange}
                          className={css.buttonWrapper}
                        >
                          <div className={css.editListingTypeText}>{type.label}</div>
                          <div className={css.svgWrapper}>{editListingSvg}</div>
                        </button>
                        <div className={css.checkMark}>{checkMark}</div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {subcategoriesData &&
                subcategoriesData.length !== 0 &&
                listingType !== LISTING_TYPES.PLAYER_PROFILE && (
                  <div className={css.editListingTypeWrap}>
                    <label className={css.editListingLabel}>SELECT SUB CATEGORY</label>
                    <div className={css.editListingCardWrap}>
                      {subcategoriesData &&
                        subcategoriesData
                          .slice(
                            0,
                            this.state.showAllSubCategories
                              ? subcategoriesData.length
                              : getNumberOfSubCategoryListings()
                          )
                          .map(type => (
                            <div
                              className={classNames(
                                css.buttonMain,
                                this.state.selectedSubCategories?.includes(type.key) && css.selected
                              )}
                            >
                              <button
                                type="button"
                                onClick={() => {
                                  form.change(
                                    'sub_category',
                                    this.setSelectedSubCategories(type.key)
                                  );
                                }}
                                className={css.buttonWrapper}
                              >
                                <div className={css.editListingTypeText}>{type.label}</div>
                                <div className={css.svgWrapper}>{type.icon}</div>
                              </button>
                              <div className={css.checkMark}>{checkMark}</div>
                            </div>
                          ))}
                      {subcategoriesData &&
                        subcategoriesData.length > getNumberOfSubCategoryListings() && (
                          <button
                            type="button"
                            className={css.subCategoriesButton}
                            onClick={() =>
                              this.setState({
                                showAllSubCategories: !this.state.showAllSubCategories,
                              })
                            }
                          >
                            {this.state.showAllSubCategories ? (
                              <FormattedMessage id="CompanyPage.viewProductsLess" />
                            ) : (
                              <FormattedMessage
                                id="CompanyPage.viewProductsMore"
                                values={{
                                  count: subcategoriesData.length,
                                }}
                              />
                            )}
                          </button>
                        )}
                    </div>
                  </div>
                )}
              {listingType !== LISTING_TYPES.PLAYER_PROFILE && (
                <div className={css.editListingTypeWrap}>
                  <div className={css.editListingTypeHead}>
                    <span className={css.editListingTypeIcon}>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M15 15H16.6667M18.3333 15H16.6667M16.6667 15V13.3334M16.6667 15V16.6667"
                          stroke="#0058FA"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M1.66669 9.16663H16.6667"
                          stroke="#0058FA"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M1.66669 14.1666H11.6667"
                          stroke="#0058FA"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M1.66669 4.16663H16.6667"
                          stroke="#0058FA"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                    <h5 className={css.editListingLabel}>LISTING MODE</h5>
                  </div>
                  {/* <label className={css.editListingLabel}>LISTING MODE</label> */}
                  <div className={css.editListingCardWrap}>
                    {listingMode.map(type => (
                      <div
                        className={classNames(
                          css.buttonMain,
                          this.state.selectedListingMode === type.mode && css.selected
                        )}
                      >
                        <div
                          onClick={() => {
                            form.change('listing_mode', this.setSelectedListingMode(type.mode));
                            // setErrorMessage(false);
                          }}
                          className={css.buttonWrapper}
                        >
                          <div className={css.editListingTypeText}>{type.mode}</div>
                          <div className={css.svgWrapper}>{type.svg}</div>
                        </div>
                        <div className={css.checkMark}>{checkMark}</div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {listingType === LISTING_TYPES.PLAYER_PROFILE && (
                <div className={css.editListingTypeWrap}>
                  <div className={css.editListingTypeHead}>
                    <span className={css.editListingTypeIcon}>
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8 1V15"
                          stroke="#0058FA"
                          stroke-width="2"
                          stroke-linecap="round"
                        />
                        <path
                          d="M15 8L1 8"
                          stroke="#0058FA"
                          stroke-width="2"
                          stroke-linecap="round"
                        />
                      </svg>
                    </span>
                    <h5 className={css.editListingLabel}>SELECT LEVEL</h5>
                  </div>
                  <div className={css.editListingCardWrap}>
                    {levelMode.map(type => (
                      <div
                        className={classNames(
                          css.buttonMain,
                          this.state.selectedLevelMode === type.mode && css.selected
                        )}
                      >
                        <div
                          onClick={() => {
                            form.change('level_mode', this.setSelectedLevelMode(type.mode));
                            // setErrorMessage(false);
                          }}
                          className={css.buttonLevelWrapper}
                        >
                          <div className={css.levelButtonWrapper}>
                            <div className={css.levelContent}>
                              {/* <div className={css.svgWrapper}>{type.svg1}</div> */}
                              <div className={css.levelwrapper}>{type.points}</div>
                              {/* <div className={css.svgWrapper}>{type.svg2}</div> */}
                            </div>
                            <div className={css.buttonContent}>
                              <div className={css.editListingTypeLevelText}>{type.mode}</div>
                            </div>
                          </div>
                        </div>
                        {/* <div className={css.checkMark}>{checkMark}</div> */}
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {/* {listingType !== LISTING_TYPES.PLAYER_PROFILE && (
                <div className={css.editListingTypeWrap}>
                  <div className={css.editListingTypeHead}>
                    <span className={css.editListingTypeIcon}>
                      <svg
                        width="24"
                        height="20"
                        viewBox="0 0 24 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.66602 1H17.1673"
                          stroke="#0058FA"
                          stroke-width="2"
                          stroke-linecap="round"
                        />
                        <path
                          d="M1.66602 9.85742H22.3345"
                          stroke="#0058FA"
                          stroke-width="2"
                          stroke-linecap="round"
                        />
                        <path
                          d="M1.66602 18.7148H10.5239"
                          stroke="#0058FA"
                          stroke-width="2"
                          stroke-linecap="round"
                        />
                      </svg>
                    </span>
                    <h5 className={css.editListingLabel}>SELECT AGE GROUP</h5>
                  </div>

                  <div className={css.editListingCardWrap}>
                    {ageGroup.map(type => (
                      <div
                        className={classNames(
                          css.buttonMain,
                          this.state.selectedAge === type.mode && css.selected
                        )}
                      >
                        <div
                          onClick={() => {
                            form.change('age_group', this.setSelectedAge(type.mode));
                            // setErrorMessage(false);
                          }}
                          className={css.buttonWrapper}
                        >
                          <div className={css.editListingTypeText}>{type.mode}</div>
                          <div className={css.svgWrapper}>{type.svg}</div>
                        </div>
                        <div className={css.checkMark}>{checkMark}</div>
                      </div>
                    ))}
                  </div>
                </div>
              )} */}
              {listingType === LISTING_TYPES.CLASS && (
                <div className={css.editListingTypeWrap}>
                  <div className={css.editListingTypeHead}>
                    <span className={css.editListingTypeIcon}>
                      <svg
                        width="24"
                        height="20"
                        viewBox="0 0 24 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.66602 1H17.1673"
                          stroke="#0058FA"
                          stroke-width="2"
                          stroke-linecap="round"
                        />
                        <path
                          d="M1.66602 9.85742H22.3345"
                          stroke="#0058FA"
                          stroke-width="2"
                          stroke-linecap="round"
                        />
                        <path
                          d="M1.66602 18.7148H10.5239"
                          stroke="#0058FA"
                          stroke-width="2"
                          stroke-linecap="round"
                        />
                      </svg>
                    </span>
                    <h5 className={css.editListingLabel}>SELECT AGE GROUP</h5>
                  </div>
                  <div className={css.editListingCardWrap}>
                    {this.state.ageCategories.map(type => (
                      <div
                        className={classNames(
                          css.buttonMain,
                          this.state.selectedAge === type.key && css.selected
                        )}
                      >
                        <button
                          id="age_group"
                          name="age_group"
                          type="button"
                          onClick={() => {
                            this.setSelectedAge(type.key);
                            form.change('age_group', type.key);
                            this.handleAgeChange(type.key);
                            this.setState({ selectedSubLevels: [] });
                          }}
                          className={css.buttonWrapper}
                        >
                          <div className={css.editListingTypeText}>{type.label}</div>
                        </button>
                        <div className={css.checkMark}>{checkMark}</div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {subLevelData && subLevelData.length !== 0 && listingType === LISTING_TYPES.CLASS && (
                <div className={css.editListingTypeWrap}>
                  <label className={css.editListingLabel}>SELECT AGE GROUP</label>
                  <div className={css.editListingCardWrap}>
                    {subLevelData &&
                      subLevelData
                        .slice(
                          0,
                          this.state.showAllLevels
                            ? subLevelData.length
                            : getNumberOfSubCategoryListings()
                        )
                        .map(type => (
                          <div
                            className={classNames(
                              css.buttonMain,
                              this.state.selectedSubLevels?.includes(type.key) && css.selected
                            )}
                          >
                            <button
                              type="button"
                              onClick={() => {
                                form.change('level_group', this.setSelectedSubLevels(type.key));
                              }}
                              className={css.buttonWrapper}
                            >
                              <div className={css.editListingTypeText}>{type.label}</div>
                            </button>
                            <div className={css.checkMark}>{checkMark}</div>
                          </div>
                        ))}
                  </div>
                </div>
              )}
              {listingType === LISTING_TYPES.CLASS && (
                <div className={css.editListingTypeWrap}>
                  <div className={css.editListingTypeHead}>
                    <span className={css.editListingTypeIcon}>
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8 1V15"
                          stroke="#0058FA"
                          stroke-width="2"
                          stroke-linecap="round"
                        />
                        <path
                          d="M15 8L1 8"
                          stroke="#0058FA"
                          stroke-width="2"
                          stroke-linecap="round"
                        />
                      </svg>
                    </span>
                    <h5 className={css.editListingLabel}>SELECT LEVEL</h5>
                  </div>
                  <div className={css.editListingCardWrap}>
                    {levelModeClass.map(type => (
                      <div
                        className={classNames(
                          css.buttonMain,
                          this.state.selectedLevelMode === type.mode && css.selected
                        )}
                      >
                        <div
                          onClick={() => {
                            form.change('level_mode', this.setSelectedLevelMode(type.mode));
                            // setErrorMessage(false);
                          }}
                          className={css.buttonLevelWrapper}
                        >
                          <div className={css.levelButtonWrapper}>
                            <div className={css.levelContent}>
                              <div className={css.levelwrapper}>{type.points}</div>
                            </div>
                            <div className={css.buttonContent}>
                              <div className={css.editListingTypeLevelText}>{type.mode}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {listingType === LISTING_TYPES.PLAYER_PROFILE && (
                <div className={css.editListingTypeWrap}>
                  <div className={css.editListingTypeHead}>
                    <span className={css.editListingTypeIcon}>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.66669 5H16.6667"
                          stroke="#0058FA"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M3.33331 5.00828L3.34165 4.99902"
                          stroke="#0058FA"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M3.33331 10.0083L3.34165 9.99902"
                          stroke="#0058FA"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M3.33331 15.0083L3.34165 14.999"
                          stroke="#0058FA"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M6.66669 10H16.6667"
                          stroke="#0058FA"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M6.66669 15H16.6667"
                          stroke="#0058FA"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                    <h5 className={css.editListingLabel}>SELECT CATEGORY</h5>
                  </div>
                  <div className={css.editListingCardWrap}>
                    {tennisCategories.map(type => (
                      <div
                        className={classNames(
                          css.buttonMain,
                          this.state.selectedTennisCategory === type.label && css.selected
                        )}
                      >
                        <div
                          onClick={() => {
                            this.setSelectedTennisCategory(type.label);
                            form.change('sub_category', [type.key]);
                            // setErrorMessage(false);
                          }}
                          className={css.buttonWrapper}
                        >
                          <div className={css.editListingTypeText}>{type.label}</div>
                          <div className={css.svgWrapper}>{type.svg}</div>
                        </div>
                        <div className={css.checkMark}>{checkMark}</div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              <div className={css.editListingFormWrap}>
                <CustomFieldTextInput
                  type="text"
                  id="title"
                  name="title"
                  label={listingTitleLabel}
                  placeholder="Enter Title"
                  maxLength={TITLE_MAX_LENGTH}
                  validate={composeValidators(required(titleRequiredMessage), maxLength60Message)}
                />

                {(listingType === LISTING_TYPES.COMPANY ||
                  listingType === LISTING_TYPES.FREELANCER ||
                  listingType === LISTING_TYPES.ACADEMY ||
                  listingType === LISTING_TYPES.REMOTE_TUTOR ||
                  listingType === LISTING_TYPES.TUTOR) && (
                  <>
                    <CustomFieldTextInput
                      inputClasses={css.inputRoot}
                      type="text"
                      id="companyEmail"
                      name="companyEmail"
                      label={`YOUR ${listingType} E-MAIL`}
                      placeholder="Enter E-mail"
                    />
                    {/* <CustomFieldTextInput
                      type="text"
                      id="facebookLink"
                      name="facebookLink"
                      label={`YOUR ${listingType} FACEBOOK LINK`}
                      placeholder="Enter Facebook Link"
                    /> */}
                    {/* <CustomFieldTextInput
                      type="text"
                      id="twitterLink"
                      name="twitterLink"
                      label={`YOUR ${listingType} TWITTER LINK`}
                      placeholder="Enter Twitter LINK"
                    /> */}
                    {/* <CustomFieldTextInput
                      type="text"
                      id="instagramLink"
                      name="instagramLink"
                      label={`YOUR ${listingType} INSTAGRAM LINK`}
                      placeholder="Enter Instagram Link"
                    />
                    <CustomFieldTextInput
                      type="text"
                      id="linkedinLink"
                      name="linkedinLink"
                      label={`YOUR ${listingType} LINKEDIN LINK`}
                      placeholder="Enter LinkedIn Link"
                    />
                    <CustomFieldTextInput
                      type="text"
                      id="webLink"
                      name="webLink"
                      label={`YOUR ${listingType} WEBSITE LINK`}
                      placeholder="Enter Website Link"
                    /> */}
                  </>
                )}
              </div>

              {(listingType === LISTING_TYPES.COMPANY ||
                listingType === LISTING_TYPES.FREELANCER ||
                listingType === LISTING_TYPES.ACADEMY ||
                listingType === LISTING_TYPES.REMOTE_TUTOR ||
                listingType === LISTING_TYPES.TUTOR) && (
                <div>
                  <div className={css.editListingTypeWrap}>
                    <div className={css.editListingTypeHead}>
                      <span className={css.editListingTypeIcon}>
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M15 15H16.6667M18.3333 15H16.6667M16.6667 15V13.3334M16.6667 15V16.6667"
                            stroke="#0058FA"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M1.66669 9.16663H16.6667"
                            stroke="#0058FA"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M1.66669 14.1666H11.6667"
                            stroke="#0058FA"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M1.66669 4.16663H16.6667"
                            stroke="#0058FA"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </span>
                      <h5 className={css.editListingLabel}>CURRENCY </h5>
                    </div>
                    {/* <label className={css.editListingLabel}>Currency</label> */}
                    <div className={css.editListingCardWrap}>
                      {currencyType.map(type => (
                        <div
                          className={classNames(
                            css.buttonMain,
                            this.state.currencies === type.currency && css.selected
                          )}
                        >
                          <div
                            onClick={() => {
                              this.setState({ ...this.state, currencies: type.currency });
                              form.change('currency', type.currency);
                            }}
                            className={css.buttonWrapper}
                          >
                            <div className={css.editListingTypeText}>{type.currency}</div>
                            <div className={css.svgWrapper}>{type.symbol}</div>
                          </div>
                          <div className={css.checkMark}>{checkMark}</div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className={css.bookingCurrencyWrapper}>
                    <FieldCurrencyInput
                      type="text"
                      id="price"
                      name="price"
                      label="AVERAGE HOURLY PRICE OF YOUR OFFERS"
                      placeholder="Enter Price Here.."
                      // currencyConfig={config.currencyConfig}
                      currencyConfig={config.formatedCurrencyConfig(this.state.currencies)}
                      validate={priceValidators}
                    />
                  </div>
                </div>
              )}

              {showCompanyName && (
                <div className={css.companyNameWrapper}>
                  <CustomFieldTextInput
                    id="companyName"
                    name="companyName"
                    className={css.companyListing}
                    label={linkToCompanyLabel}
                    placeholder={companyNamePlaceholder}
                  />
                </div>
              )}

              {showBankDetails && (
                <div className={css.bankDetailWrapper}>
                  {!isEducationSite && (
                    <CustomFieldTextInput
                      id="bankDetails"
                      name="bankDetails"
                      className={css.bankDetails}
                      type="textarea"
                      rows="5"
                      label={bankDetailsLabel}
                      placeholder={bankDetailsPlaceholder}
                    />
                  )}

                  <FieldMultipleSelection
                    name="payment_method"
                    id="payment_method"
                    className={css.customField}
                    label={selectPaymentmethodLabel}
                    placeholder={selectPaymentmethodLabel}
                    options={optionPaymentMethod}
                  />
                </div>
              )}

              <CustomFieldTextInput
                className={css.seatsField}
                id={`description`}
                name={`description`}
                type="textarea"
                rows={3}
                label={descriptionMessage}
                placeholder={descriptionPlaceholderMessage}
                validate={composeValidators(required(descriptionRequiredMessage))}
              />

              {(listingType === LISTING_TYPES.COMPANY ||
                listingType === LISTING_TYPES.FREELANCER) && (
                <>
                  <CustomFieldTextInput
                    className={css.seatsField}
                    id={`unique_features`}
                    name={`unique_features`}
                    type="textarea"
                    rows={3}
                    label="Unique Features"
                    placeholder="Describe Your Unique Features"
                    validate={composeValidators(required(uniqueFeatureRequiredMessage))}
                  />

                  <CustomFieldTextInput
                    className={css.seatsField}
                    id={`why_choose_us`}
                    name={`why_choose_us`}
                    type="textarea"
                    rows={3}
                    label="Why Choose Us?"
                    placeholder="Why Choose Us?"
                    validate={composeValidators(required(whyChooseUsRequiredMessage))}
                  />

                  <CustomFieldTextInput
                    className={css.seatsField}
                    id={`about_location`}
                    name={`about_location`}
                    type="textarea"
                    rows={3}
                    label="About the Location"
                    placeholder="About The Location"
                    validate={composeValidators(required(aboutLocationRequiredMessage))}
                  />
                </>
              )}

              {(listingType === LISTING_TYPES.ACADEMY ||
                listingType === LISTING_TYPES.REMOTE_TUTOR ||
                listingType === LISTING_TYPES.TUTOR) && (
                <>
                  <CustomFieldTextInput
                    className={css.seatsField}
                    id={`qualifications`}
                    name={`qualifications`}
                    type="textarea"
                    rows={3}
                    label="Qualifications"
                    placeholder="Describe Your Qualifications"
                    validate={composeValidators(required(qualificationsRequiredMessage))}
                  />

                  <CustomFieldTextInput
                    className={css.seatsField}
                    id={`why_choose_us`}
                    name={`why_choose_us`}
                    type="textarea"
                    rows={3}
                    label="Why Choose Us?"
                    placeholder="Why Choose Us?"
                    validate={composeValidators(required(whyChooseUsRequiredMessage))}
                  />

                  <CustomFieldTextInput
                    className={css.seatsField}
                    id={`subjects_offered`}
                    name={`subjects_offered`}
                    type="textarea"
                    rows={3}
                    label="Subjects Offered"
                    placeholder="Subjects Offered"
                    validate={composeValidators(required(sessionsRequiredMessage))}
                  />
                </>
              )}
              {listingType !== LISTING_TYPES.PLAYER_PROFILE && (
                <CustomFieldTextInput
                  className={css.seatsField}
                  id={`duration`}
                  name={`duration`}
                  type="textarea"
                  rows={2}
                  label={'DURATION'}
                  placeholder="Enter Days"
                  // validate={textRequired}
                />
              )}
              {listingType !== LISTING_TYPES.PLAYER_PROFILE && (
                <CustomFieldTextInput
                  className={css.seatsField}
                  id={`wallpaper_text`}
                  name={`wallpaper_text`}
                  type="textarea"
                  rows={2}
                  label={'WALLPAPER TEXT'}
                  placeholder="Enter Wallpaper Text"
                  maxLength={WALLPAPER_TITLE_MAX_LENGTH}
                  validate={composeValidators(required(maxLengthMessage), maxLength40Message)}
                />
              )}
              {listingType !== LISTING_TYPES.PLAYER_PROFILE && (
                <div className={css.editListingSelect}>
                  <label className={css.editListingSelectText}>ACTIVITY LEVELS</label>
                  <div className={css.editListingSelectWrap}>
                    <button
                      type="button"
                      onClick={() => this.setSelectedActivityLevel('')}
                      className={classNames(
                        css.editListingSelectCard,
                        this.state.selectedActivityLevel === '' && css.selectedActivity
                      )}
                    >
                      <span className={css.editListingSelectCardText}>None</span>
                    </button>
                    {activityLevel.map(level => (
                      <button
                        type="button"
                        onClick={() => {
                          this.setSelectedActivityLevel(level.key);
                          form.change('activity_levels', level.key);
                        }}
                        className={classNames(
                          css.editListingSelectCard,
                          this.state.selectedActivityLevel === level.key && css.selectedActivity
                        )}
                      >
                        <span className={css.editListingSelectCardText}>{level.label}</span>
                      </button>
                    ))}
                  </div>
                </div>
              )}
              {listingType !== LISTING_TYPES.PLAYER_PROFILE && (
                <div className={css.editListingSelect}>
                  <label className={css.editListingSelectText}>HOSTED IN WHICH LANGUAGE ?</label>
                  <div className={css.editListingSelectWrap}>
                    {languageFilterConfig.map(language => (
                      <button
                        type="button"
                        onClick={() => {
                          this.setSelectedHostedIn(language.label);
                          form.change('hosted_in', language.label);
                        }}
                        className={classNames(
                          css.editListingSelectCard,
                          this.state.selectedHostedIn === language.label && css.selectedActivity
                        )}
                      >
                        <span className={css.editListingSelectCardText}>{language.label}</span>
                      </button>
                    ))}
                  </div>
                </div>
              )}
              {/* {listingType !== LISTING_TYPES.PLAYER_PROFILE && (
                <div className={css.editHasSubscription}>
                  <label className={css.editHasSubscriptionText}>
                    Do you want to give discount on this listing?
                  </label>
                  <div className={css.editHasSubscriptionWrap}>
                    <Switch
                      uncheckedIcon={false}
                      checkedIcon={false}
                      onChange={() => {
                        const subtest = this.state.hasSubscription;
                        this.handleListingChange(subtest);
                      }}
                      checked={this.state.hasSubscription}
                    />
                  </div>
                </div>
              )} */}
              <CustomFieldTextInput
                className={css.seatsField}
                id={`has_subscription`}
                name={`has_subscription`}
                type="hidden"
                defaultValue={this.state.hasSubscription}
              />

              {showRequestType && (
                <div className={css.requestTypeWrapper}>
                  <FieldSelectOutline
                    className={css.customField}
                    type="select"
                    name="week_day_available"
                    id="week_day_available"
                    label={dayOfWeekAvailableLabel}
                    validate={composeValidators(required(selectDayOfWeekAvailableRequiredMessage))}
                    multiple
                  >
                    <option key="monday" value="monday">
                      Monday
                    </option>
                    <option key="tuesday" value="tuesday">
                      Tuesday
                    </option>
                    <option key="wednesday" value="wednesday">
                      Wednesday
                    </option>
                    <option key="thursday" value="thursday">
                      Thursday
                    </option>
                    <option key="friday" value="friday">
                      Friday
                    </option>
                    <option key="saturday" value="saturday">
                      Saturday
                    </option>
                    <option key="sunday" value="sunday ">
                      Sunday
                    </option>
                  </FieldSelectOutline>

                  <FieldSelectOutline
                    className={css.customField}
                    type="select"
                    name="times_of_day"
                    id="times_of_day"
                    label={timesOfDayLabel}
                    validate={composeValidators(required(selectTimesOfDayRequiredMessage))}
                    multiple
                  >
                    <option key="mornings" value="mornings">
                      Mornings
                    </option>
                    <option key="afternoons" value="afternoons">
                      Afternoons
                    </option>
                    <option key="evenings" value="evenings">
                      Evenings
                    </option>
                    <option key="nights" value="nights">
                      Nights
                    </option>
                  </FieldSelectOutline>

                  <CustomFieldTextInput
                    id="times_per_week"
                    name="times_per_week"
                    className={css.description}
                    type="Number"
                    label={numberOfTimesPerWeekMessage}
                    placeholder={numberOfTimesPerWeekPlaceholderMessage}
                    validate={composeValidators(required(numberOfTimesPerWeekRequiredMessage))}
                  />

                  <CustomFieldTextInput
                    id="add_time_days"
                    name="add_time_days"
                    className={css.description}
                    type="text"
                    label={addTimeDateMessage}
                    placeholder={addTimeDatePlaceholderMessage}
                    validate={composeValidators(required(addTimeDateRequiredMessage))}
                  />

                  <FieldSelectOutline
                    className={css.customField}
                    type="select"
                    name="job_request_type"
                    id="job_request_type"
                    label={'Request Type'}
                    validate={composeValidators(required(selectJobRequestTypeRequiredMessage))}
                  >
                    <option value="">None</option>
                    <option value="part-time">Part-time ( Individual )</option>
                    <option value="full-time">Full time ( As a Company )</option>
                  </FieldSelectOutline>
                </div>
              )}

              {/* {listingType !== LISTING_TYPES.COMPANY &&
                listingType !== LISTING_TYPES.FREELANCER &&
                listingType !== LISTING_TYPES.ENQUIRY && (
                  <CustomFields categoryBasedCustomFields={this.state.categoryBasedCustomFields} />
                )} */}
              <div className={css.submitButtonContainer}>
                <Button
                  className={css.submitButton}
                  type="submit"
                  inProgress={false}
                  disabled={submitDisabled}
                  ready={submitReady}
                >
                  {saveActionMsg}
                </Button>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

EditListingDescriptionFormComponent.defaultProps = { className: null, fetchErrors: null };

EditListingDescriptionFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  categories: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
  listingType: string,
};

export default compose(injectIntl, withViewport)(EditListingDescriptionFormComponent);
