import React, { Component } from 'react';
import moment from 'moment';
import { bool, func, instanceOf, object, shape, string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { withRouter } from 'react-router-dom';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { types as sdkTypes } from 'util/sdkLoader';
import { pathByRouteName, findRouteByRouteName } from '../../util/routes';
import emailjs, { init } from '@emailjs/browser';
import {
  propTypes,
  LINE_ITEM_NIGHT,
  LINE_ITEM_DAY,
  DATE_TYPE_DATETIME,
  LINE_ITEM_UNITS,
  LINE_ITEM_CUSTOMER_COMMISSION,
  LINE_ITEM_PROVIDER_COMMISSION,
  LINE_ITEM_VOUCHER_AMOUNT,
  LINE_ITEM_CORPORATE_DISCOUNT,
  LINE_ITEM_CARD_SERVICE_FEE,
} from '../../util/types';
import { ensureListing, ensureUser, ensureTransaction, ensureBooking } from '../../util/data';
import { createSlug } from '../../util/urlHelpers';
import { addonLabelIntoCode } from 'util/data';
import { sequence } from 'util/general';
import {
  isTransactionInitiateListingNotFoundError,
  isTransactionInitiateBookingTimeNotAvailableError,
} from '../../util/errors';
import { formatMoney } from '../../util/currency';
import { TRANSITION_ENQUIRE, txHasPassedPaymentPending } from '../../util/transaction';
import {
  AvatarMedium,
  BookingBreakdown,
  NamedLink,
  NamedRedirect,
  Page,
  ResponsiveImage,
  GenericMessage,
  Modal,
} from '../../components';
import { ShareForm, WithoutPaymentForm } from '../../forms';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';

import {
  initiateOrder,
  setInitialValues,
  speculateTransaction,
  confirmPayment,
  sendMessage,
  updateCredits,
  changeVoucherDefaultValue,
} from './CheckoutPage.duck';
import { storeData, storedData, clearData } from './CheckoutPageSessionHelpers';
import { logout } from '../../ducks/Auth.duck';
import css from './CheckoutPage.css';
import { LISTING_TYPES, PAYMENT_TYPES } from 'util/constants';
import { TopbarContainer } from '../../containers';
import { createResourceLocatorString } from 'util/routes';
import { queryPromotedListings } from '../HomePage/HomePage.duck';
import { queryPromotedListingsEdu } from 'containers/EducationPage/EducationPage.duck';

const STORAGE_KEY = 'CheckoutPageWithoutPayment';
const { Money } = sdkTypes;

const initializeOrderPage = (initialValues, routes, dispatch) => {
  const OrderPage = findRouteByRouteName('OrderDetailsPage', routes);

  // Transaction is already created, but if the initial message
  // sending failed, we tell it to the OrderDetailsPage.
  dispatch(OrderPage.setInitialValues(initialValues));
};

const applyAsync = (acc, val) => acc.then(val);
const composeAsync = (...funcs) => x => funcs.reduce(applyAsync, Promise.resolve(x));

export class CheckoutPageWithoutPaymentComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pageData: {},
      dataLoaded: false,
      submitting: false,
      errorUpdateCredits: false,

      //Topbar
      queryParams: null,
      isOpenSearchBar: false,
      showOpacity: '',
      openLeftBar: false,
      currentTab: '',
      selectedSubCategory: props.searchActivity,
      disableFilterScroll: false,
      isScrolling: false,
    };

    this.loadInitialData = this.loadInitialData.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleOrder = this.handleOrder.bind(this);
    this.customPricingParams = this.customPricingParams.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.handleHeaderSearchBar = this.handleHeaderSearchBar.bind(this);
    this.handleOpacityChange = this.handleOpacityChange.bind(this);
    this.onTabChangeSearch = this.onTabChangeSearch.bind(this);
  }

  componentDidMount() {
    document.body.style.overflow = 'unset';
    if (window) {
      init('DkU54piL9T3fDaZV1');
      this.loadInitialData();
    }
  }

  onSearch = searchquery => {
    this.props.history.push(
      createResourceLocatorString(
        this.props.isEducationSite ? 'EducationPage' : 'HomePage',
        routeConfiguration(),
        {}
      )
    );
    this.setState({ queryParams: searchquery });
    setTimeout(() => {
      this.props.isEducationSite
        ? this.props.queryPromotedListingsEdu(searchquery)
        : this.props.queryPromotedListings(searchquery);
    }, 500);
  };

  onTabChangeSearch = searchquery => {
    this.setState({ queryParams: searchquery });
    this.props.isEducationSite
      ? this.props.queryPromotedListingsEdu(searchquery)
      : this.props.queryPromotedListings(searchquery);
  };

  handleHeaderSearchBar = value => {
    this.setState({ isOpenSearchBar: value });
  };

  handleOpacityChange = value => {
    this.setState({ showOpacity: value });
  };

  /**
   * Constructs a request params object that can be used when creating bookings
   * using custom pricing.
   * @param {} params An object that contains bookingStart, bookingEnd and listing
   * @return a params object for custom pricing bookings
   */

  customPricingParams(params) {
    const {
      bookingStart,
      bookingEnd,
      listing,
      quantity,
      priceAddons,
      discountPriceAddons,
      seats,
      withDefaultCredits,
      currentUserIsCorporateUser,
      productBooking,
      ...rest
    } = params;
    const { amount, currency } = listing ? listing.attributes.price : {};

    const unitType = withDefaultCredits ? config.bookingVoucherAmount : config.bookingUnitType;

    const priceAddonsLineItems =
      priceAddons && priceAddons.length
        ? priceAddons.map(addon => {
            if (addon.code) {
              return addon;
            }
            return {
              code: addonLabelIntoCode(addon.label),
              unitPrice: new Money(addon.amount, addon.currency),
              quantity: quantity,
            };
          })
        : [];

    const discountPriceAddonsLineItems =
      discountPriceAddons && discountPriceAddons.length
        ? discountPriceAddons.map(addon => {
            if (addon.code) {
              return addon;
            }
            return {
              code: addonLabelIntoCode(addon.label),
              unitPrice: new Money(addon.amount, addon.currency),
              quantity: quantity,
            };
          })
        : [];

    const corporateAddonsLineItems =
      currentUserIsCorporateUser && unitType !== config.bookingCustomAmount
        ? [
            {
              code: addonLabelIntoCode('corporate user discount'),
              unitPrice: new Money((amount * -10) / 100, currency),
              quantity: 1,
            },
          ]
        : [];

    return productBooking
      ? {
          listingId: listing ? listing.id.uuid : '',
          providerCommissionPercentage: 0,
          lineItems: [
            ...priceAddonsLineItems,
            ...discountPriceAddonsLineItems,
            ...corporateAddonsLineItems,
            {
              code: unitType,
              unitPrice: new Money(amount, currency),
              quantity: 1,
            },
          ],
          productBooking: true,
          ...rest,
        }
      : {
          listingId: listing ? listing.id.uuid : '',
          bookingStart,
          bookingEnd,
          providerCommissionPercentage: 0,
          lineItems: [
            ...priceAddonsLineItems,
            ...discountPriceAddonsLineItems,
            ...corporateAddonsLineItems,
            {
              code: unitType,
              unitPrice: new Money(amount, currency),
              units: quantity,
              seats: Number(seats),
            },
          ],
          ...rest,
        };
  }

  /**
   * Load initial data for the page
   *
   * Since the data for the checkout is not passed in the URL (there
   * might be lots of options in the future), we must pass in the data
   * some other way. Currently the ListingPage sets the initial data
   * for the CheckoutPage's Redux store.
   *
   * For some cases (e.g. a refresh in the CheckoutPage), the Redux
   * store is empty. To handle that case, we store the received data
   * to window.sessionStorage and read it from there if no props from
   * the store exist.
   *
   * This function also sets of fetching the speculative transaction
   * based on this initial data.
   */
  loadInitialData() {
    const {
      bookingData,
      bookingDates,
      listing,
      transaction,
      fetchSpeculatedTransaction,
      history,
    } = this.props;

    // Browser's back navigation should not rewrite data in session store.
    // Action is 'POP' on both history.back() and page refresh cases.
    // Action is 'PUSH' when user has directed through a link
    // Action is 'REPLACE' when user has directed through login/signup process
    const hasNavigatedThroughLink = history.action === 'PUSH' || history.action === 'REPLACE';
    const hasDataInProps = bookingData?.productBooking
      ? !!(bookingData && listing) && hasNavigatedThroughLink
      : !!(bookingData && bookingDates && listing) && hasNavigatedThroughLink;
    if (hasDataInProps) {
      // Store data only if data is passed through props and user has navigated through a link.
      storeData(bookingData, bookingDates, listing, transaction, STORAGE_KEY);
    }

    // NOTE: stored data can be empty if user has already successfully completed transaction.
    const pageData = hasDataInProps
      ? bookingData?.productBooking
        ? { orderData: bookingData, bookingData, listing, transaction }
        : { bookingData, bookingDates, listing, transaction }
      : storedData(STORAGE_KEY);

    // Check if a booking is already created according to stored data.
    const tx = pageData ? pageData.transaction : null;
    const isBookingCreated = tx && tx.booking && tx.booking.id;

    const shouldFetchSpeculatedTransaction = bookingData?.productBooking
      ? pageData &&
        pageData.listing &&
        pageData.listing.id &&
        pageData.orderData &&
        !txHasPassedPaymentPending(tx)
      : pageData &&
        pageData.listing &&
        pageData.listing.id &&
        pageData.bookingData &&
        pageData.bookingDates &&
        pageData.bookingDates.bookingStart &&
        pageData.bookingDates.bookingEnd &&
        pageData.bookingData.quantity &&
        !isBookingCreated;

    if (!bookingData?.productBooking && shouldFetchSpeculatedTransaction) {
      const { bookingStart, bookingEnd } = pageData.bookingDates;
      const { quantity, priceAddons, seats } = pageData.bookingData;

      const currentUserIsCorporateUser = !!this.props?.currentUser?.attributes?.profile
        ?.protectedData?.corporate_user;

      // Fetch speculated transaction for showing price in booking breakdown
      // NOTE: if unit type is line-item/units, quantity needs to be added.
      // The way to pass it to checkout page is through pageData.bookingData
      fetchSpeculatedTransaction(
        this.customPricingParams({
          listing: pageData.listing,
          bookingStart,
          bookingEnd,
          quantity,
          priceAddons,
          seats,
          withoutPayment: true,
          withDefaultCredits: !!pageData?.bookingData?.withDefaultCredits,
          currentUserIsCorporateUser,
        })
      );
    }
    if (bookingData?.productBooking && shouldFetchSpeculatedTransaction) {
      fetchSpeculatedTransaction(
        this.customPricingParams({
          listing: listing,
          stockReservationQuantity: 1,
          productBooking: true,
        })
      );
    }

    this.setState({ pageData: pageData || {}, dataLoaded: true });
  }

  handleOrder(handlePaymentParams) {
    const {
      currentUser,
      onInitiateOrder,
      onConfirmPayment,
      onSendMessage,
      onUpdateCredits,
    } = this.props;
    const {
      pageData,
      speculatedTransaction,
      message,
      paymentProofSsUrls,
      credits,
      membership,
      cash,
      kidsName,
    } = handlePaymentParams;
    const storedTx = ensureTransaction(pageData.transaction);

    // Step 1
    const fnRequestOrder = fnParams => {
      return onInitiateOrder(
        {
          ...fnParams,
          withoutPayment: true,
          credits,
          paymentProofSsUrls,
          membership,
          cash,
          kidsName,
          providerCommissionPercentage: 0,
        },
        storedTx.id
      );
    };

    // Step 2
    const fnHandleOrder = fnParams => {
      const order = ensureTransaction(fnParams);
      if (order.id) {
        // Store order.
        const { bookingData, bookingDates, listing } = pageData;
        storeData(bookingData, bookingDates, listing, order, STORAGE_KEY);
        this.setState({ pageData: { ...pageData, transaction: order } });
      }
      const feeAmountCard = order?.attributes?.lineItems.filter(
        item => item.code === LINE_ITEM_CARD_SERVICE_FEE
      );
      const subamount = Number(order?.attributes?.payinTotal?.amount) / 100;
      const feeAmount =
        feeAmountCard && feeAmountCard.length && feeAmountCard[0] && feeAmountCard[0].unitPrice
          ? Number(feeAmountCard[0].unitPrice.amount * 0.05)
          : 0;

      try {
        const templateParams = {
          className: pageData?.listing?.attributes?.title,
          toName: currentUser?.attributes?.profile?.displayName,
          startTime: moment(pageData?.bookingDates?.bookingStart).format('hh:mm a'),
          endTime: moment(pageData?.bookingDates?.bookingEnd).format('hh:mm a'),
          startDate: moment(pageData?.bookingDates?.bookingStart).format('MMMM D'),
          endDate: moment(pageData?.bookingDates?.bookingEnd).format('MMMM D'),
          amount: `${pageData?.listing?.attributes?.price?.amount / 100}.00`,
          subamount: subamount,
          currency: order?.attributes?.payoutTotal?.currency,
          unit: pageData?.bookingData?.quantity,
          seat: pageData?.bookingData?.seats,
          feeAmount: `${Number(feeAmount) / 100}.00`,
          hourText: 'Hours',
          totalAmount: `${subamount + feeAmount}`,
          mailTo: currentUser?.attributes?.email,
          mailText: 'requested to book',
          priceText: 'Price per hour',
        };

        process.env.REACT_APP_ENV === 'production' &&
          emailjs
            .send('service_o59sg3q', 'template_q6uoneb', templateParams, 'DkU54piL9T3fDaZV1')
            .then();
      } catch (e) {
        console.log(e);
      }

      return Promise.resolve({ transactionId: order.id });
    };

    // // Step 3
    const fnConfirmOrder = fnParams => {
      const { transactionId } = fnParams;
      const { bookingDates } = pageData;
      if (credits === 'true') {
        // const provider = pageData && pageData.listing;
        return onUpdateCredits({
          listingId: pageData.listing.id.uuid,
          currentUser: currentUser,
          transactionId: transactionId,
          bookingDates: bookingDates,
        })
          .then(response => {
            return onConfirmPayment(fnParams);
          })
          .catch(e => {
            this.setState({
              errorUpdateCredits: true,
            });
          });
      } else {
        return onConfirmPayment(fnParams);
      }
    };

    // Step 4: send initial message
    const fnSendMessage = fnParams => {
      return onSendMessage({ ...fnParams, message });
    };

    const handleOrderCreation = composeAsync(
      fnRequestOrder,
      fnHandleOrder,
      fnConfirmOrder,
      fnSendMessage
    );

    const tx = speculatedTransaction ? speculatedTransaction : storedTx;

    const priceAddons = tx.attributes.lineItems.filter(item => {
      const filterOutCodes = [
        LINE_ITEM_UNITS,
        LINE_ITEM_CUSTOMER_COMMISSION,
        LINE_ITEM_PROVIDER_COMMISSION,
        LINE_ITEM_VOUCHER_AMOUNT,
        LINE_ITEM_CORPORATE_DISCOUNT,
      ];
      return !filterOutCodes.includes(item.code);
    });

    const currentUserIsCorporateUser = !!this.props?.currentUser?.attributes?.profile?.protectedData
      ?.corporate_user;

    const orderParams = pageData?.bookingData?.productBooking
      ? this.customPricingParams({
          listing: pageData.listing,
          quantity: 1,
          productBooking: true,
          stockReservationQuantity: 1,
        })
      : this.customPricingParams({
          listing: pageData.listing,
          bookingStart: tx.booking.attributes.start,
          bookingEnd: tx.booking.attributes.end,
          quantity: pageData.bookingData ? pageData.bookingData.quantity : null,
          seats: pageData.bookingData ? pageData.bookingData.seats : null,
          priceAddons,
          withDefaultCredits: !!pageData.bookingData?.withDefaultCredits,
          currentUserIsCorporateUser,
          // discountPriceAddons,
        });

    return handleOrderCreation(orderParams);
  }

  handleSubmit(values) {
    if (this.state.submitting) {
      return;
    }
    this.setState({ submitting: true });

    const { history, speculatedTransaction, dispatch, params, currentUser } = this.props;
    const { message, formValues, paymentProofSsUrls } = values;
    const { membership = null, kidsName = null } = formValues;

    const credits = params.credits;

    const { bookingData } = this.state.pageData;

    if (credits === 'true') {
      const { period = 1 } = this.state.pageData.bookingData;
      // set initial data for period equals to 1 week
      const variableDataArray = [
        {
          message,
          paymentProofSsUrls,
          credits: credits,
          pageData: this.state.pageData,
          speculatedTransaction: speculatedTransaction,
          membership,
          kidsName,
        },
      ];
      // if period is bigger, add entries of transaction and pageData with
      // adjusted dates for every extra week
      for (let i = 0; i < period - 1; i++) {
        const booking = { ...speculatedTransaction.booking };
        let bookingDates = { ...this.state.pageData.bookingDates };
        booking.attributes = {
          ...booking.attributes,
          displayEnd: moment(booking.attributes.displayEnd)
            .add(i + 1, 'week')
            .toDate(),
          displayStart: moment(booking.attributes.displayStart)
            .add(i + 1, 'week')
            .toDate(),
          start: moment(booking.attributes.start)
            .add(i + 1, 'week')
            .toDate(),
          end: moment(booking.attributes.end)
            .add(i + 1, 'week')
            .toDate(),
        };

        bookingDates = {
          bookingEnd: moment(bookingDates.bookingEnd)
            .add(i + 1, 'week')
            .toDate(),
          bookingStart: moment(bookingDates.bookingStart)
            .add(i + 1, 'week')
            .toDate(),
        };

        variableDataArray.push({
          message,
          paymentProofSsUrls,
          credits: credits,
          pageData: { ...this.state.pageData, bookingDates },
          speculatedTransaction: {
            ...speculatedTransaction,
            booking: booking,
          },
          membership,
          kidsName,
        });
      }

      sequence(variableDataArray, this.handleOrder)
        .then(res => {
          const { orderId, messageSuccess } = res;
          this.setState({ submitting: false });

          const routes = routeConfiguration();
          const initialMessageFailedToTransaction = messageSuccess ? null : orderId;
          const orderDetailsPath = pathByRouteName('OrderDetailsPage', routes, {
            id: orderId.uuid,
          });
          const initialValues = {
            initialMessageFailedToTransaction,
          };
          !!bookingData.withDefaultCredits &&
            this.props.onchangeVoucherDefaultValue(
              bookingData?.remainingDefalutCurrency,
              currentUser
            );
          initializeOrderPage(initialValues, routes, dispatch);
          clearData(STORAGE_KEY);
          history.push(orderDetailsPath);
        })
        .catch(err => {
          console.error(err);
          this.setState({ submitting: false });
        });
    } else {
      const requestPaymentParams = {
        pageData: this.state.pageData,
        speculatedTransaction,
        message,
        paymentProofSsUrls,
        credits: this.props.params.credits,
        cash: this.props.params.paymentType === PAYMENT_TYPES.cash,
        membership,
        kidsName,
      };

      this.handleOrder(requestPaymentParams)
        .then(res => {
          const { orderId, messageSuccess } = res;
          this.setState({ submitting: false });

          const routes = routeConfiguration();
          const initialMessageFailedToTransaction = messageSuccess ? null : orderId;
          const orderDetailsPath = pathByRouteName('OrderDetailsPage', routes, {
            id: orderId.uuid,
          });
          const initialValues = {
            initialMessageFailedToTransaction,
          };

          !!bookingData.withDefaultCredits &&
            this.props.onchangeVoucherDefaultValue(
              bookingData?.remainingDefalutCurrency,
              currentUser
            );

          initializeOrderPage(initialValues, routes, dispatch);
          clearData(STORAGE_KEY);
          history.push(orderDetailsPath);
        })
        .catch(err => {
          console.error(err);
          this.setState({ submitting: false });
        });
    }
  }

  render() {
    const {
      scrollingDisabled,
      speculateTransactionInProgress,
      speculateTransactionError,
      speculatedTransaction: speculatedTransactionMaybe,
      initiateOrderError,
      intl,
      params,
      currentUser,
    } = this.props;

    // Since the listing data is already given from the ListingPage
    // and stored to handle refreshes, it might not have the possible
    // deleted or closed information in it. If the transaction
    // initiate or the speculative initiate fail due to the listing
    // being deleted or closec, we should dig the information from the
    // errors and not the listing data.
    const listingNotFound =
      isTransactionInitiateListingNotFoundError(speculateTransactionError) ||
      isTransactionInitiateListingNotFoundError(initiateOrderError);

    const isLoading = !this.state.dataLoaded || speculateTransactionInProgress;

    const { listing, bookingDates, transaction, bookingData } = this.state.pageData;
    const existingTransaction = ensureTransaction(transaction);
    const speculatedTransaction = ensureTransaction(speculatedTransactionMaybe, {}, null);
    const currentListing = ensureListing(listing);
    const currentAuthor = ensureUser(currentListing.author);

    const listingTitle = currentListing.attributes.title;
    const { bankDetails } = currentListing.attributes.publicData;
    const title = intl.formatMessage({ id: 'CheckoutPage.title' }, { listingTitle });

    const pageProps = { title, scrollingDisabled };
    const { credits, paymentType } = params;
    const listingVariant = currentListing.attributes.publicData.listing_type;
    const isProductListing = listingVariant === LISTING_TYPES.EDUCATION_COURSES;
    const isEducationSite =
      [
        LISTING_TYPES.ACADEMY,
        LISTING_TYPES.COURSES,
        LISTING_TYPES.HOURLY_SESSION,
        LISTING_TYPES.TUTOR,
        LISTING_TYPES.REMOTE_TUTOR,
      ].includes(listingVariant) ||
      (listingVariant === LISTING_TYPES.ENQUIRY
        ? ['language', 'academic', 'management', 'art'].includes(
            currentListing?.attributes?.publicData?.category
          )
        : false);
    const topbar = (
      <TopbarContainer
        isCompanyPage={true}
        isMainWrapperBlur={this.state.isMainWrapperBlur}
        onMainWrapperBlur={this.mainWrapperBlurHandler}
        setIsOpenShareForm={value => this.setState({ isOpenShareForm: value })}
        isEducationSite={isEducationSite}
      />
    );

    if (isLoading) {
      return <Page {...pageProps}>{topbar}</Page>;
    }

    const isOwnListing =
      currentUser &&
      currentUser.id &&
      currentAuthor &&
      currentAuthor.id &&
      currentAuthor.id.uuid === currentUser.id.uuid;

    const hasListingAndAuthor = !!(currentListing.id && currentAuthor.id);
    const hasBookingDates = !!(
      bookingDates &&
      bookingDates.bookingStart &&
      bookingDates.bookingEnd
    );
    const hasRequiredData = isProductListing
      ? hasListingAndAuthor
      : hasListingAndAuthor && hasBookingDates;
    const canShowPage = hasRequiredData && !isOwnListing;
    const shouldRedirect = !isLoading && !canShowPage;

    // Redirect back to ListingPage if data is missing.
    // Redirection must happen before any data format error is thrown (e.g. wrong currency)
    if (shouldRedirect) {
      // eslint-disable-next-line no-console
      console.error('Missing or invalid data for checkout, redirecting back to listing page.', {
        transaction: speculatedTransaction,
        bookingDates,
        listing,
      });
      return <NamedRedirect name="ListingPage" params={params} />;
    }

    // Show breakdown only when speculated transaction and booking are loaded
    // (i.e. have an id)
    const tx = existingTransaction.booking ? existingTransaction : speculatedTransaction;
    const txBooking = ensureBooking(tx.booking);
    const timeZone = currentListing.attributes.availabilityPlan
      ? currentListing.attributes.availabilityPlan.timezone
      : 'Etc/UTC';
    const unitTypeBreakdown = bookingData?.withDefaultCredits
      ? config.bookingVoucherAmount
      : config.bookingUnitType;
    const breakdown =
      tx.id && txBooking.id ? (
        <BookingBreakdown
          className={css.bookingBreakdown}
          userRole="customer"
          unitType={unitTypeBreakdown}
          transaction={tx}
          booking={txBooking}
          dateType={DATE_TYPE_DATETIME}
          timeZone={timeZone}
        />
      ) : null;

    // Allow showing page when currentUser is still being downloaded,
    // but show payment form only when user info is loaded.
    const showPaymentForm = !!(
      currentUser &&
      hasRequiredData &&
      !listingNotFound &&
      !initiateOrderError &&
      !speculateTransactionError
    );

    const firstImage =
      currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

    const listingLink = (
      <NamedLink
        name="ListingPage"
        params={{ id: currentListing.id.uuid, slug: createSlug(listingTitle) }}
      >
        <FormattedMessage id="CheckoutPage.errorlistingLinkText" />
      </NamedLink>
    );

    const isBookingTimeNotAvailableError = isTransactionInitiateBookingTimeNotAvailableError(
      initiateOrderError
    );

    let initiateOrderErrorMessage = null;
    let listingNotFoundErrorMessage = null;

    if (listingNotFound) {
      listingNotFoundErrorMessage = (
        <p className={css.notFoundError}>
          <FormattedMessage id="CheckoutPage.listingNotFoundError" />
        </p>
      );
    } else if (isBookingTimeNotAvailableError) {
      initiateOrderErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.bookingTimeNotAvailableMessage" />
        </p>
      );
    } else if (initiateOrderError) {
      // Generic initiate order error
      initiateOrderErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.initiateOrderError" values={{ listingLink }} />
        </p>
      );
    }

    const speculateTransactionErrorMessage = speculateTransactionError ? (
      <p className={css.speculateError}>
        <FormattedMessage id="CheckoutPage.speculateTransactionError" />
      </p>
    ) : null;
    let speculateErrorMessage = null;

    if (isTransactionInitiateBookingTimeNotAvailableError(speculateTransactionError)) {
      speculateErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.bookingTimeNotAvailableMessage" />
        </p>
      );
    } else if (speculateTransactionError) {
      speculateErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.speculateFailedMessage" />
        </p>
      );
    }

    const unitType = config.bookingUnitType;
    const isNightly = unitType === LINE_ITEM_NIGHT;
    const isDaily = unitType === LINE_ITEM_DAY;

    const unitTranslationKey = isNightly
      ? 'CheckoutPage.perNight'
      : isDaily
      ? 'CheckoutPage.perDay'
      : 'CheckoutPage.perUnit';

    const price = currentListing.attributes.price;
    const formattedPrice = formatMoney(intl, price);
    const detailsSubTitle = `${formattedPrice} ${intl.formatMessage({ id: unitTranslationKey })}`;

    const showInitialMessageInput = !(
      existingTransaction && existingTransaction.attributes.lastTransition === TRANSITION_ENQUIRE
    );

    // Get first and last name of the current user and use it in the WithoutPaymentForm to autofill the name field
    const userName =
      currentUser && currentUser.attributes
        ? `${currentUser.attributes.profile.firstName} ${currentUser.attributes.profile.lastName}`
        : null;

    const initalValuesForStripePayment = { name: userName };

    const numberOfcredits =
      currentUser &&
      currentUser.attributes &&
      currentUser.attributes.profile.privateData.sessions &&
      currentUser.attributes.profile.privateData.sessions[currentListing.id.uuid]
        ? currentUser.attributes.profile.privateData.sessions[currentListing.id.uuid]
        : 0;
    // const hasCredits = numberOfcredits && numberOfcredits > 0 ? true : false;

    const { errorUpdateCredits } = this.state;
    const url = typeof window !== 'undefined' && window.location.href;

    return (
      <Page {...pageProps}>
        {topbar}
        <div className={css.contentContainer}>
          {errorUpdateCredits ? (
            <GenericMessage
              message={<FormattedMessage id="CheckoutPageWithoutPayment.updateCreditsError" />}
              show={errorUpdateCredits}
            />
          ) : null}
          <div className={css.aspectWrapper}>
            <ResponsiveImage
              rootClassName={css.rootForImage}
              alt={listingTitle}
              image={firstImage}
              variants={['landscape-crop', 'landscape-crop2x']}
            />
          </div>
          <div className={css.bookListingContainer}>
            <div className={css.heading}>
              <h1 className={css.title}>{title}</h1>
            </div>

            <div className={css.priceBreakdownContainer}>
              {speculateTransactionErrorMessage}
              {credits === 'true' ? null : breakdown}
            </div>

            <section className={css.paymentContainer}>
              {initiateOrderErrorMessage}
              {listingNotFoundErrorMessage}
              {speculateErrorMessage}
              <div>
                <FormattedMessage
                  id={'CheckoutPage.selectedPeriod'}
                  values={{ period: bookingData.period }}
                />
              </div>
              {!!bookingData?.withDefaultCredits && (
                <div>
                  <FormattedMessage
                    id={'CheckoutPage.remainingDefaultCreadit'}
                    values={{
                      amount: bookingData?.remainingDefalutCurrency / 100,
                      currency: listing?.attributes?.price?.currency,
                    }}
                  />
                </div>
              )}
              {showPaymentForm ? (
                <WithoutPaymentForm
                  className={css.paymentForm}
                  onSubmit={this.handleSubmit}
                  inProgress={this.state.submitting}
                  formId="CheckoutPagePaymentForm"
                  credits={credits}
                  bankDetails={bankDetails}
                  paymentInfo={intl.formatMessage({ id: 'CheckoutPage.paymentInfo' })}
                  authorDisplayName={currentAuthor.attributes.profile.displayName}
                  showInitialMessageInput={showInitialMessageInput}
                  initialValues={initalValuesForStripePayment}
                  initiateOrderError={initiateOrderError}
                  paymentType={paymentType}
                  listing={listing}
                />
              ) : null}
            </section>
          </div>

          <div className={css.withoutPaymentDetailsContainerDesktop}>
            <div className={css.detailsAspectWrapper}>
              <ResponsiveImage
                rootClassName={css.rootForImage}
                alt={listingTitle}
                image={firstImage}
                variants={['landscape-crop', 'landscape-crop2x']}
              />
            </div>
            <div className={css.Summary}>
              <div className={css.SummaryHead}>
                <div className={css.avatarWrapper}>
                  <AvatarMedium user={currentAuthor} disableProfileLink />
                </div>
                <div className={css.detailsHeadings}>
                  <h2 className={css.detailsTitle}>{listingTitle}</h2>
                  {credits === 'true' ? (
                    numberOfcredits > 0 ? (
                      <p className={`${css.detailsSubtitle} ${css.remainingSessions}`}>
                        <FormattedMessage
                          id="CheckoutPageWithoutPayment.remainingSessions"
                          values={{
                            count: numberOfcredits,
                            seller: currentAuthor.attributes.profile.displayName,
                          }}
                        />
                      </p>
                    ) : null
                  ) : (
                    <p className={css.detailsSubtitle}>{detailsSubTitle}</p>
                  )}
                </div>
              </div>
              {speculateTransactionErrorMessage}
              {credits === 'true' ? null : breakdown}
            </div>
          </div>
        </div>
        <Modal
          id="ListingPage.shareForm"
          className={css.shareFormModal}
          isOpen={this.state.isOpenShareForm}
          onClose={() => this.setState({ isOpenShareForm: false })}
          onManageDisableScrolling={this.props.onManageDisableScrolling}
        >
          <ShareForm listing={currentListing} url={url} />
        </Modal>
      </Page>
    );
  }
}

CheckoutPageWithoutPaymentComponent.defaultProps = {
  initiateOrderError: null,
  confirmPaymentError: null,
  listing: null,
  bookingData: {},
  bookingDates: null,
  speculateTransactionError: null,
  speculatedTransaction: null,
  transaction: null,
  currentUser: null,
};

CheckoutPageWithoutPaymentComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  listing: propTypes.listing,
  bookingData: object,
  bookingDates: shape({
    bookingStart: instanceOf(Date).isRequired,
    bookingEnd: instanceOf(Date).isRequired,
  }),
  fetchSpeculatedTransaction: func.isRequired,
  speculateTransactionInProgress: bool.isRequired,
  speculateTransactionError: propTypes.error,
  speculatedTransaction: propTypes.transaction,
  transaction: propTypes.transaction,
  currentUser: propTypes.currentUser,
  params: shape({
    id: string,
    slug: string,
  }).isRequired,
  onConfirmPayment: func.isRequired,
  onInitiateOrder: func.isRequired,
  onSendMessage: func.isRequired,
  initiateOrderError: propTypes.error,

  // from connect
  dispatch: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
};

const mapStateToProps = state => {
  const {
    listing,
    bookingData,
    bookingDates,
    speculateTransactionInProgress,
    speculateTransactionError,
    speculatedTransaction,
    transaction,
    initiateOrderError,
    // confirmPaymentError,
  } = state.CheckoutPage;
  const { currentUser } = state.user;
  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
    bookingData,
    bookingDates,
    speculateTransactionInProgress,
    speculateTransactionError,
    speculatedTransaction,
    transaction,
    listing,
    initiateOrderError,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
  fetchSpeculatedTransaction: params => dispatch(speculateTransaction(params)),
  onInitiateOrder: (params, transactionId) => dispatch(initiateOrder(params, transactionId)),
  onConfirmPayment: params => dispatch(confirmPayment(params)),
  onUpdateCredits: params => dispatch(updateCredits(params)),
  onSendMessage: params => dispatch(sendMessage(params)),
  onLogout: historyPush => dispatch(logout(historyPush)),
  queryPromotedListings: searchquery => dispatch(queryPromotedListings(searchquery)),
  onchangeVoucherDefaultValue: (data, user) => dispatch(changeVoucherDefaultValue(data, user)),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  queryPromotedListingsEdu: searchquery => dispatch(queryPromotedListingsEdu(searchquery)),
});

const CheckoutPageWithoutPayment = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(CheckoutPageWithoutPaymentComponent);

CheckoutPageWithoutPayment.setInitialValues = initialValues => setInitialValues(initialValues);

CheckoutPageWithoutPayment.displayName = 'CheckoutPage';

export default CheckoutPageWithoutPayment;
