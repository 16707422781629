import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { string, bool } from 'prop-types';
import { injectIntl, intlShape } from 'util/reactIntl';
import { Button, Modal } from 'components';
import { withRouter } from 'react-router-dom';
import css from './GetMatchActivityModal.css';
import { createMatchActivityListing } from 'containers/HomePage/HomePage.duck';
import { queAnsMatchActivity } from './GetMatchActivityModal.data';
import { useDispatch, useSelector } from 'react-redux';
import LocationInput from '../LocationAutocompleteInput/NewAutocompleteInput';
import {
  city,
  maximumPlayers,
  minimumPlayers,
  dateMonth,
  dateDay,
  dateWeek,
} from 'marketplace-custom-config';
import { toTitleCase } from 'util/stringHelper';
import thankyousvg from 'assets/ThankYouPage/thankyousvg.svg';
import classNames from 'classnames';

const GetMatchActivityModalComponent = props => {
  const { modalId, open, onModalClose, params = {}, intl } = props;
  const minPlayers = minimumPlayers;
  const maxPlayers = maximumPlayers;
  const day = dateDay;
  const month = dateMonth;
  const weekday = dateWeek;
  const cityData = city;

  // const printHourStrings = h => (h > 9 ? `${h}:00` : `0${h}:00`);
  // const HOURS = Array(24).fill();
  // const ALL_START_HOURS = [...HOURS].map((v, i) => printHourStrings(i));
  // const ALL_END_HOURS = [...HOURS].map((v, i) => printHourStrings(i + 1));

  const printTimeStrings = (h, m) =>
    h > 9 ? `${h}:${m.toString().padStart(2, '0')}` : `0${h}:${m.toString().padStart(2, '0')}`;
  const HOURS = Array(24).fill();

  const ALL_START_TIMES = [];
  const ALL_END_TIMES = [];

  for (let i = 0; i < 24; i++) {
    for (let j = 0; j < 60; j += 30) {
      const startHour = i;
      let endHour = i;
      const startMinute = j;
      let endMinute = (j + 30) % 60;

      if (endMinute === 0) {
        endHour = (endHour + 1) % 24;
      }

      ALL_START_TIMES.push(printTimeStrings(startHour, startMinute));
      ALL_END_TIMES.push(printTimeStrings(endHour, endMinute));
    }
  }

  const dispatch = useDispatch();
  const [showIntro, setShowIntro] = useState(true);
  const [showModal, setShowModal] = useState(open);
  const [showThankYouModal, setShowThankYouModal] = useState(false);
  const [contactDetails, setContactDetails] = useState({
    email: '',
    whatsapp: '',
    description: '',
    detaillocation: '',
    end: '',
    start: '',
    day: '',
    month: '',
    weekday: '',
    preferedArea: '',
    city: params?.city ? params?.city : '',
    price: '',
    maxPlayer: '',
    minPlayer: '',
    // category: params?.slug ? params?.slug : '',
  });

  const toggleThankYouModal = () => {
    setShowThankYouModal(!showThankYouModal);
  };
  const handleThankYouButtonClick = () => {
    toggleThankYouModal();
  };

  const startQuestions = () => {
    setShowIntro(false);
  };
  const continueOrStartQuestions = () => {
    if (
      currQueIndex >= 0
        ? queAnsList?.[currQueIndex]?.answer?.length > 0
        : contactDetails?.email !== '' &&
          contactDetails?.preferedArea !== '' &&
          contactDetails?.description !== '' &&
          contactDetails?.end !== '' &&
          contactDetails?.start !== '' &&
          contactDetails?.day !== '' &&
          contactDetails?.month !== '' &&
          contactDetails?.weekday !== '' &&
          contactDetails?.detaillocation !== '' &&
          contactDetails?.city !== '' &&
          contactDetails?.maxPlayer !== '' &&
          contactDetails?.minPlayer !== ''
    ) {
      startQuestions(); // Call startQuestions if the condition is met
    }
  };

  // You can then use continueOrStartQuestions where needed in your code.

  const [isStarted, setIsStarted] = useState(false);
  const [isLeaving, setIsLeaving] = useState(false);
  const [areOptionsSelected, setAreOptionsSelected] = useState(
    new Array(queAnsMatchActivity.length).fill(false)
  );

  const [currQueIndex, setCurrQueIndex] = useState(-1);
  const [queAnsList, setQueAnsList] = useState([]);
  const canContinue =
    currQueIndex >= 0
      ? queAnsList?.[currQueIndex]?.answer?.length > 0
      : contactDetails?.email !== '' &&
        contactDetails?.preferedArea !== '' &&
        contactDetails?.description !== '' &&
        contactDetails?.end !== '' &&
        contactDetails?.start !== '' &&
        contactDetails?.day !== '' &&
        contactDetails?.month !== '' &&
        contactDetails?.weekday !== '' &&
        contactDetails?.detaillocation !== '' &&
        contactDetails?.city !== '' &&
        contactDetails?.maxPlayer !== '' &&
        contactDetails?.minPlayer !== '';

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

  const isLastQuestion = currentQuestionIndex === queAnsMatchActivity.length - 1;

  const goToNextQuestion = () => {
    if (currentQuestionIndex < queAnsMatchActivity.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };
  const { currentUser } = useSelector(state => state.user);
  const currentUserMail = currentUser?.attributes?.email;
  const currentUserId = currentUser?.id?.uuid;
  const currentUserImage = currentUser?.profileImage?.attributes?.variants['square-small']?.url;
  const currentuserName = currentUser?.attributes?.profile?.displayName;

  const [selectedOptions, setSelectedOptions] = useState(
    new Array(queAnsMatchActivity.length).fill('')
  );

  const handleRadioChange = (questionIndex, option) => {
    const updatedSelectedOptions = [...selectedOptions];
    updatedSelectedOptions[questionIndex] = option;
    setSelectedOptions(updatedSelectedOptions);

    // Update the areOptionsSelected array for the current question
    const updatedAreOptionsSelected = [...areOptionsSelected];
    updatedAreOptionsSelected[questionIndex] = true;
    setAreOptionsSelected(updatedAreOptionsSelected);
  };

  useEffect(() => {
    setShowModal(open);
  }, [open, currentQuestionIndex, selectedOptions]);

  const closeAllModals = () => {
    setIsStarted(false);
    setIsLeaving(true);
    setCurrQueIndex(-1);
    setShowModal(false);
  };
  const onChangeFirst = e => {
    if (e.persist) {
      e.persist();
    }
    const { name, value } = e.target;
    setContactDetails({ ...contactDetails, [name]: value });
  };

  useEffect(() => {
    if (currentUserMail)
      setContactDetails(prev => ({
        ...prev,
        email: currentUserMail,
      }));
  }, [currentUserMail]);

  const handleSubmit = async (questionIndex, option) => {
    const title = 'Looking for New Match!!';
    const listing_type = 'match-activity';
    const city = params?.city ? params?.city : contactDetails.city;
    const prefered_area = toTitleCase(contactDetails?.preferedArea);
    const description = toTitleCase(contactDetails?.description);
    const detailLocation = contactDetails?.detaillocation;
    const startTime = contactDetails?.start;
    const endTime = contactDetails?.end;
    const dateDay = contactDetails?.day;
    const dateMonth = contactDetails?.month;
    const dateWeek = contactDetails?.weekday;
    const min_player = contactDetails?.minPlayer;
    const max_player = contactDetails?.maxPlayer;
    const price = contactDetails?.price;

    const answers = selectedOptions.slice(0, currentQuestionIndex + 1);

    const updatedSelectedOptions = [...selectedOptions];
    updatedSelectedOptions[questionIndex] = option;
    setSelectedOptions(updatedSelectedOptions);

    setQueAnsList(prevQueAnsList => [...prevQueAnsList, answers]);

    const createListingData = {
      title,
      description,
      publicData: {
        que_ans: [...queAnsList, answers],
        detailLocation,
        listing_type,
        ownerEmail: currentUserMail,
        user: currentUserId,
        userImage: currentUserImage,
        ownerName: currentuserName,
        location: {
          prefered_area,
          city,
        },
        date: {
          day: dateDay,
          month: dateMonth,
          week: dateWeek,
        },
        time: {
          start: startTime,
          end: endTime,
        },
        joinRequest: [],
        allowedPlayers: {
          min_player,
          max_player,
        },
        price,
      },
    };

    await dispatch(createMatchActivityListing(createListingData, !!currentUser?.attributes?.email));

    setShowModal(onModalClose);
    setShowThankYouModal(true);
  };

  return (
    <div className={css.matchModelMain}>
      {showModal && (
        <Modal
          id={modalId}
          extraContainerClasses={css.matchModelWrap}
          contentClassName={css.customModalContent}
          extraScrollLayerClasses={css.customScrollLayer}
          isOpen={showModal}
          onClose={closeAllModals}
          onManageDisableScrolling={() => {}}
        >
          {showIntro ? (
            <div className={css.matchMain}>
              <div className={css.matchHead}>
                <h2>Create a Match or Activity for Players to Join</h2>
              </div>
              <div className={css.matchWrap}>
                <div className={css.matchForm}>
                  <label className={css.matchlabel}>Enter your registered Email ID *</label>
                  <input
                    className={css.matchInput}
                    type="email"
                    placeholder="Your Email"
                    name="email"
                    value={contactDetails?.email}
                    onChange={onChangeFirst}
                  />
                </div>
                <div className={css.matchForm}>
                  <label className={css.matchlabel}>Near which area do you prefer to play *</label>
                  <LocationInput
                    intl={intl}
                    showLable=""
                    onChange={e => {
                      setContactDetails(prev => ({
                        ...prev,
                        preferedArea: e.search,
                      }));
                    }}
                  />
                </div>

                {/* <div className={css.dateTimeWrap}> */}
                <div>
                  <div className={css.matchForm}>
                    <label className={css.matchlabel}>Date of Match</label>
                    <div className={css.dateWrap}>
                      <select
                        className={css.selectCity}
                        name="day"
                        id="day"
                        value={contactDetails.day}
                        onChange={onChangeFirst}
                      >
                        <option value={''}>day</option>;
                        {day.map((val, i) => {
                          return (
                            <option key={i} value={val.key}>
                              {val.label}
                            </option>
                          );
                        })}
                      </select>
                      <select
                        className={css.selectCity}
                        name="month"
                        id="month"
                        value={contactDetails.month}
                        onChange={onChangeFirst}
                      >
                        <option value={''}>month</option>;
                        {month.map((val, i) => {
                          return (
                            <option key={i} value={val.key}>
                              {val.label}
                            </option>
                          );
                        })}
                      </select>
                      <select
                        className={css.selectCity}
                        name="weekday"
                        id="weekday"
                        value={contactDetails.weekday}
                        onChange={onChangeFirst}
                      >
                        <option value={''}>day</option>;
                        {weekday.map((val, i) => {
                          return (
                            <option key={i} value={val.key}>
                              {val.label}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
                <div>
                  <div className={css.matchForm}>
                    <label className={css.matchlabel}>Time of Match</label>
                    <div className={css.dateWrap}>
                      <select
                        className={css.selectCity}
                        name="start"
                        id="start"
                        value={contactDetails.start}
                        onChange={onChangeFirst}
                      >
                        <option value={''}>start time</option>
                        {ALL_START_TIMES.map(s => {
                          return (
                            <option value={s} key={s}>
                              {s}
                            </option>
                          );
                        })}
                      </select>
                      <select
                        className={css.selectCity}
                        name="end"
                        id="end"
                        value={contactDetails.end}
                        onChange={onChangeFirst}
                      >
                        <option value={''}>end time</option>;
                        {ALL_END_TIMES.map(s => {
                          return (
                            <option value={s} key={s}>
                              {s}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
                {/* </div> */}
                <div className={css.matchForm}>
                  <label className={css.matchlabel}>Detailed location where you want to play</label>
                  <textarea
                    rows={3}
                    placeholder="123, Street, Landmark, City"
                    name="detaillocation"
                    onChange={onChangeFirst}
                  ></textarea>
                </div>
                <div className={css.matchForm}>
                  <label className={css.matchlabel}>
                    Give a short description to get connected with players
                  </label>
                  <textarea
                    rows={3}
                    placeholder="Something you've in your mind"
                    name="description"
                    onChange={onChangeFirst}
                  ></textarea>
                </div>

                <div className={css.matchForm}>
                  <label className={css.matchlabel}>select minimum players *</label>
                  <select
                    className={css.selectCity}
                    name="minPlayer"
                    id="minPlayer"
                    value={contactDetails?.minPlayer}
                    onChange={onChangeFirst}
                  >
                    <option value={''}>select minimum players</option>;
                    {minPlayers.map((val, i) => {
                      return (
                        <option key={i} value={val.label}>
                          {val.label}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className={css.matchForm}>
                  <label className={css.matchlabel}>select maximum players *</label>
                  <select
                    className={css.selectCity}
                    name="maxPlayer"
                    id="maxPlayer"
                    value={contactDetails.maxPlayer}
                    onChange={onChangeFirst}
                  >
                    <option value={''}>select maximum players</option>;
                    {maxPlayers.map((val, i) => {
                      return (
                        <option key={i} value={val.label}>
                          {val.label}
                        </option>
                      );
                    })}
                  </select>
                </div>
                {params && !params?.city && (
                  <div className={css.matchForm}>
                    <label className={css.matchlabel}>select city *</label>
                    <select
                      className={css.selectCity}
                      name="city"
                      id="city"
                      value={contactDetails.city}
                      onChange={onChangeFirst}
                    >
                      <option value={''}>select city</option>;
                      {cityData.map((val, i) => {
                        return (
                          <option key={i} value={val.key}>
                            {val.label}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                )}
                <div className={css.matchForm}>
                  <label className={css.matchlabel}>Price (optional)</label>
                  <input
                    type="text"
                    placeholder="USD 10.00"
                    name="price"
                    value={contactDetails?.price}
                    onChange={onChangeFirst}
                  />
                </div>
              </div>
              <div className={css.matchFooter}>
                {!canContinue && <h4 className={css.errorText}>* fill the required fields.</h4>}
                <Button className={css.matchFooterBtn} onClick={continueOrStartQuestions}>
                  Continue
                </Button>
              </div>

              {/* <div className={css.optionalWrap}>
                <code>optional</code>
                <div className={css.optionsWrap}>
                  <input
                    type="tel"
                    placeholder="Your Whatsapp Number"
                    name="whatsapp"
                    value={contactDetails?.whatsapp}
                    onChange={onChangeFirst}
                  />
                </div>
              </div> */}
            </div>
          ) : (
            <div key={currentQuestionIndex} className={css.questionContainer}>
              <div className={css.matchModelHead}>
                <div className={css.questionWrapper}>
                  <h2 className={css.matchQuestion}>
                    {queAnsMatchActivity[currentQuestionIndex].question}
                  </h2>
                  {queAnsMatchActivity[currentQuestionIndex].info && (
                    <h4 className={css.infoText}>
                      {queAnsMatchActivity[currentQuestionIndex].info}
                    </h4>
                  )}
                </div>
              </div>
              <div className={css.optionsWrapSurvey}>
                {queAnsMatchActivity[currentQuestionIndex].options.map((option, optionIndex) => (
                  <div
                    className={`${css.optionsWrapper} ${
                      selectedOptions[currentQuestionIndex] === option.label ? css.checked : ''
                    }`}
                    key={optionIndex}
                  >
                    <label
                      className={css.optionsLabel}
                      htmlFor={`question_${currentQuestionIndex}_option_${optionIndex}`}
                    >
                      <input
                        className={css.optionsInput}
                        type="radio"
                        id={`question_${currentQuestionIndex}_option_${optionIndex}`}
                        name={`question_${currentQuestionIndex}`}
                        value={option.label}
                        onChange={() => handleRadioChange(currentQuestionIndex, option.label)}
                      />
                      {option.img && (
                        <div className={css.optionsImgWrap}>
                          <img src={option.img} alt="" />
                        </div>
                      )}
                      {option.label}
                    </label>
                  </div>
                ))}
              </div>
              <div className={css.actionWrapper}>
                {isLastQuestion ? (
                  <button
                    className={css.btnBlue}
                    onClick={() =>
                      handleSubmit(currentQuestionIndex, selectedOptions[currentQuestionIndex])
                    }
                    disabled={!areOptionsSelected[currentQuestionIndex]}
                  >
                    Submit
                  </button>
                ) : (
                  <button
                    className={css.btnBlue}
                    onClick={goToNextQuestion}
                    disabled={!areOptionsSelected[currentQuestionIndex]}
                  >
                    Next
                  </button>
                )}
              </div>
            </div>
          )}
        </Modal>
      )}
      {showThankYouModal && (
        <Modal
          id="thankYouModal" // You can use a different id for the "Thank You" modal
          extraContainerClasses={css.matchModelWrap}
          contentClassName={css.customModalContent}
          extraScrollLayerClasses={css.customScrollLayer}
          isOpen={showThankYouModal}
          onClose={toggleThankYouModal}
          onManageDisableScrolling={() => {}}
        >
          <div className={css.thankYouWrap}>
            <h2>Congrats your Match is now on The Match board where Players can join</h2>
            <h3>this was for better understanding of your needs.</h3>
            <img src={thankyousvg} alt={'Thank You so much img'} />
            <h3>Your Match is Posted on Match Board</h3>
            <button
              className={classNames(css.btnBlue, css.btn)}
              onClick={() => {
                onModalClose();
                window.location.reload();
              }}
            >
              Have a look
            </button>
          </div>
        </Modal>
      )}
    </div>
  );
};

GetMatchActivityModalComponent.defaultProps = {
  rootClassName: null,
  className: null,
};

GetMatchActivityModalComponent.propTypes = {
  rootClassName: string,
  className: string,
  bool,
  intl: intlShape.isRequired,
};

const GetMatchActivityModal = compose(withRouter, injectIntl)(GetMatchActivityModalComponent);

export default GetMatchActivityModal;
