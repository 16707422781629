import React, { useMemo } from 'react';
import { uid } from 'react-uid';
import cns from 'classnames';
import PropTypes from 'prop-types';
import moment from 'moment';
import { propTypes } from 'util/types';
import TableEntry from '../TableEntry/TableEntry';
import css from './Table.css';
const WEEKS = 8;

const renderWeeks = selectedWeek => {
  const arr = [];

  for (let i = 0; i < WEEKS; i++) {
    const today = moment();
    const from_date = today.startOf('week');
    // let a = from_date.add(i, 'weeks').format('YYYY MM DD')
    let elem = null;
    i === 0
      ? (elem = (
          <div key="w1" className={cns(css.cell, css.weekMarkCol)}>
            {/* W1 */}
            {from_date.add(1, 'days').format('D MMM')}
          </div>
        ))
      : (elem = (
          <div key={uid('W', i + 1)} className={cns(css.cell, css.weekMarkCol)}>
            {/* {`W${i + 1}`} */}
            {from_date
              .add(1, 'days')
              .add(i, 'weeks')
              .format('D MMM')}
          </div>
        ));
    arr.push(elem);
  }

  if (selectedWeek && selectedWeek === '1week') {
    // const weekdayNames = []
    // for (let i = 0; i < 7; i++) {
    //   weekdayNames.push(
    //       <div key={uid('W', i + 1)} className={cns(css.cell, css.weekMarkCol)}>
    //         { moment().add(i, 'days').format('D ddd') }
    //       </div>
    //     )
    // };
    return [];
  } else if (selectedWeek && selectedWeek === '2weeks') {
    return arr.slice(0, 2);
  } else if (selectedWeek && selectedWeek === '4weeks') {
    return arr.slice(0, 4);
  } else if (selectedWeek && selectedWeek === '6weeks') {
    return arr.slice(0, 6);
  } else if (selectedWeek && selectedWeek === '8weeks') {
    return arr.slice(0, 8);
  } else {
    return arr;
  }
};

function Table({
  data,
  isSaleSchedule,
  isCollapsedAll,
  selectedWeek,
  onSelect,
  selectedBookings,
  onAllSelectedSchedule,
  urlQueryParams,
  className,
  isMobileLayout,
}) {
  const entries = useMemo(() => {
    if (!data) return null;

    return (
      data
        // group items by weekday in separate arrays
        .reduce((acc, item) => {
          const index = acc.findIndex(subArr => {
            return subArr.some(subItem => subItem.weekday === item.weekday);
          });

          index >= 0 ? acc[index].push(item) : acc.push([item]);
          return acc;
        }, [])
        .map(arrayWithItems => {
          const sortedItems = arrayWithItems.sort((a, b) => {
            const aStart = a.bookingStart.split(':')[0];
            const bStart = b.bookingStart.split(':')[0];
            return aStart - bStart;
          });
          const weekday = sortedItems[0].weekday;
          const formatedWeekday = sortedItems[0].formatedWeekday;
          return { weekday, formatedWeekday, entries: sortedItems };
        })
    );
  }, [data]);

  // const totalEntry = data.reduce((count, entry) => {
  //   if (entry.clients) {
  //     return count + entry.clients.length;
  //   } else {
  //     return count + 1;
  //   }
  // }, 0);

  // const isAllCheked = selectedBookings.length === totalEntry ? true : false;

  // const onSelectAllRow = () => {
  //   const allSeletedData = [];
  //   if (!isAllCheked) {
  //     data.forEach(entry => {
  //       if (entry.multipleClients) {
  //         entry.clients.forEach(clientRow => {
  //           allSeletedData.push(clientRow.transactions[0]);
  //         });
  //       } else {
  //         allSeletedData.push(entry.transactions[0]);
  //       }
  //     });
  //   }
  //   onAllSelectedSchedule(allSeletedData);
  // };
  return (
    <div className={cns(css.wrapper, className)}>
      <div
        className={cns(css.table, {
          [css.tableScroll]: selectedWeek === '6weeks' || selectedWeek === '8weeks',
        })}
      >
        <div className={css.head}>
          <div className={css.headContainer}>
            <div className={css.checkboxTimeWrap}>
              {/* <div className={cns(css.cell, css.checkboxCol)}>
                <div className={css.checkboxField}>
                  <input
                    className={css.checkboxInput}
                    onChange={onSelectAllRow}
                    checked={isAllCheked}
                    type="checkbox"
                    id={'scheduleTable'}
                  />
                  <label htmlFor={'scheduleTable'}></label>
                </div>
              </div> */}
              <div className={css.bookingTimeWrap}>
                <div className={cns(css.cell, css.nameCol)}>Booking list</div>

                <div className={cns(css.cell, css.timeCol)}>Time</div>

                <div className={cns(css.cell, css.seatCol)}>No. of seats</div>
              </div>
            </div>
            <div className={css.listingMembershipWrap}>
              <div className={cns(css.cell, css.listingNameCol)}>Listing Name</div>

              <div className={cns(css.cell, css.membershipCol)}>Membership</div>
            </div>
            {renderWeeks(selectedWeek)}
          </div>
        </div>

        {entries.map((entry, index) => (
          <TableEntry
            key={uid(entry)}
            isSaleSchedule={isSaleSchedule}
            index={index}
            entry={entry}
            urlQueryParams={urlQueryParams}
            onSelect={onSelect}
            selectedBookings={selectedBookings}
            selectedWeek={selectedWeek}
            isCollapsedAll={isCollapsedAll}
            isMobileLayout={isMobileLayout}
          />
        ))}
      </div>
    </div>
  );
}

Table.propTypes = {
  data: PropTypes.arrayOf(propTypes.bookingPlanItem),
  isCollapsedAll: PropTypes.bool,
};

export default Table;
