export const queAnsMatchActivity = [
  {
    question: 'Sport',
    info: 'Select sport you want to play',
    options: [
      {
        label: 'Padel',
        img:
          'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1699503232/probuddy/MatchActivity/padelModalImage_eq7wzv.png',
      },
      {
        label: 'Tennis',
        img:
          'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1699503300/probuddy/MatchActivity/tennisModalImage_wgfyes.png',
      },
      {
        label: 'Pickleball',
        img:
          'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1699503270/probuddy/MatchActivity/pickleballModalImage_donaro.png',
      },
    ],
    type: 'radio',
  },
  // {
  //   question: 'Best Hand',
  //   info: 'Select hand you want to play with',
  //   options: [
  //     { label: 'Right Handed', img: "https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1699504512/probuddy/MatchActivity/righthand_m0twsh.png" },
  //     { label: 'Left Handed', img: "https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1699504506/probuddy/MatchActivity/lefthand_qirri2.png" },
  //     { label: 'Both Hands', img: "https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1699504519/probuddy/MatchActivity/bothhand_fi3qoc.png" },
  //   ],
  //   type: 'radio',
  // },
  // {
  //   question: 'Courtside',
  //   info: 'Select your courtside',
  //   options: [
  //     { label: 'Backhand', img: "https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1699504645/probuddy/MatchActivity/backhand_ehcyix.png" },
  //     { label: 'Forehand', img: "https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1699504609/probuddy/MatchActivity/forehand_og49wo.png" },
  //     { label: 'Both Sides', img: "https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1699504602/probuddy/MatchActivity/bothsides_uhjds0.png" },
  //   ],
  //   type: 'radio',
  // },

  // {
  //   question: 'Choose your Match Type',
  //   info: 'The result will affect your level and rankings',
  //   options: [
  //     { label: 'Competitive Match', img: "https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1699504083/probuddy/MatchActivity/competitve_aosztm.png" },
  //     { label: 'Friendly Match', img: "https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1699504094/probuddy/MatchActivity/friendly_nwacgt.png" },
  //   ],
  //   type: 'radio',
  // },
  {
    question: 'Level',
    info: 'Restrict participants by skill level',
    options: [
      {
        label: 'Beginner (1.0-2.0)',
        img:
          'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1699503844/probuddy/MatchActivity/advanced_lclqnd.png',
      },
      {
        label: 'Intermedaite (2.5-3.0)',
        img:
          'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1699503863/probuddy/MatchActivity/intermediate_ang1by.png',
      },
      {
        label: 'Advanced (3.5-5.0)',
        img:
          'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1699503844/probuddy/MatchActivity/advanced_lclqnd.png',
      },
      {
        label: 'Pro (5.0-7.0)',
        img:
          'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1699503856/probuddy/MatchActivity/proplayer_coo7n6.png',
      },
    ],
    type: 'radio',
  },
  {
    question: 'Players',
    info: 'Add players to your match',
    options: [
      {
        label: 'Single',
        img:
          'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1699503574/probuddy/MatchActivity/single_s8kejk.png',
      },
      {
        label: 'Double',
        img:
          'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1699503544/probuddy/MatchActivity/double_h6i6vk.png',
      },
    ],
    type: 'radio',
  },
  {
    question: 'Duration',
    info: 'Provide duration of your match',
    options: [
      {
        label: '60 mins',
        img:
          'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1699503643/probuddy/MatchActivity/sixty_rohlwf.png',
      },
      {
        label: '90 mins',
        img:
          'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1699503670/probuddy/MatchActivity/ninty_ep2vqp.png',
      },
      {
        label: '120 mins',
        img:
          'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1699503656/probuddy/MatchActivity/twenty_e88szb.png',
      },
    ],
    type: 'radio',
  },
  // {
  //   question: 'Time of Match',
  //   info: 'Provide time of your match',
  //   options: [
  //     { label: 'Early morning (before 9am)', img: "https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1699504155/probuddy/MatchActivity/earlymorning_jdj2nr.png" },
  //     { label: 'Morning (9am-noon)', img: "https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1699504162/probuddy/MatchActivity/morning_koaiun.png" },
  //     { label: 'Early afternoon (noon-3pm)', img: "https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1699504192/probuddy/MatchActivity/afternoon_gmoycr.png" },
  //     { label: 'Late afternoon (3-6pm)', img: "https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1699504201/probuddy/MatchActivity/lateafternoon_opj7l7.png" },
  //     { label: 'Evening (after 6pm)', img: "https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1699504234/probuddy/MatchActivity/evening_wjerne.png" },
  //   ],
  //   type: 'radio',
  // },
  // {
  //   question: 'Day of Match',
  //   info: 'Provide day of your match',
  //   options: [
  //     { label: 'Monday', img: "https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1699504288/probuddy/MatchActivity/monday_mj8jnv.png" },
  //     { label: 'Tuesday', img: "https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1699504297/probuddy/MatchActivity/tuesday_cc9vwh.png" },
  //     { label: 'Wednesday', img: "https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1699504305/probuddy/MatchActivity/wednesday_nsciww.png" },
  //     { label: 'Thrusday', img: "https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1699504319/probuddy/MatchActivity/thrusday_hxuojc.png" },
  //     { label: 'Friday', img: "https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1699504371/probuddy/MatchActivity/friday_gbpwrm.png" },
  //     { label: 'Saturday', img: "https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1699504376/probuddy/MatchActivity/saturday_wkwtaq.png" },
  //     { label: 'Sunday', img: "https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1699504382/probuddy/MatchActivity/sunday_rh5apw.png" },
  //   ],
  //   type: 'radio',
  // },
];
