import React, { memo } from 'react';
import { string, shape, func } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { withViewport } from '../../util/contextHelpers';
import classNames from 'classnames';
import { NamedLink } from '../../components';
import logoImage from './logo.png';

import css from './Footer.css';
import { types as sdkTypes } from '../../util/sdkLoader';
import { withRouter } from 'react-router-dom';

const { LatLng, LatLngBounds } = sdkTypes;

// const renderSocialMediaLinks = intl => {
//   const { siteFacebookPage, siteInstagramPage, siteTwitterHandle } = config;
//   const siteTwitterPage = twitterPageURL(siteTwitterHandle);

//   const goToFb = intl.formatMessage({ id: 'Footer.goToFacebook' });
//   const goToInsta = intl.formatMessage({ id: 'Footer.goToInstagram' });
//   const goToTwitter = intl.formatMessage({ id: 'Footer.goToTwitter' });

//   const fbLink = siteFacebookPage ? (
//     <ExternalLink key="linkToFacebook" href={siteFacebookPage} className={css.icon} title={goToFb}>
//       <IconSocialMediaFacebook />
//     </ExternalLink>
//   ) : null;

//   const twitterLink = siteTwitterPage ? (
//     <ExternalLink
//       key="linkToTwitter"
//       href={siteTwitterPage}
//       className={css.icon}
//       title={goToTwitter}
//     >
//       <IconSocialMediaTwitter />
//     </ExternalLink>
//   ) : null;

//   const instragramLink = siteInstagramPage ? (
//     <ExternalLink
//       key="linkToInstagram"
//       href={siteInstagramPage}
//       className={css.icon}
//       title={goToInsta}
//     >
//       <IconSocialMediaInstagram />
//     </ExternalLink>
//   ) : null;
//   return [fbLink, twitterLink, instragramLink].filter(v => v != null);
// };

const Footer = props => {
  const { rootClassName, className, isEducationSite, isTennisSite } = props;
  // const socialMediaLinks = renderSocialMediaLinks(intl);
  const classes = classNames(
    rootClassName || css.root,
    isEducationSite && css.educationRoot,
    // isTennisSite && css.tennisRoot,
    className
  );
  let isPrivateSite = false;
  if (typeof window !== 'undefined' && !!window.sessionStorage) {
    isPrivateSite = window.sessionStorage.getItem('privateSiteUrl') ? true : false;
  }

  // const isMobileLayout = viewport && viewport.width && viewport.width <= 550;

  const singaporeLabel = 'Singapore';
  const sydneyLabel = 'Sydney';
  const newYorkLabel = 'New York';
  const londonLabel = 'London';
  const LALabel = 'Los Angeles';
  const melbourneLabel = 'Melbourne';
  const chicagoLabel = 'Chicago';
  const washingtonLabel = 'Washington';
  const city = [
    {
      label: singaporeLabel,
      bounds: new LatLngBounds(
        new LatLng(1.4110994, 103.9577613),
        new LatLng(1.2642103, 103.6055395)
      ),
    },
    {
      label: sydneyLabel,
      bounds: new LatLngBounds(
        new LatLng(-33.578140996, 151.343020992),
        new LatLng(-34.118344992, 150.520928608)
      ),
    },
    {
      label: newYorkLabel,
      bounds: new LatLngBounds(
        new LatLng(45.0239286969073, -71.7564918092633),
        new LatLng(40.4771391062446, -79.8578350999901)
      ),
    },
    {
      label: londonLabel,
      bounds: new LatLngBounds(new LatLng(51.669993, 0.153177), new LatLng(51.384527, 0.351708)),
    },
    {
      label: LALabel,
      bounds: new LatLngBounds(
        new LatLng(34.161439, -118.126728),
        new LatLng(33.900014, -118.521447)
      ),
    },
    {
      label: melbourneLabel,
      bounds: new LatLngBounds(
        new LatLng(-37.535924, 145.433455),
        new LatLng(-38.154894, 144.568752)
      ),
    },
    {
      label: chicagoLabel,
      bounds: new LatLngBounds(
        new LatLng(-37.535924, 145.433455),
        new LatLng(-38.154894, 144.568752)
      ),
    },
    {
      label: washingtonLabel,
      bounds: new LatLngBounds(
        new LatLng(-37.535924, 145.433455),
        new LatLng(-38.154894, 144.568752)
      ),
    },
  ];
  const content = (
    <div className={classes}>
      <div className={css.topBorderWrapper}>
        <div className={css.content}>
          <NamedLink name="LandingPage" className={css.logoLink}>
            <img src={logoImage} alt="logo" />
          </NamedLink>
          <div className={css.links}>
            <div className={css.organization} id="organization">
              <div className={css.organizationInfo}>
                <p className={css.organizationDescription}>
                  <FormattedMessage
                    id={
                      isEducationSite
                        ? 'Footer.organizationDescriptionEducation'
                        : 'Footer.organizationDescription'
                    }
                  />
                </p>
                <p className={css.organizationCopyright}>
                  <NamedLink name="LandingPage" className={css.copyrightLink}>
                    <FormattedMessage id="Footer.copyright" />
                  </NamedLink>
                </p>
              </div>
            </div>
            <div className={css.linkWrap}>
              <div className={css.infoLinks}>
                <ul className={css.list}>
                  <li className={css.listItem}>
                    <NamedLink name="NewListingPage" className={css.link}>
                      <FormattedMessage id="Footer.toNewListingPage" />
                    </NamedLink>
                  </li>
                  <li className={css.listItem}>
                    <NamedLink name="AboutPage" className={css.link}>
                      <FormattedMessage id="Footer.toAboutPage" />
                    </NamedLink>
                  </li>
                  <li className={css.listItem}>
                    <NamedLink name="LandingPage" className={css.link}>
                      <FormattedMessage id="Footer.toFAQPage" />
                    </NamedLink>
                  </li>
                  <li className={css.listItem}>
                    <NamedLink name="LandingPage" className={css.link}>
                      <FormattedMessage id="Footer.toHelpPage" />
                    </NamedLink>
                  </li>
                  <li className={css.listItem}>
                    <NamedLink name="AboutPage" to={{ hash: '#contact' }} className={css.link}>
                      <FormattedMessage id="Footer.toContactPage" />
                    </NamedLink>
                  </li>
                </ul>
              </div>
              <div className={css.searches}>
                <ul className={css.list}>
                  {city.map(city => (
                    <li key={city.label} className={css.listItem}>
                      <NamedLink
                        name={isEducationSite ? 'EducationHomePageCity' : 'HomePageCity'}
                        params={{ city_name: city.label }}
                        className={css.link}
                      >
                        {city.label}
                      </NamedLink>
                    </li>
                  ))}
                  {/* <li className={css.listItem}>
                    <NamedLink
                      name="SearchPage"
                      to={{
                        search:
                          '?address=Bangkok%2C%20Thailand&bounds=13.8655169%2C100.7012364%2C13.6308189%2C100.4141944',
                      }}
                      className={css.link}
                    >
                      <FormattedMessage id="Footer.searchBangkok" />
                    </NamedLink>
                  </li>
                  <li className={css.listItem}>
                    <NamedLink
                      name="SearchPage"
                      to={{
                        search:
                          '?address=Hong-Kong%2C%20China&bounds=22.3472941%2C114.224484%2C22.2776183%2C114.123264',
                      }}
                      className={css.link}
                    >
                      <FormattedMessage id="Footer.searchHongKong" />
                    </NamedLink>
                  </li>
                  <li className={css.listItem}>
                    <NamedLink
                      name="SearchPage"
                      to={{
                        search:
                          '?address=Jakarta%2C%20Indonesia&bounds=-6.0887627%2C106.9715223%2C-6.326409%2C106.7436153',
                      }}
                      className={css.link}
                    >
                      <FormattedMessage id="Footer.searchJakarta" />
                    </NamedLink>
                  </li>
                  <li className={css.listItem}>
                    <NamedLink
                      name="SearchPage"
                      to={{
                        search:
                          '?address=Melbourne%2C%20Australia&bounds=-37.535924%2C145.433455%2C-38.154894%2C144.568752',
                      }}
                      className={css.link}
                    >
                      <FormattedMessage id="Footer.searchMelbourne" />
                    </NamedLink>
                  </li> */}
                </ul>
              </div>
              <div className={css.extraLinks}>
                {/* <div className={css.socialLinks}>{socialMediaLinks}</div> */}
                <div className={css.legalMatters}>
                  <ul className={css.tosAndPrivacy}>
                    <li>
                      <NamedLink name="ContactPage" className={css.legalLink}>
                        <FormattedMessage id="Footer.contact" />
                      </NamedLink>
                    </li>
                    <li>
                      <NamedLink name="TermsOfServicePage" className={css.legalLink}>
                        <FormattedMessage id="Footer.termsOfUse" />
                      </NamedLink>
                    </li>
                    <li>
                      <NamedLink name="PrivacyPolicyPage" className={css.legalLink}>
                        <FormattedMessage id="Footer.privacyPolicy" />
                      </NamedLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/* <div className={css.copyrightAndTermsMobile}>
            <NamedLink name="LandingPage" className={css.organizationCopyrightMobile}>
              <FormattedMessage id="Footer.copyright" />
              <FormattedMessage id="Footer.copyright2" />
            </NamedLink>
            <div className={css.tosAndPrivacyMobile}>
              <div className={css.contactPrivacyWrapper}>
                <NamedLink name="ContactPage" className={css.contact}>
                  <FormattedMessage id="Footer.contact" />
                </NamedLink>
                <NamedLink name="PrivacyPolicyPage" className={css.privacy}>
                  <FormattedMessage id="Footer.privacy" />
                </NamedLink>
              </div>
              <NamedLink name="TermsOfServicePage" className={css.terms}>
                <FormattedMessage id="Footer.terms" />
              </NamedLink>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );

  return !isPrivateSite ? content : null;
};

Footer.defaultProps = {
  rootClassName: null,
  className: null,
};

Footer.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,

  /* from withRouter */
  history: shape({
    push: func.isRequired,
  }).isRequired,
};

export default compose(withRouter, withViewport, injectIntl)(memo(Footer));
