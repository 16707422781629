import React from 'react';
import logo from '../../assets/companyLogo.svg';
import css from './Loading.css';

const Loading = () => {
  return (
    <div className={css.loadingWrapper}>
      <div className={css.loading}>
        <img src={logo} alt="logo" />
        <div className={css.loadingContent}>
          <div className={css.probuddy}>
            <p>Pro</p>
            <p className={css.buddy}>buddy</p>
          </div>
          <div>Loading...</div>
        </div>
      </div>
    </div>
  );
};

export default Loading;
