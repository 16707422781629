import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import config from '../../config';
import { withViewport } from '../../util/contextHelpers';
import { IMAGES } from '../../util/imagesPath';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { deleteSubscription } from '../../util/api';
import classNames from 'classnames';
import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  FooterBottomMenu,
  Modal,
  LeftBar,
  PrimaryButton,
  SecondaryButton,
  NamedLink,
} from 'components';
import { TopbarContainer } from '../../containers';
import { queryPromotedListings } from '../HomePage/HomePage.duck';
import { loadData, getBuyerSubscription, getSellerSubscription } from './UserSubscriptions.duck';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import SubscriptionCard from './SubscriptionCard';
import css from './UserSubscriptions.css';

class UserSubscriptionsComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      queryParams: null,
      activeFooterTab: 'search',
      cancelModalOpen: null,
    };
    this.onCancelSubscription = this.onCancelSubscription.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps?.currentUser?.id?.uuid !== this.props?.currentUser?.id?.uuid ||
      prevProps?.currentUserWasFetched != this.props.currentUserWasFetched
    ) {
      this.props.ongetBuyerSubscription();
      this.props.ongetSellerSubscription();
    }
  }

  onCancelSubscription = data => {
    deleteSubscription(data);
    setTimeout(() => {
      window?.location && window.location.reload(false);
    }, 5000);
  };

  onSearchProduct = searchquery => {
    this.setState({ queryParams: searchquery });
    this.props.queryPromotedListings(searchquery);
  };

  render() {
    const { scrollingDisabled, intl, viewport } = this.props;
    const siteTitle = config.siteTitle;
    const schemaDescription = intl.formatMessage({ id: 'WebAppPage.schemaDescription' });
    const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { siteTitle });
    const schemaImage = `${config.canonicalRootURL}${IMAGES.facebookImage}`;
    const schemaKeyword = intl.formatMessage({ id: 'WebAppPage.schemaKeywords' });

    const MAX_MOBILE_SCREEN_WIDTH = 1024;
    const isMobileLayout = viewport.width ? viewport.width < MAX_MOBILE_SCREEN_WIDTH : false;

    return (
      <Page
        scrollingDisabled={scrollingDisabled}
        isBottomMenuRequire={false}
        contentType="website"
        description={schemaDescription}
        keywords={schemaKeyword}
        title={schemaTitle}
        hideCookies={isMobileLayout}
        facebookImages={[{ url: IMAGES.facebookImage, width: 1200, height: 630 }]}
        twitterImages={[
          {
            url: `${config.canonicalRootURL}${IMAGES.twitterImage}`,
            width: 600,
            height: 314,
          },
        ]}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'WebPage',
          description: schemaDescription,
          name: schemaTitle,
          image: [schemaImage],
        }}
      >
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer isDefaultMobileHeader={true} />
          </LayoutWrapperTopbar>

          <LayoutWrapperMain>
            {/* <SellerSection sellerSubscriptions={this.props.sellerSubscriptions} /> */}
            {!this.props.sellerSubscriptions.length && !this.props.buyerSubscriptions.length && (
              <div className={css.subscriptiontitle}>
                <FormattedMessage
                  className={css.title}
                  id={'UserSubscription.noBuyerSunscription'}
                />
              </div>
            )}
            {!!this.props.sellerSubscriptions.length && (
              <div className={css.sellerWrapper}>
                <div className={css.breadCrumbWrapper}>
                  <NamedLink name="HomePage">Home</NamedLink>
                  <span className={css.breadcrumbArrow}>&gt;</span>
                  <NamedLink name="UserSubscriptions">My Subscription</NamedLink>
                </div>
                {!!this.props.sellerSubscriptions.length && (
                  <>
                    <div className={css.subscriptiontitle}>
                      <FormattedMessage
                        className={css.title}
                        id={'UserSubscription.noSellerSunscription'}
                      />
                    </div>
                    <div className={css.subscriptionWrapper}>
                      {this.props.sellerSubscriptions.map(listing => (
                        <SubscriptionCard
                          className={css.listingCard}
                          listing={listing}
                          history={this.props.history}
                          intl={this.props.intl}
                          viewport={this.props.viewport}
                          onCancelSubscription={this.onCancelSubscription}
                          currentUser={this.props.currentUser}
                          isSeller={true}
                          openCancelMessage={id => {
                            this.setState({ cancelModalOpen: id });
                          }}
                        />
                      ))}
                    </div>
                  </>
                )}
              </div>
            )}
            <div className={css.sellerWrapper}>
              {!!this.props.buyerSubscriptions.length && (
                <>
                  <div className={css.subscriptiontitle}>
                    <FormattedMessage
                      className={css.title}
                      id={'UserSubscription.buyerSunscription'}
                    />
                  </div>
                  <div className={css.subscriptionWrapper}>
                    {this.props.buyerSubscriptions.map(listing => (
                      <SubscriptionCard
                        className={css.listingCard}
                        listing={listing}
                        history={this.props.history}
                        intl={this.props.intl}
                        viewport={this.props.viewport}
                        onCancelSubscription={this.onCancelSubscription}
                        currentUser={this.props.currentUser}
                        isSeller={false}
                        openCancelMessage={id => {
                          this.setState({ cancelModalOpen: id });
                        }}
                      />
                    ))}
                  </div>
                </>
              )}
            </div>
          </LayoutWrapperMain>

          {!isMobileLayout ? (
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          ) : (
            <LayoutWrapperFooter
              className={classNames(css.layoutFooter, {
                [css.activeleftbarFooter]: this.state.activeFooterTab === 'leftbar',
                [css.createFooterTab]:
                  this.state.activeFooterTab === 'create' ||
                  this.state.activeFooterTab === 'activitySearch',
              })}
            >
              {!!isMobileLayout && !(this.state.activeFooterTab === 'leftbar') && (
                <FooterBottomMenu
                  changeActivieFooterTab={value => this.setState({ activeFooterTab: value })}
                  history={this.props.history}
                />
              )}
            </LayoutWrapperFooter>
          )}
        </LayoutSingleColumn>
        <Modal
          isOpen={!!(this.state.activeFooterTab === 'leftbar')}
          onClose={() => this.setState({ activeFooterTab: 'search' })}
          id="filterModal"
          onManageDisableScrolling={() => {}}
          className={css.modalLeftBar}
        >
          <LeftBar
            isMobileLayout={isMobileLayout}
            history={this.props.history}
            onShowEnquiryTab={this.onSearchProduct}
            onLogoClick={() => this.setState({ activeFooterTab: 'search' })}
            onClose={() => this.setState({ activeFooterTab: 'search' })}
          />
        </Modal>
        <Modal
          id="cancel-subscription"
          isOpen={!!this.state.cancelModalOpen}
          onClose={() => {
            this.setState({ cancelModalOpen: null });
          }}
          onManageDisableScrolling={() => {}}
        >
          <div>Are you sure, You want to cancel subscription?</div>
          <div className={css.btnWrapper}>
            <SecondaryButton
              className={css.confirmButton}
              onClick={() => {
                this.setState({ cancelModalOpen: null });
              }}
            >
              Cancel
            </SecondaryButton>
            <PrimaryButton
              className={css.confirmButton}
              onClick={() => {
                this.onCancelSubscription(this.state.cancelModalOpen);
                setTimeout(() => this.setState({ cancelModalOpen: null }), 1000);
              }}
            >
              Confirm
            </PrimaryButton>
          </div>
        </Modal>
      </Page>
    );
  }
}
const mapStateToProps = state => {
  const { currentUser, currentUserWasFetched } = state.user;
  const { buyerSubscriptionRef, sellerSubscriptionRef } = state.UserSubscription;

  const buyerSubscriptions = getMarketplaceEntities(state, buyerSubscriptionRef);
  const sellerSubscriptions = getMarketplaceEntities(state, sellerSubscriptionRef);

  return {
    currentUser,
    currentUserWasFetched,
    sellerSubscriptions,
    buyerSubscriptions,
  };
};
const mapDispatchToProps = dispatch => ({
  loadData: data => dispatch(loadData()),
  queryPromotedListings: searchquery => dispatch(queryPromotedListings(searchquery)),
  ongetSellerSubscription: () => dispatch(getSellerSubscription()),
  ongetBuyerSubscription: () => dispatch(getBuyerSubscription()),
});

const UserSubscriptions = compose(
  withRouter,
  withViewport,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(UserSubscriptionsComponent);

UserSubscriptions.loadData = loadData;

export default UserSubscriptions;
