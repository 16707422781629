import React, { memo, useEffect } from 'react';

import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';
import {
  subCategoriesFilterOptions,
  subCategoriesFilterOptionsEducational,
  subCategoriesFilterOptionsTennis,
} from 'marketplace-custom-config';
import { withRouter } from 'react-router-dom';

import { Loading, PaginationButtons } from '../../../components';

import { FormattedMessage, injectIntl } from 'react-intl';
import css from '../JobsBoard.css';
import Switch from 'react-switch';
import { useState } from 'react';
import JobsBoardCard from './JobsBoardCard';
import { withViewport } from 'util/contextHelpers';
import { compose } from 'redux';
import { MAX_MOBILE_SCREEN_WIDTH } from 'util/constants';

const JobsBoardForm = props => {
  const {
    jobsBoardListing = [],
    intl,
    prop,
    loading,
    filterSearch,
    history,
    handleOnChange,
    handleOnCheck,
    is_active,
    handleSwitch,
    handlePage,
    jobsPagination,
    checked,
    isEducationSite,
    isTennisSite,
    viewport,
  } = props;
  const [isCheckAll, setIsCheckAll] = useState(checked ? false : true);
  const [isCheck, setIsCheck] = useState([...checked]);
  const isMobileLayout = !!viewport.width && viewport.width < MAX_MOBILE_SCREEN_WIDTH;
  const [openCategoryDD, setOpenCategoryDD] = useState(false);
  // const [selectedListing, setSelectedListing] = useState('');

  useEffect(() => {
    if (!isCheckAll && isCheck.length === 0) {
      setIsCheckAll(true);
      setIsCheck([]);
    }
    // }, [isCheck, isCheckAll]);

    // useEffect(() => {
    if (isCheck.length !== 0) {
      handleOnCheck(isCheck);
    } else {
      handleOnCheck('');
    }
  }, [isCheck, isCheckAll]);

  // checkedAll
  const handleSelectAll = e => {
    setIsCheckAll(true);
    setIsCheck([]);
  };

  const subCategoryData = isEducationSite
    ? subCategoriesFilterOptionsEducational
    : isTennisSite
    ? subCategoriesFilterOptionsTennis
    : subCategoriesFilterOptions;

  const handleClick = e => {
    const { id, checked } = e.target;
    if (isCheckAll) {
      const check = subCategoryData.filter(item => item.key === id).map(li => li.key);
      setIsCheck(check);
      setIsCheckAll(!isCheckAll);
    } else {
      setIsCheck([...isCheck, id]);
      if (!checked) {
        setIsCheck(isCheck.filter(item => item !== id));
      }
    }
  };
  return (
    <FinalForm
      {...prop}
      onSubmit={() => {}}
      mutators={{ ...arrayMutators }}
      render={fieldRenderProps => {
        const { rootClassName, className, values, form } = fieldRenderProps;
        const classes = classNames(rootClassName || css.root, className);
        return (
          <div className={css.enquiryCardWrapper}>
            <div className={css.enquiryCardTopBar}>
              <div className={css.enquiryCardTitle}>Recent Customer Request</div>
              <div className={css.aciveBtn}>
                <div
                  className={classNames(css.switch, {
                    [css.switchChecked]: is_active === true,
                    [css.switchUnchecked]: is_active !== true,
                  })}
                >
                  <Switch
                    uncheckedIcon={false}
                    checkedIcon={false}
                    onChange={() => handleSwitch(true)}
                    checked={is_active}
                  />{' '}
                  <label>Open Jobs</label>
                </div>
                <div
                  className={classNames(css.switch, {
                    [css.switchChecked]: is_active === false,
                    [css.switchUnchecked]: is_active !== false,
                  })}
                >
                  <Switch
                    uncheckedIcon={false}
                    checkedIcon={true}
                    onChange={() => handleSwitch(false)}
                    checked={is_active !== null && !is_active}
                  />
                  <label>Filled Jobs</label>
                </div>
              </div>
            </div>
            <div className={css.enquiryCard}>
              <div className={css.formatWrapper}>
                <div className={css.searchWrapper}>
                  <div className={css.filterWrapper}>
                    <div className={css.formatTitle}>Filter</div>
                    <div className={css.formatBody}>
                      <input
                        type="text"
                        id="search"
                        name="search"
                        placeholder="Search"
                        inputRootClass={css.filterInputBody}
                        value={filterSearch}
                        onChange={event => {
                          const value = event.target.value;
                          form.change('search', value);
                          handleOnChange(value);
                        }}
                      />
                    </div>
                  </div>
                  <div className={css.categoryWrapper}>
                    <div
                      onClick={() => setOpenCategoryDD(!openCategoryDD)}
                      className={classNames(
                        isMobileLayout ? css.formatTitleCategory : css.formatTitle,
                        isMobileLayout && openCategoryDD && css.formatTitleOpen
                      )}
                    >
                      Category
                      {isMobileLayout && (
                        <div>
                          {openCategoryDD ? (
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
                              <path d="m12 6.586-8.707 8.707 1.414 1.414L12 9.414l7.293 7.293 1.414-1.414L12 6.586z" />
                            </svg>
                          ) : (
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
                              <path d="M12 17.414 3.293 8.707l1.414-1.414L12 14.586l7.293-7.293 1.414 1.414L12 17.414z" />
                            </svg>
                          )}
                        </div>
                      )}
                    </div>

                    <div
                      className={classNames(
                        css.categoryBody,
                        isMobileLayout &&
                          (openCategoryDD ? css.categoryBodyDDFull : css.categoryDDShrink)
                      )}
                    >
                      <ul>
                        <li>
                          <input
                            className={css.styledCheckbox}
                            id={'selectAll'}
                            name={'selectAll'}
                            type={'checkbox'}
                            onChange={handleSelectAll}
                            checked={isCheckAll}
                          />
                          <label className={css.labelText} htmlFor="selectAll">
                            {'Search all'}
                          </label>
                        </li>
                        {subCategoryData?.map((data, i) => {
                          return (
                            <li
                              key={data.key + i}
                              className={isCheck.includes(data.key) && css.selectedCategory}
                            >
                              <input
                                className={css.styledCheckbox}
                                type={'checkbox'}
                                id={data.key}
                                name={data.key}
                                onChange={handleClick}
                                checked={isCheck.includes(data.key)}
                              />
                              <label className={css.labelText} htmlFor={data.key}>
                                {data.label}
                              </label>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className={css.JobsBoardCard}>
                {loading && <Loading />}
                {!loading &&
                  jobsBoardListing.length !== 0 &&
                  jobsBoardListing.map(jobsBoardData => (
                    <JobsBoardCard
                      history={history}
                      jobsBoardData={jobsBoardData}
                      isEducationSite={isEducationSite}
                      // isTennisSite={isTennisSite}
                    />
                  ))}
                {!loading && jobsBoardListing.length === 0 && (
                  <FormattedMessage id="HomePage.NoListingsFound" />
                )}
              </div>
            </div>
            <div className={css.jobsBoardPagination}>
              {/* {totalPages} */}
              <PaginationButtons
                page={jobsPagination?.page}
                totalPages={jobsPagination?.paginationLimit}
                onPageClick={handlePage}
              />
            </div>
          </div>
        );
      }}
    />
  );
};

export default memo(compose(withRouter, injectIntl, withViewport)(JobsBoardForm));
