import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cns from 'classnames';
import { SmallScrollArrow, ScrollArrow } from 'components/index';

import css from 'components/HorizontalScroll/HorizontalScroll.css';

const getOffsetWidth = (elem, offsetWidth) => {
  const styles = typeof window !== 'undefined' && window.getComputedStyle(elem);
  const innerOffsetWidth = offsetWidth || elem.offsetWidth;
  const [marginLeft, marginRight] = [styles.marginLeft, styles.marginRight].map(value =>
    Number(value.split('px')[0])
  );
  return innerOffsetWidth + marginLeft + marginRight;
};

const getTotalNumberOfListCard = () => {
  if (typeof window !== 'undefined') {
    if (window.screen.width <= 425) {
      return 3;
    } else if (window.screen.width < 870) {
      return 3;
    } else if (window.screen.width < 1270) {
      return 4;
    } else if (window.screen.width < 1480) {
      return 6;
    } else {
      return 8;
    }
  } else {
    return 6;
  }
};

function HorizontalScroll({
  className,
  scrollClassName,
  arrowClassName,
  children,
  scrollArrowContainerClassNames,
  isSmallScrollArrow,
  offsetWidth,
  autoFlipOn = false,
}) {
  const scrollContainerRef = useRef(null);
  const numberOfListcard = getTotalNumberOfListCard();
  const [currentCount, setCurrentcount] = useState(1);
  const isArrowBtnShow = typeof window !== 'undefined' && window.screen.width > 1023;
  let count = 1;
  let totalCount = (children && children.length - numberOfListcard) || 1;
  if (totalCount < 1) {
    totalCount = 1;
  }

  const onScrollArrowClickLeft = useCallback(
    e => {
      if (!scrollContainerRef.current) return;
      if (count !== 1) {
        // eslint-disable-next-line
        count -= numberOfListcard;
        setCurrentcount(count);
      }
      const listingCard = scrollContainerRef.current.firstChild;
      const offset = getOffsetWidth(listingCard, offsetWidth);

      scrollContainerRef.current.scrollLeft =
        scrollContainerRef.current.scrollLeft - offset * numberOfListcard;
    },
    [scrollContainerRef]
  );

  const onScrollArrowClickRight = useCallback(
    e => {
      if (!scrollContainerRef.current) return;
      if (count <= totalCount) {
        // eslint-disable-next-line
        count += numberOfListcard;
        setCurrentcount(count);
      }

      const listingCard = scrollContainerRef.current.firstChild;
      const offset = getOffsetWidth(listingCard, offsetWidth);

      scrollContainerRef.current.scrollLeft =
        scrollContainerRef.current.scrollLeft + offset * numberOfListcard;
    },
    [scrollContainerRef]
  );

  useEffect(() => {
    if (autoFlipOn) {
      setTimeout(() => {
        if (currentCount <= totalCount) {
          onScrollArrowClickRight();
        } else {
          // setCurrentcount(1);
          onScrollArrowClickLeft();
        }
      }, 4000);
    }
  }, [currentCount, autoFlipOn]);

  const rootClassName = cns(css.root, className || '');
  const scrollContainerClassName = cns(css.scrollContainer, scrollClassName || '');
  const arrowClassNames = cns(css.scrollArrow, arrowClassName || '');
  const arrowContainerClassNames = cns(scrollArrowContainerClassNames);

  const scrollContent = isArrowBtnShow ? (
    isSmallScrollArrow ? (
      <>
        <div className={css.sectionCounter}>
          {currentCount}/{totalCount}
        </div>
        {currentCount !== 1 ? (
          <SmallScrollArrow
            className={arrowClassNames}
            onClick={onScrollArrowClickLeft}
            direction={'left'}
            disable={currentCount === 1}
          />
        ) : null}
        {currentCount < totalCount ? (
          <SmallScrollArrow
            className={arrowClassNames}
            onClick={onScrollArrowClickRight}
            direction={'right'}
            disable={currentCount > totalCount}
          />
        ) : null}
      </>
    ) : (
      <>
        {currentCount !== 1 ? (
          <ScrollArrow
            className={arrowClassNames}
            onClick={onScrollArrowClickLeft}
            disable={currentCount === 1}
            direction={'left'}
          />
        ) : null}

        {currentCount <= totalCount ? (
          <ScrollArrow
            className={arrowClassNames}
            onClick={onScrollArrowClickRight}
            disable={currentCount > totalCount}
            direction={'right'}
          />
        ) : null}
      </>
    )
  ) : null;

  return (
    <div className={rootClassName}>
      <div ref={scrollContainerRef} className={scrollContainerClassName}>
        {children}
      </div>
      <div className={arrowContainerClassNames}>{scrollContent}</div>
    </div>
  );
}

HorizontalScroll.propTypes = {
  className: PropTypes.string,
  scrollClassName: PropTypes.string,
  arrowClassName: PropTypes.string,
  children: PropTypes.node,
};

export default HorizontalScroll;
