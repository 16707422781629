import React from 'react';
import { FormattedMessage } from '../../../../util/reactIntl';
import classNames from 'classnames';
import plusSvg from '../../../../assets/SellerPage/PlusSvg.svg';
import sendSvg from '../../../../assets/SellerPage/send.svg';
import EnquiriesActivityFeed from '../../../../components/EnquiriesActivityFeed/EnquiriesActivityFeed';
import closeSvg from '../../../../assets/SellerPage/close.svg';
import MenuIconBtnSvg from '../../../../assets/SellerPage/MenuIcon.svg';
import { UserDisplayName, NamedLink, Button, Avatar } from '../../../../components';
import css from './FeedSection.css';

// Functional component as a helper to build ActivityFeed section
const FeedSection = props => {
  const {
    className,
    rootClassName,
    currentTransaction,
    currentUser,
    fetchMessagesError,
    fetchMessagesInProgress,
    initialMessageFailed,
    messages,
    oldestMessagePageFetched,
    onShowMoreMessages,
    onOpenReviewModal,
    totalMessagePages,
    type,
    intl,
    goBack,
    sendMessageContentValue,
    setSendMessageContentValue,
    onClickSendHandler,
    isMobileLayout,
    onMenuIconClick,
  } = props;

  const { customer, provider } = currentTransaction;
  const isOrder = type === 'order';
  const otherUser = isOrder ? provider : customer;
  const senderDisplayName = <UserDisplayName user={otherUser ? otherUser : null} intl={intl} />;
  const listingName = currentTransaction.listing.attributes.title;

  const txTransitions = currentTransaction.attributes.transitions
    ? currentTransaction.attributes.transitions
    : [];
  const hasOlderMessages = totalMessagePages > oldestMessagePageFetched;

  const showFeed =
    messages.length > 0 || txTransitions.length > 0 || initialMessageFailed || fetchMessagesError;

  const classes = classNames(rootClassName || css.feedContainer, className);

  return showFeed ? (
    <div className={classes}>
      {isMobileLayout && (
        <div className={css.titleWrapper}>
          <img onClick={onMenuIconClick} src={MenuIconBtnSvg} alt={'memu_icon'} />
          <h1 className={css.inboxMainHeading}>{'Enquiries'}</h1>
        </div>
      )}
      <div className={css.enquiryFeedHead}>
        <div className={css.profileImages}>
          <Avatar className={css.avatar} user={otherUser} disableProfileLink />
        </div>
        <div className={css.profileTitle}>
          <h4>{senderDisplayName}</h4>
          <span className={css.feedUserName}>{listingName}</span>
        </div>
        <div className={css.goBackIcon} onClick={goBack}>
          <img src={closeSvg} alt={'--'} />
        </div>
        <div className={css.feedIconDot}>
          <NamedLink
            className={css.itemLink}
            name={isOrder ? 'OrderDetailsPage' : 'SaleDetailsPage'}
            params={{ id: currentTransaction.id.uuid }}
          >
            ...
          </NamedLink>
        </div>
      </div>

      {initialMessageFailed ? (
        <p className={css.messageError}>
          <FormattedMessage id="TransactionPanel.initialMessageFailed" />
        </p>
      ) : null}
      {fetchMessagesError ? (
        <p className={css.messageError}>
          <FormattedMessage id="TransactionPanel.messageLoadingFailed" />
        </p>
      ) : null}
      <EnquiriesActivityFeed
        className={css.feed}
        messages={messages}
        transaction={currentTransaction}
        currentUser={currentUser}
        hasOlderMessages={hasOlderMessages && !fetchMessagesInProgress}
        onOpenReviewModal={onOpenReviewModal}
        onShowOlderMessages={onShowMoreMessages}
        fetchMessagesInProgress={fetchMessagesInProgress}
      />

      <div className={css.messageToolsWrapper}>
        <div className={css.addIcon}>
          <img src={plusSvg} alt={'--'} />
        </div>

        <div className={css.SendMsgKeywordWrap}>
          <div className={css.MsgWrap}>
            <div className={css.sendInputWrap}>
              <input
                value={sendMessageContentValue}
                onChange={e => setSendMessageContentValue(e.target.value)}
                placeholder={'Type a Message here...'}
              />
              <Button className={css.sendButton} onClick={onClickSendHandler}>
                <img src={sendSvg} alt={'--'} />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default FeedSection;
