import React from 'react';
import { capitalize } from 'lodash';
import { FormattedMessage } from '../../../../util/reactIntl';
import SportsLocationForm from '../SportsLocationForm/SportsLocationForm'
import css from './TopVideoSection.css';


function TopVideoSection(props) {
    const { selectedCategory, videoUrl, onSubmit, location } = props;

    return (<section className={css.hostedByProsVideoSection}>
        <div className={css.hostedByProsWrap}>
            <div className={css.hostedProsHeadingWrap}>
                <h3 className={css.hostedByProsTitle}>{capitalize(selectedCategory)} <FormattedMessage id={'ModifiedSearchFilters.activitiesHostedBy'} /><span className={css.blueText}><FormattedMessage id={'ModifiedSearchFilters.pros'} /></span></h3>
                <SportsLocationForm onSubmit={onSubmit} location={location}/>
            </div>

            <div className={css.videoWrapper}>
                <video src={videoUrl} loop autoPlay muted playsInline width="100%">
                    <source src={videoUrl} type={`video/mp4`} />
                </video>
            </div>
        </div>
    </section>);
}

export default TopVideoSection;
