import React, { useState } from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';

// Import configs and util modules
import config from '../../config';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
// import { isOldTotalMismatchStockError } from '../../util/errors';
import * as validators from '../../util/validators';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';

// Import shared components
import { Button, Form, FieldCurrencyInput } from '../../components';

// Import modules from this directory
import css from './EditListingPricingStockForm.css';

const { Money } = sdkTypes;

export const EditListingPricingStockFormComponent = props => {
  const [currencies, setCurrencies] = useState(
    props?.initialValues?.currency || props?.initialValues?.price?.currency || config.currency
  );
  const currencyType = [
    {
      currency: config.currencySGD,
      // symbol: '$',
    },
    {
      currency: config.currencyAUD,
      symbol: '$',
    },
    {
      currency: config.currencyGBP,
      symbol: '£',
    },
    {
      currency: config.currencyUSD,
      symbol: '$',
    },
    {
      currency: config.currencyAED,
      symbol: 'د.إ',
    },
  ];
  return (
    <FinalForm
      {...props}
      render={formRenderProps => {
        const {
          form,
          autoFocus,
          className,
          disabled,
          ready,
          handleSubmit,
          intl,
          invalid,
          pristine,
          saveActionMsg,
          updated,
          updateInProgress,
          fetchErrors,
        } = formRenderProps;

        const priceRequired = validators.required(
          intl.formatMessage({
            id: 'EditListingPricingStockForm.priceRequired',
          })
        );
        const minPrice = new Money(config.listingMinimumPriceSubUnits, config.currency);
        const minPriceRequired = validators.moneySubUnitAmountAtLeast(
          intl.formatMessage(
            {
              id: 'EditListingPricingStockForm.priceTooLow',
            },
            {
              minPrice: formatMoney(intl, minPrice),
            }
          ),
          config.listingMinimumPriceSubUnits
        );
        const priceValidators = config.listingMinimumPriceSubUnits
          ? validators.composeValidators(priceRequired, minPriceRequired)
          : priceRequired;

        // const stockValidator = validators.numberAtLeast(
        //   intl.formatMessage({ id: 'EditListingPricingStockForm.stockIsRequired' }),
        //   0
        // );

        const classes = classNames(css.root, className);
        const submitReady = (updated && pristine) || ready;
        const submitInProgress = updateInProgress;
        const submitDisabled = invalid || disabled || submitInProgress;
        const { updateListingError, showListingsError } = fetchErrors || {};

        return (
          <Form onSubmit={handleSubmit} className={classes}>
            {updateListingError ? (
              <p className={css.error}>
                <FormattedMessage id="EditListingPricingForm.updateFailed" />
              </p>
            ) : null}
            {showListingsError ? (
              <p className={css.error}>
                <FormattedMessage id="EditListingPricingForm.showListingFailed" />
              </p>
            ) : null}
            <div className={css.editListingTypeWrap}>
              <label className={css.editListingLabel}>Currency</label>
              <div className={css.editListingCardWrap}>
                {currencyType.map(type => (
                  <div
                    className={classNames(
                      css.buttonMain,
                      currencies === type.currency && css.selected
                    )}
                  >
                    <div
                      onClick={() => {
                        setCurrencies(type.currency);
                        form.change('currency', type.currency);
                        props?.onCurrencychange && props.onCurrencychange(type.currency);
                        // setErrorMessage(false);
                      }}
                      className={css.buttonWrapper}
                    >
                      <div className={css.editListingTypeText}>{type.currency}</div>
                      <div className={css.svgWrapper}>{type.symbol}</div>
                    </div>
                    <div className={css.checkMark}>
                      <svg
                        width="12"
                        height="10"
                        viewBox="0 0 12 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1 5.8L3.85714 9L11 1"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          fill="transparent"
                        />
                      </svg>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <FieldCurrencyInput
              id="price"
              name="price"
              className={css.input}
              autoFocus={autoFocus}
              label={intl.formatMessage({ id: 'EditListingPricingStockForm.pricePerProduct' })}
              placeholder={intl.formatMessage({
                id: 'EditListingPricingStockForm.priceInputPlaceholder',
              })}
              currencyConfig={config.formatedCurrencyConfig(currencies)}
              validate={priceValidators}
              onPriceChange={props?.onPriceChange}
            />

            {/* <FieldTextInput
            className={css.input}
            id="stock"
            name="stock"
            label={intl.formatMessage({ id: 'EditListingPricingStockForm.maxCourses' })}
            placeholder={intl.formatMessage({
              id: 'EditListingPricingStockForm.stockCoursePlaceholder',
            })}
            type="number"
            min={0}
            validate={stockValidator}
          /> */}

            <Button
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={submitReady}
            >
              {saveActionMsg}
            </Button>
          </Form>
        );
      }}
    />
  );
};

EditListingPricingStockFormComponent.defaultProps = { fetchErrors: null };

EditListingPricingStockFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingPricingStockFormComponent);
