import React, { memo } from 'react';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import css from './RecommendedUserList.css';
import { withViewport } from 'util/contextHelpers';
import { compose } from 'redux';
import RecommendedListCard from './RecommendedListCard';
import { Loading } from 'components';

const RecommendedUserList = props => {
  const { recommendationList = [], prop, isLoading } = props;
  return (
    <FinalForm
      {...prop}
      onSubmit={() => {}}
      mutators={{ ...arrayMutators }}
      render={fieldRenderProps => {
        const { rootClassName, className, values, form } = fieldRenderProps;
        const classes = classNames(rootClassName || css.root, className);
        return (
          <div className={css.enquiryCardWrapper}>
            <div className={css.enquiryCard}>
              {isLoading && <Loading />}
              {!isLoading &&
                recommendationList.length !== 0 &&
                recommendationList.map(recommendedData => (
                  <div className={css.JobsBoardCard}>
                    <RecommendedListCard recommendedData={recommendedData} />
                  </div>
                ))}
              {!isLoading && recommendationList.length === 0 && (
                <FormattedMessage id="HomePage.NoRewardsFound" />
              )}
            </div>
          </div>
        );
      }}
    />
  );
};

export default memo(compose(withRouter, injectIntl, withViewport)(RecommendedUserList));
