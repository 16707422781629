import { reject } from 'lodash';
import { updatedEntities, denormalisedEntities } from '../../util/data';
import { storableError } from '../../util/errors';

// ================ Action types ================ //

export const FETCH_LISTINGS_REQUEST = 'app/MyActivity/FETCH_LISTINGS_REQUEST';
export const FETCH_LISTINGS_SUCCESS = 'app/MyActivity/FETCH_LISTINGS_SUCCESS';
export const FETCH_LISTINGS_ERROR = 'app/MyActivity/FETCH_LISTINGS_ERROR';

export const OPEN_LISTING_REQUEST = 'app/MyActivity/OPEN_LISTING_REQUEST';
export const OPEN_LISTING_SUCCESS = 'app/MyActivity/OPEN_LISTING_SUCCESS';
export const OPEN_LISTING_ERROR = 'app/MyActivity/OPEN_LISTING_ERROR';

export const CLOSE_LISTING_REQUEST = 'app/MyActivity/CLOSE_LISTING_REQUEST';
export const CLOSE_LISTING_SUCCESS = 'app/MyActivity/CLOSE_LISTING_SUCCESS';
export const CLOSE_LISTING_ERROR = 'app/MyActivity/CLOSE_LISTING_ERROR';

export const ADD_OWN_ENTITIES = 'app/MyActivity/ADD_OWN_ENTITIES';

// ================ Reducer ================ //

const initialState = {
  fetchedData: [],
  pagination: null,
  queryParams: null,
  queryInProgress: false,
  queryListingsError: null,
  currentPageResultIds: [],
  ownEntities: {},
  openingListing: null,
  openingListingError: null,
  closingListing: null,
  closingListingError: null,
};

const resultIds = data => data.data.map(l => l.id);

const merge = (state, sdkResponse) => {
  const apiResponse = sdkResponse.data;
  return {
    ...state,
    ownEntities: updatedEntities({ ...state.ownEntities }, apiResponse),
  };
};

const updateListingAttributes = (state, listingEntity) => {
  const oldListing = state.ownEntities.ownListing[listingEntity.id.uuid];
  const updatedListing = { ...oldListing, attributes: listingEntity.attributes };
  const ownListingEntities = {
    ...state.ownEntities.ownListing,
    [listingEntity.id.uuid]: updatedListing,
  };
  return {
    ...state,
    ownEntities: { ...state.ownEntities, ownListing: ownListingEntities },
  };
};

const manageListingsPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_LISTINGS_REQUEST:
      return {
        ...state,
        queryParams: payload.queryParams,
        queryInProgress: true,
        queryListingsError: null,
        currentPageResultIds: [],
      };
    case FETCH_LISTINGS_SUCCESS:
      return {
        ...state,
        currentPageResultIds: resultIds(payload.data),
        pagination: payload.data.meta,
        queryInProgress: false,
      };
    case FETCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, queryInProgress: false, queryListingsError: payload };

    case OPEN_LISTING_REQUEST:
      return {
        ...state,
        openingListing: payload.listingId,
        openingListingError: null,
      };
    case OPEN_LISTING_SUCCESS:
      return {
        ...updateListingAttributes(state, payload.data),
        openingListing: null,
      };
    case OPEN_LISTING_ERROR: {
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        openingListing: null,
        openingListingError: {
          listingId: state.openingListing,
          error: payload,
        },
      };
    }

    case CLOSE_LISTING_REQUEST:
      return {
        ...state,
        closingListing: payload.listingId,
        closingListingError: null,
      };
    case CLOSE_LISTING_SUCCESS:
      return {
        ...updateListingAttributes(state, payload.data),
        closingListing: null,
      };
    case CLOSE_LISTING_ERROR: {
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        closingListing: null,
        closingListingError: {
          listingId: state.closingListing,
          error: payload,
        },
      };
    }

    case ADD_OWN_ENTITIES:
      return {
        ...state,
        fetchedData: payload,
      };

    default:
      return state;
  }
};

export default manageListingsPageReducer;

// ================ Selectors ================ //

/**
 * Get the denormalised own listing entities with the given IDs
 *
 * @param {Object} state the full Redux store
 * @param {Array<UUID>} listingIds listing IDs to select from the store
 */
export const getOwnListingsById = (state, listingIds) => {
  const { ownEntities } = state.MyActivity;
  const resources = listingIds.map(id => ({
    id,
    type: 'ownListing',
  }));
  const throwIfNotFound = false;
  return denormalisedEntities(ownEntities, resources, throwIfNotFound);
};

// ================ Action creators ================ //

// This works the same way as addMarketplaceEntities,
// but we don't want to mix own listings with searched listings
// (own listings data contains different info - e.g. exact location etc.)
export const addOwnEntities = sdkResponse => ({
  type: ADD_OWN_ENTITIES,
  payload: sdkResponse,
});

export const openListingRequest = listingId => ({
  type: OPEN_LISTING_REQUEST,
  payload: { listingId },
});

export const openListingSuccess = response => ({
  type: OPEN_LISTING_SUCCESS,
  payload: response.data,
});

export const openListingError = e => ({
  type: OPEN_LISTING_ERROR,
  error: true,
  payload: e,
});

export const closeListingRequest = listingId => ({
  type: CLOSE_LISTING_REQUEST,
  payload: { listingId },
});

export const closeListingSuccess = response => ({
  type: CLOSE_LISTING_SUCCESS,
  payload: response.data,
});

export const closeListingError = e => ({
  type: CLOSE_LISTING_ERROR,
  error: true,
  payload: e,
});

export const queryListingsRequest = queryParams => ({
  type: FETCH_LISTINGS_REQUEST,
  payload: { queryParams },
});

export const queryListingsSuccess = response => ({
  type: FETCH_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const queryListingsError = e => ({
  type: FETCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

// Throwing error for new (loadData may need that info)
export const queryOwnListingsMatch = queryParams => (dispatch, getState, sdk) => {
  dispatch(queryListingsRequest(queryParams));
  const { listingType, perPage, ...rest } = queryParams;
  const params = {
    ...rest,
    pub_listing_type: listingType,
  };

  return sdk.listings
    .query(params)
    .then(response => {
      const data = response.data.data;
      dispatch(addOwnEntities(data));
      dispatch(queryListingsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(queryListingsError(storableError(e)));
      throw e;
    });
};

export const closeListing = listingId => (dispatch, getState, sdk) => {
  dispatch(closeListingRequest(listingId));

  return sdk.ownListings
    .close({ id: listingId }, { expand: true })
    .then(response => {
      dispatch(closeListingSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(closeListingError(storableError(e)));
    });
};

export const openListing = listingId => (dispatch, getState, sdk) => {
  dispatch(openListingRequest(listingId));

  return sdk.ownListings
    .open({ id: listingId }, { expand: true })
    .then(response => {
      dispatch(openListingSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(openListingError(storableError(e)));
    });
};

export const updateDeclineStatus = (id, joinUserId, joinUserMail) => async (
  dispatch,
  getState,
  sdk
) => {
  const currentUser = getState().user.currentUser;
  const joinReq = await sdk.listings
    .show({ id })
    .then(response => {
      const listingRes = response.data?.data;
      return listingRes.attributes.publicData.joinRequest;
    })
    .catch(e => {
      console.log(e);
    });

  if (joinReq) {
    // Find the joinRequest item with the specified userId
    const updatedJoinReq = joinReq.map(item => {
      if (item.userId === joinUserId) {
        // Fetch the email from the item
        joinUserMail = item.userMail;

        // You can now use 'email' for any purpose you need, such as sending a notification or storing it for later use.

        return {
          ...item,
          currentStatus: 'declined',
        };
      }
      return item;
    });

    // Update the listing with the modified joinRequest
    const params = {
      id: id,
      publicData: {
        joinRequest: updatedJoinReq,
      },
    };

    await sdk.ownListings.update(params);

    // After the update is successful, you can dispatch an action to notify your MyActivity.js file
    dispatch(
      queryOwnListingsMatch({
        authorId: currentUser.id.uuid,
        listingType: 'match-activity',
      })
    );

    // Pass the email to your MyActivity component
    return joinUserMail;
  }
};

export const updateAcceptStatus = (id, joinUserId, joinUserMail) => async (
  dispatch,
  getState,
  sdk
) => {
  const currentUser = getState().user.currentUser;
  const joinReq = await sdk.listings
    .show({ id })
    .then(response => {
      const listingRes = response.data?.data;
      return listingRes.attributes.publicData.joinRequest;
    })
    .catch(e => {
      console.log(e);
    });

  if (joinReq) {
    // Find the joinRequest item with the specified userId
    const updatedJoinReq = joinReq.map(item => {
      if (item.userId === joinUserId) {
        // Fetch the email from the item
        joinUserMail = item.userMail;

        // You can now use 'email' for any purpose you need, such as sending a notification or storing it for later use.

        return {
          ...item,
          currentStatus: 'accepted',
        };
      }
      return item;
    });

    // Update the listing with the modified joinRequest
    const params = {
      id: id,
      publicData: {
        joinRequest: updatedJoinReq,
      },
    };

    await sdk.ownListings.update(params);

    // After the update is successful, you can dispatch an action to notify your MyActivity.js file
    dispatch(
      queryOwnListingsMatch({
        authorId: currentUser.id.uuid,
        listingType: 'match-activity',
      })
    );

    // Pass the email to your MyActivity component
    return joinUserMail;
  }
};
