import React, { useEffect } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { compose } from 'redux';
import { withViewport } from '../../util/contextHelpers';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  FooterBottomMenu,
  Footer,
  NamedLink,
  Page,
  ProductListing,
} from 'components';
import { useDispatch, useSelector } from 'react-redux';
import { IMAGES } from 'util/imagesPath';
import { config } from 'yargs';
import classNames from 'classnames';
import { PrimaryButton2 } from 'components/Button/Button';
import BecomeSeller from '../BlogPage/sections/BecomeSeller/BesomeSeller';
import ProbuddyLogo from '../../assets/ProbuddylogoBlue.svg';
import { queryPromotedListingsOnlineTutor } from 'containers/AllSportsPage/AllSportsPage.duck';
import { LISTING_TYPES } from 'util/constants';
import { getMarketplaceEntities } from 'ducks/marketplaceData.duck';
import css from '../BecomeVendorPage/BecomeVendorPage.css';

const OnlineTutorPage = props => {
  const { intl, history, viewport } = props;
  const dispatch = useDispatch();
  const remoteTutorListing = useSelector(state =>
    getMarketplaceEntities(state, state.AllSportsPage.promotedListingRefsOnlineTutor)
  );

  const schemaTitle = intl.formatMessage({ id: 'OnlineTutorPage.schemaTitle' });
  const schemaDescription = intl.formatMessage({ id: 'OnlineTutorPage.schemaDescription' });
  const schemaImage = `${config.canonicalRootURL}${IMAGES.facebookImage}`;
  const isMobileLayout = viewport.width < 1024;

  useEffect(() => {
    if (typeof window !== 'undefined') {
      localStorage.setItem('isEducationSite', true);
    }
  }, []);

  useEffect(() => {
    dispatch(queryPromotedListingsOnlineTutor({ pub_listing_type: LISTING_TYPES.REMOTE_TUTOR }));
  }, [dispatch]);

  return (
    <Page
      isBottomMenuRequire={false}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: IMAGES.facebookImage, width: 1200, height: 630 }]}
      twitterImages={[
        {
          url: `${config.canonicalRootURL}${IMAGES.twitterImage}`,
          width: 600,
          height: 314,
        },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <div className={css.topBarContainer}>
            <div className={css.probuddyLogo}>
              <NamedLink name="EducationPage">
                <img src={ProbuddyLogo} alt={'probuddy'} />
              </NamedLink>
            </div>
            <NamedLink name="EducationPage">
              <PrimaryButton2 className={css.exploreButton}>Explore the App</PrimaryButton2>
            </NamedLink>
          </div>
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div className={css.content}>
            <section className={css.topSection}>
              <div className={css.topHeadContainer}>
                <img
                  className={css.topHeaderOnlinetutor}
                  src="https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1679996549/probuddy/static/NewEducation/Online_Tutor_1_aaf8xx.jpg"
                  alt=" "
                />
                {/* <div className={css.topHeadSectionLeft}>
                  <div className={css.sectionContainer}>
                    <h1>
                      <FormattedMessage id="OnlineTutorPage.schemaTitle" />
                    </h1>
                    <div className={css.heroDescription}>
                      <FormattedMessage id="OnlineTutorPage.schemaDescription" />
                    </div>
                  </div>
                </div>
                <div className={css.topHeadSectionOnlineTutor}></div>*/}
              </div>
            </section>
            <div className={css.breadCrumbWrapper}>
              <NamedLink name="EducationPage">Home</NamedLink>
              <span className={css.breadcrumbArrow}>&gt;</span>
              <NamedLink name="BecomeTutorPage">Online Tutor</NamedLink>
            </div>
            <section className={css.servicesSection}>
              <div className={css.servicesTitle}>
                <FormattedMessage id="OnlineTutorPage.schemaTitle" />
              </div>
              <div>
                <div className={css.servicesSubheading}>
                  In today's fast-paced world, where everything is just a click away, it's no
                  surprise that online tutoring has become a popular choice for students. With the
                  help of online tutors, students can learn at their own pace, get personalized
                  attention, and access resources that might not be available in a traditional
                  classroom.
                </div>
                <div className={css.servicesSubheading}>
                  Probuddy Education is a platform that offers online tutoring services to students
                  of all ages and academic levels, helping them achieve their academic goals.
                </div>
                <div className={css.servicesSubheading}>
                  Online tutoring is a convenient and effective way for students to improve their
                  academic performance. With the rise of online learning platforms, students can
                  access various subjects and tutors worldwide.
                </div>
                <div className={css.servicesSubheading}>
                  Probuddy Education is a leading platform that provides online tutoring services to
                  students in various subjects, including math, science, and language. The platform
                  offers students personalized attention, ensuring they understand the concepts
                  thoroughly.
                </div>
              </div>
            </section>
            <div className={css.uniqueSectionContainer}>
              <section className={css.reverseSection}>
                <div className={css.sectionImagesContainer}>
                  <img
                    className={css.sectionImages}
                    src={
                      'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1681272491/probuddy/static/NewEducation/OnlineTutor/Online_Tutor_2_izcbbg.webp'
                    }
                    alt="sectionImage1"
                  />
                </div>
                <div className={css.uniqueDesignSection}>
                  <div>
                    <h3>
                      <FormattedMessage id="OnlineTutorPage.Benefits" />
                    </h3>
                    <div className={css.topSpan}>
                      Online tutoring with Probuddy Education has several benefits that traditional
                      classroom learning may not offer. Here are some of the benefits of online
                      tutoring with Probuddy Education
                    </div>
                    <div className={css.topSpan}>
                      Convenience: With online tutoring, students can learn from the comfort of
                      their own homes, eliminating the need to travel to a physical location. This
                      means that students can save time and money on transportation costs.
                    </div>
                    <div className={css.topSpan}>
                      Flexibility: Online tutoring with Probuddy Education allows students to learn
                      independently. Students can schedule their sessions according to availability,
                      making balancing their academic and personal commitments easier.
                    </div>
                    <div className={css.topSpan}>
                      Access to Resources: Probuddy Education offers access to a wide range of
                      resources that may not be available in a traditional classroom setting. This
                      includes digital textbooks, videos, and other educational materials that can
                      enhance the learning experience.
                    </div>
                  </div>
                </div>
              </section>

              <section className={css.section}>
                <div className={css.sectionImagesContainer}>
                  <img
                    className={css.sectionImages}
                    src={
                      'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1681272496/probuddy/static/NewEducation/OnlineTutor/Online_Tutor_3_xpywc6.jpg'
                    }
                    alt="sectionImage1"
                  />
                </div>
                <div className={css.uniqueDesignSection}>
                  <div>
                    <h3>
                      <FormattedMessage id="OnlineTutorPage.Works" />
                    </h3>
                    <span className={css.topSpan}>
                      Probuddy Education matches students with experienced and qualified tutors who
                      specialize in their subject area. Once students sign up for online tutoring,
                      they will be asked to provide information about their academic goals and areas
                      of weakness. Probuddy Education then uses this information to match the
                      student with the best tutor for their needs.
                    </span>
                  </div>
                </div>
              </section>

              <section className={css.reverseSection}>
                <div className={css.sectionImagesContainer}>
                  <img
                    className={css.sectionImages}
                    src={
                      'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1681272494/probuddy/static/NewEducation/OnlineTutor/Online_Tutor_5_ak9woi.jpg'
                    }
                    alt="sectionImage2"
                  />
                </div>
                <div className={css.uniqueDesignSection}>
                  <h3>
                    <FormattedMessage id="OnlineTutorPage.Quality" />
                  </h3>
                  <span className={css.topSpan}>
                    Probuddy Education only hires experienced and qualified tutors who are experts
                    in their subject area. All tutors undergo a rigorous selection process to meet
                    the platform's high standards. Additionally, Probuddy Education provides ongoing
                    training and support to ensure that tutors stay up-to-date with the latest
                    teaching methods and technologies.
                  </span>
                </div>
              </section>

              <section className={css.section}>
                <div className={css.sectionImagesContainer}>
                  <img
                    className={css.sectionImages}
                    src={
                      'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1681272503/probuddy/static/NewEducation/OnlineTutor/Online_Tutor_4_zuqv4p.jpg'
                    }
                    alt="sectionImage3"
                  />
                </div>
                <div className={css.uniqueDesignSection}>
                  <h3>
                    <FormattedMessage id="OnlineTutorPage.Subject" />
                  </h3>
                  <span className={css.topSpan}>
                    Probuddy Education offers online tutoring services in various subjects,
                    including math, science, language, and social studies. Whether you need help
                    with algebra, biology, Spanish, or history, Probuddy Education has a tutor who
                    can help you succeed.
                  </span>
                  <span className={css.topSpan}>
                    Take the first step towards achieving your academic and career goals with
                    Probuddy Education. Contact us today to schedule a consultation and learn more
                    about how we can help you succeed. Let us be your trusted education partner and
                    guide you toward a brighter future.
                  </span>
                </div>
              </section>
            </div>{' '}
            <div className={css.companyListing}>
              <span className={css.categoryId}>Online Tutors</span>
            </div>
            <div className={css.productsMobileWrapper}>
              <div className={classNames(css.autorListingWrapper, css.popularExercisesSection)}>
                {remoteTutorListing &&
                  remoteTutorListing.map(pl => (
                    <ProductListing
                      key={pl.id.uuid}
                      pl={pl}
                      intl={intl}
                      isMobileLayout={isMobileLayout}
                    />
                  ))}
              </div>
            </div>
            <section className={css.becomeSellerSection}>
              <BecomeSeller isVendor={true} isEducationSite={true} message={'Are you a  Tutor?'} />
            </section>
          </div>
        </LayoutWrapperMain>

        {!isMobileLayout ? (
          <LayoutWrapperFooter>
            <Footer isEducationSite={true} />
          </LayoutWrapperFooter>
        ) : (
          <LayoutWrapperFooter className={classNames(css.layoutFooter)}>
            {!!isMobileLayout && (
              <FooterBottomMenu
                changeActivieFooterTab={() => {}}
                history={history}
                isEducationSite={true}
              />
            )}
          </LayoutWrapperFooter>
        )}
      </LayoutSingleColumn>
    </Page>
  );
};

export default compose(withViewport, injectIntl)(OnlineTutorPage);
