import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { createResourceLocatorString } from 'util/routes';
import routeConfiguration from '../../routeConfiguration';
import { login } from '../../ducks/Auth.duck';

import css from './NewThankYouPage.css';
import thankyousvg from '../../assets/ThankYouPage/thankyousvg.svg';

const NewThankYouPageComponent = props => {
  const { history, submitLogin } = props;
  const [currentUserLoginData, setCurrentUserLoginData] = useState();

  useEffect(() => {
    if (localStorage.getItem('currentUserLoginData'))
      setCurrentUserLoginData(JSON.parse(localStorage.getItem('currentUserLoginData')));
  }, []);

  const userEmail = currentUserLoginData?.email;
  const userPassword = currentUserLoginData?.password;

  const GoToHome = (userEmail, userPassword) => {
    submitLogin(userEmail, userPassword);
    localStorage.removeItem('currentUserLoginData');
    history.push(createResourceLocatorString('HomePage', routeConfiguration(), {}));
  };

  return (
    <div className={css.mainWrapper}>
      <div className={css.thankyouWrapper}>
        <img src={thankyousvg} alt={' '} />
        <h1>
          <FormattedMessage id="ThankYouPage.message" />
        </h1>
        <h1>
          <FormattedMessage id="ThankYouPage.contact" />
        </h1>
        <button className={css.btnDone} onClick={() => GoToHome(userEmail, userPassword)}>
          <FormattedMessage id="ThankYouPage.done" />
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => ({
  submitLogin: (email, password) => dispatch(login(email, password)),
});

const NewThankYouPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(injectIntl(NewThankYouPageComponent));

export default NewThankYouPage;
