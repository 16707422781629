import Axios from 'axios';
import { city } from 'marketplace-custom-config';
import { getToken } from 'util/api';
const flexIntegrationSdk = require('sharetribe-flex-integration-sdk');

// ================ Action types ================ //

export const CREATE_JOBSBOARD_LISTING_REQUEST = 'app/JobsBoard/CREATE_JOBSBOARD_LISTING_REQUEST';

export const CREATE_JOBSBOARD_LISTING_SUCCESS = 'app/JobsBoard/CREATE_JOBSBOARD_LISTING_SUCCESS';

export const CREATE_JOBSBOARD_LISTING_ERROR = 'app/JobsBoard/CREATE_JOBSBOARD_LISTING_ERROR';

export const GETTING_JOBSBOARD_LISTING_REQUEST = 'app/JobsBoard/GETTING_JOBSBOARD_LISTING_REQUEST';

export const GETTING_JOBSBOARD_LISTING_SUCCESS = 'app/JobsBoard/GETTING_JOBSBOARD_LISTING_SUCCESS';

export const GETTING_JOBSBOARD_LISTING_ERROR = 'app/JobsBoard/GETTING_JOBSBOARD_LISTING_ERROR';

//
export const GETTING_VENDOR_EMAIL_REQUEST = 'app/JobsBoard/GETTING_VENDOR_EMAIL_REQUEST';

export const GETTING_VENDOR_EMAIL_SUCCESS = 'app/JobsBoard/GETTING_VENDOR_EMAIL_SUCCESS';

export const GETTING_VENDOR_EMAIL_ERROR = 'app/JobsBoard/GETTING_VENDOR_EMAIL_ERROR';

//

export const GETTING_SINGLE_JOBSBOARD_LISTING_REQUEST =
  'app/JobsBoard/GETTING_SINGLE_JOBSBOARD_LISTING_REQUEST';

export const GETTING_SINGLE_JOBSBOARD_LISTING_SUCCESS =
  'app/JobsBoard/GETTING_SINGLE_JOBSBOARD_LISTING_SUCCESS';

export const GETTING_SINGLE_JOBSBOARD_LISTING_ERROR =
  'app/JobsBoard/GETTING_SINGLE_JOBSBOARD_LISTING_ERROR';

const baseUrl = process.env.REACT_APP_SHARETRIBE_SDK_BASE_URL || 'https://flex-api.sharetribe.com';
const CLIENT_ID = process.env.REACT_APP_SHARETRIBE_SDK_CLIENT_ID;

const initialState = {
  jobsBoardListing: [],
  jobsBoardListingsError: null,
  loading: false,
  jobsPagination: null,
  creatingListings: false,
  soloJobsBoard: null,
  vendorEmail: [],
};

export default function jobsBoardReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    // POST REQUEST
    case CREATE_JOBSBOARD_LISTING_REQUEST:
      return {
        ...state,
        creatingListings: true,
      };

    case CREATE_JOBSBOARD_LISTING_SUCCESS:
      return {
        ...state,
        creatingListings: false,
      };

    case CREATE_JOBSBOARD_LISTING_ERROR:
      return {
        ...state,
        creatingListings: false,
        queryjobsBoardListingsError: payload,
      };

    // GET REQUEST
    case GETTING_JOBSBOARD_LISTING_REQUEST:
      return {
        ...state,
        jobsBoardListing: state.jobsBoardListing,
        jobsPagination: state.jobsPagination,
        jobsBoardListingsError: null,
        loading: true,
      };

    case GETTING_JOBSBOARD_LISTING_SUCCESS:
      return {
        ...state,
        //{ listingRes, pages }
        jobsBoardListing: payload.listingRes,
        jobsPagination: payload.pages,
        jobsBoardListingsError: null,
        loading: false,
      };

    case GETTING_JOBSBOARD_LISTING_ERROR:
      return {
        ...state,
        jobsBoardListing: [],
        jobsBoardListingsError: payload,
        jobsPagination: null,
        loading: false,
      };
    case GETTING_SINGLE_JOBSBOARD_LISTING_REQUEST:
      return {
        ...state,
        soloJobsBoard: state.soloJobsBoard,
        jobsBoardListingsError: null,
        loading: true,
      };
    case GETTING_SINGLE_JOBSBOARD_LISTING_SUCCESS:
      return {
        ...state,
        soloJobsBoard: payload,
        jobsBoardListingsError: null,
        loading: false,
      };
    case GETTING_SINGLE_JOBSBOARD_LISTING_ERROR:
      return {
        ...state,
        soloJobsBoard: state.soloJobsBoard,
        jobsBoardListingsError: payload,
        loading: false,
      };
    case GETTING_VENDOR_EMAIL_REQUEST:
      return {
        ...state,
        vendorEmail: state.vendorEmail,
      };
    case GETTING_VENDOR_EMAIL_SUCCESS:
      return {
        ...state,
        vendorEmail: payload,
      };
    case GETTING_VENDOR_EMAIL_ERROR:
      return {
        ...state,
        vendorEmail: [],
      };
    default:
      return state;
  }
}

// ================ Action creators ================ //

export const jobsBoardListingsRequest = () => ({
  type: CREATE_JOBSBOARD_LISTING_REQUEST,
});

export const jobsBoardListingsSuccess = () => ({
  type: CREATE_JOBSBOARD_LISTING_SUCCESS,
});

export const jobsBoardListingsError = error => ({
  type: CREATE_JOBSBOARD_LISTING_ERROR,
  error: true,
  payload: error,
});

export const gettingJobsBoardListingsRequest = () => ({
  type: GETTING_JOBSBOARD_LISTING_REQUEST,
});

export const gettingJobsBoardListingsSuccess = listing => ({
  type: GETTING_JOBSBOARD_LISTING_SUCCESS,
  payload: listing,
});

export const gettingJobsBoardListingsError = error => ({
  type: GETTING_JOBSBOARD_LISTING_ERROR,
  error: true,
  payload: error,
});
export const gettingSingleJobsBoardListingsRequest = () => ({
  type: GETTING_SINGLE_JOBSBOARD_LISTING_REQUEST,
});
export const gettingSingleJobsBoardListingsSuccess = listing => ({
  type: GETTING_SINGLE_JOBSBOARD_LISTING_SUCCESS,
  payload: listing,
});
export const gettingSingleJobsBoardListingsError = error => ({
  type: GETTING_SINGLE_JOBSBOARD_LISTING_ERROR,
  error: true,
  payload: error,
});

export const gettingVendorEmailRequest = () => ({
  type: GETTING_VENDOR_EMAIL_REQUEST,
});
export const gettingVendorEmailSuccess = emails => ({
  type: GETTING_VENDOR_EMAIL_SUCCESS,
  payload: emails,
});
export const gettingVendorEmailError = () => ({
  type: GETTING_VENDOR_EMAIL_ERROR,
  error: true,
});

// ================ Thunks ================ //

export const createJobsboardListing = (data, has_login) => {
  return async (dispatch, getState, sdk) => {
    dispatch(jobsBoardListingsRequest());

    if (!has_login) {
      const { token } = await getToken();
      const URL = `${baseUrl}/v1/api/own_listings/create`;
      const headers = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      return await Axios.post(URL, data, headers)
        .then(response => {
          return response;
        })
        .catch(e => {
          throw e;
        });
    } else {
      return sdk.ownListings
        .create(data)
        .then(response => {
          const jobsBoardRes = response.data;
          dispatch(jobsBoardListingsSuccess());
          return jobsBoardRes;
        })
        .catch(error => {
          console.log(error);
          dispatch(jobsBoardListingsError(error));
        });
    }
  };
};
export const getSingleListing = data => {
  return async (dispatch, getState, sdk) => {
    dispatch(gettingSingleJobsBoardListingsRequest());
    if (data) {
      return sdk.listings
        .show({ id: data })
        .then(res => {
          const data = res.data.data;
          const soloData = { ...data.attributes, id: data.id.uuid };
          dispatch(gettingSingleJobsBoardListingsSuccess(soloData));
        })
        .catch(e => {
          console.log(e);
          dispatch(gettingSingleJobsBoardListingsError(e));
        });
    }
  };
};
export const onGetJobsBoard = searchParams => (dispatch, getState, sdk) => {
  dispatch(gettingJobsBoardListingsRequest());
  const subcategoryAlt = searchParams.pub_sub_category
    ? {
        pub_sub_category: `has_any:${searchParams.pub_sub_category
          .join(',')
          .toLowerCase()
          .trim()
          .replaceAll(' ', '-')}`,
      }
    : {};
  const pages = { page: searchParams.page, perPage: 20 };
  const query = { ...searchParams, ...subcategoryAlt, ...pages };

  return sdk.listings
    .query(query)
    .then(response => {
      const pages = response?.data?.meta;
      const data = response.data?.data;
      if (!searchParams.location) {
        const listingRes = data.map(({ id, attributes }) => {
          return { id: id.uuid, ...attributes };
        });
        dispatch(gettingJobsBoardListingsSuccess({ listingRes, pages }));
      } else {
        const listingRes = data
          ?.map(({ id, attributes }) => {
            if (
              attributes?.publicData?.location?.city &&
              attributes?.publicData?.location?.city.toLowerCase().includes(searchParams.location)
            ) {
              return { id: id.uuid, ...attributes };
            }
          })
          .filter(data => data);

        dispatch(gettingJobsBoardListingsSuccess({ listingRes, pages }));
      }
      return response;
    })
    .catch(e => {
      console.log(e);
      dispatch(gettingJobsBoardListingsError(e));
      return e;
    });
};
const getBounds = bounds => {
  if (!bounds) return undefined;

  const { ne, sw } = bounds;
  const { lat: nelat, lng: nelng } = ne;
  const { lat: swlat, lng: swlng } = sw;

  return `${nelat},${nelng},${swlat},${swlng}`;
};

export const getVendors = params => async (dispatch, getState, sdk) => {
  dispatch(gettingVendorEmailRequest());
  const { cityParams, category, listing_type } = params;
  const bound = city.find(val => val.key === cityParams)?.bounds;
  const newBound = getBounds(bound);

  const query = {
    pub_listing_type: listing_type,
    include: ['author'],
    pub_sub_category: category,
    bounds: newBound,
  };
  const integrationSdk = await flexIntegrationSdk.createInstance({
    clientId: process.env.REACT_APP_INTEGRATION_CLIENT_ID,
    clientSecret: process.env.REACT_APP_INTEGRATION_CLIENT_SECRET,
    // baseUrl:
    //   process.env.REACT_APP_FLEX_INTEGRATION_BASE_URL || 'https://flex-integ-api.sharetribe.com',
  });
  return integrationSdk.listings
    .query(query)
    .then(response => {
      const data = response.data.included;
      const emails = data.map(val => val?.attributes?.email);
      dispatch(gettingVendorEmailSuccess(emails));
      return response;
    })
    .catch(e => {
      console.log(e);
      dispatch(gettingVendorEmailError(e));
      return e;
    });
};
