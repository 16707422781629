import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { uid } from 'react-uid';
import cns from 'classnames';
import { orderBy } from 'lodash';
import Collapsible from 'react-collapsible';
import { propTypes } from 'util/types';
import css from '../TableEntry/TableEntry.css';
import { IconArrowDown, NamedLink } from 'components';
import { IconRoundCheckWhite } from '../../../../components/IconBecomeVendor/IconBecomeVendor';

const WEEKS = 8;

const getMembershipId = item => item.transactions[0].attributes.protectedData.membership;

const renderWeekMarks = (item, selectedWeek) => {
  const currentMoment = moment();

  const weeksToMark = item.transactions
    .map(tx => {
      const txStartMoment = moment(tx.booking.attributes.start);
      const isCurrentWeek = currentMoment.isSame(txStartMoment, 'isoweek');
      const from_date = txStartMoment.startOf('isoweek');
      return isCurrentWeek ? 0 : Math.ceil(from_date.diff(currentMoment, 'week', true));
    })
    // filter out week's number that are bigger than 8
    // we are showing plan for 8 weeks only
    .filter(w => w <= WEEKS);

  const weekMarksArray = [];
  [...Array(WEEKS)].forEach((w, i) => {
    weekMarksArray.push(
      weeksToMark.includes(i) ? (
        <div key={uid(i)} className={cns(css.cell, css.marked)}>
          <IconRoundCheckWhite />
        </div>
      ) : (
        <div key={uid(i)} className={cns(css.cell, css.marked, css.not)}>
          <IconRoundCheckWhite />
        </div>
      )
    );
  });

  if (selectedWeek && selectedWeek === '1week') {
    // const activeWeekdayNames = []
    // for (let i = 0; i < 7; i++) {
    //   const loopWeekday = moment().add(i, "days").format("dddd DD MMM")
    //   if (loopWeekday === item.formatedWeekday) {
    //     activeWeekdayNames.push(<div key={uid(i)} className={cns(css.cell, css.marked)} ><IconRoundCheckWhite /></div>)
    //   }
    //   else {
    //     activeWeekdayNames.push(<div key={uid(i)} className={cns(css.cell, css.marked, css.not)} ><IconRoundCheckWhite /></div>)
    //   }
    // };
    return [];
  } else if (selectedWeek && selectedWeek === '2weeks') {
    return weekMarksArray.slice(0, 2);
  } else if (selectedWeek && selectedWeek === '4weeks') {
    return weekMarksArray.slice(0, 4);
  } else if (selectedWeek && selectedWeek === '6weeks') {
    return weekMarksArray.slice(0, 6);
  } else if (selectedWeek && selectedWeek === '8weeks') {
    return weekMarksArray.slice(0, 8);
  } else {
    return weekMarksArray;
  }
};

const countNumberOfBookings = entries => {
  return entries.reduce((sum, entry) => {
    if (entry.multipleClients) return sum + entry.clients.length;
    return sum + 1;
  }, 0);
};

function TableEntryRow({ entry, isSaleSchedule, selectedWeek, isMobileLayout }) {
  // onSelect,
  // isSelected,
  const name = entry.kidsName || entry.customer.attributes.profile.displayName;
  const lineItems = entry?.transactions[0]?.attributes?.lineItems;
  const seat = lineItems.filter(val => val?.seats)[0]?.seats;
  return (
    <div className={css.row}>
      <div className={css.cellNameWrap}>
        {/* <div className={css.cell}>
          <div className={css.checkboxField}>
            <input
              className={css.checkboxInput}
              onChange={() => onSelect(entry.transactions[0])}
              type="checkbox"
              id={entry.transactions[0].id.uuid}
              checked={isSelected}
            />
            <label htmlFor={entry.transactions[0].id.uuid}></label>
          </div>
        </div> */}
        <div className={css.nameTimeWrap}>
          <div className={cns(css.cell, css.name)}>
            <NamedLink
              name="ProfilePage"
              params={{ id: isSaleSchedule ? entry.customer.id.uuid : entry.provider.id.uuid }}
              className={css.nameContent}
            >
              {isSaleSchedule ? name : entry.provider.attributes.profile.displayName}
            </NamedLink>
          </div>
          <div className={cns(css.cell, css.time)}>
            {entry.bookingStart && entry.bookingEnd && (
              <span
                style={{ wordBreak: 'keep-all', whiteSpace: 'nowrap' }}
              >{`${entry.bookingStart} - ${entry.bookingEnd}`}</span>
            )}
          </div>
          <div className={cns(css.cell, css.seat)}>
            <span>{seat}</span>
          </div>
        </div>
      </div>

      <div className={css.listingPackageWrap}>
        <div className={cns(css.cell, css.listingName)}>
          <span className={css.listingNameContent}>{entry.listing.attributes.title}</span>
        </div>
        <div className={cns(css.cell, css.membership)}>{getMembershipId(entry)}</div>
      </div>
      {!isMobileLayout && (
        <>
          <div
            className={cns(
              css.columnGroup,
              (selectedWeek === '6weeks' || selectedWeek === '8weeks') && css.columnGroupMoreWeeks
            )}
          ></div>

          {renderWeekMarks(entry, selectedWeek)}
        </>
      )}
    </div>
  );
}

TableEntryRow.propTypes = {
  entry: propTypes.bookingPlanItem,
};

function TableEntryRowCollapsible({ entry, selectedWeek, onSelect, selectedBookings }) {
  const [isCollapsed, setCollapsed] = useState(true);
  const [isAllSelect, setIsAllSelect] = useState(false);
  const { weekday, formatedWeekday, bookingEnd, bookingStart, clients } = entry;
  // const isMobileLayout = (typeof window !== 'undefined') && window.screen.width < 1024;

  useEffect(() => {
    const groupEntry = clients.map(entry => entry.transactions[0]);
    const seletedEntry = groupEntry.filter(entry => {
      return selectedBookings.some(selectedTx => selectedTx.id.uuid === entry.id.uuid);
    });
    if (groupEntry.length === seletedEntry.length) {
      setIsAllSelect(true);
    } else {
      setIsAllSelect(false);
    }
  }, [isAllSelect, selectedBookings, clients]);

  const allGroupSelectScheduleHandler = () => {
    const groupEntry = clients.map(entry => entry.transactions[0]);
    if (isAllSelect) {
      const includedEntry = groupEntry.filter(entry => {
        return selectedBookings.some(selectedTx => selectedTx.id.uuid === entry.id.uuid);
      });
      if (includedEntry.length !== 0) {
        includedEntry.forEach(entry => {
          onSelect(entry);
        });
      } else {
        groupEntry.forEach(entry => {
          onSelect(entry);
        });
      }
    } else {
      const notIncludedEntry = groupEntry.filter(entry => {
        return selectedBookings.some(selectedTx => selectedTx.id.uuid !== entry.id.uuid);
      });
      if (notIncludedEntry.length !== 0) {
        notIncludedEntry.forEach(entry => {
          onSelect(entry);
        });
      } else {
        groupEntry.forEach(entry => {
          onSelect(entry);
        });
      }
    }
    setIsAllSelect(!isAllSelect);
  };

  const trigger = (
    <div className={css.clientTriggerWrap}>
      <div className={css.checkboxField}>
        <input
          className={css.checkboxInput}
          onChange={allGroupSelectScheduleHandler}
          checked={isAllSelect}
          type="checkbox"
          id={`${formatedWeekday}${bookingStart}${bookingEnd}${weekday}`}
        />
        <label htmlFor={`${formatedWeekday}${bookingStart}${bookingEnd}${weekday}`}></label>
      </div>
      <div
        onClick={() => setCollapsed(isCollapsed => !isCollapsed)}
        className={cns(css.row, css.clickable)}
      >
        <div className={cns(css.cell, css.name)}>
          <span className={css.nameContent}>
            <IconArrowDown
              className={cns(
                css.collapseIcon,
                css.collapseIconMultiBooking,
                isCollapsed ? css.collapsed : ''
              )}
            />
            {`${entry.clients.length} bookings`}
          </span>
        </div>
        <div className={(css.cell, css.seatTitle)}>
          <span>No. of Seats</span>
        </div>
        <div className={cns(css.cell, css.time)}>
          {entry.bookingStart && entry.bookingEnd && (
            <span
              style={{
                wordBreak: 'keep-all',
                whiteSpace: 'nowrap',
              }}
            >{`${entry.bookingStart} - ${entry.bookingEnd}`}</span>
          )}
        </div>
        <div className={cns(css.cell, css.listingName)}>
          <span className={css.listingNameContent}>{entry.listing.attributes.title}</span>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <Collapsible trigger={trigger} triggerDisabled={true} open={isCollapsed}>
        {entry.clients.map(clientRow => (
          <TableClientRow
            key={uid(clientRow)}
            selectedWeek={selectedWeek}
            onSelect={onSelect}
            isSelected={selectedBookings.some(
              selectedTx => selectedTx.id.uuid === clientRow.transactions[0].id.uuid
            )}
            client={clientRow}
          />
        ))}
      </Collapsible>
    </>
  );
}

function TableClientRow({ client, selectedWeek, onSelect, isSelected }) {
  const name = client.kidsName || client.customer.attributes.profile.displayName;
  const lineItems = client?.transactions[0]?.attributes?.lineItems;
  const seat = lineItems.filter(val => val?.seats)[0]?.seats;

  return (
    <div className={css.clientRow}>
      <div className={css.cell}>
        <div className={css.checkboxField}>
          <input
            className={css.checkboxInput}
            onChange={() => onSelect(client.transactions[0])}
            type="checkbox"
            id={client.transactions[0].id.uuid}
            checked={isSelected}
          />
          <label htmlFor={client.transactions[0].id.uuid}></label>
        </div>
      </div>
      <div className={cns(css.cell, css.clientName)}>
        <div className={css.nameSeatWrap}>
          <NamedLink
            name="ProfilePage"
            params={{ id: client.customer.id.uuid }}
            className={css.nameContent}
          >
            {name}
          </NamedLink>
          <div className={css.seatContainer}>
            <div className={cns(css.cell, css.seatClient)}>
              <span>{seat}</span>
            </div>
          </div>
        </div>
      </div>

      <div className={cns(css.cell, css.clientMembership)}>{getMembershipId(client)}</div>
      {renderWeekMarks(client, selectedWeek)}
    </div>
  );
}

const WEEKDAYS = moment.weekdays();
function TableEntry({
  entry,
  index,
  isSaleSchedule,
  isCollapsedAll,
  selectedWeek,
  onSelect,
  selectedBookings,
  urlQueryParams,
  isMobileLayout,
}) {
  const [isCollapsed, setCollapsed] = useState(true);
  const [isAllSelect, setIsAllSelect] = useState(false);
  const { weekday, entries } = entry;
  let sortedEntries = entries;
  if (urlQueryParams && urlQueryParams.sort && urlQueryParams.sort === 'desc') {
    sortedEntries = orderBy(
      entries,
      [
        entry =>
          entry.listing.attributes.title !== null
            ? entry.listing.attributes.title.toLowerCase()
            : null,
      ],
      'desc'
    );
  } else if (urlQueryParams && urlQueryParams.sort && urlQueryParams.sort === 'asc') {
    sortedEntries = orderBy(
      entries,
      [
        entry =>
          entry.listing.attributes.title !== null
            ? entry.listing.attributes.title.toLowerCase()
            : null,
      ],
      'asc'
    );
  }

  useEffect(() => {
    setCollapsed(isCollapsedAll);
  }, [isCollapsedAll]);

  useEffect(() => {
    const groupEntry = [];
    entries.forEach(entry => {
      if (entry.clients) {
        entry.clients.forEach(clientRow => {
          groupEntry.push(clientRow.transactions[0]);
        });
      } else {
        groupEntry.push(entry.transactions[0]);
      }
    });
    const seletedEntry = groupEntry.filter(entry => {
      return selectedBookings.some(selectedTx => selectedTx.id.uuid === entry.id.uuid);
    });
    if (groupEntry.length === seletedEntry.length) {
      setIsAllSelect(true);
    } else {
      setIsAllSelect(false);
    }
  }, [isAllSelect, selectedBookings, entries]);

  // const allGroupSelectScheduleHandler = () => {
  //   const groupEntry = [];
  //   entries.forEach(entry => {
  //     if (entry.clients) {
  //       entry.clients.forEach(clientRow => {
  //         groupEntry.push(clientRow.transactions[0]);
  //       });
  //     } else {
  //       groupEntry.push(entry.transactions[0]);
  //     }
  //   });
  //   if (isAllSelect) {
  //     const includedEntry = groupEntry.filter(entry => {
  //       return selectedBookings.some(selectedTx => selectedTx.id.uuid === entry.id.uuid);
  //     });
  //     if (includedEntry.length !== 0) {
  //       includedEntry.forEach(entry => {
  //         onSelect(entry);
  //       });
  //     } else {
  //       groupEntry.forEach(entry => {
  //         onSelect(entry);
  //       });
  //     }
  //   } else {
  //     const notIncludedEntry = groupEntry.filter(entry => {
  //       return selectedBookings.some(selectedTx => selectedTx.id.uuid !== entry.id.uuid);
  //     });
  //     if (notIncludedEntry.length !== 0) {
  //       notIncludedEntry.forEach(entry => {
  //         onSelect(entry);
  //       });
  //     } else {
  //       groupEntry.forEach(entry => {
  //         onSelect(entry);
  //       });
  //     }
  //   }
  //   setIsAllSelect(!isAllSelect);
  // };

  const trigger = (
    <div className={css.triggerWrap}>
      {/* <div className={css.checkboxField}>
        <input
          className={css.checkboxInput}
          onChange={allGroupSelectScheduleHandler}
          checked={isAllSelect}
          type="checkbox"
          id={formatedWeekday}
        />
        <label htmlFor={formatedWeekday}></label>
      </div> */}
      <div onClick={() => setCollapsed(isCollapsed => !isCollapsed)} className={css.weekdayRow}>
        <div className={css.weekdayName}>
          {WEEKDAYS[weekday === 7 ? 0 : weekday]}
          <IconArrowDown className={cns(css.collapseIcon, isCollapsed ? css.collapsed : '')} />
          {/* {formatedWeekday} */}
        </div>
        <div className={css.bookingNumber}>{`${countNumberOfBookings(entries)} bookings`}</div>
      </div>
    </div>
  );

  return (
    <div className={cns(css.weekday, index === 0 && css.firstRowWeekday)}>
      <Collapsible trigger={trigger} triggerDisabled={true} open={!isCollapsed}>
        {sortedEntries.map(entry =>
          entry.multipleClients ? (
            <TableEntryRowCollapsible
              key={uid(entry)}
              entry={entry}
              isCollapsedAll={isCollapsedAll}
              selectedWeek={selectedWeek}
              onSelect={onSelect}
              selectedBookings={selectedBookings}
              isMobileLayout={isMobileLayout}
            />
          ) : (
            <TableEntryRow
              key={uid(entry)}
              selectedWeek={selectedWeek}
              onSelect={onSelect}
              isSaleSchedule={isSaleSchedule}
              isSelected={selectedBookings.some(
                selectedTx => selectedTx.id.uuid === entry.transactions[0].id.uuid
              )}
              entry={entry}
              isMobileLayout={isMobileLayout}
            />
          )
        )}
      </Collapsible>
    </div>
  );
}

export default TableEntry;
