import React, { useState, useEffect } from 'react';
import { uid } from 'react-uid';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import classNames from 'classnames';
import { propTypes } from 'util/types';
import { FormattedMessage, injectIntl, intlShape } from 'util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import { Button, Form, FieldTextInput } from '../../components';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import { SecondaryButton } from '../../components';
import { getExtensionFromUrl } from 'util/urlHelpers';

import css from './EditListingBlogsForm.css';

const MAX_FIELDS = 5;

export const EditListingBlogsForm = props => {
  const textRequired = value => (value ? undefined : 'Required');
  const [assets, setAssets] = useState({});
  // const [isAssetUpdated, setIsAssetUpdated] = useState(false);
  const [pictureError, setPictureError] = useState(false);

  useEffect(() => {
    if (props.initialValues.blogs && props.initialValues.blogs.length) {
      const assetsObj = {};
      props.initialValues.blogs.forEach((item, index) => {
        const uniqId = uid(`Image${index}`);
        assetsObj[uniqId] = item.asset;
      });
      setAssets(assetsObj);
    }
  }, [props.initialValues]);

  const filedArrayRequired = value => {
    if (value && value.length < 1) {
      return 'Require at least one entry';
    }

    return undefined;
  };

  const uploadWidget = (e, uniqId) => {
    e.preventDefault();
    if (typeof window !== 'undefined') {
      window.cloudinary.openUploadWidget(
        { cloud_name: 'movementbuddy-pte-ltd', upload_preset: 'movementbuddy' },
        function(error, result) {
          if (result && result.info && result.info.secure_url) {
            setAssets({
              ...assets,
              [uniqId]: { url: result.info.secure_url, type: result.info.resource_type },
            });
          }
          // setIsAssetUpdated(true);
          setPictureError(false);
        }
      );
    }
  };

  const submitHandler = values => {
    const normalizedBlogs = values.blogs
      ? values.blogs.map((item, index) => {
          const uniqId = uid(`Image${index}`);
          return {
            ...item,
            asset: assets[uniqId],
          };
        })
      : [];

    if (normalizedBlogs.some(item => !item.asset)) {
      setPictureError(true);
      return;
    }

    props.onSubmit({ blogs: normalizedBlogs });
  };

  // const omitPictureOnPop = values => {
  //   if (!values || !values.length) {
  //     return;
  //   }

  //   const lastItem = values[values.length - 1];
  //   setAssets({
  //     ...omit(assets, lastItem.id),
  //   });
  // };

  return (
    <FinalForm
      {...props}
      mutators={{
        ...arrayMutators,
      }}
      initialValues={props.initialValues}
      onSubmit={submitHandler}
    >
      {formRenderProps => {
        const classes = classNames(css.root, formRenderProps.className);
        const { updateListingError, showListingsError } = formRenderProps.fetchErrors || {};
        const {
          mutators: { push, pop },
        } = formRenderProps.form;

        const submitInProgress = formRenderProps.updateInProgress;
        const submitReady = formRenderProps.updated || formRenderProps.ready;
        // const submitDisabled =
        //   formRenderProps.invalid ||
        //   formRenderProps.disabled ||
        //   formRenderProps.submitInProgress ||
        //   formRenderProps.pristine;

        return (
          <Form onSubmit={formRenderProps.handleSubmit} className={classes}>
            {updateListingError ? (
              <p className={css.error}>
                <FormattedMessage id="EditListingPricingForm.updateFailed" />
              </p>
            ) : null}
            {pictureError ? (
              <p className={css.error}>
                <FormattedMessage id="EditListingExpectationsForm.pictureError" />
              </p>
            ) : null}
            {showListingsError ? (
              <p className={css.error}>
                <FormattedMessage id="EditListingPricingForm.showListingFailed" />
              </p>
            ) : null}

            <FieldArray name={'blogs'} validate={filedArrayRequired}>
              {({ fields }) => {
                return fields.map((name, index) => {
                  if (index < MAX_FIELDS) {
                    const uniqId = uid(`Image${index}`);
                    return (
                      <>
                        <div className={css.imageWrapper}>
                          {assets[uniqId] ? (
                            <div>
                              <h4 className={css.pictureHeading}>
                                <FormattedMessage id="EditListingExpectationsForm.pictureHeading" />
                              </h4>
                              <div className={css.pictureContainer}>
                                {assets[uniqId].type === 'video' ? (
                                  <video src={assets[uniqId].url} controls width="100%">
                                    <source
                                      src={assets[uniqId].url}
                                      type={`video/${getExtensionFromUrl(assets[uniqId].url)}`}
                                    />
                                  </video>
                                ) : (
                                  <a
                                    className={css.picture}
                                    // eslint-disable-next-line
                                    target="_blank"
                                    href={assets[uniqId].url}
                                  >
                                    <img src={assets[uniqId].url} alt="formImage" />
                                  </a>
                                )}
                              </div>
                            </div>
                          ) : null}

                          <div>
                            <button
                              className={css.uploadPictureBtn}
                              onClick={e => uploadWidget(e, uniqId)}
                            >
                              <FormattedMessage id="EditListingExpectationsForm.uploadAsset" />
                            </button>
                          </div>
                        </div>

                        <FieldTextInput
                          key={uid(`${name}_header_${index}d`)}
                          id="certificatedHeader"
                          name={`${name}.header`}
                          type="text"
                          className={css.headerInput}
                          autoFocus
                          label={<FormattedMessage id={'EditListingBlogsForm.headerLabel'} />}
                          validate={textRequired}
                        />
                        <FieldTextInput
                          key={uid(`${name}_${index}`)}
                          id="certificate"
                          name={`${name}.text`}
                          type="textarea"
                          rows="3"
                          className={css.textInput}
                          label={<FormattedMessage id={'EditListingBlogsForm.textLabel'} />}
                          validate={textRequired}
                        />
                      </>
                    );
                  } else {
                    return null;
                  }
                });
              }}
            </FieldArray>

            <div className={css.mutateBtns}>
              <SecondaryButton
                disabled={
                  formRenderProps.values.blogs && formRenderProps.values.blogs.length === MAX_FIELDS
                }
                type="button"
                onClick={() => push('blogs', undefined)}
              >
                <FormattedMessage id={'EditListingBlogsForm.addBlogs'} />
              </SecondaryButton>

              <SecondaryButton type="button" onClick={() => pop('blogs')}>
                <FormattedMessage id={'EditListingBlogsForm.removeBlogs'} />
              </SecondaryButton>
            </div>
            <div className={css.submitButtonContainer}>
              <Button
                className={css.submitButton}
                type="submit"
                inProgress={submitInProgress}
                // disabled={submitDisabled}
                ready={submitReady}
              >
                {formRenderProps.saveActionMsg}
              </Button>
            </div>
          </Form>
        );
      }}
    </FinalForm>
  );
};

EditListingBlogsForm.defaultProps = { fetchErrors: null };

EditListingBlogsForm.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingBlogsForm);
