/**
 * Note: This form is using card from Stripe Elements https://stripe.com/docs/stripe-js#elements
 * Card is not a Final Form field so it's not available trough Final Form.
 * It's also handled separately in handleSubmit function.
 */
import React, { Component } from 'react';
import { bool, func, object, string } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import Dropzone from 'react-dropzone';
import classNames from 'classnames';

import { Form, FieldTextInput, IconClose, CustomFieldTextInput } from '../../components';
import css from './WithoutPaymentForm.css';
import { PAYMENT_TYPES } from 'util/constants';
import * as validators from 'util/validators';
import { propTypes } from 'util/types';
import { PrimaryButton2 } from 'components/Button/Button';

/**
 * Translate a Stripe API error object.
 *
 * To keep up with possible keys from the Stripe API, see:
 *
 * https://stripe.com/docs/api#errors
 *
 * Note that at least at moment, the above link doesn't list all the
 * error codes that the API returns.
 *
 * @param {Object} intl - react-intl object from injectIntl
 * @param {Object} stripeError - error object from Stripe API
 *
 * @return {String} translation message for the specific Stripe error,
 * or the given error message (not translated) if the specific error
 * type/code is not defined in the translations
 *
 */

const initialState = {
  error: null,
  paymentProofSsUrls: [],
  paymentProofRequiredError: null,
};

/**
 * Payment form that asks for credit card info using Stripe Elements.
 *
 * When the card is valid and the user submits the form, a request is
 * sent to the Stripe API to handle payment. `stripe.handleCardPayment`
 * may ask more details from cardholder if 3D security steps are needed.
 *
 * See: https://stripe.com/docs/payments/payment-intents
 *      https://stripe.com/docs/elements
 */
class WithoutPaymentForm extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.handleSubmit = this.handleSubmit.bind(this);
    this.paymentForm = this.paymentForm.bind(this);
    this.uploadWidget = this.uploadWidget.bind(this);
    this.finalFormAPI = null;
  }

  handleSubmit(values) {
    const { onSubmit, inProgress, formId, paymentType } = this.props;
    const { initialMessage } = values;

    if (inProgress) {
      // Already submitting or card value incomplete/invalid
      return;
    }

    if (this.props.credits !== 'true') {
      if (this.state.paymentProofSsUrls.length <= 0 && paymentType === PAYMENT_TYPES.direct) {
        this.setState({
          paymentProofRequiredError: 'Payment proof is required, please upload payment screenshot',
        });
        return;
      }
    }

    const params = {
      message: initialMessage ? initialMessage.trim() : null,
      paymentProofSsUrls: this.state.paymentProofSsUrls.map(a => a.secure_url),
      formId,
      formValues: values,
    };
    onSubmit(params);
  }

  onDrop = files => {
    const url = 'https://api.cloudinary.com/v1_1/movementbuddy-pte-ltd/auto/upload';
    const formData = new FormData();

    for (let i = 0; i < files.length; i++) {
      let file = files[i];
      formData.append('file', file);
      formData.append('upload_preset', 'movementbuddy');

      fetch(url, { method: 'POST', body: formData })
        .then(response => response.text())
        .then(data => {
          this.setState({
            paymentProofSsUrls: [...this.state.paymentProofSsUrls, JSON.parse(data)],
          });
        });
    }
  };

  removeFile = index => e => {
    e.stopPropagation();

    const dt = new DataTransfer();
    const file = this.state.paymentProofSsUrls[index];
    const el = document.getElementById('payment-dropzone');
    for (let i = 0; i < el.files.length; i++) {
      const rowFile = el.files[i];
      let name = rowFile.name.split('.');
      name.splice(name.length - 1, 1);
      name = name.join('');
      if (name !== file.original_filename) dt.items.add(rowFile);
    }
    el.files = dt.files;

    this.state.paymentProofSsUrls.splice(index, 1);
    this.setState({
      paymentProofSsUrls: [...this.state.paymentProofSsUrls],
    });
  };

  renderFormFields = () => {
    const { paymentProofSsUrls } = this.state;
    const { credits, bankDetails, paymentType, intl } = this.props;

    const membershipLabel = <FormattedMessage id={'withoutPaymentForm.membershipLabel'} />;
    const requestOnlyLabel = <FormattedMessage id={'withoutPaymentForm.requestOnlyLabel'} />;
    const membershipPlaceholder = intl.formatMessage({
      id: 'withoutPaymentForm.membershipPlaceholder',
    });
    const requestOnlyPlaceholder = intl.formatMessage({
      id: 'withoutPaymentForm.requestOnlyPlaceholder',
    });

    switch (paymentType) {
      case PAYMENT_TYPES.direct:
        return (
          <>
            {credits !== 'true' ? (
              <div>
                {bankDetails ? (
                  <div>
                    <h2>
                      <FormattedMessage id="withoutPaymentForm.bankDetails" />
                    </h2>
                    <p className={css.bankDetails}>{bankDetails}</p>
                  </div>
                ) : null}
                {/*<button className={css.uploadPaymentProofBtn} onClick={this.uploadWidget}>*/}
                {/*  <FormattedMessage id="withoutPaymentForm.uploadPaymentProof" />*/}
                {/*</button>*/}

                <section className={css.shareReceiptContainer}>
                  <div className={css.shareReceiptWrapper}>
                    <h4>
                      <FormattedMessage id={'withoutPaymentForm.shareTransferReceipt'} />
                    </h4>
                    <span className={css.vendorRequiredInfo}>
                      <FormattedMessage id={'withoutPaymentForm.youShareVendorReceipt'} />
                    </span>
                    <p className={css.receiptInfo}>
                      <FormattedMessage id={'withoutPaymentForm.saveProsessingFee’s'} />
                    </p>
                  </div>

                  <div className={css.shareReceiptBtnContainer}>
                    <Dropzone onDrop={this.onDrop} accept="image/*,application/pdf,.doc,.docx">
                      {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps({ className: css.shareReceiptBtnWrapper })}>
                          <input {...getInputProps()} id="payment-dropzone" />
                          <div className={css.shareReceiptBtn}>
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M18 22C19.6569 22 21 20.6569 21 19C21 17.3431 19.6569 16 18 16C16.3431 16 15 17.3431 15 19C15 20.6569 16.3431 22 18 22Z"
                                fill="white"
                                stroke="white"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M18 8C19.6569 8 21 6.65685 21 5C21 3.34315 19.6569 2 18 2C16.3431 2 15 3.34315 15 5C15 6.65685 16.3431 8 18 8Z"
                                fill="white"
                                stroke="white"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M6 15C7.65685 15 9 13.6569 9 12C9 10.3431 7.65685 9 6 9C4.34315 9 3 10.3431 3 12C3 13.6569 4.34315 15 6 15Z"
                                fill="white"
                                stroke="white"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path d="M15.5 6.5L8.5 10.5" stroke="white" strokeWidth="1.5" />
                              <path d="M8.5 13.5L15.5 17.5" stroke="white" strokeWidth="1.5" />
                            </svg>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                  </div>
                </section>

                {paymentProofSsUrls?.length > 0 ? (
                  <div className={css.paymentProofsContainer}>
                    <h2>
                      <FormattedMessage id="withoutPaymentForm.paymentScreenshots" />
                    </h2>
                    <div className={css.paymentProofSsContainer}>
                      {paymentProofSsUrls.map((ss, index) => {
                        return (
                          <div style={{ position: 'relative' }}>
                            <a
                              key={ss.public_id}
                              // eslint-disable-next-line
                              target="_blank"
                              href={ss?.secure_url}
                            >
                              <div className={css.paymentProofSs}>
                                {ss.resource_type === 'image' && (
                                  <img src={ss.secure_url} alt={ss.public_id} />
                                )}
                                {ss.resource_type === 'raw' && <p>{ss.public_id}</p>}
                              </div>
                            </a>
                            <div className={css.removeItem} onClick={this.removeFile(index)}>
                              <IconClose size="normal" className={css.removeIcon} />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : null}
              </div>
            ) : null}
          </>
        );
      case PAYMENT_TYPES.membership:
        const prevMembershipNumber = localStorage.getItem('membershipNumber');
        return (
          <CustomFieldTextInput
            type="text"
            id="membership"
            name="membership"
            label={membershipLabel}
            placeholder={membershipPlaceholder}
            className={css.message}
            initialValue={
              prevMembershipNumber !== null && prevMembershipNumber !== 'null'
                ? prevMembershipNumber
                : ''
            }
            validate={validators.required('This field is required')}
          />
        );
      case PAYMENT_TYPES.requestOnly:
        return (
          <CustomFieldTextInput
            type="text"
            id="requestOnly"
            name="requestOnly"
            label={requestOnlyLabel}
            placeholder={requestOnlyPlaceholder}
            className={css.message}
            validate={validators.required('This field is required')}
          />
        );
      default:
        return null;
    }
  };

  uploadWidget(e) {
    const _this = this;
    e.preventDefault();
    if (typeof window !== 'undefined') {
      window.cloudinary.openUploadWidget(
        { cloud_name: 'movementbuddy-pte-ltd', upload_preset: 'movementbuddy' },
        function(error, result) {
          if (result && result.info && result.info.secure_url)
            _this.setState({
              paymentProofSsUrls: [..._this.state.paymentProofSsUrls, result.info.secure_url],
            });
        }
      );
    }
  }

  paymentForm(formRenderProps) {
    const {
      className,
      rootClassName,
      inProgress: submitInProgress,
      formId,
      paymentInfo,
      authorDisplayName,
      showInitialMessageInput,
      intl,
      initiateOrderError,
      invalid,
      handleSubmit,
      form,
      listing,
      paymentType,
    } = formRenderProps;

    this.finalFormAPI = form;

    const submitDisabled = invalid || submitInProgress;
    const classes = classNames(rootClassName || css.root, className);
    // const cardClasses = classNames(css.card);

    const messagePlaceholder = intl.formatMessage(
      { id: 'StripePaymentForm.messagePlaceholder' },
      { name: authorDisplayName }
    );

    const kidsNamePlaceholder = intl.formatMessage({ id: 'StripePaymentForm.kidsNamePlaceholder' });

    const messageOptionalText = intl.formatMessage({
      id: 'StripePaymentForm.messageOptionalText',
    });

    const initialMessageLabel = intl.formatMessage(
      { id: 'StripePaymentForm.messageLabel' },
      { messageOptionalText: messageOptionalText }
    );

    const { paymentProofRequiredError, paymentProofSsUrls } = this.state;
    const listingCategory = listing.attributes.publicData.category;

    return (
      <Form className={classes} onSubmit={handleSubmit}>
        {initiateOrderError ? (
          <span className={css.errorMessage}>{initiateOrderError.message}</span>
        ) : null}

        {showInitialMessageInput && paymentType !== PAYMENT_TYPES.hitpay ? (
          <div className={css.formSection}>
            <h3 className={css.messageHeading}>
              <FormattedMessage id="StripePaymentForm.messageHeading" />
            </h3>

            <CustomFieldTextInput
              type="textarea"
              id={`${formId}-message`}
              name="initialMessage"
              label={initialMessageLabel}
              placeholder={messagePlaceholder}
              className={css.message}
            />
          </div>
        ) : null}

        {this.renderFormFields()}

        {listingCategory === 'kids' && (
          <div className={css.formSection}>
            <h3 className={css.messageHeading}>
              <FormattedMessage id="StripePaymentForm.kidsNameHeading" />
            </h3>

            <FieldTextInput
              type="textarea"
              id={`${formId}-kids-name`}
              name="kidsName"
              placeholder={kidsNamePlaceholder}
              className={css.message}
            />
          </div>
        )}

        {paymentProofRequiredError && paymentProofSsUrls.length <= 0 ? (
          <span className={css.errorMessage}>{paymentProofRequiredError}</span>
        ) : null}

        <div className={css.submitContainer}>
          {paymentType === PAYMENT_TYPES.hitpay ? null : (
            <p className={css.paymentInfo}>{paymentInfo}</p>
          )}
          <PrimaryButton2
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
          >
            {<FormattedMessage id="StripePaymentForm.submitConfirmPaymentInfo" />}
          </PrimaryButton2>
        </div>
      </Form>
    );
  }

  render() {
    const { onSubmit, ...rest } = this.props;
    return <FinalForm onSubmit={this.handleSubmit} {...rest} render={this.paymentForm} />;
  }
}

WithoutPaymentForm.defaultProps = {
  className: null,
  rootClassName: null,
  inProgress: false,
  loadingData: false,
  showInitialMessageInput: true,
  initiateOrderError: null,
};

WithoutPaymentForm.propTypes = {
  className: string,
  rootClassName: string,
  inProgress: bool,
  loadingData: bool,
  initiateOrderError: object,
  formId: string.isRequired,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  paymentInfo: string.isRequired,
  authorDisplayName: string.isRequired,
  showInitialMessageInput: bool,
  paymentType: string.isRequired,
  listing: propTypes.listing,
};

export default injectIntl(WithoutPaymentForm);
