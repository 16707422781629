import React, { useState, useEffect } from 'react';
import { func, shape } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { propTypes } from '../../util/types';
import { queryPromotedListings, showListing } from '../HomePage/HomePage.duck';
import { HeaderContainer, MobileHeaderContainer } from '../../containers';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { withViewport } from '../../util/contextHelpers';
import NewMobileHeader from '../../containers/HeaderContainer/NewMobileHeader';
import {
  queryPromotedListingsEdu,
  showListingEdu,
} from 'containers/EducationPage/EducationPage.duck';

const MAX_MOBILE_SCREEN_WIDTH = 1024;
export const TopbarContainerComponent = props => {
  const {
    currentUser,
    history,
    searchActivity,
    OnQueryPromotedListings,
    OnQueryPromotedListingsEdu,
    OnQueryPromotedListingsTen,
    showListing,
    showListingEdu,
    viewport,
    imageUploadRedirectError,
    userHasImage,
    setIsOpenShareForm,
    isEducationSite,
    isTennisSite,
    isDefaultMobileHeader,
    setModalOpen,
    showOnMob = true,
  } = props;
  const isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;
  const [quryParams, setQuryParams] = useState(null);
  const [showOpacity, setShowOpacity] = useState('');
  const [state, setState] = useState({ selectedSubCategory: searchActivity, currentTab: '' });
  const [isScrolling, setIsScrolling] = useState(false);

  const onSearch = searchquery => {
    setQuryParams(searchquery);
    history.push(
      createResourceLocatorString(
        isEducationSite ? 'EducationPage' : isTennisSite ? 'TennisPage' : 'HomePage',
        routeConfiguration(),
        {}
      )
    );
    setTimeout(() => {
      isEducationSite
        ? OnQueryPromotedListingsEdu({ ...quryParams, ...searchquery })
        : isTennisSite
        ? OnQueryPromotedListingsTen({ ...quryParams, ...searchquery })
        : OnQueryPromotedListings(searchquery);
    }, 500);
  };

  const onTabChange = searchquery => {
    setQuryParams(searchquery);
    OnQueryPromotedListings(searchquery);
  };

  const handleOpacityChange = value => setShowOpacity(value);

  const onkeywordSearch = activity => {
    history.push(
      createResourceLocatorString(
        isEducationSite ? 'EducationPage' : isTennisSite ? 'TennisPage' : 'HomePage',
        routeConfiguration(),
        {}
      )
    );
    const searchParams = {
      pub_sub_category: activity.key ? activity.key : null,
      keywords: activity.title ? null : activity.key ? null : activity,
      pub_listing_type: null,
    };
    setTimeout(() => {
      activity.id
        ? isEducationSite
          ? showListingEdu(activity.id)
          : showListing(activity.id)
        : isEducationSite
        ? OnQueryPromotedListingsEdu(searchParams)
        : OnQueryPromotedListings(searchParams);
    }, 500);
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.onscroll = () => {
        let currentScrollPos = window.pageYOffset;
        let maxScroll = document.body.scrollHeight - (window.innerHeight - 50);
        if (currentScrollPos > 0 && currentScrollPos < maxScroll && !isMobileLayout) {
          setIsScrolling(true);
        } else if (!isMobileLayout) {
          setIsScrolling(false);
        } else if (
          isMobileLayout &&
          currentScrollPos > 240 &&
          currentScrollPos < maxScroll &&
          !isScrolling
        ) {
          setIsScrolling(true);
        } else if (isMobileLayout && currentScrollPos < 240) {
          setIsScrolling(false);
        }
      };
    } // eslint-disable-next-line
  }, []);

  return (
    <>
      {!isMobileLayout ? (
        <HeaderContainer
          onSearch={onSearch}
          onTabChangeSearch={onTabChange}
          handleHeaderSearchBar={() => props.handleHeaderSearchBar} //handleHeaderSearchBar}
          handleOpacityChange={handleOpacityChange}
          isMobileLayout={isMobileLayout}
          isScrolling={isScrolling}
          onChangeActivity={value => setState({ ...state, selectedSubCategory: value })} // setSelectedSubCategory(value)}
          onkeywordSearch={onkeywordSearch}
          currentTab={tab => {
            setState({ ...state, currentTab: tab });
          }}
          currentUser={currentUser}
          disableFilterScroll={() => {}}
          showOpacity={showOpacity}
          imageUploadRedirectError={imageUploadRedirectError}
          userHasImage={userHasImage}
          isEducationSite={isEducationSite}
          // isTennisSite={isTennisSite}
        />
      ) : !!isDefaultMobileHeader ? (
        <MobileHeaderContainer
          showSearch={true}
          onSearch={onSearch}
          onTabChangeSearch={onSearch}
          resetPage={() => {}}
          handleOpacityChange={handleOpacityChange}
          onkeywordSearch={onkeywordSearch}
          isScrolling={isScrolling}
          handleHeaderSearchBar={() => props.handleHeaderSearchBar} //handleHeaderSearchBar}
          onChangeActivity={value => setState({ ...state, selectedSubCategory: value })}
          searchModalOpen={setModalOpen}
          disableFilterScroll={() => {}}
          showMap={false}
          currentTab={tab => {
            setState({ ...state, currentTab: tab });
          }}
          isEducationSite={isEducationSite}
          // isTennisSite={isTennisSite}
        />
      ) : (
        // showOnMob && (
        <NewMobileHeader
          setIsOpenShareForm={setIsOpenShareForm}
          isScrolling={isScrolling}
          history={history}
          isEducationSite={isEducationSite}
          showOnMob={showOnMob}
          // isTennisSite={isTennisSite}
        />
        // )
      )}
    </>
  );
};

TopbarContainerComponent.defaultProps = {
  currentUser: null,
};

TopbarContainerComponent.propTypes = {
  currentUser: propTypes.currentUser,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
};

const mapStateToProps = state => {
  // Topbar needs isAuthenticated
  // Topbar needs user info.
  const {
    currentUser,
    lastCompleteTransaction,
    lastBookingReviewDone,
    lastAcceptedPackage,
    lastAcceptedPackageNoteSeen,
  } = state.user;
  const { sendReviewInProgress, sendReviewError } = state.TransactionPage;
  const { searchActivity } = state.HeaderSection;
  return {
    currentUser,
    lastCompleteTransaction,
    lastBookingReviewDone,
    sendReviewInProgress,
    sendReviewError,
    lastAcceptedPackage,
    lastAcceptedPackageNoteSeen,
    searchActivity,
  };
};

const mapDispatchToProps = dispatch => ({
  OnQueryPromotedListings: searchquery => dispatch(queryPromotedListings(searchquery)),
  OnQueryPromotedListingsEdu: searchquery => dispatch(queryPromotedListingsEdu(searchquery)),
  showListing: listingId => dispatch(showListing(listingId)),
  showListingEdu: listingId => dispatch(showListingEdu(listingId)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const TopbarContainer = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withViewport
)(TopbarContainerComponent);

export default React.memo(TopbarContainer);
