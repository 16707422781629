import React, { memo } from 'react';
import { HorizontalScroll } from '../../../components';

import css from '../HomePage.css';
import classNames from 'classnames';

const CityFilter = ({ setSelectedLocation, selectedLocation, isEducationSite, city }) => {
  return (
    <div className={css.headerLocation}>
      <HorizontalScroll
        isSmallScrollArrow={false}
        className={classNames(css.locationListContainer, {
          [css.categoryListContainerWithoutArrow]:
            typeof window !== 'undefined' ? window.screen.width <= 425 : true,
        })}
      >
        {city?.map(city => (
          <div
            key={city.label}
            className={css.locationWrapperTop}
            onClick={() => setSelectedLocation(city)}
          >
            <div className={css.locationImgWrap}>
              <img src={city.cityImage} height="100px" width="100px" alt={city.label} />
            </div>
            <div
              className={classNames(
                css.locationTextWrap,
                selectedLocation?.label === city.label || selectedLocation?.search === city.label
                  ? isEducationSite
                    ? css.selectedEdu
                    : css.selected
                  : null
              )}
            >
              {city.label}
            </div>
          </div>
        ))}
      </HorizontalScroll>
    </div>
  );
};

export default memo(CityFilter);
