import React from 'react';
import { FormattedMessage } from 'react-intl';
import BecomeSeller from '../BlogPage/sections/BecomeSeller/BesomeSeller';
import { IconRoundCheckWhite } from '../../components/IconBecomeVendor/IconBecomeVendor';
import classNames from 'classnames';
import css from '../BecomeVendorPage/BecomeVendorPage.css';

const SelectPriceSection = () => {
  return (
    <section>
      <div className={css.selectPricesection}>
        <div className={css.pricingHeading}>
          <h2>
            <FormattedMessage id="BecomeVendorPage.simplePricingForEveryTeam" />
          </h2>
          <span className={css.fromFreelancers}>
            <FormattedMessage id="BecomeVendorPage.fromFreelancers" />
          </span>
        </div>
        <div className={css.planContainer}>
          <div className={css.planSection2}>
            <div className={css.proPlanMobile}>
              <div className={css.proPlanHeading}>Pro Plan</div>
              <hr className={css.proPlanDivider} />
              <div className={css.proPlanPricing}>
                <h4>$99</h4> <span>/ Per Year</span>
              </div>
              <div className={css.proPlanFeatures}>Features</div>
            </div>

            <div className={classNames(css.planLists, css.planListsWhite)}>
              <span>
                <IconRoundCheckWhite /> One Company page listing
              </span>
              <span>
                <IconRoundCheckWhite /> Up to 10 host Listings
              </span>
              <span>
                <IconRoundCheckWhite /> Multiple Class listings
              </span>
            </div>

            <div className={classNames(css.planLists, css.planListsWhite)}>
              <span>
                <IconRoundCheckWhite /> direct User messaging & Booking Request inbox
              </span>
              <span>
                <IconRoundCheckWhite /> Booking Management Tools
              </span>
              <span>
                <IconRoundCheckWhite /> New Lead Matching
              </span>
            </div>
            <div className={classNames(css.planLists, css.planListsWhite)}>
              <span>
                <IconRoundCheckWhite /> Social media reach
              </span>
              <span>
                <IconRoundCheckWhite /> Instagram feed embedded
              </span>
              <span>
                <IconRoundCheckWhite /> 0% commission
              </span>
            </div>
            <div className={classNames(css.planHeading, css.planHeadingWhite)}>
              {/* <div className={css.planPriceWrapper}>
                <h4>$9.99</h4>
                <span>/per Month</span>
              </div>
              <div className={css.primaryActionBtn}>
                <a
                  href="javascript:void(0)"
                  data-cb-type="checkout"
                  data-cb-item-0="Premium-Plan-Monthly-USD-Monthly"
                  data-cb-item-0-quantity="1"
                  data-cb-customer-cf-user-id="123"
                >
                  Get Pro Plan
                </a>
              </div>
              <span className={classNames(css.billedMonthly, css.whiteText)}>Billed Monthly</span>*/}
            </div>
          </div>
        </div>
      </div>

      <section className={css.becomeSellerSection}>
        <BecomeSeller isVendor={true} />
      </section>
    </section>
  );
};

export default SelectPriceSection;
