import React, { useMemo, useState } from 'react';
import { uid } from 'react-uid';
import moment from 'moment';
import PropTypes from 'prop-types';
import { propTypes } from 'util/types';
import { injectIntl } from '../../util/reactIntl';
import css from './BookingTimeForm.css';
import { formatMoney } from 'util/currency';
import { IconCalendar, Modal } from 'components';

const NUMBER_OF_INITIAL_OPTIONS = 6;

const Radio = props => {
  const { id, name, onClick, data, isActive = false } = props;

  const date = moment(data.bookingStartTime).format('ddd, DD MMM');
  const time = `${moment(data.bookingStartTime).format('kk:mm')} - ${moment(
    data.bookingEndTime
  ).format('kk:mm')}`;

  const price = data.price;

  return (
    <div className={css.classBookingSlot}>
      <input id={id} name={name} type="radio" />
      <label className={isActive ? css.active : ''} onClick={onClick} htmlFor={id}>
        <div>
          <div className={css.classBookingSlotDate}>{date}</div>
          <div className={css.classBookingSlotTime}>
            {time} / <span className={css.classBookingPrice}>{`1 place ${price}`}</span>
          </div>
        </div>

        <div className={css.classBookingBtn}>Choose</div>
      </label>
    </div>
  );
};

const getClassBookings = (listing, monthlyTimeSlots, timeZone, intl) => {
  const defaultClassLength = listing.attributes.publicData.defaultClassLength;
  const price = formatMoney(intl, listing.attributes.price);
  const timeSlots = Object.entries(monthlyTimeSlots).reduce(
    (acc, [key, value = { timeSlots: [] }]) => {
      const timeSlots = value.timeSlots || [];
      return [...acc, ...timeSlots];
    },
    []
  );

  const classBookings = timeSlots
    .filter(timeSLot => timeSLot.attributes.seats > 0)
    .map(timeSlot => {
      let start = moment(timeSlot.attributes.start).tz(timeZone);
      let end = moment(timeSlot.attributes.end).tz(timeZone);
      const diff = end.diff(start, 'minutes');
      const numberOfBookingSlots = diff / (defaultClassLength * 60);

      const bookingSlots = [];
      for (let i = 0; i < numberOfBookingSlots; i++) {
        const bookingStart = start;
        const bookingEnd = start.clone().add(defaultClassLength * 60, 'm');

        // increase starting time point for next booking slots
        start = start.clone().add(defaultClassLength * 60, 'm');

        const bookingSlotData = {
          bookingStartDate: { date: bookingStart.toDate() },
          bookingEndDate: { date: bookingEnd.toDate() },
          bookingStartTime: bookingStart.valueOf(),
          bookingEndTime: bookingEnd.valueOf(),
          timeSlotsOnSelectedDate: [timeSlot],
          price,
        };

        bookingSlots.push({
          id: uid(bookingSlotData.bookingStartDate),
          ...bookingSlotData,
        });
      }

      return bookingSlots;
    })
    .reduce((acc, slots) => [...acc, ...slots], []);

  return classBookings;
};

const ClassBookingFormFieldsComponent = props => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [optionIndex, setOptionIndex] = useState('');
  const { form, listing, monthlyTimeSlots, timeZone, intl, onManageDisableScrolling } = props;

  const classBookings = useMemo(() => {
    return getClassBookings(listing, monthlyTimeSlots, timeZone, intl);
    // eslint-disable-next-line
  }, [listing.id.uuid, monthlyTimeSlots, timeZone]);

  const radioClickHandler = (booking, index) => {
    setOptionIndex(index);
    const {
      bookingStartDate,
      bookingEndDate,
      bookingStartTime,
      bookingEndTime,
      timeSlotsOnSelectedDate,
    } = booking;
    form.batch(() => {
      form.change('bookingStartDate', bookingStartDate);
      form.change('bookingEndDate', bookingEndDate);
      form.change('bookingStartTime', bookingStartTime);
      form.change('bookingEndTime', bookingEndTime);
      form.change('timeSlotsOnSelectedDate', timeSlotsOnSelectedDate);
    });
    setModalOpen(false);
  };

  return (
    <>
      <div className={css.classBookingSlotContainer}>
        {classBookings.map((booking, index) =>
          index < NUMBER_OF_INITIAL_OPTIONS ? (
            <Radio
              key={booking.id}
              id={booking.id}
              name={'bookingOption'}
              onClick={() => radioClickHandler(booking, index)}
              data={booking}
            />
          ) : null
        )}
      </div>

      {optionIndex && optionIndex >= NUMBER_OF_INITIAL_OPTIONS ? (
        <Radio
          id={'selected-additional-option'}
          name={'bookingOption'}
          onClick={() => {
            setModalOpen(true);
          }}
          data={classBookings[optionIndex]}
          value={true}
          isActive={true}
        />
      ) : (
        <div className={css.classBookingMore} onClick={() => setModalOpen(true)}>
          <IconCalendar className={css.classBookingMoreIcon} /> View more variations
        </div>
      )}

      <Modal
        id="MoreVariantsModal"
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        onManageDisableScrolling={onManageDisableScrolling}
        contentClassName={css.modalContent}
        containerClassName={css.modalContainer}
      >
        {classBookings.map((booking, index) =>
          index >= NUMBER_OF_INITIAL_OPTIONS ? (
            <Radio
              key={booking.id}
              id={booking.id}
              name={'bookingOption'}
              onClick={() => radioClickHandler(booking, index)}
              data={booking}
              isActive={optionIndex === index}
            />
          ) : null
        )}
      </Modal>
    </>
  );
};

ClassBookingFormFieldsComponent.defaultProps = {
  monthlyTimeSlots: null,
};

ClassBookingFormFieldsComponent.propTypes = {
  monthlyTimeSlots: PropTypes.object,
  listing: propTypes.listing,
  onManageDisableScrolling: PropTypes.func,
};
const ClassBookingFormFields = injectIntl(ClassBookingFormFieldsComponent);
export default ClassBookingFormFields;
