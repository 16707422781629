import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { string, bool } from 'prop-types';
import { injectIntl, intlShape } from 'util/reactIntl';
import { Button, Modal } from 'components';
import { withRouter } from 'react-router-dom';
import css from './GetRecommendationModal.css';
import {
  updateAdminProfile,
  updateClientListing,
  updateRecommenderListing,
} from 'containers/HomePage/HomePage.duck';
import { useDispatch, useSelector } from 'react-redux';
import thankyousvg from 'assets/ThankYouPage/thankyousvg.svg';
import betterlucknexttime from 'assets/betterlucknexttime.jpeg';
import classNames from 'classnames';
import moment from 'moment';

const GetRecommendationModalComponent = props => {
  const { modalId, open, onModalClose, params = {}, intl } = props;
  const dispatch = useDispatch();
  const { currentUser } = useSelector(state => state.user);
  const currentUserId = currentUser?.id?.uuid;
  const [contactDetails, setContactDetails] = useState({
    email: '',
    url: '',
    name: '',
  });

  const [showThankYouModal, setShowThankYouModal] = useState(false);
  const [showBetterLuckNextTime, setShowBetterLuckNextTime] = useState(false);

  const toggleThankYouModal = () => {
    setShowThankYouModal(!showThankYouModal);
  };

  const toggleBetterLuckNextTime = () => {
    setShowBetterLuckNextTime(!showBetterLuckNextTime);
  };

  // Use the split function to divide the URL into parts using '/'
  const fullURL = contactDetails?.url.split('/');

  // Get the last part of the URL by accessing the last element in the parts array
  const extractedID = fullURL[fullURL.length - 1];

  const handleSubmit = async () => {
    // Get the current date and time in the "Asia/Singapore" time zone
    const presentDate = moment()
      .tz('Asia/Singapore')
      .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');

    const email = contactDetails?.email;
    const url = extractedID;
    const name = contactDetails?.name;

    const listingId = extractedID;
    const coachId = currentUserId;
    const startDate = presentDate;

    const createListingData = {
      email,
      url,
      name,
    };
    const result = await dispatch(updateClientListing(listingId, coachId, startDate));

    if (result === null) {
      // New entry created
      setShowThankYouModal(true);
      await dispatch(updateRecommenderListing(email, name, currentUserId, startDate, listingId));

      await dispatch(updateAdminProfile(currentUserId, startDate, listingId));
    } else {
      // Existing entry found
      setShowBetterLuckNextTime(true);
    }
  };

  const onChangeFirst = e => {
    if (e.persist) {
      e.persist();
    }
    const { name, value } = e.target;
    setContactDetails({ ...contactDetails, [name]: value });
  };

  return (
    <div>
      <Modal
        id={modalId}
        extraContainerClasses={css.matchModelWrap}
        contentClassName={css.customModalContent}
        extraScrollLayerClasses={css.customScrollLayer}
        isOpen={open}
        onClose={onModalClose}
        onManageDisableScrolling={() => {}}
      >
        <div className={css.matchMain}>
          <div className={css.matchHead}>
            <h2>Recommed Us Clients and Get Rewarded</h2>
          </div>
          <div className={css.matchWrap}>
            <div className={css.matchForm}>
              <label className={css.matchlabel}>
                Enter client's name you want to recommend us*
              </label>
              <input
                className={css.matchInput}
                type="name"
                placeholder="Client's Name (as in Probuddy.io)"
                name="name"
                value={contactDetails?.name}
                onChange={onChangeFirst}
              />
              <label className={css.matchlabel}>
                Enter client's profile URL you want to recommend us*
              </label>
              <input
                className={css.matchInput}
                type="url"
                placeholder="Client's profile URL"
                name="url"
                value={contactDetails?.url}
                onChange={onChangeFirst}
              />
              <label className={css.matchlabel}>
                Enter client's email you want to recommend us*
              </label>
              <input
                className={css.matchInput}
                type="email"
                placeholder="Client's email"
                name="email"
                value={contactDetails?.email}
                onChange={onChangeFirst}
              />
              <Button onClick={handleSubmit}>Submit</Button>
            </div>
          </div>
        </div>
      </Modal>
      {showThankYouModal && (
        <Modal
          id="thankYouModal" // You can use a different id for the "Thank You" modal
          extraContainerClasses={css.matchModelWrap}
          contentClassName={css.customModalContent}
          extraScrollLayerClasses={css.customScrollLayer}
          isOpen={showThankYouModal}
          onClose={toggleThankYouModal}
          onManageDisableScrolling={() => {}}
        >
          <div className={css.thankYouWrap}>
            <h2>Congrats!! Your recommendation has been noted.</h2>
            <h3>you will start receiving your rewards when client makes transactions.</h3>
            <img src={thankyousvg} alt={'Thank You so much img'} />
            <h3>You have successfully recommnded {contactDetails?.name}.</h3>
            <button
              className={classNames(css.btnBlue, css.btn)}
              onClick={() => {
                onModalClose();
                window.location.reload();
              }}
            >
              Have a look
            </button>
          </div>
        </Modal>
      )}
      {showBetterLuckNextTime && (
        <Modal
          id="betterlucknexttime" // You can use a different id for the "Thank You" modal
          extraContainerClasses={css.matchModelWrap}
          contentClassName={css.customModalContent}
          extraScrollLayerClasses={css.customScrollLayer}
          isOpen={showBetterLuckNextTime}
          onClose={toggleBetterLuckNextTime}
          onManageDisableScrolling={() => {}}
        >
          <div className={css.thankYouWrap}>
            <h2>Sorry!! This client has already been recommended.</h2>
            <h3>try recommending other clients.</h3>
            <img src={betterlucknexttime} alt={'Better Luck Next Time img'} />
            <h3>Client already recommended!!</h3>
            <button
              className={classNames(css.btnBlue, css.btn)}
              onClick={() => {
                onModalClose();
                window.location.reload();
              }}
            >
              Close
            </button>
          </div>
        </Modal>
      )}
    </div>
  );
};

GetRecommendationModalComponent.defaultProps = {
  rootClassName: null,
  className: null,
};

GetRecommendationModalComponent.propTypes = {
  rootClassName: string,
  className: string,
  bool,
  intl: intlShape.isRequired,
};

const GetRecommendationModal = compose(withRouter, injectIntl)(GetRecommendationModalComponent);

export default GetRecommendationModal;
