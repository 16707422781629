import React from 'react';
import { compose } from 'redux';
import css from './ListingCreateSelection.css';
import { injectIntl } from '../../util/reactIntl';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { isScrollingDisabled } from 'ducks/UI.duck';
import {
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Page,
  NamedLink,
  IconFacility,
  IconArrowRight,
} from 'components';
import IconProTrainers from 'components/IconProTrainers/IconProTrainers';
import IconUsers from 'components/IconUsers/IconUsers';
import IconGlobe from 'components/IconGlobe/IconGlobe';
import config from 'config';
import { TopbarContainer } from 'containers/index';

const LISTINGS = [
  {
    routeName: 'NewClassPage',
    icon: <IconUsers />,
    title: 'Classes',
    description: 'Setup fixed time slots and number of seats for your client to book.',
  },
  {
    routeName: 'NewEventPage',
    icon: <IconUsers />,
    title: 'Events',
    description: 'Setup fixed time slots and number of seats for your client to book.',
  },
  {
    routeName: 'NewListingPage',
    icon: <IconProTrainers />,
    title: 'Pros/Trainers',
    description:
      "Best for Coaching and Service Profiles with hourly availability. Secure clients' Payments/Packages and booking online",
  },
  {
    routeName: 'NewCompanyPage',
    icon: <IconGlobe />,
    title: 'Company Page',
    description:
      'Display your services and get exposure to millions of users. Your Classes and Pros listings well be seen here in your own easy to share mini web site.',
  },
  {
    routeName: 'NewFacilityPage',
    icon: <IconFacility />,
    title: 'Facilities',
    description:
      'Rent out your Fitness spaces like Yoga studios or Tennis courts at your desired hourly rate and view ratings and profiles for the screening of you potential clients',
  },
];

const ListingCreateSelectionComponent = props => {
  const { scrollingDisabled, intl } = props;

  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage(
    { id: 'ListingCreateSelectionComponent.schemaTitle' },
    { siteTitle }
  );
  const schema = {
    '@context': 'http://schema.org',
    '@type': 'WebPage',
    name: schemaTitle,
  };
  return (
    <Page title={schemaTitle} scrollingDisabled={scrollingDisabled} schema={schema}>
      <LayoutSingleColumn rootClassName={css.LayoutSingleColumnRoot}>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain className={css.staticPageWrapper}>
          <h1 className={css.heading}>What type of Listing do you want to create today?</h1>

          <p className={css.subHeading}>
            <span>In-person or Online Experience can be hosted from anywhere.</span>
            <span>Find new clients, enhance scheduling and payment for all.</span>
            <span>Fuss-free.</span>
          </p>

          <h2 className={css.sunHeading2}>Slect Type of Listing </h2>

          <div className={css.listingTypeContainer}>
            {LISTINGS.map(l => (
              <span className={css.listingType}>
                <div className={css.icon}>{l.icon}</div>
                <div className={css.title}>{l.title}</div>
                <div className={css.description}>{l.description}</div>
                <div className={css.linkSection}>
                  <div className={css.linkContainer}>
                    <NamedLink key={l.routeName} name={l.routeName}>
                      <IconArrowRight />
                    </NamedLink>
                  </div>
                </div>
              </span>
            ))}
          </div>
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

const mapStateToProps = state => {
  return {
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const ListingCreateSelection = compose(
  injectIntl,
  withRouter,
  connect(mapStateToProps, null)
)(ListingCreateSelectionComponent);

export default ListingCreateSelection;
