import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { uid } from 'react-uid';
import cns from 'classnames';
import Collapsible from 'react-collapsible';
import { propTypes } from 'util/types';
import css from 'containers/BookingPlanPage/components/TableEntry/TableEntry.css';
import { IconArrowDown, NamedLink } from 'components';

const WEEKS = 8;

const getMembershipId = item => item.transactions[0].attributes.protectedData.membership;

const renderWeekMarks = item => {
  const currentMoment = moment();

  const weeksToMark = item.transactions
    .map(tx => {
      const txStartMoment = moment(tx.booking.attributes.start);
      const isCurrentWeek = currentMoment.isSame(txStartMoment, 'week');
      return isCurrentWeek ? 0 : Math.ceil(txStartMoment.diff(currentMoment, 'weeks', true));
    })
    // filter out week's number that are bigger than 8
    // we are showing plan for 8 weeks only
    .filter(w => w <= WEEKS);

  const weekMarksArray = [];
  [...Array(WEEKS)].forEach((w, i) => {
    weekMarksArray.push(
      weeksToMark.includes(i) ? (
        <div key={uid(i)} className={cns(css.cell, css.marked)} />
      ) : (
        <div key={uid(i)} className={cns(css.cell, css.marked, css.not)} />
      )
    );
  });

  return weekMarksArray;
};

const countNumberOfBookings = entries => {
  return entries.reduce((sum, entry) => {
    if (entry.multipleClients) return sum + entry.clients.length;
    return sum + 1;
  }, 0);
};

function TableEntryRow({ entry }) {
  const name = entry.kidsName || entry.customer.attributes.profile.displayName;
  return (
    <div className={css.row}>
      <div className={cns(css.cell, css.name)}>
        <NamedLink
          name="ProfilePage"
          params={{ id: entry.customer.id.uuid }}
          className={css.nameContent}
        >
          {name}
        </NamedLink>
      </div>
      <div className={cns(css.cell, css.time)}>
        {entry.bookingStart && entry.bookingEnd && (
          <span
            style={{ wordBreak: 'keep-all', whiteSpace: 'nowrap' }}
          >{`${entry.bookingStart} - ${entry.bookingEnd}`}</span>
        )}
      </div>
      <div className={cns(css.cell, css.listingName)}>
        <span className={css.listingNameContent}>{entry.listing.attributes.title}</span>
      </div>
      <div className={cns(css.cell, css.membership)}>{getMembershipId(entry)}</div>
      {renderWeekMarks(entry)}
    </div>
  );
}

TableEntryRow.propTypes = {
  entry: propTypes.bookingPlanItem,
};

function TableEntryRowCollapsible({ entry }) {
  const [isCollapsed, setCollapsed] = useState(true);

  const trigger = (
    <div
      onClick={() => setCollapsed(isCollapsed => !isCollapsed)}
      className={cns(css.row, css.clickable)}
    >
      <div className={cns(css.cell, css.name)}>
        <span className={css.nameContent}>
          <IconArrowDown
            className={cns(
              css.collapseIcon,
              css.collapseIconMultiBooking,
              isCollapsed ? css.collapsed : ''
            )}
          />{' '}
          {`${entry.clients.length} bookings`}
        </span>
      </div>
      <div className={cns(css.cell, css.time)}>
        {entry.bookingStart && entry.bookingEnd && (
          <span
            style={{
              wordBreak: 'keep-all',
              whiteSpace: 'nowrap',
            }}
          >{`${entry.bookingStart} - ${entry.bookingEnd}`}</span>
        )}
      </div>
      <div className={cns(css.cell, css.listingName)}>
        <span className={css.listingNameContent}>{entry.listing.attributes.title}</span>
      </div>
    </div>
  );

  return (
    <>
      <Collapsible trigger={trigger} open={isCollapsed}>
        {entry.clients.map(clientRow => (
          <TableClientRow key={uid(clientRow)} client={clientRow} />
        ))}
      </Collapsible>
    </>
  );
}

function TableClientRow({ client }) {
  const name = client.kidsName || client.customer.attributes.profile.displayName;
  return (
    <div className={css.clientRow}>
      <div className={cns(css.cell, css.clientName)}>
        <NamedLink
          name="ProfilePage"
          params={{ id: client.customer.id.uuid }}
          className={css.nameContent}
        >
          {name}
        </NamedLink>
      </div>
      <div className={cns(css.cell, css.clientMembership)}>{getMembershipId(client)}</div>
      {renderWeekMarks(client)}
    </div>
  );
}

const WEEKDAYS = moment.weekdays();
function TableEntry({ entry, isCollapsedAll }) {
  const [isCollapsed, setCollapsed] = useState(true);
  const { weekday, entries } = entry;

  useEffect(() => {
    setCollapsed(isCollapsedAll);
  }, [isCollapsedAll]);

  const trigger = (
    <div onClick={() => setCollapsed(isCollapsed => !isCollapsed)} className={css.weekdayRow}>
      <div className={css.weekdayName}>
        <IconArrowDown className={cns(css.collapseIcon, isCollapsed ? css.collapsed : '')} />
        {WEEKDAYS[weekday === 7 ? 0 : weekday]}
      </div>
      <div className={css.bookingNumber}>{`${countNumberOfBookings(entries)} bookings`}</div>
    </div>
  );

  return (
    <div className={css.weekday}>
      <Collapsible trigger={trigger} open={!isCollapsed}>
        {entries.map(entry =>
          entry.multipleClients ? (
            <TableEntryRowCollapsible
              key={uid(entry)}
              entry={entry}
              isCollapsedAll={isCollapsedAll}
            />
          ) : (
            <TableEntryRow key={uid(entry)} entry={entry} />
          )
        )}
      </Collapsible>
    </div>
  );
}

export default TableEntry;
