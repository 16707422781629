import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink } from '../../components';
import { EditListingPricingForm } from '../../forms';
import { ensureOwnListing } from '../../util/data';
import { types as sdkTypes } from '../../util/sdkLoader';
import { defaultListingCurrency } from '../../util/types';
import { LISTING_TYPES } from 'util/constants';
import config from '../../config';

import css from './EditListingPricingPanel.css';

const { Money } = sdkTypes;

const EditListingPricingPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const listingType = currentListing?.attributes?.publicData?.listing_type;
  const isMembership = listingType === LISTING_TYPES.MEMBERSHIP;
  const { price } = currentListing.attributes;
  const { stock } = currentListing.attributes.publicData;
  const { membershipPrice } = currentListing.attributes.publicData;
  const currencyInitial =
    currentListing?.attributes?.publicData?.currency ||
    currentListing?.attributes?.price?.currency ||
    config.currency;
  const subscriptionDescription = currentListing?.attributes?.publicData?.subscriptionDescription;
  const [onChangePrice, setOnChangePrice] = useState(price);
  const [onMembershipPrice, setonMembershipPrice] = useState(membershipPrice);
  const [currency, setCurrency] = useState(price?.currency || config.currency);
  const { priceAddons } = currentListing.attributes.publicData;
  const { discountPriceAddons } = currentListing.attributes.publicData;
  const initAddonsValues = priceAddons
    ? priceAddons.map(i => ({
        label: i.label,
        price: new Money(i.amount, i.currency),
      }))
    : null;
  const initDiscountAddonsValues = discountPriceAddons
    ? discountPriceAddons.map(i => ({
        label: i.label,
        price: i.amount,
      }))
    : null;

  const { packages } = currentListing.attributes.publicData;
  const packagesValues =
    packages && packages.length > 0
      ? packages
          .filter(function(p) {
            return p.sesssions != null || p.price != null;
          })
          .map(p => ({ ...p, price: new Money(p.price, currencyInitial) }))
      : null;

  const membershipPariceValue = !!membershipPrice
    ? new Money(membershipPrice, currencyInitial)
    : null;

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingPricingPanel.title"
      values={{
        listingTitle: (
          <ListingLink listing={listing}>
            <FormattedMessage id="EditListingPricingPanel.listingTitle" />
          </ListingLink>
        ),
      }}
    />
  ) : (
    <FormattedMessage id="EditListingPricingPanel.createListingTitle" />
  );

  const priceCurrencyValid =
    price instanceof Money
      ? price.currency === config.currency || defaultListingCurrency.includes(price.currency)
      : true;
  const form = priceCurrencyValid ? (
    <EditListingPricingForm
      className={css.form}
      initialValues={{
        price,
        addons: initAddonsValues,
        discountaddons: initDiscountAddonsValues,
        packages: packagesValues,
        currency: currencyInitial,
        membershipPrice: membershipPariceValue,
        subscriptionDescription,
        stock,
      }}
      onPriceChange={value => {
        setOnChangePrice(value);
      }}
      onCurrencychange={value => {
        setCurrency(value);
      }}
      onmembershipPriceChange={value => {
        setonMembershipPrice(value);
      }}
      onSubmit={values => {
        const { addons, discountaddons, packages, stock, subscriptionDescription } = values;
        const price = new Money(onChangePrice.amount, currency);
        const packagesAttr =
          packages && packages.length > 0
            ? packages
                .filter(function(p) {
                  return p.sesssions != null || p.price != null;
                })
                .map(p => ({ ...p, price: p.price.amount }))
            : [];
        const priceAddons = addons
          ? addons.map(i => ({
              label: i.label.trim(),
              amount: i.price.amount,
              currency: currency,
            }))
          : [];
        const discountPriceAddons = discountaddons
          ? discountaddons.map(i => ({
              label: i.label.trim(),
              amount: i.price,
            }))
          : [];
        const updateValues = !!isMembership
          ? {
              price: price,
              publicData: {
                priceAddons,
                stock,
                discountPriceAddons,
                currency: currency,
                membershipPrice: onMembershipPrice && onMembershipPrice.amount,
                subscriptionDescription: subscriptionDescription,
              },
            }
          : {
              price: price,
              publicData: {
                packages: packagesAttr,
                priceAddons,
                stock,
                discountPriceAddons,
                currency: currency,
              },
            };

        onSubmit(updateValues);
      }}
      onChange={onChange}
      saveActionMsg={submitButtonText}
      disabled={disabled}
      ready={ready}
      updated={panelUpdated}
      updateInProgress={updateInProgress}
      fetchErrors={errors}
      listingType={listingType}
    />
  ) : (
    <div className={css.priceCurrencyInvalid}>
      <FormattedMessage id="EditListingPricingPanel.listingPriceCurrencyInvalid" />
    </div>
  );

  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      {form}
    </div>
  );
};

const { func, object, string, bool } = PropTypes;

EditListingPricingPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditListingPricingPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingPricingPanel;
