import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import {
  Footer,
  FooterBottomMenu,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  LeftBar,
  Modal,
  NamedLink,
  Page,
} from 'components';
import { isScrollingDisabled } from 'ducks/UI.duck';
import { injectIntl } from 'util/reactIntl';
import { withViewport } from 'util/contextHelpers';
import config from 'config';
import ProbuddylogoBlue from 'assets/ProbuddylogoBlue.svg';
import { IMAGES } from 'util/imagesPath';
import { ShareForm } from 'forms';
import classNames from 'classnames';
import routeConfiguration from 'routeConfiguration';
import { createResourceLocatorString } from 'util/routes';
import { queryPromotedListings, showListing } from '../HomePage/HomePage.duck';
import TopbarContainer from 'containers/TopbarContainer/TopbarContainer';
import { queryPromotedListingsEdu } from 'containers/EducationPage/EducationPage.duck';
import css from './BlogPage.css';

function BlogPage(props) {
  const scrollingDisabled = useSelector(state => isScrollingDisabled(state));
  const dispatch = useDispatch();

  const { intl, viewport, history, isEducationSite, isTennisSite } = props;

  useEffect(() => {
    if (typeof window !== 'undefined') {
      localStorage.setItem('isEducationSite', isEducationSite);
    }
  }, [isEducationSite]);

  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage(
    { id: isEducationSite ? 'EducationBlogPage.schemaTitle' : 'BlogPage.schemaTitle' },
    { siteTitle }
  );
  const schemaDescription = intl.formatMessage({
    id: isEducationSite ? 'Page.schemaDescription' : 'HomePage.schemaDescription',
  });

  const schemaImage = `${config.canonicalRootURL}${IMAGES.facebookImage}`;

  const viewportWidth = viewport.width;
  const MAX_MOBILE_SCREEN_WIDTH = 1024;
  const isMobileLayout = useMemo(
    () => (!!viewportWidth ? viewportWidth < MAX_MOBILE_SCREEN_WIDTH : false),
    [viewportWidth]
  );
  const url = typeof window !== 'undefined' && window.location.href;

  const [activeFooterTab, setActiveFooterTab] = useState('');
  const [isOpenShareForm, setIsOpenShareForm] = useState(false);
  const [queryParams, setQueryParams] = useState(null);

  const handleProviderQueryChange = providerQueryValue => {
    props.searchListingsAllSportsPage({
      keywords: providerQueryValue,
      pub_listing_type: null,
    });
    // props.history.push(createResourceLocatorString('HomePage', routeConfiguration()));
  };

  const blogCards = useMemo(() => {
    if (isEducationSite) {
      return [
        {
          title: 'Solo Tutors',
          image:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1683698612/probuddy/static/NewEducation/BlogPage/tutor_adhgx1.png',
        },
        {
          title: 'Academies',
          image:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1683698612/probuddy/static/NewEducation/BlogPage/academy_oqqd46.png',
        },
        {
          title: 'Students',
          image:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1683698612/probuddy/static/NewEducation/BlogPage/student_ewqywz.png',
        },
        {
          title: 'Parents',
          image:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1683698612/probuddy/static/NewEducation/BlogPage/parent_q8h8og.png',
        },
      ];
    } else {
      return [
        {
          title: 'For Instructors and Coaches',
          image:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1683708683/probuddy/static/images/BlogPage/coach_d64xyt.png',
        },
        {
          title: 'For Students',
          pageName: 'CategoryBlogPage',
          image:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1683708683/probuddy/static/images/BlogPage/students_pva9jk.png',
        },
        {
          title: 'Sports',
          slug: 'sports',
          image:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1683708683/probuddy/static/images/BlogPage/sport_rmxkkc.png',
        },
        {
          title: 'Wellness',
          image:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1683708683/probuddy/static/images/BlogPage/wellnesss_ql0ovp.png',
        },
        {
          title: 'Adventures',
          image:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1683708683/probuddy/static/images/BlogPage/adventur_rxcfke.png',
        },
        {
          title: 'For Personal Training',
          slug: 'for-personal-training',
          image:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1687427495/probuddy/static/images/BlogPage/for-personal-training_jvzwjl.png',
        },
      ];
    }
  }, [isEducationSite]);

  const onkeywordSearch = useCallback(
    activity => {
      history.push(
        createResourceLocatorString(
          isEducationSite ? 'EducationPage' : 'HomePage',
          routeConfiguration(),
          {}
        )
      );
      const searchParams = {
        pub_sub_category: activity.key ? activity.key : null,
        keywords: activity.title ? null : activity.key ? null : activity,
        pub_listing_type: null,
      };
      setTimeout(() => {
        activity.id
          ? showListing(activity.id)
          : isEducationSite
          ? dispatch(queryPromotedListingsEdu(searchParams))
          : dispatch(queryPromotedListings(searchParams));
      }, 500);
    },
    [history, dispatch]
  );

  const onSearch = searchquery => {
    props.history.push(
      createResourceLocatorString(
        isEducationSite ? 'EducationPage' : 'HomePage',
        routeConfiguration(),
        {}
      )
    );
    setQueryParams(...queryParams, searchquery);
    setTimeout(() => {
      queryPromotedListings(searchquery);
    }, 500);
  };

  return (
    <Page
      scrollingDisabled={scrollingDisabled}
      isBottomMenuRequire={false}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: IMAGES.facebookImage, width: 1200, height: 630 }]}
      twitterImages={[
        {
          url: `${config.canonicalRootURL}${IMAGES.twitterImage}`,
          width: 600,
          height: 314,
        },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer
            setIsOpenShareForm={setIsOpenShareForm}
            isEducationSite={isEducationSite}
          />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain>
          <div className={classNames(css.main)}>
            {!isMobileLayout && (
              <LeftBar
                history={props.history}
                onShowEnquiryTab={onSearch}
                onClose={() => setActiveFooterTab('search')}
                isEducationSite={isEducationSite}
                // isTennisSite={isTennisSite}
              />
            )}
            <div className={css.cardContainer}>
              <div className={css.breadCrumbWrapper}>
                <NamedLink name={isEducationSite ? 'EducationPage' : 'HomePage'}>Home</NamedLink>
                <span className={css.breadcrumbArrow}>&gt;</span>
                <NamedLink
                  name={isEducationSite ? 'EducationBlogPage' : 'BlogPage'}
                  className={css.currentListingTypeName}
                >
                  Blogs
                </NamedLink>
              </div>
              <div className={css.cardWrap}>
                <div className={css.logoContainer}>
                  <div className={css.logoWrap}>
                    <img src={ProbuddylogoBlue} alt="ProbuddyLogo" />
                    <div className={css.blog}>Blog</div>
                  </div>
                  <div className={css.suppotingText}>
                    {isEducationSite
                      ? 'Supporting you every step of the way in your educational journey.'
                      : 'Elevating your game with insider tips and advise.'}
                  </div>
                </div>
                <div className={css.cardWrapper}>
                  {blogCards.map((card, index) => (
                    <NamedLink
                      key={index}
                      name={isEducationSite ? 'EduCategoryBlogPage' : 'CategoryBlogPage'}
                      params={{
                        slug: (card.slug || card.title).replaceAll(' ', '-').toLowerCase(),
                        title: 'abc',
                      }}
                      title={card.title}
                      className={classNames(css.card, isEducationSite && css.cardEducation)}
                    >
                      <img src={card.image} alt=" " />
                      <div className={css.cardTitle}>{card.title}</div>
                    </NamedLink>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </LayoutWrapperMain>
        {!isMobileLayout ? (
          <LayoutWrapperFooter>
            <Footer isEducationSite={isEducationSite} />
          </LayoutWrapperFooter>
        ) : (
          <LayoutWrapperFooter
            className={classNames(css.layoutFooter, {
              [css.activeleftbarFooter]: activeFooterTab === 'leftbar',
              [css.createFooterTab]:
                activeFooterTab === 'create' || activeFooterTab === 'activitySearch',
            })}
          >
            {!!isMobileLayout && !(activeFooterTab === 'leftbar') && (
              <FooterBottomMenu
                activeFooterTab={activeFooterTab}
                changeActivieFooterTab={value => setActiveFooterTab(value)} // setState({ activeFooterTab: value })}
                handleProviderQueryChange={handleProviderQueryChange}
                history={props.history}
                onSearch={activity => onkeywordSearch(activity)}
                isEducationSite={isEducationSite}
              />
            )}
          </LayoutWrapperFooter>
        )}
      </LayoutSingleColumn>
      <Modal
        isOpen={!!(activeFooterTab === 'leftbar')}
        onClose={() => setActiveFooterTab('search')}
        id="filterModal"
        onManageDisableScrolling={() => {}}
        className={css.modalLeftBar}
      >
        <LeftBar
          isMobileLayout={isMobileLayout}
          history={props.history}
          onShowEnquiryTab={() => {}}
          onLogoClick={() => setActiveFooterTab('search')}
          onClose={() => setActiveFooterTab('search')}
          isEducationSite={isEducationSite}
        />
      </Modal>
      <Modal
        id="AllSportsPage.shareForm"
        className={css.shareFormModal}
        isOpen={isOpenShareForm}
        onClose={() => setIsOpenShareForm(false)}
        onManageDisableScrolling={() => {}}
      >
        <ShareForm url={url} />
      </Modal>
    </Page>
  );
}

export default compose(withRouter, withViewport, injectIntl)(BlogPage);
