import React from 'react';
import { intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { LINE_ITEM_CARD_SERVICE_FEE, propTypes } from '../../util/types';

import css from './BookingBreakdown.css';
import { FormattedMessage } from 'util/reactIntl';

const LineItemCreditCardFeeMaybe = props => {
  const { transaction, intl } = props;

  const item = transaction.attributes.lineItems.find(
    item => LINE_ITEM_CARD_SERVICE_FEE === item.code
  );

  const formattedTotal = item ? formatMoney(intl, item.lineTotal) : null;

  return item ? (
    <React.Fragment>
      <div className={css.lineItem}>
        <span className={css.itemLabel}>
          <FormattedMessage id={'BookingBreakdown.cardServiceFee'} />
        </span>
        <span className={css.itemValue}>{formattedTotal}</span>
      </div>
    </React.Fragment>
  ) : null;
};

LineItemCreditCardFeeMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemCreditCardFeeMaybe;
