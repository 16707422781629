import React, { Component, useState } from 'react';
import moment from 'moment';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { propTypes } from 'util/types';
import css from './FacilitiesInlineListingCard.css';
import { ensureListing, ensurePrice } from 'util/data';
import { ResponsiveImage, NamedRedirect } from 'components/index';
import { lazyLoadWithDimensions } from 'util/contextHelpers';
import { priceData } from 'util/currency';
import { injectIntl } from 'util/reactIntl';
import { createSlug } from 'util/urlHelpers';
import { getRatingFromReviews } from 'containers/ListingPage/helper';

class ListingImage extends Component {
  render() {
    return <ResponsiveImage {...this.props} />;
  }
}

const LazyImage = lazyLoadWithDimensions(ListingImage, { loadAfterInitialRendering: 3000 });

function FacilitiesInlineListingCard({ listing, intl, setActiveListing, review }) {
  const [isRedirect, setRedirect] = useState(false);
  const currentListing = ensureListing(listing);
  const companyName = currentListing.author?.attributes?.profile?.displayName;
  const id = currentListing?.id?.uuid;
  const { title, description, price, publicData, availabilityPlan } = currentListing.attributes;
  // const schedule = availabilityPlan.entries;
  const slug = createSlug(title);
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;
  const currentPrice = ensurePrice(price);
  const currentAddress = publicData.location.address || '';
  const { formattedPrice } = priceData(currentPrice, intl);
  const rating = getRatingFromReviews(review || []);

  return isRedirect ? (
    <NamedRedirect name="ListingPage" params={{ id, slug }} />
  ) : (
    <div
      className={css.mainCardWrapper}
      onMouseEnter={setActiveListing && (() => setActiveListing(currentListing.id))}
      onMouseLeave={setActiveListing && (() => setActiveListing(null))}
      onClick={() => setRedirect(true)}
    >
      <div className={css.mainCardSection}>
        <div className={css.courseImageWrapper}>
          <div className={css.classImage}>
            <LazyImage
              alt={listing.attributes.title}
              className={css.popularImage}
              image={firstImage}
              variants={['scaled-small', 'square-small2x']}
            />
          </div>

          <div className={css.cardDetailWrapper}>
            <div className={css.cardLocation}>
              <div>
                <svg
                  width="14"
                  height="18"
                  viewBox="0 0 14 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.99998 18C5.73693 16.9228 4.56618 15.742 3.49999 14.4697C1.9 12.5591 8.78892e-07 9.71362 8.78892e-07 7.00214C-0.000693018 5.61738 0.409508 4.26352 1.17869 3.11193C1.94787 1.96033 3.04146 1.06276 4.32106 0.532804C5.60065 0.00284618 7.00873 -0.135673 8.36709 0.134775C9.72546 0.405224 10.973 1.07248 11.952 2.05211C12.6037 2.70084 13.1203 3.47237 13.4719 4.32204C13.8234 5.17171 14.0029 6.08265 14 7.00214C14 9.71362 12.1 12.5591 10.5 14.4697C9.43378 15.7419 8.26304 16.9228 6.99998 18ZM6.99998 4.00273C6.20434 4.00273 5.44128 4.31874 4.87867 4.88124C4.31606 5.44374 3.99999 6.20665 3.99999 7.00214C3.99999 7.79764 4.31606 8.56055 4.87867 9.12305C5.44128 9.68555 6.20434 10.0016 6.99998 10.0016C7.79563 10.0016 8.55869 9.68555 9.1213 9.12305C9.68391 8.56055 9.99998 7.79764 9.99998 7.00214C9.99998 6.20665 9.68391 5.44374 9.1213 4.88124C8.55869 4.31874 7.79563 4.00273 6.99998 4.00273Z"
                    fill="#0058FA"
                  />
                </svg>
              </div>
              <div className={css.currentAddress}>{currentAddress}</div>
            </div>
            <h6 className={css.cardDetailTitle}>{title}</h6>
            <div className={css.cardUsername}>Hosted By: {companyName}</div>
            <div className={css.cardRatings}>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.03749 0.687912L10.6621 4.67558L14.9901 4.9884C16.014 5.08905 16.3443 6.31993 15.5833 6.85966L12.2159 9.60604L13.25 13.7377C13.4687 14.7226 12.3757 15.4103 11.6174 14.868L7.91088 12.578L4.22164 14.8181C3.33364 15.3266 2.32832 14.5204 2.62049 13.6462L3.69796 9.48369L0.383214 6.73688C-0.383497 6.06612 0.0875079 4.87993 1.02642 4.88167L5.39782 4.59965L7.03882 0.66188C7.45272 -0.26052 8.74975 -0.188064 9.03749 0.687912Z"
                  fill="#FFB534"
                />
              </svg>
              <div>
                {rating} <span className={css.cardTotalRatings}>{`(${review?.length || 0})`}</span>
              </div>
            </div>
          </div>
        </div>

        <div className={css.detailWrapper}>
          <div className={css.descriptionWrapper}>
            <h6 className={css.cardDescription}>{description}</h6>
          </div>

          <div className={css.bookingWrapper}>
            <div className={css.cardDetailprice}>{formattedPrice}</div>
          </div>
        </div>
      </div>
      {/* <div className={css.cardDayTimeWrap}>
        {schedule &&
          Array.isArray(schedule) &&
          schedule.map((item, index) => (
            <div key={index} className={css.cardDayTime}>
              <span>{item.dayOfWeek}:</span>
              <span>
                {item.startTime} - {item.endTime}
              </span>
            </div>
          ))}
      </div> */}
    </div>
  );
}

FacilitiesInlineListingCard.propTypes = {
  listing: propTypes.listing,
  setActiveListing: PropTypes.func,
};

export default compose(injectIntl)(FacilitiesInlineListingCard);
