import React, { useState, useEffect } from 'react';
import { uid } from 'react-uid';
import { omit } from 'lodash/object';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import classNames from 'classnames';
import { propTypes } from 'util/types';
import { FormattedMessage, injectIntl, intlShape } from 'util/reactIntl';
import { Form as FinalForm, Field } from 'react-final-form';
import { Button, Form, FieldTextInput } from '../../components';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import { SecondaryButton } from '../../components';
import { getExtensionFromUrl } from 'util/urlHelpers';

import css from './EditListingExpectationsForm.css';

const MAX_FIELDS = 7;

export const EditListingExpectationsForm = props => {
  const [assets, setAssets] = useState({});
  const [isAssetUpdated, setIsAssetUpdated] = useState(false);
  const [pictureError, setPictureError] = useState(false);

  useEffect(() => {
    if (props.initialValues.expectations && props.initialValues.expectations.length) {
      const assetsObj = {};
      props.initialValues.expectations.forEach(item => {
        assetsObj[item.id] = item.asset;
      });
      setAssets(assetsObj);
    }
  }, [props.initialValues]);

  const uploadWidget = (e, uniqId) => {
    e.preventDefault();
    if (typeof window !== 'undefined') {
      window.cloudinary.openUploadWidget(
        { cloud_name: 'movementbuddy-pte-ltd', upload_preset: 'movementbuddy' },
        function(error, result) {
          if (result && result.info && result.info.secure_url) {
            // setAssets({
            //   url: result.info.secure_url,
            //   type: result.info.resource_type,
            // });
            setAssets({
              ...assets,
              [uniqId]: { url: result.info.secure_url, type: result.info.resource_type },
            });
          }
          setIsAssetUpdated(true);
          setPictureError(false);
        }
      );
    }
  };

  const submitHandler = values => {
    const normalizedExpectations = values.expectations
      ? values.expectations.map(item => {
          return {
            ...item,
            asset: assets[item.id],
          };
        })
      : [];

    if (normalizedExpectations.some(item => !item.asset)) {
      setPictureError(true);
      return;
    }

    props.onSubmit({ expectations: normalizedExpectations });
  };

  const omitPictureOnPop = values => {
    if (!values || !values.length) {
      return;
    }

    const lastItem = values[values.length - 1];
    setAssets({
      ...omit(assets, lastItem.id),
    });
  };

  const required = value => (value ? undefined : 'Required');
  const filedArrayRequired = value => {
    if (value && value.length < 1) {
      return 'Require at least one entry';
    }

    return {};
  };

  // const addExpectation = () => {
  //   setTestArr([
  //     ...testArr,
  //     {
  //       assets: assets,
  //       id: uniqId,
  //       text: expectation,
  //     },
  //   ]);
  //   setUniqId(prev => prev + 1);
  // };

  return (
    <FinalForm
      {...props}
      mutators={{
        ...arrayMutators,
      }}
      initialValues={props.initialValues}
      onSubmit={submitHandler}
    >
      {formRenderProps => {
        const classes = classNames(css.root, formRenderProps.className);
        const {
          // form,
          mutators: { push, pop },
        } = formRenderProps.form;

        const submitInProgress = formRenderProps.updateInProgress;
        const submitReady = formRenderProps.updated || formRenderProps.ready;
        const submitDisabled =
          (formRenderProps.invalid ||
            formRenderProps.disabled ||
            formRenderProps.submitInProgress ||
            formRenderProps.pristine) &&
          !isAssetUpdated;

        return (
          <Form onSubmit={formRenderProps.handleSubmit} className={classes}>
            {pictureError ? (
              <p className={css.error}>
                <FormattedMessage id="EditListingExpectationsForm.pictureError" />
              </p>
            ) : null}
            {/* <div className={css.expectation}>
              <CustomFieldTextInput
                key={uniqId}
                type="text"
                id="expectations"
                name={`expectationsText`}
                label={<FormattedMessage id={'EditListingExpectationsForm.textLabel'} />}
                placeholder="Enter Name"
                onChange={e => {
                  const value = e.target.value;
                  form.change('expectationsText', value);
                  setExpectation(value);
                }}
                validate={required}
              />
              {testArr.id === uniqId ? (
                <div>
                  <h4 className={css.pictureHeading}>
                    <FormattedMessage id="EditListingExpectationsForm.pictureHeading" />
                  </h4>
                  <div className={css.pictureContainer}>
                    {assets.type === 'video' ? (
                      <video src={assets.url} controls width="100%">
                        <source
                          src={assets.url}
                          type={`video/${getExtensionFromUrl(assets.url)}`}
                        />
                      </video>
                    ) : (
                      <a
                        className={css.picture}
                        // eslint-disable-next-line
                        target="_blank"
                        href={assets.url}
                      >
                        <img src={assets.url} alt="formImage" />
                      </a>
                    )}
                  </div>
                </div>
              ) : null}
              <div>
                <button className={css.uploadPictureBtn} onClick={e => uploadWidget(e, uniqId)}>
                  <FormattedMessage id="EditListingExpectationsForm.uploadAsset" />
                </button>
              </div>
            </div> */}
            <FieldArray name={'expectations'} validate={filedArrayRequired}>
              {({ fields }) => {
                return fields.map((name, index) => {
                  {
                    const uniqId =
                      (props.initialValues.expectations &&
                        props.initialValues.expectations.length &&
                        props.initialValues.expectations.length <=
                          formRenderProps.values.expectations &&
                        props.initialValues.expectations[index].id) ||
                      uid(name, index);
                    return (
                      <div className={css.expectation}>
                        <Field
                          className={css.hiddenField}
                          component="input"
                          id={'expectation-id'}
                          name={`${name}.id`}
                          initialValue={uniqId}
                        />

                        <FieldTextInput
                          key={uniqId}
                          id="expectations"
                          name={`${name}.text`}
                          type="text"
                          className={css.textInput}
                          autoFocus
                          label={<FormattedMessage id={'EditListingExpectationsForm.textLabel'} />}
                          validate={required}
                        />
                        {/* <CustomFieldTextInput
                          key={uniqId}
                          type="text"
                          id="expectations"
                          name={`${name}.text`}
                          label={<FormattedMessage id={'EditListingExpectationsForm.textLabel'} />}
                          placeholder="Enter Name"
                          validate={required}
                        /> */}

                        {assets[uniqId] ? (
                          <div>
                            <h4 className={css.pictureHeading}>
                              <FormattedMessage id="EditListingExpectationsForm.pictureHeading" />
                            </h4>
                            <div className={css.pictureContainer}>
                              {assets[uniqId].type === 'video' ? (
                                <video src={assets[uniqId].url} controls width="100%">
                                  <source
                                    src={assets[uniqId].url}
                                    type={`video/${getExtensionFromUrl(assets[uniqId].url)}`}
                                  />
                                </video>
                              ) : (
                                <a
                                  className={css.picture}
                                  // eslint-disable-next-line
                                  target="_blank"
                                  href={assets[uniqId].url}
                                >
                                  <img src={assets[uniqId].url} alt="formImage" />
                                </a>
                              )}
                            </div>
                          </div>
                        ) : null}

                        <div>
                          <button
                            className={css.uploadPictureBtn}
                            onClick={e => uploadWidget(e, uniqId)}
                          >
                            <FormattedMessage id="EditListingExpectationsForm.uploadAsset" />
                          </button>
                        </div>
                      </div>
                    );
                  }
                });
              }}
            </FieldArray>

            <div className={css.mutateBtns}>
              <SecondaryButton
                disabled={
                  formRenderProps.values.expectations &&
                  formRenderProps.values.expectations.length === MAX_FIELDS
                }
                type="button"
                onClick={() => push('expectations', {})}
                // onClick={addExpectation}
              >
                <FormattedMessage id={'EditListingExpectationsForm.addExpectation'} />
              </SecondaryButton>

              <SecondaryButton
                type="button"
                onClick={() => {
                  pop('expectations');
                  omitPictureOnPop(formRenderProps.values.expectations);
                }}
              >
                <FormattedMessage id={'EditListingExpectationsForm.removeExpectation'} />
              </SecondaryButton>
            </div>
            <div className={css.submitButtonContainer}>
              <Button
                className={css.submitButton}
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
                ready={submitReady}
              >
                {formRenderProps.saveActionMsg}
              </Button>
            </div>
          </Form>
        );
      }}
    </FinalForm>
  );
};

EditListingExpectationsForm.defaultProps = { fetchErrors: null };

EditListingExpectationsForm.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingExpectationsForm);
