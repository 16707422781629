import React, { useEffect, useMemo, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import config from '../../config';
import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  NamedLink,
  ProductListing,
  Button,
  HorizontalScroll,
  FooterBottomMenu,
  Modal,
  LeftBar,
  IconSpinner,
  PaginationButtons,
} from 'components';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { createSlug } from 'util/urlHelpers';
import BecomeSeller from '../BlogPage/sections/BecomeSeller/BesomeSeller';
import classNames from 'classnames';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import StarRatingsIcon from '../../assets/VendorPage/StarRatingsIcon.svg';
import LocationIcon from '../../assets/VendorPage/LocationIcon.svg';
import ArrowIconLeft from '../../assets/landingPage/icons/arrowIconLeft.svg';
import { PrimaryButton2 } from 'components/Button/Button';
import Slider from 'react-slick';
import { NotFoundPage, TopbarContainer } from '../../containers';
import { withViewport } from '../../util/contextHelpers';
import {
  queryPromotedListingsAllSportsPage,
  searchListingsAllSportsPage,
  showListingAllSportsPage,
} from '../AllSportsPage/AllSportsPage.duck';
import { queryPromotedListings, showListing } from '../HomePage/HomePage.duck';
import { func, shape } from 'prop-types';
import { withRouter } from 'react-router-dom';
import { searchCategoryLocation } from '../HeaderContainer/HeaderContainer.duck';
import {
  MAX_MOBILE_SCREEN_WIDTH,
  sliderSettings,
  getSchemaId,
  getSchemaDescription,
} from './AllSportsPage.data';
import { city, cityEducation, searchableCategory, tennisScript } from 'marketplace-custom-config';
import { ShareForm } from 'forms';
import { isArray } from 'lodash';
import twitterImage from '../../assets/probuddyThumbnail-twitter-600X314.png';
import facebookImage from '../../assets/probuddyThumbnail-facebook-1200X630.png';
import twitterImageEdu from '../../assets/twitterThumbnail-Edu-600X310.png';
import facebookImageEdu from '../../assets/facebookThumnail-Edu-1200X630.png';
import css from './AllSportsPage.css';
import { listingMode } from 'forms/EditListingDescriptionForm/EditListingDescriptionForm.data';
import { ListingMode } from './ListingMode';
import { whatsappLinkClickTrack } from 'util/gtag_conversionTracking';
import GetQuoteModal from 'components/GetQuoteModal/GetQuoteModal';
import { getBannerImg, getQueAnsList } from 'components/GetQuoteModal/GetQuoteModal.data';
import { getHtmlElementTopPosition } from 'util/htmlElements';
import object from 'lodash/object';
import topfloatingimage from 'assets/topfloatingimage.png';

const AllSportsPageComponent = props => {
  const {
    viewport,
    params,
    history,
    isEducationSite,
    isTennisSite,
    queryPromotedListingsAllSportsPage,
    onSearchCategoryLocation,
    scrollingDisabled,
    // promotedListings,
    intl,
    gettingListings,
    isAuthenticated,
    promotedListingRefs,
    Pagination,
    currentUser,
    coursesListings,
    shopListings,
    facilityListings,
    tutorListings,
    freelancerListings,
    academyListings,
    companyListings,
  } = props;
  // scroll to specific components for : coaches academies facilities
  const topBarNavObj = isEducationSite
    ? { classesId: 'Classes', academiesId: 'Academies', tutorsId: 'Tutors' }
    : {
        shopId: 'Shop',
        facilitiesId: 'Rent Facilities',
        academiesId: 'Academies',
        coachesId: 'Coaches',
      };
  const topBarNavIds = Object.keys(topBarNavObj);
  const intialScrollClass = topBarNavIds.reduce((acc, crr) => ({ ...acc, [crr]: '' }), {});
  // { coachesId: '', academiesId: '', facilitiesId: '' };
  const [activeScrollClass, setActiveScrollClass] = useState(intialScrollClass);
  const scrollToId = eleId => {
    // const section = document.querySelector(`#${eleId}`);
    // section.scrollIntoView({ behavior: 'smooth', block: 'center' });

    const headerOffset = 52;
    const elementPosition = getHtmlElementTopPosition(`#${eleId}`);

    if (typeof window !== 'undefined') {
      const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      localStorage.setItem('isEducationSite', false);
      window.onscroll = () => {
        const activeId = topBarNavIds.find(id => {
          const idElePosition = getHtmlElementTopPosition(`#${id}`);
          if (idElePosition < 55) return true;
          return false;
        });
        setActiveScrollClass({ ...intialScrollClass, [activeId]: css.activeLink });

        // is scrolled
        setShowQuotes(window.pageYOffset > 500);
      };
    }
  }, []);

  const [selectedLocationstate, setSelectedLocationstate] = useState('');
  const [queryParams, setQueryParams] = useState(null);
  // const [showAllProduct, setShowAllProduct] = useState(false);
  const [activeFooterTab, setActiveFooterTab] = useState('search');
  const [isOpenShareForm, setIsOpenShareForm] = useState(false);
  const [selectedMode, setSelectedMode] = useState(props.searchListingTab || [listingMode[0].mode]);
  const [showQuotes, setShowQuotes] = useState(false);
  const [quotesModalOpen, setQuotesModalOpen] = useState(false);

  const [pageData, setPageData] = useState({
    'Company,company': { page: 1 },
    freelancer: { page: 1 },
    facility: { page: 1 },
    product: { page: 1 },
    academy: { page: 1 },
    tutor: { page: 1 },
    courses: { page: 1 },
  });
  useEffect(() => {
    if (Pagination && Object.keys(Pagination)?.length) {
      const pageData2 = {
        'Company,company': Pagination['Company,company'] || pageData['Company,company'],
        freelancer: Pagination?.freelancer || pageData?.freelancer,
        facility: Pagination?.facility || pageData?.facility,
        product: Pagination?.product || pageData?.product,
        academy: Pagination?.academy || pageData?.academy,
        tutor: Pagination?.tutor || pageData?.tutor,
        courses: Pagination?.courses || pageData?.courses,
      };
      setPageData(p => ({ ...p, ...pageData2 }));
      // }
    }
  }, [Pagination]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      localStorage.setItem('isEducationSite', false);
    }
  }, [isEducationSite]);

  const url = typeof window !== 'undefined' && window.location.href;
  const categoriesMediumOptions = useMemo(
    () =>
      isEducationSite
        ? config.custom.educationCategoriesMediumOptionsConfig
        : config.custom.categoriesMediumOptionsConfig,
    [isEducationSite]
  );
  const checkCategoryType = useMemo(
    () => categoriesMediumOptions.filter(item => item.params.slug === params?.slug),
    [categoriesMediumOptions, params]
  );

  const cityData = isEducationSite ? cityEducation : city;

  useEffect(() => {
    if (params?.slug) {
      // pub_listing_type: 'Company,company,freelancer,facility',
      const pub_listing_type = isEducationSite
        ? ['academy', 'tutor', 'courses']
        : ['Company,company', 'freelancer', 'facility', 'product'];
      const listingModeMaybe = isEducationSite ? { pub_listing_mode: 'Online,online' } : {};
      const category =
        checkCategoryType.length > 0
          ? { pub_category: searchableCategory(sportName) }
          : { pub_sub_category: searchableCategory(sportName) };

      pub_listing_type.map(val =>
        queryPromotedListingsAllSportsPage({
          ...category,
          page: pageData[val]?.page,
          ...listingModeMaybe,
          pub_listing_type: val,
        })
      );
    }
  }, [
    checkCategoryType,
    isEducationSite,
    isTennisSite,
    params,
    queryPromotedListingsAllSportsPage,
  ]);
  const FetchListingData = (pub_listing_type, page, activeId) => {
    const listingModeMaybe = isEducationSite ? { pub_listing_mode: 'Online,online' } : {};
    const category =
      checkCategoryType.length > 0
        ? { pub_category: searchableCategory(sportName) }
        : { pub_sub_category: searchableCategory(sportName) };

    queryPromotedListingsAllSportsPage({
      ...category,
      pub_listing_type: pub_listing_type,
      page: page,
      ...listingModeMaybe,
    });
    setActiveScrollClass({ ...intialScrollClass, [activeId]: css.activeLink });
  };

  const onSearchProduct = searchquery => {
    setQueryParams(searchquery);
    queryPromotedListingsAllSportsPage(searchquery);
  };

  const getNumberOfProductListings = () => {
    const width = viewport.width;
    if (width <= 550) return 5;
    else if (width >= 551 && width <= 1199) return 10;
    else if (width >= 1200 && width <= 1550) return 15;
    else return 20;
  };

  const setSelectedLocation = city => {
    const selectedLocation =
      selectedLocationstate?.search === city.label || selectedLocationstate?.label === city.label
        ? null
        : {
            predictions: [],
            search: city.label,
            selectedPlace: {
              address: city.label,
              bounds: city.bounds,
            },
          };
    setSelectedLocationstate(selectedLocation);
    onSearchCategoryLocation(selectedLocation);
    onSearchProduct({
      ...queryParams,
      pub_listing_type:
        queryParams?.pub_listing_type === 'enquiry' ? 'enquiry' : 'academy,tutor,courses',
      pub_listing_mode:
        selectedMode.includes('Online') && selectedMode.includes('In Person')
          ? 'Online, In Person' || 'Online,In Person'
          : selectedMode.includes('In Person')
          ? 'In Person'
          : !selectedMode.length
          ? ''
          : 'Online',
      address: selectedLocation ? selectedLocation?.search : null,
      bounds: selectedLocation ? city?.bounds : null,
      ...(checkCategoryType.length > 0
        ? { pub_category: searchableCategory(sportName) }
        : { pub_sub_category: searchableCategory(sportName) }),
    });
  };

  const handleNewListingChange = item => {
    const newSelectedListing = selectedMode.includes(item.mode)
      ? selectedMode.filter(e => e !== item.mode)
      : [...selectedMode, item.mode];

    setSelectedMode(newSelectedListing);

    const payload = {
      pub_listing_type:
        queryParams?.pub_listing_type === 'enquiry' ? 'enquiry' : 'academy,tutor,courses',
      pub_listing_mode:
        newSelectedListing.includes('Online') && newSelectedListing.includes('In Person')
          ? 'Online, In Person' || 'Online,In Person'
          : newSelectedListing.includes('In Person')
          ? 'In Person'
          : !newSelectedListing.length
          ? ''
          : 'Online',
      address: selectedLocationstate ? selectedLocationstate?.search : null,
      bounds: selectedLocationstate ? selectedLocationstate?.selectedPlace?.bounds : null,
    };

    onSearchProduct(payload);
  };

  const categoriesData = useMemo(
    () =>
      isEducationSite
        ? config.custom.educationCategoriesDataConfig
        : config.custom.categoriesDataConfig,
    [isEducationSite]
  );
  const featureOptions = isEducationSite
    ? config.custom.featureOptionsConfigEducation
    : config.custom.featureOptionsConfig;

  const sportName = params?.slug;

  const categoryData = categoriesData.find(item => item.id === sportName);

  const whyBookHeadingClass =
    categoryData?.whyBook?.position === 'center'
      ? classNames(css.title, css.textAlignCenter)
      : css.title;

  const chooseUsSectionClass =
    categoryData?.whyBook?.containerPosition === 'center'
      ? classNames(css.chooseUsSection, css.alignItemsCenter)
      : css.chooseUsSection;

  const isMobileLayout = !!viewport.width && viewport.width < MAX_MOBILE_SCREEN_WIDTH;

  const schemaIdKeyword =
    sportName === 'sports'
      ? 'CategoriesPage.schemaKeywordsSports'
      : sportName === 'wellness'
      ? 'CategoriesPage.schemaKeywordsWellness'
      : 'CategoriesPage.schemaKeywordsAdventure';
  const siteTitle = isEducationSite ? 'Probuddy Education' : config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: getSchemaId(sportName) }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: getSchemaDescription(sportName) });
  const schemaKeywords = intl.formatMessage({
    id: schemaIdKeyword,
  });

  const isSepecilaTennisScript = sportName === 'tennis' ? tennisScript : false;

  const whyBookData = useMemo(
    () => (isEducationSite ? config.custom.whyBook : categoryData?.whyBook),
    [isEducationSite, categoryData]
  );

  const instructorData = useMemo(
    () =>
      isEducationSite ? config.custom.instructorsData : categoryData?.instructors?.instructorsData,
    [isEducationSite, categoryData]
  );

  const featuredItem = useMemo(
    () => (isEducationSite ? config.custom.otherCategoryData.features : categoryData?.featured),
    [isEducationSite, categoryData]
  );

  const classesData = useMemo(
    () =>
      isEducationSite ? config.custom.classesData : categoryData?.topRatedClasses?.classesData,
    [isEducationSite, categoryData]
  );

  const reviewData = useMemo(
    () => (isEducationSite ? config.custom.otherCategoryData.reviews : categoryData?.reviews),
    [isEducationSite, categoryData]
  );

  const sellerData = useMemo(
    () => (isEducationSite ? config.custom.otherCategoryData.sellers : categoryData?.sellers),
    [isEducationSite, categoryData]
  );

  const getQuoteModalMemoized = useMemo(() => {
    const extraParams = {
      ...params,
      city: selectedLocationstate && selectedLocationstate.search,
    };
    return (
      <GetQuoteModal
        modalId={`CategoryPage.${sportName}.quotes`}
        open={quotesModalOpen}
        params={extraParams}
        intl={intl}
        banner={{
          img: getBannerImg(sportName) || categoryData?.heroImage,
          head: `Answer a couple questions to help us find you the best ${sportName} coach.`,
          desc: `With Probuddy, you can communicate with the best coaches in your city. We will match you with a program that is perfect for you within 24 hours.`,
        }}
        queOptionList={getQueAnsList(sportName)}
        onModalClose={() => setQuotesModalOpen(false)}
        currentUser={isAuthenticated ? currentUser : ''}
        isEducationSite={isEducationSite}
        isAuthenticated={isAuthenticated}
        mailAtbrs={{
          forName: `${isEducationSite ? 'Education' : ''} ${sportName} Category`,
          altUrl: `https://www.probuddy.io${isEducationSite ? '/education' : ''}/${sportName}`,
          postFixFromName: `${isEducationSite ? 'Education' : ''} ${sportName} Category`,
        }}
      />
    );
  }, [
    quotesModalOpen,
    isAuthenticated,
    isEducationSite,
    sportName,
    currentUser?.attributes?.email,
  ]);

  if (!categoryData || !Object.keys(categoryData).length) {
    return <NotFoundPage isEducationSite={isEducationSite} />;
  }

  const canonicalRootURL = config.canonicalRootURL;

  return (
    <Page
      description={schemaDescription}
      title={schemaTitle}
      keywords={schemaKeywords}
      scrollingDisabled={scrollingDisabled}
      schemaScript={isSepecilaTennisScript}
      twitterImages={[
        {
          name: 'twitter',
          url: isEducationSite
            ? `${canonicalRootURL}${twitterImageEdu}`
            : `${canonicalRootURL}${twitterImage}`,
          width: 600,
          height: 314,
        },
      ]}
      facebookImages={[
        {
          name: 'facebook',
          url: isEducationSite
            ? `${canonicalRootURL}${facebookImageEdu}`
            : `${canonicalRootURL}${facebookImage}`,
          width: 600,
          height: 405,
        },
      ]}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar className={css.positionStatic}>
          <TopbarContainer
            setIsOpenShareForm={setIsOpenShareForm}
            isEducationSite={isEducationSite}
            // isTennisSite={isTennisSite}
            showOnMob={!showQuotes}
          />
          {showQuotes && (
            <div className={css.floatingTopBar}>
              <div className={css.floatingTop}>
                {Object.entries(topBarNavObj)
                  .reverse()
                  .map(([key, val]) => (
                    <div
                      onClick={() => {
                        scrollToId(key);
                      }}
                      key={key}
                      className={classNames(css.floatingWrap, activeScrollClass[key])}
                    >
                      <p className={css.floatingLink}>{val}</p>
                    </div>
                  ))}
              </div>
              <div onClick={() => setQuotesModalOpen(true)}>
                <div className={classNames(css.wrapperInput, css.floatingInput)}>
                  <h4 className={css.wrapperInputHead}>
                    Let us find a {isEducationSite ? 'Tutor' : 'Coach'} for you?
                  </h4>
                  <div className={css.wrapperInputWrap}>
                    <div className={css.inputWrap}>
                      <label className={css.inputLabel}>Tell us what you need</label>
                      <input disabled type="text" value="We aim to respond within 30 minutes" />
                    </div>
                    <button className={css.btnQuote}>
                      <FormattedMessage id="CategoriesPage.quoteButtonText" />
                    </button>
                  </div>
                </div>
                <div className={css.newFloatingMain}>
                  <div className={css.newFloatingImage}>
                    <img src={topfloatingimage} alt="" />
                  </div>
                  <div className={css.newFloatingText}>
                    Let us find a {isEducationSite ? 'Tutor' : 'Coach'} for you?
                  </div>
                  <button className={css.newFloatingButton}>
                    <FormattedMessage id="CategoriesPage.quoteButtonText" />
                  </button>
                </div>
              </div>

              {/*
               //  floating Whatsapp messages and moblie what icon
              <div className={css.floatingWP}>
                <a
                  className={css.floatingWPLink}
                  href="https://wa.me/message/WHZFXEGQ6RL5H1"
                  onClick={() => {
                    whatsappLinkClickTrack();
                  }}
                >
                  <img className={css.imgMobile} alt="whatsapp-link" src="https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1699507459/probuddy/static/images/Icons/WhatsappImg_m1f6lt.png" />
                  <img className={css.imgDesktop} alt="whatsapp-link" src="https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1699507453/probuddy/static/images/Icons/WhatsappImgDesktop_lwohkw.png" />
                </a>
              </div>
              {!isMobileLayout && (
                <div className={classNames(css.floatingWP, css.floatingIG)}>
                  <a className={css.floatingWPLink} href="https://www.instagram.com/probuddy__io/">
                    <img className={css.imgDesktop} alt="instagram-link" src="https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1699507427/probuddy/static/images/Icons/instagramDesktop_osnszc.png" />
                  </a>
                </div>
              )} */}
            </div>
          )}
        </LayoutWrapperTopbar>

        <LayoutWrapperMain>
          <div className={css.content}>
            <section className={css.topSection}>
              <div className={css.topHeadContainer}>
                <div className={css.quoteWrapper}>
                  <h4>
                    <FormattedMessage
                      id="CategoriesPage.topHeadTitle"
                      values={{
                        sportName: sportName
                          .replaceAll('-', ' ')
                          .replace(
                            /\w\S*/g,
                            str => str.charAt(0).toUpperCase() + str.substr(1).toLowerCase()
                          ),
                        instructorText: isEducationSite ? 'Teachers' : 'Coaches',
                      }}
                    />
                  </h4>
                  <p>
                    <FormattedMessage id="CategoriesPage.topHeadSubTitle" />
                  </p>

                  <div className={classNames(css.inputWrapper)}>
                    <div className={css.wrapperInput} onClick={() => setQuotesModalOpen(true)}>
                      <input disabled type="text" value="Tell Us what you are looking for" />
                      <button className={css.btnQuote}>
                        <FormattedMessage id="CategoriesPage.quoteButtonText" />
                      </button>
                    </div>
                  </div>
                </div>
                <div className={css.heroImage}>
                  {categoryData?.heroVideo ? (
                    <>
                      <div className={css.overlayVideoBg}></div>
                      <video
                        src={categoryData.heroVideo}
                        className={css.heroVideo}
                        controls
                        loop
                        autoPlay
                        muted
                        playsInline
                        alt="How to find a Tennis Coaches"
                      >
                        <source
                          src={categoryData.heroVideo}
                          title="How to find a Tennis Coaches"
                          type="video/mp4"
                        />
                      </video>
                    </>
                  ) : (
                    <>
                      <div className={css.overlayBg}></div>
                      <img src={categoryData.heroImage} alt="heroImage" />
                    </>
                  )}
                </div>
              </div>
            </section>

            <div className={css.locationArea}>
              <div className={css.locationHeadingContainer}>
                <h3 className={css.locationTitle}>Choose Your City</h3>
              </div>
              <div className={css.locationHeader}>
                <HorizontalScroll
                  isSmallScrollArrow={false}
                  className={classNames(css.locationListContainer, {
                    [css.categoryListContainerWithoutArrow]:
                      typeof window !== 'undefined' ? window.screen.width <= 425 : true,
                  })}
                >
                  {cityData.map(city =>
                    !isEducationSite ? (
                      <NamedLink
                        key={city?.key}
                        name="AllCategoriesPage"
                        params={{
                          slug: params?.slug,
                          city: createSlug(city?.key),
                        }}
                        className={css.locationWrapperTop}
                      >
                        <div className={css.locationWrapperTop}>
                          <div className={css.locationImgWrap}>
                            <img
                              src={city.cityImage}
                              height="100px"
                              width="100px"
                              alt={city.label}
                            />
                          </div>
                          <div className={css.locationTextWrap}>{city.label}</div>
                          {/* classNames(
                              css.locationTextWrap,
                              (selectedLocationstate?.label === city.label ||
                                selectedLocationstate?.search === city.label) &&
                                css.selected
                            ) */}
                        </div>
                      </NamedLink>
                    ) : (
                      <div
                        className={css.locationWrapperTop}
                        onClick={() => setSelectedLocation(city)}
                      >
                        <div className={css.locationImgWrap}>
                          <img src={city.cityImage} height="100px" width="100px" alt={city.label} />
                        </div>
                        <div
                          className={classNames(
                            css.locationTextWrap,
                            (selectedLocationstate?.label === city.label ||
                              selectedLocationstate?.search === city.label) &&
                              css.selected
                          )}
                        >
                          {city.label}
                        </div>
                      </div>
                    )
                  )}
                </HorizontalScroll>
              </div>
            </div>

            {/* <div className={css.uniqueSectionContainer}>
              <section className={css.breadCrumbWrapper}>
                <NamedLink name={isEducationSite ? 'EducationPage' : 'HomePage'}>Home</NamedLink>
                <span className={css.breadcrumbArrow}>&gt;</span>
                <NamedLink name={isEducationSite ? 'EducationCategoriesPage' : 'CategoriesPage'}>
                  Category
                </NamedLink>
                <span className={css.breadcrumbArrow}>&gt;</span>
                <NamedLink
                  name={isEducationSite ? 'AllAcademicsPage' : 'AllSportsPage'}
                  params={{ slug: sportName }}
                  className={css.currentListingTypeName}
                >
                  {sportName.replaceAll('-', ' ')}
                </NamedLink>
              </section>
            </div> */}

            {isEducationSite && (
              <ListingMode
                handleNewListingChange={handleNewListingChange}
                selectedMode={selectedMode}
                listingModeWrapClass={css.listingMode}
              />
            )}
            {!isEducationSite && (
              <>
                <div className={css.companyListing} id="coachesId">
                  <span className={css.categoryId}>
                    {freelancerListings &&
                      pageData.freelancer?.totalItems + ' ' + sportName.replaceAll('-', ' ')}{' '}
                    Coaches
                  </span>
                </div>
                <div className={css.productsMobileWrapper}>
                  <div className={classNames(css.autorListingWrapper, css.popularExercisesSection)}>
                    {gettingListings ? (
                      <IconSpinner />
                    ) : freelancerListings && freelancerListings.length > 0 ? (
                      freelancerListings
                        // .slice(
                        //   0,
                        //   showAllProduct ? freelancerListings.length : getNumberOfProductListings()
                        // )
                        .map(pl => (
                          <ProductListing
                            showCountry={true}
                            key={pl.id.uuid}
                            pl={pl}
                            intl={intl}
                            isMobileLayout={isMobileLayout}
                            // sub_category={sportName.replaceAll('-', ' ')}
                          />
                        ))
                    ) : (
                      <div>There are no listings!!</div>
                    )}
                  </div>
                  {/* {freelancerListings && freelancerListings.length > getNumberOfProductListings() && (
                    <Button
                      className={css.productsButton}
                      onClick={() => setShowAllProduct(!showAllProduct)}
                    >
                      {showAllProduct ? (
                        <FormattedMessage id="CompanyPage.viewProductsLess" />
                      ) : (
                        <FormattedMessage
                          id="CompanyPage.viewProductsMore"
                          values={{
                            count: freelancerListings.length,
                          }}
                        />
                      )}
                    </Button>
                  )} */}
                </div>
                <div className={css.productPageWrapper}>
                  <PaginationButtons
                    page={pageData.freelancer?.page}
                    totalPages={pageData.freelancer?.totalPages}
                    onPageClick={pNo => FetchListingData('freelancer', pNo, 'coachesId')}
                  />
                </div>

                <div className={css.companyListing} id="academiesId">
                  <span className={css.categoryId}>
                    {companyListings &&
                      pageData['Company,company']?.totalItems +
                        ' ' +
                        sportName.replaceAll('-', ' ')}{' '}
                    Companies
                  </span>
                </div>
                <div className={css.productsMobileWrapper}>
                  <div className={classNames(css.autorListingWrapper, css.popularExercisesSection)}>
                    {gettingListings ? (
                      <IconSpinner />
                    ) : companyListings && companyListings.length > 0 ? (
                      companyListings
                        // .slice(
                        //   0,
                        //   showAllProduct ? companyListings.length : getNumberOfProductListings()
                        // )
                        .map(pl => (
                          <ProductListing
                            showCountry={true}
                            key={pl.id.uuid}
                            pl={pl}
                            intl={intl}
                            isMobileLayout={isMobileLayout}
                            sub_category={sportName.replaceAll('-', ' ')}
                          />
                        ))
                    ) : (
                      <div>There are no listings!!</div>
                    )}
                  </div>
                  {/* {companyListings && companyListings.length > getNumberOfProductListings() && (
                    <Button
                      className={css.productsButton}
                      onClick={() => setShowAllProduct(!showAllProduct)}
                    >
                      {showAllProduct ? (
                        <FormattedMessage id="CompanyPage.viewProductsLess" />
                      ) : (
                        <FormattedMessage
                          id="CompanyPage.viewProductsMore"
                          values={{
                            count: companyListings.length,
                          }}
                        />
                      )}
                    </Button>
                  )} */}
                </div>
                <div className={css.productPageWrapper}>
                  <PaginationButtons
                    page={pageData['Company,company']?.page} //facility
                    totalPages={pageData['Company,company']?.totalPages}
                    onPageClick={pNo => FetchListingData('Company,company', pNo, 'academiesId')}
                  />
                </div>

                <div className={css.companyListing} id="facilitiesId">
                  <span className={css.categoryId}>
                    {facilityListings &&
                      pageData.facility?.totalItems + ' ' + sportName.replaceAll('-', ' ')}{' '}
                    Facilities
                  </span>
                </div>
                <div className={css.productsMobileWrapper}>
                  <div className={classNames(css.autorListingWrapper, css.popularExercisesSection)}>
                    {gettingListings ? (
                      <IconSpinner />
                    ) : facilityListings && facilityListings.length > 0 ? (
                      facilityListings
                        // .slice(
                        //   0,
                        //   showAllProduct ? facilityListings.length : getNumberOfProductListings()
                        // )
                        .map(pl => (
                          <ProductListing
                            showCountry={true}
                            key={pl.id.uuid}
                            pl={pl}
                            intl={intl}
                            isMobileLayout={isMobileLayout}
                            sub_category={sportName.replaceAll('-', ' ')}
                          />
                        ))
                    ) : (
                      <div>There are no listings!!</div>
                    )}
                  </div>
                  {/* {facilityListings && facilityListings.length > getNumberOfProductListings() && (
                    <Button
                      className={css.productsButton}
                      onClick={() => setShowAllProduct(!showAllProduct)}
                    >
                      {showAllProduct ? (
                        <FormattedMessage id="CompanyPage.viewProductsLess" />
                      ) : (
                        <FormattedMessage
                          id="CompanyPage.viewProductsMore"
                          values={{
                            count: facilityListings.length,
                          }}
                        />
                      )}
                    </Button>
                  )} */}
                </div>
                <div className={css.productPageWrapper}>
                  <PaginationButtons
                    page={pageData.facility?.page} //facility
                    totalPages={pageData.facility?.totalPages}
                    onPageClick={pNo => FetchListingData('facility', pNo, 'facilitiesId')}
                  />
                </div>

                <div className={css.companyListing} id="shopId">
                  <span className={css.categoryId}>
                    {shopListings &&
                      pageData.product?.totalItems + ' ' + sportName.replaceAll('-', ' ')}{' '}
                    products
                  </span>
                </div>
                <div className={css.productsMobileWrapper}>
                  <div className={classNames(css.autorListingWrapper, css.popularExercisesSection)}>
                    {gettingListings ? (
                      <IconSpinner />
                    ) : shopListings && shopListings.length > 0 ? (
                      shopListings
                        // .slice(
                        //   0,
                        //   showAllProduct ? facilityListings.length : getNumberOfProductListings()
                        // )
                        .map(pl => (
                          <ProductListing
                            showCountry={true}
                            key={pl.id.uuid}
                            pl={pl}
                            intl={intl}
                            isMobileLayout={isMobileLayout}
                            sub_category={sportName.replaceAll('-', ' ')}
                          />
                        ))
                    ) : (
                      <div>There are no listings!!</div>
                    )}
                  </div>
                  {/* {facilityListings && facilityListings.length > getNumberOfProductListings() && (
                    <Button
                      className={css.productsButton}
                      onClick={() => setShowAllProduct(!showAllProduct)}
                    >
                      {showAllProduct ? (
                        <FormattedMessage id="CompanyPage.viewProductsLess" />
                      ) : (
                        <FormattedMessage
                          id="CompanyPage.viewProductsMore"
                          values={{
                            count: facilityListings.length,
                          }}
                        />
                      )}
                    </Button>
                  )} */}
                </div>
                <div className={css.productPageWrapper}>
                  <PaginationButtons
                    page={pageData.product?.page}
                    totalPages={pageData.product?.totalPages}
                    onPageClick={pNo => FetchListingData('product', pNo, 'shopId')}
                  />
                </div>
              </>
            )}

            {isEducationSite && (
              <>
                {/* <div className={css.servicesTitle}>{categoryData.topRatedClasses.title}</div>
                <div className={css.servicesSubheading}>
                  <div className={css.descriptionWrap}>
                    {isArray(categoryData.topRatedClasses.description) ? (
                      categoryData.topRatedClasses.description.map((des, index) => (
                        <span key={index} className={css.topSpan}>
                          {des}
                        </span>
                      ))
                    ) : (
                      <span className={css.topSpan}>
                        {categoryData.topRatedClasses.description}
                      </span>
                    )}
                  </div>
                </div> */}

                <div className={css.companyListing} id="tutorsId">
                  <span className={css.categoryId}>
                    {pageData.tutor?.totalItems + ' ' + sportName.replaceAll('-', ' ')} Tutors
                  </span>
                </div>
                <div className={css.productsMobileWrapper}>
                  <div className={classNames(css.autorListingWrapper, css.popularExercisesSection)}>
                    {gettingListings ? (
                      <IconSpinner />
                    ) : tutorListings && tutorListings.length > 0 ? (
                      tutorListings
                        // .slice(
                        //   0,
                        //   showAllProduct ? tutorListings.length : getNumberOfProductListings()
                        // )
                        .map(pl => (
                          <ProductListing
                            showCountry={true}
                            key={pl.id.uuid}
                            pl={pl}
                            intl={intl}
                            isMobileLayout={isMobileLayout}
                            sub_category={sportName.replaceAll('-', ' ')}
                          />
                        ))
                    ) : (
                      <div>There are no listings!!</div>
                    )}
                  </div>
                  {/* {tutorListings && tutorListings.length > getNumberOfProductListings() && (
                    <Button
                      className={css.productsButton}
                      onClick={() => setShowAllProduct(!showAllProduct)}
                    >
                      {showAllProduct ? (
                        <FormattedMessage id="CompanyPage.viewProductsLess" />
                      ) : (
                        <FormattedMessage
                          id="CompanyPage.viewProductsMore"
                          values={{
                            count: tutorListings.length,
                          }}
                        />
                      )}
                    </Button>
                  )} */}
                </div>
                <div className={css.productPageWrapper}>
                  <PaginationButtons
                    page={pageData.tutor?.page}
                    totalPages={pageData.tutor?.totalPages}
                    onPageClick={pNo => FetchListingData('tutor', pNo, 'tutorsId')}
                  />
                </div>

                <div className={css.companyListing} id="academiesId">
                  <span className={css.categoryId}>
                    {pageData.academy?.totalItems + ' ' + sportName.replaceAll('-', ' ')} Academies
                  </span>
                </div>
                <div className={css.productsMobileWrapper}>
                  <div className={classNames(css.autorListingWrapper, css.popularExercisesSection)}>
                    {gettingListings ? (
                      <IconSpinner />
                    ) : academyListings && academyListings.length > 0 ? (
                      academyListings
                        // .slice(
                        //   0,
                        //   showAllProduct ? academyListings.length : getNumberOfProductListings()
                        // )
                        .map(pl => (
                          <ProductListing
                            showCountry={true}
                            key={pl.id.uuid}
                            pl={pl}
                            intl={intl}
                            isMobileLayout={isMobileLayout}
                            sub_category={sportName.replaceAll('-', ' ')}
                          />
                        ))
                    ) : (
                      <div>There are no listings!!</div>
                    )}
                  </div>
                  {/* {academyListings && academyListings.length > getNumberOfProductListings() && (
                    <Button
                      className={css.productsButton}
                      onClick={() => setShowAllProduct(!showAllProduct)}
                    >
                      {showAllProduct ? (
                        <FormattedMessage id="CompanyPage.viewProductsLess" />
                      ) : (
                        <FormattedMessage
                          id="CompanyPage.viewProductsMore"
                          values={{
                            count: academyListings.length,
                          }}
                        />
                      )}
                    </Button>
                  )} */}
                </div>
                <div className={css.productPageWrapper}>
                  <PaginationButtons
                    page={pageData.academy?.page}
                    totalPages={pageData.academy?.totalPages}
                    onPageClick={pNo => FetchListingData('academy', pNo, 'academiesId')}
                  />
                </div>

                <div className={css.companyListing} id="classesId">
                  <span className={css.categoryId}>
                    {pageData.courses?.totalItems + ' ' + sportName.replaceAll('-', ' ')} Classes
                  </span>
                </div>
                <div className={css.productsMobileWrapper}>
                  <div className={classNames(css.autorListingWrapper, css.popularExercisesSection)}>
                    {gettingListings ? (
                      <IconSpinner />
                    ) : coursesListings && coursesListings.length > 0 ? (
                      coursesListings
                        // .slice(
                        //   0,
                        //   showAllProduct ? coursesListings.length : getNumberOfProductListings()
                        // )
                        .map(pl => (
                          <ProductListing
                            showCountry={true}
                            key={pl.id.uuid}
                            pl={pl}
                            intl={intl}
                            isMobileLayout={isMobileLayout}
                            sub_category={sportName.replaceAll('-', ' ')}
                          />
                        ))
                    ) : (
                      <div>There are no listings!!</div>
                    )}
                  </div>
                  {/* {coursesListings && coursesListings.length > getNumberOfProductListings() && (
                    <Button
                      className={css.productsButton}
                      onClick={() => setShowAllProduct(!showAllProduct)}
                    >
                      {showAllProduct ? (
                        <FormattedMessage id="CompanyPage.viewProductsLess" />
                      ) : (
                        <FormattedMessage
                          id="CompanyPage.viewProductsMore"
                          values={{
                            count: coursesListings.length,
                          }}
                        />
                      )}
                    </Button>
                  )} */}
                </div>
                <div className={css.productPageWrapper}>
                  <PaginationButtons
                    page={pageData.courses?.page}
                    totalPages={pageData.courses?.totalPages}
                    onPageClick={pNo => FetchListingData('courses', pNo, 'classesId')}
                  />
                </div>
              </>
            )}

            <div className={css.uniqueSectionContainer}>
              <HorizontalScroll
                autoFlipOn={true}
                isSmallScrollArrow={false}
                className={classNames({
                  [css.categoryListContainerWithoutArrow]:
                    typeof window !== 'undefined' ? window.screen.width <= 425 : true,
                })}
              >
                <section className={css.section}>
                  <div className={css.sectionImagesContainer}>
                    <img
                      className={css.sectionImages}
                      src={categoryData.section1.imgSrc}
                      alt="sectionImage1"
                    />

                    <div className={css.uniqueDesignSection}>
                      <h3>
                        <FormattedMessage id={categoryData.section1.heading} />
                      </h3>
                      <div className={classNames(css.descriptionWrap, css.textDescriptionLeft)}>
                        {isArray(categoryData.section1.description) ? (
                          categoryData.section1.description.map((des, index) => (
                            <span key={index} className={css.topSpan}>
                              {des}
                            </span>
                          ))
                        ) : (
                          <span className={css.topSpan}>{categoryData.section1.description}</span>
                        )}
                      </div>
                      <div className={css.learnLink}>
                        <NamedLink name={isEducationSite ? 'EducationPage' : 'HomePage'}>
                          {categoryData.section1.linkText}
                        </NamedLink>
                      </div>
                    </div>
                  </div>
                </section>

                <section className={css.section}>
                  <div className={css.sectionImagesContainer}>
                    <img
                      className={css.sectionImages}
                      src={categoryData.section2.imgSrc}
                      alt="sectionImage2"
                    />

                    <div className={css.uniqueDesignSection}>
                      <h3>
                        <FormattedMessage id={categoryData.section2.heading} />
                      </h3>
                      <div className={classNames(css.descriptionWrap, css.textDescriptionLeft)}>
                        {isArray(categoryData.section2.description) ? (
                          categoryData.section2.description.map((des, index) => (
                            <span key={index} className={css.topSpan}>
                              {des}
                            </span>
                          ))
                        ) : (
                          <span className={css.topSpan}>{categoryData.section2.description}</span>
                        )}
                      </div>
                      <div className={css.learnLink}>
                        <NamedLink name={isEducationSite ? 'BecomeTutorPage' : 'BecomeVendorPage'}>
                          {categoryData.section2.linkText}
                        </NamedLink>
                      </div>
                    </div>
                  </div>
                </section>
              </HorizontalScroll>
            </div>
            <section className={css.popularExercisesSection}>
              {!isEducationSite && (
                <div className={css.headingContainer}>
                  <h3 className={css.ourTopExampleTitle}>{categoryData.exercise.title}</h3>
                </div>
              )}

              <div className={css.exerciseCardContainer}>
                {categoryData.exercise.options &&
                  categoryData.exercise.options.length &&
                  categoryData.exercise.options.map((exercise, exIndex) => (
                    <div className={css.exerciseCard} key={`${exercise.name}_${exIndex}`}>
                      <div className={css.exerciseCardImage}>
                        <img src={exercise.imageSrc} alt="exerciseImg" />
                      </div>
                      <div className={css.exerciseCardInfo}>
                        <div className={css.exerciseCardTitle}>{exercise.name}</div>
                        <div className={css.exerciseCardDescription}>{exercise.description}</div>
                      </div>
                    </div>
                  ))}
              </div>
            </section>

            <section className={chooseUsSectionClass}>
              <img src={whyBookData.imgSrc} alt="whyBookImg" />

              <div className={css.chooseUsInfo}>
                <div className={whyBookHeadingClass}>{whyBookData.heading1}</div>
                <div className={css.description}>{whyBookData.description1}</div>

                <div className={whyBookHeadingClass}>{whyBookData.heading2}</div>
                <div className={css.description}>{whyBookData.description2}</div>

                <div className={css.cardsContainer}>
                  <span className={css.cardItem}>5000+ Members</span>
                  <span className={css.cardItem}>1000+ Trainers</span>
                </div>
                <div className={css.cardsContainer}>
                  <span className={css.cardItem}>50+ Programs</span>
                  <span className={css.cardItem}>150+ Awards</span>
                </div>
              </div>
            </section>

            <section className={css.yogaClassesLocation}>
              <div className={classNames(css.servicesTitle, css.textAlignLeft)}>
                {categoryData.classesNearYou.title}
              </div>
              <div className={css.servicesSubheading}>
                <div className={css.descriptionWrap}>
                  {isArray(categoryData.classesNearYou.description) ? (
                    categoryData.classesNearYou.description.map((des, index) => (
                      <span key={index} className={css.topSpan}>
                        {des}
                      </span>
                    ))
                  ) : (
                    <span className={css.topSpan}>{categoryData.classesNearYou.description}</span>
                  )}
                </div>
              </div>

              <div className={css.locationContainer}>
                {city.map(location => (
                  <NamedLink
                    key={location?.key}
                    name="AllCategoriesPage"
                    params={{
                      slug: params?.slug,
                      city: createSlug(location.key),
                    }}
                    className={css.companyLink}
                  >
                    <div className={css.locationItem}>
                      <img src={location.cityImage} alt="locationImg" />
                      <span src={css.locationName}>{location.label}</span>
                    </div>
                  </NamedLink>
                ))}
              </div>
            </section>

            {!isEducationSite && (
              <section className={css.servicesSection}>
                <div className={classNames(css.servicesTitle, css.textAlignLeft)}>
                  {categoryData.instructors.title}
                </div>
                <div className={css.servicesSubheading}>
                  <div className={css.descriptionWrap}>
                    {isArray(categoryData.instructors?.description) ? (
                      categoryData.instructors?.description.map((des, index) => (
                        <span key={index} className={css.topSpan}>
                          {des}
                        </span>
                      ))
                    ) : (
                      <span className={css.topSpan}>{categoryData.instructors?.description}</span>
                    )}
                  </div>
                </div>

                {!isEducationSite && (
                  <div className={css.servicesCarousel}>
                    <ul className={css.servicesCarouselItems}>
                      {instructorData.map((instructor, instIndex) => (
                        <li
                          className={css.servicesCarouselItem}
                          key={`${instructor.name}_${instIndex}`}
                        >
                          <img src={instructor.trainerImage} alt="trainerImage" />
                          <div className={css.carouselInfo}>
                            <div className={css.carouselHeading}>
                              <h5>{instructor.type}</h5>
                              <div className={css.carouselRating}>
                                <img src={StarRatingsIcon} alt="Rating Icon" />
                                <h5>{instructor.rating}</h5>
                                <span>({instructor.reviews}) Reviews</span>
                              </div>
                            </div>

                            <div className={css.carouselName}>{instructor.name}</div>
                            <div className={css.carouselDescription}>{instructor.description}</div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </section>
            )}
            <section className={css.companiesSection}>
              <div className={classNames(css.servicesTitle, css.textAlignLeft)}>
                {featuredItem?.title}
              </div>
              <div className={css.servicesSubheading}>{featuredItem?.description}</div>

              <div className={css.mobileCarouselCard}>
                <div className={css.carouselCardLeft}>
                  <img
                    src={
                      'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661504769/probuddy/static/images/BecomeVendorPage/CompanyImage1_ort1ne.png'
                    }
                    alt={' '}
                  />
                </div>

                <div className={css.carouselCardRight}>
                  <div className={css.cardTitle}>Galaxy Yoga Foundation</div>
                  <div className={css.cardInfo}>
                    <span className={css.cardRating}>
                      <img src={StarRatingsIcon} alt="StarRatingsIcon" />
                      <h5>4.8</h5>
                      <span>(230)</span>
                    </span>
                    <span className={css.cardLocation}>
                      <img src={LocationIcon} alt="LocationIcon" />
                      <span>Rajkot, Gujarat</span>{' '}
                    </span>
                  </div>

                  <div className={css.cardDescription}>
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                    Ipsum has been the industry's standard dummy text ever since the 1500s, when an
                    unknown printer took a galley of type and scrambled it to make a type specimen
                    book. It has survived not only five centuries, but also the leap into electronic
                    typesetting, remaining essentially unchanged.
                  </div>
                </div>
              </div>
              {!isEducationSite && (
                <Slider className={css.slickCarouselSlider} {...sliderSettings}>
                  {featuredItem?.featuredItems?.map((company, cmpIndex) => (
                    <div className={css.slickCarouselCard} key={`${company.name}_${cmpIndex}`}>
                      <div className={css.carouselCardLeft}>
                        <img src={company.companyImage} alt="companyImage" />
                      </div>

                      <div className={css.carouselCardRight}>
                        <div className={css.cardTitle}>{company.name}</div>
                        <div className={css.cardInfo}>
                          <span className={css.cardRating}>
                            <img src={StarRatingsIcon} alt="StarRatingsIcon" />
                            <h5>{company.rating}</h5>
                            <span>({company.reviews})</span>
                          </span>
                          <span className={css.cardLocation}>
                            <img src={LocationIcon} alt="LocationIcon" />
                            <span>{company.location}</span>
                          </span>
                        </div>

                        <div className={css.cardDescription}>{company.description}</div>
                      </div>
                    </div>
                  ))}
                </Slider>
              )}
            </section>

            <section className={css.popularYogaClassesSection}>
              {!isEducationSite && (
                <>
                  <div className={css.servicesTitle}>{categoryData?.topRatedClasses?.title}</div>
                  <div className={css.servicesSubheading}>
                    <div className={css.descriptionWrap}>
                      {isArray(categoryData?.topRatedClasses?.description) ? (
                        categoryData?.topRatedClasses?.description.map((des, index) => (
                          <span key={index} className={css.topSpan}>
                            {des}
                          </span>
                        ))
                      ) : (
                        <span className={css.topSpan}>
                          {categoryData?.topRatedClasses?.description}
                        </span>
                      )}
                    </div>
                  </div>
                </>
              )}

              {!isEducationSite && !!classesData?.length && (
                <div className={css.yogaClassesContainer}>
                  {classesData.map(item => (
                    <div className={css.yogaClassesItem} key={item.className}>
                      <div className={css.itemBasicInfo}>
                        <img className={css.itemImage} src={item.classesImage} alt="classesImage" />
                        <div className={css.itemText}>
                          <h3 className={css.yogaName}>{item.className}</h3>
                          <h6>{item.instructorName}</h6>
                          <div className={css.itemLocation}>
                            <img src={LocationIcon} alt="LocationIcon" />
                            <h6>{item.location}</h6>
                          </div>
                          <div className={css.yogaRatings}>
                            <img src={StarRatingsIcon} alt="StarRatingsIcon" />
                            <h6>
                              {item.rating} <span>({item.reviews})</span>
                            </h6>
                          </div>
                        </div>
                      </div>

                      <div className={css.itemDescription}>{item.description}</div>
                      <div className={css.itemBookingAndTimingInfo}>
                        <div className={css.timingInfo}>{item.bookingTimings}</div>
                        <div className={css.bookingPrice}>$ {item.bookingPrice}</div>
                        <NamedLink name={isEducationSite ? 'EducationPage' : 'HomePage'}>
                          <PrimaryButton2 className={css.bookNowBtn}>Book Now</PrimaryButton2>
                        </NamedLink>
                      </div>
                    </div>
                  ))}
                  <hr className={css.divider} />
                </div>
              )}
            </section>

            <section className={css.reviewsSection}>
              <div className={css.servicesTitle}>What Our Happy Clients Says!</div>
              <div className={css.servicesSubheading}>
                We let our happy clients do the talking! See what our students and instructors have
                to say about Probuddy.
              </div>

              <div className={css.reviewsCarouselContainer}>
                {!!reviewData?.length &&
                  reviewData.map(review => (
                    <div className={css.reviewsCardItem} key={review.name}>
                      <div className={css.cardHeader}>
                        <span className={css.cardImage}>
                          <img src={review.imageSrc} alt="reviewImg" />
                        </span>

                        <div className={css.cardInfo}>
                          <span className={css.cardName}>{review.name}</span>
                          <span className={css.cardSubheading}>{review.country}</span>
                        </div>
                      </div>
                      <div className={css.cardContent}>{review.description}</div>
                    </div>
                  ))}
              </div>
            </section>

            <section className={css.featuresSection}>
              <div className={css.servicesTitle}>{categoryData.featured?.title}</div>
              <div className={css.servicesSubheading}>
                {isArray(categoryData.featured?.description) ? (
                  categoryData.featured?.description.map((des, index) => (
                    <span key={index} className={css.topSpan}>
                      {des}
                    </span>
                  ))
                ) : (
                  <span className={css.topSpan}>{categoryData.featured?.description}</span>
                )}
              </div>
              <div className={css.featureItemsContainer}>
                {featureOptions &&
                  featureOptions.length &&
                  featureOptions.map((feature, index) => (
                    <div key={index} className={css.featureItem}>
                      <div className={css.featureIcon}>
                        <img src={feature.imgSrc} alt="featureImg" />
                      </div>
                      <div>
                        <div className={css.featureTitle}>{feature.title}</div>
                        <div className={css.featureTitle2}>{feature.title2}</div>
                      </div>
                      <div className={css.featureDescription}>{feature.description}</div>
                      <img className={css.arrowIconLeft} src={ArrowIconLeft} alt="ArrowIconLeft" />
                    </div>
                  ))}
              </div>
            </section>

            <section className={css.submitRequestSection}>
              <div className={css.imageContainer}>
                <h3>{sellerData.title}</h3>
                <NamedLink
                  name={isEducationSite ? 'BecomeTutorPage' : 'BecomeVendorPage'}
                  className={css.getProLink}
                >
                  {sellerData.linkText}
                </NamedLink>
              </div>
            </section>
            <section className={css.becomeSellerSection}>
              <BecomeSeller
                message={
                  !!isEducationSite && categoryData?.becomeSeller ? (
                    <div>{categoryData.becomeSeller}</div>
                  ) : (
                    <div>
                      Are you a{' '}
                      <span className={css.paramsTitle}>{sportName.replaceAll('-', ' ')}</span>{' '}
                      Expert ?
                    </div>
                  )
                }
                isEducationSite={isEducationSite}
              />
            </section>
          </div>
        </LayoutWrapperMain>
        {!isMobileLayout ? (
          <LayoutWrapperFooter>
            <Footer isEducationSite={isEducationSite} />
          </LayoutWrapperFooter>
        ) : (
          <LayoutWrapperFooter
            className={classNames(css.layoutFooter, {
              [css.activeleftbarFooter]: activeFooterTab === 'leftbar',
              [css.createFooterTab]:
                activeFooterTab === 'create' || activeFooterTab === 'activitySearch',
            })}
          >
            {!!isMobileLayout && !(activeFooterTab === 'leftbar') && (
              <FooterBottomMenu
                changeActivieFooterTab={value => setActiveFooterTab(value)}
                history={history}
                isEducationSite={isEducationSite}
              />
            )}
          </LayoutWrapperFooter>
        )}
      </LayoutSingleColumn>
      <Modal
        isOpen={!!(activeFooterTab === 'leftbar')}
        onClose={() => setActiveFooterTab('search')}
        id="filterModal"
        onManageDisableScrolling={() => {}}
        className={css.modalLeftBar}
      >
        <LeftBar
          isMobileLayout={isMobileLayout}
          history={history}
          onShowEnquiryTab={onSearchProduct}
          onLogoClick={() => setActiveFooterTab('search')}
          onClose={() => setActiveFooterTab('search')}
        />
      </Modal>
      <Modal
        id="AllSportsPage.shareForm"
        className={css.shareFormModal}
        isOpen={isOpenShareForm}
        onClose={() => setIsOpenShareForm(false)}
        onManageDisableScrolling={() => {}}
      >
        <ShareForm url={url} />
      </Modal>
      {getQuoteModalMemoized}
    </Page>
  );
};

AllSportsPageComponent.propTypes = {
  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
};

const mapStateToProps = state => {
  const { currentUserHasCompanyOrFreelancer, currentUser } = state.user;
  const {
    // promotedListingRefs,
    promotedListingRefs,
    suggestedListings,
    searchActivity,
    Pagination,
    gettingListings,
  } = state.AllSportsPage;
  // const promotedListings = promotedListingRefs[] && getMarketplaceEntities(state, promotedListingRefs);
  const coursesListings =
    promotedListingRefs.courses && getMarketplaceEntities(state, promotedListingRefs.courses);
  const shopListings =
    promotedListingRefs.product && getMarketplaceEntities(state, promotedListingRefs.product);
  const facilityListings =
    promotedListingRefs.facility && getMarketplaceEntities(state, promotedListingRefs.facility);
  const tutorListings =
    promotedListingRefs.tutor && getMarketplaceEntities(state, promotedListingRefs.tutor);
  const freelancerListings =
    promotedListingRefs.freelancer && getMarketplaceEntities(state, promotedListingRefs.freelancer);
  const academyListings =
    promotedListingRefs.academy && getMarketplaceEntities(state, promotedListingRefs.academy);
  const companyListings =
    promotedListingRefs['Company,company'] &&
    getMarketplaceEntities(state, promotedListingRefs['Company,company']);

  const { isAuthenticated, logoutInProgress } = state.Auth;

  const suggetListingRef = getMarketplaceEntities(state, suggestedListings);
  const suggetListings = suggetListingRef.map(l => ({
    id: l.id,
    title: l.attributes.title,
    image: l.images,
  }));
  return {
    // promotedListings,
    coursesListings,
    shopListings,
    facilityListings,
    tutorListings,
    freelancerListings,
    academyListings,
    companyListings,
    scrollingDisabled: isScrollingDisabled(state),
    promotedListingRefs,
    Pagination,
    promotedListingRefs,
    currentUserHasCompanyOrFreelancer,
    isAuthenticated,
    suggetListings,
    searchActivity,
    logoutInProgress,
    currentUser,
    gettingListings,
  };
};

const mapDispatchToProps = dispatch => ({
  searchListingsAllSportsPage: searchParams => dispatch(searchListingsAllSportsPage(searchParams)),
  queryPromotedListingsAllSportsPage: searchquery =>
    dispatch(queryPromotedListingsAllSportsPage(searchquery)),
  onSearchCategoryLocation: searchlocation => dispatch(searchCategoryLocation(searchlocation)),
  showListingAllSportsPage: listingId => dispatch(showListingAllSportsPage(listingId)),
  showListing: listingId => dispatch(showListing(listingId)),
  queryPromotedListings: searchquery => dispatch(queryPromotedListings(searchquery)),
});

const AllSportsPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
  withViewport
)(AllSportsPageComponent);

export default AllSportsPage;
