import { Modal } from 'components';
import React, { useEffect, useState } from 'react';
import css from '../JobsBoard.css';
import emailjs, { init } from '@emailjs/browser';
import classNames from 'classnames';
import thankyousvg from 'assets/ThankYouPage/thankyousvg.svg';

const JobsModelInitState = { message: '', email: '', name: '', whatsapp: '' };

const JobsBoardLeadModel = props => {
  const { open, onClose, userName, userEmail } = props;
  const [JobsModelInfo, setJobsModelInfo] = useState(JobsModelInitState);
  const [errorMessage, setErrorMessage] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);

  useEffect(() => {
    setJobsModelInfo(p => ({
      ...p,
      name: userName ? userName : '',
      email: userEmail ? userEmail : '',
    }));
  }, [userName, userEmail]);

  const sendJobsBoardReplyMail = ({ message, email, ...values }) => {
    const url =
      typeof window !== 'undefined' ? window?.location?.href : 'https://www.probuddy.io/jobs-board';
    const forName = `Lead Meesage from ${JobsModelInfo.name}`;
    const fromName = `Probuddy | Jobs Board ${forName}`;

    const templateParams = {
      for_name: forName,
      message: message,
      from_name: fromName,
      to_mail: 'Chris@probuddy.io,probuddyinquiries@gmail.com',
      from_mail: email,
      url: url,
    };

    if (process.env.REACT_APP_ENV === 'production') {
      emailjs
        .send('service_tn96fm5', 'template_04szlx8', templateParams, 'p656r9_5pKAHF0ioU')
        .then()
        .catch(e => console.log('e-->', e));
    }
  };

  const getPreparedMessage = () => {
    let creatingMessage = '';
    creatingMessage += `\nVendor Name: ${JobsModelInfo.name}\n`;
    creatingMessage += `Vendor Email: ${JobsModelInfo.email}\n`;
    creatingMessage += `Whatsapp number: ${JobsModelInfo.whatsapp}\n`;
    creatingMessage += `---------------------------------\n`;
    creatingMessage += `Lead message: ${JobsModelInfo.message}`;
    return creatingMessage;
  };

  const onJobsMessageSend = e => {
    e.preventDefault();
    if (!JobsModelInfo.message || !JobsModelInfo.email) {
      setErrorMessage('required');
    } else {
      try {
        const mailData = {
          email: JobsModelInfo.email,
          message: getPreparedMessage(),
        };
        sendJobsBoardReplyMail(mailData);
        setFormSubmitted(true);
        setJobsModelInfo(JobsModelInitState);
      } catch (error) {
        console.error(error);
      }
    }
  };
  const onChange = e => {
    if (errorMessage) {
      setErrorMessage('');
    }
    setJobsModelInfo({
      ...JobsModelInfo,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <Modal
      id="JobsCardReplyModel"
      extraContainerClasses={css.customModal}
      contentClassName={css.modalContent}
      isOpen={open}
      stopOutSideClose={true}
      onClose={onClose}
      onManageDisableScrolling={() => {}}
    >
      {!formSubmitted ? (
        <form onSubmit={onJobsMessageSend} className={css.JobsCardReplyModel}>
          {userName && (
            <div className={css.jobCardDetail}>
              Hello <h2 className={css.detailHead}>{userName}</h2>
            </div>
          )}
          {errorMessage && <p className={css.error}>{errorMessage}</p>}
          <div className={css.JobsCardMessagediv}>
            <label htmlFor="name">Name</label>
            <input
              className={css.JobsReplyMessagetext}
              onChange={onChange}
              value={JobsModelInfo.name}
              name="name"
              id="name"
              placeholder="full-name"
            />
          </div>
          <div className={css.JobsCardMessagediv}>
            <label htmlFor="email">Email</label>
            <input
              className={css.JobsReplyMessagetext}
              onChange={onChange}
              value={JobsModelInfo.email}
              name="email"
              id="email"
              placeholder="write email here..."
            />
          </div>
          <div className={css.JobsCardMessagediv}>
            <label htmlFor="whatsapp">Whatsapp no.</label>
            <input
              className={css.JobsReplyMessagetext}
              onChange={onChange}
              value={JobsModelInfo.whatsapp}
              name="whatsapp"
              id="whatsapp"
              placeholder="whatsapp number"
            />
          </div>
          <div className={css.JobsCardMessagediv}>
            <label htmlFor="lead">About Lead</label>
            <textarea
              className={css.JobsReplyMessagetext}
              onChange={onChange}
              name="message"
              id="lead"
              value={JobsModelInfo.message}
              rows={4}
              placeholder="write lead here..."
            ></textarea>
          </div>
          <div className={css.jobCardButtons}>
            <button type="submit" className={classNames(css.BtnBlue, css.btn, css.pointer)}>
              Submit Lead
            </button>
            <button onClick={onClose} className={classNames(css.BtnOrange, css.btn, css.pointer)}>
              Close
            </button>
          </div>
        </form>
      ) : (
        <div className={css.thankYouWrap}>
          <h2>Thank you for submiting the Lead</h2>
          <img src={thankyousvg} alt={'Thank You so much img'} />
        </div>
      )}
    </Modal>
  );
};

export default JobsBoardLeadModel;
