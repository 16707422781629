import React from 'react';
import css from './MatchJoinedUsers.css';

const MatchJoinedUsers = props => {
  const { joinRequest, numberMaxPlayers } = props;

  const totalJoined = joinRequest.filter(
    request => request.currentStatus === 'accepted' && request.showStatus === 'true'
  ).length;

  const condition = request =>
    request.currentStatus === 'accepted' && request.showStatus === 'true';
  const filteredData = joinRequest.filter(condition);
  const first3Requests = filteredData.slice(0, 3);
  const additionalPlayerCount =
    joinRequest.filter(
      request => request.currentStatus === 'accepted' && request.showStatus === 'true'
    ).length - first3Requests.length;

  const leftSeats = numberMaxPlayers - totalJoined;

  return (
    <div className={css.playersMain}>
      {first3Requests.map((request, index) => {
        if (request.currentStatus === 'accepted' && request.showStatus === 'true') {
          return (
            <div className={css.playersCard} key={index}>
              <img src={request.userImage} alt="User Image" />
            </div>
          );
        }
      })}
      {totalJoined > 3 && (
        <div className={css.playersCard}>
          <span className={css.playersMore}>+{additionalPlayerCount}</span>
        </div>
      )}
      {totalJoined === 0 && (
        <>
          <div className={css.playersCard}>
            <span className={css.playersMore}></span>
          </div>
          <div className={css.playersCard}>
            <span className={css.playersMore}></span>
          </div>
          <div className={css.playersCard}>
            <span className={css.playersMore}></span>
          </div>
        </>
      )}
      {leftSeats > 0 && <h6 className={css.joinedCount}>{leftSeats} seats vacant</h6>}
      {leftSeats < 0 && <h6 className={css.joinedCount}>No seats vacant</h6>}
    </div>
  );
};

export default MatchJoinedUsers;
