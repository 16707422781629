import React, { useState, useEffect } from 'react';
import PropTypes, { bool, func, object, number, string } from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';

import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import { propTypes } from '../../util/types';
import {
  Avatar,
  InlineTextButton,
  Logo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
  LazyLoadingImages,
} from '../../components';
import {
  IconBookings,
  IconPackages,
  IconSchedule,
  IconClient,
  IconJobsBoard,
  IconClientYourPackage,
  IconPlus,
} from '../../components/IconTopbar/IconTopbar';
import { TopbarSearchForm } from '../../forms';
import { IMAGES } from '../../util/imagesPath';

import css from './TopbarDesktop.css';

const TopbarDesktop = props => {
  const {
    className,
    currentUser,
    currentPage,
    rootClassName,
    notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues,
    isDarkMode,
  } = props;
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const authenticatedOnClientSide = mounted && isAuthenticated;
  let isPrivateSite = false;
  if (typeof window !== 'undefined' && !!window.sessionStorage) {
    isPrivateSite = window.sessionStorage.getItem('privateSiteUrl') ? true : false;
  }
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;
  const svgArrowIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="13" viewBox="0 0 8 13" fill="none">
      <path
        d="M1.95782 0.818359L0.542969 2.23321L5.13873 6.83901L0.542969 11.4448L1.95782 12.8597L7.97847 6.83901L1.95782 0.818359Z"
        fill="#0095B3"
      />
    </svg>
  );
  const classes = classNames(rootClassName || css.root, { [css.darkRoot]: isDarkMode }, className);

  const search = (
    <TopbarSearchForm
      className={classNames(css.searchLink, { [css.darkSearchLink]: isDarkMode })}
      desktopInputRoot={classNames(css.topbarSearchWithLeftPadding, {
        [css.darkTopbarSearchWithLeftPadding]: isDarkMode,
      })}
      onSubmit={onSearchSubmit}
      initialValues={initialSearchFormValues}
    />
  );

  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const profileMenu = authenticatedOnClientSide ? (
    <Menu isHoverDropDown={true} className={css.profileMenuRoot}>
      <MenuLabel
        className={classNames(css.profileMenuLabel, { [css.darkProfileMenuLabel]: isDarkMode })}
        isOpenClassName={css.profileMenuIsOpen}
      >
        <span>Hi, {currentUser && currentUser.attributes.profile.firstName} &nbsp;</span>
        <div className={css.activeUser}>
          <Avatar className={css.avatar} user={currentUser} disableProfileLink />
          <div className={css.active}></div>
        </div>
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        <MenuItem key="ProfileSettingsPage">
          <NamedLink
            className={classNames(css.profileSettingsLink, currentPageClass('ProfileSettingsPage'))}
            name="ProfileSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
          </NamedLink>
        </MenuItem>
        {!isPrivateSite && (
          <>
            <MenuItem key="MarketplaceDevelopmentPage">
              <NamedLink className={css.yourListingsLink} name="MarketplaceDevelopmentPage">
                <span className={css.menuItemBorder} />
                <FormattedMessage id="TopbarDesktop.marketplaceDevelopment" />
              </NamedLink>
            </MenuItem>
            <MenuItem key="BlogLink">
              <a className={css.yourListingsLink} href="https://probuddyblog.com/">
                <span className={css.menuItemBorder} />
                <FormattedMessage id="TopbarDesktop.blog" />
              </a>
            </MenuItem>
          </>
        )}
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  ) : null;

  const clientPortal = (
    <Menu isHoverDropDown={true}>
      <MenuLabel
        className={classNames(css.topbarMenuLink, { [css.darkTopbarMenuLink]: isDarkMode })}
      >
        <FormattedMessage id="TopbarDesktop.clientPortal" />
      </MenuLabel>
      <MenuContent className={css.clientMenuContent}>
        <div className={css.mainMenuItem}>
          <div className={css.whiteContainer}>
            <div className={css.menuContainer}>
              <MenuItem key="ClientMenuImage">
                <LazyLoadingImages
                  className={css.menuImage}
                  src={IMAGES.ImageClientPortal}
                  alt="ImageClientPortal"
                />
              </MenuItem>
              <MenuItem key="ClientMenuBookings">
                <NamedLink
                  className={css.inboxMenuLink}
                  name="InboxPage"
                  params={{ tab: 'orders' }}
                >
                  <div className={css.menuItemHolder}>
                    <div>
                      <IconBookings />
                    </div>
                    <div>
                      <span className={css.menuItemTitle}>
                        <FormattedMessage id="TopbarDesktop.inbox" />
                      </span>
                      <span className={css.menuItemInfo}>
                        <FormattedMessage id="TopbarDesktop.bookingsInfo" />{' '}
                      </span>
                    </div>
                  </div>
                </NamedLink>
              </MenuItem>
              <MenuItem key="ClientMenuWallet">
                <NamedLink name="InboxPage" params={{ tab: 'buyerPackages' }}>
                  <div className={css.menuItemHolder}>
                    <div>
                      <IconClientYourPackage />
                    </div>
                    <div>
                      <span className={css.menuItemTitle}>
                        <FormattedMessage id="TopbarDesktop.yourPackages" />
                      </span>
                      <span className={css.menuItemInfo}>
                        <FormattedMessage id="TopbarDesktop.checkBalance" />
                      </span>
                    </div>
                  </div>
                </NamedLink>
              </MenuItem>
              {!isPrivateSite && (
                <MenuItem key="ClientMenuJobsBoard">
                  <NamedLink name="InboxPage" params={{ tab: 'pro_connect_requests' }}>
                    <div className={css.menuItemHolder}>
                      <div>
                        <IconJobsBoard />
                      </div>
                      <div>
                        <span className={css.menuItemTitle}>
                          {/* <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.wallet" /> */}
                          <FormattedMessage id="TopbarDesktop.yourProConnectRequests" />
                        </span>
                        <span className={css.menuItemInfo}>
                          <FormattedMessage id="TopbarDesktop.manageOrCloseRequests" />
                        </span>
                      </div>
                    </div>
                  </NamedLink>
                </MenuItem>
              )}
              {!isPrivateSite && (
                <MenuItem key="ClientMenuRebookClass">
                  <NamedLink name="InboxPage" params={{ tab: 'orders', state: 'completed' }}>
                    <div className={css.menuItemHolder}>
                      <div>
                        <IconClient />
                      </div>
                      <div>
                        <span className={css.menuItemTitle}>
                          <FormattedMessage id="TopbarDesktop.yourFavourites" />
                        </span>
                        <span className={css.menuItemInfo}>
                          <FormattedMessage id="TopbarDesktop.rebookRecentSessions" />
                        </span>
                      </div>
                    </div>
                  </NamedLink>
                </MenuItem>
              )}
            </div>
          </div>

          <div className={classNames(css.greyContainer, { [css.hide]: isPrivateSite })}>
            <div className={css.menuContainer}>
              <MenuItem key="ClientMenuImage2">
                <LazyLoadingImages
                  className={css.menuImage}
                  src={IMAGES.ImageClientPortal2}
                  alt="ImageClientPortal2"
                />
              </MenuItem>
              <MenuItem rootClassName={css.menuItemHeading} key="ClientMenuTitle2">
                <span className={css.menuItemTitle}>
                  <FormattedMessage id="TopbarDesktop.explore" />
                </span>
              </MenuItem>
              <MenuItem key="ClientMenuDailyClass" rootClassName={css.addListingContainer}>
                <NamedLink
                  name="SearchPage"
                  to={{
                    search: `address=Singapore&bounds=1.4110994%2C103.9577613%2C1.2642103%2C103.6055395&dates=${moment().format(
                      'YYYY-MM-DD'
                    )},${moment().format('YYYY-MM-DD')}&times=00:00,23:59&pub_listing_type=class`,
                  }}
                >
                  <span className={css.displayAddMenu}>
                    <FormattedMessage id="TopbarDesktop.dailyClass" />
                    {svgArrowIcon}
                  </span>
                </NamedLink>
              </MenuItem>
              <MenuItem key="ClientMenuSearchPro" rootClassName={css.addListingContainer}>
                <NamedLink
                  name="SearchPage"
                  to={{
                    search: 'pub_listing_type=listing',
                  }}
                >
                  <span className={css.displayAddMenu}>
                    <FormattedMessage id="TopbarDesktop.searchPro" />
                    {svgArrowIcon}
                  </span>
                </NamedLink>
              </MenuItem>
              {/* <MenuItem key="ClientMenuPopularServices">
                <div>
                  <span className={css.displayAddMenu}>
                    <FormattedMessage id="TopbarDesktop.popularServices" />
                    {svgArrowIcon}
                  </span>
                </div>
              </MenuItem> */}
              <MenuItem key="ClientMenuRentSpaces" rootClassName={css.addListingContainer}>
                <NamedLink
                  name="SearchPage"
                  to={{
                    search: 'pub_listing_type=facility',
                  }}
                >
                  <span className={css.displayAddMenu}>
                    <FormattedMessage id="TopbarDesktop.rentSpaces" />
                    {svgArrowIcon}
                  </span>
                </NamedLink>
              </MenuItem>
              <MenuItem key="ClientMenuViewDeals" rootClassName={css.addListingContainer}>
                <NamedLink
                  name="SearchPage"
                  to={{
                    search: 'pub_listing_type=listing',
                  }}
                >
                  <span className={css.menuItemBorder} />
                  <span className={css.displayAddMenu}>
                    <FormattedMessage id="TopbarDesktop.viewDeals" />
                    {svgArrowIcon}
                  </span>
                </NamedLink>
              </MenuItem>
              <MenuItem key="ClientMenuEvents" rootClassName={css.addListingContainer}>
                <NamedLink
                  name="SearchPage"
                  to={{
                    search: 'pub_listing_type=product',
                  }}
                >
                  <span className={css.menuItemBorder} />
                  <span className={css.displayAddMenu}>
                    <FormattedMessage id="TopbarDesktop.events" />
                    {svgArrowIcon}
                  </span>
                </NamedLink>
              </MenuItem>
            </div>
          </div>
        </div>
      </MenuContent>
    </Menu>
  );

  const vendorPortal = (
    <Menu isHoverDropDown={true}>
      <MenuLabel
        className={classNames(css.topbarMenuLink, { [css.darkTopbarMenuLink]: isDarkMode })}
      >
        <FormattedMessage id="TopbarDesktop.hostPortal" />
      </MenuLabel>
      <MenuContent className={css.vendorMenuContent} ClassName={css.menuContent}>
        <div className={css.mainMenuItem}>
          <div className={css.whiteContainer}>
            <div className={css.menuContainer}>
              <MenuItem key="VendorMenuImage">
                <LazyLoadingImages
                  className={css.menuImage}
                  src={IMAGES.ImageVendorPortal}
                  alt="ImageVendorPortal"
                />
              </MenuItem>
              <MenuItem key="VendorMenuBookings">
                <NamedLink className={css.inboxMenuLink} name="InboxPage" params={{ tab: 'sales' }}>
                  <div className={css.menuItemHolder}>
                    <div>
                      <IconBookings />
                    </div>
                    <div>
                      <span className={css.menuItemTitleBooking}>
                        <FormattedMessage id="TopbarDesktop.inbox" /> {notificationDot}
                      </span>
                      <span className={css.menuItemInfo}>
                        <FormattedMessage id="TopbarDesktop.bookingsAcceptRescheduleCancel" />
                      </span>
                    </div>
                  </div>
                </NamedLink>
              </MenuItem>
              <MenuItem key="VendorMenuPackagesSold">
                <NamedLink
                  className={css.inboxMenuLink}
                  name="InboxPage"
                  params={{ tab: 'sellerPackages' }}
                >
                  <div className={css.menuItemHolder}>
                    <div>
                      <IconPackages />
                    </div>
                    <div>
                      <span className={css.menuItemBorder} />
                      <span className={css.menuItemTitle}>
                        <FormattedMessage id="TopbarDesktop.packagesSold" />
                      </span>
                      <span className={css.menuItemInfo}>
                        <FormattedMessage id="TopbarDesktop.packagesSoldInfo" />
                      </span>
                    </div>
                  </div>
                </NamedLink>
              </MenuItem>
              <MenuItem key="VendorMenuYourSchedule">
                <NamedLink
                  className={css.inboxMenuLink}
                  name="InboxPage"
                  params={{ tab: 'schedule' }}
                >
                  <div className={css.menuItemHolder}>
                    <div>
                      <IconSchedule />
                    </div>
                    <div>
                      <span className={css.menuItemTitle}>
                        <FormattedMessage id="TopbarDesktop.yourSchedule" />
                      </span>
                      <span className={css.menuItemInfo}>
                        <FormattedMessage id="TopbarDesktop.yourScheduleInfo" />
                      </span>
                    </div>
                  </div>
                </NamedLink>
              </MenuItem>
              <MenuItem key="VendorMenuYourClient">
                <NamedLink
                  className={css.inboxMenuLink}
                  name="InboxPage"
                  params={{ tab: 'myClients' }}
                >
                  <div className={css.menuItemHolder}>
                    <div>
                      <IconClient />
                    </div>
                    <div>
                      <span className={css.menuItemTitle}>
                        <FormattedMessage id="TopbarDesktop.yourClient" />
                      </span>
                      <span className={css.menuItemInfo}>
                        <FormattedMessage id="TopbarDesktop.yourClientInfo" />
                      </span>
                    </div>
                  </div>
                </NamedLink>
              </MenuItem>
              <MenuItem key="VendorMenuSubscription">
                <NamedLink name="SubscriptionPage" className={css.inboxMenuLink}>
                  <div className={css.menuItemHolder}>
                    <div>
                      <LazyLoadingImages
                        src={'/static/images/Icons/subscription.png'}
                        alt="subscription"
                      />
                    </div>
                    <div className={css.inboxMenuLink}>
                      <span className={css.menuItemBorder} />
                      <span className={css.menuItemTitle}>
                        <FormattedMessage id="TopbarDesktop.subscription" />
                      </span>
                      <span className={css.menuItemInfo}>
                        <FormattedMessage id="TopbarDesktop.subscriptionInfo" />
                      </span>
                    </div>
                  </div>
                </NamedLink>
              </MenuItem>
            </div>
          </div>

          <div className={css.greyContainer}>
            <div className={css.menuContainer}>
              <MenuItem key="VendorMenuImage2">
                <LazyLoadingImages
                  className={css.menuImage}
                  src={IMAGES.ImageVendorPortal2}
                  alt="ImageVendorPortal2"
                />
              </MenuItem>
              <MenuItem rootClassName={css.menuItemHeading} key="VendorMenuTitle2">
                <span className={css.menuItemTitle}>
                  <FormattedMessage id="TopbarDesktop.yourFitnessHealthListings" />
                </span>
              </MenuItem>
              <MenuItem key="VendorMenuListings">
                <NamedLink name="ManageListingsPage">
                  <span className={css.vendorPortalMenuItem}>
                    <FormattedMessage id="TopbarDesktop.manageAndEdit" />
                  </span>
                </NamedLink>
              </MenuItem>
              <MenuItem key="VendorShareBookingInviteOfYourListing">
                <NamedLink className={css.inboxMenuLink} name="ManageListingsPage">
                  <span className={css.vendorPortalMenuItem}>
                    <FormattedMessage id="TopbarDesktop.shareBookingPage" />
                  </span>
                </NamedLink>
              </MenuItem>
              <MenuItem key="VendorShareBookingEbeddedListing">
                <NamedLink
                  className={css.inboxMenuLink}
                  to={{
                    search: 'embed=true',
                  }}
                  name="ManageListingsPage"
                >
                  <span className={css.vendorPortalMenuItem}>
                    <FormattedMessage id="TopbarDesktop.embedPrivateListing" />
                  </span>
                </NamedLink>
              </MenuItem>
              <MenuItem key="VendorMenuSection2">
                <span className={css.menuItemTitle}>
                  <FormattedMessage id="TopbarDesktop.addListings" />
                </span>
              </MenuItem>
              <MenuItem key="VendorMenuAddPrivateSession" rootClassName={css.addListingContainer}>
                <NamedLink name="NewListingPage">
                  <span className={css.displayAddMenu}>
                    <FormattedMessage id="TopbarDesktop.addHostListing" />
                    {svgArrowIcon}
                  </span>
                </NamedLink>
              </MenuItem>
              <MenuItem key="VendorMenuAddGroupSession" rootClassName={css.addListingContainer}>
                <NamedLink name="NewClassPage">
                  <span className={css.displayAddMenu}>
                    <FormattedMessage id="TopbarDesktop.addClassListing" />
                    {svgArrowIcon}
                  </span>
                </NamedLink>
              </MenuItem>
              <MenuItem key="VendorMenuAddProduct" rootClassName={css.addListingContainer}>
                <NamedLink name="NewMembershipPage">
                  <span className={css.displayAddMenu}>
                    <FormattedMessage id="TopbarDesktop.createProduct" />
                    {svgArrowIcon}
                  </span>
                </NamedLink>
              </MenuItem>
              <MenuItem key="VendorMenuAddCompanyPage" rootClassName={css.addListingContainer}>
                <NamedLink name="NewCompanyPage">
                  <span className={css.displayAddMenu}>
                    <FormattedMessage id="TopbarDesktop.addCompanyPage" />
                    {svgArrowIcon}
                  </span>
                </NamedLink>
              </MenuItem>
              <MenuItem key="VendorMenuAddFacility" rootClassName={css.addListingContainer}>
                <NamedLink name="NewFacilityPage">
                  <span className={css.displayAddMenu}>
                    <FormattedMessage id="TopbarDesktop.addFacility" />
                    {svgArrowIcon}
                  </span>
                </NamedLink>
              </MenuItem>
            </div>
          </div>
        </div>
      </MenuContent>
    </Menu>
  );

  const signupLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="SignupPage" className={css.signupLink}>
      <span className={classNames(css.signup, { [css.darkTopbarMenuLink]: isDarkMode })}>
        <FormattedMessage id="TopbarDesktop.signup" />
      </span>
    </NamedLink>
  );

  const loginLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink
      name="LoginPage"
      className={classNames(css.loginLink, { [css.darkTopbarMenuLink]: isDarkMode })}
    >
      <span className={css.login}>
        <FormattedMessage id="TopbarDesktop.login" />
      </span>
    </NamedLink>
  );

  const becomeVendorPortal = (
    <Menu isHoverDropDown={true}>
      <MenuLabel
        className={classNames(css.topbarMenuLink, css.becomeVenderName, {
          [css.darkTopbarMenuLink]: isDarkMode,
        })}
      >
        <FormattedMessage id="TopbarDesktop.becomeHost" />
      </MenuLabel>
      <MenuContent className={css.clientMenuContent}>
        <div className={css.mainMenuItem}>
          <div className={css.whiteContainer}>
            <div className={css.menuContainer}>
              <MenuItem key="becomeVendorMenuImage">
                <LazyLoadingImages
                  className={css.menuImage}
                  src={IMAGES.ImagebecomeVendorPortal1}
                  alt="ImagebecomeVendorPortal1"
                />
              </MenuItem>
              <MenuItem rootClassName={css.becomeVendorHeading} key="becomeVendorMenuTitle">
                <NamedLink className={css.inboxMenuLink} name="BecomeVendorPage">
                  <span className={classNames(css.becomeVenderName)}>
                    <FormattedMessage id="TopbarDesktop.becomeHost" />
                  </span>
                  <span className={css.socialSearchMarketingInfo}>
                    <FormattedMessage id="TopbarDesktop.becomeVendorInfo" />
                  </span>
                </NamedLink>
              </MenuItem>
            </div>
          </div>

          <div className={classNames(css.greyContainer, { [css.hide]: isPrivateSite })}>
            <div className={css.menuContainer}>
              <MenuItem key="becomeVendorMenuImage2">
                <LazyLoadingImages
                  className={css.menuImage}
                  src={IMAGES.ImagebecomeVendorPortal2}
                  alt="ImagebecomeVendorPortal2"
                />
              </MenuItem>
            </div>
          </div>
        </div>
      </MenuContent>
    </Menu>
  );

  const jobsboardVendorPortal = (
    <Menu isHoverDropDown={true}>
      <MenuLabel>
        <NamedLink
          className={classNames(css.jobsBoardButtonContainer, {
            [css.darkJobsBoardButton]: isDarkMode,
          })}
          name="JobsBoardPage"
          to={{
            search: 'pub_listing_type=enquiry',
          }}
        >
          <div className={css.jobsBoardButton}>
            <span className={css.proConnetTitle}>
              <FormattedMessage id="TopbarMobileMenu.proConnect" />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M6.79922 2.23464C6.79922 1.49937 6.20317 0.90332 5.4679 0.90332C4.73263 0.90332 4.13658 1.49937 4.13658 2.23464V4.23175H2.14382C1.40855 4.23175 0.8125 4.82781 0.8125 5.56308C0.8125 6.29835 1.40855 6.8944 2.14382 6.8944H4.13658V8.89125C4.13658 9.62652 4.73263 10.2226 5.4679 10.2226C6.20317 10.2226 6.79922 9.62652 6.79922 8.89125V6.8944H8.80043C9.5357 6.8944 10.1318 6.29835 10.1318 5.56308C10.1318 4.82781 9.5357 4.23175 8.80043 4.23175H6.79922V2.23464Z"
                  fill="url(#paint0_linear_4628_79)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_4628_79"
                    x1="8.80043"
                    y1="1.56898"
                    x2="2.80948"
                    y2="9.88975"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#0095B3" />
                    <stop offset="1" stopColor="#10D6EB" />
                  </linearGradient>
                </defs>
              </svg>
            </span>
          </div>
        </NamedLink>
      </MenuLabel>
      <MenuContent className={css.clientMenuContent}>
        <div className={css.mainMenuItem}>
          <div className={css.whiteContainer}>
            <div className={css.menuContainer}>
              <h3 className={css.jobsBoardItemTitle}>
                {' '}
                <FormattedMessage id="TopbarDesktop.findWork" />
              </h3>
              <MenuItem key="jobsboardMenuImage1">
                <LazyLoadingImages
                  className={css.jobsBoardMenuImage}
                  src={'/static/images/JobsBoard/jobsBoardTopbar1.png'}
                  alt="jobsBoardTopbar1"
                />
              </MenuItem>
              <MenuItem key="jobsboardMenuFreelanceJobs">
                <NamedLink className={css.inboxMenuLink} name="JobsBoardPage">
                  <div className={css.jobsBoardItemHolder}>
                    <div>
                      <span className={css.menuItemTitle}>
                        <FormattedMessage id="TopbarDesktop.freelanceJobs" />
                      </span>
                      <span className={css.menuItemInfo}>
                        <FormattedMessage id="TopbarDesktop.freelanceJobsInfo" />
                      </span>
                    </div>
                  </div>
                </NamedLink>
              </MenuItem>
            </div>
          </div>

          <div className={classNames(css.greyContainer, { [css.hide]: isPrivateSite })}>
            <div className={css.menuContainer}>
              <h3 className={css.jobsBoardItemTitle}>
                <FormattedMessage id="TopbarDesktop.hireAPro" />
              </h3>
              <MenuItem key="jobsboardMenuImage2">
                <LazyLoadingImages
                  className={css.jobsBoardMenuImage}
                  src={'/static/images/JobsBoard/jobsBoardTopbar2.png'}
                  alt="jobsBoardTopbar2"
                />
              </MenuItem>
              <MenuItem key="jobsboardMenuListmyFreelancerRequest">
                <NamedLink className={css.inboxMenuLink} name="NewInquirePage">
                  <div className={css.jobsBoardItemHolder}>
                    <div>
                      <IconPlus />
                    </div>
                    <div>
                      <span className={css.menuItemTitle}>
                        <FormattedMessage id="TopbarDesktop.listmyFreelancerRequest" />
                      </span>
                      <span className={css.menuItemInfo}>
                        <FormattedMessage id="TopbarDesktop.listmyFreelancerRequestInfo" />
                      </span>
                    </div>
                  </div>
                </NamedLink>
              </MenuItem>
            </div>
          </div>
        </div>
      </MenuContent>
    </Menu>
  );

  return (
    <nav className={classes}>
      {isPrivateSite ? (
        <div className={css.privateNavWrap}>
          <a href={window.sessionStorage.getItem('privateSiteUrl')}>
            <Logo
              format="desktop"
              className={css.logo}
              isPrivateSite={isPrivateSite}
              isDarkMode={isDarkMode}
              alt={intl.formatMessage({ id: 'TopbarDesktop.logo' })}
            />
          </a>
          <div className={css.privateMenu}>
            {clientPortal}
            {signupLink}
            {loginLink}
            {profileMenu}
          </div>
        </div>
      ) : (
        <div id={'topbarNavWrap'} className={css.navWrap}>
          <NamedLink className={css.logoLink} name="LandingPage">
            <Logo
              format="desktop"
              className={css.logo}
              isDarkMode={isDarkMode}
              alt={intl.formatMessage({ id: 'TopbarDesktop.logo' })}
            />
          </NamedLink>
          {search}
          {jobsboardVendorPortal}
          {becomeVendorPortal}
          {/* {homeLink} */}
          {clientPortal}
          {vendorPortal}
          {signupLink}
          {loginLink}
          {profileMenu}
        </div>
      )}
    </nav>
  );
};

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  currentUserListing: null,
  currentUserListingFetched: false,
  isDarkMode: false,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUserListing: PropTypes.oneOfType([propTypes.ownListing, propTypes.listing]),
  currentUserListingFetched: bool,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
  isDarkMode: bool,
};

export default TopbarDesktop;
