import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import config from '../../config';
import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  NamedLink,
  FooterBottomMenu,
  Modal,
  LeftBar,
  ProductListing,
  HorizontalScroll,
} from 'components';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import BecomeSeller from '../BlogPage/sections/BecomeSeller/BesomeSeller';
import classNames from 'classnames';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import ArrowIconLeft from '../../assets/landingPage/icons/arrowIconLeft.svg';
import StarRatingsIcon from '../../assets/VendorPage/StarRatingsIcon.svg';
import LocationIcon from '../../assets/VendorPage/LocationIcon.svg';
import { NotFoundPage, TopbarContainer } from '../../containers';

import { withViewport } from '../../util/contextHelpers';
import { queryPromotedListingsAllCategoriesPage } from '../AllCategoriesPage/AllCategoriesPage.duck';
import { queryPromotedListings, showListing } from '../HomePage/HomePage.duck';
import { func, shape } from 'prop-types';
import { createResourceLocatorString } from 'util/routes';
import routeConfiguration from '../../routeConfiguration';
import { withRouter } from 'react-router-dom';
import {
  MAX_MOBILE_SCREEN_WIDTH,
  getSchemaId,
  getSchemaDescription,
  categoryCityReviews,
  sliderSettings,
  whyBook,
  sellers,
  topRatedClassesData,
  featuredItemsData,
  instructorsData,
  getFeatures,
  getCategoriesData,
  getCityName,
  getSection1Img,
  getSection2Img,
  getHeroImages,
  heroVideoAllCategories,
} from './AllCategoriesPage.data';
import { ShareForm } from 'forms';
import Slider from 'react-slick';
import Button, { PrimaryButton2 } from 'components/Button/Button';
import _, { isArray } from 'lodash';
import { city, cityEducation, searchableCategory } from 'marketplace-custom-config';
import css from './AllCategoriesPage.css';
import { createSlug } from 'util/urlHelpers';
import GetQuoteModal from 'components/GetQuoteModal/GetQuoteModal';
import { getBannerImg, getQueAnsList } from 'components/GetQuoteModal/GetQuoteModal.data';
import topfloatingimage from 'assets/topfloatingimage.png';
import { getHtmlElementTopPosition } from 'util/htmlElements';

const AllCategoriesPageComponent = props => {
  const {
    params,
    queryPromotedListingsAllCategoriesPage,
    scrollingDisabled,
    viewport,
    intl,
    history,
    promotedListings,
    isEducationSite,
    isAuthenticated,
    currentUser,
  } = props;

  // scroll to specific components for : coaches academies facilities
  const topBarNavObj = {
    facilitiesId: 'Rent Facilities',
    academiesId: 'Academies',
    coachesId: 'Coaches',
  };
  const topBarNavIds = Object.keys(topBarNavObj);
  const intialScrollClass = topBarNavIds.reduce((acc, crr) => ({ ...acc, [crr]: '' }), {});
  const [activeScrollClass, setActiveScrollClass] = useState(intialScrollClass);
  const scrollToId = eleId => {
    // const section = document.querySelector(`#${eleId}`);
    // section.scrollIntoView({ behavior: 'smooth', block: 'center' });

    const headerOffset = 52;
    const elementPosition = getHtmlElementTopPosition(`#${eleId}`);

    if (typeof window !== 'undefined') {
      const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      localStorage.setItem('isEducationSite', false);
      window.onscroll = () => {
        const activeId = topBarNavIds.find(id => {
          const idElePosition = getHtmlElementTopPosition(`#${id}`);
          if (idElePosition < 55) return true;
          return false;
        });
        setActiveScrollClass({ ...intialScrollClass, [activeId]: css.activeLink });

        // is scrolled
        setShowQuotes(window.pageYOffset > 500);
      };
    }
  }, []);

  const [state, setState] = useState({
    showOpacity: '',
    disableFilterScroll: false,
    queryParams: null,
    selectedLocation: '',
    showAllProduct: false,
    isSellerMode: false,
    activeFooterTab: 'search',
  });

  const sportName = params?.slug;
  const sportCity = params?.city;

  const cityData = isEducationSite ? cityEducation : city; //.filter(ele => ele.key !== sportCity);

  const [activeFooterTab, setActiveFooterTab] = useState('search');
  const [isOpenShareForm, setIsOpenShareForm] = useState(false);
  const [showAllProduct, setShowAllProduct] = useState(false);
  const [showQuotes, setShowQuotes] = useState(false);
  const url = typeof window !== 'undefined' && window.location.href;
  const [quotesModalOpen, setQuotesModalOpen] = useState(false);

  const viewportWidth = viewport?.width;

  const isMobileLayout = useMemo(() => viewportWidth && viewportWidth < MAX_MOBILE_SCREEN_WIDTH, [
    viewportWidth,
  ]);

  const whyBookData = useMemo(() => {
    return whyBook;
  }, []);

  const categoriesMediumOptions = config.custom.categoriesMediumOptionsConfig;
  const checkCategoryType = useMemo(
    () => categoriesMediumOptions.filter(item => item.params.slug === params?.slug),
    [categoriesMediumOptions, params]
  );

  useEffect(() => {
    const location = city.filter(ct => ct.key === sportCity);
    const category =
      checkCategoryType.length > 0
        ? { pub_category: searchableCategory(sportName) }
        : { pub_sub_category: searchableCategory(sportName) };
    const queryparams = {
      ...category,
      address: getCityName(sportCity),
      bounds: location?.length ? location[0].bounds : null,
    };
    queryPromotedListingsAllCategoriesPage(queryparams);
  }, [
    sportCity,
    sportName,
    params.slug,
    queryPromotedListingsAllCategoriesPage,
    checkCategoryType.length,
  ]);

  const onkeywordSearch = activity => {
    history.push(createResourceLocatorString('HomePage', routeConfiguration(), {}));
    const searchParams = {
      pub_sub_category: activity.key ? activity.key : null,
      keywords: activity.title ? null : activity.key ? null : activity,
      pub_listing_type: null,
    };
    setTimeout(() => {
      activity.id ? showListing(activity.id) : queryPromotedListings(searchParams);
    }, 500);
  };

  const onSearchProduct = searchquery => {
    queryPromotedListingsAllCategoriesPage(searchquery);
  };

  const getNumberOfProductListings = useCallback(() => {
    const width = viewportWidth;
    if (width <= 550) return 5;
    else if (width >= 551 && width <= 1199) return 10;
    else if (width >= 1200 && width <= 1550) return 15;
    else return 20;
  }, [viewportWidth]);

  const featureOptions = config.custom.featureOptionsConfig;

  const categoriesData = useMemo(() => {
    return getCategoriesData(sportName);
  }, [sportName]);

  const newCategoriesData = useMemo(() => {
    return getCategoriesData('')[0];
  }, [sportName]);

  const features = useMemo(() => {
    return getFeatures(sportName, sportCity);
  }, [sportName, sportCity]);

  const categoryData = useMemo(
    () =>
      categoriesData.find(item => item.id === sportCity) ||
      config.custom?.categoriesDataConfig.find(item => item.id === sportName),
    [sportCity, sportName, categoriesData]
  );

  const getQuoteModalMemoized = useMemo(
    () => (
      <GetQuoteModal
        modalId={`CategoryPage.${sportName}.${sportCity}.quotes`}
        open={quotesModalOpen}
        params={params}
        banner={{
          img: getBannerImg(sportName) || getHeroImages(sportName) || categoryData?.heroImage,
          head: `Answer a couple questions to help us find you the best ${sportName} Coach.`,
          desc: `With Probuddy, you can communicate with the best coaches in ${sportCity}. We will match you with a program that is perfect for you within 24 hours.`,
        }}
        queOptionList={getQueAnsList(sportName)}
        onModalClose={() => setQuotesModalOpen(false)}
        currentUserMail={isAuthenticated ? currentUser?.attributes?.email : ''}
        isEducationSite={isEducationSite}
        mailAtbrs={{
          forName: `${sportName} ${sportCity} Category`,
          altUrl: `https://www.probuddy.io/${sportName}/${sportCity}`,
          postFixFromName: `${sportName} ${sportCity} Category`,
        }}
      />
    ),
    [quotesModalOpen, isAuthenticated, isEducationSite, sportName, currentUser?.attributes?.email]
  );

  const whyBookHeadingClass =
    whyBookData.position === 'center' ? classNames(css.title, css.textAlignCenter) : css.title;

  const chooseUsSectionClass =
    whyBookData.containerPosition === 'center'
      ? classNames(css.chooseUsSection, css.alignItemsCenter)
      : css.chooseUsSection;

  const companyListings = useMemo(
    () =>
      promotedListings.filter(
        pl =>
          pl.attributes.publicData.listing_type === 'company' ||
          pl.attributes.publicData.listing_type === 'Company'
      ),
    [promotedListings]
  );

  const freelancerListings = useMemo(
    () =>
      promotedListings.filter(
        pl =>
          pl.attributes.publicData.listing_type === 'freelancer' ||
          pl.attributes.publicData.listing_type === 'listing'
      ),
    [promotedListings]
  );

  const topFreelancers = _.orderBy(
    freelancerListings?.filter(ele => {
      const {
        attributes: { publicData },
      } = ele;
      const { total_ratings = undefined, total_reviewers = undefined } = publicData;
      return total_ratings !== undefined && total_reviewers !== undefined;
    }),
    ({ attributes: { publicData } }) => [publicData.total_ratings, publicData.total_reviewers],
    ['desc']
  );

  const facilityListings = useMemo(
    () => promotedListings.filter(pl => pl.attributes.publicData.listing_type === 'facility'),
    [promotedListings]
  );
  const schemaIdKeyword =
    sportName === 'sports'
      ? 'CategoriesPage.schemaKeywordsSports'
      : sportName === 'wellness'
      ? 'CategoriesPage.schemaKeywordsWellness'
      : 'CategoriesPage.schemaKeywordsAdventure';

  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: getSchemaId(sportName) }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: getSchemaDescription(sportName) });
  const schemaKeywords = intl.formatMessage({
    id: schemaIdKeyword,
  });

  if (!categoryData || (categoryData && !Object.keys(categoryData).length)) {
    return <NotFoundPage isEducationSite={false} />;
  }

  return (
    <Page
      description={schemaDescription}
      title={schemaTitle}
      keywords={schemaKeywords}
      scrollingDisabled={scrollingDisabled}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar className={css.positionStatic}>
          <TopbarContainer setIsOpenShareForm={setIsOpenShareForm} showOnMob={!showQuotes} />
          {showQuotes && (
            <div className={css.floatingTopBar}>
              <div className={css.floatingTop}>
                {Object.entries(topBarNavObj)
                  .reverse()
                  .map(([key, val]) => (
                    <div
                      onClick={() => {
                        scrollToId(key);
                      }}
                      className={classNames(css.floatingWrap, activeScrollClass[key])}
                    >
                      <p className={css.floatingLink}>{val}</p>
                    </div>
                  ))}
              </div>

              {/* <div
                className={classNames(css.wrapperInput, css.floatingInput)}
                onClick={() => setQuotesModalOpen(true)}
              >
                <input disabled type="text" value="Tell Us what you are looking for" />
                <button className={css.btnQuote}>
                  <FormattedMessage id="CategoriesPage.quoteButtonText" />
                </button>
              </div> */}
              <div onClick={() => setQuotesModalOpen(true)}>
                <div className={classNames(css.wrapperInput, css.floatingInput)}>
                  <h4 className={css.wrapperInputHead}>
                    Let us find a {isEducationSite ? 'Tutor' : 'Coach'} for you?
                  </h4>
                  <div className={css.wrapperInputWrap}>
                    <div className={css.inputWrap}>
                      <label className={css.inputLabel}>Tell us what you need</label>
                      <input disabled type="text" value="We aim to respond within 30 minutes" />
                    </div>
                    <button className={css.btnQuote}>
                      <FormattedMessage id="CategoriesPage.quoteButtonText" />
                    </button>
                  </div>
                </div>
                <div className={css.newFloatingMain}>
                  <div className={css.newFloatingImage}>
                    <img src={topfloatingimage} alt="" />
                  </div>
                  <div className={css.newFloatingText}>
                    Let us find a {isEducationSite ? 'Tutor' : 'Coach'} for you?
                  </div>
                  <button className={css.newFloatingButton}>
                    <FormattedMessage id="CategoriesPage.quoteButtonText" />
                  </button>
                </div>
              </div>

              {/*
              // floating Whatsapp messages and moblie what icon
              <div className={css.floatingWP}>
                <a
                  className={css.floatingWPLink}
                  href="https://wa.me/message/WHZFXEGQ6RL5H1"
                  onClick={() => {
                    whatsappLinkClickTrack();
                  }}
                >
                  <img className={css.imgMobile} alt="whatsapp-link" src="https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1699507459/probuddy/static/images/Icons/WhatsappImg_m1f6lt.png" />
                  <img className={css.imgDesktop} alt="whatsapp-link" src="https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1699507453/probuddy/static/images/Icons/WhatsappImgDesktop_lwohkw.png" />
                </a>
              </div>
              {!isMobileLayout && (
                <div className={classNames(css.floatingWP, css.floatingIG)}>
                  <a className={css.floatingWPLink} href="https://www.instagram.com/probuddy__io/">
                    <img className={css.imgDesktop} alt="instagram-link" src="https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1699507427/probuddy/static/images/Icons/instagramDesktop_osnszc.png" />
                  </a>
                </div>
              )} */}
            </div>
          )}
        </LayoutWrapperTopbar>

        <LayoutWrapperMain>
          <div className={css.content}>
            <section className={css.topSection}>
              <div className={css.topHeadContainer}>
                <div className={css.quoteWrapper}>
                  <h4>
                    <FormattedMessage
                      id="CategoriesPage.topHeadTitle"
                      values={{
                        sportName: sportName
                          .replaceAll('-', ' ')
                          .replace(
                            /\w\S*/g,
                            str => str.charAt(0).toUpperCase() + str.substr(1).toLowerCase()
                          ),
                        instructorText: isEducationSite ? 'Teachers' : 'Coaches',
                      }}
                    />
                  </h4>
                  <p>
                    <FormattedMessage id="CategoriesPage.topHeadSubTitle" />
                  </p>

                  <div className={classNames(css.inputWrapper)}>
                    <div className={css.wrapperInput} onClick={() => setQuotesModalOpen(true)}>
                      <input disabled type="text" value="Tell Us what you are looking for" />
                      <button className={css.btnQuote}>
                        <FormattedMessage id="CategoriesPage.quoteButtonText" />
                      </button>
                    </div>
                  </div>
                </div>
                <div className={css.heroImage}>
                  {categoryData?.heroVideo ? (
                    <>
                      <div className={css.overlayVideoBg}></div>
                      <video
                        src={categoryData.heroVideo}
                        className={css.heroVideo}
                        controls
                        loop
                        autoPlay
                        muted
                        playsInline
                        alt="How to find a Tennis Coaches"
                      >
                        <source
                          src={categoryData.heroVideo}
                          title="How to find a Tennis Coaches"
                          type="video/mp4"
                        />
                      </video>
                    </>
                  ) : (
                    <>
                      <div className={css.overlayBg}></div>
                      <img src={categoryData.heroImage} alt="heroImage" />
                    </>
                  )}
                </div>
              </div>
            </section>

            <div className={css.locationArea}>
              <div className={css.locationHeadingContainer}>
                <h3 className={css.locationTitle}>Choose Your City</h3>
              </div>
              <div className={css.locationHeader}>
                <HorizontalScroll
                  isSmallScrollArrow={false}
                  className={classNames(css.locationListContainer, {
                    [css.categoryListContainerWithoutArrow]:
                      typeof window !== 'undefined' ? window.screen.width <= 425 : true,
                  })}
                >
                  {cityData.map(city =>
                    !isEducationSite ? (
                      <NamedLink
                        key={city?.key}
                        name="AllCategoriesPage"
                        params={{
                          slug: params?.slug,
                          city: createSlug(city?.key),
                        }}
                        className={css.locationWrapperTop}
                      >
                        <div className={css.locationWrapperTop}>
                          <div className={css.locationImgWrap}>
                            <img
                              src={city.cityImage}
                              height="100px"
                              width="100px"
                              alt={city.label}
                            />
                          </div>
                          <div
                            className={classNames(
                              css.locationTextWrap,
                              city.key === sportCity && css.selected
                            )}
                          >
                            {city.label}
                          </div>
                        </div>
                      </NamedLink>
                    ) : (
                      <div className={css.locationWrapperTop}>
                        <div className={css.locationImgWrap}>
                          <img src={city.cityImage} height="100px" width="100px" alt={city.label} />
                        </div>
                        <div className={classNames(css.locationTextWrap)}>{city.label}</div>
                      </div>
                    )
                  )}
                </HorizontalScroll>
              </div>
            </div>

            {/* <div className={css.uniqueSectionContainer}>
              <section className={css.breadCrumbWrapper}>
                <NamedLink name="HomePage">Home</NamedLink>
                <span className={css.breadcrumbArrow}>&gt;</span>
                <NamedLink name="CategoriesPage">Category</NamedLink>
                <span className={css.breadcrumbArrow}>&gt;</span>
                <NamedLink
                  name="AllSportsPage"
                  params={{ slug: sportName }}
                  className={css.currentListingTypeName}
                >
                  {sportName.replaceAll('-', ' ')}
                </NamedLink>
                <span className={css.breadcrumbArrow}>&gt;</span>
                <NamedLink
                  name="AllCategoriesPage"
                  params={{ slug: sportName, city: sportCity }}
                  className={css.currentListingTypeName}
                >
                  {sportCity.replaceAll('-', ' ')}
                </NamedLink>
              </section>
            </div> */}

            {/* <section className={css.yogaClassesLocation}>
              <div className={classNames(css.servicesTitle, css.textAlignLeft)}>
                {categoryData?.classesForYou?.title || categoriesData?.classesNearYou?.title}
              </div>

              <div className={css.descriptionWrap}>
                {isArray(categoryData?.classesForYou?.description) ? (
                  categoryData.classesForYou.description.map((des, index) => (
                    <span key={index} className={css.topSpan}>
                      {des}
                    </span>
                  ))
                ) : (
                  <span className={css.topSpan}>{categoryData?.classesNearYou?.description}</span>
                )}
              </div>

              <div className={css.categoriesContainer}>
                {Object.keys(LISTING_TYPES).map((key, index) => (
                  <NamedLink
                    key={index}
                    className={css.categoriesItem}
                    name="SelectedCategoryTypePage"
                    params={{
                      categoryType: params.slug,
                      city: params.city,
                      listingType: LISTING_TYPES[key],
                    }}
                  >
                    <div className={css.categoriesItemImage}>
                      <img
                        alt="img"
                        src="https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660296660/probuddy/static/images/CategoriesPage/Tennis/TennisImage5_h20maw.svg"
                      />
                      <div className={css.shadowContainer}></div>
                    </div>
                    <div className={css.categoriesItemTitle}>{LISTING_TYPES[key]}</div>
                  </NamedLink>
                ))}
              </div>
            </section> */}
            <div className={css.companyListing} id="coachesId">
              <span className={css.categoryId}>
                {freelancerListings.length + ' ' + sportCity.replaceAll('-', ' ')}{' '}
                {sportName.replaceAll('-', ' ')} Coaches
              </span>
            </div>
            <div className={css.productsMobileWrapper}>
              <div className={classNames(css.autorListingWrapper, css.popularExercisesSection)}>
                {freelancerListings.length > 0 ? (
                  freelancerListings
                    // .slice(
                    //   0,
                    //   showAllProduct ? freelancerListings.length : getNumberOfProductListings()
                    // )
                    .map(pl => (
                      <ProductListing
                        showCountry={true}
                        key={pl.id.uuid}
                        pl={pl}
                        intl={intl}
                        isMobileLayout={isMobileLayout}
                      />
                    ))
                ) : (
                  <div>There are no listings!!</div>
                )}
              </div>
              {/* {freelancerListings && freelancerListings.length > getNumberOfProductListings() && (
                <Button
                  className={css.productsButton}
                  onClick={() => setShowAllProduct(!showAllProduct)}
                >
                  {showAllProduct ? (
                    <FormattedMessage id="CompanyPage.viewProductsLess" />
                  ) : (
                    <FormattedMessage
                      id="CompanyPage.viewProductsMore"
                      values={{
                        count: freelancerListings.length,
                      }}
                    />
                  )}
                </Button>
              )} */}
            </div>

            <div className={css.companyListing} id="academiesId">
              <span className={css.categoryId}>
                {companyListings.length + ' ' + sportCity.replaceAll('-', ' ')}{' '}
                {sportName.replaceAll('-', ' ')} Companies
              </span>
            </div>
            <div className={css.productsMobileWrapper}>
              <div className={classNames(css.autorListingWrapper, css.popularExercisesSection)}>
                {companyListings.length > 0 ? (
                  companyListings
                    // .slice(
                    //   0,
                    //   showAllProduct ? companyListings.length : getNumberOfProductListings()
                    // )
                    .map(pl => (
                      <ProductListing
                        showCountry={true}
                        key={pl.id.uuid}
                        pl={pl}
                        intl={intl}
                        isMobileLayout={isMobileLayout}
                      />
                    ))
                ) : (
                  <div>There are no listings!!</div>
                )}
              </div>
              {/* {companyListings && companyListings.length > getNumberOfProductListings() && (
                <Button
                  className={css.productsButton}
                  onClick={() => setShowAllProduct(!showAllProduct)}
                >
                  {showAllProduct ? (
                    <FormattedMessage id="CompanyPage.viewProductsLess" />
                  ) : (
                    <FormattedMessage
                      id="CompanyPage.viewProductsMore"
                      values={{
                        count: companyListings.length,
                      }}
                    />
                  )}
                </Button>
              )} */}
            </div>

            <div className={css.companyListing} id="facilitiesId">
              <span className={css.categoryId}>
                {facilityListings.length + ' ' + sportCity.replaceAll('-', ' ')}{' '}
                {sportName.replaceAll('-', ' ')} Facilities
              </span>
            </div>
            <div className={css.productsMobileWrapper}>
              <div className={classNames(css.autorListingWrapper, css.popularExercisesSection)}>
                {facilityListings.length > 0 ? (
                  facilityListings
                    // .slice(
                    //   0,
                    //   showAllProduct ? facilityListings.length : getNumberOfProductListings()
                    // )
                    .map(pl => (
                      <ProductListing
                        showCountry={true}
                        key={pl.id.uuid}
                        pl={pl}
                        intl={intl}
                        isMobileLayout={isMobileLayout}
                      />
                    ))
                ) : (
                  <div>There are no listings!!</div>
                )}
              </div>
              {/* {facilityListings && facilityListings.length > getNumberOfProductListings() && (
                <Button
                  className={css.productsButton}
                  onClick={() => setShowAllProduct(!showAllProduct)}
                >
                  {showAllProduct ? (
                    <FormattedMessage id="CompanyPage.viewProductsLess" />
                  ) : (
                    <FormattedMessage
                      id="CompanyPage.viewProductsMore"
                      values={{
                        count: facilityListings.length,
                      }}
                    />
                  )}
                </Button>
              )} */}
            </div>

            <div className={css.uniqueSectionContainer}>
              <HorizontalScroll
                autoFlipOn={true}
                isSmallScrollArrow={false}
                className={classNames({
                  [css.categoryListContainerWithoutArrow]:
                    typeof window !== 'undefined' ? window.screen.width <= 425 : true,
                })}
              >
                <section className={css.section}>
                  <div className={css.sectionImagesContainer}>
                    <img
                      className={css.sectionImages}
                      src={getSection1Img(sportName) || categoryData.section1.imgSrc}
                      alt="sectionImage1"
                    />
                    <div className={css.uniqueDesignSection}>
                      <div>
                        <h3>
                          <FormattedMessage id={categoryData.section1.heading} />
                        </h3>
                        <div className={classNames(css.descriptionWrap, css.textDescriptionLeft)}>
                          {isArray(categoryData.section1?.description) ? (
                            categoryData.section1.description.map((des, index) => (
                              <span key={index} className={css.topSpan}>
                                {des}
                              </span>
                            ))
                          ) : (
                            <span className={css.topSpan}>{categoryData.section1.description}</span>
                          )}
                        </div>
                        <div className={css.learnLink}>
                          <NamedLink name="HomePage">{categoryData.section1.linkText}</NamedLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <section className={css.reverseSection}>
                  <div className={css.sectionImagesContainer}>
                    <img
                      className={css.sectionImages}
                      src={getSection2Img(sportName) || categoryData.section2.imgSrc}
                      alt="sectionImage2"
                    />
                    <div className={css.uniqueDesignSection}>
                      <h3>
                        <FormattedMessage id={categoryData.section2.heading} />
                      </h3>
                      <div className={classNames(css.descriptionWrap, css.textDescriptionLeft)}>
                        {isArray(categoryData.section1?.description) ? (
                          categoryData.section2.description.map((des, index) => (
                            <span key={index} className={css.topSpan}>
                              {des}
                            </span>
                          ))
                        ) : (
                          <span className={css.topSpan}>{categoryData.section2.description}</span>
                        )}
                      </div>
                      <div className={css.learnLink}>
                        <NamedLink name="BecomeVendorPage">
                          {categoryData.section2.linkText}
                        </NamedLink>
                      </div>
                    </div>
                  </div>
                </section>
              </HorizontalScroll>
            </div>

            <section className={chooseUsSectionClass}>
              <img src={whyBookData.imgSrc} alt="whyBookImg" />

              <div className={css.chooseUsInfo}>
                <div className={whyBookHeadingClass}>{whyBookData.heading1}</div>
                <div className={css.description}>{whyBookData.description1}</div>

                <div className={whyBookHeadingClass}>{whyBookData.heading2}</div>
                <div className={css.description}>{whyBookData.description2}</div>

                <div className={css.cardsContainer}>
                  <span className={css.cardItem}>5000+ Members</span>
                  <span className={css.cardItem}>1000+ Trainers</span>
                </div>
                <div className={css.cardsContainer}>
                  <span className={css.cardItem}>50+ Programs</span>
                  <span className={css.cardItem}>150+ Awards</span>
                </div>
              </div>
            </section>

            {categoryData?.instructors && (
              <section className={css.servicesSection}>
                <div className={classNames(css.servicesTitle, css.textAlignLeft)}>
                  {categoryData?.instructors?.title}
                </div>

                <div className={css.descriptionWrap}>
                  {isArray(categoryData?.instructors?.description) ? (
                    categoryData?.instructors?.description.map((des, index) => (
                      <span key={index} className={css.topSpan}>
                        {des}
                      </span>
                    ))
                  ) : (
                    <span className={css.topSpan}>{categoryData?.instructors?.description}</span>
                  )}
                </div>
                {topFreelancers.length >= 3 ? (
                  <div className={css.productsMobileWrapper}>
                    <div
                      className={classNames(
                        css.top3FreelancersWrapper,
                        css.popularExercisesSection
                      )}
                    >
                      {topFreelancers.slice(0, 3).map(pl => (
                        <ProductListing
                          key={pl.id.uuid}
                          pl={pl}
                          intl={intl}
                          isMobileLayout={isMobileLayout}
                        />
                      ))}
                    </div>
                  </div>
                ) : (
                  <div className={css.servicesCarousel}>
                    <ul className={css.servicesCarouselItems}>
                      {(
                        categoryData?.instructors?.instructorsData ||
                        instructorsData(sportName.replaceAll('-', ' '))
                      )?.map((instructor, index) => (
                        <li key={index} className={css.servicesCarouselItem}>
                          <img src={instructor.trainerImage} alt="trainerImage" />
                          <div className={css.carouselInfo}>
                            <div className={css.carouselHeading}>
                              <h5>{instructor.type}</h5>
                              <div className={css.carouselRating}>
                                <img src={StarRatingsIcon} alt="Rating Icon" />
                                <h5>{instructor.rating}</h5>
                                <span>({instructor.reviews}) Reviews</span>
                              </div>
                            </div>

                            <div className={css.carouselName}>{instructor.name}</div>

                            <div className={css.carouselDescription}>{instructor.description}</div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </section>
            )}

            {categoryData.featured && (
              <section className={css.companiesSection}>
                <div className={classNames(css.servicesTitle, css.textAlignLeft)}>
                  {categoryData.featured.title}
                </div>
                <div className={css.descriptionWrap}>
                  {isArray(categoryData?.featured?.description) ? (
                    categoryData?.featured?.description.map((des, index) => (
                      <span key={index} className={css.topSpan}>
                        {des}
                      </span>
                    ))
                  ) : (
                    <span className={css.topSpan}>{categoryData?.featured?.description}</span>
                  )}
                </div>

                <div className={css.mobileCarouselCard}>
                  <div className={css.carouselCardLeft}>
                    <img
                      src={
                        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661504769/probuddy/static/images/BecomeVendorPage/CompanyImage1_ort1ne.png'
                      }
                      alt={' '}
                    />
                  </div>

                  <div className={css.carouselCardRight}>
                    <div className={css.cardTitle}>Galaxy Yoga Foundation</div>
                    <div className={css.cardInfo}>
                      <span className={css.cardRating}>
                        <img src={StarRatingsIcon} alt="StarRatingsIcon" />
                        <h5>4.8</h5>
                        <span>(230)</span>
                      </span>
                      <span className={css.cardLocation}>
                        <img src={LocationIcon} alt="LocationIcon" />
                        <span>
                          {(sportCity?.charAt(0)?.toUpperCase() + sportCity?.slice(1))?.replace(
                            '-',
                            ' '
                          )}
                        </span>
                      </span>
                    </div>

                    <div className={css.cardDescription}>
                      Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                      Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                      when an unknown printer took a galley of type and scrambled it to make a type
                      specimen book. It has survived not only five centuries, but also the leap into
                      electronic typesetting, remaining essentially unchanged.
                    </div>
                  </div>
                </div>
                <Slider className={css.slickCarouselSlider} {...sliderSettings}>
                  {featuredItemsData(sportName.replaceAll('-', ' ')).map((company, index) => (
                    <div key={index} className={css.slickCarouselCard}>
                      <div className={css.carouselCardLeft}>
                        <img src={company.companyImage} alt="companyImage" />
                      </div>

                      <div className={css.carouselCardRight}>
                        <div className={css.cardTitle}>{company.name}</div>
                        <div className={css.cardInfo}>
                          <span className={css.cardRating}>
                            <img src={StarRatingsIcon} alt="StarRatingsIcon" />
                            <h5>{company.rating}</h5>
                            <span>({company.reviews})</span>
                          </span>
                          <span className={css.cardLocation}>
                            <img src={LocationIcon} alt="LocationIcon" />
                            <span>
                              {(sportCity?.charAt(0)?.toUpperCase() + sportCity?.slice(1))?.replace(
                                '-',
                                ' '
                              )}
                            </span>{' '}
                          </span>
                        </div>

                        <div className={css.cardDescription}>{company.description}</div>
                      </div>
                    </div>
                  ))}
                </Slider>
              </section>
            )}

            {categoryData.topRatedClasses && (
              <section className={css.popularYogaClassesSection}>
                <div className={css.servicesTitle}>{categoryData.topRatedClasses.title}</div>
                <div className={css.descriptionWrap}>
                  {isArray(categoryData?.topRatedClasses?.description) ? (
                    categoryData?.topRatedClasses?.description.map((des, index) => (
                      <span key={index} className={css.topSpan}>
                        {des}
                      </span>
                    ))
                  ) : (
                    <span className={css.topSpan}>
                      {categoryData?.topRatedClasses?.description}
                    </span>
                  )}
                </div>

                <div className={css.yogaClassesContainer}>
                  {topRatedClassesData(sportName.replaceAll('-', ' '))?.map(item => (
                    <div className={css.yogaClassesItem}>
                      <div className={css.itemBasicInfo}>
                        <img className={css.itemImage} src={item.classesImage} alt="classesImage" />
                        <div className={css.itemText}>
                          <h3 className={css.yogaName}>{item.className}</h3>
                          <h6>{item.instructorName}</h6>
                          <div className={css.itemLocation}>
                            <img src={LocationIcon} alt="LocationIcon" />
                            <h6>
                              {(sportCity?.charAt(0)?.toUpperCase() + sportCity?.slice(1))?.replace(
                                '-',
                                ' '
                              )}
                            </h6>
                          </div>
                          <div className={css.yogaRatings}>
                            <img src={StarRatingsIcon} alt="StarRatingsIcon" />
                            <h6>
                              {item.rating} <span>({item.reviews})</span>
                            </h6>
                          </div>
                        </div>
                      </div>

                      <div className={css.itemDescription}>{item.description}</div>
                      <div className={css.itemBookingAndTimingInfo}>
                        <div className={css.timingInfo}>{item.bookingTimings}</div>
                        <div className={css.bookingPrice}>$ {item.bookingPrice}</div>
                        <NamedLink name="HomePage">
                          <PrimaryButton2 className={css.bookNowBtn}>Book Now</PrimaryButton2>
                        </NamedLink>
                      </div>
                    </div>
                  ))}

                  <hr className={css.divider} />
                </div>
              </section>
            )}

            <section className={css.reviewsSection}>
              <div className={css.servicesTitle}>What Our Happy Clients Says!</div>
              <div className={css.servicesSubheading}>
                We let our happy clients do the talking! See what our students and instructors have
                to say about Probuddy.
              </div>

              <div className={css.reviewsCarouselContainer}>
                {categoryCityReviews.map((review, index) => (
                  <div key={index} className={css.reviewsCardItem}>
                    <div className={css.cardHeader}>
                      <span className={css.cardImage}>
                        <img src={review.imageSrc} alt="reviewImg" />{' '}
                      </span>

                      <div className={css.cardInfo}>
                        <span className={css.cardName}>{review.name}</span>
                        <span className={css.cardSubheading}>
                          {(sportCity?.charAt(0)?.toUpperCase() + sportCity?.slice(1))?.replace(
                            '-',
                            ' '
                          )}
                        </span>
                      </div>
                    </div>
                    <div className={css.cardContent}>{review.description}</div>
                  </div>
                ))}
              </div>
            </section>

            <section className={css.featuresSection}>
              <div className={css.servicesTitle}>{features.title}</div>
              <div className={css.servicesSubheading}>{features.description}</div>
              <div className={css.featureItemsContainer}>
                {featureOptions.map((feature, index) => (
                  <div key={index} className={css.featureItem}>
                    <div className={css.featureIcon}>
                      <img src={feature.imgSrc} alt="featureImg" />
                    </div>
                    <div>
                      <div className={css.featureTitle}>{feature.title}</div>
                      <div className={css.featureTitle2}>{feature.title2}</div>
                    </div>
                    <div className={css.featureDescription}>{feature.description}</div>
                    <img className={css.arrowIconLeft} src={ArrowIconLeft} alt="ArrowIconLeft" />
                  </div>
                ))}
              </div>
            </section>

            <section className={css.submitRequestSection}>
              <div className={css.imageContainer}>
                <h3>{sellers.title}</h3>
                <NamedLink name="BecomeVendorPage" className={css.getProLink}>
                  {sellers.linkText}
                </NamedLink>
              </div>
            </section>
            <section className={css.becomeSellerSection}>
              <BecomeSeller
                message={
                  <div>
                    Are you a{' '}
                    <span className={css.paramsTitle}>{sportName.replaceAll('-', ' ')}</span> Expert
                    ?
                  </div>
                }
              />
            </section>
          </div>
        </LayoutWrapperMain>
        {!isMobileLayout ? (
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        ) : (
          <LayoutWrapperFooter
            className={classNames(css.layoutFooter, {
              [css.activeleftbarFooter]: activeFooterTab === 'leftbar',
              [css.createFooterTab]:
                activeFooterTab === 'create' || activeFooterTab === 'activitySearch',
            })}
          >
            {!!isMobileLayout && !(activeFooterTab === 'leftbar') && (
              <FooterBottomMenu
                changeActivieFooterTab={value => setActiveFooterTab(value)} // setState({ activeFooterTab: value })}
                onSearch={activity => onkeywordSearch(activity)}
              />
            )}
          </LayoutWrapperFooter>
        )}
      </LayoutSingleColumn>
      <Modal
        isOpen={!!(activeFooterTab === 'leftbar')}
        onClose={() =>
          setState({
            ...state,
            activeFooterTab: 'search',
          })
        }
        id="filterModal"
        onManageDisableScrolling={() => {}}
        className={css.modalLeftBar}
      >
        <LeftBar
          isMobileLayout={isMobileLayout}
          history={history}
          onShowEnquiryTab={onSearchProduct}
          onLogoClick={() => setState({ activeFooterTab: 'search' })}
          onClose={() => setState({ activeFooterTab: 'search' })}
        />
      </Modal>
      <Modal
        id="AllCategoriesPage.shareForm"
        className={css.shareFormModal}
        isOpen={isOpenShareForm}
        onClose={() => setIsOpenShareForm(false)}
        onManageDisableScrolling={() => {}}
      >
        <ShareForm url={url} />
      </Modal>
      {getQuoteModalMemoized}
    </Page>
  );
};

AllCategoriesPageComponent.propTypes = {
  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
};

const mapStateToProps = state => {
  const { promotedListingRefs } = state.AllCategoriesPage;
  const promotedListings = getMarketplaceEntities(state, promotedListingRefs);
  const { isAuthenticated } = state.Auth;

  return {
    promotedListings,
    scrollingDisabled: isScrollingDisabled(state),
    promotedListingRefs,
    isAuthenticated,
  };
};

const mapDispatchToProps = dispatch => ({
  queryPromotedListingsAllCategoriesPage: searchquery =>
    dispatch(queryPromotedListingsAllCategoriesPage(searchquery)),
  showListing: listingId => dispatch(showListing(listingId)),
  queryPromotedListings: searchquery => dispatch(queryPromotedListings(searchquery)),
});

const AllCategoriesPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
  withViewport
)(AllCategoriesPageComponent);

export default AllCategoriesPage;
