import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';

// ================ Action types ================ //

export const SAVE_HITPAY_SETTINGS_REQUEST = 'app/HitpaySettingsPage/SAVE_HITPAY_SETTINGS_REQUEST';
export const SAVE_HITPAY_SETTINGS_SUCCESS = 'app/HitpaySettingsPage/SAVE_HITPAY_SETTINGS_SUCCESS';
export const SAVE_HITPAY_SETTINGS_ERROR = 'app/HitpaySettingsPage/SAVE_HITPAY_SETTINGS_ERROR';

// ================ Reducer ================ //

const initialState = {
  saveHitpayKeyError: null,
  saveHitpayKeyInProgress: false,
  updated:  false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SAVE_HITPAY_SETTINGS_REQUEST:
      return {
        ...state,
        saveHitpayKeyInProgress: true,
        saveHitpayKeyError: null,
        updated: false,
      };
    case SAVE_HITPAY_SETTINGS_SUCCESS:
      return { ...state, saveHitpayKeyInProgress: false, updated: true};
    case SAVE_HITPAY_SETTINGS_ERROR:
      return { ...state, saveHitpayKeyInProgress: false, saveHitpayKeyError: payload, updated: false };
    default:
      return state;
  }
}

// ================ Action creators ================ //

export const saveHitpaySettingsRequest = () => ({ type: SAVE_HITPAY_SETTINGS_REQUEST });
export const saveHitpaySettingsSuccess = () => ({ type: SAVE_HITPAY_SETTINGS_SUCCESS });
export const saveHitpaySettingsError = error => ({
  type: SAVE_HITPAY_SETTINGS_ERROR,
  payload: error,
  error: true,
});

// ================ Thunks ================ //
/**
 * Update HitPay settings
 */
export const saveHitpaySettings = params => (dispatch, getState, sdk) => {
  dispatch(saveHitpaySettingsRequest());

  const { hitpayKey, hitpayKeySalt } = params;

  return sdk.currentUser
    .updateProfile(
      { privateData: { hitpayKey, hitpayKeySalt } },
      {
        expand: true,
        include: ['profileImage'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
      }
    )
    .then(response => {
      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
      }
      dispatch(saveHitpaySettingsSuccess());
    })
    .catch(e => {
      dispatch(saveHitpaySettingsError(storableError(e)));
      throw e;
    });
};
