import React, { useEffect, useMemo, useState } from 'react';
import { Button, ProductListing } from 'components';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { showEducationListing } from './ListingPage.duck';
import css from './CompanyPage/CompanyPage.css';

export const SectionOtherAutherListing = props => {
  const {
    otherAuthorsListings,
    getNumberOfProductListings,
    intl,
    allListingReviews,
    viewport,
    authorId,
    isEducationSite,
  } = props;

  const dispatch = useDispatch();

  useEffect(() => {
    !!authorId &&
      dispatch(
        showEducationListing(authorId, {
          pub_listing_type: isEducationSite ? 'sessions' : 'listing,event',
        })
      );
  }, [authorId, dispatch, isEducationSite]);

  const otherAuthorListingsRender = otherAuthorsListings.map(pl => (
    <ProductListing
      key={pl.id.uuid}
      pl={pl}
      intl={intl}
      reviews={allListingReviews ? allListingReviews[pl.id.uuid] : []}
      viewport={viewport}
    />
  ));
  // useMemo(() => { return ( !!otherAuthorsListings?.length && );
  // eslint-disable-next-line
  // }, [otherAuthorsListings?.length, getNumberOfProductListings]);
  return (
    <div id="product" className={css.ourProSectionWrapper}>
      {!!otherAuthorsListings.length && (
        <>
          <div className={css.ourProSectionTitle}>
            <h3 className={css.ourProsHeading}>
              <FormattedMessage id="CompanyPage.overProducts" />
            </h3>
          </div>
          <div className={css.productsMobileWrapper}>
            <div className={css.authorListingWrapper}>{otherAuthorListingsRender}</div>
          </div>
        </>
      )}
    </div>
  );
};
