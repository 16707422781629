import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './FormNavigation.css';

const FormNavigation = props => {
  const {
    rootClassName,
    className,
    marketplaceTabs,
    tab,
    onRedirectPrevTab,
    onRedirectNextTab,
    isTabCompleted,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const tabIndex = marketplaceTabs.findIndex(tabValue => tabValue === tab);
  const showLeftArrow = tabIndex !== 0;
  const showRightArrow = tabIndex + 1 !== marketplaceTabs.length;

  return (
    <div className={classes}>
      {showLeftArrow && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className={css.arrowIcon}
          width="38"
          height="38"
          onClick={onRedirectPrevTab}
          viewBox="0 0 38 38"
          fill="none"
        >
          <path
            d="M19 36.5C28.665 36.5 36.5 28.665 36.5 19C36.5 9.33502 28.665 1.5 19 1.5C9.33502 1.5 1.5 9.33502 1.5 19C1.5 28.665 9.33502 36.5 19 36.5Z"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M19 12L12 19L19 26"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M26 19H12"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}

      <div className={css.dotWrapper}>
        {marketplaceTabs &&
          marketplaceTabs.map(tabValue => (
            <div className={classNames(css.dot, tabValue === tab && css.selectedDot)}></div>
          ))}
      </div>

      {showRightArrow && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className={classNames(css.arrowIcon, !isTabCompleted && css.disableArrow)}
          width="38"
          onClick={isTabCompleted && onRedirectNextTab}
          height="38"
          viewBox="0 0 38 38"
          fill="none"
        >
          <path
            d="M19 36.5C28.665 36.5 36.5 28.665 36.5 19C36.5 9.33502 28.665 1.5 19 1.5C9.33502 1.5 1.5 9.33502 1.5 19C1.5 28.665 9.33502 36.5 19 36.5Z"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M19 26L26 19L19 12"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12 19H26"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
    </div>
  );
};

FormNavigation.defaultProps = {
  rootClassName: null,
  className: null,
};

FormNavigation.propTypes = {
  rootClassName: string,
  className: string,
};

export default FormNavigation;
