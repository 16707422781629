import React from 'react';
import SectionImage1 from '../../assets/VendorPage/vendorPng1.png';
import SectionImage2 from '../../assets/VendorPage/vendorPng2.png';
import SectionImage3 from '../../assets/VendorPage/vendorPng3.png';
import sectionImage4 from '../../assets/VendorPage/vendorPng4.png';
import SectionImage5 from '../../assets/VendorPage/vendorPng5.png';
import BookingsIcon from '../../assets/VendorPage/BookingsIcon.svg';
import CompanySalesIcon from '../../assets/VendorPage/CompanySalesIcon.svg';
import ReviewsIcon from '../../assets/VendorPage/ReviewsIcon.svg';
import ProgressIcon from '../../assets/VendorPage/ProgressIcon.svg';
import css from '../BecomeVendorPage/BecomeVendorPage.css';
import { FormattedMessage } from 'react-intl';

const AdvancedIntegrationSection = () => {
  return (
    <div className={css.uniqueSectionContainer}>
      <section className={css.advantagesSection}>
        <div className={css.advantageItem}>
          <div className={css.advantageItemIcon}>
            <img src={BookingsIcon} alt="Bookings" />
          </div>
          <div className={css.advantageItemHeading}>Bookings</div>
          <div className={css.advantageItemSubtitle}>
            Our online booking system empowers Pros, Freelancers.
          </div>
        </div>

        <div className={css.advantageItem}>
          <div className={css.advantageItemIcon}>
            <img src={CompanySalesIcon} alt="Bookings" />
          </div>
          <div className={css.advantageItemHeading}>Company Sales</div>
          <div className={css.advantageItemSubtitle}>
            Our online booking system empowers Pros, Freelancers.
          </div>
        </div>

        <div className={css.advantageItem}>
          <div className={css.advantageItemIcon}>
            <img src={ReviewsIcon} alt="Bookings" />
          </div>
          <div className={css.advantageItemHeading}>Reviews</div>
          <div className={css.advantageItemSubtitle}>
            Our online booking system empowers Pros, Freelancers.
          </div>
        </div>

        <div className={css.advantageItem}>
          <div className={css.advantageItemIcon}>
            <img src={ProgressIcon} alt="Bookings" />
          </div>
          <div className={css.advantageItemHeading}>Progress</div>
          <div className={css.advantageItemSubtitle}>
            Our online booking system empowers Pros, Freelancers.
          </div>
        </div>
      </section>

      <section className={css.section}>
        <div className={css.sectionImagesContainer}>
          <img className={css.sectionImages} src={SectionImage1} alt="sectionImage1" />
        </div>
        <div className={css.uniqueDesignSection}>
          <div>
            <h3>
              <FormattedMessage id="BecomeVendorPage.googleCalendar" />
            </h3>
            <span className={css.topSpan}>
              <FormattedMessage id="BecomeVendorPage.liveCalendarUpdates" />
            </span>
          </div>
        </div>
      </section>

      <section className={css.reverseSection}>
        <div className={css.sectionImagesContainer}>
          <img className={css.sectionImages} src={SectionImage2} alt="sectionImage2" />
        </div>
        <div className={css.uniqueDesignSection}>
          <h3>
            <FormattedMessage id="BecomeVendorPage.onlineVideo" />
          </h3>
          <span className={css.topSpan}>
            <FormattedMessage id="BecomeVendorPage.createOnlineClasses" />
          </span>
        </div>
      </section>

      <section className={css.section}>
        <div className={css.sectionImagesContainer}>
          <img className={css.sectionImages} src={SectionImage3} alt="sectionImage3" />
        </div>
        <div className={css.uniqueDesignSection}>
          <h3>
            <FormattedMessage id="BecomeVendorPage.paypalAndStripe" />
          </h3>
          <span className={css.topSpan}>
            <FormattedMessage id="BecomeVendorPage.collectOnlinePayments" />
          </span>
        </div>
      </section>
      <section className={css.reverseSection}>
        <div className={css.sectionImagesContainer}>
          <img className={css.sectionImages} src={sectionImage4} alt="sectionImage4" />
        </div>
        <div className={css.uniqueDesignSection}>
          <h3>
            <FormattedMessage id="BecomeVendorPage.microsoftExcelAndCRM" />
          </h3>
          <span className={css.topSpan}>
            <FormattedMessage id="BecomeVendorPage.clientAndBookingManagement" />
          </span>
        </div>
      </section>

      <section className={css.section}>
        <div className={css.sectionImagesContainer}>
          <img className={css.sectionImages} src={SectionImage5} alt="sectionImage5" />
        </div>
        <div className={css.uniqueDesignSection}>
          <h3>
            <FormattedMessage id="BecomeVendorPage.messengerAndWhatsapp" />
          </h3>
          <span className={css.topSpan}>
            <FormattedMessage id="BecomeVendorPage.buildInMessenger" />
          </span>
        </div>
      </section>
    </div>
  );
};
export default AdvancedIntegrationSection;
