import {
  denormalisedEntities,
  denormalisedResponseEntities,
  ensureOwnListing,
  entityRefs,
  updatedEntities,
} from '../util/data';
import moment from 'moment';
import { storableError } from '../util/errors';
import {
  TRANSITION_CANCEL,
  TRANSITION_CANCEL_READY_TO_COMPLETE,
  TRANSITION_COMPLETE,
  TRANSITION_COMPLETE_MANUAL,
  TRANSITION_DECLINE,
  TRANSITION_ENQUIRE,
  TRANSITION_REVIEW_1_BY_PROVIDER,
  TRANSITION_SKIP_1_BY_PROVIDER,
  TRANSITIONS,
  transitionsToRequested,
} from '../util/transaction';
import { LISTING_STATE_DRAFT } from '../util/types';
import * as log from '../util/log';
import { authInfo } from './Auth.duck';
import { stripeAccountCreateSuccess } from './stripeConnectAccount.duck';
import config from 'config';
import axios from 'axios';
import { wasTransactionCreatedBeforeTimestamp } from 'util/transaction';
import { addMarketplaceEntities } from 'ducks/marketplaceData.duck';

// ================ Action types ================ //

export const CURRENT_USER_SHOW_REQUEST = 'app/user/CURRENT_USER_SHOW_REQUEST';
export const CURRENT_USER_SHOW_SUCCESS = 'app/user/CURRENT_USER_SHOW_SUCCESS';
export const CURRENT_USER_SHOW_ERROR = 'app/user/CURRENT_USER_SHOW_ERROR';

export const CLEAR_CURRENT_USER = 'app/user/CLEAR_CURRENT_USER';

export const FETCH_CURRENT_USER_HAS_LISTINGS_REQUEST =
  'app/user/FETCH_CURRENT_USER_HAS_LISTINGS_REQUEST';
export const FETCH_CURRENT_USER_HAS_LISTINGS_SUCCESS =
  'app/user/FETCH_CURRENT_USER_HAS_LISTINGS_SUCCESS';

export const FETCH_CURRENT_USER_HAS_COMPANY_FREELANCER_SUCCESS =
  'app/user/FETCH_CURRENT_USER_HAS_COMPANY_FREELANCER_SUCCESS';
export const FETCH_CURRENT_USER_HAS_TUTOR_ACADEMY_SUCCESS =
  'app/user/FETCH_CURRENT_USER_HAS_TUTOR_ACADEMY_SUCCESS';
export const FETCH_CURRENT_USER_HAS_LISTINGS_ERROR =
  'app/user/FETCH_CURRENT_USER_HAS_LISTINGS_ERROR';

export const FETCH_CURRENT_USER_NOTIFICATIONS_REQUEST =
  'app/user/FETCH_CURRENT_USER_NOTIFICATIONS_REQUEST';
export const FETCH_CURRENT_USER_NOTIFICATIONS_SUCCESS =
  'app/user/FETCH_CURRENT_USER_NOTIFICATIONS_SUCCESS';
export const FETCH_CURRENT_USER_NOTIFICATIONS_ERROR =
  'app/user/FETCH_CURRENT_USER_NOTIFICATIONS_ERROR';

export const FETCH_CURRENT_USER_HAS_ORDERS_REQUEST =
  'app/user/FETCH_CURRENT_USER_HAS_ORDERS_REQUEST';
export const FETCH_CURRENT_USER_HAS_ORDERS_SUCCESS =
  'app/user/FETCH_CURRENT_USER_HAS_ORDERS_SUCCESS';
export const FETCH_CURRENT_USER_HAS_ORDERS_ERROR = 'app/user/FETCH_CURRENT_USER_HAS_ORDERS_ERROR';

export const SEND_VERIFICATION_EMAIL_REQUEST = 'app/user/SEND_VERIFICATION_EMAIL_REQUEST';
export const SEND_VERIFICATION_EMAIL_SUCCESS = 'app/user/SEND_VERIFICATION_EMAIL_SUCCESS';
export const SEND_VERIFICATION_EMAIL_ERROR = 'app/user/SEND_VERIFICATION_EMAIL_ERROR';

export const FETCH_COMPLETED_LISTINGS_STARTED = 'app/user/FETCH_COMPLETED_LISTINGS_STARTED';
export const FETCH_COMPLETED_LISTINGS_SUCCESS = 'app/user/FETCH_COMPLETED_LISTINGS_SUCCESS';
export const FETCH_COMPLETED_LISTINGS_FAILED = 'app/user/FETCH_COMPLETED_LISTINGS_FAILED';

export const FETCH_ACCEPTED_PACKAGE_STARTED = 'app/user/FETCH_ACCEPTED_PACKAGE_STARTED';
export const FETCH_ACCEPTED_PACKAGE_SUCCESS = 'app/user/FETCH_ACCEPTED_PACKAGE_SUCCESS';
export const FETCH_ACCEPTED_PACKAGE_FAILED = 'app/user/FETCH_ACCEPTED_PACKAGE_FAILED';

export const LAST_BOOKING_REVIEWED = 'app/user/LAST_BOOKING_REVIEWED';
export const ACCEPTED_PACKAGE_NOTE_SEEN = 'app/user/ACCEPTED_PACKAGE_NOTE_SEEN';

export const FETCH_RECENT_TRANSACTIONS_STARTED = 'app/user/FETCH_RECENT_TRANSACTIONS_STARTED';
export const FETCH_RECENT_TRANSACTIONS_SUCCESS = 'app/user/FETCH_RECENT_TRANSACTIONS_SUCCESS';
export const FETCH_RECENT_TRANSACTIONS_FAILED = 'app/user/FETCH_RECENT_TRANSACTIONS_FAILED';

export const FETCH_COMPANY_STARTED = 'app/user/FETCH_COMPANY_STARTED';
export const FETCH_COMPANY_SUCCESS = 'app/user/FETCH_COMPANY_SUCCESS';
export const FETCH_COMPANY_FAILED = 'app/user/FETCH_COMPANY_FAILED';

export const USER_WAS_FETCHED = 'app/user/USER_WAS_FETCHED';

export const CHANGED_ACTIVEFOOTER_TAB = 'app/user/CHANGED_ACTIVEFOOTER_TAB';

export const FETCH_SELLER_PACKAGE_REQUESTS_ERROR = 'app/user/FETCH_SELLER_PACKAGE_REQUESTS_ERROR';
export const FETCH_SELLER_PACKAGE_REQUESTS = 'app/user/FETCH_SELLER_PACKAGE_REQUESTS';
export const FETCH_SELLER_PACKAGE_REQUESTS_SUCCESS =
  'app/user/FETCH_SELLER_PACKAGE_REQUESTS_SUCCESS';

export const FETCH_BUYER_PACKAGE_REQUESTS_SUCCESS =
  'app/user/FETCH_BUYER_PROGRESS_REPORTS_REQUEST_SUCCESS';

//chargebee
export const CURRENT_USER_HAS_CHARGEBEE_SUBSCRIPTION_REQUEST =
  'app/user/CURRENT_USER_HAS_CHARGEBEE_SUBSCRIPTION_REQUEST';
export const CURRENT_USER_HAS_CHARGEBEE_SUBSCRIPTION_SUCCESS =
  'app/user/CURRENT_USER_HAS_CHARGEBEE_SUBSCRIPTION_SUCCESS';
export const CURRENT_USER_HAS_CHARGEBEE_SUBSCRIPTION_ERROR =
  'app/user/SEND_VERIFICATION_EMAIL_ERROR';

// ================ Reducer ================ //

const mergeCurrentUser = (oldCurrentUser, newCurrentUser) => {
  const { id: oId, type: oType, attributes: oAttr, ...oldRelationships } = oldCurrentUser || {};
  const { id, type, attributes, ...relationships } = newCurrentUser || {};

  // Passing null will remove currentUser entity.
  // Only relationships are merged.
  // TODO figure out if sparse fields handling needs a better handling.
  return newCurrentUser === null
    ? null
    : oldCurrentUser === null
    ? newCurrentUser
    : { id, type, attributes, ...oldRelationships, ...relationships };
};

const initialState = {
  currentUser: null,
  currentUserWasFetched: false,
  currentUserShowError: null,
  currentUserHasListings: false,
  currentUserListings: [],
  currentUserHasCompanyOrFreelancer: false,
  currentUserHasTutorOrAcademy: false,
  currentUserHasListingsError: null,
  currentUserNotificationCount: 0,
  currentUserSaleEnquiriesNotificationCount: 0,
  currentUserOrderEnquiriesNotificationCount: 0,
  currentUserPackagesNotificationCount: 0,
  currentUserNotificationCountError: null,
  currentUserHasOrders: null, // This is not fetched unless unverified emails exist
  currentUserHasOrdersError: null,
  sendVerificationEmailInProgress: false,
  sendVerificationEmailError: null,
  currentUserListing: null,
  currentUserListingFetched: false,

  // last booking review
  lastCompleteTransaction: null,
  lastBookingReviewDone: false,
  sendLastBookingReviewInProgress: false,
  sendLastBookingReviewError: null,

  // last accepted package
  lastAcceptedPackage: null,
  lastAcceptedPackageNoteSeen: false,

  //latest transactions
  recentTransactions: [],
  recentTransactionsLoading: false,

  //user company
  createdCompanyRef: [],

  //user chargebee
  currentUserHasChargebeeSubscription: false,

  sellerPackageRequestsKeyword: [],
  fetchSellerPackageRequestInProgressKeyword: false,
  fetchSellerPackageRequestError: null,
  buyerPackageRequestsKeyword: [],

  activeFooterTab: '',
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case USER_WAS_FETCHED:
      return { ...state, currentUserWasFetched: true };
    case CURRENT_USER_SHOW_REQUEST:
      return { ...state, currentUserShowError: null, currentUserWasFetched: false };
    case CURRENT_USER_SHOW_SUCCESS:
      return {
        ...state,
        currentUser: mergeCurrentUser(state.currentUser, payload),
        currentUserWasFetched: true,
      };
    case CURRENT_USER_SHOW_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, currentUserShowError: payload };

    case CLEAR_CURRENT_USER:
      return {
        ...state,
        currentUser: null,
        currentUserShowError: null,
        currentUserHasListings: false,
        currentUserHasListingsError: null,
        currentUserNotificationCount: 0,
        currentUserNotificationCountError: null,
        currentUserListing: null,
        currentUserListingFetched: false,
      };

    case FETCH_CURRENT_USER_HAS_LISTINGS_REQUEST:
      return { ...state, currentUserHasListingsError: null };
    case FETCH_CURRENT_USER_HAS_LISTINGS_SUCCESS:
      return {
        ...state,
        currentUserHasListings: payload.hasListings,
        currentUserListing: payload.listing,
        currentUserListingFetched: true,
        currentUserListings: payload.listings,
      };

    case FETCH_CURRENT_USER_HAS_COMPANY_FREELANCER_SUCCESS:
      return {
        ...state,
        currentUserHasCompanyOrFreelancer: payload.hasListings,
      };

    case FETCH_CURRENT_USER_HAS_TUTOR_ACADEMY_SUCCESS:
      return {
        ...state,
        currentUserHasTutorOrAcademy: payload.hasListings,
      };

    case FETCH_CURRENT_USER_HAS_LISTINGS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, currentUserHasListingsError: payload };

    case FETCH_CURRENT_USER_NOTIFICATIONS_REQUEST:
      return { ...state, currentUserNotificationCountError: null };
    case FETCH_CURRENT_USER_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        currentUserNotificationCount: payload.transactions?.length,
        currentUserSaleEnquiriesNotificationCount:
          payload.saleEnquiries.length < 100 ? payload.saleEnquiries.length : 100,
        currentUserOrderEnquiriesNotificationCount:
          payload.orderEnquiries.length < 100 ? payload.orderEnquiries.length : 100,
        currentUserPackagesNotificationCount:
          payload.packages.length < 100 ? payload.packages.length : 100,
      };
    case FETCH_CURRENT_USER_NOTIFICATIONS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, currentUserNotificationCountError: payload };

    case CHANGED_ACTIVEFOOTER_TAB: {
      return { ...state, activeFooterTab: payload };
    }

    case FETCH_CURRENT_USER_HAS_ORDERS_REQUEST:
      return { ...state, currentUserHasOrdersError: null };
    case FETCH_CURRENT_USER_HAS_ORDERS_SUCCESS:
      return { ...state, currentUserHasOrders: payload.hasOrders };
    case FETCH_CURRENT_USER_HAS_ORDERS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, currentUserHasOrdersError: payload };

    case SEND_VERIFICATION_EMAIL_REQUEST:
      return {
        ...state,
        sendVerificationEmailInProgress: true,
        sendVerificationEmailError: null,
      };
    case SEND_VERIFICATION_EMAIL_SUCCESS:
      return {
        ...state,
        sendVerificationEmailInProgress: false,
      };
    case SEND_VERIFICATION_EMAIL_ERROR:
      return {
        ...state,
        sendVerificationEmailInProgress: false,
        sendVerificationEmailError: payload,
      };

    case FETCH_COMPLETED_LISTINGS_SUCCESS:
      return { ...state, lastCompleteTransaction: payload };
    case LAST_BOOKING_REVIEWED:
      return { ...state, lastBookingReviewDone: true };

    case FETCH_ACCEPTED_PACKAGE_SUCCESS:
      return { ...state, lastAcceptedPackage: payload };
    case ACCEPTED_PACKAGE_NOTE_SEEN:
      return { ...state, lastAcceptedPackageNoteSeen: true };

    case FETCH_RECENT_TRANSACTIONS_STARTED:
      return { ...state, recentTransactionsLoading: true };
    case FETCH_RECENT_TRANSACTIONS_SUCCESS:
      return { ...state, recentTransactions: payload, recentTransactionsLoading: false };
    case FETCH_RECENT_TRANSACTIONS_FAILED:
      return { ...state, recentTransactionsLoading: false };

    //Chargebee
    case CURRENT_USER_HAS_CHARGEBEE_SUBSCRIPTION_REQUEST:
      return { ...state, currentUserHasChargebeeSubscription: false };
    case CURRENT_USER_HAS_CHARGEBEE_SUBSCRIPTION_SUCCESS:
      return { ...state, currentUserHasChargebeeSubscription: true };
    case CURRENT_USER_HAS_CHARGEBEE_SUBSCRIPTION_ERROR:
      return { ...state, currentUserHasChargebeeSubscription: false };

    case FETCH_COMPANY_SUCCESS:
      return { ...state, createdCompanyRef: payload };

    case FETCH_SELLER_PACKAGE_REQUESTS:
      return {
        ...state,
        fetchSellerPackageRequestInProgress: true,
        fetchSellerPackageRequestError: null,
      };
    case FETCH_SELLER_PACKAGE_REQUESTS_SUCCESS: {
      return {
        ...state,
        fetchSellerPackageRequestInProgress: false,
        sellerPackageRequestsKeyword: payload,
        sellerPackagesPagination: payload.meta,
      };
    }
    case FETCH_SELLER_PACKAGE_REQUESTS_ERROR:
      return {
        ...state,
        fetchSellerPackageRequestInProgress: false,
        fetchSellerPackageRequestError: payload,
      };

    case FETCH_BUYER_PACKAGE_REQUESTS_SUCCESS: {
      return {
        ...state,
        buyerPackageRequestsKeyword: payload,
      };
    }

    default:
      return state;
  }
}

// ================ Selectors ================ //

export const hasCurrentUserErrors = state => {
  const { user } = state;
  return (
    user.currentUserShowError ||
    user.currentUserHasListingsError ||
    user.currentUserNotificationCountError ||
    user.currentUserHasOrdersError
  );
};

export const verificationSendingInProgress = state => {
  return state.user.sendVerificationEmailInProgress;
};

// ================ Action creators ================ //

export const setUserWasFetched = () => ({ type: USER_WAS_FETCHED });

export const currentUserShowRequest = () => ({ type: CURRENT_USER_SHOW_REQUEST });

export const currentUserShowSuccess = user => ({
  type: CURRENT_USER_SHOW_SUCCESS,
  payload: user,
});

export const currentUserShowError = e => ({
  type: CURRENT_USER_SHOW_ERROR,
  payload: e,
  error: true,
});

export const clearCurrentUser = () => ({ type: CLEAR_CURRENT_USER });

const fetchCurrentUserHasListingsRequest = () => ({
  type: FETCH_CURRENT_USER_HAS_LISTINGS_REQUEST,
});

export const fetchCurrentUserHasListingsSuccess = (hasListings, listing, listings) => ({
  type: FETCH_CURRENT_USER_HAS_LISTINGS_SUCCESS,
  payload: { hasListings, listing, listings },
});
export const fetchCurrentUserHasCompanyOrFreelancer = hasListings => ({
  type: FETCH_CURRENT_USER_HAS_COMPANY_FREELANCER_SUCCESS,
  payload: { hasListings },
});

export const fetchCurrentUserHasTutorOrAcademy = hasListings => ({
  type: FETCH_CURRENT_USER_HAS_TUTOR_ACADEMY_SUCCESS,
  payload: { hasListings },
});

export const fetchCurrentUserHasListingsError = e => ({
  type: FETCH_CURRENT_USER_HAS_LISTINGS_ERROR,
  error: true,
  payload: e,
});

const fetchCurrentUserNotificationsRequest = () => ({
  type: FETCH_CURRENT_USER_NOTIFICATIONS_REQUEST,
});

export const fetchCurrentUserNotificationsSuccess = payload => ({
  type: FETCH_CURRENT_USER_NOTIFICATIONS_SUCCESS,
  payload: payload,
});

const fetchCurrentUserNotificationsError = e => ({
  type: FETCH_CURRENT_USER_NOTIFICATIONS_ERROR,
  error: true,
  payload: e,
});

const fetchCurrentUserHasOrdersRequest = () => ({
  type: FETCH_CURRENT_USER_HAS_ORDERS_REQUEST,
});

export const fetchCurrentUserHasOrdersSuccess = hasOrders => ({
  type: FETCH_CURRENT_USER_HAS_ORDERS_SUCCESS,
  payload: { hasOrders },
});

const fetchCurrentUserHasOrdersError = e => ({
  type: FETCH_CURRENT_USER_HAS_ORDERS_ERROR,
  error: true,
  payload: e,
});

export const sendVerificationEmailRequest = () => ({
  type: SEND_VERIFICATION_EMAIL_REQUEST,
});

export const sendVerificationEmailSuccess = () => ({
  type: SEND_VERIFICATION_EMAIL_SUCCESS,
});

export const sendVerificationEmailError = e => ({
  type: SEND_VERIFICATION_EMAIL_ERROR,
  error: true,
  payload: e,
});

export const fetchCompletedBookingsStart = () => ({ type: FETCH_COMPLETED_LISTINGS_STARTED });
export const fetchCompletedBookingsSuccess = payload => ({
  type: FETCH_COMPLETED_LISTINGS_SUCCESS,
  payload,
});
export const fetchCompleteBookingsError = error => ({
  type: FETCH_COMPLETED_LISTINGS_FAILED,
  error,
});

export const setLastBookingReviewed = () => ({ type: LAST_BOOKING_REVIEWED });

export const fetchAcceptedPackageStart = () => ({ type: FETCH_ACCEPTED_PACKAGE_STARTED });
export const fetchAcceptedPackageSuccess = payload => ({
  type: FETCH_ACCEPTED_PACKAGE_SUCCESS,
  payload,
});
export const fetchAcceptedPackageError = error => ({
  type: FETCH_ACCEPTED_PACKAGE_FAILED,
  error,
});

export const setAcceptedPackageNoteSeen = () => ({ type: ACCEPTED_PACKAGE_NOTE_SEEN });

export const fetchRecentTransactionsStart = () => ({ type: FETCH_RECENT_TRANSACTIONS_STARTED });
export const fetchRecentTransactionsSuccess = payload => ({
  type: FETCH_RECENT_TRANSACTIONS_SUCCESS,
  payload,
});
export const fetchRecentTransactionsFailed = () => ({ type: FETCH_RECENT_TRANSACTIONS_FAILED });

export const fetchCompanySuccess = payload => ({
  type: FETCH_COMPANY_SUCCESS,
  payload,
});

//chargebee
export const currentUserHasChargebeeSubscriptionRequest = () => ({
  type: CURRENT_USER_HAS_CHARGEBEE_SUBSCRIPTION_REQUEST,
});
export const currentUserHasChargebeeSubscriptionSuccess = () => ({
  type: CURRENT_USER_HAS_CHARGEBEE_SUBSCRIPTION_SUCCESS,
});
export const currentUserHasChargebeeSubscriptionError = () => ({
  type: CURRENT_USER_HAS_CHARGEBEE_SUBSCRIPTION_ERROR,
});

export const changingActivefooterTab = tab => ({
  type: CHANGED_ACTIVEFOOTER_TAB,
  payload: tab,
});
//chargebee

const sellerPackageRequestsSuccess = response => ({
  type: FETCH_SELLER_PACKAGE_REQUESTS_SUCCESS,
  payload: response,
});

const buyerPackageRequestsSuccess = response => ({
  type: FETCH_BUYER_PACKAGE_REQUESTS_SUCCESS,
  payload: response,
});

// ================ Thunks ================ //

export const addChargebeeSubscription = (chargebeeObject, currentUser) => (
  dispatch,
  getState,
  sdk
) => {
  const { chargebeeId, chargebeeState, currentDate, futureDate, plan } = chargebeeObject;
  dispatch(currentUserHasChargebeeSubscriptionRequest());
  if (!currentUser) return;
  return sdk.currentUser
    .updateProfile(
      {
        protectedData: {
          ChargebeeId: chargebeeId,
          ChargebeeState: chargebeeState,
          plan: plan,
          CurrentDate: moment(currentDate).format('MM/DD/YYYY'),
          FutureDate: moment(futureDate).format('MM/DD/YYYY'),
        },
      },
      {
        expand: true,
        include: ['profileImage'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
      }
    )
    .then(res => {
      // res.data
      dispatch(currentUserHasChargebeeSubscriptionSuccess());
      dispatch(fetchCurrentUser());
      console.log(res, 'res');
    })
    .catch(e => {
      dispatch(currentUserHasChargebeeSubscriptionError());
      console.error('---->', e);
    });
};

export const fetchCurrentUserHasListings = () => (dispatch, getState, sdk) => {
  dispatch(fetchCurrentUserHasListingsRequest());
  const { currentUser } = getState().user;
  if (!currentUser) {
    dispatch(fetchCurrentUserHasListingsSuccess(false));
    return Promise.resolve(null);
  }
  dispatch(fetchUserHasCompanyOrFreelancer());
  dispatch(fetchUserTutorOrAcademy());

  const params = {
    // Since we are only interested in if the user has
    // listings, we only need at most one result.
    authorId: currentUser.id.uuid,
    pub_listing_type: 'listing,company,Company,class,freelancers,spaces',
    page: 1,
    // per_page: 1,
  };

  return sdk.listings
    .query(params)
    .then(response => {
      const hasListings = response.data.data && response.data.data.length > 0;
      const listing = hasListings ? response.data.data[0] : null;
      const listings = hasListings ? response.data.data : [];

      const hasPublishedListings =
        hasListings &&
        ensureOwnListing(response.data.data[0]).attributes.state !== LISTING_STATE_DRAFT;
      dispatch(fetchCurrentUserHasListingsSuccess(!!hasPublishedListings, listing, listings));
    })
    .catch(e => dispatch(currentUserHasChargebeeSubscriptionError(storableError(e))));
};

export const fetchUserHasCompanyOrFreelancer = () => (dispatch, getState, sdk) => {
  const { currentUser } = getState().user;
  const params = {
    authorId: currentUser.id.uuid,
    pub_listing_type: 'Company,company,freelancer',
    page: 1,
    per_page: 1,
  };
  return sdk.listings
    .query(params)
    .then(response => {
      const hasListings = response.data.data && response.data.data.length > 0;

      const hasPublishedListings =
        hasListings &&
        ensureOwnListing(response.data.data[0]).attributes.state !== LISTING_STATE_DRAFT;
      dispatch(fetchCurrentUserHasCompanyOrFreelancer(!!hasPublishedListings));
    })
    .catch(e => console.error(e));
};

export const fetchUserTutorOrAcademy = () => (dispatch, getState, sdk) => {
  const { currentUser } = getState().user;
  const params = {
    authorId: currentUser?.id?.uuid,
    pub_listing_type: 'academy,tutor,remote tutor',
    page: 1,
    per_page: 1,
  };
  return sdk.listings
    .query(params)
    .then(response => {
      const hasListings = response.data.data && response.data.data.length > 0;

      const hasPublishedListings =
        hasListings &&
        ensureOwnListing(response.data.data[0]).attributes.state !== LISTING_STATE_DRAFT;
      dispatch(fetchCurrentUserHasTutorOrAcademy(!!hasPublishedListings));
    })
    .catch(e => console.error(e));
};

export const fetchCurrentUserHasOrders = () => (dispatch, getState, sdk) => {
  dispatch(fetchCurrentUserHasOrdersRequest());

  if (!getState().user.currentUser) {
    dispatch(fetchCurrentUserHasOrdersSuccess(false));
    return Promise.resolve(null);
  }

  const params = {
    only: 'order',
    page: 1,
    per_page: 1,
  };

  return sdk.transactions
    .query(params)
    .then(response => {
      const hasOrders = response.data.data && response.data.data.length > 0;
      dispatch(fetchCurrentUserHasOrdersSuccess(!!hasOrders));
    })
    .catch(e => dispatch(fetchCurrentUserHasOrdersError(storableError(e))));
};

// Notificaiton page size is max (100 items on page)
const NOTIFICATION_PAGE_SIZE = 100;

export const fetchUser = userId => (dispatch, getState, sdk) => {
  return sdk.users
    .show({
      id: userId,
      include: ['profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
    })
    .catch(e => {
      console.log(e);
    });
};

export const fetchCurrentUserNotifications = userUuid => async (dispatch, getState, sdk) => {
  dispatch(fetchCurrentUserNotificationsRequest());

  const apiQueryParams = {
    only: 'sale',
    last_transitions: transitionsToRequested,
    page: 1,
    per_page: NOTIFICATION_PAGE_SIZE,
  };

  const packagesUrl =
    config.serverBaseUrl +
    config.creditRequestEndPoint +
    '/' +
    userUuid +
    '/' +
    config.sellerPackageRequestsEndPoint;
  const packagesHeaders = {
    headers: {
      Authorization: `Token token=${config.serverToken}`,
    },
  };

  const saleEnquiriesQueryParams = {
    only: 'sale',
    lastTransitions: TRANSITION_ENQUIRE,
    include: [],
    'fields.transaction': ['lastTransition', 'lastTransitionedAt'],
    page: 1,
    per_page: 100,
  };

  const orderEnquiriesQueryParams = {
    only: 'order',
    lastTransitions: TRANSITION_ENQUIRE,
    include: [],
    'fields.transaction': ['lastTransition', 'lastTransitionedAt'],
    page: 1,
    per_page: 100,
  };

  try {
    const [packagesRes, transactionsRes] = await Promise.allSettled([
      axios.get(packagesUrl, packagesHeaders),
      sdk.transactions.query(apiQueryParams),
    ]);

    dispatch(sellerPackageRequestsSuccess(packagesRes.value.data));

    const buyers = packagesRes.value.data.filter(
      (v, i, a) => a.findIndex(v2 => v2.buyer_id === v.buyer_id) === i
    );

    if (window?.location?.pathname && window?.location?.pathname?.includes('inbox')) {
      buyers.map(({ buyer_id }) => {
        dispatch(fetchUser(buyer_id));
      });
    }

    const saleEnquiriesresponse = await sdk.transactions.query(saleEnquiriesQueryParams);
    const saleEnquiries = saleEnquiriesresponse.data.data.filter(
      enq =>
        moment().format('YYYY-MM-DD') ===
        moment(enq.attributes.lastTransitionedAt).format('YYYY-MM-DD')
    );

    const orderEnquiriesresponse = await sdk.transactions.query(orderEnquiriesQueryParams);
    const orderEnquiries = orderEnquiriesresponse.data.data.filter(
      enq =>
        moment().format('YYYY-MM-DD') ===
        moment(enq.attributes.lastTransitionedAt).format('YYYY-MM-DD')
    );

    const packages = packagesRes.value.data
      ? packagesRes.value.data.filter(p => p.status === 'pending')
      : [];
    const transactions = transactionsRes.value.data ? transactionsRes.value.data.data : [];

    dispatch(
      fetchCurrentUserNotificationsSuccess({
        packages,
        transactions,
        saleEnquiries,
        orderEnquiries,
      })
    );
  } catch (e) {
    dispatch(fetchCurrentUserNotificationsError(storableError(e)));
  }
};

export const fetchCompletedBookings = () => async (dispatch, getState, sdk) => {
  dispatch(fetchCompletedBookingsStart());
  try {
    const response = await sdk.transactions.query({
      only: 'order',
      lastTransitions: [
        TRANSITION_COMPLETE_MANUAL,
        TRANSITION_COMPLETE,
        TRANSITION_REVIEW_1_BY_PROVIDER,
        TRANSITION_SKIP_1_BY_PROVIDER,
      ],
      include: ['listing', 'provider', 'customer'],
    });

    if (response.data.data && response.data.data.length) {
      const lastItem = response.data.data[0];

      const stateEntities = updatedEntities({}, response.data);
      const denormalizedTx = denormalisedEntities(stateEntities, [
        {
          id: lastItem.id,
          type: lastItem.type,
        },
      ]);

      const tx = denormalizedTx[0];
      const isTxOld = wasTransactionCreatedBeforeTimestamp(tx, '2020-09-24 15:30:00');
      const isValidTx =
        !tx.listing.attributes.deleted && !tx.provider.attributes.deleted && !isTxOld;
      if (isValidTx) dispatch(fetchCompletedBookingsSuccess(denormalizedTx[0]));
    }
  } catch (e) {
    console.error(e);
    dispatch(fetchCompleteBookingsError(e));
  }
};

export const fetchAcceptedPackage = buyerId => async (dispatch, getState, sdk) => {
  dispatch(fetchAcceptedPackageStart());
  try {
    const url =
      config.serverBaseUrl +
      config.creditRequestEndPoint +
      '/' +
      buyerId +
      '/' +
      config.buyerPackageRequestsEndPoint;
    const headers = {
      headers: {
        Authorization: `Token token=${config.serverToken}`,
      },
    };
    const response = await axios.get(url, headers);

    dispatch(buyerPackageRequestsSuccess(response.data));

    const lastBoughtPackage = response.data[0];
    const isValidPackage =
      !!lastBoughtPackage &&
      lastBoughtPackage.status === 'accepted' &&
      lastBoughtPackage.used_credits === 0;
    if (isValidPackage) dispatch(fetchAcceptedPackageSuccess(lastBoughtPackage));
  } catch (e) {
    console.error(e);
  }
};

export const fetchRecentTransactions = () => async (dispatch, getState, sdk) => {
  try {
    dispatch(fetchRecentTransactionsStart());
    const excludeTransition = [
      TRANSITION_ENQUIRE,
      TRANSITION_DECLINE,
      TRANSITION_CANCEL_READY_TO_COMPLETE,
      TRANSITION_CANCEL,
    ];

    const response = await sdk.transactions.query({
      only: 'order',
      lastTransitions: TRANSITIONS.filter(t => !excludeTransition.includes(t)),
      include: ['listing', 'booking'],
      page: 1,
      per_page: 10,
    });

    const refs = response.data.data.map(({ id, type }) => ({ id, type }));
    const stateEntities = updatedEntities({}, response.data);
    const recentTransactions = denormalisedEntities(stateEntities, refs);

    dispatch(fetchRecentTransactionsSuccess(recentTransactions));
  } catch (e) {
    console.error(e);
    dispatch(fetchRecentTransactionsFailed(e));
  }
};

export const fetchUserCompany = userUuid => async (dispatch, getState, sdk) => {
  try {
    const response = await sdk.listings.query({
      authorId: userUuid,
      pub_listing_type: 'company',
      per_page: 1,
    });

    const companyRef = entityRefs(response.data.data);
    dispatch(fetchCompanySuccess(companyRef));
    dispatch(addMarketplaceEntities(response));
  } catch (e) {
    console.error(e);
  }
};

export const updateListingStatus = listingId => async (dispatch, getState, sdk) => {
  try {
    const bookings = await sdk.bookings.query({
      listingId: listingId,
      start: moment()
        .subtract(90, 'days')
        .toISOString(),
      end: moment().toISOString(),
      state: 'accepted',
      perPage: 1,
    });

    const listingReviews = await sdk.reviews.query({
      listingId: listingId,
      type: 'ofProvider',
      state: 'public',
    });

    const totalBookings = bookings.data.meta.totalItems;
    const totalReviews = listingReviews.data.meta.totalItems;
    const totalRating = listingReviews.data.data.reduce(
      (acc, review) => acc + review.attributes.rating,
      0
    );
    const rating = totalRating / totalReviews;

    const params = {
      id: listingId,
      publicData: {
        total_bookings: totalBookings,
        total_reviewers: totalReviews,
        rating: isNaN(rating) ? 0 : rating,
        total_ratings: totalRating,
      },
    };
    await sdk.ownListings.update(params);
  } catch (e) {
    console.error(e);
  }
};

export const updateOwnListingStatus = () => async (dispatch, getState, sdk) => {
  const state = getState();
  const currentUserId = state.user.currentUser ? state.user.currentUser.id.uuid : '';
  if (currentUserId === '') return;

  try {
    const responseListings = await sdk.listings.query({
      authorId: currentUserId,
      pub_listing_type: ['listing', 'class', 'facility'],
      include: ['author'],
    });
    const owningListings = responseListings.data.data;
    owningListings.forEach(listing => {
      dispatch(updateListingStatus(listing.id.uuid));
    });
  } catch (e) {
    console.error(e);
  }
};

export const fetchCurrentUser = (params = null) => (dispatch, getState, sdk) => {
  dispatch(currentUserShowRequest());
  const { isAuthenticated } = getState().Auth;

  if (!isAuthenticated) {
    // Make sure current user is null
    dispatch(currentUserShowSuccess(null));
    return Promise.resolve({});
  }

  const parameters = params || {
    include: ['profileImage', 'stripeAccount'],
    'fields.image': ['variants.square-small', 'variants.square-small2x'],
  };

  return sdk.currentUser
    .show(parameters)
    .then(response => {
      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.currentUser.show response');
      }
      const currentUser = entities[0];

      // Save stripeAccount to store.stripe.stripeAccount if it exists
      if (currentUser.stripeAccount) {
        dispatch(stripeAccountCreateSuccess(currentUser.stripeAccount));
      }

      // set current user id to the logger
      log.setUserId(currentUser.id.uuid);

      dispatch(currentUserShowSuccess(currentUser));
      return currentUser;
    })
    .then(currentUser => {
      dispatch(fetchCurrentUserHasListings());
      dispatch(fetchCurrentUserNotifications(currentUser.id.uuid));
      dispatch(fetchUserCompany(currentUser.id.uuid));
      if (!currentUser.attributes.emailVerified) {
        dispatch(fetchCurrentUserHasOrders());
      }
      dispatch(fetchCompletedBookings());
      dispatch(fetchAcceptedPackage(currentUser.id.uuid));
      dispatch(fetchRecentTransactions());

      // Make sure auth info is up to date
      dispatch(authInfo());
    })
    .catch(e => {
      // Make sure auth info is up to date
      dispatch(authInfo());
      log.error(e, 'fetch-current-user-failed');
      dispatch(currentUserShowError(storableError(e)));
    });
};

export const sendVerificationEmail = () => (dispatch, getState, sdk) => {
  if (verificationSendingInProgress(getState())) {
    return Promise.reject(new Error('Verification email sending already in progress'));
  }
  dispatch(sendVerificationEmailRequest());
  return sdk.currentUser
    .sendVerificationEmail()
    .then(() => dispatch(sendVerificationEmailSuccess()))
    .catch(e => dispatch(sendVerificationEmailError(storableError(e))));
};
