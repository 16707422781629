import React, { useState } from 'react';
import { LISTING_TYPES } from 'util/constants';
import classNames from 'classnames';
import css from './HeaderContainer.css';

const tabsList = [
  {
    label: 'Book a Court',
    id: 'facilitiy',
  },
  {
    label: `Pro's`,
    id: 'freelancer',
  },
  {
    label: 'Academies',
    id: 'Company,company',
  },
  // {
  //   label: 'Players Community',
  //   id: 'player-profile',
  // },
  {
    label: 'Classes',
    id: 'class',
  },

  // {
  //   label: `Shop`,
  //   id: 'product',
  // },
  // {
  //   label: 'Events',
  //   id: 'event',
  // },
];

export const educationTab = [
  { id: LISTING_TYPES.TUTOR, label: 'Tutors' },
  { id: LISTING_TYPES.ACADEMY, label: 'Academy' },
  { id: LISTING_TYPES.COURSES, label: 'Classes' },
  { id: LISTING_TYPES.EDUCATION_COURSES, label: 'Courses' },
];

export const TennisTab = [
  { id: LISTING_TYPES.PLAYER_PROFILE, label: 'Players Community' },
  { id: LISTING_TYPES.EVENT, label: 'Activities' },
  { id: LISTING_TYPES.PRODUCT, label: 'Shop' },
];

function HeaderTabs(props) {
  const [activeTab, setActiveTab] = useState(props.initialTab || 'listing');

  const tabList = props.isEducationSite ? educationTab : props.isTennisSite ? TennisTab : tabsList;

  return (
    <div
      className={classNames(
        css.tabs,
        { [css.tabsEdu]: props.isEducationSite },
        { [css.tabsTen]: props.isTennisSite }
      )}
    >
      <div className={css.tabsListContainer}>
        {tabList.map((tab, index) => (
          <div
            key={`${index}-${tab.id}`}
            onClick={() => {
              props.onChangeTab(tab);
              setActiveTab(tab.id);
            }}
            className={tab.id === activeTab ? css.activeTab : css.inactiveTab}
          >
            {tab.label}
          </div>
        ))}
      </div>
    </div>
  );
}

export default HeaderTabs;
