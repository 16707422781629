import React, { useEffect } from 'react';
import { array, arrayOf, bool, func, object, oneOf, shape, string } from 'prop-types';
import { propTypes } from '../../util/types';
import { intlShape } from '../../util/reactIntl';
import routeConfiguration from '../../routeConfiguration';
import {
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_NEW,
  LISTING_PAGE_PARAM_TYPES,
} from '../../util/urlHelpers';
import { ensureListing } from '../../util/data';
import { createResourceLocatorString } from '../../util/routes';
import {
  EditListingAvailabilityPanel,
  EditListingDescriptionPanel,
  EditListingFeaturesPanel,
  EditListingLocationPanel,
  EditListingPhotosPanel,
  EditListingPoliciesPanel,
  EditListingPricingPanel,
  EditListingCertificatesPanel,
  EditListingFAQPanel,
  EditListingBlogsPanel,
  EditListingExpectationsPanel,
  EditListingIntroductoryPanel,
  EditListingDetailsPanel,
  EditListingPricingStockPanel,
  EditListingDeliveryPanel,
  Form,
  EditListingServicesPanel,
  EditListingSocialLinksPanel,
} from '../../components';

import css from './EditListingWizard.css';
import {
  LISTING_TYPES,
  AVAILABILITY,
  DESCRIPTION,
  FEATURES,
  POLICY,
  LOCATION,
  PRICING,
  PHOTOS,
  CERTIFICATES,
  EXPECTATIONS,
  FAQ,
  BLOGS,
  DETAILS,
  PRICESTOCK,
  DELIVERY,
  EDUCATION,
  INTODUCTORY,
  SERVICES,
  SOCIALS,
} from 'util/constants';
import EditListingEducationPanel from 'components/EditListingEducationPanel/EditListingEducationPanel';
import { availabilityPlan } from 'config';

// EditListingWizardTab component supports these tabs
export const SUPPORTED_TABS = [
  DESCRIPTION,
  FEATURES,
  POLICY,
  LOCATION,
  PRICING,
  AVAILABILITY,
  PHOTOS,
  DETAILS,
  PRICESTOCK,
  DELIVERY,
  INTODUCTORY,
  CERTIFICATES,
  EDUCATION,
  FAQ,
  BLOGS,
  EXPECTATIONS,
];

const pathParamsToPrevTab = (params, tab, marketplaceTabs) => {
  const prevTabIndex = marketplaceTabs.findIndex(s => s === tab) - 1;
  const prevTab = prevTabIndex !== 0 ? marketplaceTabs[prevTabIndex] : marketplaceTabs[0];
  return { ...params, tab: prevTab };
};

const pathParamsToNextTab = (params, tab, marketplaceTabs) => {
  const nextTabIndex = marketplaceTabs.findIndex(s => s === tab) + 1;
  const nextTab =
    nextTabIndex < marketplaceTabs.length
      ? marketplaceTabs[nextTabIndex]
      : marketplaceTabs[marketplaceTabs.length - 1];
  return { ...params, tab: nextTab };
};

// When user has update draft listing, he should be redirected to next EditListingWizardTab
const redirectAfterDraftUpdate = (
  listingId,
  listingType,
  params,
  tab,
  marketplaceTabs,
  history
) => {
  const redirectRoutes = {
    [LISTING_TYPES.LISTING]: 'EditListingPage',
    [LISTING_TYPES.ENQUIRY]: 'EditInquirePage',
    [LISTING_TYPES.COMPANY]: 'EditCompanyPage',
    [LISTING_TYPES.FREELANCER]: 'EditFreelancerPage',
    [LISTING_TYPES.CLASS]: 'EditClassPage',
    [LISTING_TYPES.EVENT]: 'EditEventPage',
    [LISTING_TYPES.PRODUCT]: 'EditProductPage',
    [LISTING_TYPES.MEMBERSHIP]: 'EditMembershipPage',
    [LISTING_TYPES.FACILITY]: 'EditFacilityPage',
    [LISTING_TYPES.ACADEMY]: 'EditAcademyPage',
    [LISTING_TYPES.COURSES]: 'EditCoursesPage',
    [LISTING_TYPES.EDUCATION_COURSES]: 'EditEducationCoursesPage',
    [LISTING_TYPES.HOURLY_SESSION]: 'EditHourlySessionPage',
    [LISTING_TYPES.REMOTE_TUTOR]: 'EditRemoteTutorPage',
    [LISTING_TYPES.TUTOR]: 'EditTutorPage',
    [LISTING_TYPES.PLAYER_PROFILE]: 'EditPlayerProfilePage',
  };
  const routeName = redirectRoutes[listingType];
  const currentPathParams = {
    ...params,
    type: LISTING_PAGE_PARAM_TYPE_DRAFT,
    id: listingId,
  };
  const routes = routeConfiguration();

  // Replace current "new" path to "draft" path.
  // Browser's back button should lead to editing current draft instead of creating a new one.
  if (params.type === LISTING_PAGE_PARAM_TYPE_NEW) {
    const draftURI = createResourceLocatorString(routeName, routes, currentPathParams, {});
    history.replace(draftURI);
  }

  // Redirect to next tab
  const nextPathParams = pathParamsToNextTab(currentPathParams, tab, marketplaceTabs);
  const to = createResourceLocatorString(routeName, routes, nextPathParams, {});
  history.push(to);
};
let isFirstRender = true;

const EditListingWizardTab = props => {
  const {
    tab,
    marketplaceTabs,
    params,
    errors,
    fetchInProgress,
    newListingPublished,
    history,
    images,
    listing,
    handleCreateFlowTabScrolling,
    handlePublishListing,
    onAddAvailabilityException,
    onDeleteAvailabilityException,
    onUpdateListing,
    onCreateListingDraft,
    onImageUpload,
    onUpdateImageOrder,
    onRemoveImage,
    onChange,
    onManageDisableScrolling,
    updatedTab,
    updateInProgress,
    intl,
    fetchExceptionsInProgress,
    availabilityExceptions,
    listingType,
    currentUser,
    isTabCompleted,
    isEducationSite,
    // isTennisSite,
  } = props;

  const { type } = params;
  const isNewURI = type === LISTING_PAGE_PARAM_TYPE_NEW;
  const isDraftURI = type === LISTING_PAGE_PARAM_TYPE_DRAFT;
  const isNewListingFlow = isNewURI || isDraftURI;

  const currentListing = ensureListing(listing);
  const imageIds = images => {
    return images ? images.map(img => img.imageId || img.id) : null;
  };

  const onCompleteEditListingWizardTab = (tab, updateValues, passThrownErrors = false) => {
    // Normalize images for API call
    const { images: updatedImages, ...otherValues } = updateValues;

    if (currentUser.id === null && updateValues.publicData.listing_type === LISTING_TYPES.ENQUIRY) {
      localStorage.setItem('LandingFormData', JSON.stringify(updateValues));
      history.push('/signup');
    }

    const imageProperty =
      typeof updatedImages !== 'undefined' ? { images: imageIds(updatedImages) } : {};
    const updateValuesWithImages = { ...otherValues, ...imageProperty };

    if (isNewListingFlow) {
      const onUpsertListingDraft = isNewURI
        ? (tab, updateValues) => onCreateListingDraft(updateValues)
        : onUpdateListing;

      const upsertValues = isNewURI
        ? updateValuesWithImages
        : { ...updateValuesWithImages, id: currentListing.id };

      return onUpsertListingDraft(tab, upsertValues)
        .then(r => {
          if (tab !== AVAILABILITY && tab !== marketplaceTabs[marketplaceTabs.length - 1]) {
            // Create listing flow: smooth scrolling polyfill to scroll to correct tab
            handleCreateFlowTabScrolling(false);

            // After successful saving of draft data, user should be redirected to next tab
            redirectAfterDraftUpdate(
              r.data.data.id.uuid,
              listingType,
              params,
              tab,
              marketplaceTabs,
              history
            );
          } else if (tab === marketplaceTabs[marketplaceTabs.length - 1]) {
            handlePublishListing(currentListing.id);
            if (listingType === LISTING_TYPES.COMPANY || listingType === LISTING_TYPES.FREELANCER) {
              history.push(
                createResourceLocatorString('EditListingPage', routeConfiguration(), {}, {})
              );
            }
          }
        })
        .catch(e => {
          if (passThrownErrors) {
            throw e;
          }
          // No need for extra actions
          // Error is logged in EditListingPage.duck file.
        });
    } else {
      return onUpdateListing(tab, { ...updateValuesWithImages, id: currentListing.id });
    }
  };

  useEffect(() => {
    if (localStorage.getItem('LandingFormData')) {
      const updateData = JSON.parse(localStorage.getItem('LandingFormData'));
      localStorage.removeItem('LandingFormData');
      onCompleteEditListingWizardTab('description', updateData);
    }
    // eslint-disable-next-line
  }, []);
  const panelProps = tab => {
    return {
      className: css.panel,
      listingType,
      errors,
      listing,
      onChange,
      panelUpdated: updatedTab === tab,
      updateInProgress,
      onManageDisableScrolling,
      // newListingPublished and fetchInProgress are flags for the last wizard tab
      ready: newListingPublished,
      disabled: fetchInProgress,
    };
  };

  const onRedirectPrevTab = () => {
    const redirectRoutes = {
      [LISTING_TYPES.LISTING]: 'EditListingPage',
      [LISTING_TYPES.ENQUIRY]: 'EditInquirePage',
      [LISTING_TYPES.COMPANY]: 'EditCompanyPage',
      [LISTING_TYPES.FREELANCER]: 'EditFreelancerPage',
      [LISTING_TYPES.CLASS]: 'EditClassPage',
      [LISTING_TYPES.EVENT]: 'EditEventPage',
      [LISTING_TYPES.PRODUCT]: 'EditProductPage',
      [LISTING_TYPES.MEMBERSHIP]: 'EditMembershipPage',
      [LISTING_TYPES.FACILITY]: 'EditFacilityPage',
      [LISTING_TYPES.ACADEMY]: 'EditAcademyPage',
      [LISTING_TYPES.REMOTE_TUTOR]: 'EditRemoteTutorPage',
      [LISTING_TYPES.COURSES]: 'EditCoursesPage',
      [LISTING_TYPES.EDUCATION_COURSES]: 'EditEducationCoursesPage',
      [LISTING_TYPES.HOURLY_SESSION]: 'EditHourlySessionPage',
      [LISTING_TYPES.TUTOR]: 'EditTutorPage',
      [LISTING_TYPES.PLAYER_PROFILE]: 'EditPlayerProfilePage',
    };
    const routeName = redirectRoutes[listingType];
    const routes = routeConfiguration();
    const prevPathParams = pathParamsToPrevTab(params, tab, marketplaceTabs);
    const to = createResourceLocatorString(routeName, routes, prevPathParams, {});
    history.push(to);
  };

  const onRedirectNextTab = () => {
    const redirectRoutes = {
      [LISTING_TYPES.LISTING]: 'EditListingPage',
      [LISTING_TYPES.ENQUIRY]: 'EditInquirePage',
      [LISTING_TYPES.COMPANY]: 'EditCompanyPage',
      [LISTING_TYPES.FREELANCER]: 'EditFreelancerPage',
      [LISTING_TYPES.CLASS]: 'EditClassPage',
      [LISTING_TYPES.EVENT]: 'EditEventPage',
      [LISTING_TYPES.PRODUCT]: 'EditProductPage',
      [LISTING_TYPES.MEMBERSHIP]: 'EditMembershipPage',
      [LISTING_TYPES.FACILITY]: 'EditFacilityPage',
      [LISTING_TYPES.REMOTE_TUTOR]: 'EditRemoteTutorPage',
      [LISTING_TYPES.ACADEMY]: 'EditAcademyPage',
      [LISTING_TYPES.COURSES]: 'EditCoursesPage',
      [LISTING_TYPES.EDUCATION_COURSES]: 'EditEducationCoursesPage',
      [LISTING_TYPES.HOURLY_SESSION]: 'EditHourlySessionPage',
      [LISTING_TYPES.TUTOR]: 'EditTutorPage',
      [LISTING_TYPES.PLAYER_PROFILE]: 'EditPlayerProfilePage',
    };
    const routeName = redirectRoutes[listingType];
    const routes = routeConfiguration();
    const prevPathParams = pathParamsToNextTab(params, tab, marketplaceTabs);
    const to = createResourceLocatorString(routeName, routes, prevPathParams, {});
    history.push(to);
  };

  switch (tab) {
    case DESCRIPTION: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewDescription'
        : 'EditListingWizard.saveEditDescription';
      return (
        <div className={css.panelNavWrapper}>
          <EditListingDescriptionPanel
            {...panelProps(DESCRIPTION)}
            submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
            onSubmit={values => {
              const updatedValues = {
                ...values,
                publicData: {
                  ...values.publicData,
                  listing_type: listingType,
                  has_premium_membership: false,
                },
              };

              onCompleteEditListingWizardTab(tab, updatedValues);
            }}
            isEducationSite={isEducationSite}
            // isTennisSite={isTennisSite}
          />
          <Form
            marketplaceTabs={marketplaceTabs}
            tab={tab}
            onRedirectPrevTab={onRedirectPrevTab}
            onRedirectNextTab={onRedirectNextTab}
            isTabCompleted={isTabCompleted}
          />
        </div>
      );
    }
    case DETAILS: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewDetails'
        : 'EditListingWizard.saveEditDetails';
      return (
        <div className={css.panelNavWrapper}>
          <EditListingDetailsPanel
            {...panelProps(DETAILS)}
            submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
            onSubmit={values => {
              const updatedValues = {
                ...values,
                publicData: {
                  ...values.publicData,
                  listing_type: listingType,
                  has_premium_membership: false,
                },
              };
              onCompleteEditListingWizardTab(tab, updatedValues);
            }}
          />
          <Form
            marketplaceTabs={marketplaceTabs}
            tab={tab}
            onRedirectPrevTab={onRedirectPrevTab}
            onRedirectNextTab={onRedirectNextTab}
            isTabCompleted={isTabCompleted}
          />
        </div>
      );
    }
    case PRICESTOCK: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewPricingListing'
        : 'EditListingWizard.saveEditPricing';
      return (
        <div className={css.panelNavWrapper}>
          <EditListingPricingStockPanel
            {...panelProps(PRICESTOCK)}
            submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
            onSubmit={values => {
              onCompleteEditListingWizardTab(tab, values);
            }}
          />
          <Form
            marketplaceTabs={marketplaceTabs}
            tab={tab}
            onRedirectPrevTab={onRedirectPrevTab}
            onRedirectNextTab={onRedirectNextTab}
            isTabCompleted={isTabCompleted}
          />
        </div>
      );
    }
    case DELIVERY: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewDelivery'
        : 'EditListingWizard.saveEditDelivery';
      if (listingType === LISTING_TYPES.PRODUCT && isFirstRender) {
        isFirstRender = false;
        onCompleteEditListingWizardTab('availability', availabilityPlan, true);
      }
      return (
        <div className={css.panelNavWrapper}>
          <EditListingDeliveryPanel
            {...panelProps(DELIVERY)}
            submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
            onSubmit={values => {
              onCompleteEditListingWizardTab(tab, values);
            }}
          />
          <Form
            marketplaceTabs={marketplaceTabs}
            tab={tab}
            onRedirectPrevTab={onRedirectPrevTab}
            onRedirectNextTab={onRedirectNextTab}
            isTabCompleted={isTabCompleted}
          />
        </div>
      );
    }
    case FEATURES: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewFeatures'
        : 'EditListingWizard.saveEditFeatures';
      return (
        <div className={css.panelNavWrapper}>
          <EditListingFeaturesPanel
            {...panelProps(FEATURES)}
            submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
            onSubmit={values => {
              onCompleteEditListingWizardTab(tab, values);
            }}
          />
          <Form
            marketplaceTabs={marketplaceTabs}
            tab={tab}
            onRedirectPrevTab={onRedirectPrevTab}
            onRedirectNextTab={onRedirectNextTab}
            isTabCompleted={isTabCompleted}
          />
        </div>
      );
    }
    case POLICY: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewPolicies'
        : 'EditListingWizard.saveEditPolicies';
      return (
        <div className={css.panelNavWrapper}>
          <EditListingPoliciesPanel
            {...panelProps(POLICY)}
            submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
            onSubmit={values => {
              onCompleteEditListingWizardTab(tab, values);
            }}
          />
          <Form
            marketplaceTabs={marketplaceTabs}
            tab={tab}
            onRedirectPrevTab={onRedirectPrevTab}
            onRedirectNextTab={onRedirectNextTab}
            isTabCompleted={isTabCompleted}
          />
        </div>
      );
    }
    case LOCATION: {
      const submitNewLabel = {
        [LISTING_TYPES.LISTING]: 'EditListingWizard.saveNewLocationListing',
        [LISTING_TYPES.COMPANY]: 'EditListingWizard.saveNextIntroductory',
        [LISTING_TYPES.FREELANCER]: 'EditListingWizard.saveNextIntroductory',
        [LISTING_TYPES.EVENT]: 'EditListingWizard.saveNewLocationListing',
        [LISTING_TYPES.ENQUIRY]: 'EditListingWizard.saveNewLocationEnquiry',
        [LISTING_TYPES.CLASS]: 'EditListingWizard.saveNewLocationListing',
        [LISTING_TYPES.FACILITY]: 'EditListingWizard.saveNewLocationListing',
        [LISTING_TYPES.PRODUCT]: 'EditListingWizard.saveNewLocationListing',
        [LISTING_TYPES.MEMBERSHIP]: 'EditListingWizard.saveNewLocationListing',
        [LISTING_TYPES.ACADEMY]: 'EditListingWizard.saveNextIntroductory',
        [LISTING_TYPES.REMOTE_TUTOR]: 'EditListingWizard.saveNextIntroductory',
        [LISTING_TYPES.COURSES]: 'EditListingWizard.saveNewLocationListing',
        [LISTING_TYPES.EDUCATION_COURSES]: 'EditListingWizard.saveNewLocationListing',
        [LISTING_TYPES.HOURLY_SESSION]: 'EditListingWizard.saveNewLocationListing',
        [LISTING_TYPES.TUTOR]: 'EditListingWizard.saveNextIntroductory',
        [LISTING_TYPES.PLAYER_PROFILE]: 'EditListingWizard.saveNextIntroductory',
      };
      const submitButtonTranslationKey = isNewListingFlow
        ? submitNewLabel[listingType]
        : 'EditListingWizard.saveEditLocation';
      return (
        <div className={css.panelNavWrapper}>
          <EditListingLocationPanel
            {...panelProps(LOCATION)}
            submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
            onSubmit={values => {
              onCompleteEditListingWizardTab(tab, values);
            }}
          />
          <Form
            marketplaceTabs={marketplaceTabs}
            tab={tab}
            onRedirectPrevTab={onRedirectPrevTab}
            onRedirectNextTab={onRedirectNextTab}
            isTabCompleted={isTabCompleted}
          />
        </div>
      );
    }
    case PRICING: {
      const submitNewLabel = {
        [LISTING_TYPES.LISTING]: 'EditListingWizard.saveNewPricingListing',
        [LISTING_TYPES.COMPANY]: 'EditListingWizard.saveNewLocationCompany',
        [LISTING_TYPES.FREELANCER]: 'EditListingWizard.saveNewLocationCompany',
        [LISTING_TYPES.EVENT]: 'EditListingWizard.saveNewPricingListing',
        [LISTING_TYPES.ENQUIRY]: 'EditListingWizard.saveNewLocationEnquiry',
        [LISTING_TYPES.CLASS]: 'EditListingWizard.saveNewPricingClass',
        [LISTING_TYPES.FACILITY]: 'EditListingWizard.saveNewPricingListing',
        [LISTING_TYPES.PRODUCT]: 'EditListingWizard.saveNewPricingListing',
        [LISTING_TYPES.MEMBERSHIP]: 'EditListingWizard.saveNewPricingListing',
        [LISTING_TYPES.ACADEMY]: 'EditListingWizard.saveNewPricingListing',
        [LISTING_TYPES.REMOTE_TUTOR]: 'EditListingWizard.saveNewPricingListing',
        [LISTING_TYPES.COURSES]: 'EditListingWizard.saveNewPricingListing',
        [LISTING_TYPES.EDUCATION_COURSES]: 'EditListingWizard.saveNewPricingListing',
        [LISTING_TYPES.HOURLY_SESSION]: 'EditListingWizard.saveNewPricingListing',
        [LISTING_TYPES.TUTOR]: 'EditListingWizard.saveNewPricingListing',
        [LISTING_TYPES.PLAYER_PROFILE]: 'EditListingWizard.saveNewPricingListing',
      };
      const submitButtonTranslationKey = isNewListingFlow
        ? submitNewLabel[listingType]
        : 'EditListingWizard.saveEditPricing';
      return (
        <div className={css.panelNavWrapper}>
          <EditListingPricingPanel
            {...panelProps(PRICING)}
            submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
            onSubmit={values => {
              onCompleteEditListingWizardTab(tab, values);
            }}
          />
          <Form
            marketplaceTabs={marketplaceTabs}
            tab={tab}
            onRedirectPrevTab={onRedirectPrevTab}
            onRedirectNextTab={onRedirectNextTab}
            isTabCompleted={isTabCompleted}
          />
        </div>
      );
    }
    case CERTIFICATES: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewCertificates'
        : 'EditListingWizard.saveEditCertificates';
      return (
        <div className={css.panelNavWrapper}>
          <EditListingCertificatesPanel
            {...panelProps(CERTIFICATES)}
            submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
            onSubmit={values => {
              const normalizedValues = values.certificates
                ? values.certificates.filter(item => !!item)
                : [];
              onCompleteEditListingWizardTab(tab, {
                publicData: {
                  certificates: normalizedValues,
                },
              });
            }}
          />
          <Form
            marketplaceTabs={marketplaceTabs}
            tab={tab}
            onRedirectPrevTab={onRedirectPrevTab}
            onRedirectNextTab={onRedirectNextTab}
            isTabCompleted={isTabCompleted}
          />
        </div>
      );
    }
    case FAQ: {
      const submitNewLabel = {
        [LISTING_TYPES.ACADEMY]: 'EditListingWizard.saveNextEducation',
        [LISTING_TYPES.REMOTE_TUTOR]: 'EditListingWizard.saveNextEducation',
        [LISTING_TYPES.TUTOR]: 'EditListingWizard.saveNextEducation',
        [LISTING_TYPES.COMPANY]: 'EditListingWizard.saveNewFAQ',
        [LISTING_TYPES.FREELANCER]: 'EditListingWizard.saveNewFAQ',
        [LISTING_TYPES.PLAYER_PROFILE]: 'EditListingWizard.saveNewFAQ',
      };
      const submitButtonTranslationKey = isNewListingFlow
        ? submitNewLabel[listingType]
        : 'EditListingWizard.saveEditFAQ';
      return (
        <div className={css.panelNavWrapper}>
          <EditListingFAQPanel
            {...panelProps(FAQ)}
            submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
            onSubmit={values => {
              onCompleteEditListingWizardTab(tab, {
                publicData: {
                  ...values,
                },
              });
            }}
          />
          <Form
            marketplaceTabs={marketplaceTabs}
            tab={tab}
            onRedirectPrevTab={onRedirectPrevTab}
            onRedirectNextTab={onRedirectNextTab}
            isTabCompleted={isTabCompleted}
          />
        </div>
      );
    }
    case EDUCATION: {
      const submitNewLabel = {
        [LISTING_TYPES.ACADEMY]: 'EditListingWizard.saveNewFAQ',
        [LISTING_TYPES.REMOTE_TUTOR]: 'EditListingWizard.saveNewFAQ',
        [LISTING_TYPES.COURSES]: 'EditListingWizard.saveNewPricingListing',
        [LISTING_TYPES.EDUCATION_COURSES]: 'EditListingWizard.saveNewPricingListing',
        [LISTING_TYPES.HOURLY_SESSION]: 'EditListingWizard.saveNewPricingListing',
        [LISTING_TYPES.TUTOR]: 'EditListingWizard.saveNewFAQ',
        [LISTING_TYPES.PLAYER_PROFILE]: 'EditListingWizard.saveNewBlogs',
      };
      const submitButtonTranslationKey = isNewListingFlow
        ? submitNewLabel[listingType]
        : 'EditListingWizard.saveEditEducation';
      return (
        <div className={css.panelNavWrapper}>
          <EditListingEducationPanel
            {...panelProps(EDUCATION)}
            submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
            onSubmit={values => {
              onCompleteEditListingWizardTab(tab, {
                publicData: {
                  ...values,
                },
              });
            }}
          />
          <Form
            marketplaceTabs={marketplaceTabs}
            tab={tab}
            onRedirectPrevTab={onRedirectPrevTab}
            onRedirectNextTab={onRedirectNextTab}
            isTabCompleted={isTabCompleted}
          />
        </div>
      );
    }
    case BLOGS: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewBlogs'
        : 'EditListingWizard.saveEditBlogs';
      return (
        <div className={css.panelNavWrapper}>
          <EditListingBlogsPanel
            {...panelProps(BLOGS)}
            submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
            onSubmit={values => {
              onCompleteEditListingWizardTab(tab, {
                publicData: {
                  ...values,
                },
              });
            }}
          />
          <Form
            marketplaceTabs={marketplaceTabs}
            tab={tab}
            onRedirectPrevTab={onRedirectPrevTab}
            onRedirectNextTab={onRedirectNextTab}
            isTabCompleted={isTabCompleted}
          />
        </div>
      );
    }
    case EXPECTATIONS: {
      const submitNewLabel = {
        [LISTING_TYPES.LISTING]: 'EditListingWizard.saveNewExpectationsListing',
        [LISTING_TYPES.COMPANY]: 'EditListingWizard.saveNewExpectationsServices',
        [LISTING_TYPES.FREELANCER]: 'EditListingWizard.saveNewExpectationsServices',
        [LISTING_TYPES.FACILITY]: 'EditListingWizard.saveNewExpectationsListing',
        [LISTING_TYPES.PRODUCT]: 'EditListingWizard.saveNewExpectationsListing',
        [LISTING_TYPES.MEMBERSHIP]: 'EditListingWizard.saveNewExpectationsListing',
        [LISTING_TYPES.CLASS]: 'EditListingWizard.saveNewExpectationsClass',
        [LISTING_TYPES.EVENT]: 'EditListingWizard.saveNewExpectationsClass',
        [LISTING_TYPES.ACADEMY]: 'EditListingWizard.saveNewExpectationsCompany',
        [LISTING_TYPES.REMOTE_TUTOR]: 'EditListingWizard.saveNewExpectationsCompany',
        [LISTING_TYPES.COURSES]: 'EditListingWizard.saveNewExpectationsListing',
        [LISTING_TYPES.EDUCATION_COURSES]: 'EditListingWizard.saveNewDelivery',
        [LISTING_TYPES.HOURLY_SESSION]: 'EditListingWizard.saveNewExpectationsListing',
        [LISTING_TYPES.TUTOR]: 'EditListingWizard.saveNewExpectationsCompany',
        [LISTING_TYPES.PLAYER_PROFILE]: 'EditListingWizard.saveNewExpectationsCompany',
      };
      const submitButtonTranslationKey = isNewListingFlow
        ? submitNewLabel[listingType]
        : 'EditListingWizard.saveEditExpectations';
      return (
        <div className={css.panelExpectNavWrapper}>
          <EditListingExpectationsPanel
            {...panelProps(EXPECTATIONS)}
            submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
            onSubmit={values => {
              onCompleteEditListingWizardTab(tab, {
                publicData: {
                  ...values,
                },
              });
            }}
          />

          {/* <div className={css.expectationsRight}>
            <h3 className={css.expectationsRightHead}>Your Expectations</h3>
            <div className={css.expectationsRightCard}>
              <div>
                <img src={GettyImages} alt="GettyImages" />
                <span className={css.expectationsCardText}>Lorem Ipsum is simply dummy text.</span>
              </div>

              <div className={css.expectationsCardDelete}>
                <svg
                  width="20"
                  height="26"
                  viewBox="0 0 20 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.50016 22.9167C1.50016 24.4821 2.76808 25.75 4.3335 25.75H15.6668C17.2322 25.75 18.5002 24.4821 18.5002 22.9167V5.91667H1.50016V22.9167ZM19.9168 1.66667H14.9585L13.5418 0.25H6.4585L5.04183 1.66667H0.0834961V4.5H19.9168V1.66667Z"
                    fill="white"
                  />
                </svg>
              </div>
            </div>
          </div> */}
          <Form
            marketplaceTabs={marketplaceTabs}
            tab={tab}
            onRedirectPrevTab={onRedirectPrevTab}
            onRedirectNextTab={onRedirectNextTab}
            isTabCompleted={isTabCompleted}
          />
        </div>
      );
    }
    case SERVICES: {
      const submitNewLabel = {
        [LISTING_TYPES.COMPANY]: 'EditListingWizard.saveNewExpectationsCompany',
        [LISTING_TYPES.FREELANCER]: 'EditListingWizard.saveNewExpectationsCompany',
      };
      const submitButtonTranslationKey = isNewListingFlow
        ? submitNewLabel[listingType]
        : 'EditListingWizard.saveEditServices';
      return (
        <div className={css.panelExpectNavWrapper}>
          <EditListingServicesPanel
            {...panelProps(SERVICES)}
            submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
            onSubmit={values => {
              onCompleteEditListingWizardTab(tab, {
                publicData: {
                  ...values,
                },
              });
            }}
          />

          {/* <div className={css.expectationsRight}>
            <h3 className={css.expectationsRightHead}>Your Expectations</h3>
            <div className={css.expectationsRightCard}>
              <div>
                <img src={GettyImages} alt="GettyImages" />
                <span className={css.expectationsCardText}>Lorem Ipsum is simply dummy text.</span>
              </div>

              <div className={css.expectationsCardDelete}>
                <svg
                  width="20"
                  height="26"
                  viewBox="0 0 20 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.50016 22.9167C1.50016 24.4821 2.76808 25.75 4.3335 25.75H15.6668C17.2322 25.75 18.5002 24.4821 18.5002 22.9167V5.91667H1.50016V22.9167ZM19.9168 1.66667H14.9585L13.5418 0.25H6.4585L5.04183 1.66667H0.0834961V4.5H19.9168V1.66667Z"
                    fill="white"
                  />
                </svg>
              </div>
            </div>
          </div> */}
          <Form
            marketplaceTabs={marketplaceTabs}
            tab={tab}
            onRedirectPrevTab={onRedirectPrevTab}
            onRedirectNextTab={onRedirectNextTab}
            isTabCompleted={isTabCompleted}
          />
        </div>
      );
    }
    case SOCIALS: {
      const submitNewLabel = {
        [LISTING_TYPES.COMPANY]: 'EditListingWizard.saveNewSocialsCompany',
        [LISTING_TYPES.FREELANCER]: 'EditListingWizard.saveNewSocialsCompany',
      };
      const submitButtonTranslationKey = isNewListingFlow
        ? submitNewLabel[listingType]
        : 'EditListingWizard.saveEditSocialLinks';
      return (
        <div className={css.panelExpectNavWrapper}>
          <EditListingSocialLinksPanel
            {...panelProps(SOCIALS)}
            submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
            onSubmit={values => {
              onCompleteEditListingWizardTab(tab, {
                publicData: {
                  ...values,
                },
              });
            }}
          />

          {/* <div className={css.expectationsRight}>
            <h3 className={css.expectationsRightHead}>Your Expectations</h3>
            <div className={css.expectationsRightCard}>
              <div>
                <img src={GettyImages} alt="GettyImages" />
                <span className={css.expectationsCardText}>Lorem Ipsum is simply dummy text.</span>
              </div>

              <div className={css.expectationsCardDelete}>
                <svg
                  width="20"
                  height="26"
                  viewBox="0 0 20 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.50016 22.9167C1.50016 24.4821 2.76808 25.75 4.3335 25.75H15.6668C17.2322 25.75 18.5002 24.4821 18.5002 22.9167V5.91667H1.50016V22.9167ZM19.9168 1.66667H14.9585L13.5418 0.25H6.4585L5.04183 1.66667H0.0834961V4.5H19.9168V1.66667Z"
                    fill="white"
                  />
                </svg>
              </div>
            </div>
          </div> */}
          <Form
            marketplaceTabs={marketplaceTabs}
            tab={tab}
            onRedirectPrevTab={onRedirectPrevTab}
            onRedirectNextTab={onRedirectNextTab}
            isTabCompleted={isTabCompleted}
          />
        </div>
      );
    }
    case INTODUCTORY: {
      const submitNewLabel = {
        [LISTING_TYPES.ACADEMY]: 'EditListingWizard.saveNextEducation',
        [LISTING_TYPES.REMOTE_TUTOR]: 'EditListingWizard.saveNextEducation',
        [LISTING_TYPES.TUTOR]: 'EditListingWizard.saveNextEducation',
        [LISTING_TYPES.PLAYER_PROFILE]: 'EditListingWizard.saveNewBlogs',
        [LISTING_TYPES.COMPANY]: 'EditListingWizard.saveNewLocationCompany',
        [LISTING_TYPES.FREELANCER]: 'EditListingWizard.saveNewLocationCompany',
      };
      const submitButtonTranslationKey = isNewListingFlow
        ? submitNewLabel[listingType]
        : 'EditListingWizard.saveEditIntroductory';
      return (
        <div className={css.panelExpectNavWrapper}>
          <EditListingIntroductoryPanel
            {...panelProps(INTODUCTORY)}
            submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
            onSubmit={values => {
              onCompleteEditListingWizardTab(tab, {
                publicData: {
                  ...values,
                },
              });
            }}
          />
        </div>
      );
    }
    case AVAILABILITY: {
      const submitNewLabel = {
        [LISTING_TYPES.LISTING]: 'EditListingWizard.saveNewAvailability',
        [LISTING_TYPES.ENQUIRY]: 'EditListingWizard.saveNewAvailabilityInquire',
        [LISTING_TYPES.CLASS]: 'EditListingWizard.saveNewAvailability',
        [LISTING_TYPES.FREELANCER]: 'EditListingWizard.saveNewAvailability',
        [LISTING_TYPES.EVENT]: 'EditListingWizard.saveNewAvailability',
        [LISTING_TYPES.FACILITY]: 'EditListingWizard.saveNewAvailability',
        [LISTING_TYPES.PRODUCT]: 'EditListingWizard.saveNewAvailability',
        [LISTING_TYPES.MEMBERSHIP]: 'EditListingWizard.saveNewAvailability',
        [LISTING_TYPES.ACADEMY]: 'EditListingWizard.saveNewAvailability',
        [LISTING_TYPES.REMOTE_TUTOR]: 'EditListingWizard.saveNewAvailability',
        [LISTING_TYPES.COURSES]: 'EditListingWizard.saveNewAvailability',
        [LISTING_TYPES.EDUCATION_COURSES]: 'EditListingWizard.saveNewAvailability',
        [LISTING_TYPES.HOURLY_SESSION]: 'EditListingWizard.saveNewAvailability',
        [LISTING_TYPES.TUTOR]: 'EditListingWizard.saveNewAvailability',
        [LISTING_TYPES.PLAYER_PROFILE]: 'EditListingWizard.saveNewAvailability',
      };
      const submitButtonTranslationKey = isNewListingFlow
        ? submitNewLabel[listingType]
        : 'EditListingWizard.saveEditAvailability';

      return (
        <div className={css.panelNavWrapper}>
          <EditListingAvailabilityPanel
            {...panelProps(AVAILABILITY)}
            fetchExceptionsInProgress={fetchExceptionsInProgress}
            availabilityExceptions={availabilityExceptions}
            submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
            onAddAvailabilityException={onAddAvailabilityException}
            onDeleteAvailabilityException={onDeleteAvailabilityException}
            onSubmit={values => {
              // We want to return the Promise to the form,
              // so that it doesn't close its modal if an error is thrown.
              return onCompleteEditListingWizardTab(tab, values, true);
            }}
            onNextTab={() =>
              redirectAfterDraftUpdate(
                listing.id.uuid,
                listingType,
                params,
                tab,
                marketplaceTabs,
                history
              )
            }
          />
          <Form
            marketplaceTabs={marketplaceTabs}
            tab={tab}
            onRedirectPrevTab={onRedirectPrevTab}
            onRedirectNextTab={onRedirectNextTab}
            isTabCompleted={isTabCompleted}
          />
        </div>
      );
    }
    case PHOTOS: {
      const submitNewLabel = {
        [LISTING_TYPES.LISTING]: 'EditListingWizard.saveNewPhotos',
        [LISTING_TYPES.COMPANY]: 'EditListingWizard.saveNewPhotosCompany',
        [LISTING_TYPES.FREELANCER]: 'EditListingWizard.saveNewPhotosCompany',
        [LISTING_TYPES.CLASS]: 'EditListingWizard.saveNewPhotosClass',
        [LISTING_TYPES.FACILITY]: 'EditListingWizard.saveNewPhotos',
        [LISTING_TYPES.EVENT]: 'EditListingWizard.saveNewPhotos',
        [LISTING_TYPES.PRODUCT]: 'EditListingWizard.saveNewPhotos',
        [LISTING_TYPES.MEMBERSHIP]: 'EditListingWizard.saveNewPhotos',
        [LISTING_TYPES.ACADEMY]: 'EditListingWizard.saveNewPhotos',
        [LISTING_TYPES.REMOTE_TUTOR]: 'EditListingWizard.saveNewPhotos',
        [LISTING_TYPES.COURSES]: 'EditListingWizard.saveNewPhotos',
        [LISTING_TYPES.EDUCATION_COURSES]: 'EditListingWizard.saveNewPhotos',
        [LISTING_TYPES.HOURLY_SESSION]: 'EditListingWizard.saveNewPhotos',
        [LISTING_TYPES.TUTOR]: 'EditListingWizard.saveNewPhotos',
        [LISTING_TYPES.PLAYER_PROFILE]: 'EditListingWizard.saveNewPhotos',
      };
      const submitButtonTranslationKey = isNewListingFlow
        ? submitNewLabel[listingType]
        : 'EditListingWizard.saveEditPhotos';

      return (
        <div className={css.panelNavWrapper}>
          <EditListingPhotosPanel
            {...panelProps(PHOTOS)}
            submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
            images={images}
            onImageUpload={onImageUpload}
            onRemoveImage={onRemoveImage}
            onSubmit={values => {
              onCompleteEditListingWizardTab(tab, values);
            }}
            currentUser={currentUser}
            onUpdateImageOrder={onUpdateImageOrder}
          />
          <Form
            marketplaceTabs={marketplaceTabs}
            tab={tab}
            onRedirectPrevTab={onRedirectPrevTab}
            onRedirectNextTab={onRedirectNextTab}
            isTabCompleted={isTabCompleted}
          />
        </div>
      );
    }
    default:
      return null;
  }
};

EditListingWizardTab.defaultProps = {
  listing: null,
  updatedTab: null,
  availabilityExceptions: [],
};

EditListingWizardTab.propTypes = {
  params: shape({
    id: string.isRequired,
    slug: string.isRequired,
    type: oneOf(LISTING_PAGE_PARAM_TYPES).isRequired,
    tab: oneOf(SUPPORTED_TABS).isRequired,
  }).isRequired,
  availabilityExceptions: arrayOf(propTypes.availabilityException),
  errors: shape({
    createListingDraftError: object,
    publishListingError: object,
    updateListingError: object,
    showListingsError: object,
    uploadImageError: object,
    fetchExceptionsError: object,
    addExceptionError: object,
    deleteExceptionError: object,
  }).isRequired,
  fetchInProgress: bool.isRequired,
  fetchExceptionsInProgress: bool.isRequired,
  newListingPublished: bool.isRequired,
  history: shape({
    push: func.isRequired,
    replace: func.isRequired,
  }).isRequired,
  images: array.isRequired,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: shape({
    attributes: shape({
      publicData: object,
      description: string,
      geolocation: object,
      pricing: object,
      title: string,
    }),
    images: array,
  }),

  handleCreateFlowTabScrolling: func.isRequired,
  handlePublishListing: func.isRequired,
  onAddAvailabilityException: func.isRequired,
  onDeleteAvailabilityException: func.isRequired,
  onUpdateListing: func.isRequired,
  onCreateListingDraft: func.isRequired,
  onImageUpload: func.isRequired,
  onUpdateImageOrder: func.isRequired,
  onRemoveImage: func.isRequired,
  onChange: func.isRequired,
  updatedTab: string,
  updateInProgress: bool.isRequired,
  listingType: string.isRequired,

  intl: intlShape.isRequired,
};

export default EditListingWizardTab;
