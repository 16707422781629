import { createTokens } from '../../util/api';
import { fetchCurrentUser } from '../../ducks/user.duck';

// ================ Action types ================ //

export const SAVE_SETTINGS_REQUEST = 'app/GoogleCalendarSettingsPage/SAVE_SETTINGS_REQUEST';
export const SAVE_SETTINGS_SUCCESS = 'app/GoogleCalendarSettingsPage/SAVE_SETTINGS_SUCCESS';
export const SAVE_SETTINGS_ERROR = 'app/GoogleCalendarSettingsPage/SAVE_SETTINGS_ERROR';

// ================ Reducer ================ //

const initialState = {
  saveSettingsError: null,
  saveSettingsInProgress: false,
  updated: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SAVE_SETTINGS_REQUEST:
      return {
        ...state,
        saveSettingsInProgress: true,
        saveSettingsError: null,
        updated: false,
      };
    case SAVE_SETTINGS_SUCCESS:
      return { ...state, saveSettingsInProgress: false, updated: true };
    case SAVE_SETTINGS_ERROR:
      return {
        ...state,
        saveSettingsInProgress: false,
        saveSettingsError: payload,
        updated: false,
      };
    default:
      return state;
  }
}

// ================ Action creators ================ //

export const saveSettingsRequest = () => ({ type: SAVE_SETTINGS_REQUEST });
export const saveSettingsSuccess = () => ({ type: SAVE_SETTINGS_SUCCESS });
export const saveSettingsError = error => ({
  type: SAVE_SETTINGS_ERROR,
  payload: error,
  error: true,
});

// ================ Thunks ================ //
/**
 * Update GoogleCalendar settings
 */
export const saveSettings = params => (dispatch, getState, sdk) => {
  dispatch(saveSettingsRequest());
  const handleSuccess = response => {
    return dispatch(fetchCurrentUser({}))
      .then(response => {
        dispatch(saveSettingsSuccess());
        return response;
      })
      .catch(e => {
        throw e;
      });
  };
  const handleError = e => {
    dispatch(saveSettingsError(e.error));
  };
  return createTokens({ code: params })
    .then(handleSuccess)
    .catch(handleError);
};

export const loadData = params => (dispatch, getState) => {
  return Promise.all([dispatch(fetchCurrentUser({}))]);
};
