import React, { useState, useEffect } from 'react';
import moment from 'moment';
import cns from 'classnames';
import css from './GroupRow.css';
import { orderBy } from 'lodash';
import { InboxItemWithButtons, IconArrowDown } from 'components';
import Collapsible from 'react-collapsible';
import {
  TRANSITION_CANCEL,
  TRANSITION_CANCEL_READY_TO_COMPLETE,
  txIsReadyToComplete,
} from 'util/transaction';
import { txState } from 'components/InboxItem/helpers';

function GroupRow(props) {
  const {
    entries,
    unitType,
    type,
    state,
    intl,
    onComplete,
    onCancel,
    onDecline,
    onAccept,
    onManageDisableScrolling,
    onSelect,
    selectedBookings,
    onBookAgain,
    rescheduleBooking,
    rescheduleRequestInProgress,
    rescheduleError,
    rescheduledsuccessfully,
    currentUser,
    onFetchTimeSlots,
    onFetchMonthlyTimeSlots,
    monthlyTimeSlots,
    isTimeSlotesLoading,
    index,
    sortBy,
    groupBy,
  } = props;

  const [selectedGroupBookings, setSelectedGroupBookings] = useState([]);
  const [isCollapsed, setCollapsed] = useState(false);
  const [isAllSelect, setIsAllSelect] = useState(false);

  const { start, displayStart } =
    entries && entries[0]?.booking ? entries[0]?.booking?.attributes : {};
  const { title } = entries && entries[0].listing ? entries[0].listing.attributes : {};
  const bookingStart = displayStart || start;

  const allGroupSelectHandler = () => {
    entries.map(tx => onSelect(tx));
    setIsAllSelect(!isAllSelect);
    const areAllSelected = selectedGroupBookings.length === entries.length;
    setSelectedGroupBookings(areAllSelected ? [] : entries);
  };

  const onSelectHandeler = tx => {
    if (selectedGroupBookings.includes(tx)) {
      setSelectedGroupBookings([]);
      setIsAllSelect(false);
    }

    onSelect(tx);
  };

  useEffect(() => {
    const data = entries && entries.filter(txItem => selectedBookings.includes(txItem) === false);
    setIsAllSelect(data.length > 0 ? false : true);
    // eslint-disable-next-line
  }, [isAllSelect, selectedBookings]);

  const headContent = (
    <>
      <div className={cns(css.weekdayRow, onSelect && css.weekdayRowWithPadding)}>
        <div className={css.weekdayName}>
          {/* {onSelect && (
            <div className={css.checkboxField}>
              <input
                onChange={allGroupSelectHandler}
                className={css.checkboxInput}
                checked={isAllSelect}
                type="checkbox"
                id={`WeekDayCheckbox_${index}`}
              />
              <label htmlFor={`WeekDayCheckbox_${index}`}></label>
            </div>
          )} */}
          <span className={css.bookingNumber}>
            bookings <span>-</span> {moment(bookingStart).format('dddd DD MMM')}{' '}
          </span>
        </div>
      </div>
    </>
  );

  const trigger = (
    <div className={css.collapsibleHeadRow}>
      {onSelect && (
        <div className={css.checkboxField}>
          <input
            onChange={allGroupSelectHandler}
            className={css.checkboxInput}
            checked={isAllSelect}
            type="checkbox"
            id={`WeekDayCheckbox_${index}`}
          />
          <label htmlFor={`WeekDayCheckbox_${index}`}></label>
        </div>
      )}
      <div
        className={css.collapsibleRowWithPadding}
        onClick={() => setCollapsed(isCollapsed => !isCollapsed)}
      >
        <IconArrowDown
          className={cns(
            css.collapseIcon,
            css.collapseIconMultiBooking,
            isCollapsed ? css.collapsed : ''
          )}
        />{' '}
        <span className={css.bookingNumber}>
          bookings <span>-</span>{' '}
          {groupBy === 'booking-date' ? moment(bookingStart).format('dddd DD MMM') : title}{' '}
        </span>
      </div>
    </div>
  );

  let stateData = txState(intl, entries[0], type);
  const InboxItemContain = tx => {
    stateData = txState(intl, tx, type);
    return stateData ? (
      <li
        key={tx.id.uuid}
        className={cns(css.listItem, state === 'ready-to-complete' && css.collapsibleListItem)}
      >
        <InboxItemWithButtons
          unitType={unitType}
          type={type}
          tx={tx}
          intl={intl}
          stateData={stateData}
          onComplete={onComplete}
          onCancel={
            txIsReadyToComplete(tx)
              ? onCancel(TRANSITION_CANCEL_READY_TO_COMPLETE)
              : onCancel(TRANSITION_CANCEL)
          }
          onDecline={onDecline}
          onAccept={onAccept}
          onManageDisableScrolling={onManageDisableScrolling}
          onSelect={onSelect && onSelectHandeler}
          isSelected={
            onSelect && selectedBookings.some(selectedTx => selectedTx.id.uuid === tx.id.uuid)
          }
          onBookAgain={onBookAgain}
          rescheduleBooking={rescheduleBooking}
          rescheduleRequestInProgress={rescheduleRequestInProgress}
          rescheduleError={rescheduleError}
          rescheduledsuccessfully={rescheduledsuccessfully}
          currentUser={currentUser}
          onFetchTimeSlots={onFetchTimeSlots}
          onFetchMonthlyTimeSlots={onFetchMonthlyTimeSlots}
          monthlyTimeSlots={monthlyTimeSlots}
          isTimeSlotesLoading={isTimeSlotesLoading}
          isReadyToCompleteState={state === 'ready-to-complete'}
        />
      </li>
    ) : null;
  };

  let sortedEntries = entries;
  if ((sortBy === 'desc' || sortBy === 'asc') && groupBy === 'booking-date') {
    sortedEntries = orderBy(
      entries,
      [
        entry =>
          entry.listing.attributes.title !== null
            ? entry.listing.attributes.title.toLowerCase()
            : null,
      ],
      sortBy
    );
  }

  if (state === 'ready-to-complete') {
    return (
      <div className={css.collapsibleWeekday}>
        <Collapsible trigger={trigger} triggerDisabled={true} open={!isCollapsed}>
          {sortedEntries &&
            sortedEntries.map(tx => {
              return InboxItemContain(tx);
            })}
        </Collapsible>
      </div>
    );
  } else {
    return (
      <div className={css.weekday}>
        {headContent}
        <div className={css.contentInnerWrapper}>
          {entries &&
            entries.map(tx => {
              return InboxItemContain(tx);
            })}
        </div>
      </div>
    );
  }
}

export default GroupRow;
