import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconGlobe.css';

const IconGlobe = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg className={classes} xmlns="http://www.w3.org/2000/svg" width="68" height="68" viewBox="0 0 68 68" fill="none">
      <path d="M62.24 15.0823L61.2227 15.4048L55.8049 15.8875L54.2746 18.3312L53.1642 17.9784L48.8525 14.091L48.227 12.0693L47.3893 9.91347L44.6794 7.48273L41.4824 6.85719L41.4088 8.3204L44.5408 11.3767L46.0733 13.1819L44.3504 14.0823L42.9478 13.6689L40.846 12.7923L40.9175 11.0975L38.1599 9.96325L37.2443 13.9481L34.4651 14.578L34.74 16.8009L38.3612 17.4979L38.9867 13.9459L41.9759 14.3875L43.3655 15.2013H45.595L47.1209 18.2576L51.1664 22.3615L50.8698 23.9568L47.6079 23.5412L41.9716 26.3875L37.9131 31.2555L37.385 33.4113H35.9283L33.214 32.1602L30.5776 33.4113L31.2335 36.1927L32.3807 34.8702L34.398 34.8074L34.2573 37.3052L35.9283 37.7944L37.5971 39.6689L40.3222 38.9026L43.4348 39.394L47.0495 40.3658L48.8547 40.578L51.9153 44.052L57.8222 47.526L54.0019 54.8247L49.9694 56.6992L48.4391 60.8702L42.6036 64.7663L41.9824 67.013C56.9001 63.42 67.9975 50.0195 67.9975 33.9979C67.9932 27 65.872 20.4871 62.24 15.0823Z" fill="#0095B3" />
      <path d="M37.9109 51.7684L35.4347 47.1775L37.7074 42.4415L35.4347 41.7619L32.8828 39.1991L27.2291 37.9307L25.3525 34.0043V36.3355H24.5256L19.6533 29.7294V24.303L16.0819 18.4956L10.4109 19.5065H6.59055L4.66847 18.2467L7.12085 16.303L4.67497 16.8679C1.72692 21.9026 0.00830078 27.7446 0.00830078 34.0021C0.00830078 52.7749 15.2269 68 34.0018 68C35.4477 68 36.8654 67.8723 38.268 67.7034L37.9131 63.5844C37.9131 63.5844 39.4737 57.4675 39.4737 57.2597C39.4715 57.0498 37.9109 51.7684 37.9109 51.7684Z" fill="#0095B3" />
      <path d="M12.6403 10.9632L18.6793 10.1212L21.4629 8.59524L24.5949 9.49784L29.5992 9.22078L31.3135 6.52597L33.8135 6.93723L39.8849 6.36797L41.5581 4.52381L43.9174 2.94805L47.2551 3.45022L48.4715 3.26623C44.0754 1.19697 39.1836 0 33.9997 0C23.4477 0 14.0126 4.80952 7.7832 12.3615H7.80052L12.6403 10.9632ZM35.4369 3.38095L38.9087 1.4697L41.1382 2.75758L37.9109 5.21429L34.8287 5.52381L33.4412 4.62338L35.4369 3.38095ZM25.1533 3.66017L26.6858 4.2987L28.6923 3.66017L29.7854 5.55411L25.1533 6.77056L22.9261 5.46753C22.9239 5.46753 25.1036 4.06494 25.1533 3.66017Z" fill="#0095B3" />
    </svg>
  );
};

IconGlobe.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconGlobe.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconGlobe;
