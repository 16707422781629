import React, { useCallback, useEffect, useState } from 'react';
import css from './EnquiryCard.css';
import { Avatar, Modal, NamedRedirect } from '../../../components';
import { createResourceLocatorString } from '../../../util/routes';
import routeConfiguration from '../../../routeConfiguration';
import { EnquiryForm as Enquiry } from '../../../forms';
import { types as sdkTypes } from '../../../util/sdkLoader';
import { formatMoney } from '../../../util/currency';
import { defaultListingCurrency } from '../../../util/types';
import { FormattedMessage } from 'react-intl';

import classNames from 'classnames';
import moment from 'moment';

const { UUID } = sdkTypes;
function EnquiryCard(props) {
  const {
    enquiryListings,
    rootClassName,
    showDate,
    searchInput,
    currentUser,
    isAuthenticated,
    onSendEnquiry,
    sendEnquiryError,
    sendEnquiryInProgress,
    intl,
    history,
    isEducationSite,
  } = props;
  const classes = classNames(rootClassName || css.rootEnqiryCard);
  const [enquiryData, setEnquiryData] = useState(enquiryListings);
  const [isRedirect, setIsRedirect] = useState(false);

  const searchLocation = useCallback(
    searchLocation => {
      const filterData = searchLocation
        ? enquiryListings.filter(
            data => data.attributes.publicData.location.address === searchLocation
          )
        : enquiryListings;
      filterData.length === 0 ? setEnquiryData(enquiryListings) : setEnquiryData(filterData);
    },
    [enquiryListings]
  );

  useEffect(() => {
    searchInput && searchLocation(searchInput);
    !searchInput && setEnquiryData(enquiryListings);
  }, [searchInput, enquiryListings, searchLocation]);

  const [enquiryModalOpen, setEnquiryModalOpen] = useState(false);
  const [selectedCurrentListing, setSelectedCurrentListing] = useState(null);

  const authorDisplayName = selectedCurrentListing?.author.attributes.profile.displayName || '';
  const onContactUser = () => {
    if (currentUser) {
      setEnquiryModalOpen(true);
    } else {
      history.push(createResourceLocatorString('SignupPage', routeConfiguration(), {}, {}));
    }
  };

  const onSubmitEnquiry = values => {
    const routes = routeConfiguration();
    const listingId = new UUID(selectedCurrentListing?.id.uuid);
    const { message } = values;

    onSendEnquiry(listingId, message?.trim())
      .then(txId => {
        setEnquiryModalOpen(false);
        setIsRedirect(true);

        // Redirect to OrderDetailsPage
        history.push(
          createResourceLocatorString('InboxPage', routes, { tab: 'enquiry-client' }, {})
        );
      })
      .catch(() => {
        // Ignore, error handling in duck file
      });
  };

  const priceData = (price, intl) => {
    if (price && defaultListingCurrency.includes(price.currency)) {
      const formattedPrice = formatMoney(intl, price, 0, 0);
      return { formattedPrice, priceTitle: formattedPrice };
    } else if (price) {
      return {
        formattedPrice: `(${price.currency})`,
        priceTitle: `Unsupported currency (${price.currency})`,
      };
    }
    return {};
  };

  if (!!isRedirect) {
    return <NamedRedirect name="InboxPage" params={{ tab: 'enquiry-client' }} />;
  }

  return (
    <div className={classNames(css.enquiryCardBody, isEducationSite && css.enquiryCardBodyEdu)}>
      {enquiryData?.map(data => (
        <div
          className={classes}
          onClick={() => {
            onContactUser();
            setSelectedCurrentListing(data);
          }}
        >
          <div className={css.cardHead}>
            <h3 className={css.cardHeadding}>{data.attributes.title}</h3>
            <div className={css.eventWrapper}>
              {showDate && <span className={css.eventText}>EVENTS</span>}
              <span className={css.cardEvents}>{data.attributes.publicData?.sub_category}</span>
            </div>
          </div>
          <div className={css.cardTextWrap}>
            <p className={css.cardText}>{data.attributes.description}</p>
          </div>
          <div className={css.timeAddressWrap}>
            <div className={css.timeWrap}>
              <span className={css.timeIcon}>
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11 5.5V11H16.5"
                    stroke="#0D60FA"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M14.7939 2.65244C13.6378 2.12617 12.3532 1.83301 11.0002 1.83301C5.93755 1.83301 1.8335 5.93706 1.8335 10.9997C1.8335 16.0622 5.93755 20.1663 11.0002 20.1663C16.0627 20.1663 20.1668 16.0622 20.1668 10.9997C20.1668 8.78957 19.3847 6.76215 18.0821 5.17911"
                    stroke="#0D60FA"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
              <span className={css.timeText}>{data.attributes.publicData?.availability}</span>
            </div>
            <div className={css.addressWrap}>
              <span className={css.addressIcon}>
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18.3334 9.16634C18.3334 13.2164 11.0001 20.1663 11.0001 20.1663C11.0001 20.1663 3.66675 13.2164 3.66675 9.16634C3.66675 5.11625 6.94999 1.83301 11.0001 1.83301C15.0502 1.83301 18.3334 5.11625 18.3334 9.16634Z"
                    stroke="#0D60FA"
                    strokeWidth="1.5"
                  />
                  <path
                    d="M10.9999 10.0833C11.5062 10.0833 11.9166 9.67294 11.9166 9.16667C11.9166 8.66041 11.5062 8.25 10.9999 8.25C10.4936 8.25 10.0833 8.66041 10.0833 9.16667C10.0833 9.67294 10.4936 10.0833 10.9999 10.0833Z"
                    fill="#0D60FA"
                    stroke="#0D60FA"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
              <span className={css.addressText}>
                {data.attributes.publicData?.location?.address}
              </span>
            </div>
            {showDate && (
              <div className={css.dateWrap}>
                <span className={css.dateIcon}>
                  <svg
                    width="19"
                    height="20"
                    viewBox="0 0 19 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 2.83333V1V2.83333ZM12 2.83333V4.66667V2.83333ZM12 2.83333H7.875H12ZM1 8.33333V16.5833C1 17.5959 1.82081 18.4167 2.83333 18.4167H15.6667C16.6792 18.4167 17.5 17.5959 17.5 16.5833V8.33333H1Z"
                      stroke="#0D60FA"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M1 8.33398V4.66732C1 3.6548 1.82081 2.83398 2.83333 2.83398H4.66667"
                      stroke="#0D60FA"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M4.66895 1V4.66667"
                      stroke="#0D60FA"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M17.4977 8.33398V4.66732C17.4977 3.6548 16.6769 2.83398 15.6644 2.83398H15.2061"
                      stroke="#0D60FA"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
                <span className={css.dateText}>
                  {moment(data.attributes.createdAt).format('ll')}
                </span>
              </div>
            )}
          </div>

          <div className={css.authorPrisingWrap}>
            <div className={css.authorWrap}>
              <Avatar user={data.author} />
              <span className={css.authorText}>
                {data?.author?.attributes?.profile?.displayName?.split(' ', 1)}
              </span>
            </div>
            <div className={css.prisingWrap}>
              {priceData(data.attributes?.price, intl)?.formattedPrice}
            </div>
          </div>
        </div>
      ))}
      <Modal
        id="HomePage.enquiry"
        className={css.enquiryModal}
        contentClassName={css.enquiryModalContent}
        isOpen={isAuthenticated && enquiryModalOpen}
        onClose={() => setEnquiryModalOpen(false)}
        onManageDisableScrolling={() => {}}
      >
        <Enquiry
          className={css.enquiryForm}
          submitButtonWrapperClassName={css.enquirySubmitButtonWrapper}
          listingTitle={
            <FormattedMessage
              id="EnquiryForm.heading"
              values={{
                listingTitle: selectedCurrentListing?.attributes.title,
              }}
            />
          }
          authorDisplayName={authorDisplayName}
          sendEnquiryError={sendEnquiryError}
          onSubmit={onSubmitEnquiry}
          inProgress={sendEnquiryInProgress}
          currentListing={selectedCurrentListing}
        />
      </Modal>
    </div>
  );
}

export default EnquiryCard;
