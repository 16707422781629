import React from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { Modal } from '..';

import css from './AddStripeAccountModal.css';

const AddStripeAccountModal = props => {
  const { isOpen, onCloseModal, onManageDisableScrolling, onOkay } = props;

  const closeButtonMessage = 'Close';
  return (
    <Modal
      id={'addStripeAccountModal'}
      contentClassName={css.modalContent}
      isOpen={isOpen}
      onClose={onCloseModal}
      onManageDisableScrolling={onManageDisableScrolling}
      closeButtonMessage={closeButtonMessage}
      containerClassName={css.addStripeAccountModal}
    >
      <h1 className={css.modalTitle}>
        <FormattedMessage id="AddStripeAccountModal.message" />
      </h1>
      <div className={css.payoutModalInfoNote}>
        <span className={css.noteFont}>
          <FormattedMessage id="AddStripeAccountModal.note" />
        </span>
        <FormattedMessage id="AddStripeAccountModal.payoutModalInfoNote" />
      </div>
      <div className={css.btnWrap}>
        <button className={css.submitButton} onClick={onOkay}>
          Add stripe account
        </button>
        <button className={css.cancelButton} onClick={onCloseModal}>
          Cancel
        </button>
      </div>
    </Modal>
  );
};

const { bool, string, func } = PropTypes;

AddStripeAccountModal.defaultProps = {
  className: null,
  rootClassName: null,
  reviewSent: false,
  sendReviewInProgress: false,
  sendReviewError: null,
};

AddStripeAccountModal.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  reviewSent: bool,
  sendReviewInProgress: bool,
  sendReviewError: propTypes.error,
  tx: propTypes.transaction,
  onSkip: func,
};

export default injectIntl(AddStripeAccountModal);
