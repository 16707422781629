import React from 'react';
import { withRouter } from 'react-router-dom';
import { get } from 'lodash/object';
import { omit } from 'lodash';
import { arrayOf, bool, number, shape, string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { PERIODS } from 'components/IncomeContent/IncomeContent';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { parse } from '../../util/urlHelpers';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import {
  fetchEnquiryMessages,
  sendMessage as sendEnquiryMessage,
} from 'containers/TransactionPage/TransactionPage.duck';
import SortByPopup from '../../components/SortBy/SortByPopup';
import { getExtensionFromUrl } from 'util/urlHelpers';
import { debounce, orderBy } from 'lodash';
import { ensureTransaction } from '../../util/data';
import {
  acceptPackageRequest,
  cancelPackageRequest,
  sellerPackageRequests,
  buyerPackageRequests,
  removePendingProgressReports,
  rescheduleBooking,
  getAllMyClientData,
} from './InboxPage.duck';
import { onClearSearchFilter } from '../HeaderContainer/HeaderContainer.duck';
import MenuIconBtnSvg from '../../assets/SellerPage/MenuIcon.svg';
import ProbuddyLogo from '../../assets/SellerPage/ProbuddyLogo.svg';
import { ReactComponent as BookingSvg } from '../../assets/SellerPage/Booking.svg';
import { ReactComponent as EnquiriesSvg } from '../../assets/SellerPage/Enquiries.svg';
import { ReactComponent as MyClientsSvg } from '../../assets/SellerPage/MyClients.svg';
import { ReactComponent as ScheduleSvg } from '../../assets/SellerPage/Schedule.svg';
import { ReactComponent as TransactionsSvg } from '../../assets/SellerPage/Transactions.svg';
import { ReactComponent as YourPackagesSvg } from '../../assets/SellerPage/YourPackages.svg';
import { ReactComponent as MyListingSvg } from '../../assets/HomePage/myListing.svg';
import { ReactComponent as MyMatchesSvg } from '../../assets/HomePage/tabIcon/matches.svg';
import { ReactComponent as MyRewards } from '../../assets/CompanyPage/yellowStar.svg';
import { ReactComponent as JoinedMatch } from '../../assets/HomePage/showMapIcon.svg';
import { ReactComponent as HostedBy } from '../../assets/HomePage/HomeIcon.svg';
import { ReactComponent as ArrowSvg } from '../../assets/SellerPage/Arrow.svg';
import downloadPackageSvg from '../../assets/SellerPage/downloadPackages.svg';
import searchIcon from '../../assets/SellerPage/searchIcon.svg';
import IconArrowDropDown from '../../assets/SellerPage/IconArrowDropDown.svg';
import {
  fetchTimeSlots,
  fetchMonthlyTimeSlots,
} from '../../containers/ListingPage/ListingPage.duck';
import MyListings from './MyListings';
import { getMarketplaceEntitiesMemo } from 'containers/BookingPlanPage/helpers';
import { createSlug } from '../../util/urlHelpers';
import Collapsible from 'react-collapsible';
import {
  NotificationBadge,
  Page,
  PaginationLinks,
  LayoutSideNavigation,
  LayoutWrapperMain,
  LayoutWrapperSideNav,
  LayoutWrapperFooter,
  IconSpinner,
  SecondaryButton,
  PrimaryButton,
  NamedLink,
  IconArrowDown,
  IncomeContent,
  WalletListingCard,
  Portal,
  Modal,
  GenericMessage,
  FooterBottomMenu,
  LeftBar,
} from '../../components';
import { txState } from 'components/InboxItem/helpers';
import { NotFoundPage } from '../../containers';
import config from '../../config';
import {
  loadData,
  transitionTransaction,
  downloadDeliveredTransactions,
  downloadPackages,
  sendMessage,
} from './InboxPage.duck';
import { SendMessageForm } from '../../forms';
import {
  TRANSITION_ACCEPT,
  TRANSITION_CANCEL,
  TRANSITION_COMPLETE_MANUAL,
  TRANSITION_DECLINE,
  TRANSITION_MARK_DELIVERED,
} from 'util/transaction';
import IconDownload from 'components/IconDownload/IconDownload';
import { withViewport } from 'util/contextHelpers';
import { getNoResultsMessageId, isResults } from 'containers/InboxPage/helpers';
import moment from 'moment';
import { createResourceLocatorString } from 'util/routes';
import routeConfiguration from 'routeConfiguration';
import Schedule from 'containers/InboxPage/Schedule';
import CSVDownloadModal from 'containers/InboxPage/components/CSVDownloadModal/CSVDownloadModal';
import GroupRow from 'containers/InboxPage/components/GroupableRow/GroupRow';
import TabNav from 'containers/InboxPage/components/TabNav/TabNav';
import Enquiries from 'containers/InboxPage/components/Enquiries/Enquiries';
import {
  UUID,
  STATUS_COLOR_CLASS,
  STATE_BTNS,
  STATES_WITH_MULTI_SELECT,
  createListingLink,
  StateButtons,
  ScheduleStateButton,
  StateMobileSlider,
  MAX_MOBILE_SCREEN_WIDTH,
  getTabName,
  getImage,
  walletPanelWidth,
  scheduleStateBtns,
  TABS,
} from './InboxPage.data';
import { IconOrdersTab, IconPackagesTab, IconScheduleTab, IconEnquiryTab } from './TabsIcons';
import { changingActivefooterTab } from 'ducks/user.duck';
import cns from 'classnames';
import css from './InboxPage.css';
import MyActivity from 'containers/MatchActivity/MyActivity';
import HostedMatches from 'containers/MatchActivity/HostedMatches';
import JoinedMatches from 'containers/MatchActivity/JoinedMatches';
import MyReward from 'containers/RecommendationCommissions/MyRewards';

export class InboxPageComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedBookings: [],
      selectedEnquireTx: {},
      isMultiActionInProgress: false,
      isCSVDownloadOpen: false,
      isCSVPackagesDownloadOpen: false,
      searchPackagesQuery: '',
      isSendMessageModalOpen: false,
      showSuccessMessage: false,
      keyWordValue: '',
      period: PERIODS.ALLTIME,
      openMenuBar: false,
      activeFooterTab: 'inboxPage',
      isMyclientDataAvailbale: false,
    };

    this.acceptPackageRequest = this.acceptPackageRequest.bind(this);
    this.rejectPackageRequest = this.rejectPackageRequest.bind(this);
    this.proceedToProgressReportHandler = this.proceedToProgressReportHandler.bind(this);
    this.handlePackageRequestQueryChange = this.handlePackageRequestQueryChange.bind(this);
    this.onMessageSubmit = this.onMessageSubmit.bind(this);
    this.onEnquiryMessageSubmit = this.onEnquiryMessageSubmit.bind(this);
    this.resetPage = this.resetPage.bind(this);
  }

  componentDidMount() {
    if (this.props.params.tab === 'sales') {
      this.props.onchangingActivefooterTab && this.props.onchangingActivefooterTab('inboxPage');
    }
    if (typeof window !== 'undefined') {
      const isEducationSite = localStorage.getItem('isEducationSite') === 'true';
      this.setState({ isEducationSite: isEducationSite });
    }
    if (typeof window !== 'undefined') {
      const isTennisSite = localStorage.getItem('isTennisSite') === 'true';
      this.setState({ isTennisSite: isTennisSite });
    }
  }

  toggleCSVDownload = value => {
    this.setState({
      isCSVDownloadOpen: value,
    });
  };

  toggleCSVPackagesDownload = value => {
    this.setState({
      isCSVPackagesDownloadOpen: value,
    });
  };

  onCloseSuccessMessage = () => {
    this.setState({ showSuccessMessage: false });
  };

  acceptPackageRequest(e) {
    e.preventDefault();
    e.stopPropagation();
    const packageReqId = e.target.closest('button').dataset.id;
    this.props.onAcceptPackageRequest(packageReqId);
  }

  rejectPackageRequest(e) {
    e.preventDefault();
    e.stopPropagation();
    const packageReqId = e.target.closest('button').dataset.id;
    this.props.onRejectPackageRequest(packageReqId, this.props.currentUser.id.uuid);
  }

  resetPage() {
    this.props.onResetHomePage();
    this.props.onchangingActivefooterTab('search');
    this.props.history.push(
      createResourceLocatorString(
        this.state.isEducationSite === 'EducationPage'
          ? this.state.isTennisSite === 'TennisPage'
          : 'HomePage',
        routeConfiguration(),
        {}
      )
    );
  }

  searchPackages = debounce(value => {
    const { tab } = this.props.params;
    const isSellerPackages = tab === 'sellerPackages';
    const isBuyerPackages = tab === 'buyerPackages';
    if (isBuyerPackages) this.props.onBuyerPackageRequests(this.props.currentUser.id.uuid, value);
    else if (isSellerPackages)
      this.props.onSellerPackageRequests(this.props.currentUser.id.uuid, value);
  }, 900);

  handlePackageRequestQueryChange(e) {
    e.preventDefault();
    this.setState({
      searchPackagesQuery: e.target.value,
    });
  }

  cancelDeclineHandler = transition => tx => {
    const txId = tx.id.uuid;
    const customerId = tx.customer.id.uuid;
    const sellerId = tx.provider.id.uuid;
    const listing = tx.listing.id.uuid;
    const withCredits = tx.attributes.protectedData.withCredits;
    const voucherAmount = tx.attributes.protectedData.voucherAmount
      ? tx.attributes?.payinTotal?.amount
      : false;
    const transitionFinal = !!tx?.attributes?.protectedData?.productBooking
      ? TRANSITION_CANCEL
      : transition;
    this.props.transitionTransaction({
      transactionId: txId,
      transition: transitionFinal,
      withCredits: withCredits,
      customerId: customerId,
      sellerId: sellerId,
      listing: listing,
      voucherAmount: voucherAmount,
    });
  };
  onMessageSubmit(values) {
    const message = values.message ? values.message.trim() : null;

    const { onSendMessage } = this.props;
    const { selectedBookings } = this.state;
    if (!message) {
      return;
    }

    selectedBookings.forEach(tx => {
      const ensuredTransaction = ensureTransaction(tx);

      onSendMessage(ensuredTransaction.id, message)
        .then(() => {})
        .catch(e => {
          console.error(e);
        });
    });
    this.setState({
      isSendMessageModalOpen: false,
      isMultiActionInProgress: false,
      showSuccessMessage: true,
      selectedBookings: [],
    });
  }

  onEnquiryMessageSubmit(values) {
    const message = values.message ? values.message.trim() : null;

    const { onSendMessage } = this.props;
    const { selectedEnquireTx } = this.state;
    if (!message) {
      return;
    }
    const ensuredTransaction = ensureTransaction(selectedEnquireTx);

    onSendMessage(ensuredTransaction.id, message)
      .then(messageId => {
        this.setState({
          isSendMessageModalOpen: false,
          showSuccessMessage: true,
          selectedEnquireTx: {},
        });
      })
      .catch(e => {
        this.setState({ isSendMessageModalOpen: false, selectedEnquireTx: {} });
        console.error(e);
      });
  }

  completeAcceptHandler = transition => tx => {
    const uuid = tx.id.uuid;
    const listing = tx.listing.id.uuid;
    const sellerId = tx.provider.id.uuid;
    const transitionFinal = tx?.attributes?.protectedData?.productBooking
      ? TRANSITION_MARK_DELIVERED
      : transition;
    this.props.transitionTransaction({
      transactionId: uuid,
      transition: transitionFinal,
      sellerId: sellerId,
      listing: listing,
    });
  };

  rescheduleHandler = transition => (tx, rescheduleParams) => {
    const uuid = tx.id.uuid;
    this.props.transitionTransaction({
      transactionId: uuid,
      transition: transition,
      rescheduleParams: rescheduleParams,
    });
  };

  allBookingsSelectHandler = () => {
    const { selectedBookings } = this.state;
    const { transactions } = this.props;

    const areAllSelected = selectedBookings.length === transactions.length;
    this.setState({ selectedBookings: areAllSelected ? [] : transactions });
  };

  allScheduleSelectedHandler = allSelectedData => {
    this.setState({ selectedBookings: allSelectedData });
  };

  bookingsSelectHandler = newTx => {
    this.setState(state => {
      if (state.selectedBookings.some(tx => tx.id.uuid === newTx.id.uuid)) {
        return {
          selectedBookings: state.selectedBookings.filter(tx => tx.id.uuid !== newTx.id.uuid),
        };
      }

      return {
        selectedBookings: [...state.selectedBookings, newTx],
      };
    });
  };

  bookAgainHandler = tx => {
    const { history } = this.props;
    if (tx.listing.attributes.title && tx.listing.id.uuid) {
      const listingLink = createResourceLocatorString(
        'ListingPage',
        routeConfiguration(),
        {
          slug: createSlug(tx.listing.attributes.title),
          id: tx.listing.id.uuid,
        },
        {}
      );
      history.push(listingLink);
    }
  };

  onRenderSortByPopup = () => {
    const { history, params } = this.props;
    const urlQueryParams = parse(history.location.search) || {};
    const handleSortBy = (urlParam, values) => {
      const queryParams = { ...urlQueryParams, [urlParam]: values };
      return history.push(
        createResourceLocatorString('InboxPage', routeConfiguration(), { ...params }, queryParams)
      );
    };
    const sortProps = {
      rootClassName: css.sortStyle,
      urlParam: 'sort',
      label: <FormattedMessage id={'SchedulePageContent.sortByHeading'} />,
      options: [
        { key: 'default', label: 'Default' },
        { key: 'asc', label: 'Ascending' },
        { key: 'desc', label: 'Descending' },
      ],
      initialValue: urlQueryParams && urlQueryParams.sort ? urlQueryParams.sort : 'default',
      onSelect: handleSortBy,
    };
    return <SortByPopup {...sortProps} className={css.menuLabelReady} />;
  };

  onRenderGroupByPopup = () => {
    const { history, params } = this.props;
    const urlQueryParams = parse(history.location.search) || {};
    const handleGroupBy = (urlParam, values) => {
      const queryParams = { ...urlQueryParams, [urlParam]: values };
      return history.push(
        createResourceLocatorString('InboxPage', routeConfiguration(), { ...params }, queryParams)
      );
    };
    const sortProps = {
      urlParam: 'group',
      label: <FormattedMessage id={'SchedulePageContent.GroupByHeading'} />,
      options: [
        { key: 'listing-name', label: 'Listing Name' },
        { key: 'booking-date', label: 'Booking Date' },
      ],
      initialValue: urlQueryParams && urlQueryParams.group ? urlQueryParams.group : 'listing-name',
      onSelect: handleGroupBy,
    };
    return <SortByPopup {...sortProps} />;
  };

  onSendMessageModalOpen = () => {
    if (this.state.selectedBookings.length === 0) {
      this.setState({ isMultiActionInProgress: false });
      return;
    }
    this.setState({ isSendMessageModalOpen: true });
  };

  onEnquireMessageModalOpen = tx => {
    this.setState({ isSendMessageModalOpen: true, selectedEnquireTx: tx });
  };

  renderSelectAllPanel = state => {
    const { selectedBookings } = this.state;
    const isMobileLayout = this.props.viewport.width < 1024;
    const { transactions } = this.props;
    const { tab } = this.props.params;
    if (state === undefined && tab === 'sales') {
      state = 'pending';
    }
    const isAllChecked =
      !selectedBookings.length && !transactions.length
        ? false
        : selectedBookings.length === transactions.length;

    return (
      STATES_WITH_MULTI_SELECT.includes(state) && (
        <>
          <div className={css.selectAllWrap}>
            <div className={css.checkboxField}>
              <input
                onChange={this.allBookingsSelectHandler}
                className={css.checkboxInput}
                type="checkbox"
                id="checkbox"
                checked={isAllChecked}
              />
              <label htmlFor="checkbox"></label>
              <div className={css.sendMsgDivider} />
              <span>
                <FormattedMessage id={'InboxPage.multiActionSelectAll'} />
              </span>
            </div>
            <div className={css.rightSideControls}>
              {/* <button onClick={this.onSendMessageModalOpen}>
                <img src={sendMessageSvg} alt={'Send_Message'} />
                <div className={css.sendMsgDivider} />
                <FormattedMessage id={'InboxPage.sendMessage'} />
              </button> */}

              <div className={css.desktopSortPopupWrapper}>
                {!isMobileLayout && (
                  <>
                    {state === 'ready-to-complete' && this.onRenderSortByPopup()}
                    {state === 'ready-to-complete' && this.onRenderGroupByPopup()}
                  </>
                )}
              </div>
            </div>
          </div>
          <div className={css.mobileSortPopupWrapper}>
            {state === 'ready-to-complete' && this.onRenderSortByPopup()}
            {state === 'ready-to-complete' && this.onRenderGroupByPopup()}
          </div>
        </>
      )
    );
  };

  proceedToProgressReportHandler(e) {}

  openNav = () => {
    document.getElementById('InboxSideNav').style.width = '255px';
  };
  closeNav = () => {
    document.getElementById('InboxSideNav').style.width = '0';
  };

  statusButtonClickHandler = state => {
    const { history, params } = this.props;
    history.push(
      createResourceLocatorString('InboxPage', routeConfiguration(), { ...params, state })
    );
  };

  render() {
    const {
      unitType,
      currentUser,
      fetchInProgress,
      fetchOrdersOrSalesError,
      intl,
      pagination,
      params,
      providerNotificationCount,
      saleEnquiriesNotificationCount,
      orderEnquiriesNotificationCount,
      providerPackagesNotificationCount,
      scrollingDisabled,
      transactions,
      manageDisableScrolling,
      buyerPackageRequests,
      fetchBuyerPackageRequestError,
      fetchBuyerPackageRequestInProgress,
      sellerPackageRequests,
      fetchSellerPackageRequestInProgress,
      fetchSellerPackageRequestError,
      acceptPackageRequestInProgress,
      cancelPackageRequestInProgress,
      fetchCSVDataInProgress,
      fetchCSVPackagesDataInProgress,
      packagesListings,
      packagesBuyers,
      incomeTransactions,
      incomeTransactionsLoading,
      viewport,
      enquiries,
      fetchEnquiryError,
      ownListings,
      fetchSellerProgressReportsRequestError,
      fetchSellerProgressReportsRequestInProgress,
      sellerProgressReports,
      fetchBuyerProgressReportsRequestError,
      fetchBuyerProgressReportsRequestInProgress,
      buyerProgressReports,
      progressReportsListings,
      progressReportsBuyers,
      downloadDeliveredTransactions,
      onDownloadPackages,
      onRescheduleBooking,
      rescheduleRequestInProgress,
      rescheduleError,
      rescheduledsuccessfully,
      onFetchTimeSlots,
      onFetchMonthlyTimeSlots,
      monthlyTimeSlots,
      isTimeSlotesLoading,
      walletListings,
      walletRequestInProgress,
      history,
      ScheduleTransactions,
      recentTransactions,
      isLoading,
      sellerPackagesPagination,
      buyerPackagesPagination,
      sellerPackageRequestsKeyword,
      buyerPackageRequestsKeyword,
    } = this.props;

    const { selectedBookings, keyWordValue, period, isEducationSite, isTennisSite } = this.state;
    const isMobileLayout = !!viewport.width && viewport.width < MAX_MOBILE_SCREEN_WIDTH;
    const { tab, state } = params;
    const ensuredCurrentUser = ensureCurrentUser(currentUser);
    const validTab = Object.entries(TABS).some(([key, t]) => t === tab);
    if (!validTab) {
      return <NotFoundPage />;
    }

    const isOrders = tab === 'orders';
    const isSellerPackages = tab === 'sellerPackages';
    const isBuyerPackages = tab === 'buyerPackages';
    const isBooking = tab === 'sales';
    const isSellerProgressReports = tab === 'progressReports';
    const isBuyerProgressReports = tab === 'progressReportsClient';

    const isTransactions = tab === 'transactions';
    const isEnquiry = tab === 'enquiry' || tab === 'enquiry-client';
    const isProConnectRequests = tab === 'pro_connect_requests';
    const isSchedule = tab === 'schedule';
    const isScheduleClient = tab === 'schedule-client';
    const isMyClients = tab === 'myClients';
    const isMyListings = tab === 'myListings';
    const isMyMatches = tab === 'myMatch';
    const isMyRewards = tab === 'myRewards';
    const isHostedMatches = tab === 'hostedMatches';
    const isJoinedMatches = tab === 'joinedMatches';

    const ordersTitle = intl.formatMessage({ id: 'InboxPage.ordersTitle' });
    const salesTitle = intl.formatMessage({ id: 'InboxPage.salesTitle' });
    const title = isOrders ? ordersTitle : salesTitle;
    const searchPackageRequestPlaceholder = intl.formatMessage({
      id: 'InboxPage.searchPackageRequestPlaceholder',
    });

    const pattern = new RegExp(this.state.searchPackagesQuery, 'i');

    const getSellerPackages = () => {
      if (!this.state.searchPackagesQuery.length) {
        return sellerPackageRequests;
      } else {
        // let sellerPackageRequestsKeywordFiltered =
        //   sellerPackageRequestsKeyword &&
        //   sellerPackageRequestsKeyword.length &&
        //   this.state.searchPackagesQuery?.length
        //     ? sellerPackageRequestsKeyword.filter(r => {
        //         return (
        //           r.buyer_name?.match(pattern) ||
        //           r.listing_title?.match(pattern) ||
        //           r.author_name?.match(pattern)
        //         );
        //       })
        //     : sellerPackageRequests;

        let a1 = packagesBuyers.filter(
          (v, i, a) => a.findIndex(v2 => v2.id.uuid === v.id.uuid) === i
        );
        let otherUser = a1.filter(buyer =>
          sellerPackageRequestsKeyword.map(pr => buyer.id.uuid === pr.buyer_id)
        );

        let ot1 = otherUser.filter((v, i, a) => a.findIndex(v2 => v2.id.uuid === v.id.uuid) === i);

        let ot2 = ot1.filter(r => {
          return r.attributes.profile.displayName.match(pattern);
        });

        let ref = [];
        sellerPackageRequestsKeyword.map(sp => {
          ot2.map(o => {
            if (o.id.uuid === sp.buyer_id) ref.push(sp);
          });
        });

        sellerPackageRequestsKeyword.map(sp => {
          if (sp.listing_title.match(pattern)) ref.push(sp);
        });

        const refFinal = ref.filter((v, i, a) => a.findIndex(v2 => v2.id === v.id) === i);
        return refFinal;
      }
    };

    const sellerPackageRequestsKeywordFilteredFinal = getSellerPackages();

    const buyerPackageRequestsKeywordFiltered =
      buyerPackageRequestsKeyword &&
      buyerPackageRequestsKeyword.length &&
      this.state.searchPackagesQuery?.length
        ? buyerPackageRequestsKeyword.filter(r => {
            return (
              r.buyer_name?.match(pattern) ||
              r.listing_title?.match(pattern) ||
              r.author_name?.match(pattern)
            );
          })
        : buyerPackageRequests;

    const isVoucherUser = currentUser?.attributes?.profile?.publicData?.voucher_user;
    const defaultVoucherAmount =
      isVoucherUser && currentUser?.attributes?.profile?.protectedData?.DefaultCredits;

    const toGroupableTxItem = () => {
      const type = isOrders ? 'order' : 'sale';
      let entries = [];
      const { history } = this.props;
      const urlQueryParams = parse(history.location.search) || {};
      const sortBy = urlQueryParams.sort ? urlQueryParams.sort : 'default';
      const groupBy = urlQueryParams.group ? urlQueryParams.group : 'listing-name';

      if (state === 'ready-to-complete') {
        let orderedTransactions = transactions;
        if (groupBy === 'booking-date') {
          orderedTransactions = orderBy(transactions, 'booking.attributes.displayStart', 'desc');
        } else {
          if (sortBy !== 'default') {
            orderedTransactions = orderBy(transactions, 'listing.attributes.title', sortBy);
          }
        }

        entries = orderedTransactions.reduce((acc, item) => {
          const index = acc.findIndex(txArr => {
            return txArr.some(txItem => {
              if (groupBy === 'booking-date')
                return (
                  moment(
                    txItem && txItem.booking ? txItem.booking.attributes.displayStart : null
                  ).format('dddd MMMM Do') ===
                  moment(item && item.booking ? item.booking.attributes.displayStart : null).format(
                    'dddd MMMM Do'
                  )
                );
              else {
                const txItemListingTitle =
                  txItem && txItem.listing ? txItem.listing.attributes.title : null;
                const itemListingTitle =
                  item && item.listing ? item.listing.attributes.title : null;
                return txItemListingTitle === itemListingTitle;
              }
            });
          });
          index >= 0 ? acc[index].push(item) : acc.push([item]);
          return acc;
        }, []);
      } else {
        entries = orderBy(transactions, 'booking.attributes.displayStart', 'desc').reduce(
          (acc, item) => {
            const index = acc.findIndex(txArr => {
              return txArr.some(txItem => {
                return (
                  moment(
                    txItem && txItem.booking ? txItem.booking.attributes.displayStart : null
                  ).format('dddd MMMM Do') ===
                  moment(item && item.booking ? item.booking.attributes.displayStart : null).format(
                    'dddd MMMM Do'
                  )
                );
              });
            });
            index >= 0 ? acc[index].push(item) : acc.push([item]);
            return acc;
          },
          []
        );
      }

      return (
        !!entries &&
        !!entries.length &&
        entries.map((entry, index) => (
          <GroupRow
            key={`M${index}`}
            unitType={unitType}
            state={state}
            type={type}
            entries={entry}
            intl={intl}
            index={index}
            onComplete={this.completeAcceptHandler(TRANSITION_COMPLETE_MANUAL)}
            onCancel={this.cancelDeclineHandler}
            onDecline={this.cancelDeclineHandler(TRANSITION_DECLINE)}
            onAccept={this.completeAcceptHandler(TRANSITION_ACCEPT)}
            onManageDisableScrolling={manageDisableScrolling}
            onSelect={
              STATES_WITH_MULTI_SELECT.includes(state) || (type === 'sale' && state === undefined)
                ? this.bookingsSelectHandler
                : null
            }
            selectedBookings={selectedBookings}
            onBookAgain={this.bookAgainHandler}
            rescheduleBooking={onRescheduleBooking}
            rescheduleRequestInProgress={rescheduleRequestInProgress}
            rescheduleError={rescheduleError}
            rescheduledsuccessfully={rescheduledsuccessfully}
            currentUser={ensuredCurrentUser}
            onFetchTimeSlots={onFetchTimeSlots}
            onFetchMonthlyTimeSlots={onFetchMonthlyTimeSlots}
            monthlyTimeSlots={monthlyTimeSlots}
            isTimeSlotesLoading={isTimeSlotesLoading}
            sortBy={sortBy}
            groupBy={groupBy}
          />
        ))
      );
    };

    const toTxItem = tx => {
      let type = isOrders ? 'order' : 'sale';
      if (tab === 'enquiry-client') {
        type = 'order';
      }
      const stateData = txState(intl, tx, type);
      // Render InboxItem only if the latest transition of the transaction is handled in the `txState` function.
      //tx.provider && currentUser && tx.provider.id.uuid === currentUser.id.uuid
      return stateData ? (
        <li key={tx.id.uuid} className={cns(css.listItem, isEnquiry && css.enquirylistItem)}>
          {/* <InboxItemWithButtons
            unitType={unitType}
            type={type}
            tx={tx}
            intl={intl}
            stateData={stateData}
            onComplete={this.completeAcceptHandler(TRANSITION_COMPLETE_MANUAL)}
            onCancel={
              txIsReadyToComplete(tx)
                ? this.cancelDeclineHandler(TRANSITION_CANCEL_READY_TO_COMPLETE)
                : this.cancelDeclineHandler(TRANSITION_CANCEL)
            }
            onDecline={this.cancelDeclineHandler(TRANSITION_DECLINE)}
            onAccept={this.completeAcceptHandler(TRANSITION_ACCEPT)}
            onManageDisableScrolling={manageDisableScrolling}
            onSelect={STATES_WITH_MULTI_SELECT.includes(state) ? this.bookingsSelectHandler : null}
            isSelected={selectedBookings.some(selectedTx => selectedTx.id.uuid === tx.id.uuid)}
            onBookAgain={this.bookAgainHandler}
            rescheduleBooking={onRescheduleBooking}
            rescheduleRequestInProgress={rescheduleRequestInProgress}
            rescheduleError={rescheduleError}
            rescheduledsuccessfully={rescheduledsuccessfully}
            currentUser={ensuredCurrentUser}
            onFetchTimeSlots={onFetchTimeSlots}
            onFetchMonthlyTimeSlots={onFetchMonthlyTimeSlots}
            monthlyTimeSlots={monthlyTimeSlots}
            onEnquireMessageModalOpen={this.onEnquireMessageModalOpen}
            isEnquiryTab={isEnquiry}
          /> */}
        </li>
      ) : null;
    };

    const toPackageRequestItem = pr => {
      const slug = createSlug(pr.listing_title);
      const listing = packagesListings.filter(listing => listing.id.uuid === pr.listing_id)[0];
      const otherUser = isSellerPackages
        ? packagesBuyers.filter(buyer => buyer.id.uuid === pr.buyer_id)[0]
        : isBuyerPackages && listing
        ? listing.author
        : null;
      const listingLink = listing && otherUser ? createListingLink(listing, otherUser) : null;
      const color = STATUS_COLOR_CLASS[pr.status];
      const paymentStatusColor = pr.payment_status === 'paid' ? '#2ecc71' : '#b2b2b2';
      const totalPrAmount = (pr.amount / 100.0).toFixed(2) + ` ${pr.currency}`;
      const usedPrAmount =
        ((pr.amount / 100.0 / pr.credits) * pr.used_credits).toFixed(2) + ` ${pr.currency}`;
      const remainingPrAmount =
        ((pr.amount / 100.0 / pr.credits) * (pr.credits - pr.used_credits)).toFixed(2) +
        ` ${pr.currency}`;

      const packageStatusSection = (
        <div className={css.triggerSectionRight}>
          <div style={{ color: color }} className={css.status}>
            {pr.status[0].toUpperCase() + pr.status.slice(1)}
          </div>
          {pr.payment_type === 'online_payment' ? (
            <div className={css.paymentInfoContainer}>
              <div className={css.payment_type}>
                <FormattedMessage id="InboxPage.onlinePayment" />
              </div>
              <div style={{ color: paymentStatusColor }} className={css.payment_status}>
                {pr.payment_status && pr.payment_status.toUpperCase()}
              </div>
            </div>
          ) : null}
          {pr.payment_type !== 'online_payment' &&
          pr.status === 'pending' &&
          ensuredCurrentUser.id.uuid === pr.seller_id ? (
            <div className={css.packageRequestActions}>
              <PrimaryButton
                data-id={pr.id}
                className={`${css.acceptPackageRequest} ${'avoidTrigger'}`}
                spinnerClassName={css.spinner}
                disabled={acceptPackageRequestInProgress}
                onClick={this.acceptPackageRequest}
              >
                <FormattedMessage id="InboxPage.acceptPackageRequest" />
              </PrimaryButton>

              <SecondaryButton
                data-id={pr.id}
                className={`${css.rejectPackageRequest} ${'avoidTrigger'}`}
                spinnerClassName={css.spinner}
                disabled={cancelPackageRequestInProgress}
                onClick={this.rejectPackageRequest}
              >
                <FormattedMessage id="InboxPage.rejectPackageRequest" />
              </SecondaryButton>
            </div>
          ) : null}
          <div className={css.iconArrowDropDown}>
            <img src={IconArrowDropDown} alt={'dropdown_icon_arrow'} />
          </div>
        </div>
      );
      const trigger = (
        <div key={pr.id} className={css.trigger}>
          <div className={css.userInfoContainer}>
            <div className={css.imgContainer}>{listingLink}</div>
            <div className={css.userListing}>
              {otherUser ? (
                <div className={css.userName}>
                  <NamedLink name={'ProfilePage'} params={{ id: otherUser.id.uuid }}>
                    {otherUser.attributes.profile.displayName}
                  </NamedLink>
                </div>
              ) : null}
              <div className={css.listingTitle}>
                <NamedLink name={'ListingPage'} params={{ slug: slug, id: pr.listing_id }}>
                  {pr.listing_title}
                </NamedLink>
              </div>
            </div>
          </div>
          {!isMobileLayout && <div className={css.trigerDivider} />}
          <div className={css.createdAt}>
            <div className={css.timeZoneWrapper}>
              <div>{moment(pr.created_at).format('Do MMMM YYYY')}</div>
              <div>{moment(pr.created_at).format('h:mm:ss a')}</div>
            </div>
            {isMobileLayout && <>{packageStatusSection}</>}
          </div>
          {!isMobileLayout && <div className={css.trigerDivider} />}
          <div className={css.sessionContainer}>
            <div className={css.sessions}>
              <span className={css.prAmount}>{totalPrAmount}</span>
              <span className={css.prTime}>
                {pr.credits} <FormattedMessage id="InboxPage.sessions" />
              </span>
            </div>
            <div className={css.trigerDivider} />
            <div className={css.usedSessions}>
              <span className={css.prAmount}>{remainingPrAmount}</span>
              <span className={css.prTime}>
                {pr.credits - pr.used_credits} <FormattedMessage id="InboxPage.sessions" />
              </span>
            </div>
          </div>
          {/* </div> */}
          {!isMobileLayout && <>{packageStatusSection}</>}
        </div>
      );
      return pr ? (
        <div key={pr.id}>
          <li className={css.listItem}>
            <Collapsible
              trigger={trigger}
              className={css.collapsible}
              openedClassName={css.collapsible}
            >
              <div>
                <h3>Total amount of package</h3>
                <div>{totalPrAmount}</div>

                <h3>Total amount of package sold</h3>
                <div>{usedPrAmount}</div>

                <h3>Remaining amount of package</h3>
                <div>{remainingPrAmount}</div>
              </div>

              <div className={css.paymentProofsContainer}>
                {pr.payment_proof_ss_urls.length > 0 ? (
                  <div>
                    <h3>
                      <FormattedMessage id="InboxPage.paymentScreenshots" />
                    </h3>
                    <div className={css.paymentProofSsContainer}>
                      {pr.payment_proof_ss_urls.map((ss, index) => {
                        return (
                          <a
                            key={`proof${index}`}
                            className={css.paymentProofSs}
                            // eslint-disable-next-line
                            target="_blank"
                            href={ss}
                          >
                            <img src={ss} alt="paymentProofSs" />
                          </a>
                        );
                      })}
                    </div>
                  </div>
                ) : null}
                {pr.membership_card_number ? (
                  <div>
                    <h3>
                      <FormattedMessage id="InboxPage.membershipCardNumber" />
                    </h3>
                    {pr.membership_card_number}
                  </div>
                ) : null}
                {pr.student_name ? (
                  <div>
                    <h3>
                      <FormattedMessage id="InboxPage.studentName" />
                    </h3>
                    {pr.student_name}
                  </div>
                ) : null}
                {pr.comments ? (
                  <div>
                    <h3>
                      <FormattedMessage id="InboxPage.comments" />
                    </h3>
                    {pr.comments}
                  </div>
                ) : null}
              </div>
              <div className={css.transactions}>
                {pr.transactions && pr.transactions.length > 0 ? (
                  <h3>Booking creation date</h3>
                ) : null}
                {pr.transactions &&
                  pr.transactions.length > 0 &&
                  pr.transactions.map(function(transaction, i) {
                    const refunds =
                      pr.refunds &&
                      pr.refunds.length > 0 &&
                      pr.refunds.map(refund => refund.transaction_id);
                    const refunded =
                      refunds && refunds.length > 0 && refunds.includes(transaction.transaction_id);
                    const user =
                      ensuredCurrentUser.id.uuid === pr.seller_id
                        ? otherUser
                          ? otherUser.attributes.profile.displayName
                          : 'Buyer'
                        : 'You';
                    return refunded ? null : (
                      <p key={`t${i}`}>
                        {user} booked {transaction.credits_used} hour(s) on{' '}
                        {moment(transaction.created_at).format('MMM Do YY, h:mm:ss a')} for amount{' '}
                        {(transaction.amountPaid / 100.0).toFixed(2)} {pr.currency}
                      </p>
                    );
                  })}
              </div>
            </Collapsible>
          </li>
        </div>
      ) : null;
    };

    const toProgressReportItem = pr => {
      const listing = progressReportsListings.filter(
        listing => listing.id.uuid === pr.listing_id
      )[0];
      const otherUser = isSellerProgressReports
        ? progressReportsBuyers.filter(buyer => buyer.id.uuid === pr.buyer_id)[0]
        : isBuyerProgressReports && listing
        ? listing.author
        : null;

      if (!listing || !otherUser) return;
      const slug = createSlug(listing.attributes.title);
      const listingLink = listing && otherUser ? createListingLink(listing, otherUser) : null;

      const trigger = (
        <div className={css.trigger}>
          <div className={css.triggerSectionLeft}>
            <div>{listingLink}</div>
            <div className={css.userAndListingName}>
              {otherUser ? (
                <div className={css.userName}>
                  <NamedLink name={'ProfilePage'} params={{ id: otherUser.id.uuid }}>
                    {otherUser.attributes.profile.displayName}
                  </NamedLink>
                </div>
              ) : null}
              <div className={css.listingTitle}>
                <NamedLink name={'ListingPage'} params={{ slug: slug, id: listing.id.uuid }}>
                  {listing.attributes.title}
                </NamedLink>
              </div>
              <div className={css.transactionLink}>
                <NamedLink
                  name={isBuyerProgressReports ? 'OrderDetailsPage' : 'SaleDetailsPage'}
                  params={{ id: pr.transaction_id }}
                >
                  {isBuyerProgressReports ? (
                    <FormattedMessage id="InboxPage.orderDetails" />
                  ) : (
                    <FormattedMessage id="InboxPage.saleDetails" />
                  )}
                </NamedLink>
              </div>
              <div className={css.createdAt}>
                {moment(pr.created_at).format('MMM Do YY, h:mm:ss a')}
              </div>
            </div>
            <div className={css.notes}>{pr.notes}</div>
          </div>
          <div className={css.triggerSectionRight}>
            <div className={css.iconArrowDown}>
              <IconArrowDown selected={false} />
            </div>
          </div>
        </div>
      );
      return pr ? (
        <div>
          <li className={css.listItem}>
            <Collapsible
              trigger={trigger}
              className={css.collapsible}
              openedClassName={css.collapsible}
            >
              <div>
                <h2>Notes</h2>
                <p>{pr.notes}</p>
              </div>

              <div className={css.attachmentContainer}>
                {pr.attachment && pr.attachment !== '' ? (
                  <div className={css.attachment}>
                    {pr.attachment_type === 'image' ? (
                      <a target="_blank" rel="noopener noreferrer" href={pr.attachment}>
                        <img src={pr.attachment} alt="attachment" />
                      </a>
                    ) : pr.attachment_type === 'video' ? (
                      <video src={pr.attachment} controls width="100%">
                        <source
                          src={pr.attachment}
                          type={`video/${getExtensionFromUrl(pr.attachment)}`}
                        />
                      </video>
                    ) : null}
                  </div>
                ) : null}
              </div>
            </Collapsible>
          </li>
        </div>
      ) : null;
    };

    const error =
      fetchOrdersOrSalesError && fetchEnquiryError ? (
        <p className={css.error}>
          <FormattedMessage id="InboxPage.fetchFailed" />
        </p>
      ) : null;

    const noResults =
      !fetchInProgress &&
      !isResults(tab, transactions, enquiries) &&
      !fetchOrdersOrSalesError &&
      !fetchEnquiryError ? (
        <li key="noResults" className={css.noResults}>
          <FormattedMessage id={getNoResultsMessageId(tab)} />
        </li>
      ) : null;

    const hasOrderOrSaleTransactions = (tx, isOrdersTab, user) => {
      return isOrdersTab
        ? user.id && tx && tx.length > 0 && get(tx[0], 'customer.id.uuid') === user.id.uuid
        : user.id && tx && tx.length > 0 && get(tx[0], 'provider.id.uuid') === user.id.uuid;
    };

    const hasTransactions =
      !fetchInProgress && hasOrderOrSaleTransactions(transactions, isOrders, ensuredCurrentUser);
    const hasEnquiries = ensuredCurrentUser.id && enquiries && enquiries.length;
    const noPagination = isSellerProgressReports || isBuyerProgressReports ? true : false;
    const pagingLinks =
      !isSellerPackages &&
      !isBuyerPackages &&
      !noPagination &&
      (hasTransactions || hasEnquiries) &&
      pagination &&
      pagination.totalPages > 1 ? (
        <PaginationLinks
          className={css.pagination}
          pageName="InboxPage"
          pagePathParams={params}
          pagination={pagination}
        />
      ) : isSellerPackages &&
        sellerPackagesPagination &&
        !this.state.searchPackagesQuery.length &&
        sellerPackagesPagination.totalPages > 1 ? (
        <PaginationLinks
          className={css.pagination}
          pageName="InboxPage"
          pagePathParams={params}
          pageSearchParams={{ query: this.state.searchPackagesQuery }}
          pagination={sellerPackagesPagination}
        />
      ) : isBuyerPackages && buyerPackagesPagination && buyerPackagesPagination.totalPages > 1 ? (
        <PaginationLinks
          className={css.pagination}
          pageName="InboxPage"
          pagePathParams={params}
          pageSearchParams={{ query: this.state.searchPackagesQuery }}
          pagination={buyerPackagesPagination}
        />
      ) : null;

    const urlQueryParams = parse(history.location.search);
    const keywordPlaceholder = intl.formatMessage({
      id: 'SchedulePageContent.searchByName',
    });
    const handleKeyword = (urlParam, values) => {
      const queryParams = values
        ? { ...urlQueryParams, [urlParam]: values }
        : omit(urlQueryParams, urlParam);

      history.push(
        createResourceLocatorString(
          'InboxPage',
          routeConfiguration(),
          { tab: 'myClients' },
          queryParams
        )
      );
    };

    const providerNotificationBadge =
      providerNotificationCount > 0 ? (
        <NotificationBadge count={providerNotificationCount} />
      ) : null;

    const saleEnquiriesNotificationBadge =
      saleEnquiriesNotificationCount > 0 ? (
        <NotificationBadge count={saleEnquiriesNotificationCount} />
      ) : null;

    const orderEnquiriesNotificationBadge =
      orderEnquiriesNotificationCount > 0 ? (
        <NotificationBadge count={orderEnquiriesNotificationCount} />
      ) : null;

    const providerPackagesNotificationBadge =
      providerPackagesNotificationCount > 0 ? (
        <NotificationBadge count={providerPackagesNotificationCount} />
      ) : null;

    const tabs = [
      {
        text: (
          <span>
            <FormattedMessage id="InboxPage.ordersTabTitle" />
          </span>
        ),
        svgIcon: <IconOrdersTab />,
        selected: tab === 'orders',
        fillSvg: true,
        linkProps: {
          name: 'InboxPage',
          params: { tab: 'orders' },
        },
        showWith: [
          'orders',
          'buyerPackages',
          'progressReportsClient',
          'pro_connect_requests',
          'enquiry-client',
          'schedule-client',
        ],
      },
      {
        text: (
          <span>
            <FormattedMessage id="InboxPage.salesTabTitle" />
            {providerNotificationBadge}
          </span>
        ),
        svgIcon: <BookingSvg />,
        selected: tab === 'sales',
        fillSvg: true,
        linkProps: {
          name: 'InboxPage',
          params: { tab: 'sales' },
        },
        showWith: [
          'sales',
          'sellerPackages',
          'myClients',
          'transactions',
          'enquiry',
          'schedule',
          'myListings',
          'myMatch',
          'myRewards',
          'hostedMatches',
          'joinedMatches',
        ],
      },
      {
        text: (
          <span>
            <FormattedMessage id="InboxPage.packages" />
            {providerPackagesNotificationBadge}
          </span>
        ),
        svgIcon: <YourPackagesSvg />,
        selected: isSellerPackages,
        linkProps: {
          name: 'InboxPage',
          params: { tab: 'sellerPackages' },
        },
        showWith: [
          'sales',
          'sellerPackages',
          'myClients',
          'transactions',
          'enquiry',
          'schedule',
          'myListings',
          'myMatch',
          'myRewards',
          'hostedMatches',
          'joinedMatches',
        ],
      },
      {
        text: (
          <span>
            <FormattedMessage id="InboxPage.myRewards" />
          </span>
        ),
        svgIcon: <MyRewards />,
        selected: isMyRewards,
        linkProps: {
          name: 'InboxPage',
          params: { tab: 'myRewards' },
        },
        showWith: [
          'sales',
          'sellerPackages',
          'myClients',
          'transactions',
          'enquiry',
          'schedule',
          'myListings',
          'myMatch',
          'myRewards',
          'hostedMatches',
          'joinedMatches',
        ],
      },
      {
        text: (
          <span>
            <FormattedMessage id="InboxPage.myMatches" />
          </span>
        ),
        svgIcon: <MyMatchesSvg />,
        selected: isMyMatches,
        linkProps: {
          name: 'InboxPage',
          params: { tab: 'myMatch' },
        },
        showWith: [
          'sales',
          'sellerPackages',
          'myClients',
          'transactions',
          'enquiry',
          'schedule',
          'myListings',
          'myMatch',
          'myRewards',
          'myRewards',
          'hostedMatches',
          'joinedMatches',
        ],
      },
      {
        text: (
          <span>
            <FormattedMessage id="InboxPage.hostedMatches" />
          </span>
        ),
        svgIcon: <HostedBy />,
        selected: isHostedMatches,
        linkProps: {
          name: 'InboxPage',
          params: { tab: 'hostedMatches' },
        },
        showWith: [
          'sales',
          'sellerPackages',
          'myClients',
          'transactions',
          'enquiry',
          'schedule',
          'myListings',
          'myMatch',
          'myRewards',
          'hostedMatches',
          'joinedMatches',
        ],
      },
      {
        text: (
          <span>
            <FormattedMessage id="InboxPage.joinedMatches" />
          </span>
        ),
        svgIcon: <JoinedMatch />,
        selected: isJoinedMatches,
        linkProps: {
          name: 'InboxPage',
          params: { tab: 'joinedMatches' },
        },
        showWith: [
          'sales',
          'sellerPackages',
          'myClients',
          'transactions',
          'enquiry',
          'schedule',
          'myListings',
          'myMatch',
          'myRewards',
          'hostedMatches',
          'joinedMatches',
        ],
      },
      {
        text: (
          <span>
            <FormattedMessage id="InboxPage.schedule" />
          </span>
        ),
        svgIcon: <ScheduleSvg />,
        fillSvg: true,
        selected: isSchedule,
        linkProps: {
          name: 'InboxPage',
          params: { tab: 'schedule' },
        },
        showWith: [
          'sales',
          'sellerPackages',
          'myClients',
          'transactions',
          'enquiry',
          'schedule',
          'myListings',
          'myMatch',
          'myRewards',
          'hostedMatches',
          'joinedMatches',
        ],
      },
      {
        text: (
          <span>
            <FormattedMessage id="InboxPage.schedule" />
          </span>
        ),
        svgIcon: <IconScheduleTab />,
        fillSvg: true,
        selected: isScheduleClient,
        linkProps: {
          name: 'InboxPage',
          params: { tab: 'schedule-client' },
        },
        showWith: [
          'orders',
          'buyerPackages',
          'progressReportsClient',
          'pro_connect_requests',
          'enquiry-client',
          'schedule-client',
        ],
      },
      {
        text: (
          <span>
            <FormattedMessage id="InboxPage.enquiryTabTitle" />
            {saleEnquiriesNotificationBadge}
          </span>
        ),
        svgIcon: <EnquiriesSvg />,
        selected: tab === 'enquiry',
        linkProps: {
          name: 'InboxPage',
          params: { tab: 'enquiry' },
        },
        showWith: [
          'sales',
          'sellerPackages',
          'myClients',
          'transactions',
          'enquiry',
          'schedule',
          'myListings',
          'myMatch',
          'myRewards',
          'hostedMatches',
          'joinedMatches',
        ],
      },
      {
        text: (
          <span>
            <FormattedMessage id="InboxPage.enquiryTabTitle" />
            {orderEnquiriesNotificationBadge}
          </span>
        ),
        svgIcon: <IconEnquiryTab />,
        selected: tab === 'enquiry-client',
        linkProps: {
          name: 'InboxPage',
          params: { tab: 'enquiry-client' },
        },
        showWith: [
          'orders',
          'buyerPackages',
          'progressReportsClient',
          'pro_connect_requests',
          'enquiry-client',
          'schedule-client',
        ],
      },
      {
        text: (
          <span>
            <FormattedMessage id="InboxPage.packageRequests" />
            <br />
            <FormattedMessage id="InboxPage.asBuyer" />
          </span>
        ),
        svgIcon: <IconPackagesTab />,
        selected: isBuyerPackages,
        linkProps: {
          name: 'InboxPage',
          params: { tab: 'buyerPackages' },
        },
        showWith: [
          'orders',
          'buyerPackages',
          'progressReportsClient',
          'pro_connect_requests',
          'enquiry-client',
          'schedule-client',
        ],
      },
      {
        text: (
          <span>
            <FormattedMessage id="InboxPage.myClient" />
          </span>
        ),
        svgIcon: <MyClientsSvg />,
        selected: isMyClients,
        linkProps: {
          name: 'InboxPage',
          params: { tab: 'myClients' },
        },
        showWith: [
          'sales',
          'sellerPackages',
          'myClients',
          'transactions',
          'enquiry',
          'schedule',
          'myListings',
          'myMatch',
          'myRewards',
          'hostedMatches',
          'joinedMatches',
        ],
      },

      // {
      //   text: (
      //     <span>
      //       <FormattedMessage id="InboxPage.proConnectRequests" />
      //     </span>
      //   ),
      //   svgIcon: <IconProgressReportsTab />,
      //   selected: isProConnectRequests,
      //   linkProps: {
      //     name: 'InboxPage',
      //     params: { tab: 'pro_connect_requests' },
      //   },
      //   showWith: [
      //     'orders',
      //     'buyerPackages',
      //     'progressReportsClient',
      //     'pro_connect_requests',
      //     'enquiry-client',
      //     'schedule-client',
      //   ],
      // },
      {
        text: (
          <span>
            <FormattedMessage id="InboxPage.transactions" />
          </span>
        ),
        svgIcon: <TransactionsSvg />,
        selected: isTransactions,
        linkProps: {
          name: 'InboxPage',
          params: { tab: 'transactions' },
        },
        showWith: [
          'sales',
          'sellerPackages',
          // 'progressReports',
          'myClients',
          'transactions',
          'enquiry',
          'schedule',
          'myListings',
          'myMatch',
          'myRewards',
          'hostedMatches',
          'joinedMatches',
        ],
      },
      {
        text: (
          <span>
            <FormattedMessage id="InboxPage.myListings" />
          </span>
        ),
        svgIcon: <MyListingSvg />,
        selected: isMyListings,
        linkProps: {
          name: 'InboxPage',
          params: { tab: 'myListings' },
        },
        showWith: [
          'sales',
          'sellerPackages',
          'myClients',
          'transactions',
          'enquiry',
          'schedule',
          'myListings',
          'myMatch',
          'myRewards',
          'hostedMatches',
          'joinedMatches',
        ],
      },
      {
        text: (
          <span>
            <FormattedMessage id="InboxPage.deliverdBookings" />
            <br />
            <FormattedMessage id="InboxPage.clickToDownload" />
          </span>
        ),
        onClick: () => this.toggleCSVDownload(true),
        showWith: [
          'sales',
          'sellerPackages',
          // 'progressReports',
          'myClients',
          'transactions',
          'enquiry',
          'schedule',
          'myListings',
          'myMatch',
          'myRewards',
          'hostedMatches',
          'joinedMatches',
        ],
      },
    ];
    const tabsToRender = tabs.filter(i => i.showWith.includes(tab));

    const handleSortBy = (urlParam, values) => {
      const queryParams = { ...urlQueryParams, [urlParam]: values };
      switch (tab) {
        case 'schedule':
          return history.push(
            createResourceLocatorString(
              'InboxPage',
              routeConfiguration(),
              { tab: 'schedule', state },
              queryParams
            )
          );
        case 'schedule-client':
          return history.push(
            createResourceLocatorString(
              'InboxPage',
              routeConfiguration(),
              { tab: 'schedule-client', state },
              queryParams
            )
          );
        case 'myClients':
          return history.push(
            createResourceLocatorString(
              'InboxPage',
              routeConfiguration(),
              { tab: 'myClients' },
              queryParams
            )
          );
        default:
          return null;
      }
    };

    const sortProps = {
      urlParam: 'sort',
      label: intl.formatMessage({ id: 'SchedulePageContent.sortByHeading' }),
      options: [
        { key: 'default', label: 'Default' },
        { key: 'asc', label: 'Ascending' },
        { key: 'desc', label: 'Descending' },
      ],
      initialValue: urlQueryParams && urlQueryParams.sort ? urlQueryParams.sort : 'default',
      onSelect: handleSortBy,
      className: css.sheculeDefault,
    };

    const walletRenderSizes = [
      `(max-width: 767px) 100vw`,
      `(max-width: 1920px) ${walletPanelWidth / 2}vw`,
      `${walletPanelWidth / 3}vw`,
    ].join(', ');

    const wallet =
      currentUser && currentUser.attributes && currentUser.attributes.profile.privateData.sessions
        ? currentUser.attributes.profile.privateData.sessions
        : {};

    const sendMessageText = intl.formatMessage({ id: 'SchedulePageContent.sendMessage' });

    const deliveredSortByClientName = intl.formatMessage({
      id: 'InboxPage.deliveredSortByClientName',
    });
    const deliveredSortByClientNameTitle = intl.formatMessage({
      id: 'InboxPage.deliveredSortByClientNameTitle',
    });
    const deliveredSortByBookingDate = intl.formatMessage({
      id: 'InboxPage.deliveredSortByBookingDate',
    });
    const deliveredSortByBookingDateTitle = intl.formatMessage({
      id: 'InboxPage.deliveredSortByBookingDateTitle',
    });

    const packageSortByListingTitle = intl.formatMessage({
      id: 'InboxPage.packageSortByListingTitle',
    });
    const packageSortByListingTitleTitle = intl.formatMessage({
      id: 'InboxPage.packageSortByListingTitleTitle',
    });
    const packageSortByPackageBoughtDate = intl.formatMessage({
      id: 'InboxPage.packageSortByPackageBoughtDate',
    });
    const packageSortByPackageBoughtDateTitle = intl.formatMessage({
      id: 'InboxPage.packageSortByPackageBoughtDateTitle',
    });

    const onClickScheduleStateButton = value => {
      const urlQueryParams = parse(history.location.search) || {};
      history.push(
        createResourceLocatorString(
          'InboxPage',
          routeConfiguration(),
          { ...params, state: value },
          urlQueryParams
        )
      );
    };

    const sendMessagePlaceholder = intl.formatMessage(
      { id: 'TransactionPanel.sendMessagePlaceholder' },
      { name: 'selected users' }
    );

    const sendMessageModal = (
      <Portal portalRoot={document.getElementById('portal-root')}>
        <Modal
          id={'sendMessage'}
          contentClassName={css.modalContent}
          isOpen={this.state.isSendMessageModalOpen}
          delivered
          onClose={() =>
            this.setState({
              isSendMessageModalOpen: false,
              isMultiActionInProgress: false,
            })
          }
          onManageDisableScrolling={manageDisableScrolling}
        >
          {this.props.sendMessageInProgress ? (
            <IconSpinner />
          ) : (
            <div className={css.contentWrap}>
              <SendMessageForm
                formId={'SendMessageForm'}
                rootClassName={css.sendMessageForm}
                messagePlaceholder={sendMessagePlaceholder}
                onSubmit={isEnquiry ? this.onEnquiryMessageSubmit : this.deliveredonMessageSubmit}
              />
            </div>
          )}
        </Modal>
      </Portal>
    );

    const filterKeywordContent = (
      <div className={css.filterKeywordWrap}>
        <div className={css.inputWrap}>
          <input
            id={'SearchFilters.keywordFilter'}
            value={keyWordValue}
            placeholder={keywordPlaceholder}
            onChange={e => {
              !this.state.isMyclientDataAvailbale &&
                this.setState({ isMyclientDataAvailbale: true });
              !this.props.myClientsData.length &&
                !this.props.gettingAllClientData &&
                this.props.getAllMyClientData(pagination.totalPages);
              this.setState({ keyWordValue: e.target.value });
              handleKeyword('keywords', e.target.value);
            }}
          />
        </div>
        <button
          className={css.searchIconWrapper}
          onClick={() => handleKeyword('keywords', keyWordValue)}
        >
          <img src={searchIcon} alt={'search_icon'} />
        </button>
      </div>
    );

    const searchWrapper = (
      <>
        <div className={css.searchWrapper}>
          <input
            type="text"
            placeholder={searchPackageRequestPlaceholder}
            value={this.state.searchPackagesQuery}
            className={css.searchPackage}
            onChange={this.handlePackageRequestQueryChange}
          />
          <div className={css.searchIconWrapper}>
            <img src={searchIcon} alt={'search_icon'} />
          </div>
        </div>

        {sellerPackageRequests && sellerPackageRequests.length > 0 ? (
          <SecondaryButton
            inProgress={fetchCSVPackagesDataInProgress}
            className={css.downloadBtn}
            onClick={() => this.toggleCSVPackagesDownload(true)}
          >
            <img src={downloadPackageSvg} alt={'download_packages'} />
            <div className={css.sendMsgDivider} />
            <FormattedMessage id={'InboxPage.downloadPackagesButton'} />
          </SecondaryButton>
        ) : null}
      </>
    );

    return (
      <>
        <Page title={title} scrollingDisabled={scrollingDisabled}>
          <LayoutSideNavigation containerClassName={css.LayoutSideContainer}>
            <LayoutWrapperSideNav className={css.navigation}>
              <div className={css.inboxTitleContainer}>
                {!isMobileLayout && (
                  <img onClick={() => this.resetPage()} src={ProbuddyLogo} alt="ProbuddyLogo" />
                )}
              </div>
              <div className={css.headerDivider} />
              {isMobileLayout ? (
                <></>
              ) : (
                <TabNav rootClassName={css.tabs} tabRootClassName={css.tab} tabs={tabsToRender} />
              )}
            </LayoutWrapperSideNav>
            <LayoutWrapperMain className={isEnquiry ? css.enquiryRightPanel : css.rightPanel}>
              {error}
              {sendMessageModal}
              <div className={css.rightContentMain}>
                <GenericMessage
                  show={this.state.showSuccessMessage}
                  onClose={this.onCloseSuccessMessage}
                  message={'Messages sent'}
                />

                {!isEnquiry && (
                  <div
                    className={cns(css.titleContainer, {
                      [css.scheduleTitleContainer]: isSchedule || isScheduleClient || isMyClients,
                      [css.mobileTitleContainer]: isMobileLayout,
                      [css.bookingTitleContainer]: isBooking,
                    })}
                  >
                    <div className={css.titleWrapper}>
                      <div className={css.titleBtnWrapper}>
                        {isMobileLayout ? (
                          <button
                            className={css.titleWrapBtn}
                            onClick={() => this.setState({ openMenuBar: !this.state.openMenuBar })}
                          >
                            <img src={MenuIconBtnSvg} alt={'memu_icon'} />
                          </button>
                        ) : (
                          <div className={css.svgContainer}>{getImage(tab)}</div>
                        )}
                        <h1 className={css.inboxMainHeading}>{tab && getTabName(tab)}</h1>
                      </div>
                      {isMobileLayout && (tab === 'sales' || tab === 'orders') ? (
                        <div className={css.linkBtn}>
                          <div className={css.linkStart}>
                            <ArrowSvg />
                          </div>
                          {tab === 'sales' ? (
                            <NamedLink
                              className={css.linkText}
                              name={'InboxPage'}
                              params={{ tab: 'orders', state: 'upcoming' }}
                            >
                              Switch to User
                            </NamedLink>
                          ) : tab === 'orders' ? (
                            <NamedLink
                              className={css.linkText}
                              name={'InboxPage'}
                              params={{ tab: 'sales', state: 'pending' }}
                            >
                              Switch to Host
                            </NamedLink>
                          ) : null}
                        </div>
                      ) : null}
                    </div>
                    {!isMobileLayout && (tab === 'sales' || tab === 'orders') && (
                      <div className={css.salesHeader}>
                        <StateButtons
                          params={params}
                          onClick={this.statusButtonClickHandler}
                          buttons={STATE_BTNS}
                        />

                        {(tab === 'sales' || tab === 'orders') &&
                          !!transactions.length &&
                          state === 'delivered' && (
                            <SecondaryButton
                              inProgress={fetchCSVDataInProgress}
                              className={css.downloadBtn}
                              onClick={() => this.toggleCSVDownload(true)}
                            >
                              <IconDownload className={css.downloadBtnIcon} />
                              <FormattedMessage id={'InboxPage.downloadButton'} />
                            </SecondaryButton>
                          )}
                      </div>
                    )}
                    {!isMobileLayout && isMyClients && filterKeywordContent}
                    {isMyClients && (
                      <div className={css.helpers}>
                        {selectedBookings.length > 0 && (
                          <>
                            <SecondaryButton
                              className={css.sendMessageButton}
                              onClick={this.onSendMessageModalOpen}
                            >
                              {sendMessageText}
                            </SecondaryButton>
                            <SecondaryButton
                              className={css.sendMessageButton}
                              onClick={() => this.allScheduleSelectedHandler([])}
                            >
                              <FormattedMessage id={'SchedulePageContent.clearSelection'} />
                            </SecondaryButton>
                          </>
                        )}
                        <SortByPopup {...sortProps} />
                      </div>
                    )}
                    {isTransactions && (
                      <ul className={css.chartMenu}>
                        {Object.entries(PERIODS).map(([key, item]) => (
                          <li
                            key={item.key}
                            onClick={() => this.setState({ period: item })}
                            className={cns(
                              css.chatMenuItem,
                              period.key === item.key && css.chatMenuItemActive
                            )}
                          >
                            <FormattedMessage
                              id={isMobileLayout ? item.mobileLabelId : item.labelId}
                            />
                          </li>
                        ))}
                      </ul>
                    )}
                    {/* </div> */}

                    {(tab === 'schedule' || tab === 'schedule-client') && (
                      <>
                        {!isMobileLayout && (
                          <ScheduleStateButton
                            onClick={onClickScheduleStateButton}
                            selectedWeek={state}
                          />
                        )}
                        <SortByPopup {...sortProps} className={css.sheculeDefault} />
                      </>
                    )}
                    {!isMobileLayout && (tab === 'sales' || tab === 'orders') ? (
                      <div className={css.linkBtn}>
                        <div className={css.linkStart}>
                          <ArrowSvg />
                        </div>
                        {tab === 'sales' ? (
                          <NamedLink
                            className={css.linkText}
                            name={'InboxPage'}
                            params={{ tab: 'orders', state: 'upcoming' }}
                          >
                            Switch to User
                          </NamedLink>
                        ) : tab === 'orders' ? (
                          <NamedLink
                            className={css.linkText}
                            name={'InboxPage'}
                            params={{ tab: 'sales', state: 'pending' }}
                          >
                            Switch to Host
                          </NamedLink>
                        ) : null}
                      </div>
                    ) : null}
                  </div>
                )}
                {/* <div className={css.contentWrapper}> */}
                <div
                  className={isEnquiry || isSellerPackages ? css.enquiryScroll : css.contentWrapper}
                >
                  {(tab === 'schedule' ||
                    tab === 'schedule-client' ||
                    tab === 'sales' ||
                    tab === 'orders') && (
                    <StateMobileSlider
                      params={params}
                      isSchedule={isSchedule}
                      isScheduleClient={isScheduleClient}
                      options={
                        tab === 'schedule' || tab === 'schedule-client'
                          ? scheduleStateBtns
                          : STATE_BTNS
                      }
                    />
                  )}

                  {/* {this.renderSelectAllPanel(state)} */}

                  {isSchedule && !!ownListings.length && (
                    <Schedule
                      transactions={ScheduleTransactions}
                      openModalSendMessage={this.onSendMessageModalOpen}
                      selectedBookings={selectedBookings}
                      onAllSelectedSchedule={this.allScheduleSelectedHandler}
                      onSelect={this.bookingsSelectHandler}
                      isLoading={isLoading}
                      params={params}
                      history={this.props.history}
                      isMobileLayout={isMobileLayout}
                    />
                  )}

                  {!isMobileLayout && !isEnquiry && !isMyClients && !isSchedule && (
                    <div className={css.tabDivider} />
                  )}

                  <ul
                    className={cns(
                      css.itemList,
                      isEnquiry && css.enquiryItemList,
                      isMyClients && css.myClientItemList,
                      isBooking && css.bookingItemList,
                      isBooking && state === 'all' && css.bookingItemListAll,
                      isOrders && css.buyerBookingList,
                      isOrders && state === 'all' && css.buyerBookingListAll,
                      isSchedule && css.scheduleItemList,
                      isTransactions && css.transectionItemList,
                      (isSellerPackages || isBuyerPackages || isMyListings) &&
                        css.sellerPackagesItemList,
                      isBuyerPackages && css.buyerPackagesItemList
                    )}
                  >
                    {isSellerPackages ? (
                      <>
                        {isSellerPackages ? (
                          isMobileLayout ? (
                            <div className={css.searchWrapperMobile}>{searchWrapper}</div>
                          ) : (
                            <>{searchWrapper}</>
                          )
                        ) : null}
                        <div>
                          {fetchSellerPackageRequestError ? (
                            <FormattedMessage id="InboxPage.genericErrorMessage" />
                          ) : null}
                          {!fetchSellerPackageRequestInProgress ? (
                            fetchSellerPackageRequestError ? null : (
                              <div>
                                {sellerPackageRequestsKeywordFilteredFinal &&
                                sellerPackageRequestsKeywordFilteredFinal.length > 0 ? (
                                  sellerPackageRequestsKeywordFilteredFinal.map(
                                    toPackageRequestItem
                                  )
                                ) : (
                                  <FormattedMessage id="InboxPage.noPackageRequestsAsSeller" />
                                )}
                              </div>
                            )
                          ) : (
                            <li className={css.listItemsLoading}>
                              <IconSpinner />
                            </li>
                          )}
                        </div>
                      </>
                    ) : isBuyerPackages ? (
                      <div>
                        {fetchBuyerPackageRequestError ? (
                          <FormattedMessage id="InboxPage.genericErrorMessage" />
                        ) : null}
                        {
                          <div className={css.voucherWrapper}>
                            <div>
                              <FormattedMessage id="InboxPage.voucherHeading" />
                            </div>
                            <div>
                              {defaultVoucherAmount / 100 || '0'} {config.currency}
                            </div>
                          </div>
                        }

                        {!fetchBuyerPackageRequestInProgress ? (
                          fetchBuyerPackageRequestError ? null : (
                            <>
                              <LayoutWrapperMain className={css.staticPageWrapper}>
                                <div className={css.walletListingPanel}>
                                  <h1 className={css.title}>
                                    <FormattedMessage id="InboxPage.walletHeading" />
                                  </h1>
                                  <div className={css.walletListingCards}>
                                    {walletRequestInProgress ? (
                                      <div className={css.listingsLoading}>
                                        <IconSpinner />
                                      </div>
                                    ) : walletListings && walletListings.length > 0 ? (
                                      walletListings.map(l =>
                                        wallet[l.id.uuid] ? (
                                          <WalletListingCard
                                            className={css.walletListingCards}
                                            key={l.id.uuid}
                                            listing={l}
                                            renderSizes={walletRenderSizes}
                                            sessionsRemaining={wallet[l.id.uuid]}
                                          />
                                        ) : null
                                      )
                                    ) : (
                                      <FormattedMessage id="InboxPage.noWalletCredits" />
                                    )}
                                  </div>
                                </div>
                              </LayoutWrapperMain>
                              <div>
                                <input
                                  type="text"
                                  placeholder={searchPackageRequestPlaceholder}
                                  value={this.state.searchPackagesQuery}
                                  className={css.searchPackageBuyer}
                                  onChange={this.handlePackageRequestQueryChange}
                                />
                                {buyerPackageRequests && buyerPackageRequests.length > 0 ? (
                                  buyerPackageRequestsKeywordFiltered.map(toPackageRequestItem)
                                ) : (
                                  <FormattedMessage id="InboxPage.noPackageRequestsAsBuyer" />
                                )}
                              </div>
                            </>
                          )
                        ) : (
                          <li className={css.listItemsLoading}>
                            <IconSpinner />
                          </li>
                        )}
                      </div>
                    ) : isSellerProgressReports ? (
                      <div>
                        {fetchSellerProgressReportsRequestError ? (
                          <FormattedMessage id="InboxPage.genericErrorMessage" />
                        ) : null}
                        {!fetchSellerProgressReportsRequestInProgress ? (
                          fetchSellerProgressReportsRequestError ? null : sellerProgressReports &&
                            sellerProgressReports.length > 0 ? (
                            sellerProgressReports.map(toProgressReportItem)
                          ) : (
                            <FormattedMessage id="InboxPage.noProgressReportAsSeller" />
                          )
                        ) : (
                          <li className={css.listItemsLoading}>
                            <IconSpinner />
                          </li>
                        )}
                      </div>
                    ) : isBuyerProgressReports ? (
                      <div>
                        {fetchBuyerProgressReportsRequestError ? (
                          <FormattedMessage id="InboxPage.genericErrorMessage" />
                        ) : null}
                        {!fetchBuyerProgressReportsRequestInProgress ? (
                          fetchBuyerProgressReportsRequestError ? null : buyerProgressReports &&
                            buyerProgressReports.length > 0 ? (
                            buyerProgressReports.map(toProgressReportItem)
                          ) : (
                            <FormattedMessage id="InboxPage.noProgressReportAsBuyer" />
                          )
                        ) : (
                          <li className={css.listItemsLoading}>
                            <IconSpinner />
                          </li>
                        )}
                      </div>
                    ) : isTransactions ? (
                      <IncomeContent
                        intl={intl}
                        transactions={incomeTransactions}
                        isLoading={incomeTransactionsLoading}
                        currentUser={ensuredCurrentUser}
                        viewport={viewport}
                        period={this.state.period}
                      />
                    ) : isMyClients ? (
                      <>
                        {isMobileLayout && filterKeywordContent}
                        <Schedule
                          recentTransactions={recentTransactions}
                          selectedBookings={selectedBookings}
                          openModalSendMessage={this.onSendMessageModalOpen}
                          onAllSelectedSchedule={this.allScheduleSelectedHandler}
                          onSelect={this.bookingsSelectHandler}
                          isLoading={isLoading}
                          history={this.props.history}
                          pagination={pagination}
                          isMobileLayout={isMobileLayout}
                          params={params}
                          myClientsData={this.props.myClientsData}
                          gettingAllClientData={this.props.gettingAllClientData}
                        />
                      </>
                    ) : isScheduleClient ? (
                      <Schedule
                        transactions={ScheduleTransactions}
                        openModalSendMessage={this.onSendMessageModalOpen}
                        selectedBookings={selectedBookings}
                        onAllSelectedSchedule={this.allScheduleSelectedHandler}
                        onSelect={this.bookingsSelectHandler}
                        isLoading={isLoading}
                        params={params}
                        history={this.props.history}
                      />
                    ) : isEnquiry ? (
                      fetchInProgress ? (
                        <li className={css.listItemsLoading}>
                          <IconSpinner />
                        </li>
                      ) : (
                        <Enquiries
                          enquiries={enquiries}
                          currentUser={currentUser}
                          type={tab === 'enquiry-client' ? 'order' : 'sale'}
                          onFetchMessage={this.props.onfetchEnquiryMessages}
                          enquiryMessages={this.props.enquiryMessages}
                          onEnquirySendMessage={this.props.onEnquirySendMessage}
                          enquirySendMessageInProgress={this.props.enquirySendMessageInProgress}
                          fetchMessagesInProgress={this.props.fetchMessagesInProgress}
                          fetchMessagesError={this.props.fetchMessagesError}
                          totalMessagePages={this.props.totalMessagePages}
                          oldestMessagePageFetched={this.props.oldestMessagePageFetched}
                          viewport={viewport}
                          openNav={this.openNav}
                          isMobileLayout={isMobileLayout}
                          pagingLinks={pagingLinks}
                          onMenuIconClick={() =>
                            this.setState({ openMenuBar: !this.state.openMenuBar })
                          }
                          intl={intl}
                        />
                      )
                    ) : isMyListings ? (
                      <MyListings />
                    ) : isMyMatches ? (
                      <MyActivity />
                    ) : isHostedMatches ? (
                      <HostedMatches />
                    ) : isJoinedMatches ? (
                      <JoinedMatches />
                    ) : isMyRewards ? (
                      <MyReward />
                    ) : isProConnectRequests ? (
                      fetchInProgress ? (
                        <li className={css.listItemsLoading}>
                          <IconSpinner />
                        </li>
                      ) : (
                        enquiries.map(toTxItem)
                      )
                    ) : !fetchInProgress ? (
                      toGroupableTxItem()
                    ) : (
                      <li className={css.listItemsLoading}>
                        <IconSpinner />
                      </li>
                    )}
                    {isTransactions ||
                    isSellerPackages ||
                    isBuyerPackages ||
                    isSchedule ||
                    isBuyerProgressReports ||
                    isSellerProgressReports ||
                    isMyClients ||
                    isScheduleClient ||
                    isMyListings ||
                    isMyMatches ||
                    isHostedMatches ||
                    isJoinedMatches ||
                    isMyRewards
                      ? null
                      : noResults}
                    {isTransactions || isSchedule || isMyClients || isEnquiry ? null : pagingLinks}
                  </ul>
                </div>
                <CSVDownloadModal
                  id={'csv-download'}
                  isOpen={this.state.isCSVDownloadOpen}
                  onConfirm={downloadDeliveredTransactions}
                  toggle={this.toggleCSVDownload}
                  onManageDisableScrolling={manageDisableScrolling}
                  sortBy1={deliveredSortByClientName}
                  sortBy1Title={deliveredSortByClientNameTitle}
                  sortBy2={deliveredSortByBookingDate}
                  sortBy2Title={deliveredSortByBookingDateTitle}
                />

                <CSVDownloadModal
                  id={'csv-download-packages'}
                  isOpen={this.state.isCSVPackagesDownloadOpen}
                  onConfirm={onDownloadPackages}
                  toggle={this.toggleCSVPackagesDownload}
                  onManageDisableScrolling={manageDisableScrolling}
                  sortBy1={packageSortByListingTitle}
                  sortBy1Title={packageSortByListingTitleTitle}
                  sortBy2={packageSortByPackageBoughtDate}
                  sortBy2Title={packageSortByPackageBoughtDateTitle}
                />
              </div>
            </LayoutWrapperMain>

            <LayoutWrapperFooter
              className={cns(
                css.footer,
                this.state.activeFooterTab === 'activitySearch' && css.activityFooter
              )}
            >
              {isMobileLayout && (
                <FooterBottomMenu
                  changeActivieFooterTab={value => {
                    this.setState({ activeFooterTab: value === 'search' ? '' : value });
                    this.props.onchangingActivefooterTab(value === 'search' ? '' : value);
                  }}
                  history={this.props.history}
                  searchActivity={this.props.searchActivity}
                  isEducationSite={isEducationSite}
                  // isTennisSite={isTennisSite}
                />
              )}
            </LayoutWrapperFooter>
          </LayoutSideNavigation>
        </Page>
        <Modal
          isOpen={this.state.openMenuBar && isMobileLayout}
          onClose={() => this.setState({ openMenuBar: false })}
          id="filterModal"
          onManageDisableScrolling={() => {}}
          className={css.sideNavModal}
        >
          <TabNav
            rootClassName={css.tabs}
            isMobileLayout={isMobileLayout}
            closeModal={() => this.setState({ openMenuBar: false })}
            tabRootClassName={css.tab}
            tabs={tabsToRender}
            isInboxPage={true}
            onClick={() => this.setState({ openMenuBar: false })}
            resetPage={() => this.resetPage()}
          />
        </Modal>
        <Modal
          isOpen={this.state.activeFooterTab === 'leftbar'}
          onClose={() => {
            this.setState({ activeFooterTab: '' });
            this.props.onchangingActivefooterTab('');
          }}
          id="filterModal"
          onManageDisableScrolling={() => {}}
          className={css.modalLeftBar}
        >
          <LeftBar
            isMobileLayout={isMobileLayout}
            isEducationSite={isEducationSite}
            // isTennisSite={isTennisSite}
            history={this.props.history}
            onLogoClick={this.resetPage}
            onClose={() => {
              this.setState({ activeFooterTab: '' });
              this.props.onchangingActivefooterTab('');
            }}
          />
        </Modal>
      </>
    );
  }
}

InboxPageComponent.defaultProps = {
  unitType: config.bookingUnitType,
  currentUser: null,
  currentUserHasOrders: null,
  fetchOrdersOrSalesError: null,
  pagination: null,
  providerNotificationCount: 0,
  sendVerificationEmailError: null,
};

InboxPageComponent.propTypes = {
  params: shape({
    tab: string.isRequired,
  }).isRequired,

  unitType: propTypes.bookingUnitType,
  currentUser: propTypes.currentUser,
  fetchInProgress: bool.isRequired,
  fetchOrdersOrSalesError: propTypes.error,
  pagination: propTypes.pagination,
  providerNotificationCount: number,
  scrollingDisabled: bool.isRequired,
  transactions: arrayOf(propTypes.transaction).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    fetchInProgress,
    fetchOrdersOrSalesError,
    pagination,
    transactionRefs,
    scheduleTransactionRefs,
    packageRequests,
    fetchSellerPackageRequestInProgress,
    fetchSellerPackageRequestError,
    sellerPackageRequests,
    sellerPackagesPagination,
    fetchBuyerPackageRequestInProgress,
    fetchBuyerPackageRequestError,
    acceptPackageRequestInProgress,
    acceptPackageRequestError,
    cancelPackageRequestInProgress,
    cancelPackageRequestError,
    buyerPackageRequests,
    buyerPackagesPagination,
    fetchSellerProgressReportsRequestError,
    fetchSellerProgressReportsRequestInProgress,
    sellerProgressReports,
    fetchBuyerProgressReportsRequestError,
    fetchBuyerProgressReportsRequestInProgress,
    buyerProgressReports,
    fetchCSVDataInProgress,
    fetchCSVPackagesDataInProgress,
    incomeTransactions,
    incomeTransactionsLoading,
    enquiryRefs,
    fetchEnquiryError,
    isAnyPendingProgressReport,
    rescheduleRequestInProgress,
    rescheduleError,
    rescheduledsuccessfully,
    walletRequestError,
    walletRequestInProgress,
    walletListings,
    isLoading,
    sendMessageInProgress,
    recentTransactions,
    myClientsData,
    gettingAllClientData,
  } = state.InboxPage;

  const {
    currentUser,
    currentUserNotificationCount: providerNotificationCount,
    currentUserPackagesNotificationCount: providerPackagesNotificationCount,
    currentUserSaleEnquiriesNotificationCount: saleEnquiriesNotificationCount,
    currentUserOrderEnquiriesNotificationCount: orderEnquiriesNotificationCount,
    sellerPackageRequestsKeyword,
    buyerPackageRequestsKeyword,
  } = state.user;

  const {
    fetchMessagesInProgress,
    sendMessageInProgress: enquirySendMessageInProgress,
    fetchMessagesError,
    totalMessagePages,
    oldestMessagePageFetched,
    messages: enquiryMessages,
  } = state.TransactionPage;

  const { monthlyTimeSlots, isTimeSlotesLoading } = state.ListingPage;
  const { ownListingsRefs } = state.Schedule;
  const ownListings = getMarketplaceEntities(state, ownListingsRefs);

  const packagesBuyers = () => {
    if (sellerPackageRequestsKeyword && sellerPackageRequestsKeyword.length > 0) {
      const buyerRefs = sellerPackageRequestsKeyword.map(pr => {
        return {
          id: new UUID(pr.buyer_id),
          type: 'user',
        };
      });
      return getMarketplaceEntities(state, buyerRefs);
    } else {
      return [];
    }
  };

  const packagesListings = () => {
    const buyerListingRefs = buyerPackageRequests.map(pr => {
      return {
        id: new UUID(pr.listing_id),
        type: 'listing',
      };
    });

    const sellerListingRefs = sellerPackageRequests.map(pr => {
      return {
        id: new UUID(pr.listing_id),
        type: 'listing',
      };
    });
    const listingRefs = [...buyerListingRefs, ...sellerListingRefs];

    return getMarketplaceEntities(state, listingRefs);
  };

  const progressReportsBuyers = () => {
    if (sellerProgressReports && sellerProgressReports.length > 0) {
      const buyerRefs = sellerProgressReports.map(pr => {
        return {
          id: new UUID(pr.buyer_id),
          type: 'user',
        };
      });
      return getMarketplaceEntities(state, buyerRefs);
    } else {
      return [];
    }
  };

  const progressReportsListings = () => {
    const sellerListingRefs = sellerProgressReports.map(pr => {
      return {
        id: new UUID(pr.listing_id),
        type: 'listing',
      };
    });

    const buyerListingRefs = buyerProgressReports.map(pr => {
      return {
        id: new UUID(pr.listing_id),
        type: 'listing',
      };
    });

    const listingRefs = [...buyerListingRefs, ...sellerListingRefs];
    return getMarketplaceEntities(state, listingRefs);
  };

  const enquiries = getMarketplaceEntities(state, enquiryRefs);

  return {
    currentUser,
    fetchInProgress,
    fetchOrdersOrSalesError,
    pagination,
    providerNotificationCount,
    providerPackagesNotificationCount,
    saleEnquiriesNotificationCount,
    orderEnquiriesNotificationCount,
    fetchCSVDataInProgress,
    fetchCSVPackagesDataInProgress,
    scrollingDisabled: isScrollingDisabled(state),
    transactions: getMarketplaceEntities(state, transactionRefs),
    ScheduleTransactions: getMarketplaceEntitiesMemo(state, scheduleTransactionRefs),
    packageRequests,
    fetchSellerPackageRequestInProgress,
    fetchBuyerPackageRequestInProgress,
    sellerPackageRequests,
    sellerPackagesPagination,
    buyerPackageRequests,
    buyerPackagesPagination,
    recentTransactions,
    fetchBuyerPackageRequestError,
    acceptPackageRequestInProgress,
    acceptPackageRequestError,
    cancelPackageRequestInProgress,
    cancelPackageRequestError,
    fetchSellerPackageRequestError,
    packagesListings: packagesListings(),
    packagesBuyers: packagesBuyers(),
    progressReportsListings: progressReportsListings(),
    progressReportsBuyers: progressReportsBuyers(),
    fetchSellerProgressReportsRequestError,
    fetchSellerProgressReportsRequestInProgress,
    sellerProgressReports,
    fetchBuyerProgressReportsRequestError,
    fetchBuyerProgressReportsRequestInProgress,
    buyerProgressReports,
    incomeTransactions,
    incomeTransactionsLoading,
    enquiries,
    fetchEnquiryError,
    ownListings,
    isAnyPendingProgressReport,
    rescheduleRequestInProgress,
    rescheduleError,
    rescheduledsuccessfully,
    monthlyTimeSlots,
    isTimeSlotesLoading,
    walletRequestError,
    walletRequestInProgress,
    walletListings,
    isLoading,
    sendMessageInProgress,
    enquirySendMessageInProgress,
    fetchMessagesInProgress,
    fetchMessagesError,
    totalMessagePages,
    oldestMessagePageFetched,
    enquiryMessages,
    sellerPackageRequestsKeyword,
    buyerPackageRequestsKeyword,
    myClientsData,
    gettingAllClientData,
  };
};

const mapDispatchToProps = dispatch => ({
  transitionTransaction: params => dispatch(transitionTransaction(params)),
  manageDisableScrolling,
  downloadDeliveredTransactions: sortBy => dispatch(downloadDeliveredTransactions(sortBy)),
  onDownloadPackages: sortBy => dispatch(downloadPackages(sortBy)),
  onAcceptPackageRequest: id => dispatch(acceptPackageRequest(id)),
  onRejectPackageRequest: id => dispatch(cancelPackageRequest(id)),
  onSellerPackageRequests: (id, query) => dispatch(sellerPackageRequests(id, query)),
  onBuyerPackageRequests: (id, query) => dispatch(buyerPackageRequests(id, query)),
  removePendingProgressReports: () => dispatch(removePendingProgressReports()),
  onRescheduleBooking: params => dispatch(rescheduleBooking(params)),
  onFetchTimeSlots: (listingId, start, end, timeZone) =>
    dispatch(fetchTimeSlots(listingId, start, end, timeZone)),
  onFetchMonthlyTimeSlots: listing => dispatch(fetchMonthlyTimeSlots(listing)),
  onfetchEnquiryMessages: (txId, page) => dispatch(fetchEnquiryMessages(txId, page)),
  onSendMessage: (txId, message) => dispatch(sendMessage(txId, message)),
  onEnquirySendMessage: (txId, message) => dispatch(sendEnquiryMessage(txId, message)),
  onResetHomePage: () => dispatch(onClearSearchFilter()),
  getAllMyClientData: totalPages => dispatch(getAllMyClientData(totalPages)),
  onchangingActivefooterTab: value => dispatch(changingActivefooterTab(value)),
});

const InboxPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withViewport,
  injectIntl
)(InboxPageComponent);

InboxPage.loadData = loadData;

export default InboxPage;
