import React from 'react';
import { string, bool, node } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { Form, FieldSelect, Button, Avatar, FieldTextInput } from '../../components';
import * as validators from '../../util/validators';
import { propTypes } from '../../util/types';
import sendSvg from '../../assets/SellerPage/send.svg';
import { ReactComponent as EnquiriesSvg } from '../../assets/SellerPage/Enquiries.svg';

import css from './EnquiryForm.css';

const EnquiryFormComponent = props => {
  const { currentListing } = props;
  return (
    <FinalForm
      {...props}
      initialValues={{
        listing: props.listings && !!props.listings.length && props.listings[0].id.uuid,
        message: localStorage.getItem('enquiry_message') || null,
      }}
      render={fieldRenderProps => {
        const {
          rootClassName,
          className,
          submitButtonWrapperClassName,
          handleSubmit,
          formId,
          intl,
          authorDisplayName,
          inProgress,
          listingTitle,
          sendEnquiryError,
          listings,
          isHideMessage,
          isAuthenticated,
        } = fieldRenderProps;

        const classes = classNames(rootClassName || css.root, className);
        const submitInProgress = inProgress;
        const submitDisabled = submitInProgress;

        // const messageLabel = intl.formatMessage(
        //   {
        //     id: 'EnquiryForm.messageLabel',
        //   },
        //   { authorDisplayName }
        // );
        // const messagePlaceholder = intl.formatMessage(
        //   {
        //     id: 'EnquiryForm.messagePlaceholder',
        //   },
        //   { authorDisplayName }
        // );
        const messageRequiredMessage = intl.formatMessage({
          id: 'EnquiryForm.messageRequired',
        });
        const messageRequired = validators.requiredAndNonEmptyString(messageRequiredMessage);

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            {/* <IconEnquiry className={css.icon} /> */}
            <div className={css.titleWrapper}>
              <div className={css.svgContainer}>
                <EnquiriesSvg />
              </div>

              <h1 className={css.inboxMainHeading}>{'Enquiries'}</h1>
            </div>
            {/* {listingTitle && <h2 className={css.heading}>{listingTitle}</h2>} */}
            <div className={css.enquiryFeedHead}>
              <div className={css.profileImages}>
                <Avatar className={css.avatar} user={currentListing?.author || ''} />
              </div>
              <div className={css.profileTitle}>
                <h4>{authorDisplayName}</h4>
                <span className={css.feedUserName}>{listingTitle.props.values.listingTitle}</span>
              </div>
              {/* <div className={css.goBackIcon}>
              <img src={closeSvg} alt={'--'} />
            </div> */}
              <div className={css.feedIconDot}>
                {/* <NamedLink
                className={css.itemLink}
                name={'SaleDetailsPage'}
                params={{ id: currentTransaction.id.uuid }}
              > */}
                ...
                {/* </NamedLink> */}
              </div>
            </div>

            {listings && !!listings.length && (
              <FieldSelect
                id="listing"
                name="listing"
                className={css.field}
                label={<FormattedMessage id="EnquiryForm.listingLabel" />}
              >
                {listings.map(l => (
                  <option key={l.id.uuid} value={l.id.uuid}>
                    {l.attributes.title}
                  </option>
                ))}
              </FieldSelect>
            )}
            {!isAuthenticated && (
              <FieldTextInput
                className={css.emailfield}
                type="email"
                name="email"
                id={formId ? `${formId}.email` : 'email'}
                label={'email'}
                placeholder={'Enter your email'}
                // validate={messageRequired}
              />
            )}
            {/* {(isHideMessage ? (
              !!listings.length
            ) : (
              true
            )) ? (
              <div className={css.messageToolsWrapper}>
                <div className={css.SendMsgKeywordWrap}>
                  <div className={css.MsgWrap}>
                    <div className={css.sendInputWrap}>
                      <FieldTextInput
                        className={css.field}
                        type="textarea"
                        name="message"
                        id={formId ? `${formId}.message` : 'message'}
                        // label={messageLabel}
                        placeholder={`Tell ${authorDisplayName} exactly what you are looking for or want to find out and your times of availability.`}
                        validate={messageRequired}
                      />
                      <Button
                        className={css.sendButton}
                        type="submit"
                        inProgress={submitInProgress}
                        disabled={submitDisabled}
                      >
                        <img src={sendSvg} alt={' '} />
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className={css.noListing}>
                <FormattedMessage id="EnquiryForm.nolistingAvailable" />
              </div>
            )} */}
            {isHideMessage && (
              <div className={css.messageToolsWrapper}>
                <div className={css.SendMsgKeywordWrap}>
                  <div className={css.MsgWrap}>
                    <div className={css.sendInputWrap}>
                      <FieldTextInput
                        className={css.field}
                        type="textarea"
                        name="message"
                        id={formId ? `${formId}.message` : 'message'}
                        placeholder={`Tell ${authorDisplayName} exactly what you are looking for or want to find out and your times of availability.`}
                        validate={messageRequired}
                      />
                      <Button
                        className={css.sendButton}
                        type="submit"
                        inProgress={submitInProgress}
                        disabled={submitDisabled}
                      >
                        <img src={sendSvg} alt={' '} />
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className={submitButtonWrapperClassName}>
              {sendEnquiryError ? (
                <p className={css.error}>
                  <FormattedMessage id="EnquiryForm.sendEnquiryError" />
                </p>
              ) : null}
              {/* <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
              <FormattedMessage id="EnquiryForm.submitButtonText" />
            </PrimaryButton> */}
            </div>
          </Form>
        );
      }}
    />
  );
};

EnquiryFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  inProgress: false,
  sendEnquiryError: null,
};

EnquiryFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  inProgress: bool,

  listingTitle: node.isRequired,
  authorDisplayName: string,
  sendEnquiryError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,
};

const EnquiryForm = compose(injectIntl)(EnquiryFormComponent);

EnquiryForm.displayName = 'EnquiryForm';

export default EnquiryForm;
