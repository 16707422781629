import React, { useCallback } from 'react';
import { NamedLink } from 'components';
import {
  // boxingData,
  // boxingWrapperColumn,
  // danceData,
  // golfData,
  // indoreCyclingData,
  // kickboxingData,
  // massageDancingData,
  // massagesData,
  mobileTennisPilatedData,
  personalFitnessData,
  personalGolfData,
  pilatesData,
  // poleDancingData,
  // rockClimbingData,
  // surfingData,
  // swimmimgData,
  // swimmingSurffingData,
  tennisData,
  // vollyBallData,
  // vollyballMountainData,
  // yogaBoxingData,
  yogaData,
  educationCategory,
} from './CategoriesPage.data';
import classNames from 'classnames';
import css from './CategoriesPage.css';

const CategoryDataContainer = props => {
  const { isEducationSite, isMobileLayout } = props;

  const getCategoriesData = useCallback(() => {
    return [
      {
        dataArray: [tennisData, yogaData], //column1,
        wrapperClassName: css.boxWrap,
      },
      {
        dataArray: [pilatesData, personalFitnessData], // column2, kickboxingData
        wrapperClassName: classNames(css.boxWrap, css.boxWrapReverse),
      },
      // {
      //   dataArray: [], // column3, boxingData danceData
      //   wrapperClassName: css.sportsWrap,
      // },
      // {
      //   dataArray: [], //column4,
      //   wrapperClassName: css.boxLargeWrap,
      // },
      // {
      //   dataArray: [swimmimgData], // column5,surfingData
      //   wrapperClassName: css.boxWrap,
      // },
      // {
      //   dataArray: [], // column6,
      //   wrapperClassName: classNames(css.boxWrap, css.boxWrapReverse),
      // },
      // {
      //   dataArray: [], //column7,
      //   wrapperClassName: classNames(css.boxWrap, css.boxWrapReverse),
      // },
    ];
  }, []);

  const categoryData = getCategoriesData();

  const categoryRender = (dataArray, wrapperClassName, itemClassName) => {
    return (
      <div className={wrapperClassName}>
        {dataArray.map(category => {
          return (
            <NamedLink className={itemClassName} name={category.page} params={category?.params}>
              <div className={css.categoriesItemImage}>
                <img src={category.imgSrc} alt={' '} />
                <div className={css.shadowContainer}></div>
              </div>
              <div className={css.categoriesItemTitle}>{category.title}</div>
            </NamedLink>
          );
        })}
      </div>
    );
  };

  return (
    <div>
      {isEducationSite ? (
        <>{categoryRender(educationCategory, css.categoryItemWrap, css.categoryItem)}</>
      ) : !isMobileLayout ? (
        <div className={css.mainGallaryWrapper}>
          {categoryData &&
            categoryData?.map(data => (
              <div className={data?.wrapperClassName}>
                {data?.dataArray?.map(category => {
                  return (
                    <NamedLink
                      className={css.categoriesItem}
                      name={category?.page}
                      params={category?.params}
                    >
                      <div className={css.categoriesItemImage}>
                        <img src={category.imgSrc} alt={' '} />
                        <div className={css.shadowContainer}></div>
                      </div>
                      <div className={css.categoriesItemTitle}>{category.title}</div>
                    </NamedLink>
                  );
                })}
              </div>
            ))}
        </div>
      ) : (
        <div className={css.mobileGalleryWrapper}>
          {categoryRender(mobileTennisPilatedData, css.mobileItemWrapperMain, css.mobileColumnItem)}
          {categoryRender(
            personalGolfData,
            classNames(css.mobileItemWrapperMain, css.mobileItemWrapperMainReverse),
            css.mobileColumnItem
          )}
          {/* {categoryRender(yogaBoxingData, css.boxWrapAlign, css.categoriesItem)} */}

          {/* <div className={css.boxWrapMobile}>
            {/* {categoryRender(massageDancingData, css.boxWrapItem, css.categoriesItem)} 
            {categoryRender(boxingWrapperColumn, css.boxWrapItem, css.categoriesItem)}
          </div> */}
          {/* {categoryRender(vollyballMountainData, css.boxWrapAlign, css.categoriesItem)} */}
          {/* {categoryRender(swimmingSurffingData, css.boxWrapAlign, css.categoriesItem)} */}
        </div>
      )}
    </div>
  );
};

export default CategoryDataContainer;
