import React, { useState, useEffect } from 'react';
import { uid } from 'react-uid';
import { omit } from 'lodash/object';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import classNames from 'classnames';
import { propTypes } from 'util/types';
import { FormattedMessage, injectIntl, intlShape } from 'util/reactIntl';
import { Form as FinalForm, Field } from 'react-final-form';
import { Button, Form, FieldTextInput, FieldSelect } from '../../components';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import { SecondaryButton } from '../../components';
import { getExtensionFromUrl } from 'util/urlHelpers';
import { socialMedia } from 'marketplace-custom-config';

import css from './EditListingSocialLinksForm.css';

const MAX_FIELDS = 7;

export const EditListingSocialLinksForm = props => {
  const { intl } = props;
  const [assets, setAssets] = useState({});
  const [isAssetUpdated, setIsAssetUpdated] = useState(false);
  const [pictureError, setPictureError] = useState(false);

  useEffect(() => {
    if (props.initialValues.socials && props.initialValues.socials.length) {
      const assetsObj = {};
      props.initialValues.socials.forEach(item => {
        assetsObj[item.id] = item.asset;
      });
      setAssets(assetsObj);
    }
  }, [props.initialValues]);

  const uploadWidget = (e, uniqId) => {
    e.preventDefault();
    if (typeof window !== 'undefined') {
      window.cloudinary.openUploadWidget(
        { cloud_name: 'movementbuddy-pte-ltd', upload_preset: 'movementbuddy' },
        function(error, result) {
          if (result && result.info && result.info.secure_url) {
            // setAssets({
            //   url: result.info.secure_url,
            //   type: result.info.resource_type,
            // });
            setAssets({
              ...assets,
              [uniqId]: { url: result.info.secure_url, type: result.info.resource_type },
            });
          }
          setIsAssetUpdated(true);
          setPictureError(false);
        }
      );
    }
  };

  const submitHandler = values => {
    const normalizedExpectations = values.socials
      ? values.socials.map(item => {
          return {
            ...item,
            asset: assets[item.id],
          };
        })
      : [];

    if (normalizedExpectations.some(item => !item.asset)) {
      setPictureError(true);
      return;
    }

    props.onSubmit({ socials: normalizedExpectations });
  };

  const omitPictureOnPop = values => {
    if (!values || !values.length) {
      return;
    }

    const lastItem = values[values.length - 1];
    setAssets({
      ...omit(assets, lastItem.id),
    });
  };

  const required = value => (value ? undefined : 'Required');
  const filedArrayRequired = value => {
    if (value && value.length < 1) {
      return 'Require at least one entry';
    }

    return {};
  };

  const categoryLabel = intl.formatMessage({
    id: 'EditListingDetailsForm.socialCategoryLabel',
  });
  const categoryPlaceholder = intl.formatMessage({
    id: 'EditListingDetailsForm.categoryPlaceholder',
  });

  const categoryRequired = required(
    intl.formatMessage({
      id: 'EditListingDetailsForm.categoryRequired',
    })
  );

  // const addExpectation = () => {
  //   setTestArr([
  //     ...testArr,
  //     {
  //       assets: assets,
  //       id: uniqId,
  //       text: expectation,
  //     },
  //   ]);
  //   setUniqId(prev => prev + 1);
  // };

  return (
    <FinalForm
      {...props}
      mutators={{
        ...arrayMutators,
      }}
      initialValues={props.initialValues}
      onSubmit={submitHandler}
    >
      {formRenderProps => {
        const classes = classNames(css.root, formRenderProps.className);
        const {
          // form,
          mutators: { push, pop },
        } = formRenderProps.form;

        const submitInProgress = formRenderProps.updateInProgress;
        const submitReady = formRenderProps.updated || formRenderProps.ready;
        const submitDisabled =
          (formRenderProps.invalid ||
            formRenderProps.disabled ||
            formRenderProps.submitInProgress ||
            formRenderProps.pristine) &&
          !isAssetUpdated;

        return (
          <Form onSubmit={formRenderProps.handleSubmit} className={classes}>
            {pictureError ? (
              <p className={css.error}>
                <FormattedMessage id="EditListingServicesForm.pictureError" />
              </p>
            ) : null}

            <FieldArray name={'socials'} validate={filedArrayRequired}>
              {({ fields }) => {
                return fields.map((name, index) => {
                  {
                    const uniqId =
                      (props.initialValues.socials &&
                        props.initialValues.socials.length &&
                        props.initialValues.socials.length <= formRenderProps.values.socials &&
                        props.initialValues.socials[index].id) ||
                      uid(name, index);
                    return (
                      <div className={css.expectation}>
                        <Field
                          className={css.hiddenField}
                          component="input"
                          id={'expectation-id'}
                          name={`${name}.id`}
                          initialValue={uniqId}
                        />

                        {/* <FieldTextInput
                          key={uniqId}
                          id="services"
                          name={`${name}.text`}
                          type="text"
                          className={css.textInput}
                          autoFocus
                          label={<FormattedMessage id={'EditListingExpectationsForm.textLabel'} />}
                          validate={required}
                        /> */}
                        <FieldSelect
                          className={css.detailsSelect}
                          name={`${name}.social`}
                          id="socials"
                          label={categoryLabel}
                          validate={categoryRequired}
                        >
                          <option disabled value="">
                            {categoryPlaceholder}
                          </option>
                          {socialMedia.map(c => (
                            <option key={c.key} value={c.key}>
                              {c.label}
                            </option>
                          ))}
                        </FieldSelect>

                        <FieldTextInput
                          key={uniqId}
                          id="socials"
                          name={`${name}.url`}
                          type="url"
                          className={css.textInput}
                          autoFocus
                          label={
                            <FormattedMessage id={'EditListingExpectationsForm.socialMediaUrl'} />
                          }
                          validate={required}
                        />
                        {/* <CustomFieldTextInput
                          key={uniqId}
                          type="text"
                          id="services"
                          name={`${name}.text`}
                          label={<FormattedMessage id={'EditListingExpectationsForm.textLabel'} />}
                          placeholder="Enter Name"
                          validate={required}
                        /> */}

                        {assets[uniqId] ? (
                          <div>
                            <h4 className={css.pictureHeading}>
                              <FormattedMessage id="EditListingExpectationsForm.pictureHeading" />
                            </h4>
                            <div className={css.pictureContainer}>
                              {assets[uniqId].type === 'video' ? (
                                <video src={assets[uniqId].url} controls width="100%">
                                  <source
                                    src={assets[uniqId].url}
                                    type={`video/${getExtensionFromUrl(assets[uniqId].url)}`}
                                  />
                                </video>
                              ) : (
                                <a
                                  className={css.picture}
                                  // eslint-disable-next-line
                                  target="_blank"
                                  href={assets[uniqId].url}
                                >
                                  <img src={assets[uniqId].url} alt="formImage" />
                                </a>
                              )}
                            </div>
                          </div>
                        ) : null}

                        <div>
                          <button
                            className={css.uploadPictureBtn}
                            onClick={e => uploadWidget(e, uniqId)}
                          >
                            <FormattedMessage id="EditListingServicessForm.uploadAsset" />
                          </button>
                        </div>
                      </div>
                    );
                  }
                });
              }}
            </FieldArray>

            <div className={css.mutateBtns}>
              <SecondaryButton
                disabled={
                  formRenderProps.values.socials &&
                  formRenderProps.values.socials.length === MAX_FIELDS
                }
                type="button"
                onClick={() => push('socials', {})}
                // onClick={addExpectation}
              >
                <FormattedMessage id={'EditListingExpectationsForm.addSocialMediaLinks'} />
              </SecondaryButton>

              <SecondaryButton
                type="button"
                onClick={() => {
                  pop('socials');
                  omitPictureOnPop(formRenderProps.values.socials);
                }}
              >
                <FormattedMessage id={'EditListingExpectationsForm.removeSocialMediaLinks'} />
              </SecondaryButton>
            </div>
            <div className={css.submitButtonContainer}>
              <Button
                className={css.submitButton}
                type="submit"
                inProgress={submitInProgress}
                // disabled={submitDisabled}
                ready={submitReady}
              >
                {formRenderProps.saveActionMsg}
              </Button>
            </div>
          </Form>
        );
      }}
    </FinalForm>
  );
};

EditListingSocialLinksForm.defaultProps = { fetchErrors: null };

EditListingSocialLinksForm.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingSocialLinksForm);
