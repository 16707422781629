import React from 'react';
import PropTypes from 'prop-types';
import { capitalize } from 'lodash';
import { uid } from 'react-uid';
import cns from 'classnames';
import { propTypes } from 'util/types';
import { SecondaryButton } from 'components';

import css from './OperatingHours.css';
import { FormattedMessage } from 'util/reactIntl';

function OperatingHours(props) {
  const { availabilityPlan, className, onEditClick } = props;
  const classes = cns({
    [css.operatingHoursContainer]: true,
    [className]: !!className,
  });

  return (
    <div className={classes}>
      <div className={css.header}>
        <div className={css.headerText}>
          <FormattedMessage id={'CalendarPage.operatingHours'} />
        </div>
        <div className={css.editLink}>
          <SecondaryButton className={css.editBtn} onClick={onEditClick}>
            Edit
          </SecondaryButton>
        </div>
      </div>

      {!!availabilityPlan && !!availabilityPlan.entries && !!availabilityPlan.entries.length && (
        <div className={css.entries}>
          {availabilityPlan.entries.map(entry => (
            <div key={uid(entry)} className={css.entry}>
              <div className={css.days}>{capitalize(entry.dayOfWeek)}</div>
              <div className={css.time}>{`${entry.startTime} - ${entry.endTime}`}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

OperatingHours.propTypes = {
  className: PropTypes.string,
  availabilityPlan: propTypes.availabilityPlan,
  onEditClick: PropTypes.func,
};

export default OperatingHours;
