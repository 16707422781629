import React from 'react';
import { ResponsiveImage } from 'components';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
  EmailIcon,
  FacebookIcon,
  WhatsappIcon,
  WhatsappShareButton,
  FacebookShareButton,
  EmailShareButton,
} from 'react-share';
import { useAlert } from 'react-alert';

import { ReactComponent as CopySvg } from '../../assets/HeaderPart/CopySvg.svg';
import css from './ShareForm.css';

const ShareForm = props => {
  const { listing, url } = props;
  const backgroundImage = listing?.images.slice(0, 1);
  const listingTitle = listing?.attributes.title;
  const alert = useAlert();
  // const icons = [
  //   {
  //     name: 'Facebook',
  //     icon: <FacebookIcon borderRadius={15} />,
  //     link: 'https://www.facebook.com/',
  //   },
  //   {
  //     name: 'Copy link',
  //     icon: <CopySvg />,
  //     link: 'NoLink',
  //   },
  //   {
  //     name: 'WhatsApp',
  //     icon: <WhatsappIcon borderRadius={15} />,
  //     link: 'https://web.whatsapp.com/',
  //   },
  //   {
  //     name: 'Email',
  //     icon: <EmailIcon borderRadius={15} />,
  //     link: 'https://www.gmail.com/',
  //   },
  // ];

  // const GoToLink = link => {
  //   if (link !== 'NoLink' && window !== 'undefined') window.open(link, '_blank', 'noreferrer');
  // };

  return (
    <div className={css.shareFormWrapper}>
      {listing && (
        <div>
          <h3 className={css.shareFormTitle}>Share this listing with friends and family</h3>

          <div className={css.shareFormListingWrapper}>
            <div className={css.shareFormImageContainer}>
              <ResponsiveImage
                src={backgroundImage[0]}
                image={backgroundImage[0]}
                variants={['square-small', 'square-small2x']}
                alt="title"
              />
            </div>
            <h4 className={css.shareFormListingTitle}>{listingTitle}</h4>
          </div>
        </div>
      )}

      <div className={css.shareFormNameIconWrapper}>
        {/* {icons.map(icon => {
          return (
            <CopyToClipboard text={url}>
              <div
                className={css.shareFormIconWrapper}
                onClick={() => {
                  alert.show('Link copied!');
                  GoToLink(icon.link);
                }}
              >
                <div className={css.iconName}>{icon.name}</div>
                <div className={css.iconWrap}>{icon.icon}</div>
              </div>
            </CopyToClipboard>
          );
        })} */}
        <FacebookShareButton url={url}>
          <div className={css.iconName}>{'Facebook'}</div>
          <FacebookIcon className={css.iconWrap} borderRadius={15} />
        </FacebookShareButton>
        <CopyToClipboard text={url}>
          <div
            className={css.shareFormIconWrapper}
            onClick={() => {
              alert.show('Link copied!');
            }}
          >
            <div className={css.iconName}>{'Copy Link'}</div>
            <CopySvg className={css.iconWrap} />
          </div>
        </CopyToClipboard>
        <WhatsappShareButton url={url}>
          <div className={css.iconName}>{'WhatsApp'}</div>
          <WhatsappIcon className={css.iconWrap} borderRadius={15} />
        </WhatsappShareButton>
        <EmailShareButton url={url}>
          <div className={css.iconName}>{'Email'}</div>
          <EmailIcon className={css.iconWrap} borderRadius={15} />
        </EmailShareButton>
      </div>
    </div>
  );
};

export default ShareForm;
