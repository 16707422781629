import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Loading } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import { getRecommendationData } from 'containers/HomePage/HomePage.duck';
import MyRewardList from './MyRewardList';
import css from './MyReward.css';

function MyReward() {
  const dispatch = useDispatch();

  const { recommendationList, isLoading } = useSelector(state => state.HomePage);

  const getListingData = async () => {
    dispatch(getRecommendationData());
  };

  useEffect(() => {
    getListingData();
  }, []);

  return (
    <div className={css.myRewardMain}>
      {isLoading && <Loading />}
      {!isLoading &&
        recommendationList.length !== 0 &&
        recommendationList.map(recommendedData => (
          <MyRewardList recommendedData={recommendedData} />
        ))}
      {!isLoading && recommendationList.length === 0 && (
        <FormattedMessage id="HomePage.NoRewardsFound" />
      )}
    </div>
  );
}

export default MyReward;
