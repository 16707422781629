import React, { useState } from 'react';
import { compose } from 'redux';
import { string, bool, node } from 'prop-types';
import * as validators from 'util/validators';
import { propTypes } from 'util/types';
import { FormattedMessage, injectIntl, intlShape } from 'util/reactIntl';
import { CustomFieldTextInput, FieldTextInput, Form } from 'components';
import { Field, Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';
import css from './NewGetQuoteForm.css';
import { getQuoteTrack } from 'util/gtag_conversionTracking';

const NewGetQuoteFormComponent = props => {
  return (
    <FinalForm
      {...props}
      onSubmit={e => e.preventDefault()}
      mutators={{ ...arrayMutators }}
      render={fieldRenderProps => {
        const {
          rootClassName,
          className,
          formId,
          intl,
          handleSubmit,
          isEducationSite,
        } = fieldRenderProps;

        const formClasses = classNames(rootClassName || css.root, className);
        const emailRequired = validators.requiredAndNonEmptyString('Please provide contact mail');
        const messageRequired = validators.requiredAndNonEmptyString('Please provide some text');
        const placeholderText = intl.formatMessage({ id: 'NewGetQuoteForm.infoText' });
        return (
          <Form
            className={formClasses}
            onSubmit={e => {
              let email = undefined;
              if (!props?.isAuthenticated) email = e.target.email.value;
              const message = e.target.message.value;

              handleSubmit({ message, email });
              getQuoteTrack();
            }}
          >
            <div className={css.quotesFormWrapper}>
              <h4>
                <FormattedMessage id="NewGetQuoteForm.title" />
              </h4>
              {!props?.isAuthenticated && (
                <FieldTextInput
                  className={css.emailfield}
                  type="email"
                  name="email"
                  id={formId ? `${formId}.email` : 'email'}
                  placeholder={'Enter your contact email'}
                  validate={emailRequired}
                />
              )}
              <FieldTextInput
                className={css.textField}
                type="textarea"
                name="message"
                id={formId ? `${formId}.message` : 'message'}
                placeholder={placeholderText || 'some info text'}
                validate={messageRequired}
              />
              <div className={css.btnWrap}>
                <button type="submit" className={css.btnQuote}>
                  <FormattedMessage id="CategoriesPage.quoteButtonText" />
                </button>
              </div>
            </div>
          </Form>
        );
      }}
    />
  );
};

NewGetQuoteFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  inProgress: false,
  sendEnquiryError: null,
};

NewGetQuoteFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  isAuthenticated: bool,
  inProgress: bool,
  authorDisplayName: string,
  sendEnquiryError: propTypes.error,
  intl: intlShape.isRequired,
};

const NewGetQuoteForm = compose(injectIntl)(NewGetQuoteFormComponent);

export default NewGetQuoteForm;
