import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'util/reactIntl';
import WhatYouCanExpectItem from 'components/WhatYouCanExpectItem/WhatYouCanExpectItem';

import css from './styles.css';

function SectionWhatYouCanExpect(props) {
  const { data, withBackground=false } = props;

  return (
    <div className={css.whatYouCanExpectHead}>
      <h2>
        <FormattedMessage id={'ListingPage.whatYouCanExpectHeading'} />
      </h2>
      <div className={css.WhatYouCanExpectItem}>
        {data.map((item, index) => (
          <WhatYouCanExpectItem
            key={item.id}
            withBackground={withBackground}
            reverse={index % 2 !== 0 ? true : false}
            item={item}
          />
        ))}
      </div>
    </div>
  );
}

SectionWhatYouCanExpect.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
};

export default SectionWhatYouCanExpect;
