/* 

queAnsList's keys supports be multi-key : single-value

question : question text
options : options list
type : options show type [checkbox | radio | text | number]
noOtherOption* : true --> don't show the other option as text input
infoText* : help/info regarding question

* are not compulsory for all fields

*/

import { expandObject } from 'util/data';

const queAnsList = {
  default: [
    {
      question: 'What is your current level of experience?',
      options: ['No experience', 'Beginner', 'Intermediate', 'Advanced'],
      type: 'radio',
    },
    {
      question: 'Have you worked with a personal trainer before?',
      options: ['Yes', 'No'],
      type: 'radio',
      noOtherOption: true,
    },
    {
      question: 'Time preference(s)',
      options: [
        'Early morning (before 9am)',
        'Morning (9am-noon)',
        'Early afternoon (noon-3pm)',
        'Late afternoon (3-6pm)',
        'Evening (after 6pm)',
      ],
      type: 'checkbox',
    },
    {
      question: 'Day preference(s)',
      options: ['Monday', 'Tuesday', 'Wednesday', 'Thrusday', 'Friday', 'Saturday', 'Sunday'],
      type: 'checkbox',
      noOtherOption: true,
    },
    {
      question: 'How often would you like your lessons?',
      options: [
        'Once off',
        'Once a week',
        'More than once a week',
        'Once or twice a month',
        'As recommended by professional',
      ],
      type: 'radio',
    },
    {
      question: 'How would you like to receive this service, remotely or in-person?',
      options: [
        'I would prefer remote - Video call etc',
        "I don't mind, either are fine",
        'I would prefer in-person',
      ],
      type: 'radio',
      noOtherOption: true,
    },
  ],
  education: [
    {
      question: 'What is your current level of experience?',
      options: ['No experience', 'Beginner', 'Intermediate', 'Advanced'],
      type: 'radio',
    },
    {
      question: 'Have you worked with a Tutor before?',
      options: ['Yes', 'No'],
      type: 'radio',
      noOtherOption: true,
    },
    {
      question: 'Time preference(s)',
      options: [
        'Early morning (before 9am)',
        'Morning (9am-noon)',
        'Early afternoon (noon-3pm)',
        'Late afternoon (3-6pm)',
        'Evening (after 6pm)',
      ],
      type: 'checkbox',
    },
    {
      question: 'How would you like to receive this service, remotely or in-person?',
      options: [
        'I would prefer remote - Video call etc',
        "I don't mind, either are fine",
        'I would prefer in-person',
      ],
      type: 'radio',
      noOtherOption: true,
    },
  ],
  tennis: [
    {
      question: "What is the student's current level of experience?",
      options: ['No experience', 'Beginner', 'Intermediate', 'Advanced'],
      type: 'radio',
    },
    {
      question: "What is the student's age?",
      options: ['Child', 'Pre-teen', 'Teenager', 'Adult'],
      type: 'radio',
    },
    {
      question: 'How often would you like your lessons?',
      options: [
        'Once off',
        'Once a week',
        'More than once a week',
        'Once or twice a month',
        'As recommended by professional',
      ],
      type: 'radio',
    },
    {
      question: 'Do you have a Tennis court access?',
      options: [
        'I have my Own Court',
        'I dont have a court but willing to travel up to 5 km',
        'I dont have a court but can travel most places',
      ],
      type: 'radio',
    },
    {
      question: 'Time preference(s)',
      options: [
        'Early morning (before 9am)',
        'Morning (9am-noon)',
        'Early afternoon (noon-3pm)',
        'Late afternoon (3-6pm)',
        'Evening (after 6pm)',
      ],
      type: 'checkbox',
    },
  ],
  'padel-tennis': [
    {
      question: "What is the student's current level of experience?",
      options: ['No experience', 'Beginner', 'Intermediate', 'Advanced'],
      type: 'radio',
    },
    {
      question: "What is the student's age?",
      options: ['Child', 'Pre-teen', 'Teenager', 'Adult'],
      type: 'radio',
    },
    {
      question: 'How often would you like your lessons?',
      options: [
        'Once off',
        'Once a week',
        'More than once a week',
        'Once or twice a month',
        'As recommended by professional',
      ],
      type: 'radio',
    },

    {
      question: 'Do you have a Padel Tennis court access?',
      options: [
        'I have my Own Court',
        'I dont have a court but willing to travel up to 5 km',
        'I dont have a court but can travel most places',
      ],
      type: 'radio',
    },
    {
      question: 'Time preference(s)',
      options: [
        'Early morning (before 9am)',
        'Morning (9am-noon)',
        'Early afternoon (noon-3pm)',
        'Late afternoon (3-6pm)',
        'Evening (after 6pm)',
      ],
      type: 'checkbox',
    },
  ],
  'pickle-ball': [
    {
      question: "What is the student's current level of experience?",
      options: ['No experience', 'Beginner', 'Intermediate', 'Advanced'],
      type: 'radio',
    },
    {
      question: "What is the student's age?",
      options: ['Child', 'Pre-teen', 'Teenager', 'Adult'],
      type: 'radio',
    },
    {
      question: 'How often would you like your lessons?',
      options: [
        'Once off',
        'Once a week',
        'More than once a week',
        'Once or twice a month',
        'As recommended by professional',
      ],
      type: 'radio',
    },
    {
      question: 'Do you have a Pickle Ball court access?',
      options: [
        'I have my Own Court',
        'I dont have a court but willing to travel up to 5 km',
        'I dont have a court but can travel most places',
      ],
      type: 'radio',
    },
    {
      question: 'Time preference(s)',
      options: [
        'Early morning (before 9am)',
        'Morning (9am-noon)',
        'Early afternoon (noon-3pm)',
        'Late afternoon (3-6pm)',
        'Evening (after 6pm)',
      ],
      type: 'checkbox',
    },
  ],
  yoga: [
    {
      question: 'What is your current level of experience?',
      options: ['No experience', 'Beginner', 'Intermediate', 'Advanced'],
      type: 'radio',
    },
    {
      question: 'How would you like to receive this service, remotely or in-person?',
      options: [
        'I would prefer remote - Video call etc',
        "I don't mind, either are fine",
        'I would prefer in-person',
      ],
      type: 'radio',
      noOtherOption: true,
    },
    {
      question: 'Session frequency',
      options: [
        'Once off',
        'Once a week',
        'More than once a week',
        'Once or twice a month',
        'As recommended by professional',
      ],
      type: 'radio',
    },
    {
      question: 'Time preference(s)',
      options: [
        'Early morning (before 9am)',
        'Morning (9am-noon)',
        'Early afternoon (noon-3pm)',
        'Late afternoon (3-6pm)',
        'Evening (after 6pm)',
      ],
      type: 'checkbox',
    },
  ],
  'personal-training': [
    {
      question: 'Have you worked with a personal trainer before?',
      options: ['Yes', 'No'],
      type: 'radio',
      noOtherOption: true,
    },
    {
      question: 'Do you have a preference for the gender of the trainer?',
      options: [
        'Male only',
        'Prefer male, would consider female',
        "It doesn't matter",
        'Prefer female, would consider male',
        'Female only',
      ],
      type: 'radio',
    },
    {
      question: 'How frequently do you want your sessions?',
      options: [
        'Once off',
        'Once a week',
        'Twice a week',
        'Several times a week',
        'Daily',
        "I'm not sure yet",
      ],
      type: 'radio',
    },
    {
      question: 'Do you have any time preference(s)?',
      infoText: 'For specific times select "Other" and enter details',
      options: [
        'Any time',
        'Early morning (before 9am)',
        'Morning (9am-noon)',
        'Early afternoon (noon-3pm)',
        'Late afternoon (3-6pm)',
        'Evening (after 6pm)',
      ],
      type: 'checkbox',
    },
  ],
  'english,chinese': [
    {
      question: 'Do you have any time preference(s)?',
      infoText: 'For specific times select "Other" and enter details',
      options: [
        'Any time',
        'Early morning (before 9am)',
        'Morning (9am-noon)',
        'Early afternoon (noon-3pm)',
        'Late afternoon (3-6pm)',
        'Evening (after 6pm)',
      ],
      type: 'checkbox',
    },
    {
      question: "What is the student's education level?",
      options: [
        'Pre-Primary',
        'Primary School (age 4-11)',
        'Secondary Education (age 11-16)',
        'College and 6th Form (age 16-18)',
        'University',
        'Post Graduate',
        'Adult Education',
      ],
      type: 'radio',
    },
    {
      question: "We'll find you a local tutor!",
      infoText: 'Instantly find the best available tutors',
      options: [
        'I want to find a tutor as soon as possible',
        'I am looking to find a tutor within the next few days',
        'I am looking to find a tutor within the next few weeks',
        'I plan to hire a tutor and am getting ideas of what options and prices are available',
        'I want an idea of prices and may or may not hire a tutor',
      ],
      type: 'radio',
    },
    {
      question: 'How often would you like tutoring?',
      infoText: 'For specific times select "Other" and enter details',
      options: [
        'More than once a week',
        'Weekly',
        'Once or twice a month',
        "I'm not sure yet",
        'I would like to discuss with the tutor',
      ],
      type: 'radio',
    },
  ],
};

const IMG_BASE_URL = 'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload';

const bannerImgList = {
  tennis: `${IMG_BASE_URL}/v1686815866/probuddy/static/images/GetQuotesModal/GetQuotesModal_BannerImg_Tennis_b8dxpq.png`,
  yoga: `${IMG_BASE_URL}/v1686815865/probuddy/static/images/GetQuotesModal/GetQuotesModal_BannerImg_Yoga_b8ubno.png`,
  'personal-training': `${IMG_BASE_URL}/v1686815866/probuddy/static/images/GetQuotesModal/GetQuotesModal_BannerImg_Personal_Training_and_Physio_eqdtib.png`,
  english: `${IMG_BASE_URL}/v1686815866/probuddy/static/images/GetQuotesModal/GetQuotesModal_BannerImg_English_Tutor_qr7ttj.png`,
  chinese: `${IMG_BASE_URL}/v1686815865/probuddy/static/images/GetQuotesModal/GetQuotesModal_BannerImg_Chinese_Tutor_wtsbyt.png`,
  sports:
    'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675340847/probuddy/static/images/CategoriesPage/sports/SportsHeroImage_lpluon.png',
  wellness:
    'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675484166/probuddy/static/images/CategoriesPage/Wellness/WellnessHeroImage_cdct6e.png',
  adventure:
    'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677222824/probuddy/static/images/CategoriesPage/Adventure/AdventureHeroImage_mm5ree.png',
  boxing:
    'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660379691/probuddy/static/images/CategoriesPage/Boxing/BoxingHeroImage_hhxmln.png',
  pilates:
    'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660825608/probuddy/static/images/CategoriesPage/Pilates/PilatesHeroImage_l4g9vx.png',
  'pole-fitness':
    'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660892018/probuddy/static/images/CategoriesPage/Pole%20Fitness/PoleFitnessHeroImage_dhjlc4.png',
  kickboxing:
    'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660895471/probuddy/static/images/CategoriesPage/Kickboxing/KickboxingHeroImage_dzrpvw.png',
  'kick-boxing':
    'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660895471/probuddy/static/images/CategoriesPage/Kickboxing/KickboxingHeroImage_dzrpvw.png',
  'kick boxing':
    'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660895471/probuddy/static/images/CategoriesPage/Kickboxing/KickboxingHeroImage_dzrpvw.png',
  swimming:
    'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661157771/probuddy/static/images/CategoriesPage/Swimming/SwimmingHeroImage_yfprpx.png',
  dance:
    'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661235853/probuddy/static/images/CategoriesPage/Dance/DanceHeroImage_hyymgp.png',
  volleyball:
    'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661333829/probuddy/static/images/CategoriesPage/Volleyball/VolleyballHeroImage_hijoax.png',
  'rock-climbing':
    'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661502112/probuddy/static/images/CategoriesPage/Rock%20Climbing/RockClimbingHeroImage_ud8hhk.png',
  surfing:
    'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661947331/probuddy/static/images/CategoriesPage/Surfing/SurfingHeroImage_yq8pda.png',
  golf:
    'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1662124080/probuddy/static/images/CategoriesPage/Golf/GolfHeroImage_xfgdm3.png',
  'personal-training':
    'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663847133/probuddy/static/images/CategoriesPage/Personal%20Trainer/PTHeroImage_fu6hom.png',
  'indoor-cycling':
    'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663851013/probuddy/static/images/CategoriesPage/Indoor%20Cycling/ICHeroImage_baqpuk.png',
  'massage-spa':
    'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663853444/probuddy/static/images/CategoriesPage/Massage%20and%20Spa/MassageHeroImage_kccuey.png',
};

export const expandedQueAnsList = expandObject(queAnsList);
export const expandedBannerImgList = expandObject(bannerImgList);

export const getQueAnsList = param => {
  if (Object.keys(expandedQueAnsList).includes(param)) return expandedQueAnsList[param];
  return expandedQueAnsList['default'];
};

export const getBannerImg = param => {
  if (Object.keys(expandedBannerImgList).includes(param)) return expandedBannerImgList[param];
  return '';
};
