import React, { useState } from 'react';
import { createResourceLocatorString } from 'util/routes';
import { routeConfiguration } from 'index';

import { ReactComponent as BackSvg } from '../../assets/HeaderPart/NewBack.svg';
import { ReactComponent as HomeIcon } from '../../assets/HomePage/HomeIcon.svg';
import { injectIntl } from '../../util/reactIntl';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { withViewport } from '../../util/contextHelpers';

import classNames from 'classnames';
import css from './NewMobileHeader.css';
import GetQuoteModal from 'components/GetQuoteModal/GetQuoteModal';
import { useSelector } from 'react-redux';
import { getBannerImg } from 'components/GetQuoteModal/GetQuoteModal.data';

const NewMobileHeader = props => {
  const { intl, isScrolling, history, isEducationSite, showOnMob } = props;
  const [quotesModalOpen, setQuotesModalOpen] = useState(false);
  const { currentUser } = useSelector(state => state.user);

  return (
    <>
      <div
        className={classNames(css.headerWrap, {
          [css.headerBg]: isScrolling,
        })}
      >
        <div className={css.iconGrop}>
          <div className={css.iconWrap} onClick={() => history.goBack()}>
            <BackSvg />
          </div>
          <div
            className={css.iconWrap}
            onClick={() =>
              props.history.push(
                createResourceLocatorString(
                  props.isEducationSite ? 'EducationPage' : 'HomePage',
                  routeConfiguration(),
                  {},
                  {}
                )
              )
            }
          >
            <HomeIcon />
          </div>
        </div>

        {/* <div className={css.iconWrap} onClick={() => props.setIsOpenShareForm(true)}> */}
        {/* <Share /> */}
        {/* </div> */}
        {showOnMob && (
          <div className={css.iconWrap}>
            <div className={css.floatingTopBar}>
              <div
                className={classNames(
                  css.wrapperInput,
                  css.floatingInput,
                  isEducationSite && css.floatingInputEdu
                )}
                onClick={() => setQuotesModalOpen(true)}
              >
                <h4
                  className={classNames(
                    css.wrapperInputHead,
                    isEducationSite && css.wrapperInputHeadEdu
                  )}
                >{`Find a ${isEducationSite ? 'Tutor' : 'coach'}`}</h4>
              </div>
            </div>
          </div>
        )}
      </div>
      <GetQuoteModal
        modalId={`CategoryPage.quotes`}
        open={quotesModalOpen}
        params={{}}
        intl={intl}
        banner={{
          img: getBannerImg('tennis'), // <---
          head: `Answer a couple questions to help us find you the best coach.`,
          desc: `With Probuddy, you can communicate with the best coaches in your city. We will match you with a program that is perfect for you within 24 hours.`,
        }}
        onModalClose={() => setQuotesModalOpen(false)}
        currentUser={!!currentUser?.attributes?.email ? currentUser : ''}
        isEducationSite={isEducationSite}
        isAuthenticated={!!currentUser?.attributes?.email}
        // queOptionList={getQueAnsList('default')}
        category={true}
        mailAtbrs={{
          forName: `${isEducationSite ? 'Education ' : ''}sportName Category`,
          altUrl:
            typeof window !== 'undefined'
              ? window?.location?.href
              : `https://www.probuddy.io${isEducationSite ? '/education' : ''}/sportName`,
          postFixFromName: `${isEducationSite ? 'Education ' : ''}sportName Category`,
        }}
      />
    </>
  );
};

export default compose(withRouter, injectIntl, withViewport)(NewMobileHeader);
