import React from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { Modal } from '..';
import { SecondaryButton } from '../../components';

import css from './PhoneNumberModal.css';

const PhoneNumberModal = props => {
  const {
    className,
    isOpen,
    onCloseModal,
    onManageDisableScrolling,
    onOkay,
    rootClassName,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const closeButtonMessage = "Close";
  return (
    <Modal
      id={'phoneNumberModal'}
      className= {css.phoneNumberModal}
      containerClassName={classes}
      contentClassName={css.modalContent}
      isOpen={isOpen}
      onClose={onCloseModal}
      onManageDisableScrolling={onManageDisableScrolling}
      closeButtonMessage={closeButtonMessage}
    >
      <p>Please verify your mobile number before making any bookings</p>
      <SecondaryButton className={css.submitButton} type="button" onClick={onOkay}>
          Okay
      </SecondaryButton>
    </Modal>
  );
};

const { bool, string, func } = PropTypes;

PhoneNumberModal.defaultProps = {
  className: null,
  rootClassName: null,
  reviewSent: false,
  sendReviewInProgress: false,
  sendReviewError: null,
};

PhoneNumberModal.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  reviewSent: bool,
  sendReviewInProgress: bool,
  sendReviewError: propTypes.error,
  tx: propTypes.transaction,
  onSkip: func,
};

export default injectIntl(PhoneNumberModal);
