import React, { Component } from 'react';
import { compose } from 'redux';
import { ReactComponent as RightIcon } from '../../assets/VendorPage/RightIcon.svg';
import { connect } from 'react-redux';
import { createResourceLocatorString } from 'util/routes';
import config from '../../config';
import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  NamedLink,
  FooterBottomMenu,
  Modal,
  LeftBar,
} from 'components';
import MarketingSection from 'containers/BecomeTutorPage/MarketingSection';
import SelectPriceSection from 'containers/BecomeTutorPage/SelectPriceSection';
import AdvancedIntegrationSection from 'containers/BecomeTutorPage/AdvancedIntegrationSection';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import ProbuddyLogo from '../../assets/ProbuddylogoBlue.svg';
import StarRatingsIcon from '../../assets/VendorPage/StarRatingsIcon.svg';
import { becomeMemberQuestions, planCards } from './BecomeVendor.data';

import { PrimaryButton2 } from 'components/Button/Button';
import { withViewport } from '../../util/contextHelpers';
import { withRouter } from 'react-router-dom';
import { bool, object } from 'prop-types';
import { propTypes } from '../../util/types';
import { routeConfiguration } from 'index';
import { searchListings, queryPromotedListings, showListing } from '../HomePage/HomePage.duck';
import { logout } from '../../ducks/Auth.duck';
import classNames from 'classnames';
import { addChargebeeSubscription } from 'ducks/user.duck';
import moment from 'moment';
import css from './BecomeVendorPage.css';
import { whatsappLinkClickTrack } from 'util/gtag_conversionTracking';
import BecomeVendorLoginModal from './BecomeVendorLoginModal';

const MAX_MOBILE_SCREEN_WIDTH = 1024;
export class BecomeVendorPageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isReviewFetch: false,
      isSellerMode: false,
      activeFooterTab: 'search',
      queryParams: null,
      isOpenSearchBar: false,
      showOpacity: '',
      chargebeeId: null,
      chargebeeState: null,
      isScrolling: false,
      open: false,
    };
    this.handleProviderQueryChange = this.handleProviderQueryChange.bind(this);
    this.onkeywordSearch = this.onkeywordSearch.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onOpen = this.onOpen.bind(this);
  }

  componentDidMount() {
    const el = document.createElement('script');
    el.onload = () => {
      if (typeof window !== 'undefined') {
        localStorage.setItem('isEducationSite', false);
        window.Chargebee.init({
          site: config.cbSite,
        });
        window.Chargebee.registerAgain();
        window.onscroll = () => {
          this.setState({ isScrolling: window.pageYOffset > 300 });
        };
      }
    };

    el.setAttribute('src', 'https://js.chargebee.com/v2/chargebee.js');

    document.body.appendChild(el);

    const queryParams = new URLSearchParams(window.location.search);
    const chargebeeId = queryParams.get('id');
    let chargebeeState = queryParams.get('state');

    const currentDate = moment(new Date());
    let futureDate = moment(currentDate).add(1, 'y');
    const futureMonthEnd = moment(futureDate).endOf('month');
    if (
      currentDate.date() != futureDate.date() &&
      futureDate.isSame(futureMonthEnd.format('YYYY-MM-DD'))
    ) {
      futureDate = futureDate.add(1, 'd');
    }
    this.setState(prev => ({
      ...prev,
      chargebeeId: chargebeeId,
      chargebeeState: chargebeeState,
      chargebeeObject: {
        chargebeeId,
        chargebeeState,
        currentDate,
        futureDate,
      },
    }));
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.currentUser !== this.props.currentUser &&
      this.props.currentUser &&
      this.state.chargebeeId !== null &&
      this.state.chargebeeState !== null
    ) {
      this.props.onAddChargebeeSubscription(this.state.chargebeeObject, this.props.currentUser);
    }
  }

  handleProviderQueryChange(providerQueryValue) {
    this.props.searchListings({
      keywords: providerQueryValue,
      pub_listing_type: null,
    });
    this.props.history.push(createResourceLocatorString('HomePage', routeConfiguration()));
  }

  onkeywordSearch = activity => {
    const searchParams = {
      pub_sub_category: activity.key ? activity.key : null,
      keywords: activity.title ? null : activity.key ? null : activity,
      pub_listing_type: null,
      // listingId: activity.title && activity.id ? activity.id : null,
    };
    activity.id
      ? this.props.showListing(activity.id)
      : this.props.queryPromotedListings(searchParams);
  };

  onSearch = searchquery => {
    this.setState({ queryParams: searchquery });
    this.props.queryPromotedListings(searchquery);
  };
  onOpen = () => {
    this.setState({ open: true });
  };
  onClose = () => {
    this.setState({ open: false });
  };
  render() {
    const { scrollingDisabled, viewport } = this.props;

    const isMobileLayout = !!viewport.width && viewport.width < MAX_MOBILE_SCREEN_WIDTH;
    return (
      <Page title={'Become vendor'} scrollingDisabled={scrollingDisabled}>
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <div className={css.topBarContainer}>
              <div className={css.probuddyLogo}>
                <NamedLink name="LandingPage">
                  <img src={ProbuddyLogo} alt={'probuddy'} />
                </NamedLink>
              </div>
              <div className={css.topHeaderButtonWrap}>
                <NamedLink name="LandingPage">
                  <PrimaryButton2 className={css.exploreButton}>Explore the App</PrimaryButton2>
                </NamedLink>
                <a className={css.exploreButton} href="https://calendly.com/probuddy/15min">
                  Vendor SignUp
                </a>
              </div>
            </div>
          </LayoutWrapperTopbar>

          <LayoutWrapperMain>
            <BecomeVendorLoginModal open={this.state.open} onClose={this.onClose} />
            {/*
            //  floating Whatsapp messages and moblie what icon
            {this.state.isScrolling && (
              <div className={css.whatsappLinkVendor}>
                <a
                  href="https://wa.me/message/WHZFXEGQ6RL5H1"
                  onClick={() => {
                    whatsappLinkClickTrack();
                  }}
                >
                  <img alt="whatsapp-link" src="https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1699507459/probuddy/static/images/Icons/WhatsappImg_m1f6lt.png" />
                </a>
              </div>
            )} */}

            <div className={css.content}>
              {/* <section className={css.servicesSectionBrand}>
                <div className={css.brandTitle}>Flexible plans with Leading Fitness Brands</div>

                <img
                  src={
                    !isMobileLayout
                      ? 'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1673512583/probuddy/becomeMemberPage/imageBecomeMember_fheen7.svg'
                      : 'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1673513857/probuddy/becomeMemberPage/imageMobile_oaiv4f.svg'
                  }
                  alt={' '}
                />
              </section> */}
              {/* <section className={css.topSection}>
                <div className={css.topHeadContainer}>
                  <div className={css.topHeadSectionLeft}>
                    <div className={css.sectionContainer}>
                      <h1>
                        <FormattedMessage id="BecomeVendorPage.adventureMarketplace" />
                      </h1>

                      <div className={css.heroDescription}>
                        <FormattedMessage id="BecomeVendorPage.getYourClients" />
                        <a
                          className={classNames(css.subscriptionLink, css.getProLink)}
                          href="https://calendly.com/probuddy/15min"
                        >
                          Vendor SignUp
                        </a>
                      </div>

                      {!!this.props.isAuthenticated ? (
                        <a
                          className={css.subscriptionLink}
                          href="javascript:void(0)"
                          data-cb-type="checkout"
                          data-cb-item-0="Annual-Plan-USD-Yearly" // "Premium-Plan-Monthly-USD-Yearly"
                          data-cb-item-0-quantity="1"
                          data-cb-customer-cf-user-id="123"
                        >
                          <FormattedMessage id="BecomeVendorPage.subscibePerYear" />
                        </a>
                      ) : (
                        <NamedLink name="LoginPage" className={css.subscriptionLink}>
                          <FormattedMessage id="BecomeVendorPage.subscibePerYear" />
                        </NamedLink>
                      )}
                    </div>
                  </div>

                  <div className={css.topHeadSectionRight}></div>
                </div>
              </section> */}

              <div className={css.breadCrumbWrapper}>
                <NamedLink name="HomePage">Home</NamedLink>
                <span className={css.breadcrumbArrow}>&gt;</span>
                <NamedLink name="BecomeVendorPage">Become a Coach</NamedLink>
              </div>
              <section className={css.servicesSection}>
                <div className={css.servicesTitle}>Flexible plans to fit your lifestyle</div>
                {/* <div className={css.servicesSubheading}>
                  Prices reflect Total payment by employee and employer*
                </div> */}

                <div className={css.popularWraper}>
                  <div className={css.popular}>Popular</div>
                </div>

                <div className={css.planCardsWrapper}>
                  {planCards.map(data => (
                    <div
                      key={data.title}
                      className={css.planCard}
                      onMouseEnter={() => localStorage.setItem('plan', data.title)}
                    >
                      <div className={css.planTitle}>{data.title}</div>
                      <div className={css.planPrice}>
                        {data.price}
                        <span className={css.perMonth}>{'/ Valid 12 Months'}</span>
                      </div>
                      <div className={css.includesTitle}>{`The ${data.title} plan includes`}</div>

                      <div className={css.descriptionWrap}>
                        {data.include.map((des, index) => (
                          <div key={index} className={css.desWrap}>
                            <RightIcon />
                            {des}
                          </div>
                        ))}
                      </div>
                      <div className={css.planDivider} />
                      {/* <div className={css.partnersTitle}>{`Popular partners on ${data.title}`}</div>
                      <div className={css.partnersWrapper}>
                        {data.partners.map(partner => (
                          <div className={css.partnerCard} key={partner.title}>
                            <div className={css.partnerImageWrap}>
                              <img src={partner.image} alt={partner.title} />
                            </div>
                            <div className={css.partnerTitle}>{partner.title}</div>
                            <div className={css.partnerDes}>{partner.des}</div>
                          </div>
                        ))}
                      </div> */}
                      {/* <button className={css.planBtn}> */}
                      {/* <div>
                        <img
                          className={css.partnerImg}
                          src={
                            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1673515411/probuddy/becomeMemberPage/imagePartner_vggkgt.svg'
                          }
                          alt="package"
                        />
                      </div> */}

                      <div
                        className={css.planBtn}
                        onClick={() => localStorage.setItem('plan', data.title)}
                      >
                        {!!this.props.isAuthenticated ? (
                          <a
                            className={css.anchfree}
                            href="javascript:void(0)"
                            data-cb-type="checkout"
                            // data-cb-item-0="Basic-Plan-Monthly-USD-Yearly"
                            data-cb-item-0={data.plan}
                            data-cb-item-0-quantity="1"
                            data-cb-customer-cf-user-id="123"
                          >
                            <div className={css.buttonText}>Order Now</div>
                          </a>
                        ) : (
                          // <NamedLink name="LoginPage">
                          <div onClick={this.onOpen} className={css.buttonText}>
                            Order Now
                          </div>
                          // </NamedLink>
                        )}
                        {/* <a
                          className={css.anchfree}
                          href="javascript:void(0)"
                          data-cb-type="checkout"
                          // data-cb-item-0="Basic-Plan-Monthly-USD-Yearly"
                          data-cb-item-0={data.plan}
                          data-cb-item-0-quantity="1"
                          data-cb-customer-cf-user-id="123"
                        >
                          <div className={css.buttonText}>Order Now</div>
                        </a> */}
                      </div>
                    </div>
                  ))}
                </div>
              </section>
              <div className={css.businessContainer}>
                <video
                  className={css.probuddyVideo}
                  src="https://res.cloudinary.com/movementbuddy-pte-ltd/video/upload/v1669013237/probuddy/static/images/Email/ProbuddyVideo_x9icyj.mp4"
                  controls="controls"
                />

                {/* <h3>The Features You Need To Grow Your Business </h3>
                  <h5>
                    We help fitness studios and freelancers manage their clients, classes, and
                    appointments. We offer a booking platform that can be accessed via the website
                    or mobile app.
                  </h5>
                  <NamedLink name="HomePage" className={css.learnMoreLink}>
                    <FormattedMessage id="BecomeVendorPage.learnMore" />
                  </NamedLink> */}
              </div>

              <section className={css.servicesSection}>
                <div className={css.servicesTitle}>
                  The Ultimate Sports Management Platform for Freelance Trainers & Fitness Studios.
                </div>
                <div className={css.servicesSubheading}>
                  Probuddy is the most effective and affordable way to book fitness for busy people.
                  With Probuddy, you get the control and flexibility of being your own boss. Create
                  a custom workflow to manage every aspect of your business, from client bookings to
                  payments.
                </div>

                <div className={css.servicesCarousel}>
                  <ul className={css.servicesCarouselItems}>
                    <li className={css.servicesCarouselItem}>
                      <img
                        src={
                          'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661505761/probuddy/static/images/BecomeVendorPage/TrainerImage1_gmosx6.png'
                        }
                        alt={' '}
                      />
                      <div className={css.carouselInfo}>
                        <div className={css.carouselHeading}>
                          <h5>Sports Coach</h5>
                          <div className={css.carouselRating}>
                            <img src={StarRatingsIcon} alt="Rating Icon" />
                            <h5>4.6</h5>
                            <span>(12 Review)</span>
                          </div>
                        </div>

                        <div className={css.carouselName}>Stiv Smith</div>
                        <div className={css.carouselDescription}>
                          Looking to learn basics of sports and fitness, get your game to another
                          level or to have a hit with high performance coaches.
                        </div>
                      </div>
                    </li>
                    <li className={css.servicesCarouselItem}>
                      <img
                        src={
                          'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661505761/probuddy/static/images/BecomeVendorPage/TrainerImage2_dlorjq.png'
                        }
                        alt={'section'}
                      />
                      <div className={css.carouselInfo}>
                        <div className={css.carouselHeading}>
                          <h5>Sports Coach</h5>
                          <div className={css.carouselRating}>
                            <img src={StarRatingsIcon} alt="Rating Icon" />
                            <h5>4.6</h5>
                            <span>(12 Review)</span>
                          </div>
                        </div>

                        <div className={css.carouselName}>Darlene Robertson</div>
                        <div className={css.carouselDescription}>
                          Center your body and mind, stretch the possibilities with personalized
                          guidance from Probuddy’s wellness coaches & instructors.
                        </div>
                      </div>
                    </li>
                    <li className={css.servicesCarouselItem}>
                      <img
                        src={
                          'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661505761/probuddy/static/images/BecomeVendorPage/TrainerImage3_crzqoh.png'
                        }
                        alt={' '}
                      />
                      <div className={css.carouselInfo}>
                        <div className={css.carouselHeading}>
                          <h5>Sports Coach</h5>
                          <div className={css.carouselRating}>
                            <img src={StarRatingsIcon} alt="Rating Icon" />
                            <h5>4.6</h5>
                            <span>(12 Review)</span>
                          </div>
                        </div>

                        <div className={css.carouselName}>Jenny Wilson</div>
                        <div className={css.carouselDescription}>
                          Book an adventure activities near you with our certified Probuddy
                          adventure pros. Open to beginners and experienced adventure seekers.
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </section>

              <MarketingSection />

              <AdvancedIntegrationSection />

              <SelectPriceSection />
            </div>
          </LayoutWrapperMain>
          {!isMobileLayout ? (
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          ) : (
            <LayoutWrapperFooter
              className={classNames(css.layoutFooter, {
                [css.activeleftbarFooter]: this.state.activeFooterTab === 'leftbar',
                [css.createFooterTab]:
                  this.state.activeFooterTab === 'create' ||
                  this.state.activeFooterTab === 'activitySearch',
              })}
            >
              {!!isMobileLayout &&
                !(this.state.activeFooterTab === 'leftbar') &&
                !this.state.modalOpen && (
                  <FooterBottomMenu
                    changeActivieFooterTab={value => this.setState({ activeFooterTab: value })}
                    history={this.props.history}
                    onSearch={activity => this.onkeywordSearch(activity)}
                  />
                )}
            </LayoutWrapperFooter>
          )}
        </LayoutSingleColumn>
        <Modal
          isOpen={!!(this.state.activeFooterTab === 'leftbar')}
          onClose={() => this.setState({ activeFooterTab: 'search' })}
          id="filterModal"
          onManageDisableScrolling={() => {}}
          className={css.modalLeftBar}
        >
          <LeftBar
            isMobileLayout={isMobileLayout}
            history={this.props.history}
            isOpenSearchBar={this.state.isOpenSearchBar}
            showOpacity={this.state.showOpacity}
            onShowEnquiryTab={this.onSearch}
            onLogoClick={() => this.setState({ activeFooterTab: 'search' })}
            onClose={() => this.setState({ activeFooterTab: 'search' })}
          />
        </Modal>
      </Page>
    );
  }
}

BecomeVendorPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUserListing: propTypes.listing,
  currentUserListingFetched: bool,
  // from withRouter
  history: object.isRequired,
  location: object.isRequired,
  currentUser: propTypes.currentUser,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  return {
    scrollingDisabled: isScrollingDisabled(state),
    isAuthenticated,
  };
};

const mapDispatchToProps = dispatch => ({
  // onQueryPromotedListingReviews: listingRef => dispatch(queryPromotedListingReviews(listingRef))
  searchListings: searchParams => dispatch(searchListings(searchParams)),
  showListing: listingId => dispatch(showListing(listingId)),
  queryPromotedListings: searchquery => dispatch(queryPromotedListings(searchquery)),
  onLogout: historyPush => dispatch(logout(historyPush)),
  onAddChargebeeSubscription: (chargebeeObject, currentUser) =>
    dispatch(addChargebeeSubscription(chargebeeObject, currentUser)),
});

const BecomeVendorPage = compose(
  withRouter,
  withViewport,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(BecomeVendorPageComponent);

export default BecomeVendorPage;
