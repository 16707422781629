import React from 'react';
import { OutSideCloseCard, RangeSlider } from 'components';
import config from 'config';
import closeBtn from '../../assets/HomePage/closeBtn.svg';
import css from './HeaderContainer.css';

const FilterPopUp = props => {
  const {
    showFilter,
    onClickOutside,
    onValueChange,
    onChangeInputPrice,
    min,
    max,
    applyPriceFilter,
    isMobileLayout,
  } = props;
  return (
    <OutSideCloseCard show={showFilter} onClickOutside={onClickOutside} className={css.filterPopUp}>
      <div className={css.filterContainer}>
        {isMobileLayout && (
          <>
            <div className={css.filterHeader}>
              <img src={closeBtn} onClick={onClickOutside} alt={" "}/>
              <div className={css.filterText}>{'Filters'}</div>
            </div>
            <div className={css.filterDivider} />
          </>
        )}
        {/* <div className={css.filterAvgText}>
          The average price is {config.currency} {(min + max) / 2}
        </div> */}
        <RangeSlider
          min={0}
          max={1000}
          step={1}
          handles={[min, max]}
          isPlainFilter={true}
          onChange={onValueChange}
          className={css.filterRangeSlider}
        />
        <div className={css.btnContainer}>
          {/* <button className={css.minBtn} onClick={() => console.log('Min clicked')}>
            <div className={css.btnLabel}>Min Price</div>
            <div className={css.btnValue}>
              {config.currency} {min}
            </div>
          </button> */}
          <div className={css.maxBtn}>
            Min Price
            <div className={css.inputWrap}>
              <div>{config.currency}&nbsp;</div>
              <input
                className={css.minMaxInput}
                type="number"
                id={'minPrice'}
                name="minPrice"
                label={'Min Price'}
                placeholder={`${min}`}
                onChange={e => {
                  onChangeInputPrice(e.target.value, 'min');
                }}
              />
            </div>
          </div>
          <div className={css.maxBtn}>
            Max Price
            <div className={css.inputWrap}>
              <div>{config.currency}&nbsp;</div>
              <input
                className={css.minMaxInput}
                type="number"
                id={'maxPrice'}
                name="maxPrice"
                label={'Max Price'}
                placeholder={`${max}`}
                onChange={e => {
                  onChangeInputPrice(e.target.value, 'max');
                }}
              />
            </div>
          </div>
          {/* <button className={css.maxBtn} onClick={() => console.log('max clicked')}>
            <div className={css.btnLabel}>Max Price</div>
            <div className={css.btnValue}>
              {config.currency} {max}
            </div>
          </button> */}
        </div>
        <button className={css.applyBtn} onClick={applyPriceFilter}>
          Apply
        </button>
      </div>
    </OutSideCloseCard>
  );
};

export default FilterPopUp;
