import React from 'react';
import css from './ListingsByCategory.css';
import { PromotedListing, HorizontalScroll } from 'components/index';

function ListingsByCategory({
  intl,
  listingsByCategory,
  headRequired = false,
  isMobileLayout,
  allListingReviews = {},
}) {
  const renderPromolistingTrainers = data => {
    const half = Math.ceil(data.length / 2);
    const firstHalf = data.splice(0, half);
    const secondHalf = data.splice(-half);
    return (
      <HorizontalScroll
        isSmallScrollArrow={true}
        scrollClassName={css.promotedPersonSessionsContainer}
      >
        <div className={css.firstHalfWrapper}>
          {firstHalf.map(pl => (
            <PromotedListing
              key={pl.id.uuid}
              pl={pl}
              isMobileLayout={isMobileLayout}
              intl={intl}
              reviews={allListingReviews ? allListingReviews[pl.id.uuid] : []}
            />
          ))}
        </div>
        <div className={css.secondHalfWrapper}>
          {secondHalf.map(pl => (
            <PromotedListing
              key={pl.id.uuid}
              pl={pl}
              intl={intl}
              isMobileLayout={isMobileLayout}
              reviews={allListingReviews ? allListingReviews[pl.id.uuid] : []}
            />
          ))}
        </div>
      </HorizontalScroll>
    );
  };
  return Object.entries(listingsByCategory).map(([key, category], index) => (
    <div key={key} className={css.listingCategory}>
      {index !== 0 || headRequired === true ? (
        <div>
          <h2 className={css.ourProsHeading}>
            <span>{category.label}</span>
          </h2>
        </div>
      ) : null}
      <h2 className={css.subHeading}>{category.label}</h2>
      <div className={css.listingCards}>
        {category.label === 'In-Person Sessions' ? (
          renderPromolistingTrainers(category.data)
        ) : (
          <HorizontalScroll isSmallScrollArrow={true} className={css.promotedListingContainer}>
            {category.data.map(pl => (
              <PromotedListing
                key={pl.id.uuid}
                pl={pl}
                intl={intl}
                isMobileLayout={isMobileLayout}
                reviews={allListingReviews ? allListingReviews[pl.id.uuid] : []}
              />
            ))}
          </HorizontalScroll>
        )}
      </div>
    </div>
  ));
}

export default ListingsByCategory;
