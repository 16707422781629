import React from 'react';
import css from './SectionWhyUs.css';
import { FormattedMessage } from '../../util/reactIntl';
import { IMAGES } from '../../util/imagesPath';
import LazyLoadingImages from 'components/LazyLoadingImages/LazyLoadingImages';

const SectionWhyUs = props => {
  return (
    <div className={css.whyUs}>
      <div className={css.mobileImage}>
        <LazyLoadingImages src={IMAGES.whyUs} alt="WhyUs" />
      </div>
      <div className={css.left}>
        <LazyLoadingImages src={IMAGES.freelancersAndStudios} alt="Freelancers and studios" />
      </div>
      <div className={css.right}>
        <div className={css.containWrapper}>
          <div className={css.title}>
            <span className={css.EasyFastSimpleTitle}>
              <FormattedMessage id="SectionWhyUs.EasyFastSimple" />
            </span>
            <h2>
              <FormattedMessage id="SectionWhyUs.bookNextActivity" />
            </h2>
          </div>

          <div className={css.points}>
            <div>
              <LazyLoadingImages
                src={IMAGES.selectIcon}
                className={css.icon}
                alt="Choose activity"
              />
            </div>
            <div>
              <h3>
                <FormattedMessage id="SectionWhyUs.chooseActivity" />
              </h3>
              <p>
                <FormattedMessage id="SectionWhyUs.chooseActivityText" />
              </p>
            </div>
          </div>

          <div className={css.points}>
            <div>
              <LazyLoadingImages src={IMAGES.cartIcon} className={css.icon} alt="Make payment" />
            </div>
            <div>
              <h3>
                <FormattedMessage id="SectionWhyUs.makePayment" />
              </h3>
              <p>
                <FormattedMessage id="SectionWhyUs.makePaymentText" />
              </p>
            </div>
          </div>

          <div className={css.imageWrapper}>
            <div className={css.points}>
              <div>
                <LazyLoadingImages
                  src={IMAGES.starIcon}
                  className={css.icon}
                  alt="Review and rebook"
                />
              </div>
              <div>
                <h3>
                  <FormattedMessage id="SectionWhyUs.reviewRebook" />
                </h3>
                <p>
                  <FormattedMessage id="SectionWhyUs.reviewRebookText" />
                </p>
              </div>
            </div>

            <div className={css.whyUsInfoImage}>
              <LazyLoadingImages src={IMAGES.section_WhyUs} alt="SectionWhyUs" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

SectionWhyUs.defaultProps = {};

SectionWhyUs.propTypes = {};

export default React.memo(SectionWhyUs);
