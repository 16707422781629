import React from 'react';
import { capitalize } from 'lodash/string';
import { ClassInlineListingCard } from 'components';
import moment from 'moment';
import css from './ClassInlineListingCard.css';

const ClassesComponent = props => {
  const {
    className,
    listings,
    selectedDate,
    allClassListingReviews,
    showAll,
    searchAllClasses,
  } = props;
  const isToday = selectedDate.date() === moment().date() && !showAll;
  const getListings = sorted => {
    const ref = sorted.sort((a, b) => a?.entity?.localeCompare(b.entity));
    if (!ref.length) return [];
    return ref.map(a => {
      return listings.filter(
        l => a.id === l.id.uuid
        // if (a.id === l.id.uuid) return l;
      )[0];
    });
  };
  const getSortedListing = listings => {
    const ref = listings.map(l => {
      const entity = l?.attributes?.availabilityPlan?.entries?.filter(
        f => capitalize(f.dayOfWeek) === selectedDate.format('ddd')
        // &&
        // f?.startTime?.split(':')[0] >= min &&
        // f?.startTime?.split(':')[0] <= max
      );
      return {
        id: l.id.uuid,
        entity: entity && !!entity.length ? entity[0].startTime : {},
        entityEnd: entity && !!entity.length ? entity[0].endTime : {},
        defaultClassLength: l.attributes.publicData?.defaultClassLength,
      };
    });

    const groups = ref.reduce((groups, game) => {
      const date =
        typeof game?.entity == 'string'
          ? isToday && game?.entity?.split(':')[0] < moment().hours()
            ? moment().hours()
            : game?.entity?.split(':')[0]
          : null;
      if (!!date && !groups[date]) {
        groups[date] = [];
      }
      isToday
        ? !!date &&
          Number(game?.entityEnd?.split(':')[0]) > Number(moment().hours()) &&
          groups[date].push(game)
        : !!date && groups[date].push(game);

      return groups;
    }, {});

    // Edit: to add it in the array format instead
    const groupArrays = Object.keys(groups).map(date => {
      return {
        date,
        games: groups[date],
      };
    });

    const arr = groupArrays.sort((a, b) => a.date.localeCompare(b.date));

    const shortedGrops = arr.map(values => {
      return { time: values.date, listings: getListings(values.games) };
    });

    return shortedGrops;
  };

  const getTime = n => {
    if (n === 12) {
      return n + ':00 PM';
    } else if (n > 12) {
      return (n % 12) + ':00 PM';
    } else {
      return n + ':00 AM';
    }
  };
  //   const ref2=

  const shortedGrops = !showAll && listings && !!listings.length ? getSortedListing(listings) : [];
  return !searchAllClasses ? (
    <div>
      {!!shortedGrops.length &&
        !showAll &&
        shortedGrops.map((value, index) => (
          <div key={index}>
            <div className={css.groupDate}>
              <div className={css.groupText}>
                {`${selectedDate.format('dddd')},`}&nbsp;&nbsp;{getTime(value.time)}
              </div>
            </div>

            {!!value?.listings?.length &&
              value.listings.map(l => (
                <ClassInlineListingCard
                  key={l.id.uuid}
                  className={className}
                  listing={l}
                  review={allClassListingReviews ? allClassListingReviews[l.id.uuid] : []}
                  selectedDate={selectedDate}
                />
              ))}
          </div>
        ))}
      {showAll &&
        !!listings.length &&
        listings.map(l => (
          <ClassInlineListingCard
            className={className}
            key={l.id.uuid}
            listing={l}
            review={allClassListingReviews ? allClassListingReviews[l.id.uuid] : []}
            selectedDate={selectedDate}
            showAll={showAll}
          />
        ))}
      {!showAll && (!shortedGrops?.length || !listings.length) && (
        <div>No Listing found for selected date or selected time period...222</div>
      )}
    </div>
  ) : (
    <div>
      {listings.map(l => (
        <ClassInlineListingCard
          className={className}
          key={l.id.uuid}
          listing={l}
          review={allClassListingReviews ? allClassListingReviews[l.id.uuid] : []}
          selectedDate={selectedDate}
          showAll={showAll}
        />
      ))}
    </div>
  );
};

export default ClassesComponent;
