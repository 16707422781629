import { Modal } from 'components';
import React, { useEffect, useState } from 'react';
import css from '../JobsBoard.css';
import { useSelector } from 'react-redux';
import emailjs, { init } from '@emailjs/browser';
import classNames from 'classnames';
import thankyousvg from 'assets/ThankYouPage/thankyousvg.svg';
import { vendorQueAns } from '../JobsBoard.data';
import { getBannerImg } from 'components/GetQuoteModal/GetQuoteModal.data';

// initState
const JobsModelInitState = { message: '', email: '' };

const JobsCardReplyModel = props => {
  const { JobsBoardInfo, onCloseJObsreplyModal } = props; // JobsBoardInfo is contanting email of who apply for jods and id of card
  const { currentUser } = useSelector(state => state.user);
  const [JobsModelInfo, setJobsModelInfo] = useState({
    ...JobsModelInitState,
    email: currentUser?.attributes?.email ? currentUser?.attributes?.email : '',
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [currIndex, setCurrIndex] = useState(-1);
  const [QueAns, setQueAns] = useState([]);
  const [otherOption, setOtherOption] = useState({});
  const [active, setActive] = useState('');
  useEffect(() => {
    const que_ans = vendorQueAns[JobsBoardInfo.sub_category] || vendorQueAns['default'];
    setQueAns(que_ans);
  }, [JobsBoardInfo.sub_category]);
  const banner = {
    img: JobsBoardInfo.heroImg || getBannerImg('tennis'),
    head: `Answer a couple questions to help us find you the best ${JobsBoardInfo.sub_category} customer.`,
    desc: `With Probuddy, you can communicate with the best customer in your city. We will match you with a program that is perfect for you within 24 hours.`,
  };

  const email = currentUser?.attributes?.email
    ? currentUser?.attributes?.email
    : JobsModelInfo.email;

  const sendJobsBoardReplyMail = ({ message, email, ...values }) => {
    const url =
      typeof window !== 'undefined' ? window?.location?.href : 'https://www.probuddy.io/jobs-board';
    const forName = `${JobsBoardInfo.sub_category} ${JobsBoardInfo?.city}`;
    const fromName = `Probuddy | Jobs Board ${forName}`;

    const templateParams = {
      for_name: forName,
      message: message,
      from_name: fromName,
      to_mail: 'Chris@probuddy.io,probuddyinquiries@gmail.com',
      from_mail: email,
      url: url,
    };

    if (process.env.REACT_APP_ENV === 'production') {
      emailjs
        .send('service_tn96fm5', 'template_04szlx8', templateParams, 'p656r9_5pKAHF0ioU')
        .then()
        .catch(e => console.log('e-->', e));
    }
  };

  const getPreparedMessage = () => {
    let creatingMessage = '';
    if (currentUser?.attributes?.email) {
      creatingMessage += `\nVendor Name: ${currentUser?.attributes?.profile?.displayName}\n`;
    }
    {
      creatingMessage = Object.keys(JobsModelInfo)
        .map(question => `${question}:\n ${JobsModelInfo[question]}\n`)
        .join('');
    }

    creatingMessage += `Jobs Apply by:\n> ${JobsBoardInfo?.email}\nJobsBoard_Id:\n> ${JobsBoardInfo?.id}\nJobsBoard URL:\n> https://www.probuddy.io/jobs-board/${JobsBoardInfo?.id}`;
    return creatingMessage;
  };

  const onJobsMessageSend = e => {
    e.preventDefault();
    if (!JobsModelInfo.message || !email) {
      setErrorMessage('required');
    } else {
      try {
        const mailData = {
          email: email,
          message: getPreparedMessage(),
        };
        sendJobsBoardReplyMail(mailData);
        setCurrIndex(p => p + 1);
        setJobsModelInfo({});
      } catch (error) {
        console.error(error);
      }
    }
  };

  const onChange = e => {
    if (errorMessage) {
      setErrorMessage('');
    }
    setJobsModelInfo({
      ...JobsModelInfo,
      [e.target.name]: e.target.value,
    });
  };

  const GetExtraFiled = ({ data }) => {
    const { name, question, type } = data;
    return (
      <div className={css.JobsCardMessagediv}>
        <label htmlFor={question}>{question}</label>
        <input
          onFocus={() => setActive(name)}
          onBlur={() => setActive('')}
          autoFocus={active === name}
          type={type}
          className={css.JobsReplyMessagetext}
          onChange={onChange}
          value={JobsModelInfo[question]}
          name={question}
          id={question}
          placeholder="write here..."
        />
      </div>
    );
  };
  const resetAll = () => {
    setJobsModelInfo(JobsModelInitState);
    setCurrIndex(-1);
    setOtherOption({});
    onCloseJObsreplyModal();
  };
  const GetQuestion = ({ data }) => {
    const { name, question, option, type } = data;
    return (
      <div className={css.JobsCardMessagediv}>
        <label htmlFor="Court">{question}</label>
        {type === 'radio' && (
          <div className={css.card}>
            <div className={css.form}>
              {option.map((val, i) => {
                return val === 'other' ? (
                  <label key={i}>
                    <input
                      type="radio"
                      className={classNames(css['input-radio'], css.blueCheck)}
                      name={question}
                      defaultChecked={
                        otherOption[question] && JobsModelInfo[question] === otherOption[question]
                      }
                      value={otherOption[question]}
                      onChange={onChange}
                    />
                    <input
                      type="text"
                      onFocus={() => setActive(name)}
                      onBlur={() => setActive('')}
                      autoFocus={active === name}
                      className={css.JobsReplyMessagetext}
                      onChange={({ target }) =>
                        setOtherOption(p => ({ ...p, [target.name]: target.value }))
                      }
                      value={otherOption[question]}
                      name={question}
                      id={question}
                      placeholder="other..."
                    />
                  </label>
                ) : (
                  <label key={i}>
                    <input
                      type="radio"
                      className={classNames(css['input-radio'], css.blueCheck)}
                      name={question}
                      defaultChecked={JobsModelInfo[question] === val}
                      value={val}
                      onChange={onChange}
                    />
                    {val}
                  </label>
                );
              })}
            </div>
          </div>
        )}
        {data[JobsModelInfo[question]] && <GetExtraFiled data={data[JobsModelInfo[question]]} />}
      </div>
    );
  };
  const handleNext = () => {
    const data = QueAns[currIndex];
    const { question } = data;
    const other = data[JobsModelInfo[question]]
      ? !!JobsModelInfo[data[JobsModelInfo[question]].question]
      : true;

    const checkEmpty = !!JobsModelInfo[question] && other;
    if (checkEmpty) {
      setCurrIndex(p => p + 1);
    } else {
      setErrorMessage('required');
    }
  };

  const progressPerc =
    currIndex === 0 ? `10%` : `${(currIndex / (QueAns?.length + 1 || 5)) * 100}%`;

  return (
    <Modal
      id="JobsCardReplyModel"
      extraContainerClasses={css.customModal}
      contentClassName={css.modalContent}
      isOpen={!!JobsBoardInfo.email}
      stopOutSideClose={true}
      onClose={resetAll}
      onManageDisableScrolling={() => {}}
    >
      {currIndex === -1 ? (
        <div className={css.banner}>
          <img className={css.bannerImg} src={banner.img} alt="bannerImage" />
          <div className={css.bannerText}>
            <h3>{banner.head}</h3>
            <p>{banner.desc}</p>
            <button onClick={() => setCurrIndex(0)} className={css.btnBlue}>
              {/* btnContinue */}
              Let's Start
            </button>
          </div>
        </div>
      ) : currIndex !== -1 && QueAns.length && QueAns.length >= currIndex ? (
        <form onSubmit={onJobsMessageSend} className={css.JobsCardReplyModel}>
          <div className={css.progressBarMain}>
            <div className={css.progress} id="progress" style={{ width: progressPerc }}></div>
          </div>
          <div className={css.jobCardDetail}>
            <h2 className={css.detailHead}>{currentUser?.attributes?.profile?.displayName}</h2>
          </div>
          {errorMessage && <p className={css.error}>{errorMessage}</p>}
          {QueAns.length - 1 >= currIndex ? (
            <>
              <GetQuestion data={QueAns[currIndex]} />
              <div className={css.jobCardButtons}>
                {currIndex === 0 ? (
                  <button
                    onClick={resetAll}
                    // className={classNames(css.BtnOrange, css.btn, css.pointer)}
                    className={css.btnBack}
                  >
                    Close
                  </button>
                ) : (
                  <button
                    onClick={() => setCurrIndex(p => p - 1)}
                    // className={classNames(css.BtnOrange, css.btn, css.pointer)}
                    className={css.btnBack}
                  >
                    Back
                  </button>
                )}
                <button
                  type="button"
                  onClick={handleNext}
                  // className={classNames(css.BtnBlue, css.btn, css.pointer)}
                  className={css.btnBlue}
                >
                  Next
                </button>
              </div>
            </>
          ) : (
            <>
              {!currentUser?.attributes?.email && (
                <div className={css.JobsCardMessagediv}>
                  <label htmlFor="email">Email</label>
                  <input
                    className={css.JobsReplyMessagetext}
                    onChange={onChange}
                    value={JobsModelInfo.email}
                    name="email"
                    id="email"
                    placeholder="write email here..."
                  />
                </div>
              )}
              <div className={css.JobsCardMessagediv}>
                <label htmlFor="message">
                  Please let this Student know as much info about your availability locations and
                  how to contact you.
                </label>
                {<p className={css.error}>{errorMessage}</p>}
                <textarea
                  className={css.JobsReplyMessagetext}
                  onChange={onChange}
                  name="message"
                  id="message"
                  value={JobsModelInfo.message}
                  rows={4}
                  placeholder="write message here..."
                >
                  {/* {JobsModelInfo.message} */}
                </textarea>
              </div>
              <div className={css.jobCardButtons}>
                <button
                  onClick={() => setCurrIndex(p => p - 1)}
                  // className={classNames(css.BtnOrange, css.btn, css.pointer)}
                  className={css.btnBack}
                >
                  Back
                </button>
                <button
                  type="submit"
                  className={css.btnBlue}
                  //  className={classNames(css.BtnBlue, css.btn, css.pointer)}
                >
                  Send
                </button>
              </div>
            </>
          )}
        </form>
      ) : (
        <div className={css.thankYouWrap}>
          <h2>Thank you for sending the message</h2>
          <img src={thankyousvg} alt={'Thank You so much img'} />
          {/* <h3>You will receive email on the provided email</h3> */}
          {/* <code>"{email}"</code> */}
        </div>
      )}
    </Modal>
  );
};

export default JobsCardReplyModel;
