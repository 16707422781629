import React, { useEffect, useMemo, useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { propTypes } from 'util/types';
import {
  Footer,
  IconSpinner,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Page,
  DropdownButton,
} from 'components';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withViewport } from 'util/contextHelpers';
import { injectIntl } from 'util/reactIntl';
import { isScrollingDisabled } from 'ducks/UI.duck';
import { TopbarContainer } from 'containers/index';
import { loadData } from './BookingPlanPage.duck';
import { resetMarketplaceEntities } from 'ducks/marketplaceData.duck';
import { getMarketplaceEntitiesMemo, getData, PERIODS } from 'containers/BookingPlanPage/helpers';
import Table from './components/Table/Table';
import RecentTable from './components/RecentTable/RecentTable';
import css from './BookingPlanPage.css';
import { pathByRouteName } from 'util/routes';
import routeConfiguration from 'routeConfiguration';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

function BookingPlanPageComponent({
  intl,
  scrollingDisabled,
  transactions,
  recentTransactions,
  isLoading,
  error,
  resetMarketplaceEntities,
  params,
  history,
}) {
  const { period } = params;
  const [isCollapsedAll, setCollapsedAll] = useState(false);
  // eslint-disable-next-line
  useEffect(() => () => resetMarketplaceEntities(), []);

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const data = useMemo(() => {
    return getData(transactions);
  }, [transactions]);

  const [filterData, setFilterData] = useState([]);
  const [isFilter, setIsFilter] = useState(false);

  const filterDataByDate = () => {
    let finalArr = [];
    const BookingData = data;
    BookingData.map((booking, i) => {
      // booking.transactions.map((t)=>{
      // if(moment(t.booking.attributes.displayEnd).isBetween(startDate, endDate)) {
      //   // if(finalArr.find(f => f !== booking))
      //     finalArr.push(booking);
      // }
      //  })
      if (
        booking.transactions[0].booking &&
        moment(booking.transactions[0].booking.attributes.start).isBetween(startDate, endDate)
      ) {
        finalArr.push(booking);
      }
      return null;
    });
    return finalArr;
  };
  useEffect(() => {
    if (startDate && endDate) {
      let d = filterDataByDate();
      setFilterData(d);
      history.push(pathByRouteName('BookingPlan', routeConfiguration(), { period: 'active' }));
    }
    // eslint-disable-next-line
  }, [startDate, endDate]);

  const content = useMemo(() => {
    if (period === PERIODS.ACTIVE && !data.length)
      return <div className={css.tablePlaceholder}>No Results</div>;
    if (period === PERIODS.RECENT && !recentTransactions.length)
      return <div className={css.tablePlaceholder}>No Results</div>;

    switch (period) {
      case PERIODS.ACTIVE:
        return <Table data={isFilter ? filterData : data} isCollapsedAll={isCollapsedAll} />;
      case PERIODS.RECENT:
        return <RecentTable data={recentTransactions} />;
      default:
        return <Table data={isFilter ? filterData : data} isCollapsedAll={isCollapsedAll} />;
    }
    // eslint-disable-next-line
  }, [transactions, recentTransactions, isCollapsedAll, period]);

  const schemaTitle = intl.formatMessage({
    id: 'BookingPlanPage.schemaTitle',
  });

  const subHeading = useMemo(() => {
    switch (period) {
      case PERIODS.ACTIVE:
        return 'Active bookings';
      case PERIODS.RECENT:
        return 'Recent bookings';
      default:
        return 'Active bookings';
    }
  }, [period]);

  return (
    <Page
      scrollingDisabled={scrollingDisabled}
      title={schemaTitle}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'BookingPlan',
        name: schemaTitle,
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage="BookingPlan" />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div className={css.heading}>
            <div>
              <h1 className={css.headingTitle}>Your Schedule</h1>
              <h3>{subHeading}</h3>
            </div>

            <div className={css.helpers}>
              <div className={css.collapsePeriod}>
                {period === PERIODS.ACTIVE && (
                  <button
                    onClick={() => setCollapsedAll(!isCollapsedAll)}
                    className={css.collapseAllBtn}
                  >
                    Collapse All
                  </button>
                )}

                <DropdownButton
                  label={'Period'}
                  actions={[
                    {
                      label: 'Active',
                      handler: () => {
                        history.push(
                          pathByRouteName('BookingPlan', routeConfiguration(), { period: 'active' })
                        );
                      },
                    },
                    {
                      label: 'Recent',
                      handler: () => {
                        history.push(
                          pathByRouteName('BookingPlan', routeConfiguration(), { period: 'recent' })
                        );
                      },
                    },
                  ]}
                  labelClassName={css.collapseAllBtn}
                />
              </div>
              {typeof window !== 'undefined' &&
              window.location.pathname.split('/')[2] === 'active' ? (
                <div className={css.dateWrap}>
                  <DatePicker
                    selected={startDate}
                    onChange={date => {
                      setStartDate(date);
                      setIsFilter(true);
                    }}
                    className={css.datePicker}
                    placeholderText={'Start Date'}
                    selectsStart
                    // startDate={startDate}
                    // endDate={endDate}
                  />
                  <DatePicker
                    selected={endDate}
                    onChange={date => {
                      setEndDate(date);
                      setIsFilter(true);
                    }}
                    className={css.datePickerSecond}
                    placeholderText={'End Date'}
                    selectsEnd
                    // startDate={startDate}
                    // endDate={endDate}
                    minDate={startDate}
                  />
                </div>
              ) : null}
            </div>
          </div>

          {isLoading ? <IconSpinner className={css.spinner} /> : content}
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
}

BookingPlanPageComponent.propTypes = {
  transactions: PropTypes.arrayOf(propTypes.transaction),
  recentTransactions: PropTypes.arrayOf({
    customer: propTypes.user,
    listing: propTypes.listing,
    provider: propTypes.user,
    transactions: PropTypes.arrayOf(propTypes.transaction),
  }),
  isLoading: PropTypes.bool,
  error: PropTypes.object,
};

const mapStateToProps = state => {
  const { transactionRefs, recentTransactions, isLoading, error } = state.BookingPlanPage;
  const transactions = getMarketplaceEntitiesMemo(state, transactionRefs);
  return {
    scrollingDisabled: isScrollingDisabled(state),
    recentTransactions,
    transactions,
    isLoading,
    error,
  };
};

const mapDispatchToProps = {
  resetMarketplaceEntities,
};

const BookingPlan = compose(
  injectIntl,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withViewport
)(BookingPlanPageComponent);

BookingPlan.loadData = loadData;

export default BookingPlan;
