import React from 'react';
import { NamedLink } from 'components';
import { FormattedMessage } from 'react-intl';
import StarRatingsIcon from '../../assets/VendorPage/StarRatingsIcon.svg';
import LocationIcon from '../../assets/VendorPage/LocationIcon.svg';
import ProbuddyLogo from '../../assets/ProbuddylogoBlue.svg';
import FootballImage from '../../assets/VendorPage/FootballImage.svg';
import vendorImage from '../../assets/VendorPage/vendorImage.svg';
import classNames from 'classnames';
import css from '../BecomeVendorPage/BecomeVendorPage.css';
import Slider from 'react-slick';

const MarketingSection = props => {
  const sliderSettings = {
    appendDots: dots => (
      <div
        style={{
          backgroundColor: 'transparent',
          borderRadius: '10px',
          padding: '10px',
          marginTop: '2rem',
        }}
      >
        <ul style={{ margin: '-15px' }}> {dots} </ul>
      </div>
    ),
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    swipe: false,
    dots: true,
    responsive: [
      {
        breakpoint: 550,
        settings: {
          slidesToScroll: 1,
          swipe: true,
          arrows: false,
          dots: true,
        },
      },
    ],
  };

  const { isEducationSite } = props;

  const sliderCardData = (
    <>
      <div className={css.carouselCardLeft}>
        <img
          src={
            isEducationSite
              ? 'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675838451/probuddy/static/NewEducation/BecomeTutor/pexels-vanessa-garcia-6326381_gzzwzy.jpg'
              : 'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661504769/probuddy/static/images/BecomeVendorPage/CompanyImage1_ort1ne.png'
          }
          alt={'carousel'}
        />
      </div>

      <div className={css.carouselCardRight}>
        <div className={css.cardTitle}>{`SmartFit ${isEducationSite ? 'Academy' : 'Pilates'}`}</div>
        <div className={css.cardInfo}>
          <span className={css.cardRating}>
            <img src={StarRatingsIcon} alt={'star'} />
            <h5>4.8</h5>
            <span>(230)</span>
          </span>
          <span className={css.cardLocation}>
            <img src={LocationIcon} alt={'location'} />
            <span>Tanglin Road, Singapore</span>{' '}
          </span>
        </div>

        <div className={css.cardDescription}>
          {`We're a ${
            isEducationSite ? 'photography' : 'boutique pilates'
          } studio at Tanglin Place that opened over 9 years ago to help
          every body live their best life through private, duet and small group ${
            isEducationSite ? 'photography' : 'pilates'
          } (Reformer
          and other apparatus) sessions.`}
        </div>
      </div>
    </>
  );

  return (
    <section>
      <div className={css.imageContainer}>
        <h3>
          {isEducationSite
            ? 'Are you prepared to achieve freelance tutoring success by leveraging your skills, knowledge, and dedication to helping students excel?'
            : 'Ready to Make Your Freelancing or Sports Business A Success?'}
        </h3>
        <a className={css.getProLink} href="https://calendly.com/probuddy/15min">
          Book a Demo
        </a>
      </div>

      <section className={css.companiesSection}>
        <div className={css.servicesTitle}>
          {isEducationSite
            ? 'Get the Teaching Opportunities You Need with Probuddy Education'
            : 'Get The Work You Need. You’re In Charge. Like A Pro.'}
        </div>
        <div className={css.servicesSubheading}>
          {isEducationSite
            ? 'Join Probuddy Education to get access to a wealth of teaching opportunities. As a tutor, you can set your own schedule, earn money, and make a difference in the lives of students.'
            : 'Freelance sports trainers and fitness studios need a reliable way to book appointments for their services. Probuddy is the easiest and most reliable solution to help you manage your business.'}
        </div>

        <Slider className={css.slickCarouselSlider} {...sliderSettings}>
          {[...Array(2)].map(key => {
            return (
              <div className={css.slickCarouselCard} key={key}>
                {sliderCardData}
              </div>
            );
          })}
        </Slider>
      </section>

      <div className={css.listingContainer}>
        <div className={css.listingContainerText}>
          <img src={ProbuddyLogo} alt={'probuddy'} className={css.probuddyLogo} />

          <h3>
            <FormattedMessage
              id={
                isEducationSite
                  ? 'BecomeTutorPage.viewTopServices'
                  : 'BecomeVendorPage.viewTopServices'
              }
            />
          </h3>
          <NamedLink
            name={isEducationSite ? 'EducationPage' : 'HomePage'}
            className={classNames(css.learnMoreLink, css.blackButton)}
          >
            <FormattedMessage id="BecomeVendorPage.learnMore" />
          </NamedLink>
        </div>

        <img
          className={css.footballImage}
          alt={'football'}
          src={isEducationSite ? vendorImage : FootballImage}
        />
      </div>

      <div className={css.paymentContainer}>
        <div className={css.paymentInfo}>
          <h3>
            <FormattedMessage id="BecomeVendorPage.SimpleBookings" />{' '}
          </h3>

          <h5>
            <FormattedMessage id="BecomeVendorPage.onlineBookingSystem" />
          </h5>
        </div>
        <div className={css.paymentImage}>
          <img
            className={css.paymentImage}
            src={
              'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675249509/probuddy/static/images/BecomeVendorPage/PaymentImage_hgsow3.svg'
            }
            alt={'payment'}
          />
        </div>
      </div>
    </section>
  );
};
export default MarketingSection;
