import { Modal } from 'components';
import React from 'react';
import css from './BecomeVendorPage.css';
import { NamedLink } from 'components';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';

const BecomeVendorLoginModal = ({ open, onClose }) => {
  return (
    <Modal
      id="BecomeVendorLoginModal"
      //   extraContainerClasses={css.customModal}
      //   contentClassName={css.modalContent}
      isOpen={open}
      //   stopOutSideClose={true}
      onClose={onClose}
      onManageDisableScrolling={() => {}}
    >
      <div className={css.thankYouWrap}>
        <h2>Please Login to purchase membership</h2>
        <div className={css.btnGroup}>
          <button onClick={onClose} className={css.btnClose}>
            Close
          </button>
          <NamedLink className={css.btnLogin} name="LoginPage">
            Login
          </NamedLink>
        </div>
      </div>
    </Modal>
  );
};

export default BecomeVendorLoginModal;
