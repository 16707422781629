import React from 'react';
import cns from 'classnames';
import PropTypes from 'prop-types';
import css from 'components/PackagesModal/PackagesModal.css';
import config from 'config';
import { FormattedMessage, intlShape } from 'util/reactIntl';
import { PrimaryButton, HorizontalScroll } from 'components';
import { toTitleCase } from 'util/stringHelper';

export const USAGE = {
  listingPage: 'listingPage',
};

const Package = ({
  numberOfCredits,
  unformattedPrice,
  description,
  handlePackageBuy,
  inProgress,
  disabled,
  currency,
  category,
  listing_type,
}) => {
  const currencyConfig = config.currencyConfig;
  const params = {
    amount: unformattedPrice,
    credits: numberOfCredits,
  };
  return (
    <div className={css.standoutInlinePackage}>
      <div
        className={cns(css.card)}
        onClick={
          typeof window !== 'undefined' && window.innerWidth < 550
            ? () => {
                handlePackageBuy(params);
              }
            : null
        }
      >
        <div className={css.productWrap}>
          <div className={css.timeInfoContainer}>
            <div className={css.svgWrap}>
              <svg
                width="60"
                height="60"
                viewBox="0 0 60 60"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="30" cy="30" r="30" fill="#604AE5" />
                <path
                  d="M27.0417 22.9167L30.8733 31.1268L39.0833 34.9584L30.8733 38.79L27.0417 47.0001L23.21 38.79L15 34.9584L23.21 31.1268L27.0417 22.9167Z"
                  fill="white"
                />
                <path
                  d="M39.083 13L41.1115 17.3465L45.458 19.375L41.1115 21.4035L39.083 25.75L37.0545 21.4035L32.708 19.375L37.0545 17.3465L39.083 13Z"
                  fill="white"
                />
                <path
                  d="M19.9587 13L21.0856 15.4147L23.5003 16.5417L21.0856 17.6686L19.9587 20.0833L18.8317 17.6686L16.417 16.5417L18.8317 15.4147L19.9587 13Z"
                  fill="white"
                />
              </svg>
            </div>
            <div className={css.pacakageHour}>
              <span>
                {listing_type === 'product' ? (
                  `Price of ${numberOfCredits} ${toTitleCase(category.replaceAll('_', ' '))} `
                ) : (
                  <>
                    {numberOfCredits} <FormattedMessage id="PackagesModal.hours" />
                  </>
                )}
              </span>
            </div>
          </div>
          <div className={css.packagePrice}>
            <span>
              {unformattedPrice / 100} {currency || currencyConfig.currency}
            </span>
          </div>

          <ul className={css.product}>
            <div className={css.description}>{description}</div>
          </ul>
          <div className={css.productBtn}>
            <PrimaryButton
              className={css.packagePurchase}
              spinnerClassName={css.spinner}
              params={params}
              inProgress={inProgress}
              disabled={disabled}
              onClick={() => {
                handlePackageBuy(params);
              }}
            >
              <FormattedMessage id="PackagesModal.bookNow" />
            </PrimaryButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export const Packages = ({
  usage,
  packages,
  packageAttr,
  withPayment,
  sellerId,
  buyerId,
  intl,
  listing,
  handleBuy,
  creditRequestInProgress,
  showInline,
  currency,
}) => {
  const containerClasses = cns({
    [css.packagesContainer]: showInline !== true,
    [css.inlinePackagesContainer]: showInline === true,
    [css.listingPackages]: usage === USAGE.listingPage,
  });

  return (
    <div className={containerClasses}>
      <HorizontalScroll
        isSmallScrollArrow={true}
        className={css.horizontalScroll}
        scrollClassName={css.scrollControl}
      >
        {packages && packages.length > 0
          ? packages.map((p, index) => {
              const price = p.price;
              const disabled =
                packageAttr &&
                packageAttr.amount === price &&
                packageAttr.credits === p.sessions &&
                creditRequestInProgress;

              return (
                <Package
                  intl={intl}
                  index={index}
                  key={`Package${index}`}
                  numberOfCredits={p.sessions}
                  unformattedPrice={price}
                  description={p.description}
                  listing={listing}
                  sellerId={sellerId}
                  buyerId={buyerId}
                  standOut={index === 1}
                  handlePackageBuy={handleBuy}
                  disabled={disabled}
                  category={listing?.attributes?.publicData?.category}
                  listing_type={listing?.attributes?.publicData?.listing_type}
                  inProgress={disabled}
                  inline={showInline}
                  withPayment={withPayment}
                  currency={currency}
                />
              );
            })
          : null}
      </HorizontalScroll>
    </div>
  );
};

Packages.propTypes = {
  intl: intlShape,
  listing: PropTypes.object,
  packages: PropTypes.array,
  packageAttr: PropTypes.array,
  withPayment: PropTypes.bool,
  sellerId: PropTypes.string,
  buyerId: PropTypes.string,
  handleBuy: PropTypes.func,
  showInline: PropTypes.bool,
};
