import React from 'react';
import classNames from 'classnames';
import css from './LevelFilter.css';
import { levelSwitches } from 'containers/HomePage/HomePage.data';
import Switch from 'react-switch';

const LevelFilter = ({ selectedLevelMode, handleLevelChange, isMobileLayout }) => {
  return (
    <div className={css.filterWrap}>
      {levelSwitches.map(item => {
        let label = item.label;
        if (isMobileLayout && label === 'Tournament Players') {
          label = 'Competition';
        }
        return (
          <div
            key={item.id} // Make sure to include a unique key for each item
            className={classNames(css.ageSwitch, {
              [css.switchChecked]: selectedLevelMode === item.id,
              [css.switchUnchecked]: selectedLevelMode !== item.id,
            })}
          >
            <h4 className={css.ageSwitchHead}>{label}</h4>
            <Switch
              uncheckedIcon={false}
              checkedIcon={false}
              onChange={() => handleLevelChange(item)} // Wrap the function call in an arrow function
              checked={selectedLevelMode === item.id}
            />
          </div>
        );
      })}
    </div>
  );
};

export default LevelFilter;
