import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import config from '../../config';
import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  NamedLink,
  FooterBottomMenu,
  Modal,
  LeftBar,
  NamedRedirect,
} from 'components';

import { isScrollingDisabled } from '../../ducks/UI.duck';
import classNames from 'classnames';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import ProbuddyLogo from '../../assets/ProbuddylogoBlue.svg';
import { ReactComponent as RightIcon } from '../../assets/VendorPage/RightIcon.svg';
import plusIcon from '../../assets/VendorPage/plusIcon.svg';
import minusIcon from '../../assets/VendorPage/minusIcon.svg';

import { PrimaryButton2 } from 'components/Button/Button';
import { withViewport } from '../../util/contextHelpers';
import { withRouter } from 'react-router-dom';
import { bool, object } from 'prop-types';
import { propTypes } from '../../util/types';
import { createResourceLocatorString } from 'util/routes';
import { routeConfiguration } from 'index';
import { searchListings, queryPromotedListings, showListing } from '../HomePage/HomePage.duck';
import { addChargebeeSubscription } from '../../ducks/user.duck.js';
import { logout } from '../../ducks/Auth.duck';
import moment from 'moment';
import SweetAlert from 'react-bootstrap-sweetalert';
import { becomeMemberQuestions, planCards } from './BecomeMember.data';
import css from './BecomeMemberPage.css';

const MAX_MOBILE_SCREEN_WIDTH = 1024;
export class BecomeMemberPageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSellerMode: false,
      activeFooterTab: 'search',
      queryParams: null,
      chargebeeId: null,
      chargebeeState: null,
      chargebeeObject: {},
      showAlert: true,
      isRenderHome: false,
      selectedTab: 0,
      ans1: false,
      ans2: false,
      ans3: false,
      ans4: false,
    };
    this.handleProviderQueryChange = this.handleProviderQueryChange.bind(this);
    this.onkeywordSearch = this.onkeywordSearch.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.renderHomePage = this.renderHomePage.bind(this);
  }

  componentDidMount() {
    const el = document.createElement('script');
    el.onload = () => {
      if (typeof window !== 'undefined') {
        localStorage.setItem('isEducationSite', false);
        window.Chargebee.init({
          site: config.cbSite,
        });
        window.Chargebee.registerAgain();
      }
    };

    el.setAttribute('src', 'https://js.chargebee.com/v2/chargebee.js');

    document.body.appendChild(el);

    const queryParams = new URLSearchParams(window.location.search);
    const chargebeeId = queryParams.get('id');
    let chargebeeState = queryParams.get('state');

    const currentDate = moment(new Date());
    let futureDate = moment(currentDate).add(1, 'y');
    const futureMonthEnd = moment(futureDate).endOf('month');
    const plan = localStorage.getItem('plan');
    if (
      currentDate.date() != futureDate.date() &&
      futureDate.isSame(futureMonthEnd.format('YYYY-MM-DD'))
    ) {
      futureDate = futureDate.add(1, 'd');
    }
    this.setState(prev => ({
      ...prev,
      chargebeeId: chargebeeId,
      chargebeeState: chargebeeState,
      plan: plan,
      chargebeeObject: {
        chargebeeId,
        chargebeeState,
        currentDate,
        futureDate,
        plan,
      },
    }));
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.currentUser !== this.props.currentUser &&
      this.props.currentUser &&
      this.state.chargebeeId !== null &&
      this.state.chargebeeState !== null
    ) {
      this.props.onAddChargebeeSubscription(this.state.chargebeeObject, this.props.currentUser);
    }
  }

  handleProviderQueryChange(providerQueryValue) {
    this.props.searchListings({
      keywords: providerQueryValue,
      pub_listing_type: null,
    });
    this.props.history.push(createResourceLocatorString('HomePage', routeConfiguration()));
  }

  onkeywordSearch = activity => {
    const searchParams = {
      pub_sub_category: activity.key ? activity.key : null,
      keywords: activity.title ? null : activity.key ? null : activity,
      pub_listing_type: null,
      // listingId: activity.title && activity.id ? activity.id : null,
    };
    activity.id
      ? this.props.showListing(activity.id)
      : this.props.queryPromotedListings(searchParams);
  };

  onSearch = searchquery => {
    this.setState({ queryParams: searchquery });
    this.props.queryPromotedListings(searchquery);
  };

  renderHomePage = () => {
    this.setState({ ...this.state, isRenderHome: true });
  };

  render() {
    const { scrollingDisabled, viewport } = this.props;

    const isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;

    return this.state.isRenderHome ? (
      <NamedRedirect name="HomePage" />
    ) : (
      <Page title={'Become member'} scrollingDisabled={scrollingDisabled}>
        {this.state.chargebeeId && this.state.showAlert && (
          <SweetAlert
            className={css.successAlert}
            customClass={css.successAlert}
            success
            title="Thank you!"
            confirmBtnText="Go to Home Page"
            onConfirm={() => this.renderHomePage()}
            // timeout={2000}
          >
            Your subscription added successfully!
          </SweetAlert>
        )}
        <LayoutSingleColumn>
          <LayoutWrapperTopbar rootClassName={css.layoutTopbar}>
            <div className={css.topBarContainer}>
              <div className={css.probuddyLogo}>
                <NamedLink name="LandingPage">
                  <img src={ProbuddyLogo} alt={' '} />
                </NamedLink>
              </div>
              <NamedLink name="LandingPage">
                <PrimaryButton2 className={css.exploreButton}>Explore the App</PrimaryButton2>
              </NamedLink>
            </div>
          </LayoutWrapperTopbar>

          <LayoutWrapperMain>
            <div className={css.content}>
              <section className={css.servicesSectionBrand}>
                <div className={css.brandTitle}>Flexible plans with Leading Fitness Brands</div>

                <img
                  src={
                    !isMobileLayout
                      ? 'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1673512583/probuddy/becomeMemberPage/imageBecomeMember_fheen7.svg'
                      : 'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1673513857/probuddy/becomeMemberPage/imageMobile_oaiv4f.svg'
                  }
                  alt={' '}
                />
              </section>

              <div className={css.breadCrumbWrapper}>
                <NamedLink name="HomePage">Home</NamedLink>
                <span className={css.breadcrumbArrow}>&gt;</span>
                <NamedLink name="BecomeMemberPage">Become a Member</NamedLink>
              </div>

              <section className={css.servicesSection}>
                <div className={css.servicesTitle}>Flexible plans to fit your lifestyle</div>
                <div className={css.servicesSubheading}>
                  Prices reflect Total payment by employee and employer*
                </div>

                <div className={css.popularWraper}>
                  <div className={css.popular}>Popular</div>
                </div>

                <div className={css.planCardsWrapper}>
                  {planCards.map(data => (
                    <div
                      key={data.title}
                      className={css.planCard}
                      onMouseEnter={() => localStorage.setItem('plan', data.title)}
                    >
                      <div className={css.planTitle}>{data.title}</div>
                      <div className={css.planPrice}>
                        {data.price}
                        <span className={css.perMonth}>{'/ Valid 12 Months'}</span>
                      </div>
                      <div className={css.includesTitle}>{`The ${data.title} plan includes`}</div>

                      <div className={css.descriptionWrap}>
                        {data.include.map((des, index) => (
                          <div key={index} className={css.desWrap}>
                            <RightIcon />
                            {des}
                          </div>
                        ))}
                      </div>
                      <div className={css.planDivider} />
                      {/* <div className={css.partnersTitle}>{`Popular partners on ${data.title}`}</div>
                      <div className={css.partnersWrapper}>
                        {data.partners.map(partner => (
                          <div className={css.partnerCard} key={partner.title}>
                            <div className={css.partnerImageWrap}>
                              <img src={partner.image} alt={partner.title} />
                            </div>
                            <div className={css.partnerTitle}>{partner.title}</div>
                            <div className={css.partnerDes}>{partner.des}</div>
                          </div>
                        ))}
                      </div> */}
                      {/* <button className={css.planBtn}> */}
                      <div>
                        <img
                          className={css.partnerImg}
                          src={
                            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1673515411/probuddy/becomeMemberPage/imagePartner_vggkgt.svg'
                          }
                          alt="package"
                        />
                      </div>
                      <div
                        className={css.planBtn}
                        onClick={() => localStorage.setItem('plan', data.title)}
                      >
                        {!!this.props.isAuthenticated ? (
                          <a
                            className={css.anchfree}
                            href="javascript:void(0)"
                            data-cb-type="checkout"
                            // data-cb-item-0="Basic-Plan-Monthly-USD-Yearly"
                            data-cb-item-0={data.plan}
                            data-cb-item-0-quantity="1"
                            data-cb-customer-cf-user-id="123"
                          >
                            <div className={css.buttonText}>Order Now</div>
                            {/* </button> */}
                          </a>
                        ) : (
                          <NamedLink name="LoginPage">
                            <div className={css.buttonText}>Order Now</div>
                          </NamedLink>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </section>

              <section className={css.topSection}>
                <div className={css.topHeadContainer}>
                  <img
                    src={
                      !isMobileLayout
                        ? 'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1672978027/probuddy/becomeMemberPage/member_heroImage_ecywzj.svg'
                        : 'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1672978464/probuddy/becomeMemberPage/member_heroImage_mobile_r8hhkh.svg'
                    }
                    alt="hero"
                  />
                  <div className={css.sectionContainer}>
                    <h1 className={css.heroTitle}>
                      <FormattedMessage id="BecomeMemberPage.adventureMarketplace" />
                    </h1>

                    <div className={css.heroDescription}>
                      <FormattedMessage id="BecomeMemberPage.getYourClients" />
                    </div>

                    <a
                      className={css.subscriptionLink}
                      href="https://calendly.com/probuddy/15min"
                      target="blank"
                    >
                      <FormattedMessage id="BecomeMemberPage.requestDemo" />
                    </a>
                  </div>
                </div>
              </section>

              <section className={css.servicesSection}>
                <div className={css.servicesTitle}>What you’ll get</div>
                <div className={css.servicesSubheading}>
                  Access To Classes , Wellness Apps and Probuddy.io Marketplace valid up too 12
                  Months
                </div>
                {/* <div className={css.tabsWrapper}>
                  {tabs.map((tab, index) => (
                    <div
                      className={classNames(css.getTabs, {
                        [css.selectedTab]: this.state.selectedTab === index,
                      })}
                      key={index}
                      onClick={() => this.setState({ selectedTab: index })}
                    >
                      <div>{tab}</div>
                      <div
                        className={classNames(css.borderBottom, {
                          [css.selectedBorderBottom]: this.state.selectedTab === index,
                        })}
                      />
                    </div>
                  ))}
                </div> */}
                <div className={css.imageWrapper}>
                  <img
                    src={
                      'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1672823498/probuddy/becomeMemberPage/becomevendor_tabImage_zuxuhk.svg'
                    }
                    alt="tab"
                  />
                </div>
              </section>

              <section className={css.servicesSection}>
                <div className={css.servicesTitle}>Frequently Asked Questions</div>
                <div className={css.questionWraper}>
                  {becomeMemberQuestions.map((data, index) => (
                    <div
                      className={classNames(css.questionWrap, {
                        [css.questionWrapOpen]: this.state[`ans${index + 1}`],
                      })}
                      onClick={() => {
                        this.setState({ [`ans${index + 1}`]: !this.state[`ans${index + 1}`] });
                      }}
                    >
                      <div className={classNames(css.questionRow)}>
                        <div className={css.question}>{data}</div>
                        <img
                          src={this.state[`ans${index + 1}`] ? minusIcon : plusIcon}
                          alt={'plus'}
                        />
                      </div>
                      {this.state[`ans${index + 1}`] && (
                        <div className={css.ans}>
                          Simply let us know and we will connect with your company
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </section>
            </div>
          </LayoutWrapperMain>
          {!isMobileLayout ? (
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          ) : (
            <LayoutWrapperFooter
              className={classNames(css.layoutFooter, {
                [css.activeleftbarFooter]: this.state.activeFooterTab === 'leftbar',
                [css.createFooterTab]:
                  this.state.activeFooterTab === 'create' ||
                  this.state.activeFooterTab === 'activitySearch',
              })}
            >
              {!!isMobileLayout &&
                !(this.state.activeFooterTab === 'leftbar') &&
                !this.state.modalOpen && (
                  <FooterBottomMenu
                    changeActivieFooterTab={value => this.setState({ activeFooterTab: value })}
                    history={this.props.history}
                    onSearch={activity => this.onkeywordSearch(activity)}
                  />
                )}
            </LayoutWrapperFooter>
          )}
        </LayoutSingleColumn>
        <Modal
          isOpen={!!(this.state.activeFooterTab === 'leftbar')}
          onClose={() => this.setState({ activeFooterTab: 'search' })}
          id="filterModal"
          onManageDisableScrolling={() => {}}
          className={css.modalLeftBar}
        >
          <LeftBar
            isMobileLayout={isMobileLayout}
            history={this.props.history}
            isOpenSearchBar={false}
            onShowEnquiryTab={this.onSearch}
            onLogoClick={() => this.setState({ activeFooterTab: 'search' })}
            onClose={() => this.setState({ activeFooterTab: 'search' })}
          />
        </Modal>
      </Page>
    );
  }
}

BecomeMemberPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUserListing: propTypes.listing,
  currentUserListingFetched: bool,
  // from withRouter
  history: object.isRequired,
  location: object.isRequired,
  currentUser: propTypes.currentUser,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const { isAuthenticated } = state.Auth;

  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
    isAuthenticated,
  };
};

const mapDispatchToProps = dispatch => ({
  // onQueryPromotedListingReviews: listingRef => dispatch(queryPromotedListingReviews(listingRef))
  searchListings: searchParams => dispatch(searchListings(searchParams)),
  showListing: listingId => dispatch(showListing(listingId)),
  queryPromotedListings: searchquery => dispatch(queryPromotedListings(searchquery)),
  onLogout: historyPush => dispatch(logout(historyPush)),
  onAddChargebeeSubscription: (chargebeeObject, currentUser) =>
    dispatch(addChargebeeSubscription(chargebeeObject, currentUser)),
});

const BecomeMemberPage = compose(
  withRouter,
  withViewport,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(BecomeMemberPageComponent);

export default BecomeMemberPage;
