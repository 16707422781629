import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { uid } from 'react-uid';
import PropTypes from 'prop-types';
import moment from 'moment';
import css from './UpcomingCalendar.css';
import cns from 'classnames';
import { HorizontalScroll } from 'components';

const renderCalendarItems = (selectedDate, onDateSelect, numberOfDays) => {
  const currentDate = moment();
  const days = Array(numberOfDays).fill();
  return [...days].map((w, i) => {
    const date = currentDate.clone().add(i, 'days');
    const isSelectedDate = selectedDate ? selectedDate.isSame(date, 'day') : false;
    const dd = date.format('DD');

    return (
      <div
        key={uid(dd, i)}
        className={cns(css.date, isSelectedDate && css.selected)}
        onClick={() => onDateSelect(date)}
      >
        <div className={css.dateLabelContainer}>
          <div className={css.weekDate}>{date.format('ddd')}</div>
          <div className={css.numberDate}>{date.format('D')}</div>
        </div>
      </div>
    );
  });
};

function UpcomingCalendar({ initialDate, onDateSelect, numberOfDays }) {
  const [selectedDate, setSelectedDate] = useState();

  useEffect(() => {
    setSelectedDate(initialDate);
  }, [initialDate]);

  const onClickHandler = useCallback(date => {
    onDateSelect(date);
    setSelectedDate(date);
    // eslint-disable-next-line
  }, []);

  const calendarDates = useMemo(() => {
    return renderCalendarItems(selectedDate, onClickHandler, numberOfDays);
    // eslint-disable-next-line
  }, [selectedDate, numberOfDays]);

  return (
    <div className={css.upcomingCalendarContainer}>
      {selectedDate && <div className={css.month}>{selectedDate.format('MMMM')}</div>}
      <HorizontalScroll
        arrowClassName={css.scrollArrow}
        className={css.calendarContainer}
        scrollClassName={css.upcomingCalendar}
      >
        {calendarDates}
      </HorizontalScroll>
    </div>
  );
}

UpcomingCalendar.propsTypes = {
  onDateSelect: PropTypes.func.isRequired,
  numberOfDays: PropTypes.number.isRequired,
  initialDate: PropTypes.instanceOf(moment),
};

export default UpcomingCalendar;
