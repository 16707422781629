import React, { useState } from 'react';
import { richText } from '../../util/richText';
import ShowMore from 'react-show-more';

import css from './ListingPage.css';

const MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION = 20;

const SectionDescriptionMaybe = props => {
  const { description, isParagraph = false } = props;
  const [isMoreCliked, setMoreCliked] = useState(false)
  let modified = description
  const onClickedMoreHandler = () => {
    setMoreCliked(!isMoreCliked)
  }
  if (description && description.length > 500) {
    modified = description.substring(0, 500) + "..."
  }
  return description ? (
    <div className={css.sectionDescription}>
      {!isParagraph ? <ShowMore className={css.description} lines={10} more="+More" less="Less" anchorClass="">
        {richText(description, {
          longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION,
          longWordClass: css.longWord,
        })}
      </ShowMore> :
        <div className={css.paragraphContainer}>
          <pre className={css.paragraphDescription}>
            {isMoreCliked ? description : modified}
            {description && description.length > 500 && <span className={css.paragraphButton} onClick={onClickedMoreHandler}>
              &nbsp; {!isMoreCliked ? '+More' : 'Less'}
            </span>} 
          </pre>
        </div>}
    </div>
  ) : null;
};

export default SectionDescriptionMaybe;
