import React, { memo, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import config from '../../../config';
import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  FooterBottomMenu,
} from 'components';
import classNames from 'classnames';
import { injectIntl } from '../../../util/reactIntl';
import { withViewport } from '../../../util/contextHelpers';
import { withRouter } from 'react-router-dom';
import { MAX_MOBILE_SCREEN_WIDTH } from '../JobsBoard.data';
import twitterImage from '../../../assets/probuddyThumbnail-twitter-600X314.png';
import facebookImage from '../../../assets/probuddyThumbnail-facebook-1200X630.png';
import twitterImageEdu from '../../../assets/twitterThumbnail-Edu-600X310.png';
import facebookImageEdu from '../../../assets/facebookThumnail-Edu-1200X630.png';
import css from '../JobsBoard.css';
import { compose } from 'redux';
import TopbarContainer from 'containers/TopbarContainer/TopbarContainer';
import { getSingleListing } from '../JobsBoard.duck';
import JobsBoardCard from '../jobsboardComponents/JobsBoardCard';
import { ReactComponent as BackSvg } from '../../../assets/HeaderPart/NewBack.svg';
const SingleListingJobsBoard = props => {
  const { viewport, history, location, params, intl, isEducationSite } = props;
  const cardId = params.cardID;
  const dispatch = useDispatch();
  const { soloJobsBoard } = useSelector(state => state.JobsBoard);

  const [activeFooterTab, setActiveFooterTab] = useState('Jobs Board');

  const isMobileLayout = !!viewport.width && viewport.width < MAX_MOBILE_SCREEN_WIDTH;

  const siteTitle = isEducationSite ? 'Probuddy Education' : config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'JobsBoard.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'JobsBoard.schemaDescription' });

  const schemaKeywords = intl.formatMessage({ id: 'JobsBoard.schemaKeywords' });

  const canonicalRootURL = config.canonicalRootURL;

  useEffect(() => {
    dispatch(getSingleListing(cardId));
  }, []);
  return (
    <Page
      description={schemaDescription}
      title={schemaTitle}
      keywords={schemaKeywords}
      scrollingDisabled={false}
      schemaScript={false}
      twitterImages={[
        {
          name: 'twitter',
          url: isEducationSite
            ? `${canonicalRootURL}${twitterImageEdu}`
            : `${canonicalRootURL}${twitterImage}`,
          width: 600,
          height: 314,
        },
      ]}
      facebookImages={[
        {
          name: 'facebook',
          url: isEducationSite
            ? `${canonicalRootURL}${facebookImageEdu}`
            : `${canonicalRootURL}${facebookImage}`,
          width: 600,
          height: 405,
        },
      ]}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar className={css.positionStatic}>
          <TopbarContainer setIsOpenShareForm={false} isEducationSite={isEducationSite} />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain>
          <div className={css.JobsBoardBackBtn}>
            <div className={css.backbtn} onClick={() => history.goBack()}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
                <path d="M15.293 3.293 6.586 12l8.707 8.707 1.414-1.414L9.414 12l7.293-7.293-1.414-1.414z" />
              </svg>
              Back
            </div>
          </div>
          <div className={css.soloJobsBoardCard}>
            {soloJobsBoard && <JobsBoardCard jobsBoardData={soloJobsBoard} />}
          </div>
        </LayoutWrapperMain>
        {!isMobileLayout ? (
          <LayoutWrapperFooter>
            <Footer isEducationSite={isEducationSite} />
          </LayoutWrapperFooter>
        ) : (
          <LayoutWrapperFooter
            className={classNames(css.layoutFooter, {
              [css.activeleftbarFooter]: activeFooterTab === 'leftbar',
              [css.createFooterTab]:
                activeFooterTab === 'create' || activeFooterTab === 'activitySearch',
            })}
          >
            {!!isMobileLayout && !(activeFooterTab === 'leftbar') && (
              <FooterBottomMenu
                changeActivieFooterTab={value => setActiveFooterTab(value)}
                history={history}
                isEducationSite={isEducationSite}
              />
            )}
          </LayoutWrapperFooter>
        )}
      </LayoutSingleColumn>
    </Page>
  );
};

const SingleListingBoard = compose(withRouter, injectIntl, withViewport)(SingleListingJobsBoard);

export default memo(SingleListingBoard);
