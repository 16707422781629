import React, { useState } from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { withViewport } from '../../util/contextHelpers';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { loadData } from './VendorListings.duck';
import {
  Avatar,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  NamedRedirect,
  Page,
} from 'components';
import { createResourceLocatorString, openLinkNewTab } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { createSlug } from 'util/urlHelpers';
import { FormattedMessage } from '../../util/reactIntl';
import VendorCategories from './VendorCategories';
import VendorShowListings from './VendorShowListings';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { bool } from 'prop-types';
import config from 'config';
import VendorHeader from './VendorHeader';
import BGvender from '../../assets/backgroundLIstinPage.png';
import BgImageBlueDote from 'assets/BgImageDotBlue.png';
import css from './VendorListings.css';
import { LISTING_TYPES } from 'util/constants';

const VendorListingsComponent = props => {
  const {
    vendorListings,
    authorProfile,
    intl,
    scrollingDisabled,
    history,
    viewport,
    isEducationSite,
  } = props;
  const authorName = authorProfile?.attributes?.profile?.displayName || (
    <FormattedMessage id="VendorListings.noTitle" />
  );
  const authorCompanyName = authorProfile?.attributes?.profile?.publicData?.companyName;

  const [id, setId] = useState('');
  const [slug, setSlug] = useState('');
  const [categoryListings, setCategoryListings] = useState('');
  const [isRedirect, setIsRedirect] = useState(false);
  const [showListings, setShowListings] = useState(false);
  const [categoryType, setCategoryType] = useState('');

  const resetPage = () => {
    history.push(createResourceLocatorString('HomePage', routeConfiguration(), {}));
    window?.location && window.location.reload(false);
  };

  const GoToListing = vendor => {
    const id = vendor?.id?.uuid;
    const slug = createSlug(vendor?.attributes?.title);
    setId(id);
    setSlug(slug);
    setIsRedirect(true);
  };

  const links = vendorListings?.filter(
    listing =>
      listing.attributes.publicData.listing_type === LISTING_TYPES.FREELANCER ||
      listing.attributes.publicData.listing_type === LISTING_TYPES.COMPANY
  );
  const hasLinks = links.length === 1 && links[0].attributes.publicData.services;

  const getListingCategory = category => {
    setCategoryType(category);
    if (category === 'LINKS') {
      hasLinks.length === 1 && setCategoryListings(hasLinks);
    } else {
      setCategoryListings(
        vendorListings?.filter(
          listing => listing.attributes.publicData.listing_type === category.key
        )
      );
    }
    setShowListings(true);
  };

  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'HomePage.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({
    id: 'HomePage.schemaDescription',
  });

  // const listingUrl = createResourceLocatorString(
  //   'ListingPage',
  //   routeConfiguration(),
  //   { id, slug },
  //   {}
  // );

  // if (isRedirect) openLinkNewTab(listingUrl);

  return isRedirect ? (
    <NamedRedirect name="ListingPage" params={{ id: id, slug: slug }} />
  ) : (
    <Page
      className={css.mainPage}
      scrollingDisabled={scrollingDisabled}
      isBottomMenuRequire={false}
      description={schemaDescription}
      title={schemaTitle}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <VendorHeader history={history} viewport={viewport} isEducationSite={isEducationSite} />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div className={css.vendorListingsMain}>
            <div className={css.vendorBgImage}>
              <img className={css.vendorBg} src={BGvender} alt="" />
              <img className={css.vendorImageDote} src={BgImageBlueDote} alt="" />
              <div className={css.circle1}></div>
              <div className={css.circle2}></div>
              <div className={css.circle3}></div>
              <div className={css.circle4}></div>
            </div>
            <div className={css.vendorListingsWrapper}>
              {/* <div className={css.authorDetails}>
                <Avatar className={css.authorAvatar} user={authorProfile} />
                <h2 className={css.authorName}>{authorName}</h2>
                {authorCompanyName && (
                  <h2 className={css.authorCompanyName}>{authorCompanyName}</h2>
                )}
                <img src={Stars} alt="Stars" />
              </div> */}

              {!showListings ? (
                <VendorCategories
                  getListingCategory={getListingCategory}
                  vendorListings={vendorListings}
                  isEducationSite={isEducationSite}
                  hasLinks={hasLinks}
                />
              ) : (
                <VendorShowListings
                  categoryListings={categoryListings}
                  GoToListing={GoToListing}
                  categoryType={categoryType}
                  setShowListings={setShowListings}
                />
              )}
            </div>
            {/* <div className={css.probuddyLogo}>
              <div onClick={resetPage}>
                <img src={ProbuddyLogo} alt="ProbuddyLogo" />
              </div>
            </div> */}
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter />
      </LayoutSingleColumn>
    </Page>
  );
};

VendorListingsComponent.propTypes = {
  scrollingDisabled: bool.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { vendorListings, authorProfile } = state.VendorListings;
  return { scrollingDisabled: isScrollingDisabled(state), vendorListings, authorProfile };
};

const VendorListings = compose(
  withRouter,
  withViewport,
  connect(mapStateToProps)
)(injectIntl(VendorListingsComponent));

VendorListings.loadData = loadData;

export default VendorListings;
