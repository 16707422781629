import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import thankyousvg from '../../assets/ThankYouPage/thankyousvg.svg';
import css from '../ThankYouPage/ThankYouPage.css';

const MessageDeliveredPageComponent = props => {
  const { history } = props;

  const GoToHome = () => history.goBack();

  return (
    <div className={css.mainWrapper}>
      <div className={css.thankyouWrapper}>
        <img src={thankyousvg} alt={' '} />
        <h1>
          <FormattedMessage id="MessageDeliveredPage.message" />
        </h1>
        <button className={css.btnDone} onClick={GoToHome}>
          <FormattedMessage id="ThankYouPage.done" />
        </button>
      </div>
    </div>
  );
};

const MessageDeliveredPage = compose(withRouter)(injectIntl(MessageDeliveredPageComponent));

export default MessageDeliveredPage;
