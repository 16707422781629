import React from 'react';
import { uid } from 'react-uid';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import classNames from 'classnames';
import { propTypes } from 'util/types';
import { FormattedMessage, injectIntl, intlShape } from 'util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import { Button, Form, FieldTextInput } from '../../components';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import { SecondaryButton } from '../../components';

import css from './EditListingCertificatesForm.css';

const MAX_FIELDS = 5;

export const EditListingCertificatesForm = props => {
  const textRequired = value => (value ? undefined : 'Required');
  const filedArrayRequired = value => {
    if (value && value.length < 1) {
      return 'Require at least one entry';
    }

    return undefined;
  };
  return (
    <FinalForm
      {...props}
      mutators={{
        ...arrayMutators,
      }}
      initialValues={props.initialValues}
    >
      {formRenderProps => {
        const classes = classNames(css.root, formRenderProps.className);
        const { updateListingError, showListingsError } = formRenderProps.fetchErrors || {};
        const {
          mutators: { push, pop },
        } = formRenderProps.form;

        const submitInProgress = formRenderProps.updateInProgress;
        const submitReady = formRenderProps.updated || formRenderProps.ready;
        const submitDisabled =
          formRenderProps.invalid ||
          formRenderProps.disabled ||
          formRenderProps.submitInProgress ||
          formRenderProps.pristine;

        return (
          <Form onSubmit={formRenderProps.handleSubmit} className={classes}>
            {updateListingError ? (
              <p className={css.error}>
                <FormattedMessage id="EditListingPricingForm.updateFailed" />
              </p>
            ) : null}
            {showListingsError ? (
              <p className={css.error}>
                <FormattedMessage id="EditListingPricingForm.showListingFailed" />
              </p>
            ) : null}

            <FieldArray name={'certificates'} validate={filedArrayRequired}>
              {({ fields }) => {
                return fields.map((name, index) => {
                  if (index < MAX_FIELDS) {
                    return (
                      <>
                        <FieldTextInput
                          key={uid(`${name}_header_${index}d`)}
                          id="certificatedHeader"
                          name={`${name}.header`}
                          type="text"
                          className={css.headerInput}
                          autoFocus
                          label={
                            <FormattedMessage id={'EditListingCertificatesForm.headerLabel'} />
                          }
                          validate={textRequired}
                        />
                        <FieldTextInput
                          key={uid(`${name}_${index}`)}
                          id="certificate"
                          name={`${name}.text`}
                          type="textarea"
                          rows="3"
                          className={css.textInput}
                          label={<FormattedMessage id={'EditListingCertificatesForm.bodyLabel'} />}
                          validate={textRequired}
                        />
                      </>
                    );
                  } else {
                    return null;
                  }
                });
              }}
            </FieldArray>

            <div className={css.mutateBtns}>
              <SecondaryButton
                disabled={
                  formRenderProps.values.certificates &&
                  formRenderProps.values.certificates.length === MAX_FIELDS
                }
                type="button"
                onClick={() => push('certificates', undefined)}
              >
                <FormattedMessage id={'EditListingCertificatesForm.addCertificate'} />
              </SecondaryButton>

              <SecondaryButton type="button" onClick={() => pop('certificates')}>
                <FormattedMessage id={'EditListingCertificatesForm.removeCertificate'} />
              </SecondaryButton>
            </div>
            <div className={css.submitButtonContainer}>
              <Button
                className={css.submitButton}
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
                ready={submitReady}
              >
                {formRenderProps.saveActionMsg}
              </Button>
            </div>
          </Form>
        );
      }}
    </FinalForm>
  );
};

EditListingCertificatesForm.defaultProps = { fetchErrors: null };

EditListingCertificatesForm.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingCertificatesForm);
