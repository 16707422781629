import React from 'react';
import classNames from 'classnames';
import css from './ModeFilter.css';
import { levelSwitchesKids } from 'containers/HomePage/HomePage.data';
import Switch from 'react-switch';

const ModeFilter = ({ selectedKidsAge, handleModeChange }) => {
  return (
    <div className={css.filterMain}>
      <h5 className={css.filterHead}>Select Kids Age</h5>
      <div className={css.filterWrap}>
        {levelSwitchesKids.map(item => {
          let label = item.label;
          return (
            <div
              key={item.id} // Make sure to include a unique key for each item
              className={classNames(css.ageSwitch, {
                [css.switchChecked]: selectedKidsAge === item.id,
                [css.switchUnchecked]: selectedKidsAge !== item.id,
              })}
            >
              <h4 className={css.ageSwitchHead}>{label}</h4>
              <Switch
                uncheckedIcon={false}
                checkedIcon={false}
                onChange={() => handleModeChange(item)} // Wrap the function call in an arrow function
                checked={selectedKidsAge === item.id}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ModeFilter;
