import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { SportsCategoriesCard } from '../../components';
import { CategoriesListOne, CategoriesListTwo } from '../../util/gameTypesData';
import { FormattedMessage } from '../../util/reactIntl';
import css from './SectionTopIconDesktop.css';

const SectionTopIconDesktop = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  const [firstSectionActivatePosition, setFirstSectionActivatePosition] = useState(1);
  const [secondSectionActivatePosition, setSecondSectionActivatePosition] = useState(1);
  const svgIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="15" viewBox="0 0 10 15" fill="none">
      <path
        d="M2.3454 0.333984L0.708008 1.97137L6.02662 7.3016L0.708008 12.6318L2.3454 14.2692L9.31301 7.3016L2.3454 0.333984Z"
        fill="#0095B3"
      />
    </svg>
  );

  const scrollTo = value => {
    var scroller = document.getElementById('FirstPromoCategorySection');
    scroller.scrollLeft = value;
  };

  const scrollSecoundTo = value => {
    var scroller = document.getElementById('SecondPromoCategorySection');
    scroller.scrollLeft = value;
  };

  return (
    <div className={classes}>
      <div className={css.header}>
        <div className={css.title}>
          <FormattedMessage id="SectionTopIcon.title" />
        </div>
      </div>
      <div className={css.topIcons}>
        <div className={css.categoryButtonContainer}>
          <div
            className={classNames(css.categoryButton, {
              [css.seletedCategoryButton]: firstSectionActivatePosition === 1,
            })}
            onClick={() => {
              setFirstSectionActivatePosition(1);
              scrollTo('0');
            }}
          >
            Adventure
          </div>
          <div
            className={classNames(css.categoryButton, {
              [css.seletedCategoryButton]: firstSectionActivatePosition === 2,
            })}
            onClick={() => {
              setFirstSectionActivatePosition(2);
              scrollTo('320');
            }}
          >
            Wellness
          </div>
          <div
            className={classNames(css.categoryButton, {
              [css.seletedCategoryButton]: firstSectionActivatePosition === 3,
            })}
            onClick={() => {
              setFirstSectionActivatePosition(3);
              scrollTo('640');
            }}
          >
            Kids
          </div>
        </div>
        <div id={'FirstPromoCategorySection'} className={css.serviceSection}>
          {CategoriesListOne.map(val => {
            const { title, image, name, links, searchPageLink } = val;
            return (
              <SportsCategoriesCard
                svgIcon={svgIcon}
                image={image}
                title={title}
                name={name}
                links={links}
                searchPageLink={searchPageLink}
              />
            );
          })}
        </div>
        <div className={css.categoryButtonContainer}>
          <div
            className={classNames(css.categoryButton, {
              [css.seletedCategoryButton]: secondSectionActivatePosition === 1,
            })}
            onClick={() => {
              setSecondSectionActivatePosition(1);
              scrollSecoundTo('0');
            }}
          >
            Fitness
          </div>
          <div
            className={classNames(css.categoryButton, {
              [css.seletedCategoryButton]: secondSectionActivatePosition === 2,
            })}
            onClick={() => {
              setSecondSectionActivatePosition(2);
              scrollSecoundTo('320');
            }}
          >
            Racket
          </div>
          <div
            className={classNames(css.categoryButton, {
              [css.seletedCategoryButton]: secondSectionActivatePosition === 3,
            })}
            onClick={() => {
              setSecondSectionActivatePosition(3);
              scrollSecoundTo('640');
            }}
          >
            Combat Sports
          </div>
        </div>
        <div id={'SecondPromoCategorySection'} className={css.serviceSection}>
          {CategoriesListTwo.map(val => {
            const { title, image, name, links, searchPageLink } = val;
            return (
              <SportsCategoriesCard
                svgIcon={svgIcon}
                image={image}
                title={title}
                name={name}
                links={links}
                searchPageLink={searchPageLink}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

SectionTopIconDesktop.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionTopIconDesktop.propTypes = {
  rootClassName: string,
  className: string,
};

export default React.memo(SectionTopIconDesktop);
