/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import moment from 'moment';
import { get } from 'lodash/object';
import { array, arrayOf, bool, func, object, shape, string, oneOf } from 'prop-types';
import cns from 'classnames';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import emailjs, { init } from '@emailjs/browser';
import config from '../../config';
import { addMetaData } from '../../util/api';
import routeConfiguration from '../../routeConfiguration';
import {
  LISTING_STATE_PENDING_APPROVAL,
  LISTING_STATE_CLOSED,
  LINE_ITEM_NIGHT,
  LINE_ITEM_DAY,
  propTypes,
} from '../../util/types';
import { withViewport } from '../../util/contextHelpers';
import packagesvg from '../../assets/HomePage/packages.svg';
import CompanySelectionIcon from '../../assets/VendorListingPage/brand.svg';
import LeftbarMenuicon from '../../assets/HomePage/new_categories.svg';
import TopFloatingMessage from 'components/TopFloatingMessage/TopFloatingMessage';
import levelSvg from '../../assets/HomePage/filterIcon.svg';
import MyListing from '../../assets/HomePage/myListing.svg';

import axios from 'axios';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  createSlug,
  parse,
} from '../../util/urlHelpers';
import { createResourceLocatorString, findRouteByRouteName } from '../../util/routes';
import {
  ensureListing,
  ensureOwnListing,
  ensurePrice,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import { timestampToDate, calculateQuantityFromHours } from '../../util/dates';
import { addSubscription, deleteSubscription } from '../../util/api';
import { richText } from '../../util/richText';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { initializeCardPaymentData } from '../../ducks/stripe.duck.js';
import {
  Page,
  Modal,
  NamedLink,
  NamedRedirect,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  BookingPanel,
  // SectionLevel,
  PackagesModal,
  IconStar,
  IconCheckedShield,
  AvatarLarge,
  // Avatar,
  Button,
  // IconClose,
} from '../../components';
import { EnquiryForm, ShareForm } from '../../forms';
import { TopbarContainer, NotFoundPage } from '../../containers';
import { showListing } from '../HomePage/HomePage.duck';
import {
  sendEnquiry,
  loadData,
  setInitialValues,
  fetchTimeSlots,
  timeSlotsRequestFacility,
  addingCustomPrice,
  removingCustomPrice,
  addBookingData,
  cancelSubscription,
  createTranstion,
} from './ListingPage.duck';
import SectionHeading from './SectionHeading';
import SectionMapMaybe from './SectionMapMaybe';
// import SectionFacilities from './SectionFacilities';
import MdMedkit from 'react-ionicons/lib/MdMedkit';
import MdGlobe from 'react-ionicons/lib/MdGlobe';
import MdVideocam from 'react-ionicons/lib/MdVideocam';
import LogoDropbox from 'react-ionicons/lib/LogoDropbox';
import MdCheckmark from 'react-ionicons/lib/MdCheckmark';
import MdSend from 'react-ionicons/lib/MdSend';
import ActionBarMaybe from 'containers/ListingPage/ActionBarMaybe';
import EnquiryPageContent from 'containers/ListingPage/EnquiryPageContent';
import SectionHeadImagesSlider from 'components/SectionHeadImagesSlider/SectionHeadImagesSlider';
import SectionCertification from 'components/SectionCertifications/SectionCertifications';
import SectionWhatYouCanExpect from 'components/SectionWhatYouCanExpect/SectionWhatYouCanExpect';
import ResponsiveImage from 'components/ResponsiveImage/ResponsiveImage';
import { ReactComponent as Share } from '../../assets/HeaderPart/ShareNew.svg';
import { LISTING_TYPES, PAYMENT_TYPES } from 'util/constants';
import { GenericMessage } from 'components';
import { Packages, USAGE } from 'components/PackagesModal/Packages';
import CompanyPageContent from 'containers/ListingPage/CompanyPage/CompanyPageContent';
import { fetchAuthorFacilities } from 'containers/ListingPage/ListingPage.duck';
import { pathByRouteName } from 'util/routes';
import SectionReviews from './SectionReviews';

import {
  bookingTitleId,
  MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
  priceData,
  getRemovedWithOutContentReview,
  getSeatsFromMonthlyTimeSlots,
  openBookModal,
  UUID,
  removeFile,
  companyMenuList,
  bookingMenuList,
  actionBarRouteName,
  listingImages,
} from './ListingPage.data';
import { specialListings } from 'marketplace-custom-config';
import { SectionOtherAutherListing } from './SectionOtherAutherListing';
import classNames from 'classnames';
import css from './ListingPage.css';
import { listingPageTrack } from 'util/gtag_conversionTracking';
import { toTitleCase } from 'util/stringHelper';
// import { initiateOrder } from 'containers/CheckoutPage/CheckoutPage.duck';

export class ListingPageComponent extends Component {
  constructor(props) {
    super(props);
    const { enquiryModalOpenForListingId, params } = props;
    this.state = {
      pageClassNames: [],
      imageCarouselOpen: false,
      enquiryModalOpen: enquiryModalOpenForListingId === params.id,
      // rating: 3,
      // whatwilldo: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
      showPackages: false,
      withPayment: true,
      paymentProofSsUrls: [],
      paymentProofRequiredError: null,
      packageAttr: [],
      showQuotes: false,
      creditsRequestSuccessMessage: false,
      creditsRequestFailMessage: false,
      cancelSubscriptionSucessMessage: false,
      cancelSubscriptionErrorMessage: false,
      creditRequestInProgress: false,
      isReviewFetch: false,
      openChatBox: false,
      isMainWrapperBlur: false,
      showAllImages: false,
      showFacility: false,
      isOpenShareForm: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.onContactUser = this.onContactUser.bind(this);
    this.onSubmitEnquiry = this.onSubmitEnquiry.bind(this);
    this.handlePackagesModalClose = this.handlePackagesModalClose.bind(this);
    this.handlePackagesModalOpen = this.handlePackagesModalOpen.bind(this);
    this.handlePackageBuy = this.handlePackageBuy.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.onCloseCreditsRequestFailMessage = this.onCloseCreditsRequestFailMessage.bind(this);
    this.mainWrapperBlurHandler = this.mainWrapperBlurHandler.bind(this);
    this.onClickChatIconHandler = this.onClickChatIconHandler.bind(this);
    this.onCloseChatBoxHandler = this.onCloseChatBoxHandler.bind(this);
    this.getNumberOfImageDisplay = this.getNumberOfImageDisplay.bind(this);
    this.addMembershipSubscription = this.addMembershipSubscription.bind(this);
    this.onCancelSubscription = this.onCancelSubscription.bind(this);
    this.onMakeListingLive = this.onMakeListingLive.bind(this);
  }

  componentDidUpdate() {
    if (!this.state.isReviewFetch && this.props.authorsListingsRefs.length !== 0) {
      this.setState({ isReviewFetch: true });
    }
    return false;
  }

  componentDidMount() {
    this.setState({ enquiryModalOpen: false });
    init('DkU54piL9T3fDaZV1');
    init('p656r9_5pKAHF0ioU');
    if (
      this.props.location.search === '?private' &&
      typeof window !== 'undefined' &&
      !!window.sessionStorage
    ) {
      window.sessionStorage.setItem('privateSiteUrl', this.props.location.pathname);
    }
    if (typeof window !== 'undefined') {
      localStorage.setItem('isEducationSite', false);
      localStorage.setItem('isTennisSite', false);
      window.onscroll = () => {
        this.setState({ showQuotes: window.pageYOffset > 100 });
      };
      listingPageTrack();
    }
  }

  checkIfUserLoggedIn = () => {
    const { history } = this.props;
    history.push(pathByRouteName('LoginPage', routeConfiguration()));
  };

  onClosecreditsRequestSuccessMessage = () => {
    this.setState({ creditsRequestSuccessMessage: false });
  };

  onCloseCreditsRequestFailMessage = () => {
    this.setState({ creditsRequestFailMessage: false });
  };

  onMakeListingLive = data => {
    const listing = addMetaData(data);
    console.log(listing);
    setTimeout(() => {
      window?.location && window.location.reload(false);
    }, 3000);
  };

  onDrop = files => {
    const url = 'https://api.cloudinary.com/v1_1/movementbuddy-pte-ltd/auto/upload';
    const formData = new FormData();

    for (let i = 0; i < files.length; i++) {
      let file = files[i];
      formData.append('file', file);
      formData.append('upload_preset', 'movementbuddy');

      fetch(url, { method: 'POST', body: formData })
        .then(response => response.text())
        .then(data => {
          this.setState({
            paymentProofSsUrls: [...this.state.paymentProofSsUrls, JSON.parse(data)],
          });
        });
    }
  };

  handleSubmit(values) {
    const {
      history,
      getListing,
      params,
      callSetInitialValues,
      onInitializeCardPaymentData,
      currentUser,
    } = this.props;

    if (!currentUser) {
      this.checkIfUserLoggedIn();
      return;
    }

    const listingId = new UUID(params.id);
    const listing = getListing(listingId);
    const { bookingStartTime, bookingEndTime, period, ...restOfValues } = values;
    const bookingStart = timestampToDate(bookingStartTime);
    const bookingEnd = timestampToDate(bookingEndTime);
    const defaultCredits = currentUser?.attributes?.profile?.protectedData?.DefaultCredits;

    const bookingData = {
      quantity: calculateQuantityFromHours(bookingStart, bookingEnd),
      period: Number(period),
      remainingDefalutCurrency:
        values.withDefaultCredits &&
        defaultCredits &&
        defaultCredits - listing?.attributes?.price?.amount,
      ...restOfValues,
    };

    const initialValues = values.productBooking
      ? {
          listing,
          bookingData: restOfValues,
          confirmPaymentError: null,
          bookingDates: {
            bookingStart: new Date(),
          },
        }
      : {
          listing,
          bookingData,
          bookingDates: {
            bookingStart,
            bookingEnd,
          },
          confirmPaymentError: null,
        };

    const saveToSessionStorage = !this.props.currentUser;

    const routes = routeConfiguration();
    // Customize checkout page state with current listing and selected bookingDates
    const pageName = values.withPayment === true ? 'CheckoutPage' : 'CheckoutPageWithoutPayment';
    const { setInitialValues } = findRouteByRouteName(pageName, routes);

    const checkoutParams = values.withPayment
      ? { id: listing.id.uuid, slug: createSlug(listing.attributes.title) }
      : {
          id: listing.id.uuid,
          slug: createSlug(listing.attributes.title),
          credits: !!values.withDefaultCredits
            ? (!values.withDefaultCredits).toString()
            : !!values.requestBooking
            ? (!values.requestBooking).toString()
            : values.withCredits
            ? values.withCredits.toString()
            : false,
        };

    callSetInitialValues(setInitialValues, initialValues);

    callSetInitialValues(setInitialValues, initialValues, saveToSessionStorage);

    // Clear previous Stripe errors from store if there is any
    onInitializeCardPaymentData();
    // Redirect to CheckoutPage

    values.withCredits
      ? history.push(
          createResourceLocatorString('CheckoutPageWithoutPayment', routes, checkoutParams)
        )
      : history.push(createResourceLocatorString('CheckoutPage', routes, checkoutParams));
  }

  onContactUser() {
    const { currentUser, callSetInitialValues, params } = this.props;

    if (!currentUser) {
      // const state = { from: `${location.pathname}${location.search}${location.hash}` };

      // We need to log in before showing the modal, but first we need to ensure
      // that modal does open when user is redirected back to this listingpage
      callSetInitialValues(setInitialValues, { enquiryModalOpenForListingId: params.id });

      this.setState({ enquiryModalOpen: true });
      // signup and return back to listingPage.
      // history.push(createResourceLocatorString('SignupPage', routeConfiguration(), {}, {}), state);
    } else {
      this.setState({ enquiryModalOpen: true });
    }
  }

  onSubmitEnquiry(values) {
    const { history, params, onSendEnquiry, currentUser, isAuthenticated } = this.props;

    const routes = routeConfiguration();
    const listingId = new UUID(params.id);
    const { message } = values;

    if (!currentUser || !isAuthenticated) {
      // localStorage.setItem('enquiry_message', message);
      // localStorage.setItem('showloginMessage', true);
      // const state = { from: `${location.pathname}${location.search}${location.hash}` };
      // history.push(createResourceLocatorString('SignupPage', routeConfiguration(), {}, {}), state);

      const url =
        typeof window !== undefined
          ? window?.location?.href
          : `https://www.probuddy.io/l/${listingId?.uuid}`;
      const templateParams = {
        for_name: listingId?.uuid,
        message: message,
        from_mail: values?.email,
        from_name: 'Probuddy',
        reply_to: values?.email,
        to_mail: 'Chris@probuddy.io,probuddyinquiries@gmail.com',
        url: url,
      };
      // process.env.REACT_APP_ENV === 'production' &&
      emailjs
        .send('service_tn96fm5', 'template_04szlx8', templateParams, 'p656r9_5pKAHF0ioU')
        .then()
        .catch(e => console.log('e-->', e));
      this.setState({ enquiryModalOpen: false });
      history.push('/message-delivered');
    } else {
      onSendEnquiry(listingId, message?.trim())
        .then(txId => {
          this.setState({ enquiryModalOpen: false });
          // localStorage.removeItem('enquiry_message');
          // localStorage.removeItem('showloginMessage');

          // Redirect to OrderDetailsPage
          history.push(
            createResourceLocatorString('InboxPage', routes, { tab: 'enquiry-client' }, {})
          );
        })
        .catch(e => {
          // Ignore, error handling in duck file
          console.log('inside catch', e);
        });
    }
  }

  handlePackagesModalClose() {
    this.setState({
      showPackages: false,
    });
  }

  handlePackagesModalOpen() {
    const { currentUser } = this.props;
    if (!currentUser) {
      this.checkIfUserLoggedIn();
      return;
    }

    this.setState({
      showPackages: true,
    });
  }

  handlePaymentTypeSelect = withPayment => {
    this.setState({
      withPayment,
    });
  };

  addMembershipSubscription() {
    const { getListing, params, currentUser } = this.props;
    if (!currentUser) {
      this.checkIfUserLoggedIn();
      return;
    }
    this.stripe = typeof window !== 'undefined' && window.Stripe(config.stripe.publishableKey);

    const listingId = new UUID(params.id);
    const isPendingApprovalVariant = params.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
    const isDraftVariant = params.variant === LISTING_PAGE_DRAFT_VARIANT;
    const currentListing =
      isPendingApprovalVariant || isDraftVariant ? null : ensureListing(getListing(listingId));

    const authorAvailable = currentListing && currentListing.author;
    const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
    const isOwnListing =
      userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;
    if (isOwnListing) {
      return;
    }
    const currentAuthor = authorAvailable ? currentListing.author : null;
    const ensuredAuthor = ensureUser(currentAuthor);
    const currency = currentListing?.attributes.price.currency;
    const applicationFeeAmount = currentListing?.attributes?.publicData?.membershipPrice
      ? currentListing?.attributes?.publicData?.membershipPrice * config.creditCardExtraFee
      : currentListing?.attributes?.price?.amount * config.creditCardExtraFee;

    const amount = currentListing?.attributes?.publicData?.membershipPrice
      ? Number(currentListing?.attributes?.publicData?.membershipPrice) +
        Number(applicationFeeAmount)
      : Number(currentListing?.attributes?.price?.amount) + Number(applicationFeeAmount);
    const payment_param = {
      amount: amount,
      buyer: currentUser,
      currency: currency,
      listing_id: listingId.uuid,
      listing_title: currentListing.attributes.title,
      seller: ensuredAuthor,
    };

    addSubscription(payment_param)
      .then(response => {
        window.open(response.url);
        // this.stripe
        //   .redirectToCheckout({
        //     // Make the id field from the Checkout Session creation API response
        //     // available to this file, so you can provide it as parameter here
        //     // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
        //     sessionId: response.id,
        //   })
        //   .then(res => {
        //     if (res?.error?.message) {
        //       console.log('error::::', res.error.message);
        //     }
        //   });
      })
      .catch(error => {
        console.log(error);
      });
  }

  onCancelSubscription = data => {
    deleteSubscription(data);
    setTimeout(() => {
      window?.location && window.location.reload(false);
    }, 5000);
  };

  async handlePackageBuy(packageAttr, withPayment, callBack) {
    // if (
    //   !withPayment &&
    //   !packageAttr.student_name &&
    //   this.state.paymentProofSsUrls.length <= 0 &&
    //   !packageAttr.membership_card_number
    // )
    const paymentType = packageAttr.paymentType === PAYMENT_TYPES.hitpay;

    if (
      !withPayment &&
      (!packageAttr.student_name ||
        (this.state.paymentProofSsUrls.length <= 0 && !packageAttr.membership_card_number))
    ) {
      this.setState({
        paymentProofRequiredError: <FormattedMessage id="ListingPage.paymentProofRequiredError" />,
      });
      return;
    } else if (withPayment === true && packageAttr.student_name === undefined) {
      this.setState({
        paymentProofRequiredError: <FormattedMessage id="ListingPage.paymentProofRequiredError" />,
      });
      return;
    }

    this.setState({
      creditRequestInProgress: true,
    });

    this.stripe = typeof window !== 'undefined' && window.Stripe(config.stripe.publishableKey);
    const { currentUser, params, getListing } = this.props;
    const { paymentProofSsUrls } = this.state;

    const listingId = new UUID(params.id);
    const isPendingApprovalVariant = params.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
    const isDraftVariant = params.variant === LISTING_PAGE_DRAFT_VARIANT;
    const currentListing =
      isPendingApprovalVariant || isDraftVariant ? null : ensureListing(getListing(listingId));

    const authorAvailable = currentListing && currentListing.author;
    const currentAuthor = authorAvailable ? currentListing.author : null;
    const ensuredAuthor = ensureUser(currentAuthor);

    const currentDate = moment(new Date());
    let futureDate = moment(currentDate).add(1, 'M');
    const futureMonthEnd = moment(futureDate).endOf('month');
    if (
      currentDate.date() !== futureDate.date() &&
      futureDate.isSame(futureMonthEnd.format('YYYY-MM-DD'))
    ) {
      futureDate = futureDate.add(1, 'd');
    }

    const currency =
      currentListing?.attributes?.publicData?.currency ||
      currentListing?.attributes?.price?.currency ||
      config.currency;

    const amount = withPayment
      ? Number(packageAttr?.amount) - Number(packageAttr?.application_fee_amount)
      : Number(packageAttr?.amount);
    const totalAmount = Number(packageAttr?.amount);
    // with payments (card payments) so we add this %

    const providerCommissionPercentage = withPayment
      ? currentListing?.attributes?.metadata &&
        currentListing?.attributes?.metadata?.hasOwnProperty('providerCommissionPercentage')
        ? 4 + Number(currentListing?.attributes?.metadata?.providerCommissionPercentage || 0)
        : 14
      : 0;

    const application_fee_amount =
      (packageAttr?.amount - packageAttr?.application_fee_amount) *
      0.01 *
      providerCommissionPercentage;
    const backURL = withPayment
      ? !paymentType
        ? config.paymentIntentsEndPoint
        : config.hitPayEndPointForPackages
      : config.creditRequestEndPoint;
    const url = config.serverBaseUrl + backURL;

    const currentPageUrl = typeof window !== 'undefined' ? window?.location?.href : '';

    const packagePaymentParams =
      withPayment === true
        ? paymentType
          ? {
              hitpay_params: {
                email: currentUser?.attributes?.email,
                amount: totalAmount,
                student_name: packageAttr?.student_name,
                currency: currency,
                listing_id: listingId.uuid,
                listing_title: currentListing.attributes.title,
                redirect_url: currentPageUrl,
                // new added
                buyer: currentUser,
                seller: ensuredAuthor,
                payment_type: 'online_payment',
                payment_mode: 'hitPay',
                credits: packageAttr?.credits || 1,
              },
              token: config.serverToken,
            }
          : {
              payment_params: {
                ...packageAttr,
                buyer: currentUser,
                seller: ensuredAuthor,
                currency: currency,
                listing_id: listingId.uuid,
                listing_title: currentListing.attributes.title,
                payment_type: 'online_payment',
                expiry_date: futureDate,
                application_fee_amount: application_fee_amount,
              },
            }
        : {
            credit_request_params: {
              ...packageAttr,
              buyer_id: currentUser.id.uuid,
              seller_id: ensuredAuthor.id.uuid,
              currency: currency,
              listing_id: listingId.uuid,
              payment_proof_ss_urls: paymentProofSsUrls.map(a => a.secure_url),
              listing_title: currentListing.attributes.title,
              author_name: currentListing.author.attributes.profile.displayName,
              payment_type: 'bank_payment',
              expiry_date: futureDate,
            },
          };
    const feeAmount = withPayment ? Number(packageAttr?.application_fee_amount) / 100 : 0;

    const templateParams = {
      className: currentListing?.attributes?.title,
      toName: currentUser?.attributes?.profile?.displayName,
      startTime: moment(new Date()).format('hh:mm a'),
      endTime: futureDate.format('hh:mm a'),
      startDate: moment(new Date()).format('MMMM D YYYY'),
      endDate: futureDate.format('MMMM D YYYY'),
      amount: `${amount / 100}`,
      subamount: `${amount / 100}`,
      currency: currency,
      unit: packageAttr?.credits,
      seat: '1',
      feeAmount: `${feeAmount}`,
      hourText: 'Total hours',
      totalAmount: `${totalAmount / 100}`,
      mailTo: currentUser?.attributes?.email,
      mailText: 'buy the package of',
      priceText: 'Package Price',
    };
    const headers = {
      headers: {
        Authorization: `Token token=${config.serverToken}`,
      },
    };

    this.setState({
      packageAttr: packageAttr,
    });

    axios
      .post(url, packagePaymentParams, headers)
      .then(response => {
        process.env.REACT_APP_ENV === 'production' &&
          emailjs
            .send('service_o59sg3q', 'template_q6uoneb', templateParams, 'DkU54piL9T3fDaZV1')
            .then()
            .catch(e => console.error(e));
        if (withPayment === true) {
          paymentType
            ? (window.location.href = response.data)
            : this.stripe
                .redirectToCheckout({
                  // Make the id field from the Checkout Session creation API response
                  // available to this file, so you can provide it as parameter here
                  // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
                  sessionId: response.data,
                })
                .then(function(result) {
                  // If `redirectToCheckout` fails due to a browser or network
                  // error, display the localized error message to your customer
                  // using `result.error.message`.
                  if (!result.error.message) {
                    callBack();
                  }
                });
        } else {
          this.setState({
            creditsRequestSuccessMessage: true,
            creditsRequestFailMessage: false,
            // showPackages: false,
            creditRequestInProgress: false,
          });
          callBack();
          // closeBookModal(history, location);
        }
      })
      .catch(error => {
        this.setState({
          creditsRequestFailMessage: true,
          creditsRequestSuccessMessage: false,
          showPackages: false,
          creditRequestInProgress: false,
        });
        // closeBookModal(history, location);
      });
  }

  mainWrapperBlurHandler = value => {
    this.setState({ isMainWrapperBlur: value });
  };

  onClickChatIconHandler = () => {
    this.setState({ openChatBox: true });
  };

  onCloseChatBoxHandler = () => {
    this.setState({ openChatBox: false });
  };

  getNumberOfImageDisplay = () => {
    const width = this.props.viewport.width;
    if (width > 1366) return 8;
    else if (width > 768) return 6;
    else return 8;
  };

  render() {
    const {
      unitType,
      isAuthenticated,
      currentUser,
      getListing,
      getOwnListing,
      intl,
      onFetchTimeSlots,
      params: rawParams,
      location,
      scrollingDisabled,
      showListingError,
      reviews,
      sendEnquiryInProgress,
      sendEnquiryError,
      monthlyTimeSlots,
      sportsConfig,
      activityLevelConfig,
      treatmentRoomOptionsConfig,
      viewport,
      getAllAuthorsListingsExceptCurrent,
      getAllAuthorsListingsExceptCurrentFacility,
      onFetchAuthorFacilities,
      authorFacilitiesIsLoading,
      authorFacilities,
      timeSlotsRequestFacility,
      facilityTimeslots,
      bookedTimeslots,
      authorClasses,
      authorClassesIsLoading,
      listingAllReviews,
      authorsCompanyListingDetails,
      history,
      addBookingData,
      isTimeSlotesLoading,
    } = this.props;
    const isMobileLayout = viewport && viewport.width && viewport.width <= 1024;
    const listingId = new UUID(rawParams.id);
    const isPendingApprovalVariant = rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
    const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
    const currentListing =
      isPendingApprovalVariant || isDraftVariant
        ? ensureOwnListing(getOwnListing(listingId))
        : ensureListing(getListing(listingId));

    const authorId = currentListing?.author?.id;

    const otherAuthorsListings = getAllAuthorsListingsExceptCurrent(listingId);
    const otherAuthorsFacilitiesListings = getAllAuthorsListingsExceptCurrentFacility(listingId);
    const listingVariant = currentListing.attributes.publicData.listing_type;
    const routeName = actionBarRouteName(listingVariant);
    // const listing_mode = currentListing?.attributes?.publicData?.listing_mode;
    // const isProduct =
    //   listingVariant === LISTING_TYPES.PRODUCT ||
    //   listingVariant === LISTING_TYPES.EDUCATION_COURSES;
    const isProduct = listingVariant === LISTING_TYPES.EDUCATION_COURSES;

    const isEducationSite =
      [
        LISTING_TYPES.ACADEMY,
        LISTING_TYPES.COURSES,
        LISTING_TYPES.EDUCATION_COURSES,
        LISTING_TYPES.HOURLY_SESSION,
        LISTING_TYPES.TUTOR,
        LISTING_TYPES.REMOTE_TUTOR,
      ].includes(listingVariant) ||
      (listingVariant === LISTING_TYPES.ENQUIRY
        ? ['language', 'academic', 'management', 'art'].includes(
            currentListing?.attributes?.publicData?.category
          )
        : false);

    const isTennisSite =
      [LISTING_TYPES.PLAYER_PROFILE].includes(listingVariant) ||
      (listingVariant === LISTING_TYPES.ENQUIRY
        ? ['sports'].includes(currentListing?.attributes?.publicData?.category)
        : false);

    if (typeof window !== 'undefined' && !!isEducationSite && !!isTennisSite) {
      !localStorage.getItem('isEducationSite') && localStorage.setItem('isEducationSite', true);
      !localStorage.getItem('isTennisSite') && localStorage.setItem('isTennisSite', true);
    }
    const currency =
      currentListing?.attributes?.publicData?.currency ||
      currentListing?.attributes?.price?.currency ||
      config.currency;

    const listingSlug = rawParams.slug || createSlug(currentListing.attributes.title || '');
    const params = { slug: listingSlug, ...rawParams };
    const listingType = isDraftVariant
      ? LISTING_PAGE_PARAM_TYPE_DRAFT
      : LISTING_PAGE_PARAM_TYPE_EDIT;
    const listingTab = isDraftVariant ? 'photos' : 'description';
    const isApproved =
      currentListing.id && currentListing.attributes.state !== LISTING_STATE_PENDING_APPROVAL;

    const pendingIsApproved = isPendingApprovalVariant && isApproved;

    // const hasListingState = !!currentListing.attributes.state;
    // const isClosed = hasListingState && currentListing.attributes.state === LISTING_STATE_CLOSED;

    // If a /pending-approval URL is shared, the UI requires
    // authentication and attempts to fetch the listing from own
    // listings. This will fail with 403 Forbidden if the author is
    // another user. We use this information to try to fetch the
    // public listing.
    const pendingOtherUsersListing =
      (isPendingApprovalVariant || isDraftVariant) &&
      showListingError &&
      showListingError.status === 403;
    const shouldShowPublicListingPage = pendingIsApproved || pendingOtherUsersListing;

    if (shouldShowPublicListingPage) {
      return <NamedRedirect name="ListingPage" params={params} search={location.search} />;
    }
    const {
      description = '',
      price = null,
      title = '',
      publicData,
      metadata,
    } = currentListing.attributes;
    const { brand, size, category, level, duration, stock } = publicData;
    const richTitle = (
      <span>
        {richText(title, {
          longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
          longWordClass: css.longWord,
        })}
      </span>
    );

    const onCloseEnquiryModal = () => {
      this.setState({ enquiryModalOpen: false });
      localStorage.removeItem('enquiry_message');
      localStorage.removeItem('showloginMessage');
    };

    const bookingTitle = (
      <FormattedMessage id={bookingTitleId(listingVariant)} values={{ title: richTitle }} />
    );

    const authorAvailable = currentListing && currentListing.author;
    const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
    const isOwnListing =
      userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;
    const showContactUser = authorAvailable && (!currentUser || (currentUser && !isOwnListing));

    const topbar = (
      <TopbarContainer
        menuList={listingVariant === LISTING_TYPES.COMPANY ? companyMenuList : bookingMenuList}
        isCompanyPage={true}
        isMainWrapperBlur={this.state.isMainWrapperBlur}
        onMainWrapperBlur={this.mainWrapperBlurHandler}
        setIsOpenShareForm={value => this.setState({ isOpenShareForm: value })}
        isEducationSite={isEducationSite}
        // isTennisSite={isTennisSite}
        showOnMob={!isOwnListing}
      />
    );

    const disbleListing = !!currentListing?.attributes?.metadata?.hidelisting;

    const isToHideListing = disbleListing ? (disbleListing && isOwnListing ? false : true) : false;

    if (isToHideListing || (showListingError && showListingError.status === 404)) {
      // 404 listing not found

      return <NotFoundPage isEducationSite={isEducationSite} />;
    } else if (showListingError) {
      // Other error in fetching listing

      const errorTitle = intl.formatMessage({
        id: 'ListingPage.errorLoadingListingTitle',
      });

      return (
        <Page title={errorTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <p className={css.errorText}>
                <FormattedMessage id="ListingPage.errorLoadingListingMessage" />
              </p>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    } else if (!currentListing.id) {
      // Still loading the listing

      const loadingTitle = intl.formatMessage({
        id: 'ListingPage.loadingListingTitle',
      });

      return (
        <Page title={loadingTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <p className={css.loadingText}>
                <FormattedMessage id="ListingPage.loadingListingMessage" />
              </p>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    }

    const currentAuthor = authorAvailable ? currentListing.author : null;
    const ensuredAuthor = ensureUser(currentAuthor);

    const otherImages = currentListing.images.slice(1, currentListing.images.length);

    // When user is banned or deleted the listing is also deleted.
    // Because listing can be never showed with banned or deleted user we don't have to provide
    // banned or deleted display names for the function
    const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');
    // const authorDateOfJoin = moment(ensuredAuthor.attributes.createdAt).format('YYYY');
    const currentPrice = ensurePrice(price);
    const { formattedPrice, priceTitle } = priceData(currentPrice, intl);
    const credits =
      currentUser &&
      currentUser.attributes &&
      currentUser.attributes.profile.privateData?.sessions &&
      currentUser.attributes.profile.privateData?.sessions[currentListing.id.uuid]
        ? currentUser.attributes.profile.privateData?.sessions[currentListing.id.uuid]
        : 0;
    const hasCredits = credits && credits > 0;

    const handleBookingSubmit = values => {
      const isCurrentlyClosed = currentListing.attributes.state === LISTING_STATE_CLOSED;
      // const isMembership =
      //   currentListing?.attributes?.publicData?.listing_type === LISTING_TYPES.MEMBERSHIP;
      // if (listing_mode === 'online' && googleAccessToken === null) {
      //   this.props.history.push(
      //     pathByRouteName('GoogleCalendarSettingsPage', routeConfiguration())
      //   );
      // } else
      if ((isOwnListing || isCurrentlyClosed) && typeof window !== 'undefined') {
        window.scrollTo(0, 0);
      } else {
        this.handleSubmit(values);
      }
    };

    const sportsOptions = sportsConfig
      ? sportsConfig.filter(option => publicData.sports.includes(option.key)).map(s => s.label)
      : null;

    const activity_levels =
      activityLevelConfig && publicData.activity_levels && publicData.activity_levels[0]
        ? activityLevelConfig
            .filter(al => publicData.activity_levels.includes(al.key))
            .map(al => al.label)
            .join(', ')
        : '';
    const treatment_room =
      treatmentRoomOptionsConfig && publicData.treatment_room
        ? treatmentRoomOptionsConfig.find(al => publicData.treatment_room.includes(al.key)).label
        : '';
    // const delivery_options =
    //   deliveryOptionsConfig && publicData.delivery_options
    //     ? deliveryOptionsConfig.find(al => publicData.deliveryOptions.includes(al.key)).label
    //     : '';
    const isSpecialListing = specialListings.find(item => item.id === listingId?.uuid);
    const facebookImages = listingImages(currentListing, 'facebook');
    const twitterImages = listingImages(currentListing, 'twitter');
    const schemaImages = JSON.stringify(facebookImages.map(img => img.url));
    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage(
      { id: 'ListingPage.schemaTitle' },
      { title, price: formattedPrice, siteTitle }
    );
    const metaTitle = !!isSpecialListing?.title
      ? intl.formatMessage({ id: isSpecialListing?.title })
      : schemaTitle;

    const schemaDescription = !!isSpecialListing?.description
      ? intl.formatMessage({ id: isSpecialListing?.description })
      : description;

    const hostLink = (
      <NamedLink
        className={css.authorNameLink}
        name="ListingPage"
        params={params}
        to={{ hash: '#host' }}
      >
        {authorDisplayName}
      </NamedLink>
    );
    const { packages } = currentListing.attributes.publicData;
    const locationSearch = new URLSearchParams(location.search);
    const sessions_bought = locationSearch.get('sessions_bought');
    const {
      creditsRequestSuccessMessage,
      creditsRequestFailMessage,
      creditRequestInProgress,
    } = this.state;
    const seats = getSeatsFromMonthlyTimeSlots(monthlyTimeSlots);

    const hostLanguageArray =
      publicData.hosted_in && Array.isArray(publicData.hosted_in)
        ? publicData.hosted_in.map(lang => {
            return lang.split('_')[1];
          })
        : publicData.hosted_in;

    let hostLanguages =
      hostLanguageArray && Array.isArray(hostLanguageArray) ? '' : hostLanguageArray;
    hostLanguageArray &&
      Array.isArray(hostLanguageArray) &&
      hostLanguageArray.forEach((lang, index) => {
        hostLanguages += lang;
        if (hostLanguageArray.length !== 1 && hostLanguageArray.length - 2 === index) {
          hostLanguages += ' and ';
        } else if (hostLanguageArray.length !== 1 && hostLanguageArray.length - 1 !== index) {
          hostLanguages += ', ';
        }
      });

    const authorPublicData = ensuredAuthor.attributes.profile.publicData || {};
    const contactNumber = authorPublicData.phoneNumber || '';
    // const number = contactNumber && contactNumber.replace(/[^\w\s]/gi, '').replace(/ /g, '');
    // const url = `${URL}/${number}`;
    const url = typeof window !== 'undefined' && window.location.href;

    const listingDisplayImages = currentListing.images;

    const isNightly = unitType === LINE_ITEM_NIGHT;
    const isDaily = unitType === LINE_ITEM_DAY;

    const unitTranslationKey = isNightly
      ? 'BookingPanel.perNight'
      : isDaily
      ? 'BookingPanel.perDay'
      : 'BookingPanel.perUnit';

    const hasListingState = !!currentListing.attributes.state;
    const isClosed = hasListingState && currentListing.attributes.state === LISTING_STATE_CLOSED;
    const showBookingTimeForm = hasListingState && !isClosed;
    const listingTypeLabel = publicData?.listing_type;
    const listingParamId = params?.id;
    const width = this.props.viewport.width;
    const isBook = width < 1034 && !!parse(location.search).book;

    return (
      <Page
        title={metaTitle}
        scrollingDisabled={this.state.isMainWrapperBlur ? true : scrollingDisabled}
        author={authorDisplayName}
        contentType="website"
        description={schemaDescription}
        facebookImages={facebookImages}
        twitterImages={twitterImages}
        stopScrolling={true}
        // isBottomMenuRequire={listingVariant === LISTING_TYPES.COMPANY ? true : false}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'ItemPage',
          description: description,
          name: schemaTitle,
          image: schemaImages,
        }}
        schemaScript={isSpecialListing?.script}
      >
        {listingVariant === LISTING_TYPES.COMPANY ||
        listingVariant === LISTING_TYPES.FREELANCER ||
        listingVariant === LISTING_TYPES.PLAYER_PROFILE ||
        listingVariant === LISTING_TYPES.ACADEMY ||
        listingVariant === LISTING_TYPES.REMOTE_TUTOR ||
        listingVariant === LISTING_TYPES.TUTOR ? (
          <CompanyPageContent
            intl={intl}
            topbar={topbar}
            isOwnListing={isOwnListing}
            currentListing={currentListing}
            currentUser={currentUser}
            listingId={listingId}
            listingSlug={listingSlug}
            listingType={listingType}
            listingTab={listingTab}
            authorDisplayName={authorDisplayName}
            onContactUser={this.onContactUser}
            otherAuthorsListings={otherAuthorsListings}
            otherAuthorsFacilitiesListings={otherAuthorsFacilitiesListings}
            onFetchAuthorFacilities={onFetchAuthorFacilities}
            authorFacilities={authorFacilities}
            authorFacilitiesIsLoading={authorFacilitiesIsLoading}
            onFetchTimeSlots={timeSlotsRequestFacility}
            facilityTimeslots={facilityTimeslots}
            bookedTimeslots={bookedTimeslots}
            authorClasses={authorClasses}
            authorClassesIsLoading={authorClassesIsLoading}
            allListingReviews={listingAllReviews}
            viewport={viewport}
            isMainWrapperBlur={this.state.isMainWrapperBlur}
            addBookingData={addBookingData}
            disbleListing={disbleListing}
            onMakeListingLive={this.onMakeListingLive}
            isEducationSite={isEducationSite}
            // isTennisSite={isTennisSite}
            routeName={routeName}
          />
        ) : listingVariant === LISTING_TYPES.ENQUIRY ? (
          <EnquiryPageContent
            topbar={topbar}
            isOwnListing={isOwnListing}
            currentListing={currentListing}
            listingId={listingId}
            listingSlug={listingSlug}
            listingType={listingType}
            listingTab={listingTab}
            richTitle={richTitle}
            onManageDisableScrolling={() => {}}
            isAuthenticated={isAuthenticated}
            onSubmitEnquiry={this.onSubmitEnquiry}
            title={title}
            sendEnquiryInProgress={sendEnquiryInProgress}
            sendEnquiryError={sendEnquiryError}
            enquiryModalOpen={this.state.enquiryModalOpen}
            setEnquiryModalOpen={value => this.setState({ enquiryModalOpen: value })}
            showContactUser={showContactUser}
            onContactUser={this.onContactUser}
            ensuredAuthor={ensuredAuthor}
            isEducationSite={isEducationSite}
            // isTennisSite={isTennisSite}
          />
        ) : (
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar
              rootClassName={classNames(css.topbarRoot, isBook && css.topbarRootHide)}
            >
              {topbar}
            </LayoutWrapperTopbar>
            <LayoutWrapperMain>
              {this.state.isMainWrapperBlur ? <div className={css.overlayBody} /> : null}
              {true && !isOwnListing && (
                <TopFloatingMessage
                  FormattedButtonText="CategoriesPage.quoteButtonText"
                  titleMessage={`Contact This Vendor Now`}
                  lableText="Tell them what you need"
                  className={css.floatingTopBar}
                  inputText="We will reply within hours"
                  onClick={this.onContactUser}
                  isEducationSite={false}
                  isTennisSite={false}
                />
              )}
              <div className={css.actionBarWrapperContainer}>
                <div className={css.actionBarWrapper}>
                  <ActionBarMaybe
                    isOwnListing={isOwnListing}
                    listing={currentListing}
                    routeName={routeName}
                    editParams={{
                      id: listingId.uuid,
                      slug: listingSlug,
                      type: listingType,
                      tab: listingVariant === LISTING_TYPES.PRODUCT ? 'details' : listingTab,
                    }}
                  />
                  {disbleListing && (
                    <div className={css.goLiveWrapper}>
                      <button
                        className={css.goLiveBtn}
                        onClick={() => {
                          this.onMakeListingLive({
                            id: currentListing.id.uuid,
                            hidelisting: false,
                          });
                        }}
                      >
                        <FormattedMessage id={'ListingPage.makeLive'} />
                      </button>
                    </div>
                  )}
                </div>
                <div>
                  {sessions_bought === 'true' ? (
                    <GenericMessage
                      message={<FormattedMessage id="ListingPage.sessionsBoughtSuccessMessage" />}
                      show={true}
                    />
                  ) : sessions_bought === 'false' ? (
                    <GenericMessage
                      message={<FormattedMessage id="ListingPage.sessionsBoughtFailureMessage" />}
                      show={true}
                    />
                  ) : null}
                </div>
                <GenericMessage
                  message={<FormattedMessage id="ListingPage.creditsRequestSuccessMessage" />}
                  show={creditsRequestSuccessMessage}
                  onClose={this.onClosecreditsRequestSuccessMessage}
                />
                <GenericMessage
                  message={<FormattedMessage id="ListingPage.creditsRequestFailMessage" />}
                  show={creditsRequestFailMessage}
                  onClose={this.onCloseCreditsRequestFailMessage}
                />
                <GenericMessage
                  message={<FormattedMessage id="ListingPage.cancelSubscriptionSuccessMessage" />}
                  show={this.state.cancelSubscriptionSucessMessage}
                  onClose={() => this.setState({ cancelSubscriptionSucessMessage: false })}
                />
                <GenericMessage
                  message={<FormattedMessage id="ListingPage.cancelSubscriptionErrorMessage" />}
                  show={this.state.cancelSubscriptionErrorMessage}
                  onClose={() => {
                    this.setState({ cancelSubscriptionErrorMessage: false });
                    window?.location && window.location.reload(false);
                  }}
                />

                <SectionHeadImagesSlider
                  rootClassName={css.imageSlider}
                  images={listingDisplayImages}
                >
                  {listingDisplayImages.map(image => {
                    return (
                      <div key={image.id.uuid} className={css.singleImageContainer}>
                        <ResponsiveImage
                          className={css.image}
                          src={image}
                          image={image}
                          variants={[
                            'scaled-medium',
                            'scaled-large',
                            'scaled-small',
                            'scaled-small2x',
                          ]}
                          alt={'title'}
                        />
                      </div>
                    );
                  })}
                </SectionHeadImagesSlider>
                <div className={css.breadCrumbWrapper}>
                  <NamedLink name="HomePage">Home</NamedLink>
                  <span className={css.breadcrumbArrow}>&gt;</span>
                  <NamedLink
                    className={css.currentListingTypeName}
                    name="ListingPage"
                    params={{ slug: listingSlug, id: listingParamId }}
                  >
                    {listingTypeLabel}
                  </NamedLink>
                </div>
                <div className={css.contentContainer}>
                  <div className={css.mainContent}>
                    {packages && packages.length !== 0 && (
                      <div id="packages" className={cns(css.sectionWrapper, css.packagesDisplay)}>
                        <h2>
                          <FormattedMessage id={'ListingPage.packagesSectionHeading'} />
                        </h2>
                        <Packages
                          usage={USAGE.listingPage}
                          intl={intl}
                          listing={currentListing}
                          packageAttr={this.state.packageAttr}
                          packages={packages}
                          handleBuy={this.handlePackagesModalOpen}
                          showInline={true}
                          creditRequestInProgress={creditRequestInProgress}
                          currency={currency}
                        />
                      </div>
                    )}
                    <div id="about" className={css.sectionHeadingWrapper}>
                      <SectionHeading
                        priceTitle={priceTitle}
                        formattedPrice={formattedPrice}
                        richTitle={title}
                        hostLink={hostLink}
                        showContactUser={showContactUser}
                        onContactUser={this.onContactUser}
                      />

                      <div
                        className={css.iconWrap}
                        onClick={() => {
                          this.setState({
                            isOpenShareForm: true,
                          });
                        }}
                      >
                        <Share />
                      </div>

                      <div className={css.detailSection}>
                        <div className={css.authorDetailsLargeImageRelative}>
                          <AvatarLarge
                            className={css.avatarLargeImage}
                            user={currentAuthor}
                            disableProfileLink
                          />
                        </div>
                        <div className={css.detailSectionInfoWrapper}>
                          <div className={css.detailSectionWrapper}>
                            <div className={css.detailSectionInfo}>
                              {authorsCompanyListingDetails.id ? (
                                <NamedLink
                                  name="ListingPage"
                                  params={{
                                    id: authorsCompanyListingDetails.id,
                                    slug: createSlug(authorsCompanyListingDetails.title),
                                  }}
                                  className={css.detailSectionAuthorNameLink}
                                >
                                  <h2>
                                    <FormattedMessage
                                      id="ListingPage.onlineExperienceHostedByMessage"
                                      values={{ authorDisplayName }}
                                    />
                                  </h2>
                                </NamedLink>
                              ) : (
                                <h2>
                                  <FormattedMessage
                                    id="ListingPage.onlineExperienceHostedByMessage"
                                    values={{ authorDisplayName }}
                                  />
                                </h2>
                              )}
                              {hostLanguages && (
                                <span className={css.detailInfo}>
                                  {publicData.duration} · Hosted in {hostLanguages}
                                </span>
                              )}
                            </div>
                            {/* <div className={css.whatsappSection}>
                              {metadata && (
                                <SectionLevel metadata={metadata} bigIcon={true} size={100} />
                              )}
                            </div> */}
                          </div>
                          <div className={css.reviewWrapper}>
                            <div className={css.reviewSpan}>
                              <IconCheckedShield rootClassName={css.iconCheckedShield} />
                              <IconStar colorName={'#FFB534'} rootClassName={css.reviewStar} />
                              <span>{reviews.length}</span>
                              <FormattedMessage id="ListingPage.reviews" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={css.mainWraper}>
                        {/* description section */}
                        <div className={css.detailDescription}>{description}</div>

                        {/* reviews author etc */}
                        <div className={css.authorReviewInfo}>
                          <div className={css.sectionWrapper}>
                            <div className={css.publicData}>
                              <div className={css.feature}>
                                {sportsOptions
                                  ? sportsOptions.map((s, i) => (
                                      <span key={i} className={css.feature_item}>
                                        {s}
                                      </span>
                                    ))
                                  : null}
                              </div>
                              <ul className={css.property}>
                                {stock && (
                                  <li className={css.propertyItem}>
                                    <div>
                                      {/* <MdPeople fontSize="44px" className={css.itemIcon} /> */}
                                      {<img src={MyListing} className={css.listingIcons} />}
                                    </div>
                                    <div className={css.listPublicInfo}>
                                      <span className={css.propertyTitle}>Stock</span>
                                      <span className={css.desc}>{stock}</span>
                                    </div>
                                  </li>
                                )}
                                {publicData.duration ? (
                                  <li className={css.propertyItem}>
                                    <div>
                                      {/* <MdTime fontSize="44px" className={css.itemIcon} /> */}
                                      <svg
                                        width="26"
                                        height="26"
                                        viewBox="0 0 26 26"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M13 6.5V13H19.5"
                                          stroke="#0058FA"
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M17.4838 3.13516C16.1175 2.51321 14.5994 2.16675 13.0003 2.16675C7.01724 2.16675 2.16699 7.01699 2.16699 13.0001C2.16699 18.9831 7.01724 23.8334 13.0003 23.8334C18.9834 23.8334 23.8337 18.9831 23.8337 13.0001C23.8337 10.3881 22.9093 7.99209 21.3698 6.12123"
                                          stroke="#0058FA"
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>
                                    </div>
                                    <div className={css.listPublicInfo}>
                                      <span className={css.propertyTitle}>Duration</span>
                                      <span className={css.desc}>{duration}</span>
                                    </div>
                                  </li>
                                ) : null}

                                {seats ? (
                                  <li className={css.propertyItem}>
                                    <div>
                                      {/* <MdPeople fontSize="44px" className={css.itemIcon} /> */}
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="44"
                                        height="45"
                                        viewBox="0 0 44 45"
                                        style={{ fill: '#fff' }}
                                        fill="none"
                                      >
                                        <path
                                          d="M33 9.63184H11C8.97494 9.63184 7.33331 11.2735 7.33331 13.2985V35.2985C7.33331 37.3235 8.97494 38.9652 11 38.9652H33C35.025 38.9652 36.6666 37.3235 36.6666 35.2985V13.2985C36.6666 11.2735 35.025 9.63184 33 9.63184Z"
                                          stroke="#0058FA"
                                          strokeWidth="2.75"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M29.3333 5.96484V13.2982"
                                          stroke="#0058FA"
                                          strokeWidth="2.75"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M14.6667 5.96484V13.2982"
                                          stroke="#0058FA"
                                          strokeWidth="2.75"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M7.33331 20.6318H36.6666"
                                          stroke="#0058FA"
                                          strokeWidth="2.75"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M18.3334 27.9648H14.6667V31.6315H18.3334V27.9648Z"
                                          stroke="#0058FA"
                                          strokeWidth="2.75"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>
                                    </div>
                                    <div className={css.listPublicInfo}>
                                      <span className={css.propertyTitle}>Booking slots</span>
                                      <span className={css.desc}>{seats}</span>
                                    </div>
                                  </li>
                                ) : null}
                                {category && (
                                  <li className={css.propertyItem}>
                                    <div>
                                      {/* <MdPeople fontSize="44px" className={css.itemIcon} /> */}
                                      {<img src={LeftbarMenuicon} className={css.listingIcons} />}
                                    </div>
                                    <div className={css.listPublicInfo}>
                                      <span className={css.propertyTitle}>Category</span>
                                      <span className={css.desc}>
                                        {toTitleCase(category.replaceAll('_', ' '))}
                                      </span>
                                    </div>
                                  </li>
                                )}
                                {brand && (
                                  <li className={css.propertyItem}>
                                    <div>
                                      {/* <MdPeople fontSize="44px" className={css.itemIcon} /> */}
                                      {
                                        <img
                                          src={CompanySelectionIcon}
                                          className={css.listingIcons}
                                        />
                                      }
                                    </div>
                                    <div className={css.listPublicInfo}>
                                      <span className={css.propertyTitle}>Brands</span>
                                      <span className={css.desc}>{brand}</span>
                                    </div>
                                  </li>
                                )}
                                {size && (
                                  <li className={css.propertyItem}>
                                    <div>
                                      {/* <MdPeople fontSize="44px" className={css.itemIcon} /> */}
                                      {<img src={packagesvg} className={css.listingIcons} />}
                                    </div>
                                    <div className={css.listPublicInfo}>
                                      <span className={css.propertyTitle}>Size</span>
                                      <span className={css.desc}>{size}</span>
                                    </div>
                                  </li>
                                )}
                                {level && (
                                  <li className={css.propertyItem}>
                                    <div>
                                      {/* <MdPeople fontSize="44px" className={css.itemIcon} /> */}
                                      <img src={levelSvg} className={css.listingIcons} />
                                    </div>
                                    <div className={css.listPublicInfo}>
                                      <span className={css.propertyTitle}>Levels</span>
                                      <span className={css.desc}>{level}</span>
                                    </div>
                                  </li>
                                )}

                                {publicData.activity_levels ? (
                                  <li className={css.propertyItem}>
                                    <div>
                                      {/* <MdPricetag fontSize="44px" className={css.itemIcon} /> */}
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="44"
                                        height="44"
                                        viewBox="0 0 44 44"
                                        style={{ fill: '#fff' }}
                                        fill="none"
                                      >
                                        <path
                                          d="M32.6333 36.3307L28.6513 38.417C28.5332 38.4786 28.4002 38.506 28.2674 38.4963C28.1345 38.4867 28.0069 38.4402 27.8989 38.3623C27.7909 38.2843 27.7067 38.1778 27.6557 38.0548C27.6047 37.9317 27.5888 37.7969 27.61 37.6654L28.3708 33.2452L25.1497 30.1157C25.0535 30.0228 24.9855 29.9047 24.9532 29.7749C24.921 29.6451 24.9259 29.5089 24.9674 29.3817C25.0089 29.2546 25.0853 29.1417 25.1878 29.0559C25.2904 28.9701 25.415 28.9149 25.5475 28.8965L29.9988 28.2512L31.9898 24.2307C32.0494 24.111 32.1412 24.0102 32.2548 23.9398C32.3685 23.8693 32.4996 23.832 32.6333 23.832C32.7671 23.832 32.8982 23.8693 33.0118 23.9398C33.1255 24.0102 33.2173 24.111 33.2768 24.2307L35.2678 28.2512L39.7192 28.8965C39.8513 28.9155 39.9754 28.9711 40.0775 29.057C40.1796 29.1429 40.2557 29.2556 40.2971 29.3825C40.3385 29.5093 40.3436 29.6452 40.3117 29.7748C40.2799 29.9044 40.2125 30.0225 40.117 30.1157L36.8958 33.2452L37.6548 37.6635C37.6776 37.7953 37.663 37.9308 37.6126 38.0547C37.5623 38.1785 37.4782 38.2858 37.3699 38.3643C37.2617 38.4427 37.1336 38.4893 37.0002 38.4986C36.8668 38.5079 36.7335 38.4797 36.6153 38.417L32.6333 36.3307V36.3307Z"
                                          stroke="#0058FA"
                                          strokeWidth="2.75"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M11.3667 36.3307L7.38468 38.417C7.26651 38.4786 7.13357 38.506 7.00071 38.4963C6.86784 38.4867 6.74028 38.4402 6.63226 38.3623C6.52425 38.2843 6.44003 38.1778 6.38901 38.0548C6.33799 37.9317 6.32218 37.7969 6.34335 37.6654L7.10418 33.2452L3.88301 30.1157C3.78687 30.0228 3.71879 29.9047 3.68656 29.7749C3.65432 29.6451 3.65923 29.5089 3.70071 29.3817C3.7422 29.2546 3.81859 29.1417 3.92117 29.0559C4.02375 28.9701 4.14838 28.9149 4.28085 28.8965L8.73218 28.2512L10.7232 24.2307C10.7827 24.111 10.8745 24.0102 10.9882 23.9398C11.1019 23.8693 11.2329 23.832 11.3667 23.832C11.5004 23.832 11.6315 23.8693 11.7452 23.9398C11.8589 24.0102 11.9506 24.111 12.0102 24.2307L14.0012 28.2512L18.4525 28.8965C18.5846 28.9155 18.7087 28.9711 18.8108 29.057C18.913 29.1429 18.989 29.2556 19.0304 29.3825C19.0718 29.5093 19.0769 29.6452 19.0451 29.7748C19.0132 29.9044 18.9458 30.0225 18.8503 30.1157L15.6292 33.2452L16.3882 37.6635C16.411 37.7953 16.3963 37.9308 16.346 38.0547C16.2956 38.1785 16.2115 38.2858 16.1033 38.3643C15.995 38.4427 15.8669 38.4893 15.7335 38.4986C15.6001 38.5079 15.4668 38.4797 15.3487 38.417L11.3667 36.3307V36.3307Z"
                                          stroke="#0058FA"
                                          strokeWidth="2.75"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M22 17.9977L18.018 20.084C17.8998 20.1455 17.7669 20.173 17.634 20.1633C17.5011 20.1536 17.3736 20.1072 17.2656 20.0293C17.1575 19.9513 17.0733 19.8448 17.0223 19.7218C16.9713 19.5987 16.9555 19.4639 16.9766 19.3324L17.7375 14.9122L14.5163 11.7827C14.4202 11.6898 14.3521 11.5717 14.3199 11.4419C14.2876 11.3121 14.2925 11.1759 14.334 11.0487C14.3755 10.9216 14.4519 10.8087 14.5545 10.7229C14.6571 10.6371 14.7817 10.5819 14.9141 10.5635L19.3655 9.91821L21.3565 5.89771C21.416 5.77796 21.5078 5.6772 21.6215 5.60677C21.7352 5.53634 21.8662 5.49902 22 5.49902C22.1337 5.49902 22.2648 5.53634 22.3785 5.60677C22.4922 5.6772 22.5839 5.77796 22.6435 5.89771L24.6345 9.91821L29.0858 10.5635C29.2179 10.5825 29.342 10.6381 29.4441 10.724C29.5463 10.8099 29.6223 10.9226 29.6637 11.0494C29.7051 11.1763 29.7102 11.3122 29.6784 11.4418C29.6465 11.5714 29.5791 11.6895 29.4836 11.7827L26.2625 14.9122L27.0215 19.3305C27.0443 19.4623 27.0296 19.5978 26.9793 19.7217C26.9289 19.8455 26.8448 19.9528 26.7366 20.0313C26.6283 20.1097 26.5002 20.1563 26.3668 20.1656C26.2334 20.1749 26.1001 20.1467 25.982 20.084L22 17.9977V17.9977Z"
                                          stroke="#0058FA"
                                          strokeWidth="2.75"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>
                                    </div>
                                    <div className={css.listPublicInfo}>
                                      <span className={css.propertyTitle}>Activity level</span>

                                      <span className={css.desc}>{activity_levels}</span>
                                    </div>
                                  </li>
                                ) : null}

                                {publicData.hosted_in ? (
                                  <li className={css.propertyItem}>
                                    <div>
                                      {/* <MdMic fontSize="44px" className={css.itemIcon} /> */}
                                      <svg
                                        width="26"
                                        height="26"
                                        viewBox="0 0 26 26"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M2.1665 12.9998C2.1665 18.9829 7.01675 23.8332 12.9998 23.8332C18.9829 23.8332 23.8332 18.9829 23.8332 12.9998C23.8332 7.01675 18.9829 2.1665 12.9998 2.1665C7.01675 2.1665 2.1665 7.01675 2.1665 12.9998Z"
                                          stroke="#0058FA"
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M14.0835 2.22021C14.0835 2.22021 17.3335 6.50005 17.3335 13C17.3335 19.5 14.0835 23.7799 14.0835 23.7799"
                                          stroke="#0058FA"
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M11.9165 23.7799C11.9165 23.7799 8.6665 19.5 8.6665 13C8.6665 6.50005 11.9165 2.22021 11.9165 2.22021"
                                          stroke="#0058FA"
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M3 17H19"
                                          stroke="#0058FA"
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M2.84863 9.2085H23.1511"
                                          stroke="#0058FA"
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>
                                    </div>
                                    <div className={css.listPublicInfo}>
                                      <span className={css.propertyTitle}>Hosted in</span>

                                      <span className={css.desc}>{hostLanguages}</span>
                                    </div>
                                  </li>
                                ) : null}

                                {publicData.treatment_room ? (
                                  <li className={css.propertyItem}>
                                    <div>
                                      <MdMedkit fontSize="44px" className={css.itemIcon} />
                                    </div>
                                    <div className={css.listPublicInfo}>
                                      <span className={css.propertyTitle}>Treatment room</span>

                                      <span className={css.desc}>{treatment_room}</span>
                                    </div>
                                  </li>
                                ) : null}

                                {publicData.time_zone ? (
                                  <li className={css.propertyItem}>
                                    <div>
                                      <MdGlobe fontSize="44px" className={css.itemIcon} />
                                    </div>
                                    <div className={css.listPublicInfo}>
                                      <span className={css.propertyTitle}>Time zone</span>

                                      <span className={css.desc}>{publicData.time_zone}</span>
                                    </div>
                                  </li>
                                ) : null}

                                {publicData.video_chat_available ? (
                                  <li className={css.propertyItem}>
                                    <div>
                                      <MdVideocam fontSize="44px" className={css.itemIcon} />
                                    </div>
                                    <div className={css.listPublicInfo}>
                                      <span className={css.propertyTitle}>
                                        Video chat available?
                                      </span>

                                      <span className={css.desc}>
                                        {publicData.video_chat_available === 'true' ? 'Yes' : 'No'}
                                      </span>
                                    </div>
                                  </li>
                                ) : null}

                                {publicData.new_product ? (
                                  <li className={css.propertyItem}>
                                    <div>
                                      <LogoDropbox fontSize="44px" className={css.itemIcon} />
                                    </div>
                                    <div className={css.listPublicInfo}>
                                      <span className={css.propertyTitle}>New product?</span>

                                      <span className={css.desc}>
                                        {publicData.new_product[0] === 'true' ? 'Yes' : 'No'}
                                      </span>
                                    </div>
                                  </li>
                                ) : null}

                                {publicData.warranty ? (
                                  <li className={css.propertyItem}>
                                    <div>
                                      <MdCheckmark fontSize="44px" className={css.itemIcon} />
                                    </div>
                                    <div className={css.listPublicInfo}>
                                      <span className={css.propertyTitle}>Warranty</span>

                                      <span className={css.desc}>
                                        {publicData.warranty === 'true' ? 'Yes' : 'No'}
                                      </span>
                                    </div>
                                  </li>
                                ) : null}

                                {publicData.transferable ? (
                                  <li className={css.propertyItem}>
                                    <div>
                                      <MdSend fontSize="44px" className={css.itemIcon} />
                                    </div>
                                    <div className={css.listPublicInfo}>
                                      <span className={css.propertyTitle}>Transferable? </span>

                                      <span className={css.desc}>
                                        {publicData.transferable === 'true' ? 'Yes' : 'No'}
                                      </span>
                                    </div>
                                  </li>
                                ) : null}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={css.sectionWrapper}>
                        {/* {showContactUser ? (
                          <div className={css.contactWrapper}>
                            <Button className={css.contactLink} onClick={this.onContactUser}>
                              <FormattedMessage id="ListingPage.contactUser" />
                            </Button>
                          </div>
                        ) : null} */}
                      </div>
                    </div>
                    {/* {packages && packages.length !== 0 && (
                      <div id="packages" className={cns(css.sectionWrapper, css.packagesDisplay)}>
                        <h2>
                          <FormattedMessage id={'ListingPage.packagesSectionHeading'} />
                        </h2>
                        <Packages
                          usage={USAGE.listingPage}
                          intl={intl}
                          sellerId={get(currentAuthor, 'id')}
                          listing={currentListing}
                          packageAttr={this.state.packageAttr}
                          packages={packages}
                          handleBuy={this.handlePackagesModalOpen}
                          showInline={true}
                          creditRequestInProgress={creditRequestInProgress}
                          currency={currency}
                        />
                      </div>
                    )} */}
                    {/* <div className={css.sectionGallery}>
                      <div className={css.galleryWrapper}>
                        {otherImages &&
                          otherImages
                            .slice(
                              0,
                              this.state.showAllImages
                                ? otherImages.length
                                : this.getNumberOfImageDisplay()
                            )
                            .map((image, index) => {
                              return (
                                <div key={index} className={css.gallerItems}>
                                  <ResponsiveImage
                                    className={css.itemImg}
                                    src={image}
                                    image={image}
                                    variants={['scaled-small', 'scaled-small2x']}
                                    alt={'title'}
                                  />
                                </div>
                              );
                            })}
                      </div>
                      {!!(otherImages.length > this.getNumberOfImageDisplay()) && (
                        <div className={css.galleryButton}>
                          <Button
                            onClick={() =>
                              this.setState({ showAllImages: !this.state.showAllImages })
                            }
                          >
                            View More
                          </Button>
                        </div>
                      )}
                    </div> */}
                    {/* {publicData.expectations && !!publicData.expectations.length && (
                      <div className={css.sectionWhatYouCanExpect}>
                        <SectionWhatYouCanExpect data={publicData.expectations} />
                      </div>
                    )} */}
                    {/* <div className={cns(css.sectionWrapper, css.cancelPolicySection)}>
                      <div className={css.cancelPolicy}>
                        <FormattedMessage
                          id={'ListingPage.cancelPolicy'}
                          values={{
                            bold: header => <h2>{header}</h2>,
                            normal: header => <p>{header}</p>,
                          }}
                        />
                      </div>
                    </div> */}
                    {/* {!isEducationSite && listingVariant === 'facility' && currentAuthor && (
                      <div id="classes" className={cns(css.sectionWrapper, css.facilityDisplay)}>
                        {!!authorFacilities?.length && (
                          <h2>
                            <FormattedMessage id={'ListingPage.facilitySectionHeading'} />
                          </h2>
                        )}
                        <div
                          className={classNames(css.facilityContainer, {
                            [css.facilityTop]: !authorFacilities?.length,
                          })}
                        >
                          <SectionFacilities
                            listing={currentListing}
                            onFetchAuthorFacilities={onFetchAuthorFacilities}
                            authorFacilities={authorFacilities}
                            authorFacilitiesIsLoading={authorFacilitiesIsLoading}
                            onFetchTimeSlots={timeSlotsRequestFacility}
                            facilityTimeslots={facilityTimeslots}
                            bookedTimeslots={bookedTimeslots}
                            intl={intl}
                            addBookingData={addBookingData}
                            isTimeSlotesLoading={isTimeSlotesLoading}
                            location={location}
                            isClosed={isClosed}
                            isOwnListing={isOwnListing}
                            history={history}
                            isMobileLayout={isMobileLayout}
                            setShowFacility={value => this.setState({ showFacility: value })}
                          />
                        </div>
                      </div>
                    )} */}

                    {/* {packages && packages.length !== 0 && (
                      <div id="packages" className={cns(css.sectionWrapper, css.packagesDisplay)}>
                        <h2>
                          <FormattedMessage id={'ListingPage.packagesSectionHeading'} />
                        </h2>
                        <Packages
                          usage={USAGE.listingPage}
                          intl={intl}
                          listing={currentListing}
                          packageAttr={this.state.packageAttr}
                          packages={packages}
                          handleBuy={this.handlePackagesModalOpen}
                          showInline={true}
                          creditRequestInProgress={creditRequestInProgress}
                          currency={currency}
                        />
                      </div>
                    )}

                    {publicData.certificates && !!publicData.certificates.length && (
                      <div id="accolades" className={css.sectionWrapper}>
                        <SectionCertification data={publicData.certificates} />
                      </div>
                    )} */}

                    {/* booking start from here */}
                    {isProduct && (
                      <div className={css.sectionWrapper}>
                        <SectionMapMaybe
                          geolocation={currentListing.attributes.geolocation}
                          publicData={publicData}
                          listingId={currentListing.id}
                        />
                      </div>
                    )}
                  </div>
                  <div className={css.bookingPanelContainer}>
                    <BookingPanel
                      className={css.bookingPanel}
                      listing={currentListing}
                      currentUser={currentUser}
                      isOwnListing={isOwnListing}
                      unitType={unitType}
                      onSubmit={handleBookingSubmit}
                      onCancelSubscription={this.onCancelSubscription}
                      addSubscription={this.addMembershipSubscription}
                      title={bookingTitle}
                      authorDisplayName={authorDisplayName}
                      onManageDisableScrolling={() => {}} // ??
                      monthlyTimeSlots={monthlyTimeSlots}
                      onFetchTimeSlots={onFetchTimeSlots}
                      viewport={viewport}
                      handlePackagesModalOpen={this.handlePackagesModalOpen}
                      isProduct={isProduct}
                      onAddingCustomPrice={this.props.onAddingCustomPrice}
                      onRemovingCustomPrice={this.props.onRemovingCustomPrice}
                      bookingData={this.props.bookingData}
                      isTimeSlotesLoading={isTimeSlotesLoading}
                      listingType={listingVariant}
                    />
                  </div>
                </div>
                {/* {packages && packages.length !== 0 && (
                  <div id="packages" className={cns(css.sectionWrapper, css.packagesDisplay)}>
                    <h2>
                      <FormattedMessage id={'ListingPage.packagesSectionHeading'} />
                    </h2>
                    <Packages
                      usage={USAGE.listingPage}
                      intl={intl}
                      listing={currentListing}
                      packageAttr={this.state.packageAttr}
                      packages={packages}
                      handleBuy={this.handlePackagesModalOpen}
                      showInline={true}
                      creditRequestInProgress={creditRequestInProgress}
                      currency={currency}
                    />
                  </div>
                )}

                {publicData.certificates && !!publicData.certificates.length && (
                  <div id="accolades" className={css.sectionWrapper}>
                    <SectionCertification data={publicData.certificates} />
                  </div>
                )}

                <div className={css.sectionGallery}>
                  <div className={css.galleryWrapper}>
                    {otherImages &&
                      otherImages
                        .slice(
                          0,
                          this.state.showAllImages
                            ? otherImages.length
                            : this.getNumberOfImageDisplay()
                        )
                        .map((image, index) => {
                          return (
                            <div key={index} className={css.gallerItems}>
                              <ResponsiveImage
                                className={css.itemImg}
                                src={image}
                                image={image}
                                variants={['scaled-small', 'scaled-small2x']}
                                alt={'title'}
                              />
                            </div>
                          );
                        })}
                  </div>
                  {!!(otherImages.length > this.getNumberOfImageDisplay()) && (
                    <div className={css.galleryButton}>
                      <Button
                        onClick={() => this.setState({ showAllImages: !this.state.showAllImages })}
                      >
                        View More
                      </Button>
                    </div>
                  )}
                </div> */}
                {!isBook && (
                  <div className={css.openBookingForm}>
                    <div className={css.priceContainer}>
                      {hasCredits ? (
                        <div className={css.packageText}>Package</div>
                      ) : isProduct ? (
                        <>
                          <div className={css.priceValue} title={priceTitle}>
                            {formattedPrice}
                          </div>
                          <div className={css.perUnit}>
                            <FormattedMessage id={'BookingPanel.perCourse'} />
                          </div>
                        </>
                      ) : (
                        <>
                          <div className={css.priceValue} title={priceTitle}>
                            {formattedPrice}
                          </div>
                          <div className={css.perUnit}>
                            <FormattedMessage id={unitTranslationKey} />
                          </div>
                        </>
                      )}
                    </div>

                    {showBookingTimeForm ? (
                      <Button
                        rootClassName={css.bookButton}
                        onClick={() =>
                          openBookModal(
                            isOwnListing,
                            isClosed,
                            history,
                            location,
                            this.props.viewport.width
                          )
                        }
                      >
                        <FormattedMessage
                          id={isProduct ? 'BookingPanel.buy' : 'BookingPanel.book'}
                        />
                      </Button>
                    ) : isClosed ? (
                      <div className={css.closedListingButton}>
                        <FormattedMessage id="BookingPanel.closedListingButtonText" />
                      </div>
                    ) : null}
                  </div>
                )}
                {currentUser && !isOwnListing && packages && packages.length > 0 ? (
                  <div>
                    <PackagesModal
                      containerClassName={css.packagesModal}
                      handlePackagesModalClose={this.handlePackagesModalClose}
                      showPackages={this.state.showPackages}
                      withPayment={this.state.withPayment}
                      paymentProofSsUrls={this.state.paymentProofSsUrls}
                      paymentProofRequiredError={this.state.paymentProofRequiredError}
                      closeButtonMessage={intl.formatMessage({ id: 'Modal.close' })}
                      intl={intl}
                      isOwnListing={isOwnListing}
                      unitType={unitType}
                      onSubmit={handleBookingSubmit}
                      title={bookingTitle}
                      currentUser={currentUser}
                      monthlyTimeSlots={monthlyTimeSlots}
                      onFetchTimeSlots={onFetchTimeSlots}
                      handlePackagesModalOpen={this.handlePackagesModalOpen}
                      isProduct={isProduct}
                      listing={currentListing}
                      sellerId={get(currentAuthor, 'id')}
                      buyerId={currentUser.id}
                      handlePackageBuy={this.handlePackageBuy}
                      packageAttr={this.state.packageAttr}
                      onManageDisableScrolling={() => {}}
                      handlePaymentTypeSelect={this.handlePaymentTypeSelect}
                      creditRequestInProgress={creditRequestInProgress}
                      onDrop={this.onDrop}
                      onRemoveFile={removeFile}
                      reviews={reviews}
                      hostLanguages={hostLanguages}
                    />
                  </div>
                ) : null}
                <div id="expectation" className={css.contentBottomContainer}>
                  {/* {publicData.expectations && !!publicData.expectations.length && (
                    <div className={css.sectionWhatYouCanExpect}>
                      <SectionWhatYouCanExpect data={publicData.expectations} />
                    </div>
                  )} */}
                  {/* {packages && packages.length !== 0 && (
                    <div id="packages" className={cns(css.sectionWrapper, css.packagesDisplay)}>
                      <h2>
                        <FormattedMessage id={'ListingPage.packagesSectionHeading'} />
                      </h2>
                      <Packages
                        usage={USAGE.listingPage}
                        intl={intl}
                        listing={currentListing}
                        packageAttr={this.state.packageAttr}
                        packages={packages}
                        handleBuy={this.handlePackagesModalOpen}
                        showInline={true}
                        creditRequestInProgress={creditRequestInProgress}
                        currency={currency}
                      />
                    </div>
                  )} */}

                  {publicData.certificates && !!publicData.certificates.length && (
                    <div id="accolades" className={css.sectionWrapper}>
                      <SectionCertification data={publicData.certificates} />
                    </div>
                  )}

                  <div className={css.sectionGallery}>
                    <div className={css.galleryWrapper}>
                      {otherImages &&
                        otherImages
                          .slice(
                            0,
                            this.state.showAllImages
                              ? otherImages.length
                              : this.getNumberOfImageDisplay()
                          )
                          .map((image, index) => {
                            return (
                              <div key={index} className={css.gallerItems}>
                                <ResponsiveImage
                                  className={css.itemImg}
                                  src={image}
                                  image={image}
                                  variants={['scaled-small', 'scaled-small2x']}
                                  alt={'title'}
                                />
                              </div>
                            );
                          })}
                    </div>
                    {!!(otherImages.length > this.getNumberOfImageDisplay()) && (
                      <div className={css.galleryButton}>
                        <Button
                          onClick={() =>
                            this.setState({ showAllImages: !this.state.showAllImages })
                          }
                        >
                          View More
                        </Button>
                      </div>
                    )}
                  </div>
                  {/* <SectionOtherAutherListing
                    authorId={authorId}
                    isEducationSite={isEducationSite}
                    otherAuthorsListings={otherAuthorsListings}
                    getNumberOfProductListings={() => {}}
                    showAllProductProp={true}
                    intl={intl}
                    viewport={viewport}
                  /> */}
                  {publicData.expectations && !!publicData.expectations.length && (
                    <div className={css.sectionWhatYouCanExpect}>
                      <SectionWhatYouCanExpect data={publicData.expectations} />
                    </div>
                  )}
                  <SectionOtherAutherListing
                    authorId={authorId}
                    isEducationSite={isEducationSite}
                    // isTennisSite={isTennisSite}
                    otherAuthorsListings={otherAuthorsListings}
                    getNumberOfProductListings={() => {}}
                    showAllProductProp={true}
                    intl={intl}
                    viewport={viewport}
                  />
                  <div className={cns(css.sectionWrapper, css.cancelPolicySection)}>
                    <div className={css.cancelPolicy}>
                      <FormattedMessage
                        id={'ListingPage.cancelPolicy'}
                        values={{
                          bold: header => <h2>{header}</h2>,
                          normal: header => <p>{header}</p>,
                        }}
                      />
                    </div>
                  </div>
                  {!!reviews.length && (
                    <div id="reviews" className={cns(css.sectionWrapper, css.greyReviewSection)}>
                      <h2>
                        <FormattedMessage id={'ListingPage.reviewSectionHeading'} />
                      </h2>
                      <SectionReviews reviews={getRemovedWithOutContentReview(reviews)} />
                      {/* <SectionLatestReviews
                          data={getRemovedWithOutContentReview(reviews)}
                          className={css.mainReviewContainer}
                          title={`Reviews`}
                        /> */}
                    </div>
                  )}
                </div>
              </div>

              {/* 
              // message Popup
              {!isMobileLayout && (contactNumber || showContactUser) && (
                <div className={css.mobileChatSection}>
                  {!this.state.openChatBox ? (
                    <div className={css.mobileChatWrapper} onClick={this.onClickChatIconHandler}>
                      <div className={css.activeUser}>
                        <Avatar className={css.avatar} user={ensuredAuthor} disableProfileLink />
                        <div className={css.active}></div>
                      </div>
                      <span>
                        <FormattedMessage id={'ListingPage.chat'} />
                      </span>
                    </div>
                  ) : (
                    <div className={css.whatsappInboxSection}>
                      {contactNumber && !isEducationSite && (
                        <a href={url}>
                          <div className={css.iconWrapper}>
                            <div className={css.iconImageWrapper}>
                              <img src={'/static/images/Icons/WhatsappIcon.png'} alt="" />
                            </div>
                            <span>
                              <FormattedMessage id={'ListingPage.whatsapp'} />
                            </span>
                          </div>
                        </a>
                      )}
                      {showContactUser && (
                        <div className={css.iconWrapper} onClick={this.onContactUser}>
                          <div className={css.iconImageWrapper}>
                            <svg
                              width="250"
                              height="250"
                              viewBox="0 0 225 256"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className={css.iconImageWrapper}
                            >
                              <path
                                d="M0.0390625 255.151H163.209L62.0024 153.708C43.3523 163.278 27.7006 177.811 16.7686 195.708C5.83662 213.606 0.0478889 234.174 0.0390625 255.151Z"
                                fill="#0058FA"
                              />
                              <path
                                d="M227.897 255.147C227.907 234.192 222.145 213.64 211.244 195.749C200.344 177.858 184.728 163.321 166.111 153.733L101.492 193.366L163.22 255.147H227.897Z"
                                fill="#004ECC"
                              />
                              <path
                                d="M174.13 25.4471C158.189 9.4846 136.566 0.513842 114.017 0.508303C91.4678 0.502765 69.8401 9.4629 53.8916 25.4176C37.943 41.3723 28.9801 63.0146 28.9746 85.5835C28.9691 108.152 37.9214 129.799 53.8621 145.762L62.0058 153.706C78.136 145.429 96.0023 141.112 114.129 141.112C132.255 141.112 150.122 145.429 166.252 153.706L174.219 145.732C190.137 129.766 199.069 108.127 199.053 85.5712C199.036 63.0157 190.072 41.3898 174.13 25.4471Z"
                                fill="#FFB81D"
                              />
                              <path
                                d="M113.967 141.118C95.8939 141.121 78.082 145.434 62.0059 153.699L113.996 205.882L166.104 153.728C149.983 145.411 132.103 141.086 113.967 141.118V141.118Z"
                                fill="#FF6D34"
                              />
                              <path
                                d="M113.992 122.727C104.786 122.743 95.7556 120.211 87.8979 115.411C80.0402 110.611 73.6615 103.73 69.4668 95.5281L85.1937 87.4067C87.9339 92.6825 92.0682 97.1043 97.1462 100.19C102.224 103.276 108.051 104.908 113.992 104.908C119.933 104.908 125.76 103.276 130.838 100.19C135.916 97.1043 140.05 92.6825 142.79 87.4067L158.517 95.5576C154.313 103.749 147.931 110.621 140.075 115.415C132.219 120.209 123.193 122.739 113.992 122.727V122.727Z"
                                fill="white"
                              />
                            </svg>
                          </div>
                          <span>
                          // <FormattedMessage id={'ListingPage.appMessenger' /> 
                            Contact: {authorDisplayName}
                          </span>
                        </div>
                      )}
                      <Button onClick={this.onCloseChatBoxHandler} rootClassName={css.close}>
                        <span className={css.closeBtnWrap}>
                          <IconClose rootClassName={css.closeIcon} />
                        </span>
                      </Button>
                    </div>
                  )}
                </div>
              )} */}
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              {!isMobileLayout && (
                <Footer
                  className={css.listingFooter}
                  isEducationSite={isEducationSite}
                  // isTennisSite={isTennisSite}
                />
              )}
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        )}
        <Modal
          id="ListingPage.enquiry"
          contentClassName={css.enquiryModalContent}
          isOpen={this.state.enquiryModalOpen}
          onClose={onCloseEnquiryModal}
          onManageDisableScrolling={() => {}}
        >
          <EnquiryForm
            className={css.enquiryForm}
            submitButtonWrapperClassName={css.enquirySubmitButtonWrapper}
            listingTitle={
              <FormattedMessage id="EnquiryForm.heading" values={{ listingTitle: title }} />
            }
            authorDisplayName={authorDisplayName}
            sendEnquiryError={sendEnquiryError}
            onSubmit={this.onSubmitEnquiry}
            inProgress={sendEnquiryInProgress}
            isAuthenticated={this.props.isAuthenticated}
            isHideMessage={true}
          />
        </Modal>
        <Modal
          id="ListingPage.shareForm"
          className={css.shareFormModal}
          isOpen={this.state.isOpenShareForm}
          onClose={() => this.setState({ isOpenShareForm: false })}
          onManageDisableScrolling={() => {}}
          containerClassName={css.shareContainer}
        >
          <ShareForm listing={currentListing} url={url} />
        </Modal>
      </Page>
    );
  }
}

ListingPageComponent.defaultProps = {
  unitType: config.bookingUnitType,
  currentUser: null,
  enquiryModalOpenForListingId: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  monthlyTimeSlots: null,
  sendEnquiryError: null,
  sportsConfig: config.custom.sports,
  activityLevelConfig: config.custom.activityLevel,
  treatmentRoomOptionsConfig: config.custom.treatmentRoomOptions,
};

ListingPageComponent.propTypes = {
  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  unitType: propTypes.bookingUnitType,
  // from injectIntl
  intl: intlShape.isRequired,

  params: shape({
    id: string.isRequired,
    slug: string,
    variant: oneOf([LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT]),
  }).isRequired,

  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  getListing: func.isRequired,
  getOwnListing: func.isRequired,
  scrollingDisabled: bool.isRequired,
  enquiryModalOpenForListingId: string,
  showListingError: propTypes.error,
  callSetInitialValues: func.isRequired,
  reviews: arrayOf(propTypes.review),
  fetchReviewsError: propTypes.error,
  monthlyTimeSlots: object,
  sendEnquiryInProgress: bool.isRequired,
  sendEnquiryError: propTypes.error,
  onSendEnquiry: func.isRequired,
  onInitializeCardPaymentData: func.isRequired,

  sportsConfig: array,
  activityLevelConfig: array,
  treatmentRoomOptionsConfig: array,
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  const {
    showListingError,
    reviews,
    monthlyTimeSlots,
    sendEnquiryInProgress,
    sendEnquiryError,
    enquiryModalOpenForListingId,
    authorsListingsRefs,
    authorsListingsFacilityRefs,
    authorClassesIsLoading,
    authorClassesRefs,
    authorFacilitiesRef,
    authorFacilitiesIsLoading,
    facilityTimeslots,
    bookedTimeslots,
    listingAllReviews,
    authorsCompanyListingDetails,
    bookingData,
    isTimeSlotesLoading,
    transactionHitpay,
  } = state.ListingPage;
  const { currentUser } = state.user;

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const getAllAuthorsListingsExceptCurrent = id => {
    return getMarketplaceEntities(state, authorsListingsRefs).filter(
      listing => listing.id.uuid !== id.uuid
    );
  };
  const getAllAuthorsListingsExceptCurrentFacility = id => {
    return getMarketplaceEntities(state, authorsListingsFacilityRefs).filter(
      listing => listing.id.uuid !== id.uuid
    );
  };
  const authorClasses = getMarketplaceEntities(state, authorClassesRefs);
  const authorFacilities = getMarketplaceEntities(state, authorFacilitiesRef);

  return {
    isAuthenticated,
    currentUser,
    getListing,
    transactionHitpay,
    getOwnListing,
    getAllAuthorsListingsExceptCurrent,
    getAllAuthorsListingsExceptCurrentFacility,
    scrollingDisabled: isScrollingDisabled(state),
    enquiryModalOpenForListingId,
    showListingError,
    reviews,
    monthlyTimeSlots,
    sendEnquiryInProgress,
    sendEnquiryError,
    authorClasses,
    authorClassesIsLoading,
    authorsListingsRefs,
    authorsListingsFacilityRefs,
    listingAllReviews,
    authorsCompanyListingDetails,
    authorFacilities,
    authorFacilitiesIsLoading,
    facilityTimeslots,
    bookedTimeslots,
    bookingData,
    isTimeSlotesLoading,
  };
};

const mapDispatchToProps = dispatch => ({
  callSetInitialValues: (setInitialValues, values, saveToSessionStorage) =>
    dispatch(setInitialValues(values, saveToSessionStorage)),
  onSendEnquiry: (listingId, message) => dispatch(sendEnquiry(listingId, message)),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
  onFetchTimeSlots: (listingId, start, end, timeZone) =>
    dispatch(fetchTimeSlots(listingId, start, end, timeZone)),
  timeSlotsRequestFacility: (listingId, start, end, timezone) =>
    dispatch(timeSlotsRequestFacility(listingId, start, end, timezone)),
  onFetchAuthorFacilities: (autherId, dates) => dispatch(fetchAuthorFacilities(autherId, dates)),
  onAddingCustomPrice: price => dispatch(addingCustomPrice(price)),
  onRemovingCustomPrice: () => dispatch(removingCustomPrice()),
  showListing: listingId => dispatch(showListing(listingId)),
  addBookingData: data => dispatch(addBookingData(data)),
  trantion: param => dispatch(createTranstion(param)),
  onCancelSubscription: data => dispatch(cancelSubscription(data)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ListingPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withViewport,
  injectIntl
)(ListingPageComponent);

ListingPage.setInitialValues = initialValues => setInitialValues(initialValues);
ListingPage.loadData = loadData;

export default ListingPage;
