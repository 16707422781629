import React, { memo } from 'react';
import { Field, Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';
import {
  autocompleteSearchRequired,
  autocompletePlaceSelected,
  composeValidators,
} from '../../util/validators';
import { LocationAutocompleteInputField, Form } from '..';

import css from './NewAutocompleteInput.css';

import { identity } from '../../containers/JobsBoard/JobsBoard.data';

const LocationInput = props => {
  const { intl, showLable = 'Location' } = props;

  // const { sendEnquiryError, sendEnquiryInProgress } = useSelector(state => state.HomePage);

  const addressPlaceholderMessage = intl.formatMessage({
    id: 'EditListingLocationForm.addressPlaceholder',
  });

  const addressRequiredMessage = intl.formatMessage({
    id: 'EditListingLocationForm.addressRequired',
  });

  const addressNotRecognizedMessage = intl.formatMessage({
    id: 'EditListingLocationForm.addressNotRecognized',
  });

  return (
    <FinalForm
      {...props}
      onSubmit={() => {}}
      mutators={{ ...arrayMutators }}
      render={fieldRenderProps => {
        const { rootClassName, className, values, form } = fieldRenderProps;
        let { searchInputData } = fieldRenderProps;
        // const classes = classNames(rootClassName || css.root, className);
        return (
          <Form onSubmit={e => e.preventDefault()}>
            <div>
              {showLable && <div>{showLable}</div>}
              <div>
                <Field
                  name="filter_location"
                  format={identity}
                  render={({ input, meta }) => {
                    const { onChange, ...restInput } = input;

                    const searchOnChange = value => {
                      onChange(value);
                      props.onChange(value);
                    };

                    const searchInput = {
                      ...restInput,
                      onChange: searchOnChange,
                    };
                    return (
                      <LocationAutocompleteInputField
                        className={css.locationAddress}
                        rootClassName={css.rootClass}
                        inputClassName={css.locationInput}
                        iconClassName={css.locationAutocompleteInputIcon}
                        predictionsClassName={css.predictionsRoot}
                        autoFocus
                        name="location"
                        placeholder={addressPlaceholderMessage}
                        useDefaultPredictions={false}
                        valueFromForm={values.location}
                        validate={composeValidators(
                          autocompleteSearchRequired(addressRequiredMessage),
                          autocompletePlaceSelected(addressNotRecognizedMessage)
                        )}
                        inputRef={node => {
                          searchInputData = node;
                        }}
                        input={searchInput}
                        meta={meta}
                      />
                    );
                  }}
                />
              </div>
            </div>
          </Form>
        );
      }}
    />
  );
};

export default memo(LocationInput);

/*
props you have to pass 
 intl,
 onChange,
 showLable? optional defalt ture lable text is Loaction
 */
