import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import moment from 'moment';
import css from './SectionTopIconMobile.css';

const SectionTopIconMobile = props => {
  const { rootClassName, className, categories } = props;

  const classes = classNames(rootClassName || css.root, className);
  const getCategories = () => {
    let uniqCategories = [];
    categories.map((category, i) => {
      let index = uniqCategories.findIndex(c => c.key === category.key)
      if (index === -1) {
        uniqCategories.push(category)
      }
      return null;
    })
    return uniqCategories;
  }
  const getListingType = (listingType) => {
    if (listingType === 'Classes')
      return 'class';
    else if (listingType === 'Enquiry')
      return 'enquiry'
    else if (listingType === 'Facilities')
      return 'facility'
    else
      return 'listing';
  }

  return (
    <div className={classes}>
      <div className={css.header}>
        <div className={css.title}>
          <FormattedMessage id="SectionTopIcon.title" />
        </div>
      </div>
      <ul className={css.topIcons}>
        {categories
          ? getCategories().map(c =>
            c.key && c.key !== "semi_private_trainers" && c.key && c.key !== 'online' ? (
              <li key={c.key} className={css.icon}>
                {/* <a href={`/s?pub_category=${c.key}`} className={css.icon_link}> */}
                <a className={css.icon_link} href={c.listing === 'Classes'
                  ? `/s?&dates=${moment().format('YYYY-MM-DD')}&pub_category=${c.key}&pub_listing_type=${getListingType(c.listing)}`
                  : `/s?&pub_category=${c.key}&pub_listing_type=${getListingType(c.listing)}`} >
                  <div className={css.icon_item}>
                    <img className={css.img} src={`/static/images/SectionTopIcon/${c.key}.png`} alt="SectionTopIcon" />
                    <h3 className={css.iconName}>
                      <FormattedMessage id={`SectionTopIcon.${c.key}`} />
                    </h3>
                    <p className={css.iconDescription}>
                      <FormattedMessage id={`SectionTopIcon.iconDescription.${c.key}`} />
                    </p>
                    <p className={css.iconResultLink}>
                      <span className={css.iconResultLinkArrow}>> </span>
                      <FormattedMessage id={`SectionTopIcon.iconResultLink.${c.key}`} />
                    </p>
                  </div>
                </a>
              </li>
            ) : null
          )
          : null}
      </ul>
    </div>
  );
};

SectionTopIconMobile.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionTopIconMobile.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionTopIconMobile;
