import React from 'react';
import moment from 'moment';
import cns from 'classnames';
import PropTypes from 'prop-types';
import css from './RecentBooking.css';
import { propTypes } from 'util/types';
import { SecondaryButton } from 'components';
import { types as sdkTypes } from 'util/sdkLoader';
import { intlShape } from 'util/reactIntl';
import { formatMoney } from 'util/currency';
import config from 'config';
const { Money } = sdkTypes;

const CURRENCY = config.currency;

function formattedDate(tx) {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return moment(tx.booking.attributes.start)
    .tz(timeZone)
    .format('dddd, DD MMM');
}

function RecentBookings(props) {
  const { tx, onRebook, intl, className } = props;

  const listingTitle = tx.listing.attributes.title;
  const price = new Money(
    tx.attributes.metadata.amountPaid
      ? tx.attributes.metadata.amountPaid
      : tx.attributes.payoutTotal.amount,
    CURRENCY
  );
  const date = formattedDate(tx);
  const cardClassName = cns(css.card, className || '');
  return (
    <div className={cardClassName}>
      <div className={css.row}>
        <div className={css.listingTitle}>{listingTitle}</div>
        <div className={css.price}>{formatMoney(intl, price)}</div>
      </div>
      <div className={cns(css.row, css.alignedCenter)}>
        <div className={css.date}>{date}</div>
        <SecondaryButton onClick={onRebook} className={css.rebook}>
          Re-book
        </SecondaryButton>
      </div>
    </div>
  );
}

RecentBookings.propTypes = {
  intl: intlShape,
  tx: propTypes.transaction,
  onRebook: PropTypes.func,
  className: PropTypes.string,
};

export default RecentBookings;
