import React from 'react';
import { FormattedMessage } from 'react-intl';
import { NamedLink } from 'components';
import css from './BecomeSeller.css';

const BesomeSeller = ({ message = 'Are you an Expert ?', isVendor, isEducationSite }) => {
  return (
    <div className={css.becomeSellerWrap}>
      <div className={css.becomeSellerImg}>
        <img
          src={
            isEducationSite
              ? 'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1676289529/probuddy/static/NewEducation/become-seller_fyvqj3.png'
              : 'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1682396660/probuddy/static/NewEducation/YogaCardImage_vkhqgt.png'
          }
          alt="YogaCardImage"
        />
      </div>
      <div className={css.becomeSellerText}>
        <h3 className={css.becomeSellerHead}>{message}</h3>
        <p className={css.becomeSellerDesc}>
          {!isEducationSite
            ? 'Join our Network of Sellers and Explore our Integrated Business Management Tools.'
            : 'Join our community at ProBuddy Education and unlock the potential of hundreds of students waiting to learn from you!'}
        </p>
        <NamedLink
          name={
            isEducationSite ? 'BecomeTutorPage' : isVendor ? 'SubscriptionPage' : 'BecomeVendorPage'
          }
        >
          <button className={css.becomeSellerButton}>
            <FormattedMessage id="HeaderContainer.becomeAHost" />
          </button>
        </NamedLink>
      </div>
    </div>
  );
};

export default BesomeSeller;
