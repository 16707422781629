import React, { memo } from 'react';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import { Loading } from '../../../components';
import { FormattedMessage, injectIntl } from 'react-intl';
import css from '../MatchActivity.css';
import { withViewport } from 'util/contextHelpers';
import { compose } from 'redux';

import MatchActivityCard from './MatchActivityCard';

const MatchActivityForm = props => {
  const { jobsBoardListing = [], prop, loading, history, isEducationSite } = props;
  return (
    <FinalForm
      {...prop}
      onSubmit={() => {}}
      mutators={{ ...arrayMutators }}
      render={fieldRenderProps => {
        const { rootClassName, className, values, form } = fieldRenderProps;
        const classes = classNames(rootClassName || css.root, className);
        return (
          <div className={css.enquiryCardWrapper}>
            <div className={css.enquiryCard}>
              {loading && <Loading />}
              {!loading &&
                jobsBoardListing.length !== 0 &&
                jobsBoardListing.map(jobsBoardData => (
                  <div className={css.JobsBoardCard}>
                    <MatchActivityCard
                      history={history}
                      jobsBoardData={jobsBoardData}
                      isEducationSite={isEducationSite}
                    />
                  </div>
                ))}
              {!loading && jobsBoardListing.length === 0 && (
                <FormattedMessage id="HomePage.NoListingsFound" />
              )}
            </div>
            <div className={css.jobsBoardPagination}>
              {/* {totalPages} */}
              {/* <PaginationButtons
                page={jobsPagination?.page}
                totalPages={jobsPagination?.paginationLimit}
                onPageClick={handlePage}
              /> */}
            </div>
          </div>
        );
      }}
    />
  );
};

export default memo(compose(withRouter, injectIntl, withViewport)(MatchActivityForm));
