import React, { useState, useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { propTypes } from 'util/types';
import {
  TransactionStateButton,
  IconSpinner,
  PaginationButtons,
  InboxItemWithButtons,
} from 'components';
import css from './BookingsFromCurrentUserSection.css';
import { FormattedMessage, intlShape } from 'util/reactIntl';
import config from 'config';
import { txState } from 'components/InboxItem/helpers';

import {
  TRANSITION_CANCEL,
  TRANSITION_CANCEL_READY_TO_COMPLETE,
  TRANSITION_COMPLETE_MANUAL,
  txIsReadyToComplete,
} from 'util/transaction';

const STATE_BTNS = [
  {
    state: 'accepted',
    label: <FormattedMessage id={'InboxPage.stateLabelAccepted'} />,
  },
  {
    state: 'delivered',
    label: <FormattedMessage id={'InboxPage.stateLabelDelivered'} />,
  },
];

const StateButtons = ({ onClick, state }) => {
  return (
    <div className={css.stateBtnsWtap}>
      {STATE_BTNS.map(btn => (
        <TransactionStateButton
          onClick={() => onClick(btn.state)}
          isActive={state === btn.state}
          {...btn}
        />
      ))}
    </div>
  );
};

// const PER_PAGE = 15;

// const paginatedData = (data, currentPage) => {
//   const offset = (currentPage - 1) * PER_PAGE;
//   return slice(data, offset, offset + PER_PAGE);
// };

function BookingsFromCurrentSection({
  params,
  transactions,
  onStateChange,
  isLoading,
  intl,
  profileUser,
  transitionTransaction,
  onManageDisableScrolling,
  onRescheduleBooking,
  rescheduleRequestInProgress,
  rescheduleError,
  rescheduledsuccessfully,
  currentUser,
  onFetchTimeSlots,
  onFetchMonthlyTimeSlots,
  monthlyTimeSlots,
  isTimeSlotesLoading,
  paginationData,
}) {
  const [status, setStatus] = useState('accepted');
  const [page, setPage] = useState(1);

  const profileUserId = profileUser.id ? profileUser.id.uuid : '';
  const filteredTransactions = useMemo(() => {
    return transactions.filter(tx => tx?.customer?.id?.uuid === profileUserId);
  }, [transactions, profileUserId]);

  // const totalPages = Math.ceil(filteredTransactions.length / PER_PAGE);
  const profileUserName = profileUser.attributes.profile.displayName;

  useEffect(() => {
    onStateChange(params.id, status);
    // eslint-disable-next-line
  }, [status]);

  const stateChangeHandler = status => {
    setStatus(status);
  };
  const pageClick = useCallback(
    e => {
      onStateChange(params?.id, status, e);
      setPage(e);
    },
    [status, params, onStateChange]
  );

  const cancelDeclineHandler = transition => tx => {
    const txId = tx.id.uuid;
    const customerId = tx.customer.id.uuid;
    const sellerId = tx.provider.id.uuid;
    const listing = tx.listing.id.uuid;
    const withCredits = tx.attributes.protectedData.withCredits;

    transitionTransaction({
      transactionId: txId,
      transition: transition,
      withCredits: withCredits,
      customerId: customerId,
      sellerId: sellerId,
      listing: listing,
    });
  };

  const completeAcceptHandler = transition => tx => {
    const uuid = tx.id.uuid;
    const listing = tx.listing.id.uuid;
    const sellerId = tx.provider.id.uuid;
    transitionTransaction({
      transactionId: uuid,
      transition: transition,
      sellerId: sellerId,
      listing: listing,
    });
  };

  return (
    <div>
      <h2>{`${profileUserName || ''}'s bookings`}</h2>
      <StateButtons params={params} onClick={stateChangeHandler} state={status} />
      {isLoading ? (
        <IconSpinner className={css.loader} />
      ) : (
        <div className={css.bookingsContent}>
          {filteredTransactions.length ? (
            <ul className={css.bookingsList}>
              {filteredTransactions &&
                !!filteredTransactions?.length &&
                filteredTransactions.map(tx => {
                  const stateData = txState(intl, tx, 'sale');
                  return (
                    <li key={tx.id.uuid}>
                      <InboxItemWithButtons
                        unitType={config.bookingUnitType}
                        type={'sale'}
                        tx={tx}
                        intl={intl}
                        stateData={stateData}
                        onComplete={completeAcceptHandler(TRANSITION_COMPLETE_MANUAL)}
                        onCancel={
                          txIsReadyToComplete(tx)
                            ? cancelDeclineHandler(TRANSITION_CANCEL_READY_TO_COMPLETE)
                            : cancelDeclineHandler(TRANSITION_CANCEL)
                        }
                        onManageDisableScrolling={onManageDisableScrolling}
                        rescheduleBooking={onRescheduleBooking}
                        rescheduleRequestInProgress={rescheduleRequestInProgress}
                        rescheduleError={rescheduleError}
                        rescheduledsuccessfully={rescheduledsuccessfully}
                        currentUser={currentUser}
                        onFetchTimeSlots={onFetchTimeSlots}
                        onFetchMonthlyTimeSlots={onFetchMonthlyTimeSlots}
                        monthlyTimeSlots={monthlyTimeSlots}
                        isTimeSlotesLoading={isTimeSlotesLoading}
                      />
                    </li>
                  );
                })}
            </ul>
          ) : (
            'No bookings'
          )}

          <PaginationButtons
            page={page}
            totalPages={paginationData?.totalPages}
            onPageClick={pageClick}
          />
        </div>
      )}
    </div>
  );
}

BookingsFromCurrentSection.propTypes = {
  intl: intlShape,
  transactions: PropTypes.arrayOf(propTypes.transaction),
  params: PropTypes.object,
  onStateChange: PropTypes.func,
  isLoading: PropTypes.bool,
  profileUser: propTypes.user,
  transitionTransaction: PropTypes.func,
};

export default BookingsFromCurrentSection;
