import React, { useState, useEffect } from 'react';
import { slice } from 'lodash/array';
import { orderBy } from 'lodash';
import { uid } from 'react-uid';
import cns from 'classnames';
import css from './RecentTable.css';
import { NamedLink } from 'components';

function RecentTableRow({ entry, onSelect, isSelected }) {
  const name =
    entry.transactions[0].attributes.protectedData?.kidsName ||
    entry.customer.attributes.profile.displayName;

  const contactNumber = entry.customer.attributes.profile.publicData
    ? entry.customer.attributes.profile.publicData.phoneNumber
    : '';
  const phoneNo = entry.customer.attributes.profile.publicData.phoneNumber || null;

  const mobileView = typeof window !== 'undefined' && window.screen.width <= 1023;
  return (
    <div className={css.row}>
      {!mobileView && (
        <>
          <div className={css.checkboxField}>
            <input
              className={css.checkboxInput}
              onChange={() => onSelect(entry.transactions[0])}
              type="checkbox"
              id={entry.transactions[0].id.uuid}
              checked={isSelected}
            />
            <label htmlFor={entry.transactions[0].id.uuid}></label>
            <div className={cns(css.cell, css.checkboxFieldCol)}></div>
          </div>

          <div className={cns(css.cell, css.name, css.whatsappIconSection)}>
            <NamedLink
              name="ProfilePage"
              params={{ id: entry.customer.id.uuid }}
              className={css.nameContent}
            >
              {name}
            </NamedLink>
            {contactNumber ? (
              <div className={css.iconWhatsapp}>{/* <IconWhatsapp /> */}</div>
            ) : null}
          </div>
          <div className={cns(css.cell, css.phone)}>
            {phoneNo ? (
              <NamedLink
                name="ProfilePage"
                params={{ id: entry.customer.id.uuid }}
                className={css.nameContent}
              >
                {phoneNo}
              </NamedLink>
            ) : (
              <span className={css.emptyPhone}>-</span>
            )}
          </div>
          <div className={cns(css.cell, css.listingName)}>
            <span className={css.listingNameContent}>{entry.listing.attributes.title}</span>
          </div>
        </>
      )}

      {mobileView && (
        <>
          <div className={cns(css.cell, css.checkboxFieldCol)}>
            <div className={css.checkboxField}>
              <input
                className={css.checkboxInput}
                onChange={() => onSelect(entry.transactions[0])}
                type="checkbox"
                id={entry.transactions[0].id.uuid}
                checked={isSelected}
              />
              <label htmlFor={entry.transactions[0].id.uuid}></label>
            </div>
          </div>
          <div className={css.mobileTableWrap}>
            <div className={cns(css.cell, css.name, css.whatsappIconSection)}>
              <NamedLink
                name="ProfilePage"
                params={{ id: entry.customer.id.uuid }}
                className={css.nameContent}
              >
                {name}
              </NamedLink>
              {contactNumber ? (
                <div className={css.iconWhatsapp}>{/* <IconWhatsapp /> */}</div>
              ) : null}
            </div>
            <div className={cns(css.cell, css.listingName)}>
              <span className={css.listingNameContent}>{entry.listing.attributes.title}</span>
            </div>
          </div>
          <div className={cns(css.cell, css.phone)}>
            {phoneNo ? (
              <NamedLink
                name="ProfilePage"
                params={{ id: entry.customer.id.uuid }}
                className={css.nameContent}
              >
                {phoneNo}
              </NamedLink>
            ) : (
              <span className={css.emptyPhone}>-</span>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default function RecentTable({
  data,
  onAllSelectedClient,
  selectedBookings,
  onSelect,
  urlQueryParams,
  myClientsData,
}) {
  // eslint-disable-next-line
  const [page, setPage] = useState(1);
  const [filterData, setFilterData] = useState(data);
  const perPage = 30;
  const isAllCheked = selectedBookings.length === data.length ? true : false;

  useEffect(() => {
    let sortedData = data;
    if (urlQueryParams && data && urlQueryParams.sort && urlQueryParams.sort === 'desc') {
      sortedData = orderBy(
        data,
        [
          entry =>
            entry.listing.attributes.title !== null
              ? entry.listing.attributes.title.toLowerCase()
              : null,
        ],
        'desc'
      );
    } else if (urlQueryParams && data && urlQueryParams.sort && urlQueryParams.sort === 'asc') {
      sortedData = orderBy(
        data,
        [
          entry =>
            entry.listing.attributes.title !== null
              ? entry.listing.attributes.title.toLowerCase()
              : null,
        ],
        'asc'
      );
    }

    if (urlQueryParams && data && urlQueryParams.keywords) {
      let pattern = new RegExp(urlQueryParams.keywords, 'i');
      const searchClients = myClientsData.filter(entry => {
        return entry.customer.attributes.profile.displayName.match(pattern);
      });
      sortedData = searchClients;
    }
    setFilterData(sortedData);
    /* eslint-disable-next-line */
  }, []);
  const paginatedData = data => {
    const offset = (page - 1) * perPage;
    return slice(data, offset, offset + perPage);
  };

  const onSelectAllRow = () => {
    const allSeletedData = [];
    if (!isAllCheked) {
      data.forEach(entry => {
        allSeletedData.push(entry.transactions[0]);
      });
    }
    onAllSelectedClient(allSeletedData);
  };

  return (
    <div className={css.wrapper}>
      <div className={css.table}>
        <div className={css.head}>
          <div className={cns(css.cell, css.checkboxCol)}>
            <div className={css.checkboxField}>
              <input
                className={css.checkboxInput}
                onChange={onSelectAllRow}
                checked={isAllCheked}
                type="checkbox"
                id={'scheduleTable'}
              />
              <label htmlFor={'scheduleTable'}></label>
            </div>
          </div>

          <div className={cns(css.cell, css.nameCol)}>Name</div>

          <div className={cns(css.cell, css.phoneCol)}>Phone Number</div>
          <div className={cns(css.cell, css.listingNameCol)}>Listing Name</div>
        </div>

        {paginatedData(filterData).map(entry => (
          <RecentTableRow
            key={uid(entry)}
            isSelected={selectedBookings.some(
              selectedTx => selectedTx.id.uuid === entry.transactions[0].id.uuid
            )}
            onSelect={onSelect}
            entry={entry}
          />
        ))}
      </div>

      {/* <PaginationButtons page={page} totalPages={totalPages} onPageClick={setPage} /> */}
    </div>
  );
}
