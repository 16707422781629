import React from 'react';
import { bool, func } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import config from '../../config';
import {
  LayoutSideNavigation,
  LayoutWrapperMain,
  LayoutWrapperAccountSettingsSideNav,
  LayoutWrapperTopbar,
  LayoutWrapperFooter,
  Footer,
  Page,
  UserNav,
  IconSuccess,
  ExternalLink,
} from '../../components';
import { GoogleLogin } from 'react-google-login';
import { TopbarContainer } from '../../containers';

import { isScrollingDisabled } from '../../ducks/UI.duck';
import { saveSettings, loadData } from './GoogleCalendarSettingsPage.duck';
import css from './GoogleCalendarSettingsPage.css';

export const GoogleCalendarSettingsPageComponent = props => {
  const { currentUser, scrollingDisabled, onSaveSettings, intl, isEducationSite } = props;

  const user = ensureCurrentUser(currentUser);
  const title = intl.formatMessage({ id: 'GoogleCalendarSettingsPage.title' });
  const authorizeCalendarText = intl.formatMessage({
    id: 'GoogleCalendarSettingsPage.authorizeCalendar',
  });
  const googleClientId = config.googleClientId;
  const googleCalendarTokens = user?.attributes?.profile?.privateData?.googleCalendarTokens;
  const disabled = googleCalendarTokens?.refresh_token;
  const accessToken = googleCalendarTokens?.access_token;
  const googleSecuritySettingsLink = (
    <ExternalLink
      href="https://myaccount.google.com/security"
      className={css.googleSecuritySettingsLink}
    >
      <FormattedMessage id="GoogleCalendarSettingsPage.googleSecuritySettingsLink" />
    </ExternalLink>
  );
  const tokenMessage = disabled ? (
    <div className={css.validTokenMessage}>
      <IconSuccess className={css.icon} />
      <FormattedMessage id="GoogleCalendarSettingsPage.validTokenMessage" />
    </div>
  ) : accessToken ? (
    <div className={css.inValidTokenMessage}>
      <FormattedMessage
        id="GoogleCalendarSettingsPage.missingRefreshTokenMessage"
        values={{ link: googleSecuritySettingsLink, br: <br /> }}
      />
    </div>
  ) : user?.attributes?.profile?.privateData ? (
    <div className={css.inValidTokenMessage}>
      <FormattedMessage id="GoogleCalendarSettingsPage.inValidTokenMessage" />
    </div>
  ) : null;

  const onLoginSuccess = response => {
    const { code } = response;
    onSaveSettings(code);
  };

  const onLoginFailure = response => {
    console.log(response);
  };

  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSideNavigation>
        <LayoutWrapperTopbar className={css.layoutTopbar}>
          <TopbarContainer
            currentPage="HitpaySettingsPage"
            desktopClassName={css.desktopTopbar}
            mobileClassName={css.mobileTopbar}
            isEducationSite={isEducationSite}
          />
          <UserNav selectedPageName="HitpaySettingsPage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperAccountSettingsSideNav
          currentTab="GoogleCalendarSettingsPage"
          isEducationSite={isEducationSite}
        />
        <LayoutWrapperMain>
          <div className={css.content}>
            <h1 className={css.title}>
              <FormattedMessage id="GoogleCalendarSettingsPage.heading" />
            </h1>
            <GoogleLogin
              clientId={googleClientId}
              buttonText={authorizeCalendarText}
              onSuccess={onLoginSuccess}
              onFailure={onLoginFailure}
              responseType="code"
              accessType="offline"
              scope="openid email profile https://www.googleapis.com/auth/calendar"
              disabled={disabled}
            />
            {tokenMessage}
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter rootClassName={css.layoutFooter}>
          <Footer isEducationSite={isEducationSite} />
        </LayoutWrapperFooter>
      </LayoutSideNavigation>
    </Page>
  );
};

GoogleCalendarSettingsPageComponent.defaultProps = {
  saveSettingsError: null,
  saveSettingsInProgress: null,
  currentUser: null,
};

GoogleCalendarSettingsPageComponent.propTypes = {
  saveSettingsError: propTypes.error,
  saveSettingsInProgress: bool.isRequired,
  currentUser: propTypes.currentUser,
  onSaveSettings: func.isRequired,
  scrollingDisabled: bool.isRequired,
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const { saveSettingsError, saveSettingsInProgress } = state.HitpaySettingsPage;

  return {
    currentUser,
    saveSettingsError,
    saveSettingsInProgress,
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const mapDispatchToProps = dispatch => ({
  onSaveSettings: values => dispatch(saveSettings(values)),
});

const GoogleCalendarSettingsPage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(GoogleCalendarSettingsPageComponent);

GoogleCalendarSettingsPage.loadData = loadData;

export default GoogleCalendarSettingsPage;
