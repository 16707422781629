import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { string, bool } from 'prop-types';
import { injectIntl, intlShape } from 'util/reactIntl';
import { Modal } from 'components';
import LocationInput from '../LocationAutocompleteInput/NewAutocompleteInput';
import { withRouter } from 'react-router-dom';

import classNames from 'classnames';
import css from './GetQuoteModal.css';
import thankyousvg from 'assets/ThankYouPage/thankyousvg.svg';
import { getQuoteTrack } from 'util/gtag_conversionTracking';
import emailjs, { init } from '@emailjs/browser';
import { useDispatch, useSelector } from 'react-redux';
import { createJobsboardListing, getVendors } from 'containers/JobsBoard/JobsBoard.duck';
import { toTitleCase } from 'util/stringHelper';
import {
  city,
  cityEducation,
  subCategoriesFilterOptions,
  subCategoriesFilterOptionsEducational,
  subCategoriesFilterOptionsTennis,
} from 'marketplace-custom-config';
import { getQueAnsList } from './GetQuoteModal.data';

const GetQuoteModalComponent = props => {
  const {
    modalId,
    open,
    params = {},
    isEducationSite,
    isTennisSite,
    onModalClose,
    banner = { img: '', head: '', desc: '' },
    isAuthenticated,
    intl,
    category,
    history,
    mailAtbrs: { forName, altUrl, postFixFromName = '' },
  } = props;

  const cityData = isEducationSite ? cityEducation : city;
  const subCategoryData = isEducationSite
    ? subCategoriesFilterOptionsEducational
    : isTennisSite
    ? subCategoriesFilterOptionsTennis
    : subCategoriesFilterOptions;

  const { currentUser } = useSelector(state => state.user);
  const { vendorEmail } = useSelector(state => state.JobsBoard);

  const currentUserMail = currentUser?.attributes?.email;

  const [isStarted, setIsStarted] = useState(false);
  const [isLeaving, setIsLeaving] = useState(false);
  const [showError, setShowError] = useState('');
  const [clicked, setClicked] = useState(false);
  const [contactDetails, setContactDetails] = useState({
    email: '',
    whatsapp: '',
    preferedArea: '',
    city: params?.city ? params?.city : '',
    category: params?.slug ? params?.slug : '',
  });
  const [mailData, setMailData] = useState({
    forName: forName,
    altUrl: altUrl,
    postFixFromName: postFixFromName,
  });

  useEffect(() => {
    if (contactDetails.category) {
      const editFName = mailData.forName.replaceAll('sportName', contactDetails.category);
      const editAUrl = mailData.altUrl.replaceAll('sportName', contactDetails.category);
      const editPFFName = mailData.postFixFromName.replaceAll('sportName', contactDetails.category);

      setMailData({
        forName: editFName,
        altUrl: editAUrl,
        postFixFromName: editPFFName,
      });
      const queOptionList = isEducationSite
        ? getQueAnsList('education')
        : getQueAnsList(contactDetails.category.toLowerCase().replaceAll(' ', '-'));

      const extendedQueOptList = [
        ...queOptionList,
        {
          question: 'Give us details of what you are looking for.',
          infoText: 'The better the info the faster the match',
          type: 'text',
          noOtherOption: true,
          answer: '',
        },
      ];
      setAllQueAns(extendedQueOptList);

      const initialQueAns = extendedQueOptList?.map(({ question, answer = '', type }) => ({
        question,
        answer: type === 'checkbox' ? [] : answer,
      })) ?? [{ question: '', answer: '' }];
      setQueAnsList(initialQueAns);
    }
    // template_0zp589j
  }, [contactDetails.category]);

  const [currQueIndex, setCurrQueIndex] = useState(-1);
  const [queAnsList, setQueAnsList] = useState([]);
  const [allqueAns, setAllQueAns] = useState([]);
  const [otherOptionText, setOtherOptionText] = useState(
    Array(allqueAns?.length)
      .fill('')
      .map((e, i) => [i, e])
      .reduce((acc, val) => {
        const [key, value] = val;
        acc[key] = value;
        return acc;
      }, {})
  );
  const dispatch = useDispatch();

  useEffect(() => {
    init('p656r9_5pKAHF0ioU');
  }, []);

  useEffect(() => {
    if (currentUserMail)
      setContactDetails(prev => ({
        ...prev,
        email: currentUserMail,
      }));
  }, [currentUserMail]);

  const sendQuotesInquiryMail = ({ message, email, ...values }) => {
    const url = typeof window !== 'undefined' ? window?.location?.href : mailData.altUrl;

    let fromName;
    if (mailData.postFixFromName)
      fromName = `Probuddy | Get a Quote for ${mailData.postFixFromName}`;
    else fromName = `Probuddy | Get a Quote `;

    const templateParams = {
      for_name: mailData.forName,
      message: message,
      from_name: fromName,
      to_mail: 'Chris@probuddy.io,probuddyinquiries@gmail.com',
      from_mail: email,
      url: url,
    };
    if (process.env.REACT_APP_ENV === 'production') {
      emailjs
        .send('service_tn96fm5', 'template_04szlx8', templateParams, 'p656r9_5pKAHF0ioU')
        .then()
        .catch(e => console.log('e-->', e));
      getQuoteTrack();
    }
  };
  const sendVendorJObsBoardEmail = ({ message, cc_list, jobsID, ...values }) => {
    const url = window?.location?.origin;

    let fromName;
    if (mailData.postFixFromName) fromName = mailData.postFixFromName;
    else fromName = `categorys`;

    const templateParams = {
      message: message,
      from_name: fromName,
      to_mail: 'Chris@probuddy.io,probuddyinquiries@gmail.com',
      cc_list: cc_list,
      jobslink: `${url}/jobs-board/${jobsID}`,
      // jobslink:,
    };
    console.log(templateParams);
    if (process.env.REACT_APP_ENV === 'production') {
      emailjs
        .send('service_tn96fm5', 'template_0zp589j', templateParams, 'p656r9_5pKAHF0ioU')
        .then()
        .catch(e => console.log('e-->', e));
      getQuoteTrack();
    }
  };

  const canContinue =
    currQueIndex >= 0
      ? queAnsList?.[currQueIndex]?.answer?.length > 0
      : contactDetails?.email !== '' &&
        contactDetails?.preferedArea !== '' &&
        contactDetails?.city !== '' &&
        contactDetails?.category !== '';

  const isOptionChecked = (optionType, ans, option) =>
    ans?.length > 0 && (optionType === 'checkbox' ? ans.includes(option) : ans === option);

  const optionChangeHandler = event => {
    const value = event.target.value.trim();
    if (showError) {
      setShowError('');
    }
    if ('details_LookingFor' === event.target.name && !(value.length >= 20)) {
      return;
    }
    // const isOther = event.target.id?.toString()?.endsWith('otherOption');

    // if (!value) return;

    const updatedQAList = queAnsList.map((_qas, _ind) => {
      if (_ind === currQueIndex) {
        if (allqueAns[currQueIndex].type === 'checkbox') {
          const checkBoxAns = [..._qas?.answer];

          if (checkBoxAns.includes(value)) {
            const existingAnsIndex = checkBoxAns.indexOf(value);
            checkBoxAns.splice(existingAnsIndex, 1);
          } else checkBoxAns.push(value);

          return { ..._qas, answer: checkBoxAns };
        } else return { ..._qas, answer: value };
      }
      return _qas;
    });

    setQueAnsList(updatedQAList);
  };
  const getPreparedMessage = (complect = false) => {
    let combinedMessage = '';
    if (contactDetails?.whatsapp) combinedMessage += `WhatsApp No.: ${contactDetails.whatsapp}\n`;

    if (progressPerc) combinedMessage += `Survey Completed: ${complect ? '100%' : progressPerc}\n`;

    if (contactDetails?.preferedArea)
      combinedMessage += `Prefered Area: ${contactDetails.preferedArea}\n`;

    combinedMessage += '-'.repeat(15);

    combinedMessage += queAnsList.reduce(
      (acc, curr) => `${acc}\n${curr?.question}\n=> ${curr?.answer}`,
      '\nQue-Ans for Quotes'
    );

    return combinedMessage;
  };
  const getPreparedMessageVendor = () => {
    let combinedMessage = '';

    if (contactDetails?.city) combinedMessage += `City: ${contactDetails.city}\n`;
    if (contactDetails?.preferedArea)
      combinedMessage += `Prefered Area: ${contactDetails.preferedArea}\n`;

    combinedMessage += '-'.repeat(20);

    combinedMessage += queAnsList
      // .slice(1, -1)
      .reduce((acc, curr) => `${acc}\n${curr?.question}\n=> ${curr?.answer}`, '\nQue-Ans for Jobs');

    return combinedMessage;
  };

  // onChangeFirst
  const onChangeFirst = e => {
    if (e.persist) {
      e.persist();
    }
    const { name, value } = e.target;
    setContactDetails({ ...contactDetails, [name]: value });
  };
  const submitSurvey = (servery = false) => {
    try {
      const mailData = {
        email: contactDetails.email,
        message: getPreparedMessage(servery),
      };
      console.info('===LOG==> file: GetQuoteModal ~ submitSurvey ~ mailData:', mailData);
      sendQuotesInquiryMail(mailData);
    } catch (error) {
      console.error('===LOG==> file: GetQuoteModal ~ submitSurvey ~ error:', error);
    }
  };

  const submitVendorEmail = id => {
    try {
      const mailData = {
        cc_list: vendorEmail.join(','),
        // message: JobsEmailTemplate(emailInfo), // email template
        message: getPreparedMessageVendor(),
        jobsID: id,
      };
      sendVendorJObsBoardEmail(mailData);
    } catch (error) {
      console.log(error);
    }
  };

  const continueClickHandler = async () => {
    const city = params?.city ? params?.city : contactDetails.city;
    const sub_cat = params?.slug ? params.slug : contactDetails.category;
    const sab_cat_trim =
      sub_cat &&
      sub_cat
        .toLowerCase()
        .trim()
        .replaceAll(' ', '-');

    if (currQueIndex === 0) {
      const listings = !isEducationSite
        ? 'freelancer,listing,company,enquiry'
        : 'courses,sessions,education courses,tutor,remote tutor'
        ? isTennisSite
        : 'player-profile,events,shop';

      const Emailparams = { cityParams: city, category: sab_cat_trim, listing_type: listings };
      dispatch(getVendors(Emailparams));
    }
    if (!(queAnsList?.length - 1 > currQueIndex) && !clicked) {
      setClicked(true);
      // if (currentUser?.attributes?.email) {
      const title = `Looking for ` + toTitleCase(mailData.postFixFromName);
      const email = contactDetails?.email;
      const description = toTitleCase(queAnsList.slice(-1)[0]?.answer);
      const que_ans = queAnsList.slice(1, -1);
      const prefered_area = toTitleCase(contactDetails?.preferedArea);
      const sub_category = [sab_cat_trim];
      const category = isEducationSite ? ('education' ? isTennisSite : 'tennis') : 'sports';
      const listing_type = 'jobsboard';
      // const profileImage = currentUser?.profileImage.attributes.variants['square-small2x'].url;
      // const email = currentUser?.attributes?.email;
      // const profile = currentUser?.attributes?.profile;

      const createListingData = {
        title,
        description,
        publicData: {
          category,
          sub_category,
          que_ans,
          is_active: true,
          email,
          location: {
            prefered_area,
            city,
          },
          listing_type,
        },
      };
      const JobsId = await dispatch(
        createJobsboardListing(createListingData, !!currentUser?.attributes?.email)
      );
      const id = JobsId;

      submitSurvey(true);

      if (id?.data?.data?.id) {
        const uuid = id?.data?.data?.id;
        // submitVendorEmail(uuid);
        // const emailInfo = { category, city, title, description, que_ans, id: uuid };
        submitVendorEmail(uuid);
      } else if (id?.data?.id?.uuid) {
        const uuid = id?.data?.id?.uuid;
        // submitVendorEmail(uuid);
        // const emailInfo = { category, city, title, description, que_ans, id: uuid };
        submitVendorEmail(uuid);
      } else {
        console.error({ error: id });
      }
    }

    setCurrQueIndex(prev => prev + 1);
  };

  const progressPerc =
    currQueIndex === -1 ? `0%` : `${(currQueIndex / (queAnsList?.length || 5)) * 100}%`;

  return (
    <Modal
      id={modalId}
      extraContainerClasses={css.customModal}
      contentClassName={css.customModalContent}
      extraScrollLayerClasses={css.customScrollLayer}
      isOpen={open}
      onClose={() => {
        if (!(queAnsList?.length > currQueIndex)) {
          onModalClose();
        } else {
          setIsLeaving(true);
        } // write
      }}
      onManageDisableScrolling={() => {}}
    >
      {!isStarted ? (
        <div className={css.banner}>
          <img className={css.bannerImg} src={banner.img} alt="bannerImage" />
          <div className={css.bannerText}>
            <h3>{banner.head}</h3>
            <p>{banner.desc}</p>
            <button onClick={() => setIsStarted(true)} className={css.btnBlue}>
              {/* btnContinue */}
              Let's Start
            </button>
          </div>
        </div>
      ) : allqueAns.length > currQueIndex ? (
        <div className={css.quotesWrapper}>
          <div className={css.progressBarMain}>
            <div className={css.progress} id="progress" style={{ width: progressPerc }}></div>
          </div>
          {currQueIndex >= 0 ? (
            <>
              <div className={css.questionWrapper}>
                <h2>{allqueAns[currQueIndex].question}</h2>
                {allqueAns[currQueIndex]?.infoText?.length > 0 ? (
                  <h5>{allqueAns[currQueIndex].infoText}</h5>
                ) : (
                  ''
                )}
              </div>
              <div className={css.optionsWrapSurvey}>
                {allqueAns[currQueIndex].type !== 'text' &&
                allqueAns[currQueIndex]?.options?.length > 0 ? (
                  allqueAns[currQueIndex].options.map((optionText, ind) => (
                    <div
                      className={css.optionsWrapper}
                      key={`Que${currQueIndex}_option${ind}_wrap`}
                    >
                      <input
                        type={allqueAns[currQueIndex].type}
                        value={optionText}
                        key={`Que${currQueIndex}_option${ind}`}
                        id={`Que${currQueIndex}_option${ind}`}
                        name={`Que${currQueIndex}`}
                        checked={isOptionChecked(
                          allqueAns[currQueIndex].type,
                          queAnsList[currQueIndex].answer,
                          optionText
                        )}
                        onChange={optionChangeHandler}
                      />
                      <label
                        key={`Que${currQueIndex}_option${ind}_label`}
                        htmlFor={`Que${currQueIndex}_option${ind}`}
                      >
                        {optionText}
                      </label>
                    </div>
                  ))
                ) : (
                  <>
                    <div className={css.optionalWrap}>
                      <code>required *</code>
                      {showError === 'details' && (
                        <code className={css.errorClass}>Minimum 20 characters required.</code>
                      )}
                      <div className={css.optionsWrap}>
                        <textarea
                          rows={3}
                          placeholder="Something you've in your mind"
                          name="details_LookingFor"
                          onBlur={event => {
                            const value = event.target.value.trim();
                            if (
                              'details_LookingFor' === event.target.name ||
                              !(value.length >= 20)
                            ) {
                              setShowError('details');
                            }
                          }}
                          onChange={optionChangeHandler}
                        ></textarea>
                      </div>
                    </div>
                    {currQueIndex === allqueAns.length - 1 && (
                      <div className={css.optionalWrap}>
                        <code>We use whats app to match Coaches with the Student</code>
                        <div className={css.optionsWrap}>
                          <input
                            type="tel"
                            placeholder="Your Whatsapp Number"
                            name="whatsapp"
                            value={contactDetails?.whatsapp}
                            onChange={onChangeFirst}
                          />
                        </div>
                      </div>
                    )}
                  </>
                )}

                {allqueAns[currQueIndex]?.noOtherOption ? (
                  <></>
                ) : (
                  <div className={css.optionsWrapper} key={`Que${currQueIndex}_otherOption_wrap`}>
                    <input
                      type={allqueAns[currQueIndex].type}
                      key={`Que${currQueIndex}_otherOption`}
                      id={`Que${currQueIndex}_otherOption`}
                      name={`Que${currQueIndex}`}
                      value={otherOptionText[currQueIndex]}
                      checked={isOptionChecked(
                        allqueAns[currQueIndex].type,
                        queAnsList[currQueIndex].answer,
                        otherOptionText[currQueIndex]
                      )}
                      onChange={optionChangeHandler}
                    />
                    <label
                      key={`Que${currQueIndex}_otherOption_label`}
                      htmlFor={`Que${currQueIndex}_otherOption`}
                      className={css.otherOptionText}
                    >
                      <input
                        type="text"
                        name="otherOptionText"
                        placeholder="Other"
                        key={`Que${currQueIndex}_otherOptionText`}
                        value={otherOptionText[currQueIndex]}
                        onChange={({ target }) =>
                          setOtherOptionText(prev => ({
                            ...prev,
                            [currQueIndex]: target.value,
                          }))
                        }
                      />
                    </label>
                  </div>
                )}
              </div>
              {!canContinue && (
                <h4 className={css.errorText}>
                  {
                    {
                      checkbox: 'please select some options to proceed.',
                      radio: 'please select any option to proceed.',
                      text: 'please write something to proceed.',
                    }[allqueAns[currQueIndex].type]
                  }
                </h4>
              )}
            </>
          ) : (
            <>
              <div className={css.questionWrapper}>
                <h2>What email address would you like quotes sent to?</h2>
              </div>
              <div className={css.requiredWrap}>
                <code>required *</code>
                <div className={css.optionsWrap}>
                  <input
                    type="email"
                    placeholder="Your Email"
                    onBlur={() =>
                      !/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(contactDetails?.email) &&
                      setShowError('email')
                    }
                    name="email"
                    value={contactDetails?.email}
                    onChange={onChangeFirst}
                  />
                  {showError === 'email' && (
                    <code className={css.errorClass}>enter valid email address</code>
                  )}
                  <code>Near which area you prefer *</code>
                  <LocationInput
                    intl={intl}
                    showLable=""
                    onChange={e => {
                      // e.persist();
                      setContactDetails(prev => ({
                        ...prev,
                        preferedArea: e.search,
                      }));
                    }}
                  />
                  {params && !params?.city && (
                    <>
                      <code>select city *</code>
                      <select
                        className={css.selectCity}
                        name="city"
                        id="city"
                        value={contactDetails.city}
                        onChange={onChangeFirst}
                      >
                        <option value={''}>select city</option>;
                        {cityData.map((val, i) => {
                          return (
                            <option key={i} value={val.key}>
                              {val.label}
                            </option>
                          );
                        })}
                      </select>
                    </>
                  )}
                  {category && params && !params?.slug && (
                    <>
                      <code>select category *</code>
                      <select
                        className={css.selectCity}
                        name="category"
                        id="category"
                        value={contactDetails.category}
                        onChange={onChangeFirst}
                      >
                        <option value={''}>select category</option>;
                        {subCategoryData.map((val, i) => {
                          return (
                            <option key={i} value={val.label}>
                              {val.label}
                            </option>
                          );
                        })}
                      </select>
                    </>
                  )}
                </div>
              </div>
              {/* <div className={css.optionalWrap}>
                <code>optional</code>
                <div className={css.optionsWrap}>
                  <input
                    type="tel"
                    placeholder="Your Whatsapp Number"
                    name="whatsapp"
                    value={contactDetails?.whatsapp}
                    onChange={onChangeFirst}
                  />
                </div>
              </div> */}
              {!canContinue && <h4 className={css.errorText}>* fill the required fields.</h4>}
            </>
          )}

          <div className={css.actionWrapper}>
            <button
              disabled={!canContinue}
              className={classNames(canContinue ? css.btnBlue : css.disabledContinue)}
              // css.btnContinue
              onClick={continueClickHandler}
            >
              {queAnsList?.length - 1 > currQueIndex ? 'Continue' : 'Submit'}
            </button>
            {currQueIndex >= 0 && (
              <button className={css.btnBack} onClick={() => setCurrQueIndex(prev => prev - 1)}>
                Back
              </button>
            )}
          </div>
        </div>
      ) : (
        <div className={css.thankYouWrap}>
          <h3>Congrats your Request is now on The Jobs board where our Coaches can apply</h3>
          <h5>this was for better understanding of your needs.</h5>
          <img src={thankyousvg} alt={'Thank You so much img'} />
          <h3>You will receive Quotes to the provided email</h3>
          <code>"{contactDetails?.email}"</code>
          {/* <code>{contactDetails?.whatsapp ?? ''}</code> */}
          <h3>Your Request is Posted on Jobs Board</h3>
          <button
            className={classNames(css.btnBlue, css.btn)}
            onClick={() => {
              onModalClose();
              if (!isEducationSite) {
                history.push('/jobs-board');
              } else {
                history.push('/education/jobs-board');
              }
            }}
          >
            Have a Look
          </button>
        </div>
      )}
      <Modal
        id={`${modalId}_close_confirm`}
        extraContainerClasses={css.customModal}
        contentClassName={css.customModalContent}
        extraScrollLayerClasses={css.customScrollLayer}
        isOpen={isLeaving}
        onClose={() => {
          setIsLeaving(false);
        }}
        onManageDisableScrolling={() => {}}
      >
        <div className={css.leaveWrap}>
          <h2>Don't Just Leave Yet!</h2>
          <h5>
            {progressPerc.startsWith('0')
              ? banner.desc
              : `You've completed almost ${progressPerc} till now.`}
          </h5>
          <div className={css.leaveActionWrap}>
            <button
              className={css.btnRed}
              onClick={() => {
                onModalClose();
                if (progressPerc.replace('%', '') >= 0 && contactDetails?.email) submitSurvey();
                setIsLeaving(false);
                setCurrQueIndex(-1);
                setIsStarted(false);
              }}
            >
              Leave Anyway
            </button>
            <button className={css.btnBlue} onClick={() => setIsLeaving(false)}>
              Stay
            </button>
          </div>
        </div>
      </Modal>
    </Modal>
  );
};

GetQuoteModalComponent.defaultProps = {
  rootClassName: null,
  className: null,
};

GetQuoteModalComponent.propTypes = {
  rootClassName: string,
  className: string,
  bool,
  intl: intlShape.isRequired,
};

const GetQuoteModal = compose(withRouter, injectIntl)(GetQuoteModalComponent);

export default GetQuoteModal;
