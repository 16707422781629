import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { ensureCURLListings } from '../../util/data';
import { storableError } from '../../util/errors';
import { util as sdkUtil } from '../../util/sdkLoader';
import { formatDateStringToTz, getDefaultTimeZoneOnBrowser } from '../../util/dates';
import { convertUnitToSubUnit, unitDivisor } from '../../util/currency';

import { setInitialState as clearFilter } from '../HeaderContainer/HeaderContainer.duck';
import axios from 'axios';
import omit from 'lodash/omit';
import { LISTING_TYPES } from 'util/constants';
import config from '../../config';
import qs from 'qs';
import { TRANSITION_ENQUIRE } from '../../util/transaction';
import { fetchCurrentUserHasOrdersSuccess } from '../../ducks/user.duck';
// ================ Action types ================ //

export const SET_INITIAL_STATE = 'app/TennisPage/SET_INITIAL_STATE';

export const FETCH_ALL_LISTING_REVIEWS = 'app/TennisPage/FETCH_ALL_LISTING_REVIEWS';

export const QUERY_PROMOTED_LISTINGS_REQUEST = 'app/TennisPage/QUERY_PROMOTED_LISTINGS_REQUEST';
export const QUERY_PROMOTED_LISTINGS_SUCCESS = 'app/TennisPage/QUERY_PROMOTED_LISTINGS_SUCCESS';
export const QUERY_PROMOTED_LISTINGS_ERROR = 'app/TennisPage/QUERY_PROMOTED_LISTINGS_ERROR';

export const SEARCH_LISTINGS_REQUEST = 'app/TennisPage/SEARCH_LISTINGS_REQUEST';
export const SEARCH_LISTINGS_SUCCESS = 'app/TennisPage/SEARCH_LISTINGS_SUCCESS';
export const SEARCH_LISTINGS_ERROR = 'app/TennisPage/SEARCH_LISTINGS_ERROR';
export const ADD_PAGINATION_DATA = 'app/TennisPage/ADD_PAGINATION_DATA';

//Enquiry
export const SEND_ENQUIRY_REQUEST = 'app/TennisPage/SEND_ENQUIRY_REQUEST';
export const SEND_ENQUIRY_SUCCESS = 'app/TennisPage/SEND_ENQUIRY_SUCCESS';
export const SEND_ENQUIRY_ERROR = 'app/TennisPage/SEND_ENQUIRY_ERROR';

// ================ Reducer ================ //

const initialState = {
  queryPromotedListingsError: null,
  promotedListingRefs: [],
  promotedListingReviews: [],
  suggestedListings: [],
  listingAllReviews: {},
  showListingError: null,
  gettingListings: false,
  pagination: {},
  //enquiry
  sendEnquiryError: null,
  sendEnquiryInProgress: false,
};

export default function tennisPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_STATE:
      return { ...initialState };
    case QUERY_PROMOTED_LISTINGS_REQUEST:
      return {
        ...state,
        promotedListingRefs: state.promotedListingRefs,
        queryPromotedListingsError: null,
        gettingListings: true,
      };
    case QUERY_PROMOTED_LISTINGS_SUCCESS:
      return { ...state, promotedListingRefs: payload.listingRefs, gettingListings: false };
    case QUERY_PROMOTED_LISTINGS_ERROR:
      return {
        ...state,
        promotedListingRefs: [],
        queryPromotedListingsError: payload,
        gettingListings: false,
      };
    case SEARCH_LISTINGS_REQUEST:
      return {
        ...state,
        suggestedListings: state.suggestedListings,
        searchListingsError: null,
      };
    case SEARCH_LISTINGS_SUCCESS:
      return { ...state, suggestedListings: payload.listings };
    case SEARCH_LISTINGS_ERROR:
      return { ...state, suggestedListings: [], searchListingsError: payload };

    case FETCH_ALL_LISTING_REVIEWS:
      return { ...state, listingAllReviews: payload };

    case ADD_PAGINATION_DATA:
      return { ...state, pagination: payload };

    //enquiry
    case SEND_ENQUIRY_REQUEST:
      return { ...state, sendEnquiryInProgress: true, sendEnquiryError: null };
    case SEND_ENQUIRY_SUCCESS:
      return { ...state, sendEnquiryInProgress: false };
    case SEND_ENQUIRY_ERROR:
      return { ...state, sendEnquiryInProgress: false, sendEnquiryError: payload };
    default:
      return state;
  }
}

// ================ Action creators ================ //

export const setInitialState = () => ({
  type: SET_INITIAL_STATE,
});

export const allListingReviews = reviewData => ({
  type: FETCH_ALL_LISTING_REVIEWS,
  payload: reviewData,
});

export const queryPromotedListingsRequest = () => ({
  type: QUERY_PROMOTED_LISTINGS_REQUEST,
});

export const queryPromotedListingsSuccess = listingRefs => ({
  type: QUERY_PROMOTED_LISTINGS_SUCCESS,
  payload: { listingRefs },
});

export const addPaginationData = data => ({
  type: ADD_PAGINATION_DATA,
  payload: data,
});
export const queryPromotedListingsError = e => ({
  type: QUERY_PROMOTED_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const searchListingsRequest = () => ({
  type: SEARCH_LISTINGS_REQUEST,
});

export const searchListingsSuccess = listings => ({
  type: SEARCH_LISTINGS_SUCCESS,
  payload: { listings },
});

export const searchListingsError = e => ({
  type: SEARCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

//enquiry
export const sendEnquiryRequest = () => ({ type: SEND_ENQUIRY_REQUEST });
export const sendEnquirySuccess = () => ({ type: SEND_ENQUIRY_SUCCESS });
export const sendEnquiryError = e => ({ type: SEND_ENQUIRY_ERROR, error: true, payload: e });

// ================ Thunks ================ //

const getBounds = listingParams => {
  if (!listingParams.bounds) return undefined;

  const { ne, sw } = listingParams.bounds;
  const { lat: nelat, lng: nelng } = ne;
  const { lat: swlat, lng: swlng } = sw;

  return `${nelat},${nelng},${swlat},${swlng}`;
};

const searchClassListings = listingParams => async (dispatch, getState, sdk) => {
  try {
    const boundsString = getBounds(listingParams);

    const response = await axios.get(config.serverBaseUrl + 'listings', {
      headers: {
        Authorization: `Token token=${config.serverToken}`,
      },
      params: {
        ...omit(listingParams, [
          'fields.listing',
          'fields.user',
          'fields.image',
          'include',
          // 'per_page',
        ]),
        mapSearch: true,
        bounds: boundsString,
        include: 'author,author.profileImage,images',
        'fields.image':
          'variants.scaled-small,variants.square-small2x,variants.square-small,variants.square-small2x',
      },
      paramsSerializer: params => {
        return qs.stringify(params);
      },
    });

    const ensureResponse = ensureCURLListings(response);
    if (response.data.data.length) {
      dispatch(addMarketplaceEntities(ensureResponse));
    }

    // const listingRefs = response.data.data.filter(l => {
    //   const { id, type } = l;
    //   const hide = !!l?.attributes?.metadata?.hidelisting;
    //   if (!hide)
    //     return {
    //       id,
    //       type,
    //     };
    // });

    const listingRefs = response.data.data.filter(
      ({ id, type, attributes }) => !attributes?.metadata?.hidelisting && { id, type }
    );

    // const listingRefs = response.data.data.map(({ id, type }) => ({
    //   id,
    //   type,
    // }));
    dispatch(queryPromotedListingsSuccess(listingRefs));
    return ensureResponse;
  } catch (e) {
    console.log(e);
    dispatch(queryPromotedListingsError(storableError(e)));
    throw e;
  }
};
export const queryPromotedListingsTen = (searchquery = {}) => (dispatch, getState, sdk) => {
  dispatch(queryPromotedListingsRequest());
  const availabilityParams = (datesParam, minDurationParam, timesParam) => {
    const dateValues = datesParam ? datesParam.split(',') : [];
    const hasDateValues = datesParam && dateValues.length === 2;
    const timeValues = timesParam ? timesParam.split(',') : [];
    const hasTimeValues = timeValues && timeValues.length === 2;
    const startDate =
      hasDateValues && hasTimeValues
        ? `${dateValues[0]} ${timeValues[0]}`
        : hasDateValues
        ? dateValues[0]
        : null; //"2020-06-12 12:00"//;
    const endDate =
      hasDateValues && hasTimeValues
        ? `${dateValues[1]} ${timeValues[1]}`
        : hasDateValues
        ? dateValues[1]
        : null; //"2020-06-12 13:00"//;
    const minDurationMaybe =
      minDurationParam && Number.isInteger(minDurationParam) && hasDateValues
        ? { minDuration: minDurationParam }
        : {};
    const defaultTimeZone = () =>
      typeof window !== 'undefined'
        ? getDefaultTimeZoneOnBrowser()
        : config.custom.dateRangeLengthFilterConfig.searchTimeZone;
    const timeZone = defaultTimeZone();
    return hasDateValues
      ? {
          start: searchquery.start ? searchquery.start : formatDateStringToTz(startDate, timeZone),
          end: searchquery.end ? searchquery.end : formatDateStringToTz(endDate, timeZone),
          // When we have `time-partial` value in the availability, the
          // API returns listings that don't necessarily have the full
          // start->end range available, but enough that the minDuration
          // (in minutes) can be fulfilled.
          //
          // See: https://www.sharetribe.com/api-reference/marketplace.html#availability-filtering
          availability: 'time-partial',
          ...minDurationMaybe,
        }
      : {};
  };
  const priceSearchParams = priceParam => {
    const inSubunits = value =>
      convertUnitToSubUnit(value, unitDivisor(config.currencyConfig.currency));
    const values = priceParam ? priceParam.split(',') : [];
    return priceParam && values.length === 2
      ? {
          price: [inSubunits(values[0]), inSubunits(values[1]) + 1].join(','),
        }
      : {};
  };
  const getParams = () => {
    const {
      dates,
      minDuration,
      times,
      price,
      pub_sub_category,
      pub_category,
      start,
      end,
      pub_kids_promotions,
      pub_listing_type,
      pub_listing_mode,
      ...rest
    } = searchquery;
    const availabilityMaybe = availabilityParams(dates, minDuration, times);
    const priceMaybe = priceSearchParams(price);
    const subcategory = pub_sub_category ? { pub_sub_category: `has_any:${pub_sub_category}` } : {};
    const publicCategory = pub_category ? { pub_category: pub_category } : {};
    const listingModeMaybe = !!pub_listing_mode ? { pub_listing_mode: pub_listing_mode } : {};
    return {
      ...rest,
      ...availabilityMaybe,
      ...priceMaybe,
      ...subcategory,
      ...publicCategory,
      ...listingModeMaybe,
      pub_listing_type: pub_listing_type || 'player-player',
      mapSearch: true,
      include: ['author', 'author.profileImage', 'images'],
      'fields.image': [
        'variants.scaled-small',
        'variants.scaled-small2x',
        'variants.scaled-medium',
        // Avatars
        'variants.square-small',
        'variants.square-small2x',
        'variants.company-background-crop',
      ],
      'imageVariant.company-background-crop': sdkUtil.objectQueryString({
        w: 1920,
        h: 473,
        fit: 'crop',
      }),
      'limit.images': 6,
      'page.limit': 50,
      sort: 'meta_promoted,createdAt',
    };
  };
  const params = getParams();
  // if (params.pub_listing_type === LISTING_TYPES.PLAYER_PROFILE) {
  //   return dispatch(searchClassListings(params));
  // } else {

  return sdk.listings
    .query(params)
    .then(response => {
      // const listingRefs = response.data.data.filter(l => {
      //   const { id, type } = l;
      //   const hide = !!l?.attributes?.metadata?.hidelisting;
      //   if (!hide)
      //     return {
      //       id,
      //       type,
      //     };
      // });

      const listingRefs = response.data.data.filter(
        ({ id, type, attributes }) => !attributes?.metadata?.hidelisting && { id, type }
      );
      // const listingCompanyRefs = response.data.data.map(({ id, type, relationships }) => ({
      //   id,
      //   type,
      //   authorId: relationships.author.data.id.uuid,
      // }));
      dispatch(addMarketplaceEntities(response));
      dispatch(queryPromotedListingsSuccess(listingRefs));
      dispatch(addPaginationData(response.data.meta));

      // dispatch(fetchAllCompanyReviews(listingCompanyRefs));
      // if (searchquery?.pub_listing_type === 'company') {
      //   dispatch(fetchAllCompanyReviews(listingCompanyRefs));
      // }
      if (!searchquery) {
        dispatch(clearFilter());
      }
      // Fetch reviews
      // response.data.data.map(({id}) => { dispatch(queryPromotedListingReviews(id.uuid)) })
      return response;
    })
    .catch(e => dispatch(queryPromotedListingsError(storableError(e))));
  // }
};

export const showListingTen = listingId => (dispatch, getState, sdk) => {
  dispatch(queryPromotedListingsRequest());
  dispatch(clearFilter());
  return sdk.listings
    .show({
      id: listingId,
      include: ['author', 'author.profileImage', 'images'],
      'fields.image': [
        'variants.scaled-small',
        'variants.square-small2x',

        // Avatars
        'variants.square-small',
        'variants.square-small2x',
      ],
      'limit.images': 6,
      'page.limit': 50,
      sort: 'meta_promoted,createdAt',
    })
    .then(response => {
      const res = [response.data];
      const listingRefs = res.map(r => ({
        id: r.data.id,
        type: r.data.type,
      }));
      // const listingCompanyRefs = res.map(r => ({
      //   id: r.data.id,
      //   type: r.data.type,
      //   authorId: r.data.relationships.author.data.id.uuid,
      // }));

      dispatch(addMarketplaceEntities(response));
      dispatch(queryPromotedListingsSuccess(listingRefs));
      // dispatch(fetchAllCompanyReviews(listingCompanyRefs));

      return response;
    })
    .catch(e => dispatch(queryPromotedListingsError(storableError(e))));
};

export const onSearchEnquiry = searchParams => (dispatch, getState, sdk) => {
  // dispatch(queryPromotedListingsRequest());
  const subcategory = searchParams.pub_sub_category
    ? { pub_sub_category: `has_any:${searchParams.pub_sub_category}` }
    : {};
  const params = {
    ...searchParams,
    ...subcategory,
    mapSearch: true,
    include: ['author', 'author.profileImage', 'images'],
    'fields.image': [
      'variants.scaled-small',
      'variants.square-small2x',
      // Avatars
      'variants.square-small',
      'variants.square-small2x',
      'variants.company-background-crop',
    ],
    'imageVariant.company-background-crop': sdkUtil.objectQueryString({
      w: 1920,
      h: 473,
      fit: 'crop',
    }),
    'limit.images': 6,
    'page.limit': 50,
    sort: 'meta_promoted,createdAt',
  };

  sdk.listings
    .query({ ...params })
    .then(response => {
      // const listingRefs = response.data.data.filter(l => {
      //   const { id, type } = l;
      //   const hide = !!l?.attributes?.metadata?.hidelisting;
      //   if (!hide)
      //     return {
      //       id,
      //       type,
      //     };
      // });

      const listingRefs = response.data.data.filter(
        ({ id, type, attributes }) => !attributes?.metadata?.hidelisting && { id, type }
      );
      dispatch(addMarketplaceEntities(response));
      dispatch(queryPromotedListingsSuccess(listingRefs));
      return response;
    })
    .catch(e => dispatch(queryPromotedListingsError(storableError(e))));
};

export const searchListings = searchParams => (dispatch, getState, sdk) => {
  dispatch(searchListingsRequest());
  return sdk.listings
    .query(searchParams)
    .then(response => {
      // const listings = response.data.data.map(({ id, attributes }) => ({
      //   id,
      //   title: attributes.title,
      // }));

      // const listingRefs = response.data.data.filter(l => {
      //   const { id } = l;
      //   const hide = !!l?.attributes?.metadata?.hidelisting;
      //   if (!hide)
      //     return {
      //       id,
      //       title: l.attributes.title,
      //     };
      // });

      const listingRefs = response.data.data.filter(
        ({ id, type, attributes }) =>
          !attributes?.metadata?.hidelisting && { id, type, title: attributes.title }
      );

      dispatch(addMarketplaceEntities(response));
      dispatch(searchListingsSuccess(listingRefs));
      return response;
    })
    .catch(e => {
      dispatch(searchListingsError(e));
      throw e;
    });
};

//for enquiry
export const sendEnquiry = (listingId, message) => (dispatch, getState, sdk) => {
  dispatch(sendEnquiryRequest());
  const bodyParams = {
    transition: TRANSITION_ENQUIRE,
    processAlias: config.bookingProcessAlias,
    params: { listingId },
  };

  return sdk.transactions
    .initiate(bodyParams)
    .then(response => {
      const transactionId = response.data.data.id;

      // Send the message to the created transaction
      return sdk.messages.send({ transactionId, content: message }).then(res => {
        dispatch(sendEnquirySuccess());
        dispatch(fetchCurrentUserHasOrdersSuccess(true));
        return transactionId;
      });
    })
    .catch(e => {
      dispatch(sendEnquiryError(storableError(e)));
      throw e;
    });
};

export const loadData = () => (dispatch, getState, sdk) => {
  // Clear state so that previously loaded data is not visible
  // in case this page load fails.
  const currentState = getState().HeaderSection;
  if (
    currentState.searchLocation?.search ||
    currentState.searchActivity ||
    currentState.searchSelectedRating
  )
    return Promise.resolve();

  dispatch(setInitialState());
  dispatch(clearFilter());

  return Promise.all([
    dispatch(
      queryPromotedListingsTen({
        // pub_listing_mode: 'Online,online',
        pub_listing_type: 'player-profile',
      })
    ),
  ]);
  // return Promise.all([dispatch(queryPromotedListings()), dispatch(fetchCompanies())]);
};
