import React, { useEffect, useState } from 'react';
import css from './RecommendedListCard.css';
import { Button } from 'components';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import {
  getClientTransactions,
  getRecommendationData,
  onAcceptRequestAdmin,
  onAcceptRequestRecommender,
  onRewardRequestAdmin,
  onRewardRequestRecommender,
} from 'containers/HomePage/HomePage.duck';

const RecommendedListCard = props => {
  const { currentUser } = useSelector(state => state.user);

  const { recommendedData } = props;

  const [count, setCount] = useState(null);
  const [totalPayinAmount, setTotalPayinAmount] = useState(null);
  const [tenPercent, setTenPercent] = useState(null);

  const clientId = recommendedData.clientId;
  const startDate = recommendedData.startDate;
  const status = recommendedData.status;
  const currentUserId = currentUser.id.uuid;

  const recommendedDate = new Date(recommendedData.startDate);

  const options = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  };
  const formattedRecommendedDate = new Intl.DateTimeFormat('en-US', options).format(
    recommendedDate
  );

  const today = new Date();

  const optionsPresent = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  };
  const presentDate = new Intl.DateTimeFormat('en-US', optionsPresent).format(today);

  // Get UTC hours for Singapore time zone to get transaction data
  const singaporeTimeOffset = 8; // Singapore is UTC+8
  const singaporeTimeHours = today.getUTCHours() + singaporeTimeOffset;

  // Adjust the UTC hours for Singapore time zone
  today.setUTCHours(singaporeTimeHours);

  // Format for today's date in "2023-12-08T18:00:10.857Z" in Asia/Singapore time zone for query transaction
  const formattedPresentDate = today.toISOString();

  const getListingData = async () => {
    try {
      const { count, totalPayinAmount } = await dispatch(
        getClientTransactions(clientId, startDate, formattedPresentDate)
      );
      // Now you can use the count value here
      setCount(count);
      setTotalPayinAmount(totalPayinAmount);

      const calculatedFivePercent = (totalPayinAmount * 0.1) / 100;
      // Set decimal value to first two digits
      const roundedFivePercent = +calculatedFivePercent.toFixed(2);
      setTenPercent(roundedFivePercent);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    getListingData();
  }, []);

  const handleRequestClick = async () => {
    // Dispatch an action to indicate that the API request is in progress
    await dispatch(onRewardRequestAdmin(clientId, tenPercent, formattedPresentDate));
    await dispatch(
      onRewardRequestRecommender(currentUserId, clientId, tenPercent, formattedPresentDate)
    );
    await dispatch(getRecommendationData());
    await getListingData();
  };

  const handleAcceptClick = async () => {
    // Dispatch an action to indicate that the API request is in progress
    await dispatch(onAcceptRequestAdmin(clientId));
    await dispatch(onAcceptRequestRecommender(currentUserId, clientId));
    await dispatch(getRecommendationData());
    await getListingData();
  };

  const dispatch = useDispatch();

  return (
    <div className={css.jobCardWrap}>
      <div className={css.jobCardHead}>
        <div>
          <img className={css.matchCardImg} src={recommendedData.clientImage} />
          <h5 className={css.cardHead}>{recommendedData.clientName}</h5>
          <p className={css.detailDesc}>{recommendedData.clientEmail}</p>
        </div>
      </div>
      <div className={css.playersDetailWrap}>
        <div className={css.playingDetail}>
          Transactions From: {formattedRecommendedDate} to {presentDate}
        </div>
      </div>
      <div className={css.playerTime}>
        <img src="https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1702460030/probuddy/static/images/HomePage/transaction_tmdc4f.png" />
        <div className={css.timewrap}>
          <h6 className={css.timetext}>Total transactions: {count}</h6>
          <span className={css.timeLabel}>Total amount: SGD {totalPayinAmount / 100}</span>
        </div>
      </div>
      <div className={css.playerLocation}>
        <img src="https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1702459932/probuddy/static/images/HomePage/reward_f4bpqi.png" />
        <div className={css.locationText}>
          <h6 className={css.locationTextHead}>Your Rewards</h6>
          <span className={css.locationValue}>SGD {tenPercent}</span>
        </div>
      </div>
      <div className={css.cardButtons}>
        {status !== 'pending' && (
          <Button className={css.joinBtn} onClick={handleRequestClick}>
            Request
          </Button>
        )}
        {status === 'pending' && (
          <h6 className={css.detailLocation}>Your requested reward is in process. Accepted?</h6>
        )}
        {status === 'pending' && (
          <Button className={css.cardBtn} onClick={handleAcceptClick}>
            Accepted
          </Button>
        )}
      </div>
    </div>
  );
};

export default RecommendedListCard;
