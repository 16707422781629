import React from 'react';
import { BookingTimeForm } from '../../forms';
import Dropzone from 'react-dropzone';
import {
  Modal,
  NamedLink,
  IconClose,
  ResponsiveImage,
  AvatarMedium,
  IconStar,
  IconCheckedShield,
} from '../../components';
import { ensureUser } from '../../util/data';
import { FormattedMessage } from '../../util/reactIntl';
import config from '../../config';
import { Packages } from 'components/PackagesModal/Packages';
import { PAYMENT_TYPES } from 'util/constants';
import IconMembership from 'components/IconMembership/IconMembership';
import css from './PackagesModal.css';
import cns from 'classnames';
import { types as sdkTypes } from '../../util/sdkLoader';
import VisaIcon from '../../assets/CheckoutPage/Visa_Icon.svg';
import MasterCardIcon from '../../assets/CheckoutPage/MasterCard_Icon.svg';
import AmericanExpressIcon from '../../assets/CheckoutPage/AmericanExpress_Icon.svg';
import PaypalIcon from '../../assets/CheckoutPage/Paypal_Icon.svg';
import JCBIcon from '../../assets/CheckoutPage/JCB_Icon.svg';
import HitPayIcon from '../../assets/CheckoutPage/HitPay_Icon.svg';
import BankIcon from '../../assets/CheckoutPage/Bank_Icon.svg';
import PayNowIcon from '../../assets/CheckoutPage/PayNow_Icon.svg';
import { PrimaryButton2 } from 'components/Button/Button';

const TODAY = new Date();
const dateFormattingOptions = { month: 'short', day: 'numeric', weekday: 'short' };
const { Money } = sdkTypes;

class PackagesModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedPackageParams: {},
      selectedPaymentType: null,
      currentUserData: {},
      step: 1,
    };

    this.membershipCardRef = React.createRef();
    this.nameRef = React.createRef();
    this.commentRef = React.createRef();
    this.handleBuy = this.handleBuy.bind(this);
    this.handelPaymentTypeSelecting = this.handelPaymentTypeSelecting.bind(this);
    this.handelStepChange = this.handelStepChange.bind(this);
    this.handelStepBack = this.handelStepBack.bind(this);
    this.handelStepSkip = this.handelStepSkip.bind(this);
    this.handelPackagePayment = this.handelPackagePayment.bind(this);
    this.packageCallback = this.packageCallback.bind(this);
  }

  handelStepChange(step) {
    this.setState({ step });
  }
  handelStepSkip() {
    this.handelStepChange(this.state.step + 1);
  }
  handelPaymentTypeSelecting(paymentType) {
    this.setState({ selectedPaymentType: paymentType });
    this.handelStepChange(3);
  }
  handelStepBack(step) {
    if (this.state.step > step) this.setState({ step });
  }

  handelPackagePayment(params) {
    let withPayment = '';
    params['paymentType'] = this.state.selectedPaymentType;
    const membershipCardValue = this.membershipCardRef.current
      ? this.membershipCardRef.current.value
      : null;
    if (membershipCardValue) {
      localStorage.setItem('membershipNumber', membershipCardValue);
      params['membership_card_number'] = membershipCardValue;
    }

    const nameValue = this.nameRef.current ? this.nameRef.current.value : null;

    if (nameValue) {
      params['student_name'] = nameValue;
    }

    const commentValue = this.commentRef.current ? this.commentRef.current.value : null;

    if (commentValue) {
      params['comment'] = commentValue;
    }
    if (this.state.selectedPaymentType === PAYMENT_TYPES.card) {
      withPayment = true;
      const applicationFeeAmount = params.amount * config.creditCardExtraFee;
      params['application_fee_amount'] = applicationFeeAmount;
      params['amount'] = params['amount'] + applicationFeeAmount;
    } else if (this.state.selectedPaymentType === PAYMENT_TYPES.hitpay) {
      withPayment = true;
      params['amount'] = params['amount'];
    } else {
      withPayment = false;
    }
    this.props.handlePackageBuy(params, withPayment, this.packageCallback);
  }

  packageCallback() {
    try {
      const { currentUser, listing } = this.props;
      const { selectedPackageParams } = this.state;
      let currentUserData = { ...currentUser };
      let listingId = listing.id.uuid;
      let prevcredits =
        currentUserData &&
        currentUserData.attributes.profile.privateData.sessions &&
        currentUserData.attributes.profile.privateData.sessions[listingId]
          ? currentUserData.attributes.profile.privateData.sessions[listingId]
          : 0;
      let credits = Number(selectedPackageParams && selectedPackageParams.credits);
      currentUserData.attributes.profile.privateData.sessions
        ? (currentUserData.attributes.profile.privateData.sessions[listingId] =
            credits + prevcredits)
        : (currentUserData.attributes.profile.privateData.sessions = {
            [listingId]: credits + prevcredits,
          });
      this.setState({ currentUserData });
      this.handelStepChange(this.state.step + 1);
    } catch (error) {
      console.log(error);
    }
  }

  handleBuy(params) {
    this.setState({ selectedPackageParams: params });
    this.handelStepChange(this.state.step + 1);
  }

  renderBackDetails = bankDetails =>
    bankDetails ? (
      <div>
        <h3>
          <FormattedMessage id="withoutPaymentForm.bankDetails" />
        </h3>
        <p className={css.bankDetails}>{bankDetails}</p>
      </div>
    ) : null;

  renderPackages = () => {
    const { intl, listing, buyerId, sellerId, packageAttr, creditRequestInProgress } = this.props;
    const { packages } = listing.attributes.publicData;
    const currency =
      listing?.attributes?.publicData?.currency ||
      listing?.attributes?.price?.currency ||
      config.currency;

    return (
      <div className={css.packageSection}>
        <Packages
          intl={intl}
          listing={listing}
          buyerId={buyerId}
          packageAttr={packageAttr}
          showInline={true}
          packages={packages}
          sellerId={sellerId}
          withPayment={
            this.state.selectedPaymentType === PAYMENT_TYPES.card ||
            this.state.selectedPaymentType === PAYMENT_TYPES.hitpay
          }
          handleBuy={this.handleBuy}
          creditRequestInProgress={creditRequestInProgress}
          currency={currency}
        />
      </div>
    );
  };

  renderPurchaseWithCard = isFeeshow => {
    const studentNamePlaceholder = this.props.intl.formatMessage({
      id: 'PackagesModal.studentNamePlaceholder',
    });
    const commentPlaceholder = this.props.intl.formatMessage({
      id: 'PackagesModal.commentPlaceholder',
    });
    return (
      <>
        {/* <h1>Choose how many credits you'd like to buy</h1> */}
        {isFeeshow && (
          <p className={css.creditFeeText}>By paying with credit card extra 4% fee is applied</p>
        )}
        <div>
          <input
            type="text"
            placeholder={studentNamePlaceholder}
            className={css.membershipCard}
            ref={this.nameRef}
          />
        </div>
        {/* <div>
          <input
            type="text"
            placeholder={commentPlaceholder}
            className={css.membershipCard}
            ref={this.commentRef}
          />
        </div> */}
      </>
    );
  };

  renderPurchaseWithDirect = () => {
    const { listing, paymentProofSsUrls, onDrop, onRemoveFile } = this.props;
    const { bankDetails } = listing.attributes.publicData;
    const studentNamePlaceholder = this.props.intl.formatMessage({
      id: 'PackagesModal.studentNamePlaceholder',
    });
    const commentPlaceholder = this.props.intl.formatMessage({
      id: 'PackagesModal.commentPlaceholder',
    });
    return (
      <>
        <div className={css.paymentProofsContainer}>
          <div>
            <input
              type="text"
              placeholder={studentNamePlaceholder}
              className={css.membershipCard}
              ref={this.nameRef}
            />
          </div>
          <div>
            <input
              type="text"
              placeholder={commentPlaceholder}
              className={css.membershipCard}
              ref={this.commentRef}
            />
          </div>

          {this.renderBackDetails(bankDetails)}

          <section className={css.shareReceiptContainer}>
            <div className={css.shareReceiptWrapper}>
              <h4>
                <FormattedMessage id={'PackagesModal.shareTransferReceipt'} />
              </h4>
              <span className={css.vendorRequiredInfo}>
                <FormattedMessage id={'PackagesModal.youShareVendorReceipt'} />
              </span>
              <p className={css.receiptInfo}>
                <FormattedMessage id={'PackagesModal.saveProsessingFee’s'} />
              </p>
            </div>
            <div className={css.shareReceiptBtnContainer}>
              <Dropzone onDrop={onDrop} accept="image/*,application/pdf,.doc,.docx">
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps({ className: css.shareReceiptBtnWrapper })}>
                    <input {...getInputProps()} id="payment-dropzone" />
                    <div className={css.shareReceiptBtn}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="26"
                        height="29"
                        viewBox="0 0 26 29"
                        fill="none"
                      >
                        <path
                          d="M21.3536 20.2151C22.4674 20.2151 23.4098 20.622 24.1808 21.4359C24.9519 22.207 25.3374 23.128 25.3374 24.1989C25.3374 25.3126 24.9305 26.2765 24.1166 27.0904C23.3455 27.8614 22.4245 28.247 21.3536 28.247C20.2827 28.247 19.3403 27.8614 18.5264 27.0904C17.7553 26.2765 17.3698 25.3126 17.3698 24.1989C17.3698 23.7705 17.3912 23.4707 17.434 23.2993L7.73145 17.6448C6.91755 18.3731 5.97514 18.7372 4.90421 18.7372C3.79045 18.7372 2.82662 18.3302 2.01272 17.5163C1.19882 16.7024 0.791866 15.7386 0.791866 14.6248C0.791866 13.5111 1.19882 12.5472 2.01272 11.7333C2.82662 10.9194 3.79045 10.5125 4.90421 10.5125C5.97514 10.5125 6.91755 10.8766 7.73145 11.6048L17.3698 6.01461C17.2841 5.58624 17.2413 5.26496 17.2413 5.05078C17.2413 3.93701 17.6482 2.97318 18.4621 2.15928C19.276 1.34538 20.2398 0.938428 21.3536 0.938428C22.4674 0.938428 23.4312 1.34538 24.2451 2.15928C25.059 2.97318 25.4659 3.93701 25.4659 5.05078C25.4659 6.16454 25.059 7.12837 24.2451 7.94227C23.4312 8.75617 22.4674 9.16312 21.3536 9.16312C20.3255 9.16312 19.3831 8.77759 18.5264 8.00652L8.88805 13.661C8.97372 14.0894 9.01656 14.4106 9.01656 14.6248C9.01656 14.839 8.97372 15.1603 8.88805 15.5887L18.6549 21.2431C19.4259 20.5577 20.3255 20.2151 21.3536 20.2151Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                  </div>
                )}
              </Dropzone>
            </div>
          </section>

          {paymentProofSsUrls?.length > 0 ? (
            <div className={css.paymentProofsContainer}>
              <h2>
                <FormattedMessage id="PackagesModal.paymentScreenshots" />
              </h2>
              <div className={css.paymentProofSsContainer}>
                {paymentProofSsUrls.map((ss, index) => {
                  return (
                    <div style={{ position: 'relative' }}>
                      <a
                        key={ss.public_id}
                        // eslint-disable-next-line
                        target="_blank"
                        href={ss?.secure_url}
                      >
                        <div className={css.paymentProofSs}>
                          {ss.resource_type === 'image' && (
                            <img src={ss.secure_url} alt={ss.public_id} />
                          )}
                          {ss.resource_type === 'raw' && <p>{ss.public_id}</p>}
                        </div>
                      </a>
                      <div className={css.removeItem} onClick={onRemoveFile(index)}>
                        <IconClose size="normal" className={css.removeIcon} />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : null}
        </div>
      </>
    );
  };

  renderPurchaseWithMembership = () => {
    const { intl } = this.props;
    const prevMembershipNumber = localStorage.getItem('membershipNumber');
    const studentNamePlaceholder = intl.formatMessage({
      id: 'PackagesModal.studentNamePlaceholder',
    });
    const commentPlaceholder = intl.formatMessage({
      id: 'PackagesModal.commentPlaceholder',
    });
    const membershipCardPlaceholder = intl.formatMessage({
      id: 'PackagesModal.membershipCardPlaceholder',
    });
    return (
      <>
        <div>
          <input
            type="text"
            placeholder={studentNamePlaceholder}
            className={css.membershipCard}
            ref={this.nameRef}
          />
        </div>
        <div>
          <input
            type="text"
            placeholder={commentPlaceholder}
            className={css.membershipCard}
            ref={this.commentRef}
          />
        </div>
        <div>
          <input
            type="text"
            placeholder={membershipCardPlaceholder}
            className={css.membershipCard}
            defaultValue={prevMembershipNumber ? prevMembershipNumber : ''}
            ref={this.membershipCardRef}
          />
        </div>
      </>
    );
  };

  handleSelectTypeClick = paymentType => () => {
    const { handlePaymentTypeSelect } = this.props;

    handlePaymentTypeSelect(paymentType === PAYMENT_TYPES.card);

    this.setState({
      selectedPaymentType: paymentType,
    });
  };

  handleBackClick = () => {
    this.setState({ selectedPackageParams: {} });
  };

  render() {
    const {
      containerClassName,
      handlePackagesModalClose,
      closeButtonMessage,
      onManageDisableScrolling,
      showPackages,
      listing,
      intl,
      isOwnListing,
      currentUser,
      monthlyTimeSlots,
      onFetchTimeSlots,
      handlePackagesModalOpen,
      isProduct,
      unitType,
      paymentProofRequiredError,
      onSubmit,
      reviews = [],
      hostLanguages,
    } = this.props;

    const price = listing.attributes.price;
    const selectedPaymentMethod =
      listing.attributes.publicData.payment_method &&
      listing.attributes.publicData.payment_method.length !== 0
        ? listing.attributes.publicData.payment_method
        : ['cash', 'card', 'member', 'bank', 'hitpay'];

    const { selectedPaymentType, selectedPackageParams, step } = this.state;
    const priceAddons = listing.attributes.publicData
      ? listing.attributes.publicData.priceAddons
      : [];

    const formattedPriceAddons =
      priceAddons &&
      priceAddons.map(i => ({
        label: i.label,
        price: new Money(i.amount, i.currency),
      }));

    const discountPriceAddons = listing.attributes.publicData
      ? listing.attributes.publicData.discountPriceAddons
      : [];

    const formattedDiscountPriceAddons =
      discountPriceAddons &&
      discountPriceAddons.map(i => ({
        label: i.label,
        price: new Money(i.amount, i.currency),
      }));

    const handleSubmit = values => {
      if (values.withPayment && listing.attributes.publicData) {
        values.payment_method = listing.attributes.publicData.payment_method || [];
      }

      if (values.withCredits && !values.seats) {
        values.seats = 1;
      }
      const selectedPriceAddons =
        values &&
        values.additionalItems &&
        priceAddons.filter(p => values.additionalItems.find(i => i === p.label));

      const selectedDiscountPriceAddons =
        values &&
        values.discountAdditionalItems &&
        discountPriceAddons.filter(p => values.discountAdditionalItems.find(i => i === p.label));

      onSubmit({
        ...values,
        priceAddons: selectedPriceAddons,
        discountPriceAddons: selectedDiscountPriceAddons,
      });
    };

    const listingTitle = listing.attributes.title;
    const timeZone =
      listing.attributes.availabilityPlan && listing.attributes.availabilityPlan.timezone;
    const firstImage = listing.images && listing.images.length > 0 ? listing.images[0] : null;
    const currentAuthor = ensureUser(listing.author);
    return (
      <Modal
        extraScrollLayerClasses={css.customScrollLayer}
        id="Packages"
        containerClassName={containerClassName}
        isOpen={showPackages}
        onClose={handlePackagesModalClose}
        closeButtonMessage={closeButtonMessage}
        onManageDisableScrolling={onManageDisableScrolling}
        contentClassName={css.modalContent}
      >
        <div className={css.stepContainer}>
          <div className={css.bookListingContainer}>
            <div className={css.steps}>
              <div className={cns(css.mdstepperhorizontal, css.activeColor)}>
                <div
                  className={cns(
                    css.mdstep,
                    (step === 1 || step > 1) && css.active,
                    step > 1 && css.done
                  )}
                >
                  <div className={css.mdstepcircle}>{step > 1 ? '✓' : 1}</div>
                  <div className={css.mdsteptitle}>Choose Package</div>
                  <div className={css.mdstepbarleft}></div>
                  <div className={css.mdstepbarright}></div>
                </div>
                <div
                  className={cns(
                    css.mdstep,
                    (step === 2 || step > 2) && css.active,
                    step > 2 && css.done
                  )}
                >
                  <div className={css.mdstepcircle}>{step > 2 ? '✓' : 2}</div>
                  <div className={css.mdsteptitle}>
                    <FormattedMessage id={'PaymentTypeSelect.heading'} />
                  </div>
                  <div className={css.mdstepbarleft}></div>
                  <div className={css.md2stepbarright}></div>
                </div>
                <div
                  className={cns(
                    css.mdstep,
                    (step === 3 || step > 3) && css.active,
                    step > 3 && css.done
                  )}
                >
                  <div className={css.mdstepcircle}>{step > 3 ? '✓' : 3}</div>
                  <div className={css.mdsteptitle}>
                    {' '}
                    <FormattedMessage id={'PaymentTypeSelect.confirmPayment'} />
                  </div>
                  <div className={css.mdstepbarleft}></div>
                  <div className={css.mdstepbarright}></div>
                </div>
                <div
                  className={cns(
                    css.mdstep,
                    (step === 4 || step > 4) && css.active,
                    step > 4 && css.done
                  )}
                >
                  <div className={css.mdstepcircle}>{step > 4 ? '✓' : 4}</div>
                  <div className={css.mdsteptitle}>
                    <FormattedMessage id={'PaymentTypeSelect.recurringTimeDay'} />
                  </div>
                  <div className={css.mdstepbarleft}></div>
                  <div className={css.mdstepbarright}></div>
                </div>
              </div>
            </div>

            <div className={css.stepContentContainer}>
              {this.state.step === 1 && (
                <div className={css.stepSection}>
                  <h3 className={css.stepTitleChoosePackage}>
                    <FormattedMessage id={'PackagesModal.choosePackage'} />
                  </h3>
                  {this.renderPackages()}
                </div>
              )}

              {this.state.step === 2 && (
                <div className={css.stepSection}>
                  <div className={css.paymentType}>
                    <h3 className={css.stepTitle}>
                      <FormattedMessage id={'PaymentTypeSelect.heading'} />
                    </h3>
                    <div className={css.options}>
                      {selectedPaymentMethod.includes('card') && (
                        <button
                          type="button"
                          className={
                            this.state.selectedPaymentType === PAYMENT_TYPES.card
                              ? css.selectedPaymentOptionItem
                              : css.optionItem
                          }
                          onClick={() => this.handelPaymentTypeSelecting(PAYMENT_TYPES.card)}
                        >
                          <div className={css.label}>
                            <FormattedMessage
                              id={'PaymentTypeSelect.cardPaymentLabel'}
                              tagName="p"
                            />
                          </div>

                          <ul className={css.icons}>
                            <li>
                              <img src={VisaIcon} alt="VisaIcon" />
                            </li>
                            <li>
                              <img src={MasterCardIcon} alt="MasterCardIcon" />
                            </li>
                            <li>
                              <img src={AmericanExpressIcon} alt="AmericanExpressIcon" />
                            </li>
                            <li>
                              <img src={PaypalIcon} alt="PaypalIcon" />
                            </li>
                            <li>
                              <img src={JCBIcon} alt="JCBIcon" />
                            </li>
                          </ul>
                        </button>
                      )}

                      {selectedPaymentMethod.includes('bank') && (
                        <button
                          type="button"
                          className={
                            this.state.selectedPaymentType === PAYMENT_TYPES.direct
                              ? css.selectedPaymentOptionItem
                              : css.optionItem
                          }
                          onClick={() => this.handelPaymentTypeSelecting(PAYMENT_TYPES.direct)}
                        >
                          <div className={css.label}>
                            <FormattedMessage
                              id={'PaymentTypeSelect.directPaymentLabel'}
                              values={{ br: <br /> }}
                              tagName="p"
                            />
                          </div>

                          <ul className={css.icons}>
                            <li>
                              <img src={BankIcon} alt="BankIcon" />
                            </li>
                            <li>
                              <img src={PayNowIcon} alt="PayNowIcon" />
                            </li>
                          </ul>
                        </button>
                      )}
                      {selectedPaymentMethod.includes('member') && (
                        <button
                          type="button"
                          className={
                            this.state.selectedPaymentType === PAYMENT_TYPES.membership
                              ? css.selectedPaymentOptionItem
                              : css.optionItem
                          }
                          onClick={() => this.handelPaymentTypeSelecting(PAYMENT_TYPES.membership)}
                        >
                          <div className={css.icons}>
                            <IconMembership />
                          </div>
                          <div className={css.label}>
                            <FormattedMessage
                              id={'PaymentTypeSelect.membershipPaymentLabel'}
                              tagName="p"
                            />
                          </div>
                        </button>
                      )}
                      {(selectedPaymentMethod.includes('hitPay') ||
                        selectedPaymentMethod.includes('hitpay')) && (
                        <button
                          type="button"
                          className={
                            this.state.selectedPaymentType === PAYMENT_TYPES.hitpay
                              ? css.selectedPaymentOptionItem
                              : css.optionItem
                          }
                          onClick={() => this.handelPaymentTypeSelecting(PAYMENT_TYPES.hitpay)}
                        >
                          <div className={css.label}>
                            <FormattedMessage id={'PaymentTypeSelect.hitpayLabel'} tagName="p" />
                          </div>
                          <ul className={css.icons}>
                            <li>
                              <img src={HitPayIcon} alt="HitPayIcon" />
                            </li>
                          </ul>
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              )}

              {this.state.step === 3 && (
                <div className={css.paymentFormSection}>
                  <div className={css.bookingFormContainer}>
                    <h3 className={css.stepTitle}>
                      <FormattedMessage id={'PaymentTypeSelect.confirmPayment'} />
                    </h3>
                    {selectedPaymentType === PAYMENT_TYPES.card &&
                      this.renderPurchaseWithCard(true)}
                    {selectedPaymentType === PAYMENT_TYPES.direct &&
                      this.renderPurchaseWithDirect()}
                    {selectedPaymentType === PAYMENT_TYPES.membership &&
                      this.renderPurchaseWithMembership()}
                    {
                      <div className={css.heading}>
                        {selectedPaymentType === PAYMENT_TYPES.hitpay && (
                          <>
                            <span className={css.infoSpan}>
                              For mobile phone please Screenshot the QR, go to the banking app and
                              use the from your album option to upload the QR
                            </span>
                            {this.renderPurchaseWithCard(false)}
                          </>
                        )}
                      </div>
                    }
                    <span className={css.errorMessage}>{paymentProofRequiredError}</span>
                    <div className={css.buttonContainer}>
                      <PrimaryButton2
                        rootClassName={css.buttonContinue}
                        onClick={() => this.handelPackagePayment(selectedPackageParams)}
                      >
                        <FormattedMessage id={'PaymentTypeSelect.continue'} />
                      </PrimaryButton2>
                    </div>
                  </div>
                </div>
              )}

              {this.state.step === 4 && (
                <div className={css.packageBookingPanelContainer}>
                  <div className={css.packageBookingPanel}>
                    <div className={css.bookingHeading}>
                      {/* <div className={css.desktopPriceContainer}>
                        <div className={css.desktopPriceValue} title={priceTitle}>
                          {formattedPrice}
                        </div>
                        <div className={css.desktopPerUnit}>
                          <FormattedMessage id={unitTranslationKey} />
                        </div>
                      </div> */}
                      <div className={css.bookingHeadingContainer}>
                        <h2 className={css.bookingTitle}>Select your weekly time</h2>
                      </div>
                    </div>
                    <BookingTimeForm
                      className={css.bookingForm}
                      formId="BookingPanel"
                      submitButtonWrapperClassName={css.packageSubmitButtonWrapper}
                      unitType={unitType}
                      onSubmit={handleSubmit}
                      price={price}
                      isOwnListing={isOwnListing}
                      listingId={listing.id}
                      listing={listing}
                      ComponentOverlap={true}
                      currentUser={
                        this.state.currentUserData ? this.state.currentUserData : currentUser
                      }
                      monthlyTimeSlots={monthlyTimeSlots}
                      onFetchTimeSlots={onFetchTimeSlots}
                      startDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
                      endDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
                      timeZone={timeZone}
                      handlePackagesModalOpen={handlePackagesModalOpen}
                      priceAddons={formattedPriceAddons}
                      discountPriceAddons={formattedDiscountPriceAddons}
                      isProduct={isProduct}
                      onSkip={this.handelStepSkip}
                      onManageDisableScrolling={onManageDisableScrolling}
                      showTopup={false}
                    />
                  </div>
                  <div className={css.packageBookingPanelInfo}>
                    <h1>
                      <FormattedMessage id={'PaymentTypeSelect.bookMultipleSessions'} />
                    </h1>
                    <p>
                      <FormattedMessage id={'PaymentTypeSelect.bookingSystemHelps'} />
                    </p>
                    <NamedLink
                      className={css.buttonSkip}
                      name="InboxPage"
                      params={{ tab: 'buyerPackages' }}
                    >
                      <FormattedMessage id={'PaymentTypeSelect.skipBookLater'} />
                    </NamedLink>
                  </div>
                </div>
              )}

              {this.state.step !== 4 && (
                <div className={css.listingBookingDetail}>
                  <div className={css.bookingDetailContainer}>
                    <div className={css.bookingDetailSection}>
                      <div className={css.detailsAspectWrapper}>
                        <ResponsiveImage
                          rootClassName={css.rootForImage}
                          alt={listingTitle}
                          image={firstImage}
                          variants={['landscape-crop', 'landscape-crop2x']}
                        />
                      </div>
                      <div className={css.ContainerLine}></div>
                      <div className={css.BookingDetailInfo}>
                        <div className={css.BookingDetailInfoHead}>
                          <div className={css.avatarWrapper}>
                            <AvatarMedium user={currentAuthor} disableProfileLink />
                          </div>
                          <div className={css.BookingDetailTitleSection}>
                            <h2 className={css.bookingDetailListName}>
                              {listing.attributes.title}
                            </h2>
                            <div className={css.listDescription}>
                              {listing.attributes.description}
                            </div>
                          </div>
                        </div>
                        <div className={css.authorDetailInfo}>
                          <span className={css.authorExperience}>
                            <FormattedMessage id="PaymentTypeSelect.onlineExperienceHostedBy" />{' '}
                            {currentAuthor.attributes.profile.displayName}
                          </span>
                          <span className={css.authorLanguage}>
                            {listing.attributes.publicData.duration
                              ? listing.attributes.publicData.duration
                              : null}{' '}
                            {hostLanguages ? `· Hosted in ${hostLanguages}` : null}{' '}
                          </span>
                        </div>
                        <div className={css.authorReviewInfo}>
                          <div className={css.reviewSpan}>
                            <IconStar rootClassName={css.reviewStar} />{' '}
                            <span>{reviews.length}</span>{' '}
                            <FormattedMessage id="ListingPage.reviews" />
                          </div>
                          <div className={css.reviewSpan}>
                            <IconCheckedShield rootClassName={css.iconCheckedShield} />
                            <FormattedMessage id="ListingPage.identityVerified" />
                          </div>
                        </div>
                      </div>
                      <div className={css.ContainerLine}></div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

PackagesModal.defaultProps = {
  unitType: config.bookingUnitType,
};

// PackagesModal.setInitialValues = (initialValues, saveToSessionStorage = false) => {
//   if (saveToSessionStorage) {
//     const { listing, bookingData, bookingDates } = initialValues;
//     storeData(bookingData, bookingDates, listing, null, STORAGE_KEY);
//   }

//   return setInitialValues(initialValues);
// };

PackagesModal.propTypes = {};

export default PackagesModal;
