import React from 'react';
import {
  AboutPage,
  SubscriptionPage,
  AuthenticationPage,
  NewAuthenticationPage,
  CorporateAuthenticationPage,
  CheckoutPage,
  CheckoutPageWithoutPayment,
  ContactDetailsPage,
  EditListingPage,
  EmailVerificationPage,
  InboxPage,
  ListingPage,
  NotFoundPage,
  PasswordChangePage,
  WalletPage,
  PasswordRecoveryPage,
  PasswordResetPage,
  StripePayoutPage,
  PaymentMethodsPage,
  HitpaySettingsPage,
  GoogleCalendarSettingsPage,
  PrivacyPolicyPage,
  ProfilePage,
  ProfileSettingsPage,
  StyleguidePage,
  SubscriptionSettingsPage,
  TermsOfServicePage,
  TransactionPage,
  ManageListingsPage,
  PaymentTypeSelect,
  ContactPage,
  BookingPlanPage,
  ListingCreateSelection,
  BecomeVendorPage,
  BecomeMemberPage,
  BecomeTutorPage,
  OnlineTutorPage,
  ProfileAvatarPage,
  HomePage,
  MatchActivity,
  WebAppPage,
  CategoriesPage,
  AllSportsPage,
  AllCategoriesPage,
  SelectedCategoryTypePage,
  ListingType,
  UserSubscriptions,
  VendorListings,
  ThankYouPage,
  NewThankYouPage,
  CorporateThankYouPage,
  BlogPage,
  ShowBlogPage,
  EducationPage,
  CategoryBlogPage,
  MessageDeliveredPage,
  JobsBoard,
  TennisPage,
  SingleListingBoard,
} from './containers';

// routeConfiguration needs to initialize containers first
// Otherwise, components will import form container eventually and
// at that point css bundling / imports will happen in wrong order.
import { NamedRedirect } from './components';
import { LISTING_TYPES } from 'util/constants';
import SingleListingJobsBoard from 'containers/JobsBoard/SingleListingJobsBoard/SingleListingJobsBoard';

export const ACCOUNT_SETTINGS_PAGES = [
  'ContactDetailsPage',
  'PasswordChangePage',
  'StripePayoutPage',
  'PaymentMethodsPage',
  'HitpaySettingsPage',
  'WalletPage',
];

// https://en.wikipedia.org/wiki/Universally_unique_identifier#Nil_UUID
const draftId = '00000000-0000-0000-0000-000000000000';
const draftSlug = 'draft';

const RedirectToLandingPage = () => <NamedRedirect name="LandingPage" />;
// Our routes are exact by default.
// See behaviour from Routes.js where Route is created.
const routeConfiguration = () => {
  return [
    {
      path: '/thankyou',
      name: 'ThankYouPage',
      component: props => <ThankYouPage {...props} />,
    },
    {
      path: '/message-delivered',
      name: 'MessageDeliveredPage',
      component: props => <MessageDeliveredPage {...props} />,
    },
    {
      path: '/voucher-thankyou',
      name: 'NewThankYouPage',
      component: props => <NewThankYouPage {...props} />,
    },
    {
      path: '/corporate-thankyou',
      name: 'CorporateThankYouPage',
      component: props => <CorporateThankYouPage {...props} />,
    },
    {
      path: '/',
      name: 'WebAppPage',
      component: props => <WebAppPage {...props} />,
    },
    {
      path: '/search',
      name: 'LandingPage',
      // auth: true,
      // authPage: 'LoginPage',
      component: props => <HomePage {...props} isEducationSite={false} />,
      loadData: HomePage.loadData,
    },
    {
      path: '/match-activity',
      name: 'MatchActivity',
      // auth: true,
      // authPage: 'LoginPage',
      component: props => <MatchActivity {...props} isEducationSite={false} />,
      loadData: MatchActivity.loadData,
    },
    {
      path: '/education',
      name: 'EducationPage',
      component: props => <EducationPage {...props} isEducationSite={true} />,
      loadData: EducationPage.loadData,
    },
    // {
    //   path: '/tennis-profiles',
    //   name: 'TennisPage',
    //   component: props => <TennisPage {...props} isTennisSite={true} />,
    //   loadData: TennisPage.loadData,
    // },
    {
      path: '/blogs',
      name: 'BlogPage',
      component: props => <BlogPage {...props} isEducationSite={false} />,
    },
    {
      path: '/blogs/:slug',
      name: 'CategoryBlogPage',
      component: props => <CategoryBlogPage {...props} isEducationSite={false} />,
    },
    {
      path: '/blogs/:slug/:blogSlug',
      name: 'ShowBlogPage',
      component: props => <ShowBlogPage {...props} isEducationSite={false} />,
    }, //EducationBlogPage
    {
      path: '/education/blogs',
      name: 'EducationBlogPage',
      component: props => <BlogPage {...props} isEducationSite={true} />,
    },
    {
      path: '/education/blogs/:slug',
      name: 'EduCategoryBlogPage',
      component: props => <CategoryBlogPage {...props} isEducationSite={true} />,
    },
    {
      path: '/education/blogs/:slug/:blogSlug',
      name: 'EduShowBlogPage',
      component: props => <ShowBlogPage {...props} isEducationSite={false} />,
    },
    {
      path: '/jobs-board',
      name: 'JobsBoard',
      component: props => <JobsBoard {...props} isEducationSite={false} />,
    },
    {
      path: '/education/jobs-board',
      name: 'EducationJobsBoard',
      component: props => <JobsBoard {...props} isEducationSite={true} />,
    },
    {
      path: '/tennis/jobs-board',
      name: 'TennisJobsBoard',
      component: props => <JobsBoard {...props} isTennisSite={true} />,
    },
    {
      path: '/education/categories',
      name: 'EducationCategoriesPage',
      component: props => <CategoriesPage {...props} isEducationSite={true} />,
      // loadData: CategoriesPage.loadData,
    },
    {
      path: '/tennis/categories',
      name: 'TennisCategoriesPage',
      component: props => <CategoriesPage {...props} isTennisSite={true} />,
      // loadData: CategoriesPage.loadData,
    },
    {
      path: '/jobs-board/:cardID',
      name: 'SingleListingJobsBoard',
      component: props => <SingleListingJobsBoard {...props} isEducationSite={false} />,
    },
    {
      path: '/education/jobs-board/:cardID',
      name: 'EducationSingleListingJobsBoard',
      component: props => <SingleListingJobsBoard {...props} isEducationSite={true} />,
    },
    {
      path: '/tennis/jobs-board/:cardID',
      name: 'EducationSingleListingJobsBoard',
      component: props => <SingleListingJobsBoard {...props} isTennisSite={true} />,
    },
    {
      path: '/categories',
      name: 'CategoriesPage',
      component: props => <CategoriesPage {...props} isEducationSite={false} />,
      // loadData: CategoriesPage.loadData,
    },
    {
      path: '/about',
      name: 'AboutPage',
      component: AboutPage,
    },
    {
      path: '/subscription',
      name: 'SubscriptionPage',
      component: SubscriptionPage,
    },
    {
      path: '/:name/subscription',
      name: 'SubscriptionHostOrMemberPage',
      component: SubscriptionPage,
    },
    {
      path: '/l',
      name: 'ListingBasePage',
      component: RedirectToLandingPage,
    },
    {
      path: '/l/:slug/:id',
      name: 'ListingPage',
      component: props => <ListingPage {...props} />,
      loadData: ListingPage.loadData,
    },
    {
      path: '/l/:slug/:id/checkout',
      name: 'CheckoutPage',
      auth: true,
      component: props => <CheckoutPage {...props} />,
      setInitialValues: CheckoutPage.setInitialValues,
      loadData: CheckoutPage.loadData,
    },
    {
      path: '/l/:slug/:id/:credits/:paymentType?/checkout_c',
      name: 'CheckoutPageWithoutPayment',
      auth: true,
      component: props => <CheckoutPageWithoutPayment {...props} />,
      setInitialValues: CheckoutPageWithoutPayment.setInitialValues,
      loadData: HomePage.loadData,
    },
    {
      path: '/l/:slug/:id/:credits?/select-type',
      name: 'CheckoutPaymentTypeSelect',
      auth: true,
      component: props => <PaymentTypeSelect {...props} />,
      loadData: HomePage.loadData,
    },
    {
      path: '/l/:slug/:id/:variant',
      name: 'ListingPageVariant',
      auth: true,
      authPage: 'LoginPage',
      component: props => <ListingPage {...props} />,
      loadData: ListingPage.loadData,
    },
    // Intermediate step before creating any listing
    // Allows to select what type of listing to create
    {
      path: '/create-listing',
      name: 'ListingCreateSelection',
      auth: true,
      component: props => <ListingCreateSelection {...props} />,
    },
    {
      path: '/listing-type',
      name: 'ListingType',
      auth: true,
      component: props => <ListingType {...props} />,
      loadData: HomePage.loadData,
    },
    {
      path: '/listing-type/edu',
      name: 'ListingTypeEducation',
      auth: true,
      component: props => <ListingType {...props} />,
      loadData: HomePage.loadData,
    },
    {
      path: '/listing-type/ten',
      name: 'ListingTypeTennis',
      auth: true,
      component: props => <ListingType {...props} />,
      loadData: HomePage.loadData,
    },
    {
      path: '/l/new',
      name: 'NewListingPage',
      auth: true,
      component: () => (
        <NamedRedirect
          name="EditListingPage"
          params={{ slug: draftSlug, id: draftId, type: 'new', tab: 'description' }}
        />
      ),
    },
    {
      path: '/l/:slug/:id/:type/:tab',
      name: 'EditListingPage',
      auth: true,
      component: props => <EditListingPage {...props} allowOnlyOneListing={false} />,
      loadData: params =>
        EditListingPage.loadData({ ...params, listingType: LISTING_TYPES.LISTING }),
    },
    //Vendor Listings
    {
      path: '/vendor-listings/:id',
      name: 'VendorListings',
      component: props => <VendorListings {...props} isEducationSite={false} />,
      loadData: params => VendorListings.loadData({ ...params }),
    },
    {
      path: '/education/vendor-listings/:id',
      name: 'EducationVendorListings',
      component: props => <VendorListings {...props} isEducationSite={true} />,
      loadData: params => VendorListings.loadData({ ...params }),
    },
    // Class Create/Edit pages
    {
      path: '/cl/new',
      name: 'NewClassPage',
      auth: true,
      component: () => (
        <NamedRedirect
          name="EditClassPage"
          params={{ slug: draftSlug, id: draftId, type: 'new', tab: 'description' }}
        />
      ),
    },
    {
      path: '/cl/:slug/:id/:type/:tab',
      name: 'EditClassPage',
      auth: true,
      component: props => (
        <EditListingPage listingType={LISTING_TYPES.CLASS} {...props} allowOnlyOneListing={false} />
      ),
      loadData: params => EditListingPage.loadData({ ...params, listingType: LISTING_TYPES.CLASS }),
    },
    {
      path: '/et/new',
      name: 'NewEventPage',
      auth: true,
      component: () => (
        <NamedRedirect
          name="EditEventPage"
          params={{ slug: draftSlug, id: draftId, type: 'new', tab: 'details' }}
        />
      ),
    },
    {
      path: '/et/:slug/:id/:type/:tab',
      name: 'EditEventPage',
      auth: true,
      component: props => (
        <EditListingPage listingType={LISTING_TYPES.EVENT} {...props} allowOnlyOneListing={false} />
      ),
      loadData: params => EditListingPage.loadData({ ...params, listingType: LISTING_TYPES.EVENT }),
    },
    {
      path: '/cr/new',
      name: 'NewCoursesPage',
      auth: true,
      component: () => (
        <NamedRedirect
          name="EditCoursesPage"
          params={{ slug: draftSlug, id: draftId, type: 'new', tab: 'description' }}
        />
      ),
    },
    {
      path: '/ec/new',
      name: 'NewEducationCoursesPage',
      auth: true,
      component: () => (
        <NamedRedirect
          name="EditEducationCoursesPage"
          params={{ slug: draftSlug, id: draftId, type: 'new', tab: 'description' }}
        />
      ),
    },
    {
      path: '/rt/new',
      name: 'NewRemoteTutor',
      auth: true,
      component: () => (
        <NamedRedirect
          name="EditRemoteTutorPage"
          params={{ slug: draftSlug, id: draftId, type: 'new', tab: 'description' }}
        />
      ),
    },
    {
      path: '/rt/:slug/:id/:type/:tab',
      name: 'EditRemoteTutorPage',
      auth: true,
      component: props => (
        <EditListingPage
          listingType={LISTING_TYPES.REMOTE_TUTOR}
          {...props}
          allowOnlyOneListing={false}
          isEducationSite={true}
        />
      ),
      loadData: params =>
        EditListingPage.loadData({ ...params, listingType: LISTING_TYPES.REMOTE_TUTOR }),
    },
    {
      path: '/cr/:slug/:id/:type/:tab',
      name: 'EditCoursesPage',
      auth: true,
      component: props => (
        <EditListingPage
          listingType={LISTING_TYPES.COURSES}
          {...props}
          allowOnlyOneListing={false}
          isEducationSite={true}
        />
      ),
      loadData: params =>
        EditListingPage.loadData({ ...params, listingType: LISTING_TYPES.COURSES }),
    },
    {
      path: '/ec/:slug/:id/:type/:tab',
      name: 'EditEducationCoursesPage',
      auth: true,
      component: props => (
        <EditListingPage
          listingType={LISTING_TYPES.EDUCATION_COURSES}
          {...props}
          allowOnlyOneListing={false}
          isEducationSite={true}
        />
      ),
      loadData: params =>
        EditListingPage.loadData({ ...params, listingType: LISTING_TYPES.EDUCATION_COURSES }),
    },
    {
      path: '/hs/:slug/:id/:type/:tab',
      name: 'EditHourlySessionPage',
      auth: true,
      component: props => (
        <EditListingPage
          listingType={LISTING_TYPES.HOURLY_SESSION}
          {...props}
          allowOnlyOneListing={false}
          isEducationSite={true}
        />
      ),
      loadData: params =>
        EditListingPage.loadData({ ...params, listingType: LISTING_TYPES.HOURLY_SESSION }),
    },
    {
      path: '/hs/new',
      name: 'NewHourlySessionPage',
      auth: true,
      component: () => (
        <NamedRedirect
          name="EditHourlySessionPage"
          params={{ slug: draftSlug, id: draftId, type: 'new', tab: 'description' }}
        />
      ),
    },
    {
      path: '/tl/new',
      name: 'NewTutorPage',
      auth: true,
      component: () => (
        <NamedRedirect
          name="EditTutorPage"
          params={{ slug: draftSlug, id: draftId, type: 'new', tab: 'description' }}
        />
      ),
    },
    {
      path: '/tl/:slug/:id/:type/:tab',
      name: 'EditTutorPage',
      auth: true,
      component: props => (
        <EditListingPage
          listingType={LISTING_TYPES.TUTOR}
          {...props}
          allowOnlyOneListing={false}
          isEducationSite={true}
        />
      ),
      loadData: params => EditListingPage.loadData({ ...params, listingType: LISTING_TYPES.TUTOR }),
    },
    {
      path: '/ep/:slug/:id/:type/:tab',
      name: 'EditPlayerProfilePage',
      auth: true,
      component: props => (
        <EditListingPage
          listingType={LISTING_TYPES.PLAYER_PROFILE}
          {...props}
          allowOnlyOneListing={false}
          isTennisSite={true}
        />
      ),
      loadData: params =>
        EditListingPage.loadData({ ...params, listingType: LISTING_TYPES.PLAYER_PROFILE }),
    },
    {
      path: '/al/new',
      name: 'NewAcademyPage',
      auth: true,
      component: () => (
        <NamedRedirect
          name="EditAcademyPage"
          params={{ slug: draftSlug, id: draftId, type: 'new', tab: 'description' }}
        />
      ),
    },
    {
      path: '/al/:slug/:id/:type/:tab',
      name: 'EditAcademyPage',
      auth: true,
      component: props => (
        <EditListingPage
          listingType={LISTING_TYPES.ACADEMY}
          {...props}
          allowOnlyOneListing={false}
          isEducationSite={true}
        />
      ),
      loadData: params =>
        EditListingPage.loadData({ ...params, listingType: LISTING_TYPES.ACADEMY }),
    },
    {
      path: '/pl/new',
      name: 'NewProductPage',
      auth: true,
      component: () => (
        <NamedRedirect
          name="EditProductPage"
          params={{ slug: draftSlug, id: draftId, type: 'new', tab: 'details' }}
        />
      ),
    },
    {
      path: '/pl/:slug/:id/:type/:tab',
      name: 'EditProductPage',
      auth: true,
      component: props => (
        <EditListingPage
          listingType={LISTING_TYPES.PRODUCT}
          {...props}
          allowOnlyOneListing={false}
        />
      ),
      loadData: params =>
        EditListingPage.loadData({ ...params, listingType: LISTING_TYPES.PRODUCT }),
    },

    {
      path: '/pp/new',
      name: 'NewPlayerProfilePage',
      auth: true,
      component: () => (
        <NamedRedirect
          name="EditPlayerProfilePage"
          params={{ slug: draftSlug, id: draftId, type: 'new', tab: 'details' }}
        />
      ),
    },
    {
      path: '/pp/:slug/:id/:type/:tab',
      name: 'EditPlayerProfilePage',
      auth: true,
      component: props => (
        <EditListingPage
          listingType={LISTING_TYPES.PLAYER_PROFILE}
          {...props}
          allowOnlyOneListing={false}
        />
      ),
      loadData: params =>
        EditListingPage.loadData({ ...params, listingType: LISTING_TYPES.PLAYER_PROFILE }),
    },

    {
      path: '/m/new',
      name: 'NewMembershipPage',
      auth: true,
      component: () => (
        <NamedRedirect
          name="EditMembershipPage"
          params={{ slug: draftSlug, id: draftId, type: 'new', tab: 'details' }}
        />
      ),
    },
    {
      path: '/m/:slug/:id/:type/:tab',
      name: 'EditMembershipPage',
      auth: true,
      component: props => (
        <EditListingPage
          listingType={LISTING_TYPES.MEMBERSHIP}
          {...props}
          allowOnlyOneListing={false}
        />
      ),
      loadData: params =>
        EditListingPage.loadData({ ...params, listingType: LISTING_TYPES.MEMBERSHIP }),
    },
    // Company Create/Edit page
    {
      path: '/c/new',
      name: 'NewCompanyPage',
      auth: true,
      component: () => (
        <NamedRedirect
          name="EditCompanyPage"
          params={{ slug: draftSlug, id: draftId, type: 'new', tab: 'description' }}
        />
      ),
    },
    {
      path: '/c/:slug/:id/:type/:tab',
      name: 'EditCompanyPage',
      auth: true,
      component: props => (
        <EditListingPage
          listingType={LISTING_TYPES.COMPANY}
          {...props}
          allowOnlyOneListing={false}
        />
      ),
      loadData: params =>
        EditListingPage.loadData({ ...params, listingType: LISTING_TYPES.COMPANY }),
    },
    // Freelancer Create/Edit page
    {
      path: '/f/new',
      name: 'NewFreelancerPage',
      auth: true,
      component: () => (
        <NamedRedirect
          name="EditFreelancerPage"
          params={{ slug: draftSlug, id: draftId, type: 'new', tab: 'description' }}
        />
      ),
    },
    {
      path: '/f/:slug/:id/:type/:tab',
      name: 'EditFreelancerPage',
      auth: true,
      component: props => (
        <EditListingPage
          listingType={LISTING_TYPES.FREELANCER}
          {...props}
          allowOnlyOneListing={false}
        />
      ),
      loadData: params =>
        EditListingPage.loadData({ ...params, listingType: LISTING_TYPES.FREELANCER }),
    },
    // Inquire routes
    {
      path: '/i/new',
      name: 'NewInquirePage',
      // auth: true,
      component: () => (
        <NamedRedirect
          name="EditInquirePage"
          params={{ slug: draftSlug, id: draftId, type: 'new', tab: 'description' }}
        />
      ),
    },
    {
      path: '/search',
      name: 'HomePage',
      auth: true,
      component: props => <HomePage {...props} />,
      loadData: HomePage.loadData,
    },
    {
      path: '/search/:city_name',
      name: 'HomePageCity',
      auth: true,
      component: props => <HomePage {...props} />,
      loadData: HomePage.loadData,
    },
    {
      path: '/i/:slug/:id/:type/:tab',
      name: 'EditInquirePage',
      // auth: true,
      component: props => (
        <EditListingPage
          listingType={LISTING_TYPES.ENQUIRY}
          {...props}
          allowOnlyOneListing={false}
        />
      ),
      loadData: params =>
        EditListingPage.loadData({ ...params, listingType: LISTING_TYPES.ENQUIRY }),
    },
    // Facility pages
    {
      path: '/fc/new',
      name: 'NewFacilityPage',
      auth: true,
      component: () => (
        <NamedRedirect
          name="EditFacilityPage"
          params={{ slug: draftSlug, id: draftId, type: 'new', tab: 'description' }}
        />
      ),
    },
    {
      path: '/fc/:slug/:id/:type/:tab',
      name: 'EditFacilityPage',
      auth: true,
      component: props => (
        <EditListingPage
          {...props}
          listingType={LISTING_TYPES.FACILITY}
          allowOnlyOneListing={false}
        />
      ),
      loadData: params =>
        EditListingPage.loadData({ ...params, listingType: LISTING_TYPES.FACILITY }),
    },
    {
      path: '/l/:slug/:id/:type/:tab/:returnURLType',
      name: 'EditListingStripeOnboardingPage',
      auth: true,
      component: props => <EditListingPage {...props} />,
      loadData: EditListingPage.loadData,
    },

    // Canonical path should be after the `/l/new` path since they
    // conflict and `new` is not a valid listing UUID.
    {
      path: '/l/:id',
      name: 'ListingPageCanonical',
      component: props => <ListingPage {...props} />,
      loadData: ListingPage.loadData,
    },
    {
      path: '/u',
      name: 'ProfileBasePage',
      component: RedirectToLandingPage,
    },
    {
      path: '/u/:id',
      name: 'ProfilePage',
      component: props => <ProfilePage {...props} />,
      loadData: ProfilePage.loadData,
    },
    {
      path: '/profile-settings',
      name: 'ProfileSettingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <ProfileSettingsPage {...props} />,
    },

    // Note: authenticating with IdP (e.g. Facebook) expects that /login path exists
    // so that in the error case users can be redirected back to the LoginPage
    // In case you change this, remember to update the route in server/api/auth/loginWithIdp.js
    {
      path: '/login',
      name: 'LoginPage',
      component: props => <AuthenticationPage {...props} tab="login" />,
    },
    {
      path: '/signup',
      name: 'SignupPage',
      component: props => <AuthenticationPage {...props} tab="signup" />,
    },
    {
      path: '/confirm',
      name: 'ConfirmPage',
      component: props => <AuthenticationPage {...props} tab="confirm" />,
    },
    {
      path: '/voucher-signup',
      name: 'NewSignupPage',
      component: props => <NewAuthenticationPage {...props} tab="voucher-signup" />,
    },
    {
      path: '/corporate-signup',
      name: 'CorporateSignupPage',
      component: props => <CorporateAuthenticationPage {...props} tab="corporate-signup" />,
    },
    {
      path: '/recover-password',
      name: 'PasswordRecoveryPage',
      component: props => <PasswordRecoveryPage {...props} />,
    },
    {
      path: '/inbox',
      name: 'InboxBasePage',
      auth: true,
      authPage: 'LoginPage',
      component: () => <NamedRedirect name="InboxPage" params={{ tab: 'sales', state: 'all' }} />,
    },
    {
      path: '/education/inbox',
      name: 'EducationInboxBasePage',
      auth: true,
      authPage: 'LoginPage',
      component: () => <NamedRedirect name="InboxPage" params={{ tab: 'sales', state: 'all' }} />,
    },
    {
      path: '/inbox/:tab/:state?',
      name: 'InboxPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <InboxPage {...props} />,
      loadData: InboxPage.loadData,
    },
    {
      path: '/order/:id',
      name: 'OrderPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <NamedRedirect name="OrderDetailsPage" params={{ ...props.params }} />,
    },
    {
      path: '/order/:id/details',
      name: 'OrderDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <TransactionPage {...props} transactionRole="customer" />,
      loadData: params => TransactionPage.loadData({ ...params, transactionRole: 'customer' }),
      setInitialValues: TransactionPage.setInitialValues,
    },
    {
      path: '/sale/:id',
      name: 'SalePage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <NamedRedirect name="SaleDetailsPage" params={{ ...props.params }} />,
    },
    {
      path: '/sale/:id/details',
      name: 'SaleDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <TransactionPage {...props} transactionRole="provider" />,
      loadData: params => TransactionPage.loadData({ ...params, transactionRole: 'provider' }),
    },
    {
      path: '/listings',
      name: 'ManageListingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <ManageListingsPage {...props} isEducationSite={false} />,
      loadData: ManageListingsPage.loadData,
    },
    {
      path: '/education/listings',
      name: 'EducationManageListingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <ManageListingsPage {...props} isEducationSite={true} />,
      loadData: ManageListingsPage.loadData,
    },
    {
      path: '/tennis/listings',
      name: 'TennisManageListingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <ManageListingsPage {...props} isTennisSite={true} />,
      loadData: ManageListingsPage.loadData,
    },
    {
      path: '/my-subscription',
      name: 'UserSubscriptions',
      auth: true,
      authPage: 'LoginPage',
      component: props => <UserSubscriptions {...props} />,
      loadData: UserSubscriptions.loadData,
    },
    {
      path: '/account',
      name: 'AccountSettingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: () => <NamedRedirect name="ContactDetailsPage" />,
    },
    {
      path: '/education/account',
      name: 'EducationAccountSettingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: () => <NamedRedirect name="EducationContactDetailsPage" />,
    },
    {
      path: '/tennis/account',
      name: 'TennisAccountSettingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: () => <NamedRedirect name="TennisContactDetailsPage" />,
    },
    {
      path: '/account/contact-details',
      name: 'ContactDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <ContactDetailsPage {...props} />,
      loadData: ContactDetailsPage.loadData,
    },
    {
      path: '/education/account/contact-details',
      name: 'EducationContactDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <ContactDetailsPage {...props} isEducationSite={true} />,
      loadData: ContactDetailsPage.loadData,
    },
    {
      path: '/tennis/account/contact-details',
      name: 'TennisContactDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <ContactDetailsPage {...props} isTennisSite={true} />,
      loadData: ContactDetailsPage.loadData,
    },
    {
      path: '/account/change-password',
      name: 'PasswordChangePage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <PasswordChangePage {...props} />,
    },
    {
      path: '/education/account/change-password',
      name: 'EducationPasswordChangePage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <PasswordChangePage {...props} isEducationSite={true} />,
    },
    {
      path: '/wallet',
      name: 'WalletPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <WalletPage {...props} />,
      loadData: WalletPage.loadData,
    },
    {
      path: '/education/wallet',
      name: 'EducationWalletPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <WalletPage {...props} isEducationSite={true} />,
      loadData: WalletPage.loadData,
    },
    {
      path: '/account/payments',
      name: 'StripePayoutPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <StripePayoutPage {...props} />,
      loadData: StripePayoutPage.loadData,
    },
    {
      path: '/education/account/payments',
      name: 'EducationStripePayoutPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <StripePayoutPage {...props} isEducationSite={true} />,
      loadData: StripePayoutPage.loadData,
    },
    {
      path: '/account/payments/:returnURLType',
      name: 'StripePayoutOnboardingPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <StripePayoutPage {...props} />,
      loadData: StripePayoutPage.loadData,
    },
    {
      path: '/account/payment-methods',
      name: 'PaymentMethodsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <PaymentMethodsPage {...props} />,
      loadData: PaymentMethodsPage.loadData,
    },
    {
      path: '/education/account/payment-methods',
      name: 'EducationPaymentMethodsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <PaymentMethodsPage {...props} isEducationSite={true} />,
      loadData: PaymentMethodsPage.loadData,
    },
    {
      path: '/account/hitpay',
      name: 'HitpaySettingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <HitpaySettingsPage {...props} />,
      loadData: HitpaySettingsPage.loadData,
    },
    {
      path: '/education/account/hitpay',
      name: 'EducationHitpaySettingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <HitpaySettingsPage {...props} isEducationSite={true} />,
      loadData: HitpaySettingsPage.loadData,
    },
    {
      path: '/account/googleCalendar',
      name: 'GoogleCalendarSettingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <GoogleCalendarSettingsPage {...props} />,
      loadData: GoogleCalendarSettingsPage.loadData,
    },
    {
      path: '/education/account/googleCalendar',
      name: 'EducationGoogleCalendarSettingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <GoogleCalendarSettingsPage {...props} isEducationSite={true} />,
      loadData: GoogleCalendarSettingsPage.loadData,
    },
    {
      path: '/account/subscription',
      name: 'SubscriptionSettingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <SubscriptionSettingsPage {...props} />,
    },
    {
      path: '/terms-of-service',
      name: 'TermsOfServicePage',
      component: props => <TermsOfServicePage {...props} />,
    },
    {
      path: '/privacy-policy',
      name: 'PrivacyPolicyPage',
      component: props => <PrivacyPolicyPage {...props} />,
    },
    {
      path: '/contact',
      name: 'ContactPage',
      component: props => <ContactPage {...props} />,
    },
    {
      path: '/styleguide',
      name: 'Styleguide',
      component: props => <StyleguidePage {...props} />,
    },
    {
      path: '/styleguide/g/:group',
      name: 'StyleguideGroup',
      component: props => <StyleguidePage {...props} />,
    },
    {
      path: '/styleguide/c/:component',
      name: 'StyleguideComponent',
      component: props => <StyleguidePage {...props} />,
    },
    {
      path: '/styleguide/c/:component/:example',
      name: 'StyleguideComponentExample',
      component: props => <StyleguidePage {...props} />,
    },
    {
      path: '/styleguide/c/:component/:example/raw',
      name: 'StyleguideComponentExampleRaw',
      component: props => <StyleguidePage raw {...props} />,
    },
    {
      path: '/notfound',
      name: 'NotFoundPage',
      component: props => <NotFoundPage {...props} />,
    },

    // Do not change this path!
    //
    // The API expects that the application implements /reset-password endpoint
    {
      path: '/reset-password',
      name: 'PasswordResetPage',
      component: props => <PasswordResetPage {...props} />,
    },

    // Do not change this path!
    //
    // The API expects that the application implements /verify-email endpoint
    {
      path: '/verify-email',
      name: 'EmailVerificationPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <EmailVerificationPage {...props} />,
      loadData: EmailVerificationPage.loadData,
    },
    {
      path: '/yourSchedule/:period?',
      name: 'BookingPlan',
      auth: true,
      authPage: 'LoginPage',
      component: props => <BookingPlanPage {...props} />,
      loadData: BookingPlanPage.loadData,
    },
    {
      path: '/education/become-tutor',
      name: 'BecomeTutorPage',
      component: props => <BecomeTutorPage {...props} />,
    },
    {
      path: '/online-tutor',
      name: 'OnlineTutorPage',
      component: props => <OnlineTutorPage {...props} />,
    },
    {
      path: '/become_vendor',
      name: 'BecomeVendorPage',
      component: props => <BecomeVendorPage {...props} />,
    },
    // {
    //   path: '/become_member',
    //   name: 'BecomeMemberPage',
    //   component: props => <BecomeMemberPage {...props} />,
    // },
    {
      path: '/profile-avatar',
      name: 'ProfileAvatarPage',
      auth: true,
      component: props => <ProfileAvatarPage {...props} />,
    },
    {
      path: '/education/:slug',
      name: 'AllAcademicsPage',
      component: props => <AllSportsPage {...props} isEducationSite={true} />,
      loadData: AllSportsPage.loadData,
    },
    {
      path: '/education/:city_name',
      name: 'EducationHomePageCity',
      component: props => <EducationPage {...props} />,
      loadData: EducationPage.loadData,
    },
    {
      path: '/:slug',
      name: 'AllSportsPage',
      component: props => <AllSportsPage {...props} />,
      loadData: AllSportsPage.loadData,
    },
    {
      path: '/:slug/:city',
      name: 'AllCategoriesPage',
      component: props => <AllCategoriesPage {...props} />,
      loadData: AllCategoriesPage.loadData,
    },
    {
      path: `/:categoryType/:city/:listingType`,
      name: 'SelectedCategoryTypePage',
      component: props => <SelectedCategoryTypePage {...props} />,
      loadData: SelectedCategoryTypePage.loadData,
    },
  ];
};

export default routeConfiguration;
