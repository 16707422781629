import React, { Component } from 'react';
import moment from 'moment';
import { bool, func, instanceOf, object, oneOfType, shape, string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { withRouter } from 'react-router-dom';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { types as sdkTypes } from 'util/sdkLoader';
import emailjs, { init } from '@emailjs/browser';
import { sequence } from 'util/general';
import { pathByRouteName, findRouteByRouteName } from '../../util/routes';
import {
  propTypes,
  LINE_ITEM_NIGHT,
  LINE_ITEM_DAY,
  DATE_TYPE_DATETIME,
  LINE_ITEM_UNITS,
  LINE_ITEM_CUSTOM_AMOUNT,
  LINE_ITEM_CUSTOMER_COMMISSION,
  LINE_ITEM_PROVIDER_COMMISSION,
  DATE_TYPE_DATE,
} from '../../util/types';
import {
  ensureListing,
  ensureCurrentUser,
  ensureUser,
  ensureTransaction,
  ensureBooking,
  ensureStripeCustomer,
  ensurePaymentMethodCard,
  addonLabelIntoCode,
} from '../../util/data';
import { minutesBetween } from '../../util/dates';
import { createSlug } from '../../util/urlHelpers';
import {
  isTransactionInitiateAmountTooLowError,
  isTransactionInitiateListingNotFoundError,
  isTransactionInitiateMissingStripeAccountError,
  isTransactionInitiateBookingTimeNotAvailableError,
  isTransactionChargeDisabledError,
  isTransactionZeroPaymentError,
  transactionInitiateOrderStripeErrors,
} from '../../util/errors';
import { formatMoney } from '../../util/currency';
import { WithoutPaymentForm } from '../../forms';
import {
  TRANSITION_ENQUIRE,
  txIsPaymentPending,
  txIsPaymentExpired,
  txHasPassedPaymentPending,
} from '../../util/transaction';
import {
  AvatarMedium,
  BookingBreakdown,
  NamedLink,
  NamedRedirect,
  OrderBreakdown,
  Page,
  ResponsiveImage,
} from '../../components';
import { HeaderContainer } from '../../containers';
import { StripePaymentForm } from '../../forms';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { handleCardPayment, retrievePaymentIntent } from '../../ducks/stripe.duck';
import { savePaymentMethod } from '../../ducks/paymentMethods.duck';
import { manageDisableScrolling } from '../../ducks/UI.duck';
import { logout } from '../../ducks/Auth.duck';
import {
  initiateOrder,
  setInitialValues,
  speculateTransaction,
  stripeCustomer,
  confirmPayment,
  sendMessage,
  hitPay,
} from './CheckoutPage.duck';
import { LISTING_TYPES, PAYMENT_TYPES } from 'util/constants';
import { storeData, storedData, clearData } from './CheckoutPageSessionHelpers';
import css from './CheckoutPage.css';
import { LINE_ITEM_CARD_SERVICE_FEE } from 'util/types';

import VisaIcon from '../../assets/CheckoutPage/Visa_Icon.svg';
import MasterCardIcon from '../../assets/CheckoutPage/MasterCard_Icon.svg';
import AmericanExpressIcon from '../../assets/CheckoutPage/AmericanExpress_Icon.svg';
import PaypalIcon from '../../assets/CheckoutPage/Paypal_Icon.svg';
import JCBIcon from '../../assets/CheckoutPage/JCB_Icon.svg';
import BankIcon from '../../assets/CheckoutPage/Bank_Icon.svg';
import PayNowIcon from '../../assets/CheckoutPage/PayNow_Icon.svg';
import MembershipIcon from '../../assets/CheckoutPage/Membership_Icon.svg';
import HitPayIcon from '../../assets/CheckoutPage/HitPay_Icon.svg';

import { withViewport } from '../../util/contextHelpers';
import { createResourceLocatorString } from 'util/routes';
import { queryPromotedListings } from '../HomePage/HomePage.duck';
import { queryPromotedListingsEdu } from 'containers/EducationPage/EducationPage.duck';

const { UUID } = sdkTypes;
const STORAGE_KEY = 'CheckoutPage';
const { Money } = sdkTypes;
const MAX_MOBILE_SCREEN_WIDTH = 1024;

// Stripe PaymentIntent statuses, where user actions are already completed
// https://stripe.com/docs/payments/payment-intents/status
const STRIPE_PI_USER_ACTIONS_DONE_STATUSES = ['processing', 'requires_capture', 'succeeded'];

// Payment charge options
const ONETIME_PAYMENT = 'ONETIME_PAYMENT';
const PAY_AND_SAVE_FOR_LATER_USE = 'PAY_AND_SAVE_FOR_LATER_USE';
const USE_SAVED_CARD = 'USE_SAVED_CARD';

const CARD_PROVIDER_COMMISSION = -5;

const applyAsync = (acc, val) => acc.then(val);
const composeAsync = (...funcs) => x => funcs.reduce(applyAsync, Promise.resolve(x));

const paymentFlow = (selectedPaymentMethod, saveAfterOnetimePayment) => {
  // Payment mode could be 'replaceCard', but without explicit saveAfterOnetimePayment flag,
  // we'll handle it as one-time payment
  return selectedPaymentMethod === 'defaultCard'
    ? USE_SAVED_CARD
    : saveAfterOnetimePayment
    ? PAY_AND_SAVE_FOR_LATER_USE
    : ONETIME_PAYMENT;
};

const initializeOrderPage = (initialValues, routes, dispatch) => {
  const OrderPage = findRouteByRouteName('OrderDetailsPage', routes);

  // Transaction is already created, but if the initial message
  // sending failed, we tell it to the OrderDetailsPage.
  dispatch(OrderPage.setInitialValues(initialValues));
};

const checkIsPaymentExpired = existingTransaction => {
  return txIsPaymentExpired(existingTransaction)
    ? true
    : txIsPaymentPending(existingTransaction)
    ? minutesBetween(existingTransaction.attributes.lastTransitionedAt, new Date()) >= 15
    : false;
};

export class CheckoutPageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pageData: {},
      dataLoaded: false,
      submitting: false,
      selectedPaymentType: '',
      step: 1,
      isOpen: false,
      isModalAccepted: false,
      //Topbar
      queryParams: null,
      isOpenSearchBar: false,
      showOpacity: '',
      openLeftBar: false,
      currentTab: '',
      selectedSubCategory: props.searchActivity,
      disableFilterScroll: false,
      isScrolling: false,
    };
    this.stripe = null;

    this.onStripeInitialized = this.onStripeInitialized.bind(this);
    this.loadInitialData = this.loadInitialData.bind(this);
    this.handlePaymentIntent = this.handlePaymentIntent.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSubmitOrder = this.handleSubmitOrder.bind(this);
    this.customPricingParams = this.customPricingParams.bind(this);
    this.handelPaymentTypeSelecting = this.handelPaymentTypeSelecting.bind(this);
    this.handelStepChange = this.handelStepChange.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.handleHeaderSearchBar = this.handleHeaderSearchBar.bind(this);
    this.handleOpacityChange = this.handleOpacityChange.bind(this);
    this.onTabChangeSearch = this.onTabChangeSearch.bind(this);
    this.resetPage = this.resetPage.bind(this);
  }

  componentDidMount() {
    document.body.style.overflow = 'unset';
    init('DkU54piL9T3fDaZV1');
    if (window) this.loadInitialData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentUser?.id?.uuid !== this.props.currentUser?.id?.uuid)
      this.loadInitialData();
  }

  onSearch = searchquery => {
    this.props.history.push(
      createResourceLocatorString(
        this.props.isEducationSite ? 'EducationPage' : 'HomePage',
        routeConfiguration(),
        {}
      )
    );
    this.setState({ queryParams: searchquery });
    this.props.isEducationSite
      ? this.props.queryPromotedListingsEdu(searchquery)
      : this.props.queryPromotedListings(searchquery);
  };

  onTabChangeSearch = searchquery => {
    this.setState({ queryParams: searchquery });
    this.props.queryPromotedListings(searchquery);
  };

  handleHeaderSearchBar = value => {
    this.setState({ isOpenSearchBar: value });
  };

  handleOpacityChange = value => {
    this.setState({ showOpacity: value });
  };

  handelStepChange(step) {
    this.setState({ step });
  }
  handelPaymentTypeSelecting(paymentType) {
    this.setState({ selectedPaymentType: paymentType });
    const { pageData } = this.state;
    // Check if a booking is already created according to stored data.
    const tx = pageData ? pageData.transaction : null;
    const isBookingCreated = tx && tx.booking && tx.booking.id;
    const shouldFetchSpeculatedTransaction = pageData?.bookingData?.productBooking
      ? pageData &&
        pageData.listing &&
        pageData.listing.id &&
        pageData.orderData &&
        !txHasPassedPaymentPending(tx)
      : pageData &&
        pageData.listing &&
        pageData.listing.id &&
        pageData.bookingData &&
        pageData.bookingDates &&
        pageData.bookingDates.bookingStart &&
        pageData?.bookingDates?.bookingEnd &&
        pageData.bookingData.quantity &&
        !isBookingCreated;
    const currentUserChargebeeId = false;
    const currentUserIsCorporateUser = !!this.props?.currentUser?.attributes?.profile?.protectedData
      ?.corporate_user;
    const currentUserEndDate = this.props.currentUser?.attributes?.profile?.protectedData
      ?.FutureDate;
    if (!pageData?.bookingData?.productBooking && shouldFetchSpeculatedTransaction) {
      const { bookingStart, bookingEnd } = pageData.bookingDates;
      const { quantity, priceAddons, discountPriceAddons, seats, price } = pageData.bookingData;
      // this.props.currentUser?.attributes?.profile?.protectedData
      // ?.ChargebeeId;
      const todayDate = moment(new Date()).format('MM/DD/YYYY');
      // Fetch speculated transaction for showing price in booking breakdown
      // NOTE: if unit type is line-item/units, quantity needs to be added.
      // The way to pass it to checkout page is through pageData.bookingData
      this.props.fetchSpeculatedTransaction(
        this.customPricingParams({
          currentUserChargebeeId,
          currentUserIsCorporateUser,
          currentUserEndDate,
          listing: pageData.listing,
          price: price,
          bookingStart,
          bookingEnd,
          quantity,
          priceAddons,
          discountPriceAddons,
          seats,
          paymentType,
          todayDate,
        })
      );
    }
    if (pageData?.bookingData?.productBooking && shouldFetchSpeculatedTransaction) {
      this.props.fetchSpeculatedTransaction(
        this.customPricingParams({
          currentUserChargebeeId,
          currentUserIsCorporateUser,
          currentUserEndDate,
          listing: pageData?.listing,
          stockReservationQuantity: 1,
          paymentType,
          productBooking: true,
        })
      );
    }
    this.handelStepChange(2);
  }

  onModalAccept = () => {
    this.setState({
      isModalAccepted: true,
      isOpen: false,
      step: 2,
    });
  };

  toggleModal = () => {
    this.setState({
      isOpen: false,
    });
  };

  /**
   * Constructs a request params object that can be used when creating bookings
   * using custom pricing.
   * @param {} params An object that contains bookingStart, bookingEnd and listing
   * @return a params object for custom pricing bookings
   */

  customPricingParams(params) {
    const {
      bookingStart,
      bookingEnd,
      currentUserChargebeeId,
      currentUserIsCorporateUser,
      currentUserEndDate,
      listing,
      quantity = 1,
      priceAddons,
      discountPriceAddons,
      seats = 1,
      todayDate,
      productBooking,
      ...rest
    } = params;
    const { amount, currency } =
      !!params.price && params.price.amount && params.price.amount > 0
        ? params.price
        : listing
        ? listing.attributes.price
        : {};

    const unitType = !!(params.price && params.price.amount && params.price.amount > 0)
      ? config.bookingCustomAmount
      : config.bookingUnitType;

    const priceAddonsLineItems =
      priceAddons && priceAddons.length > 0
        ? priceAddons.map(addon => {
            if (addon.code) {
              return addon;
            }
            return {
              code: addonLabelIntoCode(addon.label),
              unitPrice: new Money(addon.amount, addon.currency),
              quantity: quantity,
            };
          })
        : [];

    const discountPriceAddonsLineItems =
      discountPriceAddons && discountPriceAddons.length > 0
        ? discountPriceAddons.map(addon => {
            if (addon.code) {
              return addon;
            }
            return {
              code: addonLabelIntoCode(addon.label),
              unitPrice: new Money((amount * -addon.amount) / 100, currency),
              quantity: quantity,
            };
          })
        : [];

    const chargebeeAddonsLineItems =
      currentUserChargebeeId &&
      todayDate < currentUserEndDate &&
      unitType !== config.bookingCustomAmount
        ? [
            {
              code: addonLabelIntoCode('subscription discount'),
              unitPrice: new Money((amount * -10) / 100, currency),
              quantity: 1,
            },
          ]
        : [];

    const corporateAddonsLineItems =
      currentUserIsCorporateUser &&
      !chargebeeAddonsLineItems.length &&
      unitType !== config.bookingCustomAmount
        ? [
            {
              code: addonLabelIntoCode('corporate user discount'),
              unitPrice: new Money((amount * -10) / 100, currency),
              quantity: 1,
            },
          ]
        : [];

    const addonsTotalPrice = priceAddonsLineItems.reduce((acc, curr) => {
      return acc + curr.unitPrice.amount * curr.quantity;
    }, 0);

    const discountAddonsTotalPrice = discountPriceAddonsLineItems.reduce((acc, curr) => {
      return acc - curr.unitPrice.amount * curr.quantity;
    }, 0);

    const chargebeeAddonsTotalPrice = chargebeeAddonsLineItems.reduce((acc, curr) => {
      return acc - curr.unitPrice.amount * curr.quantity;
    }, 0);

    const corporateAddonsTotalPrice = corporateAddonsLineItems.reduce((acc, curr) => {
      return acc - curr.unitPrice.amount * curr.quantity;
    }, 0);

    const totalPrice =
      amount * quantity * seats +
      addonsTotalPrice -
      discountAddonsTotalPrice -
      chargebeeAddonsTotalPrice -
      corporateAddonsTotalPrice;
    // const payoutTotal = new Money(totalPrice, currency);
    // const payinTotal = new Money(totalPrice, currency);
    const cardFeeAmount = totalPrice * config.creditCardExtraFee; // 5%

    const providerCommissionPercentage =
      CARD_PROVIDER_COMMISSION -
      Number(listing?.attributes?.metadata?.providerCommissionPercentage || 0);

    // while making a card payment, for Chirsto listings it charges 5%, and for others it charges 14% fee
    // const providerCommissionPercentage = listing?.attributes?.metadata?.hasOwnProperty(
    //   'providerCommissionPercentage'
    // )
    //   ? CARD_PROVIDER_COMMISSION -
    //     Number(listing?.attributes?.metadata?.providerCommissionPercentage || 0)
    //   : -14;

    const listingId = listing && listing.id ? new UUID(listing.id.uuid) : '';
    if (productBooking) {
      if (
        (rest && rest.paymentType === PAYMENT_TYPES.card) ||
        this.state.selectedPaymentType === PAYMENT_TYPES.card
      ) {
        return {
          listingId: listingId,
          providerCommissionPercentage,
          // payoutTotal: payoutTotal,
          // payinTotal: payinTotal,
          productBooking,
          lineItems: [
            ...priceAddonsLineItems,
            ...discountPriceAddonsLineItems,
            ...chargebeeAddonsLineItems,
            ...corporateAddonsLineItems,
            {
              code: LINE_ITEM_CARD_SERVICE_FEE,
              unitPrice: new Money(cardFeeAmount, currency),
              quantity: 1,
            },
            {
              code: unitType,
              unitPrice: new Money(amount, currency),
              quantity: 1,
            },
          ],
          bookingStart: bookingStart || new Date(),
          bookingEnd: new Date(),
          ...rest,
        };
      } else {
        return {
          listingId: listingId,
          providerCommissionPercentage: 0,
          productBooking,
          lineItems: [
            ...priceAddonsLineItems,
            ...discountPriceAddonsLineItems,
            ...chargebeeAddonsLineItems,
            ...corporateAddonsLineItems,
            {
              code: unitType,
              unitPrice: new Money(amount, currency),
              quantity: 1,
            },
          ],
          bookingStart: new Date(),
          bookingEnd: new Date(),
          ...rest,
        };
      }
    } else {
      if (
        (rest && rest.paymentType === PAYMENT_TYPES.card) ||
        this.state.selectedPaymentType === PAYMENT_TYPES.card
      ) {
        return {
          listingId: listingId,
          bookingStart,
          bookingEnd,
          quantity,
          providerCommissionPercentage,
          // payoutTotal: payoutTotal,
          // payinTotal: payinTotal,
          lineItems: [
            ...priceAddonsLineItems,
            ...discountPriceAddonsLineItems,
            ...chargebeeAddonsLineItems,
            ...corporateAddonsLineItems,
            {
              code: LINE_ITEM_CARD_SERVICE_FEE,
              unitPrice: new Money(cardFeeAmount, currency),
              quantity: 1,
            },
            {
              code: unitType,
              unitPrice: new Money(amount, currency),
              units: quantity,
              seats: Number(seats),
            },
          ],
          ...rest,
        };
      } else {
        return {
          listingId: listingId,
          bookingStart,
          bookingEnd,
          quantity,
          providerCommissionPercentage: 0,
          // payoutTotal: payoutTotal,
          // payinTotal: payinTotal,
          lineItems: [
            ...priceAddonsLineItems,
            ...discountPriceAddonsLineItems,
            ...chargebeeAddonsLineItems,
            ...corporateAddonsLineItems,
            {
              code: unitType,
              unitPrice: new Money(amount, currency),
              units: quantity,
              seats: Number(seats),
            },
          ],
          ...rest,
        };
      }
    }
  }

  /**
   * Load initial data for the page
   *
   * Since the data for the checkout is not passed in the URL (there
   * might be lots of options in the future), we must pass in the data
   * some other way. Currently the ListingPage sets the initial data
   * for the CheckoutPage's Redux store.
   *
   * For some cases (e.g. a refresh in the CheckoutPage), the Redux
   * store is empty. To handle that case, we store the received data
   * to window.sessionStorage and read it from there if no props from
   * the store exist.
   *
   * This function also sets of fetching the speculative transaction
   * based on this initial data.
   */
  loadInitialData() {
    const {
      bookingData,
      bookingDates,
      listing,
      transaction,
      fetchSpeculatedTransaction,
      fetchStripeCustomer,
      history,
    } = this.props;

    // Fetch currentUser with stripeCustomer entity
    // Note: since there's need for data loading in "componentWillMount" function,
    //       this is added here instead of loadData static function.
    fetchStripeCustomer();

    // Browser's back navigation should not rewrite data in session store.
    // Action is 'POP' on both history.back() and page refresh cases.
    // Action is 'PUSH' when user has directed through a link
    // Action is 'REPLACE' when user has directed through login/signup process
    const hasNavigatedThroughLink = history.action === 'PUSH' || history.action === 'REPLACE';
    const hasDataInProps = bookingData?.productBooking
      ? !!(bookingData && listing) && hasNavigatedThroughLink
      : !!(bookingData && bookingDates && listing) && hasNavigatedThroughLink;
    if (hasDataInProps) {
      // Store data only if data is passed through props and user has navigated through a link.
      storeData(bookingData, bookingDates, listing, transaction, STORAGE_KEY);
    }

    // NOTE: stored data can be empty if user has already successfully completed transaction.
    const pageData = hasDataInProps
      ? bookingData?.productBooking
        ? { orderData: bookingData, bookingData, listing, transaction }
        : { bookingData, bookingDates, listing, transaction }
      : storedData(STORAGE_KEY);

    // Check if a booking is already created according to stored data.
    const tx = pageData ? pageData.transaction : null;
    const isBookingCreated = tx && tx.booking && tx.booking.id;

    const shouldFetchSpeculatedTransaction = bookingData?.productBooking
      ? pageData &&
        pageData.listing &&
        pageData.listing.id &&
        pageData.orderData &&
        !txHasPassedPaymentPending(tx)
      : pageData &&
        pageData.listing &&
        pageData.listing.id &&
        pageData.bookingData &&
        pageData.bookingDates &&
        pageData.bookingDates.bookingStart &&
        pageData.bookingDates.bookingEnd &&
        pageData.bookingData.quantity &&
        !isBookingCreated;

    const currentUserChargebeeId = false;
    // this.props.currentUser?.attributes?.profile?.protectedData
    // ?.ChargebeeId;
    const currentUserIsCorporateUser = !!this.props?.currentUser?.attributes?.profile?.protectedData
      ?.corporate_user;
    const currentUserEndDate = this.props.currentUser?.attributes?.profile?.protectedData
      ?.FutureDate;

    if (!bookingData?.productBooking && shouldFetchSpeculatedTransaction) {
      const { bookingStart, bookingEnd } = pageData.bookingDates;
      const { quantity, priceAddons, discountPriceAddons, seats, price } = pageData.bookingData;
      const currentUserChargebeeId = false;
      // this.props.currentUser?.attributes?.profile?.protectedData
      // ?.ChargebeeId;
      const currentUserIsCorporateUser = !!this.props?.currentUser?.attributes?.profile
        ?.protectedData?.corporate_user;
      const currentUserEndDate = this.props.currentUser?.attributes?.profile?.protectedData
        ?.FutureDate;
      const todayDate = moment(new Date()).format('MM/DD/YYYY');
      // Fetch speculated transaction for showing price in booking breakdown
      // NOTE: if unit type is line-item/units, quantity needs to be added.
      // The way to pass it to checkout page is through pageData.bookingData
      fetchSpeculatedTransaction(
        this.customPricingParams({
          currentUserChargebeeId,
          currentUserIsCorporateUser,
          currentUserEndDate,
          listing: pageData.listing,
          bookingStart,
          bookingEnd,
          quantity,
          priceAddons,
          discountPriceAddons,
          seats,
          price,
          todayDate,
        })
      );
    }
    if (bookingData?.productBooking && shouldFetchSpeculatedTransaction) {
      fetchSpeculatedTransaction(
        this.customPricingParams({
          currentUserChargebeeId,
          currentUserIsCorporateUser,
          currentUserEndDate,
          listing: listing,
          stockReservationQuantity: 1,
          productBooking: true,
        })
      );
    }

    this.setState({ pageData: pageData || {}, dataLoaded: true });
  }

  handlePaymentIntent(handlePaymentParams) {
    const {
      currentUser,
      stripeCustomerFetched,
      onInitiateOrder,
      onHandleCardPayment,
      onConfirmPayment,
      onSendMessage,
      onSavePaymentMethod,
    } = this.props;
    const {
      pageData,
      speculatedTransaction,
      message,
      paymentIntent,
      selectedPaymentMethod,
      saveAfterOnetimePayment,
      kidsName,
    } = handlePaymentParams;
    const storedTx = ensureTransaction(pageData.transaction);

    const ensuredCurrentUser = ensureCurrentUser(currentUser);
    const ensuredStripeCustomer = ensureStripeCustomer(ensuredCurrentUser.stripeCustomer);
    const ensuredDefaultPaymentMethod = ensurePaymentMethodCard(
      ensuredStripeCustomer.defaultPaymentMethod
    );

    let createdPaymentIntent = null;

    const hasDefaultPaymentMethod = !!(
      stripeCustomerFetched &&
      ensuredStripeCustomer.attributes.stripeCustomerId &&
      ensuredDefaultPaymentMethod.id
    );
    const stripePaymentMethodId = hasDefaultPaymentMethod
      ? ensuredDefaultPaymentMethod.attributes.stripePaymentMethodId
      : null;

    const selectedPaymentFlow = paymentFlow(selectedPaymentMethod, saveAfterOnetimePayment);

    // Step 1: initiate order by requesting payment from Marketplace API
    const fnRequestPayment = fnParams => {
      // fnParams should be { listingId, bookingStart, bookingEnd }
      const hasPaymentIntents =
        storedTx.attributes.protectedData && storedTx.attributes.protectedData.stripePaymentIntents;
      // If paymentIntent exists, order has been initiated previously.
      return hasPaymentIntents
        ? Promise.resolve(storedTx)
        : onInitiateOrder(
            {
              ...fnParams,
              listing_mode: pageData?.listing?.attributes?.publicData?.listing_mode,
              title: pageData?.listing?.attributes?.title,
              timeZone: pageData?.listing?.attributes?.availabilityPlan
                ? pageData?.listing?.attributes?.availabilityPlan?.timezone
                : 'Etc/UTC',
              providerCommissionPercentage: fnParams.withoutPayment
                ? 0
                : CARD_PROVIDER_COMMISSION -
                  Number(
                    pageData?.listing?.attributes?.metadata?.providerCommissionPercentage || 0
                  ),
            },
            storedTx.id
          );
    };

    // Step 2: pay using Stripe SDK
    const fnHandleCardPayment = fnParams => {
      // fnParams should be returned transaction entity

      const order = ensureTransaction(fnParams);
      if (order.id) {
        // Store order.
        const { bookingData, bookingDates, listing } = pageData;
        storeData(bookingData, bookingDates, listing, order, STORAGE_KEY);
        this.setState({ pageData: { ...pageData, transaction: order } });
      }

      const hasPaymentIntents =
        order.attributes.protectedData && order.attributes.protectedData.stripePaymentIntents;

      if (!hasPaymentIntents) {
        throw new Error(
          `Missing StripePaymentIntents key in transaction's protectedData. Check that your transaction process is configured to use payment intents.`
        );
      }

      const { stripePaymentIntentClientSecret } = hasPaymentIntents
        ? order.attributes.protectedData.stripePaymentIntents.default
        : null;

      const { stripe, card, billingDetails, paymentIntent } = handlePaymentParams;
      const stripeElementMaybe = selectedPaymentFlow !== USE_SAVED_CARD ? { card } : {};

      // Note: payment_method could be set here for USE_SAVED_CARD flow.
      // { payment_method: stripePaymentMethodId }
      // However, we have set it already on API side, when PaymentIntent was created.
      const paymentParams =
        selectedPaymentFlow !== USE_SAVED_CARD
          ? {
              payment_method_data: {
                billing_details: billingDetails,
              },
            }
          : {};

      const params = {
        stripePaymentIntentClientSecret,
        orderId: order.id,
        stripe,
        ...stripeElementMaybe,
        paymentParams,
      };

      // If paymentIntent status is not waiting user action,
      // handleCardPayment has been called previously.
      const hasPaymentIntentUserActionsDone =
        paymentIntent && STRIPE_PI_USER_ACTIONS_DONE_STATUSES.includes(paymentIntent.status);
      return hasPaymentIntentUserActionsDone
        ? Promise.resolve({ transactionId: order.id, paymentIntent })
        : onHandleCardPayment(params);
    };

    // Step 3: complete order by confirming payment to Marketplace API
    // Parameter should contain { paymentIntent, transactionId } returned in step 2
    const fnConfirmPayment = fnParams => {
      const order = ensureTransaction(fnParams);
      const subamount =
        Number(pageData?.listing?.attributes?.price?.amount) *
        Number(pageData?.bookingData?.quantity) *
        Number(pageData?.bookingData?.seats);
      const feeAmount = Number(fnParams?.paymentIntent?.amount) - subamount;

      try {
        const templateParams = {
          className: pageData?.listing?.attributes?.title,
          toName: currentUser?.attributes?.profile?.displayName,
          startTime: moment(pageData?.bookingDates?.bookingStart).format('hh:mm a'),
          endTime: moment(pageData?.bookingDates?.bookingEnd).format('hh:mm a'),
          startDate: moment(pageData?.bookingDates?.bookingStart).format('MMMM D'),
          endDate: moment(pageData?.bookingDates?.bookingEnd).format('MMMM D'),
          amount: `${pageData?.listing?.attributes?.price?.amount / 100}`,
          subamount: `${Number(subamount) / 100}`,
          currency: `${order.paymentIntent.currency.toUpperCase()}`,
          unit: pageData?.bookingData?.quantity,
          seat: pageData?.bookingData?.seats,
          feeAmount: `${Number(feeAmount) / 100}`,
          hourText: 'Hours',
          totalAmount: Number(fnParams?.paymentIntent?.amount) / 100,
          mailTo: currentUser?.attributes?.email,
          mailText: 'requested to book',
        };

        process.env.REACT_APP_ENV === 'production' &&
          emailjs
            .send('service_o59sg3q', 'template_q6uoneb', templateParams, 'DkU54piL9T3fDaZV1')
            .then();
      } catch (e) {
        console.log(e);
      }
      createdPaymentIntent = fnParams.paymentIntent;
      return onConfirmPayment(fnParams);
    };

    // Step 4: send initial message
    const fnSendMessage = fnParams => {
      return onSendMessage({ ...fnParams, message });
    };

    // Step 5: optionally save card as defaultPaymentMethod
    const fnSavePaymentMethod = fnParams => {
      const pi = createdPaymentIntent || paymentIntent;

      if (selectedPaymentFlow === PAY_AND_SAVE_FOR_LATER_USE) {
        return onSavePaymentMethod(ensuredStripeCustomer, pi.payment_method)
          .then(response => {
            if (response.errors) {
              return { ...fnParams, paymentMethodSaved: false };
            }
            return { ...fnParams, paymentMethodSaved: true };
          })
          .catch(e => {
            // Real error cases are catched already in paymentMethods page.
            return { ...fnParams, paymentMethodSaved: false };
          });
      } else {
        return Promise.resolve({ ...fnParams, paymentMethodSaved: true });
      }
    };

    // Here we create promise calls in sequence
    // This is pretty much the same as:
    // fnRequestPayment({...initialParams})
    //   .then(result => fnHandleCardPayment({...result}))
    //   .then(result => fnConfirmPayment({...result}))
    const applyAsync = (acc, val) => acc.then(val);
    const composeAsync = (...funcs) => x => funcs.reduce(applyAsync, Promise.resolve(x));
    const handlePaymentIntentCreation = composeAsync(
      fnRequestPayment,
      fnHandleCardPayment,
      fnConfirmPayment,
      fnSendMessage,
      fnSavePaymentMethod
    );

    // Create order aka transaction
    // NOTE: if unit type is line-item/units, quantity needs to be added.
    // The way to pass it to checkout page is through pageData.bookingData
    const tx = speculatedTransaction ? speculatedTransaction : storedTx;

    // Filter price addons from other line-items
    const priceAddons = tx.attributes.lineItems.filter(item => {
      const filterOutCodes = [
        LINE_ITEM_UNITS,
        LINE_ITEM_CUSTOMER_COMMISSION,
        LINE_ITEM_PROVIDER_COMMISSION,
        LINE_ITEM_CARD_SERVICE_FEE,
        LINE_ITEM_CUSTOM_AMOUNT,
      ];
      return !filterOutCodes.includes(item.code);
    });

    // const discountPriceAddons = tx.attributes.lineItems.filter(item => {
    //   const filterOutCodes = [
    //     LINE_ITEM_UNITS,
    //     LINE_ITEM_CUSTOMER_COMMISSION,
    //     LINE_ITEM_PROVIDER_COMMISSION,
    //     LINE_ITEM_CARD_SERVICE_FEE,
    //     LINE_ITEM_CUSTOM_AMOUNT,
    //   ];
    //   return !filterOutCodes.includes(item.code);
    // });

    // Note: optionalPaymentParams contains Stripe paymentMethod,
    // but that can also be passed on Step 2
    // stripe.handleCardPayment(stripe, { payment_method: stripePaymentMethodId })
    const optionalPaymentParams =
      selectedPaymentFlow === USE_SAVED_CARD && hasDefaultPaymentMethod
        ? { paymentMethod: stripePaymentMethodId }
        : selectedPaymentFlow === PAY_AND_SAVE_FOR_LATER_USE
        ? { setupPaymentMethodForSaving: true }
        : {};

    const orderParams = pageData?.bookingData?.productBooking
      ? this.customPricingParams({
          listing: pageData.listing,
          quantity: 1,
          stockReservationQuantity: 1,
          ...optionalPaymentParams,
          productBooking: pageData?.bookingData?.productBooking,
        })
      : this.customPricingParams({
          listing: pageData.listing,
          price: pageData.bookingData?.price,
          bookingStart: tx.booking.attributes.start,
          bookingEnd: tx.booking.attributes.end,
          quantity: pageData.bookingData ? pageData.bookingData.quantity : null,
          seats: pageData.bookingData ? pageData.bookingData.seats : null,
          priceAddons,
          // discountPriceAddons,
          kidsName,
          ...optionalPaymentParams,
        });

    return handlePaymentIntentCreation(orderParams);
  }

  handleOrder(handlePaymentParams) {
    const {
      currentUser,
      onInitiateOrder,
      onHitPay,
      onConfirmPayment,
      onSendMessage,
      onUpdateCredits,
    } = this.props;
    const {
      pageData,
      speculatedTransaction,
      message,
      paymentProofSsUrls,
      credits,
      membership,
      cash,
      hitpay,
      kidsName,
    } = handlePaymentParams;
    const storedTx = ensureTransaction(pageData.transaction);

    // Step 1
    const fnRequestOrder = fnParams => {
      return onInitiateOrder(
        {
          ...fnParams,
          withoutPayment: true,
          credits,
          paymentProofSsUrls,
          membership,
          cash,
          kidsName,
          hitpay,
          listing_mode: pageData?.listing?.attributes?.publicData?.listing_mode,
          title: pageData?.listing?.attributes?.title,
          timeZone: pageData?.listing?.attributes?.availabilityPlan
            ? pageData?.listing?.attributes?.availabilityPlan?.timezone
            : 'Etc/UTC',
          providerCommissionPercentage: 0,
        },
        storedTx.id
      );
    };

    // Step 2
    const fnHandleOrder = fnParams => {
      const order = ensureTransaction(fnParams);

      const feeAmountCard = order?.attributes?.lineItems.filter(
        item => item.code === LINE_ITEM_CARD_SERVICE_FEE
      );
      const subamount = Number(order?.attributes?.payinTotal?.amount) / 100;
      const feeAmount =
        feeAmountCard && feeAmountCard.length && feeAmountCard[0] && feeAmountCard[0].unitPrice
          ? Number(feeAmountCard[0].unitPrice.amount * 0.05)
          : 0;

      try {
        const templateParams = {
          className: pageData?.listing?.attributes?.title,
          toName: currentUser?.attributes?.profile?.displayName,
          startTime: moment(pageData?.bookingDates?.bookingStart).format('hh:mm a'),
          endTime: moment(pageData?.bookingDates?.bookingEnd).format('hh:mm a'),
          startDate: moment(pageData?.bookingDates?.bookingStart).format('MMMM D'),
          endDate: moment(pageData?.bookingDates?.bookingEnd).format('MMMM D'),
          amount: `${pageData?.listing?.attributes?.price?.amount / 100}.00`,
          subamount: subamount,
          currency: order?.attributes?.payoutTotal?.currency,
          unit: pageData?.bookingData?.quantity,
          seat: pageData?.bookingData?.seats,
          feeAmount: `${Number(feeAmount) / 100}.00`,
          hourText: 'Hours',
          totalAmount: `${subamount + feeAmount}`,
          mailTo: currentUser?.attributes?.email,
          mailText: 'requested to book',
          priceText: 'Price per hour',
        };

        process.env.REACT_APP_ENV === 'production' &&
          emailjs
            .send('service_o59sg3q', 'template_q6uoneb', templateParams, 'DkU54piL9T3fDaZV1')
            .then();
      } catch (e) {
        console.log(e);
      }
      if (order.id) {
        // Store order.
        const { bookingData, bookingDates, listing } = pageData;
        storeData(bookingData, bookingDates, listing, order, STORAGE_KEY);
        this.setState({ pageData: { ...pageData, transaction: order } });
      }

      return Promise.resolve({ transactionId: order.id });
    };

    // Step 3
    const fnConfirmOrder = fnParams => {
      const { transactionId } = fnParams;
      const { bookingDates } = pageData;
      if (credits === 'true') {
        // const provider = pageData && pageData.listing;
        return onUpdateCredits({
          listingId: pageData.listing.id.uuid,
          currentUser: currentUser,
          transactionId: transactionId,
          bookingDates: bookingDates,
        })
          .then(response => {
            return onConfirmPayment(fnParams);
          })
          .catch(e => {
            this.setState({
              errorUpdateCredits: true,
            });
          });
      } else if (hitpay) {
        // In case of hitpay confirmation of order is from backend
        return onHitPay({
          listingId: pageData.listing.id.uuid,
          currentUser: currentUser,
          transactionId: transactionId,
          bookingDates: bookingDates,
        })
          .then(response => {
            if (response && response.data && typeof window !== 'undefined') {
              window.location.href = response.data;
            } else {
              throw new Error('HitPay payment request error');
            }
          })
          .catch(e => {
            throw e;
          });
      } else {
        return onConfirmPayment(fnParams);
      }
    };

    // Step 4: send initial message
    const fnSendMessage = fnParams => {
      return onSendMessage({ ...fnParams, message });
    };

    const handleOrderCreation = composeAsync(
      fnRequestOrder,
      fnHandleOrder,
      fnConfirmOrder,
      fnSendMessage
    );

    const tx = speculatedTransaction ? speculatedTransaction : storedTx;

    const priceAddons = tx.attributes.lineItems.filter(item => {
      const filterOutCodes = [
        LINE_ITEM_UNITS,
        LINE_ITEM_CUSTOMER_COMMISSION,
        LINE_ITEM_PROVIDER_COMMISSION,
        LINE_ITEM_CUSTOM_AMOUNT,
      ];
      return !filterOutCodes.includes(item.code);
    });

    // const discountPriceAddons = tx.attributes.lineItems.filter(item => {
    //   const filterOutCodes = [
    //     LINE_ITEM_UNITS,
    //     LINE_ITEM_CUSTOMER_COMMISSION,
    //     LINE_ITEM_PROVIDER_COMMISSION,
    //     LINE_ITEM_CUSTOM_AMOUNT,
    //   ];
    //   return !filterOutCodes.includes(item.code);
    // });

    const orderParams = pageData?.bookingData?.productBooking
      ? this.customPricingParams({
          listing: pageData.listing,
          quantity: 1,
          productBooking: true,
          stockReservationQuantity: 1,
        })
      : this.customPricingParams({
          listing: pageData.listing,
          price: pageData.bookingData.price,
          bookingStart: tx.booking.attributes.start,
          bookingEnd: tx.booking.attributes.end,
          quantity: pageData.bookingData ? pageData.bookingData.quantity : null,
          seats: pageData.bookingData ? pageData.bookingData.seats : null,
          priceAddons,
          // discountPriceAddons,
        });

    return handleOrderCreation(orderParams);
  }

  handleSubmitOrder(values) {
    if (this.state.submitting) {
      return;
    }
    this.setState({ submitting: true });

    const { history, speculatedTransaction, dispatch, params } = this.props;
    const { message, formValues, paymentProofSsUrls } = values;
    const { membership = null, kidsName = null } = formValues;

    const credits = params.credits;
    if (credits === 'true') {
      const { period = 1 } = this.state.pageData.bookingData;
      // set initial data for period equals to 1 week
      const variableDataArray = [
        {
          message,
          paymentProofSsUrls,
          credits: credits,
          pageData: this.state.pageData,
          speculatedTransaction: speculatedTransaction,
          membership,
          kidsName,
        },
      ];
      // if period is bigger, add entries of transaction and pageData with
      // adjusted dates for every extra week
      for (let i = 0; i < period - 1; i++) {
        const booking = { ...speculatedTransaction.booking };
        let bookingDates = { ...this.state.pageData.bookingDates };
        booking.attributes = {
          ...booking.attributes,
          displayEnd: moment(booking.attributes.displayEnd)
            .add(i + 1, 'week')
            .toDate(),
          displayStart: moment(booking.attributes.displayStart)
            .add(i + 1, 'week')
            .toDate(),
          start: moment(booking.attributes.start)
            .add(i + 1, 'week')
            .toDate(),
          end: moment(booking.attributes.end)
            .add(i + 1, 'week')
            .toDate(),
        };

        bookingDates = {
          bookingEnd: moment(bookingDates.bookingEnd)
            .add(i + 1, 'week')
            .toDate(),
          bookingStart: moment(bookingDates.bookingStart)
            .add(i + 1, 'week')
            .toDate(),
        };

        variableDataArray.push({
          message,
          paymentProofSsUrls,
          credits: credits,
          pageData: { ...this.state.pageData, bookingDates },
          speculatedTransaction: {
            ...speculatedTransaction,
            booking: booking,
          },
          membership,
          kidsName,
        });
      }

      sequence(variableDataArray, this.handleOrder)
        .then(res => {
          const { orderId, messageSuccess } = res;
          this.setState({ submitting: false });

          const routes = routeConfiguration();
          const initialMessageFailedToTransaction = messageSuccess ? null : orderId;
          const orderDetailsPath = pathByRouteName('OrderDetailsPage', routes, {
            id: orderId.uuid,
          });
          const initialValues = {
            initialMessageFailedToTransaction,
          };

          initializeOrderPage(initialValues, routes, dispatch);
          clearData(STORAGE_KEY);
          history.push(orderDetailsPath);
        })
        .catch(err => {
          console.error(err);
          this.setState({ submitting: false });
        });
    } else {
      localStorage.setItem('membershipNumber', membership);
      const requestPaymentParams = {
        pageData: this.state.pageData,
        speculatedTransaction,
        message,
        paymentProofSsUrls,
        credits: this.props.params.credits,
        cash: this.props.params.paymentType === PAYMENT_TYPES.cash,
        hitpay: this.state.selectedPaymentType === PAYMENT_TYPES.hitpay,
        membership,
        kidsName,
      };

      this.handleOrder(requestPaymentParams)
        .then(res => {
          const { orderId, messageSuccess } = res;
          this.setState({ submitting: false });

          const routes = routeConfiguration();
          const initialMessageFailedToTransaction = messageSuccess ? null : orderId;
          const orderDetailsPath = pathByRouteName('OrderDetailsPage', routes, {
            id: orderId.uuid,
          });
          const initialValues = {
            initialMessageFailedToTransaction,
          };

          initializeOrderPage(initialValues, routes, dispatch);
          clearData(STORAGE_KEY);
          history.push(orderDetailsPath);
        })
        .catch(err => {
          console.error(err);
          this.setState({ submitting: false });
        });
    }
  }

  handleSubmit(values) {
    if (this.state.submitting) {
      return;
    }
    this.setState({ submitting: true });

    const { history, speculatedTransaction, currentUser, paymentIntent, dispatch } = this.props;
    const { card, message, paymentMethod, formValues } = values;
    const {
      name,
      addressLine1,
      addressLine2,
      postal,
      city,
      state,
      country,
      saveAfterOnetimePayment,
      kidsName,
    } = formValues;

    // Billing address is recommended.
    // However, let's not assume that <StripePaymentAddress> data is among formValues.
    // Read more about this from Stripe's docs
    // https://stripe.com/docs/stripe-js/reference#stripe-handle-card-payment-no-element
    const addressMaybe =
      addressLine1 && postal
        ? {
            address: {
              city: city,
              country: country,
              line1: addressLine1,
              line2: addressLine2,
              postal_code: postal,
              state: state,
            },
          }
        : {};
    const billingDetails = {
      name,
      email: ensureCurrentUser(currentUser).attributes.email,
      ...addressMaybe,
    };

    const requestPaymentParams = {
      pageData: this.state.pageData,
      speculatedTransaction,
      stripe: this.stripe,
      card,
      billingDetails,
      message,
      paymentIntent,
      selectedPaymentMethod: paymentMethod,
      saveAfterOnetimePayment: !!saveAfterOnetimePayment,
      kidsName,
    };

    this.handlePaymentIntent(requestPaymentParams)
      .then(res => {
        const { orderId, messageSuccess, paymentMethodSaved } = res;
        this.setState({ submitting: false });

        const routes = routeConfiguration();
        const initialMessageFailedToTransaction = messageSuccess ? null : orderId;
        const orderDetailsPath = pathByRouteName('OrderDetailsPage', routes, { id: orderId.uuid });
        const initialValues = {
          initialMessageFailedToTransaction,
          savePaymentMethodFailed: !paymentMethodSaved,
        };

        initializeOrderPage(initialValues, routes, dispatch);
        clearData(STORAGE_KEY);
        history.push(orderDetailsPath);
      })
      .catch(err => {
        console.error(err);
        this.setState({ submitting: false });
      });
  }

  onStripeInitialized(stripe) {
    this.stripe = stripe;

    const { paymentIntent, onRetrievePaymentIntent } = this.props;
    const tx = this.state.pageData ? this.state.pageData.transaction : null;

    // We need to get up to date PI, if booking is created but payment is not expired.
    const shouldFetchPaymentIntent =
      this.stripe &&
      !paymentIntent &&
      tx &&
      tx.id &&
      tx.booking &&
      tx.booking.id &&
      txIsPaymentPending(tx) &&
      !checkIsPaymentExpired(tx);

    if (shouldFetchPaymentIntent) {
      const { stripePaymentIntentClientSecret } =
        tx.attributes.protectedData && tx.attributes.protectedData.stripePaymentIntents
          ? tx.attributes.protectedData.stripePaymentIntents.default
          : {};

      // Fetch up to date PaymentIntent from Stripe
      onRetrievePaymentIntent({ stripe, stripePaymentIntentClientSecret });
    }
  }

  resetPage() {
    this.props.history.push(
      createResourceLocatorString(
        this.props.isEducationSite ? 'EducationPage' : 'HomePage',
        routeConfiguration(),
        {}
      )
    );
    window?.location && window.location.reload(false);
  }

  render() {
    const {
      scrollingDisabled,
      speculateTransactionInProgress,
      speculateTransactionError,
      speculatedTransaction: speculatedTransactionMaybe,
      initiateOrderError,
      confirmPaymentError,
      intl,
      params,
      currentUser,
      handleCardPaymentError,
      paymentIntent,
      retrievePaymentIntentError,
      stripeCustomerFetched,
      viewport,
    } = this.props;
    // Since the listing data is already given from the ListingPage
    // and stored to handle refreshes, it might not have the possible
    // deleted or closed information in it. If the transaction
    // initiate or the speculative initiate fail due to the listing
    // being deleted or closec, we should dig the information from the
    // errors and not the listing data.
    const listingNotFound =
      isTransactionInitiateListingNotFoundError(speculateTransactionError) ||
      isTransactionInitiateListingNotFoundError(initiateOrderError);

    const isLoading = !this.state.dataLoaded || speculateTransactionInProgress;
    const { listing, bookingDates, transaction } = this.state.pageData;
    const existingTransaction = ensureTransaction(transaction);
    const speculatedTransaction = ensureTransaction(speculatedTransactionMaybe, {}, null);
    const currentListing = ensureListing(listing);
    const currentAuthor = ensureUser(currentListing.author);

    const listingTitle = currentListing.attributes.title;
    const title = intl.formatMessage({ id: 'CheckoutPage.title' }, { listingTitle });
    const isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;
    const listingVariant = currentListing.attributes.publicData.listing_type;
    const isProductListing = listingVariant === LISTING_TYPES.EDUCATION_COURSES;
    const isEducationSite =
      [
        LISTING_TYPES.ACADEMY,
        LISTING_TYPES.COURSES,
        LISTING_TYPES.EDUCATION_COURSES,
        LISTING_TYPES.HOURLY_SESSION,
        LISTING_TYPES.TUTOR,
        LISTING_TYPES.REMOTE_TUTOR,
      ].includes(listingVariant) ||
      (listingVariant === LISTING_TYPES.ENQUIRY
        ? ['language', 'academic', 'management', 'art'].includes(
            currentListing?.attributes?.publicData?.category
          )
        : false);

    const pageProps = { title, scrollingDisabled };
    const topbar = (
      <HeaderContainer
        onSearch={this.onSearch}
        onTabChangeSearch={this.onTabChangeSearch}
        handleHeaderSearchBar={this.handleHeaderSearchBar}
        handleOpacityChange={this.handleOpacityChange}
        openLeftBar={() => this.setState({ openLeftBar: true })}
        isMobileLayout={isMobileLayout}
        currentTab={tab => {
          this.setState({ currentTab: tab });
        }}
        currentUser={this.props.currentUser}
        onChangeActivity={value => this.setState({ selectedSubCategory: value })}
        hideBottomHeader={true}
        disableFilterScroll={value => this.setState({ disableFilterScroll: value })}
        showOpacity={this.state.showOpacity}
        isScrolling={this.state.isScrolling}
        isAuthenticated={this.props.isAuthenticated}
        logoutInProgress={this.props.logoutInProgress}
        logout={this.props.onLogout}
        isEducationSite={isEducationSite}
      />
    );

    if (isLoading) {
      return <Page {...pageProps}>{topbar}</Page>;
    }

    const isOwnListing =
      currentUser &&
      currentUser.id &&
      currentAuthor &&
      currentAuthor.id &&
      currentAuthor.id.uuid === currentUser.id.uuid;

    const hasListingAndAuthor = !!(currentListing.id && currentAuthor.id);
    const hasBookingDates = !!(
      bookingDates &&
      bookingDates.bookingStart &&
      bookingDates.bookingEnd
    );
    const hasRequiredData = isProductListing
      ? hasListingAndAuthor
      : hasListingAndAuthor && hasBookingDates;
    const canShowPage = hasRequiredData && !isOwnListing;
    const shouldRedirect = !isLoading && !canShowPage;

    // Redirect back to ListingPage if data is missing.
    // Redirection must happen before any data format error is thrown (e.g. wrong currency)
    if (shouldRedirect) {
      // eslint-disable-next-line no-console
      console.error('Missing or invalid data for checkout, redirecting back to listing page.', {
        transaction: speculatedTransaction,
        bookingDates,
        listing,
      });
      return <NamedRedirect name="ListingPage" params={params} />;
    }

    // Show breakdown only when speculated transaction and booking are loaded
    // (i.e. have an id)
    const tx = speculatedTransaction;
    // const tx = existingTransaction.booking ? existingTransaction : speculatedTransaction;
    const txBooking = ensureBooking(tx.booking);
    const timeZone = currentListing.attributes.availabilityPlan
      ? currentListing.attributes.availabilityPlan.timezone
      : 'Etc/UTC';
    const unitt =
      tx?.attributes?.lineItems && tx?.attributes?.lineItems[0]?.code === config.bookingCustomAmount
        ? config.bookingCustomAmount
        : config.bookingUnitType;
    const txBookingMaybe = tx.booking?.id
      ? { booking: ensureBooking(tx.booking), dateType: DATE_TYPE_DATE }
      : {};
    const breakdown = isProductListing ? (
      tx.id && tx.attributes.lineItems?.length ? (
        <OrderBreakdown
          className={css.orderBreakdown}
          userRole="customer"
          unitType={unitt || ''}
          transaction={tx}
          {...txBookingMaybe}
        />
      ) : null
    ) : tx.id && txBooking.id ? (
      <BookingBreakdown
        className={css.bookingBreakdown}
        userRole="customer"
        unitType={unitt}
        transaction={tx}
        booking={txBooking}
        dateType={DATE_TYPE_DATETIME}
        timeZone={timeZone}
        listing={listing}
      />
    ) : null;

    const isPaymentExpired = checkIsPaymentExpired(existingTransaction);
    const hasDefaultPaymentMethod = !!(
      stripeCustomerFetched &&
      ensureStripeCustomer(currentUser.stripeCustomer).attributes.stripeCustomerId &&
      ensurePaymentMethodCard(currentUser.stripeCustomer.defaultPaymentMethod).id
    );

    // Allow showing page when currentUser is still being downloaded,
    // but show payment form only when user info is loaded.
    const showPaymentForm = !!(
      currentUser &&
      hasRequiredData &&
      !listingNotFound &&
      !initiateOrderError &&
      !speculateTransactionError &&
      !retrievePaymentIntentError &&
      !isPaymentExpired
    );
    const firstImage =
      currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

    const listingLink = (
      <NamedLink
        name="ListingPage"
        params={{ id: currentListing.id.uuid, slug: createSlug(listingTitle) }}
      >
        <FormattedMessage id="CheckoutPage.errorlistingLinkText" />
      </NamedLink>
    );

    const isAmountTooLowError = isTransactionInitiateAmountTooLowError(initiateOrderError);
    const isChargeDisabledError = isTransactionChargeDisabledError(initiateOrderError);
    const isBookingTimeNotAvailableError = isTransactionInitiateBookingTimeNotAvailableError(
      initiateOrderError
    );
    const stripeErrors = transactionInitiateOrderStripeErrors(initiateOrderError);

    let initiateOrderErrorMessage = null;
    let listingNotFoundErrorMessage = null;

    if (listingNotFound) {
      listingNotFoundErrorMessage = (
        <p className={css.notFoundError}>
          <FormattedMessage id="CheckoutPage.listingNotFoundError" />
        </p>
      );
    } else if (isAmountTooLowError) {
      initiateOrderErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.initiateOrderAmountTooLow" />
        </p>
      );
    } else if (isBookingTimeNotAvailableError) {
      initiateOrderErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.bookingTimeNotAvailableMessage" />
        </p>
      );
    } else if (isChargeDisabledError) {
      initiateOrderErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.chargeDisabledMessage" />
        </p>
      );
    } else if (stripeErrors && stripeErrors.length > 0) {
      // NOTE: Error messages from Stripes are not part of translations.
      // By default they are in English.
      const stripeErrorsAsString = stripeErrors.join(', ');
      initiateOrderErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage
            id="CheckoutPage.initiateOrderStripeError"
            values={{ stripeErrors: stripeErrorsAsString }}
          />
        </p>
      );
    } else if (initiateOrderError) {
      // Generic initiate order error
      initiateOrderErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.initiateOrderError" values={{ listingLink }} />
        </p>
      );
    }

    const speculateTransactionErrorMessage = speculateTransactionError ? (
      <p className={css.speculateError}>
        <FormattedMessage id="CheckoutPage.speculateTransactionError" />
      </p>
    ) : null;
    let speculateErrorMessage = null;

    if (isTransactionInitiateMissingStripeAccountError(speculateTransactionError)) {
      speculateErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.providerStripeAccountMissingError" />
        </p>
      );
    } else if (isTransactionInitiateBookingTimeNotAvailableError(speculateTransactionError)) {
      speculateErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.bookingTimeNotAvailableMessage" />
        </p>
      );
    } else if (isTransactionZeroPaymentError(speculateTransactionError)) {
      speculateErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.initiateOrderAmountTooLow" />
        </p>
      );
    } else if (speculateTransactionError) {
      speculateErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.speculateFailedMessage" />
        </p>
      );
    }

    const unitType = config.bookingUnitType;
    const isNightly = unitType === LINE_ITEM_NIGHT;
    const isDaily = unitType === LINE_ITEM_DAY;

    const unitTranslationKey = isNightly
      ? 'CheckoutPage.perNight'
      : isDaily
      ? 'CheckoutPage.perDay'
      : 'CheckoutPage.perUnit';

    const price = currentListing.attributes.price;
    const { bankDetails } = currentListing.attributes.publicData;
    const formattedPrice = formatMoney(intl, price);
    const detailsSubTitle = `${formattedPrice} ${intl.formatMessage({
      id: unitTranslationKey,
    })}`;

    const showInitialMessageInput = !(
      existingTransaction && existingTransaction.attributes.lastTransition === TRANSITION_ENQUIRE
    );
    const selectedPaymentMethod =
      currentListing.attributes.publicData.payment_method &&
      currentListing.attributes.publicData.payment_method.length !== 0
        ? currentListing.attributes.publicData.payment_method
        : isEducationSite
        ? ['card', 'member']
        : ['cash', 'card', 'member', 'bank', 'hitpay'];
    // Get first and last name of the current user and use it in the StripePaymentForm to autofill the name field
    const userName =
      currentUser && currentUser.attributes
        ? `${currentUser.attributes.profile.firstName} ${currentUser.attributes.profile.lastName}`
        : null;

    // If paymentIntent status is not waiting user action,
    // handleCardPayment has been called previously.
    const hasPaymentIntentUserActionsDone =
      paymentIntent && STRIPE_PI_USER_ACTIONS_DONE_STATUSES.includes(paymentIntent.status);

    // If your marketplace works mostly in one country you can use initial values to select country automatically
    // e.g. {country: 'FI'}

    const initalValuesForStripePayment = { name: userName };
    const credits = params.credits;
    const { step } = this.state;

    return (
      <Page {...pageProps} className={css.pageContainer}>
        <div>{topbar}</div>
        <div className={css.contentContainer}>
          <div className={step === 2 ? css.modifiedBookListingContainer : css.bookListingContainer}>
            {this.state.step === 2 && (
              <div className={css.heading}>
                <h1 className={css.title}>{title}</h1>
                {this.state.selectedPaymentType === PAYMENT_TYPES.hitpay && (
                  <span className={css.infoSpan}>
                    For mobile phone please Screenshot the QR, go to the banking app and use the
                    from your album option to upload the QR
                  </span>
                )}
              </div>
            )}
            {this.state.step === 1 && (
              <div>
                <h3 className={css.paymentSelectHeading}>
                  <FormattedMessage id={'PaymentTypeSelect.heading'} />
                </h3>
                <div className={css.options}>
                  {selectedPaymentMethod.includes('card') && (
                    <button
                      type="button"
                      className={
                        this.state.selectedPaymentType === PAYMENT_TYPES.card
                          ? css.selectedPaymentOptionItem
                          : css.optionItem
                      }
                      onClick={() => this.handelPaymentTypeSelecting(PAYMENT_TYPES.card)}
                    >
                      <div className={css.label}>
                        <FormattedMessage id={'PaymentTypeSelect.cardPaymentLabel'} tagName="p" />
                      </div>
                      <ul className={css.icons}>
                        <li>
                          <img src={VisaIcon} alt="VisaIcon" />
                        </li>
                        <li>
                          <img src={MasterCardIcon} alt="MasterCardIcon" />
                        </li>
                        <li>
                          <img src={AmericanExpressIcon} alt="AmericanExpressIcon" />
                        </li>
                        <li>
                          <img src={PaypalIcon} alt="PaypalIcon" />
                        </li>
                        <li>
                          <img src={JCBIcon} alt="JCBIcon" />
                        </li>
                      </ul>
                    </button>
                  )}

                  {selectedPaymentMethod.includes('bank') && (
                    <button
                      type="button"
                      className={
                        this.state.selectedPaymentType === PAYMENT_TYPES.direct
                          ? css.selectedPaymentOptionItem
                          : css.optionItem
                      }
                      onClick={() => this.handelPaymentTypeSelecting(PAYMENT_TYPES.direct)}
                    >
                      {/* <div className={css.icon}>
                    <IconBank />
                  </div> */}
                      <div className={css.label}>
                        <FormattedMessage
                          id={'PaymentTypeSelect.directPaymentLabel'}
                          values={{ br: <br /> }}
                          tagName="p"
                        />
                      </div>
                      <ul className={css.icons}>
                        <li>
                          <img src={BankIcon} alt="BankIcon" />
                        </li>
                        <li>
                          <img src={PayNowIcon} alt="PayNowIcon" />
                        </li>
                      </ul>
                    </button>
                  )}
                  {selectedPaymentMethod.includes('member') && (
                    <button
                      type="button"
                      className={
                        this.state.selectedPaymentType === PAYMENT_TYPES.membership
                          ? css.selectedPaymentOptionItem
                          : css.optionItem
                      }
                      onClick={() => this.handelPaymentTypeSelecting(PAYMENT_TYPES.membership)}
                    >
                      <div className={css.label}>
                        <FormattedMessage
                          id={'PaymentTypeSelect.membershipPaymentLabel'}
                          tagName="p"
                        />
                      </div>
                      <ul className={css.icons}>
                        <li>
                          <img src={MembershipIcon} alt="MembershipIcon" />
                        </li>
                      </ul>
                    </button>
                  )}
                  {(selectedPaymentMethod.includes('hitPay') ||
                    selectedPaymentMethod.includes('hitpay')) && (
                    <button
                      type="button"
                      className={
                        this.state.selectedPaymentType === PAYMENT_TYPES.hitpay
                          ? css.selectedPaymentOptionItem
                          : css.optionItem
                      }
                      onClick={() => this.handelPaymentTypeSelecting(PAYMENT_TYPES.hitpay)}
                    >
                      {/* <div className={css.icon}>
                    <IconHitpay />
                  </div> */}
                      <div className={css.label}>
                        <FormattedMessage id={'PaymentTypeSelect.hitpayLabel'} tagName="p" />
                      </div>
                      <ul className={css.icons}>
                        <li>
                          <img src={HitPayIcon} alt="HitPayIcon" />
                        </li>
                      </ul>
                    </button>
                  )}

                  {/* {selectedPaymentMethod.includes('requestOnly') && (
                    <button
                      type="button"
                      className={
                        this.state.selectedPaymentType === PAYMENT_TYPES.requestOnly
                          ? css.selectedPaymentOptionItem
                          : css.optionItem
                      }
                      onClick={() => this.handelPaymentTypeSelecting(PAYMENT_TYPES.requestOnly)}
                    >
                      <div className={css.label}>
                        <FormattedMessage id={'PaymentTypeSelect.requestOnlyLabel'} tagName="p" />
                      </div>
                      <ul className={css.icons}>
                        <li>
                          <img src={MembershipIcon} alt="MembershipIcon" />
                        </li>
                      </ul>
                    </button>
                  )} */}
                </div>
              </div>
            )}

            {this.state.step === 2 && this.state.selectedPaymentType === PAYMENT_TYPES.card && (
              <section className={css.paymentContainer}>
                {initiateOrderErrorMessage}
                {listingNotFoundErrorMessage}
                {speculateErrorMessage}
                {retrievePaymentIntentError ? (
                  <p className={css.orderError}>
                    <FormattedMessage
                      id="CheckoutPage.retrievingStripePaymentIntentFailed"
                      values={{ listingLink }}
                    />
                  </p>
                ) : null}
                {showPaymentForm ? (
                  <StripePaymentForm
                    className={css.paymentForm}
                    onSubmit={this.handleSubmit}
                    inProgress={this.state.submitting}
                    formId="CheckoutPagePaymentForm"
                    paymentInfo={intl.formatMessage({ id: 'CheckoutPage.paymentInfo' })}
                    authorDisplayName={currentAuthor.attributes.profile.displayName}
                    showInitialMessageInput={showInitialMessageInput}
                    initialValues={initalValuesForStripePayment}
                    initiateOrderError={initiateOrderError}
                    handleCardPaymentError={handleCardPaymentError}
                    confirmPaymentError={confirmPaymentError}
                    hasHandledCardPayment={hasPaymentIntentUserActionsDone}
                    loadingData={!stripeCustomerFetched}
                    defaultPaymentMethod={
                      hasDefaultPaymentMethod
                        ? currentUser.stripeCustomer.defaultPaymentMethod
                        : null
                    }
                    paymentIntent={paymentIntent}
                    onStripeInitialized={this.onStripeInitialized}
                    listing={listing}
                  />
                ) : null}
                {isPaymentExpired ? (
                  <p className={css.orderError}>
                    <FormattedMessage
                      id="CheckoutPage.paymentExpiredMessage"
                      values={{ listingLink }}
                    />
                  </p>
                ) : null}
              </section>
            )}

            {this.state.step === 2 &&
              (this.state.selectedPaymentType === PAYMENT_TYPES.direct ||
                this.state.selectedPaymentType === PAYMENT_TYPES.membership ||
                this.state.selectedPaymentType === PAYMENT_TYPES.hitpay ||
                this.state.selectedPaymentType === PAYMENT_TYPES.requestOnly) && (
                <section className={css.paymentContainer}>
                  {initiateOrderErrorMessage}
                  {listingNotFoundErrorMessage}
                  {speculateErrorMessage}
                  {/* <div>
                    <FormattedMessage
                      id={'CheckoutPage.selectedPeriod'}
                      values={{ period: this.state.pageData.bookingData.period }}
                    />
                  </div> */}
                  {showPaymentForm ? (
                    <WithoutPaymentForm
                      className={css.paymentForm}
                      onSubmit={this.handleSubmitOrder}
                      inProgress={this.state.submitting}
                      formId="CheckoutPagePaymentForm"
                      credits={credits}
                      bankDetails={bankDetails}
                      paymentInfo={intl.formatMessage({ id: 'CheckoutPage.paymentInfo' })}
                      authorDisplayName={currentAuthor.attributes.profile.displayName}
                      showInitialMessageInput={showInitialMessageInput}
                      initialValues={initalValuesForStripePayment}
                      initiateOrderError={initiateOrderError}
                      paymentType={this.state.selectedPaymentType}
                      listing={listing}
                    />
                  ) : null}
                </section>
              )}
          </div>

          <div className={css.detailsContainerDesktop}>
            <div className={css.detailsAspectWrapper}>
              <ResponsiveImage
                rootClassName={css.rootForImage}
                alt={listingTitle}
                image={firstImage}
                variants={['square-small', 'square-small2x']}
              />
            </div>
            <div className={css.ContainerLine}></div>
            <div className={css.Summary}>
              <div className={css.SummaryHead}>
                <div className={css.avatarWrapper}>
                  <AvatarMedium user={currentAuthor} disableProfileLink />
                </div>

                <div className={css.detailsHeadings}>
                  <h2 className={css.detailsTitle}>{listingTitle}</h2>
                  <p className={css.detailsSubtitle}>{detailsSubTitle}</p>
                </div>
              </div>
              {speculateTransactionErrorMessage}
              {breakdown}
            </div>
          </div>
        </div>

        {/* <div className={css.priceForm}>
          <div className={css.priceContainer}>
            <div className={css.priceValue}>{formattedPrice}</div>
            <div className={css.perUnit}>
              <FormattedMessage id={unitTranslationKey} />
            </div>
          </div>
          <Button
            rootClassName={css.contactUsButton}
            // onClick={() => openBookModal(isOwnListing, isClosed, history, location)}
          >
            <FormattedMessage id="CheckoutPage.contactUs" />
          </Button>
        </div> */}
        {/* <LiabilityWaiverModal
          id="LiabilityWaiverModal"
          isOpen={this.state.isOpen}
          onSubmit={this.onModalAccept}
          onCloseModal={this.toggleModal}
          onManageDisableScrolling={onManageDisableScrolling}
          rootClassName={''}
          listingTitle={listingTitle}
        /> */}
      </Page>
    );
  }
}

CheckoutPageComponent.defaultProps = {
  initiateOrderError: null,
  confirmPaymentError: null,
  listing: null,
  bookingData: {},
  bookingDates: null,
  speculateTransactionError: null,
  speculatedTransaction: null,
  transaction: null,
  currentUser: null,
  paymentIntent: null,
};

CheckoutPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  listing: propTypes.listing,
  bookingData: object,
  bookingDates: shape({
    bookingStart: instanceOf(Date).isRequired,
    bookingEnd: instanceOf(Date).isRequired,
  }),
  fetchStripeCustomer: func.isRequired,
  stripeCustomerFetched: bool.isRequired,
  fetchSpeculatedTransaction: func.isRequired,
  speculateTransactionInProgress: bool.isRequired,
  speculateTransactionError: propTypes.error,
  speculatedTransaction: propTypes.transaction,
  transaction: propTypes.transaction,
  currentUser: propTypes.currentUser,
  params: shape({
    id: string,
    slug: string,
  }).isRequired,
  onConfirmPayment: func.isRequired,
  onInitiateOrder: func.isRequired,
  onHandleCardPayment: func.isRequired,
  onRetrievePaymentIntent: func.isRequired,
  onSavePaymentMethod: func.isRequired,
  onSendMessage: func.isRequired,
  onHitPay: func.isRequired,
  initiateOrderError: propTypes.error,
  confirmPaymentError: propTypes.error,
  // handleCardPaymentError comes from Stripe so that's why we can't expect it to be in a specific form
  handleCardPaymentError: oneOfType([propTypes.error, object]),
  paymentIntent: object,

  // from connect
  dispatch: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
};

const mapStateToProps = state => {
  const {
    listing,
    bookingData,
    bookingDates,
    stripeCustomerFetched,
    speculateTransactionInProgress,
    speculateTransactionError,
    speculatedTransaction,
    transaction,
    initiateOrderError,
    confirmPaymentError,
  } = state.CheckoutPage;
  const { currentUser } = state.user;
  const { isAuthenticated, logoutInProgress } = state.Auth;
  const { handleCardPaymentError, paymentIntent, retrievePaymentIntentError } = state.stripe;
  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
    stripeCustomerFetched,
    bookingData,
    bookingDates,
    speculateTransactionInProgress,
    speculateTransactionError,
    speculatedTransaction,
    transaction,
    listing,
    initiateOrderError,
    handleCardPaymentError,
    confirmPaymentError,
    paymentIntent,
    retrievePaymentIntentError,
    isAuthenticated,
    logoutInProgress,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
  fetchSpeculatedTransaction: params => dispatch(speculateTransaction(params)),
  fetchStripeCustomer: () => dispatch(stripeCustomer()),
  onInitiateOrder: (params, transactionId) => dispatch(initiateOrder(params, transactionId)),
  onRetrievePaymentIntent: params => dispatch(retrievePaymentIntent(params)),
  onHandleCardPayment: params => dispatch(handleCardPayment(params)),
  onConfirmPayment: params => dispatch(confirmPayment(params)),
  onSendMessage: params => dispatch(sendMessage(params)),
  onSavePaymentMethod: (stripeCustomer, stripePaymentMethodId) =>
    dispatch(savePaymentMethod(stripeCustomer, stripePaymentMethodId)),
  onHitPay: params => dispatch(hitPay(params)),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onLogout: historyPush => dispatch(logout(historyPush)),
  queryPromotedListings: searchquery => dispatch(queryPromotedListings(searchquery)),
  queryPromotedListingsEdu: searchquery => dispatch(queryPromotedListingsEdu(searchquery)),
});

const CheckoutPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
  withViewport
)(CheckoutPageComponent);

CheckoutPage.setInitialValues = (initialValues, saveToSessionStorage = false) => {
  if (saveToSessionStorage) {
    const { listing, bookingData, bookingDates } = initialValues;
    storeData(bookingData, bookingDates, listing, null, STORAGE_KEY);
  }

  return setInitialValues(initialValues);
};

CheckoutPage.displayName = 'CheckoutPage';

export default CheckoutPage;
