import React, { useState } from 'react';
import { func, node, object, string } from 'prop-types';
import { Field } from 'react-final-form';
import classNames from 'classnames';
import { ValidationError } from '../../components';

import css from './FieldSelect.css';

const handleChange = (propsOnChange, inputOnChange) => event => {
  // If "onChange" callback is passed through the props,
  // it can notify the parent when the content of the input has changed.
  if (propsOnChange) {
    // "handleChange" function is attached to the low level <select> component
    // value of the element needs to be picked from target
    const value = event.nativeEvent.target.value;
    propsOnChange(value);
  }
  // Notify Final Form that the input has changed.
  // (Final Form knows how to deal with synthetic events of React.)
  inputOnChange(event);
};

const FieldSelectComponent = props => {
  const {
    rootClassName,
    className,
    selectClassName,
    id,
    label,
    input,
    meta,
    children,
    onChange,
    inputRef,
    ...rest
  } = props;

  if (label && !id) {
    throw new Error('id required when a label is given');
  }
  const [selectedValues, setSelectedValues] = useState([]);

  const { valid, invalid, touched, error } = meta;

  // Error message and input error styles are only shown if the
  // field has been touched and the validation has failed.
  const hasError = touched && invalid && error;

  const selectClasses = classNames(selectClassName, css.select, {
    [css.selectSuccess]: Array.isArray(input.value)
      ? input.value[0] !== undefined && valid
      : input.value && valid,
    [css.selectError]: hasError,
  });

  const { onChange: inputOnChange, ...restOfInput } = input;

  const refMaybe = inputRef ? { ref: inputRef } : {};

  let selectProps = {
    className: selectClasses,
    id,
    onChange: handleChange(onChange, inputOnChange),
    ...refMaybe,
    ...restOfInput,
    ...rest,
  };
  if (props.input.multiple && typeof window !== 'undefined' && window.screen.width >= 550) {
    selectProps = {
      className: selectClasses,
      id,
      ...refMaybe,
      ...restOfInput,
      ...rest,
    };
  }

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      {label ? (
        <label className={css.label} htmlFor={id}>
          {label}
        </label>
      ) : null}

      {props.input.multiple && typeof window !== 'undefined' && window.screen.width >= 550 ? (
        <select
          {...selectProps}
          onClick={e => {
            let arr = [...selectedValues];
            if (selectedValues.length === 0 && input.value !== '') {
              // arr=[...input.value]
              arr = input.value.filter(function(value) {
                return value !== null && value !== undefined;
              });
            }
            const unique = arr.findIndex(item => item === e.target.value);
            if (unique === -1) arr.push(e.target.value);
            else {
              arr.splice(unique, 1);
            }
            setSelectedValues(arr);
            if (arr.length === 0) inputOnChange('');
            else inputOnChange(arr);
          }}
        >
          {children}
        </select>
      ) : (
        <select {...selectProps}>{children}</select>
      )}
      <ValidationError fieldMeta={meta} />
    </div>
  );
};

FieldSelectComponent.defaultProps = {
  rootClassName: null,
  className: null,
  selectClassName: null,
  id: null,
  label: null,
  children: null,
};

FieldSelectComponent.propTypes = {
  rootClassName: string,
  className: string,
  selectClassName: string,

  onChange: func,

  // Label is optional, but if it is given, an id is also required so
  // the label can reference the input in the `for` attribute
  id: string,
  label: string,

  // Generated by final-form's Field component
  input: object.isRequired,
  meta: object.isRequired,

  children: node,
};

const FieldSelect = props => {
  return <Field component={FieldSelectComponent} {...props} />;
};

export default FieldSelect;
