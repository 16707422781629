import React from 'react';
import css from './TopFloatingMessage.css';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import topfloatingimage from 'assets/topfloatingimage.png';

const TopFloatingMessage = props => {
  const {
    titleMessage = '',
    lableText = '',
    inputText = '',
    FormattedButtonText = 'EditListingDescriptionForm.submit',
    onClick,
    className,
    isEducationSite,
  } = props;
  return (
    <div className={className || css.floatingTopBar} onClick={() => onClick && onClick()}>
      <div
        className={classNames(
          css.wrapperInput,
          css.floatingInput,
          isEducationSite && css.floatingInputEdu
        )}
      >
        <h4 className={css.wrapperInputHead}>{titleMessage}</h4>
        <div className={css.wrapperInputWrap}>
          <div className={css.inputWrap}>
            <label className={css.inputLabel}>{lableText}</label>
            <input disabled type="text" value={inputText} />
          </div>
          <button className={css.btnQuote}>
            <FormattedMessage id={FormattedButtonText} />
          </button>
        </div>
      </div>
      <div className={classNames(css.newFloatingMain, isEducationSite && css.newFloatingMainEdu)}>
        <div className={css.newFloatingImage}>
          <img src={topfloatingimage} alt="" />
        </div>
        <div className={css.newFloatingText}>{titleMessage}</div>
        <button className={css.newFloatingButton}>
          {' '}
          <FormattedMessage id={FormattedButtonText} />
        </button>
      </div>
    </div>
  );
};

export default TopFloatingMessage;

const d = {
  FormattedButtonText: 'CategoriesPage.quoteButtonText',
  titleMessage: 'Let us fined you a couch',
  lableText: 'we aim to give you responce',
  inputText: 'we have somting',
  onClick: () => console.log('hello'),
};
