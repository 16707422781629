import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { StaticPage, TopbarContainer } from '../../containers';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
} from '../../components';

import css from './AboutPage.css';
import image from './about-us-1056.jpg';

const AboutPage = () => {
  // const { siteTwitterHandle, siteFacebookPage } = config;
  // const siteTwitterPage = twitterPageURL(siteTwitterHandle);

  // prettier-ignore
  return (
    <StaticPage
      title="About Us"
      schema={{
        '@context': 'http://schema.org',
        '@type': 'AboutPage',
        description: 'About Yogatime',
        name: 'About page',
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain className={css.staticPageWrapper}>
          {/* <h1 className={css.pageTitle}>Find new depths in your yoga practice</h1> */}
          <img className={css.coverImage} src={image} alt="My first ice cream." />
          <div>
            <div>
              <h4 className={css.AboutTitles}>
                <FormattedMessage id="AboutPage.aboutUs" />
              </h4>
              <p className={css.AboutBodyText}>
                ProBuddy Sports and Education is a platform with a mission to make it easy for
                people to connect with sports, wellness, online tutor, and learning and book them on
                the go, wherever and whenever they want. Our Marketplace provides access to verified
                fitness and wellness experts and online tutors and teachers offering the best Pros,
                classes, instructors in one place.
              </p>
            </div>

            <div>
              <h4 className={css.AboutTitles}>
                <FormattedMessage id="AboutPage.ourStory" />
              </h4>
              <p className={css.AboutBodyText}>
                Francesca Wong founded ProBuddy Education after experiencing frustration in trying
                to find a professional trainer in her city. She wanted to create a platform that
                could connect people with sports, wellness, and adventure Pros and Small Businesses,
                providing convenient access to sessions, classes, and appointments that can be
                scheduled at a time and place that suits them. Beginning in Singapore, ProBuddy
                Education operates in multiple locations and is expanding rapidly.
              </p>
            </div>

            <div>
              <h4 className={css.AboutTitles}>
                <FormattedMessage id="AboutPage.areYouFreelancerOrBusiness" />
              </h4>
              <p className={css.AboutBodyText}>
                Our Marketplace makes it easy for users to find, book, and pay for the services they
                need. Whether you're looking for a relaxing massage, a private tennis session, or a
                yoga class, the French class ProBuddy Sports and Education can help you find what
                you need. If you're a Pro, Freelancer, or Small Business, you can use our platform
                to manage bookings, payments, and packages, attract new clients, and reduce
                cancellations. With ProBuddy Sports and Education, you can create effective business
                listings to connect with clients, access all the tools you need to optimize and
                expand your business, and get your business in front of more people.
              </p>
              <p className={css.AboutBodyText}>
                Join the many businesses already using ProBuddy Sports and Education to manage and
                grow their business. We're here to help you deliver the best experience possible for
                your clients, so why wait? Join ProBuddy Sports and Education today and start taking
                your business to the next level.
              </p>
            </div>
          </div>

          {/* <div className={css.contentWrapper}>
            <div className={css.contentSide}>
              <p>Yoga was listed by UNESCO as an intangible cultural heritage.</p>
            </div>

            <div className={css.contentMain}>
              <h2>
                Each yoga practitioner is an individual, and each one of us needs different care.
                Working together with an experienced yoga trainer offers the possibility to rise
                our practise to a whole new level.
              </h2>

              <p>
                Whether it is the alignment of asanas or being able to set the focus of the class,
                we all have our own struggles and goals. Some of these cannot be addressed in a
                regular class of twenty yogis. Working together with the experienced yoga trainers
                from Yogatime, you can together create just the right class for you.
              </p>

              <h3 className={css.subtitle}>Are you a yoga trainer?</h3>

              <p>
                Yogatime offers you a platform through which you can reach thousands of yoga
                practitioners. Offering private yoga classes through Yogatime offers you a
                possibility to grow your customer base and earn some extra income on top of your
                regular classes.
              </p>

              <h3 id="contact" className={css.subtitle}>
                Create your own marketplace like Yogatime
              </h3>
              <p>
                Yogatime is brought to you by{' '}
                <ExternalLink href="http://sharetribe.com">Sharetribe</ExternalLink>. Sharetribe
                offers anyone a possibility to create a marketplace without restricting your own
                creativity. Do not hesitate to reach out and learn how to best turn your
                marketplace idea to reality.
              </p>
              <p>
                You can also checkout our{' '}
                <ExternalLink href={siteFacebookPage}>Facebook</ExternalLink> and{' '}
                <ExternalLink href={siteTwitterPage}>Twitter</ExternalLink>.
              </p>
            </div>
          </div> */}
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

export default AboutPage;
