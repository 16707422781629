import React from 'react';
import { set } from 'lodash/fp';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { FormattedMessage, injectIntl } from 'util/reactIntl';
import { Logo, NamedLink, Page } from 'components';
import IconCreditCard from 'components/IconCreditCard/IconCreditCard';
import IconMembership from 'components/IconMembership/IconMembership';
import IconBank from 'components/IconBank/IconBank';
import IconCash from 'components/IconCash/IconCash';
import css from './PaymentTypeSelect.css';
import routeConfiguration from 'routeConfiguration';
import { PAYMENT_TYPES } from 'util/constants';
import { createResourceLocatorString } from 'util/routes';

const TopBar = ({ intl }) => (
  <div className={css.topbar}>
    <NamedLink className={css.home} name="LandingPage">
      <Logo
        className={css.logoMobile}
        title={intl.formatMessage({ id: 'CheckoutPage.goToLandingPage' })}
        format="mobile"
      />
      <Logo
        className={css.logoDesktop}
        alt={intl.formatMessage({ id: 'CheckoutPage.goToLandingPage' })}
        format="desktop"
      />
    </NamedLink>
  </div>
);

export const PaymentTypeSelectContent = ({ onClick, withCash,withCard,withMemberShip,withBankTransfer }) => {
  return (
    <>
      <h3>
        <FormattedMessage id={'PaymentTypeSelect.heading'} />
      </h3>
      <div className={css.options}>
        {withCard && (
        <button type="button" onClick={onClick(PAYMENT_TYPES.card)} className={css.optionItem}>
          <div className={css.icon}>
            <IconCreditCard />
          </div>
          <div className={css.label}>
            <FormattedMessage id={'PaymentTypeSelect.cardPaymentLabel'} tagName="p" />
          </div>
        </button>)}
        {withBankTransfer && (
        <button type="button" onClick={onClick(PAYMENT_TYPES.direct)} className={css.optionItem}>
          <div className={css.icon}>
            <IconBank />
          </div>
          <div className={css.label}>
            <FormattedMessage
              id={'PaymentTypeSelect.directPaymentLabel'}
              values={{ br: <br /> }}
              tagName="p"
            />
          </div>
        </button>)}
        {withMemberShip && (
        <button
          type="button"
          onClick={onClick(PAYMENT_TYPES.membership)}
          className={css.optionItem}
        >
          <div className={css.icon}>
            <IconMembership />
          </div>
          <div className={css.label}>
            <FormattedMessage id={'PaymentTypeSelect.membershipPaymentLabel'} tagName="p" />
          </div>
        </button>)}

        {withCash && (
          <button type="button" onClick={onClick(PAYMENT_TYPES.cash)} className={css.optionItem}>
            <div className={css.icon}>
              <IconCash />
            </div>
            <div className={css.label}>
              <FormattedMessage id={'PaymentTypeSelect.cashPaymentLabel'} tagName="p" />
            </div>
          </button>
        )}
      </div>
    </>
  );
};

function PaymentTypeSelectComponent( { scrollingDisabled, intl, history, params,location } ) {
  let payment_methods=['cash','card','member','bank']
  if(location.search)
  {
    payment_methods=decodeURIComponent(location.search.split('=')[1]).split(',')
  }
  const pageProps = { title: 'Select a payment Type', scrollingDisabled };

  const handleOnClick = paymentType => () => {
    const routes = routeConfiguration();
    const pageName =
      paymentType !== PAYMENT_TYPES.card ? 'CheckoutPageWithoutPayment' : 'CheckoutPage';

    const paramsWithoutCredits = set('credits', 'false', params);

    history.push(
      createResourceLocatorString(pageName, routes, {
        ...paramsWithoutCredits,
        paymentType: paymentType !== PAYMENT_TYPES.card ? paymentType : undefined,
      })
    );
  };

  return (
    <Page {...pageProps}>
      <TopBar intl={intl} />

      <div className={css.container}>
        <PaymentTypeSelectContent onClick={handleOnClick} withCash={payment_methods.includes("cash")} withBankTransfer={payment_methods.includes("bank")} 
        withCard={payment_methods.includes("card")} withMemberShip={payment_methods.includes("member")} />
      </div>
    </Page>
  );
}

const mapStateToProps = state => {
  return {
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const PaymentTypeSelect = compose(
  withRouter,
  connect(mapStateToProps, null),
  injectIntl
)(PaymentTypeSelectComponent);

export default PaymentTypeSelect;
