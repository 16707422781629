import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './Topbar.css';

const SearchIcon = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.rootSearchIcon, className);

  return (
    <svg
      className={classes}
      width="23" height="23" viewBox="0 0 23 23" style={{ fill: "transparent" }} fill="none">
      <path d="M10.2082 19.2591C14.8398 19.2591 18.5951 15.37 18.5951 10.5724C18.5951 5.7749 14.8398 1.88574 10.2082 1.88574C5.5756 1.88574 1.82031 5.7749 1.82031 10.5724C1.82031 15.37 5.5756 19.2591 10.2082 19.2591Z" stroke="#233045" strokeWidth="2.26529" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M21.5905 21.655L16.1992 16.8623" stroke="#233045" strokeWidth="2.26529" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

const { string } = PropTypes;

SearchIcon.defaultProps = {
  className: null,
  rootClassName: null,
};

SearchIcon.propTypes = {
  className: string,
  rootClassName: string,
};

export default SearchIcon;
