import React, { memo, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { OutSideCloseCard } from 'components';
import classNames from 'classnames';
import { changingActivefooterTab } from 'ducks/user.duck';
import { NamedLink, NamedRedirect } from '../../components';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';
import closeBtn from '../../assets/HomePage/closeBtn.svg';
import { ReactComponent as ProfileIcon } from '../../assets/HomePage/ProfileIcon.svg';
import { ReactComponent as PackagesFooter } from '../../assets/HomePage/PackagesFooter.svg';
import { ReactComponent as LeftbarMenuicon } from '../../assets/HomePage/LeftbarMenuicon.svg';
import { ReactComponent as OpenPopupIcon } from '../../assets/HomePage/openPopupIcon.svg';
import { ReactComponent as HomeIcon } from '../../assets/HomePage/HomeIcon.svg';
import { ReactComponent as CategoriesIcon } from '../../assets/HomePage/categoriesIcon.svg';
import { ReactComponent as BookingBottom } from '../../assets/HomePage/BookingBottom.svg';
import { ReactComponent as Rewards } from '../../assets/CompanyPage/greyStar.svg';
import buyer from '../../assets/HomePage/buyer.svg';

import css from './FooterBottomMenu.css';

const FooterBottomMenu = props => {
  const {
    changeActivieFooterTab,
    // isSellerMode,
    isEducationSite,
    isTennisSite,
    // isScrolling,
  } = props;
  const dispatch = useDispatch();
  const { activeFooterTab, currentUser } = useSelector(state => state.user);
  const isAuthenticated = useSelector(state => state.Auth.isAuthenticated);
  const [selected, setSelected] = useState('');
  const [isRedirect, setIsRedirect] = useState(false);

  const isSellerMode = currentUser?.attributes?.profile?.publicData?.sellerMode;

  const resetPage = useCallback(() => {
    props.history.push(
      createResourceLocatorString(
        isEducationSite ? ('EducationPage' ? isTennisSite : 'TennisPage') : 'HomePage',
        routeConfiguration(),
        {}
      )
    );
    window?.location && window.location.reload(false);
  }, [props.history, isEducationSite]);

  const footerIcon = useMemo(
    () => [
      {
        name: 'search',
        redirect: isEducationSite ? 'EducationPage' : 'HomePage',
        img: <HomeIcon />,
        tag: 'Explore',
      },
      { name: 'create', img: <OpenPopupIcon />, tag: 'Post' },
      {
        name: 'category',
        redirect: isEducationSite ? 'EducationCategoriesPage' : 'CategoriesPage',
        img: <CategoriesIcon style={{ height: '25px', width: '25px' }} />,
        tag: 'Category',
      },
      {
        name: 'leftbar',
        img: <LeftbarMenuicon />,
        tag: 'Menu',
      },
      {
        name: 'player-profile',
        redirect: 'LoginPage',
        img: <ProfileIcon />,
        tag: 'Profile',
      },
    ],
    [isEducationSite]
  );

  const footerLogIn = useMemo(
    () => [
      {
        name: 'search',
        redirect: isEducationSite ? 'EducationPage' : 'HomePage',
        img: <HomeIcon />,
        tag: 'Explore',
      },
      {
        name: 'inboxPage',
        redirect: 'InboxPage',
        img: <BookingBottom />,
        tag: 'Bookings',
        params: {
          tab: 'sales',
          state: 'pending',
        },
      },
      {
        name: 'jobsBoard',
        img: <PackagesFooter />,
        tag: 'JobsBoard',
        redirect: isEducationSite
          ? 'EducationJobsBoard'
            ? isTennisSite
            : 'TennisJobsBoard'
          : 'JobsBoard',
      },
      // {
      //   name: 'inboxPageReward',
      //   redirect: 'InboxPage',
      //   img: <Rewards />,
      //   tag: 'Rewards',
      //   params: {
      //     tab: 'myRewards',
      //   },
      // },
      {
        name: 'category',
        redirect: isEducationSite ? 'EducationCategoriesPage' : 'CategoriesPage',
        img: <CategoriesIcon style={{ height: '25px', width: '25px' }} />,
        tag: 'Category',
      },
      {
        name: 'leftbar',
        img: <LeftbarMenuicon />,
        tag: 'Menu',
      },
    ],
    [isEducationSite]
  );

  const checkboxSvg = (
    <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 5.8L3.85714 9L11 1"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="transparent"
      />
    </svg>
  );

  const classNameItem = name =>
    classNames(css.bottomMenuItemText, {
      [css.activeBottomMenuItemText]: name === activeFooterTab,
      [css.activeBottomMenuItemTextEdu]: isEducationSite && name === activeFooterTab,
    });

  const footerMenu = useMemo(() => (isAuthenticated ? footerLogIn : footerIcon), [
    isAuthenticated,
    footerLogIn,
    footerIcon,
  ]);
  const footerItem = useCallback(
    (item, index) => {
      return (
        <div
          key={index}
          className={classNames(css.footerItem, {
            [css.activeFooterItem]: !isEducationSite && item?.name === activeFooterTab,
            [css.activeFooterItemEdu]: isEducationSite && item?.name === activeFooterTab,
          })}
          onClick={() => {
            const activeItem = activeFooterTab === item?.name ? 'search' : item?.name;
            dispatch(changingActivefooterTab(activeItem));
            activeFooterTab === item?.name && resetPage();
            changeActivieFooterTab && changeActivieFooterTab(activeItem);
            // setActivieFooterTab(activeItem);
          }}
        >
          {item?.img}
        </div>
      );
    },
    [activeFooterTab, resetPage, dispatch, changeActivieFooterTab, isEducationSite]
  );

  return (
    <>
      {activeFooterTab === 'create' ? (
        <OutSideCloseCard
          show={activeFooterTab === 'create'}
          onClickOutside={() => {
            dispatch(changingActivefooterTab('search'));
          }}
          className={classNames(css.footer, {
            [css.createBottomMenu]: activeFooterTab === 'create',
          })}
        >
          <div
            className={css.closeBtn}
            onClick={() => {
              dispatch(changingActivefooterTab('search'));
              resetPage();
              // setActivieFooterTab('search');
            }}
          >
            <img className={css.createImage} src={closeBtn} alt={'clodse_btn'} />
          </div>
          {isRedirect ? (
            <NamedRedirect name={selected === 'buyer' ? 'NewInquirePage' : 'ListingType'} />
          ) : (
            <div className={css.btnWrapper}>
              {/* <img src={footerImg} alt={'footer_image'} /> */}
              <video
                className={css.footerImg}
                src="https://res.cloudinary.com/movementbuddy-pte-ltd/video/upload/v1669271489/probuddy/static/images/Email/MobilePost_ijslli.mp4"
                controls="controls"
                autoplay="autoplay"
                playsinline="playsinline"
              />
              <div
                className={classNames(css.requestWrap, {
                  [css.selectedRequest]: selected === 'buyer',
                })}
                onClick={() => setSelected('buyer')}
              >
                {/* <NamedLink className={css.requestWrap} name="NewInquirePage"> */}

                <FormattedMessage id="HomePage.RequestBuyer" />
                <img src={buyer} alt={'buyer_request'} />
                <div className={css.checkMark}>{checkboxSvg}</div>
                {/* </NamedLink> */}
              </div>
              {/* <div
                className={classNames(css.requestWrap, {
                  [css.selectedRequest]: selected === 'seller',
                })}
                onClick={() => setSelected('seller')}
              >
                 <NamedLink className={css.requestWrap} name={'NewListingPage'}> 
                <FormattedMessage id="HomePage.SellerListing" />
                <img src={seller} alt={'seller_request'} />
                <div className={css.checkMark}>{checkboxSvg}</div>
                 </NamedLink> 
              </div> */}
              <button className={css.nextBtn} onClick={() => setIsRedirect(true)}>
                Next
              </button>
            </div>
          )}
        </OutSideCloseCard>
      ) : (
        <>
          <div
            className={classNames(css.footer, {
              [css.activitySearch]: activeFooterTab === 'activitySearch',
            })}
          >
            <div
              className={classNames(css.footerItemWrap, {
                // [css.hideFooter]: isScrolling,
              })}
            >
              {// !isScrolling &&
              !!footerMenu.length &&
                footerMenu.map((item, index) => {
                  return item.redirect ? (
                    // (item.tag === 'Bookings' || item.tag === 'Packages' ? isSellerMode : true) ? showBelow : <></>
                    <div key={index} className={css.bottomMenuItemWrap}>
                      <NamedLink key={index} name={item.redirect} params={item.params}>
                        {footerItem(item)}
                      </NamedLink>
                      <div className={classNameItem(item.name)}>{item.tag}</div>
                    </div>
                  ) : (
                    <div key={index} className={css.bottomMenuItemWrap}>
                      {footerItem(item, index)}
                      <div className={classNameItem(item.name)}>{item.tag}</div>
                    </div>
                  );
                })}
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default memo(FooterBottomMenu);
