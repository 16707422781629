import React from 'react';
import { types as sdkTypes } from '../../util/sdkLoader';

export const MAX_MOBILE_SCREEN_WIDTH = 1024;

export const { LatLng, LatLngBounds } = sdkTypes;

export const sliderSettings = {
  appendDots: dots => (
    <div
      style={{
        backgroundColor: 'transparent',
        borderRadius: '10px',
        padding: '10px',
        marginTop: '2rem',
      }}
    >
      <ul style={{ margin: '-15px' }}> {dots} </ul>
    </div>
  ),
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  swipeToSlide: true,
  swipe: false,
  dots: true,
  responsive: [
    {
      breakpoint: 550,
      settings: {
        slidesToScroll: 1,
        swipe: true,
        arrows: false,
        dots: true,
      },
    },
  ],
};

export const getSchemaId = param => {
  switch (param) {
    case 'sports':
      return 'CategoriesPage.schemaTitleSports';
    case 'wellness':
      return 'CategoriesPage.schemaTitleWellness';
    case 'adventure':
      return 'CategoriesPage.schemaTitleAdventure';
    case 'yoga':
      return 'CategoriesPage.schemaTitleYoga';
    case 'pilates':
      return 'CategoriesPage.schemaTitlePilates';
    case 'pole-fitness':
      return 'CategoriesPage.schemaTitlePoleFitness';
    case 'rock-climbing':
      return 'CategoriesPage.schemaTitleRockClimbing';
    case 'kickboxing':
      return 'CategoriesPage.schemaTitleKickboxing';
    case 'tennis':
      return 'CategoriesPage.schemaTitleTennis';
    case 'golf':
      return 'CategoriesPage.schemaTitleGolf';
    case 'dance':
      return 'CategoriesPage.schemaTitleDance';
    case 'volleyball':
      return 'CategoriesPage.schemaTitleVolleyball';
    case 'surfing':
      return 'CategoriesPage.schemaTitleSurfing';
    case 'math':
      return 'CategoriesPage.schemaTitleMath';
    case 'language':
      return 'CategoriesPage.schemaTitleLanguage';
    case 'english':
      return 'CategoriesPage.schemaTitleEnglish';
    case 'french':
      return 'CategoriesPage.schemaTitleFrench';
    case 'german':
      return 'CategoriesPage.schemaTitleGerman';
    case 'development':
      return 'CategoriesPage.schemaTitleDevelopment';
    case 'language-learning':
      return 'CategoriesPage.schemaTitleLanguageLearning';
    case 'academic':
      return 'CategoriesPage.schemaTitleAcademics';
    case 'physics':
      return 'CategoriesPage.schemaTitlePhysics';
    case 'chemistry':
      return 'CategoriesPage.schemaTitleChemistry';
    case 'biology':
      return 'CategoriesPage.schemaTitleBiology';
    case 'geography':
      return 'CategoriesPage.schemaTitleGeography';
    case 'engineering':
      return 'CategoriesPage.schemaTitleEngineering';
    case 'it-and-software':
      return 'CategoriesPage.schemaTitleItandSoftware';
    case 'management':
      return 'CategoriesPage.schemaTitleManageMent';
    case 'business':
      return 'categoriesPage.schemaTitleBusiness';
    case 'business-development':
      return 'CategoriesPage.schemaTitleBusinessDevelopment';
    case 'finance-and-accounting':
      return 'CategoriesPage.schemaTitleFinanceAndAcounting';
    case 'academic-writing':
      return 'CategoriesPage.schemaTitleAcademicWriting';
    case 'office-productivity':
      return 'CategoriesPage.schemaTitleOfficeProductivity';
    case 'personal-development':
      return 'CategoriesPage.schemaTitlePersonalDevelopment';
    case 'design':
      return 'CategoriesPage.schemaTitleDesign';
    case 'marketing':
      return 'CategoriesPage.schemaTitleMarketing';
    case 'lifestyle':
      return 'CategoriesPage.schemaTitleLifestyle';
    case 'art':
      return 'CategoriesPage.schemaTitleArts';
    case 'photography-and-video':
      return 'CategoriesPage.schemaTitlePhotography';
    case 'health-and-fitness':
      return 'CategoriesPage.schemaTitleHealthAndFitness';
    case 'music':
      return 'CategoriesPage.schemaTitleMusic';

    default:
      return 'CategoriesPage.schemaTitleSports';
  }
};

export const getSchemaDescription = param => {
  switch (param) {
    case 'sports':
      return 'CategoriesPage.schemaDescriptionSports';
    case 'wellness':
      return 'CategoriesPage.schemaDescriptionWellness';
    case 'adventure':
      return 'CategoriesPage.schemaDescriptionAdventure';
    case 'yoga':
      return 'CategoriesPage.schemaDescriptionYoga';
    case 'pilates':
      return 'CategoriesPage.schemaDescriptionPilates';
    case 'pole-fitness':
      return 'CategoriesPage.schemaDescriptionPoleFitness';
    case 'rock-climbing':
      return 'CategoriesPage.schemaDescriptionRockClimbing';
    case 'kickboxing':
      return 'CategoriesPage.schemaDescriptionKickboxing';
    case 'tennis':
      return 'CategoriesPage.schemaDescriptionTennis';
    case 'golf':
      return 'CategoriesPage.schemaDescriptionGolf';
    case 'dance':
      return 'CategoriesPage.schemaDescriptionDance';
    case 'volleyball':
      return 'CategoriesPage.schemaDescriptionVolleyball';
    case 'surfing':
      return 'CategoriesPage.schemaDescriptionSurfing';
    case 'language':
      return 'CategoriesPage.schemaDescriptionLanguage';
    case 'english':
      return 'CategoriesPage.schemaDescriptionEnglish';
    case 'french':
      return 'CategoriesPage.schemaDescriptionFrench';
    case 'german':
      return 'CategoriesPage.schemaDescriptionGerman';
    case 'language-learning':
      return 'CategoriesPage.schemaDescriptionLanguageLearning';
    case 'academic':
      return 'CategoriesPage.schemaDescriptionAcademic';
    case 'math':
      return 'CategoriesPage.schemaDescriptionMath';
    case 'physics':
      return 'CategoriesPage.schemaDescriptionPhysics';
    case 'chemistry':
      return 'CategoriesPage.schemaDescriptionChemistry';
    case 'biology':
      return 'CategoriesPage.schemaDescriptionBiology';
    case 'geography':
      return 'CategoriesPage.schemaDescriptionGeography';
    case 'engineering':
      return 'CategoriesPage.schemaDescriptionEngineering';
    case 'it-and-software':
      return 'CategoriesPage.schemaDescriptionItSoftware';
    case 'development':
      return 'CategoriesPage.schemaDescriptionDevelopment';
    case 'management':
      return 'CategoriesPage.schemaDescriptionManagement';
    case 'business':
      return 'CategoriesPage.schemaDescriptionBusiness';
    case 'business-development':
      return 'CategoriesPage.schemaDescriptionBussinessDevelopment';
    case 'finance-and-accounting':
      return 'CategoriesPage.schemaDescriptionFinanceAccounting';
    case 'academic-writing':
      return 'CategoriesPage.schemaDescriptionAcademicWriting';
    case 'office-productivity':
      return 'CategoriesPage.schemaDescriptionOfficeProductivity';
    case 'personal-development':
      return 'CategoriesPage.schemaDescriptionPersonalDevelopment';
    case 'design':
      return 'CategoriesPage.schemaDescriptionDesign';
    case 'marketing':
      return 'CategoriesPage.schemaDescriptionMarketing';
    case 'lifestyle':
      return 'CategoriesPage.schemaDescriptionLifestyle';
    case 'art':
      return 'CategoriesPage.schemaDescriptionArts';
    case 'photography-and-video':
      return 'CategoriesPage.schemaDescriptionPhotography';
    case 'health-and-fitness':
      return 'CategoriesPage.schemaDescriptionHealthFitness';
    case 'music':
      return 'CategoriesPage.schemaDescriptionMusic';

    default:
      return 'CategoriesPage.schemaDescriptionSports';
  }
};
