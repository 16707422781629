import React from 'react';
import classNames from 'classnames';

import { ReactComponent as BackSvg } from '../../assets/HeaderPart/NewBack.svg';
import css from './VendorListings.css';
const MAX_MOBILE_SCREEN_WIDTH = 550;

const VendorHeader = props => {
  const { history, viewport, isEducationSite } = props;

  const isMobileLayout = !!viewport.width ? viewport.width < MAX_MOBILE_SCREEN_WIDTH : false;

  return (
    <div
      className={classNames(css.headerRoot, {
        [css.headerRootMobile]: isMobileLayout,
        [css.headerRootEducation]: isEducationSite,
      })}
    >
      <div className={css.iconWrap} onClick={() => history.goBack()}>
        <BackSvg />
      </div>
      {/* <div
        className={classNames(css.bgImgIcon, {
          [css.bgImgIconMobile]: isMobileLayout,
          [css.hideImg]: isScrolling,
        })}
      ></div> */}
    </div>
  );
};

export default VendorHeader;
