import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { createResourceLocatorString } from 'util/routes';
import routeConfiguration from '../../routeConfiguration';
import { login } from '../../ducks/Auth.duck';

import css from './CorporateThankYouPage.css';
import thankyousvg from '../../assets/ThankYouPage/thankyousvg.svg';

const CorporateThankYouPageComponent = props => {
  const { history, submitLogin } = props;
  const [currentCorporateUserLoginData, setCurrentCorporateUserLoginData] = useState();

  useEffect(() => {
    if (localStorage.getItem('currentCorporateUserLoginData'))
      setCurrentCorporateUserLoginData(
        JSON.parse(localStorage.getItem('currentCorporateUserLoginData'))
      );
  }, []);

  const userEmail = currentCorporateUserLoginData?.email;
  const userPassword = currentCorporateUserLoginData?.password;

  const GoToHome = (userEmail, userPassword) => {
    submitLogin(userEmail, userPassword);
    localStorage.removeItem('currentCorporateUserLoginData');
    history.push(createResourceLocatorString('HomePage', routeConfiguration(), {}));
  };

  return (
    <div className={css.mainWrapper}>
      <div className={css.thankyouWrapper}>
        <img src={thankyousvg} alt={' '} />
        <h1>
          <FormattedMessage id="ThankYouPage.message" />
        </h1>
        {/* <h1>
          <FormattedMessage id="ThankYouPage.contact" />
        </h1> */}
        <button className={css.btnDone} onClick={() => GoToHome(userEmail, userPassword)}>
          <FormattedMessage id="ThankYouPage.done" />
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => ({
  submitLogin: (email, password) => dispatch(login(email, password)),
});

const CorporateThankYouPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(injectIntl(CorporateThankYouPageComponent));

export default CorporateThankYouPage;
