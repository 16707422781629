import React, { useState } from 'react';
import { FormattedMessage } from 'util/reactIntl';
import { IconClose, Button } from '../../../components';

import css from './CompanyPage.css';

const SectionBlogs = props => {
  const { blogs, viewport } = props;
  const [selectedBlog, setSelectedBlog] = useState(null);
  const [showAllBlogs, setShowAllBlogs] = useState(false);
  const getNumberOfBlogsDisplay = () => {
    const width = viewport.width;
    if (width < 550) return 2;
    else if (width < 768) return 3;
    else return 4;
  };

  return (
    <div className={css.sectionBlog}>
      {selectedBlog && selectedBlog.header ? (
        <div className={css.blogsContainer}>
          <div className={css.blogModalContent}>
            <div className={css.blogModalImage}>
              <img src={selectedBlog && selectedBlog.asset.url} alt="blogImg" />
            </div>

            <div>
              <div>
                <h2 className={css.blogHeader}>{selectedBlog && selectedBlog.header}</h2>
              </div>
              <div className={css.paragraphContainer}>
                <pre className={css.paragraphDescription}>{selectedBlog && selectedBlog.text}</pre>
              </div>
            </div>
          </div>
          <Button
            onClick={() => setSelectedBlog(null)}
            rootClassName={css.close}
            title={'closeModalMessage'}
          >
            <span className={css.closeText}></span>
            <span className={css.closeBtnWrap}>
              <IconClose rootClassName={css.closeIcon} />
            </span>
          </Button>
        </div>
      ) : (
        <>
          <div className={css.blogContentWrapper}>
            {blogs
              .slice(0, showAllBlogs ? blogs.length : getNumberOfBlogsDisplay())
              .map((blog, i) => {
                return (
                  <div
                    key={i}
                    onClick={() => setSelectedBlog(blog)}
                    className={css.blogItemWrapper}
                  >
                    <img src={blog.asset.url} alt={blog.header} />
                    <div className={css.blogItemTitle}>
                      {blog.header.split(' ').length > 5
                        ? `${blog.header
                            .split(' ')
                            .slice(0, 5)
                            .join(' ')}...`
                        : blog.header}
                    </div>
                  </div>
                );
              })}
          </div>
          {blogs.length > getNumberOfBlogsDisplay() && (
            <div className={css.viewAllBlogButton}>
              <Button onClick={() => setShowAllBlogs(prevState => !prevState)}>
                {!showAllBlogs ? (
                  <FormattedMessage id="CompanyPage.viewAllBlogs" />
                ) : (
                  <FormattedMessage id="CompanyPage.viewLess" />
                )}
              </Button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default SectionBlogs;
