import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';

function OutSideCloseCard(props) {
  const ref = useRef(null);
  const { onClickOutside, children } = props;

  useEffect(() => {
    const handleClickOutside = event => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside && onClickOutside();
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [onClickOutside]);

  if (!props.show) return null;
  return (
    <div ref={ref} className={classNames(props.className, 'info-box')}>
      {children}
    </div>
  );
}

export default OutSideCloseCard;
