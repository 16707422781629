import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { fetchCurrentUser } from '../../ducks/user.duck';
import config from '../../config';
import axios from 'axios';
import _ from 'lodash';

// ================ Action types ================ //

export const SET_INITIAL_STATE = 'app/WalletPage/SET_INITIAL_STATE';
export const FETCH_BUYER_PACKAGE_REQUESTS = 'app/WalletPage/FETCH_BUYER_PACKAGE_REQUESTS';
export const FETCH_BUYER_PACKAGE_REQUESTS_SUCCESS =
  'app/WalletPage/FETCH_BUYER_PACKAGE_REQUESTS_SUCCESS';
export const FETCH_BUYER_PACKAGE_REQUESTS_ERROR =
  'app/WalletPage/FETCH_BUYER_PACKAGE_REQUESTS_ERROR';
export const FETCH_LISTING_SUCCESS = 'app/WalletPage/FETCH_LISTING_SUCCESS';
export const FETCH_LISTING_ERROR = 'app/WalletPage/FETCH_LISTING_ERROR';
export const QUERY_LISTINGS_REQUEST = 'app/WalletPage/QUERY_LISTINGS_REQUEST';
export const QUERY_LISTINGS_SUCCESS = 'app/WalletPage/QUERY_LISTINGS_SUCCESS';
export const QUERY_LISTINGS_ERROR = 'app/WalletPage/QUERY_LISTINGS_ERROR';
export const NO_PACKAGE = 'app/WalletPage/NO_PACKAGE';

// ================ Reducer ================ //

const initialState = {
  walletRequestError: null,
  walletRequestInProgress: true,
  walletListings: [],
  buyerPackageRequests: [],
  fetchBuyerPackageRequestInProgress: false,
  fetchBuyerPackageRequestError: null,
  fetchedLisitng: null,
  fetchedLisitngError: null,
};

export default function walletPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_STATE:
      return { ...initialState };
    case QUERY_LISTINGS_REQUEST:
      return {
        ...state,
        walletRequestError: null,
        walletRequestInProgress: true,
      };
    case FETCH_BUYER_PACKAGE_REQUESTS:
      return {
        ...state,
        fetchBuyerPackageRequestInProgress: true,
        fetchBuyerPackageRequestError: null,
      };
    case FETCH_BUYER_PACKAGE_REQUESTS_SUCCESS: {
      return {
        ...state,
        fetchBuyerPackageRequestInProgress: false,
        buyerPackageRequests: payload,
      };
    }
    case FETCH_BUYER_PACKAGE_REQUESTS_ERROR:
      return {
        ...state,
        fetchBuyerPackageRequestInProgress: false,
        fetchBuyerPackageRequestError: payload,
      };
    case FETCH_LISTING_SUCCESS:
      return {
        ...state,
        fetchedLisitng: payload,
      };
    case FETCH_LISTING_ERROR:
      return { ...state, fetchedLisitngError: payload };
    case QUERY_LISTINGS_SUCCESS:
      const listings = _.uniqBy([...(state.walletListings), payload.listing], function (e) {
        return e.id.uuid;
      });
      return { ...state, walletListings: listings, walletRequestInProgress: false };
    case QUERY_LISTINGS_ERROR:
      return { ...state, walletRequestError: payload, walletRequestInProgress: false };
    case NO_PACKAGE:
      return { ...state, walletRequestInProgress: false };
    default:
      return state;
  }
}

// ================ Action creators ================ //

export const setInitialState = () => ({
  type: SET_INITIAL_STATE,
});

const buyerPackageRequestsRequest = () => ({ type: FETCH_BUYER_PACKAGE_REQUESTS });

const buyerPackageRequestsSuccess = response => ({
  type: FETCH_BUYER_PACKAGE_REQUESTS_SUCCESS,
  payload: response,
});

const buyerPackageRequestsError = e => ({
  type: FETCH_BUYER_PACKAGE_REQUESTS_ERROR,
  error: true,
  payload: e,
});

const fetchListingSuccess = response => ({ type: FETCH_LISTING_SUCCESS, payload: response });
const fetchListingError = e => ({ type: FETCH_LISTING_ERROR, error: true, payload: e });


export const queryListingsRequest = () => ({
  type: QUERY_LISTINGS_REQUEST,
});

export const queryListingsSuccess = listing => ({
  type: QUERY_LISTINGS_SUCCESS,
  payload: { listing },
});

export const queryListingsError = e => ({
  type: QUERY_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const noPackage = () => ({
  type: NO_PACKAGE,
});



// ================ Thunks ================ //

export const loadData = (params) => (dispatch, getState, sdk) => {

  dispatch(setInitialState());
  dispatch(queryListingsRequest());

  return dispatch(fetchCurrentUser()).then(response => {
    const currentUser = getState().user.currentUser;
    if (currentUser && currentUser.attributes.profile.privateData.sessions) {
      const listingIds = Object.keys(currentUser.attributes.profile.privateData.sessions);
      listingIds.map((listingId) =>
        sdk.listings.show({
          id: listingId,
          include: ['author', 'author.profileImage', 'images'],
          'fields.image': [
            'variants.landscape-crop',
            'variants.landscape-crop2x',

            // Avatars
            'variants.square-small',
            'variants.square-small2x',
          ],
        })
          .then(response => {
            const listingResponse = denormalisedResponseEntities(response);
            dispatch(queryListingsSuccess(listingResponse[0]));
          })
          .catch(e => {
            console.log(listingId)
            dispatch(queryListingsError(storableError(e)))
          })
      )
    }
    else {
      dispatch(noPackage())
    }
  });
};


export const fetchListing = listingId => async (dispatch, getState, sdk) => {
  return sdk.listings
    .show({
      id: listingId,
      include: ['author', 'author.profileImage', 'images'],
      'fields.image': [
        'variants.scaled-small',
        'variants.square-small2x',

        // Avatars
        'variants.square-small',
        'variants.square-small2x',
      ],
    })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchListingSuccess(response.data));
      return response;
    })
    .catch(e => dispatch(fetchListingError(storableError(e))));
};




export const buyerPackageRequests = (buyerId) => (dispatch, getState, sdk) => {
  dispatch(buyerPackageRequestsRequest());
  const url =
    config.serverBaseUrl +
    config.creditRequestEndPoint +
    '/' +
    buyerId +
    '/' +
    config.buyerPackageRequestsEndPoint;
  const params = {
    headers: {
      Authorization: `Token token=${config.serverToken}`,
    },
  };
  return axios
    .get(url, params)
    .then(response => {
      // eslint-disable-next-line
      response.data.map(({ listing_id }) => {
        dispatch(fetchListing(listing_id));
      });
      dispatch(buyerPackageRequestsSuccess(response.data));
      return response.data;
    })
    .catch(e => {
      dispatch(buyerPackageRequestsError(e));
    });
};