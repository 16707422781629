import { currencyAED } from 'util/types';
import { queryOwnListingsMatch } from './MyActivity.duck';

const flexIntegrationSdk = require('sharetribe-flex-integration-sdk');

export const GETTING_JOBSBOARD_LISTING_REQUEST =
  'app/MatchActivity/GETTING_JOBSBOARD_LISTING_REQUEST';

export const GETTING_JOIN_LISTING_REQUEST = 'app/MatchActivity/GETTING_JOIN_LISTING_REQUEST';

export const GETTING_JOBSBOARD_LISTING_ERROR = 'app/MatchActivity/GETTING_JOBSBOARD_LISTING_ERROR';

export const GETTING_JOIN_LISTING_ERROR = 'app/MatchActivity/GETTING_JOIN_LISTING_ERROR';

export const GETTING_JOBSBOARD_LISTING_SUCCESS =
  'app/MatchActivity/GETTING_JOBSBOARD_LISTING_SUCCESS';

export const ADD_OWN_ENTITIES = 'app/MyActivity/ADD_OWN_ENTITIES';

export const GETTING_JOIN_LISTING_SUCCESS = 'app/MatchActivity/GETTING_JOIN_LISTING_SUCCESS';

const initialState = {
  fetchedJoinedRequest: [],
  jobsBoardListing: [],
  jobsBoardListingsError: null,
  loading: false,
  jobsPagination: null,
  creatingListings: false,
  soloJobsBoard: null,
  vendorEmail: [],
};

export default function matchActivityReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case GETTING_JOBSBOARD_LISTING_REQUEST:
      return {
        ...state,
        jobsBoardListing: state.jobsBoardListing,
        jobsPagination: state.jobsPagination,
        jobsBoardListingsError: null,
        loading: true,
      };
    case GETTING_JOIN_LISTING_REQUEST:
      return {
        ...state,
        jobsBoardListing: state.jobsBoardListing,
        jobsPagination: state.jobsPagination,
        jobsBoardListingsError: null,
        loading: true,
      };
    case GETTING_JOBSBOARD_LISTING_SUCCESS:
      return {
        ...state,
        //{ listingRes, pages }
        jobsBoardListing: payload.listingRes,
        jobsPagination: payload.pages,
        jobsBoardListingsError: null,
        loading: false,
      };
    case GETTING_JOIN_LISTING_SUCCESS:
      return {
        ...state,
        //{ listingRes, pages }
        jobsBoardListing: payload.listingRes,
        jobsPagination: payload.pages,
        jobsBoardListingsError: null,
        loading: false,
      };

    case GETTING_JOBSBOARD_LISTING_ERROR:
      return {
        ...state,
        jobsBoardListing: [],
        jobsBoardListingsError: payload,
        jobsPagination: null,
        loading: false,
      };
    case GETTING_JOIN_LISTING_ERROR:
      return {
        ...state,
        jobsBoardListing: [],
        jobsBoardListingsError: payload,
        jobsPagination: null,
        loading: false,
      };
    case ADD_OWN_ENTITIES:
      return {
        ...state,
        fetchedJoinedRequest: payload,
      };
    default:
      return state;
  }
}
export const gettingJobsBoardListingsError = error => ({
  type: GETTING_JOBSBOARD_LISTING_ERROR,
  error: true,
  payload: error,
});
export const addOwnEntities = sdkResponse => ({
  type: ADD_OWN_ENTITIES,
  payload: sdkResponse,
});
export const gettingJoinListingsError = error => ({
  type: GETTING_JOIN_LISTING_ERROR,
  error: true,
  payload: error,
});
export const gettingJobsBoardListingsRequest = () => ({
  type: GETTING_JOBSBOARD_LISTING_REQUEST,
});

export const gettingJoinRequest = () => ({
  type: GETTING_JOIN_LISTING_REQUEST,
});

export const gettingJobsBoardListingsSuccess = listing => ({
  type: GETTING_JOBSBOARD_LISTING_SUCCESS,
  payload: listing,
});
export const gettingJoinListingsSuccess = listing => ({
  type: GETTING_JOIN_LISTING_SUCCESS,
  payload: listing,
});
export const onGetJobsBoard = searchParams => (dispatch, getState, sdk) => {
  dispatch(gettingJobsBoardListingsRequest());

  // const pages = { page: searchParams.page, perPage: 20 };
  const query = { ...searchParams }; //, ...pages };

  return sdk.listings
    .query(query)
    .then(response => {
      // const pages = response?.data?.meta;
      const data = response.data?.data;
      if (!searchParams.location) {
        const listingRes = data.map(({ id, attributes }) => {
          return { id: id.uuid, ...attributes };
        });
        dispatch(gettingJobsBoardListingsSuccess({ listingRes })); //pages}));
      } else {
        const listingRes = data
          ?.map(({ id, attributes }) => {
            if (
              attributes?.publicData?.location?.city &&
              attributes?.publicData?.location?.city.toLowerCase().includes(searchParams.location)
            ) {
              return { id: id.uuid, ...attributes };
            }
          })
          .filter(data => data);

        dispatch(gettingJobsBoardListingsSuccess({ listingRes })); //pages }));
      }
      return response;
    })
    .catch(e => {
      console.log(e);
      dispatch(gettingJobsBoardListingsError(e));
      return e;
    });
};

export const onGetJoinRequest = (id, searchParams) => async (dispatch, getState, sdk) => {
  dispatch(gettingJoinRequest());

  const integrationSdk = await flexIntegrationSdk.createInstance({
    clientId: process.env.REACT_APP_INTEGRATION_CLIENT_ID,
    clientSecret: process.env.REACT_APP_INTEGRATION_CLIENT_SECRET,
    // baseUrl:
    //   process.env.REACT_APP_FLEX_INTEGRATION_BASE_URL || 'https://flex-integ-api.sharetribe.com',
  });

  const currentUser = getState().user.currentUser;
  const currentUserID = currentUser.id.uuid;
  const currentUserImage = currentUser?.profileImage?.attributes?.variants['square-small']?.url;
  const newUser = {
    userId: currentUserID,
    userMail: currentUser?.attributes?.email,
    userName: currentUser?.attributes?.profile?.displayName,
    userImage: currentUserImage,
    currentStatus: 'noActionTaken',
    showStatus: 'true',
  };

  const initSearchValue = {
    keywords: '',
    pub_listing_type: 'match-activity',
    location: '',
    include: ['author', 'author.profileImage'],
    // page: 1,
  };

  const searchValue = {
    ...initSearchValue,
  };
  const joinReq = await sdk.listings
    .show({ id })
    .then(response => {
      const listingRes = response.data?.data;
      return listingRes?.attributes?.publicData?.joinRequest;
    })
    .catch(e => {
      console.log(e);
    });
  const checkJoins = joinReq.filter(val => val.userId === currentUserID);
  let newJoin = [];
  if (checkJoins.length) {
    return;
  } else {
    newJoin = [...joinReq, newUser];
  }
  return integrationSdk.listings
    .update({
      id,
      publicData: {
        joinRequest: newJoin,
      },
    })
    .then(response => {
      const listingRes = response.data?.data;
      dispatch(addOwnEntities(listingRes));
      dispatch(gettingJoinListingsSuccess(response));
      dispatch(onGetJobsBoard(searchValue));
      return response;
    })
    .catch(e => {
      console.log(e);
      dispatch(gettingJoinListingsError(e));
      return e;
    });
};

export const cancelJoinRequest = (id, joinUserId) => async (dispatch, getState, sdk) => {
  const integrationSdk = await flexIntegrationSdk.createInstance({
    clientId: process.env.REACT_APP_INTEGRATION_CLIENT_ID,
    clientSecret: process.env.REACT_APP_INTEGRATION_CLIENT_SECRET,
    // baseUrl:
    //   process.env.REACT_APP_FLEX_INTEGRATION_BASE_URL || 'https://flex-integ-api.sharetribe.com',
  });
  const currentUser = getState().user.currentUser;
  const joinReq = await sdk.listings
    .show({ id })
    .then(response => {
      const listingRes = response.data?.data;
      return listingRes.attributes.publicData.joinRequest;
    })
    .catch(e => {
      console.log(e);
    });

  if (joinReq) {
    // Find the joinRequest item with the specified userId
    const updatedJoinReq = joinReq.map(item => {
      if (item.userId === joinUserId) {
        // Fetch the email from the item

        // You can now use 'email' for any purpose you need, such as sending a notification or storing it for later use.

        return {
          ...item,
          showStatus: 'false',
        };
      }
      return item;
    });

    // Update the listing with the modified joinRequest
    const params = {
      id: id,
      publicData: {
        joinRequest: updatedJoinReq,
      },
    };

    await integrationSdk.listings.update(params);

    // After the update is successful, you can dispatch an action to notify your MyActivity.js file
    dispatch(
      queryOwnListingsMatch({
        authorId: currentUser.id.uuid,
        listingType: 'match-activity',
      })
    );

    // Pass the email to your MyActivity component
  }
};
