import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import { ensureCurrentUser } from '../../util/data';

import { Form, PrimaryButton, FieldTextInput } from '../../components';

import css from './HitpaySettingsForm.css';


class HitpaySettingsFormComponent extends Component {


  render() {
    return (
      <FinalForm
        {...this.props}
        render={fieldRenderProps => {
          const {
            rootClassName,
            className,
            saveHitpayKeyError,
            currentUser,
            formId,
            handleSubmit,
            inProgress,
            intl,
            invalid,
            // values,
            // hitpayKeyCurrentValue,
            // hitpayKeySaltCurrentValue,
            ready,
          } = fieldRenderProps;
          // const { hitpayKey, hitpayKeySalt } = values;

          // const hitpayKeyChanged = hitpayKeyCurrentValue !== hitpayKey;
          // const hitpayKeySaltChanged = hitpayKeySaltCurrentValue !== hitpayKeySalt;
          const user = ensureCurrentUser(currentUser);

          if (!user.id) {
            return null;
          }

          const hitpayKeyPlaceholder = intl.formatMessage({
            id: 'HitpaySettingsForm.hitpayKeyPlaceholder',
          });
          const hitpayKeyLabel = intl.formatMessage({ id: 'HitpaySettingsForm.hitpayKeyLabel' });
         
          const hitpayKeySaltLabel = intl.formatMessage({
            id: 'HitpaySettingsForm.hitpayKeySaltLabel',
          });
          const hitpayKeySaltPlaceholder = intl.formatMessage({
            id: 'HitpaySettingsForm.hitpayKeySaltPlaceholder',
          });

          const hitpayKeyRequiredMessage = intl.formatMessage({
            id: 'HitpaySettingsForm.hitpayKeyRequiredMessage',
          });

          const hitpayKeySaltRequiredMessage = intl.formatMessage({
            id: 'HitpaySettingsForm.hitpayKeySaltRequiredMessage',
          });

          const hitpayKeyRequired = validators.requiredAndNonEmptyString(hitpayKeyRequiredMessage);
          const hitpayKeySaltRequired = validators.requiredAndNonEmptyString(hitpayKeySaltRequiredMessage);

          const genericError =  saveHitpayKeyError ? (
            <span className={css.error}>
              <FormattedMessage id="HitpaySettingsForm.genericFailure" />
            </span>
          ) : null;

          const classes = classNames(rootClassName || css.root, className);
          const submitDisabled = invalid || inProgress ;
          return (
            <Form
              className={classes}
              onSubmit={e => {
                handleSubmit(e);
              }}
            >
              <div className={css.hitpaySettingsSection}>
                <FieldTextInput
                  type="text"
                  name="hitpayKey"
                  id={formId ? `${formId}.hitpayKey` : 'hitpayKey'}
                  label={hitpayKeyLabel}
                  placeholder={hitpayKeyPlaceholder}
                  validate={hitpayKeyRequired}
                />

                <FieldTextInput
                  type="text"
                  name="hitpayKeySalt"
                  id={formId ? `${formId}.hitpayKeySalt` : 'hitpayKeySalt'}
                  label={hitpayKeySaltLabel}
                  placeholder={hitpayKeySaltPlaceholder}
                  validate={hitpayKeySaltRequired}
                />
              </div>
              <div className={css.bottomWrapper}>
                {genericError}
                <PrimaryButton
                  type="submit"
                  inProgress={inProgress}
                  ready={ready}
                  disabled={submitDisabled}
                  >
                  <FormattedMessage id="HitpaySettingsForm.saveChanges" />
                </PrimaryButton>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

HitpaySettingsFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  formId: null,
  saveHitpayKeyError: null,
  inProgress: false,
};

const { bool, string } = PropTypes;

HitpaySettingsFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  formId: string,
  saveHitpayKeyError: propTypes.error,
  inProgress: bool,
  intl: intlShape.isRequired,
  ready: bool.isRequired,
};

const HitpaySettingsForm = compose(injectIntl)(HitpaySettingsFormComponent);

HitpaySettingsForm.displayName = 'HitpaySettingsForm';

export default HitpaySettingsForm;
