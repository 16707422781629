import { types as sdkTypes } from '../../util/sdkLoader';

export const MAX_MOBILE_SCREEN_WIDTH = 1024;

export const { LatLng, LatLngBounds } = sdkTypes;

export const getSchemaId = param => {
  switch (param) {
    case 'sports':
      return 'CategoriesPage.schemaTitleSports';
    case 'wellness':
      return 'CategoriesPage.schemaTitleWellness';
    case 'adventure':
      return 'CategoriesPage.schemaTitleAdventure';
    case 'yoga':
      return 'CategoriesPage.schemaTitleYoga';
    case 'pilates':
      return 'CategoriesPage.schemaTitlePilates';
    case 'pole-fitness':
      return 'CategoriesPage.schemaTitlePoleFitness';
    case 'rock-climbing':
      return 'CategoriesPage.schemaTitleRockClimbing';
    case 'kickboxing':
      return 'CategoriesPage.schemaTitleKickboxing';
    case 'tennis':
      return 'CategoriesPage.schemaTitleTennis';
    case 'golf':
      return 'CategoriesPage.schemaTitleGolf';
    case 'dance':
      return 'CategoriesPage.schemaTitleDance';
    case 'volleyball':
      return 'CategoriesPage.schemaTitleVolleyball';
    case 'surfing':
      return 'CategoriesPage.schemaTitleSurfing';

    default:
      return 'CategoriesPage.schemaTitleSports';
  }
};

export const getSchemaDescription = param => {
  switch (param) {
    case 'sports':
      return 'CategoriesPage.schemaDescriptionSports';
    case 'wellness':
      return 'CategoriesPage.schemaDescriptionWellness';
    case 'adventure':
      return 'CategoriesPage.schemaDescriptionAdventure';
    case 'yoga':
      return 'CategoriesPage.schemaDescriptionYoga';
    case 'pilates':
      return 'CategoriesPage.schemaDescriptionPilates';
    case 'pole-fitness':
      return 'CategoriesPage.schemaDescriptionPoleFitness';
    case 'rock-climbing':
      return 'CategoriesPage.schemaDescriptionRockClimbing';
    case 'kickboxing':
      return 'CategoriesPage.schemaDescriptionKickboxing';
    case 'tennis':
      return 'CategoriesPage.schemaDescriptionTennis';
    case 'golf':
      return 'CategoriesPage.schemaDescriptionGolf';
    case 'dance':
      return 'CategoriesPage.schemaDescriptionDance';
    case 'volleyball':
      return 'CategoriesPage.schemaDescriptionVolleyball';
    case 'surfing':
      return 'CategoriesPage.schemaDescriptionSurfing';

    default:
      return 'CategoriesPage.schemaDescriptionSports';
  }
};