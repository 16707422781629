import React, { useEffect } from 'react';
import css from './EditEnquiryPage.css';
import { EditListingEnquiryForm } from '../../../forms';
import EnquiryCard from './EnquiryCard';
import { NamedLink } from 'components';
import classNames from 'classnames';

const EditEnquiryPage = props => {
  let currentUserEnquiryData = [];
  let otherEnquiryData = [];

  const {
    currentUser,
    listingType,
    onQueryPromotedListings,
    enquiryListings,
    intl,
    history,
    isEducationSite,
  } = props;

  useEffect(() => {
    const category = isEducationSite
      ? { pub_category: 'language,academic,management,art,undefined' }
      : {};
    onQueryPromotedListings({ pub_listing_type: 'enquiry', ...category });
  }, [onQueryPromotedListings, isEducationSite]);

  enquiryListings &&
    enquiryListings.forEach(data =>
      data?.author?.id.uuid === currentUser?.id.uuid
        ? currentUserEnquiryData.push(data)
        : otherEnquiryData.push(data)
    );

  return (
    <div>
      <div className={css.breadCrumbWrapper}>
        <NamedLink name={isEducationSite ? 'EducationPage' : 'HomePage'}>Home</NamedLink>
        <span className={css.breadcrumbArrow}>&gt;</span>
        <NamedLink name="NewInquirePage">Inquiry Request</NamedLink>
      </div>
      <div className={css.enquiryWrapper}>
        <div className={css.enquiryFormWrapper}>
          <EditListingEnquiryForm
            listingType={props.listingType}
            isEducationSite={isEducationSite}
            onSubmit={async values => {
              const {
                category,
                sub_category,
                title,
                description,
                price,
                availability,
                location,
                address,
              } = values;
              const priceMaybe = price ? { price } : {};
              const {
                selectedPlace: { address: locationAddress, origin },
              } = location;
              const updatedValues = {
                title: title.trim(),
                description: description,
                geolocation: origin,
                ...priceMaybe,
                publicData: {
                  address: address,
                  location: { address: locationAddress },
                  category: category,
                  sub_category: sub_category,
                  availability: availability,
                  listing_type: props.listingType,
                },
              };

              const res = await props.onCreateListingDraft(updatedValues);
              props.onPublishListingDraft(res.data.data.id);
            }}
          />
        </div>

        {/* Right side */}
        <div
          className={classNames(
            css.enquiryExampleWrapper,
            isEducationSite && css.enquiryExampleWrapperEdu
          )}
        >
          <div className={css.enquiryRequestWrapper}>
            <div className={css.enquiryRequestTitle}>Your Request</div>
            {/* <EnquiryCard       
            listingType={listingType}
            enquiryListings={currentUserEnquiryData}
            currentUser={currentUser}
            showDate={false}
            intl={intl}
            history={history}
          /> */}
            <video
              className={css.mobilePostVideo}
              src="https://res.cloudinary.com/movementbuddy-pte-ltd/video/upload/v1669271489/probuddy/static/images/Email/MobilePost_ijslli.mp4"
              controls="controls"
              autoplay="autoplay"
            />
          </div>
          <div className={css.enquiryRequestWrapper}>
            <div className={css.enquiryRequestTitle}>Other Request</div>
            <EnquiryCard
              listingType={listingType}
              enquiryListings={otherEnquiryData}
              currentUser={currentUser}
              showDate={false}
              intl={intl}
              history={history}
              isEducationSite={isEducationSite}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditEnquiryPage;
