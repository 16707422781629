import React from 'react';
import css from './SectionRentSpace.css';
import { FormattedMessage } from '../../util/reactIntl';
import { NamedLink, SportsCategoriesCard } from '../../components';
import { RentSpace } from '../../util/gameTypesData';

const SectionRentSpace = props => {
  return (
    <div className={css.findMembership}>
      <div className={css.title}>
        <FormattedMessage id="SectionRentSpace.title" />
      </div>
      <div className={css.memberships}>
        {RentSpace.map(space => (
          <NamedLink
            name="SearchPage"
            to={{
              search: space.searchPageLink,
            }}
          >
            <SportsCategoriesCard
              key={space.id}
              image={space.image}
              title={space.title}
              isOtherLinks={false}
              serviceTitleSectionClass={css.serviceTitleSectionClass}
              serviceSectionClass={css.serviceSectionClass}
            />
            {/* <div className={css.gymPass}>
                        <h3><FormattedMessage id="SectionRentSpace.gymPass" /></h3>
                    </div> */}
          </NamedLink>
        ))}

        {/* <NamedLink
          name="SearchPage"
          to={{
            search: `pub_listing_type=facility&pub_category=health_care&pub_sub_category=tennis`,
          }}
        >
          <div className={css.tennisCourts}>
            <h3>
              <FormattedMessage id="SectionRentSpace.tennisCourts" />
            </h3>
          </div>
        </NamedLink>

        <NamedLink
          name="SearchPage"
          to={{
            search: `pub_listing_type=facility&pub_category=health_care&pub_sub_category=personal training`,
          }}
        >
          <div className={css.fitnessStudios}>
            <h3>
              <FormattedMessage id="SectionRentSpace.fitnessStudios" />
            </h3>
          </div>
        </NamedLink> */}
      </div>
    </div>
  );
};

SectionRentSpace.defaultProps = {};

SectionRentSpace.propTypes = {};

export default SectionRentSpace;
