import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { withViewport } from '../../util/contextHelpers';
import { connect, useSelector } from 'react-redux';
import Switch from 'react-switch';
import {
  FooterBottomMenu,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  LeftBar,
  Loading,
  Modal,
  Page,
} from 'components';
import { HeaderContainer, MobileHeaderContainer } from '../../containers';
import SubCategoriesFilter from 'containers/HomePage/SubCategoriesFilter/SubCategoriesFilter';
import {
  city,
  cityEducation,
  subCategoriesFilterOptionsEducational,
} from 'marketplace-custom-config';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';
import moment from 'moment';
import {
  loadData,
  onSearchEnquiry,
  queryPromotedListingsEdu,
  searchListings,
  sendEnquiry,
  showListingEdu,
} from './EducationPage.duck';
import {
  onSearchListingTab,
  onSearchLocation,
  onSearchSubCategory,
} from 'containers/HeaderContainer/HeaderContainer.duck';
import EnquiryFormHome from '../HomePage/EnquiryForm/EnquiryForm';
import { changingActivefooterTab } from 'ducks/user.duck';
import { isScrollingDisabled } from 'ducks/UI.duck';
import { getMarketplaceEntities } from 'ducks/marketplaceData.duck';
import ShowListings from 'containers/HomePage/ShowListings/ShowListings';
import CityFilter from 'containers/HomePage/CityFilter/CityFilter';
import { LISTING_TYPES } from 'util/constants';
import { listingMode } from 'forms/EditListingDescriptionForm/EditListingDescriptionForm.data';
import { ListingMode } from 'containers/AllSportsPage/ListingMode';
import css from './EducationPage.css';
import { whatsappLinkClickTrack } from 'util/gtag_conversionTracking';
import TopFloatingMessage from 'components/TopFloatingMessage/TopFloatingMessage';
import GetQuoteModal from 'components/GetQuoteModal/GetQuoteModal';
import { getBannerImg } from 'components/GetQuoteModal/GetQuoteModal.data';

export const listingSwitches = [
  {
    label: 'Tutors',
    id: LISTING_TYPES.TUTOR,
    image:
      'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1699505236/probuddy/static/images/HomePage/tutors_sf1ezq.jpg',
  },
  {
    label: 'Academy',
    id: LISTING_TYPES.ACADEMY,
    image:
      'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1699505251/probuddy/static/images/HomePage/academies_ajtg5t.jpg',
  },
  {
    label: 'Classes',
    id: LISTING_TYPES.COURSES,
    image:
      'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1699505285/probuddy/static/images/HomePage/classes_xvzpcl.jpg',
  },
  {
    label: 'Courses',
    id: LISTING_TYPES.EDUCATION_COURSES,
    image:
      'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1699505292/probuddy/static/images/HomePage/courses_zlbel3.jpg',
  },
];

export const EducationPageComponent = props => {
  const { currentUser } = useSelector(state => state.user);

  const {
    intl,
    promotedListings,
    gettingListings,
    listingAllReviews,
    queryPromotedListings,
    onSendEnquiry,
    searchLocation,
    searchActivity,
    onchangingActivefooterTab,
    onSearchListingTab,
    showListing,
    params,
    pagination,
    viewport,
  } = props;
  const [currentTab, setCurrentTab] = useState(props.searchListingTab || '');
  const [chargebeeData, setchargebeeData] = useState({});
  const [startPoint, setstartPoint] = useState(0);
  const steps = 15;
  const [items, setItems] = useState([]);

  const [isScrolling, setIsScrolling] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(searchLocation);
  const [selectedListing, setSelectedListing] = useState(
    props.searchListingTab || LISTING_TYPES.TUTOR
  );
  const [selectedSubCategory, setSelectedSubCategory] = useState(searchActivity);
  const [activeFooterTab, setActiveFooterTab] = useState('search');
  const [isOpenSearchBar, setIsOpenSearchBar] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [showOpacity, setShowOpacity] = useState(false);
  const [selectedDate, setselectedDate] = useState();
  const [selectedMinTime, setselectedMinTime] = useState(0);
  const [selectedMaxTime, setselectedMaxTime] = useState(23);
  const [minTime, setMintime] = useState(0);
  const [maxTime, setMaxTime] = useState(22);
  const [queryParams, setqueryParams] = useState({});
  const [showTimePopup, setshowTimePopup] = useState(false);
  const [filterSearch, setfilterSearch] = useState('');
  const [categorySearch, setCategorySearch] = useState([]);
  const [selectedMode, setSelectedMode] = useState(props.searchListingTab || [listingMode[0].mode]);
  const [page, setpage] = useState(1);
  const [showQuotes, setShowQuotes] = useState(false);
  const [quotesModalOpen, setQuotesModalOpen] = useState(false);

  useEffect(() => {
    if (
      currentUser?.attributes?.profile?.protectedData?.ChargebeeId &&
      Object.keys(chargebeeData).length === 0
    ) {
      setchargebeeData(currentUser?.attributes?.profile?.protectedData);
    }
    if (currentUser === null && Object.keys(chargebeeData).length > 0) {
      setchargebeeData({});
    }
  }, [currentUser, chargebeeData]);

  const MAX_MOBILE_SCREEN_WIDTH = 1024;
  const viewportWidth = viewport?.width;
  const isMobileLayoutLeftbar = useMemo(() => viewportWidth < MAX_MOBILE_SCREEN_WIDTH, [
    viewportWidth,
  ]);
  const isMobileLayout = useMemo(
    () => (!!viewportWidth ? viewportWidth < MAX_MOBILE_SCREEN_WIDTH : false),
    [viewportWidth]
  );

  const onSearch = useCallback(
    searchquery => {
      const queryParam = {
        pub_listing_type: 'tutor',
        pub_listing_mode:
          selectedMode.includes('Online') && selectedMode.includes('In Person')
            ? 'Online, In Person' || 'Online,In Person'
            : selectedMode.includes('In Person')
            ? 'In Person'
            : !selectedMode.length
            ? null
            : 'Online,online',
        ...queryParams,
        page: 1,
        ...searchquery,
      };
      setqueryParams(queryParam);
      setCurrentTab(
        queryParam?.pub_kids_promotions
          ? null
          : queryParam?.pub_listing_type
          ? queryParam?.pub_listing_type
          : ''
      );

      queryPromotedListings(queryParam);
    },
    [queryPromotedListings, queryParams, selectedMode]
  );

  useEffect(() => {
    onchangingActivefooterTab('search');
    if (typeof window !== 'undefined') {
      window.onscroll = () => {
        let currentScrollPos = window.pageYOffset;
        let maxScroll = document.body.scrollHeight - (window.innerHeight - 50);
        if (currentScrollPos > 0 && currentScrollPos < maxScroll && !isMobileLayout) {
          setIsScrolling(true);
          setShowQuotes(window.pageYOffset > 250);
        } else if (!isMobileLayout) {
          setIsScrolling(false);
        } else if (
          isMobileLayout &&
          currentScrollPos > 200 &&
          currentScrollPos < maxScroll &&
          !isScrolling
        ) {
          setIsScrolling(true);
        } else if (isMobileLayout && currentScrollPos < 200) {
          setIsScrolling(false);
        }
      };
    }
  }, [isMobileLayout, isScrolling, onchangingActivefooterTab]);

  useEffect(() => {
    setItems(promotedListings.slice(0, 15));
    setstartPoint(0);
  }, [promotedListings]);

  const getSearchLocation = useCallback(
    city => {
      const selectedLocationNew = {
        predictions: [],
        search: city?.label,
        selectedPlace: {
          address: city?.label,
          bounds: city?.bounds,
        },
      };
      setSelectedLocation(selectedLocationNew);
      onSearch({
        address: selectedLocationNew ? selectedLocationNew?.search : null,
        bounds: selectedLocationNew ? city?.bounds : null,
      });
    },
    [onSearch]
  );

  useEffect(() => {
    if (params && params.city_name) {
      const getCityData = city.find(city => params.city_name === city.label);
      getSearchLocation(getCityData);
    }
  }, [params, getSearchLocation]);

  useEffect(() => {
    if (showOpacity) {
      document.body.classList.add(css.testScroll);
    } else {
      document.body.classList.remove(css.testScroll);
    }
  }, [showOpacity]);

  const handleOpacityChange = value => {
    setShowOpacity(value);
  };

  const handleHeaderSearchBar = value => {
    setIsOpenSearchBar(value);
  };

  const onkeywordSearch = useCallback(
    activity => {
      const searchParams = {
        pub_sub_category: activity?.key ? activity?.key : null,
        keywords: activity.title ? null : activity.key ? null : activity,
      };
      if (!!activity?.id) {
        setCurrentTab(null);
        setSelectedSubCategory(null);
        setqueryParams(null);
      }
      !!activity.id && onSearchListingTab(null);
      !!activity.id ? showListing(activity.id) : onSearch(searchParams);
    },
    [onSearchListingTab, showListing, onSearch]
  );

  const topbar = useMemo(
    () =>
      !!isMobileLayout ? (
        <MobileHeaderContainer
          showSearch={true}
          onSearch={onSearch}
          onTabChangeSearch={onSearch}
          resetPage={() => {}}
          handleOpacityChange={handleOpacityChange}
          onkeywordSearch={onkeywordSearch}
          isScrolling={isScrolling}
          handleHeaderSearchBar={handleHeaderSearchBar}
          onChangeActivity={value => {
            setSelectedSubCategory(value);
          }}
          searchModalOpen={value => setModalOpen(value)}
          disableFilterScroll={() => {}}
          showMap={false}
          currentTab={tab => {
            setSelectedListing(tab.id);
            setCurrentTab(tab.id);
          }}
          isEducationSite={true}
        />
      ) : (
        <HeaderContainer
          onSearch={onSearch}
          handleHeaderSearchBar={handleHeaderSearchBar}
          onTabChangeSearch={onSearch}
          handleOpacityChange={handleOpacityChange}
          isMobileLayout={isMobileLayout}
          onkeywordSearch={onkeywordSearch}
          isScrolling={isScrolling}
          currentUser={currentUser}
          onChangeActivity={value => {
            setSelectedSubCategory(value);
          }}
          resetPage={() => {}}
          currentTab={tab => {
            setSelectedListing(tab.id);
            setCurrentTab(tab.id);
          }}
          disableFilterScroll={() => {}}
          showOpacity={showOpacity}
          isEducationSite={true}
        />
      ),
    [isMobileLayout, onSearch, showOpacity, isScrolling, currentUser, onkeywordSearch]
  );

  const fetchMoreData = () => {
    setTimeout(() => {
      setItems(
        items.concat(promotedListings?.slice(startPoint + steps, startPoint + steps + steps))
      );
    }, 1500);
    setstartPoint(startPoint + steps);
  };

  const handleClassDateChange = selectedDate => {
    const date = moment(selectedDate).format('YYYY-MM-DD');
    setselectedDate(date);
    setselectedMinTime(0);
    setselectedMaxTime(23);
    setMintime(0);
    setMaxTime(22);
    onSearch({
      pub_listing_type: 'courses',
      dates: `${date},${date}`,
      times: '00:00,23:59',
    });
  };

  const onTimeChange = value => {
    setMintime(value[0]);
    setMaxTime(value[1]);
  };

  const onChangeApplyTimeSearch = () => {
    const date = selectedDate
      ? moment(selectedDate).format('YYYY-MM-DD')
      : moment().format('YYYY-MM-DD');
    setshowTimePopup(false);
    setselectedMinTime(minTime);
    setselectedMaxTime(maxTime);
    const searchParams = {
      pub_listing_type: 'class',
      dates: `${date},${date}`,
      times: `${selectedMinTime}:00,${selectedMaxTime}:00`,
    };
    onSearch(searchParams);
  };

  const onSubCategorySearch = item => {
    const isSelected = item?.label === selectedSubCategory?.label;
    setSelectedSubCategory(isSelected ? null : item);
    props.onSearchSubCategory(isSelected ? null : item);
    const searchParams = {
      pub_sub_category: isSelected ? null : item.key,
    };
    onSearch(searchParams);
  };

  const onLocationSearch = city => {
    const selectLocation =
      selectedLocation?.search === city.label || selectedLocation?.label === city.label
        ? null
        : {
            predictions: [],
            search: city.label,
            selectedPlace: {
              address: city.label,
              bounds: city.bounds,
            },
          };
    setSelectedLocation(selectLocation);
    props.onSearchLocation(selectLocation);
    const searchParams = {
      address: selectLocation ? selectLocation?.search : null,
      bounds: selectLocation ? city?.bounds : null,
    };
    onSearch(searchParams);
  };

  const handleListingChange = item => {
    const selectListing = selectedListing === item.id ? null : item.id;

    setSelectedListing(selectListing || 'tutor');
    setCurrentTab(selectListing);
    props.onSearchListingTab(selectListing);

    const date = moment().format('YYYY-MM-DD');
    // .add(i, 'days');
    const searchParams = {
      pub_category: null,
      pub_listing_type: selectListing ? selectListing : 'tutor',
      dates: selectListing === 'courses' ? `${date},${date}` : null,
      times: selectListing === 'courses' ? '00:00,23:59' : null,
    };
    onSearch(searchParams);

    props.onSearchListingTab(selectListing);
  };

  const handleNewListingChange = item => {
    const newSelectedListing = selectedMode.includes(item.mode)
      ? selectedMode.filter(e => e !== item.mode)
      : [...selectedMode, item.mode];

    setSelectedMode(newSelectedListing);
    // selectedMode.includes(item.mode)
    //   ? setSelectedMode(prev => prev.filter(e => e !== item.mode))
    //   : setSelectedMode(prev => [...prev, item.mode]);

    // setNewSelectedListing(item.mode || 'Online');
    setCurrentTab(item.mode);

    const payload = {
      pub_listing_type: selectedListing,
      pub_listing_mode:
        newSelectedListing.includes('Online') && newSelectedListing.includes('In Person')
          ? 'Online, In Person' || 'Online,In Person'
          : newSelectedListing.includes('In Person')
          ? 'In Person'
          : !newSelectedListing.length
          ? null
          : 'Online',
    };

    onSearch(payload);

    props.onSearchListingTab(item.mode);
  };

  const handleOnChange = value => {
    setfilterSearch(value);

    props.onSearchEnquiry({
      pub_listing_type: 'enquiry',
      keywords: value,
    });
  };

  const handleOnCheck = e => {
    const { value, checked } = e.target;
    let updatedValue = [];
    // Case 1 : The user checks the box
    if (checked) {
      updatedValue = [...categorySearch, value.toLowerCase()];
      setCategorySearch(updatedValue);
    }

    // Case 2  : The user unchecks the box
    else {
      updatedValue = [...categorySearch];
      setCategorySearch(updatedValue);
    }
    updatedValue.length === 0
      ? props.onSearchEnquiry({ pub_listing_type: 'enquiry' })
      : props.onSearchEnquiry({
          pub_listing_type: 'enquiry',
          pub_sub_category: updatedValue,
        });
  };

  const onChange = () => {};

  return (
    <Page>
      <LayoutSingleColumn>
        <LayoutWrapperTopbar className={css.layoutTopbar}>{topbar}</LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <SubCategoriesFilter
            showOpacity={showOpacity}
            showFilter={false}
            activeFooterTab={activeFooterTab}
            selectedSubCategory={selectedSubCategory}
            onSubCategorySearch={onSubCategorySearch}
            isEducationSite={true}
            options={subCategoriesFilterOptionsEducational}
          />
          {!!isMobileLayoutLeftbar ? (
            <></>
          ) : (
            <LeftBar
              isMobileLayout={isMobileLayout}
              history={props.history}
              isOpenSearchBar={isOpenSearchBar}
              showOpacity={showOpacity}
              onShowEnquiryTab={onSearch}
              isScrolling={isScrolling}
              currentTab={tab => {
                setCurrentTab(tab.id);
              }}
              onClose={() => setActiveFooterTab('search')}
              isEducationSite={true}
            />
          )}
          <div
            className={classNames(css.main, {
              // [css.mapMain]: showMap,
              [css.openMainHeaderMain]: isOpenSearchBar && showOpacity,
              [css.disableScroll]:
                showOpacity ||
                activeFooterTab === 'leftbar' ||
                activeFooterTab === 'create' ||
                activeFooterTab === 'activitySearch' ||
                modalOpen,
              [css.disableFilterScroll]: showOpacity,
              [css.openModalDisableScrolling]: modalOpen,
            })}
          >
            {showQuotes && (
              <TopFloatingMessage
                FormattedButtonText="CategoriesPage.quoteButtonText"
                titleMessage={`Let us find a Tutor for you?`}
                lableText="Tell us what you need"
                inputText="We aim to respond within 30 minutes"
                onClick={() => setQuotesModalOpen(true)}
                isEducationSite={true}
              />
            )}
            <GetQuoteModal
              modalId={`Education.quotes`}
              open={quotesModalOpen}
              intl={intl}
              banner={{
                img: getBannerImg('tennis'), // <---
                head: `Answer a couple questions to help us find you the best Tutor.`,
                desc: `With Probuddy, you can communicate with the best Tutor in your city. We will match you with a program that is perfect for you within 24 hours.`,
              }}
              onModalClose={() => setQuotesModalOpen(false)}
              currentUser={!!currentUser?.attributes?.email ? currentUser : ''}
              isEducationSite={true}
              isAuthenticated={!!currentUser?.attributes?.email}
              category={true} // if outside from category set true
              mailAtbrs={{
                forName: `sportName Category`,
                altUrl: `https://www.probuddy.io/sportName`,
                postFixFromName: `sportName Category`,
              }}
            />
            {/*
            //  floating Whatsapp messages and moblie what icon
            {isScrolling && (
              <div className={css.whatsappLinkHome}>
                <a
                  href="https://wa.me/message/WHZFXEGQ6RL5H1"
                  onClick={() => {
                    whatsappLinkClickTrack();
                  }}
                >
                  <img alt="whatsapp-link" src="https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1699507459/probuddy/static/images/Icons/WhatsappImg_m1f6lt.png" />
                </a>
              </div>
            )} */}
            <div>
              <img
                className={css.bannerImage}
                src={
                  isMobileLayout
                    ? 'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1679462361/probuddy/static/NewEducation/EducationBannerMobile_wccgfr.png'
                    : 'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1678949046/probuddy/static/NewEducation/EducationBanner1_bp0ly7.png'
                }
                alt=" "
              />
            </div>

            <div className={css.cityHeading}>Tutors from the following Countries</div>
            <CityFilter
              setSelectedLocation={onLocationSearch}
              selectedLocation={selectedLocation}
              isEducationSite={true}
              city={cityEducation}
            />
            {/* <div className={css.switchBtnWrap}> */}
            <div className={css.switchContainer}>
              {/* {listingSwitches.map(item => {
                  return (
                    <div
                      key={item.id}
                      className={classNames(css.switch, {
                        [css.switchChecked]: selectedListing === item.id,
                        [css.switchUnchecked]: selectedListing !== item.id,
                      })}
                    >
                      <h4>{item.label}</h4>
                      <Switch
                        uncheckedIcon={false}
                        checkedIcon={false}
                        onChange={() => handleListingChange(item)}
                        checked={selectedListing === item.label}
                      />
                    </div>
                  );
                })} */}
              {listingSwitches.map(item => {
                let label = item.label;
                return (
                  <div
                    key={item.id}
                    className={classNames(css.switch, {
                      [css.switchActive]: selectedListing === item.id,
                    })}
                    onClick={() => handleListingChange(item)}
                  >
                    <img className={css.switchimage} src={item.image} alt={label} />
                    <h4>{label}</h4>
                    {/* <Switch
                          uncheckedIcon={false}
                          checkedIcon={false}
                          // onChange={() => this.handleListingChange(item)}
                          checked={this.state.selectedListing === item.label}
                        /> */}
                  </div>
                );
              })}
            </div>
            <ListingMode
              handleNewListingChange={handleNewListingChange}
              selectedMode={selectedMode}
            />
            {/* </div> */}

            {showOpacity || activeFooterTab === 'leftbar' ? (
              <div className={css.overlayMain} />
            ) : null}
            {gettingListings ? (
              <Loading />
            ) : (
              <div>
                {currentTab === 'enquiry' ? (
                  <EnquiryFormHome
                    promotedListings={promotedListings}
                    intl={intl}
                    prop={props}
                    currentUser={currentUser}
                    searchInputData={null}
                    filterSearch={filterSearch}
                    handleOnChange={handleOnChange}
                    handleOnCheck={handleOnCheck}
                    onChange={onChange}
                    onSendEnquiry={onSendEnquiry}
                    subCategoriesFilterOptions={subCategoriesFilterOptionsEducational}
                    isEducationSite={true}
                  />
                ) : (
                  <ShowListings
                    currentTab={currentTab}
                    chargebeeData={chargebeeData}
                    fetchMoreData={fetchMoreData}
                    gettingListings={gettingListings}
                    handleClassDateChange={handleClassDateChange}
                    intl={intl}
                    items={items}
                    maxTime={maxTime}
                    minTime={minTime}
                    onChangeApplyTimeSearch={onChangeApplyTimeSearch}
                    onTimeChange={onTimeChange}
                    promotedListings={promotedListings}
                    showTimePopup={showTimePopup}
                    selectedDate={selectedDate}
                    selectedMaxTime={selectedMaxTime}
                    selectedMinTime={selectedMinTime}
                    setShowTimePopup={value => {
                      setshowTimePopup(value);
                    }}
                    pagination={pagination}
                    changePage={page => {
                      onSearch({ page: page });
                      setpage(page);
                    }}
                    page={page}
                    listingAllReviews={listingAllReviews}
                    isEducationSite={true}
                    viewport={viewport}
                  />
                )}
              </div>
            )}
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter
          className={classNames(css.layoutFooter, {
            [css.activeleftbarFooter]: activeFooterTab === 'leftbar',
          })}
        >
          {!!isMobileLayout && !(activeFooterTab === 'leftbar') && !modalOpen && (
            <FooterBottomMenu
              activeFooterTab={activeFooterTab}
              changeActivieFooterTab={value => setActiveFooterTab(value)}
              history={props.history}
              isScrolling={isScrolling}
              isEducationSite={true}
            />
          )}
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
      <Modal
        isOpen={!!(activeFooterTab === 'leftbar')}
        onClose={() => {
          setActiveFooterTab('search');
        }}
        id="filterModal"
        onManageDisableScrolling={() => {}}
        className={css.modalLeftBar}
      >
        <LeftBar
          isMobileLayout={isMobileLayout}
          history={props.history}
          isOpenSearchBar={isOpenSearchBar}
          showOpacity={showOpacity}
          onShowEnquiryTab={onSearch}
          onLogoClick={() => {
            setActiveFooterTab('search');
          }}
          onClose={() => {
            setActiveFooterTab('search');
          }}
          currentTab={tab => {
            setCurrentTab(tab.id);
          }}
          isEducationSite={true}
        />
      </Modal>
    </Page>
  );
};
const mapStateToProps = state => {
  const { currentUser, currentUserListings, currentUserHasListings } = state.user;
  const {
    promotedListingRefs,
    gettingListings,
    listingAllReviews,
    pagination,
  } = state.EducationPage;
  const { searchActivity, searchLocation, searchListingTab } = state.HeaderSection;
  const { saveContactDetailsInProgress } = state.ContactDetailsPage;
  const promotedListings = getMarketplaceEntities(state, promotedListingRefs);

  return {
    scrollingDisabled: isScrollingDisabled(state),
    promotedListings,
    currentUser,
    saveContactDetailsInProgress,
    searchActivity,
    searchLocation,
    gettingListings,
    currentUserListings,
    currentUserHasListings,
    searchListingTab,
    listingAllReviews,
    pagination,
  };
};

const mapDispatchToProps = dispatch => ({
  searchListings: searchParams => dispatch(searchListings(searchParams)),
  queryPromotedListings: searchquery => dispatch(queryPromotedListingsEdu(searchquery)),
  showListing: listingId => dispatch(showListingEdu(listingId)),
  onSearchSubCategory: searchSubCategory => dispatch(onSearchSubCategory(searchSubCategory)),
  onSearchLocation: searchlocation => dispatch(onSearchLocation(searchlocation)),
  onSearchListingTab: searchListing => dispatch(onSearchListingTab(searchListing)),
  onSendEnquiry: (listingId, message) => dispatch(sendEnquiry(listingId, message)),
  onSearchEnquiry: data => dispatch(onSearchEnquiry(data)),
  onchangingActivefooterTab: value => dispatch(changingActivefooterTab(value)),
});

const EducationPage = compose(
  withRouter,
  withViewport,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(EducationPageComponent);

EducationPage.loadData = loadData;

export default EducationPage;
