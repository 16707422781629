import React from 'react';
import { LISTING_TYPES } from 'util/constants';

import classes from '../../assets/HomePage/tabIcon/classes.svg';
import myClient from '../../assets/HomePage/myClient.svg';
import companies from '../../assets/HomePage/tabIcon/companies.svg';
import events from '../../assets/HomePage/tabIcon/events.svg';
import freelancers from '../../assets/HomePage/tabIcon/freelancers.svg';
import spaces from '../../assets/HomePage/tabIcon/spaces.svg';
import membership from '../../assets/HomePage/tabIcon/membership.svg';

export const tabsList = [
  {
    id: LISTING_TYPES.COMPANY,
    image: companies,
    name: 'Companies',
  },
  {
    id: LISTING_TYPES.FREELANCER,
    image: freelancers,
    name: `Instructors/Pro's`,
  },
  {
    id: LISTING_TYPES.CLASS,
    image: classes,
    name: 'Classes',
  },
  {
    id: LISTING_TYPES.FACILITY,
    image: spaces,
    name: 'Facilities',
  },
  {
    id: LISTING_TYPES.EVENT,
    image: events,
    name: 'Events',
  },
  {
    id: LISTING_TYPES.MEMBERSHIP,
    image: membership,
    name: 'Memberships',
  },
];

export const educationTab = [
  { id: LISTING_TYPES.ACADEMY, image: classes, name: 'Academy' },
  { id: LISTING_TYPES.TUTOR, image: myClient, name: 'Tutors' },
  { id: LISTING_TYPES.COURSES, image: companies, name: 'Classes' },
  { id: LISTING_TYPES.EDUCATION_COURSES, image: freelancers, name: 'Courses' },
  { id: LISTING_TYPES.REMOTE_TUTOR, image: events, name: 'Remote Tutor' },
];

export const TennisTab = [
  { id: LISTING_TYPES.PLAYER_PROFILE, label: 'Players Community' },
  { id: LISTING_TYPES.EVENT, label: 'Activities' },
  { id: LISTING_TYPES.PRODUCT, label: 'Shop' },
];

export const checkboxSvg = (
  <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 5.8L3.85714 9L11 1"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="transparent"
    />
  </svg>
);

export const onSuggestionsClearRequested = () => {};

export const shouldRenderSuggestions = () => true;

export const renderSectionTitle = section => section.title;

export const getSectionSuggestions = section => section.options;

export const onSuggestionsFetchRequested = () => false;

export const handleQueryFocusout = e => {
  document.getElementById('name-category-query').focus();
};
