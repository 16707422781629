import React, { useState, useEffect } from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import classNames from 'classnames';
import { propTypes } from 'util/types';
import { FormattedMessage, injectIntl, intlShape } from 'util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import { Button, Form } from '../../components';
import { getExtensionFromUrl } from 'util/urlHelpers';

import css from '../EditListingExpectationsForm/EditListingExpectationsForm.css';

export const EditListingIntroductoryForm = props => {
  const [assets, setAssets] = useState({});
  const [isAssetUpdated, setIsAssetUpdated] = useState(false);
  const [pictureError, setPictureError] = useState(false);
  const { initialValues } = props;
  useEffect(() => {
    if (initialValues?.introductoryVideo) {
      setAssets({ introductoryVideo: initialValues.introductoryVideo });
    }
  }, [initialValues]);

  const uploadWidget = (e, uniqId) => {
    e.preventDefault();

    if (typeof window !== 'undefined') {
      window.cloudinary.openUploadWidget(
        {
          cloud_name: 'movementbuddy-pte-ltd',
          upload_preset: 'movementbuddy',
          clientAllowedFormats: ['video'],
          allowed_formats: ['mp4'],
        },
        function(error, result) {
          console.log(error, result);
          if (result && result.info && result.info.secure_url) {
            setAssets({ introductoryVideo: result.info.secure_url });
          }
          setIsAssetUpdated(true);
          setPictureError(false);
        }
      );
    }
  };

  const submitHandler = values => {
    console.log(values);

    if (!assets.introductoryVideo) {
      setPictureError(true);
      return;
    }

    props.onSubmit({ introductoryVideo: assets.introductoryVideo });
  };

  return (
    <FinalForm {...props} initialValues={props.initialValues} onSubmit={submitHandler}>
      {formRenderProps => {
        const classes = classNames(css.root, formRenderProps.className);

        const submitInProgress = formRenderProps.updateInProgress;
        const submitReady = formRenderProps.updated || formRenderProps.ready;

        const submitDisabled =
          (formRenderProps.invalid ||
            formRenderProps.disabled ||
            formRenderProps.submitInProgress) &&
          !isAssetUpdated;

        return (
          <Form onSubmit={formRenderProps.handleSubmit} className={classes}>
            {pictureError ? (
              <p className={css.error}>
                <FormattedMessage id="EditListingIntroductionForm.pictureError" />
              </p>
            ) : null}
            {assets.introductoryVideo ? (
              <div>
                <div className={css.pictureContainer}>
                  <video src={assets.introductoryVideo} controls width="100%">
                    <source
                      src={assets.introductoryVideo}
                      type={`video/${getExtensionFromUrl(assets.introductoryVideo)}`}
                    />
                  </video>
                </div>
              </div>
            ) : null}
            <div>
              <button
                className={css.uploadPictureBtn}
                onClick={e => uploadWidget(e, 'introductoryVideo')}
              >
                <FormattedMessage id="EditListingIntroductoryForm.uploadAsset" />
              </button>
            </div>
            {!assets.introductoryVideo && (
              <div className={css.videoWarningText}>{'* only mp4 file is allowed'} </div>
            )}
            <div className={css.submitButtonContainer}>
              <Button
                className={css.submitButton}
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
                ready={submitReady}
              >
                {formRenderProps.saveActionMsg}
              </Button>
            </div>
          </Form>
        );
      }}
    </FinalForm>
  );
};

EditListingIntroductoryForm.defaultProps = { fetchErrors: null };

EditListingIntroductoryForm.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingIntroductoryForm);
