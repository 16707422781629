import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Modal } from 'components';
import moment from 'moment';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import routeConfiguration from '../../routeConfiguration';
import { queryPromotedListings, showListing } from '../HomePage/HomePage.duck';
import { createResourceLocatorString } from '../../util/routes';
import ProbuddyLogo from '../../assets/HomePage/ProbuddylogoWhite.svg';
import HeaderSearchIcon from '../../assets/HomePage/HeaderSearchIcon.svg';
import { HomePageLocationSearchForm } from '../../forms';
import config from '../../config';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  onSearchFilter,
  onSearchSubCategory,
  searchListings,
  onSearchListingTab,
} from './HeaderContainer.duck';
import { parse } from 'util/urlHelpers';
import classNames from 'classnames';
import css from './HeaderContainer.css';

class HeaderContainerComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeSearchTab: false,
      showSearch: false,
      min: 0,
      max: 1000,
      // isVisible: false,
      activeFilterTab: null,
      selectedSubCategory: props.searchActivity,
      selectedLocation: props.searchLocation
        ? props.searchLocation
        : {
            predictions: [],
            search: '',
            selectedPlace: {
              address: '',
            },
          },
      showTopCategoryPopup: false,
      showActivityPopup: false,
      showModal: false,
    };
    this.handleProviderQueryChange = this.handleProviderQueryChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.clearAll = this.clearAll.bind(this);
    this.onSubCategorySearch = this.onSubCategorySearch.bind(this);
    this.onSelectSearchTab = this.onSelectSearchTab.bind(this);
    this.resetPage = this.resetPage.bind(this);
    this.formContainer = React.createRef();
    this.onChangePrice = this.onChangePrice.bind(this);
    this.getQueryParameters = this.getQueryParameters.bind(this);
  }

  componentDidMount() {
    const queryString = parse(this.props.location.search);
    if (queryString && queryString?.listing_type) {
      // this.setState({ activeFilterTab: queryString.listing_type });
    }
  }

  onSelectSearchTab(searchTab) {
    this.setState({ activeSearchTab: searchTab, showSearch: true });
    this.props.searchModalOpen(true);
    this.props.handleHeaderSearchBar(true);
    this.props.handleOpacityChange(true);
  }

  handleProviderQueryChange(providerQueryValue) {
    providerQueryValue.length &&
      this.props.searchListings({
        keywords: providerQueryValue,
        pub_listing_type: this.props.isEducationSite
          ? 'academy,courses,tutor'
          : 'Company,company,listing,product,membership,freelancer,class,facility,event',
      });
  }

  getQueryParameters = () => {
    const { currentSearchParams } = this.props;
    const { search, selectedPlace } = this.props.searchLocation || {
      predictions: [],
      search: '',
      selectedPlace: {
        address: '',
      },
    };
    const { origin, bounds } = selectedPlace ? selectedPlace : {};
    const originMaybe = config.sortSearchByDistance ? { origin } : {};
    const searchParams = {
      ...currentSearchParams,
      ...originMaybe,
      pub_listing_type: this.state.activeFilterTab || 'company,freelancer,Company',
      pub_sub_category:
        (this.state.selectedSubCategory && this.state.selectedSubCategory.key) ||
        (this.props.searchActivity && this.props.searchActivity.key) ||
        null,
      address: search ? search : null,
      bounds: bounds ? bounds : null,
    };
    return searchParams;
  };

  handleSubmit = values => {
    const { searchActivity, searchLocation } = values;
    this.setState({
      activeSearchTab: false,
      showSearch: false,
      selectedSubCategory: searchActivity.id ? null : searchActivity,
      selectedLocation: searchLocation,
    });
    this.props.searchModalOpen(false);
    this.props.onSearchFilter(values);
    this.props.onChangeActivity(searchActivity);
    this.props.handleOpacityChange(false);
    const params = this.getQueryParameters();
    const date = moment().format('YYYY-MM-DD');
    const { search, selectedPlace } = values.searchLocation
      ? searchLocation
      : {
          predictions: [],
          search: '',
          selectedPlace: {
            address: '',
          },
        };
    const { origin, bounds } = selectedPlace ? selectedPlace : {};
    const originMaybe = config.sortSearchByDistance ? { origin } : {};
    const searchParams = {
      ...params,
      ...originMaybe,
      pub_sub_category: searchActivity && searchActivity.key ? searchActivity.key : null,
      pub_listing_type: searchActivity && searchActivity.title ? null : this.state.activeFilterTab,
      keywords: searchActivity?.id
        ? searchActivity.title
        : searchActivity?.key
        ? null
        : searchActivity?.label
        ? searchActivity.label
        : searchActivity,
      address: search ? search : null,
      bounds: bounds ? bounds : null,
      dates: this.state.activeFilterTab === 'class' ? `${date},${date}` : null,
      times: this.state.activeFilterTab === 'class' ? '00:00,23:59' : null,
    };
    searchActivity && searchActivity.id
      ? this.props.onkeywordSearch && this.props.onkeywordSearch(searchActivity)
      : this.props.onSearch(searchParams);
    this.props.handleHeaderSearchBar(false);
  };

  onKeywordSeleceted = activity => {
    this.setState({
      activeSearchTab: false,
      showSearch: false,
      selectedSubCategory: activity.id ? null : activity,
    });
    this.props.searchModalOpen(false);
    this.props.onSearchSubCategory(activity);
    this.props.onChangeActivity(activity);
    this.props.handleOpacityChange(false);
    this.props.handleHeaderSearchBar(false);
    this.props.showListing(activity.id);
    this.props.onkeywordSearch && this.props.onkeywordSearch(activity);
  };

  clearAll = values => {
    const { searchActivity, searchLocation } = values;
    this.setState({
      activeSearchTab: false,
      showSearch: false,
      selectedSubCategory: searchActivity,
      selectedLocation: searchLocation,
      activeFilterTab: 'company,Company,Freelancers',
      showLocationPopup: false,
    });
    this.props.searchModalOpen(false);
    this.props.onSearchFilter(values);
    this.props.onChangeActivity(searchActivity);
    this.props.handleOpacityChange(false);
    const searchParams = {
      pub_sub_category: null,
      address: null,
      bounds: null,
    };
    this.props.onSearch(searchParams);
    this.props.handleHeaderSearchBar(false);
  };

  onSubCategorySearch = item => {
    const combineCategory = ['Adventure', 'Racket Sports', 'Wellness', 'Fitness Classes'];
    this.setState({ activeSearchTab: false, selectedSubCategory: item });
    !combineCategory.includes(item.label) && this.props.onSearchSubCategory(item);
    // : this.props.onSearchSubCategory();
    const params = this.getQueryParameters();
    const searchParams = {
      ...params,
      pub_sub_category: item.key,
    };
    this.props.onSearch(searchParams);
  };

  onTabChangeSearch = tab => {
    this.props.currentTab && this.props.currentTab(tab);
    this.setState({
      activeFilterTab: this.state.activeFilterTab === tab.id ? null : tab.id,
      showSearch: true,
      showActivityPopup: false,
    });
    this.props.handleOpacityChange(false);
    this.props.searchModalOpen(true);
    const params = this.getQueryParameters();
    const date = moment().format('YYYY-MM-DD');
    const searchParams = {
      ...params,
      pub_kids_promotions: null,
      pub_listing_type: tab.id,
      dates: tab.id === 'class' ? `${date},${date}` : null,
      times: tab.id === 'class' ? '00:00,23:59' : null,
    };
    this.props.onSearchListingTab(tab.id);
    this.props.onTabChangeSearch && this.props.onTabChangeSearch(searchParams);
    // this.props.onSearch(searchParams);
  };

  onChangePrice = () => {
    this.props.handleOpacityChange(false);
    const params = this.getQueryParameters();
    const searchParams = {
      ...params,
      price: `${this.state.min},${this.state.max}`,
    };
    this.props.onSearch(searchParams);
  };

  resetPage() {
    this.props.history.push(createResourceLocatorString('HomePage', routeConfiguration(), {}));
    window?.location && window.location.reload(false);
  }

  render() {
    const { activeSearchTab } = this.state;
    const {
      searchActivity,
      searchLocation,
      searchSelectedListing,
      rootClassName,
      hideLogo,
      isEducationSite,
    } = this.props;

    return (
      <div
        className={classNames(rootClassName || css.mobileTopHeader, {
          [css.activeSearchCard]: this.state.showSearch,
          [css.topHeaderLogoHide]:
            (this.props.isScrolling && !this.state.showSearch) || hideLogo || this.props.showMap,
          [css.mobileTopHeaderEdu]: isEducationSite,
        })}
      >
        {!hideLogo && (
          <div
            className={classNames(css.logoContainer, {
              [css.logoHideOnScrolling]:
                (this.props.isScrolling && !this.state.showSearch) || this.props.showMap,
              [css.hideLogo]: this.state.showSearch,
            })}
            onClick={() => {
              this.setState({
                activeSearchTab: false,
                showSearch: false,
              });
              this.props.searchModalOpen(false);
            }}
          >
            <img src={ProbuddyLogo} onClick={() => this.resetPage()} alt="ProbuddyLogo" />
          </div>
        )}
        {!activeSearchTab && (
          <div className={css.mobileTopHeaderSearchContainer}>
            <div
              className={css.mobileSearchWrapper}
              onClick={() => {
                this.onSelectSearchTab('anyWhere');
                this.setState({ showLocationPopup: false });
              }}
            >
              <div>
                <img src={HeaderSearchIcon} alt="HeaderSearchIcon" />
              </div>
              <div className={css.mobileSearchContent}>
                <div className={css.toDoText}>
                  <FormattedMessage id="HomePageHeaderSection.whatToDo" />
                </div>
                <div className={css.searchLinkContainer}>
                  {/* <div
                    className={css.mobileSearchLink}
                    onClick={() => {
                      this.onSelectSearchTab('anyActivity');
                    }}
                  >
                    <FormattedMessage id="HomePageHeaderSection.AnyActivity" />
                  </div> */}
                  {/* <div
                    className={css.mobileSearchLink}
                    onClick={() => {
                      this.onSelectSearchTab('anyWhere');
                    }}
                  >
                    <FormattedMessage id="HomePageHeaderSection.AnyWhere" />
                  </div> */}
                  <div
                    className={css.mobileSearchLink}
                    onClick={() => this.onSelectSearchTab({ activeSearchTab: 'anyRating' })}
                  >
                    {/* <FormattedMessage id="HomePageHeaderSection.AnyListing" /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {/* {activeSearchTab && (
          <div className={hideOverlay ? css.hideZindex : css.mobileTabContainer}>
            <div
              className={css.closeIcon}
              onClick={() => {
                this.setState({
                  activeSearchTab: false,
                  showSearch: false,
                });
                this.props.handleHeaderSearchBar(false);
                this.props.searchModalOpen(false);
              }}
            >
              <img src={closeIcon} alt={'close'} />
            </div>
          </div>
        )} */}
        <Modal
          isOpen={this.state.showSearch}
          onClose={() => {
            this.setState({
              showSearch: false,
            });
            this.props.handleHeaderSearchBar(false);
            this.props.handleOpacityChange(false);
            this.props.searchModalOpen(false);
          }}
          id="filterModal"
          onManageDisableScrolling={() => {}}
          hideCloseBtn={true}
          overlayTab={false}
          className={classNames(css.modalSearchBar, {
            [css.tabModalSearchbar]: false,
          })}
        >
          <HomePageLocationSearchForm
            activeFilterTab={this.state.activeFilterTab}
            className={classNames(css.searchLink)}
            changeOpacity={value => {
              this.props.handleOpacityChange(value);
            }}
            currentOfSet={this.formContainer?.current && this.formContainer.current.offsetLeft}
            isMobileLayout={true}
            showActivityPopup={this.state.showActivityPopup}
            desktopInputRoot={classNames(css.headerSearchWithLeftPadding)}
            getSuggestedListing={this.props.searchListings}
            onSubmit={this.handleSubmit}
            onKeywordSeleceted={this.onKeywordSeleceted}
            initialValues={{
              searchActivity: searchActivity,
              searchLocation: searchLocation ? searchLocation : null,
              selectedListing: searchSelectedListing,
            }}
            clearAll={this.clearAll}
            intl={this.props.intl}
            history={this.props.history}
            suggestedListings={this.props.suggetListings}
            handleProviderQueryChange={this.handleProviderQueryChange}
            onTabChangeSearch={this.onTabChangeSearch}
            activeSearchTab={true}
            isEducationSite={isEducationSite}
          />
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    suggestedListings,
    searchActivity,
    searchLocation,
    searchSelectedListing,
  } = state.HeaderSection;
  const suggetListingRef = getMarketplaceEntities(state, suggestedListings);
  const suggetListings = suggetListingRef.map(l => ({
    id: l.id,
    title: l.attributes.title,
    image: l.images,
    address: l.attributes?.publicData?.location?.address,
  }));
  return {
    suggestedListings,
    searchActivity,
    searchLocation,
    searchSelectedListing,
    suggetListings,
  };
};

const mapDispatchToProps = dispatch => ({
  searchListings: searchParams => dispatch(searchListings(searchParams)),
  onSearchFilter: searchFilters => dispatch(onSearchFilter(searchFilters)),
  onSearchSubCategory: searchSubCategory => dispatch(onSearchSubCategory(searchSubCategory)),
  queryPromotedListings: searchquery => dispatch(queryPromotedListings(searchquery)),
  showListing: listingId => dispatch(showListing(listingId)),
  onSearchListingTab: searchListing => dispatch(onSearchListingTab(searchListing)),
});

const MobileHeaderContainer = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(HeaderContainerComponent);

export default MobileHeaderContainer;
