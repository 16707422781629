import { types as sdkTypes } from '../../util/sdkLoader';

export const MAX_MOBILE_SCREEN_WIDTH = 1024;

export const identity = v => v;

const { LatLng, LatLngBounds } = sdkTypes;

export const vendorQueAns = {
  tennis: [
    {
      name: 'timePreference',
      question: "Are you available to provide services on customer's time preference?",
      type: 'radio',
      option: ['yes', 'no'],
      no: {
        name: 'suggestTime',
        question: 'Suggest a times',
        type: 'text',
      },
    },
    {
      name: 'tennisCourts',
      question: 'Do you have access to private tennis courts?',
      type: 'radio',
      option: ['yes', 'I can go to the customers court', 'other'],
      yes: {
        name: 'tennisCourtsLocation',
        question: 'Add location',
        type: 'text',
      },
    },
    {
      name: 'vendorLocation',
      question: "Are you near the customer's location?",
      type: 'radio',
      option: ['yes', 'No But I can Travel', 'No Customer needs to travel', 'other'],
      yes: {
        name: 'tennisLocation',
        question: 'Add your location',
        type: 'text',
      },
    },
    {
      name: 'vendorCharge',
      question: 'How much do you charge for your tennis lessons?',
      type: 'radio',
      option: ['30-60', '60-100', '100-130', '130+', 'other'],
    },
  ],
  default: [
    {
      name: 'timePreference',
      question: "Are you available to provide services on customer's time preference?",
      type: 'radio',
      option: ['yes', 'no'],
      no: {
        name: 'suggestTime',
        question: 'Suggest a times',
        type: 'text',
      },
    },
    {
      name: 'vendorLocation',
      question: "Are you near the customer's location?",
      type: 'radio',
      option: ['yes', 'No But I can Travel', 'No Customer needs to travel', 'other'],
      yes: {
        name: 'tennisLocation',
        question: 'Add your location',
        type: 'text',
      },
    },
    {
      name: 'vendorCharge',
      question: 'How much do you charge for your lessons?',
      type: 'radio',
      option: ['30-60', '60-100', '100-130', '130+', 'other'],
    },
  ],
};
