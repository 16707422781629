import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { ensureCURLListings } from '../../util/data';
import { storableError } from '../../util/errors';
import { util as sdkUtil } from '../../util/sdkLoader';
import { formatDateStringToTz, getDefaultTimeZoneOnBrowser } from '../../util/dates';
import { convertUnitToSubUnit, unitDivisor } from '../../util/currency';

import { setInitialState as clearFilter } from '../HeaderContainer/HeaderContainer.duck';
import axios from 'axios';
import omit from 'lodash/omit';
import { LISTING_TYPES } from 'util/constants';
import config from '../../config';
import qs from 'qs';
// ================ Action types ================ //

export const SET_INITIAL_STATE = 'app/CategoriesPage/SET_INITIAL_STATE';

export const QUERY_PROMOTED_LISTINGS_REQUEST = 'app/CategoriesPage/QUERY_PROMOTED_LISTINGS_REQUEST';
export const QUERY_PROMOTED_LISTINGS_SUCCESS = 'app/CategoriesPage/QUERY_PROMOTED_LISTINGS_SUCCESS';
export const QUERY_PROMOTED_LISTINGS_ERROR = 'app/CategoriesPage/QUERY_PROMOTED_LISTINGS_ERROR';

export const COMPANY_FREELANCER_REQUEST = 'app/CategoriesPage/COMPANY_FREELANCER_REQUEST';
export const COMPANY_FREELANCER_SUCCESS = 'app/CategoriesPage/COMPANY_FREELANCER_SUCCESS';
export const COMPANY_FREELANCER_ERROR = 'app/CategoriesPage/COMPANY_FREELANCER_ERROR';

export const SEARCH_LISTINGS_REQUEST = 'app/CategoriesPage/SEARCH_LISTINGS_REQUEST';
export const SEARCH_LISTINGS_SUCCESS = 'app/CategoriesPage/SEARCH_LISTINGS_SUCCESS';
export const SEARCH_LISTINGS_ERROR = 'app/CategoriesPage/SEARCH_LISTINGS_ERROR';

export const QUERY_PROMOTED_LISTING_REVIEWS_REQUEST =
  'app/CategoriesPage/QUERY_PROMOTED_LISTING_REVIEWS_REQUEST';
export const QUERY_PROMOTED_LISTING_REVIEWS_SUCCESS =
  'app/CategoriesPage/QUERY_PROMOTED_LISTING_REVIEWS_SUCCESS';
export const QUERY_PROMOTED_LISTING_REVIEWS_ERROR =
  'app/CategoriesPage/QUERY_PROMOTED_LISTING_REVIEWS_ERROR';

export const FETCH_COMPANIES_REQUEST = 'app/CategoriesPage/FETCH_COMPANIES_REQUEST';
export const FETCH_COMPANIES_SUCCESS = 'app/CategoriesPage/FETCH_COMPANIES_SUCCESS';
export const FETCH_COMPANIES_ERROR = 'app/CategoriesPage/FETCH_COMPANIES_ERROR';

export const FETCH_ALL_LISTING_REVIEWS = 'app/CategoriesPage/FETCH_ALL_LISTING_REVIEWS';

//author classes
export const FETCH_AUTHOR_CLASSES_REQUEST = 'app/CategoriesPage/FETCH_AUTHOR_CLASSES_REQUEST';
export const FETCH_AUTHOR_CLASSES_SUCCESS = 'app/CategoriesPage/FETCH_AUTHOR_CLASSES_SUCCESS';
export const FETCH_AUTHOR_CLASSES_ERROR = 'app/CategoriesPage/FETCH_AUTHOR_CLASSES_ERROR';

//Author other listing
export const SET_AUTHORS_LISTINGS_REFS = 'app/CategoriesPage/SET_AUTHORS_LISTINGS_REFS';
export const SHOW_LISTING_REQUEST = 'app/CategoriesPage/SHOW_LISTING_REQUEST';
export const SHOW_LISTING_ERROR = 'app/CategoriesPage/SHOW_LISTING_ERROR';

// ================ Reducer ================ //

const initialState = {
  queryPromotedListingsError: null,
  promotedListingRefs: [],
  promotedListingReviews: [],
  suggestedListings: [],
  companiesRefs: [],
  companiesError: null,
  companiesLoading: false,
  listingAllReviews: {},
  authorsListingsRefs: [],
  showListingError: null,
  gettingListings: false,
  companyFreelancerListings: [],

  //enquiry
  sendEnquiryError: null,
  sendEnquiryInProgress: false,
};

export default function categoriesPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_STATE:
      return { ...initialState };
    case COMPANY_FREELANCER_REQUEST:
      return {
        ...state,
        companyFreelancerListings: state.companyFreelancerListings,
      };
    case COMPANY_FREELANCER_SUCCESS:
      return { ...state, companyFreelancerListings: payload.listingRefs };
    case COMPANY_FREELANCER_ERROR:
      return {
        ...state,
        companyFreelancerListings: [],
      };
    case QUERY_PROMOTED_LISTINGS_REQUEST:
      return {
        ...state,
        promotedListingRefs: state.promotedListingRefs,
        queryPromotedListingsError: null,
        gettingListings: true,
      };
    case QUERY_PROMOTED_LISTINGS_SUCCESS:
      return { ...state, promotedListingRefs: payload.listingRefs, gettingListings: false };
    case QUERY_PROMOTED_LISTINGS_ERROR:
      return {
        ...state,
        promotedListingRefs: [],
        queryPromotedListingsError: payload,
        gettingListings: false,
      };
    case SEARCH_LISTINGS_REQUEST:
      return {
        ...state,
        suggestedListings: state.suggestedListings,
        searchListingsError: null,
      };
    case SEARCH_LISTINGS_SUCCESS:
      return { ...state, suggestedListings: payload.listings };
    case SEARCH_LISTINGS_ERROR:
      return { ...state, suggestedListings: [], searchListingsError: payload };
    case QUERY_PROMOTED_LISTING_REVIEWS_REQUEST:
      return { ...state, queryPromotedListingReviewsError: null };
    case QUERY_PROMOTED_LISTING_REVIEWS_SUCCESS:
      return { ...state, promotedListingReviews: { ...state.promotedListingReviews, payload } };
    case QUERY_PROMOTED_LISTING_REVIEWS_ERROR:
      return { ...state, reviews: [], queryPromotedListingReviewsError: payload };

    //Companies
    case FETCH_COMPANIES_REQUEST:
      return { ...state, companiesError: null, companiesLoading: true };
    case FETCH_COMPANIES_SUCCESS:
      return { ...state, companiesRefs: payload, companiesLoading: false };
    case FETCH_COMPANIES_ERROR:
      return { ...state, companiesError: payload, companiesLoading: false };

    // reviews
    case FETCH_ALL_LISTING_REVIEWS:
      return { ...state, listingAllReviews: payload };
    default:
      return state;

    //author classes
    case FETCH_AUTHOR_CLASSES_REQUEST:
      return { ...state, authorClassesIsLoading: true };
    case FETCH_AUTHOR_CLASSES_SUCCESS:
      return { ...state, authorClassesIsLoading: false, authorClassesRefs: payload };
    case FETCH_AUTHOR_CLASSES_ERROR:
      return { ...state, authorClassesIsLoading: false, authorClassesError: payload };

    //Author other listing
    case SET_AUTHORS_LISTINGS_REFS: {
      return { ...state, authorsListingsRefs: payload };
    }
    case SHOW_LISTING_REQUEST:
      return { ...state, id: payload.id, showListingError: null };
    case SHOW_LISTING_ERROR:
      return { ...state, showListingError: payload };
  }
}

// ================ Action creators ================ //

export const setInitialState = () => ({
  type: SET_INITIAL_STATE,
});

export const companyFreelancerRequest = () => ({
  type: COMPANY_FREELANCER_REQUEST,
});

export const companyFreelancerSuccess = listingRefs => ({
  type: COMPANY_FREELANCER_SUCCESS,
  payload: { listingRefs },
});

export const companyFreelancerError = e => ({
  type: COMPANY_FREELANCER_ERROR,
  error: true,
  payload: e,
});

export const queryPromotedListingsRequest = () => ({
  type: QUERY_PROMOTED_LISTINGS_REQUEST,
});

export const queryPromotedListingsSuccess = listingRefs => ({
  type: QUERY_PROMOTED_LISTINGS_SUCCESS,
  payload: { listingRefs },
});

export const queryPromotedListingsError = e => ({
  type: QUERY_PROMOTED_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const searchListingsRequest = () => ({
  type: SEARCH_LISTINGS_REQUEST,
});

export const searchListingsSuccess = listings => ({
  type: SEARCH_LISTINGS_SUCCESS,
  payload: { listings },
});

export const searchListingsError = e => ({
  type: SEARCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const queryPromotedListingReviewsRequest = () => ({
  type: QUERY_PROMOTED_LISTING_REVIEWS_REQUEST,
});

export const allListingReviews = reviewData => ({
  type: FETCH_ALL_LISTING_REVIEWS,
  payload: reviewData,
});

export const queryPromotedListingReviewsSuccess = reviews => ({
  type: QUERY_PROMOTED_LISTING_REVIEWS_SUCCESS,
  payload: reviews,
});

export const queryPromotedListingReviewsError = e => ({
  type: QUERY_PROMOTED_LISTING_REVIEWS_ERROR,
  error: true,
  payload: e,
});

export const fetchCompaniesRequest = () => ({
  type: FETCH_COMPANIES_REQUEST,
});
export const fetchCompaniesSuccess = payload => ({
  type: FETCH_COMPANIES_SUCCESS,
  payload,
});
export const fetchCompaniesError = payload => ({
  type: FETCH_COMPANIES_ERROR,
  payload,
});

export const fetchAuthorClassesRequest = () => ({ type: FETCH_AUTHOR_CLASSES_REQUEST });
export const fetchAuthorClassesSuccess = reviews => ({
  type: FETCH_AUTHOR_CLASSES_SUCCESS,
  payload: reviews,
});
export const fetchAuthorClassesError = error => ({
  type: FETCH_AUTHOR_CLASSES_ERROR,
  error: true,
  payload: error,
});

//Author other listing
export const setAuthorsListingsRefs = author => ({
  type: SET_AUTHORS_LISTINGS_REFS,
  payload: author,
});

// ================ Thunks ================ //

const getBounds = listingParams => {
  if (!listingParams.bounds) return undefined;

  const { ne, sw } = listingParams.bounds;
  const { lat: nelat, lng: nelng } = ne;
  const { lat: swlat, lng: swlng } = sw;

  return `${nelat},${nelng},${swlat},${swlng}`;
};

const searchClassListings = listingParams => async (dispatch, getState, sdk) => {
  try {
    const boundsString = getBounds(listingParams);

    const response = await axios.get(config.serverBaseUrl + 'listings', {
      headers: {
        Authorization: `Token token=${config.serverToken}`,
      },
      params: {
        ...omit(listingParams, [
          'fields.listing',
          'fields.user',
          'fields.image',
          'include',
          // 'per_page',
        ]),
        mapSearch: true,
        bounds: boundsString,
        include: 'author,author.profileImage,images',
        'fields.image':
          'variants.scaled-small,variants.square-small2x,variants.square-small,variants.square-small2x',
      },
      paramsSerializer: params => {
        return qs.stringify(params);
      },
    });

    const ensureResponse = ensureCURLListings(response);
    if (response.data.data.length) {
      dispatch(addMarketplaceEntities(ensureResponse));
    }
    const listingRefs = response.data.data.filter(
      ({ id, type, attributes }) => !attributes?.metadata?.hidelisting && { id, type }
    );
    dispatch(queryPromotedListingsSuccess(listingRefs));
    return ensureResponse;
  } catch (e) {
    console.log(e);
    dispatch(queryPromotedListingsError(storableError(e)));
    throw e;
  }
};
export const queryPromotedListings = searchquery => (dispatch, getState, sdk) => {
  dispatch(queryPromotedListingsRequest());
  const availabilityParams = (datesParam, minDurationParam, timesParam) => {
    const dateValues = datesParam ? datesParam.split(',') : [];
    const hasDateValues = datesParam && dateValues.length === 2;
    const timeValues = timesParam ? timesParam.split(',') : [];
    const hasTimeValues = timeValues && timeValues.length === 2;
    const startDate =
      hasDateValues && hasTimeValues
        ? `${dateValues[0]} ${timeValues[0]}`
        : hasDateValues
        ? dateValues[0]
        : null; //"2020-06-12 12:00"//;
    const endDate =
      hasDateValues && hasTimeValues
        ? `${dateValues[1]} ${timeValues[1]}`
        : hasDateValues
        ? dateValues[1]
        : null; //"2020-06-12 13:00"//;
    const minDurationMaybe =
      minDurationParam && Number.isInteger(minDurationParam) && hasDateValues
        ? { minDuration: minDurationParam }
        : {};
    const defaultTimeZone = () =>
      typeof window !== 'undefined'
        ? getDefaultTimeZoneOnBrowser()
        : config.custom.dateRangeLengthFilterConfig.searchTimeZone;
    const timeZone = defaultTimeZone();
    return hasDateValues
      ? {
          start: formatDateStringToTz(startDate, timeZone),
          end: formatDateStringToTz(endDate, timeZone),
          // When we have `time-partial` value in the availability, the
          // API returns listings that don't necessarily have the full
          // start->end range available, but enough that the minDuration
          // (in minutes) can be fulfilled.
          //
          // See: https://www.sharetribe.com/api-reference/marketplace.html#availability-filtering
          availability: 'time-partial',
          ...minDurationMaybe,
        }
      : {};
  };
  const priceSearchParams = priceParam => {
    const inSubunits = value =>
      convertUnitToSubUnit(value, unitDivisor(config.currencyConfig.currency));
    const values = priceParam ? priceParam.split(',') : [];
    return priceParam && values.length === 2
      ? {
          price: [inSubunits(values[0]), inSubunits(values[1]) + 1].join(','),
        }
      : {};
  };
  const getParams = () => {
    if (searchquery) {
      const {
        dates,
        minDuration,
        times,
        price,
        pub_sub_category,
        pub_category,
        ...rest
      } = searchquery;
      const availabilityMaybe = availabilityParams(dates, minDuration, times);
      const priceMaybe = priceSearchParams(price);
      const subcategory = pub_sub_category
        ? { pub_sub_category: `has_any:${pub_sub_category}` }
        : {};
      const publicCategory = pub_category ? { pub_category: pub_category } : {};
      return {
        pub_listing_type: 'freelancer',
        ...rest,
        ...availabilityMaybe,
        ...priceMaybe,
        ...subcategory,
        ...publicCategory,
        mapSearch: true,
        include: ['author', 'author.profileImage', 'images'],
        'fields.image': [
          'variants.scaled-small',
          'variants.square-small2x',
          // Avatars
          'variants.square-small',
          'variants.square-small2x',
          'variants.company-background-crop',
        ],
        'imageVariant.company-background-crop': sdkUtil.objectQueryString({
          w: 1920,
          h: 473,
          fit: 'crop',
        }),
        'limit.images': 6,
        'page.limit': 50,
        sort: 'meta_promoted,createdAt',
      };
    } else
      return {
        pub_listing_type: 'freelancer',
        include: ['author', 'author.profileImage', 'images'],
        'fields.image': [
          'variants.scaled-small',
          'variants.square-small2x',
          // Avatars
          'variants.square-small',
          'variants.square-small2x',
          'variants.company-background-crop',
        ],
        'imageVariant.company-background-crop': sdkUtil.objectQueryString({
          w: 1920,
          h: 473,
          fit: 'crop',
        }),
        'limit.images': 6,
        'page.limit': 50,
        sort: 'meta_promoted,createdAt',
      };
  };
  const params = getParams();
  if (
    params &&
    (params.pub_listing_type === LISTING_TYPES.CLASS ||
      params.pub_listing_type === LISTING_TYPES.COURSES ||
      params.pub_listing_type === LISTING_TYPES.EVENT ||
      params.pub_listing_type === LISTING_TYPES.HOURLY_SESSION)
  ) {
    return dispatch(searchClassListings(params));
  } else {
    return sdk.listings
      .query(params)
      .then(response => {
        const listingRefs = response.data.data.filter(
          ({ id, type, attributes }) => !attributes?.metadata?.hidelisting && { id, type }
        );
        // const listingCompanyRefs = response.data.data.map(({ id, type, relationships }) => ({
        //   id,
        //   type,
        //   authorId: relationships.author.data.id.uuid,
        // }));
        dispatch(addMarketplaceEntities(response));
        dispatch(queryPromotedListingsSuccess(listingRefs));

        // dispatch(fetchAllCompanyReviews(listingCompanyRefs));
        // if (searchquery?.pub_listing_type === 'company') {
        //   dispatch(fetchAllCompanyReviews(listingCompanyRefs));
        // }
        if (!searchquery) {
          dispatch(clearFilter());
        }
        // Fetch reviews
        // response.data.data.map(({id}) => { dispatch(queryPromotedListingReviews(id.uuid)) })
        return response;
      })
      .catch(e => dispatch(queryPromotedListingsError(storableError(e))));
  }
};

export const showListing = listingId => (dispatch, getState, sdk) => {
  return sdk.listings
    .show({
      id: listingId,
      include: ['author', 'author.profileImage', 'images'],
      'fields.image': [
        'variants.scaled-small',
        'variants.square-small2x',

        // Avatars
        'variants.square-small',
        'variants.square-small2x',
      ],
      'limit.images': 6,
      'page.limit': 50,
      sort: 'meta_promoted,createdAt',
    })
    .then(response => {
      const res = [response.data];
      const listingRefs = res.map(r => ({
        id: r.data.id,
        type: r.data.type,
      }));
      // const listingCompanyRefs = res.map(r => ({
      //   id: r.data.id,
      //   type: r.data.type,
      //   authorId: r.data.relationships.author.data.id.uuid,
      // }));
      dispatch(addMarketplaceEntities(response));
      dispatch(queryPromotedListingsSuccess(listingRefs));
      // dispatch(fetchAllCompanyReviews(listingCompanyRefs));

      return response;
    })
    .catch(e => dispatch(queryPromotedListingsError(storableError(e))));
};

export const searchListings = searchParams => (dispatch, getState, sdk) => {
  dispatch(searchListingsRequest());
  return sdk.listings
    .query(searchParams)
    .then(response => {
      const listings = response.data.data.map(({ id, attributes }) => ({
        id,
        title: attributes.title,
      }));
      dispatch(addMarketplaceEntities(response));
      dispatch(searchListingsSuccess(listings));
      return response;
    })
    .catch(e => {
      dispatch(searchListingsError(e));
      throw e;
    });
};

export const loadData = () => (dispatch, getState, sdk) => {
  // Clear state so that previously loaded data is not visible
  // in case this page load fails.
  const currentState = getState().HeaderSection;
  if (
    currentState.searchLocation?.search ||
    currentState.searchActivity ||
    currentState.searchSelectedRating
  )
    return Promise.resolve();

  dispatch(setInitialState());

  return Promise.all([dispatch(queryPromotedListings())]);
  // return Promise.all([dispatch(queryPromotedListings()), dispatch(fetchCompanies())]);
};
