import React from 'react';
import { bool, oneOfType, object, string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import {
  LISTING_STATE_PENDING_APPROVAL,
  LISTING_STATE_CLOSED,
  LISTING_STATE_DRAFT,
  propTypes,
} from '../../util/types';
import { NamedLink } from '../../components';
import EditIcon from './EditIcon';

import css from './ListingPage.css';

const LISTING_EDIT_ROUTE = 'EditListingPage';
const ENQUIRY_EDIT_ROUTE = 'EditInquirePage';
const CLASS_EDIT_ROUTE = 'EditClassPage';
const FACILITY_EDIT_ROUTE = 'EditFacilityPage';

export const ActionBarMaybe = props => {
  const { isOwnListing, listing, editParams, routeName } = props;
  const state = listing.attributes.state;
  const isPendingApproval = state === LISTING_STATE_PENDING_APPROVAL;
  const isClosed = state === LISTING_STATE_CLOSED;
  const isDraft = state === LISTING_STATE_DRAFT;

  if (isOwnListing) {
    let ownListingTextTranslationId = 'ListingPage.ownListing';

    if (isPendingApproval) {
      ownListingTextTranslationId = 'ListingPage.ownListingPendingApproval';
    } else if (isClosed) {
      ownListingTextTranslationId = 'ListingPage.ownClosedListing';
    } else if (isDraft) {
      ownListingTextTranslationId = 'ListingPage.ownListingDraft';
    }

    const listingMessage = isDraft ? 'ListingPage.finishListing' : 'ListingPage.editListing';
    const enquiryMessage = isDraft ? 'ListingPage.finishEnquiry' : 'ListingPage.editEnquiry';
    const classMessage = isDraft ? 'ListingPage.finishClass' : 'ListingPage.editClass';
    const facilityMessage = isDraft ? 'ListingPage.finishFacility' : 'ListingPage.editFacility';

    const ownListingTextClasses = classNames(css.ownListingText, {
      [css.ownListingTextPendingApproval]: isPendingApproval,
    });

    const editLinkText = {
      [LISTING_EDIT_ROUTE]: listingMessage,
      [ENQUIRY_EDIT_ROUTE]: enquiryMessage,
      [CLASS_EDIT_ROUTE]: classMessage,
      [FACILITY_EDIT_ROUTE]: facilityMessage,
    };

    return (
      <div className={css.actionBar}>
        <p className={ownListingTextClasses}>
          <FormattedMessage id={ownListingTextTranslationId} />
        </p>
        <NamedLink className={css.editListingLink} name={routeName} params={editParams}>
          <EditIcon className={css.editIcon} />
          <FormattedMessage id={editLinkText[routeName] || listingMessage} />
        </NamedLink>
      </div>
    );
  } else if (isClosed) {
    return (
      <div className={css.actionBar}>
        <p className={css.closedListingText}>
          <FormattedMessage id="ListingPage.closedListing" />
        </p>
      </div>
    );
  }
  return null;
};

ActionBarMaybe.propTypes = {
  isOwnListing: bool.isRequired,
  listing: oneOfType([propTypes.listing, propTypes.ownListing]).isRequired,
  editParams: object.isRequired,
  routeName: string,
};

ActionBarMaybe.displayName = 'ActionBarMaybe';

export default ActionBarMaybe;
