import React, { useRef, useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { SecondaryButton, GenericMessage } from 'components';

import css from 'components/ShareButton/ShareButton.css';
import IconShare from 'components/IconShare/IconShare';

const MAX_MOBILE_SCREEN_WIDTH = 1024;

const copyToClipboard = inputRef => {
  const copyText = inputRef.current;
  copyText.select();
  copyText.setSelectionRange(0, 99999);
  document.execCommand('copy');
};

function ShareButton(props) {
  const [showMessage, setShowMessage] = useState(false);
  const { className, shareData, viewport, children } = props;
  const inputRef = useRef(null);

  const onShareClick = e => {
    const isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;

    if (isMobileLayout && navigator.share) {
      navigator
        .share(shareData)
        .then(() => console.log('Share was successful.'))
        .catch(error => console.log('Sharing failed', error));
    }

    copyToClipboard(inputRef);
    setShowMessage(true);
    setTimeout(() => {
      setShowMessage(false);
    }, 2500);
  };

  const classes = classnames(css.root, className || '');
  return (
    <div className={classes}>
      <GenericMessage show={showMessage} message={'URL is copied'} />
      <input
        ref={inputRef}
        className={css.input}
        type="text"
        value={shareData.url}
        aria-hidden="true"
        readOnly
      />
      {children ? <SecondaryButton onClick={onShareClick}>
        <IconShare className={css.icon} />
        {children}
      </SecondaryButton>:
      <div onClick={onShareClick} style={{display: 'flex', alignItems: 'center'}}>
      <img src={'/static/images/Icons/shareIcon.png'} alt="shareIcon" width="26px"/> <span className={css.shareFontStyle}>Share</span>
      </div>}
    </div>
  );
}

ShareButton.propTypes = {
  className: PropTypes.string,
  shareData: PropTypes.shape({
    url: PropTypes.string,
    title: PropTypes.string,
  }),
  viewport: PropTypes.shape({
    height: PropTypes.number,
    wight: PropTypes.number,
  }),
};

export default ShareButton;
