import React, { useEffect, useMemo, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import config from '../../config';
import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  NamedLink,
  ProductListing,
  Button,
  FooterBottomMenu,
  Modal,
  LeftBar,
} from 'components';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import BecomeSeller from '../BlogPage/sections/BecomeSeller/BesomeSeller';
import { LISTING_TYPES } from 'util/constants';
import classNames from 'classnames';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import StarRatingsIcon from '../../assets/VendorPage/StarRatingsIcon.svg';
import ArrowIconLeft from '../../assets/landingPage/icons/arrowIconLeft.svg';
import { NotFoundPage, TopbarContainer } from '../../containers';

import css from './SelectedCategoryTypePage.css';
import { withViewport } from '../../util/contextHelpers';
import { queryPromotedListingsSelectedCategoryTypePage } from './SelectedCategoryTypePage.duck';
import { queryPromotedListings, showListing } from '../HomePage/HomePage.duck';
import { func, shape } from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
  MAX_MOBILE_SCREEN_WIDTH,
  getSchemaId,
  getSchemaDescription,
} from './SelectedCategoryTypePage.data';
import { city } from 'marketplace-custom-config';
import { ShareForm } from 'forms';

export const tabsList = [
  {
    id: LISTING_TYPES.COMPANY,
    name: 'Companies',
  },
  {
    id: LISTING_TYPES.FREELANCER,
    name: `Instructors/Pro's`,
  },
  {
    id: LISTING_TYPES.CLASS,
    name: 'Classes',
  },
  {
    id: LISTING_TYPES.FACILITY,
    name: 'Facilities',
  },
  {
    id: LISTING_TYPES.EVENT,
    name: 'Events',
  },
  {
    id: LISTING_TYPES.MEMBERSHIP,
    name: 'Memberships',
  },
  {
    id: LISTING_TYPES.LISTING,
    name: 'Listings',
  },
  {
    id: LISTING_TYPES.ENQUIRY,
    name: 'Enquiry',
  },
  {
    id: LISTING_TYPES.PRODUCT,
    name: 'Product',
  },
];
const SelectedCategoryTypePageComponent = props => {
  const { params, queryPromotedListingsSelectedCategoryTypePage } = props;
  const [showAllProduct, setShowAllProduct] = useState(false);
  const [activeFooterTab, setActiveFooterTab] = useState('search');
  const locationParam = useMemo(() => city.filter(city => city.key === params?.city), [params]);
  const [isOpenShareForm, setIsOpenShareForm] = useState(false);
  const url = typeof window !== 'undefined' && window.location.href;

  const categoriesMediumOptions = config.custom.categoriesMediumOptionsConfig;
  const checkCategoryType = categoriesMediumOptions.filter(
    item => item.params.slug === props?.params?.categoryType
  );

  useEffect(() => {
    if (params.categoryType) {
      queryPromotedListingsSelectedCategoryTypePage(
        checkCategoryType.length > 0
          ? {
              pub_category: params?.categoryType,
              pub_listing_type: params?.listingType,
              address: locationParam ? locationParam[0]?.label : null,
              bounds: locationParam ? locationParam[0]?.bounds : null,
            }
          : {
              pub_sub_category: params.categoryType,
              pub_listing_type: params?.listingType,
              address: locationParam ? locationParam[0]?.label : null,
              bounds: locationParam ? locationParam[0]?.bounds : null,
            }
      );
    }
  }, [
    checkCategoryType.length,
    queryPromotedListingsSelectedCategoryTypePage,
    params,
    locationParam,
  ]);

  const onSearchProduct = searchquery => {
    queryPromotedListingsSelectedCategoryTypePage(searchquery);
  };

  const getNumberOfProductListings = () => {
    const width = props.viewport.width;
    if (width <= 550) return 5;
    else if (width >= 551 && width <= 1199) return 10;
    else if (width >= 1200 && width <= 1550) return 15;
    else return 20;
  };

  const { scrollingDisabled, viewport, promotedListings, intl } = props;

  const categoriesData = config.custom.categoryTypeDataConfig;
  const featureOptions = config.custom.featureOptionsConfig;

  const categoryType = props.params.categoryType;
  const cityFilter = city.filter(city => city.key === props.params.city);
  const listingTypeFilter = tabsList.filter(type => type.id === props.params.listingType);

  const categoryTypeLabel = categoryType.charAt(0).toUpperCase() + categoryType.slice(1);
  const cityLabel =
    cityFilter && cityFilter[0] && cityFilter[0]?.label ? cityFilter[0]?.label : null;
  const listingTypeLabel =
    listingTypeFilter && listingTypeFilter[0] && listingTypeFilter[0]?.name
      ? listingTypeFilter[0]?.name
      : null;

  const categoryData = categoriesData.find(item => item.id === categoryType);
  const whyBookHeadingClass =
    categoryData.whyBook.position === 'center'
      ? classNames(css.title, css.textAlignCenter)
      : css.title;

  const chooseUsSectionClass =
    categoryData.whyBook.containerPosition === 'center'
      ? classNames(css.chooseUsSection, css.alignItemsCenter)
      : css.chooseUsSection;

  const isMobileLayout = !!viewport.width && viewport.width < MAX_MOBILE_SCREEN_WIDTH;

  const schemaIdKeyword =
    categoryType === 'sports'
      ? 'CategoriesPage.schemaKeywordsSports'
      : categoryType === 'wellness'
      ? 'CategoriesPage.schemaKeywordsWellness'
      : 'CategoriesPage.schemaKeywordsAdventure';

  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: getSchemaId(categoryType) }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: getSchemaDescription(categoryType) });
  const schemaKeywords = intl.formatMessage({ id: schemaIdKeyword });
  const sportName = props?.params?.categoryType;
  const sportCity = props?.params?.city;
  const sportListingType = props?.params?.listingType;

  if (!categoryData || !Object.keys(categoryData).length) {
    return <NotFoundPage />;
  }

  return (
    <Page
      description={schemaDescription}
      title={schemaTitle}
      keywords={schemaKeywords}
      scrollingDisabled={scrollingDisabled}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer setIsOpenShareForm={setIsOpenShareForm} />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain>
          <div className={css.content}>
            <section className={css.topSection}>
              <div className={css.topHeadContainer}>
                <div className={css.heroImage}>
                  <img src={categoryData.heroImage} alt="heroImage" />
                </div>
              </div>
            </section>

            <div className={css.breadCrumbWrapper}>
              <NamedLink name="HomePage">Home</NamedLink>
              <span className={css.breadcrumbArrow}>&gt;</span>
              <NamedLink name="CategoriesPage">Category</NamedLink>
              <span className={css.breadcrumbArrow}>&gt;</span>
              <NamedLink
                name="AllSportsPage"
                params={{ slug: sportName }}
                className={css.currentListingTypeName}
              >
                {sportName}
              </NamedLink>
              <span className={css.breadcrumbArrow}>&gt;</span>
              <NamedLink
                name="AllCategoriesPage"
                params={{ slug: sportName, city: sportCity }}
                className={css.currentListingTypeName}
              >
                Category Type
              </NamedLink>
              <span className={css.breadcrumbArrow}>&gt;</span>
              <NamedLink
                name="SelectedCategoryTypePage"
                params={{ categoryType: sportName, city: sportCity, listingType: sportListingType }}
                className={css.currentListingTypeName}
              >
                {sportListingType}
              </NamedLink>
            </div>

            <div className={css.uniqueSectionContainer}>
              <section className={css.section}>
                <div className={css.uniqueDesignSection}>
                  <div>
                    <h3
                      className={css.sectionHeading}
                    >{`Popular ${categoryTypeLabel} ${listingTypeLabel} in ${cityLabel}`}</h3>
                    <div className={css.sectionDescription}>
                      <span
                        className={css.topSpan}
                      >{`Find the perfect ${categoryTypeLabel} ${listingTypeLabel} in ${cityLabel} with Probuddy. 
                      We have listings for ${categoryTypeLabel} ${listingTypeLabel} all across ${cityLabel}. 
                      Save time and find the perfect location with a few clicks on Probuddy.`}</span>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <div className={classNames(css.headingContainer, css.popularExercisesSection)}>
              <h3 className={css.ourTopExampleTitle}>{categoryData.exercise.productTitle}</h3>
            </div>

            <div className={css.productsMobileWrapper}>
              <div className={classNames(css.autorListingWrapper, css.popularExercisesSection)}>
                {promotedListings?.length > 0 ? (
                  promotedListings
                    .slice(
                      0,
                      showAllProduct ? promotedListings.length : getNumberOfProductListings()
                    )
                    .map(pl => (
                      <ProductListing
                        key={pl.id.uuid}
                        pl={pl}
                        intl={intl}
                        isMobileLayout={isMobileLayout}
                      />
                    ))
                ) : (
                  <div>There are no listings!!</div>
                )}
              </div>
              {promotedListings && promotedListings.length > getNumberOfProductListings() && (
                <Button
                  className={css.productsButton}
                  onClick={() => setShowAllProduct(!showAllProduct)}
                >
                  {showAllProduct ? (
                    <FormattedMessage id="CompanyPage.viewProductsLess" />
                  ) : (
                    <FormattedMessage
                      id="CompanyPage.viewProductsMore"
                      values={{
                        count: promotedListings.length,
                      }}
                    />
                  )}
                </Button>
              )}
            </div>

            <section className={chooseUsSectionClass}>
              <img src={categoryData.whyBook.imgSrc} alt="whyBookImg" />

              <div className={css.chooseUsInfo}>
                <div
                  className={whyBookHeadingClass}
                >{`Find the Best ${categoryTypeLabel} ${listingTypeLabel} in ${cityLabel}`}</div>
                <div className={css.description}>
                  {`It's never been easier to find a top-rated class & coaches with one search. Meet
                  our profile of the most popular classes in your area and book on the spot.`}
                </div>

                <div
                  className={whyBookHeadingClass}
                >{`Why Book on Probuddy for ${categoryTypeLabel} ${listingTypeLabel}?`}</div>
                <div className={css.description}>
                  {`Probuddy is the easiest platform to use to book the best classes across ${cityLabel}.
                  No more waiting or driving around the city to find the right classes for you. Our
                  goal is to help you find the perfect instructor or class in your area so that you
                  can make your next activity.`}
                </div>

                <div className={css.cardsContainer}>
                  <span className={css.cardItem}>5000+ Members</span>
                  <span className={css.cardItem}>1000+ Trainers</span>
                </div>
                <div className={css.cardsContainer}>
                  <span className={css.cardItem}>50+ Programs</span>
                  <span className={css.cardItem}>150+ Awards</span>
                </div>
              </div>
            </section>

            <section className={css.yogaClassesLocation}>
              <div className={classNames(css.servicesTitle, css.textAlignLeft)}>
                {categoryData.classesNearYou.title}
              </div>
              <div className={css.servicesSubheading}>
                {categoryData.classesNearYou.description}
              </div>

              <div className={css.locationContainer}>
                <div className={css.locationContainer}>
                  {city.map(location => (
                    <div className={css.locationItem}>
                      <img src={location.cityImage} alt="locationImg" />
                      <span src={css.locationName}>{location.label}</span>
                    </div>
                  ))}
                </div>
              </div>
            </section>

            <section className={css.servicesSection}>
              <div className={classNames(css.servicesTitle, css.textAlignLeft)}>
                {categoryData.instructors.title}
              </div>
              <div className={css.servicesSubheading}>{categoryData.instructors.description}</div>

              <div className={css.servicesCarousel}>
                <ul className={css.servicesCarouselItems}>
                  {categoryData.instructors.instructorsData.map(instructor => (
                    <li className={css.servicesCarouselItem}>
                      <img src={instructor.trainerImage} alt="trainerImage" />
                      <div className={css.carouselInfo}>
                        <div className={css.carouselHeading}>
                          <h5>{instructor.type}</h5>
                          <div className={css.carouselRating}>
                            <img src={StarRatingsIcon} alt="Rating Icon" />
                            <h5>{instructor.rating}</h5>
                            <span>({instructor.reviews}) Reviews</span>
                          </div>
                        </div>

                        <div className={css.carouselName}>{instructor.name}</div>
                        <div className={css.carouselDescription}>{instructor.description}</div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </section>

            <section className={css.reviewsSection}>
              <div className={css.servicesTitle}>What Our Happy Clients Says!</div>
              <div className={css.servicesSubheading}>
                We let our happy clients do the talking! See what our students and instructors have
                to say about Probuddy.
              </div>

              <div className={css.reviewsCarouselContainer}>
                {categoryData.reviews.map(review => (
                  <div className={css.reviewsCardItem}>
                    <div className={css.cardHeader}>
                      <span className={css.cardImage}>
                        <img src={review.imageSrc} alt="reviewImg" />
                      </span>

                      <div className={css.cardInfo}>
                        <span className={css.cardName}>{review.name}</span>
                        <span className={css.cardSubheading}>{review.country}</span>
                      </div>
                    </div>
                    <div className={css.cardContent}>{review.description}</div>
                  </div>
                ))}
              </div>
            </section>

            <section className={css.featuresSection}>
              <div className={css.servicesTitle}>{categoryData.features.title}</div>
              <div className={css.servicesSubheading}>{categoryData.features.description}</div>
              <div className={css.featureItemsContainer}>
                {featureOptions.map(feature => (
                  <div className={css.featureItem}>
                    <div className={css.featureIcon}>
                      <img src={feature.imgSrc} alt="featureImg" />
                    </div>
                    <div>
                      <div className={css.featureTitle}>{feature.title}</div>
                      <div className={css.featureTitle2}>{feature.title2}</div>
                    </div>
                    <div className={css.featureDescription}>{feature.description}</div>
                    <img className={css.arrowIconLeft} src={ArrowIconLeft} alt="ArrowIconLeft" />
                  </div>
                ))}
              </div>
            </section>

            <section className={css.submitRequestSection}>
              <div className={css.imageContainer}>
                <h3>{categoryData.sellers.title}</h3>
                <NamedLink name="BecomeVendorPage" className={css.getProLink}>
                  {categoryData.sellers.linkText}
                </NamedLink>
              </div>
            </section>
            <section className={css.becomeSellerSection}>
              <BecomeSeller
                message={
                  <div>
                    Are you a <span className={css.paramsTitle}>{categoryType}</span> Expert ?
                  </div>
                }
              />
            </section>
          </div>
        </LayoutWrapperMain>
        {!isMobileLayout ? (
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        ) : (
          <LayoutWrapperFooter
            className={classNames(css.layoutFooter, {
              [css.activeleftbarFooter]: activeFooterTab === 'leftbar',
              [css.createFooterTab]:
                activeFooterTab === 'create' || activeFooterTab === 'activitySearch',
            })}
          >
            {!!isMobileLayout && !(activeFooterTab === 'leftbar') && (
              <FooterBottomMenu
                changeActivieFooterTab={value => setActiveFooterTab(value)}
                history={props.history}
              />
            )}
          </LayoutWrapperFooter>
        )}
      </LayoutSingleColumn>
      <Modal
        isOpen={!!(activeFooterTab === 'leftbar')}
        onClose={() => setActiveFooterTab('search')}
        id="filterModal"
        onManageDisableScrolling={() => {}}
        className={css.modalLeftBar}
      >
        <LeftBar
          isMobileLayout={isMobileLayout}
          history={props.history}
          onShowEnquiryTab={onSearchProduct}
          onLogoClick={() => setActiveFooterTab('search')}
          onClose={() => setActiveFooterTab('search')}
        />
      </Modal>
      <Modal
        id="SelectedCategoryTypePage.shareForm"
        className={css.shareFormModal}
        isOpen={isOpenShareForm}
        onClose={() => setIsOpenShareForm(false)}
        onManageDisableScrolling={() => {}}
      >
        <ShareForm url={url} />
      </Modal>
    </Page>
  );
};

SelectedCategoryTypePageComponent.propTypes = {
  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
};

const mapStateToProps = state => {
  const { promotedListingRefs } = state.SelectedCategoryTypePage;
  const promotedListings = getMarketplaceEntities(state, promotedListingRefs);

  return {
    promotedListings,
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const mapDispatchToProps = dispatch => ({
  queryPromotedListingsSelectedCategoryTypePage: searchquery =>
    dispatch(queryPromotedListingsSelectedCategoryTypePage(searchquery)),
  showListing: listingId => dispatch(showListing(listingId)),
  queryPromotedListings: searchquery => dispatch(queryPromotedListings(searchquery)),
});

const SelectedCategoryTypePage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
  withViewport
)(SelectedCategoryTypePageComponent);

export default SelectedCategoryTypePage;
