import silver1 from '../../assets/VendorPage/silver1.svg';
import silver2 from '../../assets/VendorPage/silver2.svg';
import silver3 from '../../assets/VendorPage/silver3.svg';
import gold1 from '../../assets/VendorPage/gold1.svg';
import gold2 from '../../assets/VendorPage/gold2.svg';
import gold3 from '../../assets/VendorPage/gold3.svg';
import platinum1 from '../../assets/VendorPage/platinum1.svg';
import platinum3 from '../../assets/VendorPage/platinum3.svg';
import platinum2 from '../../assets/VendorPage/platinum2.svg';

export const planCards = [
  {
    title: 'Basic',
    price: '$99',
    include: ['All access ', '2-3 Leads Matches Guaranteed', 'Admin Support ', 'No Commissions'],
    plan: 'Basic-Plan-USD-Yearly',
    partners: [
      {
        image: silver1,
        title: 'Crunch',
        des: 'Gym',
      },
      {
        image: silver2,
        title: 'Esporta',
        des: 'Gym',
      },
      {
        image: silver3,
        title: 'Eos',
        des: 'Gym',
      },
    ],
  },
  {
    title: 'Premium',
    price: '$299',
    include: ['All access ', '5-10 Leads Matches Guaranteed', 'Admin Support ', 'No Commissions'],
    plan: 'Premium-plan-USD-Yearly',
    partners: [
      {
        image: gold1,
        title: 'Life Time',
        des: 'Clubs',
      },
      {
        image: gold2,
        title: 'Orangetheory',
        des: 'Gym',
      },
      {
        image: gold3,
        title: 'F45',
        des: 'Gym',
      },
    ],
  },
  {
    title: 'Company',
    price: '$499',
    include: ['All access ', '10-20 Leads Matches Guaranteed', 'Admin Support ', 'No Commissions'],
    plan: 'Company-plan-vendor-USD-Yearly',
    partners: [
      {
        image: platinum1,
        title: 'SLT',
        des: 'Studios',
      },
      {
        image: platinum2,
        title: "Barry's",
        des: 'Studios',
      },
      {
        image: platinum3,
        title: 'Dancebody',
        des: 'Studios',
      },
    ],
  },
];

export const tabs = ['What you’ll get', 'Make it your own', 'At your fingertips'];

export const becomeMemberQuestions = [
  'How Long is The package Classes and Subscriptions Valid for?',
  'How Can I get my Company to use Probuddy?',
  'Do i have to Commit to any memberships?',
  'How can I find gyms & apps specific to my location?',
];
