import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeListing } from './MyActivity.duck';
import css from './MyActivity.css';

function MyActivity() {
  const dispatch = useDispatch();

  const fetchedData = useSelector(state => state.MyActivity.fetchedData);

  const handleCloseListing = async listingId => {
    await dispatch(closeListing(listingId));
    window.location.reload();
  };

  return (
    <div className={css.myActivitySection}>
      <div className={css.myActivityMain}>
        {fetchedData.length !== 0 &&
          fetchedData.map(listing => {
            return (
              <>
                <div className={css.myActivityWrap} key={listing.id.uuid}>
                  <div className={css.myActivityDetail}>
                    <h5 className={css.myActivityHead}>
                      {listing.attributes.publicData.que_ans[0][0]}
                    </h5>
                    <h5 className={css.myActivitySubHead}>
                      {listing.attributes.publicData.que_ans[0][1]}
                    </h5>
                  </div>
                  <div className={css.myActivityDetail}>
                    <p className={css.myActivityText}>
                      {listing.attributes.publicData.que_ans[0][3]}
                    </p>
                  </div>
                  <div className={css.myActivityDetail}>
                    <p className={css.myActivityText}>
                      {listing.attributes.publicData.que_ans[0][2]}
                    </p>
                  </div>
                  <div className={css.myActivityDetail}>
                    <p className={css.myActivityText}>
                      {listing.attributes.publicData.date.day} -{' '}
                      {listing.attributes.publicData.date.month},{' '}
                      {listing.attributes.publicData.date.week}
                    </p>
                    <p className={css.myActivityText}>
                      {listing.attributes.publicData.time.start} -{' '}
                      {listing.attributes.publicData.time.end}
                    </p>
                  </div>
                  <div className={css.myActivityDetail}>
                    <button
                      className={css.myActivityDeclineBtn}
                      onClick={() => handleCloseListing(listing.id)}
                    >
                      Close Listing
                    </button>
                  </div>
                </div>
              </>
            );
          })}
        {fetchedData.length === 0 && <div>You have no join requests on your matches yet...</div>}
      </div>
    </div>
  );
}

export default MyActivity;
