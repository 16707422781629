import React, { memo, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  UpcomingCalendar,
  ClassesComponent,
  PromotedListing,
  RangeSlider,
  OutSideCloseCard,
  IconSpinner,
  PaginationButtons,
  ProductListing,
} from '../../../components';
import InfiniteScroll from 'react-infinite-scroll-component';
import moment from 'moment';
import filter from '../../../assets/HomePage/filter.svg';
import css from '../HomePage.css';
import { useDispatch, useSelector } from 'react-redux';
import { LISTING_TYPES, MAX_MOBILE_SCREEN_WIDTH } from 'util/constants';
import ListingFlipCard from 'components/PromotedListing/ListingFlipCard/ListingFlipCard';
import MatchActivityForm from 'containers/MatchActivity/MatchActivityComponents/MatchActivityForm';
import { onGetJobsBoard } from 'containers/MatchActivity/MatchActivity.duck';
import AgeGroupSwitch from 'components/AgeGroupFilter/AgeGroup';
import LevelFilter from 'components/LevelFilter/LevelFilter';
import ModeFilter from 'components/ModeFilter/ModeFilter';
import FacilitiesComponent from 'components/FacilitiesInLineListingCard/FacilitiesComponent';
import classNames from 'classnames';
import Switch from 'react-switch';
import { getRecommendationData } from '../HomePage.duck';
import RecommendedUserList from 'containers/RecommendationCommissions/RecommendedUserList';

const ShowListings = props => {
  const {
    currentTab,
    chargebeeData,
    fetchMoreData,
    gettingListings,
    handleAgeGroupChange,
    handleModeChange,
    selectedKidsAge,
    selectedAgeGroup,
    handleClassDateChange,
    selectedLevelMode,
    handleLevelChange,
    handleClassDateSearch,
    searchAllClasses,
    intl,
    items,
    maxTime,
    listingType,
    minTime,
    open,
    recommend,
    onChangeApplyTimeSearch,
    onTimeChange,
    promotedListings,
    showTimePopup,
    selectedDate,
    selectedMaxTime,
    selectedMinTime,
    setShowTimePopup,
    listingAllReviews,
    isEducationSite,
    // isTennisSite,
    page,
    pagination,
    changePage,
    viewport,
  } = props;
  const allClassListingReviews = useSelector(state => state.ListingPage.allClassListingReviews);
  const isMobileLayout = !!viewport?.width && viewport?.width < MAX_MOBILE_SCREEN_WIDTH;
  const isProfilePage = listingType === LISTING_TYPES.PLAYER_PROFILE;
  const dispatch = useDispatch();
  const initSearchValue = {
    keywords: '',
    pub_listing_type: 'match-activity',
    location: '',
    include: ['author', 'author.profileImage'],
    // page: 1,
  };
  const { currentUser } = useSelector(state => state.user);
  const { jobsBoardListing, loading, jobsPagination } = useSelector(state => state.MatchActivity);
  const { recommendationList, recommendationPagination, isLoading } = useSelector(
    state => state.HomePage
  );

  const getListingData = async searchParams => {
    dispatch(onGetJobsBoard(searchParams));
    dispatch(getRecommendationData());
  };

  useEffect(() => {
    const searchValue = {
      ...initSearchValue,
    };

    getListingData(searchValue);
  }, []);

  return (
    <>
      {open === true ? (
        <MatchActivityForm
          jobsBoardListing={jobsBoardListing}
          jobsPagination={jobsPagination}
          // handlePage={handlePage}
          intl={intl}
          loading={loading}
          isEducationSite={isEducationSite}
          // history={history}
          prop={props}
          currentUser={currentUser}
          // filterSearch={jobsSearch.keywords}
        />
      ) : recommend === true ? (
        <RecommendedUserList
          recommendationList={recommendationList}
          recommendationPagination={recommendationPagination}
          // handlePage={handlePage}
          intl={intl}
          isLoading={isLoading}
          isEducationSite={isEducationSite}
          // history={history}
          prop={props}
          currentUser={currentUser}
          // filterSearch={jobsSearch.keywords}
        />
      ) : (
        <>
          {currentTab !== 'class' && currentTab !== 'courses' && currentTab !== 'facility' ? (
            // <div
            //   className={classNames({
            //     [css.smallCardContainer]: promotedListings?.length < 4,
            //   })}
            // >
            //   {promotedListings?.length === 0 ? (
            //     <div className={css.NoListingsFound}>
            //       <FormattedMessage id="HomePage.NoListingsFound" />
            //     </div>
            //   ) : (
            //     <InfiniteScroll
            //       dataLength={items?.length}
            //       next={fetchMoreData}
            //       hasMore={items?.length < promotedListings?.length}
            //       loader={
            //         gettingListings === false && (
            //           <div className={css.contentLoader}>
            //             <IconSpinner />
            //           </div>
            //         )
            //       }
            //       className={classNames(css.cardContainer, isProfilePage && css.profilecard)}
            //     >
            //       {!isProfilePage &&
            //         items?.map(pl => (
            //           <div key={pl.id.uuid} className={css.homePageCardContainer}>
            //             <div className={css.cardItem}>
            //               {!isMobileLayout ? (
            //                 <PromotedListing
            //                   className={css.item}
            //                   chargebeeData={chargebeeData}
            //                   key={pl.id.uuid}
            //                   pl={pl}
            //                   intl={intl}
            //                   listingAllReviews={listingAllReviews}
            //                   newReviews={(listingAllReviews && listingAllReviews[pl.id.uuid]) || []}
            //                   promotedListings={promotedListings}
            //                   isTennisSite={isTennisSite}
            //                   isMobileLayout={isMobileLayout}
            //                 />
            //               ) : (
            //                 <ProductListing
            //                   showCountry={true}
            //                   key={pl.id.uuid}
            //                   pl={pl}
            //                   intl={intl}
            //                   isMobileLayout={isMobileLayout}

            //                   // sub_category={sportName.replaceAll('-', ' ')}
            //                 />
            //               )}
            //             </div>
            //           </div>
            //         ))}
            //       {isProfilePage &&
            //         items?.map(pl => (
            //           <div key={pl.id.uuid} className={css.homePageCardContainerPlayerProfile}>
            //             <div className={css.cardItemPlayerProfile}>
            //               <ProductListing
            //                 showCountry={true}
            //                 listingType={LISTING_TYPES.PLAYER_PROFILE}
            //                 key={pl.id.uuid}
            //                 pl={pl}
            //                 intl={intl}
            //                 isMobileLayout={isMobileLayout}

            //                 // sub_category={sportName.replaceAll('-', ' ')}
            //               />
            //             </div>
            //           </div>
            //         ))}
            //     </InfiniteScroll>
            //   )}
            // </div>
            <>
              <div className={css.homeMainWrapper}>
                <InfiniteScroll
                  dataLength={items?.length}
                  next={fetchMoreData}
                  hasMore={items?.length < promotedListings?.length}
                  loader={
                    gettingListings === false && (
                      <div className={css.contentLoader}>
                        <IconSpinner />
                      </div>
                    )
                  }
                  // className={classNames(css.cardContainer)}
                >
                  <div className={css.newHomeMain}>
                    {items.length !== 0 &&
                      items.map((pl, i) => {
                        return (
                          <ListingFlipCard
                            showCountry={true}
                            key={i}
                            pl={pl}
                            intl={intl}
                            isEducationSite={isEducationSite}
                            isMobileLayout={isMobileLayout}
                            chargebeeData={chargebeeData}
                            listingType={listingType}
                            // sub_category={sportName.replaceAll('-', ' ')}
                          />
                        );
                      })}
                  </div>
                </InfiniteScroll>
              </div>
            </>
          ) : currentTab === 'facility' ? (
            <div className={css.facilitiesCardContainer}>
              <FacilitiesComponent
                className={css.classCard}
                listings={promotedListings}
                allClassListingReviews={allClassListingReviews}
                showAll={false}
                min={selectedMinTime}
                max={selectedMaxTime}
                selectedDate={selectedDate ? moment(selectedDate) : moment()}
              />
            </div>
          ) : (
            <div className={css.classContainer}>
              <div className={css.classHeader}>
                {!isMobileLayout && (
                  <div
                    // key={item.id}
                    className={classNames(css.showClasses, {
                      [css.checkedShowClasses]: searchAllClasses === true,
                      [css.uncheckedShowClasses]: searchAllClasses === false,
                    })}
                    onClick={e => handleClassDateSearch(!searchAllClasses)}
                  >
                    <h4>Show All</h4>

                    <Switch
                      uncheckedIcon={false}
                      checkedIcon={true}
                      onChange={e => handleClassDateSearch(e)}
                      checked={searchAllClasses}
                    />
                  </div>
                )}
                <UpcomingCalendar
                  initialDate={selectedDate ? moment(selectedDate) : moment()}
                  onDateSelect={handleClassDateChange}
                  numberOfDays={30}
                  totalItems={3}
                />
                <div className={css.dropDown}>
                  <button
                    className={css.filterButton}
                    onClick={() => {
                      setShowTimePopup(true);
                    }}
                  >
                    <img src={filter} alt="filter" />
                    {'Time'}
                  </button>
                  <OutSideCloseCard
                    show={showTimePopup}
                    onClickOutside={() => {
                      setShowTimePopup(false);
                    }}
                    className={css.timeDropdown}
                  >
                    <div className={css.dropdownContent}>
                      <RangeSlider
                        min={0}
                        max={22}
                        step={1}
                        handles={[minTime, maxTime]}
                        isPlainFilter={true}
                        onChange={onTimeChange}
                        className={css.filterRangeSlider}
                      />
                      <div className={css.timeContainer}>
                        <div className={css.timeValue}>{`${minTime}:00`}</div>
                        <div className={css.timeValue}>{`${maxTime}:00`}</div>
                      </div>
                      <button className={css.applyBtn} onClick={onChangeApplyTimeSearch}>
                        {'APPLY'}
                      </button>
                    </div>
                  </OutSideCloseCard>

                  {isMobileLayout && (
                    <div
                      // key={item.id}
                      className={classNames(css.showClasses, {
                        [css.checkedShowClasses]: searchAllClasses === true,
                        [css.uncheckedShowClasses]: searchAllClasses === false,
                      })}
                      onClick={e => handleClassDateSearch(!searchAllClasses)}
                    >
                      <h4>Show All</h4>

                      <Switch
                        uncheckedIcon={false}
                        checkedIcon={true}
                        onChange={e => handleClassDateSearch(e)}
                        checked={searchAllClasses}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className={css.filterMain}>
                <AgeGroupSwitch
                  handleAgeGroupChange={handleAgeGroupChange}
                  selectedAgeGroup={selectedAgeGroup}
                />
                <LevelFilter
                  handleLevelChange={handleLevelChange}
                  selectedLevelMode={selectedLevelMode}
                  isMobileLayout={isMobileLayout}
                />
                {selectedAgeGroup === 'Kids' && (
                  <ModeFilter
                    handleModeChange={handleModeChange}
                    selectedKidsAge={selectedKidsAge}
                  />
                )}
              </div>
              {!!promotedListings.length ? (
                <div className={css.classCardContainer}>
                  <ClassesComponent
                    className={css.classCard}
                    listings={promotedListings}
                    allClassListingReviews={allClassListingReviews}
                    showAll={false}
                    min={selectedMinTime}
                    max={selectedMaxTime}
                    searchAllClasses={searchAllClasses}
                    selectedDate={selectedDate ? moment(selectedDate) : moment()}
                  />
                </div>
              ) : (
                <span className={css.NoListingsFound}>
                  <FormattedMessage id="HomePage.NoListingsFound" />
                </span>
              )}
            </div>
          )}
          {currentTab !== 'class' && currentTab !== 'courses' && !!pagination?.totalPages && (
            <PaginationButtons
              page={page}
              totalPages={pagination?.totalPages}
              onPageClick={changePage}
            />
          )}
        </>
      )}
    </>
  );
};

export default memo(ShowListings);
