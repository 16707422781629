import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { orderBy } from 'lodash';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { parse } from '../../util/urlHelpers';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import {
  ManageListingCard,
  Page,
  PaginationLinks,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  FooterBottomMenu,
  NamedLink,
} from '../../components';
import { TopbarContainer } from '../../containers';
import { withViewport } from '../../util/contextHelpers';
import { withRouter } from 'react-router-dom';
import {
  closeListing,
  openListing,
  getOwnListingsById,
  queryOwnListings,
} from './ManageListingsPage.duck';

import classNames from 'classnames';
import css from './ManageListingsPage.css';

// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 42 is divisible by 2 and 3
// So, there's enough cards to fill all columns on full pagination pages
const RESULT_PAGE_SIZE = 42;
export const MAX_MOBILE_SCREEN_WIDTH = 1024;

export class ManageListingsPageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      listingMenuOpen: null,
      activeFooterTab: 'search',
      modalOpen: false,
    };
    this.onToggleMenu = this.onToggleMenu.bind(this);
    this.onkeywordSearch = this.onkeywordSearch.bind(this);
  }

  onToggleMenu(listing) {
    this.setState({ listingMenuOpen: listing });
  }

  onkeywordSearch = activity => {
    const searchParams = {
      pub_sub_category: activity.key ? activity.key : null,
      keywords: activity.title ? null : activity.key ? null : activity,
      pub_listing_type: null,
    };
    !!activity.id && this.props.onSearchListingTab(null);
    activity.id
      ? this.props.showListing(activity.id)
      : this.props.queryPromotedListings(searchParams);
  };

  render() {
    const {
      closingListing,
      closingListingError,
      listings,
      onCloseListing,
      onOpenListing,
      openingListing,
      openingListingError,
      pagination,
      queryInProgress,
      queryListingsError,
      queryParams,
      scrollingDisabled,
      intl,
      viewport,
      isEducationSite,
      isTennisSite,
    } = this.props;

    const hasPaginationInfo = !!pagination && pagination.totalItems != null;
    const listingsAreLoaded = !queryInProgress && hasPaginationInfo;

    const isMobileLayout = !!viewport.width && viewport.width < MAX_MOBILE_SCREEN_WIDTH;
    const loadingResults = (
      <h2>
        <FormattedMessage id="ManageListingsPage.loadingOwnListings" />
      </h2>
    );

    const queryError = (
      <h2 className={css.error}>
        <FormattedMessage id="ManageListingsPage.queryError" />
      </h2>
    );

    const noResults =
      listingsAreLoaded && pagination.totalItems === 0 ? (
        <h1 className={css.title}>
          <FormattedMessage id="ManageListingsPage.noResults" />
        </h1>
      ) : null;

    const heading =
      listingsAreLoaded && pagination.totalItems > 0 ? (
        <h1 className={css.title}>
          <FormattedMessage
            id="ManageListingsPage.youHaveListings"
            values={{ count: pagination.totalItems }}
          />
        </h1>
      ) : (
        noResults
      );

    const page = queryParams ? queryParams.page : 1;
    const paginationLinks =
      listingsAreLoaded && pagination && pagination.totalPages > 1 ? (
        <PaginationLinks
          className={css.pagination}
          pageName="ManageListingsPage"
          pageSearchParams={{ page }}
          pagination={pagination}
        />
      ) : null;

    const listingMenuOpen = this.state.listingMenuOpen;
    const closingErrorListingId = !!closingListingError && closingListingError.listingId;
    const openingErrorListingId = !!openingListingError && openingListingError.listingId;

    const title = intl.formatMessage({ id: 'ManageListingsPage.title' });

    const panelWidth = 62.5;
    // Render hints for responsive image
    const renderSizes = [
      `(max-width: 767px) 100vw`,
      `(max-width: 1920px) ${panelWidth / 2}vw`,
      `${panelWidth / 3}vw`,
    ].join(', ');

    const sortedListings = listings && orderBy(listings, 'attributes.state', 'desc');

    return (
      <Page title={title} scrollingDisabled={scrollingDisabled}>
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer
              currentPage="ManageListingsPage"
              isEducationSite={isEducationSite}
              // isTennisSite={isTennisSite}
            />
            {/* <UserNav selectedPageName="ManageListingsPage" /> */}
          </LayoutWrapperTopbar>
          <LayoutWrapperMain>
            {queryInProgress ? loadingResults : null}
            {queryListingsError ? queryError : null}
            <div className={css.listingPanel}>
              <div className={css.breadCrumbWrapper}>
                <NamedLink
                  name={
                    isEducationSite ? 'EducationPage' : isTennisSite ? 'TennisPage' : 'HomePage'
                  }
                >
                  Home
                </NamedLink>
                <span className={css.breadcrumbArrow}>&gt;</span>
                <NamedLink name="ManageListingsPage">My Listings</NamedLink>
              </div>
              {heading}
              <ul className={css.listingCards}>
                {sortedListings.map(l => (
                  <ManageListingCard
                    className={css.listingCard}
                    key={l.id.uuid}
                    listing={l}
                    isMenuOpen={!!listingMenuOpen && listingMenuOpen.id.uuid === l.id.uuid}
                    actionsInProgressListingId={openingListing || closingListing}
                    onToggleMenu={this.onToggleMenu}
                    onCloseListing={onCloseListing}
                    onOpenListing={onOpenListing}
                    hasOpeningError={openingErrorListingId.uuid === l.id.uuid}
                    hasClosingError={closingErrorListingId.uuid === l.id.uuid}
                    renderSizes={renderSizes}
                  />
                ))}
              </ul>
              {paginationLinks}
            </div>
          </LayoutWrapperMain>
          {!isMobileLayout ? (
            <LayoutWrapperFooter>
              <Footer isEducationSite={isEducationSite} />
            </LayoutWrapperFooter>
          ) : (
            <LayoutWrapperFooter
              className={classNames(css.layoutFooter, {
                [css.activeleftbarFooter]: this.state.activeFooterTab === 'leftbar',
                [css.createFooterTab]:
                  this.state.activeFooterTab === 'create' ||
                  this.state.activeFooterTab === 'activitySearch',
              })}
            >
              {!!isMobileLayout &&
                !(this.state.activeFooterTab === 'leftbar') &&
                !this.state.modalOpen && (
                  <FooterBottomMenu
                    changeActivieFooterTab={value =>
                      this.setState({ ...this.state, activeFooterTab: value })
                    }
                    history={this.props.history}
                    isEducationSite={isEducationSite}
                    // isTennisSite={isTennisSite}
                  />
                )}
            </LayoutWrapperFooter>
          )}
        </LayoutSingleColumn>
      </Page>
    );
  }
}

ManageListingsPageComponent.defaultProps = {
  listings: [],
  pagination: null,
  queryListingsError: null,
  queryParams: null,
  closingListing: null,
  closingListingError: null,
  openingListing: null,
  openingListingError: null,
};

const { arrayOf, bool, func, object, shape, string } = PropTypes;

ManageListingsPageComponent.propTypes = {
  closingListing: shape({ uuid: string.isRequired }),
  closingListingError: shape({
    listingId: propTypes.uuid.isRequired,
    error: propTypes.error.isRequired,
  }),
  listings: arrayOf(propTypes.ownListing),
  onCloseListing: func.isRequired,
  onOpenListing: func.isRequired,
  openingListing: shape({ uuid: string.isRequired }),
  openingListingError: shape({
    listingId: propTypes.uuid.isRequired,
    error: propTypes.error.isRequired,
  }),
  pagination: propTypes.pagination,
  queryInProgress: bool.isRequired,
  queryListingsError: propTypes.error,
  queryParams: object,
  scrollingDisabled: bool.isRequired,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,
  currentUser: propTypes.currentUser,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    currentPageResultIds,
    pagination,
    queryInProgress,
    queryListingsError,
    queryParams,
    openingListing,
    openingListingError,
    closingListing,
    closingListingError,
  } = state.ManageListingsPage;
  const listings = getOwnListingsById(state, currentPageResultIds);
  return {
    currentPageResultIds,
    listings,
    pagination,
    queryInProgress,
    queryListingsError,
    queryParams,
    scrollingDisabled: isScrollingDisabled(state),
    openingListing,
    openingListingError,
    closingListing,
    closingListingError,
  };
};

const mapDispatchToProps = dispatch => ({
  onCloseListing: listingId => dispatch(closeListing(listingId)),
  onOpenListing: listingId => dispatch(openListing(listingId)),
});

const ManageListingsPage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
  withViewport,
  withRouter
)(ManageListingsPageComponent);

ManageListingsPage.loadData = (params, search) => {
  const queryParams = parse(search);
  const page = queryParams.page || 1;
  return queryOwnListings({
    ...queryParams,
    page,
    perPage: RESULT_PAGE_SIZE,
    include: ['images'],
    'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
    'limit.images': 1,
  });
};

export default ManageListingsPage;
