import React from 'react';
import css from './SectionBookingUrl.css';

const SectionBookingUrl = props => {
  const { services } = props;

  return (
    //     <div className={css.serviceMain}>
    //       {services.map(service => {
    //         return (
    //           <div className={css.BookingUrlCard}>
    //             <img src={service?.asset?.url} alt="name" />
    //             <h3>{service?.text}</h3>
    //             <button className={css.btn}>View</button>
    //           </div>
    //         );
    //       })}
    //     </div>
    <div className={css.serviceCardMain}>
      <div className={css.serviceCard}>
        {services.map((service, i) => {
          return (
            <div key={i} className={css.sreviceCardWrap}>
              <img className={css.serviceImage} src={service?.asset?.url} alt="name" />
              <h3 className={css.serviceText}>{service?.text}</h3>
              <a href={service?.url} target="_blank" className={css.btnView}>
                View
              </a>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SectionBookingUrl;
