import React from 'react';
import calendarIcon from 'assets/calendarIcon.png';
import packagesIcon from 'assets/packagesIcon.png';
import scheduleIcon from 'assets/scheduleIcon.png';
import favouriteIcon from 'assets/favouriteIcon.png';
import subscriptionIcon from 'assets/subscriptionIcon.png';
import jobsBoardIcon from 'assets/jobsBoardIcon.png';
import yourPackagesIcon from 'assets/yourPackagesIcon.png';


export const IconBookings = props => {
  return (
    <img width="32" height="35" src={calendarIcon} alt="Bookings" />
  );
};


export const IconPackages = props => {
  return (
    <img width="35" height="35" src={packagesIcon} alt="Packages" />
  );
};

export const IconSchedule = props => {
  return (
    <img width="33" height="32" src={scheduleIcon} alt="Schedule" />
  );
};

export const IconClient = props => {
  return (
    <img width="39" height="39" src={favouriteIcon} alt="Favourite" />
  );
};

export const IconSubscriptions = props => {
  return (
    <img width="33" height="28" src={subscriptionIcon} alt="Subscription" />
  );
};

export const IconJobsBoard = props => {
  return (
    <img width="34" height="29" src={jobsBoardIcon} alt="Jobs board" />
  );
};

export const IconClientYourPackage = props => {
  return (
    <img width="31" height="31" src={yourPackagesIcon} alt="Your packages" />
  );
};

export const IconPlus = props => {
  return (<svg xmlns="http://www.w3.org/2000/svg" width="17" height="46" viewBox="0 0 17 46" fill="none">
    <path d="M1.91235 26.2023V23.3657H7.93625V17.7243H10.8685V23.3657H16.8924V26.2023H10.8685V31.8756H7.93625V26.2023H1.91235Z" fill="#0095B3" />
  </svg>);
};