import React, { useState } from 'react';
import { compose } from 'redux';
import { NamedRedirect } from '..';
import moment from 'moment';
import { ResponsiveImage, HorizontalScroll } from '../../components';
import { createSlug } from '../../util/urlHelpers';
import { ensurePrice } from 'util/data';
import { withViewport } from '../../util/contextHelpers';
import config from '../../config';
import { defaultListingCurrency } from '../../util/types';
import starRatingIcon from '../../assets/HomePage/starRatingIcon.svg';
import { formatMoney } from '../../util/currency';
import Price from 'react-price';

import css from './ProductListing.css';
import classNames from 'classnames';
import { createResourceLocatorString, openLinkNewTab } from 'util/routes';
import routeConfiguration from 'routeConfiguration';
import { LISTING_TYPES } from 'util/constants';

const priceData = (price, intl) => {
  if (
    price &&
    (price.currency === config.currency || defaultListingCurrency.includes(price.currency))
  ) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

const ProductListing = props => {
  const { pl, intl, isMobileLayout, showCountry = false, listingType, chargebeeData } = props;

  const [isRedirect, setRedirect] = useState(false);
  const images = pl?.images || [];
  const id = pl.id.uuid;
  const slug = createSlug(pl.attributes.title);
  const ratings = pl.attributes.publicData.rating || pl.attributes.publicData.total_ratings;
  const price = pl.attributes?.price;
  const currentPrice = ensurePrice(price);
  const { formattedPrice } = priceData(currentPrice, intl);
  const { discountPrice } = priceData(currentPrice, intl, 'discount');
  const levelMode = pl?.attributes?.publicData?.level_mode;
  const title = pl.attributes.title;
  const description = pl.attributes?.description;
  const address = pl.attributes?.publicData?.location?.address;
  const otherImages = pl?.images?.slice(0, 6);
  const country =
    address &&
    address
      .split(',')
      .pop()
      .replaceAll(/\d/g, '')
      .trim();

  const todayDate = moment(new Date()).format('MM/DD/YYYY');
  const hasSubscription = pl.attributes.publicData.has_subscription;
  const sideImages = images.length > 4 ? images.slice(1, 5) : images.slice(0, 4);

  const titleWords = title.split(' ');

  const firstWord = titleWords.slice(0, 1)[0];

  const wordsExceptFirst = titleWords.slice(1);
  const remainingTitle = wordsExceptFirst.join(' ');

  const listingUrl = createResourceLocatorString(
    'ListingPage',
    routeConfiguration(),
    { id, slug },
    {}
  );
  const openLinkNewTabFun = () => {
    setRedirect(false);
    openLinkNewTab(listingUrl);
  };

  const fun = levelMode => {
    switch (levelMode) {
      case 'Begineer Level': //Begineer Level
        return (
          <div className={css.levelButtonWrapper}>
            <div className={css.levelContent}>
              <div className={css.svgWrapper}>{levelNumber.svg1}</div>
              <div className={css.levelwrapper}>{1}</div>
              <div className={css.svgWrapper}>{levelNumber.svg2}</div>
            </div>
            <div className={css.buttonContent}>
              <div className={css.editListingTypeText}>{levelMode}</div>
            </div>
          </div>
        );
      case 'Begineer Can Rally':
        return (
          <div className={css.levelButtonWrapper}>
            <div className={css.levelContent}>
              <div className={css.svgWrapper}>{levelNumber.svg1}</div>
              <div className={css.levelwrapper}>{2}</div>
              <div className={css.svgWrapper}>{levelNumber.svg2}</div>
            </div>
            <div className={css.buttonContent}>
              <div className={css.editListingTypeText}>{levelMode}</div>
            </div>
          </div>
        );
      case 'Intermediate Can Rally':
        return (
          <div className={css.levelButtonWrapper}>
            <div className={css.levelContent}>
              <div className={css.svgWrapper}>{levelNumber.svg1}</div>
              <div className={css.levelwrapper}>{2.5}</div>
              <div className={css.svgWrapper}>{levelNumber.svg2}</div>
            </div>
            <div className={css.buttonContent}>
              <div className={css.editListingTypeText}>{levelMode}</div>
            </div>
          </div>
        );
      case 'Intermediate Can Play Points':
        return (
          <div className={css.levelButtonWrapper}>
            <div className={css.levelContent}>
              <div className={css.svgWrapper}>{levelNumber.svg1}</div>
              <div className={css.levelwrapper}>{3}</div>
              <div className={css.svgWrapper}>{levelNumber.svg2}</div>
            </div>
            <div className={css.buttonContent}>
              <div className={css.editListingTypeText}>{levelMode}</div>
            </div>
          </div>
        );
      case 'Intermediate / Advanced':
        return (
          <div className={css.levelButtonWrapper}>
            <div className={css.levelContent}>
              <div className={css.svgWrapper}>{levelNumber.svg1}</div>
              <div className={css.levelwrapper}>{3.5}</div>
              <div className={css.svgWrapper}>{levelNumber.svg2}</div>
            </div>
            <div className={css.buttonContent}>
              <div className={css.editListingTypeText}>{levelMode}</div>
            </div>
          </div>
        );
      case 'Advanced Rally':
        return (
          <div className={css.levelButtonWrapper}>
            <div className={css.levelContent}>
              <div className={css.svgWrapper}>{levelNumber.svg1}</div>
              <div className={css.levelwrapper}>{4}</div>
              <div className={css.svgWrapper}>{levelNumber.svg2}</div>
            </div>
            <div className={css.buttonContent}>
              <div className={css.editListingTypeText}>{levelMode}</div>
            </div>
          </div>
        );
      case 'Advanced Match Play':
        return (
          <div className={css.levelButtonWrapper}>
            <div className={css.levelContent}>
              <div className={css.svgWrapper}>{levelNumber.svg1}</div>
              <div className={css.levelwrapper}>{4.5}</div>
              <div className={css.svgWrapper}>{levelNumber.svg2}</div>
            </div>
            <div className={css.buttonContent}>
              <div className={css.editListingTypeText}>{levelMode}</div>
            </div>
          </div>
        );
      case 'Advanced Tournament Play':
        return (
          <div className={css.levelButtonWrapper}>
            <div className={css.levelContent}>
              <div className={css.svgWrapper}>{levelNumber.svg1}</div>
              <div className={css.levelwrapper}>{5}</div>
              <div className={css.svgWrapper}>{levelNumber.svg2}</div>
            </div>
            <div className={css.buttonContent}>
              <div className={css.editListingTypeText}>{levelMode}</div>
            </div>
          </div>
        );
      case 'Advanced High Level':
        return (
          <div className={css.levelButtonWrapper}>
            <div className={css.levelContent}>
              <div className={css.svgWrapper}>{levelNumber.svg1}</div>
              <div className={css.levelwrapper}>{5.5}</div>
              <div className={css.svgWrapper}>{levelNumber.svg2}</div>
            </div>
            <div className={css.buttonContent}>
              <div className={css.editListingTypeText}>{levelMode}</div>
            </div>
          </div>
        );
      case 'Advanced National Level':
        return (
          <div className={css.levelButtonWrapper}>
            <div className={css.levelContent}>
              <div className={css.svgWrapper}>{levelNumber.svg1}</div>
              <div className={css.levelwrapper}>{6}</div>
              <div className={css.svgWrapper}>{levelNumber.svg2}</div>
            </div>
            <div className={css.buttonContent}>
              <div className={css.editListingTypeText}>{levelMode}</div>
            </div>
          </div>
        );
      default:
        return (
          <div className={css.levelButtonWrapper}>
            <div className={css.levelContent}>
              <div className={css.svgWrapper}>{levelNumber.svg1}</div>
              <div className={css.levelwrapper}>{7}</div>
              <div className={css.svgWrapper}>{levelNumber.svg2}</div>
            </div>
            <div className={css.buttonContent}>
              <div className={css.editListingTypeText}>{levelMode}</div>
            </div>
          </div>
        );
    }
  };

  const levelNumber = {
    svg1: (
      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 33 40">
        <path
          d="M14,12 L6,12 L14,20 L6,28 L14,28 L22,20 L14,12 Z"
          fill="#005ef4"
          data-name="Layer 1"
        />
      </svg>
    ),
    points: '1',
    svg2: (
      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 33 40">
        <path
          d="M14,12 L6,12 L14,20 L6,28 L14,28 L22,20 L14,12 Z"
          fill="#005ef4"
          data-name="Layer 1"
        />
      </svg>
    ),
  };

  return isRedirect ? (
    // isMobileLayout ? (
    <NamedRedirect name="ListingPage" params={{ id, slug }} />
  ) : (
    // ) : (
    //   openLinkNewTabFun()
    // )
    // !isMobileLayout ? (
    //   <div className={css.productListingWrap} onClick={() => setRedirect(true)}>
    //     <div className={css.productImgWrap}>
    //       <ResponsiveImage
    //         src={images[0]}
    //         alt="authorProfileImg"
    //         image={images[0]}
    //         className={css.productListingImg}
    //         variants={['scaled-small', 'square-small', 'square-small2x']}
    //       />
    //       <div className={css.productListingStartIcon}>
    //         <svg
    //           width="17"
    //           height="16"
    //           viewBox="0 0 17 16"
    //           fill="#fff"
    //           xmlns="http://www.w3.org/2000/svg"
    //         >
    //           <path
    //             d="M8.32093 0.775751C8.38463 0.579683 8.66202 0.579683 8.72572 0.775751L10.3867 5.88783C10.4152 5.97552 10.4969 6.03488 10.5891 6.03488H15.9643C16.1705 6.03488 16.2562 6.29869 16.0894 6.41987L11.7408 9.57931C11.6662 9.6335 11.635 9.72956 11.6635 9.81724L13.3245 14.9293C13.3882 15.1254 13.1638 15.2884 12.997 15.1673L8.64841 12.0078C8.57383 11.9536 8.47282 11.9536 8.39824 12.0078L4.04964 15.1673C3.88286 15.2884 3.65845 15.1254 3.72215 14.9293L5.38317 9.81724C5.41166 9.72956 5.38045 9.6335 5.30586 9.57931L0.957264 6.41987C0.790479 6.29869 0.876195 6.03488 1.08235 6.03488H6.45751C6.54971 6.03488 6.63142 5.97552 6.65991 5.88783L8.32093 0.775751Z"
    //             fill="white"
    //           />
    //         </svg>
    //       </div>
    //     </div>

    //     <div className={css.productListingDesc}>
    //       <div className={css.categoryRating}>
    //         <span className={css.categoryText}>
    //           {props?.sub_category || pl.attributes.publicData.sub_category}
    //         </span>
    //         <div className={css.wrapper}>
    //           {showCountry && <h6 className={css.countryText}>{country}</h6>}
    //           <div className={css.ratingTextWrap}>
    //             <img src={starRatingIcon} alt={' '} />
    //             <span className={css.ratingText}>{ratings?.toFixed(2)}</span>
    //           </div>
    //         </div>
    //       </div>
    //       <h3 className={css.titleText}>{pl.attributes.title}</h3>
    //       <p className={css.descText}>{pl.attributes.description}</p>
    //       <div className={css.desktopPriceNBtnWrap}>
    //         {listingType !== LISTING_TYPES.PLAYER_PROFILE && (
    //           <>
    //             <button className={css.cardDetailButton}>Book Now</button>
    //             <div className={css.productPrice}>{formattedPrice}</div>
    //           </>
    //         )}
    //         {listingType === LISTING_TYPES.PLAYER_PROFILE && (
    //           <>
    //             <button className={css.cardDetailButton}>Message</button>
    //             {fun(levelMode)}
    //           </>
    //         )}
    //       </div>
    //     </div>
    //   </div>
    // ) : (
    //   <div className={css.slider} onClick={() => setRedirect(true)}>
    //     <HorizontalScroll
    //       scrollClassName={css.newScroll}
    //       isSmallScrollArrow={true}
    //       className={classNames(css.categoryListContainer, {
    //         [css.categoryListContainerWithoutArrow]:
    //           typeof window !== 'undefined' ? window.screen.width <= 425 : true,
    //       })}
    //     >
    //       <div className={css.sliderWrap}>
    //         <div className={css.trainerMainWrapper}>
    //           <div className={css.trainerSectionWrap} onClick={() => setRedirect(true)}>
    //             <ResponsiveImage
    //               src={pl.images[0]}
    //               alt="authorProfileImg"
    //               image={pl.images[0]}
    //               variants={['scaled-small', 'square-small', 'square-small2x']}
    //             />
    //           </div>
    //           <div className={css.trainerDetailMain}>
    //             <div className={css.trainerDetailWrap}>
    //               <div className={css.listingHeadWrap}>
    //                 <span className={css.categoryText}>
    //                   {props?.sub_category || pl.attributes.publicData.sub_category}
    //                 </span>
    //                 {showCountry && <h6 className={css.countryText}>{country}</h6>}
    //               </div>
    //               <div className={css.trainerDetailTitle} onClick={() => setRedirect(true)}>
    //                 {title}
    //               </div>

    //               <div className={css.ratingLocationWrapper} onClick={() => setRedirect(true)}>
    //                 <div className={css.ratingWrap}>
    //                   <div className={css.ratingTextWrap}>
    //                     <img src={starRatingIcon} alt={' '} />
    //                     <span className={css.ratingText}>{ratings?.toFixed(2)}</span>
    //                   </div>
    //                 </div>
    //                 <div className={css.locationWrap}>
    //                   <div className={css.locationTextWrap}>
    //                     <svg
    //                       width="12"
    //                       height="16"
    //                       viewBox="0 0 12 16"
    //                       fill="none"
    //                       xmlns="http://www.w3.org/2000/svg"
    //                     >
    //                       <path
    //                         d="M6.24479 15.5361C5.21174 14.6551 4.25419 13.6892 3.38216 12.6487C2.07353 11.086 0.519532 8.7587 0.519532 6.54099C0.518964 5.4084 0.854466 4.30109 1.48358 3.3592C2.11269 2.41732 3.00713 1.68319 4.05371 1.24974C5.10028 0.816293 6.25195 0.702998 7.36295 0.924197C8.47395 1.1454 9.49435 1.69115 10.295 2.49238C10.8281 3.02297 11.2506 3.654 11.5381 4.34895C11.8256 5.04389 11.9724 5.78894 11.97 6.54099C11.97 8.7587 10.4161 11.086 9.10742 12.6487C8.23538 13.6892 7.27784 14.6551 6.24479 15.5361ZM6.24479 4.08778C5.59403 4.08778 4.96993 4.34625 4.50978 4.80631C4.04962 5.26638 3.79111 5.89036 3.79111 6.54099C3.79111 7.19163 4.04962 7.81561 4.50978 8.27568C4.96993 8.73574 5.59403 8.9942 6.24479 8.9942C6.89555 8.9942 7.51965 8.73574 7.97981 8.27568C8.43996 7.81561 8.69847 7.19163 8.69847 6.54099C8.69847 5.89036 8.43996 5.26638 7.97981 4.80631C7.51965 4.34625 6.89555 4.08778 6.24479 4.08778Z"
    //                         fill="#0058FA"
    //                       />
    //                     </svg>

    //                     <span className={css.locationMainText}>{address}</span>
    //                   </div>
    //                 </div>
    //               </div>

    //               <div className={css.trainerDetailDesc} onClick={() => setRedirect(true)}>
    //                 {description}
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       </div>

    //       <div className={css.gallerItemsWrap}>
    //         {otherImages &&
    //           otherImages.slice(0, 4).map((image, index) => {
    //             return (
    //               <div key={index} className={css.gallerItems}>
    //                 <ResponsiveImage
    //                   className={css.itemImg}
    //                   src={image}
    //                   image={image}
    //                   variants={['scaled-small', 'scaled-small2x']}
    //                   alt={'title'}
    //                 />
    //               </div>
    //             );
    //           })}
    //       </div>
    //     </HorizontalScroll>
    //     <div className={css.promotedBookingWrapper}>
    //       <button className={css.cardDetailButton}>Book Now</button>
    //       <div className={css.priceWrapper}>
    //         <div className={css.priceFrom}>From</div>
    //         <div className={css.priceProduct}>
    //           <Price cost={formattedPrice} />
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // );
    <div className={css.homeCardMain} onClick={() => setRedirect(true)}>
      <div className={css.homeCardHead}>
        <div className={css.homeHeadImage}>
          <ResponsiveImage
            src={images[0]}
            alt="authorProfileImg"
            image={images[0]}
            // variants={['scaled-small', 'square-small', 'square-small2x']}
            variants={['scaled-medium', 'scaled-small', 'scaled-small2x']}
            onClick={() => setRedirect(true)}
          />
        </div>
      </div>
      <div className={css.homeHeadDetail}>
        <h5 className={css.frontHead}>{title}</h5>
        <div className={css.headSports}>
          {listingType !== LISTING_TYPES.PLAYER_PROFILE && formattedPrice && (
            <span className={css.headSGD}>
              {' '}
              {chargebeeData?.ChargebeeId &&
              todayDate <= chargebeeData?.FutureDate &&
              hasSubscription ? (
                <>
                  <Price cost={formattedPrice} type="old" />
                  <Price cost={discountPrice} />
                </>
              ) : (
                <Price cost={formattedPrice} />
              )}
            </span>
          )}

          {pl.attributes.publicData.sub_category instanceof Array ? (
            pl.attributes.publicData?.sub_category?.slice(0, 3)?.map((subCat, i) => (
              <span key={i} className={css.subCategoryText}>
                {subCat}
              </span>
            ))
          ) : (
            <span className={css.subCategoryText}>{pl.attributes.publicData.sub_category}</span>
          )}

          {/* <span className={css.sport1}>Tennis</span>
              <span className={css.sport2}>Padel Tennis</span> */}
        </div>

        {/* {listingType !== LISTING_TYPES.PLAYER_PROFILE && formattedPrice && (
            <div className={css.headRate}>
              <div className={css.priceWrapper}>
                <div className={css.priceProduct}>
                  {chargebeeData?.ChargebeeId &&
                  todayDate <= chargebeeData?.FutureDate &&
                  hasSubscription ? (
                    <>
                      <Price cost={formattedPrice} type="old" />
                      <Price cost={discountPrice} />
                    </>
                  ) : (
                    <Price cost={formattedPrice} />
                  )}
                </div>
              </div>
            </div>
          )} */}
      </div>

      <div className={css.homeDetails}>
        <div className={css.detailLocation}>{address}</div>
        <p className={css.detailDesc}>{description}</p>
        <div className={css.detailsImageGallery}>
          {!!sideImages &&
            !!sideImages.length &&
            sideImages.slice(0, 3).map((image, index) => {
              return (
                <div key={index} className={css.cardImgWrapper}>
                  <ResponsiveImage
                    className={css.itemImg}
                    src={image}
                    image={image}
                    variants={['scaled-small', 'scaled-small2x']}
                    alt="title"
                  />
                </div>
              );
            })}
        </div>
      </div>

      <div className={css.homeButtonGroup}>
        <button className={css.buttonMessage}>Message</button>
        <button className={css.buttonBook}>Book Now</button>
      </div>

      <div className={css.homePremium}>
        {pl.attributes.publicData?.has_premium_membership ? (
          pl.attributes.publicData?.has_premium_membership && (
            <div className={css.ratingTextWrap}>
              <img src={starRatingIcon} alt={' '} />
              <span className={css.ratingText}>Premium Member</span>
            </div>
          )
        ) : (
          <span className={css.membershipButton}>Basic Member</span>
        )}
      </div>
    </div>
  );
};

export default compose(withViewport)(ProductListing);
