import React from 'react';
import { LISTING_TYPES } from 'util/constants';
import { defaultListingCurrency } from '../../util/types';
import { formatMoney } from '../../util/currency';
import config from '../../config';
import moment from 'moment';
import { parse, stringify } from '../../util/urlHelpers';
import { types as sdkTypes } from '../../util/sdkLoader';

import MdImages from 'react-ionicons/lib/MdImages';
import MdSchool from 'react-ionicons/lib/MdSchool';
import MdAppstore from 'react-ionicons/lib/MdAppstore';
import MdListBox from 'react-ionicons/lib/MdListBox';
import MdStar from 'react-ionicons/lib/MdStar';
import IosListBox from 'react-ionicons/lib/IosListBox';

export const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16;
export const { UUID } = sdkTypes;
export const URL = 'https://wa.me';

export const companyMenuList = [
  {
    key: 'gallery',
    textId: 'TopbarDesktop.gallery',
    icon: <MdImages fontSize="24px" />,
  },
  {
    key: 'classes',
    textId: 'TopbarDesktop.classes',
    icon: <MdSchool fontSize="24px" />,
  },
  {
    key: 'product',
    textId: 'TopbarDesktop.products',
    icon: <MdAppstore fontSize="24px" />,
  },
  {
    key: 'faq',
    textId: 'TopbarDesktop.FAQ',
    icon: <MdListBox fontSize="24px" />,
  },
  {
    key: 'reviews',
    textId: 'TopbarDesktop.rating',
    icon: <MdStar fontSize="24px" />,
  },
  {
    key: 'blogs',
    textId: 'TopbarDesktop.blog',
    icon: <IosListBox fontSize="24px" />,
  },
];

export const bookingMenuList = [
  {
    key: 'about',
    textId: 'TopbarDesktop.about',
    icon: <MdImages fontSize="24px" />,
  },
  {
    key: 'packages',
    textId: 'TopbarDesktop.packages',
    icon: <MdSchool fontSize="24px" />,
  },
  {
    key: 'accolades',
    textId: 'TopbarDesktop.accolades',
    icon: <MdAppstore fontSize="24px" />,
  },

  {
    key: 'reviews',
    textId: 'TopbarDesktop.reviews',
    icon: <MdStar fontSize="24px" />,
  },
  {
    key: 'expectation',
    textId: 'TopbarDesktop.expectation',
    icon: <IosListBox fontSize="24px" />,
  },
];

export const actionBarRouteName = listingVariant => {
  switch (listingVariant) {
    case LISTING_TYPES.LISTING:
      return 'EditListingPage';
    case LISTING_TYPES.COMPANY:
      return 'EditCompanyPage';
    case LISTING_TYPES.FREELANCER:
      return 'EditFreelancerPage';
    case LISTING_TYPES.CLASS:
      return 'EditClassPage';
    case LISTING_TYPES.FACILITY:
      return 'EditFacilityPage';
    case LISTING_TYPES.EVENT:
      return 'EditEventPage';
    case LISTING_TYPES.PRODUCT:
      return 'EditProductPage';
    case LISTING_TYPES.MEMBERSHIP:
      return 'EditMembershipPage';
    case LISTING_TYPES.COURSES:
      return 'EditCoursesPage';
    case LISTING_TYPES.EDUCATION_COURSES:
      return 'EditEducationCoursesPage';
    case LISTING_TYPES.HOURLY_SESSION:
      return 'EditHourlySessionPage';
    case LISTING_TYPES.TUTOR:
      return 'EditTutorPage';
    case LISTING_TYPES.ACADEMY:
      return 'EditAcademyPage';
    case LISTING_TYPES.REMOTE_TUTOR:
      return 'EditRemoteTutorPage';
    case LISTING_TYPES.PLAYER_PROFILE:
      return 'EditPlayerProfilePage';
    default:
      return 'EditListingPage';
  }
};

export const bookingTitleId = listingType => {
  switch (listingType) {
    case LISTING_TYPES.EVENT:
    case LISTING_TYPES.PRODUCT:
    case LISTING_TYPES.MEMBERSHIP:
      return 'ListingPage.productTitle';
    case LISTING_TYPES.FACILITY:
      return 'ListingPage.facilityTitle';
    case LISTING_TYPES.COMPANY:
      return 'EditCompanyPage';
    case LISTING_TYPES.FREELANCER:
      return 'EditFreelancerPage';
    case LISTING_TYPES.LISTING:
      return 'EditListingPage';
    case LISTING_TYPES.EDUCATION_COURSES:
      return 'ListingPage.coursesTitle';
    default:
      return 'ListingPage.bookingTitle';
  }
};

export const priceData = (price, intl) => {
  if (price && defaultListingCurrency.includes(price.currency)) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

export const getRemovedWithOutContentReview = reviews => {
  return reviews.filter(review => review.attributes.content !== config.dummyReviewContent);
};

export const getSeatsFromMonthlyTimeSlots = monthlyTimeSlots => {
  const key = moment().format('YYYY-MM');
  const keyNextMonth = moment()
    .add(1, 'month')
    .format('YYYY-MM');
  const res =
    (monthlyTimeSlots[key] &&
      monthlyTimeSlots[key].timeSlots &&
      monthlyTimeSlots[key].timeSlots.length &&
      monthlyTimeSlots[key].timeSlots[0].attributes.seats) ||
    (monthlyTimeSlots[keyNextMonth] &&
      monthlyTimeSlots[keyNextMonth].timeSlots &&
      monthlyTimeSlots[keyNextMonth].timeSlots.length &&
      monthlyTimeSlots[keyNextMonth].timeSlots[0].attributes.seats) ||
    '1';

  return res;
};

export const openBookModal = (isOwnListing, isClosed, history, location, width) => {
  if (isOwnListing || isClosed) {
    window.scrollTo(0, 0);
  } else {
    const { pathname, search, state } = location;
    if (width < 1024) {
      const searchString = `?${stringify({ ...parse(search), book: true })}`;
      history.push(`${pathname}${searchString}`, state);
    }
  }
};

export const removeFile = index => e => {
  e.stopPropagation();

  const dt = new DataTransfer();
  const file = this.state.paymentProofSsUrls[index];
  const el = document.getElementById('payment-dropzone');
  for (let i = 0; i < el.files.length; i++) {
    const rowFile = el.files[i];
    let name = rowFile.name.split('.');
    name.splice(name.length - 1, 1);
    name = name.join('');
    if (name !== file.original_filename) dt.items.add(rowFile);
  }
  el.files = dt.files;

  this.state.paymentProofSsUrls.splice(index, 1);
  this.setState({
    paymentProofSsUrls: [...this.state.paymentProofSsUrls],
  });
};
export const listingImages = (listing, variantName) =>
  (listing.images || [])
    .map(image => {
      const variants = image.attributes.variants;
      const variant = variants ? variants[variantName] : null;

      // deprecated
      // for backwards combatility only
      const sizes = image.attributes.sizes;
      const size = sizes ? sizes.find(i => i.name === variantName) : null;

      return variant || size;
    })
    .filter(variant => variant != null);
