import React from 'react';
import PropTypes from 'prop-types';
import css from './CompanyResults.css';
import { CompanyListingCard } from 'components';
import { propTypes } from 'util/types';

function CompanyResults({ listings, setActiveListing, allListingReview={}, allAuthorNumberOfListings={} }) {
  // Panel width relative to the viewport

  return (
    <div className={css.CompanyCards}>
      {listings.map(l => (
        <CompanyListingCard
          className={css.listingCard}
          key={l.id.uuid}
          listing={l}
          setActiveListing={setActiveListing}
          reviews={allListingReview[l.id.uuid] || []}
          authorNumberOfListings={(l.author && allAuthorNumberOfListings[l.author.id.uuid]) || []}
        />
      ))}
    </div>
  );
}

CompanyResults.propTypes = {
  listings: PropTypes.arrayOf(propTypes.listing),
  setActiveListing: PropTypes.func,
};

export default CompanyResults;
