import { TABS } from 'containers/InboxPage/InboxPage.data';

export const isResults = (tab, transactions, enquiries) => {
  const hasTransactions =
    (tab === TABS.sale || tab === TABS.order) && transactions.length && !enquiries.length;
  const hasEnquiries =
    (tab === TABS.enquiry || tab === TABS.enquiryClient) &&
    !transactions.length &&
    enquiries.length;
  const hasEverything = transactions.length && enquiries.length;

  return hasEverything || hasTransactions || hasEnquiries;
};
export const getNoResultsMessageId = tab => {
  /*eslint default-case: 0*/
  switch (tab) {
    case TABS.schedule:
      return 'InboxPage.noScheduleBookings';
    case TABS.order:
      return 'InboxPage.noOrdersFound';
    case TABS.sale:
      return 'InboxPage.noSalesFound';
    case TABS.proConnectRequests:
      return 'InboxPage.noproConnectRequestsFound';
    case TABS.enquiry:
    case TABS.enquiryClient:
      return 'InboxPage.noEnquiriesFound';
    case TABS.MyListings:
      return 'InboxPage.noMyListings';
    case TABS.MyMatch:
      return 'InboxPage.noMyMatches';
    case TABS.HostedMatches:
      return 'InboxPage.noMyMatches';
    case TABS.JoinedMatches:
      return 'InboxPage.noJoinedMatches';
    case TABS.MyRewards:
      return 'InboxPage.noRewards';
    default:
      return 'InboxPage.noData';
  }
};
