import React from 'react';
import moment from 'moment'
import { UserDisplayName, Button, Avatar, NamedLink } from 'components/index';
import { FormattedMessage } from 'util/reactIntl';
import { createSlug, stringify } from 'util/urlHelpers';

import css from './InboxEnquireItem.css';

const createListingLink = (listing, otherUser, searchParams = {}, className = '') => {
    const listingId = listing.id && listing.id.uuid;
    const label = listing.attributes.title;
    const listingDeleted = listing.attributes.deleted;

    if (!listingDeleted) {
        const params = { id: listingId, slug: createSlug(label) };
        const to = { search: stringify(searchParams) };
        return (
            <NamedLink className={className} name="ListingPage" params={params} to={to}>
                <Avatar user={otherUser} disableProfileLink />
            </NamedLink>
        );
    } else {
        return <FormattedMessage id="TransactionPanel.deletedListingOrderTitle" />;
    }
};

const InboxEnquireItem = props => {
    const {
        tx,
        type,
        intl,
        onEnquireMessageModalOpen
    } = props;

    const { customer, provider, listing, messages } = tx;

    const isOrder = type === 'order';
    const otherUser = isOrder ? provider : customer;
    const listingName = listing && listing.attributes.title;
    const messageContent = messages && messages.length > 0 ? messages[(messages.length - 1)].attributes.content : null;
    const messageCreationTime = messageContent ? messages[(messages.length - 1)].attributes.createdAt : null;
    const formattedMessageTime = moment(messageCreationTime).format('d/YY H:mm A');

    const isTodayEnquiry = (tx.attributes.lastTransitionedAt) ? moment().format('YYYY-MM-DD') === moment(tx.attributes.lastTransitionedAt).format('YYYY-MM-DD') : false;
    const rowNotificationDot = isTodayEnquiry ? <div className={css.notificationDot} /> : null;

    const otherUserDisplayName = <UserDisplayName user={otherUser ? otherUser : null} intl={intl} />;
    const listingLink = listing ? createListingLink(listing, otherUser) : null;

    return (
        <div className={css.enquireCardRoot}>
            <NamedLink
                className={css.itemLink}
                name={isOrder ? 'OrderDetailsPage' : 'SaleDetailsPage'}
                params={{ id: tx.id.uuid }}
            >
                <div className={css.otherUserinfoWrapper}>
                    <div className={css.userAvatar}>
                        <div className={css.rowNotificationDot}>{rowNotificationDot}</div>
                        {isOrder && listing ? listingLink : <Avatar className={css.avatarUser} user={otherUser} />}
                    </div>
                    <div className={css.userInfo}>
                        <span className={css.userName}>{otherUserDisplayName}</span>
                        <span className={css.userMessageTime}>{formattedMessageTime}</span>
                    </div>
                </div>
                <h3 className={css.listingName}>{listingName}</h3>
                <div className={css.messageContent}>
                    {messageContent}
                </div>
            </NamedLink>
            <Button className={css.messageButton}
                onClick={() => onEnquireMessageModalOpen(tx)}
            >Message {otherUser ? otherUser.attributes.profile.displayName : null}</Button>
        </div>
    );
}

export default InboxEnquireItem;