import { denormalisedResponseEntities } from '../../util/data';

// ================ Action types ================ //

export const SET_INITIAL_STATE = 'app/VendorListings/SET_INITIAL_STATE';

export const VENDOR_LISTINGS_REQUEST = 'app/VendorListings/VENDOR_LISTINGS_REQUEST';
export const VENDOR_LISTINGS_SUCCESS = 'app/VendorListings/VENDOR_LISTINGS_SUCCESS';
export const VENDOR_LISTINGS_ERROR = 'app/VendorListings/VENDOR_LISTINGS_ERROR';

export const FETCH_AUTHOR_PROFILE_REQUEST = 'app/VendorListings/FETCH_AUTHOR_PROFILE_REQUEST';
export const FETCH_AUTHOR_PROFILE_SUCCESS = 'app/VendorListings/FETCH_AUTHOR_PROFILE_SUCCESS';
export const FETCH_AUTHOR_PROFILE_ERROR = 'app/VendorListings/FETCH_AUTHOR_PROFILE_ERROR';

// ================ Reducer ================ //

const initialState = {
  vendorListings: [],
  authorProfile: null,
  vendorListingsError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_STATE:
      return { ...initialState };

    case VENDOR_LISTINGS_REQUEST:
      return { ...state, vendorListings: [], vendorListingsError: null };
    case VENDOR_LISTINGS_SUCCESS:
      return { ...state, vendorListings: payload.vendorListings, vendorListingsError: null };
    case VENDOR_LISTINGS_ERROR:
      return { ...state, vendorListings: [], vendorListingsError: payload };

    case FETCH_AUTHOR_PROFILE_REQUEST:
      return { ...state, authorProfile: null, authorProfileError: null };
    case FETCH_AUTHOR_PROFILE_SUCCESS:
      return { ...state, authorProfile: payload.authorProfile[0], authorProfileError: null };
    case FETCH_AUTHOR_PROFILE_ERROR:
      return { ...state, authorProfile: null, authorProfileError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const setInitialState = () => ({
  type: SET_INITIAL_STATE,
});

export const vendorListingRequest = () => ({
  type: VENDOR_LISTINGS_REQUEST,
});

export const vendorListingSuccess = vendorListings => ({
  type: VENDOR_LISTINGS_SUCCESS,
  payload: { vendorListings },
});

export const vendorListingError = e => ({
  type: VENDOR_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const authorProfileRequest = () => ({
  type: FETCH_AUTHOR_PROFILE_REQUEST,
});

export const authorProfileSuccess = authorProfile => ({
  type: FETCH_AUTHOR_PROFILE_SUCCESS,
  payload: { authorProfile },
});

export const authorProfileError = e => ({
  type: FETCH_AUTHOR_PROFILE_ERROR,
  error: true,
  payload: e,
});

// ================ Thunk ================ //

export const showVendorListing = id => (dispatch, getState, sdk) => {
  dispatch(vendorListingRequest());
  return sdk.listings
    .query({
      authorId: id,
      include: 'author,author.profileImage,images',
    })
    .then(response => {
      const vendorListings = denormalisedResponseEntities(response);
      dispatch(vendorListingSuccess(vendorListings));
    })
    .catch(e => dispatch(vendorListingError(e)));
};

export const showAuthor = id => (dispatch, getState, sdk) => {
  dispatch(authorProfileRequest());
  return sdk.users
    .show({
      id: id,
      include: ['profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(response => {
      const showAuthor = denormalisedResponseEntities(response);
      dispatch(authorProfileSuccess(showAuthor));
    })
    .catch(e => dispatch(authorProfileError(e)));
};

export const loadData = params => (dispatch, getState, sdk) => {
  const { id } = params;
  dispatch(setInitialState());

  return Promise.all([dispatch(showVendorListing(id)), dispatch(showAuthor(id))]);
};
