import React from 'react';
import { uid } from 'react-uid';
import { propTypes } from 'util/types';
import config from 'config';
import { formatMoney } from 'util/currency';
import { types as sdkTypes } from 'util/sdkLoader';
import { FormattedMessage, intlShape } from 'util/reactIntl';

import css from './SimplePackages.css';

const { Money } = sdkTypes;

const SimplePackages = ({ listing, intl }) => {
  const currency =
    listing?.attributes?.publicData?.currency ||
    listing?.attributes?.publicData?.price?.currency ||
    config.currency;

  if (!listing.attributes.publicData.packages) {
    return null;
  }

  return (
    <ul className={css.packagesList}>
      {listing.attributes.publicData.packages.map((p, i) => {
        const { price, description, sessions } = p;
        const formattedPrice = formatMoney(intl, new Money(price, currency));
        return (
          <li key={uid(p, i)} className={css.packageItem}>
            <div className={css.section}>
              <span className={css.session}>
                <FormattedMessage id={'SimplePackages.sessions'} values={{ sessions }} />
              </span>
              <span className={css.desc}>{description}</span>
            </div>
            <div className={css.pricingSection}>
              <span className={css.price}>{formattedPrice}</span>
            </div>
          </li>
        );
      })}
    </ul>
  );
};

SimplePackages.propTypes = {
  listing: propTypes.listing.isRequired,
  intl: intlShape.isRequired,
};

export default SimplePackages;
