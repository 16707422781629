import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './TermsOfService.css';

const TermsOfService = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <p className={css.lastUpdated}>Last updated: November 23, 2020</p>

      <p>
        This page explains the terms by which you may use our online platform and services provided
        on or in connection with the service (collectively the “Service”). By accessing or using the
        Service, you agree to be bound by this Terms of Use Agreement (“Agreement”) and to the
        collection and use of your information as set forth in the Probuddy.io Privacy Policy,
        whether or not you are a registered user of our Service. This Agreement applies to all
        visitors, users, customers, contributors and others who access the Service (“Users”).
      </p>

      <p>
        By visiting our site and/or purchasing something from us, you engage in our “Service” and
        agree to be bound by the following terms and conditions (“Terms of Service”, “Terms”),
        including those additional terms and conditions and policies referenced herein and/or
        available by hyperlink. These Terms of Service apply to all users of the site, including
        without limitation users who are browsers, vendors, customers, merchants, and/or
        contributors of content.
      </p>

      <p>
        Please read these Terms of Service carefully before accessing or using our website. By
        accessing or using any part of the site, you agree to be bound by these Terms of Service. If
        you do not agree to all the terms and conditions of this agreement, then you may not access
        the website or use any services. If these Terms of Service are considered an offer,
        acceptance is expressly limited to these Terms of Service.
      </p>

      <p>
        Any new features or tools which are added to the current store shall also be subject to the
        Terms of Service. You can review the most current version of the Terms of Service at any
        time on this page. We reserve the right to update, change or replace any part of these Terms
        of Service by posting updates and/or changes to our website. It is your responsibility to
        check this page periodically for changes. Your continued use of or access to the website
        following the posting of any changes constitutes acceptance of those changes.
      </p>

      <h2>Use of Probuddy.io Platform Terms</h2>
      <p>
        By agreeing to these Terms of Service, you represent that you are at least the age of
        majority in your state or province of residence, or that you are the age of majority in your
        state or province of residence and you have given us your consent to allow any of your minor
        dependents to use this site.
      </p>
      <p>
        You may not use our services for any illegal or unauthorized purpose nor may you, in the use
        of the Service, violate any laws in your jurisdiction.
      </p>
      <p>
        A breach or violation of any of the Terms will result in an immediate termination of your
        Services.
      </p>

      <h2>General Conditions</h2>
      <p>We reserve the right to refuse service to anyone for any reason at any time.</p>
      <p>
        You understand that your content (not including credit card information), may be transferred
        unencrypted and involve (a) transmissions over various networks; and (b) changes to conform
        and adapt to technical requirements of connecting networks or devices. Credit card
        information is always encrypted during transfer over networks.
      </p>
      <p>
        You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the
        Service, use of the Service, or access to the Service or any contact on the website through
        which the service is provided, without express written permission by us.
      </p>
      <p>
        The headings used in this agreement are included for convenience only and will not limit or
        otherwise affect these Terms.
      </p>
      <p>
        The probuddy.io platform enables users to reserve, schedule, purchase, access and attend a
        wide range of fitness, recreational and wellness Classes offered and operated by fitness
        studios, gyms, trainers, venues or other third parties (collectively, “Listings”).
        Probuddy.io itself is not a gymnasium, place of amusement or recreation, health club,
        facility, fitness studio or similar establishment and does not own, operate or control any
        of the Classes that are offered at or through such facilities.
      </p>

      <h2>Use of Account</h2>
      <p>
        Your Probuddy.io account is personal to you and you agree not to create more than one
        account. You cannot transfer or gift Classes or Packages to third parties or allow third
        parties to use your Probuddy.io account, including other Probuddy.io users. Your packages
        might be transferred to a new Instructor that takes over from a existing instructor on the
        platform if a provider decides to move out of the platform a instructor of similar
        experience will be assigned to the acount. You can opt out of receiving any promotions on the
        companies offerings at anytime. You must not use or exploit the Site and/or Classes for
        commercial purposes. We continually update and test various aspects of the Probuddy.io
        platform. We reserve the right to, and by using the Site and/or Classes you agree that we
        may, include you in or exclude you from these tests without notice. You understand and agree
        that Probuddy.io may take actions we deem reasonably necessary to prevent fraud and abuse.
      </p>
    </div>
  );
};

TermsOfService.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

TermsOfService.propTypes = {
  rootClassName: string,
  className: string,
};

export default TermsOfService;
