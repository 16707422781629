import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { withViewport } from '../../util/contextHelpers';
import routeConfiguration from 'routeConfiguration';
import { FormattedMessage, injectIntl } from 'react-intl';
import { createResourceLocatorString } from 'util/routes';
import { IMAGES } from 'util/imagesPath';
import config from 'config';
import { queryPromotedListings } from 'containers/HomePage/HomePage.duck';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import BecomeSeller from '../BlogPage/sections/BecomeSeller/BesomeSeller';
import {
  Footer,
  FooterBottomMenu,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  LeftBar,
  Modal,
  NamedLink,
  Page,
} from 'components';
import { PrimaryButton2 } from 'components/Button/Button';
import MarketingSection from './MarketingSection';
import ProbuddyLogo from '../../assets/ProbuddylogoBlue.svg';
import classNames from 'classnames';
import AdvancedIntegrationSection from './AdvancedIntegrationSection';
import { addChargebeeSubscription } from 'ducks/user.duck';
import moment from 'moment';
import css from '../BecomeVendorPage/BecomeVendorPage.css';

const MAX_MOBILE_SCREEN_WIDTH = 1024;

const BecomeTutor = props => {
  const { viewport, intl } = props;
  const { currentUser } = useSelector(state => state.user);
  const dispatch = useDispatch();

  const isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;
  const schemaTitle = intl.formatMessage({ id: 'BecomeTutorPage.schemaTitle' });
  const schemaDescription = intl.formatMessage({ id: 'BecomeTutorPage.schemaDescription' });
  const schemaImage = `${config.canonicalRootURL}${IMAGES.facebookImage}`;

  const { scrollingDisabled } = isScrollingDisabled(useSelector(state => state));

  const [state, setState] = useState({
    currentTab: '',
    isSellerMode: false,
    isOpenSearchBar: false,
    showOpacity: null,
    isScrolling: false,
    activeFooterTab: '',
  });

  const [chargebeeId, setChargebeeId] = useState(null);
  const [chargebeeState, setchargebeeState] = useState(null);
  const [chargebeeObject, setchargebeeObject] = useState({});

  useEffect(() => {
    const el = document.createElement('script');
    el.onload = () => {
      if (typeof window !== 'undefined') {
        window.Chargebee.init({
          site: config.cbSite,
        });
        window.Chargebee.registerAgain();
      }
    };

    el.setAttribute('src', 'https://js.chargebee.com/v2/chargebee.js');

    document.body.appendChild(el);

    const queryParams = new URLSearchParams(window.location.search);
    const chargebeeIdparam = queryParams.get('id');
    let chargebeeStateparam = queryParams.get('state');
    const currentDate = moment(new Date());
    let futureDate = moment(currentDate).add(1, 'm');
    const futureMonthEnd = moment(futureDate).endOf('month');
    if (
      currentDate.date() !== futureDate.date() &&
      futureDate.isSame(futureMonthEnd.format('YYYY-MM-DD'))
    ) {
      futureDate = futureDate.add(1, 'd');
    }
    setChargebeeId(chargebeeIdparam);
    setchargebeeState(chargebeeStateparam);
    setchargebeeObject({ chargebeeIdparam, chargebeeStateparam, currentDate, futureDate });
  }, []);

  useEffect(() => {
    dispatch(addChargebeeSubscription(chargebeeObject, currentUser));
  }, [chargebeeId, chargebeeState, chargebeeObject, currentUser, dispatch]);

  const onSearch = searchquery => {
    props.history.push(createResourceLocatorString('EducationPage', routeConfiguration(), {}));
    setTimeout(() => {
      dispatch(queryPromotedListings(searchquery));
    }, 500);
  };

  const onkeywordSearch = activity => {
    const searchParams = {
      pub_sub_category: activity.key ? activity.key : null,
      keywords: activity.title ? null : activity.key ? null : activity,
      pub_listing_type: null,
    };
    setTimeout(() => {
      activity.id ? props.showListing(activity.id) : props.queryPromotedListings(searchParams);
    }, 500);
  };

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      isBottomMenuRequire={false}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: IMAGES.facebookImage, width: 1200, height: 630 }]}
      twitterImages={[
        {
          url: `${config.canonicalRootURL}${IMAGES.twitterImage}`,
          width: 600,
          height: 314,
        },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <div className={css.topBarContainer}>
            <div className={css.probuddyLogo}>
              <NamedLink name="EducationPage">
                <img src={ProbuddyLogo} alt={'probuddy'} />
              </NamedLink>
            </div>
            <NamedLink name="EducationPage">
              <PrimaryButton2 className={css.exploreButton}>Explore the App</PrimaryButton2>
            </NamedLink>
          </div>
        </LayoutWrapperTopbar>

        <LayoutWrapperMain>
          <div className={css.content}>
            <section className={css.topSection}>
              <div className={css.topHeadContainer}>
                <div className={css.topHeadSectionLeft}>
                  <div className={css.sectionContainer}>
                    <h1>
                      <FormattedMessage id="BecomeTutorPage.adventureMarketplace" />
                    </h1>

                    <div className={css.heroDescription}>
                      <FormattedMessage id="BecomeTutorPage.getYourClients" />
                    </div>

                    {/* {!!isAuthenticated ? (
                      <a
                        className={css.subscriptionLink}
                        href="javascript:void(0)"
                        data-cb-type="checkout"
                        data-cb-item-0="Premium-Plan-Monthly-USD-Monthly"
                        data-cb-item-0-quantity="1"
                        data-cb-customer-cf-user-id="123"
                      >
                        <FormattedMessage id="BecomeVendorPage.subscibePerYear" />
                      </a>
                    ) : (
                      <NamedLink name="LoginPage" className={css.subscriptionLink}>
                        <FormattedMessage id="BecomeVendorPage.subscibePerYear" />
                      </NamedLink>
                    )} */}
                  </div>
                </div>

                <div className={css.topHeadSectionRightEdu}></div>
              </div>
            </section>

            <div className={css.breadCrumbWrapper}>
              <NamedLink name="EducationPage">Home</NamedLink>
              <span className={css.breadcrumbArrow}>&gt;</span>
              <NamedLink name="BecomeTutorPage">Become a Tutor</NamedLink>
            </div>
            <section className={css.servicesSection}>
              <div className={css.servicesTitle}>
                Empower Your Teaching Career with Probuddy Education: A Premier Platform for
                Freelance Tutors and Professors
              </div>
              <div className={css.servicesSubheading}>
                At Probuddy Education, we believe in the power of education to change lives. That's
                why we've created a platform for talented tutors and professors to share their
                knowledge and skills with students around the world. With a commitment to quality
                and a focus on student success, Probuddy Education provides the tools, resources,
                and support you need to succeed as a freelance tutor or professor.
              </div>
            </section>
            {/* <div className={css.businessContainer}>
              <video
                className={css.probuddyVideo}
                src="https://res.cloudinary.com/movementbuddy-pte-ltd/video/upload/v1669013237/probuddy/static/images/Email/ProbuddyVideo_x9icyj.mp4"
                controls="controls"
              />
            </div> */}

            {/* <div className={css.servicesCarousel}>
                <ul className={css.servicesCarouselItems}>
                  {coachData &&
                    coachData.map(data => (
                      <li className={css.servicesCarouselItem}>
                        <img src={data.image} alt={' '} />
                        <div className={css.carouselInfo}>
                          <div className={css.carouselHeading}>
                            <h5>Sports Coach</h5>
                            <div className={css.carouselRating}>
                              <img src={StarRatingsIcon} alt="Rating Icon" />
                              <h5>4.6</h5>
                              <span>(12 Review)</span>
                            </div>
                          </div>

                          <div className={css.carouselName}>{data.name}</div>
                          <div className={css.carouselDescription}>{data.description}</div>
                        </div>
                      </li>
                    ))}
                </ul>
              </div> */}
            {/* </section> */}
            <MarketingSection isEducationSite={true} />

            <AdvancedIntegrationSection />

            <section className={css.becomeSellerSection}>
              <BecomeSeller isVendor={true} isEducationSite={true} message={'Are you a Tutor?'} />
            </section>
          </div>
        </LayoutWrapperMain>
        {!isMobileLayout ? (
          <LayoutWrapperFooter>
            <Footer isEducationSite={true} />
          </LayoutWrapperFooter>
        ) : (
          <LayoutWrapperFooter
            className={classNames(css.layoutFooter, {
              [css.activeleftbarFooter]: state.activeFooterTab === 'leftbar',
              [css.createFooterTab]:
                state.activeFooterTab === 'create' || state.activeFooterTab === 'activitySearch',
            })}
          >
            {!!isMobileLayout && !(state.activeFooterTab === 'leftbar') && !state.modalOpen && (
              <FooterBottomMenu
                changeActivieFooterTab={value => setState({ ...state, activeFooterTab: value })}
                history={props.history}
                onSearch={activity => onkeywordSearch(activity)}
                isEducationSite={true}
              />
            )}
          </LayoutWrapperFooter>
        )}
      </LayoutSingleColumn>
      <Modal
        isOpen={!!(state.activeFooterTab === 'leftbar')}
        onClose={() => setState({ ...state, activeFooterTab: 'search' })}
        id="filterModal"
        onManageDisableScrolling={() => {}}
        className={css.modalLeftBar}
      >
        <LeftBar
          isMobileLayout={isMobileLayout}
          history={props.history}
          isOpenSearchBar={state.isOpenSearchBar}
          showOpacity={state.showOpacity}
          onShowEnquiryTab={onSearch}
          onLogoClick={() => setState({ ...state, activeFooterTab: 'search' })}
          onClose={() => setState({ ...state, activeFooterTab: 'search' })}
          isEducationSite={true}
        />
      </Modal>
    </Page>
  );
};

const BecomeTutorPage = compose(withViewport)(BecomeTutor);

export default compose(withViewport, injectIntl)(BecomeTutorPage);
