import React, { useState, useEffect } from 'react';
import cns from 'classnames';
import moment from 'moment';
import { LISTING_TYPES } from '../../util/constants';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';
import { FormattedMessage } from '../../util/reactIntl';
import css from './ModifiedSearchFilters.css';
import { parse } from '../../util/urlHelpers';
import DropdownButton from './Components/DropdownButton/DropdownButton';
import FilterDropdown from './Components/FilterDropdown/FilterDropdown';
import TopVideoSection from './Components/TopVideoSection/TopVideoSection';

export const ListingTypes = [
  {
    key: LISTING_TYPES.COMPANY,
    label: 'Company',
  },
  {
    key: LISTING_TYPES.CLASS,
    label: 'Classes',
  },
  {
    key: LISTING_TYPES.LISTING,
    label: 'Instructors',
  },
  {
    key: LISTING_TYPES.FACILITY,
    label: 'Rent Space',
  },
];

const Level_Of_Seller = [
  {
    key: 'new',
    label: 'New Arrival',
    isSortable: false,
  },
  {
    key: 'pub_total_bookings',
    label: 'Most Bookings',
    isSortable: true,
  },
  {
    key: 'pro',
    label: 'Pro Verified',
    isSortable: false,
  },
  {
    key: 'master_verified',
    label: 'Master Verified',
    isSortable: false,
  },
  {
    key: 'pub_total_ratings',
    label: 'Best Rated',
    isSortable: true,
  },
  {
    key: 'pub_last_active',
    label: 'Last Online',
    isSortable: true,
  },
];

const getInitialMainCategory = urlQuery => {
  const listing = urlQuery && urlQuery.pub_listing_type;
  const category = urlQuery && urlQuery.pub_category;
  const adventerCategories = [
    'cycling',
    'running',
    'biking',
    'rock climbing',
    'bootcamp',
    'obstacle sports',
    'surfing',
    'kite surfing',
    'skateboarding',
  ].join(',');
  const subCategory = urlQuery && urlQuery.pub_sub_category;

  if (category && category === 'wellness_and_beauty') {
    return 'wellness';
  } else if (!listing || subCategory === adventerCategories) {
    return 'adventure';
  } else {
    return listing;
  }
};

const ModifiedSearchFilters = props => {
  const {
    urlQueryParams,
    isMobileLayout,
    categoryFilter,
    priceFilter,
    languageFilter,
    redirectPageName,
    history,
    listingModeElement,
  } = props;

  const [mainListingType, setMainListingType] = useState(LISTING_TYPES.COMPANY);
  const [selectedSellerLevels, setSelectedSellerLevels] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('sports');
  const [selectedSubCategories, setSelectedSubCategories] = useState(['all_sports']);

  const subCategoryLabel = (selectedSubCategories && selectedSubCategories[0]) || '';

  useEffect(() => {
    const categoryData = filterCategoriesByListing();
    setCategories(categoryData);
    /* eslint-disable-next-line */
  }, [mainListingType]);

  const redirectToUrl = (redirectPageName, queryParams) => {
    return history.push(
      createResourceLocatorString(redirectPageName, routeConfiguration(), {}, queryParams)
    );
  };

  const initialStateData = () => {
    const urlParams = parse(history.location.search);
    const MainListingName = getInitialMainCategory(urlQueryParams);
    setMainListingType(MainListingName);
    if (urlParams && (urlParams.meta_level || urlParams.sort)) {
      const selectedLevels = urlParams.meta_level
        ? Array.isArray(urlParams.meta_level)
          ? urlParams.meta_level
          : urlParams.meta_level.split(',')
        : [];

      const selectedSorts = urlParams.sort
        ? Array.isArray(urlParams.sort)
          ? urlParams.sort
          : urlParams.sort.split(',')
        : [];

      setSelectedSellerLevels([...selectedLevels, ...selectedSorts]);
    }
    if (urlParams && urlParams.pub_category) {
      setSelectedCategory(urlParams && urlParams.pub_category);
    }
    if (urlParams && urlParams.pub_sub_category) {
      const subCategoriesData = Array.isArray(urlParams.pub_sub_category)
        ? urlParams.pub_sub_category
        : urlParams.pub_sub_category.split(',');
      setSelectedSubCategories(subCategoriesData);

      if (MainListingName === 'adventure' && subCategoriesData.length === 10) {
        setSelectedSubCategories([]);
      }
    }
  };
  useEffect(() => {
    initialStateData();
    // eslint-disable-next-line
  }, []);

  const onSelectMainListingType = value => {
    const prevListingType = getInitialMainCategory(urlQueryParams);
    if (value === prevListingType) {
      return;
    } else {
      let queryParams = { ...urlQueryParams };
      setSelectedSubCategories([]);
      if (value === LISTING_TYPES.CLASS) {
        const date = moment().format('YYYY-MM-DD');
        queryParams = {
          ...urlQueryParams,
          dates: `${date},${date}`,
          times: '00:00,23:59',
          pub_listing_type: LISTING_TYPES.CLASS,
          pub_sub_category: null,
          sort: 'pub_total_bookings',
        };
        setSelectedSellerLevels(['pub_total_bookings']);
      } else {
        queryParams = {
          ...urlQueryParams,
          pub_listing_type: value,
          pub_sub_category: null,
          sort: 'pub_total_ratings',
          dates: null,
          times: null,
        };
        setSelectedSellerLevels(['pub_total_ratings']);
      }
      setMainListingType(value);

      return redirectToUrl(redirectPageName, queryParams);
    }
  };

  const filterCategoriesByListing = () => {
    // const searchListingType = mainListingType;

    const categories = categoryFilter.options;
    return categories;
  };

  const getVideoUrl = value => {
    switch (value) {
      case 'adventure':
        return 'https://res.cloudinary.com/movementbuddy/video/upload/v1648126037/adventureVideo_ek8hj5.mp4';
      case 'wellness':
        return 'https://res.cloudinary.com/movementbuddy/video/upload/v1648125985/wellnessVideo_zwd011.mp4';
      default:
        return 'https://res.cloudinary.com/movementbuddy/video/upload/v1648126221/proSportsVideo_euehkb.mp4';
    }
  };

  const onSelectSellerLevelHandler = value => {
    const updateValue = selectedSellerLevels;
    const urlParams = parse(history.location.search);
    const fetchLevel = Level_Of_Seller.find(level => level.key === value);

    let queryParams = { ...urlParams };
    if (fetchLevel.isSortable) {
      const sortData = urlParams.sort
        ? Array.isArray(urlParams.sort)
          ? urlParams.sort
          : urlParams.sort.split(',')
        : [];
      if (updateValue.includes(value)) {
        sortData.splice(sortData.indexOf(value), 1);
        queryParams = { ...urlParams, sort: sortData };
      } else {
        sortData.push(value);
        queryParams = { ...urlParams, sort: sortData };
      }
    } else {
      const metaData = urlParams.meta_level
        ? Array.isArray(urlParams.meta_level)
          ? urlParams.meta_level
          : urlParams.meta_level.split(',')
        : [];
      if (updateValue.includes(value)) {
        metaData.splice(metaData.indexOf(value), 1);
        queryParams = { ...urlParams, meta_level: metaData };
      } else {
        metaData.push(value);
        queryParams = { ...urlParams, meta_level: metaData };
      }
    }

    if (updateValue.includes(value)) {
      updateValue.splice(updateValue.indexOf(value), 1);
    } else {
      updateValue.push(value);
    }

    redirectToUrl(redirectPageName, queryParams);
    setSelectedSellerLevels(updateValue);
  };

  const onChangeCategoryHandler = value => {
    const urlParams = parse(history.location.search);
    setSelectedCategory(value);
    let queryParams = { ...urlParams, pub_category: value };
    return redirectToUrl(redirectPageName, queryParams);
  };

  const submitHandler = values => {
    const validQueryParams = history.location.search && parse(history.location.search);
    const type = validQueryParams && validQueryParams.pub_category;
    if (
      !values.location &&
      JSON.parse(values.promoCategory).label === subCategoryLabel &&
      JSON.parse(values.promoCategory).type === type
    )
      return;
    let searchParams = {};
    if (values.location && values.location.search !== validQueryParams.address) {
      const { search = '', selectedPlace } = values.location;
      const { origin, bounds } = selectedPlace;
      searchParams = {
        ...validQueryParams,
        address: search,
        origin,
        bounds,
        pub_sub_category: values.promoCategory
          ? JSON.parse(values.promoCategory).key
          : history.location.search && parse(history.location.search).pub_sub_category,
        pub_category: null,
      };
    } else {
      searchParams = {
        ...validQueryParams,
        pub_sub_category:
          JSON.parse(values.promoCategory).key ||
          (history.location.search && parse(history.location.search).pub_sub_category),
        pub_category: null,
      };
    }
    if (values.promoCategory && JSON.parse(values.promoCategory).type) {
      searchParams['pub_category'] = JSON.parse(values.promoCategory).type;
    }

    history.push(
      createResourceLocatorString(redirectPageName, routeConfiguration(), {}, searchParams)
    );
  };

  const videoUrl = getVideoUrl(selectedCategory);

  return (
    <>
      {redirectPageName === 'SearchPage' && (
        <TopVideoSection
          selectedCategory={selectedCategory}
          onSubmit={submitHandler}
          location={history.location}
          videoUrl={videoUrl}
        />
      )}

      <section>
        <div className={css.proCategoriesWrapper}>
          <div className={css.proCategoriesMain}>
            {categories &&
              categories.map(cat => {
                if (selectedCategory === cat.key) {
                  return (
                    <div key={cat.key} className={css.proSelectedCategory}>
                      pro<span>{cat.label}</span>{' '}
                    </div>
                  );
                } else {
                  return (
                    <div
                      key={cat.key}
                      onClick={() => onChangeCategoryHandler(cat.key)}
                      className={css.proCategory}
                    >
                      {cat.label}
                    </div>
                  );
                }
              })}
          </div>
        </div>

        <div className={css.mainCategoryFiltersBox}>
          <div
            className={cns(css.mainCategoryFiltersWrapper, {
              [css.mainAdventureWrapper]: selectedCategory === 'adventure',
              [css.mainWellnessWrapper]: selectedCategory === 'wellness',
            })}
          >
            {ListingTypes.map(listType => {
              return (
                <div
                  key={listType.key}
                  onClick={() => onSelectMainListingType(listType.key)}
                  className={cns(
                    css.mainCategoryFilters,
                    mainListingType === listType.key
                      ? isMobileLayout
                        ? css.selectedMobileMainCategory
                        : css.selectedMainCategory
                      : null
                  )}
                >
                  <div className={css.categoryImage}>
                    <img src={`/static/images/searchPage/${listType.key}.png`} alt="" />
                  </div>
                  <div className={css.categoryLabel}>{listType.label}</div>
                  {isMobileLayout && (
                    <div className={css.svgEllipse}>
                      {mainListingType === listType.key ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="21"
                          height="22"
                          viewBox="0 0 21 22"
                          fill="none"
                        >
                          <circle
                            cx="10.5"
                            cy="10.8848"
                            r="9.5"
                            fill="white"
                            stroke="#0095B3"
                            strokeWidth="2"
                          />
                          <path
                            d="M5.44531 11.5848L8.55642 14.3848L16.3342 7.38477"
                            stroke="#0095B3"
                            strokeWidth="2.5"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="21"
                          viewBox="0 0 20 21"
                          fill="none"
                        >
                          <circle
                            cx="10"
                            cy="10.543"
                            r="9"
                            stroke="#CFCFCF"
                            strokeOpacity="0.42"
                            strokeWidth="2"
                          />
                        </svg>
                      )}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>

        {isMobileLayout && (
          <section>
            <div className={css.filterToolWrap}>
              <DropdownButton
                labelClassName={css.labelSellerLevels}
                label={<FormattedMessage id={'ModifiedSearchFilters.sellerLevel'} />}
                inProgress={false}
                selectedOption={selectedSellerLevels}
                direction={'right'}
                actions={Level_Of_Seller.map(level => {
                  return {
                    label: level.label,
                    key: level.key,
                    handler: () => onSelectSellerLevelHandler(level.key),
                  };
                })}
              />

              {isMobileLayout && listingModeElement}

              <FilterDropdown
                urlQueryParams={urlQueryParams}
                languageFilter={languageFilter}
                priceFilter={priceFilter}
                redirectPageName={redirectPageName}
                history={history}
                isMobileLayout={isMobileLayout}
              />
            </div>
          </section>
        )}
      </section>
    </>
  );
};

export default ModifiedSearchFilters;
