import React from 'react';
import PropTypes from 'prop-types';

const IconCheckedShield = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.18182 0L0 3.63636V10C0 14.5836 3.49091 18.8545 8.18182 20C12.8727 18.8545 16.3636 14.5455 16.3636 10V3.63636L8.18182 0Z"
        fill="#2196F3"
      />
      <path d="M0 3.63636V10C0 14.5836 3.49091 18.8545 8.18182 20V0L0 3.63636Z" fill="#64B5F6" />
      <path
        d="M6.36479 14.5455L1.81934 10L3.10115 8.71821L6.36479 11.9728L13.2648 5.07275L14.5466 6.36366L6.36479 14.5455Z"
        fill="#ECEFF1"
      />
    </svg>
  );
};

IconCheckedShield.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconCheckedShield.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconCheckedShield;
