import React from 'react';
import {
  listingMode,
  checkMark,
} from 'forms/EditListingDescriptionForm/EditListingDescriptionForm.data';
import css from '../EducationPage/EducationPage.css';
import classNames from 'classnames';

export const ListingMode = props => {
  const { handleNewListingChange, selectedMode, listingModeWrapClass } = props;

  const listingModeWrapClassName = listingModeWrapClass || css.listingModeWrap;

  return (
    <div className={listingModeWrapClassName}>
      <div className={css.listingCardWrap}>
        {listingMode?.map(type => (
          <div
            className={classNames(css.buttonMain, selectedMode.includes(type.mode) && css.selected)}
          >
            <div onClick={() => handleNewListingChange(type)} className={css.buttonWrapper}>
              <div className={css.listingModeText}>{type.mode}</div>
              <div className={css.svgWrapper}>{type.svg}</div>
            </div>
            <div className={css.checkMark}>{checkMark}</div>
          </div>
        ))}
      </div>
    </div>
  );
};
