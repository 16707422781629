import React, { useState } from 'react';
import { slice } from 'lodash/array';
import { uid } from 'react-uid';
import cns from 'classnames';
import css from './RecentTable.css';
import { NamedLink, PaginationButtons } from 'components';
import IconWhatsapp from 'components/IconWhatsapp/IconWhatsapp';

function RecentTableRow({ entry }) {
  const name =
    entry.transactions[0].attributes.protectedData?.kidsName ||
    entry.customer.attributes.profile.displayName;

  const contactNumber = entry.customer.attributes.profile.publicData
    ? entry.customer.attributes.profile.publicData.phoneNumber
    : ''
  const phoneNo = entry.customer.attributes.profile.publicData.phoneNumber || null
  return (
    <div className={css.row}>
      <div className={cns(css.cell, css.name, css.whatsappIconSection)}>
        <NamedLink
          name="ProfilePage"
          params={{ id: entry.customer.id.uuid }}
          className={css.nameContent}
        >
          {name}
        </NamedLink>
        {
          contactNumber ? <div className={css.iconWhatsapp}>
            <IconWhatsapp />
          </div> : null
        }
      </div>
      <div className={cns(css.cell, css.phone)}>
        {phoneNo ? <NamedLink
          name="ProfilePage"
          params={{ id: entry.customer.id.uuid }}
          className={css.nameContent}
        >
          {phoneNo}
        </NamedLink> : <span className={css.emptyPhone}>-</span>}

      </div>
      <div className={cns(css.cell, css.listingName)}>
        <span className={css.listingNameContent}>{entry.listing.attributes.title}</span>
      </div>
    </div>
  );
}

export default function RecentTable({ data }) {
  const [page, setPage] = useState(1);
  const perPage = 30;

  const totalPages = Math.ceil(data.length / perPage);

  const paginatedData = data => {
    const offset = (page - 1) * perPage;
    return slice(data, offset, offset + perPage);
  };

  return (
    <div className={css.wrapper}>
      <div className={css.table}>
        <div className={css.head}>
          <div className={cns(css.cell, css.nameCol)}>Name</div>
          <div className={cns(css.cell, css.phoneCol)}>Phone Number</div>
          <div className={cns(css.cell, css.listingNameCol)}>Listing Name</div>
        </div>

        {paginatedData(data).map(entry => (
          <RecentTableRow key={uid(entry)} entry={entry} />
        ))}
      </div>

      <PaginationButtons page={page} totalPages={totalPages} onPageClick={setPage} />
    </div>
  );
}
