import React, { memo, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import config from '../../config';
import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  FooterBottomMenu,
  Modal,
} from 'components';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import { injectIntl } from '../../util/reactIntl';
import { withViewport } from '../../util/contextHelpers';
import { withRouter } from 'react-router-dom';

import { MAX_MOBILE_SCREEN_WIDTH } from './MatchActivity.data';
import { city, cityEducation } from 'marketplace-custom-config';
import { ShareForm } from 'forms';
import twitterImage from '../../assets/probuddyThumbnail-twitter-600X314.png';
import facebookImage from '../../assets/probuddyThumbnail-facebook-1200X630.png';
import twitterImageEdu from '../../assets/twitterThumbnail-Edu-600X310.png';
import facebookImageEdu from '../../assets/facebookThumnail-Edu-1200X630.png';
import css from './MatchActivity.css';
import { compose } from 'redux';
import TopbarContainer from 'containers/TopbarContainer/TopbarContainer';
import { onGetJobsBoard } from './MatchActivity.duck';
import { parse } from 'util/urlHelpers';
import { changingActivefooterTab } from 'ducks/user.duck';
import MatchActivityForm from './MatchActivityComponents/MatchActivityForm';
import GetMatchActivityModal from 'components/GetMatchActivityModal/GetMatchActivityModal';

const DEBOUNCE_WAIT_TIME = 600;
const initSearchValue = {
  keywords: '',
  pub_listing_type: 'match-activity',
  location: '',
  include: ['author', 'author.profileImage'],
  // page: 1,
};
const MatchActivityComponent = props => {
  // props
  const { viewport, history, location, params, intl, isEducationSite, isTennisSite } = props;
  const { pathname, search, state } = location;

  const searchParams = parse(search);

  // for Quote Model

  // search state
  const [jobsSearch, setJobsSearch] = useState({
    ...initSearchValue,
    keywords: searchParams?.q || '',
    location: searchParams?.city || '',
  });

  const [activeFooterTab, setActiveFooterTab] = useState('jobsBoard');
  const [isOpenShareForm, setIsOpenShareForm] = useState(false);
  const [showQuote, setShowQuote] = useState(false);
  const [quotesModalOpen, setQuotesModalOpen] = useState(false);
  const [leadModel, setLeadModel] = useState(false);

  const dispatch = useDispatch();

  const { currentUser } = useSelector(state => state.user);
  const { jobsBoardListing, loading, jobsPagination } = useSelector(state => state.MatchActivity);

  const getListingData = async searchParams => {
    dispatch(onGetJobsBoard(searchParams));
  };

  // useEffect(() => {
  //   dispatch(changingActivefooterTab('jobsBoard'));
  //   if (typeof window !== 'undefined') {
  //     window.onscroll = () => {
  //       setShowQuote(window.pageYOffset > 500);
  //     };
  //   }
  // }, []);

  useEffect(() => {
    const searchValue = {
      ...jobsSearch,
    };

    getListingData(searchValue);
  }, [jobsSearch.keywords, jobsSearch.location, jobsSearch.page]);

  const url = typeof window !== 'undefined' && window.location.href;

  const isMobileLayout = !!viewport.width && viewport.width < MAX_MOBILE_SCREEN_WIDTH;

  const siteTitle = isEducationSite ? 'Probuddy Education' : config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'JobsBoard.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'JobsBoard.schemaDescription' });

  const schemaKeywords = intl.formatMessage({ id: 'JobsBoard.schemaKeywords' });

  const canonicalRootURL = config.canonicalRootURL;

  // Functions

  const makeUrl = () => {
    const subCategories =
      Array.isArray(jobsSearch.pub_sub_category) &&
      jobsSearch.pub_sub_category?.length > 0 &&
      !jobsSearch.pub_sub_category.includes('select-all')
        ? jobsSearch.pub_sub_category.join(',')
        : '';
    const keywords = jobsSearch.keywords;
    const city = jobsSearch.location;
    const active = jobsSearch.pub_is_active;

    let pushStr = '?';
    if (city) pushStr += `city=${city}`;

    if (pushStr.length > 1 && subCategories) pushStr += '&';
    if (subCategories) pushStr += `category=${subCategories}`;

    if (pushStr.length > 1 && keywords) pushStr += '&';
    if (keywords) pushStr += `q=${keywords}`;

    if (pushStr.length > 1 && typeof active === 'boolean') pushStr += '&';
    if (typeof active === 'boolean') pushStr += `active=${active}`;

    history.push(pathname + pushStr);
  };

  useEffect(() => {
    makeUrl();
  }, [jobsSearch.keywords, jobsSearch.location, jobsSearch.page]);

  // city search functions

  const handlePage = page => {
    if (jobsSearch.page !== page) {
      setJobsSearch(p => ({ ...p, page: page }));
    }
  };
  // serching functions
  const handleOnChange = value => {
    const searchValue = value.toLowerCase();

    setJobsSearch(p => ({ ...p, keywords: searchValue }));
  };

  // checkBox search functions
  const handleOnCheck = event => {
    setJobsSearch(p => ({ ...p, pub_sub_category: event }));
  };

  return (
    <Page
      description={schemaDescription}
      title={schemaTitle}
      keywords={schemaKeywords}
      scrollingDisabled={false}
      schemaScript={false}
      twitterImages={[
        {
          name: 'twitter',
          url: isEducationSite
            ? `${canonicalRootURL}${twitterImageEdu}`
            : `${canonicalRootURL}${twitterImage}`,
          width: 600,
          height: 314,
        },
      ]}
      facebookImages={[
        {
          name: 'facebook',
          url: isEducationSite
            ? `${canonicalRootURL}${facebookImageEdu}`
            : `${canonicalRootURL}${facebookImage}`,
          width: 600,
          height: 405,
        },
      ]}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar className={css.positionStatic}>
          <TopbarContainer
            setIsOpenShareForm={setIsOpenShareForm}
            isEducationSite={isEducationSite}
            // isTennisSite={isTennisSite}
          />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain>
          <GetMatchActivityModal
            modalId={`MatchActicity.activity`}
            intl={intl}
            open={quotesModalOpen}
            banner={{
              img:
                'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1651477194/probuddy/about-us-1056_pmbsd1.webp', // <---
              head: `Answer a couple questions to help us find you the best match.`,
              desc: `With Probuddy Player Community, you can communicate and play with the best matches.`,
            }}
            isEducationSite={false}
            category={true}
            onModalClose={() => setQuotesModalOpen(false)}
          />
          <div className={css.content}>
            <div className={css.locationArea}>
              {/* <LeftBar></LeftBar> */}

              <div className={css.locationHeader}>
                <section className={css.topSection}>
                  <div className={css.topHeadContainer}>
                    <div className={css.quoteWrapper}>
                      <h4>Get Matched With Players Matching Your Profile.</h4>
                      <p>
                        Want to find a perfect Match? Create your match and get matched with
                        players.
                      </p>
                      <div className={css.quoteWrapper}>
                        {/* <p><FormattedMessage id="CategoriesPage.topHeadSubTitle" /></p> */}

                        <div className={classNames(css.inputWrapper)}>
                          <div
                            className={css.wrapperInput}
                            onClick={() => setQuotesModalOpen(true)}
                          >
                            <input disabled type="text" value="Tell Us what you are looking for" />
                            <button className={css.btnQuote}>Create a Match</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={css.heroImage}>
                      <div className={css.overlayBg}></div>

                      <img
                        src="https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1696927975/probuddy/static/images/CategoriesPage/Pickleball/3_vsp7s8.png"
                        alt="heroImage"
                      />
                    </div>
                  </div>
                </section>
                {/* <HorizontalScroll
                  isSmallScrollArrow={false}
                  className={classNames(css.locationListContainer, {
                    [css.categoryListContainerWithoutArrow]:
                      typeof window !== 'undefined' ? window.screen.width <= 425 : true,
                  })}
                >
                  {cityData.map(city =>
                    !isEducationSite ? (
                      <div
                        key={city.key}
                        className={css.locationWrapperTop}
                        onClick={() => setSelectedLocation(city.key)}
                      >
                        <div className={css.locationImgWrap}>
                          <img src={city.cityImage} height="100px" width="100px" alt={city.label} />
                        </div>
                        <div
                          className={classNames(
                            css.locationTextWrap,
                            jobsSearch.location === city.key && css.selected
                          )}
                        >
                          {city.label}
                        </div>
                      </div>
                    ) : (
                      <div
                        key={city.key}
                        className={css.locationWrapperTop}
                        onClick={() => setSelectedLocation(city.key)}
                      >
                        <div className={css.locationImgWrap}>
                          <img src={city.cityImage} height="100px" width="100px" alt={city.label} />
                        </div>
                        <div
                          className={classNames(
                            css.locationTextWrap,
                            jobsSearch.location === city.key && css.selected
                          )}
                        >
                          {city.label}
                        </div>
                      </div>
                    )
                  )}
                </HorizontalScroll> */}
              </div>
            </div>

            <MatchActivityForm
              jobsBoardListing={jobsBoardListing}
              jobsPagination={jobsPagination}
              handlePage={handlePage}
              intl={intl}
              loading={loading}
              isEducationSite={isEducationSite}
              history={history}
              prop={props}
              currentUser={currentUser}
              filterSearch={jobsSearch.keywords}
            />
          </div>
        </LayoutWrapperMain>
        {!isMobileLayout ? (
          <LayoutWrapperFooter>
            <Footer isEducationSite={isEducationSite} />
          </LayoutWrapperFooter>
        ) : (
          <LayoutWrapperFooter
            className={classNames(css.layoutFooter, {
              [css.activeleftbarFooter]: activeFooterTab === 'leftbar',
              [css.createFooterTab]:
                activeFooterTab === 'create' || activeFooterTab === 'activitySearch',
            })}
          >
            {!!isMobileLayout && !(activeFooterTab === 'leftbar') && (
              <FooterBottomMenu
                changeActivieFooterTab={value => {
                  setActiveFooterTab(value);
                }}
                history={history}
                isEducationSite={isEducationSite}
              />
            )}
          </LayoutWrapperFooter>
        )}
      </LayoutSingleColumn>

      <Modal
        id="MatchActivity.shareForm"
        className={css.shareFormModal}
        isOpen={isOpenShareForm}
        onClose={() => setIsOpenShareForm(false)}
        onManageDisableScrolling={() => {}}
      >
        <ShareForm url={url} />
      </Modal>
    </Page>
  );
};

const MatchActivity = compose(withRouter, injectIntl, withViewport)(MatchActivityComponent);

export default memo(MatchActivity);
