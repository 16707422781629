import React from 'react';
import PropTypes from 'prop-types';
import css from './ListingResults.css';
import { ListingCard } from 'components';
import { propTypes } from 'util/types';

function ListingResults({ listings, setActiveListing, allListingReview={} }) {
  // Panel width relative to the viewport
  const panelMediumWidth = 50;
  const panelLargeWidth = 62.5;
  const cardRenderSizes = [
    '(max-width: 767px) 100vw',
    `(max-width: 1023px) ${panelMediumWidth}vw`,
    `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
    `${panelLargeWidth / 3}vw`,
  ].join(', ');

  return (
    <div className={css.listingCards}>
      {listings.map(l => (
        <ListingCard
          className={css.listingCard}
          key={l.id.uuid}
          listing={l}
          renderSizes={cardRenderSizes}
          setActiveListing={setActiveListing}
          reviews={allListingReview[l.id.uuid] || []}
        />
      ))}
    </div>
  );
}

ListingResults.propTypes = {
  listings: PropTypes.arrayOf(propTypes.listing),
  setActiveListing: PropTypes.func,
};

export default ListingResults;
