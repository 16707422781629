import React, { useState } from 'react';
import { withViewport } from 'util/contextHelpers';
import PropTypes from 'prop-types';
import { getExtensionFromUrl } from 'util/urlHelpers';
import cns from 'classnames';
import css from './WhatYouCanExpectItem.css';

function WhatYouCanExpectItem(props) {
  const { item, reverse = false, withBackground } = props;
  const [isMoreCliked, setMoreCliked] = useState(false);
  let modified = item.text;
  const onClickedMoreHandler = () => {
    setMoreCliked(!isMoreCliked);
  };

  if (item && item.text?.length > 500) {
    modified = item.text.substring(0, 500) + '...';
  }

  // function checkScroll() {
  //   var videos = document.getElementById("expectationVideo");
  //   if (videos) {
  //     var fraction = 0.8; // Play when 80% of the player is visible.

  //     for (var i = 0; i < videos.length; i++) {

  //       var video = videos[i];

  //       var x = video.offsetLeft, y = video.offsetTop, w = video.offsetWidth, h = video.offsetHeight, r = x + w, //right
  //         b = y + h, //bottom
  //         visibleX, visibleY, visible;

  //       visibleX = Math.max(0, Math.min(w, window.pageXOffset + window.innerWidth - x, r - window.pageXOffset));
  //       visibleY = Math.max(0, Math.min(h, window.pageYOffset + window.innerHeight - y, b - window.pageYOffset));

  //       visible = visibleX * visibleY / (w * h);

  //       if (visible > fraction) {
  //         video.play();
  //       } else {
  //         video.pause();
  //       }

  //     }
  //   }
  // }

  // useEffect(() => {
  //   window.addEventListener('scroll', checkScroll, false);
  //   window.addEventListener('resize', checkScroll, false);
  // })

  // const onVideoLoadStart = e => {
  //   const videoElem = e.target;
  //   videoElem.volume = 0;
  //   videoElem.autoplay = true;
  //   // const isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;
  //   // if (isMobileLayout) {
  //   //   videoElem.volume = 0;
  //   //   videoElem.autoplay = true;
  //   // }
  // };
  const classes = cns(css.expectationItem, {
    [css.reverseSection]: reverse,
  });
  //id={"expectationVideo"} onLoadStart={onVideoLoadStart}
  return (
    <div className={classes}>
      <div className={css.imageWrap}>
        {item.asset &&
          (item.asset.type === 'video' ? (
            <video src={item.asset.url} controls loop autoPlay muted playsInline width="100%">
              <source src={item.asset.url} type={`video/${getExtensionFromUrl(item.asset.url)}`} />
            </video>
          ) : (
            <img src={item.asset.url} alt="" />
          ))}
      </div>
      <div
        className={cns(css.DetailContainer, {
          [css.DetailContainerWithBackground]: withBackground,
        })}
      >
        <div className={css.expectDetailWrapper}>
          <pre
            className={cns(css.paragraphDescription, {
              [css.paragraphDescriptionWithBackground]: withBackground,
            })}
          >
            {isMoreCliked ? item.text : modified}
            {item.text?.length > 500 && (
              <span className={css.paragraphButton} onClick={onClickedMoreHandler}>
                &nbsp; {!isMoreCliked ? 'See More' : 'See Less'}
              </span>
            )}
          </pre>
        </div>
      </div>
    </div>
  );
}

WhatYouCanExpectItem.propTypes = {
  item: PropTypes.object,
};

export default withViewport(WhatYouCanExpectItem);
