import moment from 'moment';
import { types as sdkTypes } from '../../util/sdkLoader';
const { LatLng, LatLngBounds } = sdkTypes;

export const MAX_MOBILE_SCREEN_WIDTH = 1024;
export const identity = v => v;
export const DEFAULT_DATE = {
  start: moment()
    .set({ hour: 0, minute: 0, second: 0 })
    .toISOString(),
  end: moment()
    .set({ hour: 23, minute: 59, second: 59 })
    .toISOString(),
};

export const defaultLocation = {
  search: 'Singapore',
  selectedPlace: {
    address: 'Singapore',
    bounds: new LatLngBounds(
      new LatLng(1.4110994, 103.9577613),
      new LatLng(1.2642103, 103.6055395)
    ),
  },
};
export const listingSwitches = [
  // {
  //   label: 'Players Community',
  //   id: 'player-profile',
  //   image:
  //     'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1699504950/probuddy/static/images/HomePage/playerprofile_sonwhn.png',
  // },
  {
    label: 'Book a Court',
    id: 'facility',
    image:
      'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1699504959/probuddy/static/images/HomePage/facilities_txmjxz.png',
  },
  {
    label: `Pro's`,
    id: 'freelancer',
    image:
      'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1699504965/probuddy/static/images/HomePage/pro_os5z56.png',
  },
  {
    label: 'Academies',
    id: 'Company,company',
    image:
      'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1699504992/probuddy/static/images/HomePage/company_tscd6g.png',
  },
  {
    label: 'Classes',
    id: 'class',
    image:
      'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1699505019/probuddy/static/images/HomePage/classes_lbhngh.png',
  },
  // {
  //   label: `Shop`,
  //   id: 'product',
  //   image:
  //     'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1699504974/probuddy/static/images/HomePage/shop_xedlq5.png',
  // },
  // {
  //   label: `Events`,
  //   id: 'event',
  //   image:
  //     'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1699504983/probuddy/static/images/HomePage/event1_dwwwmy.jpg',
  // },
];

export const ageSwitches = [
  {
    label: 'Adult',
    id: 'Adult',
    image:
      'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1700196635/probuddy/static/images/HomePage/adult_ctw5m3.jpg',
  },

  {
    label: `Kids`,
    id: 'Kids',
    image:
      'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1700196643/probuddy/static/images/HomePage/kids_bzokya.jpg',
  },
];

export const levelSwitches = [
  {
    label: 'Beginner',
    id: 'Beginner',
  },
  {
    label: `Intermediate`,
    id: 'Intermediate',
  },

  {
    label: `Advanced`,
    id: 'Advanced',
  },
  {
    label: `Tournament Players`,
    id: 'Tournament Players',
  },
];

export const levelSwitchesKids = [
  {
    label: '4-6 years',
    id: '4-6 years',
  },
  {
    label: `6-9 years`,
    id: '6-9 years',
  },

  {
    label: `8-12 years`,
    id: '8-12 years',
  },
  {
    label: `10-14 years`,
    id: '10-14 years',
  },
  {
    label: `14-18 years`,
    id: '14-18 years',
  },
];
