export const IMAGES = {
  ascottLogo:
    'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1649762620/probuddy/LandingPages/ascottLogo_oyno7a.png',
  standartCharteredLogo:
    'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1649762616/probuddy/LandingPages/standartCharteredLogo_k1n2sz.png',
  googleLogo:
    'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1649762615/probuddy/LandingPages/googleLogo_ggmaez.png',
  shangriLogo:
    'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1649762616/probuddy/LandingPages/shangriLogo_hjkcmp.png',
  facebookImage:
    'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675929674/probuddy/static/probuddyThumbnail_jggcdk_dsnv17.svg',
  twitterImage:
    'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675936121/probuddy/static/probuddyThumbnail-twitter-600X314_pjsjjw.png',
  IconPostRequestMobile:
    'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1649762619/probuddy/LandingPages/icons/postRequestIcon_s4afzm.png',
  joinSession1:
    'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1649762616/probuddy/LandingPages/joinSession1_gxrq3b.jpg',
  joinSession2:
    'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1649762616/probuddy/LandingPages/joinSession2_h3lv0c.jpg',
  joinSession3:
    'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1649762614/probuddy/LandingPages/joinSession3_omxhct.jpg',
  joinSession4:
    'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1649762613/probuddy/LandingPages/joinSession4_rbiise.jpg',
  joinSession5:
    'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1649762613/probuddy/LandingPages/joinSession5_ne8lpu.jpg',
  joinSession6:
    'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1649762613/probuddy/LandingPages/joinSession6_pmhtji.jpg',
  selectIcon:
    'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1649762618/probuddy/LandingPages/icons/selectIcon_d6x6qs.png',
  cartIcon:
    'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1649762618/probuddy/LandingPages/icons/cartIcon_eogyz9.png',
  starIcon:
    'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1649762619/probuddy/LandingPages/icons/starIcon_d22ax9.png',
  freelancersAndStudios:
    'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1649762615/probuddy/LandingPages/freelancersAndStudios_emefj8.png',
  whyUs:
    'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1649843243/probuddy/LandingPages/WhyUs_w71s7o.jpg',
  section_WhyUs:
    'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1649843243/probuddy/LandingPages/SectionWhyUs_ofrf3l.png',
  postRequestGif:
    'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1649843243/probuddy/LandingPages/landingpage_postRequestGif_mt0knd.gif',
  postRequestGifBG:
    'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1649843243/probuddy/LandingPages/landingpage_postRequestGifBackground_jotzxd.png',
  comunity: 'https://res.cloudinary.com/movementbuddy/image/upload/v1652870855/Comunity_swlc2y.png',
  comunityMobile:
    'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1649845052/probuddy/becomeVendorPage/ComunityMobile_qfzc7a.png',
  homePageBanner:
    'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1649843243/probuddy/LandingPages/HomePageBanner_xmtfz5.png',
  topbarMobileBottom:
    'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1649843242/probuddy/LandingPages/topbarMobile-bottom_hu9ymt.png',
  gymPass:
    'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1649762615/probuddy/LandingPages/gymPass_jvedsl.jpg',
  fitnessStudio:
    'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1649762614/probuddy/LandingPages/fitnessStudios_ykgqwj.jpg',
  tennisCourts:
    'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1649762616/probuddy/LandingPages/tennisCourts_n03dfk.jpg',
  ImageVendorPortal:
    'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1649768305/probuddy/menuImages/venderportal_rr2dza.png',
  ImageVendorPortal2:
    'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1649768306/probuddy/menuImages/venderportal2_ncrx6s.png',
  ImageClientPortal:
    'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1649768304/probuddy/menuImages/clientprotal_yhs6dl.png',
  ImageClientPortal2:
    'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1649768304/probuddy/menuImages/clientprotal2_jytmx5.png',
  ImagebecomeVendorPortal1:
    'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1649768302/probuddy/menuImages/becomeVendorPortal1_q3qc0c.png',
  ImagebecomeVendorPortal2:
    'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1649768303/probuddy/menuImages/becomeVendorPortal2_xnmvmf.png',
  newIcon:
    'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1649768286/probuddy/newIcon_agvfoi.png',
  landingPage_Topbar:
    'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1650000088/probuddy/LandingPages/landingPage_topbar_bzdlcl.png',
  landingPage_Bottombar:
    'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1650000088/probuddy/LandingPages/landingPage_bottomBar_tiiflx.png',
};
