import React, { useMemo } from 'react';
import { uid } from 'react-uid';
import cns from 'classnames';
import PropTypes from 'prop-types';
import moment from 'moment';
import { propTypes } from 'util/types';
import TableEntry from 'containers/BookingPlanPage/components/TableEntry/TableEntry';
import css from './Table.css';
const WEEKS = 8;

const renderWeeks = () => {
  const arr = [];
  
  for (let i = 0; i < WEEKS; i++) {
    const today = moment();
    const from_date = today.startOf('week');
    // let a = from_date.add(i, 'weeks').format('YYYY MM DD')
    let elem = null;
    i === 0
      ? (elem = (
          <div key="w1" className={cns(css.cell, css.weekMarkCol)}>
            {/* W1 */}
            {
              from_date.add(1, 'days').format('YY/MM/DD')
            }            
          </div>
        ))
      : (elem = (
          <div key={uid('W', i + 1)} className={cns(css.cell, css.weekMarkCol)}>
            {/* {`W${i + 1}`} */}
          {
            from_date.add(1, 'days').add(i, 'weeks').format('YY/MM/DD')
          }  
          </div>
        ));
    arr.push(elem);
  }
  return arr;
};

function Table({ data, isCollapsedAll }) {
  const entries = useMemo(() => {
    if (!data) return null;

    return (
      data
        // group items by weekday in separate arrays
        .reduce((acc, item) => {
          const index = acc.findIndex(subArr => {
            return subArr.some(subItem => subItem.weekday === item.weekday);
          });

          index >= 0 ? acc[index].push(item) : acc.push([item]);
          return acc;
        }, [])
        .map(arrayWithItems => {
          const sortedItems = arrayWithItems.sort((a, b) => {
            const aStart = a.bookingStart.split(':')[0];
            const bStart = b.bookingStart.split(':')[0];
            return aStart - bStart;
          });
          const weekday = sortedItems[0].weekday;
          return { weekday, entries: sortedItems };
        })
    );
  }, [data]);
  return (
    <div className={css.wrapper}>
      <div className={css.table}>
        <div className={css.head}>
          <div className={cns(css.cell, css.nameCol)}>Name</div>
          <div className={cns(css.cell, css.timeCol)}>Time</div>
          <div className={cns(css.cell, css.listingNameCol)}>Listing Name</div>
          <div className={cns(css.cell, css.membershipCol)}>Membership</div>
          {renderWeeks()}
        </div>

        {entries.map(entry => (
          <TableEntry key={uid(entry)} entry={entry} isCollapsedAll={isCollapsedAll} />
        ))}
      </div>
    </div>
  );
}

Table.propTypes = {
  data: PropTypes.arrayOf(propTypes.bookingPlanItem),
  isCollapsedAll: PropTypes.bool,
};

export default Table;
