import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form as FinalForm, Field } from 'react-final-form';
import { intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import Autosuggest from 'react-autosuggest';
import debounce from 'lodash/debounce';
import {
  Form,
  NewLocationAutocompleteInput,
  OutSideCloseCard,
  ResponsiveImage,
} from '../../components';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as HeaderSearchIcon } from '../../assets/HomePage/HeaderSearchIcon.svg';
import HeaderSearchIconImg from '../../assets/HomePage/HeaderSearchIcon.svg';
import { startCase, toLower } from 'lodash';
import Cross_Icon from '../../assets/HomePage/Cross_Icon.svg';
import Search_Icon from '../../assets/HomePage/Search_Icon.svg';
import searchBtn from '../../assets/HomePage/searchBtn.svg';
import {
  tabsList,
  checkboxSvg,
  onSuggestionsClearRequested,
  shouldRenderSuggestions,
  renderSectionTitle,
  getSectionSuggestions,
  onSuggestionsFetchRequested,
  handleQueryFocusout,
  educationTab,
} from './HomePageLocationSearchForm.data';
import config from 'config';
import { getCategoryImage } from 'containers/CategoriesPage/CategoriesPage.data';
import css from './HomePageLocationSearchForm.css';
import { TennisTab } from 'containers/HeaderContainer/HeaderTabs';

const identity = v => v;

class HomePageLocationSearchFormComponent extends Component {
  constructor(props) {
    super(props);
    this.searchInput = null;
    this.state = {
      activity: '',
      searchActivity: props.initialValues.searchActivity
        ? props.initialValues.searchActivity
        : null,
      showLocation: false,
      showActivity: false,
      showRating: false,
      selectedListing: props.initialValues.selectedListing
        ? props.initialValues.selectedListing
        : '',
      availabilityQueryParams: {},
      searchLocation: props.initialValues.searchLocation
        ? props.initialValues.searchLocation
        : {
            predictions: [],
            search: '',
            selectedPlace: {
              address: '',
            },
          },
      isRatingModalRight: false,
      isActivityModalRight: false,
    };
    this.handleQueryFocus = this.handleQueryFocus.bind(this);
    this.onSuggestionSelected = this.onSuggestionSelected.bind(this);
    this.onChange = this.onChange.bind(this);
    this.clearActivity = this.clearActivity.bind(this);
    this.renderSuggestion = this.renderSuggestion.bind(this);
    this.clearAll = this.clearAll.bind(this);
    this.ratingModalRef = React.createRef();
    this.activityBtnRef = React.createRef();
  }

  componentDidMount() {
    const { searchActivity, searchLocation, selectedListing } = this.props.initialValues;
    this.setState({
      activity: searchActivity || '',
      searchActivity: searchActivity || '',
      selectedListing: selectedListing || '',
      searchLocation: searchLocation || {
        predictions: [],
        search: '',
        selectedPlace: {
          address: '',
        },
      },
      showActivity: this.props.activeSearchTab,
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.showActivityPopup !== prevProps.showActivityPopup) {
      this.toggleActivity();
    }
    const { searchActivity, searchLocation } = this.props.initialValues;
    if (searchActivity !== prevProps.initialValues.searchActivity) {
      this.setState({
        activity: searchActivity || '',
        searchActivity: searchActivity || '',
      });
    }
    if (searchLocation !== prevProps.initialValues.searchLocation) {
      this.setState({
        searchLocation: searchLocation || {
          predictions: [],
          search: '',
          selectedPlace: {
            address: '',
          },
        },
      });
    }
  }

  clearActivity() {
    this.setState({
      searchActivity: null,
      activity: '',
    });
  }

  clearAll() {
    this.setState({
      searchLocation: {
        predictions: [],
        search: '',
        selectedPlace: {
          address: '',
        },
      },
      searchActivity: null,
      activity: '',
      selectedListing: '',
      showLocation: false,
    });
    this.props.clearAll({
      searchActivity: null,
      searchLocation: {
        predictions: [],
        search: '',
        selectedPlace: {
          address: '',
        },
      },
      selectedListing: '',
    });
  }

  getSuggestionValue(suggestion) {
    return suggestion.keyword
      ? suggestion.label.split(' | ')[0]
      : suggestion.label
      ? suggestion.label
      : suggestion.title;
  }

  renderSuggestion(suggestion) {
    const subCategoryImageName = suggestion.label
      ? startCase(toLower(suggestion.label)).replace(' ', '_')
      : '';
    const isImgAvail = this.props.isEducationSite ? getCategoryImage(suggestion.key) : false;

    return (
      <div className={css.suggestionContainer}>
        {!!isImgAvail ? (
          <img
            src={isImgAvail}
            width="38px"
            height="38px"
            className={css.suggestionImg}
            alt={' '}
          />
        ) : suggestion?.image && suggestion?.image[0] ? (
          <ResponsiveImage
            className={css.suggestionImg}
            style={{ width: '38px', height: '38px' }}
            src={suggestion?.image[0]}
            image={suggestion?.image[0]}
            variants={['scaled-small', 'scaled-small2x']}
            // alt={suggestion.title}
          />
        ) : (
          <img
            src={`/static/promoPage/${subCategoryImageName}.jpg`}
            width="38px"
            height="38px"
            className={css.suggestionImg}
            onError={e => {
              e.target.onerror = null;
              e.target.src = '/static/promoPage/Tennis.jpg';
            }}
            alt="suggestion"
          />
        )}
        <div className={css.suggestionDetailsContainer}>
          <div className={css.suggestionTitle}>
            {suggestion.label && suggestion.label.title
              ? suggestion.label.title
              : suggestion.title
              ? suggestion.title
              : suggestion.label}
          </div>
          <div className={css.suggestionDetailsText}>
            {suggestion?.details || suggestion?.address}
          </div>
        </div>
      </div>
    );
  }

  handleQueryFocus(e) {
    this.focusedSearchInput = e?.target;
    e.target.focus();
    if (e && e.target) {
      e.target.closest("div[class*='searchQuery']").classList.add('active-search-query');
    }
  }

  getSuggestions(value, suggestedListings) {
    const escapeRegexCharacters = str => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const escapedValue = escapeRegexCharacters(
      (value?.key
        ? value.key
        : value?.title
        ? value.title
        : value.label
        ? value.label
        : value
      )?.trim()
    );

    const regex = new RegExp(escapedValue, 'i');

    const PromoCategories = this.props.isEducationSite
      ? config.custom.subCategoriesFilterOptionsEducational
      : this.props.isTennisSite
      ? config.custom.subCategoriesFilterOptionsTennis
      : config.custom.PromoCategories;

    const matchedPromoCategories = PromoCategories.filter(c => regex.test(c.label));

    // const suggestedListing1 = suggestedListings.map((l)=> {id:suggestions.id,title:s.attributes.title});

    const suggetListing =
      suggestedListings?.length > 60
        ? suggestedListings.filter(c => regex.test(c.title))
        : suggestedListings;

    // this.props.onSuggetListing(suggetListing);

    const suggestions = [];
    if (value !== '') {
      suggestions.push({
        title: '',
        options: [{ keyword: true, label: value?.label ? value.label : value }],
      });
      // if (!this.props.searchPage) {
      if (matchedPromoCategories && matchedPromoCategories.length > 0) {
        suggestions.push({
          title: '',
          options: matchedPromoCategories,
        });
      }
      // }

      if (suggestedListings && suggestedListings.length > 0) {
        if (!!suggetListing.length) {
          suggestions.push({
            title: '',
            options: suggetListing,
          });
        }
      }
    } else if (value === '') {
      if (PromoCategories && PromoCategories.length > 0) {
        suggestions.push({
          title: '',
          options: PromoCategories,
        });
      }
    }

    return suggestions;
  }

  toggleActivity = () => {
    const { showActivity, showLocation, showRating } = this.state;
    this.props.changeOpacity(!showActivity || showLocation || showRating);
    this.setState({ showActivity: !showActivity });
    const headerConatinerLeft = this.props.currentOfSet;
    const ratingButtonLeft = this.activityBtnRef.current.offsetLeft;
    // this.props.getSuggestedListing();
    if (
      !this.props.isMobileLayout &&
      window.innerWidth - (headerConatinerLeft + ratingButtonLeft) <= 750
    ) {
      this.setState({ isActivityModalRight: true });
    } else if (this.props.isMobileLayout) {
      this.setState({ isActivityModalRight: true });
    } else {
      this.setState({ isActivityModalRight: false });
    }
  };

  toggleLocation = () => {
    const { showLocation, showActivity, showRating } = this.state;
    this.props.changeOpacity(showActivity || !showLocation || showRating);
    this.setState({ showLocation: !showLocation });
  };

  toggleRating = () => {
    const { showRating, showLocation, showActivity } = this.state;
    this.props.changeOpacity(showActivity || showLocation || !showRating);
    this.setState({ showRating: !showRating });
  };
  // const headerConatinerLeft = this.props.currentOfSet;
  // const ratingButtonLeft = this.ratingModalRef?.current?.offsetLeft;
  // if (
  //   !this.props.isMobileLayout &&
  //   window.innerWidth - (headerConatinerLeft + ratingButtonLeft) <= 765
  // ) {
  //   this.setState({ isRatingModalRight: true });
  // } else if (this.props.isMobileLayout) {
  //   this.setState({ isRatingModalRight: true });
  // } else {
  //   this.setState({ isRatingModalRight: false });
  // }

  handleSearchChange = debounce(() => {
    if (this.searchInput) {
      this.searchInput.blur();
    }
  }, 1000);

  onChange(location) {
    const defaultLocation = {
      search: 'Singapore',
      selectedPlace: {
        address: 'Singapore',
        bounds: {
          ne: { _sdkType: 'LatLng', lat: 1.4110994, lng: 103.9577613 },
          sw: { _sdkType: 'LatLng', lat: 1.2642103, lng: 103.6055395 },
          _sdkType: 'LatLngBounds',
        },
      },
    };
    if (location.selectedPlace) {
      this.setState({ searchLocation: location.search ? location : defaultLocation });
      this.handleSearchChange();
      this.toggleLocation();
      this.toggleRating();
    } else {
      this.setState({ searchLocation: location });
    }
  }

  handleProviderQueryChange = (event, { newValue, method }) => {
    this.setState({
      activity: newValue,
    });
    document.getElementById('name-category-query').focus();
    this.props.handleProviderQueryChange(newValue);
    // this.searchListings(newValue);
  };

  onSuggestionSelected(event, { suggestion, suggestionValue }) {
    this.setState({
      searchActivity: suggestion,
      activity: suggestionValue,
    });
    if (suggestion.id && suggestion.title) {
      this.props.onKeywordSeleceted(suggestion);
    } else {
      document.getElementById('name-category-query').focus();
      // this.searchListings(suggestionValue);
      this.toggleActivity();
      this.toggleLocation();
    }
  }

  onChangeRating(value) {
    const { selectedListing } = this.state;
    this.setState({ selectedListing: selectedListing === value ? '' : value });
  }

  render() {
    const {
      activity,
      showActivity,
      showLocation,
      showRating,
      selectedListing,
      searchLocation,
      // isRatingModalRight,
      isActivityModalRight,
    } = this.state;
    const { intl, suggestedListings, isEducationSite, isTennisSite } = this.props;

    const getTabList = isEducationSite ? educationTab : isTennisSite ? TennisTab : tabsList;

    const suggestions = this.getSuggestions(activity, suggestedListings);
    // const isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;
    // const FILTER_DROPDOWN_OFFSET = isMobileLayout ? -35 : -14;
    const nameOrCategoryPlaceholder = intl.formatMessage({
      id: 'HomePageSectionSearch.nameOrCategoryQueryPlaceholder',
    });

    const inputProps = {
      placeholder: nameOrCategoryPlaceholder,
      value: activity.label ? activity.label : activity.title ? activity.title : activity,
      onChange: this.handleProviderQueryChange,
      onClick: this.handleQueryFocus,
      onBlur: handleQueryFocusout,
      autoComplete: 'on',
      id: 'name-category-query',
      ref: function(inputEl) {
        this.searchInput = inputEl;
      }.bind(this),
    };

    const activityContainer = (
      <div className={classNames(css.searchQuery)}>
        <img src={Search_Icon} className={css.activeSearchIcon} alt={'Activity Search'} />
        <Autosuggest
          suggestions={suggestions}
          shouldRenderSuggestions={shouldRenderSuggestions}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          getSuggestionValue={this.getSuggestionValue}
          renderSuggestion={this.renderSuggestion}
          renderSectionTitle={renderSectionTitle}
          multiSection={true}
          getSectionSuggestions={getSectionSuggestions}
          onSuggestionSelected={this.onSuggestionSelected}
          focusInputOnSuggestionClick={true}
          alwaysRenderSuggestions={true}
          inputProps={inputProps}
          ref={() => {
            document.getElementById('name-category-query').focus();
          }}
        />
      </div>
    );

    const ratingContainer = (
      <div className={classNames(css.showRatingContainer)}>
        <div className={css.showRatingTabContainer}>
          {getTabList.map(key => (
            <div
              key={key.id}
              className={classNames(css.mobileTabWrapper, {
                [css.selectedTab]: this.props.activeFilterTab === key.id,
              })}
              onClick={() => {
                this.props.onTabChangeSearch(key);
                this.onChangeRating(key.id);
              }}
            >
              <div className={css.checkMark}>{checkboxSvg}</div>
              <div className={css.listingTypeName}>{key.name}</div>
              <img className={css.tabIcon} src={key.image} alt={' '} />
            </div>
          ))}
        </div>
      </div>
    );

    return (
      <FinalForm
        {...this.props}
        render={formRenderProps => {
          const {
            rootClassName,
            className,
            desktopInputRoot,
            intl,
            isMobile,
            searchActivity,
          } = formRenderProps;

          const classes = classNames(rootClassName, className);
          const desktopInputRootClass = desktopInputRoot || css.desktopInputRoot;

          // Allow form submit only when the place has changed
          const preventFormSubmit = e => e.preventDefault();
          return (
            <Form className={classes} onSubmit={preventFormSubmit}>
              <Field
                name="location"
                format={identity}
                render={({ input, meta }) => {
                  const { onChange, ...restInput } = input;

                  // Merge the standard onChange function with custom behaviur. A better solution would
                  // be to use the FormSpy component from Final Form and pass this.onChange to the
                  // onChange prop but that breaks due to insufficient subscription handling.
                  // See: https://github.com/final-form/react-final-form/issues/159
                  const searchOnChange = value => {
                    onChange(value);
                    this.onChange(value);
                  };

                  const searchInput = {
                    ...restInput,
                    onChange: searchOnChange,
                    value: searchLocation,
                  };
                  return (
                    <>
                      {
                        <>
                          <div
                            className={
                              this.props.isMobileLayout
                                ? css.mobileInputRoot
                                : desktopInputRootClass
                            }
                          >
                            <div className={css.ratingTitle}>
                              <button
                                className={classNames(css.ratingHeader, {
                                  [css.activityHeader]: !showActivity,
                                  [css.activityRatingActive]: showActivity,
                                  [css.activitySelectedLocation]: showLocation,
                                  [css.activitySelectedRating]: showRating,
                                })}
                                onClick={this.toggleActivity}
                                ref={this.activityBtnRef}
                              >
                                {showActivity ? (
                                  <FormattedMessage id="HomePageSectionSearch.activityActive" />
                                ) : (
                                  <FormattedMessage id="HomePageSectionSearch.activity" />
                                )}
                                <div className={css.ratingContentContainer}>
                                  <span className={css.ratingContent}>
                                    {searchActivity
                                      ? searchActivity.label
                                      : activity.label
                                      ? activity.label
                                      : activity.title
                                      ? activity.title
                                      : activity || (this.props.isMobileLayout ? 'By Names' : '')}
                                  </span>
                                  {this.state.searchActivity && (
                                    <div
                                      className={css.clearActivityButton}
                                      onClick={this.clearActivity}
                                    >
                                      <img
                                        src={Cross_Icon}
                                        alt={'Cross_Icon'}
                                        width="10px"
                                        height="10px"
                                      />
                                    </div>
                                  )}
                                </div>
                              </button>
                            </div>
                            <OutSideCloseCard
                              show={showActivity}
                              onClickOutside={this.toggleActivity}
                              className={classNames(css.showActivityContainer, {
                                [css.singleActivityContainer]:
                                  suggestions.length && suggestions[0].options.length <= 3,
                                [css.activityContainerRight]:
                                  isActivityModalRight &&
                                  suggestions.length &&
                                  suggestions[0].options.length > 3,
                              })}
                            >
                              {activityContainer}
                            </OutSideCloseCard>
                          </div>
                          {/* <NewLocationAutocompleteInput
                            className={isMobile ? css.mobileInputRoot : desktopInputRootClass}
                            iconClassName={isMobile ? css.mobileIcon : css.desktopIcon}
                            inputClassName={isMobile ? css.mobileInput : css.desktopInput}
                            predictionsClassName={
                              isMobile ? css.mobilePredictions : css.desktopPredictions
                            }
                            predictionsAttributionClassName={
                              isMobile ? css.mobilePredictionsAttribution : null
                            }
                            placeholder={`${intl.formatMessage({
                              id: 'HomePageSectionSearch.nameQueryPlaceholder',
                            })}...`}
                            showLocationPopup={this.state.showLocation}
                            changeOpacity={this.props.changeOpacity}
                            isMobileLayout={this.props.isMobileLayout}
                            closeOnBlur={!isMobile}
                            inputRef={node => {
                              this.searchInput = node;
                            }}
                            input={searchInput}
                            meta={meta}
                            toggleLocation={this.toggleLocation}
                          /> */}

                          {!this.props.isMobileLayout && (
                            <button
                              className={css.searchIcon}
                              type="submit"
                              onClick={() =>
                                this.props.onSubmit({
                                  searchActivity: this.state.searchActivity
                                    ? this.state.searchActivity
                                    : activity,
                                  searchLocation: this.state.searchLocation,
                                  selectedListing,
                                })
                              }
                            >
                              {isEducationSite ? (
                                <HeaderSearchIcon />
                              ) : (
                                <img src={HeaderSearchIconImg} alt={' '} />
                              )}
                              {/* <img src={HeaderSearchIcon} alt="HeaderSearchIcon" /> */}
                              {/* <HeaderSearchIcon /> */}
                            </button>
                          )}

                          {this.props.isMobileLayout && (
                            <div
                              className={
                                this.props.isMobileLayout
                                  ? css.mobileInputRoot
                                  : desktopInputRootClass
                              }
                            >
                              <div
                                className={classNames(css.ratingTitle, {
                                  [css.activityRatingActive]: showRating,
                                })}
                              >
                                <button
                                  className={classNames(
                                    css.ratingHeader,
                                    showRating && css.ratingHeaderButton
                                  )}
                                  onClick={this.toggleRating}
                                >
                                  <FormattedMessage id="HomePageSectionSearch.rating" />
                                  <div className={css.ratingContentContainer}>
                                    <span className={css.ratingContent}>
                                      {selectedListing ||
                                        (this.props.isMobileLayout ? 'Select Listing' : '')}
                                    </span>
                                  </div>
                                </button>
                              </div>
                              <OutSideCloseCard
                                show={showRating}
                                onClickOutside={this.toggleRating}
                                className={css.ratingPopup}
                              >
                                {ratingContainer}
                              </OutSideCloseCard>
                            </div>
                          )}
                        </>
                      }

                      {this.props.isMobileLayout && (
                        <div className={css.modalFooter}>
                          <div className={css.clearBtn} onClick={this.clearAll}>
                            <FormattedMessage id="HomePageSectionSearch.clearAll" />
                          </div>
                          <button
                            onClick={() =>
                              this.props.onSubmit({
                                searchActivity: this.state.searchActivity
                                  ? this.state.searchActivity
                                  : activity,
                                searchLocation: this.state.searchLocation,
                                selectedListing,
                              })
                            }
                          >
                            <img src={searchBtn} alt="searchbtn" />
                            <div>{'Search'}</div>
                          </button>
                        </div>
                      )}
                    </>
                  );
                }}
              />
            </Form>
          );
        }}
      />
    );
  }
}

const { func, string, bool } = PropTypes;

HomePageLocationSearchFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  desktopInputRoot: null,
  isMobile: false,
};

HomePageLocationSearchFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  desktopInputRoot: string,
  onSubmit: func.isRequired,
  isMobile: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const HomePageLocationSearchForm = injectIntl(HomePageLocationSearchFormComponent);

export default HomePageLocationSearchForm;
