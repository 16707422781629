import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import emailjs from '@emailjs/browser';
import { updateAcceptStatus, updateDeclineStatus } from './MyActivity.duck';
import css from './MyActivity.css';

function MyActivity() {
  const dispatch = useDispatch();
  const [acceptUserEmail, setAcceptUserEmail] = useState('');

  const fetchedData = useSelector(state => state.MyActivity.fetchedData);

  const handleAccept = async (id, joinUserId, joinUserMail) => {
    await dispatch(updateAcceptStatus(id, joinUserId, joinUserMail));
    setAcceptUserEmail(joinUserMail);
    sendAcceptMail(joinUserMail);
  };

  const sendAcceptMail = ({ email, ...values }) => {
    const templateParams = {
      to_mail: acceptUserEmail,
      from_mail: email,
    };
    if (process.env.REACT_APP_ENV === 'production') {
      emailjs
        .send('service_tn96fm5', 'template_haq5qxa', templateParams, 'p656r9_5pKAHF0ioU')
        .then()
        .catch(e => console.log('e-->', e));
    }
  };
  const handleDecline = async (id, joinUserId, joinUserMail) => {
    await dispatch(updateDeclineStatus(id, joinUserId));
    setAcceptUserEmail(joinUserMail);
  };

  return (
    <div className={css.myActivitySection}>
      <div className={css.myActivityMain}>
        {fetchedData.length !== 0 &&
          fetchedData.map(listing => {
            const { joinRequest } = listing.attributes.publicData;
            return joinRequest?.map(jionUser => {
              if (jionUser.showStatus === 'true') {
                return (
                  <div className={css.myActivityWrap} key={listing.id.uuid}>
                    <div className={css.myActivityDetail}>
                      <h5 className={css.myActivityHead}>
                        {listing.attributes.publicData.que_ans[0][0]}
                      </h5>
                      <h5 className={css.myActivitySubHead}>
                        {listing.attributes.publicData.que_ans[0][1]}
                      </h5>
                    </div>
                    <div className={css.myActivityDetail}>
                      <p className={css.myActivityText}>
                        {listing.attributes.publicData.que_ans[0][3]}
                      </p>
                      <p className={css.myActivityText}>
                        {listing.attributes.publicData.que_ans[0][2]}
                      </p>
                    </div>
                    <div className={css.myActivityDetail}>
                      <p className={css.myActivityText}>
                        {listing.attributes.publicData.date.day} -{' '}
                        {listing.attributes.publicData.date.month},{' '}
                        {listing.attributes.publicData.date.week}
                      </p>
                      <p className={css.myActivityText}>
                        {listing.attributes.publicData.time.start} -{' '}
                        {listing.attributes.publicData.time.end}
                      </p>
                    </div>
                    <div className={css.myActivityDetail}>
                      <p className={css.myActivityText}>Booked By:</p>
                      <p className={css.myActivityText}>{jionUser.userName}</p>
                    </div>
                    {/* <div className={css.myActivityDetail}>
                {jionUser.currentStatus === 'noActionTaken' && (
                  <p className={css.myActivityText}>{jionUser.currentStatus}</p>
                )}
              </div> */}
                    {jionUser.currentStatus === 'accepted' && (
                      <div className={css.myActivityDetail}>
                        <p className={css.myActivityAccepted}>{jionUser.currentStatus}</p>
                      </div>
                    )}
                    {jionUser.currentStatus === 'declined' && (
                      <div className={css.myActivityDetail}>
                        <p className={css.myActivityDeclined}>{jionUser.currentStatus}</p>
                      </div>
                    )}
                    {jionUser.currentStatus === 'noActionTaken' && (
                      <div className={`${css.myActivityDetail} ${css.myActivityButtons}`}>
                        <button
                          className={css.myActivityAcceptBtn}
                          onClick={() =>
                            handleAccept(listing.id.uuid, jionUser.userId, jionUser.userMail)
                          }
                        >
                          Accept
                        </button>

                        <button
                          className={css.myActivityDeclineBtn}
                          onClick={() =>
                            handleDecline(listing.id.uuid, jionUser.userId, jionUser.userMail)
                          }
                        >
                          Decline
                        </button>
                      </div>
                    )}
                  </div>
                );
              }
            });
          })}
        {fetchedData.length === 0 && <div>You have no join requests on your matches yet...</div>}
      </div>
    </div>
  );
}

export default MyActivity;
