const conversionTrack = conversionArgs => {
  if (typeof window !== 'undefined') {
    window.gtag('event', 'conversion', {
      send_to: conversionArgs,
    });
  }
};

export const listingPageTrack = () => {
  // Conversion Tracking for Listing Page
  conversionTrack('AW-11100519904/nHrPCJTX4qgYEOD7ka0p');
  console.log('Listing Page Tracked ---------------------');
};

export const locationChangeTrack = () => {
  // disabled
  // Conversion Tracking for Location Change
  // conversionTrack('AW-11100519904/yc-RCO2JsaYYEOD7ka0p');
  // console.log('Location Tracked ---------------------');
};

export const getQuoteTrack = () => {
  // Conversion Tracking for "Get a Quote" Button
  conversionTrack('AW-11100519904/aQcrCLbqragYEOD7ka0p');
};

export const whatsappLinkClickTrack = () => {
  // pause
  // Conversion Tracking for "Floating Whatsapp Link" Button
  // conversionTrack('AW-11100519904/co0FCKDh3qgYEOD7ka0p');
};

export default conversionTrack;
