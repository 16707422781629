import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from '../../util/reactIntl';
import { NamedLink, IconSpinner } from 'components';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { logout } from 'ducks/Auth.duck';
import Switch from 'react-switch';
import classNames from 'classnames';
import { addingSellerModeUserData } from 'containers/HeaderContainer/HeaderContainer.duck';
import { changingActivefooterTab } from 'ducks/user.duck';

import booking from '../../assets/HomePage/booking.svg';
import messages from '../../assets/HomePage/messages.svg';
import settings from '../../assets/HomePage/Settings.svg';
import packages from '../../assets/HomePage/packages.svg';
import CategoriesIcon from '../../assets/HomePage/new_categories.svg';
import plus from '../../assets/HomePage/plus.svg';
import matches from '../../assets/HomePage/tabIcon/matches.svg';
import hostedBy from '../../assets/HomePage/tabIcon/HomeIconBlue.svg';
import rewards from '../../assets/CompanyPage/yellowStar.svg';
import joinedMatch from '../../assets/HomePage/joinedMatchBlue.svg';
import addLising from '../../assets/SellerPage/ScheduleBlue.svg';
import myClient from '../../assets/HomePage/myClient.svg';
// import BlueRacketSports from '../../assets/HomePage/subCategory/BlueRacketSports.svg';
import myListing from '../../assets/HomePage/myListing.svg';
import Probuddylogo from '../../assets/ProbuddylogoBlue.svg';
import closeBtn from '../../assets/HomePage/closeBtn.svg';
import calendarIcon from '../../assets/WebAppPage/CalendarIcon.svg';
import education from '../../assets/education.svg';
import sportsSvg from '../../assets/sports.svg';
import topInCity from '../../assets/HomePage/TopInCity.svg';
import css from './LeftBar.css';

const LeftBar = props => {
  const dispatch = useDispatch();
  const {
    onClose,
    currentTab,
    onShowEnquiryTab,
    isEducationSite,
    isTennisSite,
    history,
    isMobileLayout,
    isOpenSearchBar,
    isScrolling,
    onLogoClick,
    showOpacity,
  } = props;
  const submitRequestText = <FormattedMessage id="HomePage.SendARequest" />;
  const settingsText = <FormattedMessage id="HomePage.settings" />;

  const { logoutInProgress, isAuthenticated } = useSelector(state => state.Auth);
  const {
    currentUser,
    currentUserHasCompanyOrFreelancer,
    currentUserHasTutorOrAcademy,
    currentUserSaleEnquiriesNotificationCount,
    currentUserNotificationCount,
    activeFooterTab,
  } = useSelector(state => state.user);

  const [isSellerMode, setIsSellerMode] = useState(
    currentUser?.attributes?.profile?.publicData?.sellerMode || false
  );

  useEffect(() => {
    return () => {
      activeFooterTab === 'leftbar' && dispatch(changingActivefooterTab(''));
      // Anything in here is fired on component unmount.
    };
  }, [activeFooterTab, dispatch]);

  useEffect(() => {
    setIsSellerMode(currentUser?.attributes?.profile?.publicData?.sellerMode || false);
  }, [currentUser]);

  const tabs = useMemo(
    () => [
      {
        title: <FormattedMessage id="HomePage.categories" />,
        name: isEducationSite
          ? 'EducationCategoriesPage'
          : isTennisSite
          ? 'TennisCategoriesPage'
          : 'CategoriesPage',
        src: CategoriesIcon,
        showTab: true,
      },
      {
        title: <FormattedMessage id="HomePage.booking" />,
        name: 'InboxPage',
        params: {
          tab: isSellerMode ? 'sales' : 'orders',
          state: isSellerMode ? 'pending' : 'upcoming',
        },
        src: booking,
        showTab: true,
        notification: !!currentUserNotificationCount,
      },
      {
        title: <FormattedMessage id="HomePage.packages" />,
        name: 'InboxPage',
        params: { tab: isSellerMode ? 'sellerPackages' : 'buyerPackages' },
        src: packages,
        showTab: true,
      },
      {
        title: <FormattedMessage id="HomePage.rewards" />,
        name: 'InboxPage',
        params: { tab: 'myRewards' },
        src: rewards,
        showTab: true,
      },
      // {
      //   title: <FormattedMessage id="HomePage.matches" />,
      //   name: 'InboxPage',
      //   params: { tab: 'myMatch' },
      //   src: matches,
      //   showTab: true,
      // },
      // {
      //   title: <FormattedMessage id="HomePage.hostedMatches" />,
      //   name: 'InboxPage',
      //   params: { tab: 'hostedMatches' },
      //   src: hostedBy,
      //   showTab: true,
      // },
      // {
      //   title: <FormattedMessage id="HomePage.joinedMatches" />,
      //   name: 'InboxPage',
      //   params: { tab: 'joinedMatches' },
      //   src: joinedMatch,
      //   showTab: true,
      // },
      {
        title: <FormattedMessage id="HomePage.messages" />,
        name: 'InboxPage',
        params: { tab: isSellerMode ? 'enquiry' : 'enquiry-client' },
        notification: !!currentUserSaleEnquiriesNotificationCount,
        src: messages,
        showTab: true,
      },
      // {
      //   title: <FormattedMessage id="HomePage.blogPage" />,
      //   name: isEducationSite ? 'EducationBlogPage' : 'BlogPage',
      //   src: topInCity,
      //   showTab: true,
      // },
      // {
      //   title: <FormattedMessage id="HomePage.subscription" />,
      //   name: 'UserSubscriptions',
      //   src: myListing,
      //   isSellerMode: true,
      // },
      // {
      //   title: <FormattedMessage id="HomePage.customerRequest" />,
      //   src: topInCity,
      //   showTab: isSellerMode,
      // },
      {
        title: <FormattedMessage id="HomePage.jobsBoard" />,
        name: isEducationSite
          ? 'EducationJobsBoard'
          : isTennisSite
          ? 'TennisJobsBoard'
          : 'JobsBoard',
        src: topInCity,
        showTab: true,
      },
      // {
      //   title: <FormattedMessage id="HomePage.Community" />,
      //   name: 'HomePage',
      //   // params: { tab: 'community' },
      //   src: BlueRacketSports,
      //   showTab: true,
      // },
      {
        title: <FormattedMessage id="Homepage.AddListing" />,
        params: { tab: isSellerMode ? 'schedule' : 'schedule' },
        name: 'InboxPage',
        src: addLising,
        showTab: isSellerMode,
      },
      {
        title: <FormattedMessage id="HomePage.MyListing" />,
        name: isEducationSite
          ? 'EducationManageListingsPage'
          : isTennisSite
          ? 'TennisManageListingsPage'
          : 'ManageListingsPage',
        src: myListing,
        showTab: true,
      },
      {
        title: <FormattedMessage id="HomePage.MyClients" />,
        name: 'InboxPage',
        params: { tab: 'myClients' },
        src: myClient,
        showTab: isSellerMode,
      },
    ],
    [
      isSellerMode,
      isEducationSite,
      currentUserSaleEnquiriesNotificationCount,
      currentUserNotificationCount,
    ]
  );

  // const isMobile = typeof window !== 'undefined' && window.screen.width < 550;

  const onLogin = useCallback(() => {
    if (isAuthenticated) {
      // setIsSellerMode(false);
      localStorage.removeItem('isProfile');
      // props.logout && props.logout();
      dispatch(logout());
      onClose && onClose();
    } else {
      history && history.push(createResourceLocatorString('LoginPage', routeConfiguration(), {}));
    }
  }, [dispatch, onClose, history, isAuthenticated]);

  const onChangeSellerMode = useCallback(() => {
    dispatch(addingSellerModeUserData(!isSellerMode, currentUser));
    setIsSellerMode(!isSellerMode);
  }, [dispatch, isSellerMode, currentUser]);

  const onrequestClick = useCallback(() => {
    currentTab && currentTab('enquiry');
    const category = isEducationSite
      ? { pub_category: 'language,academic,management,art,undefined' }
      : { pub_category: 'adventure,wellness,sports' };
    onShowEnquiryTab &&
      onShowEnquiryTab({
        pub_listing_type: 'enquiry',
        ...category,
        dates: null,
        times: null,
        price: null,
      });
  }, [currentTab, onShowEnquiryTab, isEducationSite]);
  // [isSellerMode, props.onShowEnquiryTab, props.onChangeMode, props.currentTab]);

  const sellerModeBtn = useMemo(
    () => (
      <div
        className={classNames(css.switch, {
          [css.switchChecked]: isSellerMode,
          [css.switchUnchecked]: !isSellerMode,
          [css.switchCheckedEdu]: isSellerMode && isEducationSite,
        })}
      >
        <h4>Host Mode</h4>
        <Switch
          uncheckedIcon={false}
          checkedIcon={false}
          onChange={onChangeSellerMode}
          checked={isSellerMode}
        />
      </div>
    ),
    [isSellerMode, isEducationSite, onChangeSellerMode]
  );
  // useEffect(() => {}, [isSellerMode]);

  return (
    <div
      className={classNames(css.LeftBar, {
        [css.leftBarActiveSearchBar]: isOpenSearchBar,
        [css.LeftbarTop]: isSellerMode && !isMobileLayout,
        [css.leftbarTopScrolling]: isScrolling,
        [null]: isMobileLayout,
        [css.LeftBarEdu]: isEducationSite,
      })}
    >
      {isMobileLayout && (
        <div className={css.logoContainer}>
          <img onClick={onLogoClick} src={Probuddylogo} alt="Probuddylogo" />
          {/* <NamedLink className={css.inboxMenuLink} name="BecomeVendorPage">
            <button className={css.sellerButton}>
              <FormattedMessage id="HeaderContainer.becomeAHost" />
            </button>
          </NamedLink> */}
          <div
            className={classNames(css.becomeHostWrapper, {
              [css.educationHostWrapper]: isEducationSite,
            })}
          >
            <div className={css.becomeHostMember}>
              {/* {!isEducationSite && (
                <NamedLink className={css.becomeHostMemberWrap} name="BecomeMemberPage">
                  <img
                    src="https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1664864117/probuddy/static/images/BecomeMemberPage/becomeMember_oaizaz.png"
                    className={css.becomeHostMemberImg}
                    alt="Become a Member"
                  />
                  <span className={css.becomeHostMemberText}>Sports Packages</span>
                </NamedLink>
              )} */}
              <NamedLink
                className={css.becomeHostMemberWrap}
                name={isEducationSite ? 'BecomeTutorPage' : 'BecomeVendorPage'}
              >
                <img
                  src="https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1664864117/probuddy/static/images/BecomeMemberPage/becomeHost_alwafl.png"
                  className={css.becomeHostMemberImg}
                  alt=" "
                />
                <span className={css.becomeHostMemberText}>
                  {isEducationSite ? 'Become a Tutor' : 'Become a Coach'}
                </span>
              </NamedLink>
              <NamedLink className={css.becomeHostMemberWrap} name={'ListingType'}>
                <img
                  src="https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1664864117/probuddy/static/images/BecomeMemberPage/becomeMember_oaizaz.png"
                  className={css.becomeHostMemberImg}
                  alt=" "
                />
                <span className={css.becomeHostMemberText}>{'Add Player Profile'}</span>
              </NamedLink>
            </div>
          </div>
          <div className={css.closeBtn} onClick={onClose}>
            <img src={closeBtn} alt={'clodse_btn'} />
          </div>
        </div>
      )}
      {showOpacity ? <div className={css.overlayLeft} /> : null}
      {isMobileLayout && sellerModeBtn}
      {/* <div className={css.LeftNavBarLogo}>
        <img
          src={
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1689676780/probuddy/static/ProbuddylogoWhite_napj2o.png'
          }
          alt="logo"
        />
      </div> */}
      {!isEducationSite ? (
        <NamedLink name="EducationPage" className={css.switchToEdu}>
          Switch to Education
          <img src={education} alt="logo" />
        </NamedLink>
      ) : (
        <NamedLink name="HomePage" className={css.switchToSport}>
          Switch to Sports
          <img src={sportsSvg} alt="logo" />
        </NamedLink>
      )}
      {/* <NamedLink
        className={classNames(css.requestButton, { [css.educationalBtn]: isEducationSite })}
        name="NewInquirePage"
      >
        <button className={css.button}>{submitRequestText}</button>
      </NamedLink> */}
      {!isMobileLayout && sellerModeBtn}
      <div className={css.btnWrapper}>
        {tabs
          .filter(tab => tab.showTab)
          .map((tab, index) => {
            return tab?.name ? (
              <NamedLink
                className={classNames(css.tabContainer, { [css.tabContainerEdu]: isEducationSite })}
                key={index}
                name={tab.name}
                params={tab.params}
              >
                <img src={tab.src} alt={tab.title} width="20" height="20" />
                <div>{tab.title}</div>
                {!!tab.notification && <div className={css.active}></div>}
              </NamedLink>
            ) : (
              <div
                key={index}
                className={classNames(css.tabContainer, { [css.tabContainerEdu]: isEducationSite })}
                onClick={() => onrequestClick()}
              >
                <img src={tab.src} alt={tab.title} width="20" height="20" />
                <div>{tab.title}</div>
              </div>
            );
          })}
        {isMobileLayout && (
          <NamedLink className={css.tabContainer} name="ContactDetailsPage">
            <img src={settings} alt={settingsText} width="20" height="20" />
            <div>{settingsText}</div>
          </NamedLink>
        )}
      </div>
      {isSellerMode && (
        <NamedLink
          className={css.googleLink}
          name={
            isEducationSite ? 'EducationGoogleCalendarSettingsPage' : 'GoogleCalendarSettingsPage'
          }
        >
          <div className={css.calendarBtn}>
            <div>Google Calendar</div>
            <img src={calendarIcon} alt={'google-calendar'} />
          </div>
        </NamedLink>
      )}
      {isMobileLayout && (
        <div className={css.logoutBtn} onClick={onLogin}>
          {logoutInProgress ? null : isAuthenticated ? 'Log Out' : 'Login'}
          {logoutInProgress && <IconSpinner className={css.spinner} />}
        </div>
      )}
      <div className={css.footerBtn}>
        <NamedLink
          name={
            isEducationSite
              ? 'ListingTypeEducation'
              : isTennisSite
              ? 'ListingTypeTennis'
              : 'ListingType'
          }
        >
          <button
            className={classNames(css.createButton, {
              [css.educationalCreateBtn]: isEducationSite,
              [css.tennisCreateBtn]: isTennisSite,
            })}
          >
            <div className={css.curve}>
              <img className={css.btnImg} src={plus} alt={'plus'} width="55" height="55" />
            </div>
            <div className={css.projectTitle}>
              <FormattedMessage
                id={
                  currentUserHasCompanyOrFreelancer || currentUserHasTutorOrAcademy
                    ? 'HomePage.createYourListings'
                    : 'HomePage.createProject'
                }
              />
            </div>
          </button>
        </NamedLink>
      </div>
    </div>
  );
};

export default memo(LeftBar);
