import React from 'react';
import { types as sdkTypes } from '../../util/sdkLoader';
import { FormattedMessage } from '../../util/reactIntl';
import { createSlug, stringify } from '../../util/urlHelpers';
import { NamedLink, Avatar, TransactionStateButton } from '../../components';

import { ReactComponent as BookingSvg } from '../../assets/SellerPage/Booking.svg';
import { ReactComponent as EnquiriesSvg } from '../../assets/SellerPage/Enquiries.svg';
import { ReactComponent as MyClientsSvg } from '../../assets/SellerPage/MyClients.svg';
import { ReactComponent as ScheduleSvg } from '../../assets/SellerPage/Schedule.svg';
import { ReactComponent as TransactionsSvg } from '../../assets/SellerPage/Transactions.svg';
import { ReactComponent as YourPackagesSvg } from '../../assets/SellerPage/YourPackages.svg';
import { ReactComponent as MyListings } from '../../assets/HomePage/myListingGray.svg';
import { ReactComponent as MatchBookings } from '../../assets/HomePage/tabIcon/matchesGray.svg';
import { ReactComponent as HostedMatches } from '../../assets/HomePage/homeIconGray.svg';
import { ReactComponent as JoinedMatches } from '../../assets/HomePage/joinedMatchGray.svg';
import { ReactComponent as MyRewards } from '../../assets/CompanyPage/greyStar.svg';

import css from './InboxPage.css';
import cns from 'classnames';

export const TABS = {
  order: 'orders',
  sale: 'sales',
  sellerPackages: 'sellerPackages',
  buyerPackages: 'buyerPackages',
  progressReports: 'progressReports',
  myClients: 'myClients',
  progressReportsClient: 'progressReportsClient',
  transactions: 'transactions',
  enquiry: 'enquiry',
  enquiryClient: 'enquiry-client',
  schedule: 'schedule',
  scheduleClient: 'schedule-client',
  proConnectRequests: 'pro_connect_requests',
  MyListings: 'myListings',
  MyMatch: 'myMatch',
  MyRewards: 'myRewards',
  HostedMatches: 'hostedMatches',
  JoinedMatches: 'joinedMatches',
};

export const { UUID } = sdkTypes;

export const STATES_WITH_MULTI_SELECT = ['ready-to-complete', 'accepted', 'pending', 'all'];

export const MAX_MOBILE_SCREEN_WIDTH = 1024;

export const walletPanelWidth = 62.5;

export const STATUS_COLOR_CLASS = {
  pending: '#ffaa00',
  accepted: '#2ecc71',
  cancelled: '#b2b2b2',
};

export const STATE_BTNS = [
  {
    state: 'all',
    label: <FormattedMessage id={'InboxPage.stateLabelAll'} />,
    tabs: ['sales', 'orders'],
  },
  {
    state: 'upcoming',
    label: <FormattedMessage id={'InboxPage.stateLabelUpcoming'} />,
    tabs: ['orders'],
  },
  {
    state: 'pending',
    label: <FormattedMessage id={'InboxPage.stateLabelPending'} />,
    tabs: ['sales'],
  },
  {
    state: 'ready-to-complete',
    label: <FormattedMessage id={'InboxPage.stateLabelReadyToComplete'} />,
    tabs: ['sales'],
  },
  {
    state: 'accepted',
    label: <FormattedMessage id={'InboxPage.stateLabelAccepted'} />,
    tabs: ['sales'],
  },
  {
    state: 'rescheduled',
    label: <FormattedMessage id={'InboxPage.stateLabelRescheduled'} />,
    tabs: ['sales', 'orders'],
  },
  {
    state: 'completed',
    label: <FormattedMessage id={'InboxPage.stateLabelCompleted'} />,
    tabs: ['orders'],
  },
  {
    state: 'canceled',
    label: <FormattedMessage id={'InboxPage.stateLabelCancelled'} />,
    tabs: ['sales', 'orders'],
  },
  {
    state: 'delivered',
    label: <FormattedMessage id={'InboxPage.stateLabelDelivered'} />,
    tabs: ['sales'],
  },
];

export const scheduleStateBtns = [
  {
    state: '1week',
    label: <FormattedMessage id={'InboxPage.state1Week'} />,
  },
  {
    state: '2weeks',
    label: <FormattedMessage id={'InboxPage.state2Week'} />,
  },
  {
    state: '4weeks',
    label: <FormattedMessage id={'InboxPage.state4Weeks'} />,
  },
  {
    state: '6weeks',
    label: <FormattedMessage id={'InboxPage.state6Weeks'} />,
  },
  {
    state: '8weeks',
    label: <FormattedMessage id={'InboxPage.state8Weeks'} />,
  },
];

export const getTabName = tab => {
  switch (tab) {
    case 'sales':
    case 'orders':
      return 'Bookings';
    case 'sellerPackages':
      return 'Your Packages';
    case 'enquiry':
    case 'enquiry-client':
      return 'Enquiries';
    case 'pro_connect_requests':
      return 'Pro Connect Requests';
    case 'progressReports':
      return 'Progress Reports';
    case 'myClients':
      return 'My Clients';
    case 'progressReportsClient':
      return 'Progress Reports Client';
    case 'schedule':
    case 'schedule-client':
      return 'Schedule';
    case 'buyerPackages':
      return 'Buyer Packages';
    case 'transactions':
      return 'Transactions';
    case 'myListings':
      return 'My Listings';
    case 'myMatch':
      return 'Match Bookings';
    case 'hostedMatches':
      return 'Hosted Matches';
    case 'joinedMatches':
      return 'Joined Matches';
    case 'myRewards':
      return 'My Rewards';
    default:
      return tab;
  }
};

export const getImage = tab => {
  switch (tab) {
    case 'sales':
      return <BookingSvg />;
    case 'sellerPackages':
      return <YourPackagesSvg />;
    case 'schedule':
      return <ScheduleSvg />;
    case 'myClients':
      return <MyClientsSvg />;
    case 'transactions':
      return <TransactionsSvg />;
    case 'myListings':
      return <MyListings />;
    case 'myMatch':
      return <MatchBookings />;
    case 'hostedMatches':
      return <HostedMatches />;
    case 'joinedMatches':
      return <JoinedMatches />;
    case 'myRewards':
      return <MyRewards />;
    default:
      return <EnquiriesSvg />;
  }
};

export const createListingLink = (listing, otherUser, searchParams = {}, className = '') => {
  const listingId = listing.id && listing.id.uuid;
  const label = listing.attributes.title;
  const listingDeleted = listing.attributes.deleted;

  if (!listingDeleted) {
    const params = { id: listingId, slug: createSlug(label) };
    const to = { search: stringify(searchParams) };
    return (
      <NamedLink className={className} name="ListingPage" params={params} to={to}>
        <Avatar user={otherUser} disableProfileLink />
      </NamedLink>
    );
  } else {
    return <FormattedMessage id="TransactionPanel.deletedListingOrderTitle" />;
  }
};

export const StateButtons = ({ params, onClick, buttons }) => {
  const { tab } = params;
  const { state = tab === 'orders' ? 'all' : 'pending' } = params;

  const stateBtns = buttons.filter(btn => btn.tabs.includes(tab));
  return (
    <div className={css.stateBtnsWtap}>
      {stateBtns.map(btn => (
        <TransactionStateButton
          key={btn.state}
          onClick={() => onClick(btn.state)}
          isActive={btn.state === state}
          {...btn}
        />
      ))}
    </div>
  );
};

export const ScheduleStateButton = ({ onClick, selectedWeek = '1week' }) => {
  const stateBtns = [
    {
      state: '1week',
      label: <FormattedMessage id={'InboxPage.state1Week'} />,
    },
    {
      state: '2weeks',
      label: <FormattedMessage id={'InboxPage.state2Week'} />,
    },
    {
      state: '4weeks',
      label: <FormattedMessage id={'InboxPage.state4Weeks'} />,
    },
    {
      state: '6weeks',
      label: <FormattedMessage id={'InboxPage.state6Weeks'} />,
    },
    {
      state: '8weeks',
      label: <FormattedMessage id={'InboxPage.state8Weeks'} />,
    },
  ];
  return (
    <div className={css.stateBtnsWtap}>
      {stateBtns.map(btn => (
        <TransactionStateButton
          key={btn.state}
          onClick={() => onClick(btn.state)}
          isActive={selectedWeek === btn.state}
          {...btn}
        />
      ))}
    </div>
  );
};

export const StateMobileSlider = ({ params, options, isSchedule, isScheduleClient }) => {
  const { tab } = params;
  const {
    state = isSchedule || isScheduleClient ? '1week' : tab === 'orders' ? 'all' : 'pending',
  } = params;

  const getScheduleStateName = value => {
    switch (value) {
      case '1week':
        return '1 Week';
      case '2weeks':
        return '2 Weeks';
      case '4weeks':
        return '4 Weeks';
      case '6weeks':
        return '6 Weeks';
      case '8weeks':
        return '8 Weeks';
      default:
        return value;
    }
  };

  let stateBtns = options;
  if (isSchedule !== true && isScheduleClient !== true) {
    stateBtns = options.filter(opt => opt.tabs.includes(tab));
  }
  return (
    <div className={css.mobileStateSlider}>
      {stateBtns &&
        stateBtns.map(opt => (
          <NamedLink
            key={opt.state}
            name="InboxPage"
            params={{ ...params, state: opt.state }}
            className={cns(css.mobileStateSliderLink, {
              [css.mobileStateSliderSelectedLink]: opt.state === state,
            })}
          >
            {getScheduleStateName(opt.state)}
          </NamedLink>
        ))}
    </div>
  );
};
