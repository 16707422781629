import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CloseBtnIcon from '../../../../assets/SellerPage/closeIcon.svg';
import ProbuddyLogo from '../../../../assets/SellerPage/ProbuddyLogo.svg';
import { NamedLink } from '../../../../components';

import css from './TabNav.css';

const Tab = props => {
  const {
    className,
    id,
    disabled,
    text,
    svgIcon,
    selected,
    linkProps,
    onClick,
    fillSvg = false,
  } = props;
  const linkClasses = classNames(css.link, {
    [css.selectedLink]: selected,
    [css.fillSvg]: fillSvg && selected,
    [css.disabled]: disabled,
  });
  return (
    <div id={id} className={className}>
      {!onClick ? (
        <NamedLink className={linkClasses} {...linkProps}>
          {svgIcon}
          {text}
        </NamedLink>
      ) : (
        <div className={linkClasses} onClick={onClick}>
          {text}
        </div>
      )}
    </div>
  );
};

Tab.defaultProps = { className: null, disabled: false, selected: false };

const { arrayOf, bool, node, object, string } = PropTypes;

Tab.propTypes = {
  id: string.isRequired,
  className: string,
  text: node.isRequired,
  disabled: bool,
  selected: bool,
  linkProps: object,
};

const TabNav = props => {
  const { className, rootClassName, tabRootClassName, tabs, isMobileLayout, closeModal, resetPage } = props;
  const classes = classNames(rootClassName || css.root, className);
  const tabClasses = tabRootClassName || css.tab;
  return (
    <nav className={classes}>
      {isMobileLayout && (
        <>
          <button className={css.tabClose} onClick={closeModal}>
            <img src={CloseBtnIcon} alt={'close_icon'} />
          </button>
          <div className={css.logo}><img src={ProbuddyLogo} onClick={resetPage} alt="ProbuddyLogo" /></div>
        </>
      )}
      <div className={css.tabWrapper} onClick={closeModal}>
        {tabs.map((tab, index) => {
          const id = typeof tab.id === 'string' ? tab.id : `${index}`;
          return <Tab key={id} id={id} className={tabClasses} {...tab} />;
        })}
      </div>
    </nav>
  );
};

TabNav.defaultProps = {
  className: null,
  rootClassName: null,
  tabRootClassName: null,
  tabClassName: null,
};

TabNav.propTypes = {
  className: string,
  rootClassName: string,
  tabRootClassName: string,
  tabs: arrayOf(object).isRequired,
};

export default TabNav;
