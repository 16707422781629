import React from 'react';
import { NewSeller, MasterVerified, LevelTwo } from './../../components/IconLevel/IconLevel';

const SectionLevel = props => {
  const { metadata, bigIcon = false } = props;

  if (bigIcon) {
    switch (metadata && metadata.level) {
      case 'one':
      case 'two':
      case 'three':
        return <NewSeller />;
      case 'pro':
        return <LevelTwo />;
      case 'master_verified':
        return <MasterVerified />;
      default:
        return <NewSeller />;
    }
  } else {
    switch (metadata && metadata.level) {
      case 'one':
      case 'two':
      case 'three':
        return <NewSeller />;
      case 'pro':
        return <LevelTwo />;
      case 'master_verified':
        return <MasterVerified />;
      default:
        return <NewSeller />;
    }
  }
};

export default SectionLevel;
