import { AVAILABILITY, LISTING_TYPES } from 'util/constants';
import {
  CERTIFICATES,
  DESCRIPTION,
  EXPECTATIONS,
  SERVICES,
  LOCATION,
  SOCIALS,
  PHOTOS,
  PRICING,
  DETAILS,
  PRICESTOCK,
  DELIVERY,
  FAQ,
  BLOGS,
  EDUCATION,
  INTODUCTORY,
} from 'util/constants';
import config from 'config';

// Show availability calendar only if environment variable availabilityEnabled is true
const availabilityMaybe = config.enableAvailability ? [AVAILABILITY] : [];

// You can reorder these panels.
// Note 1: You need to change save button translations for new listing flow
// Note 2: Ensure that draft listing is created after the first panel
// and listing publishing happens after last panel.
// Note 3: in FTW-hourly template we don't use the POLICY tab so it's commented out.
// If you want to add a free text field to your listings you can enable the POLICY tab
export const TABS = [
  DESCRIPTION,
  // FEATURES,
  //POLICY,
  LOCATION,
  PRICING,
  CERTIFICATES,
  EXPECTATIONS,
  // SERVICES,
  ...availabilityMaybe,
  PHOTOS,
  EDUCATION,
  INTODUCTORY,
  FAQ,
  BLOGS,
];

export const LISTING_TABS = [
  DESCRIPTION,
  LOCATION,
  PRICING,
  CERTIFICATES,
  EXPECTATIONS,
  // SERVICES,
  ...availabilityMaybe,
  PHOTOS,
];

export const EDUCATION_TABS = [
  DESCRIPTION,
  LOCATION,
  PRICING,
  // SERVICES,
  CERTIFICATES,
  EXPECTATIONS,
  ...availabilityMaybe,
  PHOTOS,
];
export const ENQUIRY_TABS = [DESCRIPTION, LOCATION];
export const COMPANY_TABS = [
  DESCRIPTION,
  LOCATION,
  INTODUCTORY,
  EXPECTATIONS,
  SERVICES,
  SOCIALS,
  FAQ,
  BLOGS,
  PHOTOS,
];
export const PLAYER_PROFILE_TABS = [DESCRIPTION, LOCATION, INTODUCTORY, PHOTOS];
export const ACADEMY_TABS = [
  DESCRIPTION,
  LOCATION,
  INTODUCTORY,
  EDUCATION,
  EXPECTATIONS,
  FAQ,
  BLOGS,
  PHOTOS,
];
export const CLASS_TABS = [
  DESCRIPTION,
  LOCATION,
  PRICING,
  EXPECTATIONS,
  ...availabilityMaybe,
  PHOTOS,
];

export const COURSES_PRODUCT = [
  DESCRIPTION,
  LOCATION,
  PRICESTOCK,
  CERTIFICATES,
  EXPECTATIONS,
  PHOTOS,
];

export const PRODUCT_TABS = [DETAILS, PRICING, CERTIFICATES, EXPECTATIONS, DELIVERY, PHOTOS];

export const getTabs = listingType => {
  switch (listingType) {
    case LISTING_TYPES.LISTING:
    case LISTING_TYPES.FACILITY:
    case LISTING_TYPES.MEMBERSHIP:
      return LISTING_TABS;
    case LISTING_TYPES.ENQUIRY:
      return ENQUIRY_TABS;
    case LISTING_TYPES.COMPANY:
    case LISTING_TYPES.FREELANCER:
      return COMPANY_TABS;
    case LISTING_TYPES.CLASS:
    case LISTING_TYPES.EVENT:
      return CLASS_TABS;
    case LISTING_TYPES.PRODUCT:
      return PRODUCT_TABS;
    case LISTING_TYPES.ACADEMY:
    case LISTING_TYPES.TUTOR:
    case LISTING_TYPES.REMOTE_TUTOR:
      return ACADEMY_TABS;
    case LISTING_TYPES.COURSES:
    case LISTING_TYPES.HOURLY_SESSION:
      return EDUCATION_TABS;
    case LISTING_TYPES.EDUCATION_COURSES:
      return COURSES_PRODUCT;
    case LISTING_TYPES.PLAYER_PROFILE:
      return PLAYER_PROFILE_TABS;
    default:
      return LISTING_TABS;
  }
};
