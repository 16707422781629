import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { bool, func, node, object, oneOfType, shape, string } from 'prop-types';
import classNames from 'classnames';
import omit from 'lodash/omit';
import {
  propTypes,
  LISTING_STATE_CLOSED,
  LINE_ITEM_NIGHT,
  LINE_ITEM_DAY,
  defaultListingCurrency,
} from '../../util/types';
import { formatMoney } from '../../util/currency';
import { parse, stringify } from '../../util/urlHelpers';
import { LISTING_TYPES } from 'util/constants';
import config from '../../config';
import { ModalInMobile } from '../../components';
import { BookingTimeForm } from '../../forms';
import { types as sdkTypes } from '../../util/sdkLoader';

import css from './BookingPanel.css';
import { useSelector } from 'react-redux';

// This defines when ModalInMobile shows content as Modal
const MODAL_BREAKPOINT = 1023;
const TODAY = new Date();

const { Money } = sdkTypes;

const priceData = (price, intl) => {
  if (
    price &&
    (price.currency === config.currency || defaultListingCurrency.includes(price.currency))
  ) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

// const openBookModal = (isOwnListing, isClosed, history, location) => {
//   if ((isOwnListing || isClosed) && typeof window !== 'undefined') {
//     window.scrollTo(0, 0);
//   } else {
//     const { pathname, search, state } = location;
//     const searchString = `?${stringify({ ...parse(search), book: true })}`;
//     history.push(`${pathname}${searchString}`, state);
//   }
// };

const closeBookModal = (history, location) => {
  const { pathname, search, state } = location;
  const searchParams = omit(parse(search), 'book');
  const searchString = `?${stringify(searchParams)}`;
  history.push(`${pathname}${searchString}`, state);
};

const dateFormattingOptions = { month: 'short', day: 'numeric', weekday: 'short' };

const BookingPanel = props => {
  const {
    titleClassName,
    listing,
    viewport,
    currentUser,
    isOwnListing,
    unitType,
    onSubmit,
    title,
    listingType,
    onManageDisableScrolling,
    onFetchTimeSlots,
    monthlyTimeSlots,
    history,
    location,
    intl,
    handlePackagesModalOpen,
    isProduct,
    onAddingCustomPrice,
    onRemovingCustomPrice,
    bookingData,
    isTimeSlotesLoading,
    addSubscription,
    onCancelSubscription,
  } = props;

  const [isCookieMessageShow, setCookieMessageShow] = useState(false);
  const { fetchCalendarEventsInProgress, fetchCalendarEventsError, calendarEvents } = useSelector(
    state => state.ListingPage
  );
  useEffect(() => {
    const cookies = document.cookie.split('; ').reduce((acc, c) => {
      const [name, value] = c.split('=');
      return { ...acc, [name]: decodeURIComponent(value) };
    }, {});

    if (cookies.euCookiesAccepted !== '1') {
      setCookieMessageShow(true);
    }
  }, [isCookieMessageShow, setCookieMessageShow]);

  const priceAddons = listing.attributes.publicData
    ? listing.attributes.publicData.priceAddons
    : [];

  const formattedPriceAddons =
    priceAddons &&
    priceAddons.map(i => ({
      label: i.label,
      price: new Money(i.amount, i.currency),
    }));

  const discountPriceAddons = listing.attributes.publicData
    ? listing.attributes.publicData.discountPriceAddons
    : [];

  const formattedDiscountPriceAddons =
    discountPriceAddons &&
    discountPriceAddons.map(i => ({
      label: i.label,
      amount: i.amount,
    }));

  const handleSubmit = values => {
    if (values.withPayment && listing.attributes.publicData) {
      values.payment_method = listing.attributes.publicData.payment_method || [];
    }

    if (values.withCredits && !values.seats) {
      values.seats = 1;
    }
    const selectedPriceAddons =
      values &&
      values.additionalItems &&
      priceAddons.filter(p => values.additionalItems.find(i => i === p.label));

    const selectedDiscountPriceAddons =
      values &&
      values.discountAdditionalItems &&
      discountPriceAddons.filter(p => values.discountAdditionalItems.find(i => i === p.label));

    onSubmit({
      ...values,
      priceAddons: selectedPriceAddons,
      discountPriceAddons: selectedDiscountPriceAddons,
    });
  };
  useEffect(() => {
    const book = !!parse(location.search).book;
    if (book && viewport.width > 1034) {
      closeBookModal(history, location);
    }
  }, []);

  const price = listing.attributes.price;
  const timeZone =
    listing.attributes.availabilityPlan && listing.attributes.availabilityPlan.timezone;
  const hasListingState = !!listing.attributes.state;
  const isClosed = hasListingState && listing.attributes.state === LISTING_STATE_CLOSED;
  const showBookingTimeForm = hasListingState && !isClosed;
  // const showClosedListingHelpText = listing.id && isClosed;
  const { formattedPrice } = priceData(price, intl);
  const isBook = !!parse(location.search).book;
  const isMembership = listing?.attributes?.publicData?.listing_type === LISTING_TYPES.MEMBERSHIP;

  // const subTitleText = !!subTitle
  //   ? subTitle
  //   : showClosedListingHelpText
  //   ? intl.formatMessage({ id: 'BookingPanel.subTitleClosedListing' })
  //   : null;

  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;

  const unitTranslationKey = isProduct
    ? 'BookingPanel.perCourse'
    : isMembership
    ? 'BookingPanel.month'
    : isNightly
    ? 'BookingPanel.perNight'
    : isDaily
    ? 'BookingPanel.perDay'
    : 'BookingPanel.perUnit';

  // const classes = classNames(rootClassName || css.root, className);
  const titleClasses = classNames(
    titleClassName || css.bookingTitle,
    isMembership && css.bookingTitleMembership
  );

  return (
    <div className={css.bookingContainer}>
      <div className={css.stickyContainer}>
        <div className={css.bookingPanelContainer}>
          <ModalInMobile
            containerClassName={css.modalContainer}
            id="BookingTimeFormInModal"
            isModalOpenOnMobile={isBook}
            onClose={() => closeBookModal(history, location)}
            showAsModalMaxWidth={MODAL_BREAKPOINT}
            onManageDisableScrolling={onManageDisableScrolling}
          >
            <div className={css.powerByProbuddyWrapper}>
              <h4 className={css.TopHeading}>
                {listingType === LISTING_TYPES.PRODUCT
                  ? 'Buy This Product'
                  : !!isProduct
                  ? 'Buy This Course'
                  : 'Book a Session'}
              </h4>
            </div>
            <div className={css.bottomBookingWrapper}>
              {/* <div className={css.modalHeading}>
                <h1 className={css.title}>{title}</h1>
              </div> */}
              <div className={css.bookingHeading}>
                <div className={css.desktopPriceContainer}>
                  <h2 className={css.desktopPriceValue}>
                    {formattedPrice}

                    {listingType === LISTING_TYPES.PRODUCT ? (
                      <span className={css.desktopPerUnit}>Per Product</span>
                    ) : (
                      !isProduct && (
                        <span className={css.desktopPerUnit}>
                          <FormattedMessage id={unitTranslationKey} />
                        </span>
                      )
                    )}
                  </h2>
                </div>
                <div className={css.bookingHeadingContainer}>
                  <h2 className={titleClasses}>
                    {isMembership ? `Subscribe to ${listing.attributes.title}` : title}
                  </h2>
                  {isMembership && (
                    <div className={css.bookingSubtitle}>{'Extra 4% card fee will be applied'}</div>
                  )}
                  {/* {subTitleText ? <div className={css.bookingHelp}>{subTitleText}</div> : null} */}
                </div>
              </div>

              {showBookingTimeForm ? (
                <BookingTimeForm
                  className={css.bookingForm}
                  formId="BookingPanel"
                  submitButtonWrapperClassName={css.submitButtonWrapper}
                  unitType={unitType}
                  onSubmit={handleSubmit}
                  price={price}
                  isOwnListing={isOwnListing}
                  listingId={listing.id}
                  listing={listing}
                  currentUser={currentUser}
                  monthlyTimeSlots={monthlyTimeSlots}
                  onFetchTimeSlots={onFetchTimeSlots}
                  startDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
                  endDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
                  timeZone={timeZone}
                  handlePackagesModalOpen={handlePackagesModalOpen}
                  showTopup={true}
                  priceAddons={formattedPriceAddons}
                  discountPriceAddons={formattedDiscountPriceAddons}
                  isProduct={isProduct}
                  onManageDisableScrolling={onManageDisableScrolling}
                  history={history}
                  fetchCalendarEventsInProgress={fetchCalendarEventsInProgress}
                  calendarEvents={calendarEvents}
                  fetchCalendarEventsError={fetchCalendarEventsError}
                  onAddingCustomPrice={onAddingCustomPrice}
                  onRemovingCustomPrice={onRemovingCustomPrice}
                  bookingDataFacility={bookingData}
                  isTimeSlotesLoading={isTimeSlotesLoading}
                  onCancelSubscription={onCancelSubscription}
                  addSubscription={addSubscription}
                />
              ) : null}
            </div>
          </ModalInMobile>
        </div>
      </div>
    </div>
  );
};

BookingPanel.defaultProps = {
  rootClassName: null,
  className: null,
  titleClassName: null,
  isOwnListing: false,
  subTitle: null,
  unitType: config.bookingUnitType,
  monthlyTimeSlots: null,
};

BookingPanel.propTypes = {
  rootClassName: string,
  className: string,
  titleClassName: string,
  listing: oneOfType([propTypes.listing, propTypes.ownListing]),
  isOwnListing: bool,
  unitType: propTypes.bookingUnitType,
  onSubmit: func.isRequired,
  title: oneOfType([node, string]).isRequired,
  subTitle: oneOfType([node, string]),
  authorDisplayName: oneOfType([node, string]).isRequired,
  onManageDisableScrolling: func.isRequired,
  onFetchTimeSlots: func.isRequired,
  monthlyTimeSlots: object,
  isProduct: bool,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default compose(withRouter, injectIntl)(BookingPanel);
