import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';

export const SET_INITIAL_STATE = 'app/UserSubscription/SET_INITIAL_STATE';

export const BUYER_SUBSCRIPTION_REQUEST = 'app/UserSubscription/BUYER_SUBSCRIPTION_REQUEST';
export const BUYER_SUBSCRIPTION_SUCCESS = 'app/UserSubscription/BUYER_SUBSCRIPTION_SUCCESS';

export const SELLER_SUBSCRIPTION_REQUEST = 'app/UserSubscription/BUYER_SUBSCRIPTION_REQUEST';
export const SELLER_SUBSCRIPTION_SUCCESS = 'app/UserSubscription/SELLER_SUBSCRIPTION_SUCCESS';

const initialState = {
  buyerSubscriptionRef: [],
  gettingBuyerSubscription: false,
  sellerSubscriptionRef: [],
  gettingellerSubscription: false,
};

export default function userSubscriptionReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_STATE:
      return { ...initialState };
    case BUYER_SUBSCRIPTION_REQUEST:
      return { ...state, gettingBuyerSubscription: true };
    case BUYER_SUBSCRIPTION_SUCCESS:
      return { ...state, gettingBuyerSubscription: false, buyerSubscriptionRef: payload };
    case SELLER_SUBSCRIPTION_REQUEST:
      return { ...state, gettingellerSubscription: true };
    case SELLER_SUBSCRIPTION_SUCCESS:
      return { ...state, gettingellerSubscription: false, sellerSubscriptionRef: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const setInitialState = () => ({
  type: SET_INITIAL_STATE,
});

export const buyerSubscriptionRequest = () => ({
  type: BUYER_SUBSCRIPTION_REQUEST,
});
export const buyerSubscriptionSuccess = data => ({
  type: BUYER_SUBSCRIPTION_SUCCESS,
  payload: data,
});
export const sellerSubscriptionRequest = () => ({
  type: SELLER_SUBSCRIPTION_REQUEST,
});
export const sellerSubscriptionSuccess = data => ({
  type: SELLER_SUBSCRIPTION_SUCCESS,
  payload: data,
});

// ================ Thunks ================ //

export const getBuyerSubscription = item => (dispatch, getState, sdk) => {
  const currentUser = getState().user.currentUser;
  const buyerSubscriptions =
    currentUser && currentUser.attributes.profile.privateData.buyerSubscription;
  const subscriptions = Object.keys(buyerSubscriptions || {});
  dispatch(buyerSubscriptionRequest());
  let buyerListings = [];
  subscriptions.map(s => {
    return sdk.listings
      .show({
        id: s,
        include: ['author', 'author.profileImage', 'images'],
        'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
        'limit.images': 6,
        'page.limit': 50,
        sort: 'meta_promoted,createdAt',
      })
      .then(response => {
        const res = [response.data];
        const listingRefs = res.map(r => ({
          id: r.data.id,
          type: r.data.type,
        }));
        // const listingCompanyRefs = response.data.data.map(({ id, type, relationships }) => ({
        //   id,
        //   type,
        //   authorId: relationships.author.data.id.uuid,
        // }));
        dispatch(addMarketplaceEntities(response));
        buyerListings.push(...listingRefs);
      })
      .catch(e => console.log(e));
  });
  dispatch(buyerSubscriptionSuccess(buyerListings));
  return buyerListings;
};

export const getSellerSubscription = item => (dispatch, getState, sdk) => {
  const currentUser = getState().user.currentUser;
  const buyerSubscriptions =
    currentUser && currentUser.attributes.profile.privateData.sellerSubscription;
  const subscriptions = Object.keys(buyerSubscriptions || {});
  dispatch(sellerSubscriptionRequest());
  let buyerListings = [];
  subscriptions.map(s => {
    return sdk.listings
      .show({
        id: s,
        include: ['author', 'author.profileImage', 'images'],
        'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
        'limit.images': 6,
        'page.limit': 50,
        sort: 'meta_promoted,createdAt',
      })
      .then(response => {
        const res = [response.data];
        const listingRefs = res.map(r => ({
          id: r.data.id,
          type: r.data.type,
        }));
        // const listingCompanyRefs = response.data.data.map(({ id, type, relationships }) => ({
        //   id,
        //   type,
        //   authorId: relationships.author.data.id.uuid,
        // }));
        dispatch(addMarketplaceEntities(response));
        buyerListings.push(...listingRefs);
      })
      .catch(e => console.log(e));
  });
  dispatch(sellerSubscriptionSuccess(buyerListings));
  return buyerListings;
};

export const loadData = () => (dispatch, getState, sdk) => {
  // Clear state so that previously loaded data is not visible
  // in case this page load fails.

  dispatch(setInitialState());
  const currentUser = getState().user.currentUser;

  while (!currentUser) {
    return Promise.all([dispatch(getBuyerSubscription()), dispatch(getSellerSubscription)]);
  }
  return Promise.all([
    dispatch(fetchCurrentUser()),
    // dispatch(getBuyerSubscription()),
    // dispatch(getSellerSubscription),
  ]);
  // return Promise.all([dispatch(queryPromotedListings()), dispatch(fetchCompanies())]);
};
