import React from 'react';
import classNames from 'classnames';
import css from './AgeGroup.css';
import { ageSwitches } from 'containers/HomePage/HomePage.data';

const AgeGroupSwitch = ({ selectedAgeGroup, handleAgeGroupChange }) => {
  return (
    <div className={css.switchContainer}>
      {ageSwitches.map(item => {
        let label = item.label;
        return (
          <div
            key={item.id}
            className={classNames(css.switch, {
              [css.switchActive]: selectedAgeGroup === item.id,
            })}
            onClick={() => handleAgeGroupChange(item)}
          >
            <img className={css.switchimage} src={item.image} alt={label} />
            <h4>{label}</h4>
          </div>
        );
      })}
    </div>
  );
};

export default AgeGroupSwitch;
