import React from 'react';
import css from './SectionFindMembership.css';
import { FormattedMessage } from '../../util/reactIntl';

const SectionFindMembership = props => {
    return (
        <div className={css.findMembership}>
            <div className={css.title}>
                <FormattedMessage id="SectionFindMembership.title" />
            </div>
            <div className={css.memberships}>
                <a href="/book/company/Tennis?pub_listing_type=company&pub_sub_category=tennis">
                    <div className={css.tennisClubs}>
                        <h3><FormattedMessage id="SectionFindMembership.tennisClubs" /></h3>
                    </div>
                </a>

                <a href="/book/company/Crossfit?pub_listing_type=company&pub_sub_category=crossfit">
                    <div className={css.crossFit}>
                        <h3><FormattedMessage id="SectionFindMembership.crossFit" /></h3>
                    </div>
                </a>

                <a href="/book/company/Personal Training?pub_listing_type=company&pub_sub_category=personal training">
                    <div className={css.fitnessGym}>
                        <h3><FormattedMessage id="SectionFindMembership.fitnessGym" /></h3>
                    </div>
                </a>

                <a href="/book/company/Yoga?pub_listing_type=company&pub_sub_category=yoga">
                    <div className={css.yogaStudios}>
                        <h3><FormattedMessage id="SectionFindMembership.yogaStudios" /></h3>
                    </div>
                </a>

                <a href="/book/company/Cycling?pub_listing_type=company&pub_sub_category=cycling">
                    <div className={css.cycling}>
                        <h3><FormattedMessage id="SectionFindMembership.cycling" /></h3>
                    </div>
                </a>
            </div>
        </div>
    );
};

SectionFindMembership.defaultProps = { };

SectionFindMembership.propTypes = {
  
};

export default SectionFindMembership;
