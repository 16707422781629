import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { PrimaryButton, SecondaryButton, Portal, Modal, NamedLink } from 'components';
import css from './RescheduleModal.css';
import { Form } from 'react-final-form';
import FieldDateAndTimeInput from '../../forms/BookingTimeForm/FieldDateAndTimeInput';

import {
  TRANSITION_RESCHEDULE_BY_PROVIDER,
  TRANSITION_RESCHEDULE_BY_CUSTOMER,
  TRANSITION_RESCHEDULE_BY_PROVIDER_AFTER_READY_TO_COMPLETE,
  txIsReadyToComplete,
} from '../../util/transaction';

const dateFormattingOptions = { month: 'short', day: 'numeric', weekday: 'short' };
const TODAY = new Date();

// const timeFormatOptions = {
//   hour: 'numeric',
//   minute: 'numeric',
// };

// const dateFormatOptions = {
//   weekday: 'short',
//   day: 'numeric',
//   month: 'long',
// };

class RescheduleModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      focused: null,
    };
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.rescheduledsuccessfully !== prevProps.rescheduledsuccessfully &&
      this.props.rescheduledsuccessfully
    ) {
      this.props.onClose();
    }
  }

  getFormInitData = () => {
    const { monthlyTimeSlots, tx } = this.props;
    const { booking } = tx;
    const initData = monthlyTimeSlots ? Object.entries(monthlyTimeSlots) : [];
    const initDate = initData.length ? initData[0][1] : {};
    const startTime =
      initDate && initDate.timeSlots && initDate.timeSlots.length
        ? initDate.timeSlots[0]?.attributes?.start
        : null;
    const endTime =
      initDate.timeSlots && initDate.timeSlots.length
        ? initDate.timeSlots[0]?.attributes?.end
        : null;
    const bookedMins = booking
      ? moment
          .duration(moment(booking.attributes.end).diff(moment(booking.attributes.start)))
          .asMinutes()
      : 0;

    return {
      bookingStartDate: { date: startTime },
      bookingEndDate: { date: endTime },
      bookingStartTime: moment(startTime).valueOf(),
      bookingEndTime: moment(startTime)
        .add(bookedMins, 'minutes')
        .valueOf(),
      timeSlotsOnSelectedDate: initDate.timeSlots,
    };
  };

  onSubmit(e) {
    const transition = this.props.isSale
      ? txIsReadyToComplete(this.props.tx)
        ? TRANSITION_RESCHEDULE_BY_PROVIDER_AFTER_READY_TO_COMPLETE
        : TRANSITION_RESCHEDULE_BY_PROVIDER
      : TRANSITION_RESCHEDULE_BY_CUSTOMER;
    const { booking } = this.props.tx;

    const startTime = parseInt(e.bookingStartTime);
    const endTime = parseInt(e.bookingEndTime);
    const bookedMins = moment
      .duration(moment(booking.attributes.end).diff(moment(booking.attributes.start)))
      .asMinutes();
    const bookingMins = moment.duration(moment(endTime).diff(moment(startTime))).asMinutes();
    const isSameHours = bookedMins === bookingMins;

    if (isSameHours) {
      this.props.rescheduleBooking({
        transactionId: this.props.tx.id.uuid,
        bookingStart: parseInt(startTime),
        bookingEnd: parseInt(endTime),
        transition,
      });
    }
  }

  render() {
    const {
      intl,
      id,
      isOpen,
      onClose,
      onManageDisableScrolling,
      heading,
      onCancelReschedule,
      tx,
      rescheduleRequestInProgress,
      rescheduleError,
      rescheduledsuccessfully,
      onFetchTimeSlots,
      monthlyTimeSlots,
      isTimeSlotesLoading,
      type,
    } = this.props;

    const { listing, booking } = tx;
    const isOrder = type === 'order';
    const timeZone =
      listing.attributes.availabilityPlan && listing.attributes.availabilityPlan.timezone;

    const startDatePlaceholder = intl.formatDate(TODAY, dateFormattingOptions);
    const endDatePlaceholder = intl.formatDate(TODAY, dateFormattingOptions);

    const bookingStartLabel = intl.formatMessage({
      id: 'BookingTimeForm.bookingStartTitle',
    });
    const bookingEndLabel = intl.formatMessage({
      id: 'BookingTimeForm.bookingEndTitle',
    });

    const startDateInputProps = {
      label: bookingStartLabel,
      placeholderText: startDatePlaceholder,
    };
    const endDateInputProps = {
      label: bookingEndLabel,
      placeholderText: endDatePlaceholder,
    };

    const dateInputProps = {
      startDateInputProps,
      endDateInputProps,
    };

    return (
      <Portal portalRoot={document.getElementById('portal-root')}>
        <Modal
          id={id}
          contentClassName={css.modalContent}
          isOpen={isOpen}
          onClose={onClose}
          onManageDisableScrolling={onManageDisableScrolling}
        >
          <Form
            onSubmit={this.onSubmit}
            initialValues={this.getFormInitData()}
            validate={values => {
              return values.bookingStartDate &&
                values.bookingStartDate.date &&
                values.bookingStartTime
                ? undefined
                : 'Booking date and time must be selected';
            }}
            render={fieldRenderProps => {
              const { form, pristine, handleSubmit, values } = fieldRenderProps;

              const startTime =
                values && values.bookingStartTime ? parseInt(values.bookingStartTime) : null;
              const endTime =
                values && values.bookingEndTime ? parseInt(values.bookingEndTime) : null;
              // const startDate = startTime ? timestampToDate(startTime) : null;
              // const endDate = endTime ? timestampToDate(endTime) : null;
              const bookedMins = moment
                .duration(moment(booking.attributes.end).diff(moment(booking.attributes.start)))
                .asMinutes();
              const bookingMins = moment
                .duration(moment(endTime).diff(moment(startTime)))
                .asMinutes();
              const isSameHours = bookedMins === bookingMins;

              return (
                <form onSubmit={handleSubmit}>
                  <div className={css.contentWrap}>
                    <h3>{heading}</h3>
                    {// transaction-booking-time-not-available
                    rescheduleError &&
                    rescheduleError.apiErrors &&
                    rescheduleError.apiErrors[0] &&
                    rescheduleError.apiErrors[0].code ===
                      'transaction-booking-time-not-available' ? (
                      <p className={css.error}>The requested booking time is not available.</p>
                    ) : rescheduleError ? (
                      <p className={css.error}>There was an error rescheduling your booking.</p>
                    ) : null}
                    {startTime && endTime && !isSameHours ? (
                      <p className={css.error}>
                        Note: Number of hours should be equal to booked hours. If you want to change
                        number of booking hours you need to cancel the booking and rebook.
                      </p>
                    ) : null}
                    <div className={css.orderLinkWrapper}>
                      <span>
                        <NamedLink
                          className={css.orderLink}
                          name={isOrder ? 'OrderDetailsPage' : 'SaleDetailsPage'}
                          params={{ id: tx.id.uuid }}
                        >
                          Contact&nbsp;
                        </NamedLink>
                      </span>
                      {isOrder ? (
                        <span>
                          your trainer before rescheduling. Provide a reason for rescheduling and
                          confirm date and time for rescheduling.
                        </span>
                      ) : (
                        <span>
                          your client before rescheduling. Provide a reason for rescheduling and
                          confirm date and time for rescheduling.
                        </span>
                      )}
                    </div>
                    {
                      <div className={css.bookingPeriod}>
                        <div>
                          <h3>Booking period</h3>
                          <div className={css.currentPeriod}>
                            <span>{moment(booking.attributes.start).format('LLL')}</span>
                            <span> - </span>
                            <span>{moment(booking.attributes.end).format('LLL')}</span>
                          </div>
                        </div>

                        {/*
                      (startTime && endTime) ?
                      (
                        <div>
                          <h3>Updated booking period will be</h3>
                          <div className={css.updatedPeriod}>
                            <span>
                              <FormattedDate value={startDate} {...dateFormatOptions} timeZone={timeZone} />, 
                              <FormattedDate value={startDate} {...timeFormatOptions} timeZone={timeZone} />
                            </span>
                            <span> - </span>
                            <span>
                              <FormattedDate value={endDate} {...dateFormatOptions} timeZone={timeZone} />, 
                              <FormattedDate value={endDate} {...timeFormatOptions} timeZone={timeZone} />
                            </span>
                          </div>
                        </div>
                        
                      ) : null */}
                      </div>
                    }
                    <div className={css.dates}>
                      <div className={css.date}>
                        <FieldDateAndTimeInput
                          {...dateInputProps}
                          className={css.bookingDates}
                          listingId={listing.id}
                          bookingStartLabel={bookingStartLabel}
                          onFetchTimeSlots={onFetchTimeSlots}
                          monthlyTimeSlots={monthlyTimeSlots}
                          values={values}
                          intl={intl}
                          form={form}
                          pristine={pristine}
                          timeZone={timeZone}
                          onChange={(val, prevVal) => console.log(val, prevVal)}
                          isTimeSlotesLoading={isTimeSlotesLoading}
                        />
                      </div>
                    </div>

                    <div className={css.confirmButtons}>
                      <SecondaryButton className={css.confirmButton} onClick={onCancelReschedule}>
                        Cancel
                      </SecondaryButton>
                      <PrimaryButton
                        className={css.confirmButton}
                        inProgress={rescheduleRequestInProgress}
                        disabled={rescheduleRequestInProgress}
                        ready={rescheduledsuccessfully}
                        type="submit"
                      >
                        Reschedule
                      </PrimaryButton>
                    </div>
                  </div>
                </form>
              );
            }}
          />
        </Modal>
      </Portal>
    );
  }
}

RescheduleModal.propTypes = {
  id: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onManageDisableScrolling: PropTypes.func.isRequired,
  heading: PropTypes.node,
  onReschedule: PropTypes.func,
  onCancelReschedule: PropTypes.func,
};

export default RescheduleModal;
