import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';

// Import configs and util modules
import config from '../../config';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { maxLength, required, composeValidators } from '../../util/validators';
import { findConfigForSelectFilter } from '../../util/search';

// Import shared components
import {
  Form,
  FieldSelect,
  Button,
  CustomFieldTextInput,
  FieldTextInput,
  FieldMultipleSelection,
} from '../../components';
// Import modules from this directory
import css from './EditListingDetailsForm.css';
import { subCategoriesProductFilterOptions } from 'marketplace-custom-config';

const TITLE_MAX_LENGTH = 60;

const EditListingDetailsFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        autoFocus,
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        filterConfig,
        values,
      } = formRenderProps;

      const renderCategoryRelatedFields = category => {
        if (!category) {
          return null;
        }
        const findCategory = filterConfig.find(f => f.key === category);
        if (findCategory) {
          const renderFields = findCategory.fields.map(f => {
            return f.type !== 'text' ? (
              <FieldSelect
                className={css.detailsSelect}
                name={f.id}
                id={f.id}
                key={f.id}
                label={f.label}
                validate={required(
                  intl.formatMessage(
                    { id: 'EditListingDetailsForm.fieldRequired' },
                    { value: f.label.toLowerCase() }
                  )
                )}
              >
                <option disabled value="">
                  {intl.formatMessage({ id: 'EditListingDetailsForm.sizePlaceholder' })}
                </option>
                {f.config.options.map(c => (
                  <option key={c.key} value={c.key}>
                    {c.label}
                  </option>
                ))}
              </FieldSelect>
            ) : (
              <FieldTextInput
                id={f.id}
                label={f.label}
                className={css.description}
                type="text"
                name={f.id}
                placeholder={f.placeholder}
                validate={composeValidators(required(f.validateMessage))}
              />
            );
          });

          return renderFields;
        } else {
          return null;
        }
      };
      const optionPaymentMethod = [
        { key: 'card', label: 'Card payment' },
        { key: 'bank', label: 'Bank transfer' },
        { key: 'member', label: 'Membership Number' },
        { key: 'cash', label: 'Cash' },
        { key: 'hitpay', label: 'HitPay' },
      ];
      const selectPaymentmethodLabel = intl.formatMessage({
        id: 'EditListingDescriptionForm.selectPaymentmethodLabel',
      });
      const titleMessage = intl.formatMessage({ id: 'EditListingDetailsForm.title' });
      const titlePlaceholderMessage = intl.formatMessage({
        id: 'EditListingDetailsForm.titlePlaceholder',
      });
      const titleRequiredMessage = intl.formatMessage({
        id: 'EditListingDetailsForm.titleRequired',
      });
      const maxLengthMessage = intl.formatMessage(
        { id: 'EditListingDetailsForm.maxLength' },
        {
          maxLength: TITLE_MAX_LENGTH,
        }
      );

      const bankDetailsLabel = intl.formatMessage({
        id: 'EditListingDescriptionForm.bankDetailsLabel',
      });
      const bankDetailsPlaceholder = intl.formatMessage({
        id: 'EditListingDescriptionForm.bankDetailsPlaceholder',
      });

      const descriptionMessage = intl.formatMessage({
        id: 'EditListingDetailsForm.description',
      });
      const descriptionPlaceholderMessage = intl.formatMessage({
        id: 'EditListingDetailsForm.descriptionPlaceholder',
      });
      const maxLength60Message = maxLength(maxLengthMessage, TITLE_MAX_LENGTH);
      const descriptionRequiredMessage = intl.formatMessage({
        id: 'EditListingDetailsForm.descriptionRequired',
      });

      const { updateListingError, createListingDraftError, showListingsError } = fetchErrors || {};
      const errorMessageUpdateListing = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDetailsForm.updateFailed" />
        </p>
      ) : null;

      // This error happens only on first tab (of EditListingWizard)
      const errorMessageCreateListingDraft = createListingDraftError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDetailsForm.createListingDraftError" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDetailsForm.showListingFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      const categoryConfig = findConfigForSelectFilter('category', filterConfig);
      const categories = categoryConfig.options ? categoryConfig.options : [];
      const categoryLabel = intl.formatMessage({
        id: 'EditListingDetailsForm.categoryLabel',
      });
      const productLabel = intl.formatMessage({
        id: 'EditListingDetailsForm.productLabel',
      });
      const categoryPlaceholder = intl.formatMessage({
        id: 'EditListingDetailsForm.categoryPlaceholder',
      });

      const categoryRequired = required(
        intl.formatMessage({
          id: 'EditListingDetailsForm.categoryRequired',
        })
      );

      // const sizeConfig = findConfigForSelectFilter('size', filterConfig);
      // const sizeSchemaType = sizeConfig ? sizeConfig.schemaType : null;
      // const sizes = sizeConfig && sizeConfig.options ? sizeConfig.options : [];
      // const sizeLabel = intl.formatMessage({
      //   id: 'EditListingDetailsForm.sizeLabel',
      // });
      // const sizePlaceholder = intl.formatMessage({
      //   id: 'EditListingDetailsForm.sizePlaceholder',
      // });

      // const sizeRequired = required(
      //   intl.formatMessage({
      //     id: 'EditListingDetailsForm.sizeRequired',
      //   })
      // );

      // const brandConfig = findConfigForSelectFilter('brand', filterConfig);
      // const brandSchemaType = brandConfig ? brandConfig.schemaType : null;
      // const brands = brandConfig && brandConfig.options ? brandConfig.options : [];
      // const brandLabel = intl.formatMessage({
      //   id: 'EditListingDetailsForm.brandLabel',
      // });
      // const brandPlaceholder = intl.formatMessage({
      //   id: 'EditListingDetailsForm.brandPlaceholder',
      // });

      // const brandRequired = required(
      //   intl.formatMessage({
      //     id: 'EditListingDetailsForm.brandRequired',
      //   })
      // );

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessageCreateListingDraft}
          {errorMessageUpdateListing}
          {errorMessageShowListing}
          <FieldTextInput
            id="title"
            name="title"
            className={css.title}
            type="text"
            label={titleMessage}
            placeholder={titlePlaceholderMessage}
            maxLength={TITLE_MAX_LENGTH}
            validate={composeValidators(required(titleRequiredMessage), maxLength60Message)}
            autoFocus={autoFocus}
          />
          <FieldTextInput
            id="description"
            name="description"
            className={css.description}
            type="textarea"
            label={descriptionMessage}
            placeholder={descriptionPlaceholderMessage}
            validate={composeValidators(required(descriptionRequiredMessage))}
          />
          <CustomFieldTextInput
            id="bankDetails"
            name="bankDetails"
            className={css.bankDetails}
            type="textarea"
            rows="5"
            label={bankDetailsLabel}
            placeholder={bankDetailsPlaceholder}
          />
          <FieldMultipleSelection
            name="payment_method"
            id="payment_method"
            className={css.description}
            label={selectPaymentmethodLabel}
            placeholder={selectPaymentmethodLabel}
            options={optionPaymentMethod}
          />
          <FieldSelect
            className={css.detailsSelect}
            name="sub_category"
            id="sub_category"
            label={categoryLabel}
            validate={categoryRequired}
          >
            <option disabled value="">
              {categoryPlaceholder}
            </option>
            {/* //If adding all the sports categories use  subCategoriesFilterOptions*/}
            {subCategoriesProductFilterOptions.map(c => (
              <option key={c.key} value={c.key}>
                {c.label}
              </option>
            ))}
          </FieldSelect>

          <FieldSelect
            className={css.detailsSelect}
            name="category"
            id="category"
            label={productLabel}
            validate={categoryRequired}
          >
            <option disabled value="">
              {categoryPlaceholder}
            </option>
            {categories.map(c => (
              <option key={c.key} value={c.key}>
                {c.label}
              </option>
            ))}
          </FieldSelect>

          {renderCategoryRelatedFields(values.category)}

          {/* <FieldSelect
            className={css.detailsSelect}
            name="size"
            id="size"
            label={sizeLabel}
            validate={sizeRequired}
          >
            <option disabled value="">
              {sizePlaceholder}
            </option>
            {sizes.map(c => (
              <option key={c.key} value={c.key}>
                {c.label}
              </option>
            ))}
          </FieldSelect>

          <FieldSelect
            className={css.detailsSelect}
            name="brand"
            id="brand"
            label={brandLabel}
            validate={brandRequired}
          >
            <option disabled value="">
              {brandPlaceholder}
            </option>
            {brands.map(c => (
              <option key={c.key} value={c.key}>
                {c.label}
              </option>
            ))}
          </FieldSelect> */}

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingDetailsFormComponent.defaultProps = {
  className: null,
  fetchErrors: null,
  filterConfig: config.custom.productFilters,
};

EditListingDetailsFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  filterConfig: propTypes.filterConfig,
};

export default compose(injectIntl)(EditListingDetailsFormComponent);
