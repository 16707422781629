import React, { useState } from 'react';
import { uid } from 'react-uid';
import { Menu, MenuLabel, MenuContent, MenuItem } from 'components/index';
import PropTypes from 'prop-types';
import { IconSpinner } from 'components/index';
import { IconArrowDown } from 'components/index';

import css from './DropdownButton.css';

function DropdownButton(props) {
  const [isOpen, setIsOpen] = useState(false);
  const labelClassName = props.labelClassName || css.label;

  return (
    <Menu isOpen={isOpen} onToggleActive={setIsOpen} useArrow={false} contentPosition="left">
      <MenuLabel className={labelClassName}>
        {props.inProgress ? (
          <IconSpinner />
        ) : (
          <span>
            {props.label} <IconArrowDown />
          </span>
        )}
      </MenuLabel>
      <MenuContent className={css.menuContent}>
        {props.actions.map(action => (
          <MenuItem key={uid(action.label)}>
            <button
              className={`${css.menuItem}`}
              onClick={() => {
                setIsOpen(false);
                action.handler();
              }}
            >
              <span className={css.menuItemBorder} />

              {action.label}
            </button>
          </MenuItem>
        ))}
      </MenuContent>
    </Menu>
  );
}

DropdownButton.propTypes = {
  // label: PropTypes.oneOf([PropTypes.string, PropTypes.node]),
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      // label: PropTypes.oneOf([PropTypes.string, PropTypes.node]),
      handler: PropTypes.func,
    })
  ),
  inProgress: PropTypes.bool,
  labelClassName: PropTypes.string,
};

export default DropdownButton;
