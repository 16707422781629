import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import css from './MyActivity.css';
import { cancelJoinRequest, onGetJobsBoard } from './MatchActivity.duck';

function JoinedMatches() {
  const dispatch = useDispatch();

  const initSearchValue = {
    keywords: '',
    pub_listing_type: 'match-activity',
    location: '',
    include: ['author', 'author.profileImage'],
    // page: 1,
  };
  const { currentUser } = useSelector(state => state.user);
  const { jobsBoardListing, loading, jobsPagination } = useSelector(state => state.MatchActivity);

  const getListingData = async searchParams => {
    dispatch(onGetJobsBoard(searchParams));
  };

  useEffect(() => {
    const searchValue = {
      ...initSearchValue,
    };

    getListingData(searchValue);
  }, []);

  const presentId = currentUser?.id?.uuid;

  const handleCancel = async (id, joinUserId) => {
    await dispatch(cancelJoinRequest(id, joinUserId));
  };

  return (
    <div className={css.myActivitySection}>
      <div className={css.myActivityMain}>
        {jobsBoardListing.length !== 0 &&
          jobsBoardListing.map(listing => {
            const { joinRequest } = listing.publicData;
            return joinRequest?.map(jionUser => {
              if (presentId === jionUser?.userId && jionUser.showStatus === 'true') {
                return (
                  <div className={css.myActivityWrap} key={listing.id.uuid}>
                    <div className={css.myActivityDetail}>
                      <h5 className={css.myActivityHead}>{listing.publicData?.que_ans[0][0]}</h5>
                      <h5 className={css.myActivitySubHead}>{listing.publicData?.que_ans[0][1]}</h5>
                    </div>
                    <div className={css.myActivityDetail}>
                      <p className={css.myActivityText}>{listing.publicData.que_ans[0][3]}</p>
                      <p className={css.myActivityText}>{listing.publicData.que_ans[0][2]}</p>
                    </div>
                    <div className={css.myActivityDetail}>
                      <p className={css.myActivityText}>
                        {listing.publicData.date.day} - {listing.publicData.date.month},{' '}
                        {listing.publicData.date.week}
                      </p>
                      <p className={css.myActivityText}>
                        {listing.publicData.time.start} - {listing.publicData.time.end}
                      </p>
                    </div>
                    <div className={css.myActivityDetail}>
                      <p className={css.myActivityText}>Hosted By:</p>
                      <p className={css.myActivityText}>{listing.publicData.ownerName}</p>
                    </div>
                    <div className={css.myActivityDetail}>
                      <button
                        className={css.myActivityDeclineBtn}
                        onClick={() => handleCancel(listing.id, jionUser.userId)}
                      >
                        Cancel Request
                      </button>
                    </div>
                  </div>
                );
              }
            });
          })}
        {jobsBoardListing.length === 0 && (
          <div>You have no join requests on your matches yet...</div>
        )}
      </div>
    </div>
  );
}

export default JoinedMatches;
