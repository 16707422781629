import React from 'react';
import masterVerified from 'assets/IconMasterVerified.png';
import newPro from 'assets/IconNewPro.png';
import level1 from 'assets/IconLevel1.png';
import proVerified from 'assets/IconProVerified.png';
import css from './IconLevel.css';

export const NewSeller = props => {
  return <button className={css.newSellerBtn}>New Seller</button>;
};

export const LevelTwo = props => {
  return <button className={css.levelTwoBtn}>Level 2</button>;
};

export const MasterVerified = props => {
  return <button className={css.masterVerifiedBtn}>Master Verified</button>;
};

export const IconMasterVerifiedSmall = props => {
  return <img width="73" height="29" src={masterVerified} alt="Master verified" />;
};

export const IconNew = props => {
  return <img width="147" height="58" src={newPro} alt="New" />;
};

export const IconNewSmall = props => {
  return <img width="73" height="29" src={newPro} alt="New" />;
};
export const IconLevel1Small = props => {
  return <img width="73" height="29" src={level1} alt="Level 1" />;
};
export const IconProVerifiedSmall = props => {
  return <img width="73" height="29" src={proVerified} alt="Pro verified" />;
};