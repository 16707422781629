import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import config from '../../config';
import { withViewport } from '../../util/contextHelpers';
import { IMAGES } from '../../util/imagesPath';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { LayoutWrapperMain, NamedLink, NamedRedirect, Page } from 'components';
import { injectIntl } from '../../util/reactIntl';
import css from './WebAppPage.css';
import classNames from 'classnames';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';

export class WebAppPageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isReviewFetch: false,
      isRedirected: null,
      browserName: null,
    };
  }

  render() {
    const { scrollingDisabled, intl, viewport } = this.props;

    const siteTitle = config.siteTitle;
    const schemaDescription = intl.formatMessage({ id: 'WebAppPage.schemaDescription' });
    const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { siteTitle });
    const schemaImage = `${config.canonicalRootURL}${IMAGES.facebookImage}`;
    const schemaKeyword = intl.formatMessage({ id: 'WebAppPage.schemaKeywords' });

    const MAX_MOBILE_SCREEN_WIDTH = 1024;
    const isMobileLayout = viewport.width ? viewport.width < MAX_MOBILE_SCREEN_WIDTH : false;

    // return <Redirect to={'/search'} />;
    return (
      <Page
        rootClassName={css.pageRootClass}
        scrollingDisabled={scrollingDisabled}
        isBottomMenuRequire={false}
        contentType="website"
        description={schemaDescription}
        keywords={schemaKeyword}
        title={schemaTitle}
        hideCookies={isMobileLayout}
        facebookImages={[{ url: IMAGES.facebookImage, width: 1200, height: 630 }]}
        twitterImages={[
          {
            url: `${config.canonicalRootURL}${IMAGES.twitterImage}`,
            width: 600,
            height: 314,
          },
        ]}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'WebPage',
          description: schemaDescription,
          name: schemaTitle,
          image: [schemaImage],
        }}
      >
        {!!this.state.isRedirected && <NamedRedirect name={this.state.isRedirected} />}
        <LayoutWrapperMain>
          <section className={css.topSection}>
            {/* <div className={css.topHeadContainer}> */}
            <img
              className={css.topSectionImage}
              src={
                isMobileLayout
                  ? 'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1702275570/probuddy/static/images/HomePage/Log_in_page_zllrkt.jpg'
                  : 'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1702275570/probuddy/static/images/HomePage/Log_in_page_zllrkt.jpg'
              }
              alt={' '}
            />
            <div
              className={classNames(css.webPageContent, {
                // [css.webPageContentChrome]: isMobileLayout && this.state.browserName !== 'safari'
              })}
            >
              <div className={css.webAppContentWrap}>
                {/* <div className={css.webAppMainHeading}>Education Marketplace</div> */}
                {/* <div className={css.webAppHeading}>Tennis</div> */}
                <div className={css.webAppImageWrap}>
                  <img
                    className={css.webAppImage}
                    src="https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1721985051/probuddy/static/images/HomePage/book_courts_nr8oht.jpg"
                    alt=""
                  />
                </div>
                <div className={css.educationBtnWrap}>
                  <NamedLink className={classNames(css.educationBtn, css.btn)} name="HomePage">
                   Pickleball
                  </NamedLink>
                </div>
              </div>
              <div className={css.contentDivider}></div>
              <div className={css.webAppContentWrap}>
                {/* <div className={css.webAppMainHeading}>Sports Marketplace</div> */}
                {/* <div className={css.webAppHeading}>Pickleball</div> */}
                <div className={css.webAppImageWrap}>
                  <img
                    className={css.webAppImage}
                    src="https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1721985050/probuddy/static/images/HomePage/find_coach_aortvh.jpg"
                    alt=""
                  />
                </div>
                <div className={css.educationBtnWrap}>
                  <NamedLink className={classNames(css.sportsBtn, css.btn)} name="HomePage">
                  Padel
                  </NamedLink>
                </div>
              </div>
              <div className={css.contentDivider}></div>
              <div className={css.webAppContentWrap}>
                {/* <div className={css.webAppMainHeading}>Community Platform</div> */}
                {/* <div className={css.webAppHeading}>Padel</div> */}
                <div className={css.webAppImageWrap}>
                  <img
                    className={css.webAppImage}
                    src="https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1721985050/probuddy/static/images/HomePage/jobs_board_axtvua.jpg"
                    alt=""
                  />
                </div>
                <div className={css.educationBtnWrap}>
                  <NamedLink className={classNames(css.tennisBtn, css.btn)} name="HomePage">
                  Tennis
                  </NamedLink>
                </div>
              </div>
            </div>
          </section>
        </LayoutWrapperMain>
      </Page>
    );
  }
}

const mapStateToProps = state => {
  const { promotedListingRefs } = state.HomePage;
  return {
    scrollingDisabled: isScrollingDisabled(state),
    promotedListingRefs,
  };
};

const WebAppPage = compose(
  connect(mapStateToProps, null),
  injectIntl,
  withViewport
)(WebAppPageComponent);

export default WebAppPage;
