import React, { memo } from 'react';
import { HorizontalScroll } from '../../../components';

import classNames from 'classnames';
import css from './SubCategoriesFilter.css';

const SubCategoriesFilter = props => {
  const {
    showOpacity,
    showFilter,
    activeFooterTab,
    selectedSubCategory,
    onSubCategorySearch,
    showEnquiryCards,
    isEducationSite,
    isTennisSite,
    options,
  } = props;
  return (
    <div
      className={classNames(css.bottomHeader, {
        [css.opacityBottomHeader]: showOpacity || showFilter,
        [css.bottomHeaderEducational]: isEducationSite,
        [css.bottomHeaderTennis]: isTennisSite,
      })}
    >
      {showOpacity ||
      activeFooterTab === 'create' ||
      activeFooterTab === 'activitySearch' ||
      activeFooterTab === 'leftbar' ? (
        <div className={css.overlayMain} />
      ) : null}
      <div className={css.mobileCategoryContainer}>
        <HorizontalScroll
          isSmallScrollArrow={true}
          className={classNames(css.categoryListContainer, {
            [css.hideSubcategory]: !!showEnquiryCards,
            [css.categoryListContainerWithoutArrow]:
              typeof window !== 'undefined' ? window.screen.width <= 425 : true,
          })}
          offsetWidth={100}
        >
          {options.map((item, index) => (
            <div
              className={classNames(css.item, {
                [css.activeItem]:
                  !isEducationSite &&
                  item.label === (selectedSubCategory && selectedSubCategory.label),
                [css.itemEdu]: isEducationSite,
                [css.activeItemEdu]:
                  isEducationSite &&
                  item.label === (selectedSubCategory && selectedSubCategory.label),
                [css.itemTen]: isTennisSite,
                [css.activeItemTen]:
                  isTennisSite && item.label === (selectedSubCategory && selectedSubCategory.label),
              })}
              key={`${item.title}-${index}`}
              onClick={() => onSubCategorySearch(item)}
            >
              {item.image}
              <div>{item.label}</div>
            </div>
          ))}
        </HorizontalScroll>
      </div>
    </div>
  );
};

export default memo(SubCategoriesFilter);
