import { ManageListingCard, PaginationLinks } from 'components';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { orderBy } from 'lodash';
import {
  closeListing,
  openListing,
  getOwnListingsById,
} from '../../containers/ManageListingsPage/ManageListingsPage.duck';
import css from './InboxPage.css';

export default function MyListings() {
  const {
    currentPageResultIds,
    pagination,
    queryInProgress,
    queryListingsError,
    queryParams,
    openingListing,
    openingListingError,
    closingListing,
    closingListingError,
  } = useSelector(state => state.ManageListingsPage);

  const dispatch = useDispatch();

  const listings = useSelector(state => getOwnListingsById(state, currentPageResultIds));

  const [listingMenuOpen, setlistingMenuOpen] = useState(null);

  const onToggleMenu = listing => {
    setlistingMenuOpen(listing);
  };

  const onCloseListing = listingId => {
    dispatch(closeListing(listingId));
  };

  const onOpenListing = listingId => {
    dispatch(openListing(listingId));
  };

  const page = queryParams ? queryParams.page : 1;
  const hasPaginationInfo = !!pagination && pagination.totalItems != null;
  const sortedListings = listings && orderBy(listings, 'attributes.state', 'desc');
  const closingErrorListingId = !!closingListingError && closingListingError.listingId;
  const openingErrorListingId = !!openingListingError && openingListingError.listingId;
  const listingsAreLoaded = !queryInProgress && hasPaginationInfo;

  const panelWidth = 62.5;
  const renderSizes = [
    `(max-width: 767px) 100vw`,
    `(max-width: 1920px) ${panelWidth / 2}vw`,
    `${panelWidth / 3}vw`,
  ].join(', ');

  //   const loadingResults = (
  //     <h2>
  //       <FormattedMessage id="ManageListingsPage.loadingOwnListings" />
  //     </h2>
  //   );

  //   const queryError = (
  //     <h2 className={css.error}>
  //       <FormattedMessage id="ManageListingsPage.queryError" />
  //     </h2>
  //   );

  //   const noResults =
  //     listingsAreLoaded && pagination.totalItems === 0 ? (
  //       <h1 className={css.title}>
  //         <FormattedMessage id="ManageListingsPage.noResults" />
  //       </h1>
  //     ) : null;

  //   const heading =
  //     listingsAreLoaded && pagination.totalItems > 0 ? (
  //       <h1 className={css.title}>
  //         <FormattedMessage
  //           id="ManageListingsPage.youHaveListings"
  //           values={{ count: pagination.totalItems }}
  //         />
  //       </h1>
  //     ) : listingsAreLoaded && pagination.totalItems === 0 ? (
  //       <h1 className={css.title}>
  //         <FormattedMessage id="ManageListingsPage.noResults" />
  //       </h1>
  //     ) : null;

  const paginationLinks =
    listingsAreLoaded && pagination && pagination.totalPages > 1 ? (
      <PaginationLinks
        className={css.pagination}
        pageName="InboxPage"
        pagePathParams={{ tab: 'myListings' }}
        pageSearchParams={{ page }}
        pagination={pagination}
      />
    ) : null;

  return (
    <>
      {queryInProgress ? (
        <h2>
          <FormattedMessage id="ManageListingsPage.loadingOwnListings" />
        </h2>
      ) : null}
      {queryListingsError ? (
        <h2 className={css.error}>
          <FormattedMessage id="ManageListingsPage.queryError" />
        </h2>
      ) : null}
      <div className={css.listingPanel}>
        {listingsAreLoaded && pagination.totalItems > 0 ? (
          <div className={css.totalItems}>
            <FormattedMessage
              id="ManageListingsPage.youHaveListings"
              values={{ count: pagination.totalItems }}
            />
          </div>
        ) : listingsAreLoaded && pagination.totalItems === 0 ? (
          <h1 className={css.title}>
            <FormattedMessage id="ManageListingsPage.noResults" />
          </h1>
        ) : null}
        <div className={css.listingCards}>
          {sortedListings.map(l => (
            <ManageListingCard
              className={css.listingCard}
              key={l.id.uuid}
              listing={l}
              isMenuOpen={!!listingMenuOpen && listingMenuOpen.id.uuid === l.id.uuid}
              actionsInProgressListingId={openingListing || closingListing}
              onToggleMenu={onToggleMenu}
              onCloseListing={onCloseListing}
              onOpenListing={onOpenListing}
              hasOpeningError={openingErrorListingId.uuid === l.id.uuid}
              hasClosingError={closingErrorListingId.uuid === l.id.uuid}
              renderSizes={renderSizes}
            />
          ))}
        </div>
        {paginationLinks}
      </div>
    </>
  );
}
