import React from 'react';
import { defaultListingCurrency } from '../../util/types';
import { createSlug } from '../../util/urlHelpers';
import { ResponsiveImage, InlineTextButton, SecondaryButton } from 'components';
import { FormattedMessage } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { createResourceLocatorString } from 'util/routes';
import routeConfiguration from '../../routeConfiguration';
import classNames from 'classnames';
import { types as sdkTypes } from '../../util/sdkLoader';
import css from './UserSubscriptions.css';
import config from 'config';

const { Money } = sdkTypes;

const priceData = (price, intl) => {
  if (price && defaultListingCurrency.includes(price.currency)) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ManageListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ManageListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const createListingURL = (routes, listing) => {
  const id = listing.id.uuid;
  const slug = createSlug(listing.attributes.title);

  const linkProps = {
    name: 'ListingPage',
    params: { id, slug },
  };

  return createResourceLocatorString(linkProps.name, routes, linkProps.params, {});
};

const panelWidth = 62.5;
const MAX_LENGTH_FOR_WORDS_IN_TITLE = 7;
const formatTitle = (title, maxLength) => {
  const nonWhiteSpaceSequence = /([^\s]+)/gi;
  return title.split(nonWhiteSpaceSequence).map((word, index) => {
    return word.length > maxLength ? (
      <span key={index} style={{ wordBreak: 'break-all' }}>
        {word}
      </span>
    ) : (
      word
    );
  });
};

const renderSizes = [
  `(max-width: 767px) 100vw`,
  `(max-width: 1920px) ${panelWidth / 2}vw`,
  `${panelWidth / 3}vw`,
].join(', ');

const SubscriptionCard = props => {
  const {
    listing,
    history,
    rootClassName,
    className,
    intl,
    currentUser,
    isSeller,
    openCancelMessage,
  } = props;
  const firstImage = listing.images && listing.images.length > 0 ? listing.images[0] : null;
  const classes = classNames(rootClassName || css.root, className);
  const { title, price, publicData } = listing?.attributes;
  const newPrice = !!publicData?.membershipPrice
    ? new Money(publicData?.membershipPrice, publicData.currency || config.currency)
    : price;
  const { formattedPrice } = priceData(newPrice, intl);
  const subscriptionId = !!isSeller
    ? currentUser?.attributes?.profile?.privateData?.sellerSubscription &&
      currentUser?.attributes?.profile?.privateData?.sellerSubscription[listing.id.uuid]
    : currentUser.attributes.profile.privateData.buyerSubscription &&
      currentUser.attributes.profile.privateData.buyerSubscription[listing.id.uuid];
  // const shareUrl = getCurrentPageUrl().replace('/listings', `/l/${title}/${listing.id.uuid}`);
  return (
    <div className={classes}>
      <div
        className={css.threeToTwoWrapper}
        tabIndex={0}
        onClick={event => {
          event.preventDefault();
          event.stopPropagation();
          history.push(createListingURL(routeConfiguration(), listing));
        }}
      >
        <div className={css.aspectWrapper}>
          <ResponsiveImage
            rootClassName={css.rootForImage}
            alt={title}
            image={firstImage}
            variants={['landscape-crop', 'landscape-crop2x']}
            sizes={renderSizes}
          />
        </div>
      </div>

      <div className={css.info}>
        <div className={css.price}>
          {formattedPrice ? (
            <React.Fragment>
              <div className={css.priceValue}>{formattedPrice}</div>
              <div className={css.perUnit}>
                <FormattedMessage id={'BookingPanel.month'} />
              </div>
            </React.Fragment>
          ) : (
            <div className={css.noPrice}>
              <FormattedMessage id="ManageListingCard.priceNotSet" />
            </div>
          )}
        </div>

        <div className={css.mainInfo}>
          <div className={css.titleWrapper}>
            <InlineTextButton
              rootClassName={css.title}
              onClick={event => {
                event.preventDefault();
                event.stopPropagation();
                history.push(createListingURL(routeConfiguration(), listing));
              }}
            >
              {formatTitle(title, MAX_LENGTH_FOR_WORDS_IN_TITLE)}
            </InlineTextButton>
          </div>
        </div>
      </div>
      <SecondaryButton
        onClick={e => {
          e.preventDefault();
          openCancelMessage(subscriptionId);
        }}
      >
        <FormattedMessage id={'UserSubscription.cancelSubscription'} />
      </SecondaryButton>
    </div>
  );
};

export default SubscriptionCard;
