import React from 'react';
import { instanceOf, string } from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import { isSameDay, formatDateToText } from '../../util/dates';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { DATE_TYPE_DATE, DATE_TYPE_DATETIME, propTypes } from '../../util/types';

import css from './TimeRange.css';

export const TimeRangeComponent = props => {
  const {
    rootClassName,
    className,
    startDate,
    endDate,
    dateType,
    timeZone,
    intl,
    hasGoogleMeetLink,
  } = props;
  const start = formatDateToText(intl, startDate, timeZone);
  const end = formatDateToText(intl, endDate, timeZone);
  const isSingleDay = isSameDay(startDate, endDate, timeZone);

  const classes = classNames(rootClassName || css.root, className);

  const googleMeet = !!hasGoogleMeetLink ? (
    <a
      className={css.dateSection}
      // eslint-disable-next-line
      target="_blank"
      href={hasGoogleMeetLink}
    >
      Google Meet Link
    </a>
  ) : null;

  if (isSingleDay && dateType === DATE_TYPE_DATE) {
    return (
      <div className={classes}>
        <div className={css.dateSection}>{start.date}</div>
        <div className={css.dateSection}>{moment(startDate).format('ddd')}</div>
        {googleMeet}
      </div>
    );
  } else if (dateType === DATE_TYPE_DATE) {
    return (
      <div className={classes}>
        <span className={css.dateSection}>{`${start.date}, ${moment(startDate).format(
          'ddd'
        )} -`}</span>
        <span className={css.dateSection}>{`${end.date}, ${moment(startDate).format('ddd')}`}</span>
        {googleMeet}
      </div>
    );
  } else if (isSingleDay && dateType === DATE_TYPE_DATETIME) {
    return (
      <div className={classes}>
        <div className={css.dateSection}>{start.date}</div>
        <div className={css.dateSection}>{`${moment(startDate).format('ddd')} ${start.time} to ${
          end.time
        }`}</div>
        {googleMeet}
        {/* <span className={css.dateSection}>{`${start.date},${moment(startDate).format('ddd')} ${start.time} to ${end.time}`}</span> */}
      </div>
    );
  } else {
    return (
      <div className={classes}>
        <span className={css.dateSection}>{`${start.dateAndTime} - `}</span>
        <span className={css.dateSection}>{`${end.dateAndTime}`}</span>
        {googleMeet}
      </div>
    );
  }
};

TimeRangeComponent.defaultProps = {
  rootClassName: null,
  className: null,
  dateType: null,
  timeZone: null,
};

TimeRangeComponent.propTypes = {
  rootClassName: string,
  className: string,
  startDate: instanceOf(Date).isRequired,
  endDate: instanceOf(Date).isRequired,
  dateType: propTypes.dateType,
  timeZone: string,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default injectIntl(TimeRangeComponent);
