import silver1 from '../../assets/VendorPage/silver1.svg';
import silver2 from '../../assets/VendorPage/silver2.svg';
import silver3 from '../../assets/VendorPage/silver3.svg';
import gold1 from '../../assets/VendorPage/gold1.svg';
import gold2 from '../../assets/VendorPage/gold2.svg';
import gold3 from '../../assets/VendorPage/gold3.svg';
import platinum1 from '../../assets/VendorPage/platinum1.svg';
import platinum3 from '../../assets/VendorPage/platinum3.svg';
import platinum2 from '../../assets/VendorPage/platinum2.svg';

export const planCards = [
  {
    title: 'Silver',
    price: '$299',
    include: ['10 Classes and or Open Gym', '$50 Probuddy Credits', '1  Wellbeing App'],
    plan: 'Silver-plan-USD-Yearly',
    partners: [
      {
        image: silver1,
        title: 'Crunch',
        des: 'Gym',
      },
      {
        image: silver2,
        title: 'Esporta',
        des: 'Gym',
      },
      {
        image: silver3,
        title: 'Eos',
        des: 'Gym',
      },
    ],
  },
  {
    title: 'Gold',
    price: '$699',
    include: ['18 Classes and or Open Gym', '$200 Probuddy Credits', '2  Wellbeing Apps'],
    plan: 'Gold-plan-USD-Yearly',
    partners: [
      {
        image: gold1,
        title: 'Life Time',
        des: 'Clubs',
      },
      {
        image: gold2,
        title: 'Orangetheory',
        des: 'Gym',
      },
      {
        image: gold3,
        title: 'F45',
        des: 'Gym',
      },
    ],
  },
  {
    title: 'Platinum',
    price: '$1099',
    include: ['30 Classes and or Open Gym ', '$300 Probuddy Credits', '2 Wellbeing Apps'],
    plan: 'Platinum-plan-USD-Yearly',
    partners: [
      {
        image: platinum1,
        title: 'SLT',
        des: 'Studios',
      },
      {
        image: platinum2,
        title: "Barry's",
        des: 'Studios',
      },
      {
        image: platinum3,
        title: 'Dancebody',
        des: 'Studios',
      },
    ],
  },
];

export const tabs = ['What you’ll get', 'Make it your own', 'At your fingertips'];

export const becomeMemberQuestions = [
  'How Long is The package Classes and Subscriptions Valid for?',
  'How Can I get my Company to use Probuddy?',
  'Do i have to Commit to any memberships?',
  'How can I find gyms & apps specific to my location?',
];
