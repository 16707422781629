import React, { useState } from 'react';
import { string, bool, node } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import {
  Form,
  PrimaryButton,
  CustomFieldTextInput,
  LocationAutocompleteInputField,
  FieldCurrencyInput,
  Button,
} from '../../components';
import { propTypes } from '../../util/types';
import config from '../../config';
import { orderBy } from 'lodash';
import { formatMoney } from '../../util/currency';
import {
  autocompleteSearchRequired,
  autocompletePlaceSelected,
  composeValidators,
  moneySubUnitAmountAtLeast,
  required,
} from '../../util/validators';
import { types as sdkTypes } from '../../util/sdkLoader';

import { withViewport } from '../../util/contextHelpers';
import css from './EditListingEnquiryForm.css';

const identity = v => v;
const { Money } = sdkTypes;

const EditListingEnquiryFormComponent = props => {
  // const [category, setCategory] = useState('');
  const [subCategories, setSubCategories] = useState([]);
  const [selectedListingType, setSelectedListingType] = useState('');
  const [selectedSubCategories, setSelectedSubCategories] = useState([]);
  const [showAllSubCategories, setShowAllSubCategories] = useState(false);

  const { isEducationSite } = props;

  return (
    <FinalForm
      {...props}
      // initialValues={}
      render={fieldRenderProps => {
        const {
          form,
          rootClassName,
          className,
          handleSubmit,
          inProgress,
          intl,
          values,
        } = fieldRenderProps;

        const priceRequired = required(
          intl.formatMessage({
            id: 'EditListingPricingForm.priceRequired',
          })
        );

        const minPrice = new Money(config.listingMinimumPriceSubUnits, config.currency);

        const minPriceRequired = moneySubUnitAmountAtLeast(
          intl.formatMessage(
            {
              id: 'EditListingPricingForm.priceTooLow',
            },
            {
              minPrice: formatMoney(intl, minPrice),
            }
          ),
          config.listingMinimumPriceSubUnits
        );

        const priceValidators = config.listingMinimumPriceSubUnits
          ? composeValidators(priceRequired, minPriceRequired)
          : priceRequired;

        const addressPlaceholderMessage = intl.formatMessage({
          id: 'EditListingLocationForm.addressPlaceholder',
        });

        const addressRequiredMessage = intl.formatMessage({
          id: 'EditListingLocationForm.addressRequired',
        });

        const addressNotRecognizedMessage = intl.formatMessage({
          id: 'EditListingLocationForm.addressNotRecognized',
        });

        const classes = classNames(rootClassName || css.root, className);
        const submitInProgress = inProgress;
        const submitDisabled = submitInProgress;
        const textRequired = value => (value ? undefined : 'Required');

        const filterCategories = isEducationSite
          ? config.custom.educationCategories
          : orderBy(
              config.custom.categories.filter(c => c.key !== ''),
              'label',
              'asc'
            );

        const handleCategoryChange = value => {
          const subCategoryConfig = value ? filterCategories.find(cc => cc.key === value) : [];
          const subCategories = (subCategoryConfig && subCategoryConfig.subCategories) || [];
          // setCategory(value);
          setSubCategories(subCategories);
        };

        const getNumberOfSubCategoryListings = () => {
          const width = props.viewport.width;
          if (width <= 550) {
            return 1;
          } else if (width >= 551 && width <= 1199) {
            return 4;
          } else if (width >= 1200 && width <= 1550) {
            return 6;
          } else {
            return 8;
          }
        };

        const handleSelectedSubCategories = subCategory => {
          let updatedVal = [...selectedSubCategories, subCategory];
          const selectedType = selectedSubCategories.includes(subCategory)
            ? updatedVal.filter(e => e !== subCategory)
            : [...updatedVal];
          setSelectedSubCategories(selectedType);
          return selectedType;
        };

        const subcategoriesData = [];
        subCategories &&
          subCategories.map(sc => sc.options.map(type => subcategoriesData.push(type)));

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <div className={css.bookingWrapper}>
              {/* <div className={css.periodSelect}>
                <FieldSelectOutline
                  id="category"
                  name="category"
                  label={'CATEGORY'}
                  validate={textRequired}
                  onChange={handleCategoryChange}
                >
                  <option value={''}>For Example Sports</option>
                  {filterCategories.map(c => (
                    <option key={c.key} value={c.key}>
                      {c.label}
                    </option>
                  ))}
                </FieldSelectOutline>
              </div> */}
              <div className={css.editListingTypeWrap}>
                <label className={css.editListingLabel}>Select Category</label>
                <div className={css.editListingCardWrap}>
                  {filterCategories?.map(type => (
                    <div
                      className={classNames(
                        css.buttonMain,
                        selectedListingType === type.key && css.selected
                      )}
                    >
                      <button
                        id="category"
                        name="category"
                        type="button"
                        onClick={() => {
                          setSelectedListingType(type.key);
                          handleCategoryChange(type.key);
                          setSelectedSubCategories([]);
                          form.change('category', type.key);
                          // this.setErrorMessage(false);
                        }}
                        className={css.buttonWrapper}
                      >
                        <div className={css.editListingTypeText}>{type.label}</div>
                        <div className={css.svgWrapper}>
                          <svg
                            width="27"
                            height="23"
                            viewBox="0 0 27 23"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M21.2165 18.0957H17.9873V22.6165H21.2165V18.0957Z"
                              fill="#A95B2F"
                            />
                            <path
                              d="M8.30046 18.0957H5.07129V22.6165H8.30046V18.0957Z"
                              fill="#A95B2F"
                            />
                            <path
                              d="M18.9565 1.16016L16.1878 5.13528L16.7988 6.01167H15.5775L15.2798 6.43921L15.8132 7.33369H16.4087L16.1491 7.76509L18.361 11.4987H16.3693L17.1075 12.6676H16.0393L18.755 16.9682H12.9425V18.298H26.505L23.0511 12.8297H24.8194L21.5631 7.33369H23.2571L18.9565 1.16016ZM7.42708 1.16016L3.12713 7.33369H4.87927L1.56421 12.8297H3.40613L0 18.298H12.2708V16.9676H6.45833L9.03521 12.8297H9.1366L9.96521 11.4993H8.02254L10.2817 7.75346L10.0337 7.33369H10.6162L11.1329 6.48249L10.8067 6.01167H9.5861L10.1964 5.13528L7.42708 1.16016Z"
                              fill="#0058FA"
                            />
                            <path
                              d="M14.7585 16.8047H11.5293V22.6172H14.7585V16.8047Z"
                              fill="#A95B2F"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M5.85228 18.9707C5.76698 18.9723 5.68574 19.0075 5.62633 19.0687C5.56691 19.13 5.53414 19.2122 5.53518 19.2975V21.7078C5.53518 21.7934 5.5692 21.8755 5.62976 21.9361C5.69032 21.9967 5.77245 22.0307 5.8581 22.0307C5.94374 22.0307 6.02588 21.9967 6.08643 21.9361C6.14699 21.8755 6.18101 21.7934 6.18101 21.7078V19.2975C6.18154 19.2543 6.1734 19.2115 6.15708 19.1715C6.14076 19.1316 6.11659 19.0953 6.08601 19.0649C6.05542 19.0344 6.01903 19.0103 5.979 18.9941C5.93896 18.978 5.8961 18.97 5.85293 18.9707H5.85228ZM12.4927 17.6836C12.4073 17.685 12.3259 17.7201 12.2664 17.7814C12.2068 17.8426 12.174 17.925 12.175 18.0104V21.6826C12.175 21.7682 12.209 21.8504 12.2696 21.911C12.3301 21.9715 12.4123 22.0055 12.4979 22.0055C12.5836 22.0055 12.6657 21.9715 12.7262 21.911C12.7868 21.8504 12.8208 21.7682 12.8208 21.6826V18.0104C12.8214 17.9672 12.8132 17.9244 12.7969 17.8844C12.7806 17.8445 12.7564 17.8082 12.7258 17.7777C12.6952 17.7472 12.6588 17.7232 12.6188 17.7071C12.5788 17.6909 12.5359 17.6829 12.4927 17.6836ZM18.7696 18.9707C18.6842 18.9721 18.6028 19.0072 18.5432 19.0685C18.4837 19.1297 18.4508 19.2121 18.4518 19.2975V21.7078C18.4518 21.7934 18.4859 21.8755 18.5464 21.9361C18.607 21.9967 18.6891 22.0307 18.7748 22.0307C18.8604 22.0307 18.9425 21.9967 19.0031 21.9361C19.0637 21.8755 19.0977 21.7934 19.0977 21.7078V19.2975C19.0982 19.2543 19.0901 19.2115 19.0737 19.1715C19.0574 19.1316 19.0333 19.0953 19.0027 19.0649C18.9721 19.0344 18.9357 19.0103 18.8957 18.9941C18.8556 18.978 18.8128 18.97 18.7696 18.9707Z"
                              fill="#D2885E"
                            />
                            <path
                              d="M13.1439 0L8.84392 6.17353H10.5909L7.28101 11.6605H9.12228L5.7168 17.1301H20.571L17.117 11.6605H19.0068L15.7537 6.17353H17.4438L13.1439 0Z"
                              fill="#FFB81D"
                            />
                          </svg>
                        </div>
                      </button>
                      <div className={css.checkMark}>
                        <svg
                          width="12"
                          height="10"
                          viewBox="0 0 12 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1 5.8L3.85714 9L11 1"
                            stroke="white"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            fill="transparent"
                          />
                        </svg>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className={css.editListingTypeWrap}>
              <label className={css.editListingLabel}>SELECT SUB CATEGORY</label>
              <div className={css.editListingCardWrap}>
                {subcategoriesData &&
                  subcategoriesData
                    .slice(
                      0,
                      showAllSubCategories
                        ? subcategoriesData.length
                        : getNumberOfSubCategoryListings()
                    )
                    .map(type => (
                      <div
                        className={classNames(
                          css.buttonMain,
                          selectedSubCategories?.includes(type.key) && css.selected
                        )}
                      >
                        <button
                          type="button"
                          onClick={() => {
                            handleSelectedSubCategories(type.key);
                            form.change('sub_category', handleSelectedSubCategories(type.key));
                          }}
                          className={css.buttonWrapper}
                        >
                          <div className={css.editListingTypeText}>{type.label}</div>
                          <div className={css.svgWrapper}>
                            {/* <svg
                              width="27"
                              height="23"
                              viewBox="0 0 27 23"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M21.2165 18.0957H17.9873V22.6165H21.2165V18.0957Z"
                                fill="#A95B2F"
                              />
                              <path
                                d="M8.30046 18.0957H5.07129V22.6165H8.30046V18.0957Z"
                                fill="#A95B2F"
                              />
                              <path
                                d="M18.9565 1.16016L16.1878 5.13528L16.7988 6.01167H15.5775L15.2798 6.43921L15.8132 7.33369H16.4087L16.1491 7.76509L18.361 11.4987H16.3693L17.1075 12.6676H16.0393L18.755 16.9682H12.9425V18.298H26.505L23.0511 12.8297H24.8194L21.5631 7.33369H23.2571L18.9565 1.16016ZM7.42708 1.16016L3.12713 7.33369H4.87927L1.56421 12.8297H3.40613L0 18.298H12.2708V16.9676H6.45833L9.03521 12.8297H9.1366L9.96521 11.4993H8.02254L10.2817 7.75346L10.0337 7.33369H10.6162L11.1329 6.48249L10.8067 6.01167H9.5861L10.1964 5.13528L7.42708 1.16016Z"
                                fill="#0058FA"
                              />
                              <path
                                d="M14.7585 16.8047H11.5293V22.6172H14.7585V16.8047Z"
                                fill="#A95B2F"
                              />
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M5.85228 18.9707C5.76698 18.9723 5.68574 19.0075 5.62633 19.0687C5.56691 19.13 5.53414 19.2122 5.53518 19.2975V21.7078C5.53518 21.7934 5.5692 21.8755 5.62976 21.9361C5.69032 21.9967 5.77245 22.0307 5.8581 22.0307C5.94374 22.0307 6.02588 21.9967 6.08643 21.9361C6.14699 21.8755 6.18101 21.7934 6.18101 21.7078V19.2975C6.18154 19.2543 6.1734 19.2115 6.15708 19.1715C6.14076 19.1316 6.11659 19.0953 6.08601 19.0649C6.05542 19.0344 6.01903 19.0103 5.979 18.9941C5.93896 18.978 5.8961 18.97 5.85293 18.9707H5.85228ZM12.4927 17.6836C12.4073 17.685 12.3259 17.7201 12.2664 17.7814C12.2068 17.8426 12.174 17.925 12.175 18.0104V21.6826C12.175 21.7682 12.209 21.8504 12.2696 21.911C12.3301 21.9715 12.4123 22.0055 12.4979 22.0055C12.5836 22.0055 12.6657 21.9715 12.7262 21.911C12.7868 21.8504 12.8208 21.7682 12.8208 21.6826V18.0104C12.8214 17.9672 12.8132 17.9244 12.7969 17.8844C12.7806 17.8445 12.7564 17.8082 12.7258 17.7777C12.6952 17.7472 12.6588 17.7232 12.6188 17.7071C12.5788 17.6909 12.5359 17.6829 12.4927 17.6836ZM18.7696 18.9707C18.6842 18.9721 18.6028 19.0072 18.5432 19.0685C18.4837 19.1297 18.4508 19.2121 18.4518 19.2975V21.7078C18.4518 21.7934 18.4859 21.8755 18.5464 21.9361C18.607 21.9967 18.6891 22.0307 18.7748 22.0307C18.8604 22.0307 18.9425 21.9967 19.0031 21.9361C19.0637 21.8755 19.0977 21.7934 19.0977 21.7078V19.2975C19.0982 19.2543 19.0901 19.2115 19.0737 19.1715C19.0574 19.1316 19.0333 19.0953 19.0027 19.0649C18.9721 19.0344 18.9357 19.0103 18.8957 18.9941C18.8556 18.978 18.8128 18.97 18.7696 18.9707Z"
                                fill="#D2885E"
                              />
                              <path
                                d="M13.1439 0L8.84392 6.17353H10.5909L7.28101 11.6605H9.12228L5.7168 17.1301H20.571L17.117 11.6605H19.0068L15.7537 6.17353H17.4438L13.1439 0Z"
                                fill="#FFB81D"
                              />
                            </svg> */}
                          </div>
                        </button>
                        <div className={css.checkMark}>
                          <svg
                            width="12"
                            height="10"
                            viewBox="0 0 12 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1 5.8L3.85714 9L11 1"
                              stroke="white"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              fill="transparent"
                            />
                          </svg>
                        </div>
                      </div>
                    ))}
                {subcategoriesData && subcategoriesData.length > getNumberOfSubCategoryListings() && (
                  <Button
                    type="button"
                    className={css.productsButton}
                    onClick={() => setShowAllSubCategories(!showAllSubCategories)}
                  >
                    {showAllSubCategories ? (
                      <FormattedMessage id="CompanyPage.viewProductsLess" />
                    ) : (
                      <FormattedMessage
                        id="CompanyPage.viewProductsMore"
                        values={{
                          count: subcategoriesData.length,
                        }}
                      />
                    )}
                  </Button>
                )}
              </div>
            </div>

            {/* <div className={css.bookingWrapper}>
              <div className={css.periodSelect}>
                <FieldSelectOutline
                  id="sub_category"
                  name="sub_category"
                  label={'SUB CATEGORY'}
                  validate={textRequired}
                >
                  {props.listingType === 'company' && !category ? (
                    <option key="" value="">
                      None
                    </option>
                  ) : props.listingType !== 'company' ? (
                    <option key="" value="">
                      For Example Yoga{' '}
                    </option>
                  ) : null}
                  {subCategories
                    ? subCategories.map(sc => (
                        <optgroup label={sc.group}>
                          {sc.options.length
                            ? sc.options.map(opt => (
                                <option key={opt.key} value={opt.key}>
                                  {opt.label}
                                </option>
                              ))
                            : null}
                        </optgroup>
                      ))
                    : null}
                </FieldSelectOutline>
              </div>
            </div> */}

            <div className={css.bookingWrapper}>
              <CustomFieldTextInput
                type="text"
                id="title"
                name="title"
                label="TITLE"
                placeholder="Enter Title"
                validate={textRequired}
              />
            </div>

            <div className={css.bookingWrapper}>
              <CustomFieldTextInput
                className={css.seatsField}
                id={`description`}
                name={`description`}
                type="textarea"
                rows={3}
                label={'DESCRIPTION'}
                placeholder="Enter Description"
                validate={textRequired}
              />
            </div>

            <div className={css.bookingCurrencyWrapper}>
              <FieldCurrencyInput
                id="price"
                name={'price'}
                className={css.seatsField}
                label={'BUDGET'}
                placeholder={'Budget'}
                currencyConfig={config.currencyConfig}
                validate={priceValidators}
              />
            </div>

            <div className={css.bookingWrapper}>
              <CustomFieldTextInput
                type="text"
                id="availability"
                name="availability"
                label="AVAILABILITY"
                placeholder="Enter Availability"
                validate={textRequired}
              />
            </div>

            <div className={css.addressNewWrapper}>
              <LocationAutocompleteInputField
                className={css.locationAddress}
                rootClassName={css.rootClass}
                labelClassName={css.label}
                inputClassName={css.locationInput}
                iconClassName={css.locationAutocompleteInputIcon}
                predictionsClassName={css.predictionsRoot}
                validClassName={css.validLocation}
                autoFocus
                name="location"
                label="ADDRESS"
                placeholder={addressPlaceholderMessage}
                useDefaultPredictions={false}
                format={identity}
                valueFromForm={values.location}
                validate={composeValidators(
                  autocompleteSearchRequired(addressRequiredMessage),
                  autocompletePlaceSelected(addressNotRecognizedMessage)
                )}
              />
            </div>

            <div className={css.requestBtnWrap}>
              <PrimaryButton
                rootClassName={css.requestBtn}
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
              >
                <FormattedMessage id="EnquiryForm.submitButtonText" />
              </PrimaryButton>
            </div>
          </Form>
        );
      }}
    />
  );
};

EditListingEnquiryFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  inProgress: false,
  sendEnquiryError: null,
};

EditListingEnquiryFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  inProgress: bool,

  listingTitle: node.isRequired,
  authorDisplayName: string,
  sendEnquiryError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,
};

const EditListingEnquiryForm = compose(injectIntl, withViewport)(EditListingEnquiryFormComponent);

EditListingEnquiryForm.displayName = 'EditListingEnquiryForm';

export default EditListingEnquiryForm;
