import React, { useCallback } from 'react';
import { Portal, Modal, PrimaryButton } from 'components';
import css from './CSVDownloadModal.css';

function CSVDownloadModal({ 
  id,
  isOpen,
  toggle,
  onConfirm,
  onManageDisableScrolling,
  sortBy1,
  sortBy1Title,
  sortBy2,
  sortBy2Title
}) {
  const onClose = () => {
    toggle(false);
  };

  const onClickHandler = useCallback(value => {
    onConfirm(value);
    toggle(false);
    // eslint-disable-next-line
  }, []);

  return (
    <Portal portalRoot={document.getElementById('portal-root')}>
      <Modal
        id={id}
        isOpen={isOpen}
        onClose={onClose}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <h3>Data will be sorted by?</h3>
        <div className={css.buttons}>
          <PrimaryButton className={css.btn} onClick={() => onClickHandler(sortBy1)}>
            {sortBy1Title}
          </PrimaryButton>

          <PrimaryButton className={css.btn} onClick={() => onClickHandler(sortBy2)}>
            {sortBy2Title}
          </PrimaryButton>
        </div>
      </Modal>
    </Portal>
  );
}

export default CSVDownloadModal;
