import React from 'react';

import css from './ListingPage.css';

const SectionHeading = props => {
  const { richTitle } = props;

  return (
    <div className={css.sectionWrapper}>
      <div className={css.heading}>
        <h1 className={css.title}>{richTitle}</h1>
        <div className={css.author}></div>
      </div>
    </div>
  );
};

export default SectionHeading;
