import { ensureTransaction } from './data';
import moment from 'moment';

/**
 * Transitions
 *
 * These strings must sync with values defined in Flex API,
 * since transaction objects given by API contain info about last transitions.
 * All the actions in API side happen in transitions,
 * so we need to understand what those strings mean.
 */

// When a customer makes a booking to a listing, a transaction is
// created with the initial request-payment transition.
// At this transition a PaymentIntent is created by Marketplace API.
// After this transition, the actual payment must be made on client-side directly to Stripe.
export const TRANSITION_REQUEST_PAYMENT = 'transition/request-payment';

// A customer can also initiate a transaction with an enquiry, and
// then transition that with a request.
export const TRANSITION_ENQUIRE = 'transition/enquire';
export const TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY = 'transition/request-payment-after-enquiry';

// Stripe SDK might need to ask 3D security from customer, in a separate front-end step.
// Therefore we need to make another transition to Marketplace API,
// to tell that the payment is confirmed.
export const TRANSITION_CONFIRM_PAYMENT = 'transition/confirm-payment';

// In case of hitpay we are confriming status of payment from backend when we receive webhook response from hitpay server
export const TRANSITION_CONFIRM_PAYMENT_BY_OPERATOR = 'transition/confirm-payment-by-operator';

// If the payment is not confirmed in the time limit set in transaction process (by default 15min)
// the transaction will expire automatically.
export const TRANSITION_EXPIRE_PAYMENT = 'transition/expire-payment';

// When the provider accepts or declines a transaction from the
// SalePage, it is transitioned with the accept or decline transition.
export const TRANSITION_ACCEPT = 'transition/accept';
export const TRANSITION_DECLINE = 'transition/decline';
export const TRANSITION_MARK_DELIVERED = 'transition/mark-delivered';

// Accepted transaction before it can be completed, switch into
// ready-to-complete state automatically when after booking end time
export const TRANSITION_SET_READY_TO_COMPLETE = 'transition/set-ready-to-complete';

// The backend automatically expire the transaction.
export const TRANSITION_EXPIRE = 'transition/expire';

// Admin can also cancel the transition.
export const TRANSITION_CANCEL = 'transition/cancel';
export const TRANSITION_CANCEL_READY_TO_COMPLETE = 'transition/cancel-ready-to-complete';

// The backend will mark the transaction completed.
export const TRANSITION_COMPLETE = 'transition/complete';

// Manual variation of complete transition.
export const TRANSITION_COMPLETE_MANUAL = 'transition/complete-manual';

export const TRANSITION_AUTO_COMPLETE = 'transition/auto-complete';

export const TRANSITION_RESCHEDULE_BY_CUSTOMER = 'transition/reschedule-by-customer';
export const TRANSITION_RESCHEDULE_BY_PROVIDER = 'transition/reschedule-by-provider';
export const TRANSITION_RESCHEDULE_BY_PROVIDER_AFTER_READY_TO_COMPLETE =
  'transition/reschedule-by-provider-after-ready-to-complete-state';

// Reviews are given through transaction transitions. Review 1 can be
// by provider or customer, and review 2 will be the other party of
// the transaction.
export const TRANSITION_REVIEW_1_BY_PROVIDER = 'transition/review-1-by-provider';
export const TRANSITION_REVIEW_2_BY_PROVIDER = 'transition/review-2-by-provider';
export const TRANSITION_REVIEW_3_BY_PROVIDER = 'transition/review-3-by-provider';
export const TRANSITION_REVIEW_1_BY_CUSTOMER = 'transition/review-1-by-customer';
export const TRANSITION_REVIEW_2_BY_CUSTOMER = 'transition/review-2-by-customer';
export const TRANSITION_REVIEW_3_BY_CUSTOMER = 'transition/review-3-by-customer';
export const TRANSITION_SKIP_1_BY_PROVIDER = 'transition/skip-1-by-provider';
export const TRANSITION_SKIP_2_BY_PROVIDER = 'transition/skip-2-by-provider';
export const TRANSITION_SKIP_3_BY_PROVIDER = 'transition/skip-3-by-provider';
export const TRANSITION_SKIP_1_BY_CUSTOMER = 'transition/skip-1-by-customer';
export const TRANSITION_SKIP_2_BY_CUSTOMER = 'transition/skip-2-by-customer';
export const TRANSITION_SKIP_3_BY_CUSTOMER = 'transition/skip-3-by-customer';
export const TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD = 'transition/expire-customer-review-period';
export const TRANSITION_EXPIRE_PROVIDER_REVIEW_PERIOD = 'transition/expire-provider-review-period';
export const TRANSITION_EXPIRE_REVIEW_PERIOD = 'transition/expire-review-period';
export const TRANSITION_EXPIRE_PROVIDER_SKIP_PERIOD = 'transition/expire-provider-skip-period';
export const TRANSITION_EXPIRE_CUSTOMER_SKIP_PERIOD = 'transition/expire-customer-skip-period';

/**
 * Actors
 *
 * There are 4 different actors that might initiate transitions:
 */

// Roles of actors that perform transaction transitions
export const TX_TRANSITION_ACTOR_CUSTOMER = 'customer';
export const TX_TRANSITION_ACTOR_PROVIDER = 'provider';
export const TX_TRANSITION_ACTOR_SYSTEM = 'system';
export const TX_TRANSITION_ACTOR_OPERATOR = 'operator';

export const TX_TRANSITION_ACTORS = [
  TX_TRANSITION_ACTOR_CUSTOMER,
  TX_TRANSITION_ACTOR_PROVIDER,
  TX_TRANSITION_ACTOR_SYSTEM,
  TX_TRANSITION_ACTOR_OPERATOR,
];

/**
 * States
 *
 * These constants are only for making it clear how transitions work together.
 * You should not use these constants outside of this file.
 *
 * Note: these states are not in sync with states used transaction process definitions
 *       in Marketplace API. Only last transitions are passed along transaction object.
 */
const STATE_INITIAL = 'initial';
const STATE_ENQUIRY = 'enquiry';
const STATE_PENDING_PAYMENT = 'pending-payment';
const STATE_PAYMENT_EXPIRED = 'payment-expired';
const STATE_PREAUTHORIZED = 'preauthorized';
const STATE_DECLINED = 'declined';
const STATE_ACCEPTED = 'accepted';
const STATE_RESCHEDULED = 'accepted';
const STATE_RESCHEDULED_AFTER_READY_TO_COMPLETE = 'ready-to-complete';
const STATE_CANCELED = 'canceled';
const STATE_READY_TO_COMPLETE = 'ready-to-complete';
const STATE_DELIVERED = 'delivered';
const STATE_REVIEWED = 'reviewed';
const STATE_REVIEWED_BY_CUSTOMER = 'reviewed-by-customer';
const STATE_REVIEWED_BY_PROVIDER = 'reviewed-by-provider';
const STATE_SKIPPED_BY_CUSTOMER = 'skipped-by-customer';
const STATE_SKIPPED_BY_PROVIDER = 'skipped-by-provider';
const STATE_COMPLETED = 'completed';
const STATE_RECEIVED = 'received';
const STATE_AUTO_COMPLETE = 'auto-complete';

/**
 * Description of transaction process
 *
 * You should keep this in sync with transaction process defined in Marketplace API
 *
 * Note: we don't use yet any state machine library,
 *       but this description format is following Xstate (FSM library)
 *       https://xstate.js.org/docs/
 */
const stateDescription = {
  // id is defined only to support Xstate format.
  // However if you have multiple transaction processes defined,
  // it is best to keep them in sync with transaction process aliases.
  id: 'preauth-unit-time-booking/release-1',

  // This 'initial' state is a starting point for new transaction
  initial: STATE_INITIAL,

  // States
  states: {
    [STATE_INITIAL]: {
      on: {
        [TRANSITION_ENQUIRE]: STATE_ENQUIRY,
        [TRANSITION_REQUEST_PAYMENT]: STATE_PENDING_PAYMENT,
      },
    },
    [STATE_ENQUIRY]: {
      on: {
        [TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY]: STATE_PENDING_PAYMENT,
      },
    },

    [STATE_PENDING_PAYMENT]: {
      on: {
        [TRANSITION_EXPIRE_PAYMENT]: STATE_PAYMENT_EXPIRED,
        [TRANSITION_CONFIRM_PAYMENT]: STATE_PREAUTHORIZED,
        [TRANSITION_CONFIRM_PAYMENT_BY_OPERATOR]: STATE_PREAUTHORIZED,
      },
    },

    [STATE_PAYMENT_EXPIRED]: {},
    [STATE_PREAUTHORIZED]: {
      on: {
        [TRANSITION_DECLINE]: STATE_DECLINED,
        [TRANSITION_EXPIRE]: STATE_DECLINED,
        [TRANSITION_ACCEPT]: STATE_ACCEPTED,
      },
    },

    [STATE_DECLINED]: {},
    [STATE_ACCEPTED]: {
      on: {
        [TRANSITION_CANCEL]: STATE_CANCELED,
        [TRANSITION_SET_READY_TO_COMPLETE]: STATE_READY_TO_COMPLETE,
        [TRANSITION_RESCHEDULE_BY_PROVIDER]: STATE_RESCHEDULED,
        [TRANSITION_RESCHEDULE_BY_CUSTOMER]: STATE_RESCHEDULED,
      },
    },

    [STATE_READY_TO_COMPLETE]: {
      on: {
        [TRANSITION_COMPLETE]: STATE_DELIVERED,
        [TRANSITION_COMPLETE_MANUAL]: STATE_DELIVERED,
        [TRANSITION_CANCEL_READY_TO_COMPLETE]: STATE_CANCELED,
        [TRANSITION_RESCHEDULE_BY_PROVIDER_AFTER_READY_TO_COMPLETE]: STATE_RESCHEDULED_AFTER_READY_TO_COMPLETE,
      },
    },

    [STATE_CANCELED]: {},
    [STATE_DELIVERED]: {
      on: {
        [TRANSITION_EXPIRE_REVIEW_PERIOD]: STATE_REVIEWED,
        [TRANSITION_REVIEW_1_BY_CUSTOMER]: STATE_REVIEWED_BY_CUSTOMER,
        [TRANSITION_REVIEW_1_BY_PROVIDER]: STATE_REVIEWED_BY_PROVIDER,
        [TRANSITION_SKIP_1_BY_CUSTOMER]: STATE_SKIPPED_BY_CUSTOMER,
        [TRANSITION_SKIP_1_BY_PROVIDER]: STATE_SKIPPED_BY_PROVIDER,
      },
    },
    [STATE_AUTO_COMPLETE]: {
      on: {
        [TRANSITION_EXPIRE_REVIEW_PERIOD]: STATE_REVIEWED,
        [TRANSITION_REVIEW_1_BY_CUSTOMER]: STATE_REVIEWED_BY_CUSTOMER,
        [TRANSITION_REVIEW_1_BY_PROVIDER]: STATE_REVIEWED_BY_PROVIDER,
        [TRANSITION_SKIP_1_BY_CUSTOMER]: STATE_SKIPPED_BY_CUSTOMER,
        [TRANSITION_SKIP_1_BY_PROVIDER]: STATE_SKIPPED_BY_PROVIDER,
      },
    },

    [STATE_REVIEWED_BY_CUSTOMER]: {
      on: {
        [TRANSITION_REVIEW_2_BY_PROVIDER]: STATE_REVIEWED,
        [TRANSITION_EXPIRE_PROVIDER_REVIEW_PERIOD]: STATE_REVIEWED,
        [TRANSITION_SKIP_3_BY_PROVIDER]: STATE_REVIEWED,
      },
    },
    [STATE_REVIEWED_BY_PROVIDER]: {
      on: {
        [TRANSITION_REVIEW_2_BY_CUSTOMER]: STATE_REVIEWED,
        [TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD]: STATE_REVIEWED,
        [TRANSITION_SKIP_3_BY_CUSTOMER]: STATE_REVIEWED,
      },
    },
    [STATE_SKIPPED_BY_CUSTOMER]: {
      on: {
        [TRANSITION_SKIP_2_BY_PROVIDER]: STATE_REVIEWED,
        [TRANSITION_EXPIRE_PROVIDER_SKIP_PERIOD]: STATE_REVIEWED,
        [TRANSITION_REVIEW_3_BY_PROVIDER]: STATE_REVIEWED,
      },
    },
    [STATE_SKIPPED_BY_PROVIDER]: {
      on: {
        [TRANSITION_SKIP_2_BY_CUSTOMER]: STATE_REVIEWED,
        [TRANSITION_EXPIRE_CUSTOMER_SKIP_PERIOD]: STATE_REVIEWED,
        [TRANSITION_REVIEW_3_BY_CUSTOMER]: STATE_REVIEWED,
      },
    },
    [STATE_REVIEWED]: { type: 'final' },
  },
};

// Note: currently we assume that state description doesn't contain nested states.
const statesFromStateDescription = description => description.states || {};

// Get all the transitions from states object in an array
const getTransitions = states => {
  const stateNames = Object.keys(states);

  const transitionsReducer = (transitionArray, name) => {
    const stateTransitions = states[name] && states[name].on;
    const transitionKeys = stateTransitions ? Object.keys(stateTransitions) : [];
    return [
      ...transitionArray,
      ...transitionKeys.map(key => ({ key, value: stateTransitions[key] })),
    ];
  };

  return stateNames.reduce(transitionsReducer, []);
};

// This is a list of all the transitions that this app should be able to handle.
export const TRANSITIONS = getTransitions(statesFromStateDescription(stateDescription)).map(
  t => t.key
);

// This function returns a function that has given stateDesc in scope chain.
const getTransitionsToStateFn = stateDesc => state =>
  getTransitions(statesFromStateDescription(stateDesc))
    .filter(t => t.value === state)
    .map(t => t.key);

// Get all the transitions that lead to specified state.
const getTransitionsToState = getTransitionsToStateFn(stateDescription);

// This is needed to fetch transactions that need response from provider.
// I.e. transactions which provider needs to accept or decline
export const transitionsToRequested = getTransitionsToState(STATE_PREAUTHORIZED);

/**
 * Helper functions to figure out if transaction is in a specific state.
 * State is based on lastTransition given by transaction object and state description.
 */

const txLastTransition = tx => ensureTransaction(tx).attributes.lastTransition;

export const txIsEnquired = tx =>
  getTransitionsToState(STATE_ENQUIRY).includes(txLastTransition(tx));

export const txIsProductPurchase = tx =>
  getTransitionsToState(STATE_AUTO_COMPLETE).includes(txLastTransition(tx));

export const txIsPaymentPending = tx =>
  getTransitionsToState(STATE_PENDING_PAYMENT).includes(txLastTransition(tx));

export const txIsPaymentExpired = tx =>
  getTransitionsToState(STATE_PAYMENT_EXPIRED).includes(txLastTransition(tx));

// Note: state name used in Marketplace API docs (and here) is actually preauthorized
// However, word "requested" is used in many places so that we decided to keep it.
export const txIsRequested = tx =>
  getTransitionsToState(STATE_PREAUTHORIZED).includes(txLastTransition(tx));

export const txIsAccepted = tx => {
  return getTransitionsToState(STATE_ACCEPTED).includes(txLastTransition(tx));
};

export const txIsRescheduled = tx => {
  return [
    TRANSITION_RESCHEDULE_BY_CUSTOMER,
    TRANSITION_RESCHEDULE_BY_PROVIDER,
    TRANSITION_RESCHEDULE_BY_PROVIDER_AFTER_READY_TO_COMPLETE,
  ].includes(txLastTransition(tx));
};

export const txIsRescheduledByCustomer = tx => {
  return [TRANSITION_RESCHEDULE_BY_CUSTOMER].includes(txLastTransition(tx));
};

export const txIsRescheduledByProvider = tx => {
  return [
    TRANSITION_RESCHEDULE_BY_PROVIDER,
    TRANSITION_RESCHEDULE_BY_PROVIDER_AFTER_READY_TO_COMPLETE,
  ].includes(txLastTransition(tx));
};

export const txIsReadyToComplete = tx =>
  getTransitionsToState(STATE_READY_TO_COMPLETE).includes(txLastTransition(tx));

export const txIsDeclined = tx =>
  getTransitionsToState(STATE_DECLINED).includes(txLastTransition(tx));

export const txIsCanceled = tx => {
  return getTransitionsToState(STATE_CANCELED).includes(txLastTransition(tx));
};
export const txIsReceived = tx =>
  getTransitionsToState(STATE_RECEIVED).includes(txLastTransition(tx));
export const txIsCompleted = tx =>
  getTransitionsToState(STATE_COMPLETED).includes(txLastTransition(tx));

export const txIsDelivered = tx =>
  getTransitionsToState(STATE_DELIVERED).includes(txLastTransition(tx));

const firstReviewTransitions = [
  ...getTransitionsToState(STATE_REVIEWED_BY_CUSTOMER),
  ...getTransitionsToState(STATE_REVIEWED_BY_PROVIDER),
];
export const txIsInFirstReview = tx => firstReviewTransitions.includes(txLastTransition(tx));

export const txIsInFirstReviewBy = (tx, isCustomer) =>
  isCustomer
    ? getTransitionsToState(STATE_REVIEWED_BY_CUSTOMER).includes(txLastTransition(tx))
    : getTransitionsToState(STATE_REVIEWED_BY_PROVIDER).includes(txLastTransition(tx));

export const txIsReviewed = tx =>
  getTransitionsToState(STATE_REVIEWED).includes(txLastTransition(tx));

/**
 * Helper functions to figure out if transaction has passed a given state.
 * This is based on transitions history given by transaction object.
 */

const txTransitions = tx => ensureTransaction(tx).attributes.transitions || [];
const hasPassedTransition = (transitionName, tx) =>
  !!txTransitions(tx).find(t => t.transition === transitionName);

const hasPassedStateFn = state => tx =>
  getTransitionsToState(state).filter(t => hasPassedTransition(t, tx)).length > 0;

export const txHasPassedPaymentPending = hasPassedStateFn(STATE_PENDING_PAYMENT);
export const txHasBeenAccepted = hasPassedStateFn(STATE_ACCEPTED);
export const txHasBeenDelivered = hasPassedStateFn(STATE_DELIVERED);
export const txProductHasBeenDelivered = tx =>
  [...getTransitionsToState(STATE_DELIVERED), TRANSITION_MARK_DELIVERED].filter(t =>
    hasPassedTransition(t, tx)
  ).length > 0;

/**
 * Other transaction related utility functions
 */

export const transitionIsReviewed = transition =>
  getTransitionsToState(STATE_REVIEWED).includes(transition);

export const transitionIsFirstReviewedBy = (transition, isCustomer) =>
  isCustomer
    ? getTransitionsToState(STATE_REVIEWED_BY_CUSTOMER).includes(transition)
    : getTransitionsToState(STATE_REVIEWED_BY_PROVIDER).includes(transition);

export const getReview1Transition = isCustomer =>
  isCustomer ? TRANSITION_REVIEW_1_BY_CUSTOMER : TRANSITION_REVIEW_1_BY_PROVIDER;

export const getReview2Transition = isCustomer =>
  isCustomer ? TRANSITION_REVIEW_2_BY_CUSTOMER : TRANSITION_REVIEW_2_BY_PROVIDER;

export const getReview3Transition = isCustomer =>
  isCustomer ? TRANSITION_REVIEW_3_BY_CUSTOMER : TRANSITION_REVIEW_3_BY_PROVIDER;

export const getSkip1Transition = isCustomer =>
  isCustomer ? TRANSITION_SKIP_1_BY_CUSTOMER : TRANSITION_SKIP_1_BY_PROVIDER;

export const getSkip2Transition = isCustomer =>
  isCustomer ? TRANSITION_SKIP_2_BY_CUSTOMER : TRANSITION_SKIP_2_BY_PROVIDER;

export const getSkip3Transition = isCustomer =>
  isCustomer ? TRANSITION_SKIP_3_BY_CUSTOMER : TRANSITION_SKIP_3_BY_PROVIDER;

// Check if a transition is the kind that should be rendered
// when showing transition history (e.g. ActivityFeed)
// The first transition and most of the expiration transitions made by system are not relevant
export const isRelevantPastTransition = transition => {
  return [
    TRANSITION_ACCEPT,
    TRANSITION_CANCEL,
    TRANSITION_CANCEL_READY_TO_COMPLETE,
    TRANSITION_SET_READY_TO_COMPLETE,
    TRANSITION_COMPLETE,
    TRANSITION_COMPLETE_MANUAL,
    TRANSITION_CONFIRM_PAYMENT,
    TRANSITION_CONFIRM_PAYMENT_BY_OPERATOR,
    TRANSITION_DECLINE,
    TRANSITION_EXPIRE,
    TRANSITION_AUTO_COMPLETE,
    TRANSITION_MARK_DELIVERED,
    TRANSITION_REVIEW_1_BY_CUSTOMER,
    TRANSITION_REVIEW_1_BY_PROVIDER,
    TRANSITION_REVIEW_2_BY_CUSTOMER,
    TRANSITION_REVIEW_2_BY_PROVIDER,
    TRANSITION_REVIEW_3_BY_CUSTOMER,
    TRANSITION_REVIEW_3_BY_PROVIDER,
    TRANSITION_SKIP_1_BY_PROVIDER,
    TRANSITION_SKIP_1_BY_CUSTOMER,
    TRANSITION_SKIP_2_BY_PROVIDER,
    TRANSITION_SKIP_2_BY_CUSTOMER,
    TRANSITION_SKIP_3_BY_PROVIDER,
    TRANSITION_SKIP_3_BY_CUSTOMER,
  ].includes(transition);
};

export const isCustomerReview = transition => {
  return [
    TRANSITION_REVIEW_1_BY_CUSTOMER,
    TRANSITION_REVIEW_2_BY_CUSTOMER,
    TRANSITION_REVIEW_3_BY_CUSTOMER,
  ].includes(transition);
};

export const isProviderReview = transition => {
  return [
    TRANSITION_REVIEW_1_BY_PROVIDER,
    TRANSITION_REVIEW_2_BY_PROVIDER,
    TRANSITION_REVIEW_3_BY_PROVIDER,
  ].includes(transition);
};

export const getUserTxRole = (currentUserId, transaction) => {
  const tx = ensureTransaction(transaction);
  const customer = tx.customer;
  if (currentUserId && currentUserId.uuid && tx.id && customer.id) {
    // user can be either customer or provider
    return currentUserId.uuid === customer.id.uuid
      ? TX_TRANSITION_ACTOR_CUSTOMER
      : TX_TRANSITION_ACTOR_PROVIDER;
  } else {
    throw new Error(`Parameters for "userIsCustomer" function were wrong.
      currentUserId: ${currentUserId}, transaction: ${transaction}`);
  }
};

export const txRoleIsProvider = userRole => userRole === TX_TRANSITION_ACTOR_PROVIDER;
export const txRoleIsCustomer = userRole => userRole === TX_TRANSITION_ACTOR_CUSTOMER;

export const COMPLETE_TRANSITIONS = [
  TRANSITION_COMPLETE,
  TRANSITION_COMPLETE_MANUAL,
  TRANSITION_AUTO_COMPLETE,
  TRANSITION_MARK_DELIVERED,
  TRANSITION_REVIEW_1_BY_PROVIDER,
  TRANSITION_REVIEW_2_BY_PROVIDER,
  TRANSITION_REVIEW_3_BY_PROVIDER,
  TRANSITION_REVIEW_1_BY_CUSTOMER,
  TRANSITION_REVIEW_2_BY_CUSTOMER,
  TRANSITION_REVIEW_3_BY_CUSTOMER,
  TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD,
  TRANSITION_EXPIRE_PROVIDER_REVIEW_PERIOD,
  TRANSITION_EXPIRE_REVIEW_PERIOD,
  TRANSITION_SKIP_1_BY_CUSTOMER,
  TRANSITION_SKIP_2_BY_CUSTOMER,
  TRANSITION_SKIP_3_BY_CUSTOMER,
  TRANSITION_SKIP_1_BY_PROVIDER,
  TRANSITION_SKIP_2_BY_PROVIDER,
  TRANSITION_SKIP_3_BY_PROVIDER,
  TRANSITION_EXPIRE_CUSTOMER_SKIP_PERIOD,
  TRANSITION_EXPIRE_PROVIDER_SKIP_PERIOD,
];

export const getTransitionsByState = state => {
  switch (state) {
    case 'pending':
      return [TRANSITION_CONFIRM_PAYMENT, TRANSITION_CONFIRM_PAYMENT_BY_OPERATOR];
    case 'accepted':
      return [
        TRANSITION_ACCEPT,
        TRANSITION_RESCHEDULE_BY_PROVIDER,
        TRANSITION_RESCHEDULE_BY_CUSTOMER,
      ];
    case 'canceled':
      return [TRANSITION_CANCEL, TRANSITION_CANCEL_READY_TO_COMPLETE];
    case 'rescheduled':
      return [
        TRANSITION_RESCHEDULE_BY_PROVIDER,
        TRANSITION_RESCHEDULE_BY_CUSTOMER,
        TRANSITION_RESCHEDULE_BY_PROVIDER_AFTER_READY_TO_COMPLETE,
      ];
    case STATE_READY_TO_COMPLETE:
      return [
        TRANSITION_SET_READY_TO_COMPLETE,
        TRANSITION_RESCHEDULE_BY_PROVIDER_AFTER_READY_TO_COMPLETE,
      ];
    case 'upcoming':
      return [
        TRANSITION_EXPIRE_PAYMENT,
        TRANSITION_CONFIRM_PAYMENT,
        TRANSITION_CONFIRM_PAYMENT_BY_OPERATOR,
        TRANSITION_ACCEPT,
      ];
    case 'delivered':
      return [...COMPLETE_TRANSITIONS];
    case 'completed':
      return COMPLETE_TRANSITIONS;
    case 'all':
      return [...TRANSITIONS, TRANSITION_AUTO_COMPLETE, TRANSITION_MARK_DELIVERED];
    default:
      return [...TRANSITIONS, TRANSITION_AUTO_COMPLETE, TRANSITION_MARK_DELIVERED];
  }
};

export const wasTransactionCreatedBeforeTimestamp = (tx, beforeTime) => {
  const firstTransitionCreatedAt = tx.attributes.transitions[0].createdAt;
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const timeStampPoint = moment(beforeTime)
    .tz(timeZone)
    .toDate();
  return moment(firstTransitionCreatedAt).isBefore(timeStampPoint);
};

export const filterTxByListing = (listingId, txs) => {
  return txs.filter(tx => tx.listing.id.uuid === listingId.uuid);
};

export const isPrivileged = transition => {
  return [TRANSITION_REQUEST_PAYMENT, TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY].includes(
    transition
  );
};
