/*
 * Marketplace specific configuration.
 */
import React from 'react';
// import { ReactComponent as Outdoor } from './assets/HomePage/subCategory/outdoor.svg';
import { ReactComponent as Adventure } from './assets/HomePage/subCategory/Adventure.svg';
// import { ReactComponent as Basketball } from './assets/HomePage/subCategory/Basketball.svg';
// import { ReactComponent as Cycling } from './assets/HomePage/subCategory/Cycling.svg';
import { ReactComponent as FitnessClasses } from './assets/HomePage/subCategory/FitnessClasses.svg';
// import { ReactComponent as Fooball } from './assets/HomePage/subCategory/Football.svg';
import { ReactComponent as RacketSports } from './assets/HomePage/subCategory/RacketSports.svg';
import { ReactComponent as Running } from './assets/HomePage/subCategory/Running.svg';
import { ReactComponent as Swimming } from './assets/HomePage/subCategory/Swimming.svg';
// import { ReactComponent as Wellness } from './assets/HomePage/subCategory/Wellness.svg';
import { ReactComponent as Yoga } from './assets/HomePage/subCategory/Yoga.svg';

import { ReactComponent as Tennis } from './assets/CategoriesPage/icons/Tennis.svg';
import { ReactComponent as TableTennis } from './assets/CategoriesPage/icons/TableTennis.svg';
import { ReactComponent as Badminton } from './assets/CategoriesPage/icons/Badminton.svg';
import { ReactComponent as Squash } from './assets/CategoriesPage/icons/Squash.svg';
import { ReactComponent as PaddleTennis } from './assets/CategoriesPage/icons/PaddleTennis.svg';
import { ReactComponent as PickleBall } from './assets/CategoriesPage/icons/PickleBall.svg';
import { ReactComponent as IconYoga } from './assets/CategoriesPage/icons/Yoga.svg';
import { ReactComponent as Pilates } from './assets/CategoriesPage/icons/Pilates.svg';
// import { ReactComponent as Dance } from './assets/CategoriesPage/icons/Dance.svg';
// import { ReactComponent as Barre } from './assets/CategoriesPage/icons/Barre.svg';
import { ReactComponent as Meditation } from './assets/CategoriesPage/icons/Meditation.svg';
import { ReactComponent as PersonalTraining } from './assets/CategoriesPage/icons/PersonalTraining.svg';
// import { ReactComponent as Crossfit } from './assets/CategoriesPage/icons/Crossfit.svg';
import { ReactComponent as OlympicLifting } from './assets/CategoriesPage/icons/OlympicLifting.svg';
import { ReactComponent as IconCycling } from './assets/CategoriesPage/icons/Cycling.svg';
import { ReactComponent as RockClimbing } from './assets/CategoriesPage/icons/RockClimbing.svg';
import { ReactComponent as Bootcamp } from './assets/CategoriesPage/icons/Bootcamp.svg';
import { ReactComponent as WakeBoarding } from './assets/CategoriesPage/icons/WakeBoarding.svg';
import { ReactComponent as ObstacleSports } from './assets/CategoriesPage/icons/ObstacleSports.svg';
import { ReactComponent as Surfing } from './assets/CategoriesPage/icons/Surfing.svg';
import { ReactComponent as KiteSurfing } from './assets/CategoriesPage/icons/KiteSurfing.svg';
import { ReactComponent as Skateboarding } from './assets/CategoriesPage/icons/SkateBoarding.svg';
// import { ReactComponent as MartialArts } from './assets/CategoriesPage/icons/MartialArts.svg';
// import { ReactComponent as Wrestling } from './assets/CategoriesPage/icons/Wrestling.svg';
// import { ReactComponent as Boxing } from './assets/CategoriesPage/icons/Boxing.svg';
// import { ReactComponent as Kickboxing } from './assets/CategoriesPage/icons/Kickboxing.svg';
import { ReactComponent as Golf } from './assets/CategoriesPage/icons/MartialArts.svg';
import { ReactComponent as Cricket } from './assets/CategoriesPage/icons/Cricket.svg';
import { ReactComponent as IconBasketball } from './assets/CategoriesPage/icons/Basketball.svg';
// import { ReactComponent as Netball } from './assets/CategoriesPage/icons/Netball.svg';
import { ReactComponent as Hockey } from './assets/CategoriesPage/icons/Hockey.svg';
import { ReactComponent as Baseball } from './assets/CategoriesPage/icons/Baseball.svg';

import { ReactComponent as CrossCountryRunning } from './assets/CategoriesPage/icons/CrossCountryRunning.svg';

import { ReactComponent as Massage } from './assets/CategoriesPage/icons/Wellness/Massage.svg';
import { ReactComponent as PhysicalTherapy } from './assets/CategoriesPage/icons/Wellness/PhysicalTherapy.svg';
// import { ReactComponent as Chiropractor } from './assets/CategoriesPage/icons/Wellness/Chiropractor.svg';
// import { ReactComponent as Reflexology } from './assets/CategoriesPage/icons/Wellness/Reflexology.svg';
import { ReactComponent as Prenatal } from './assets/CategoriesPage/icons/Wellness/Prenatal.svg';
import { ReactComponent as Acupuncture } from './assets/CategoriesPage/icons/Wellness/Acupuncture.svg';
import { ReactComponent as Cryotherapy } from './assets/CategoriesPage/icons/Wellness/Cryotherapy.svg';
// import { ReactComponent as TCMPractice } from './assets/CategoriesPage/icons/Wellness/TCMPractice.svg';
import { ReactComponent as Nutrition } from './assets/CategoriesPage/icons/Wellness/Nutrition.svg';
import { ReactComponent as Spas } from './assets/CategoriesPage/icons/Wellness/Spas.svg';
// import { ReactComponent as FaceTreatment } from './assets/CategoriesPage/icons/Wellness/FaceTreatment.svg';
// import { ReactComponent as HairRemoval } from './assets/CategoriesPage/icons/Wellness/HairRemoval.svg';
// import { ReactComponent as NailSalon } from './assets/CategoriesPage/icons/Wellness/NailSalon.svg';
// import { ReactComponent as HairSalon } from './assets/CategoriesPage/icons/Wellness/HairSalon.svg';
// import { ReactComponent as Tattoos } from './assets/CategoriesPage/icons/Wellness/Tattoos.svg';
// import { ReactComponent as Tanning } from './assets/CategoriesPage/icons/Wellness/Tanning.svg';

import { ReactComponent as Music } from './assets/EducationPage/music.svg';
import { ReactComponent as Biology } from './assets/EducationPage/Biology.svg';
import { ReactComponent as AcademicWriting } from './assets/EducationPage/academicWriting.svg';
// import { ReactComponent as BussinessDevelopment } from './assets/EducationPage/bussinessDevelopment.svg';
import { ReactComponent as Chemistry } from './assets/EducationPage/Chemistry.svg';
import { ReactComponent as Design } from './assets/EducationPage/Design.svg';
import { ReactComponent as Development } from './assets/EducationPage/Development.svg';
import { ReactComponent as Engineering } from './assets/EducationPage/engineering.svg';
import { ReactComponent as English } from './assets/EducationPage/English.svg';
// import { ReactComponent as FinanceAndAccounting } from './assets/EducationPage/financeAndAccounting.svg';
import { ReactComponent as French } from './assets/EducationPage/French.svg';
import { ReactComponent as Geography } from './assets/EducationPage/Geography.svg';
import { ReactComponent as German } from './assets/EducationPage/German.svg';
// import { ReactComponent as HealthAndFitness } from './assets/EducationPage/healthAndFitness.svg';
import { ReactComponent as Itandsoftware } from './assets/EducationPage/Itandsoftware.svg';
import { ReactComponent as LanguageLearning } from './assets/EducationPage/lanuageLearning.svg';
// import { ReactComponent as Lifestyle } from './assets/EducationPage/Lifestyle.svg';
// import { ReactComponent as Marketing } from './assets/EducationPage/marketing.svg';
import { ReactComponent as Math } from './assets/EducationPage/math.svg';
import { ReactComponent as OfficeProductivity } from './assets/EducationPage/officeProduct.svg';
// import { ReactComponent as Photography } from './assets/EducationPage/Photography.svg';
import { ReactComponent as Physics } from './assets/EducationPage/Physics.svg';
import { ReactComponent as Bussiness } from './assets/EducationPage/Bussiness.svg';

import YogaIcon from './assets/landingPage/icons/yogaIcon.svg';
import BuildingIcon from './assets/landingPage/icons/buildingIcon.svg';
import UserIcon from './assets/landingPage/icons/userIcon.svg';
import MultipleUserIcon from './assets/landingPage/icons/multipleUserIcon.svg';

import ReviewImage1 from './assets/CategoriesPage/ReviewImage1.png';
import ReviewImage2 from './assets/CategoriesPage/ReviewImage2.png';
import ReviewImage3 from './assets/CategoriesPage/ReviewImage3.png';
import ReviewImage4 from './assets/CategoriesPage/ReviewImage4.png';
import ReviewImage5 from './assets/CategoriesPage/ReviewImage5.png';
import ReviewImage6 from './assets/CategoriesPage/ReviewImage6.png';

import { types as sdkTypes } from './util/sdkLoader';

import la from './assets/HomePage/locationTopbar/la.png';
import london from './assets/HomePage/locationTopbar/london.png';
import melbourne from './assets/HomePage/locationTopbar/melbourne.png';
import newyork from './assets/HomePage/locationTopbar/newyork.png';
import singapore from './assets/HomePage/locationTopbar/singapore.png';
import sydney from './assets/HomePage/locationTopbar/sydney.png';
import chicago from './assets/HomePage/locationTopbar/chicago.png';
import washington from './assets/HomePage/locationTopbar/washington.png';
import manchester from './assets/HomePage/locationTopbar/manchester.jpg';
import sanfrancisco from './assets/HomePage/locationTopbar/sanfrancisco.jpg';
import texas from './assets/HomePage/locationTopbar/texas.jpg';
import philippines from './assets/HomePage/locationTopbar/philippines.jpeg';
import dubai from './assets/HomePage/locationTopbar/dubai.png';

const { LatLng, LatLngBounds } = sdkTypes;

export const city = [
  {
    key: 'singapore',
    label: 'Singapore',
    cityImage: singapore,
    bounds: new LatLngBounds(
      new LatLng(1.4110994, 103.9577613),
      new LatLng(1.2642103, 103.6055395)
    ),
  },
  {
    key: 'sydney',
    label: 'Sydney',
    cityImage: sydney,
    bounds: new LatLngBounds(
      new LatLng(-33.578140996, 151.343020992),
      new LatLng(-34.118344992, 150.520928608)
    ),
  },
  {
    key: 'new-york',
    label: 'New York',
    cityImage: newyork,
    bounds: new LatLngBounds(
      new LatLng(45.0239286969073, -71.7564918092633),
      new LatLng(40.4771391062446, -79.8578350999901)
    ),
  },
  {
    key: 'london',
    label: 'London',
    cityImage: london,
    bounds: new LatLngBounds(new LatLng(51.669993, 0.153177), new LatLng(51.384527, -0.351708)),
  },
  {
    key: 'los-angeles',
    label: 'Los Angeles',
    cityImage: la,
    bounds: new LatLngBounds(
      new LatLng(34.161439, -118.126728),
      new LatLng(33.900014, -118.521447)
    ),
  },
  {
    key: 'melbourne',
    label: 'Melbourne',
    cityImage: melbourne,
    bounds: new LatLngBounds(
      new LatLng(-37.535924, 145.433455),
      new LatLng(-38.154894, 144.568752)
    ),
  },
  {
    key: 'chicago',
    label: 'Chicago',
    cityImage: chicago,
    bounds: new LatLngBounds(
      new LatLng(42.023135, -87.523907294),
      new LatLng(41.644286, -87.869226)
    ),
  },
  {
    key: 'seattle',
    label: 'Seattle',
    cityImage: washington,
    bounds: new LatLngBounds(
      new LatLng(47.734163, -122.218866),
      new LatLng(47.479248, -122.435899)
    ),
  },
  {
    key: 'manchester',
    label: 'Manchester',
    cityImage: manchester,
    bounds: new LatLngBounds(new LatLng(53.586199, -2.087691), new LatLng(53.348598, -2.52355)),
  },
  {
    key: 'san-francisco',
    label: 'San Francisco',
    cityImage: sanfrancisco,
    bounds: new LatLngBounds(
      new LatLng(37.832427, -122.356967),
      new LatLng(37.707798, -122.517688)
    ),
  },
  {
    key: 'dubai',
    label: 'Dubai',
    cityImage: dubai,
    bounds: new LatLngBounds(new LatLng(26.2639, 56.3817), new LatLng(22.6331, 51.4975)),
  },
  {
    key: 'houston',
    label: 'Houston',
    cityImage: texas,
    bounds: new LatLngBounds(new LatLng(30.09345, -95.054091), new LatLng(29.523188, -95.728515)),
  },
];

export const cityEducation = [
  {
    key: 'australia',
    label: 'Australia',
    cityImage: melbourne,
    bounds: new LatLngBounds(
      new LatLng(-9.0436707, 159.200456),
      new LatLng(-54.8327658, 112.8256904)
    ),
  },
  {
    key: 'united-states',
    label: 'United States',
    cityImage: chicago,
    bounds: new LatLngBounds(new LatLng(71.420291, -66.8847656), new LatLng(18.8164227, -179.9)),
  },
  {
    key: 'united-kingdom',
    label: 'United Kingdom',
    cityImage: london,
    bounds: new LatLngBounds(new LatLng(60.9093508, 1.8623843), new LatLng(49.80777, -8.7497874)),
  },
  {
    key: 'singapore',
    label: 'Singapore',
    cityImage: singapore,
    bounds: new LatLngBounds(
      new LatLng(1.4110994, 103.9577613),
      new LatLng(1.2642103, 103.6055395)
    ),
  },
  {
    key: 'philippines',
    label: 'Philippines',
    cityImage: philippines,
    bounds: new LatLngBounds(
      new LatLng(21.2117484, 126.7047199),
      new LatLng(4.4898406, 116.8295833)
    ),
  },
  {
    key: 'dubai',
    label: 'Dubai',
    cityImage: dubai,
    bounds: new LatLngBounds(new LatLng(26.2639, 56.3817), new LatLng(22.6331, 51.4975)),
  },
];

export const subCategoriesFilterOptions = [
  { key: 'tennis', label: 'Tennis', image: <RacketSports /> },
  { key: 'padel tennis', label: 'Padel Tennis', image: <PaddleTennis /> },
  { key: 'pickle ball', label: 'Pickle Ball', image: <PickleBall /> },
  // { key: 'personal training', label: 'Personal Training', image: <Wellness /> },
  // { key: 'yoga', label: 'Yoga', image: <Yoga /> },
  // {
  //   key:
  //     'massage,physical therapy,chiropractor,reflexology,prenatal,acupuncture,cryotherapy,holistic wellness,TCM,nutrition practise',
  //   label: 'Wellness',
  //   image: <Wellness />,
  // },
  // { key: 'pilates', label: 'Pilates', image: <FitnessClasses /> },
  // { key: 'physical therapy', label: 'Physical Therapy', image: <Adventure /> },
  // { key: 'holistic wellness', label: 'Holistic Wellness', image: <Yoga /> },
  // {
  //   key: 'crossfit,yoga,pilates,personal training,swimming,bootcamp',
  //   label: 'Fitness Classes',
  //   image: <FitnessClasses />,
  // },
  // {
  //   key:
  //     'cycling,biking,rock climbing,bootcamp,wake boarding,obstacle sports,surfing,kite surfing,skateboarding',
  //   label: 'Adventure',
  //   image: <Adventure />,
  // }
  // { key: 'golf', label: 'Golf', image: <Outdoor /> },
  // { key: 'boxing', label: 'Boxing', image: <FitnessClasses /> },
  // { key: 'crossfit', label: 'Crossfit', image: <FitnessClasses /> },
  // { key: 'swimming', label: 'Swimming', image: <Swimming /> },
  // { key: 'martial arts', label: 'Martial Arts', image: <Wellness /> },
  // { key: 'rock climbing', label: 'Rock Climbing', image: <Swimming /> },
  // { key: 'indoor cycling', label: 'Indoor Cycling', image: <Cycling /> },
  // { key: 'massage', label: 'Massage', image: <Yoga /> },
  // { key: 'dance', label: 'Dance', image: <Wellness /> },
  // { key: 'karate', label: 'Karate', image: <Adventure /> },
  // { key: 'jiu-jitsu', label: 'Jiu-jitsu', image: <FitnessClasses /> },
  // { key: 'wake boarding', label: 'Wake Boarding', image: <Running /> },
  // { key: 'badminton', label: 'Badminton', image: <RacketSports /> },
  // { key: 'barre', label: 'Barre', image: <FitnessClasses /> },
  // { key: 'bootcamp', label: 'Bootcamp', image: <Cycling /> },
  // { key: 'biking', label: 'Biking', image: <Cycling /> },
  // { key: 'obstacle sports', label: 'Obstacle Sports', image: <Swimming /> },
  // { key: 'cycling', label: 'Cycling', image: <Cycling /> },
  // { key: 'running', label: 'Running', image: <Running /> },
  // { key: 'skateboarding', label: 'Skateboarding', image: <Outdoor /> },
  // { key: 'acupuncture', label: 'Acupuncture', image: <Fooball /> },
  // { key: 'chiropractor', label: 'Chiropractor', image: <Yoga /> },
  // { key: 'cryotherapy', label: 'Cryotherapy', image: <Wellness /> },
  // { key: 'nutrition', label: 'Nutrition', image: <Wellness /> },
  // { key: 'physical therapy', label: 'Physical Therapy', image: <Outdoor /> },
  // { key: 'prenatal', label: 'Prenatal', image: <Fooball /> },
  // { key: 'TCM practise', label: 'TCM Practise', image: <Running /> },
  // { key: 'pickle ball', label: 'Pickle Ball', image: <Basketball /> },
  // { key: 'squash', label: 'Squash', image: <Running /> },
  // { key: 'kick boxing', label: 'Kick Boxing', image: <FitnessClasses /> },
  // {
  //   key: 'tennis,padel tennis,pickle ball,squash,badminton',
  //   label: 'Racket Sports',
  //   image: <RacketSports />,
  // },
];

export const subCategoriesProductFilterOptions = [
  { key: 'physical therapy', label: 'Physical Therapy', image: <Adventure /> },
  { key: 'pilates', label: 'Pilates', image: <FitnessClasses /> },
  { key: 'yoga', label: 'Yoga', image: <Yoga /> },
  { key: 'tennis', label: 'Tennis', image: <RacketSports /> },
];

export const socialMedia = [
  { key: 'facebook', label: 'Facebook' },
  { key: 'instagram', label: 'Instagram' },
  { key: 'linkedin', label: 'Linked In' },
  { key: 'website', label: 'Website' },
];

export const minimumPlayers = [
  { key: '2', label: '2' },
  { key: '3', label: '3' },
  { key: '4', label: '4' },
  { key: '5', label: '5' },
  { key: '6', label: '6' },
  { key: '7', label: '7' },
  { key: '8', label: '8' },
  { key: '9', label: '9' },
  { key: '10', label: '10' },
  { key: '11', label: '11' },
  { key: '12', label: '12' },
  { key: '13', label: '13' },
  { key: '14', label: '14' },
  { key: '15', label: '15' },
  { key: '16', label: '16' },
];

export const dateDay = [
  { key: '1', label: '1' },
  { key: '2', label: '2' },
  { key: '3', label: '3' },
  { key: '4', label: '4' },
  { key: '5', label: '5' },
  { key: '6', label: '6' },
  { key: '7', label: '7' },
  { key: '8', label: '8' },
  { key: '9', label: '9' },
  { key: '10', label: '10' },
  { key: '11', label: '11' },
  { key: '12', label: '12' },
  { key: '13', label: '13' },
  { key: '14', label: '14' },
  { key: '15', label: '15' },
  { key: '16', label: '16' },
  { key: '17', label: '17' },
  { key: '18', label: '18' },
  { key: '19', label: '19' },
  { key: '20', label: '20' },
  { key: '21', label: '21' },
  { key: '22', label: '22' },
  { key: '23', label: '23' },
  { key: '24', label: '24' },
  { key: '25', label: '25' },
  { key: '26', label: '26' },
  { key: '27', label: '27' },
  { key: '28', label: '28' },
  { key: '29', label: '29' },
  { key: '30', label: '30' },
  { key: '31', label: '31' },
];

export const dateMonth = [
  { key: 'jan', label: 'Jan' },
  { key: 'feb', label: 'Feb' },
  { key: 'mar', label: 'Mar' },
  { key: 'apr', label: 'Apr' },
  { key: 'may', label: 'May' },
  { key: 'jun', label: 'Jun' },
  { key: 'jul', label: 'Jul' },
  { key: 'aug', label: 'Aug' },
  { key: 'sep', label: 'Sep' },
  { key: 'oct', label: 'Oct' },
  { key: 'nov', label: 'Nov' },
  { key: 'dec', label: 'Dec' },
];

export const dateWeek = [
  { key: 'monday', label: 'Monday' },
  { key: 'tuesday', label: 'Tuesday' },
  { key: 'wednesday', label: 'Wednesday' },
  { key: 'thrusday', label: 'Thrusday' },
  { key: 'friday', label: 'Friday' },
  { key: 'saturday', label: 'Saturday' },
  { key: 'sunday', label: 'Sunday' },
];

export const maximumPlayers = [
  { key: '2', label: '2' },
  { key: '3', label: '3' },
  { key: '4', label: '4' },
  { key: '5', label: '5' },
  { key: '6', label: '6' },
  { key: '7', label: '7' },
  { key: '8', label: '8' },
  { key: '9', label: '9' },
  { key: '10', label: '10' },
  { key: '11', label: '11' },
  { key: '12', label: '12' },
  { key: '13', label: '13' },
  { key: '14', label: '14' },
  { key: '15', label: '15' },
  { key: '16', label: '16' },
];

export const subCategoriesFilterOptionsEducational = [
  { key: 'chemistry', label: 'Chemistry', image: <Chemistry /> },
  { key: 'math', label: 'Math', image: <Math /> },
  { key: 'english', label: 'English', image: <English /> },
  { key: 'biology', label: 'Biology', image: <Biology /> },
  { key: 'physics', label: 'Physics', image: <Physics /> },
  { key: 'history', label: 'History', image: <OfficeProductivity /> },
  { key: 'science', label: 'science', image: <Chemistry /> },
  { key: 'french', label: 'French', image: <French /> },
  {
    key: 'german',
    label: 'German',
    image: <German />,
  },
  { key: 'geography', label: 'Geography', image: <Geography /> },
  { key: 'engineering', label: 'Engineering', image: <Engineering /> },
  { key: 'academic writing', label: 'Academic Writing', image: <AcademicWriting /> },
  { key: 'development', label: 'Development', image: <Development /> },
  { key: 'business', label: 'Business', image: <Bussiness /> },
  { key: 'language learning', label: 'Language Learning', image: <LanguageLearning /> },
  // {
  //   key: 'finance and accounting',
  //   label: 'Finance & Accounting',
  //   image: <FinanceAndAccounting />,
  // },
  { key: 'it and software', label: 'IT & Software', image: <Itandsoftware /> },
  // {
  //   key: 'office productivity',
  //   label: 'Office productivity',
  //   image: <OfficeProductivity />,
  // },
  {
    key: 'personal development',
    label: 'Personal Development',
    image: <PersonalTraining />,
  },
  // {
  //   key: 'business development',
  //   label: 'Business Development',
  //   image: <BussinessDevelopment />,
  // },
  {
    key: 'design',
    label: 'Design',
    image: <Design />,
  },
  // {
  //   key: 'marketing',
  //   label: 'Marketing',
  //   image: <Marketing />,
  // },
  // { key: 'lifestyle', label: 'Lifestyle', image: <Lifestyle /> },
  // { key: 'photography and video', label: 'Photography & Video', image: <Photography /> },
  // { key: 'health and fitness', label: 'Health & Fitness', image: <HealthAndFitness /> },
  { key: 'music', label: 'Music', image: <Music /> },
];

export const subCategoriesFilterOptionsTennis = [
  { key: 'tennis', label: 'Tennis', image: <RacketSports /> },
  { key: 'padel tennis', label: 'Padel Tennis', image: <PaddleTennis /> },
];

export const languageFilterConfig = [
  { key: 'english_English', label: 'English' },
  { key: 'chinese_Chinese', label: 'Chinese' },
  { key: 'spanish_Spanish', label: 'Spanish' },
  { key: 'russian_Russian', label: 'Russian' },
  { key: 'french_French', label: 'French' },
];

export const kidsPromotions = [
  { key: 'no', label: 'No' },

  {
    key: 'kids',
    label: 'Kids',
    subCategories: [
      {
        group: 'Rockett/Ball sports',
        options: [
          { key: 'tennis', label: 'Tennis', icon: <Tennis /> },
          { key: 'table tennis', label: 'Table Tennis', icon: <TableTennis /> },
          { key: 'badminton', label: 'Badminton', icon: <Badminton /> },
          // { key: 'squash', label: 'Squash', icon: <Squash /> },
          { key: 'padel tennis', label: 'Padel Tennis', icon: <PaddleTennis /> },
          // { key: 'pickle ball', label: 'Pickle Ball', icon: <PickleBall /> },
        ],
      },
      {
        group: 'Movement and Mind',
        options: [
          { key: 'yoga', label: 'Yoga', icon: <IconYoga /> },
          { key: 'pilates', label: 'Pilates', icon: <Pilates /> },
          // { key: 'dance', label: 'Dance', icon: <Dance /> },
          // { key: 'barre', label: 'Barre', icon: <Barre /> },
          { key: 'holistic wellness', label: 'Holistic Wellness', icon: <Meditation /> },
        ],
      },
      {
        group: 'Strenght Focussed',
        options: [
          {
            key: 'personal training',
            label: 'Personal Training',
            icon: <PersonalTraining />,
          },
          // { key: 'crossfit', label: 'Crossfit', icon: <Crossfit /> },
          { key: 'swimming', label: 'Swimming', icon: <Swimming /> },
          { key: 'olympic lifting', label: 'Olympic Lifting', icon: <OlympicLifting /> },
          { key: 'running', label: 'Running', icon: <Running /> },
        ],
      },
      {
        group: 'Adventure/Outdoor',
        options: [
          { key: 'cycling', label: 'Cycling', icon: <IconCycling /> },
          // { key: 'biking', label: 'Biking', icon: <IconCycling /> },
          // { key: 'rock climbing', label: 'Rock Climbing', icon: <RockClimbing /> },
          // { key: 'bootcamp', label: 'Bootcamp', icon: <Bootcamp /> },
          // { key: 'wake boarding', label: 'Wake Boarding', icon: <WakeBoarding /> },
          { key: 'obstacle sports', label: 'Obstacle Sports', icon: <ObstacleSports /> },
          // { key: 'surfing', label: 'Surfing', icon: <Surfing /> },
          // { key: 'kite surfing', label: 'Kite Surfing', icon: <KiteSurfing /> },
          // { key: 'skateboarding', label: 'Skateboarding', icon: <Skateboarding /> },
        ],
      },
      {
        group: 'Combat Sports',
        options: [
          // { key: 'martial arts', label: 'Martial Arts', icon: <MartialArts /> },
          // { key: 'wrestling', label: 'Wrestling', icon: <Wrestling /> },
          // { key: 'boxing', label: 'Boxing', icon: <Boxing /> },
          // { key: 'kick boxing', label: 'Kick Boxing', icon: <Kickboxing /> },
        ],
      },
      {
        group: 'Ball Sports',
        options: [
          { key: 'golf', label: 'Golf', icon: <Golf /> },
          // { key: 'cricket', label: 'Cricket', icon: <Cricket /> },
          { key: 'basketball', label: 'Basketball', icon: <IconBasketball /> },
          // { key: 'netball', label: 'Netball', icon: <Netball /> },
          { key: 'hockey', label: 'Hockey', icon: <Hockey /> },
          { key: 'baseball', label: 'Baseball', icon: <Baseball /> },
          { key: 'soccer', label: 'Soccer', icon: <Baseball /> },
        ],
      },
    ],
  },
  {
    key: 'currentlyPromoted',
    label: 'Currently Promoted',
    subCategories: [
      {
        group: 'Adventure',
        options: [
          { key: 'cycling', label: 'Cycling', icon: <IconCycling /> },
          { key: 'running', label: 'Cross Country Running', icon: <CrossCountryRunning /> },
          { key: 'biking', label: 'Biking', icon: <IconCycling /> },
          { key: 'rock climbing', label: 'Rock Climbing', icon: <RockClimbing /> },
          { key: 'bootcamp', label: 'Bootcamp', icon: <Bootcamp /> },
          { key: 'wake boarding', label: 'Wake Boarding', icon: <WakeBoarding /> },
          { key: 'obstacle sports', label: 'Obstacle Sports', icon: <ObstacleSports /> },
          { key: 'surfing', label: 'Surfing', icon: <Surfing /> },
          { key: 'kite surfing', label: 'Kite Surfing', icon: <KiteSurfing /> },
          { key: 'skateboarding', label: 'Skateboarding', icon: <Skateboarding /> },
          { key: 'diving', label: 'Diving', icon: <KiteSurfing /> },
          { key: 'scuba', label: 'Scuba', icon: <Bootcamp /> },
          { key: 'mountain biking', label: 'Mountain Biking', icon: <IconCycling /> },
          { key: 'sky diving', label: 'Sky Diving', icon: <KiteSurfing /> },
          { key: 'obstacle courses', label: 'Obstacle Courses', icon: <RockClimbing /> },
          { key: '4x4 driving', label: '4x4 driving', icon: <Skateboarding /> },
          { key: 'camping', label: 'Camping', icon: <Bootcamp /> },
          { key: 'boating', label: 'Boating', icon: <Surfing /> },
          { key: 'rafting', label: 'Rafting', icon: <Skateboarding /> },
          { key: 'fishing', label: 'Fishing', icon: <Bootcamp /> },
        ],
      },
    ],
  },
];

export const categories = [
  { key: '', label: 'None', hideFromFilters: true, hideFromListingInfo: true },

  {
    key: 'sports',
    label: 'Sports',
    subCategories: [
      {
        group: 'Rockett/Ball sports',
        options: [
          { key: 'tennis', label: 'Tennis', icon: <Tennis /> },
          { key: 'table tennis', label: 'Table Tennis', icon: <TableTennis /> },
          { key: 'beach tennis', label: 'Beach Tennis', icon: <Squash /> },
          { key: 'badminton', label: 'Badminton', icon: <Badminton /> },
          // { key: 'squash', label: 'Squash', icon: <Squash /> },
          { key: 'padel tennis', label: 'Padel Tennis', icon: <PaddleTennis /> },
          { key: 'pickle ball', label: 'Pickle Ball', icon: <PickleBall /> },
        ],
      },
      {
        group: 'Movement and Mind',
        options: [
          // { key: 'dance', label: 'Dance', icon: <Dance /> },
          // { key: 'barre', label: 'Barre', icon: <Barre /> },
        ],
      },
      {
        group: 'Strenght Focussed',
        options: [
          {
            key: 'personal training',
            label: 'Personal Training',
            icon: <PersonalTraining />,
          },
          // { key: 'crossfit', label: 'Crossfit', icon: <Crossfit /> },
          { key: 'swimming', label: 'Swimming', icon: <Swimming /> },
          { key: 'olympic lifting', label: 'Olympic Lifting', icon: <OlympicLifting /> },
          { key: 'running', label: 'Running', icon: <Running /> },
        ],
      },
      {
        group: 'Adventure/Outdoor',
        options: [
          { key: 'cycling', label: 'Cycling', icon: <IconCycling /> },
          // { key: 'biking', label: 'Biking', icon: <IconCycling /> },
          // { key: 'rock climbing', label: 'Rock Climbing', icon: <RockClimbing /> },
          // { key: 'bootcamp', label: 'Bootcamp', icon: <Bootcamp /> },
          // { key: 'wake boarding', label: 'Wake Boarding', icon: <WakeBoarding /> },
          { key: 'obstacle sports', label: 'Obstacle Sports', icon: <ObstacleSports /> },
          // { key: 'surfing', label: 'Surfing', icon: <Surfing /> },
          // { key: 'kite surfing', label: 'Kite Surfing', icon: <KiteSurfing /> },
          // { key: 'skateboarding', label: 'Skateboarding', icon: <Skateboarding /> },
        ],
      },
      {
        group: 'Combat Sports',
        options: [
          // { key: 'martial arts', label: 'Martial Arts', icon: <MartialArts /> },
          // { key: 'wrestling', label: 'Wrestling', icon: <Wrestling /> },
          // { key: 'boxing', label: 'Boxing', icon: <Boxing /> },
          // { key: 'kick boxing', label: 'Kick Boxing', icon: <Kickboxing /> },
        ],
      },
      {
        group: 'Ball Sports',
        options: [
          { key: 'golf', label: 'Golf', icon: <Golf /> },
          { key: 'cricket', label: 'Cricket', icon: <Cricket /> },
          { key: 'basketball', label: 'Basketball', icon: <IconBasketball /> },
          // { key: 'netball', label: 'Netball', icon: <Netball /> },
          { key: 'hockey', label: 'Hockey', icon: <Hockey /> },
          // { key: 'baseball', label: 'Baseball', icon: <Baseball /> },
          { key: 'soccer', label: 'Soccer', icon: <Baseball /> },
        ],
      },
    ],
  },
  // {
  //   key: 'adventure',
  //   label: 'Adventure',
  //   subCategories: [
  //     {
  //       group: 'Adventure',
  //       options: [
  //         { key: 'cycling', label: 'Cycling', icon: <IconCycling /> },
  //         { key: 'running', label: 'Cross Country Running', icon: <CrossCountryRunning /> },
  //         { key: 'biking', label: 'Biking', icon: <IconCycling /> },
  //         { key: 'rock climbing', label: 'Rock Climbing', icon: <RockClimbing /> },
  //         { key: 'bootcamp', label: 'Bootcamp', icon: <Bootcamp /> },
  //         { key: 'wake boarding', label: 'Wake Boarding', icon: <WakeBoarding /> },
  //         { key: 'obstacle sports', label: 'Obstacle Sports', icon: <ObstacleSports /> },
  //         { key: 'surfing', label: 'Surfing', icon: <Surfing /> },
  //         { key: 'kite surfing', label: 'Kite Surfing', icon: <KiteSurfing /> },
  //         { key: 'skateboarding', label: 'Skateboarding', icon: <Skateboarding /> },
  //         { key: 'diving', label: 'Diving', icon: <KiteSurfing /> },
  //         { key: 'scuba', label: 'Scuba', icon: <Bootcamp /> },
  //         { key: 'mountain biking', label: 'Mountain Biking', icon: <IconCycling /> },
  //         { key: 'sky diving', label: 'Sky Diving', icon: <KiteSurfing /> },
  //         { key: 'obstacle courses', label: 'Obstacle Courses', icon: <RockClimbing /> },
  //         { key: '4x4 driving', label: '4x4 driving', icon: <Skateboarding /> },
  //         { key: 'camping', label: 'Camping', icon: <Bootcamp /> },
  //         { key: 'boating', label: 'Boating', icon: <Surfing /> },
  //         { key: 'rafting', label: 'Rafting', icon: <Skateboarding /> },
  //         { key: 'fishing', label: 'Fishing', icon: <Bootcamp /> },
  //       ],
  //     },
  //   ],
  // },
  {
    key: 'wellness',
    label: 'Wellness',
    subCategories: [
      {
        group: 'Wellness',
        options: [
          { key: 'massage', label: 'Massage', icon: <Massage /> },
          { key: 'physical therapy', label: 'Physical Therapy', icon: <PhysicalTherapy /> },
          // { key: 'chiropractor', label: 'Chiropractor', icon: <Chiropractor /> },
          // { key: 'reflexology', label: 'Reflexology', icon: <Reflexology /> },
          { key: 'prenatal', label: 'Prenatal', icon: <Prenatal /> },
          { key: 'acupuncture', label: 'Acupuncture', icon: <Acupuncture /> },
          // { key: 'cryotherapy', label: 'Cryotherapy', icon: <Cryotherapy /> },
          { key: 'holistic wellness', label: 'Holistic Wellness', icon: <Meditation /> },
          // { key: 'TCM practise', label: 'TCM Practise', icon: <TCMPractice /> },
          // { key: 'nutrition', label: 'Nutrition', icon: <Nutrition /> },
          { key: 'yoga', label: 'Yoga', icon: <IconYoga /> },
          { key: 'pilates', label: 'Pilates', icon: <Pilates /> },
        ],
      },
      {
        group: 'Beuty',
        options: [
          // { key: `spa's`, label: `Spa's`, icon: <Spas /> },
          // { key: 'face treatments', label: 'Face Treatments', icon: <FaceTreatment /> },
          // { key: 'hair removal', label: 'Hair Removal', icon: <HairRemoval /> },
          // { key: 'hair salon', label: 'Hair Salon', icon: <HairSalon /> },
          // { key: 'nail salon', label: 'Nail Salon', icon: <NailSalon /> },
          // { key: `tattoo's`, label: `Tattoo's`, icon: <Tattoos /> },
          // { key: 'tanning', label: 'Tanning', icon: <Tanning /> },
        ],
      },
    ],
  },
];

export const ageGroup = [
  {
    key: 'Adult',
    label: 'Adult',
    subLevel: [
      {
        group: 'Adult',
        options: [
          // { key: 'Beginner', label: 'Beginner' },
          // { key: 'Intermediate', label: 'Intermediate' },
          // { key: 'Advanced', label: 'Advanced' },
          // { key: 'Tournament Players', label: 'Tournament Players' },
        ],
      },
    ],
  },
  {
    key: 'Kids',
    label: 'Kids',
    subLevel: [
      {
        group: 'Kids',
        options: [
          { key: '4-6 years', label: '4-6 years' },
          { key: '6-9 years', label: '6-9 years' },
          { key: '8-12 years', label: '8-12 years' },
          { key: '10-14 years', label: '10-14 years' },
          { key: '14-18 years', label: '14-18 years' },
        ],
      },
    ],
  },
];
export const educationCategories = [
  {
    key: 'language',
    label: 'Languages',
    subCategories: [
      {
        group: 'Languages',
        options: [
          { key: 'english', label: 'English', icon: <Spas /> },
          { key: 'french', label: 'French', icon: <Spas /> },
          { key: 'german', label: 'German', icon: <Spas /> },
          { key: 'chinese', label: 'Chinese', icon: <Spas /> },
          { key: 'language learning', label: 'Language Learning', icon: <Spas /> },
          { key: 'IELTS', label: 'IELTS', icon: <Spas /> },
          { key: 'TOEFL', label: 'TOEFL', icon: <Spas /> },
          { key: 'spanish', label: 'Spanish', icon: <Spas /> },
        ],
      },
    ],
  },
  {
    key: 'academic',
    label: 'Academics',
    subCategories: [
      {
        group: 'Academics',
        options: [
          { key: 'math', label: 'Math', icon: <PersonalTraining /> },
          { key: 'physics', label: 'Physics', icon: <PersonalTraining /> },
          {
            key: 'chemistry',
            label: 'Chemistry',
            icon: <PersonalTraining />,
          },
          {
            key: 'biology',
            label: 'Biology',
            icon: <PersonalTraining />,
          },
          {
            key: 'science',
            label: 'Science',
            icon: <PersonalTraining />,
          },
          {
            key: 'history',
            label: 'History',
            icon: <PersonalTraining />,
          },
          { key: 'geography', label: 'Geography', icon: <PersonalTraining /> },
          { key: 'engineering', label: 'Engineering', icon: <PersonalTraining /> },
          {
            key: 'it and software',
            label: 'IT & Software',
            icon: <PersonalTraining />,
          },
        ],
      },
    ],
  },
  {
    key: 'management',
    label: 'Management',
    subCategories: [
      {
        group: 'Management',
        options: [
          { key: 'development', label: 'Development', icon: <Cryotherapy /> },
          { key: 'business', label: 'Business', icon: <Cryotherapy /> },
          {
            key: 'finance and accounting',
            label: 'Finance & Accounting',
            icon: <Cryotherapy />,
          },
          {
            key: 'office productivity',
            label: 'Office productivity',
            icon: <Cryotherapy />,
          },
          { key: 'academic writing', label: 'Academic Writing', icon: <Cryotherapy /> },
          {
            key: 'personal development',
            label: 'Personal Development',
            icon: <Cryotherapy />,
          },
          {
            key: 'marketing',
            label: 'Marketing',
            icon: <Cryotherapy />,
          },
          { key: 'GMAT', label: 'GMAT', icon: <Spas /> },
          { key: 'GRE', label: 'GRE', icon: <Spas /> },
        ],
      },
    ],
  },
  {
    key: 'art',
    label: 'Arts',
    subCategories: [
      {
        group: 'Languages',
        options: [
          { key: 'design', label: 'Design', icon: <Nutrition /> },
          { key: 'lifestyle', label: 'Lifestyle', icon: <Nutrition /> },
          {
            key: 'photography and video',
            label: 'Photography & Video',
            icon: <Nutrition />,
          },
          { key: 'music', label: 'Music', icon: <Nutrition /> },
          { key: 'health and fitness', label: 'Health & Fitness', icon: <Nutrition /> },
        ],
      },
    ],
  },
];

export const PromoCategories = [
  // { key: 'personal training', label: 'Personal Training', details: 'Fitness Classes' },
  // { key: 'yoga', label: 'Yoga', details: 'Fitness Classes' },
  // { key: 'pilates', label: 'Pilates', details: 'Fitness Classes' },
  // { key: 'barre', label: 'Barre', details: 'Outdoor and Adventure' },
  // { key: 'crossfit', label: 'Crossfit', details: 'Outdoor and Adventure' },
  // { key: 'swimming', label: 'Swimming', details: 'Outdoor and Adventure' },
  // { key: 'bootcamp', label: 'Bootcamp', details: 'Fitness Classes' },
  // { key: 'dance', label: 'Dance', details: 'Outdoor and Adventure' },
  // { key: 'olympic lifting', label: 'Olympic Lifting', details: 'Outdoor and Adventure' },
  { key: 'tennis', label: 'Tennis', details: 'Racket Sports' },
  { key: 'padel tennis', label: 'Padel Tennis', details: 'Racket Sports' },
  { key: 'pickle ball', label: 'Pickle Ball', details: 'Racket Sports' },
  // { key: 'pickle ball', label: 'Pickle Ball', details: 'Racket Sports' },
  // { key: 'squash', label: 'Squash', details: 'Racket Sports' },
  // { key: 'badminton', label: 'Badminton', details: 'Racket Sports' },
  // { key: 'massage', label: 'Massage', details: 'Wellness' },
  // { key: 'physical therapy', label: 'Physical Therapy', details: 'Wellness' },
  // { key: 'chiropractor', label: 'Chiropractor', details: 'Wellness' },
  // { key: 'holistic wellness', label: 'Holistic Wellness', details: 'Wellness' },
  // { key: 'cryotherapy', label: 'Cryotherapy', details: 'Wellness' },
  // { key: 'TCM practise', label: 'TCM Practise', details: 'Wellness' },
  // { key: 'prenatal', label: 'Prenatal', details: 'Wellness' },
  // { key: 'acupuncture', label: 'Acupuncture', details: 'Wellness' },
  // { key: 'nutrition', label: 'Nutrition', details: 'Wellness' },
  // { key: 'martial arts', label: 'Martial Arts', details: 'Combat Sports' },
  // { key: 'boxing', label: 'Boxing', details: 'Combat Sports' },
  // { key: 'kick boxing', label: 'Kick Boxing', details: 'Combat Sports' },
  // { key: 'jiu-jitsu', label: 'Jiu-jitsu', details: 'Combat Sports' },
  // { key: 'karate', label: 'Karate', details: 'Combat Sports' },
  // { key: 'rock climbing', label: 'Rock Climbing', details: 'Outdoor and Adventure' },
  // { key: 'skateboarding', label: 'Skateboarding', details: 'Outdoor and Adventure' },
  // { key: 'golf', label: 'Golf', details: 'Kids Specialist' },
  // { key: 'obstacle sports', label: 'Obstacle Sports', details: 'Outdoor and Adventure' },
  // { key: 'wake boarding', label: 'Wake Boarding', details: 'Outdoor and Adventure' },
  // { key: 'cycling', label: 'Cycling', details: 'Outdoor and Adventure' },
  // { key: 'biking', label: 'Biking', details: 'Outdoor and Adventure' },
  // { key: 'running', label: 'Running', details: 'Outdoor and Adventure' },
];

export const defaultCategoryBasedFields = [
  {
    key: 'duration',
    label: 'Duration',
    type: 'text',
    placeholder: '2 days',
    required_message: 'Duration is required',
  },
  {
    key: 'listing_mode',
    label: 'Listing mode',
    type: 'dropdown',
    required_message: 'Listing mode is required',
    options: [
      { key: '', label: 'None', hideFromFilters: true, hideFromListingInfo: true },
      { key: 'in_person', label: 'In-Person' },
      { key: 'online', label: 'Online' },
    ],
  },
  {
    key: 'activity_levels',
    label: 'Activity levels',
    type: 'multiselect',
    multiselect: 'true',
    required_message: 'Activity levels are required',
    options: [
      { key: '', label: 'None', hideFromFilters: true, hideFromListingInfo: true },
      { key: 'all_levels', label: 'All levels' },
      { key: 'beginner', label: 'Beginner' },
      { key: 'intermediate', label: 'Intermediate' },
      { key: 'advanced', label: 'Advanced' },
    ],
  },
  {
    key: 'hosted_in',
    label: 'Hosted in which language?',
    type: 'multiselect',
    multiselect: 'true',
    required_message: 'Hosted in language is required',
    options: languageFilterConfig,
  },
];

export const categoryBasedFields = [
  {
    // key: 'In-Person Sessions',
    key: 'sports',
    fields: [
      {
        key: 'duration',
        label: 'Duration',
        type: 'text',
        placeholder: '1 day',
        required_message: 'Duration is required',
      },
      {
        key: 'activity_levels',
        label: 'Activity levels',
        type: 'multiselect',
        multiselect: 'true',
        required_message: 'Activity levels are required',
        options: [
          { key: '', label: 'None', hideFromFilters: true, hideFromListingInfo: true },
          { key: 'all_levels', label: 'All levels' },
          { key: 'beginner', label: 'Beginner' },
          { key: 'intermediate', label: 'Intermediate' },
          { key: 'advanced', label: 'Advanced' },
        ],
      },
      {
        key: 'hosted_in',
        label: 'Hosted in which language?',
        type: 'multiselect',
        multiselect: 'true',
        required_message: 'Hosted language is required',
        options: languageFilterConfig,
      },
    ],
  },
  {
    // key: 'Wellness and Beauty',
    key: 'wellness',
    fields: [
      {
        key: 'duration',
        label: 'Duration',
        type: 'text',
        placeholder: '1 day',
        required_message: 'Duration is required',
      },
      {
        key: 'listing_mode',
        label: 'Listing mode',
        type: 'dropdown',
        required_message: 'Listing mode is required',
        options: [
          { key: '', label: 'None', hideFromFilters: true, hideFromListingInfo: true },
          { key: 'in_person', label: 'In-Person' },
          { key: 'online', label: 'Online' },
        ],
      },
      {
        key: 'activity_levels',
        label: 'Activity levels',
        type: 'multiselect',
        multiselect: 'true',
        required_message: 'Activity levels are required',
        options: [
          { key: '', label: 'None', hideFromFilters: true, hideFromListingInfo: true },
          { key: 'all_levels', label: 'All levels' },
          { key: 'beginner', label: 'Beginner' },
          { key: 'intermediate', label: 'Intermediate' },
          { key: 'advanced', label: 'Advanced' },
        ],
      },
      {
        key: 'hosted_in',
        label: 'Hosted in which language?',
        type: 'multiselect',
        multiselect: 'true',
        required_message: 'Hosted language is required',
        options: languageFilterConfig,
      },
    ],
  },
  {
    key: 'adventure',
    fields: [
      {
        key: 'duration',
        label: 'Duration',
        type: 'text',
        placeholder: '2 days',
        required_message: 'Duration is required',
      },
      {
        key: 'listing_mode',
        label: 'Listing mode',
        type: 'dropdown',
        required_message: 'Listing mode is required',
        options: [
          { key: '', label: 'None', hideFromFilters: true, hideFromListingInfo: true },
          { key: 'in_person', label: 'In-Person' },
          { key: 'online', label: 'Online' },
        ],
      },
      {
        key: 'activity_levels',
        label: 'Activity levels',
        type: 'multiselect',
        multiselect: 'true',
        required_message: 'Activity levels are required',
        options: [
          { key: '', label: 'None', hideFromFilters: true, hideFromListingInfo: true },
          { key: 'all_levels', label: 'All levels' },
          { key: 'beginner', label: 'Beginner' },
          { key: 'intermediate', label: 'Intermediate' },
          { key: 'advanced', label: 'Advanced' },
        ],
      },
      {
        key: 'hosted_in',
        label: 'Hosted in which language?',
        type: 'multiselect',
        multiselect: 'true',
        required_message: 'Hosted in language is required',
        options: languageFilterConfig,
      },
    ],
  },
  {
    key: 'academic',
    fields: defaultCategoryBasedFields,
  },
  {
    key: 'language',
    fields: defaultCategoryBasedFields,
  },
  {
    key: 'management',
    fields: defaultCategoryBasedFields,
  },
  {
    key: 'art',
    fields: defaultCategoryBasedFields,
  },
];

export const educationCategoryField = [
  {
    key: 'duration',
    label: 'Duration',
    type: 'text',
    placeholder: '2 days',
    required_message: 'Duration is required',
  },
  {
    key: 'listing_mode',
    label: 'Listing mode',
    type: 'dropdown',
    required_message: 'Listing mode is required',
    options: [
      { key: '', label: 'None', hideFromFilters: true, hideFromListingInfo: true },
      { key: 'in_person', label: 'In-Person' },
      { key: 'online', label: 'Online' },
    ],
  },
  {
    key: 'activity_levels',
    label: 'Activity levels',
    type: 'multiselect',
    multiselect: 'true',
    required_message: 'Activity levels are required',
    options: [
      { key: '', label: 'None', hideFromFilters: true, hideFromListingInfo: true },
      { key: 'all_levels', label: 'All levels' },
      { key: 'beginner', label: 'Beginner' },
      { key: 'intermediate', label: 'Intermediate' },
      { key: 'advanced', label: 'Advanced' },
    ],
  },
  {
    key: 'hosted_in',
    label: 'Hosted in which language?',
    type: 'multiselect',
    multiselect: 'true',
    required_message: 'Hosted in language is required',
    options: languageFilterConfig,
  },
];

export const activityLevel = [
  { key: 'all_levels', label: 'All levels' },
  { key: 'beginner', label: 'Beginner' },
  { key: 'intermediate', label: 'Intermediate' },
  { key: 'advanced', label: 'Advanced' },
];

export const treatmentRoomOptions = [
  { key: 'private_room', label: 'Private room' },
  { key: 'separator', label: 'Separator' },
  { key: 'open_space', label: 'Open space' },
];

export const deliveryOptions = [
  { key: 'home_delivery', label: 'Home delivery' },
  { key: 'meetup', label: 'Meetup' },
  { key: 'self_pick_up', label: 'Self pick up' },
];

// Price filter configuration
// Note: unlike most prices this is not handled in subunits
export const priceFilterConfig = {
  min: 0,
  max: 1000,
  step: 5,
};

// Activate booking dates filter on search page
export const dateRangeLengthFilterConfig = {
  active: true,

  // A global time zone to use in availability searches. As listings
  // can be in various time zones, we must decide what time zone we
  // use in search when looking for available listings within a
  // certain time interval.
  //
  // If you have all/most listings in a certain time zone, change this
  // config value to that.
  //
  // See: https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
  searchTimeZone: 'Etc/UTC',

  // Options for the minimum duration of the booking
  options: [
    { key: '0', label: 'Any length' },
    { key: '60', label: '1 hour', shortLabel: '1h' },
    { key: '120', label: '2 hours', shortLabel: '2h' },
  ],
};

const relevanceKey = 'relevance';

export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: true,

  relevanceKey,
  conflictingFilters: [],
  options: [
    { key: 'createdAt', label: 'Newest' },
    { key: '-createdAt', label: 'Oldest' },
    { key: '-price', label: 'Lowest price' },
    { key: 'price', label: 'Highest price' },

    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    { key: relevanceKey, label: 'Relevance', longLabel: 'Relevance (Keyword search)' },
  ],
};

// Activate keyword filter on search page

// NOTE: If you are ordering search results by distance the keyword search can't be used at the same time.
// You can turn off ordering by distance in config.js file
export const keywordFilterConfig = {
  active: true,
};

export const productFilters = [
  {
    id: 'category',
    label: 'Category',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_category'],
    config: {
      options: [
        { key: 'tennis_rackets', label: 'Tennis Rackets' },
        { key: 'tennis_unique_products', label: 'Tennis Unique Products' },
        { key: 'shoes', label: 'Shoes' },
        { key: 'tennis_bags', label: 'Tennis Bags' },
        { key: 'exercise_unique_equipment', label: 'Exercise Unique Equipment' },
      ],
    },
  },
  {
    key: 'shoes',
    fields: [
      {
        id: 'size',
        label: 'Size (US)',
        type: 'SelectMultipleFilter',
        group: 'primary',
        queryParamNames: ['pub_size'],
        config: {
          options: [
            { key: '4', label: '4' },
            { key: '5', label: '5' },
            { key: '6', label: '6' },
            { key: '7', label: '7' },
            { key: '8', label: '8' },
            { key: '9', label: '9' },
            { key: '10', label: '10' },
            { key: '11', label: '11' },
            { key: '12', label: '12' },
          ],
        },
      },
      {
        id: 'brand',
        label: 'Brand',
        type: 'SelectMultipleFilter',
        group: 'primary',
        queryParamNames: ['pub_brand'],
        config: {
          options: [
            { key: 'adidas', label: 'Adidas' },
            { key: 'air_jordan', label: 'Air Jordan' },
            { key: 'converse', label: 'Converse' },
            { key: 'new_balance', label: 'New Balance' },
            { key: 'nike', label: 'Nike' },
            { key: 'puma', label: 'Puma' },
            { key: 'ultraboost', label: 'Ultraboost' },
            { key: 'vans', label: 'Vans' },
            { key: 'yeezy', label: 'Yeezy' },
            { key: 'other', label: 'Other' },
          ],
        },
      },
    ],
  },
  {
    key: 'tennis_rackets',
    fields: [
      {
        id: 'level',
        label: 'Level',
        type: 'SelectSingleFilter',
        group: 'primary',
        queryParamNames: ['meta_level'],
        config: {
          options: [
            { key: 'pro', label: 'Pro' },
            { key: 'intermediate', label: 'Intermediate' },
            { key: 'junior', label: 'Junior ' },
          ],
        },
      },
      {
        id: 'brand',
        label: 'Brand',
        type: 'SelectMultipleFilter',
        group: 'primary',
        queryParamNames: ['pub_brand'],
        config: {
          options: [
            { key: 'yonex', label: 'Yonex' },
            { key: 'head', label: 'Head' },
          ],
        },
      },
      {
        id: 'size',
        label: 'Grip size',
        validateMessage: 'You need to add grip size',
        type: 'text',
        placeholder: 'Please enter grip size',
        group: 'primary',
        queryParamNames: ['pub_size'],
        // config: {
        //   options: [
        //     { key: '2', label: '2' },
        //     { key: '3', label: '3' },
        //     { key: '4', label: '4' },
        //   ],
        // },
      },
    ],
  },
  {
    id: 'price',
    label: 'Price',
    type: 'PriceFilter',
    group: 'primary',
    // Note: PriceFilter is fixed filter,
    // you can't change "queryParamNames: ['price'],"
    queryParamNames: ['price'],
    // Price filter configuration
    // Note: unlike most prices this is not handled in subunits
    config: {
      min: 0,
      max: 1000,
      step: 5,
    },
  },
  {
    id: 'keyword',
    label: 'Keyword',
    type: 'KeywordFilter',
    group: 'primary',
    queryParamNames: ['keywords'],
    config: {},
  },
];

export const categoriesDataConfig = [
  {
    id: 'sports',
    title: 'Sports Classes',
    description: 'All Sports Classes & Activities In One Place',
    heroImage:
      'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675340847/probuddy/static/images/CategoriesPage/sports/SportsHeroImage_lpluon.png',

    section1: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675340856/probuddy/static/images/CategoriesPage/sports/SportsActivitiesImage_qxzjr1.png',
      heading: 'All Sports Activities. One Marketplace.',
      description:
        'Bring the entire world of sports classes at your fingertips. Hundreds of daily classes in tennis, boxing, golf, martial arts and more. Complete your workout in the comfort of your own home, on the beach, or at your favorite class studio. Just choose your activity and book it!',
      linkText: 'Book A Class Now',
    },

    section2: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675340865/probuddy/static/images/CategoriesPage/sports/EnjoyClassImage_xsztwa.png',
      heading: 'Enjoy class, in comfort, anytime, anywhere.',
      description:
        "Whether you're an experienced or complete beginner, or you're looking for a more holistic approach to learn sports, there's something for everyone at Probuddy!Whatever your preferences or ability, there's an array of instructor-led classes available. Choose from a wide mix of different workouts, keep fit and healthy or push yourself to the limits.",
      linkText: 'Explore Our Listings',
    },

    exercise: {
      productTitle: 'Popular Sports Listings in your area',
      title: 'Private or Group Sports Classes Offered by Our Pros',
    },

    whyBook: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675483916/probuddy/static/images/CategoriesPage/sports/BookSportsImage_hhxsfe.png',
      heading1: 'Why Choose Us',
      position: 'start',
      containerPosition: 'center',
      description1:
        'We make it easy for you to find, schedule and attend sports classes taught by the Pros through your local sports facility, and at your convenience.',
      heading2: '',
      description2: '',
    },

    classesNearYou: {
      title: "Whatever type of sports classes you're looking for, you'll find it here.",
      description:
        'With our huge range of fun and motivating sports classes, we have something for you, no matter what your personal workout style. Whether you want to get fit and healthy, relax or release stress, we have something for everyone!',
    },

    instructors: {
      title: 'Top-Rated Sports Coaches & Instructors',
      description:
        "Discover sports classes and coaches near you. Learn from the best athletes, trainers and instructors in your area. Probuddy gives you access to your city's most elite coaches who live in your neighborhood.",
      instructorsData: [
        {
          id: '1',
          trainerImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1681967398/probuddy/static/images/CategoriesPage/sports/SportsTrainerImage1_folgnd.png',
          name: 'Tennis Coach',
          type: 'Christo Weise',
          rating: '4.4',
          reviews: '17',
          category: 'sports',
          description:
            "Great with Corporate group programs currently Google's tennis program coach and i have also conducted groups for the Singapore Police and Facebook.",
        },
        {
          id: '2',
          trainerImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1681967398/probuddy/static/images/CategoriesPage/sports/SportsTrainerImage2_xetnnl.png',
          name: 'Agustin Velotti',
          type: 'Tennis Coach',
          rating: '4.8',
          reviews: '16',
          category: 'sports',
          description:
            'Let Agustin help you improve your skills and fitness. Classes are for all levels and ages. Currently world ranked 391 ATP and highest ranked active tennis player in Singapore.',
        },
        {
          id: '3',
          trainerImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1681967399/probuddy/static/images/CategoriesPage/sports/SportsTrainerImage3_kv873p.png',
          name: 'Michel Van De Sanden',
          type: 'Tennis Coach',
          rating: '4.4',
          reviews: '11',
          category: 'sports',
          description:
            'Looking to learn basics of tennis, get your game to another level or to have a hit with high performance coach and former WTA tour coach.',
        },
      ],
    },

    featured: {
      title: 'Top-Rated Sports Companies & Fintess Studios',
      description:
        "Sweat with confidence. Whether you're looking for a new workout buddy or want to deepen your practice, you'll have plenty of options available in your area. Probuddy makes it easy to search and find local fitness classes and sports groups or private classes near you.",
      featuredItems: [
        {
          id: '1',
          companyImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677220313/probuddy/static/images/CategoriesPage/sports/SportsCompanyImage1_n8iwgh.png',
          name: 'SCC Tennis Academy',
          location: 'Connaught Dr., Singapore',
          rating: '4.2',
          reviews: '225',
          category: 'sports',
          description:
            "SCC Tennis Academy is more than just a workout ~ it's a lifestyle that inspires confidence, community and celebration! Whether you are beginning an exercise program, a high level athlete, or just want to tone up, we have a program for you. Mindful movement is our motto and the common thread that runs through our philosophy and approach to living a healthy, happy life.",
        },
        {
          id: '2',
          companyImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677220313/probuddy/static/images/CategoriesPage/sports/SportsCompanyImage1_n8iwgh.png',
          name: 'SCC Tennis Academy',
          location: 'Connaught Dr., Singapore',
          rating: '4.6',
          reviews: '195',
          category: 'sports',
          description:
            "SCC Tennis Academy is more than just a workout ~ it's a lifestyle that inspires confidence, community and celebration! Whether you are beginning an exercise program, a high level athlete, or just want to tone up, we have a program for you. Mindful movement is our motto and the common thread that runs through our philosophy and approach to living a healthy, happy life.",
        },
      ],
    },

    topRatedClasses: {
      title: 'Most Popular Sports Classes in your area',
      description:
        "Find the perfect exercise class near you. Whether you're looking to try a new sport, get fit, stay healthy or have fun with friends - our wide variety of sports classes can help you live well so you can do more of what you love.",
      classesData: [
        {
          id: '1',
          classesImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677220326/probuddy/static/images/CategoriesPage/sports/SportsClassesImage1_a4vij6.png',
          className: 'Christo Wiese Private Coaching (GST Included)',
          instructorName: 'Christo Weise',
          location: 'Bridge Road, Singapore',
          rating: '4.6',
          reviews: '242',
          category: 'sports',
          description:
            'Online experience hosted by Singapore Cricket Club Tennis. Members will have priority to book there preferred weekday classes with tennis director Christo Wiese.',
          bookingTimings: '8.00 Am to 10.00 Am',
          bookingPrice: '125',
        },
        {
          id: '2',
          classesImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677220332/probuddy/static/images/CategoriesPage/sports/SportsClassesImage2_dqggda.png',
          className: 'Group Tennis Classes Kids And Adults',
          instructorName: 'Dion Russell',
          location: 'Singapore',
          rating: '4.2',
          reviews: '229',
          category: 'sports',
          description: 'Book a session with Group tennis classes Kids and Adults',
          bookingTimings: '8.00 Am to 10.00 Am',
          bookingPrice: '120',
        },
        {
          id: '3',
          classesImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677220336/probuddy/static/images/CategoriesPage/sports/SportsClassesImage3_ofv3oi.png',
          className: 'Children Tennis Group Session',
          instructorName: 'Sam Henderson',
          location: 'Singapore',
          rating: '4.6',
          reviews: '168',
          category: 'sports',
          description: 'Learn this game of a lifetime with your friends or in a group setting.',
          bookingTimings: '8.00 Am to 10.00 Am',
          bookingPrice: '100',
        },
      ],
    },

    reviews: [
      {
        name: 'Courtney Henry',
        country: 'Hong Kong',
        imageSrc: ReviewImage1,
        description:
          'The Probuddy platform is a great solution for business owners. We highly recommend it for businesses that need to keep track of everything.',
      },
      {
        name: 'Simon Hart',
        country: 'Texas',
        imageSrc: ReviewImage2,
        description:
          'I have tried many online courses in my life, but none that have been so effective as Probuddy. The course has helped me with my business and gave me the tools to be more successful. The instructors are very knowledgeable and helpful. I have enjoyed the course and look forward to taking more classes.',
      },
      {
        name: 'Hayley Smith',
        country: 'Manchester',
        imageSrc: ReviewImage6,
        description:
          'Taking time out to destress with a tennis session is always a wonderful thing. Using Probuddy makes it even more enjoyable.',
      },
    ],

    features: {
      title: 'Post Your Request And Let Our Pro’s Connect with you right away!',
      description:
        'Match up with verified and certified pros near you with Pro Connect+. Just create a Match Request, and we’ll connect you with a perfect match.',
    },

    sellers: {
      title:
        'Join over 1000 sports, wellness, and adventure Pros that have seen their business grow with Probuddy.',
      linkText: 'Learn More',
    },
  },

  {
    id: 'wellness',
    title: 'Wellness Classes',
    description: 'All Wellness Classes As Flexible As You Are',
    heroImage:
      'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675484166/probuddy/static/images/CategoriesPage/Wellness/WellnessHeroImage_cdct6e.png',

    section1: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675484174/probuddy/static/images/CategoriesPage/Wellness/WellnessActivitiesImage_qqaulw.png',
      heading: 'Wellness classes, activities, and events that are made for you.',
      description:
        "Probuddy's mission is to help people realize their holistic wellness goals. That could be anything from staying healthy, to improving your mental health, or even feeling more energized. Whatever your wellness goals may be, we work with you every step of the way. Our goal is to make it easy for you to find a professional who can help you get healthy, fit, and happy for your body, mind & soul.",
      linkText: 'Book A Class Now',
    },

    section2: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675484188/probuddy/static/images/CategoriesPage/Wellness/FunSocialRelaxingImage_v4tvjr.png',
      heading: 'Find fun, social and relaxing classes and activities that match your schedule.',
      description:
        'We are a team of experienced professionals who have dedicated their lives to helping others reach their holistic wellness goals. Our goal is to motivate and inspire you to take action in your own life! Our program is designed for people looking for transformation or simply for enjoyment. We understand that our bodies are made up of many systems that work together as one.',
      linkText: 'Explore Our Listings',
    },

    exercise: {
      productTitle: 'Popular Wellness Listings in your area',
      title: 'Wellness Classes & Appointments Offered by Our Pros',
      options: [
        {
          name: 'Yoga',
          description:
            'We’ll take you through stress-relieving asanas to relax both your mind and body.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677220467/probuddy/static/images/CategoriesPage/Wellness/WellnessImage1_b7vpk0.png',
        },
        {
          name: 'Pilates',
          description:
            'Work your body, feel the flow. Take your workout to the next level with Pilates training.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677220472/probuddy/static/images/CategoriesPage/Wellness/WellnessImage2_g66sox.png',
        },
        {
          name: 'Traditional Chinese Medicine',
          description:
            'Focus on balance, harmony, and energy in the body and mind to promote overall well-being.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677220476/probuddy/static/images/CategoriesPage/Wellness/WellnessImage3_zjxpqz.png',
        },
        {
          name: 'Health Coach',
          description: 'Work side by side with our health and wellness coaches.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677220480/probuddy/static/images/CategoriesPage/Wellness/WellnessImage4_kt7zll.png',
        },
        {
          name: 'Physical Therapy',
          description: 'Improve your overall health, mobilize and restore muscle function.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677220484/probuddy/static/images/CategoriesPage/Wellness/WellnessImage5_a4gx4i.png',
        },
        {
          name: 'Massage & Spa',
          description: 'Treat yourself to a relaxing and rejuvenating body massage and spa.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677220488/probuddy/static/images/CategoriesPage/Wellness/WellnessImage6_pieiaz.png',
        },
      ],
    },

    whyBook: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675484195/probuddy/static/images/CategoriesPage/Wellness/BookWellnessImage_jlbz6z.png',
      heading1: 'Why Choose Us',
      position: 'start',
      containerPosition: 'start',
      description1:
        "Probuddy is the easiest way to book the best fitness & wellness classes across the globe - no more waiting or driving around to find the right class for you. Our goal is to help you find the perfect instructor or class in your area so that you can make your next activity one you'll never forget!",
      heading2: '',
      description2: '',
    },

    classesNearYou: {
      title: 'Round-the-World Holistic Wellness Classes & Appointments',
      description:
        'Probuddy gives you worldwide access to thousands of round-the-world holistic yoga classes, fitness gyms, nail salons, and massage & spa—all from one marketplace.',
    },

    instructors: {
      title: 'Top-Rated Wellness Coaches & Instructors',
      description:
        "You deserve a workout that isn't boring, and a community of like-minded people to push you to go further than you've gone before. At Probuddy we believe in the power of wellness and social connection to help you achieve your health and fitness goal.",
      instructorsData: [
        {
          id: '1',
          trainerImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677220787/probuddy/static/images/CategoriesPage/Yoga/YogaTrainerImage1_g1bp2r.png',
          name: 'Frankie Wong',
          type: 'Yoga Instructor',
          rating: '4.6',
          reviews: '22',
          category: 'wellness',
          description:
            'Center your body and mind, stretch the possibilities with personalized guidance from Probuddy yoga instructor Frankie Wong.',
        },
        {
          id: '2',
          trainerImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677220783/probuddy/static/images/CategoriesPage/Yoga/YogaTrainerImage2_ha6997.png',
          name: 'Carl Roberts',
          type: 'Yoga Instructor',
          rating: '4.6',
          reviews: '11',
          category: 'wellness',
          description:
            'Carl is a qualified and accredited yoga teacher and has been teaching yoga since 2015.',
        },
        {
          id: '3',
          trainerImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677220779/probuddy/static/images/CategoriesPage/Yoga/YogaTrainerImage3_vhqjva.png',
          name: 'Jenny Wilson',
          type: 'Yoga Instructor',
          rating: '4.6',
          reviews: '16',
          category: 'wellness',
          description:
            'Jenny embraces that yoga as a lifelong personal practice for the overall wellbeing of mind, body and spirit.',
        },
      ],
    },

    featured: {
      title: 'Most Popular Wellness Companies & Studios',
      description:
        "Whether you're looking for a new workout routine or want to deepen your practice, there are plenty of options available in your space.If you're looking for a new wellness studio and classes booking near you, take a look at these top-rated companies below.",
      featuredItems: [
        {
          id: '1',
          companyImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677220984/probuddy/static/images/CategoriesPage/Wellness/WellnessCompanyImage1_jglgn6.png',
          name: 'SmartFit Pilates',
          location: 'Tanglin Road, Singapore',
          rating: '4.6',
          reviews: '220',
          category: 'wellness',
          description:
            "We're a boutique Pilates studio at Tanglin Place that opened over 9 years ago to help every body live their best life through private, duet and small group Pilates (Reformer and other apparatus) sessions.",
        },
        {
          id: '2',
          companyImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677220984/probuddy/static/images/CategoriesPage/Wellness/WellnessCompanyImage1_jglgn6.png',
          name: 'SCC Tennis Academy',
          location: 'Connaught Dr., Singapore',
          rating: '4.6',
          reviews: '210',
          category: 'wellness',
          description:
            "SCC Tennis Academy is more than just a workout ~ it's a lifestyle that inspires confidence, community and celebration! Whether you are beginning an exercise program, a high level athlete, or just want to tone up, we have a program for you. Mindful movement is our motto and the common thread that runs through our philosophy and approach to living a healthy, happy life.",
        },
      ],
    },

    topRatedClasses: {
      title: 'Featured Wellness Classes & Activities At Your Fingertips',
      description:
        "Become the healthiest and better version of yourself! If you're looking for a yoga classes that can help you achieve the best results, look no further than the following list of top-rated yoga classes near you.",
      classesData: [
        {
          id: '1',
          classesImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677221049/probuddy/static/images/CategoriesPage/Wellness/WellnessClassesImage1_dqlqby.png',
          className: 'Private Yoga Sessions At Your Space Or Mine *Includes Travel',
          instructorName: 'Frankie Wong',
          location: 'Bridge Road, Singapore',
          rating: '4.8',
          reviews: '200',
          category: 'wellness',
          description:
            'Book a session with Private Yoga Sessions at Your Space or Mine *Includes Travel',
          bookingTimings: '8.00 Am to 10.00 Am',
          bookingPrice: '100',
        },
        {
          id: '2',
          classesImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677221049/probuddy/static/images/CategoriesPage/Wellness/WellnessClassesImage2_vkxlgq.png',
          className: 'Moving Our Bodies with PilatesPlus',
          instructorName: 'Pilates Plus',
          location: 'Shenton Way, Singapore',
          rating: '4.5',
          reviews: '202',
          category: 'sports',
          description:
            'At PilatesPlus, we provide unique and innovative ways of teaching Pilates exercise. We are dedicated to guiding our clients to achieve their fitness goal.',
          bookingTimings: '8.00 Am to 10.00 Am',
          bookingPrice: '120',
        },
        {
          id: '3',
          classesImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677221049/probuddy/static/images/CategoriesPage/Wellness/WellnessClassesImage3_reqa1y.png',
          className: 'Thai Oil Massage (Signature Service)',
          instructorName: 'Healing Thai Massage',
          location: 'Singapore',
          rating: '4.6',
          reviews: '230',
          category: 'sports',
          description:
            'Our Focus has always been to excel in traditional, time-tested therapies through a team of carefully selected and experienced, certified Massage Therapists.',
          bookingTimings: '8.00 Am to 10.00 Am',
          bookingPrice: '40',
        },
      ],
    },

    reviews: [
      {
        name: 'Tim McGowan',
        country: 'Sydney',
        imageSrc: ReviewImage1,
        description:
          'Working with Probuddy was a great experience. Their platform covers all of your business needs, and they are easy to work with. I would highly recommend them.',
      },
      {
        name: 'Austin Wade',
        country: 'New York',
        imageSrc: ReviewImage5,
        description:
          'The Probuddy platform is a great solution for business owners. We highly recommend it for businesses that need to keep track of everything.',
      },
      {
        name: 'Michelle Uy',
        country: 'Singapore',
        imageSrc: ReviewImage3,
        description:
          'I have booked a yoga session using Probuddy and it’s the best thing. The instructor is very warm and friendly, thank you very much for that!',
      },
    ],

    features: {
      title: 'Post Your Request And Let Our Pro’s Connect with you right away!',
      description:
        'Match up with verified and certified pros near you with Pro Connect+. Just create a Match Request, and we’ll connect you with a perfect match.',
    },

    sellers: {
      title:
        'Join over 1000 sports, wellness, and adventure Pros that have seen their business grow with Probuddy.',
      linkText: 'Learn More',
    },
  },
  //adventure
  // {
  //   id: 'adventure',
  //   title: 'Adventure Classes',
  //   description: 'Quench Your Thirst For Outdoor Adventure',
  //   heroImage:
  //     'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677222824/probuddy/static/images/CategoriesPage/Adventure/AdventureHeroImage_mm5ree.png',

  //   section1: {
  //     imgSrc:
  //       'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677222818/probuddy/static/images/CategoriesPage/Adventure/SearchBookFunImage_j6wx66.png',
  //     heading: 'Search. Book. Have Fun Adrenaline Junkie',
  //     description:
  //       'Dive into the unknown with our outdoor activities. Swim against the tide, jump to the unknown and immerse yourself in the darkness of caves. Climb beyond your limits and listen to the symphony of nature. Tick off your Bucket List and discover your inner explorer with our outdoor activities. So, search our website for an adventure close to your heart and book it today!',
  //     linkText: 'Book A Class Now',
  //   },

  //   section2: {
  //     imgSrc:
  //       'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677222819/probuddy/static/images/CategoriesPage/Adventure/WhereverAndWheneverImage_mndvmm.png',
  //     heading: 'Go Wherever and Whenever You Want To Go',
  //     description:
  //       'Let us introduce you to the best of yourself by taking you on an exciting adventure. Get out of your comfort zone and explore the unknown with us. We have a wide variety of outdoor activities in our beautiful natural environment. We offer a variety of experiences from easy strolls along the beach to challenging treks through the jungle, we promise you will never be bored while you are with us.',
  //     linkText: 'Explore Our Listings',
  //   },

  //   exercise: {
  //     productTitle: 'Popular Adventure Listings in your area',
  //     title: 'All Recreation & Adventure Activities Hosted by our Pros',
  //     options: [
  //       {
  //         name: 'Rock Climbing',
  //         description: 'Looking for a fun weekend activity? Book a rock climbing sesh today!',
  //         imageSrc:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677223363/probuddy/static/images/CategoriesPage/Adventure/AdventureImage1_tf9apm.png',
  //       },
  //       {
  //         name: 'Surfing',
  //         description: 'Surf for all levels, meet new friends & make memories for life.',
  //         imageSrc:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677223363/probuddy/static/images/CategoriesPage/Adventure/AdventureImage2_uupvhy.png',
  //       },
  //       {
  //         name: 'Sky Diving',
  //         description:
  //           'Learn to skydive solo and experience the thrill of freefall and deploying your own parachute.',
  //         imageSrc:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677223363/probuddy/static/images/CategoriesPage/Adventure/AdventureImage3_pixc1j.png',
  //       },
  //       {
  //         name: 'Scuba Diving',
  //         description: 'Learning to scuba dive involves mastering basic scuba training and skills.',
  //         imageSrc:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677223364/probuddy/static/images/CategoriesPage/Adventure/AdventureImage4_pbn9gp.png',
  //       },
  //       {
  //         name: 'Hiking',
  //         description:
  //           'Learn to hike, explore all types of terrain, basic guidelines, and safety measures.',
  //         imageSrc:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677223363/probuddy/static/images/CategoriesPage/Adventure/AdventureImage5_iq7qij.png',
  //       },
  //       {
  //         name: 'ATV Off Road',
  //         description:
  //           'Bring out the adrenaline junkie in you with an exhilarating off-road ATV adventure.',
  //         imageSrc:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677223364/probuddy/static/images/CategoriesPage/Adventure/AdventureImage6_ljvztq.png',
  //       },
  //     ],
  //   },

  //   whyBook: {
  //     imgSrc:
  //       'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677222819/probuddy/static/images/CategoriesPage/Adventure/BookAdventureImage_z4gvek.png',
  //     heading1: 'Why Choose Us',
  //     position: 'start',
  //     containerPosition: 'start',
  //     description1:
  //       'Probuddy is a one-stop Marketplace for all things sports, wellness & adventure. It gives you worldwide access to thousands of top-rated fitness instructors, yoga classes, tennis coaches, or rock climbing events. All you need to do is find your ideal workout activity and book it!',
  //     heading2: '',
  //     description2: '',
  //   },

  //   classesNearYou: {
  //     title: 'Explore More Adventure & Recreation Activities Near You',
  //     description:
  //       "Don't miss out on the adventure! Let us help you plan a fun-filled adventure or recreational trip. Book now!",
  //   },

  //   instructors: {
  //     title: 'Go Beyond Your Limits With Our Top Rated Adventure Pros',
  //     description:
  //       'Book your next adventures with our Pros! Our team of experts has been out there and knows what it takes to make sure that your experience is as fulfilling as possible. Skip the hassle and book a tour now!',
  //     instructorsData: [
  //       {
  //         id: '1',
  //         trainerImage:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677223364/probuddy/static/images/CategoriesPage/Adventure/AdventureTrainerImage1_yeqhtk.png',
  //         name: 'Adventure Pro',
  //         type: 'Phil Harper',
  //         rating: '4.4',
  //         reviews: '13',
  //         category: 'adventure',
  //         description:
  //           'Book a hiking activity with adventure pro guide & instructor Phil. Open to beginners and pro hikers.',
  //       },
  //       {
  //         id: '2',
  //         trainerImage:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677223364/probuddy/static/images/CategoriesPage/Adventure/AdventureTrainerImage2_t1hxmp.png',
  //         name: 'Max Arnette',
  //         type: 'Adventure Pro',
  //         rating: '4.2',
  //         reviews: '11',
  //         category: 'adventure',
  //         description:
  //           'Book a wakeboarding classes with world-renowned instructor Max. Open to beginners and pro wakeboarders.',
  //       },
  //       {
  //         id: '3',
  //         trainerImage:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677223366/probuddy/static/images/CategoriesPage/Adventure/AdventureTrainerImage3_chkdmd.png',
  //         name: 'Jenny Soh',
  //         type: 'Adventure Pro',
  //         rating: '4.1',
  //         reviews: '16',
  //         category: 'adventure',
  //         description:
  //           'Book a freediving classes with world-renowned freediving instructor Jenny. Open to beginners and pro freedivers.',
  //       },
  //     ],
  //   },

  //   featured: {
  //     title: 'Top-Rated Outdoor Adventure Companies',
  //     description:
  //       'Packed with adventure, Probuddy gives you the chance to get out of your comfort zone and explore the unknown. Book your first adventure with us today!',
  //     featuredItems: [
  //       {
  //         id: '1',
  //         companyImage:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677223362/probuddy/static/images/CategoriesPage/Adventure/AdventureClassesImage1_exxzti.png',
  //         name: 'Climb Central Singapore',
  //         location: 'Central, Singapore',
  //         rating: '4.6',
  //         reviews: '230',
  //         category: 'adventure',
  //         description:
  //           "At Climb Central, we believe that climbing is for everyone. We offer a unique experience with easy-to-use safety set-ups for anyone to walk in and climb anytime we're open!",
  //       },
  //       {
  //         id: '2',
  //         companyImage:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677223362/probuddy/static/images/CategoriesPage/Adventure/AdventureClassesImage1_exxzti.png',
  //         name: 'Climb Central Singapore',
  //         location: 'Central, Singapore',
  //         rating: '4.7',
  //         reviews: '258',
  //         category: 'adventure',
  //         description:
  //           "At Climb Central, we believe that climbing is for everyone. We offer a unique experience with easy-to-use safety set-ups for anyone to walk in and climb anytime we're open!",
  //       },
  //     ],
  //   },

  //   topRatedClasses: {
  //     title: 'Most Popular Adventure Activities & Events',
  //     description:
  //       'Enjoy a fun day out with the family and friends at these adventurous events. See activities. Get the adrenaline pumping at these exciting events.',
  //     classesData: [
  //       {
  //         id: '1',
  //         classesImage:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677223362/probuddy/static/images/CategoriesPage/Adventure/AdventureClassesImage1_exxzti.png',
  //         className: 'Climb Central Singapore',
  //         instructorName: 'Climb Central',
  //         location: 'Bridge Road, Singapore',
  //         rating: '4.2',
  //         reviews: '250',
  //         category: 'adventure',
  //         description:
  //           'Online experience hosted by Singapore Cricket Club Tennis. Members will have priority to book there preferred weekday classes with tennis director Christo Wiese.',
  //         bookingTimings: '8.00 Am to 10.00 Am',
  //         bookingPrice: '100',
  //       },
  //       {
  //         id: '2',
  //         classesImage:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677223362/probuddy/static/images/CategoriesPage/Adventure/AdventureClassesImage2_kqgs4k.png',
  //         className: 'Learn To Skate And Cycle',
  //         instructorName: 'Learn To Skate and Cycle',
  //         location: 'Singapore',
  //         rating: '4.8',
  //         reviews: '230',
  //         category: 'adventure',
  //         description:
  //           'Don’t let a few unsuccessful attempts dampen your passion to skate and cycle. Everyone is unique and has different background.',
  //         bookingTimings: '8.00 Am to 10.00 Am',
  //         bookingPrice: '120',
  //       },
  //       {
  //         id: '3',
  //         classesImage:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677223362/probuddy/static/images/CategoriesPage/Adventure/AdventureClassesImage3_faiuqi.png',
  //         className: 'Learn to Surf at Surf Cove',
  //         instructorName: 'Surf Cove',
  //         location: 'Singapore',
  //         rating: '4.2',
  //         reviews: '176',
  //         category: 'adventure',
  //         description:
  //           'SURF COVE by Wave House Sentosa (expecting in 2022) occupies close to 15,000 sq feet of space and is a new integrated surfing and lifestyle concept destination.',
  //         bookingTimings: '8.00 Am to 10.00 Am',
  //         bookingPrice: '40',
  //       },
  //     ],
  //   },

  //   reviews: [
  //     {
  //       name: 'Sam Sebastian',
  //       country: 'Melbourne',
  //       imageSrc: ReviewImage1,
  //       description:
  //         'The Probuddy platform is a great solution for business owners. We highly recommend it for businesses that need to keep track of everything.',
  //     },
  //     {
  //       name: 'Courtney Henry',
  //       country: 'Los Angeles, CA',
  //       imageSrc: ReviewImage1,
  //       description:
  //         'I have tried many online courses in my life, but none that have been so effective as Probuddy. The course has helped me with my business and gave me the tools to be more successful. The instructors are very knowledgeable and helpful. I have enjoyed the course and look forward to taking more classes.',
  //     },
  //     {
  //       name: 'Jeff Yao',
  //       country: 'Singapore',
  //       imageSrc: ReviewImage4,
  //       description:
  //         'Working with Probuddy was a great experience. Their platform covers all of your business needs, and they are easy to work with. I would highly recommend them.',
  //     },
  //   ],

  //   features: {
  //     title: 'Post Your Request And Let Our Pro’s Connect with you right away!',
  //     description:
  //       'Match up with verified and certified pros near you with Pro Connect+. Just create a Match Request, and we’ll connect you with a perfect match.',
  //   },

  //   sellers: {
  //     title:
  //       'Join over 1000 sports, wellness, and adventure Pros that have seen their business grow with Probuddy.',
  //     linkText: 'Learn More',
  //   },
  // },

  {
    id: 'yoga',
    title: 'Yoga Classes',
    description: 'Build Strength and Wellness through Yoga',
    heroVideo:
      'https://res.cloudinary.com/movementbuddy-pte-ltd/video/upload/v1688542514/probuddy/static/images/CategoriesPage/Yoga/video/yoga1_o9z8v2.mp4',
    heroImage:
      'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675485809/probuddy/static/images/CategoriesPage/Yoga/YogaHeroImage_bgzyfq.svg',

    section1: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677224089/probuddy/static/images/CategoriesPage/Yoga/workoutProgram_vf8csc.png',
      heading: 'YogaPage.yogaRight',
      description:
        'If you are looking for the best yoga classes in Singapore, then you have come to the right place. We offer a wide range of yoga classes for those who want to learn about their body and mind.  Our yoga teachers are experienced professionals who ensure that each class is not only enjoyable but also beneficial. Whether it’s a group or private session, we provide a safe and friendly environment for everyone to learn how to achieve their health goals.',
      linkText: 'Book A Yoga Class Now',
    },

    section2: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677224089/probuddy/static/images/CategoriesPage/Yoga/certifiedInstructorsImage_uyyhwb.png',
      heading: 'YogaPage.certifiedInstructors',
      description:
        "All instructors have been trained to world-class standards by one of the most respected yoga instructors in the world. All instructors are certified and licensed ensuring that they are well-versed in both the practice and philosophy of yoga. All of our classes are taught by certified yoga instructors, so you can be sure that you're learning from an expert in the field. This ensures that your yoga experience will be safe, effective, and enjoyable.",
      linkText: 'Book A Yoga Class Now',
    },

    exercise: {
      productTitle: 'Popular Yoga Listings in your area',
      title: 'Health Benefits Of Yoga',
      options: [
        {
          name: 'Promote Good Posture',
          description:
            'Yoga promotes good posture by strengthening the core muscles and improving body awareness.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677220819/probuddy/static/images/CategoriesPage/Yoga/YogaImage1_xb0d2y.png',
        },
        {
          name: 'Effective Brain Development',
          description: 'Yoga promotes a stronger and healthier brain.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677220813/probuddy/static/images/CategoriesPage/Yoga/YogaImage2_f7wtas.png',
        },
        {
          name: 'Reinforces better breathing',
          description: 'Yoga helps you to relax and focus on yourself.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677220810/probuddy/static/images/CategoriesPage/Yoga/YogaImage3_jboany.png',
        },
        {
          name: 'Relief from stress and anxiety',
          description: 'It also helps you sleep better and feel good about yourself.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677220802/probuddy/static/images/CategoriesPage/Yoga/YogaImage4_gh0k3q.png',
        },
        {
          name: 'Reduced blood pressure',
          description:
            'Yoga can help improve your concentration and focus, which will help you accomplish your goals in life.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677220798/probuddy/static/images/CategoriesPage/Yoga/YogaImage5_zc12lz.png',
        },
        {
          name: 'Enhanced Range of Motion',
          description:
            'Yoga enhances range of motion by focusing on movements that gently stretch and lengthen the muscles throughout the body.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677220794/probuddy/static/images/CategoriesPage/Yoga/YogaImage6_gcshbg.png',
        },
      ],
    },

    whyBook: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677229881/probuddy/static/images/CategoriesPage/Yoga/BookYogaImage_qctlnk.png',
      heading1: 'One Marketplace for All Things Sports, Events and Adventure',
      position: 'center',
      description1:
        'Probuddy is a one-stop Marketplace for all things sports, wellness & adventure. It gives you worldwide access to thousands of top-rated fitness instructors, yoga classes, tennis coaches, or rock climbing events. All you need to do is find your ideal sportds, wellness, or adventure activity and book it!',
      heading2: 'Why Book on Probuddy?',
      description2:
        "Probuddy is the easiest way to book the best fitness classes across the globe - no more waiting or driving around to find the right class for you. Our goal is to help you find the perfect instructor or class in your area so that you can make your next activity one you'll never forget!",
    },

    classesNearYou: {
      title: 'Book The Best Yoga Classes Near You',
      description:
        'Yoga is a very popular form of exercise and  there are hundreds of yoga classes for beginners, intermediate and advanced practitioners. Our goal is to help you find the right yoga classes near you while keeping your body, mind, and spirit for deeper healing and rejuvenation. Book now and start your yoga journey.',
    },

    instructors: {
      title: 'Client’s Choice Freelance ProYoga Instructors',
      description:
        'Our certified freelance yoga instructors are passionate about helping you grow in body and mind. They are dedicated to spreading positivity and inspiration through their classes. Browse through our offerings and book a class today!',
      instructorsData: [
        {
          id: '1',
          trainerImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677220787/probuddy/static/images/CategoriesPage/Yoga/YogaTrainerImage1_g1bp2r.png',
          name: 'Frankie Wong',
          type: 'Yoga Instructor',
          rating: '4.4',
          reviews: '18',
          category: 'yoga',
          description:
            'Center your body and mind, stretch the possibilities with personalized guidance from Probuddy yoga instructor Frankie Wong.',
        },
        {
          id: '2',
          trainerImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677220783/probuddy/static/images/CategoriesPage/Yoga/YogaTrainerImage2_ha6997.png',
          name: 'Carl Roberts',
          type: 'Yoga Instructor',
          rating: '4.2',
          reviews: '13',
          category: 'yoga',
          description:
            'Carl is a qualified and accredited yoga teacher and has been teaching yoga since 2015.',
        },
        {
          id: '3',
          trainerImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677220779/probuddy/static/images/CategoriesPage/Yoga/YogaTrainerImage3_vhqjva.png',
          name: 'Jenny Wilson',
          type: 'Yoga Instructor',
          rating: '4.6',
          reviews: '12',
          category: 'yoga',
          description:
            'Jenny embraces that yoga as a lifelong personal practice for the overall wellbeing of mind, body and spirit.',
        },
      ],
    },

    featured: {
      title: 'Top-Rated Yoga Studios and Companies',
      description:
        "Whether you're looking for a new workout routine or want to deepen your practice, there are plenty of options available in your area.If you're looking for a new yoga studios and events booking near you, take a look at these best-reviewed companies below.",
      featuredItems: [
        {
          id: '1',
          companyImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677220828/probuddy/static/images/CategoriesPage/Yoga/YogaCompanyImage1_utyly8.png',
          name: 'Barre 2 Barre',
          location: 'Pickering, Singapore',
          rating: '4.7',
          reviews: '210',
          category: 'yoga',
          description:
            "Barre 2 Barre is more than just a workout ~ it's a lifestyle that inspires confidence, community and celebration! Whether you are beginning an exercise program, a high level athlete, or just want to tone up, we have a program for you. Mindful movement is our motto and the common thread that runs through our philosophy and approach to living a healthy, happy life.",
        },
        {
          id: '2',
          companyImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677220828/probuddy/static/images/CategoriesPage/Yoga/YogaCompanyImage1_utyly8.png',
          name: 'Barre 2 Barre',
          location: 'Pickering, Singapore',
          rating: '4.6',
          reviews: '230',
          category: 'yoga',
          description:
            "Barre 2 Barre is more than just a workout ~ it's a lifestyle that inspires confidence, community and celebration! Whether you are beginning an exercise program, a high level athlete, or just want to tone up, we have a program for you. Mindful movement is our motto and the common thread that runs through our philosophy and approach to living a healthy, happy life.",
        },
      ],
    },

    topRatedClasses: {
      title: 'Find More Top-Rated Yoga Classes',
      description:
        "Get ready for a life-changing yoga experience!  Yoga is a wonderful way to relax and de-stress. It improves your body's flexibility, strength, and balance. If you're looking for a yoga classes that can help you achieve the best results, look no further than the following list of top-rated yoga classes near you.",
      classesData: [
        {
          id: '1',
          classesImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677220840/probuddy/static/images/CategoriesPage/Yoga/YogaClassesImage1_ycvwqj.png',
          className: 'Private Yoga Sessions At Your Space Or Mine *Includes Travel',
          instructorName: 'Frankie Wong',
          location: 'Bridge Road, Singapore',
          rating: '4.6',
          reviews: '196',
          category: 'yoga',
          description:
            'Book a session with Private Yoga Sessions at Your Space or Mine *Includes Travel.',
          bookingTimings: '8.00 Am to 10.00 Am',
          bookingPrice: '100',
        },
        {
          id: '2',
          classesImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677220836/probuddy/static/images/CategoriesPage/Yoga/YogaClassesImage2_xvxlui.png',
          className: 'Morning Meditation With Yoga',
          instructorName: 'Dion Russell',
          location: 'Los Angeles, CA',
          rating: '4.8',
          reviews: '230',
          category: 'yoga',
          description:
            'This class focuses on slowing down the body to allow it to calm down, heal, and recover. Recommended for everyone.',
          bookingTimings: '8.00 Am to 10.00 Am',
          bookingPrice: '120',
        },
        {
          id: '3',
          classesImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677220831/probuddy/static/images/CategoriesPage/Yoga/YogaClassesImage3_ardfoj.png',
          className: 'Basics, Core, Zen, Hit Yoga Session',
          instructorName: 'Sam Henderson',
          location: 'Melbourne, AU',
          rating: '4.6',
          reviews: '250',
          category: 'yoga',
          description:
            'A multi-level Vinyasa based class that integrates more effective learning and discerning yoga experience.',
          bookingTimings: '8.00 Am to 10.00 Am',
          bookingPrice: '100',
        },
      ],
    },

    reviews: [
      {
        name: 'Sam Sebastian',
        country: 'Melbourne',
        imageSrc: ReviewImage1,
        description:
          'The Probuddy platform is a great solution for business owners. We highly recommend it for businesses that need to keep track of everything.',
      },
      {
        name: 'Courtney Henry',
        country: 'Los Angeles, CA',
        imageSrc: ReviewImage1,
        description:
          'I have tried many online courses in my life, but none that have been so effective as Probuddy. The course has helped me with my business and gave me the tools to be more successful. The instructors are very knowledgeable and helpful. I have enjoyed the course and look forward to taking more classes.',
      },
      {
        name: 'Jeff Yao',
        country: 'Singapore',
        imageSrc: ReviewImage4,
        description:
          'Working with Probuddy was a great experience. Their platform covers all of your business needs, and they are easy to work with. I would highly recommend them.',
      },
    ],

    features: {
      title: 'Looking for a Yoga Instructor? We can help.',
      description:
        'Match up with verified and certified pros near you with Pro Connect+. Just create a Match Request, and we’ll connect you with a perfect match.',
    },

    sellers: {
      title:
        'Join over 1000 sports, wellness, and adventure Pros that have seen their business grow with Probuddy.',
      linkText: 'Learn More',
    },
  },

  {
    id: 'tennis',
    title: 'Tennis Classes',
    description: 'Tennis Lessons & Classes for Kids and Adults',
    heroVideo:
      'https://res.cloudinary.com/movementbuddy-pte-ltd/video/upload/v1687173237/probuddy/static/images/CategoriesPage/Tennis/Video/TENNIS_v7_hydl3g.mp4',
    heroImage:
      'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1685082963/probuddy/static/images/CategoriesPage/Tennis/tenis_heroImage_3_gd0ijc.png',

    section1: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677224835/probuddy/static/images/CategoriesPage/Tennis/TennisPlayersImage_rwynjg.png',
      heading: 'Tennis Classes For All Levels Of Players Regardless Of Age',
      description:
        'Looking for tennis lessons in a fun, professional setting? Want to learn tennis under the guidance of a professional tennis coach? Probuddy offers personalized coaching sessions and top-rated tennis group or private classes near you.',
      linkText: 'Book A Tennis Class Now',
    },

    section2: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677224837/probuddy/static/images/CategoriesPage/Tennis/ProfessionalTennisCoachesImage_fy3jgw.png',
      heading: 'Tennis Lesson By Professional Tennis Coaches Near You',
      description:
        'Probuddy has the widest assembly of tennis coaches and anywhere in the world. With decades of instructional and professional competitive experience worldwide, our tennis instructors are equipped with an unmatched wealth of experience and expertise.',
      linkText: 'Become A Tennis Pro',
    },

    exercise: {
      productTitle: 'Popular Tennis Listings in your area',
      title: 'Types of Tennis Classes Hosted by Pros',
      options: [
        {
          name: 'Kids Tennis Lesson',
          description:
            'Tennis helps build your kids self-confidence and develop good sportsmanship.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677224836/probuddy/static/images/CategoriesPage/Tennis/TennisImage1_d9qywe.png',
        },
        {
          name: 'Private Tennis Class',
          description:
            'A one-to-one private session with an experienced and world-class tennis coach.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677224836/probuddy/static/images/CategoriesPage/Tennis/TennisImage2_khqriu.png',
        },
        {
          name: 'Semi-Private Tennis Lesson',
          description: 'Suitable for tennis beginners and intermediate tennis players.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677224836/probuddy/static/images/CategoriesPage/Tennis/TennisImage3_ha25c2.png',
        },
        {
          name: 'Ladies Tennis Training',
          description: 'Our certified tennis coaches will guide you professionally.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677224836/probuddy/static/images/CategoriesPage/Tennis/TennisImage4_rj2zev.png',
        },
        {
          name: 'Group Tennis Lesson',
          description: 'Learn and spend time together with your family and friends.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677224835/probuddy/static/images/CategoriesPage/Tennis/TennisImage5_pqnxub.png',
        },
        {
          name: 'Corporate Tennis Lesson',
          description:
            'Enjoy team building activities as a company. Build stronger connections with your colleagues.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677224835/probuddy/static/images/CategoriesPage/Tennis/TennisImage6_b2tkaa.png',
        },
      ],
    },

    whyBook: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677224837/probuddy/static/images/CategoriesPage/Tennis/BookTennisImage_uut7me.png',
      heading1: 'One Marketplace for All Things Sports, Events and Adventure',
      position: 'center',
      containerPosition: 'start',
      description1:
        'Probuddy is a one-stop Marketplace for all things sports, wellness & adventure. It gives you worldwide access to thousands of top-rated fitness instructors, yoga classes, tennis coaches, or rock climbing events. All you need to do is find your ideal workout activity and book it!',
      heading2: 'Why Book on Probuddy?',
      description2:
        "Probuddy is the easiest way to book the best fitness classes across the globe - no more waiting or driving around to find the right class for you. Our goal is to help you find the perfect instructor or class in your area so that you can make your next activity one you'll never forget!",
    },

    classesNearYou: {
      title: 'Book The Best Tennis Classes Near You',
      description:
        'Learn tennis with the Pros in your area. Book a class with these world-renowned tennis coaches who are dedicated to improve your skills and abilities.',
    },

    instructors: {
      title: 'Top-Rated Wellness Coaches & Instructors',
      description:
        "You deserve a workout that isn't boring, and a community of like-minded people to push you to go further than you've gone before. At Probuddy we believe in the power of wellness and social connection to help you achieve your health and fitness goal.",
      instructorsData: [
        {
          id: '1',
          trainerImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661939156/probuddy/static/images/CategoriesPage/Tennis/TennisTrainer1_qy1dwe.png',
          name: 'Jaime Boliver',
          type: 'Christo Weise',
          rating: '4.4',
          reviews: '13',
          category: 'sports',
          description:
            'Delivering well-structured, customised tennis programs. Offering private tennis lessons for kids, juniors and adults.',
        },
        {
          id: '2',
          trainerImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661939155/probuddy/static/images/CategoriesPage/Tennis/TennisTrainer2_g1hg9j.png',
          name: 'Kris Wintel',
          type: 'Tennis Coach',
          rating: '4.5',
          reviews: '22',
          category: 'sports',
          description:
            'Train with tennis Pro Kris Wintel to develop your fastest and most efficient movement in a private or group tennis classes.',
        },
        {
          id: '3',
          trainerImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661939156/probuddy/static/images/CategoriesPage/Tennis/TennisTrainer3_ggdyyc.png',
          name: 'Carlos Chien',
          type: 'Tennis Coach',
          rating: '4.3',
          reviews: '12',
          category: 'sports',
          description:
            'Looking to learn basics of tennis, get your game to another level or to have a hit with high performance coach Carlos.',
        },
      ],
    },

    featured: {
      title: 'Popular Tennis Classes and Events Bookings',
      description:
        "Whether you're looking for a new workout routine or want to deepen your practice, there are plenty of options available in your area.If you're looking for new tennis lessons and events booking near you, take a look at these top-rated companies below.",
      featuredItems: [
        {
          id: '1',
          companyImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660296657/probuddy/static/images/CategoriesPage/Tennis/TennisCompanyImage1_c1jf8z.svg',
          name: 'Olaso Tennis Program ',
          location: 'ONE°15 Marina Sentosa Cove, Singapore',
          rating: '4.3',
          reviews: '187',
          category: 'boxing',
          description:
            'Olaso Tennis program now available at ONE°15 Marina Sentosa Cove, Singapore, is a waterfront lifestyle destination offering world-class marina facilities replete with a comprehensive range of exclusive club amenities. From the infinity pool, fitness centre, members’ lounge, modern spa, restaurants and bars, to the 26 tastefully appointed rooms with spectacular views, no effort has been spared to make your time here an absolute pleasure.',
        },
        {
          id: '2',
          companyImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660296657/probuddy/static/images/CategoriesPage/Tennis/TennisCompanyImage1_c1jf8z.svg',
          name: 'Olaso Tennis Program ',
          location: 'ONE°15 Marina Sentosa Cove, Singapore',
          rating: '4.6',
          reviews: '185',
          category: 'boxing',
          description:
            'Olaso Tennis program now available at ONE°15 Marina Sentosa Cove, Singapore, is a waterfront lifestyle destination offering world-class marina facilities replete with a comprehensive range of exclusive club amenities. From the infinity pool, fitness centre, members’ lounge, modern spa, restaurants and bars, to the 26 tastefully appointed rooms with spectacular views, no effort has been spared to make your time here an absolute pleasure.',
        },
      ],
    },

    topRatedClasses: {
      title: 'Featured Tennis Classes and Lessons near you',
      description:
        'Let our our world-class tennis coaches take your game to the next level with our featured tennis classes and lessons in your area.',
      classesData: [
        {
          id: '1',
          classesImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661939155/probuddy/static/images/CategoriesPage/Tennis/TennisClasses1_jmbcd4.png',
          className: 'Private  Tennis Classes for Adults',
          instructorName: 'Ricky Lowe',
          location: 'Brooklyn, NY',
          rating: '4.3',
          reviews: '196',
          category: 'boxing',
          description:
            'Private and group lessons available. Perfect for beginners & advanced. Fully guided session. Certified coaches.',
          bookingTimings: '8.00 Am to 10.00 Am',
          bookingPrice: '120',
        },
        {
          id: '2',
          classesImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661939155/probuddy/static/images/CategoriesPage/Tennis/TennisClasses2_r9byro.png',
          className: 'Children Tennis Group Session',
          instructorName: 'Stephen Chao',
          location: 'Singapore',
          rating: '4.8',
          reviews: '270',
          category: 'boxing',
          description:
            'Tennis lessons kids. Learn to play with professional coaches. We offer private tennis lessons for kids in your condo.',
          bookingTimings: '8.00 Am to 10.00 Am',
          bookingPrice: '40',
        },
        {
          id: '3',
          classesImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661939155/probuddy/static/images/CategoriesPage/Tennis/TennisClasses3_d9g6xi.png',
          className: 'Tennis Ladies Training (3 Players)',
          instructorName: 'Jenn Teller',
          location: 'Los Angeles, CA',
          rating: '4.6',
          reviews: '134',
          category: 'boxing',
          description:
            'Learn this game of a lifetime with your friends or in a group setting.  Intensive 4 day programs.',
          bookingTimings: '8.00 Am to 10.00 Am',
          bookingPrice: '150',
        },
      ],
    },

    reviews: [
      {
        name: 'Tim McGowan',
        country: 'Sydney',
        imageSrc: ReviewImage1,
        description:
          'Working with Probuddy was a great experience. Their platform covers all of your business needs, and they are easy to work with. I would highly recommend them.',
      },
      {
        name: 'Austin Wade',
        country: 'New York',
        imageSrc: ReviewImage5,
        description:
          'The Probuddy platform is a great solution for business owners. We highly recommend it for businesses that need to keep track of everything.',
      },
      {
        name: 'Michelle Uy',
        country: 'Singapore',
        imageSrc: ReviewImage3,
        description:
          'I have booked a yoga session using Probuddy and it’s the best thing. The instructor is very warm and friendly, thank you very much for that!',
      },
    ],

    features: {
      title: 'Looking for Tennis Classes? We can help.',
      description:
        'Match up with verified and certified pros near you with Pro Connect+. Just create a Match Request, and we’ll connect you with a perfect match.',
    },

    sellers: {
      title:
        'Join over 1000 sports, wellness, and adventure Pros that have seen their business grow with Probuddy.',
      linkText: 'Learn More',
    },
  },
  {
    id: 'padel-tennis',
    title: 'Padel Tennis Classes',
    description: 'Padel Tennis Lessons & Classes for Kids and Adults',
    // heroVideo:
    //   'https://res.cloudinary.com/movementbuddy-pte-ltd/video/upload/v1687173237/probuddy/static/images/CategoriesPage/Tennis/Video/TENNIS_v7_hydl3g.mp4',
    heroImage:
      'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1692765434/probuddy/static/images/CategoriesPage/Padel/000_32EA8CV_noe0gp.webp',

    section1: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1692765452/probuddy/static/images/CategoriesPage/Padel/5a73bfa764fd87896d87ea3db417df4f_jt6ize.webp',
      heading: 'Tennis Classes in Padel suitable for Players of All Ages and Skill Levels',
      description:
        "We offer classes that are designed to cater to individuals of every age and skill level. Whether you're a seasoned player or a complete novice, our program is tailored to meet your needs. Whether you're a young enthusiast looking to hone your skills or a senior player seeking an engaging way to stay active, our tennis classes in Padel are the perfect choice to embrace this dynamic and exciting sport. Join us, and let us hit the courts together!",
      linkText: 'Book A Padel Tennis Class Now',
    },

    section2: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1692765422/probuddy/static/images/CategoriesPage/Padel/man-playing-padel_c8xuyv.jpg',
      heading: 'Padel Tennis Lessons Conducted by Skilled Coaches Near You ',
      description:
        'Discover the world of Padel tennis through our expertly guided lessons, led by highly skilled Padel tennis coaches right in your local area. Our dedicated team of instructors brings a wealth of knowledge and experience to the court, ensuring that you receive top-notch coaching tailored to your needs and goals. Join us, and let our knowledgeable coaches help you take your Padel tennis game to the next level.',
      linkText: 'Become A Padel Tennis Pro',
    },

    exercise: {
      productTitle: 'Popular Padel Tennis Listings in your area',
      title: 'Types of Padel Tennis Classes Hosted by Pros',
      options: [
        {
          name: 'Kids Padel Tennis Lesson',
          description:
            "Padel tennis contributes to the enhancement of children's self-assurance and fosters the cultivation of positive sportsmanship traits.",
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1692871629/probuddy/static/images/CategoriesPage/Padel/csm_o_16617843890736b7_617cc71176_lsgkqs.jpg',
        },
        {
          name: 'Private Padel Tennis Class',
          description:
            'A personalized, private session with a highly skilled and internationally renowned padel tennis instructor.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1692871612/probuddy/static/images/CategoriesPage/Padel/Bishops_Padel-2133-scaled_a6uwoj.jpg',
        },
        {
          name: 'Semi-Private Padel Tennis Lesson',
          description:
            'Appropriate for newcomers to padel tennis as well as those at an intermediate skill level in the sport.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1692871600/probuddy/static/images/CategoriesPage/Padel/NMS_JAN20_Padel-Tennis-47_bjm6ck.jpg',
        },
        {
          name: 'Ladies Padel Tennis Training',
          description: 'Our certified padel tennis coaches will guide you professionally.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677224835/probuddy/static/images/CategoriesPage/Tennis/TennisImage6_b2tkaa.png',
        },
        {
          name: 'Group Padel Tennis Lesson',
          description: 'Learn and spend time together with your family and friends.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677224835/probuddy/static/images/CategoriesPage/Tennis/TennisImage5_pqnxub.png',
        },
        {
          name: 'Corporate Padel Tennis Lesson',
          description:
            'Enjoy team building activities as a company. Build stronger connections with your colleagues.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677224836/probuddy/static/images/CategoriesPage/Tennis/TennisImage2_khqriu.png',
        },
      ],
    },

    whyBook: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677224837/probuddy/static/images/CategoriesPage/Tennis/BookTennisImage_uut7me.png',
      heading1: 'One Marketplace for All Things Sports, Events and Adventure',
      position: 'center',
      containerPosition: 'start',
      description1:
        'Probuddy is a one-stop Marketplace for all things sports, wellness & adventure. It gives you worldwide access to thousands of top-rated fitness instructors, yoga classes, tennis coaches, or rock climbing events. All you need to do is find your ideal workout activity and book it!',
      heading2: 'Why Book on Probuddy?',
      description2:
        "Probuddy is the easiest way to book the best fitness classes across the globe - no more waiting or driving around to find the right class for you. Our goal is to help you find the perfect instructor or class in your area so that you can make your next activity one you'll never forget!",
    },

    classesNearYou: {
      title: 'Book The Best Padel Tennis Classes Near You',
      description:
        'Learn padel tennis with the Pros in your area. Book a class with these world-renowned padel tennis coaches who are dedicated to improve your skills and abilities.',
    },

    instructors: {
      title: 'Top-Rated Wellness Coaches & Instructors',
      description:
        "You deserve a workout that isn't boring, and a community of like-minded people to push you to go further than you've gone before. At Probuddy we believe in the power of wellness and social connection to help you achieve your health and fitness goal.",
      instructorsData: [
        {
          id: '1',
          trainerImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1692959271/probuddy/static/images/CategoriesPage/Tennis/image0_mpgpqv.jpg',
          name: 'Michel van de Sanden',
          type: 'Padel Tennis Coach',
          rating: '4.4',
          reviews: '13',
          category: 'sports',
          description:
            'Accomplished Tennis and Padel Coach in Singapore. Former professional athlete, currently enthusiastic about golf and squash.',
        },
        {
          id: '2',
          trainerImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661939155/probuddy/static/images/CategoriesPage/Tennis/TennisTrainer2_g1hg9j.png',
          name: 'Ezequiel Suarez',
          type: 'Tennis Coach',
          rating: '4.5',
          reviews: '22',
          category: 'sports',
          description:
            "Ezequiel achieved recognition as one of the nation's elite junior tennis players, earning him a scholarship to compete in tennis in US.",
        },
        {
          id: '3',
          trainerImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661939156/probuddy/static/images/CategoriesPage/Tennis/TennisTrainer3_ggdyyc.png',
          name: 'Juan Ricardo Ortiz',
          type: 'Tennis Coach',
          rating: '4.3',
          reviews: '12',
          category: 'sports',
          description:
            'Juan Richard Ortiz is a former #1 nationally ranked U16 player in Paraguay, participation in Junior ITF tournaments.',
        },
      ],
    },

    // featured: {
    //   title: 'Popular Padel Tennis Classes and Events Bookings',
    //   description:
    //     "Whether you're looking for a new workout routine or want to deepen your practice, there are plenty of options available in your area.If you're looking for new padel tennis lessons and events booking near you, take a look at these top-rated companies below.",
    //   featuredItems: [
    //     {
    //       id: '1',
    //       companyImage:
    //         'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660296657/probuddy/static/images/CategoriesPage/Tennis/TennisCompanyImage1_c1jf8z.svg',
    //       name: 'Olaso Tennis Program ',
    //       location: 'ONE°15 Marina Sentosa Cove, Singapore',
    //       rating: '4.3',
    //       reviews: '187',
    //       category: 'boxing',
    //       description:
    //         'Olaso Tennis program now available at ONE°15 Marina Sentosa Cove, Singapore, is a waterfront lifestyle destination offering world-class marina facilities replete with a comprehensive range of exclusive club amenities. From the infinity pool, fitness centre, members’ lounge, modern spa, restaurants and bars, to the 26 tastefully appointed rooms with spectacular views, no effort has been spared to make your time here an absolute pleasure.',
    //     },
    //     {
    //       id: '2',
    //       companyImage:
    //         'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660296657/probuddy/static/images/CategoriesPage/Tennis/TennisCompanyImage1_c1jf8z.svg',
    //       name: 'Olaso Tennis Program ',
    //       location: 'ONE°15 Marina Sentosa Cove, Singapore',
    //       rating: '4.6',
    //       reviews: '185',
    //       category: 'boxing',
    //       description:
    //         'Olaso Tennis program now available at ONE°15 Marina Sentosa Cove, Singapore, is a waterfront lifestyle destination offering world-class marina facilities replete with a comprehensive range of exclusive club amenities. From the infinity pool, fitness centre, members’ lounge, modern spa, restaurants and bars, to the 26 tastefully appointed rooms with spectacular views, no effort has been spared to make your time here an absolute pleasure.',
    //     },
    //   ],
    // },

    // topRatedClasses: {
    //   title: 'Featured Padel Tennis Classes and Lessons near you',
    //   description:
    //     'Let our our world-class padel tennis coaches take your game to the next level with our featured padel tennis classes and lessons in your area.',
    //   classesData: [
    //     {
    //       id: '1',
    //       classesImage:
    //         'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661939155/probuddy/static/images/CategoriesPage/Tennis/TennisClasses1_jmbcd4.png',
    //       className: 'Private Padel Tennis Classes for Adults',
    //       instructorName: 'Ricky Lowe',
    //       location: 'Brooklyn, NY',
    //       rating: '4.3',
    //       reviews: '196',
    //       category: 'boxing',
    //       description:
    //         'Private and group lessons available. Perfect for beginners & advanced. Fully guided session. Certified coaches.',
    //       bookingTimings: '8.00 Am to 10.00 Am',
    //       bookingPrice: '120',
    //     },
    //     {
    //       id: '2',
    //       classesImage:
    //         'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661939155/probuddy/static/images/CategoriesPage/Tennis/TennisClasses2_r9byro.png',
    //       className: 'Children Padel Tennis Group Session',
    //       instructorName: 'Stephen Chao',
    //       location: 'Singapore',
    //       rating: '4.8',
    //       reviews: '270',
    //       category: 'boxing',
    //       description:
    //         'Padel Tennis lessons kids. Learn to play with professional coaches. We offer private padel tennis lessons for kids in your condo.',
    //       bookingTimings: '8.00 Am to 10.00 Am',
    //       bookingPrice: '40',
    //     },
    //     {
    //       id: '3',
    //       classesImage:
    //         'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661939155/probuddy/static/images/CategoriesPage/Tennis/TennisClasses3_d9g6xi.png',
    //       className: 'Padel Tennis Ladies Training (3 Players)',
    //       instructorName: 'Jenn Teller',
    //       location: 'Los Angeles, CA',
    //       rating: '4.6',
    //       reviews: '134',
    //       category: 'boxing',
    //       description:
    //         'Learn this game of a lifetime with your friends or in a group setting.  Intensive 4 day programs.',
    //       bookingTimings: '8.00 Am to 10.00 Am',
    //       bookingPrice: '150',
    //     },
    //   ],
    // },

    reviews: [
      {
        name: 'Tim McGowan',
        country: 'Sydney',
        imageSrc: ReviewImage1,
        description:
          'Working with Probuddy was a great experience. Their platform covers all of your business needs, and they are easy to work with. I would highly recommend them.',
      },
      {
        name: 'Austin Wade',
        country: 'New York',
        imageSrc: ReviewImage5,
        description:
          'The Probuddy platform is a great solution for business owners. We highly recommend it for businesses that need to keep track of everything.',
      },
      {
        name: 'Michelle Uy',
        country: 'Singapore',
        imageSrc: ReviewImage3,
        description:
          'I have booked a yoga session using Probuddy and it’s the best thing. The instructor is very warm and friendly, thank you very much for that!',
      },
    ],

    features: {
      title: 'Looking for Padel Tennis Classes? We can help.',
      description:
        'Match up with verified and certified pros near you with Pro Connect+. Just create a Match Request, and we’ll connect you with a perfect match.',
    },

    sellers: {
      title:
        'Join over 1000 sports, wellness, and adventure Pros that have seen their business grow with Probuddy.',
      linkText: 'Learn More',
    },
  },

  {
    id: 'pickle-ball',
    title: 'Pickleball Classes',
    description: 'Enjoy Pickleball for All Levels and Ages',
    // heroVideo:
    //   'https://res.cloudinary.com/movementbuddy-pte-ltd/video/upload/v1687173237/probuddy/static/images/CategoriesPage/Tennis/Video/TENNIS_v7_hydl3g.mp4',
    heroImage:
      'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1696927962/probuddy/static/images/CategoriesPage/Pickleball/17_ntprzt.png',

    section1: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1696927961/probuddy/static/images/CategoriesPage/Pickleball/18_oozafl.png',
      heading: 'Pickleball Lessons Suitable for All Ages and Skill Levels',
      description:
        "Are you seeking an exciting pickleball adventure in a top-notch setting? Probuddy has you covered. Join our expert instructors and elevate your pickleball skills, whether you're a seasoned enthusiast or new to the game.",
      linkText: 'Book A Pickleball Class Now',
    },

    section2: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1696927960/probuddy/static/images/CategoriesPage/Pickleball/19_ldspbn.png',
      heading: 'Elevate Your Pickleball Game with Local Pickleball Pros at Your Fingertips ',
      description:
        'Probuddy brings together an extensive network of pickleball experts, hailing from various parts of the world. With decades of teaching and professional experience on a global scale, our pickleball instructors possess a wealth of unmatched knowledge and expertise in the sport.',
      linkText: 'Become A Pickleball Pro',
    },

    exercise: {
      productTitle: 'Popular Pickleball Listings in your area',
      title: 'Types of Pickleball Classes Hosted by Pros',
      options: [
        {
          name: 'Beginner Pickleball Class',
          description:
            'Perfect for newcomers to pickleball. Learn the basics and start enjoying the game.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1696927967/probuddy/static/images/CategoriesPage/Pickleball/13_wfwdzj.png',
        },
        {
          name: 'Advanced Pickleball Coaching',
          description:
            'Take your pickleball skills to the next level with personalized coaching from experts.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1696927966/probuddy/static/images/CategoriesPage/Pickleball/14_ahnygu.png',
        },
        {
          name: 'Pickleball Doubles Training',
          description: 'Enhance your doubles game with specialized training sessions.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1696927965/probuddy/static/images/CategoriesPage/Pickleball/15_nnak05.png',
        },
        {
          name: 'Pickleball for Seniors',
          description: 'Tailored classes for seniors looking to stay active and enjoy pickleball.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1696927975/probuddy/static/images/CategoriesPage/Pickleball/3_vsp7s8.png',
        },
        {
          name: 'Family Pickleball Sessions',
          description: 'Enjoy quality family time while learning and playing pickleball together.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1696927957/probuddy/static/images/CategoriesPage/Pickleball/22_a4ubpk.png',
        },
        {
          name: 'Corporate Pickleball Workshops',
          description: 'Build teamwork and have fun with corporate pickleball workshops.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1696927963/probuddy/static/images/CategoriesPage/Pickleball/16_i1fobk.png',
        },
      ],
    },

    whyBook: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677224837/probuddy/static/images/CategoriesPage/Tennis/BookTennisImage_uut7me.png',
      heading1: 'One Marketplace for All Things Sports, Events and Adventure',
      position: 'center',
      containerPosition: 'start',
      description1:
        'Probuddy is a one-stop Marketplace for all things sports, wellness & adventure. It gives you worldwide access to thousands of top-rated fitness instructors, yoga classes, tennis coaches, or rock climbing events. All you need to do is find your ideal workout activity and book it!',
      heading2: 'Why Book on Probuddy?',
      description2:
        "Probuddy is the easiest way to book the best fitness classes across the globe - no more waiting or driving around to find the right class for you. Our goal is to help you find the perfect instructor or class in your area so that you can make your next activity one you'll never forget!",
    },

    classesNearYou: {
      title: 'Book The Best Pickleball Classes Near You',
      description:
        'Learn pickleball with the Pros in your area. Book a class with these world-renowned pickleball coaches who are dedicated to improve your skills and abilities.',
    },

    instructors: {
      title: 'Top-Rated Wellness Coaches & Instructors',
      description:
        "You deserve a workout that isn't boring, and a community of like-minded people to push you to go further than you've gone before. At Probuddy we believe in the power of wellness and social connection to help you achieve your health and fitness goal.",
      instructorsData: [
        {
          id: '1',
          trainerImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1696996009/byjmvcce5htyummjozhq.jpg',
          name: 'Charli Teo',
          type: 'Pickle Ball Coach',
          rating: '4.4',
          reviews: '13',
          category: 'sports',
          description:
            "I'm Charlie Teo, and my journey with pickleball has been a lifelong love affair. With its unique blend of tennis and badminton has been my companion.",
        },
        {
          id: '2',
          trainerImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1696927972/probuddy/static/images/CategoriesPage/Pickleball/6_h0tivf.png',
          name: 'Roger Ho',
          type: 'Pickle Ball Coach',
          rating: '4.5',
          reviews: '22',
          category: 'sports',
          description:
            'A seasoned pickleball coach in the bustling scene of Singapore. With a wealth of experience in the sport, I am here to guide players to success on the court.',
        },
        {
          id: '3',
          trainerImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1697009191/probuddy/static/images/CategoriesPage/Pickleball/processed-77f8b816-ba09-4533-a927-1ebf49a57f7c_qR8aumc3_qsmal1.jpg',
          name: 'Alfred Chua Boonhuat',
          type: 'Pickle Ball Coach',
          rating: '4.3',
          reviews: '12',
          category: 'sports',
          description:
            "I'm Alfred Chua Boonhuat, a passionate pickleball coach eager to help players of all levels improve their skills and foster a love for the sport.",
        },
      ],
    },

    // featured: {
    //   title: 'Popular Padel Tennis Classes and Events Bookings',
    //   description:
    //     "Whether you're looking for a new workout routine or want to deepen your practice, there are plenty of options available in your area.If you're looking for new padel tennis lessons and events booking near you, take a look at these top-rated companies below.",
    //   featuredItems: [
    //     {
    //       id: '1',
    //       companyImage:
    //         'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660296657/probuddy/static/images/CategoriesPage/Tennis/TennisCompanyImage1_c1jf8z.svg',
    //       name: 'Olaso Tennis Program ',
    //       location: 'ONE°15 Marina Sentosa Cove, Singapore',
    //       rating: '4.3',
    //       reviews: '187',
    //       category: 'boxing',
    //       description:
    //         'Olaso Tennis program now available at ONE°15 Marina Sentosa Cove, Singapore, is a waterfront lifestyle destination offering world-class marina facilities replete with a comprehensive range of exclusive club amenities. From the infinity pool, fitness centre, members’ lounge, modern spa, restaurants and bars, to the 26 tastefully appointed rooms with spectacular views, no effort has been spared to make your time here an absolute pleasure.',
    //     },
    //     {
    //       id: '2',
    //       companyImage:
    //         'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660296657/probuddy/static/images/CategoriesPage/Tennis/TennisCompanyImage1_c1jf8z.svg',
    //       name: 'Olaso Tennis Program ',
    //       location: 'ONE°15 Marina Sentosa Cove, Singapore',
    //       rating: '4.6',
    //       reviews: '185',
    //       category: 'boxing',
    //       description:
    //         'Olaso Tennis program now available at ONE°15 Marina Sentosa Cove, Singapore, is a waterfront lifestyle destination offering world-class marina facilities replete with a comprehensive range of exclusive club amenities. From the infinity pool, fitness centre, members’ lounge, modern spa, restaurants and bars, to the 26 tastefully appointed rooms with spectacular views, no effort has been spared to make your time here an absolute pleasure.',
    //     },
    //   ],
    // },

    // topRatedClasses: {
    //   title: 'Featured Padel Tennis Classes and Lessons near you',
    //   description:
    //     'Let our our world-class padel tennis coaches take your game to the next level with our featured padel tennis classes and lessons in your area.',
    //   classesData: [
    //     {
    //       id: '1',
    //       classesImage:
    //         'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661939155/probuddy/static/images/CategoriesPage/Tennis/TennisClasses1_jmbcd4.png',
    //       className: 'Private Padel Tennis Classes for Adults',
    //       instructorName: 'Ricky Lowe',
    //       location: 'Brooklyn, NY',
    //       rating: '4.3',
    //       reviews: '196',
    //       category: 'boxing',
    //       description:
    //         'Private and group lessons available. Perfect for beginners & advanced. Fully guided session. Certified coaches.',
    //       bookingTimings: '8.00 Am to 10.00 Am',
    //       bookingPrice: '120',
    //     },
    //     {
    //       id: '2',
    //       classesImage:
    //         'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661939155/probuddy/static/images/CategoriesPage/Tennis/TennisClasses2_r9byro.png',
    //       className: 'Children Padel Tennis Group Session',
    //       instructorName: 'Stephen Chao',
    //       location: 'Singapore',
    //       rating: '4.8',
    //       reviews: '270',
    //       category: 'boxing',
    //       description:
    //         'Padel Tennis lessons kids. Learn to play with professional coaches. We offer private padel tennis lessons for kids in your condo.',
    //       bookingTimings: '8.00 Am to 10.00 Am',
    //       bookingPrice: '40',
    //     },
    //     {
    //       id: '3',
    //       classesImage:
    //         'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661939155/probuddy/static/images/CategoriesPage/Tennis/TennisClasses3_d9g6xi.png',
    //       className: 'Padel Tennis Ladies Training (3 Players)',
    //       instructorName: 'Jenn Teller',
    //       location: 'Los Angeles, CA',
    //       rating: '4.6',
    //       reviews: '134',
    //       category: 'boxing',
    //       description:
    //         'Learn this game of a lifetime with your friends or in a group setting.  Intensive 4 day programs.',
    //       bookingTimings: '8.00 Am to 10.00 Am',
    //       bookingPrice: '150',
    //     },
    //   ],
    // },

    reviews: [
      {
        name: 'Tim McGowan',
        country: 'Sydney',
        imageSrc: ReviewImage1,
        description:
          'Working with Probuddy was a great experience. Their platform covers all of your business needs, and they are easy to work with. I would highly recommend them.',
      },
      {
        name: 'Austin Wade',
        country: 'New York',
        imageSrc: ReviewImage5,
        description:
          'The Probuddy platform is a great solution for business owners. We highly recommend it for businesses that need to keep track of everything.',
      },
      {
        name: 'Michelle Uy',
        country: 'Singapore',
        imageSrc: ReviewImage3,
        description:
          'I have booked a yoga session using Probuddy and it’s the best thing. The instructor is very warm and friendly, thank you very much for that!',
      },
    ],

    features: {
      title: 'Looking for Padel Tennis Classes? We can help.',
      description:
        'Match up with verified and certified pros near you with Pro Connect+. Just create a Match Request, and we’ll connect you with a perfect match.',
    },

    sellers: {
      title:
        'Join over 1000 sports, wellness, and adventure Pros that have seen their business grow with Probuddy.',
      linkText: 'Learn More',
    },
  },

  // {
  //   id: 'boxing',
  //   title: 'Boxing Classes',
  //   description: 'Boxing Classes To Knock You In Good Shape',
  //   heroImage:
  //     'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660379691/probuddy/static/images/CategoriesPage/Boxing/BoxingHeroImage_hhxmln.png',

  //   section1: {
  //     imgSrc:
  //       'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677228667/probuddy/static/images/CategoriesPage/Boxing/BoxingTrainingSessionsImage_y13r3f.png',
  //     heading: 'Tailor Made Boxing Training Sessions',
  //     description:
  //       'If you are looking for a fun and easy way to improve your fitness or just want to learn how to box, Probuddy can help. With boxing classes near you, you can train safely in a group or private setting that’s tailored to your needs. Our structured workouts are cater to all levels, beginners to advanced, and our experts will help you reach your fitness goals with ease.',
  //     linkText: 'Book A Boxing Class Now',
  //   },

  //   section2: {
  //     imgSrc:
  //       'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677228667/probuddy/static/images/CategoriesPage/Boxing/ProfessionalCoachesImage_xr17e7.png',
  //     heading: 'Accredited and Professional Coaches to Guide You',
  //     description:
  //       'Whether you are experienced or beginner, Probuddy has everything you need to learn the sport. Find your progression, develop your fitness all while improving technique, power, speed and endurance. Our Probuddy team’s certified coaches can help you through every step of your journey. Gloves up! ',
  //     linkText: 'Become a Boxing Pro',
  //   },

  //   exercise: {
  //     productTitle: 'Popular Boxing Listings in your area',
  //     title: 'Types of Boxing Classes Hosted by Pros',
  //     options: [
  //       {
  //         name: 'Boxing Training for Beginners',
  //         description:
  //           'Highly recommended for beginners and for those who wish to refine their boxing fundamentals.',
  //         imageSrc:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677228664/probuddy/static/images/CategoriesPage/Boxing/BoxingImage1_mqfipq.png',
  //       },
  //       {
  //         name: 'Boxing Class for Kids',
  //         description:
  //           'Our kids boxing also teaches and cultivates discipline and sportsmanship within the kids.',
  //         imageSrc:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677228665/probuddy/static/images/CategoriesPage/Boxing/BoxingImage2_loh2ag.png',
  //       },
  //       {
  //         name: 'Private Boxing Training',
  //         description:
  //           'Tailored to meet your own fitness goals while also improving your form, coordination and stamina.',
  //         imageSrc:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677228665/probuddy/static/images/CategoriesPage/Boxing/BoxingImage3_bdidcn.png',
  //       },
  //       {
  //         name: 'Strength & Conditioning',
  //         description:
  //           "Physical training techniques that boxers do to achieve a boxer's stamina, speed and endurance.",
  //         imageSrc:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677228666/probuddy/static/images/CategoriesPage/Boxing/BoxingImage4_ulchto.png',
  //       },
  //       {
  //         name: 'Competitive Training',
  //         description:
  //           'Packed with short and intense bursts of exercises and boxing rounds, this class is for you if you’re looking for fitness boxing.',
  //         imageSrc:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677228665/probuddy/static/images/CategoriesPage/Boxing/BoxingImage5_u6r4qn.png',
  //       },
  //       {
  //         name: 'Group Training',
  //         description:
  //           "You'll learn how to give effective boxing fundamentals. This class is a great way to relieve your stress!",
  //         imageSrc:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677228665/probuddy/static/images/CategoriesPage/Boxing/BoxingImage6_qkrcbq.png',
  //       },
  //     ],
  //   },

  //   whyBook: {
  //     imgSrc:
  //       'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677228664/probuddy/static/images/CategoriesPage/Boxing/BookBoxingImage_x5lqkw.png',
  //     heading1: 'One Marketplace for All Things Sports, Events and Adventure',
  //     position: 'center',
  //     containerPosition: 'start',
  //     description1:
  //       'Probuddy is a one-stop Marketplace for all things sports, wellness & adventure. It gives you worldwide access to thousands of top-rated fitness instructors, yoga classes, tennis coaches, or rock climbing events. All you need to do is find your ideal workout activity and book it!',
  //     heading2: 'Why Book on Probuddy?',
  //     description2:
  //       "Probuddy is the easiest way to book the best fitness classes across the globe - no more waiting or driving around to find the right class for you. Our goal is to help you find the perfect instructor or class in your area so that you can make your next activity one you'll never forget!",
  //   },

  //   classesNearYou: {
  //     title: 'Book The Best Boxing Classes and Trainings Near You',
  //     description:
  //       'Our certified boxing coaches are passionate about helping you grow. They are dedicated to spreading positivity and inspiration through their classes. Browse through our offerings, book a class today, and feel the difference!',
  //   },

  //   instructors: {
  //     title: 'Top-Rated Wellness Coaches & Instructors',
  //     description:
  //       "You deserve a workout that isn't boring, and a community of like-minded people to push you to go further than you've gone before. At Probuddy we believe in the power of wellness and social connection to help you achieve your health and fitness goal.",
  //     instructorsData: [
  //       {
  //         id: '1',
  //         trainerImage:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677228666/probuddy/static/images/CategoriesPage/Boxing/BoxingTrainerImage1_rdcyqp.png',
  //         name: 'Mike Vaughn',
  //         type: 'Boxing Trainer',
  //         rating: '4.4',
  //         reviews: '15',
  //         category: 'boxing',
  //         description:
  //           'Book a session with Private Boxing Sessions at Your Space or Mine *Includes Travel',
  //       },
  //       {
  //         id: '2',
  //         trainerImage:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677228666/probuddy/static/images/CategoriesPage/Boxing/BoxingTrainerImage2_f3vhcx.png',
  //         name: 'Julian Larson',
  //         type: 'Boxing Coach',
  //         rating: '4.5',
  //         reviews: '19',
  //         category: 'boxing',
  //         description:
  //           'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ipsum, nec vestibulum lectus pharetra, metus.',
  //       },
  //       {
  //         id: '3',
  //         trainerImage:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677228667/probuddy/static/images/CategoriesPage/Boxing/BoxingTrainerImage3_c0dev2.png',
  //         name: 'Steve Liao',
  //         type: 'Boxing Instructor',
  //         rating: '4.7',
  //         reviews: '13',
  //         category: 'boxing',
  //         description:
  //           'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ipsum, nec vestibulum lectus pharetra, metus.',
  //       },
  //     ],
  //   },

  //   featured: {
  //     title: 'Featured Boxing Gyms and Events Bookings',
  //     description:
  //       "To get the most out of your workouts, it's important to find a gym or trainer that fits your needs and schedule. Take a look at these top-rated profiles and classes near you to find a place that suits you. Book a class today!",
  //     featuredItems: [
  //       {
  //         id: '1',
  //         companyImage:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677228665/probuddy/static/images/CategoriesPage/Boxing/BoxingCompanyImage1_vjlxd7.png',
  //         name: 'The Ring',
  //         location: 'Kim Yam Road, Singapore',
  //         rating: '4.1',
  //         reviews: '225',
  //         category: 'boxing',
  //         description:
  //           'Learn the art of sweet science in this ultra-empowering and stress-reducing workout that helps you kick ass and improve cardiovascular strength. Experience authentic boxing and work out with a combination of a cardio and technical boxing class.',
  //       },
  //       {
  //         id: '2',
  //         companyImage:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677228665/probuddy/static/images/CategoriesPage/Boxing/BoxingCompanyImage1_vjlxd7.png',
  //         name: 'The Ring',
  //         location: 'Kim Yam Road, Singapore',
  //         rating: '4.2',
  //         reviews: '198',
  //         category: 'boxing',
  //         description:
  //           'Learn the art of sweet science in this ultra-empowering and stress-reducing workout that helps you kick ass and improve cardiovascular strength. Experience authentic boxing and work out with a combination of a cardio and technical boxing class.',
  //       },
  //     ],
  //   },

  //   topRatedClasses: {
  //     title: 'Find More Top-Rated Boxing Classes',
  //     description:
  //       'Book your boxing class to learn the basics of boxing in a beginner-friendly environment or be guided by our experienced certified world-class boxing coaches near you.',
  //     classesData: [
  //       {
  //         id: '1',
  //         classesImage:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677228664/probuddy/static/images/CategoriesPage/Boxing/BoxingClassesImage1_ebvid3.png',
  //         className: 'Boxing Group Class for Kids',
  //         instructorName: 'Mike Vaughn',
  //         location: 'Melbourne, AU',
  //         rating: '4.8',
  //         reviews: '225',
  //         category: 'boxing',
  //         description:
  //           'Book a session with Private Yoga Sessions at Your Space or Mine *Includes Travel',
  //         bookingTimings: '8.00 Am to 10.00 Am',
  //         bookingPrice: '100',
  //       },
  //       {
  //         id: '2',
  //         classesImage:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677228664/probuddy/static/images/CategoriesPage/Boxing/BoxingClassesImage2_bwn1oe.png',
  //         className: '1 on 1 Personal Boxing Training',
  //         instructorName: 'Dion Russell',
  //         location: 'Yishun, Singapore',
  //         rating: '4.8',
  //         reviews: '201',
  //         category: 'boxing',
  //         description:
  //           'Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text.',
  //         bookingTimings: '8.00 Am to 10.00 Am',
  //         bookingPrice: '120',
  //       },
  //       {
  //         id: '3',
  //         classesImage:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677228664/probuddy/static/images/CategoriesPage/Boxing/BoxingClassesImage3_fve3vs.png',
  //         className: 'Advanced Competitive Boxing Class',
  //         instructorName: 'Dianne Russell',
  //         location: 'Bukit Batok, SG',
  //         rating: '4.6',
  //         reviews: '225',
  //         category: 'boxing',
  //         description:
  //           'Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text.',
  //         bookingTimings: '8.00 Am to 10.00 Am',
  //         bookingPrice: '120',
  //       },
  //     ],
  //   },

  //   reviews: [
  //     {
  //       name: 'Courtney Henry',
  //       country: 'Hong Kong',
  //       imageSrc: ReviewImage1,
  //       description:
  //         'The Probuddy platform is a great solution for business owners. We highly recommend it for businesses that need to keep track of everything.',
  //     },
  //     {
  //       name: 'Simon Hart',
  //       country: 'Texas',
  //       imageSrc: ReviewImage2,
  //       description:
  //         'I have tried many online courses in my life, but none that have been so effective as Probuddy. The course has helped me with my business and gave me the tools to be more successful. The instructors are very knowledgeable and helpful. I have enjoyed the course and look forward to taking more classes.',
  //     },
  //     {
  //       name: 'Hayley Smith',
  //       country: 'Manchester',
  //       imageSrc: ReviewImage6,
  //       description:
  //         'Taking time out to destress with a tennis session is always a wonderful thing. Using Probuddy makes it even more enjoyable.',
  //     },
  //   ],

  //   features: {
  //     title: 'Looking for Tennis Classes? We can help.',
  //     description:
  //       'Match up with verified and certified pros near you with Pro Connect+. Just create a Match Request, and we’ll connect you with a perfect match.',
  //   },

  //   sellers: {
  //     title:
  //       'Join over 1000 sports, wellness, and adventure Pros that have seen their business grow with Probuddy.',
  //     linkText: 'Learn More',
  //   },
  // },

  {
    id: 'pilates',
    title: 'Pilates Classes',
    description: 'Discover Balance & Harmony With Pilates',
    heroImage:
      'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660825608/probuddy/static/images/CategoriesPage/Pilates/PilatesHeroImage_l4g9vx.png',

    section1: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660825606/probuddy/static/images/CategoriesPage/Pilates/BookPilatesClasses_dmwco9.png',
      heading: 'Book Pilates Classes That Works & Designed For You',
      description:
        "Whether you're looking to work on your core strength, balance, flexibility or even learn new exercises, Probuddy offers hundreds of pilates sessions all over the world. We help hundreds of fitness enthusiasts from around the world find and book the best pilates classes and sessions with accredited, professional coaches near you and designed for you.",
      linkText: 'Book A Pilates Class Now',
    },

    section2: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660825606/probuddy/static/images/CategoriesPage/Pilates/BestPilatesInstructors_qpzlol.png',
      heading: 'Choose from the Best Pilates Instructors',
      description:
        "Whether it’s your first time or you're an experienced fitness enthusiast, stepping into a Pilates class can be intimidating. That’s why Probuddy is here to help! We have the best pilates coaches who will guide you and offer personalized exercises designed specifically for your needs. Our certified professionals will make sure you get the most out of your sessions and always work at your pace to ensure maximum results.",
      linkText: 'Become a Pilates Pro',
    },

    exercise: {
      productTitle: 'Popular Pilates Listings in your area',
      title: 'Types of Pilates Classes Hosted by Pros',
      options: [
        {
          name: 'Pilates Private Sessions',
          description:
            'Looking for some one-on-one attention for a session tailored to your body? Try our private Pilates classes.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660825607/probuddy/static/images/CategoriesPage/Pilates/PilatesImage1_klsj8c.png',
        },
        {
          name: 'Group Classes',
          description: 'Enjoy a fun and unique group class experience on a pilates.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660825607/probuddy/static/images/CategoriesPage/Pilates/PilatesImage2_zey50q.png',
        },
        {
          name: 'Reformer Pilates',
          description: 'Develop a better understanding of your posture, strengths, and weaknesses.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660825607/probuddy/static/images/CategoriesPage/Pilates/PilatesImage3_vlr8zu.png',
        },
        {
          name: 'Prenatal & Postpartum Pilates',
          description:
            'An excellent way to help you prepare for natural childbirth and life after pregnancy.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660825607/probuddy/static/images/CategoriesPage/Pilates/PilatesImage4_swn80v.png',
        },
        {
          name: 'Advanced Pilates',
          description: 'Take your fitness to the next level with this advanced pilates routine.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660825608/probuddy/static/images/CategoriesPage/Pilates/PilatesImage5_fnlqcp.png',
        },
        {
          name: 'Stretching Classes',
          description:
            'Reprogram your body to keep muscles flexible, long and toned with stretch mat Pilates classes.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660825608/probuddy/static/images/CategoriesPage/Pilates/PilatesImage6_twtiqf.png',
        },
      ],
    },

    whyBook: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660825606/probuddy/static/images/CategoriesPage/Pilates/BookPilatesImage_hqptht.png',
      heading1: 'One Marketplace for All Things Sports, Events and Adventure',
      position: 'center',
      containerPosition: 'start',
      description1:
        'Probuddy is a one-stop Marketplace for all things sports, wellness & adventure. It gives you worldwide access to thousands of top-rated fitness instructors, yoga classes, tennis coaches, or rock climbing events. All you need to do is find your ideal workout activity and book it!',
      heading2: 'Why Book on Probuddy?',
      description2:
        "Probuddy is the easiest way to book the best fitness classes across the globe - no more waiting or driving around to find the right class for you. Our goal is to help you find the perfect instructor or class in your area so that you can make your next activity one you'll never forget!",
    },

    classesNearYou: {
      title: 'Book The Best Pilates Classes and Sessions Near You',
      description:
        "The first ever marketplace for the best pilates classes near you, with accredited and professional pilates coaches to guide you. Our goal is to help you  find the best pilates class and session near them. We'll guide you through a ton of options based on your needs and location.",
    },

    instructors: {
      title: 'Featured Top-Rated Pilates Trainers & Coaches',
      description:
        "Get fit while having the motivation and guidance of a professional pilates coaches and trainers. It's easy to book a session from Probuddy with many accredited and professional coaches in your area.",
      instructorsData: [
        {
          id: '1',
          trainerImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660825608/probuddy/static/images/CategoriesPage/Pilates/PilatesTrainer1_mktet5.png',
          name: 'Maggie Yiao',
          type: 'Pilates Trainer',
          rating: '4.4',
          reviews: '15',
          category: 'pilates',
          description:
            'Book a session with private or group Pilates  sessions at Your Space or Mine *Includes Travel',
        },
        {
          id: '2',
          trainerImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660825608/probuddy/static/images/CategoriesPage/Pilates/PilatesTrainer2_pn71pu.png',
          name: 'Carlene Smith',
          type: 'Pilates Coach',
          rating: '4.5',
          reviews: '19',
          category: 'pilates',
          description:
            'Transformative approach to Pilates that will have you feeling stronger, longer, & leaner health.',
        },
        {
          id: '3',
          trainerImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660825608/probuddy/static/images/CategoriesPage/Pilates/PilatesTrainer3_xodhbk.png',
          name: 'Valerie Carter',
          type: 'Pilates Instructor',
          rating: '4.7',
          reviews: '13',
          category: 'pilates',
          description:
            'Join Valerie for beat-driven, muscle-burning, total body sculpting reformer Pilates.',
        },
      ],
    },

    featured: {
      title: 'Featured Pilates Studios and Events Bookings',
      description:
        "To get the most out of your workouts, it's important to find a gym or trainer that fits your needs and schedule. Take a look at these top-rated profiles and classes near you to find a place that suits you. Book a class today!",
      featuredItems: [
        {
          id: '1',
          companyImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660825607/probuddy/static/images/CategoriesPage/Pilates/PilatesCompany1_p2fbfm.png',
          name: 'Pilates Plus',
          location: 'Kim Yam Road, Singapore',
          rating: '4.1',
          reviews: '225',
          category: 'pilates',
          description:
            'At PilatesPlus, we provide unique and innovative ways of teaching Pilates exercise. We are dedicated to guiding our clients to achieve their fitness goal. Pilates Plus Is About Moving Our Bodies. We don’t just stop at Pilates, We enjoy Life through Fitness!',
        },
        {
          id: '2',
          companyImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660825607/probuddy/static/images/CategoriesPage/Pilates/PilatesCompany1_p2fbfm.png',
          name: 'Pilates Plus',
          location: 'Kim Yam Road, Singapore',
          rating: '4.2',
          reviews: '198',
          category: 'pilates',
          description:
            'At PilatesPlus, we provide unique and innovative ways of teaching Pilates exercise. We are dedicated to guiding our clients to achieve their fitness goal. Pilates Plus Is About Moving Our Bodies. We don’t just stop at Pilates, We enjoy Life through Fitness!',
        },
      ],
    },

    topRatedClasses: {
      title: 'Explore More Top-Rated Pilates Classes Near You',
      description:
        'Get into the best shape of your life with certified, professional, and accredited pilates classes. We have the best pilates classes available in your area and certified Pros who will guide you and offer personalized exercises designed specifically for your needs.',
      classesData: [
        {
          id: '1',
          classesImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660825606/probuddy/static/images/CategoriesPage/Pilates/PilatesClasses1_gde4jg.png',
          className: 'Pilates Group Session',
          instructorName: 'Maggie Yao',
          location: 'Melbourne, AU',
          rating: '4.8',
          reviews: '225',
          category: 'pilates',
          description:
            'Book a session with groups Pilates Sessions at Your Space or Mine *Includes Travel',
          bookingTimings: '8.00 Am to 10.00 Am',
          bookingPrice: '100',
        },
        {
          id: '2',
          classesImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660825606/probuddy/static/images/CategoriesPage/Pilates/PilatesClasses2_f43y7f.png',
          className: 'Advanced Group Pilates',
          instructorName: 'Carlene Smith',
          location: 'Yishun, Singapore',
          rating: '4.8',
          reviews: '201',
          category: 'pilates',
          description:
            'Transformative approach to Pilates that will have you feeling stronger, longer, & leaner health.',
          bookingTimings: '8.00 Am to 10.00 Am',
          bookingPrice: '120',
        },
        {
          id: '3',
          classesImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660825606/probuddy/static/images/CategoriesPage/Pilates/PilatesClasses3_whxllb.png',
          className: 'Reformer Pilates Class',
          instructorName: 'Valerie Carter',
          location: 'Bukit Batok, SG',
          rating: '4.6',
          reviews: '225',
          category: 'pilates',
          description:
            'Join Valerie for beat-driven, muscle-burning, total body sculpting reformer Pilates.',
          bookingTimings: '8.00 Am to 10.00 Am',
          bookingPrice: '120',
        },
      ],
    },

    reviews: [
      {
        name: 'Courtney Henry',
        country: 'Hong Kong',
        imageSrc: ReviewImage1,
        description:
          'The Probuddy platform is a great solution for business owners. We highly recommend it for businesses that need to keep track of everything.',
      },
      {
        name: 'Simon Hart',
        country: 'Texas',
        imageSrc: ReviewImage2,
        description:
          'I have tried many online courses in my life, but none that have been so effective as Probuddy. The course has helped me with my business and gave me the tools to be more successful. The instructors are very knowledgeable and helpful. I have enjoyed the course and look forward to taking more classes.',
      },
      {
        name: 'Hayley Smith',
        country: 'Manchester',
        imageSrc: ReviewImage6,
        description:
          'Taking time out to destress with a tennis session is always a wonderful thing. Using Probuddy makes it even more enjoyable.',
      },
    ],

    features: {
      title: 'Ready to start your Pilates journey? We can help.',
      description:
        'Match up with verified and certified pros near you with Pro Connect+. Just create a Match Request, and we’ll connect you with a perfect match.',
    },

    sellers: {
      title:
        'Join over 1000 sports, wellness, and adventure Pros that have seen their business grow with Probuddy.',
      linkText: 'Learn More',
    },
  },
  //pole-fitness
  // {
  //   id: 'pole-fitness',
  //   title: 'Pole Fitness',
  //   description: 'Pole Dancing Classes for Fitness and Fun!',
  //   heroImage:
  //     'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660892018/probuddy/static/images/CategoriesPage/Pole%20Fitness/PoleFitnessHeroImage_dhjlc4.png',

  //   section1: {
  //     imgSrc:
  //       'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660891906/probuddy/static/images/CategoriesPage/Pole%20Fitness/PoleFitnessClasses_vqb7sj.png',
  //     heading: 'Pole Fitness Classes for everybody',
  //     description:
  //       "With Probuddy, you can have a private pole fitness instructor come to your home or office and teach you how to dance on the pole. Your personalized lessons are tailored towards building upper body strength, boosting self-confidence, and much more! With each class, you'll be aiming to improve on your individual goals. We will help you develop a personalized fitness regimen that is both challenging and fun!",
  //     linkText: 'Book Your First Pole Dance Class Now',
  //   },

  //   section2: {
  //     imgSrc:
  //       'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660891903/probuddy/static/images/CategoriesPage/Pole%20Fitness/BuildStrengthImage_ewtipt.png',
  //     heading: 'Build Strength, Flexibility and Acro-Movement Skills With Our Pros',
  //     description:
  //       'With private pole fitness classes, you can learn at your own pace. Your Pros will tailor their teaching to what you need and work with you one-on-one so that you never feel left behind! Learn how to pole dance with a certified pole fitness instructor. Get the basic moves and techniques to help you build upper, body and core strength and boost self-confidence.',
  //     linkText: 'Become Our Pole Fitness  Pro',
  //   },

  //   exercise: {
  //     productTitle: 'Popular Pole Dance & Fitness Listings in your area',
  //     title: 'Types of Pole Dance & Fitness Classes Hosted by Pros',
  //     options: [
  //       {
  //         name: 'Private Pole Fitness Sessions',
  //         description:
  //           'Looking for some one-on-one attention for a session tailored to your body? Try our private Pole Fitness classes.',
  //         imageSrc:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660891903/probuddy/static/images/CategoriesPage/Pole%20Fitness/PoleFitness1_mkxozd.png',
  //       },
  //       {
  //         name: 'Pole Dancing Group Classes',
  //         description: 'Enjoy a fun and unique group class experience on a pole.',
  //         imageSrc:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660891904/probuddy/static/images/CategoriesPage/Pole%20Fitness/PoleFitness2_d4fwyd.png',
  //       },
  //       {
  //         name: 'Online Pole Fitness Classes',
  //         description:
  //           'Our online pole fitness classes are live and taught with the expertise of our incredible instructors.',
  //         imageSrc:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660891902/probuddy/static/images/CategoriesPage/Pole%20Fitness/PoleFitness3_fwtm4f.png',
  //       },
  //       {
  //         name: 'Drop-in Pole Fitness Classes',
  //         description:
  //           'This drop-in pole courses give you your strong pole foundation flexibility and creative movement classes.',
  //         imageSrc:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660891902/probuddy/static/images/CategoriesPage/Pole%20Fitness/PoleFitness4_udnixf.png',
  //       },
  //       {
  //         name: 'Advanced Pole Fitness',
  //         description:
  //           'Take your fitness to the next level with this advanced pole fitness routine.',
  //         imageSrc:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660891903/probuddy/static/images/CategoriesPage/Pole%20Fitness/PoleFitness5_pvhhvx.png',
  //       },
  //       {
  //         name: 'Flexibility For All Levels',
  //         description:
  //           'Learn new pole techniques for every level of fitness/experience absolute beginner to advance.',
  //         imageSrc:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660891904/probuddy/static/images/CategoriesPage/Pole%20Fitness/PoleFitness6_czvspn.png',
  //       },
  //     ],
  //   },

  //   whyBook: {
  //     imgSrc:
  //       'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660891903/probuddy/static/images/CategoriesPage/Pole%20Fitness/PoleFitnessBookImage_iu4pmq.png',
  //     heading1: 'One Marketplace for All Things Sports, Events and Adventure',
  //     position: 'center',
  //     containerPosition: 'start',
  //     description1:
  //       'Probuddy is a one-stop Marketplace for all things sports, wellness & adventure. It gives you worldwide access to thousands of top-rated fitness instructors, yoga classes, tennis coaches, or rock climbing events. All you need to do is find your ideal workout activity and book it!',
  //     heading2: 'Why Book on Probuddy?',
  //     description2:
  //       "Probuddy is the easiest way to book the best fitness classes across the globe - no more waiting or driving around to find the right class for you. Our goal is to help you find the perfect instructor or class in your area so that you can make your next activity one you'll never forget!",
  //   },

  //   classesNearYou: {
  //     title: 'Book The Best Pole Fitness Classes and Pole Dance Lessons Near You',
  //     description:
  //       "The first ever marketplace for the best pole fitness classes near you, with accredited and professional pole dance instructors to guide you. We've partnered with some of the most reputable partners in the industry to provide our users with a wide variety of classes at every skill level. Whether you're just starting out or are an elite dancer, Probuddy has something for everyone!",
  //   },

  //   instructors: {
  //     title: 'Featured Top-Rated Pole Dance and Fitness Instructors',
  //     description:
  //       'Interested in learning the basics of pole fitness? Our Pros will teach you the basic moves and techniques to help build upper body strength and self confidence. Book a session today and learn how to do spins, climbs, stalls and drops.',
  //     instructorsData: [
  //       {
  //         id: '1',
  //         trainerImage:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660891906/probuddy/static/images/CategoriesPage/Pole%20Fitness/PoleFitnessTrainer1_x9tlk6.png',
  //         name: 'Phil Scotch',
  //         type: 'Pole Fitness instructor',
  //         rating: '4.4',
  //         reviews: '15',
  //         category: 'pole fitness',
  //         description:
  //           'Experienced pole dancing instructor Phil delivers pole dancing lessons to begginers, intermediate and advanced students.',
  //       },
  //       {
  //         id: '2',
  //         trainerImage:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660891906/probuddy/static/images/CategoriesPage/Pole%20Fitness/PoleFitnessTrainer2_clhgjl.png',
  //         name: 'Zarah Ebner',
  //         type: 'Pole Fitness instructor',
  //         rating: '4.5',
  //         reviews: '19',
  //         category: 'pole fitness',
  //         description:
  //           'Pole dancing, pole choreography, pole flow and movement classes for all levels. Online and in-person.',
  //       },
  //       {
  //         id: '3',
  //         trainerImage:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660891905/probuddy/static/images/CategoriesPage/Pole%20Fitness/PoleFitnessTrainer3_w6aqww.png',
  //         name: 'Anne Hatchet',
  //         type: 'Pole Fitness instructor',
  //         rating: '4.7',
  //         reviews: '13',
  //         category: 'pole fitness',
  //         description:
  //           'Certified Pole Dancing & Pole Fitness Instructor with 6 years experience. Availanle in 1-2-1 and group sessions online & in person.',
  //       },
  //     ],
  //   },

  //   featured: {
  //     title: 'Featured Pole Fitness Studios and Events Bookings',
  //     description:
  //       "We've partnered with fitness experts, trainers, freelancers and other professionals who are passionate about their craft to bring you a curated experience that is fun, fresh and inspiring.  Check out these top-rated pole fitness studios and pole dancing lessons. Book a class today!",
  //     featuredItems: [
  //       {
  //         id: '1',
  //         companyImage:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660891906/probuddy/static/images/CategoriesPage/Pole%20Fitness/PoleFitnessCompany1_kjizll.png',
  //         name: 'Pole Fitness Studio',
  //         location: 'Melbourne, Australia',
  //         rating: '4.1',
  //         reviews: '205',
  //         category: 'pole fitness',
  //         description:
  //           'Pole Fitness Studio helps you to discover exciting new ways to exercise, work out, get fit and healthy. We help women tone up, shape up, feel good and look great. We run pole fitness classes in Melbourne, Australia that are a great way to work out. The classes really get results and not only that but you learn an awesome new skill at the same time.',
  //       },
  //       {
  //         id: '2',
  //         companyImage:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660891906/probuddy/static/images/CategoriesPage/Pole%20Fitness/PoleFitnessCompany1_kjizll.png',
  //         name: 'Pole Fitness Studio',
  //         location: 'Melbourne, Australia',
  //         rating: '4.2',
  //         reviews: '198',
  //         category: 'pole fitness',
  //         description:
  //           'Pole Fitness Studio helps you to discover exciting new ways to exercise, work out, get fit and healthy. We help women tone up, shape up, feel good and look great. We run pole fitness classes in Melbourne, Australia that are a great way to work out. The classes really get results and not only that but you learn an awesome new skill at the same time.',
  //       },
  //     ],
  //   },

  //   topRatedClasses: {
  //     title: 'Check Out More Pole Dance & Fitness Classes Availble Near You',
  //     description:
  //       'Get into the best shape of your life with certified, professional, and accredited pole fitness classes. We have the best pole fitness classes available in your area and certified Pros who will guide you and offer personalized exercises designed specifically for your needs.',
  //     classesData: [
  //       {
  //         id: '1',
  //         classesImage:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660891904/probuddy/static/images/CategoriesPage/Pole%20Fitness/PoleFitnessClasses1_hp49u0.png',
  //         className: 'Spinning Pole Intermediate/Advance Level',
  //         instructorName: 'Anne Hatchet',
  //         location: 'Melbourne, AU',
  //         rating: '4.8',
  //         reviews: '225',
  //         category: 'pilates',
  //         description:
  //           'Certified Pole Dancing & Pole Fitness Instructor with 6 years experience. Availanle in 1-2-1 and group sessions online & in person.',
  //         bookingTimings: '8.00 Am to 10.00 Am',
  //         bookingPrice: '100',
  //       },
  //       {
  //         id: '2',
  //         classesImage:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660891905/probuddy/static/images/CategoriesPage/Pole%20Fitness/PoleFitnessClasses2_vw4jy1.png',
  //         className: 'Pole Fitness All Levels',
  //         instructorName: 'Carlene Smith',
  //         location: 'Yishun, Singapore',
  //         rating: '4.8',
  //         reviews: '201',
  //         category: 'pilates',
  //         description:
  //           'Experienced pole dancing instructor Phil delivers pole dancing lessons to begginers, intermediate and advanced students.',
  //         bookingTimings: '8.00 Am to 10.00 Am',
  //         bookingPrice: '120',
  //       },
  //       {
  //         id: '3',
  //         classesImage:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660891905/probuddy/static/images/CategoriesPage/Pole%20Fitness/PoleFitnessClasses3_nyh6ay.png',
  //         className: 'Pole Dance Beginners level 1 and 2',
  //         instructorName: 'Valerie Carter',
  //         location: 'Bukit Batok, SG',
  //         rating: '4.6',
  //         reviews: '225',
  //         category: 'pilates',
  //         description:
  //           'Pole dancing, pole choreography, pole flow and movement classes for all levels. Online and in-person.',
  //         bookingTimings: '8.00 Am to 10.00 Am',
  //         bookingPrice: '120',
  //       },
  //     ],
  //   },

  //   reviews: [
  //     {
  //       name: 'Courtney Henry',
  //       country: 'Hong Kong',
  //       imageSrc: ReviewImage1,
  //       description:
  //         'The Probuddy platform is a great solution for business owners. We highly recommend it for businesses that need to keep track of everything.',
  //     },
  //     {
  //       name: 'Simon Hart',
  //       country: 'Texas',
  //       imageSrc: ReviewImage2,
  //       description:
  //         'I have tried many online courses in my life, but none that have been so effective as Probuddy. The course has helped me with my business and gave me the tools to be more successful. The instructors are very knowledgeable and helpful. I have enjoyed the course and look forward to taking more classes.',
  //     },
  //     {
  //       name: 'Hayley Smith',
  //       country: 'Manchester',
  //       imageSrc: ReviewImage6,
  //       description:
  //         'Taking time out to destress with a tennis session is always a wonderful thing. Using Probuddy makes it even more enjoyable.',
  //     },
  //   ],

  //   features: {
  //     title: 'Ready to start your Pilates journey? We can help.',
  //     description:
  //       'Match up with verified and certified pros near you with Pro Connect+. Just create a Match Request, and we’ll connect you with a perfect match.',
  //   },

  //   sellers: {
  //     title:
  //       'Join over 1000 sports, wellness, and adventure Pros that have seen their business grow with Probuddy.',
  //     linkText: 'Learn More',
  //   },
  // },

  // {
  //   id: 'kickboxing',
  //   title: 'KickBoxing Classes',
  //   description: 'Elevate Your Fitness With Kickboxing',
  //   heroImage:
  //     'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660895471/probuddy/static/images/CategoriesPage/Kickboxing/KickboxingHeroImage_dzrpvw.png',

  //   section1: {
  //     imgSrc:
  //       'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660895106/probuddy/static/images/CategoriesPage/Kickboxing/KickboxingClasses_rzvkte.png',
  //     heading: 'Kickboxing Classes To Keep You More Motivated Than Ever Before',
  //     description:
  //       "Our kickboxing classes have you work hard and get in great shape, both physically and mentally. You'll strengthen your body and improve your confidence as you learn how to defend yourself. Kickboxing classes are available at several different levels — from beginner to advanced.",
  //     linkText: 'Book A Kickboxing Class Now',
  //   },

  //   section2: {
  //     imgSrc:
  //       'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660895107/probuddy/static/images/CategoriesPage/Kickboxing/KickboxingPros_odwonh.png',
  //     heading: 'Join our Global Community of Kickboxing Pros',
  //     description:
  //       'Our experienced kickboxing instructors will help you find the right program for you, depending on your goals. We have a variety of options for all ages, from kids to adults, men and women.',
  //     linkText: 'Become Our Kickboxing  Pro',
  //   },

  //   exercise: {
  //     productTitle: 'Popular Kickboxing Listings in your area',
  //     title: 'Types of Kickboxing Classes Hosted by Pros',
  //     options: [
  //       {
  //         name: 'Kickboxing for Beginners',
  //         description:
  //           'Designed for beginners who are new to the sport or looking to start fresh after taking a break from training.',
  //         imageSrc:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660895105/probuddy/static/images/CategoriesPage/Kickboxing/Kickboxing1_c4f4lw.png',
  //       },
  //       {
  //         name: 'Kickboxing Class for Kids',
  //         description:
  //           'Our kids kickboxing also teaches and cultivates discipline and sportsmanship within the kids.',
  //         imageSrc:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660895105/probuddy/static/images/CategoriesPage/Kickboxing/Kickboxing2_qixbdm.png',
  //       },
  //       {
  //         name: 'Private Kickboxing Class',
  //         description:
  //           'Consists of a total body workout that incorporates cardio training, strength training and boxing techniques.',
  //         imageSrc:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660895105/probuddy/static/images/CategoriesPage/Kickboxing/Kickboxing3_aljwcn.png',
  //       },
  //       {
  //         name: 'Advanced Kickboxing Session',
  //         description:
  //           'Designed for those who have been training for a while but are looking for more advanced techniques.',
  //         imageSrc:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660895105/probuddy/static/images/CategoriesPage/Kickboxing/Kickboxing4_f2kpk4.png',
  //       },
  //       {
  //         name: 'Strength & Conditioning',
  //         description:
  //           'Focuses on improving fitness while learning basic techniques that can be applied to other areas of life.',
  //         imageSrc:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660895105/probuddy/static/images/CategoriesPage/Kickboxing/Kickboxing5_wq6sx8.png',
  //       },
  //       {
  //         name: 'Group Kickboxing Classes',
  //         description:
  //           "You'll learn how to give effective boxing fundamentals. This class is a great way to relieve your stress!",
  //         imageSrc:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660895105/probuddy/static/images/CategoriesPage/Kickboxing/Kickboxing6_bo4bj8.png',
  //       },
  //     ],
  //   },

  //   whyBook: {
  //     imgSrc:
  //       'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660895106/probuddy/static/images/CategoriesPage/Kickboxing/KickboxingBookImage_rxtglg.png',
  //     heading1: 'One Marketplace for All Things Sports, Events and Adventure',
  //     position: 'center',
  //     containerPosition: 'start',
  //     description1:
  //       'Probuddy is a one-stop Marketplace for all things sports, wellness & adventure. It gives you worldwide access to thousands of top-rated fitness instructors, yoga classes, tennis coaches, or rock climbing events. All you need to do is find your ideal workout activity and book it!',
  //     heading2: 'Why Book on Probuddy?',
  //     description2:
  //       "Probuddy is the easiest way to book the best fitness classes across the globe - no more waiting or driving around to find the right class for you. Our goal is to help you find the perfect instructor or class in your area so that you can make your next activity one you'll never forget!",
  //   },

  //   classesNearYou: {
  //     title: 'Book The Best Kickboxing Classes and Trainings Near You',
  //     description:
  //       'Our certified kickboxing coaches will help you maximize your workout to achieve your health and fitness goals. Our classes are designed with the most current fitness trends in mind to help you get the most out of each session. Book a class today, and feel the difference!',
  //   },

  //   instructors: {
  //     title: 'Featured Top-Rated Kickboxing Trainers & Coaches',
  //     description:
  //       'Improve your muscular endurance, power, and conflict-resolution skills while you train like the pros with our kickboxing classes. Whatever your fitness goals may be, Probuddy can help you achieve them, with a very experienced and diverse group of kickboxing instructors ready to help.',
  //     instructorsData: [
  //       {
  //         id: '1',
  //         trainerImage:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660895106/probuddy/static/images/CategoriesPage/Kickboxing/KickboxingTrainer1_gp1e5o.png',
  //         name: 'Tyler Sheik',
  //         type: 'Kickboxing Instructor',
  //         rating: '4.4',
  //         reviews: '15',
  //         category: 'pole fitness',
  //         description:
  //           'Coach Tyler has an accomplished kickboxing and MMA career, which he transfers that experience into his teaching.',
  //       },
  //       {
  //         id: '2',
  //         trainerImage:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660895106/probuddy/static/images/CategoriesPage/Kickboxing/KickboxingTrainer2_v1z2t6.png',
  //         name: 'Jane Lowrey',
  //         type: 'Kickboxing Coach',
  //         rating: '4.5',
  //         reviews: '19',
  //         category: 'pole fitness',
  //         description:
  //           'Pole dancing, pole choreography, pole flow and movement classes for all levels. Online and in-person.',
  //       },
  //       {
  //         id: '3',
  //         trainerImage:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660895107/probuddy/static/images/CategoriesPage/Kickboxing/KickboxingTrainer3_hvgtng.png',
  //         name: 'Stanley Crowe',
  //         type: 'Kickboxing Instructor',
  //         rating: '4.7',
  //         reviews: '13',
  //         category: 'pole fitness',
  //         description:
  //           'Certified Pole Dancing & Pole Fitness Instructor with 6 years experience. Availanle in 1-2-1 and group sessions online & in person.',
  //       },
  //     ],
  //   },

  //   featured: {
  //     title: 'Featured Kickboxing Gyms and Events Bookings',
  //     description:
  //       "To get the most out of your workouts, it's important to find a gym or trainer that fits your needs and schedule. Take a look at these top-rated kickboxing companies and studios near you . Book a class today!",
  //     featuredItems: [
  //       {
  //         id: '1',
  //         companyImage:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677228665/probuddy/static/images/CategoriesPage/Boxing/BoxingCompanyImage1_vjlxd7.png',
  //         name: 'The Ring',
  //         location: 'Kim Yam Road, Singapore',
  //         rating: '4.1',
  //         reviews: '225',
  //         category: 'boxing',
  //         description:
  //           'Learn the art of sweet science in this ultra-empowering and stress-reducing workout that helps you kick ass and improve cardiovascular strength. Experience authentic boxing and work out with a combination of a cardio and technical boxing class.',
  //       },
  //       {
  //         id: '2',
  //         companyImage:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677228665/probuddy/static/images/CategoriesPage/Boxing/BoxingCompanyImage1_vjlxd7.png',
  //         name: 'The Ring',
  //         location: 'Kim Yam Road, Singapore',
  //         rating: '4.2',
  //         reviews: '198',
  //         category: 'boxing',
  //         description:
  //           'Learn the art of sweet science in this ultra-empowering and stress-reducing workout that helps you kick ass and improve cardiovascular strength. Experience authentic boxing and work out with a combination of a cardio and technical boxing class.',
  //       },
  //     ],
  //   },

  //   topRatedClasses: {
  //     title: 'Find More Top-Rated Kickboxing Classes',
  //     description:
  //       'You are in the right place to book your kickboxing class with Probuddy. Whether you want to learn the basics of kickboxing or want to be guided by our experienced certified Pros near you, we have a class for you. Book your private or group kickboxing class today!',
  //     classesData: [
  //       {
  //         id: '1',
  //         classesImage:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677228664/probuddy/static/images/CategoriesPage/Boxing/BoxingClassesImage1_ebvid3.png',
  //         className: 'Boxing Group Class for Kids',
  //         instructorName: 'Mike Vaughn',
  //         location: 'Melbourne, AU',
  //         rating: '4.8',
  //         reviews: '225',
  //         category: 'kickboxing',
  //         description:
  //           'Book a session with Private Yoga Sessions at Your Space or Mine *Includes Travel',
  //         bookingTimings: '8.00 Am to 10.00 Am',
  //         bookingPrice: '100',
  //       },
  //       {
  //         id: '2',
  //         classesImage:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677228664/probuddy/static/images/CategoriesPage/Boxing/BoxingClassesImage2_bwn1oe.png',
  //         className: '1 on 1 Personal Boxing Training',
  //         instructorName: 'Dion Russell',
  //         location: 'Yishun, Singapore',
  //         rating: '4.8',
  //         reviews: '201',
  //         category: 'kickboxing',
  //         description:
  //           'Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text.',
  //         bookingTimings: '8.00 Am to 10.00 Am',
  //         bookingPrice: '120',
  //       },
  //       {
  //         id: '3',
  //         classesImage:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677228664/probuddy/static/images/CategoriesPage/Boxing/BoxingClassesImage3_fve3vs.png',
  //         className: 'Advanced Competitive Boxing Class',
  //         instructorName: 'Dianne Russell',
  //         location: 'Bukit Batok, SG',
  //         rating: '4.6',
  //         reviews: '225',
  //         category: 'kickboxing',
  //         description:
  //           'Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text.',
  //         bookingTimings: '8.00 Am to 10.00 Am',
  //         bookingPrice: '120',
  //       },
  //     ],
  //   },

  //   reviews: [
  //     {
  //       name: 'Courtney Henry',
  //       country: 'Hong Kong',
  //       imageSrc: ReviewImage1,
  //       description:
  //         'The Probuddy platform is a great solution for business owners. We highly recommend it for businesses that need to keep track of everything.',
  //     },
  //     {
  //       name: 'Simon Hart',
  //       country: 'Texas',
  //       imageSrc: ReviewImage2,
  //       description:
  //         'I have tried many online courses in my life, but none that have been so effective as Probuddy. The course has helped me with my business and gave me the tools to be more successful. The instructors are very knowledgeable and helpful. I have enjoyed the course and look forward to taking more classes.',
  //     },
  //     {
  //       name: 'Hayley Smith',
  //       country: 'Manchester',
  //       imageSrc: ReviewImage6,
  //       description:
  //         'Taking time out to destress with a tennis session is always a wonderful thing. Using Probuddy makes it even more enjoyable.',
  //     },
  //   ],

  //   features: {
  //     title: 'Ready to start your fitness goal on Kickboxing? We can help.',
  //     description:
  //       'Match up with verified and certified pros near you with Pro Connect+. Just create a Match Request, and we’ll connect you with a perfect match.',
  //   },

  //   sellers: {
  //     title:
  //       'Join over 1000 sports, wellness, and adventure Pros that have seen their business grow with Probuddy.',
  //     linkText: 'Learn More',
  //   },
  // },

  // {
  //   id: 'swimming',
  //   title: 'Swimming Classes',
  //   description: 'Be the Best Swimmer That You Can Be',
  //   heroImage:
  //     'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661157771/probuddy/static/images/CategoriesPage/Swimming/SwimmingHeroImage_yfprpx.png',

  //   section1: {
  //     imgSrc:
  //       'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661157768/probuddy/static/images/CategoriesPage/Swimming/PlungeSwimPro_bn6qfy.png',
  //     heading: 'Take the Plunge. Learn To Swim Like A Pro.',
  //     description:
  //       "Strengthen your body as well as your confidence as you master swimming skills — physically and mentally. Our swimming classes are available at several different levels - from beginner to advanced - so you can find one that's perfect for you! You'll feel more at ease in any body of water – no matter how deep or challenging it is!",
  //     linkText: 'Book A Boxing Class Now',
  //   },

  //   section2: {
  //     imgSrc:
  //       'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661157773/probuddy/static/images/CategoriesPage/Swimming/SwimmingTechnique_wnsgso.png',
  //     heading: 'Improve Your Swimming Technique With Our Pros',
  //     description:
  //       "Our swimming lessons can help teach kids how to swim as well as improve their muscle memory, making them more comfortable when they are at the pool. At Probuddy, you'll learn how to swim from a professional swimming instructor in one-on-one or small group classes at your own pace and skill level.",
  //     linkText: 'Become Our Swimming  Pro',
  //   },

  //   exercise: {
  //     productTitle: 'Popular Swimming Listings in your area',
  //     title: 'Types of Swimming Classes & Lessons Hosted by Pros',
  //     options: [
  //       {
  //         name: 'Swimming Classes for Adults',
  //         description:
  //           'Designed for beginners who are new to the sport or looking to start fresh after taking a break from training.',
  //         imageSrc:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661157768/probuddy/static/images/CategoriesPage/Swimming/Swimming1_bnbrcm.png',
  //       },
  //       {
  //         name: 'Swimming Lessons for Kids',
  //         description:
  //           'Swimming lessons  for kids, levels from absolute beginners to technique improvement.',
  //         imageSrc:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661157768/probuddy/static/images/CategoriesPage/Swimming/Swimming2_exokjt.png',
  //       },
  //       {
  //         name: 'Infant & Toddler Swim Lessons',
  //         description:
  //           'A perfect chance to introduce toddlers to the water and prepare them for stage 1.',
  //         imageSrc:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661157768/probuddy/static/images/CategoriesPage/Swimming/Swimming3_hzdzhx.png',
  //       },
  //       {
  //         name: 'Advance Swimming Classes',
  //         description:
  //           'Designed for those who have been training for a while but are looking for more advanced techniques.',
  //         imageSrc:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661157768/probuddy/static/images/CategoriesPage/Swimming/Swimming4_pypvtp.png',
  //       },
  //       {
  //         name: 'Private Swimming Lessons',
  //         description:
  //           'Private 1:1, 2:1 and 3:1 Swimming lessons available at a range of levels and specialities.',
  //         imageSrc:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661157769/probuddy/static/images/CategoriesPage/Swimming/Swimming5_sce0rq.png',
  //       },
  //       {
  //         name: 'Group Swimming Classes',
  //         description:
  //           'Learn effective swimming techniques tailored to your particular range of skills, ability and fitness.',
  //         imageSrc:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661157769/probuddy/static/images/CategoriesPage/Swimming/Swimming6_gevqma.png',
  //       },
  //     ],
  //   },

  //   whyBook: {
  //     imgSrc:
  //       'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661157768/probuddy/static/images/CategoriesPage/Swimming/BookSwimming_msnjwv.png',
  //     heading1: 'One Marketplace for All Things Sports, Events and Adventure',
  //     position: 'center',
  //     containerPosition: 'start',
  //     description1:
  //       'Probuddy is a one-stop Marketplace for all things sports, wellness & adventure. It gives you worldwide access to thousands of top-rated fitness instructors, yoga classes, tennis coaches, or rock climbing events. All you need to do is find your ideal workout activity and book it!',
  //     heading2: 'Why Book on Probuddy?',
  //     description2:
  //       "Probuddy is the easiest way to book the best fitness classes across the globe - no more waiting or driving around to find the right class for you. Our goal is to help you find the perfect instructor or class in your area so that you can make your next activity one you'll never forget!",
  //   },

  //   classesNearYou: {
  //     title: 'Book The Best Swimming Classes and Lessons Near You',
  //     description:
  //       'Our certified swimming instructors will help you maximize your workout to achieve your health and fitness goals. Our swimming lessons are designed with the most current fitness trends in mind to help you get the most out of each session. Take the plunge and book  a swimming class today!',
  //   },

  //   instructors: {
  //     title: 'Featured Top-Rated Swimming Instructors',
  //     description: 'Take the plunge and book  a swimming class today!',
  //     instructorsData: [
  //       {
  //         id: '1',
  //         trainerImage:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661157770/probuddy/static/images/CategoriesPage/Swimming/SwimmingTrainer1_kcmnh2.png',
  //         name: 'Sara Miele',
  //         type: 'Swimming Instructor',
  //         rating: '4.4',
  //         reviews: '15',
  //         category: 'swimming',
  //         description:
  //           'Professional swim lessons for all ages which are convenient, fun and catered to your individual learning needs.',
  //       },
  //       {
  //         id: '2',
  //         trainerImage:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661157770/probuddy/static/images/CategoriesPage/Swimming/SwimmingTrainer2_dkwx7r.png',
  //         name: 'Ellie Bartz',
  //         type: 'Swimming Instructor',
  //         rating: '4.5',
  //         reviews: '19',
  //         category: 'swimming',
  //         description:
  //           'Experienced swimming teacher offering 1:1 & small group lessons to kids and adults (10y+ experience) - FIN/STA Level 2',
  //       },
  //       {
  //         id: '3',
  //         trainerImage:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661157770/probuddy/static/images/CategoriesPage/Swimming/SwimmingTrainer3_sdgwjr.png',
  //         name: 'Andrew Artone',
  //         type: 'Swimming Instructor',
  //         rating: '4.7',
  //         reviews: '13',
  //         category: 'swimming',
  //         description:
  //           'Andrew has 3+ years experience in teaching all ages and abilities, from 0 years all the way up to 50+ year old adults.',
  //       },
  //     ],
  //   },

  //   featured: {
  //     title: 'Featured Swimming Companies and Events Bookings',
  //     description:
  //       "To get the most out of your workouts, it's important to find a swimming coach or events place that fits your needs and schedule. Take a look at these top-rated swimming companies and events near you. Book a swimming lesson today!",
  //     featuredItems: [
  //       {
  //         id: '1',
  //         companyImage:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661157770/probuddy/static/images/CategoriesPage/Swimming/SwimmingCompany1_jw0km6.png',
  //         name: 'Swim Coach John',
  //         location: 'York Hill, Singapore',
  //         rating: '4.1',
  //         reviews: '225',
  //         category: 'swimming',
  //         description:
  //           'Professional swim lessons for all ages which are convenient, fun and catered to your individual learning needs. We believe that swimming lessons should be accessible, convenient, fulfilling and fun. Whether you aim to be the next Olympic champion or want to learn to swim as a life skill, let us be a part of your swimming journey.',
  //       },
  //       {
  //         id: '2',
  //         companyImage:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661157770/probuddy/static/images/CategoriesPage/Swimming/SwimmingCompany1_jw0km6.png',
  //         name: 'Swim Coach John',
  //         location: 'York Hill, Singapore',
  //         rating: '4.2',
  //         reviews: '198',
  //         category: 'swimming',
  //         description:
  //           'Professional swim lessons for all ages which are convenient, fun and catered to your individual learning needs. We believe that swimming lessons should be accessible, convenient, fulfilling and fun. Whether you aim to be the next Olympic champion or want to learn to swim as a life skill, let us be a part of your swimming journey.',
  //       },
  //     ],
  //   },

  //   topRatedClasses: {
  //     title: 'Take A Plunge For More Top-Rated Swimming Classes',
  //     description:
  //       'You are in the right place to book your swimming class with Probuddy. Whether you want to learn the basics of swimming or want to be guided by our experienced certified Pros near you, we have a class for you. Book your private or group swimming lesson today!',
  //     classesData: [
  //       {
  //         id: '1',
  //         classesImage:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661157769/probuddy/static/images/CategoriesPage/Swimming/SwimmingClasses1_fkerli.png',
  //         className: 'Adult Swimming Class for Beginners',
  //         instructorName: 'Coach Jon Swim',
  //         location: 'Melbourne, AU',
  //         rating: '4.8',
  //         reviews: '225',
  //         category: 'swimming',
  //         description:
  //           'Swimming lessons for all ages, cut short your learning boundaries, and acquire an effective life skill from expert swimming instructors.',
  //         bookingTimings: '8.00 Am to 10.00 Am',
  //         bookingPrice: '100',
  //       },
  //       {
  //         id: '2',
  //         classesImage:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661157769/probuddy/static/images/CategoriesPage/Swimming/SwimmingClasses2_jmhxkj.png',
  //         className: 'Swimming Class for Kids',
  //         instructorName: 'Dion Russell',
  //         location: 'Yishun, Singapore',
  //         rating: '4.8',
  //         reviews: '201',
  //         category: 'swimming',
  //         description:
  //           'Help your child develop water confidence and learn how to swim like a fish with kid-friendly swimming lessons.',
  //         bookingTimings: '8.00 Am to 10.00 Am',
  //         bookingPrice: '120',
  //       },
  //       {
  //         id: '3',
  //         classesImage:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661157769/probuddy/static/images/CategoriesPage/Swimming/SwimmingClasses3_p8geob.png',
  //         className: 'Advanced Swimming Lessons',
  //         instructorName: 'Dianne Russell',
  //         location: 'Bukit Batok, SG',
  //         rating: '4.6',
  //         reviews: '225',
  //         category: 'swimming',
  //         description:
  //           'Structured and customizable swimming lessons to suit the needs of individuals from varying swimming skill levels.',
  //         bookingTimings: '8.00 Am to 10.00 Am',
  //         bookingPrice: '120',
  //       },
  //     ],
  //   },

  //   reviews: [
  //     {
  //       name: 'Courtney Henry',
  //       country: 'Hong Kong',
  //       imageSrc: ReviewImage1,
  //       description:
  //         'The Probuddy platform is a great solution for business owners. We highly recommend it for businesses that need to keep track of everything.',
  //     },
  //     {
  //       name: 'Simon Hart',
  //       country: 'Texas',
  //       imageSrc: ReviewImage2,
  //       description:
  //         'I have tried many online courses in my life, but none that have been so effective as Probuddy. The course has helped me with my business and gave me the tools to be more successful. The instructors are very knowledgeable and helpful. I have enjoyed the course and look forward to taking more classes.',
  //     },
  //     {
  //       name: 'Hayley Smith',
  //       country: 'Manchester',
  //       imageSrc: ReviewImage6,
  //       description:
  //         'Taking time out to destress with a tennis session is always a wonderful thing. Using Probuddy makes it even more enjoyable.',
  //     },
  //   ],

  //   features: {
  //     title: 'Ready to start your Swimming journey? We can help.',
  //     description:
  //       'Match up with verified and certified pros near you with Pro Connect+. Just create a Match Request, and we’ll connect you with a perfect match.',
  //   },

  //   sellers: {
  //     title:
  //       'Join over 1000 sports, wellness, and adventure Pros that have seen their business grow with Probuddy.',
  //     linkText: 'Learn More',
  //   },
  // },

  // {
  //   id: 'dance',
  //   title: 'Dance Classes',
  //   description: 'The Fun Way To Master New Moves',
  //   heroImage:
  //     'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661235853/probuddy/static/images/CategoriesPage/Dance/DanceHeroImage_hyymgp.png',

  //   section1: {
  //     imgSrc:
  //       'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661235853/probuddy/static/images/CategoriesPage/Dance/DanceYourWay_be2g2v.png',
  //     heading: 'Dance Your Way Into A Healthier You',
  //     description:
  //       "Searching for the best way to exercise? You're in the right place. Discover your body's potential as you progress. Our dance classes are designed for anyone and everyone. Come join us in a fun and engaging way to keep fit. Move your body. Get fit. Burn the calories. Dance like nobody is watching!",
  //     linkText: 'Book A Dancing Class Now',
  //   },

  //   section2: {
  //     imgSrc:
  //       'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661235853/probuddy/static/images/CategoriesPage/Dance/LearnDance_h1r1lu.png',
  //     heading: 'Learn To Dance Like You Never Have Before',
  //     description:
  //       "Ready to move? Go ahead and take the first step. You can be fearless. Dancin' is a passion. And so is learning. Why not take a dance class and learn from the best? Whether you're a beginner or a pro, our classes are for you Join a dance class today.",
  //     linkText: 'See Our Dance Listings',
  //   },

  //   exercise: {
  //     productTitle: 'Popular Dance Listings in your area',
  //     title: 'Types of Dancing Classes & Lessons Hosted by Pros',
  //     options: [
  //       {
  //         name: 'Weekly Open Dance Classes',
  //         description:
  //           'Non-syllabus based and are structured to provide you with an understanding of your bodies, style of dance and technique required.',
  //         imageSrc:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661235851/probuddy/static/images/CategoriesPage/Dance/Dance1_vponk8.png',
  //       },
  //       {
  //         name: 'Contemporary Dance Lessons',
  //         description: 'Learn contemporary dance led by professional dancers and instructors.',
  //         imageSrc:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661235851/probuddy/static/images/CategoriesPage/Dance/Dance2_jscjqu.png',
  //       },
  //       {
  //         name: 'Private Dance Classes',
  //         description:
  //           'Looking for a more bespoke, tailored learning experience? Book a private dance class with personalised training at your own convenience!',
  //         imageSrc:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661235851/probuddy/static/images/CategoriesPage/Dance/Dance3_t7oguf.png',
  //       },
  //       {
  //         name: 'Kids Dance Classes',
  //         description:
  //           "Unleash your child's talent in ballet, hip-hop, K-Pop and jazz with our guide to dance classes for kids.",
  //         imageSrc:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661235851/probuddy/static/images/CategoriesPage/Dance/Dance4_mglchb.png',
  //       },
  //       {
  //         name: 'KPop Dance Classes',
  //         description:
  //           'For dance enthusisasts (kids, teens & adults) of various levels and experience incorporated with group transformations.',
  //         imageSrc:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661235851/probuddy/static/images/CategoriesPage/Dance/Dance5_ftqpoj.png',
  //       },
  //       {
  //         name: 'Ballroom Dance Lessons',
  //         description:
  //           'Learn effective dancing techniques tailored to your particular range of skills, ability and fitness.',
  //         imageSrc:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661235852/probuddy/static/images/CategoriesPage/Dance/Dance6_wlrzsf.png',
  //       },
  //     ],
  //   },

  //   whyBook: {
  //     imgSrc:
  //       'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661235851/probuddy/static/images/CategoriesPage/Dance/BookDanceImage_vt8wgz.png',
  //     heading1: 'One Marketplace for All Things Sports, Events and Adventure',
  //     position: 'center',
  //     containerPosition: 'start',
  //     description1:
  //       'Probuddy is a one-stop Marketplace for all things sports, wellness & adventure. It gives you worldwide access to thousands of top-rated fitness instructors, yoga classes, tennis coaches, or rock climbing events. All you need to do is find your ideal workout activity and book it!',
  //     heading2: 'Why Book on Probuddy?',
  //     description2:
  //       "Probuddy is the easiest way to book the best fitness classes across the globe - no more waiting or driving around to find the right class for you. Our goal is to help you find the perfect instructor or class in your area so that you can make your next activity one you'll never forget!",
  //   },

  //   classesNearYou: {
  //     title: 'Book The Best Dance Classes and Lessons Near You',
  //     description:
  //       "Book the best dance classes and lessons near you. Our easy-to-use platform lets you book the best dance classes and lessons near you. Find the right studio, instructor and class for your needs. It's as simple as that.",
  //   },

  //   instructors: {
  //     title: 'Featured Top-Rated Dance Instructors & Choreographers',
  //     description:
  //       'The Best of the Best. Our dance instructors are certified choreographers and have won awards and competitions, and are recognized as some of the best in the world. At Probuddy, we take pride in offering the best dance instructors available.',
  //     instructorsData: [
  //       {
  //         id: '1',
  //         trainerImage:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661235852/probuddy/static/images/CategoriesPage/Dance/DanceTrainer1_om0eqk.png',
  //         name: 'Srjan Miele',
  //         type: 'Dance Instructor',
  //         rating: '4.4',
  //         reviews: '15',
  //         category: 'dance',
  //         description:
  //           'Srjan trained full-time at Melbourne Australia Conservatoire of Classical Ballet, before pursuing dance teaching as his chosen career.',
  //       },
  //       {
  //         id: '2',
  //         trainerImage:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661235853/probuddy/static/images/CategoriesPage/Dance/DanceTrainer2_qdmqfh.png',
  //         name: 'Ellie Dario',
  //         type: 'Dance Choreographer',
  //         rating: '4.5',
  //         reviews: '19',
  //         category: 'dance',
  //         description:
  //           'Ellie has been teaching for almost 3 years, with students ranging from 4-year olds to adults in Ballet, Jazz, Hip Hop and Contemporary.',
  //       },
  //       {
  //         id: '3',
  //         trainerImage:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661235853/probuddy/static/images/CategoriesPage/Dance/DanceTrainer3_ohmxyp.png',
  //         name: 'Andy Wong',
  //         type: 'Dance Teacher',
  //         rating: '4.7',
  //         reviews: '13',
  //         category: 'dance',
  //         description:
  //           'Under his guidance, many of his students have won awards, scholarships local and international dance competitions.',
  //       },
  //     ],
  //   },

  //   featured: {
  //     title: 'Find The Best Dance Studios and Events Bookings',
  //     description:
  //       'The most fun you can have while burning calories. Dance is a great way to get in shape and have fun. Probuddy helps you find the best dance studios and events near you. Our features dance studios are curated to provide a unique guide to the best of what dancing class has to offer.',
  //     featuredItems: [
  //       {
  //         id: '1',
  //         companyImage:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661235852/probuddy/static/images/CategoriesPage/Dance/DanceCompany1_jhrzgr.png',
  //         name: 'O School',
  //         location: 'Orchard Link, Singapore',
  //         rating: '4.1',
  //         reviews: '225',
  //         category: 'dance',
  //         description:
  //           'Break, lock and pop like the stunners in Step Up. The school’s repertoire of dance courses will teach you everything from hip hop to the eclectic reggae dancehall. In addition to more structured dance courses, the school also offers open classes that you can jump right into – y’know, if you’re feeling spontaneously footloose like Kevin Bacon.',
  //       },
  //       {
  //         id: '2',
  //         companyImage:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661235852/probuddy/static/images/CategoriesPage/Dance/DanceCompany1_jhrzgr.png',
  //         name: 'O School',
  //         location: 'Orchard Link, Singapore',
  //         rating: '4.2',
  //         reviews: '198',
  //         category: 'dance',
  //         description:
  //           'Break, lock and pop like the stunners in Step Up. The school’s repertoire of dance courses will teach you everything from hip hop to the eclectic reggae dancehall. In addition to more structured dance courses, the school also offers open classes that you can jump right into – y’know, if you’re feeling spontaneously footloose like Kevin Bacon.',
  //       },
  //     ],
  //   },

  //   topRatedClasses: {
  //     title: 'Book The Right Dance Classes Near You',
  //     description:
  //       "More of everything you want. Dance your way to the top. Get the best dance classes in town. Learn from our best instructors. Our dance classes have it all, and we're confident you'll love them. More top-rated dance classes, more dances to learn, more fun to be had. Start dancing now!",
  //     classesData: [
  //       {
  //         id: '1',
  //         classesImage:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661235852/probuddy/static/images/CategoriesPage/Dance/DanceClasses1_unwlri.png',
  //         className: 'Group Dance Class (All Ages)',
  //         instructorName: 'O School',
  //         location: 'Singapore, SG',
  //         rating: '4.8',
  //         reviews: '225',
  //         category: 'dance',
  //         description:
  //           'A dance school like no other. Find dance courses in O School, study and learn to dance in a more fun way. Go now and learn the magic of dancing.',
  //         bookingTimings: '8.00 Am to 10.00 Am',
  //         bookingPrice: '100',
  //       },
  //       {
  //         id: '2',
  //         classesImage:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661235852/probuddy/static/images/CategoriesPage/Dance/DanceClasses2_iz4tah.png',
  //         className: 'Ballroom Dance Class (Adults)',
  //         instructorName: 'Dion Russell',
  //         location: 'London, UK',
  //         rating: '4.8',
  //         reviews: '201',
  //         category: 'dance',
  //         description:
  //           'Let your foot do the talking. Your body craves the workout, the fun, and the freedom to really let loose. Book your ballroom dance class now!',
  //         bookingTimings: '8.00 Am to 10.00 Am',
  //         bookingPrice: '120',
  //       },
  //       {
  //         id: '3',
  //         classesImage:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661235852/probuddy/static/images/CategoriesPage/Dance/DanceClasses3_xykxdd.png',
  //         className: 'Private Dance Lessons',
  //         instructorName: 'Sean Kenny',
  //         location: 'Brooklyn, NY',
  //         rating: '4.6',
  //         reviews: '225',
  //         category: 'dance',
  //         description:
  //           'Dancing Together is the Perfect Date. The world is a brighter place when it has more dancing. We have the right music. We have the right moves. Come and join us!',
  //         bookingTimings: '8.00 Am to 10.00 Am',
  //         bookingPrice: '120',
  //       },
  //     ],
  //   },

  //   reviews: [
  //     {
  //       name: 'Courtney Henry',
  //       country: 'Hong Kong',
  //       imageSrc: ReviewImage1,
  //       description:
  //         'The Probuddy platform is a great solution for business owners. We highly recommend it for businesses that need to keep track of everything.',
  //     },
  //     {
  //       name: 'Simon Hart',
  //       country: 'Texas',
  //       imageSrc: ReviewImage2,
  //       description:
  //         'I have tried many online courses in my life, but none that have been so effective as Probuddy. The course has helped me with my business and gave me the tools to be more successful. The instructors are very knowledgeable and helpful. I have enjoyed the course and look forward to taking more classes.',
  //     },
  //     {
  //       name: 'Hayley Smith',
  //       country: 'Manchester',
  //       imageSrc: ReviewImage6,
  //       description:
  //         'Taking time out to destress with a tennis session is always a wonderful thing. Using Probuddy makes it even more enjoyable.',
  //     },
  //   ],

  //   features: {
  //     title: 'Get your groove on. Start dancing today! We can help.',
  //     description:
  //       'Match up with verified and certified pros near you with Pro Connect+. Just create a Match Request, and we’ll connect you with a perfect match.',
  //   },

  //   sellers: {
  //     title:
  //       ' Class, Appointment, Activity booking on the go! Wherever you are, your schedule is right at your fingertips. From the first class to the last, Probuddy has your back!',
  //     linkText: 'Learn More',
  //   },
  // },

  // {
  //   id: 'volleyball',
  //   title: 'Volleyball Classes',
  //   description: 'Supercharge your Game with Volleyball',
  //   heroImage:
  //     'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661333829/probuddy/static/images/CategoriesPage/Volleyball/VolleyballHeroImage_hijoax.png',

  //   section1: {
  //     imgSrc:
  //       'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661333825/probuddy/static/images/CategoriesPage/Volleyball/BestVolleyballClasses_hf3qmx.png',
  //     heading: 'The Best Volleyball Classes, Now at Your Fingertips.',
  //     description:
  //       'Are you looking for a fun place to play with friends? Want to improve your skills? Volleyball classes are a fun way to get fit and stay healthy and Probuddy has the perfect volleyball classes for you! You can even choose a specific level of play, so you can keep things competitive or just have fun. From beginners to advanced volleyball players, you can find a class that is suited for you.',
  //     linkText: 'Book A Volleyball Class Now',
  //   },

  //   section2: {
  //     imgSrc:
  //       'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661333825/probuddy/static/images/CategoriesPage/Volleyball/BringVolleyballClasses_f2ceob.png',
  //     heading: 'We Bring the Volleyball Class to You',
  //     description:
  //       "Whether you're looking to practice with friends, or to improve your skills, we've surely one volleyclass class that's right for you! Book a voleyball class with Probuddy and get access to all volleyball classes taught by certified volleyball coaches. You can even choose a specific level of play, so you can keep things competitive or just have fun.",
  //     linkText: 'See our Volleyball Listings',
  //   },

  //   exercise: {
  //     productTitle: 'Popular Volleyball Listings in your area',
  //     title: 'Types of Volleyball Classes & Lessons Hosted by Pros',
  //     options: [
  //       {
  //         name: 'Group Volleyball Lessons',
  //         description:
  //           'Recommended for groups looking for a focused training approach to excel and compete on a higher level!',
  //         imageSrc:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661333825/probuddy/static/images/CategoriesPage/Volleyball/Volleyball1_yup8ho.png',
  //       },
  //       {
  //         name: 'Indoor Volleyball Classes',
  //         description:
  //           'Indoor class provide our top coaches with the ability to incorporate drills that reflect game-like situations.',
  //         imageSrc:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661333828/probuddy/static/images/CategoriesPage/Volleyball/Volleyball2_kcvrgj.png',
  //       },
  //       {
  //         name: 'Private/Semi Private Classes',
  //         description: 'Single to small group training with one of our amazing Volleyball coaches!',
  //         imageSrc:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661333826/probuddy/static/images/CategoriesPage/Volleyball/Volleyball3_cwbgmx.png',
  //       },
  //       {
  //         name: 'Beach Volleyball Class',
  //         description:
  //           'Beach training for those who want to get some sun, we are sure to have something for you!',
  //         imageSrc:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661333825/probuddy/static/images/CategoriesPage/Volleyball/Volleyball4_tscnhi.png',
  //       },
  //       {
  //         name: 'Beginner/Kids Classes',
  //         description:
  //           'Your child will learn the game through drills and scrimmages including rules of the game, sportsmanship and fun!',
  //         imageSrc:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661333826/probuddy/static/images/CategoriesPage/Volleyball/Volleyball5_erdjp6.png',
  //       },
  //       {
  //         name: 'Intermediate Class',
  //         description:
  //           'Ideal for players with basic understanding of the game but is looking to take their skills to the next level!',
  //         imageSrc:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661333826/probuddy/static/images/CategoriesPage/Volleyball/Volleyball6_thgnyi.png',
  //       },
  //     ],
  //   },

  //   whyBook: {
  //     imgSrc:
  //       'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661333825/probuddy/static/images/CategoriesPage/Volleyball/BookVolleyballImage_f3xpym.png',
  //     heading1: 'One Marketplace for All Things Sports, Events and Adventure',
  //     position: 'center',
  //     containerPosition: 'start',
  //     description1:
  //       'Probuddy is a one-stop Marketplace for all things sports, wellness & adventure. It gives you worldwide access to thousands of top-rated fitness instructors, yoga classes, tennis coaches, or rock climbing events. All you need to do is find your ideal workout activity and book it!',
  //     heading2: 'Why Book on Probuddy?',
  //     description2:
  //       "Probuddy is the easiest way to book the best fitness classes across the globe - no more waiting or driving around to find the right class for you. Our goal is to help you find the perfect instructor or class in your area so that you can make your next activity one you'll never forget!",
  //   },

  //   classesNearYou: {
  //     title: 'Book The Best Volleyball Classes and Lessons Near You',
  //     description:
  //       "The best volleyball classes are open to everyone and available for all skill levels. Whether you just want to play a few times a week or improve your skills, we've got a class that's right for you. Book your next class with Probuddy today and get access to over 100+ different classes in your area.",
  //   },

  //   instructors: {
  //     title: 'Certified Volleyball Instructors & Coaches. Join the Pros',
  //     description:
  //       "Build confidence with our lessons, gain skills, and learn from our volleyball Pros. We've built a platform that allows volleyball coaches to create fun, engaging lessons and make them available to students. That's how we help you take your skills to the next level.",
  //     instructorsData: [
  //       {
  //         id: '1',
  //         trainerImage:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661333827/probuddy/static/images/CategoriesPage/Volleyball/VolleyballTrainer1_grkymb.png',
  //         name: 'Carl Grand',
  //         type: 'Volleyball Instructor',
  //         rating: '4.4',
  //         reviews: '15',
  //         category: 'volleyball',
  //         description:
  //           'Representing Australia in both the indoor volleyball and beach volleyball and was awarded ‘Australian Volleyball Athlete of the year’.',
  //       },
  //       {
  //         id: '2',
  //         trainerImage:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661333826/probuddy/static/images/CategoriesPage/Volleyball/VolleyballTrainer2_tz9yic.png',
  //         name: 'Ken Schmidt',
  //         type: 'Volleyball Coach',
  //         rating: '4.5',
  //         reviews: '19',
  //         category: 'volleyball',
  //         description:
  //           'Ken has 3+ years experience in teaching volleyball for all ages and abilities, from 12 years all the way up to 40+ year old adults.',
  //       },
  //       {
  //         id: '3',
  //         trainerImage:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661333827/probuddy/static/images/CategoriesPage/Volleyball/VolleyballTrainer3_abulrb.png',
  //         name: 'Tricia Tan',
  //         type: 'Volleyball Coach',
  //         rating: '4.7',
  //         reviews: '13',
  //         category: 'volleyball',
  //         description:
  //           'Coach Tricia competed internationally in the 2020 Olympic Qualification Tournament and Asian & World Championships for over 5 years.',
  //       },
  //     ],
  //   },

  //   featured: {
  //     title: 'Top-Rated Volleyball Courts and Facilities Bookings',
  //     description:
  //       'We know that it can be hard to find the right class for you, but with our help, finding your perfect fit is easier than ever. Probuddy is the ultimate resource for finding the best volleyball courts near you. Our featured top-rated volleyball facilities are curated to provide a unique guide to the best of what class has to offer.',
  //     featuredItems: [
  //       {
  //         id: '1',
  //         companyImage:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661333827/probuddy/static/images/CategoriesPage/Volleyball/VolleyballCompany1_ne7jwu.png',
  //         name: 'Central Park Volleyball Courts',
  //         location: 'New York, NY',
  //         rating: '4.1',
  //         reviews: '225',
  //         category: 'volleyball',
  //         description:
  //           "Volleyball courts for pickup games are available at two locations in Central Park. There are two asphalt courts and two sand courts to the east of Sheep Meadow, and two asphalt courts northeast of the Great Lawn. Courts are available on a first-come, first-served basis. Be sure to bring your own ball. For play on asphalt courts, you'll also need to bring your own net.",
  //       },
  //       {
  //         id: '2',
  //         companyImage:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661333827/probuddy/static/images/CategoriesPage/Volleyball/VolleyballCompany1_ne7jwu.png',
  //         name: 'Central Park Volleyball Courts',
  //         location: 'New York, NY',
  //         rating: '4.2',
  //         reviews: '198',
  //         category: 'volleyball',
  //         description:
  //           "Volleyball courts for pickup games are available at two locations in Central Park. There are two asphalt courts and two sand courts to the east of Sheep Meadow, and two asphalt courts northeast of the Great Lawn.Courts are available on a first-come, first-served basis. Be sure to bring your own ball. For play on asphalt courts, you'll also need to bring your own net.",
  //       },
  //     ],
  //   },

  //   topRatedClasses: {
  //     title: 'Search and Book The Best Volleyball Classes Right Here!',
  //     description:
  //       "You'll always have access to the volleyball experience you need. Find great volleyball classes in your area and book lessons with instructors who are passionate about the game for a more personal experience. Find all the best volleyball classes and lessons on Probuddy.",
  //     classesData: [
  //       {
  //         id: '1',
  //         classesImage:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661333826/probuddy/static/images/CategoriesPage/Volleyball/VolleyballClasses1_hyro5k.png',
  //         className: 'Group Vollleyball Class (Adult Beginner)',
  //         instructorName: 'O School',
  //         location: 'Singapore, SG',
  //         rating: '4.8',
  //         reviews: '225',
  //         category: 'volleyball',
  //         description:
  //           'Volleyball programs for beginners with  fundamental concepts of the game in a fun, supportive, non-competitive environment.',
  //         bookingTimings: '8.00 Am to 10.00 Am',
  //         bookingPrice: '100',
  //       },
  //       {
  //         id: '2',
  //         classesImage:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661333826/probuddy/static/images/CategoriesPage/Volleyball/VolleyballClasses2_xwzrrg.png',
  //         className: 'Volleyball Fundamentals for Kids & Teens',
  //         instructorName: 'Dianne Russell',
  //         location: 'London, UK',
  //         rating: '4.8',
  //         reviews: '201',
  //         category: 'volleyball',
  //         description:
  //           'We  provide an opportunity to learn and enjoy playing volleyball at an early age for development and growth.',
  //         bookingTimings: '8.00 Am to 10.00 Am',
  //         bookingPrice: '120',
  //       },
  //       {
  //         id: '3',
  //         classesImage:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661333826/probuddy/static/images/CategoriesPage/Volleyball/VolleyballClasses3_nckrlw.png',
  //         className: 'Private Advanced Volleyball Lessons',
  //         instructorName: 'Sean Kenny',
  //         location: 'Brooklyn, NY',
  //         rating: '4.6',
  //         reviews: '225',
  //         category: 'volleyball',
  //         description:
  //           'All aspects of the game are taught through drills and exercises that focus on passing, setting, hitting and serving.',
  //         bookingTimings: '8.00 Am to 10.00 Am',
  //         bookingPrice: '120',
  //       },
  //     ],
  //   },

  //   reviews: [
  //     {
  //       name: 'Courtney Henry',
  //       country: 'Hong Kong',
  //       imageSrc: ReviewImage1,
  //       description:
  //         'The Probuddy platform is a great solution for business owners. We highly recommend it for businesses that need to keep track of everything.',
  //     },
  //     {
  //       name: 'Simon Hart',
  //       country: 'Texas',
  //       imageSrc: ReviewImage2,
  //       description:
  //         'I have tried many online courses in my life, but none that have been so effective as Probuddy. The course has helped me with my business and gave me the tools to be more successful. The instructors are very knowledgeable and helpful. I have enjoyed the course and look forward to taking more classes.',
  //     },
  //     {
  //       name: 'Hayley Smith',
  //       country: 'Manchester',
  //       imageSrc: ReviewImage6,
  //       description:
  //         'Taking time out to destress with a tennis session is always a wonderful thing. Using Probuddy makes it even more enjoyable.',
  //     },
  //   ],

  //   features: {
  //     title: 'Get your groove on. Start dancing today! We can help.',
  //     description:
  //       'Match up with verified and certified pros near you with Pro Connect+. Just create a Match Request, and we’ll connect you with a perfect match.',
  //   },

  //   sellers: {
  //     title:
  //       ' Class, Appointment, Activity booking on the go! Wherever you are, your schedule is right at your fingertips. From the first class to the last, Probuddy has your back!',
  //     linkText: 'Learn More',
  //   },
  // },

  // {
  //   id: 'rock-climbing',
  //   title: 'Bouldering & Rock Climbing Classes',
  //   description: 'The Best Place to Find Your Next Climbing Spot',
  //   heroImage:
  //     'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661502112/probuddy/static/images/CategoriesPage/Rock%20Climbing/RockClimbingHeroImage_ud8hhk.png',

  //   section1: {
  //     imgSrc:
  //       'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661502109/probuddy/static/images/CategoriesPage/Rock%20Climbing/ClimbBetterFaster_w0vjth.png',
  //     heading: 'Climb Better. Faster. Bolder. Stronger.',
  //     description:
  //       "Looking for an adrenaline-pumping rock climbing or bouldering adventure? You've come to the right place! We've got the best selection of rock climbing and bouldering spots, so you can find the perfect one for your skills and preferences. At Probuddy,  you can easily search and book the best rock climbing and bouldering experiences in your area. Climb with us today!",
  //     linkText: 'Book a Rock Climbing or Bouldering Session Now',
  //   },

  //   section2: {
  //     imgSrc:
  //       'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661502109/probuddy/static/images/CategoriesPage/Rock%20Climbing/MoreRocksRopes_xy8z01.png',
  //     heading: 'More Rocks, More Ropes, More Fun!',
  //     description:
  //       "Looking for an adventurous way to spend your free time? Why not try rock climbing or bouldering? Search and book the best rock climbing and bouldering spots near you, right now!! With so many different locations to choose from, you're sure to find the perfect place to test your skills. Whether you're a beginner or a seasoned pro, we have something for everyone!",
  //     linkText: 'See Our Rock Climbing Listings',
  //   },

  //   exercise: {
  //     productTitle: 'Popular Rock Climbing & Bouldering Listings in your area',
  //     title: 'Types of Rock Climbing & Bouldering Hosted by Pros',
  //     options: [
  //       {
  //         name: 'Bouldering Classes',
  //         description:
  //           'Keen to learn more about bouldering? Explore our bouldering lessons for beginners and Pros.',
  //         imageSrc:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661502109/probuddy/static/images/CategoriesPage/Rock%20Climbing/RockClimbing1_qwpo8l.png',
  //       },
  //       {
  //         name: 'Top Rope Climbing Classes',
  //         description: 'Learn climbing knots, harnesses, belay skills, and climbing commands.',
  //         imageSrc:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661502109/probuddy/static/images/CategoriesPage/Rock%20Climbing/RockClimbing2_gqaqbq.png',
  //       },
  //       {
  //         name: 'Indoor Rock Climbing',
  //         description:
  //           'From beginners to professionals, you can find climbing routes at any skill level and difficulty level.',
  //         imageSrc:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661502109/probuddy/static/images/CategoriesPage/Rock%20Climbing/RockClimbing3_etutln.png',
  //       },
  //       {
  //         name: 'Outdoor Rock Climbing',
  //         description:
  //           'A fun, exhilarating outdoor rock climbing adventure that can be enjoyed by people of all ages and skills.',
  //         imageSrc:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661502110/probuddy/static/images/CategoriesPage/Rock%20Climbing/RockClimbing4_tq17m6.png',
  //       },
  //       {
  //         name: 'Rock Climbing for Kids',
  //         description:
  //           'The best total body workout for children. Improve their core strength, balance, coordination, and flexibility.',
  //         imageSrc:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661502110/probuddy/static/images/CategoriesPage/Rock%20Climbing/RockClimbing5_fyoiz1.png',
  //       },
  //       {
  //         name: 'Rock Climbing for Beginners',
  //         description:
  //           'Ideal for people with basic understanding of the rock climbing and bouldering, who are looking to take their skills in the next level!',
  //         imageSrc:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661502110/probuddy/static/images/CategoriesPage/Rock%20Climbing/RockClimbing6_rolkdg.png',
  //       },
  //     ],
  //   },

  //   whyBook: {
  //     imgSrc:
  //       'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661502109/probuddy/static/images/CategoriesPage/Rock%20Climbing/BookRockClimbingImage_gy9ir0.png',
  //     heading1: 'One Marketplace for All Things Sports, Events and Adventure',
  //     position: 'center',
  //     containerPosition: 'start',
  //     description1:
  //       'Probuddy is a one-stop Marketplace for all things sports, wellness & adventure. It gives you worldwide access to thousands of top-rated fitness instructors, yoga classes, tennis coaches, or rock climbing events. All you need to do is find your ideal workout activity and book it!',
  //     heading2: 'Why Book on Probuddy?',
  //     description2:
  //       "Probuddy is the easiest way to book the best fitness classes across the globe - no more waiting or driving around to find the right class for you. Our goal is to help you find the perfect instructor or class in your area so that you can make your next activity one you'll never forget!",
  //   },

  //   classesNearYou: {
  //     title: 'Book The Best Rock Climbing & Bouldering Near You',
  //     description:
  //       "The rock climbing and bouldering scene is a diverse one, with hundreds of excellent local spots to explore. Whether you're looking for a short loop around the around the city center or a full-day adventure to the west or east side of the city, we've got the perfect rock climbing and bouldering adventures for you. Book your next class with Probuddy today and get access to over 100+ different classes in your area.",
  //   },

  //   instructors: {
  //     title: 'Certified Rock Climbing & Bouldering Instructors. Join the Pros',
  //     description:
  //       'Join the Pros at our rock climbing & bouldering adventures! We offer a variety of training options for all ages and skill levels. Our certified rock climbing & bouldering instructors are passionate about helping you become a better climber and can help you improve your climbing technique. Whether you want to climb indoors or outdoors, in gyms, parks or at home, our certified rock climbing & bouldering Pros will make sure you reach your goals safely and efficiently.',
  //     instructorsData: [
  //       {
  //         id: '1',
  //         trainerImage:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661502110/probuddy/static/images/CategoriesPage/Rock%20Climbing/RockClimbingTrainer1_ivn2uu.png',
  //         name: 'Steve Bloom',
  //         type: 'Rock Climbing Instructor',
  //         rating: '4.4',
  //         reviews: '15',
  //         category: 'rock-climbing',
  //         description:
  //           'Steve has been developing his climb skills with us for 3 years. His bright and fun energy keeps all the kids moving when he coaches youth class.',
  //       },
  //       {
  //         id: '2',
  //         trainerImage:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661502111/probuddy/static/images/CategoriesPage/Rock%20Climbing/RockClimbingTrainer2_rfdgyd.png',
  //         name: 'Ryan Cheng',
  //         type: 'Rock Climbing Instructor',
  //         rating: '4.5',
  //         reviews: '19',
  //         category: 'rock-climbing',
  //         description:
  //           'Ryan has climbed at a number of places, indoors and outdoors when he can manage it.',
  //       },
  //       {
  //         id: '3',
  //         trainerImage:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661502111/probuddy/static/images/CategoriesPage/Rock%20Climbing/RockClimbingTrainer3_vkvqqm.png',
  //         name: 'Tanya Collins',
  //         type: 'Rock Climbing Instructor',
  //         rating: '4.7',
  //         reviews: '13',
  //         category: 'rock-climbing',
  //         description:
  //           'Tanya joins us to share her incredible expertise on rock climbing and outdoor adventure sports.',
  //       },
  //     ],
  //   },

  //   featured: {
  //     title: 'Top-Rated Rock Climbing & Bouldering Clubs Bookings',
  //     description:
  //       "If you're looking for a rock climbing or bouldering club near you, then look no further than our list of the best ones. We have a great range of rock climbing & bouldering clubs in your area to suit all abilities, styles and ages. From beginner to expert, we have the perfect climbing location for you!",
  //     featuredItems: [
  //       {
  //         id: '1',
  //         companyImage:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661502111/probuddy/static/images/CategoriesPage/Rock%20Climbing/RockClimbingCompany1_zcb6cc.png',
  //         name: 'Climb Central Sports Hub',
  //         location: 'Stadium Place, Singapore',
  //         rating: '4.1',
  //         reviews: '225',
  //         category: 'rock-climbing',
  //         description:
  //           "You don’t need prior experience to scale the walls at Kallang Wave Mall's Climb Central Sports Hub, home to the tallest indoor air-conditioned rock face in Singapore which goes up to 16-metres high. Advanced climbing safety systems are in place to ensure even the most inexperienced climbers (as long as you’re above the age of five) can clamber to new heights – there are 45 climbing lanes and 4-metre-high bouldering walls – in a safe and controlled environment.",
  //       },
  //       {
  //         id: '2',
  //         companyImage:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661502111/probuddy/static/images/CategoriesPage/Rock%20Climbing/RockClimbingCompany1_zcb6cc.png',
  //         name: 'Climb Central Sports Hub',
  //         location: 'Stadium Place, Singapore',
  //         rating: '4.1',
  //         reviews: '225',
  //         category: 'rock-climbing',
  //         description:
  //           "You don’t need prior experience to scale the walls at Kallang Wave Mall's Climb Central Sports Hub, home to the tallest indoor air-conditioned rock face in Singapore which goes up to 16-metres high. Advanced climbing safety systems are in place to ensure even the most inexperienced climbers (as long as you’re above the age of five) can clamber to new heights – there are 45 climbing lanes and 4-metre-high bouldering walls – in a safe and controlled environment.",
  //       },
  //     ],
  //   },

  //   topRatedClasses: {
  //     title: 'Search and Book The Best Rock Climbing & Bouldering Here!',
  //     description:
  //       "Rock climbing and bouldering are fun, exhilarating sports that can be enjoyed by people of all ages and abilities. Whether you're looking for the best bouldering, the best rock climbing gyms or the best walls in your area, we have you covered with our marketplace. We have rock climbing classes and private lessons as well as outdoor climbing and bouldering trips near you and beyond.",
  //     classesData: [
  //       {
  //         id: '1',
  //         classesImage:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661502109/probuddy/static/images/CategoriesPage/Rock%20Climbing/RockClimbingClasses1_bmcpgg.png',
  //         className: 'Group Rock Climbing (Adult Beginner)',
  //         instructorName: 'Climb Central',
  //         location: 'Singapore, SG',
  //         rating: '4.8',
  //         reviews: '225',
  //         category: 'rock-climbing',
  //         description:
  //           'Looking for an adventurous way to spend your free time? Book the best rock climbing and bouldering spots right here!',
  //         bookingTimings: '8.00 Am to 10.00 Am',
  //         bookingPrice: '100',
  //       },
  //       {
  //         id: '2',
  //         classesImage:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661502110/probuddy/static/images/CategoriesPage/Rock%20Climbing/RockClimbingClasses2_rjslxb.png',
  //         className: 'Volleyball Fundamentals for Kids & Teens',
  //         instructorName: 'Dianne Russell',
  //         location: 'London, UK',
  //         rating: '4.8',
  //         reviews: '201',
  //         category: 'rock-climbing',
  //         description:
  //           'We are excited to offer you the best selection of rock climbing and bouldering options for kinds in your area.',
  //         bookingTimings: '8.00 Am to 10.00 Am',
  //         bookingPrice: '120',
  //       },
  //       {
  //         id: '3',
  //         classesImage:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661502109/probuddy/static/images/CategoriesPage/Rock%20Climbing/RockClimbingClasses3_k8cpon.png',
  //         className: 'Private Rock Climbing  Lessons',
  //         instructorName: 'Sean Kenny',
  //         location: 'Brooklyn, NY',
  //         rating: '4.6',
  //         reviews: '225',
  //         category: 'rock-climbing',
  //         description:
  //           'Looking for the best rock climbing and bouldering in town? Look no further! We have everything you need to get started on your next adventure.',
  //         bookingTimings: '8.00 Am to 10.00 Am',
  //         bookingPrice: '120',
  //       },
  //     ],
  //   },

  //   reviews: [
  //     {
  //       name: 'Courtney Henry',
  //       country: 'Hong Kong',
  //       imageSrc: ReviewImage1,
  //       description:
  //         'The Probuddy platform is a great solution for business owners. We highly recommend it for businesses that need to keep track of everything.',
  //     },
  //     {
  //       name: 'Simon Hart',
  //       country: 'Texas',
  //       imageSrc: ReviewImage2,
  //       description:
  //         'I have tried many online courses in my life, but none that have been so effective as Probuddy. The course has helped me with my business and gave me the tools to be more successful. The instructors are very knowledgeable and helpful. I have enjoyed the course and look forward to taking more classes.',
  //     },
  //     {
  //       name: 'Hayley Smith',
  //       country: 'Manchester',
  //       imageSrc: ReviewImage6,
  //       description:
  //         'Taking time out to destress with a tennis session is always a wonderful thing. Using Probuddy makes it even more enjoyable.',
  //     },
  //   ],

  //   features: {
  //     title: 'Adventure Awaits. Ready to Climb? We can help.',
  //     description:
  //       'Match up with verified and certified pros near you with Pro Connect+. Just create a Match Request, and we’ll connect you with a perfect match.',
  //   },

  //   sellers: {
  //     title:
  //       ' Class, Appointment, Activity booking on the go! Wherever you are, your schedule is right at your fingertips. From the first class to the last, Probuddy has your back!',
  //     linkText: 'Learn More',
  //   },
  // },

  // {
  //   id: 'surfing',
  //   title: 'Surfing Classes',
  //   description: 'Find Your Perfect Wave. Surf’s Up!',
  //   heroImage:
  //     'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661947331/probuddy/static/images/CategoriesPage/Surfing/SurfingHeroImage_yq8pda.png',

  //   section1: {
  //     imgSrc:
  //       'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661947328/probuddy/static/images/CategoriesPage/Surfing/HappinessSurfing_bsk5is.png',
  //     heading: 'Happiness Comes In Waves. Go Surfing!',
  //     description:
  //       "Whether you're looking for something new to do on your next vacation, or just want to try out surfing near you, this is the place to start! Our surfing lessons for both beginners and advanced surfers alike. We have surfing instructors who are passionate about teaching you how to stand up paddle, catch waves and get back up on their feet again after being knocked down by a rogue wave. Learning to surf is on top of your bucket list? Book your first surfing class today and be ready to surf until you drop!",
  //     linkText: 'Book A Surfing Class Now',
  //   },

  //   section2: {
  //     imgSrc:
  //       'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661947328/probuddy/static/images/CategoriesPage/Surfing/ScorePerfectWave_zq1pm5.png',
  //     heading: 'Score Perfect Waves & Surfing Classes',
  //     description:
  //       'Have you ever wanted to learn how to surf, but didn’t know where to start? Whether you’re a beginner, intermediate or advanced surfer, there are many places and classes that can help you improve your skills. For the ultimate surf-cation, Probuddy has a wide array a surfing classes and lessons for you and your friends. Our experienced instructors will take you through the basics of surfing and bring out your inner surfer. We’ll teach you how to surf in all kinds of conditions, from calm seas to big waves. You’ll have fun from start to finish.',
  //     linkText: 'See Our Surfing Listings',
  //   },

  //   exercise: {
  //     productTitle: 'Popular Surfing Listings in your area',
  //     title: 'Types of Surfing Lessons Hosted by Pros',
  //     options: [
  //       {
  //         name: 'Private Surfing Lessons',
  //         description: 'Learn to ride the waves like a pro with private surf lessons in your area.',
  //         imageSrc:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661947328/probuddy/static/images/CategoriesPage/Surfing/Surfing1_cbdqol.png',
  //       },
  //       {
  //         name: 'Surfing Group Classes',
  //         description: 'Learn climbing knots, harnesses, belay skills, and climbing commands.',
  //         imageSrc:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661947327/probuddy/static/images/CategoriesPage/Surfing/Surfing2_ykvcej.png',
  //       },
  //       {
  //         name: 'Kids & Adults Surf Camp',
  //         description:
  //           "Great for after school programs, women's programs and surfing lessons for everyone.",
  //         imageSrc:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661947327/probuddy/static/images/CategoriesPage/Surfing/Surfing3_aq5wvb.png',
  //       },
  //       {
  //         name: 'Indoor Surfing Lessons',
  //         description:
  //           'A fun, exhilarating outtdoor indoor surfing lessons that can be enjoyed by people of all ages.',
  //         imageSrc:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661947328/probuddy/static/images/CategoriesPage/Surfing/Surfing4_wp4jmv.png',
  //       },
  //       {
  //         name: 'Surfing Lessons for Beginners',
  //         description:
  //           'Perfect for the beginner to intermediate surfer with a guarantee that you will be standing up before you leave!',
  //         imageSrc:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661947328/probuddy/static/images/CategoriesPage/Surfing/Surfing5_d5u6ed.png',
  //       },
  //       {
  //         name: 'Outdoor Surfing Lessons',
  //         description:
  //           'Advanced surf lessons are tailored to those who are already experienced surfers mastering maneuvers.',
  //         imageSrc:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661947328/probuddy/static/images/CategoriesPage/Surfing/Surfing6_juina3.png',
  //       },
  //     ],
  //   },

  //   whyBook: {
  //     imgSrc:
  //       'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661947328/probuddy/static/images/CategoriesPage/Surfing/BookSurfingImage_yncwjj.png',
  //     heading1: 'One Marketplace for All Things Sports, Events and Adventure',
  //     position: 'center',
  //     containerPosition: 'start',
  //     description1:
  //       'Probuddy is a one-stop Marketplace for all things sports, wellness & adventure. It gives you worldwide access to thousands of top-rated fitness instructors, yoga classes, tennis coaches, or rock climbing events. All you need to do is find your ideal workout activity and book it!',
  //     heading2: 'Why Book on Probuddy?',
  //     description2:
  //       "Probuddy is the easiest way to book the best fitness classes across the globe - no more waiting or driving around to find the right class for you. Our goal is to help you find the perfect instructor or class in your area so that you can make your next activity one you'll never forget!",
  //   },

  //   classesNearYou: {
  //     title: 'Book The Best Surfing Classes & Lessons Near You',
  //     description:
  //       'If you’re ready to take your love for the water a step further and learn how to surf, we have some great news for you! Probuddy has every surfing classes and lessons available that can get you acquainted with this exhilarating pastime in no time at all. Whether you want to learn as a beginner or master the art as an intermediate surfer, these classes will help you get started on the right foot. Book your next class today and get access to over hundreds of different classes in your area.',
  //   },

  //   instructors: {
  //     title: 'Certified Surf Instructors. Join the Pros',
  //     description:
  //       'Looking to get into the water and catch a few waves? No matter your skill level, there is a surfing class near you that will meet your needs and skill level and teach you everything from fundamentals to more advanced techniques our certified surfing instructors in your area. Our Pros know that every student has different abilities and learning styles, so they tailor each class to suit everyone’s needs.',
  //     instructorsData: [
  //       {
  //         id: '1',
  //         trainerImage:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661947329/probuddy/static/images/CategoriesPage/Surfing/SurfingTrainer1_hvfkno.png',
  //         name: 'Chad Davis',
  //         type: 'Surf Instructor',
  //         rating: '4.4',
  //         reviews: '15',
  //         category: 'surfing',
  //         description:
  //           'Steve has been developing his climb skills with us for 3 years. His bright and fun energy keeps all the kids moving when he coaches youth class,',
  //       },
  //       {
  //         id: '2',
  //         trainerImage:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661947329/probuddy/static/images/CategoriesPage/Surfing/SurfingTrainer2_ovb5bc.png',
  //         name: 'Jeremy Bagg',
  //         type: 'Surf Instructor',
  //         rating: '4.5',
  //         reviews: '19',
  //         category: 'surfing',
  //         description:
  //           'Ryan has climbed at a number of places, indoors and outdoors when he can manage it.',
  //       },
  //       {
  //         id: '3',
  //         trainerImage:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661947330/probuddy/static/images/CategoriesPage/Surfing/SurfingTrainer3_nuhto6.png',
  //         name: 'Maine Chua',
  //         type: 'Surf Instructor',
  //         rating: '4.7',
  //         reviews: '13',
  //         category: 'surfing',
  //         description:
  //           'Tanya joins us to share her incredible expertise on rock climbing and outdoor adventure sports.',
  //       },
  //     ],
  //   },

  //   featured: {
  //     title: 'Top-Rated Surfing Activity & Events  Bookings',
  //     description:
  //       "If you're looking for a surfing schools near you, then look no further than our list of the best ones. We have a wide range of surfing activities, schools and upcoming events in your area to suit all abilities, styles and ages. From beginner to expert, we have the right one for you!",
  //     featuredItems: [
  //       {
  //         id: '1',
  //         companyImage:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661947329/probuddy/static/images/CategoriesPage/Surfing/SurfingCompany1_cfoeui.png',
  //         name: 'Stingray®',
  //         location: 'Stadium Boulevard Kallang, Singapore',
  //         rating: '4.1',
  //         reviews: '225',
  //         category: 'surfing',
  //         description:
  //           'Water you doing this weekend? Come ride some waves with us on the Stingray®, book the most favorite sun and surf activity that promises a splashing good time - no prior surfing experience needed!',
  //       },
  //       {
  //         id: '2',
  //         companyImage:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661947329/probuddy/static/images/CategoriesPage/Surfing/SurfingCompany1_cfoeui.png',
  //         name: 'Stingray®',
  //         location: 'Stadium Boulevard Kallang, Singapore',
  //         rating: '4.1',
  //         reviews: '225',
  //         category: 'surfing',
  //         description:
  //           'Water you doing this weekend? Come ride some waves with us on the Stingray®, book the most favorite sun and surf activity that promises a splashing good time - no prior surfing experience needed!',
  //       },
  //     ],
  //   },

  //   topRatedClasses: {
  //     title: 'Search and Book The Best Surfing Classes & Lessons Here!',
  //     description:
  //       'Want to learn how to surf? Or are you ready to take your surfing skills to the next level? There’s no better time to start than now with Probuddy! And with so many surf lessons popping up everywhere, we want to make your search as easy as possible. If you want to learn how to surf this summer or any other time of year, here’s where you can find different surf classes available. Book a surfing lesson today!',
  //     classesData: [
  //       {
  //         id: '1',
  //         classesImage:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661947329/probuddy/static/images/CategoriesPage/Surfing/SurfingClasses1_ubgue4.png',
  //         className: 'Beginner Group Surf Lesson',
  //         instructorName: 'Surfs Up',
  //         location: 'Singapore, SG',
  //         rating: '4.8',
  //         reviews: '225',
  //         category: 'rock-climbing',
  //         description:
  //           'Looking for an adventurous way to spend your free time? Book the best surfing classes and lessons right here!',
  //         bookingTimings: '8.00 Am to 10.00 Am',
  //         bookingPrice: '100',
  //       },
  //       {
  //         id: '2',
  //         classesImage:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661947329/probuddy/static/images/CategoriesPage/Surfing/SurfingClasses2_mh13i8.png',
  //         className: 'Beginner Private Surf Lesson',
  //         instructorName: 'Douglas Bagg',
  //         location: 'London, UK',
  //         rating: '4.8',
  //         reviews: '201',
  //         category: 'rock-climbing',
  //         description:
  //           'We are excited to offer you the best selection of surfing lesons and indoor surfing options for kinds in your area.',
  //         bookingTimings: '8.00 Am to 10.00 Am',
  //         bookingPrice: '120',
  //       },
  //       {
  //         id: '3',
  //         classesImage:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661947329/probuddy/static/images/CategoriesPage/Surfing/SurfingClasses3_ucckbo.png',
  //         className: 'Intermediate Private Surf Lesson',
  //         instructorName: 'Sean Kenny',
  //         location: 'Brooklyn, NY',
  //         rating: '4.6',
  //         reviews: '225',
  //         category: 'rock-climbing',
  //         description:
  //           'Looking for the best surfing lessons in town? Look no further! We have everything you need to get started on your next adventure.',
  //         bookingTimings: '8.00 Am to 10.00 Am',
  //         bookingPrice: '120',
  //       },
  //     ],
  //   },

  //   reviews: [
  //     {
  //       name: 'Courtney Henry',
  //       country: 'Hong Kong',
  //       imageSrc: ReviewImage1,
  //       description:
  //         'The Probuddy platform is a great solution for business owners. We highly recommend it for businesses that need to keep track of everything.',
  //     },
  //     {
  //       name: 'Simon Hart',
  //       country: 'Texas',
  //       imageSrc: ReviewImage2,
  //       description:
  //         'I have tried many online courses in my life, but none that have been so effective as Probuddy. The course has helped me with my business and gave me the tools to be more successful. The instructors are very knowledgeable and helpful. I have enjoyed the course and look forward to taking more classes.',
  //     },
  //     {
  //       name: 'Hayley Smith',
  //       country: 'Manchester',
  //       imageSrc: ReviewImage6,
  //       description:
  //         'Taking time out to destress with a tennis session is always a wonderful thing. Using Probuddy makes it even more enjoyable.',
  //     },
  //   ],

  //   features: {
  //     title: 'The Fun Doesn’t Stop. Book A Surfing Class Today! ',
  //     description:
  //       'Match up with verified and certified pros near you with Pro Connect+. Just create a Match Request, and we’ll connect you with a perfect match.',
  //   },

  //   sellers: {
  //     title:
  //       ' Class, Appointment, Activity booking on the go! Wherever you are, your schedule is right at your fingertips. From the first class to the last, Probuddy has your back!',
  //     linkText: 'Learn More',
  //   },
  // },

  // {
  //   id: 'golf',
  //   title: 'Golf Lessons',
  //   description: 'Enjoy Golf Lessons & Coachings at Your Own Pace, Anytime.',
  //   heroImage:
  //     'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1662124080/probuddy/static/images/CategoriesPage/Golf/GolfHeroImage_xfgdm3.png',

  //   section1: {
  //     imgSrc:
  //       'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1662124076/probuddy/static/images/CategoriesPage/Golf/GolfLessons_nye3ip.png',
  //     heading: "We'll help you shave strokes off your game.",
  //     description:
  //       "Whether you're looking for something new to do on your next vacation, or just want to try out surfing near you, this is the place to start! Our surfing lessons for both beginners and advanced surfers alike. We have surfing instructors who are passionate about teaching you how to stand up paddle, catch waves and get back up on their feet again after being knocked down by a rogue wave. Learning to surf is on top of your bucket list? Book your first surfing class today and be ready to surf until you drop!",
  //     linkText: 'Book A Golf Lesson Now',
  //   },

  //   section2: {
  //     imgSrc:
  //       'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1662124075/probuddy/static/images/CategoriesPage/Golf/StrokesGame_j3yxup.png',
  //     heading: 'Get golf lessons from top instructors, without the travel.',
  //     description:
  //       'Have you ever wanted to learn how to surf, but didn’t know where to start? Whether you’re a beginner, intermediate or advanced surfer, there are many places and classes that can help you improve your skills. For the ultimate surf-cation, Probuddy has a wide array a surfing classes and lessons for you and your friends. Our experienced instructors will take you through the basics of surfing and bring out your inner surfer. We’ll teach you how to surf in all kinds of conditions, from calm seas to big waves. You’ll have fun from start to finish.',
  //     linkText: 'See Our Golf Listings',
  //   },

  //   exercise: {
  //     productTitle: 'Popular Golf Listings in your area',
  //     title: 'Types of Golf Lessons Hosted by Pros',
  //     options: [
  //       {
  //         name: 'Private Golf Lessons',
  //         description: 'One-on-one golf lessons for a fun and effective way to learn the game.',
  //         imageSrc:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663745809/probuddy/static/images/CategoriesPage/Golf/Golf1_h2tlzj.png',
  //       },
  //       {
  //         name: 'Golf Lessons for Beginners',
  //         description:
  //           'Start to learn golf the proper way and get the right technique from the start.',
  //         imageSrc:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663745810/probuddy/static/images/CategoriesPage/Golf/Golf2_pxu0mm.png',
  //       },
  //       {
  //         name: 'Golf Lessons for Kids',
  //         description:
  //           'Fun and enjoyable golf games, tkids will learn basic skills, etiquette and safety when playing.',
  //         imageSrc:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663745810/probuddy/static/images/CategoriesPage/Golf/Golf3_s0mwly.png',
  //       },
  //       {
  //         name: 'PGA Golf Coaching',
  //         description: 'Learn to play the game of golf from a certified PGA member.',
  //         imageSrc:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663745810/probuddy/static/images/CategoriesPage/Golf/Golf4_cgyjxd.png',
  //       },
  //       {
  //         name: 'Group Golf Lessons',
  //         description:
  //           'Learn how to play golf with your family & friends, then group golf lessons are the way to go!',
  //         imageSrc:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663745809/probuddy/static/images/CategoriesPage/Golf/Golf5_okk3mi.png',
  //       },
  //       {
  //         name: 'Corporate Golf Lessons',
  //         description:
  //           'Gel your team together and build uni-tee with our corporate team building golf lessons.',
  //         imageSrc:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663745809/probuddy/static/images/CategoriesPage/Golf/Golf6_flx5el.png',
  //       },
  //     ],
  //   },

  //   whyBook: {
  //     imgSrc:
  //       'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1662124075/probuddy/static/images/CategoriesPage/Golf/BookGolfImage_cvd9se.png',
  //     heading1: 'One Marketplace for All Things Sports, Events and Adventure',
  //     position: 'center',
  //     containerPosition: 'start',
  //     description1:
  //       'Probuddy is a one-stop Marketplace for all things sports, wellness & adventure. It gives you worldwide access to thousands of top-rated fitness instructors, yoga classes, tennis coaches, or rock climbing events. All you need to do is find your ideal workout activity and book it!',
  //     heading2: 'Why Book on Probuddy?',
  //     description2:
  //       "Probuddy is the easiest way to book the best fitness classes across the globe - no more waiting or driving around to find the right class for you. Our goal is to help you find the perfect instructor or class in your area so that you can make your next activity one you'll never forget!",
  //   },

  //   classesNearYou: {
  //     title: 'Book The Best Golf Lessons Near You',
  //     description:
  //       'If you’re ready to take your love for the water a step further and learn how to surf, we have some great news for you! Probuddy has every surfing classes and lessons available that can get you acquainted with this exhilarating pastime in no time at all. Whether you want to learn as a beginner or master the art as an intermediate surfer, these classes will help you get started on the right foot. Book your next class today and get access to over hundreds of different classes in your area.',
  //   },

  //   instructors: {
  //     title: 'Certified Golf Instructors & Coaches. Join the Pros',
  //     description:
  //       'Looking to get into the water and catch a few waves? No matter your skill level, there is a surfing class near you that will meet your needs and skill level and teach you everything from fundamentals to more advanced techniques our certified surfing instructors in your area. Our Pros know that every student has different abilities and learning styles, so they tailor each class to suit everyone’s needs.',
  //     instructorsData: [
  //       {
  //         id: '1',
  //         trainerImage:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663746470/probuddy/static/images/CategoriesPage/Golf/GolfTrainer1_fzsnyt.png',
  //         name: 'Courtney Chen',
  //         type: 'Golf Instructor',
  //         rating: '4.6',
  //         reviews: '12',
  //         category: 'golf',
  //         description: `Courtney’s goal and heart is to help every golfer to reach their personal best while still creating a fun learning environment on the game.`,
  //       },
  //       {
  //         id: '2',
  //         trainerImage:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663746469/probuddy/static/images/CategoriesPage/Golf/GolfTrainer2_cztzhr.png',
  //         name: 'Marvin Meyer',
  //         type: 'Golf Instructor',
  //         rating: '4.6',
  //         reviews: '12',
  //         category: 'golf',
  //         description:
  //           'With his years of playing experience, PGA coach Marvin made a commitment to share his golf insights and help others.',
  //       },
  //       {
  //         id: '3',
  //         trainerImage:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663746471/probuddy/static/images/CategoriesPage/Golf/GolfTrainer3_cimtm6.png',
  //         name: 'Raj Patel',
  //         type: 'Golf Instructor',
  //         rating: '4.6',
  //         reviews: '12',
  //         category: 'golf',
  //         description:
  //           'Raj began his teaching career back in 2017 but decided to chase his dreams of overseas golfing experience and taught golf in Texas for 2 years.',
  //       },
  //     ],
  //   },

  //   featured: {
  //     title: 'Top-Rated Golf Courses, Lessons & Events  Bookings',
  //     description:
  //       "Finding a golf course, lessons, or event near you can be difficult. Luckily we've done all the hard work for you! Whether you're an avid golfer or just starting out on the course, we have a wide range of courses that will suit your needs.  We rank the best golf courses, golf lessons, and golf events available in your area.",
  //     featuredItems: [
  //       {
  //         id: '1',
  //         companyImage:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663746771/probuddy/static/images/CategoriesPage/Golf/GolfCompany_qgixzu.png',
  //         name: 'LA Golf Academy',
  //         location: 'Los Angeles, California',
  //         rating: '4.8',
  //         reviews: '230',
  //         category: 'golf',
  //         description:
  //           'We offer golf lessons for beginners, intermediate and advanced players. All students taught by PGA Professionals. All equipment provided, including clubs if needed.',
  //       },
  //       {
  //         id: '2',
  //         companyImage:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663746771/probuddy/static/images/CategoriesPage/Golf/GolfCompany_qgixzu.png',
  //         name: 'LA Golf Academy',
  //         location: 'Los Angeles, California',
  //         rating: '4.8',
  //         reviews: '230',
  //         category: 'golf',
  //         description:
  //           'We offer golf lessons for beginners, intermediate and advanced players. All students taught by PGA Professionals. All equipment provided, including clubs if needed.',
  //       },
  //     ],
  //   },

  //   topRatedClasses: {
  //     title: 'Search and Book The Best Golf Lessons & Coaching Here!',
  //     description:
  //       'Are you ready to learn how to play golf? Or are you ready to take your golf skills to the next level? Probuddy is the largest marketplace for golf lessons. We provide comprehensive listings of all types of golf classes in your area that include everything from beginners to advanced classes. If you are looking for a golf class, we can help you find the perfect one. Whether you are a beginner or an expert golfer, we have something for everyone!',
  //     classesData: [
  //       {
  //         id: '1',
  //         classesImage:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663747314/probuddy/static/images/CategoriesPage/Golf/GolfClasses1_qlmyfy.png',
  //         className: 'Adult Group Golf Lessons',
  //         instructorName: 'Courtney Chen',
  //         location: 'Singapore, SG',
  //         rating: '4.8',
  //         reviews: '230',
  //         category: 'golf',
  //         description:
  //           'We offer lessons for beginners, intermediate and advanced players. All lessons taught by certified PGA coaches.',
  //         bookingTimings: '8.00 Am to 10.00 Am',
  //         bookingPrice: '100',
  //       },
  //       {
  //         id: '2',
  //         classesImage:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663747314/probuddy/static/images/CategoriesPage/Golf/GolfClasses2_w9ufne.png',
  //         className: 'Private Golf Lessons for Beginners',
  //         instructorName: 'Raj Patel',
  //         location: 'London, UK',
  //         rating: '4.8',
  //         reviews: '230',
  //         category: 'golf',
  //         description:
  //           'Get personal, one on one beginner golf lessons. If you’re serious about making progress, one on one lessons are the best way to become a better golfer.',
  //         bookingTimings: '8.00 Am to 10.00 Am',
  //         bookingPrice: '120',
  //       },
  //       {
  //         id: '3',
  //         classesImage:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663747314/probuddy/static/images/CategoriesPage/Golf/GolfClasses3_tn7abj.png',
  //         className: 'PGA Golf Coaching',
  //         instructorName: 'Marvin meyer',
  //         location: 'Brooklyn, NY',
  //         rating: '4.8',
  //         reviews: '230',
  //         category: 'golf',
  //         description:
  //           'We will teach you the basics of the stance and set up , whether you have never played before or are an experienced player it always starts with good golf fundamentals.',
  //         bookingTimings: '8.00 Am to 10.00 Am',
  //         bookingPrice: '120',
  //       },
  //     ],
  //   },

  //   reviews: [
  //     {
  //       name: 'Courtney Henry',
  //       country: 'Hong Kong',
  //       imageSrc: ReviewImage1,
  //       description:
  //         'The Probuddy platform is a great solution for business owners. We highly recommend it for businesses that need to keep track of everything.',
  //     },
  //     {
  //       name: 'Simon Hart',
  //       country: 'Texas',
  //       imageSrc: ReviewImage2,
  //       description:
  //         'I have tried many online courses in my life, but none that have been so effective as Probuddy. The course has helped me with my business and gave me the tools to be more successful. The instructors are very knowledgeable and helpful. I have enjoyed the course and look forward to taking more classes.',
  //     },
  //     {
  //       name: 'Hayley Smith',
  //       country: 'Manchester',
  //       imageSrc: ReviewImage6,
  //       description:
  //         'Taking time out to destress with a tennis session is always a wonderful thing. Using Probuddy makes it even more enjoyable.',
  //     },
  //   ],

  //   features: {
  //     title: 'Get to grips with the basics. Book A Golf Lesson Faster!',
  //     description:
  //       'Match up with verified and certified pros near you with Pro Connect+. Just create a Match Request, and we’ll connect you with a perfect match.',
  //   },

  //   sellers: {
  //     title: `Never miss a thing! Book any class, appointment, or activity, in seconds. It's never been easier to find the perfect activity for you and your friends with Probuddy.`,
  //     linkText: 'Learn More',
  //   },
  // },

  {
    id: 'personal-training',
    title: 'Personal Training Classes',
    description: 'Personalized, Results-Driven, World-Class Physical Therapy',
    heroVideo:
      'https://res.cloudinary.com/movementbuddy-pte-ltd/video/upload/v1688542371/probuddy/static/images/CategoriesPage/Personal%20Trainer/Video/Personal_Training_VIdeo_vu4sv5.mp4',
    heroImage:
      'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663847133/probuddy/static/images/CategoriesPage/Personal%20Trainer/PTHeroImage_fu6hom.png',

    section1: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663847729/probuddy/static/images/CategoriesPage/Personal%20Trainer/PTLesson_tpjwmw.png',
      heading: 'Tried and tested Physical Therapy programs, proven by our client results.',
      description:
        "Get physical therapy programs that are based on the latest research and with the most effective methods to help you get fit and stay healthy. Our certified personal trainers will work with you one-on-one, ensuring that you receive personalized attention and guidance. Our personal training classes are designed to help you achieve your fitness goals, whether you're a beginner or advanced fitness pro.",
      linkText: 'Book A Personal Training',
    },

    section2: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663848118/probuddy/static/images/CategoriesPage/Personal%20Trainer/PTLesson2_pqmhag.png',
      heading: 'Your goals. Your schedule. Our Mission',
      description: `Need to find a personal trainier in your area? We’ll connect you with the best and certified personal trainers near you in seconds! Our Pros can help you achieve your goals by providing expert guidance and assistance for every fitness level. Whether it's a total body workout that challenges every muscle group or a lifestyle and wellness training sessions that helps improve flexibility and balance, we have what you need to through each step of the journey towards your fitness goals.`,
      linkText: 'See Our Personal Training Listings',
    },

    exercise: {
      productTitle: 'Popular Personal Training Listings in your area',
      title: 'Types of Personal Training Classes & Programs Hosted by Pros',
      options: [
        {
          name: 'Private Training Sessions',
          description:
            'One-on-one personal training with a goal in mind- getting fit, improving your flexibility, strength & endurance levels.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663848427/probuddy/static/images/CategoriesPage/Personal%20Trainer/PT1_onjwrp.png',
        },
        {
          name: 'Bootcamp/Crossfit Trainer',
          description:
            'Take on the most exciting workout with a results-driven Bootcamp/Crossfit personal trainier.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663848427/probuddy/static/images/CategoriesPage/Personal%20Trainer/PT2_ratmk1.png',
        },
        {
          name: 'Physical Fitness Trainer',
          description:
            'Our personal training will whip you into shape as you circulate between cardio and strength training.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663848427/probuddy/static/images/CategoriesPage/Personal%20Trainer/PT3_r4jhye.png',
        },
        {
          name: 'Personal Training for Athletes',
          description:
            'Wide range of science-based training programs that allow athletes to reach their goals more quickly.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663848427/probuddy/static/images/CategoriesPage/Personal%20Trainer/PT4_hnulmw.png',
        },
        {
          name: 'Group Fitness Training Classes',
          description:
            'Personal trainers can also teach group classes and lead groups at gyms or other locations.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663848428/probuddy/static/images/CategoriesPage/Personal%20Trainer/PT5_omctoe.png',
        },
        {
          name: 'Lifestyle Personal Trainer',
          description:
            'Your personal training sessions are developed to the highest standards & fully customised for your lifestyle.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663848427/probuddy/static/images/CategoriesPage/Personal%20Trainer/PT6_mguwva.png',
        },
      ],
    },

    whyBook: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663846877/probuddy/static/images/CategoriesPage/Personal%20Trainer/BookPersonalTrainerImage_bw9rmc.png',
      heading1: 'One Marketplace for All Things Sports, Events and Adventure',
      position: 'center',
      containerPosition: 'start',
      description1:
        'Probuddy is a one-stop Marketplace for all things sports, wellness & adventure. It gives you worldwide access to thousands of top-rated fitness instructors, yoga classes, tennis coaches, or rock climbing events. All you need to do is find your ideal workout activity and book it!',
      heading2: 'Why Book on Probuddy?',
      description2:
        "Probuddy is the easiest way to book the best fitness classes across the globe - no more waiting or driving around to find the right class for you. Our goal is to help you find the perfect instructor or class in your area so that you can make your next activity one you'll never forget!",
    },

    classesNearYou: {
      title: 'Book the Best Personal Training Classes Near You',
      description: `Whether you’re looking to improve your body or just looking to tighten up your routine and get into shape, Probuddy is here to help. We offer a wide variety of personal training classes around the world, so whether you need more cardio or want to focus on staying healthy, we’ve got something for everyone.  With Probuddy, you can find a class that's suited to your current level and needs, including beginner classes and advanced classes if you're ready to start taking it up a notch. Book your next session right now!`,
    },

    instructors: {
      title: 'Unlock your Potential with Our Certified Personal Trainers & Physical Therapists',
      description:
        'If you’re looking for a personal trainer in your area, we can help. Our certified personal trainers can help you reach your fitness goals and get in the best shape of your life. Whether you want to get in shape, stay healthy or build muscle, our network of personal trainers has the knowledge and experience to help you reach your goals. We’ll match you with the right Pro based on your specific needs and goals, so you can start getting the results and a positive change to your health and fitness.',
      instructorsData: [
        {
          id: '1',
          trainerImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663848839/probuddy/static/images/CategoriesPage/Personal%20Trainer/CPT1_oqeq92.png',
          name: 'Brian Denver',
          type: 'Certified Personal Trainer',
          rating: '4.6',
          reviews: '12',
          category: 'personal-trainer',
          description: `Coach Brian’s mission is to empower others in the same state to discover how fitness can be fun and sustainable through personal training.`,
        },
        {
          id: '2',
          trainerImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663848840/probuddy/static/images/CategoriesPage/Personal%20Trainer/CPT2_pifkaq.png',
          name: 'Xian Chao',
          type: 'Certified Personal Trainer',
          rating: '4.6',
          reviews: '12',
          category: 'personal-trainer',
          description:
            'Specialising in strength and conditioning training, Xian can help you to work on an personalized training plan to attain your fitness goals.',
        },
        {
          id: '3',
          trainerImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663848840/probuddy/static/images/CategoriesPage/Personal%20Trainer/CPT3_y09khz.png',
          name: 'Kelly Jacobs',
          type: 'Certified Personal Trainer',
          rating: '4.6',
          reviews: '12',
          category: 'personal-trainer',
          description:
            'Kelly has helped hundreds of men and women become better, healthier versions of themselves through personal training,',
        },
      ],
    },

    featured: {
      title: 'Top-Rated Personal Training Studios Classes Bookings',
      description:
        'If you’re looking for a new gym, studio or trainer, check out our list of top-rated personal training studios in your area that cater to all fitness levels. Probuddy’s goal is to help you achieve your fitness goals faster by making it easy for you to find the best personal training classes around. We know that it can be hard to find the right class or facility for you, but with our help, finding your perfect fit is easier than ever!',
      featuredItems: [
        {
          id: '1',
          companyImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663849071/probuddy/static/images/CategoriesPage/Personal%20Trainer/PTClasses_fnds84.png',
          name: 'Level Singapore',
          location: 'Telok Ayer Street, Singapore',
          rating: '4.8',
          reviews: '230',
          category: 'personal-trainer',
          description: `Level offers personal training and functional strength-based classes in two boutique studios located at Telok Ayer and Robinson Road in the CBD, Singapore.
Our coaches comprise of specialists across a wide range of disciplines including Olympic Weightlifting, Hand Balancing, Powerlifting, Strength and Conditioning and Obstacle Course Racing.`,
        },
        {
          id: '2',
          companyImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663849071/probuddy/static/images/CategoriesPage/Personal%20Trainer/PTClasses_fnds84.png',
          name: 'Level Singapore',
          location: 'Telok Ayer Street, Singapore',
          rating: '4.8',
          reviews: '230',
          category: 'personal-trainer',
          description: `Level offers personal training and functional strength-based classes in two boutique studios located at Telok Ayer and Robinson Road in the CBD, Singapore.
Our coaches comprise of specialists across a wide range of disciplines including Olympic Weightlifting, Hand Balancing, Powerlifting, Strength and Conditioning and Obstacle Course Racing.`,
        },
      ],
    },

    topRatedClasses: {
      title: 'Search and Book The Best Personal Training Classes Right Here!',
      description: `If you're looking for a high-energy personal training prorams, or want to feel healthier, stronger, and happier with effective workouts, there's no better place than a booking a personal training class with Probuddy! Unlocok lifelong result with a result-driven listings that are  designed for everyone from beginners to seasoned athletes who want to take their fitness and health to the next level. Whether you're looking for a personal training studios in your area or want to try hire certified a personal trainer, we've got the perfect class for you.`,
      classesData: [
        {
          id: '1',
          classesImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663849506/probuddy/static/images/CategoriesPage/Personal%20Trainer/PTClasses1_wyfwsa.png',
          className: 'Private Muscle Gain Personal Training',
          instructorName: 'O School',
          location: 'Singapore, SG',
          rating: '4.8',
          reviews: '230',
          category: 'personal-trainer',
          description:
            'Team up with our certified  personal  trainers that can deliver effective results for you. Get to know your own strength, Book now!',
          bookingTimings: '8.00 Am to 10.00 Am',
          bookingPrice: '100',
        },
        {
          id: '2',
          classesImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663849506/probuddy/static/images/CategoriesPage/Personal%20Trainer/PTClasses2_e9er27.png',
          className: 'Sports Performance Personal Training',
          instructorName: 'Dianne Russell',
          location: 'London, UK',
          rating: '4.8',
          reviews: '230',
          category: 'personal-trainer',
          description: `Want to level up your health and fitness regime? We've got a list of the personal training classes and sessions with the best personal trainers in your area!`,
          bookingTimings: '8.00 Am to 10.00 Am',
          bookingPrice: '120',
        },
        {
          id: '3',
          classesImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663849507/probuddy/static/images/CategoriesPage/Personal%20Trainer/PTClasses3_sxc7us.png',
          className: 'Corrective Exercise Personal Training',
          instructorName: 'Sean Kenny',
          location: 'Brooklyn, NY',
          rating: '4.8',
          reviews: '230',
          category: 'personal-trainer',
          description:
            'Looking for a personal trainer near you? We offer weight loss, muscle and body building training, correcrtive excersise for injuries. Book now.',
          bookingTimings: '8.00 Am to 10.00 Am',
          bookingPrice: '120',
        },
      ],
    },

    reviews: [
      {
        name: 'Courtney Henry',
        country: 'Hong Kong',
        imageSrc: ReviewImage1,
        description:
          'The Probuddy platform is a great solution for business owners. We highly recommend it for businesses that need to keep track of everything.',
      },
      {
        name: 'Simon Hart',
        country: 'Texas',
        imageSrc: ReviewImage2,
        description: `I've ticked off a fitness  goal I never thought I would have, simply because I thought it was never achievable. Probuddy has helped me reach my goals by bringing discipline back into my life. I’ve achieved a much better state of mental wellbeing and physical appearance, giving me back confidence in myself.`,
      },
      {
        name: 'Hayley Smith',
        country: 'Manchester',
        imageSrc: ReviewImage6,
        description:
          'Taking time out to destress with a yoga session is always a wonderful thing. Using Probuddy makes it even more enjoyable.',
      },
    ],

    features: {
      title: 'Connect with the Best Personal Trainer and Become a Stronger, Healthier You!',
      description:
        'Match up with verified and certified pros near you with Pro Connect+. Just create a Match Request, and we’ll connect you with a perfect match.',
    },

    sellers: {
      title: `Find the right class, appointment, or acitivity at the right time, on the right place.
Level up your exercise! today!`,
      linkText: 'Learn More',
    },
  },

  //   {
  //     id: 'indoor-cycling',
  //     title: 'Indoor Cycling & Spin Classes',
  //     description: 'A Bike Ride Away From A Better You',
  //     heroImage:
  //       'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663851013/probuddy/static/images/CategoriesPage/Indoor%20Cycling/ICHeroImage_baqpuk.png',

  //     section1: {
  //       imgSrc:
  //         'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663851492/probuddy/static/images/CategoriesPage/Indoor%20Cycling/ICLesson1_owmgyn.png',
  //       heading: 'Burn calories, boost fitness, and have fun with indoor cycling!',
  //       description:
  //         ' If you’re looking for a fun, challenging workout that also helps you burn calories, get fit, and stay healthy, then book an indoor cycling or spin classes! At Probuddy, we offer several different kinds of indoor cycling classes, so you can find the one that fits your needs best. We have classes for beginners who want to learn basic techniques, as well as advanced riders who want more challenging workouts.',
  //       linkText: 'Book An Indoor Cycling Today',
  //     },

  //     section2: {
  //       imgSrc:
  //         'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663851492/probuddy/static/images/CategoriesPage/Indoor%20Cycling/ICLesson2_knsojy.png',
  //       heading: 'Take Your Cycling to the Next Level!',
  //       description: `Indoor cycling classes are a great way to get some cardio and strength training in, while you ride your bike to some top tunes. Probuddy's indoor cycling classes are great for anyone from beginners to advanced riders. Our pros will guide you through each exercise as they go along so that everyone can participate equally. They'll help keep you motivated as well as keep track of your progress throughout the program so that when you leave class, you'll know how far along on your journey towards fitness it is time to take another step forward.`,
  //       linkText: 'See Our Spinning & Indoor Cycling Listings',
  //     },

  //     exercise: {
  //       productTitle: 'Popular Spinning & Indoor Cycling Listings in your area',
  //       title: 'Types of Indoor Cycling Hosted by Pros',
  //       options: [
  //         {
  //           name: 'Private Indoor Cycling Classes',
  //           description:
  //             'Get ready for one-on-one cycling sessions and work on strength, flexibility, endurance & more.',
  //           imageSrc:
  //             'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663851936/probuddy/static/images/CategoriesPage/Indoor%20Cycling/IC1_ug6vnf.png',
  //         },
  //         {
  //           name: 'Group Indoor Cycling',
  //           description:
  //             'Inspire riders of all kinds with an exciting indoor ride powered by sound, lights, and real-time metrics with your friends.',
  //           imageSrc:
  //             'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663851936/probuddy/static/images/CategoriesPage/Indoor%20Cycling/IC2_ywngax.png',
  //         },
  //         {
  //           name: 'Spin Class Sessions',
  //           description:
  //             'Our personal training will whip you into shape as you circulate between cardio and strength training.',
  //           imageSrc:
  //             'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663851937/probuddy/static/images/CategoriesPage/Indoor%20Cycling/IC3_l0ydmh.png',
  //         },
  //         {
  //           name: 'Skillbike Indoor Cycling Class',
  //           description:
  //             'With a focus on personal attention, these classes are perfect for riders who want a personalized workout experience.',
  //           imageSrc:
  //             'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663851936/probuddy/static/images/CategoriesPage/Indoor%20Cycling/IC4_grerjd.png',
  //         },
  //         {
  //           name: 'Indoor Cycling for Adults',
  //           description:
  //             'Make fitness easy at your own pace and improve your health, fitness and mental well-being.',
  //           imageSrc:
  //             'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663851937/probuddy/static/images/CategoriesPage/Indoor%20Cycling/IC5_sjsfw6.png',
  //         },
  //         {
  //           name: 'Advanced Cycling Classes',
  //           description:
  //             'Take an advanced indoor cycling class from a Pro who will bring out the best cyclist in you!',
  //           imageSrc:
  //             'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663851937/probuddy/static/images/CategoriesPage/Indoor%20Cycling/IC6_kqjodp.png',
  //         },
  //       ],
  //     },

  //     whyBook: {
  //       imgSrc:
  //         'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663850891/probuddy/static/images/CategoriesPage/Indoor%20Cycling/BookIndoorCyclingImage_ixyoho.png',
  //       heading1: 'One Marketplace for All Things Sports, Events and Adventure',
  //       position: 'center',
  //       containerPosition: 'start',
  //       description1:
  //         'Probuddy is a one-stop Marketplace for all things sports, wellness & adventure. It gives you worldwide access to thousands of top-rated fitness instructors, yoga classes, tennis coaches, or rock climbing events. All you need to do is find your ideal workout activity and book it!',
  //       heading2: 'Why Book on Probuddy?',
  //       description2:
  //         "Probuddy is the easiest way to book the best fitness classes across the globe - no more waiting or driving around to find the right class for you. Our goal is to help you find the perfect instructor or class in your area so that you can make your next activity one you'll never forget!",
  //     },

  //     classesNearYou: {
  //       title: 'Book the Best Indoor Cycling & Spin Classes Near You',
  //       description: `The demand for spin classes has never been greater. With more and more people taking up cycling as a hobby, it's no surprise that there are now so many fitness studios opening up all over the country. If you are looking for the best indoor cycling or spin classes near you, then you have come to the right place. We have compiled a list of some of the top indoor cycling classes in your area. Book a class today!`,
  //     },

  //     instructors: {
  //       title: 'Get Fit, Stay Fit with Our Certified Indoor Cycling & Spin Instructors',
  //       description:
  //         'If you are looking to get fit, stay fit and have fun with your fitness routine, then you need to look no further than our certified indoor cycling instructors. Our Pros can help you get started on your fitness journey by providing you with the best workouts and advice. They are  committed to helping people improve their overall health and well-being through cycling. They provide a safe and fun workout that allows people of all ages and fitness levels to burn calories, strengthen your legs, increase cardio endurance and reduce stress.',
  //       instructorsData: [
  //         {
  //           id: '1',
  //           trainerImage:
  //             'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663852393/probuddy/static/images/CategoriesPage/Indoor%20Cycling/ICT1_zz2lyu.png',
  //           name: 'Michelle Teng',
  //           type: 'Indoor Cycling Instructor',
  //           rating: '4.9',
  //           reviews: '12',
  //           category: 'indoor-cycling',
  //           description: `Take your cycling to a new level with energetic and fun cycling instructor, Michelle. Open for beginners to national level athletes.`,
  //         },
  //         {
  //           id: '2',
  //           trainerImage:
  //             'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663852394/probuddy/static/images/CategoriesPage/Indoor%20Cycling/ICT2_ucoeh4.png',
  //           name: 'Zac Lawson',
  //           type: 'Indoor Cycling Instructor',
  //           rating: '4.8',
  //           reviews: '12',
  //           category: 'indoor-cycling',
  //           description:
  //             'Zac can coach & motivate you from beginners to fitness junkies, and guarantee each session will be different to the last. ',
  //         },
  //         {
  //           id: '3',
  //           trainerImage:
  //             'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663852393/probuddy/static/images/CategoriesPage/Indoor%20Cycling/ICT3_f9uxin.png',
  //           name: 'Cherie Mugler',
  //           type: 'Indoor Cycling Instructor',
  //           rating: '4.8',
  //           reviews: '12',
  //           category: 'indoor-cycling',
  //           description:
  //             'Coach Cherie provides the support and guidance to enable you to reach your potential through indoor cycling.',
  //         },
  //       ],
  //     },

  //     featured: {
  //       title: 'Top-Rated Indoor Cycling & Spinning Class Studios Bookings',
  //       description:
  //         'Probuddy is a one-stop shop for all your fitness needs. Our goal is to help you find the best indoor cycling studios in your area and make a booking with them easy and convenient. We have partnered with each studio to provide their most popular classes, trainers, and facilities so that you can find your perfect fit. We know that it can be hard to find the right class or facility for you, but with Probuddy, finding the right indoor cycling studios is easier than ever! Check out our list of top-rated indoor cycling studios booking in your area that cater to all fitness levels.',
  //       featuredItems: [
  //         {
  //           id: '1',
  //           companyImage:
  //             'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663852659/probuddy/static/images/CategoriesPage/Indoor%20Cycling/ICClasses_xemmj0.png',
  //           name: 'Sync Cycle',
  //           location: 'Yio Chu Kang Road, Singapore',
  //           rating: '4.8',
  //           reviews: '230',
  //           category: 'indoor-cycling',
  //           description: `Sync Cycle is a indoor cycling studio and our classes are all about mashing up fun, fitness and music. Let today be your day one. We are here to help get you started. From our entire team at Sync Cycle, we are excited to help you discover your full potential on the bike. Simply click on the link and follow the steps to get started. We can’t wait to ride with you.`,
  //         },
  //         {
  //           id: '2',
  //           companyImage:
  //             'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663852659/probuddy/static/images/CategoriesPage/Indoor%20Cycling/ICClasses_xemmj0.png',
  //           name: 'Sync Cycle',
  //           location: 'Yio Chu Kang Road, Singapore',
  //           rating: '4.8',
  //           reviews: '230',
  //           category: 'indoor-cycling',
  //           description: `Sync Cycle is a indoor cycling studio and our classes are all about mashing up fun, fitness and music. Let today be your day one. We are here to help get you started. From our entire team at Sync Cycle, we are excited to help you discover your full potential on the bike. Simply click on the link and follow the steps to get started. We can’t wait to ride with you.`,
  //         },
  //       ],
  //     },

  //     topRatedClasses: {
  //       title: 'Search and Book The Best Indoor Cycling Classes Right Here!',
  //       description: `If you love riding bikes and want to improve your cardiovascular health, then this is the right time to join our indoor cycling classes. We have the best instructors who will help you achieve your fitness goals in no time. Our instructors are certified in cycling and can help you get started on an effective path of building a strong cardiovascular system. They also have years of experience in teaching people how to ride their bikes correctly so that they can reach their full potential as athletes.`,
  //       classesData: [
  //         {
  //           id: '1',
  //           classesImage:
  //             'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663852850/probuddy/static/images/CategoriesPage/Indoor%20Cycling/ICClasses1_fy1qmr.png',
  //           className: 'Group Indoor Cycling [Beginners]',
  //           instructorName: 'Sync Cycle',
  //           location: 'Singapore, SG',
  //           rating: '4.8',
  //           reviews: '230',
  //           category: 'personal-trainer',
  //           description:
  //             'Reserve your spot at group indoor cycling class. With a focus on personal attention, these classes are perfect for riders who want a personalized workout experience.',
  //           bookingTimings: '8.00 Am to 10.00 Am',
  //           bookingPrice: '100',
  //         },
  //         {
  //           id: '2',
  //           classesImage:
  //             'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663852851/probuddy/static/images/CategoriesPage/Indoor%20Cycling/ICClasses2_mlajvq.png',
  //           className: 'Fun Indoor Cycling for Adults',
  //           instructorName: 'Brian Osted',
  //           location: 'London, UK',
  //           rating: '4.8',
  //           reviews: '230',
  //           category: 'personal-trainer',
  //           description: `Want to level up your health and fitness regime? We've got a list of the personal training classes and sessions with the best personal trainers in your area!`,
  //           bookingTimings: '8.00 Am to 10.00 Am',
  //           bookingPrice: '120',
  //         },
  //         {
  //           id: '3',
  //           classesImage:
  //             'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663852851/probuddy/static/images/CategoriesPage/Indoor%20Cycling/ICClasses3_tlgdrd.png',
  //           className: 'Advanced Indoor Spin Class',
  //           instructorName: 'Miranda Lee',
  //           location: 'Brooklyn, NY',
  //           rating: '4.8',
  //           reviews: '230',
  //           category: 'personal-trainer',
  //           description:
  //             'Whether you want to lose weight or just want to improve your flexibility and strength, our advanced indoor cycling classes offers a results-drive session designed just for you.',
  //           bookingTimings: '8.00 Am to 10.00 Am',
  //           bookingPrice: '120',
  //         },
  //       ],
  //     },

  //     reviews: [
  //       {
  //         name: 'Courtney Henry',
  //         country: 'Hong Kong',
  //         imageSrc: ReviewImage1,
  //         description:
  //           'The Probuddy platform is a great solution for business owners. We highly recommend it for businesses that need to keep track of everything.',
  //       },
  //       {
  //         name: 'Simon Hart',
  //         country: 'Texas',
  //         imageSrc: ReviewImage2,
  //         description: `I've ticked off a fitness  goal I never thought I would have, simply because I thought it was never achievable. Probuddy has helped me reach my goals by bringing discipline back into my life. I’ve achieved a much better state of mental wellbeing and physical appearance, giving me back confidence in myself.`,
  //       },
  //       {
  //         name: 'Hayley Smith',
  //         country: 'Manchester',
  //         imageSrc: ReviewImage6,
  //         description:
  //           'Taking time out to destress with a yoga session is always a wonderful thing. Using Probuddy makes it even more enjoyable.',
  //       },
  //     ],

  //     features: {
  //       title: 'Learn how to speed up your cycling skills. We’re here to help.',
  //       description:
  //         'Match up with verified and certified pros near you with Pro Connect+. Just create a Match Request, and we’ll connect you with a perfect match.',
  //     },

  //     sellers: {
  //       title: `Find the right class, appointment, or acitivity at the right time, on the right place.
  // Level up your exercise! today!`,
  //       linkText: 'Learn More',
  //     },
  //   },

  //   {
  //     id: 'massage-spa',
  //     title: 'Massage & Spa Treatments',
  //     description: 'Restore Balance and Renew Your Energy',
  //     heroImage:
  //       'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663853444/probuddy/static/images/CategoriesPage/Massage%20and%20Spa/MassageHeroImage_kccuey.png',

  //     section1: {
  //       imgSrc:
  //         'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663853663/probuddy/static/images/CategoriesPage/Massage%20and%20Spa/MassageLesson1_cg3q3e.png',
  //       heading: `We give you the relaxation you deserve. Get a massage today!`,
  //       description: `Get your day off to a great start with a luxurious massage or spa treatment. Probuddy features the most comprehensive listings of massage therapists near you, so you can easily find the perfect therapist to fit your needs. Whether you need a full-body massage or are looking for something more targeted, we have you covered. If you're feeling stressed out, our therapists can help you unwind and relax.`,
  //       linkText: 'Book An Appointment Today',
  //     },

  //     section2: {
  //       imgSrc:
  //         'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663853664/probuddy/static/images/CategoriesPage/Massage%20and%20Spa/MassageLesson2_sumnw2.png',
  //       heading: `Relax, Recharge and Rejuvenate. Give yourself a treat.`,
  //       description: `A massage is a great way to unwind and rejuvenate your mind, body and spirit. We’ve partnered with freelance massage therapists that can help you relax, soothe away pain and stress, and even boost your immune system. Take the time today to treat yourself to a soothing massage or spa treatment. Let the expert hands of Probuddy’s certified massage therapist or licensed esthetician soothe you and relax your mind. You deserve it!`,
  //       linkText: 'See Our Massage & Spa Listings',
  //     },

  //     exercise: {
  //       productTitle: 'Popular Massage & Spa Listings in your area',
  //       title: 'Types of Massage & Spa Treatments & Services',
  //       options: [
  //         {
  //           name: 'Thai Massage',
  //           description:
  //             'Relax and recover with a wide variety of healing massages. A true holistic journey to tranquil peace.',
  //           imageSrc:
  //             'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663853880/probuddy/static/images/CategoriesPage/Massage%20and%20Spa/Massage1_skuv6q.png',
  //         },
  //         {
  //           name: 'Swedish Massage',
  //           description: `Deep, specific pressure combined with stretching that will help relieve tension and increase your body's mobility.`,
  //           imageSrc:
  //             'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663853880/probuddy/static/images/CategoriesPage/Massage%20and%20Spa/Massage2_emwgh5.png',
  //         },
  //         {
  //           name: 'Shiatsu Massage',
  //           description:
  //             'Long rhythmic strokes to ease the mind for deep relaxation, restful sleep and jet lag recovery.',
  //           imageSrc:
  //             'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663853881/probuddy/static/images/CategoriesPage/Massage%20and%20Spa/Massage3_df2mks.png',
  //         },
  //         {
  //           name: 'Spa Massage',
  //           description:
  //             'Healing herbs and warm poultice clear the mind of negativity and naturally detoxify your face and body.',
  //           imageSrc:
  //             'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663853881/probuddy/static/images/CategoriesPage/Massage%20and%20Spa/Massage4_jflpgd.png',
  //         },
  //         {
  //           name: 'Hot Stone Massage',
  //           description:
  //             'Relax and ease tense muscles, joints and damaged soft tissues throughout your body.',
  //           imageSrc:
  //             'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663853881/probuddy/static/images/CategoriesPage/Massage%20and%20Spa/Massage5_yfmil0.png',
  //         },
  //         {
  //           name: 'Head and Scalp Massage',
  //           description:
  //             'Take a break from your busy day to unwind and recharge with focused work on the head, neck and shoulders.',
  //           imageSrc:
  //             'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663853881/probuddy/static/images/CategoriesPage/Massage%20and%20Spa/Massage6_pzora6.png',
  //         },
  //       ],
  //     },

  //     whyBook: {
  //       imgSrc:
  //         'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663853267/probuddy/static/images/CategoriesPage/Massage%20and%20Spa/BookMassageImage_yxxxcl.png',
  //       heading1: 'One Marketplace for All Things Sports, Events and Adventure',
  //       position: 'center',
  //       containerPosition: 'start',
  //       description1:
  //         'Probuddy is a one-stop Marketplace for all things sports, wellness & adventure. It gives you worldwide access to thousands of top-rated fitness instructors, yoga classes, tennis coaches, or rock climbing events. All you need to do is find your ideal workout activity and book it!',
  //       heading2: 'Why Book on Probuddy?',
  //       description2:
  //         "Probuddy is the easiest way to book the best fitness classes across the globe - no more waiting or driving around to find the right class for you. Our goal is to help you find the perfect instructor or class in your area so that you can make your next activity one you'll never forget!",
  //     },

  //     classesNearYou: {
  //       title: 'Book the Best Massage & Spa Treatments Near You',
  //       description: `The demand for spin classes has never been greater. With more and more people taking up cycling as a hobby, it's no surprise that there are now so many fitness studios opening up all over the country. If you are looking for the best indoor cycling or spin classes near you, then you have come to the right place. We have compiled a list of some of the top indoor cycling classes in your area. Book a class today!`,
  //     },

  //     instructors: {
  //       title: 'Most Popular Freelance Massage and Spa Therapists',
  //       description: `Whether you're looking for a therapist who can relieve your back pain, a masseuse who can help relax and indulge in some pampering, our marketplace helps you find the right match. Search through our list of hundreds of therapists and spas across the country. We have listings from all types of massage therapists from deep tissue, Thai massage, Swedish and Sports massage therapists. If you're looking for a professional massage therapist for your next vacation, look no further than Probuddy. Book an appointment today!`,
  //       instructorsData: [
  //         {
  //           id: '1',
  //           trainerImage:
  //             'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663854193/probuddy/static/images/CategoriesPage/Massage%20and%20Spa/MassageInstructor1_nwqjei.png',
  //           name: 'Millie Von',
  //           type: 'Massage Therapist',
  //           rating: '4.6',
  //           reviews: '12',
  //           category: 'massage-spa',
  //           description: `Millie is  passionate, dedicated massage therapist who always seeks to give her best to helping her patients achieve their desired outcomes.`,
  //         },
  //         {
  //           id: '2',
  //           trainerImage:
  //             'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663854194/probuddy/static/images/CategoriesPage/Massage%20and%20Spa/MassageInstructor2_aid2s5.png',
  //           name: 'Gavin Deby',
  //           type: 'Massage Therapist',
  //           rating: '4.6',
  //           reviews: '12',
  //           category: 'massage-spa',
  //           description:
  //             'Specializing in Swedish and Thai massage  including work-related and post-operative conditions for athletes.',
  //         },
  //         {
  //           id: '3',
  //           trainerImage:
  //             'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663854194/probuddy/static/images/CategoriesPage/Massage%20and%20Spa/MassageInstructor3_mt2uek.png',
  //           name: 'Emily Shaulz',
  //           type: 'Massage Therapist',
  //           rating: '4.6',
  //           reviews: '12',
  //           category: 'massage-spa',
  //           description:
  //             'With her strong belief in a holistic approach towards her treatment approaches, she develops an individualized massage treatment for her clients.',
  //         },
  //       ],
  //     },

  //     featured: {
  //       title: 'Top-Rated Massage & Spa Companies & Treatments Bookings',
  //       description:
  //         'Book a massage or spa treatment at some of the top-rated massage and spa companies near you. Choose from a wide range of massage and spa treatments, including hot stone massages and aromatherapy massages. From the most popular treatments like Swedish massages to more exclusive treatments such as reflexology, we have all the best deals to keep you feeling relaxed, pampered and rejuvenated. These companies offer a range of packages that suit every budget and customer needs.',
  //       featuredItems: [
  //         {
  //           id: '1',
  //           companyImage:
  //             'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663854390/probuddy/static/images/CategoriesPage/Massage%20and%20Spa/MassageClasses_rqkrjq.png',
  //           name: 'Sabaai Sabaai ',
  //           location: 'Boat Quay, Singapore',
  //           rating: '4.8',
  //           reviews: '230',
  //           category: 'massage-spa',
  //           description: `Treat yourself to an UNESCO recognised Thai massage in Singapore. Experience our back cracking combination steps to ease stiff muscle aches yet relaxing, Sabaai Sabaai. We are an established Thai massage boutique specializing in traditional Thai massage, located in the mid of the bustling city with a great environment, river view & one of the must-visit tourist spots in Singapore. Started in 2009 & has since grown and is reputed for our skill`,
  //         },
  //         {
  //           id: '2',
  //           companyImage:
  //             'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663854390/probuddy/static/images/CategoriesPage/Massage%20and%20Spa/MassageClasses_rqkrjq.png',
  //           name: 'Sabaai Sabaai ',
  //           location: 'Boat Quay, Singapore',
  //           rating: '4.8',
  //           reviews: '230',
  //           category: 'massage-spa',
  //           description: `Treat yourself to an UNESCO recognised Thai massage in Singapore. Experience our back cracking combination steps to ease stiff muscle aches yet relaxing, Sabaai Sabaai. We are an established Thai massage boutique specializing in traditional Thai massage, located in the mid of the bustling city with a great environment, river view & one of the must-visit tourist spots in Singapore. Started in 2009 & has since grown and is reputed for our skill`,
  //         },
  //       ],
  //     },

  //     topRatedClasses: {
  //       title: 'Search and Book The Best Massage & Spa Treatments Right Here!',
  //       description: `Whether you're looking for a relaxing weekend away or an escape from the stresses of everyday life, there's no denying that a massage is a perfect way to unwind. No matter what kind of massage you're looking for, we've got the top-rated massages & spa treatments and certificed massage therapists and licensed masseuses near you. No matter what kind of treatment you want, Probuddy helps you get back on track with their unique approach to healing and relaxation. Book a massage and spa treatment today!`,
  //       classesData: [
  //         {
  //           id: '1',
  //           classesImage:
  //             'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663854635/probuddy/static/images/CategoriesPage/Massage%20and%20Spa/MassageClasses1_zemr7n.png',
  //           className: 'Private Muscle Gain Personal Training',
  //           instructorName: 'Take Five Group',
  //           location: 'Singapore, SG',
  //           rating: '4.8',
  //           reviews: '230',
  //           category: 'massage-spa',
  //           description:
  //             'Team up with our certified  personal  trainers that can deliver effective results for you. Get to know your own strength, Book now!',
  //           bookingTimings: '8.00 Am to 10.00 Am',
  //           bookingPrice: '100',
  //         },
  //         {
  //           id: '2',
  //           classesImage:
  //             'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663854636/probuddy/static/images/CategoriesPage/Massage%20and%20Spa/MassageClasses2_mmiybw.png',
  //           className: 'Sports Performance Personal Training',
  //           instructorName: 'Extreme Outdoor Club',
  //           location: 'London, UK',
  //           rating: '4.8',
  //           reviews: '230',
  //           category: 'massage-spa',
  //           description: `Want to level up your health and fitness regime? We've got a list of the personal training classes and sessions with the best personal trainers in your area!`,
  //           bookingTimings: '8.00 Am to 10.00 Am',
  //           bookingPrice: '120',
  //         },
  //         {
  //           id: '3',
  //           classesImage:
  //             'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663854636/probuddy/static/images/CategoriesPage/Massage%20and%20Spa/MassageClasses3_dllp0w.png',
  //           className: 'Corrective Exercise Personal Training',
  //           instructorName: 'Sean Kenny',
  //           location: 'Brooklyn, NY',
  //           rating: '4.8',
  //           reviews: '230',
  //           category: 'massage-spa',
  //           description:
  //             'Looking for a personal trainer near you? We offer weight loss, muscle and body building training, correcrtive excersise for injuries. Book now.',
  //           bookingTimings: '8.00 Am to 10.00 Am',
  //           bookingPrice: '120',
  //         },
  //       ],
  //     },

  //     reviews: [
  //       {
  //         name: 'Courtney Henry',
  //         country: 'Hong Kong',
  //         imageSrc: ReviewImage1,
  //         description:
  //           'The Probuddy platform is a great solution for business owners. We highly recommend it for businesses that need to keep track of everything.',
  //       },
  //       {
  //         name: 'Simon Hart',
  //         country: 'Texas',
  //         imageSrc: ReviewImage2,
  //         description: `I've ticked off a fitness  goal I never thought I would have, simply because I thought it was never achievable. Probuddy has helped me reach my goals by bringing discipline back into my life. I’ve achieved a much better state of mental wellbeing and physical appearance, giving me back confidence in myself.`,
  //       },
  //       {
  //         name: 'Hayley Smith',
  //         country: 'Manchester',
  //         imageSrc: ReviewImage6,
  //         description:
  //           'Taking time out to destress with a yoga session is always a wonderful thing. Using Probuddy makes it even more enjoyable.',
  //       },
  //     ],

  //     features: {
  //       title: 'Indulge in a Relaxing Massage and Spa Treatment. We can help!',
  //       description:
  //         'Match up with verified and certified pros near you with Pro Connect+. Just create a Match Request, and we’ll connect you with a perfect match.',
  //     },

  //     sellers: {
  //       title: `Search and book the right class, appointment, or acitivity at the right time, on the right place.
  // A day of beauty, relaxation, and indulgence awaits you at Probuddy.`,
  //       linkText: 'Learn More',
  //     },
  //   },
];

export const otherCategoryData = {
  reviews: [
    {
      name: 'Courtney Henry',
      country: 'Hong Kong',
      imageSrc: ReviewImage1,
      description:
        'The Probuddy platform is a great solution for business owners. We highly recommend it for businesses that need to keep track of everything.',
    },
    {
      name: 'Simon Hart',
      country: 'Texas',
      imageSrc: ReviewImage2,
      description:
        'I have tried many online courses in my life, but none that have been so effective as Probuddy. The course has helped me with my business and gave me the tools to be more successful. The instructors are very knowledgeable and helpful. I have enjoyed the course and look forward to taking more classes.',
    },
    {
      name: 'Hayley Smith',
      country: 'Manchester',
      imageSrc: ReviewImage6,
      description:
        'Taking time out to destress with a session is always a wonderful thing. Using Probuddy makes it even more enjoyable.',
    },
  ],

  features: {
    title: 'Post Your Request And Let Our Pro’s Connect with you right away!',
    description:
      'Match up with verified and certified pros near you with Pro Connect+. Just create a Match Request, and we’ll connect you with a perfect match.',
    featuredItems: [
      {
        id: '1',
        companyImage:
          'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677220313/probuddy/static/images/CategoriesPage/sports/SportsCompanyImage1_n8iwgh.png',
        name: 'SCC Tennis Academy',
        location: 'Connaught Dr., Singapore',
        rating: '4.2',
        reviews: '225',
        category: 'sports',
        description:
          "SCC Tennis Academy is more than just a workout ~ it's a lifestyle that inspires confidence, community and celebration! Whether you are beginning an exercise program, a high level athlete, or just want to tone up, we have a program for you. Mindful movement is our motto and the common thread that runs through our philosophy and approach to living a healthy, happy life.",
      },
      {
        id: '2',
        companyImage:
          'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677220313/probuddy/static/images/CategoriesPage/sports/SportsCompanyImage1_n8iwgh.png',
        name: 'SCC Tennis Academy',
        location: 'Connaught Dr., Singapore',
        rating: '4.6',
        reviews: '195',
        category: 'sports',
        description:
          "SCC Tennis Academy is more than just a workout ~ it's a lifestyle that inspires confidence, community and celebration! Whether you are beginning an exercise program, a high level athlete, or just want to tone up, we have a program for you. Mindful movement is our motto and the common thread that runs through our philosophy and approach to living a healthy, happy life.",
      },
    ],
  },

  sellers: {
    title:
      'Unlock your full potential and join the Probuddy Education community to access over a thousand expert tutors and professionals and take your skills to the next level.',
    linkText: 'Learn More',
  },
};

export const instructorsData = [
  {
    id: '1',
    trainerImage:
      'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1681967398/probuddy/static/images/CategoriesPage/sports/SportsTrainerImage1_folgnd.png',
    name: 'Tennis Coach',
    type: 'Christo Weise',
    rating: '4.4',
    reviews: '17',
    category: 'sports',
    description:
      "Great with Corporate group programs currently Google's tennis program coach and i have also conducted groups for the Singapore Police and Facebook.",
  },
  {
    id: '2',
    trainerImage:
      'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1681967398/probuddy/static/images/CategoriesPage/sports/SportsTrainerImage2_xetnnl.png',
    name: 'Agustin Velotti',
    type: 'Tennis Coach',
    rating: '4.8',
    reviews: '16',
    category: 'sports',
    description:
      'Let Agustin help you improve your skills and fitness. Classes are for all levels and ages. Currently world ranked 391 ATP and highest ranked active tennis player in Singapore.',
  },
  {
    id: '3',
    trainerImage:
      'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1681967399/probuddy/static/images/CategoriesPage/sports/SportsTrainerImage3_kv873p.png',
    name: 'Michel Van De Sanden',
    type: 'Tennis Coach',
    rating: '4.4',
    reviews: '11',
    category: 'sports',
    description:
      'Looking to learn basics of tennis, get your game to another level or to have a hit with high performance coach and former WTA tour coach.',
  },
];

export const classesData = [
  {
    id: '1',
    classesImage:
      'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663854635/probuddy/static/images/CategoriesPage/Massage%20and%20Spa/MassageClasses1_zemr7n.png',
    className: 'Private Muscle Gain Personal Training',
    instructorName: 'Take Five Group',
    location: 'Singapore, SG',
    rating: '4.8',
    reviews: '230',
    category: 'massage-spa',
    description:
      'Team up with our certified  personal  trainers that can deliver effective results for you. Get to know your own strength, Book now!',
    bookingTimings: '8.00 Am to 10.00 Am',
    bookingPrice: '100',
  },
  {
    id: '2',
    classesImage:
      'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663854636/probuddy/static/images/CategoriesPage/Massage%20and%20Spa/MassageClasses2_mmiybw.png',
    className: 'Sports Performance Personal Training',
    instructorName: 'Extreme Outdoor Club',
    location: 'London, UK',
    rating: '4.8',
    reviews: '230',
    category: 'massage-spa',
    description: `Want to level up your health and fitness regime? We've got a list of the personal training classes and sessions with the best personal trainers in your area!`,
    bookingTimings: '8.00 Am to 10.00 Am',
    bookingPrice: '120',
  },
  {
    id: '3',
    classesImage:
      'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663854636/probuddy/static/images/CategoriesPage/Massage%20and%20Spa/MassageClasses3_dllp0w.png',
    className: 'Corrective Exercise Personal Training',
    instructorName: 'Sean Kenny',
    location: 'Brooklyn, NY',
    rating: '4.8',
    reviews: '230',
    category: 'massage-spa',
    description:
      'Looking for a personal trainer near you? We offer weight loss, muscle and body building training, correcrtive excersise for injuries. Book now.',
    bookingTimings: '8.00 Am to 10.00 Am',
    bookingPrice: '120',
  },
];

export const whyBook = {
  imgSrc:
    'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1676282533/probuddy/static/NewEducation/CategoryPage/whyBook_ocaxzo.jpg',
  heading1: 'Why Choose Us',
  position: 'start',
  containerPosition: 'center',
  description1:
    'Choose Probuddy Education for a convenient and personalized learning experience. Our platform connects you with a network of highly qualified professionals who offer a wide range of online classes, so you can learn at your own pace and on your own schedule. Browse through our teachers, find the right match, and start your journey towards personal and professional growth today!',
  heading2: '',
  description2: '',
};

export const educationCategoriesDataConfig = [
  {
    id: 'math',
    title: 'Maths',
    description: 'Online Math Courses: Advance Your Skills and Knowledge',
    heroImage:
      'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675081079/probuddy/static/NewEducation/CategoryPage/MathPage/image3_pzyz8i.png',

    section1: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675081064/probuddy/static/NewEducation/CategoryPage/MathPage/image1_pfhsqm.jpg',
      heading: 'Online Math Classes: Learn at Your Own Pace',
      description: [
        "Probuddy offers online math classes that allow you to learn at your own pace. Our expert instructors have designed the curriculum to be self-paced and flexible, so you can learn on your own schedule. Whether you're a student, a professional, or simply looking to improve your math skills, our classes are tailored to meet your needs.",
        "With Probuddy, you'll have access to a wide range of math courses, including basic arithmetic, algebra, geometry, and more. All our classes are interactive and engaging, and you'll have access to our team of experienced math tutors who are available to help you with any questions or challenges you may encounter. With Probuddy, you'll be able to master math at your own pace and on your own terms.",
      ],
      linkText: 'Book A Class Now',
    },
    becomeSeller: 'Are you a Math Expert?',

    section2: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675081071/probuddy/static/NewEducation/CategoryPage/MathPage/image2_ao2p42.jpg',
      heading: 'Select classes instructed by industry professionals',
      description: [
        "Probuddy is proud to offer a selection of classes instructed by industry professionals. Our instructors are experts in their field and bring a wealth of real-world experience to the classroom. This means that you'll be learning from people who have firsthand knowledge of the math concepts and skills that you'll be studying.",
        "Whether you're looking to improve your math skills for work or for personal development, our instructors will provide you with the guidance and support you need to succeed.",
      ],
      linkText: 'Explore Our Listings',
    },

    exercise: {
      productTitle: 'Popular Sports Listings in your area',
      title: 'Private or Group Sports Classes Offered by Our Pros',
    },

    whyBook: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675483916/probuddy/static/images/CategoriesPage/sports/BookSportsImage_hhxsfe.png',
      heading1: 'Why Choose Us',
      position: 'start',
      containerPosition: 'center',
      description1:
        'We make it easy for you to find, schedule and attend sports classes taught by the Pros through your local sports facility, and at your convenience.',
      heading2: '',
      description2: '',
    },

    classesNearYou: {
      title: 'Enroll Now: Boost Your Math Skills with Online Courses',
      description: [
        "Enrolling in Probuddy's online math courses is the perfect way to brush up on your math skills. These comprehensive, self-paced courses are designed with students in mind, so whether you're looking to better understand basic algebra or want to get ahead for upcoming classes, you're sure to be a master of mathematics in no time. Plus, Probuddy's online courses provide round-the-clock access from anywhere with an internet connection. So don't wait - boost your math skills and enroll now!",
      ],
    },

    instructors: {
      title: 'Top-Rated Maths Coaches & Instructors',
      description:
        "Discover sports classes and coaches near you. Learn from the best athletes, trainers and instructors in your area. Probuddy gives you access to your city's most elite coaches who live in your neighborhood.",
    },

    featured: {
      title: 'Featured Online Math Courses: Advance Your Skills and Knowledge',
      description: [
        "Thinking about taking your math knowledge and skills to the next level? Probuddy offers Featured Online Math Courses designed to help you unlock new insights. Whether you're looking to enter a degree program, switch careers, or just improve your understanding of basic topics, we'll provide you with all the resources necessary to reach your goals.",
        'With our Featured Online Math Courses, not only can you grow your subject matter expertise, but you can also develop the skills required to problem solve and think critically. Take the first step today on your journey towards mastering mathematics!',
      ],
    },

    topRatedClasses: {
      title: 'Expert-Endorsed Online Math Courses',
      description: [
        "Are you in need of brushing up on your Math? If so, Expert-Endorsed Online Math Courses here at Probuddy are the answer! They are cost-effective and comprehensive. The courses cover a wide range of topics from times tables to calculus. You get direct access to expert instructors who have years of experience teaching Math online. With the real time feedback, you can easily understand concept better than ever before and conquer those annoying math questions. So don't wait up, come join Expert-Endorsed Online Math Courses today for an incomparable maths learning experience!",
      ],
    },
  },
  {
    id: 'english',
    title: 'English',
    description: 'Online English Courses: Master the Language and Achieve Success',
    heroImage:
      'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675329279/probuddy/static/NewEducation/CategoryPage/EnglishPage/pexels-katerina-holmes-5905709_budzzm.jpg',

    section1: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675329263/probuddy/static/NewEducation/CategoryPage/EnglishPage/pexels-karolina-grabowska-4491461_xeanhh.jpg',
      heading: 'Online English Tutoring: Master the language, achieve your goals',
      description: [
        "Probuddy offers online English tutoring that will help you master the language and achieve your goals. Our expert instructors provide personalized instruction and guidance to help you improve your English skills quickly and efficiently. Whether you're a beginner looking to improve your basic vocabulary and grammar, or an advanced learner working to perfect your accent and fluency, our tutors will work with you to create a customized lesson plan that meets your specific needs.",
        "With Probuddy, you'll have access to a wide range of English courses, including grammar, writing, speaking, and listening. Our interactive and engaging classes are designed to help you master English concepts quickly and efficiently. Sign up with Probuddy to take your English skills to the next level and achieve your goals.",
      ],
      linkText: 'Book A Class Now',
    },
    becomeSeller: 'Are you an English Tutor?',

    section2: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675329267/probuddy/static/NewEducation/CategoryPage/EnglishPage/pexels-kampus-production-5940721_x7iubz.jpg',
      heading: 'Select classes instructed by industry professionals',
      description: [
        'Probuddy offers select classes instructed by industry professionals, ensuring that students gain real-world experience and knowledge. Our instructors are experts in their field and bring a wealth of practical knowledge to the classroom.',
        'This means that students will be learning from individuals who have firsthand experience in the subject matter being taught, providing a valuable and unique learning experience. Sign up with Probuddy to learn from industry professionals and gain the skills and knowledge needed to succeed in your field.',
      ],
      linkText: 'Explore Our Listings',
    },

    exercise: {
      productTitle: 'Popular English Listings in your area',
      title: 'Private or Group English Classes Offered by Our Pros',
    },

    classesNearYou: {
      title: 'Enroll Now: Boost Your English Skills with Online Courses',
      description: [
        'Enroll Now at Probuddy and take advantage of the online courses available to boost your English skills! At Probuddy, you will gain access to a library full of resources, including webinars and tutorials, that you can use to enhance your pronunciation, grammar, comprehension and vocabulary.',
        "You can find courses tailored to meet your individual needs as well as interactive lessons with experienced tutors. Enrollment is easy; simply sign up with your email and get started! Don't miss this amazing opportunity to improve your English skills today - Enroll Now at Probuddy!",
      ],
    },

    instructors: {
      title: 'Top-Rated English Coaches & Instructors',
      description:
        "Discover English classes and coaches near you. Learn from the best instructors in your area. Probuddy gives you access to your city's most elite coaches who live in your neighborhood.",
    },

    featured: {
      title: 'Featured Online English Courses: Boost your Career and Knowledge',
      description: [
        'Finding the right online English course can seem overwhelming, but with Probuddy Education, you can make the process much easier. In addition to their Featured Online English Courses, they have a variety of options designed to boost your career and knowledge.',
        'Probuddy Education provides knowledgeable and patient tutors that are devoted to helping you reach your goals. All of their materials are up-to-date and tailored to providing the best education possible. Whether you’re planning on studying abroad or looking for personal career growth, their Featured Online English Courses offer something for everyone!',
      ],
    },

    topRatedClasses: {
      title: 'Flexible and Affordable Online English Courses for Everyone',
      description: [
        'If you are looking for quality, yet affordable English courses that can help you improve your language skills, then Probuddy Education is the perfect option! They offer Flexible and Affordable Online English Courses for Everyone, allowing students to pick a course that best suits their needs. The great thing about these courses is that they are affordable so almost everyone can access them despite having different budgets.',
        "Moreover, they provide an excellent learning experience that is more flexible than traditional classrooms. With this you have the freedom to set your own pace and learn at your own time and convenience. I would absolutely recommend Probuddy Education if you're searching for reliable online English courses!",
      ],
    },
  },
  {
    id: 'chemistry',
    title: 'Chemistry',
    description: 'Online Chemistry Courses: How They Can Help Your Career',
    heroImage:
      'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675081258/probuddy/static/NewEducation/CategoryPage/Chemistry%20Page/image3_glo8u9.png',

    section1: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675081244/probuddy/static/NewEducation/CategoryPage/Chemistry%20Page/image1_j80lit.png',
      heading: 'Advantages of Applying Chemistry Classes',
      description: [
        'Chemistry is an essential branch of science that deals with the composition, structure, properties, and reactions of matter. Studying Chemistry provides numerous benefits that can be useful in many different aspects of life. Here are some of the key advantages of applying for Chemistry classes for Probuddy:',
        'Problem-solving skills: Chemistry requires students to think critically and solve complex problems, which can be applied in other areas, such as the workplace and daily decision-making.',
        'A better understanding of the world around us: Chemistry provides insight into the composition and properties of matter and how they interact with each other, helping us better understand the world we live in.',
        'Career opportunities: Studying Chemistry can open doors to numerous career paths, including in fields such as medicine, forensics, and environmental science.',
        'Improves critical thinking and analytical skills: The field of Chemistry requires the ability to analyze data and make informed decisions, which can help improve critical thinking and analytical skills.',
        'Essential for advanced studies: For students interested in pursuing careers in the scientific and medical fields, a strong foundation in Chemistry is crucial.',
      ],
      linkText: 'Book A Class Now',
    },
    becomeSeller: 'Are you a Chemistry Tutor?',

    section2: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675081254/probuddy/static/NewEducation/CategoryPage/Chemistry%20Page/image2_q2blwq.png',
      heading: 'How Can Chemistry Classes Help You?',
      description: [
        'Chemistry is an essential branch of science that deals with the composition, structure, properties, and reactions of matter. Studying Chemistry provides numerous benefits that can be useful in many different aspects of life. Taking Chemistry classes can provide individuals with a wealth of knowledge and skills that can be applied in many different aspects of life. Whether for personal or professional growth, the benefits of studying Chemistry are numerous and can help individuals reach their full potential.',
      ],
      linkText: 'Explore Our Listings',
    },

    exercise: {
      productTitle: 'Popular Chemistry Listings in your area',
      title: 'Private or Group Chemistry Classes Offered by Our Pros',
    },

    classesNearYou: {
      title: 'Practical Uses of Chemistry',
      description: [
        'Chemistry is a fundamental and versatile science with practical applications in numerous areas of our daily lives, including medicine, agriculture, the food industry, environmental science, energy production, materials science, forensics, and manufacturing.',
        'It is used to develop drugs and treatments for diseases, fertilizers, and pesticides for agriculture, preserve and enhance food, address environmental issues, produce fuels and energy sources, create new materials with improved properties, analyze evidence in criminal investigations, and manufacture consumer goods. Chemistry has a significant impact on our lives and is essential for solving complex problems and improving our standard of living.',
      ],
    },

    instructors: {
      title: 'Top-Rated Chemistry Coaches & Instructors',
      description:
        "Discover Chemistry classes and coaches near you. Learn from the best instructors in your area. Probuddy gives you access to your city's most elite coaches who live in your neighborhood.",
    },

    featured: {
      title: 'How Can Chemistry Help in Your Career?',
      description: [
        'Chemistry is a valuable field that can provide numerous career opportunities and enhance professional development. A strong foundation in Chemistry can lead to careers in scientific and medical fields, including research and development, environmental science, forensics, and the pharmaceutical industry.',
        'Chemistry also helps develop critical thinking and analytical skills, problem-solving abilities, and technical proficiency, which are highly sought after in various industries. Additionally, taking Chemistry classes can provide valuable knowledge and skills that can be applied in many different aspects of life and can help individuals reach their full potential, both personally and professionally.',
        'In conclusion, studying Chemistry can provide a solid foundation for a successful career and provide individuals with valuable skills and knowledge that can be applied in numerous fields.',
      ],
    },

    topRatedClasses: {
      title: 'Flexible and Affordable Online Chemistry Courses for Everyone',
      description: [
        'If you are looking for quality, yet affordable Chemistry courses that can help you improve your language skills, then Probuddy Education is the perfect option! They offer Flexible and Affordable Online Chemistry Courses for Everyone, allowing students to pick a course that best suits their needs. The great thing about these courses is that they are affordable so almost everyone can access them despite having different budgets.',
        "Moreover, they provide an excellent learning experience that is more flexible than traditional classrooms. With this you have the freedom to set your own pace and learn at your own time and convenience. I would absolutely recommend Probuddy Education if you're searching for reliable online Chemistry courses!",
      ],
    },
  },
  {
    id: 'biology',
    title: 'Biology',
    description: 'Master and Learn Biology Online: Explore the Fundamentals and Beyond',
    heroImage:
      'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675081348/probuddy/static/NewEducation/CategoryPage/BiologyPage/image3_cgm0zu.png',

    section1: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675081339/probuddy/static/NewEducation/CategoryPage/BiologyPage/image1_ahkxwi.png',
      heading: 'Online Biology Classes: Learn at Your Own Pace',
      description: [
        'Probuddy offers a unique learning experience for individuals seeking to expand their knowledge of Biology through online classes. With Probuddy, students can learn at their own pace and on their schedule, making it an ideal option for busy individuals who need flexibility in their learning.',
        'Online classes provide access to high-quality resources, including multimedia presentations and interactive activities, which can help students understand complex concepts and retain information.',
        'Additionally, students can connect with experienced instructors and receive personalized feedback, ensuring they receive a comprehensive education in Biology. With Probuddy, students can learn from anywhere and take control of their education, allowing them to achieve their goals and reach their full potential.',
      ],
      linkText: 'Book A Class Now',
    },
    becomeSeller: 'Are you a Biology Tutor?',

    section2: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675081345/probuddy/static/NewEducation/CategoryPage/BiologyPage/image2_bxvw7j.png',
      heading: 'What is Covered in a Biology Class',
      description: [
        'Biology is a broad and fascinating subject that covers various topics ranging from the smallest building blocks of life (cell and molecular biology) to studying complex living organisms and their interactions with each other and the environment (ecology).',
        'In a biology class, students will learn about the fundamental principles of genetics and evolution, physiology and anatomy, and taxonomy and biodiversity. Additionally, they will explore topics such as photosynthesis, the immune system, and biotechnology. All these topics are essential in understanding the functioning of living organisms and their role in the world.',
      ],
      linkText: 'Explore Our Listings',
    },

    exercise: {
      productTitle: 'Popular Biology Listings in your area',
      title: 'Private or Group Biology Classes Offered by Our Pros',
    },

    classesNearYou: {
      title: 'Benefits of Studying Biology',
      description: [
        'Studying biology benefits individuals in various fields, including medicine, agriculture, environmental science, and biotechnology. Understanding the fundamental principles of biology can lead to a better understanding of human health and the development of new medical treatments. ',
        'Biology also helps us understand and address critical environmental issues such as climate change and habitat loss. Additionally, studying biology can lead to careers in fields such as biotechnology and genetic engineering, which can result in new and innovative products and solutions. Overall, a solid understanding of biology can lead to a greater appreciation of the world around us and the interconnectedness of all living things.',
      ],
    },

    instructors: {
      title: 'Top-Rated Biology Coaches & Instructors',
      description:
        "Discover Biology classes and coaches near you. Learn from the best instructors in your area. Probuddy gives you access to your city's most elite coaches who live in your neighborhood.",
    },

    featured: {
      title: 'How Can Biology Help in Your Career?',
      description: [
        'Biology is a valuable field that can provide numerous career opportunities and enhance professional development. A strong foundation in Biology can lead to careers in scientific and medical fields, including research and development, environmental science, forensics, and the pharmaceutical industry.',
        'Biology also helps develop critical thinking and analytical skills, problem-solving abilities, and technical proficiency, which are highly sought after in various industries. Additionally, taking Biology classes can provide valuable knowledge and skills that can be applied in many different aspects of life and can help individuals reach their full potential, both personally and professionally.',
        'In conclusion, studying Biology can provide a solid foundation for a successful career and provide individuals with valuable skills and knowledge that can be applied in numerous fields.',
      ],
    },

    topRatedClasses: {
      title: 'Flexible and Affordable Online Biology Courses for Everyone',
      description: [
        'If you are looking for quality, yet affordable Biology courses that can help you improve your language skills, then Probuddy Education is the perfect option! They offer Flexible and Affordable Online Biology Courses for Everyone, allowing students to pick a course that best suits their needs. The great thing about these courses is that they are affordable so almost everyone can access them despite having different budgets.',
        "Moreover, they provide an excellent learning experience that is more flexible than traditional classrooms. With this you have the freedom to set your own pace and learn at your own time and convenience. I would absolutely recommend Probuddy Education if you're searching for reliable online Biology courses!",
      ],
    },
  },
  {
    id: 'french',
    title: 'French',
    description:
      'Take French Classes Online and Learn from the Comfort of Your Home | Probuddy Education',
    heroImage:
      'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675081412/probuddy/static/NewEducation/CategoryPage/FrenchPage/image1_bhdeb3.png',

    section1: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675081416/probuddy/static/NewEducation/CategoryPage/FrenchPage/image2_sdnhes.png',
      heading: 'What Is Online French Language Learning',
      description: [
        'Online French language learning refers to learning the French language using online resources such as websites, apps, and virtual classrooms.',
        'Probuddy.io is one such platform that offers French language learning services to individuals worldwide. With Probuddy, you can access many resources, including online classes with experienced and certified French tutors, interactive language learning materials, and tools to track your progress.',
        "The online platform provides a convenient and flexible way to learn French from anywhere and anytime, making it accessible to students of all ages and levels. Whether you're a beginner looking to learn the basics or an advanced learner seeking to refine your speaking and writing skills, Probuddy offers an engaging and personalized approach to French language learning.",
      ],
      linkText: 'Book A Class Now',
    },
    becomeSeller: 'Are you a French Tutor?',

    section2: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675081420/probuddy/static/NewEducation/CategoryPage/FrenchPage/image3_do1q6t.png',
      heading: 'Advantages of Learning French Online',
      description: [
        'Learning French online with Probuddy.io offers several advantages over traditional classroom-based learning. Firstly, it provides a high degree of flexibility, allowing students to study at their own pace and schedule. Online resources are available 24/7, making it possible to study anytime, anywhere. Secondly, online French language learning provides access to various interactive materials, multimedia resources, and virtual classes with experienced tutors. This can lead to a more engaging and personalized learning experience.',
        "Furthermore, online learning eliminates the need for travel, saving time and money. Finally, with Probuddy.io's progress-tracking tools and resources, students can monitor their progress and receive immediate feedback, leading to a more efficient learning experience. Overall, online French language learning with Probuddy.io offers a convenient, flexible, and effective way to learn the language.",
      ],
      linkText: 'Explore Our Listings',
    },

    exercise: {
      productTitle: 'Popular French Listings in your area',
      title: 'Private or Group French Classes Offered by Our Pros',
    },

    classesNearYou: {
      title: 'Benefits of Taking French Classes Onliney',
      description: [
        'Taking French classes online with Probuddy.io provides several benefits for students looking to learn the language. Firstly, online classes offer a high degree of flexibility, allowing students to learn at their own pace and schedule, with resources available 24/7. Secondly, online classes provide access to experienced and certified French tutors who can tailor lessons to the individual needs and goals of the student.',
        'Additionally, the virtual classroom environment provides a dynamic and interactive learning experience, with the use of multimedia resources and tools to track progress. Furthermore, online classes eliminate the need for travel, saving time and money. Finally, Probuddy.io provides a supportive and collaborative community for students to connect and share their experiences, leading to a more enjoyable and social learning experience. Taking French classes online with Probuddy.io offers a convenient, flexible, and effective way to learn the language.',
      ],
    },

    instructors: {
      title: 'Top-Rated French Coaches & Instructors',
      description:
        "Discover French classes and coaches near you. Learn from the best instructors in your area. Probuddy gives you access to your city's most elite coaches who live in your neighborhood.",
    },

    featured: {
      title: 'How Can French Help in Your Career?',
      description: [
        'French is a valuable field that can provide numerous career opportunities and enhance professional development. A strong foundation in French can lead to careers in scientific and medical fields, including research and development, environmental science, forensics, and the pharmaceutical industry.',
        'French also helps develop critical thinking and analytical skills, problem-solving abilities, and technical proficiency, which are highly sought after in various industries. Additionally, taking French classes can provide valuable knowledge and skills that can be applied in many different aspects of life and can help individuals reach their full potential, both personally and professionally.',
        'In conclusion, studying French can provide a solid foundation for a successful career and provide individuals with valuable skills and knowledge that can be applied in numerous fields.',
      ],
    },

    topRatedClasses: {
      title: 'Flexible and Affordable Online Biology Courses for Everyone',
      description: [
        'If you are looking for quality, yet affordable Biology courses that can help you improve your language skills, then Probuddy Education is the perfect option! They offer Flexible and Affordable Online Biology Courses for Everyone, allowing students to pick a course that best suits their needs. The great thing about these courses is that they are affordable so almost everyone can access them despite having different budgets.',
        "Moreover, they provide an excellent learning experience that is more flexible than traditional classrooms. With this you have the freedom to set your own pace and learn at your own time and convenience. I would absolutely recommend Probuddy Education if you're searching for reliable online Biology courses!",
      ],
    },
  },
  {
    id: 'german',
    title: 'German',
    description:
      'Take French Classes Online and Learn from the Comfort of Your Home | Probuddy Education',
    heroImage:
      'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675336588/probuddy/static/NewEducation/CategoryPage/German/pexels-adriana-gonzaga-9318518_vo6vha.jpg',
    section1: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675336592/probuddy/static/NewEducation/CategoryPage/German/pexels-vlada-karpovich-4050315_fxsdqk.jpg',
      heading:
        "Unlock Your German Language Potential with Probuddy Education's Online German Classes!",
      description: [
        "Are you looking to improve your German language skills? Do you want to study German in a flexible and convenient way? If so, then Probuddy Education's Online German Classes are perfect for you! We offer high-quality, results-driven German courses online that cater to learners of all levels and learning styles. Whether you're a complete beginner or an advanced speaker, our German classes online will help you reach your goals.",
      ],
      linkText: 'Book A Class Now',
    },
    becomeSeller: 'Are you a German Tutor?',

    section2: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675336583/probuddy/static/NewEducation/CategoryPage/German/pexels-andrea-piacquadio-838413_g5zjw4.jpg',
      heading:
        'Take Your German to the Next Level with Our Convenient, High-Quality Online Classes!',
      description: [
        "At Probuddy Education, we understand that not everyone has the time or opportunity to attend in-person German classes. That's why we offer German courses online that are accessible, convenient, and flexible. With our German classes online, you'll be able to study German from anywhere, at any time, and at your own pace. You'll never have to worry about scheduling conflicts, traffic, or finding time to attend in-person classes.",
      ],
      linkText: 'Explore Our Listings',
    },

    exercise: {
      productTitle: 'Popular German Listings in your area',
      title: 'Private or Group German Classes Offered by Our Pros',
    },

    classesNearYou: {
      title: 'German Courses Online That Fit Your Schedule and Learning Style!',
      description: [
        "Our German classes online are designed to cater to your individual needs and learning style. Whether you're a visual, auditory, or kinesthetic learner, our German courses online are designed to suit your learning style. We also offer flexible scheduling options, so you can choose a schedule that works best for you. Whether you're a busy professional, a student, or anyone in between, our German classes online are a great option for anyone looking to improve their German language skills.",
      ],
    },

    instructors: {
      title: 'Top-Rated German Coaches & Instructors',
      description:
        "Discover German classes and coaches near you. Learn from the best instructors in your area. Probuddy gives you access to your city's most elite coaches who live in your neighborhood.",
    },

    featured: {
      title: 'German Classes Online That Deliver Results!',
      description: [
        "At Probuddy Education, we're committed to delivering results for our students. Our German classes online are taught by experienced and qualified native German speakers, who will help you improve your German language skills in a supportive and engaging learning environment. You'll be able to interact with your teacher and classmates, ask questions, and receive personalized feedback on your progress. Our German classes online are designed to help you achieve your goals and reach fluency in German.",
      ],
    },

    topRatedClasses: {
      title: 'Enroll Now and Join Our Community of German Language Learners!',
      description: [
        "Enrolling in Probuddy Education's German classes online is easy and straightforward. Simply choose the German classes online that best suit your needs and goals, and enroll today. You'll join a supportive and engaging community of German language learners, who are all working towards the same goal - fluency in German.",
        "At Probuddy Education, we believe that learning a new language should be fun and enjoyable, not stressful or overwhelming. That's why our German classes online are designed to be engaging and interactive, with plenty of opportunities for you to practice and improve your German language skills. You'll have access to a wealth of resources, including audio and video lessons, practice exercises, and interactive activities. You'll also have access to our knowledgeable and supportive teachers, who are always available to help you reach your goals.",
      ],
    },
  },
  {
    id: 'geography',
    title: 'Geography',
    description:
      'Take French Classes Online and Learn from the Comfort of Your Home | Probuddy Education',
    heroImage:
      'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675337426/probuddy/static/NewEducation/CategoryPage/Geography/pexels-pixabay-414916_q0wgso.jpg',
    section1: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675337457/probuddy/static/NewEducation/CategoryPage/Geography/pexels-aaditya-arora-592753_mvbqrs.jpg',
      heading: "Unlock Your Potential with Probuddy Education's Online Geography Courses!",
      description: [
        "Do you have a passion for geography and want to expand your knowledge in the subject? Or, are you looking for a new and engaging way to study geography? If so, then Probuddy Education's Online Geography Courses are perfect for you! We offer high-quality, results-driven geography courses online that cater to learners of all levels and learning styles. Whether you're a beginner or an advanced learner, our online geography courses will help you reach your goals.",
      ],
      linkText: 'Book A Class Now',
    },
    becomeSeller: 'Are you a Geography Tutor?',

    section2: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675337431/probuddy/static/NewEducation/CategoryPage/Geography/pexels-yan-krukau-8618069_xzwzh9.jpg',
      heading:
        'Take Your Geography Knowledge to the Next Level with Our Convenient, High-Quality Online Courses!',
      description: [
        "At Probuddy Education, we understand that not everyone has the time or opportunity to attend in-person geography classes. That's why we offer online geography courses that are accessible, convenient, and flexible. With our online geography courses, you'll be able to study geography from anywhere, at any time, and at your own pace. You'll never have to worry about scheduling conflicts, traffic, or finding time to attend in-person classes.",
      ],
      linkText: 'Explore Our Listings',
    },

    exercise: {
      productTitle: 'Popular Geography Listings in your area',
      title: 'Online Geography Courses That Deliver Results!',
    },

    classesNearYou: {
      title: 'Online Geography Courses That Fit Your Schedule and Learning Style!',
      description: [
        "Our online geography courses are designed to cater to your individual needs and learning style. Whether you're a visual, auditory, or kinesthetic learner, our online geography courses are designed to suit your learning style. We also offer flexible scheduling options, so you can choose a schedule that works best for you. Whether you're a busy professional, a student, or anyone in between, our online geography courses are a great option for anyone looking to improve their geography knowledge.",
      ],
    },

    instructors: {
      title: 'Top-Rated Geography Coaches & Instructors',
      description:
        "Discover Geography classes and coaches near you. Learn from the best instructors in your area. Probuddy gives you access to your city's most elite coaches who live in your neighborhood.",
    },

    featured: {
      title: 'Online Geography Courses That Deliver Results!',
      description: [
        "At Probuddy Education, we're committed to delivering results for our students. Our online geography courses are taught by experienced and qualified geography teachers, who will help you expand your knowledge in the subject in a supportive and engaging learning environment. You'll be able to interact with your teacher and classmates, ask questions, and receive personalized feedback on your progress. Our online geography courses are designed to help you achieve your goals and become an expert in geography.",
      ],
    },

    topRatedClasses: {
      title: 'Enroll Now and Join Our Community of Geography Enthusiasts!',
      description: [
        "Enrolling in Probuddy Education's online geography courses is easy and straightforward. Simply choose the online geography courses that best suit your needs and goals, and enroll today. You'll join a supportive and engaging community of geography enthusiasts, who are all working towards the same goal - becoming an expert in geography.",
        "At Probuddy Education, we believe that learning about geography should be fun and enjoyable, not stressful or overwhelming. That's why our online geography courses are designed to be engaging and interactive, with plenty of opportunities for you to practice and expand your knowledge in the subject. You'll have access to a wealth of resources, including audio and video lessons, practice exercises, and interactive activities.",
      ],
    },
  },
  {
    id: 'physics',
    title: 'Physics',
    description:
      'Take Physics Classes Online and Learn from the Comfort of Your Home | Probuddy Education',
    heroImage:
      'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675415960/probuddy/static/NewEducation/CategoryPage/Physics/pexels-jeshootscom-714698_tqqwmu.jpg',
    section1: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675416041/probuddy/static/NewEducation/CategoryPage/Physics/pexels-pixabay-60582_tef2fb.jpg',

      heading: 'Master Physics Online: Explore the Fundamentals and Beyond',
      description: [
        "Probuddy offers online physics classes that allow you to learn at your own pace. Whether you're a student looking to improve your understanding of basic physics concepts or a professional looking to expand your knowledge, our expert instructors are here to help. Our interactive and engaging classes cover a wide range of topics in physics, from mechanics and thermodynamics to quantum mechanics and cosmology.",
        "With Probuddy, you'll have access to a variety of resources, including video lectures, interactive simulations, and quizzes to help you solidify your understanding of the material. Our classes are designed to be flexible, so you can learn on your schedule, whether you're a busy student or working professional. Sign up with Probuddy and start mastering the principles of physics today!",
      ],
      linkText: 'Book A Class Now',
    },
    becomeSeller: 'Are you a Physics Tutor?',

    section2: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675415963/probuddy/static/NewEducation/CategoryPage/Physics/pexels-yan-krukau-8197497_ypstxr.jpg',
      heading: 'Select classes instructed by industry professionals',
      description: [
        'Probuddy Education instructors are experts in their field and bring a wealth of practical knowledge to the classroom. This means that students will be learning from individuals who have firsthand experience in the subject matter being taught, providing a valuable and unique learning experience. Sign up with Probuddy to learn from industry professionals and gain the skills and knowledge needed to succeed in your field.',
      ],
      linkText: 'Explore Our Listings',
    },

    exercise: {
      productTitle: 'Popular Physics Listings in your area',
      title: 'Online Physics Courses That Deliver Results!',
    },

    classesNearYou: {
      title: 'Online Physics Learning: Sign up for Courses and Start Advancing',
      description: [
        'If you’re ready to take your physics studies to the next level, look no further than Probuddy Education! With online courses in physics, students can sign up and start advancing their knowledge on a wide range of topics anytime, anywhere.',
        "Whether you’re preparing for graduate school or just looking to broaden your understanding of the physical world, there are plenty of interactive course offerings that you can use to supplement your education. Learn more about Newton's laws, wave mechanics and quantum physics today at Probuddy!",
      ],
    },

    instructors: {
      title: 'Top-Rated Physics Coaches & Instructors',
      description:
        "Discover Physics classes and coaches near you. Learn from the best instructors in your area. Probuddy gives you access to your city's most elite coaches who live in your neighborhood.",
    },

    featured: {
      title: 'Featured Online Physics Instructors: Advance Your Skills and Knowledge',
      description: [
        'For the most effective way to advance your Physics knowledge, look no further than Probuddy Education’s Featured Online Physics Instructors! Being able to access instructors from around the world makes it possible for you to find a tutor that is perfectly attuned to your individual learning style and needs.',
        'With vibrant online classrooms and comprehensive curriculum, this is an ideal opportunity for you to engage in interactive sessions with brilliant professionals in the field of physics. Get ready to take on the world – your physics skills will be unstoppable when you join today!',
      ],
    },

    topRatedClasses: {
      title: 'Expert-Endorsed Online Physics Courses',
      description: [
        'Are you looking for online physics courses that are endorsed by experts? Probuddy Education has the perfect solution. Expert-Endorsed Online Physics Courses is a collection of courses designed to help people understand physics and all its complexities. They provide thorough explanations with example problems and easy to follow learning materials so it’s easy to stay on track.',
        'You can also look forward to mentorship or feedback at any stage which will significantly reduce the learning curve. With these Expert-Endorsed Online Physics Courses, you’ll be able to learn in comfort while still having an effective experience and grasping challenging concepts in no time!',
      ],
    },
  },
  {
    id: 'engineering',
    title: 'Engineering',
    description:
      'Take Engineering Classes Online and Learn from the Comfort of Your Home | Probuddy Education',
    heroImage:
      'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675399911/probuddy/static/NewEducation/CategoryPage/Engineering/pexels-pixabay-256381_dguxix.jpg',
    section1: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675399916/probuddy/static/NewEducation/CategoryPage/Engineering/pexels-pixabay-414579_xaonas.jpg',
      heading: 'Probuddy Education - Your Destination for Online Engineering Classes!',
      description: [
        "Looking for a convenient and effective way to learn engineering? You've come to the right place!",
        "We offer a range of online engineering classes that you can enroll in from the comfort of your own home. Our courses are designed to help you achieve your learning goals, whether you're just starting out or you're looking to advance your career.",
      ],
      linkText: 'Book A Class Now',
    },
    becomeSeller: 'Are you a Engineering Tutor?',

    section2: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675399924/probuddy/static/NewEducation/CategoryPage/Engineering/pexels-thirdman-8482822_li1nfh.jpg',
      heading: 'Get the Education You Need to Succeed as an Engineer',
      description: [
        "Our engineering course online is designed to give you the skills and knowledge you need to succeed in your chosen career. Whether you're interested in civil engineering, electrical engineering, or mechanical engineering, our courses will give you the foundation you need to succeed.",
      ],
      linkText: 'Explore Our Listings',
    },

    exercise: {
      productTitle: 'Popular Geography Listings in your area',
      title: 'Online Geography Courses That Deliver Results!',
    },

    classesNearYou: {
      title: 'Engineer Your Future with Online Engineering Classes',
      description: [
        "Online engineering classes offer you the flexibility to study at your own pace and on your own schedule. You'll have access to world-class engineering instruction, and you'll be able to learn from experienced instructors who have years of industry experience.",
      ],
    },

    instructors: {
      title: 'Advance Your Career with Online Engineering Classes',
      description: [
        "Whether you're looking to advance your career or you're just starting out, our online engineering classes will help you achieve your goals. You'll gain the skills and knowledge you need to succeed, and you'll be able to take your career to the next level.",
      ],
    },

    featured: {
      title: 'Join the Probuddy Community and Connect with Other Engineers',
      description: [
        "When you enroll in our online engineering classes, you'll become part of a community of students and instructors who are passionate about engineering. You'll be able to connect with other students, share your experiences, and get the support you need to succeed.",
      ],
    },

    topRatedClasses: {
      title: 'Get Started Today and Take Your Career to the Next Level',
      description: [
        "So what are you waiting for? Get started today and take your career to the next level with Probuddy Education! Our online engineering classes are designed to help you achieve your goals, and you'll be able to enroll from the comfort of your own home.",
        'Start learning engineering today and join the Probuddy community!',
      ],
    },
  },
  {
    id: 'academic-writing',
    title: 'Academic Writing',
    description:
      'Take Academic Writing Classes Online and Learn from the Comfort of Your Home | Probuddy Education',
    heroImage:
      'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675401169/probuddy/static/NewEducation/CategoryPage/Academic%20Writing/pexels-lisa-fotios-851213_vxr74e.jpg',
    section1: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675401159/probuddy/static/NewEducation/CategoryPage/Academic%20Writing/pexels-vlada-karpovich-4050350_tushc5.jpg',
      heading: 'Welcome to Probuddy Education - Your Home for Academic Writing Courses!',
      description: [
        'Do you want to improve your academic writing skills? Are you looking for an effective and convenient way to learn? Look no further than Probuddy Education!',
        "We offer a range of academic writing courses that you can enroll in from the comfort of your own home. Our courses are designed to help you achieve your writing goals, whether you're just starting out or you're looking to advance your skills.",
      ],
      linkText: 'Book A Class Now',
    },
    becomeSeller: 'Are you an Academic Writing Tutor?',

    section2: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675401161/probuddy/static/NewEducation/CategoryPage/Academic%20Writing/pexels-andrea-piacquadio-3783520_xdlsf9.jpg',
      heading: 'Master the Art of Academic Writing with Probuddy Education',
      description: [
        "Our courses on academic writing are designed to give you the skills and knowledge you need to succeed. Whether you're looking to improve your writing for school, or you're looking to advance your career, our courses will give you the foundation you need to succeed.",
      ],
      linkText: 'Explore Our Listings',
    },

    exercise: {
      productTitle: 'Popular Geography Listings in your area',
      title: 'Online Geography Courses That Deliver Results!',
    },

    classesNearYou: {
      heading: 'Achieve Your Writing Goals with an Online Academic Writing Course',
      description: [
        "Our academic writing online course offers you the flexibility to study at your own pace and on your own schedule. You'll have access to world-class writing instruction, and you'll be able to learn from experienced instructors who have years of experience.",
      ],
    },

    instructors: {
      title: 'English Academic Writing Course - Learn from the Best',
      description: [
        "If you're looking to improve your English academic writing skills, we have just the course for you! Our English academic writing course is designed to give you the tools you need to write clear, concise, and effective academic papers.",
      ],
    },

    featured: {
      title: 'Take Your Writing Skills to the Next Level with Probuddy Education',
      description: [
        "Whether you're just starting out or you're looking to advance your skills, our academic writing courses will help you achieve your goals. You'll gain the skills and knowledge you need to succeed, and you'll be able to take your writing to the next level.",
      ],
    },

    topRatedClasses: {
      title: 'Get Started Today and Take Your Writing Skills to the Next Level',
      description: [
        "So what are you waiting for? Get started today and take your writing skills to the next level with Probuddy Education! Our academic writing courses are designed to help you achieve your goals, and you'll be able to enroll from the comfort of your own home.",
        'Start learning how to write academic papers today and join the Probuddy community!',
      ],
    },
  },
  {
    id: 'development',
    title: 'Development',
    description:
      'Take Development Classes Online and Learn from the Comfort of Your Home | Probuddy Education',
    heroImage:
      'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675402943/probuddy/static/NewEducation/CategoryPage/Development/pexels-pixabay-270348_vdph3w.jpg',
    section1: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675402983/probuddy/static/NewEducation/CategoryPage/Development/pexels-cottonbro-studio-5473298_gs9txz.jpg',
      heading: 'Web Development Courses with Probuddy Education',
      description: [
        "Discover the world of web development and launch a career in tech with Probuddy Education's online development courses. Whether you're a complete beginner or looking to expand your skills, our web developer course will equip you with the knowledge and skills you need to succeed.",
      ],
      linkText: 'Book A Class Now',
    },
    becomeSeller: 'Are you an Academic Development Tutor?',

    section2: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675402941/probuddy/static/NewEducation/CategoryPage/Development/pexels-christina-morillo-1181263_omrrsn.jpg',
      heading: 'Learn from Experts in the Field',
      description: [
        'All of our online development courses are taught by industry experts, who have years of experience in the web development field. They bring their real-world experience to the course, ensuring that you learn the most up-to-date skills and techniques. Our expert instructors are passionate about web development, and are dedicated to helping you achieve your goals.',
      ],
      linkText: 'Explore Our Listings',
    },

    exercise: {
      productTitle: 'Popular Geography Listings in your area',
      title: 'Online Geography Courses That Deliver Results!',
    },

    classesNearYou: {
      title: 'Online Web Development Courses',
      description: [
        "Our online development courses are designed to be flexible and accessible, allowing you to study at your own pace, from anywhere in the world. With a range of courses to choose from, including website development, web development bootcamp, and more, you'll be able to find the perfect course to suit your needs and career aspirations.",
      ],
    },

    instructors: {
      title: 'Build Your Portfolio with Practical Projects',
      description: [
        "Our courses are designed to be practical and hands-on, allowing you to build a portfolio of projects as you progress through the course. You'll learn by doing, and you'll be able to showcase your skills and knowledge to potential employers when you complete the course. Whether you're looking to become a freelance web developer or join a tech company, a strong portfolio is essential.",
      ],
    },

    featured: {
      title: 'Support and Guidance Every Step of the Way',
      description: [
        "At Probuddy Education, we understand that learning a new skill can be challenging, which is why we're here to provide you with support and guidance every step of the way. Our dedicated student support team is available to answer any questions you may have, and our online learning platform is designed to make learning as easy and accessible as possible.",
      ],
    },

    topRatedClasses: {
      title: 'Start Your Journey to a Career in Tech Today',
      description: [
        "If you're ready to start your journey to a career in tech, enroll in one of Probuddy Education's online development courses today. Our courses are designed to be accessible and affordable, making it possible for anyone to learn web development, regardless of their background or experience. With Probuddy Education, you'll have the knowledge and skills you need to succeed in a fast-growing and exciting industry.",
        "Take the first step towards a career in tech and enroll in one of Probuddy Education's web development courses today!",
      ],
    },
  },
  {
    id: 'business',
    title: 'Business',
    description:
      'Take Business Classes Online and Learn from the Comfort of Your Home | Probuddy Education',
    heroImage:
      'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675403614/probuddy/static/NewEducation/CategoryPage/Bussiness/pexels-andrea-piacquadio-3756679_zjmxhm.jpg',
    section1: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675403608/probuddy/static/NewEducation/CategoryPage/Bussiness/pexels-andrea-piacquadio-3769021_lmpz5l.jpg',
      heading: 'Business courses online with Probuddy Education',
      description: [
        "Welcome to the business course category at Probuddy Education! Here, you will find a wide range of online courses designed to help you develop the skills and knowledge you need to succeed in the business world. Whether you're an aspiring entrepreneur, a seasoned professional, or simply looking to upskill, our courses are designed to meet your needs.",
      ],
      linkText: 'Book A Class Now',
    },
    becomeSeller: 'Are you a Business Expert?',

    section2: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675403606/probuddy/static/NewEducation/CategoryPage/Bussiness/pexels-andrew-neel-2312369_h1vamg.jpg',
      heading: 'Overview of Business Courses Online',
      description: [
        'Our business courses online cover a variety of topics, including entrepreneurship, financial management, marketing and sales, and more. Each course is designed to give you a comprehensive understanding of the subject and provide you with the skills and knowledge you need to succeed in the business world.',
      ],
      linkText: 'Explore Our Listings',
    },

    exercise: {
      productTitle: 'Popular Business Listings in your area',
      title: 'Online Business Courses That Deliver Results!',
    },

    classesNearYou: {
      title: 'Business Online Courses for Beginners',
      description: [
        "If you're just starting out in the business world, our beginner-level courses are the perfect place to start. These courses provide a foundation in the essential concepts and skills you need to get started, and they're taught by experienced instructors who are passionate about helping you succeed.",
      ],
    },

    instructors: {
      title: 'Advance Business Online Courses for Professionals',
      description: [
        "For professionals looking to take their business knowledge and skills to the next level, our advanced courses are the perfect solution. These courses cover more complex topics and provide a deeper understanding of the business world, so you can stay ahead of the curve and stay competitive in today's fast-paced market.",
      ],
    },

    featured: {
      title: 'Top-Rated Business Online Classes',
      description: [
        'At Probuddy Education, we take pride in offering high-quality online courses that are rated highly by our students. Our business courses are no exception, and they have received rave reviews from students all over the world.',
      ],
    },

    topRatedClasses: {
      title: 'Why Choose Probuddy Education for Business Courses',
      description: [
        "At Probuddy Education, we are dedicated to providing the best online education experience possible. Our courses are affordable, flexible, and taught by experienced instructors who are passionate about helping you succeed. Whether you're just starting out or you're a seasoned professional, we have the courses you need to take your business knowledge and skills to the next level.",
        'Enroll in one of our business courses today and start your journey to success!',
      ],
    },
  },
  {
    id: 'language-learning',
    title: 'Language Learning',
    description:
      'Take Language Learning Classes Online and Learn from the Comfort of Your Home | Probuddy Education',
    heroImage:
      'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675404121/probuddy/static/NewEducation/CategoryPage/Language%20Learning/pexels-pixabay-247819_di7gvq.jpg',
    section1: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675404113/probuddy/static/NewEducation/CategoryPage/Language%20Learning/pexels-tima-miroshnichenko-5427868_jsjyp0.jpg',
      heading: 'Language Learning Courses Online with Probuddy Education',
      description: [
        "Welcome to the language learning course category at Probuddy Education! Here, you'll find a variety of language courses designed to help you achieve your language learning goals, whether you're a beginner or an intermediate learner. Our courses are taught by experienced and knowledgeable instructors who are passionate about helping students achieve fluency.",
        "You'll have access to a wide range of resources, including interactive lessons, quizzes, and multimedia materials, to help you improve your language skills. With flexible scheduling and a supportive community of peers and instructors, you'll have everything you need to succeed in your language learning journey. Join us today and start speaking your new language with confidence!",
      ],
      linkText: 'Book A Class Now',
    },
    becomeSeller: 'Are you a Language Expert?',

    section2: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675403606/probuddy/static/NewEducation/CategoryPage/Bussiness/pexels-andrew-neel-2312369_h1vamg.jpg',
      heading: 'Overview of Business Courses Online',
      description: [
        'Language Learning Courses by Probuddy are an excellent choice for any aspiring language learner. Through online classes, learners can take their pick of over 15 different languages, with the option to learn either in a group or one-on-one. Language Learning Courses by Probuddy offer professional and engaging instruction designed to be both comprehensive and engaging.',
        'From complete beginners to experienced speakers wanting to brush up on their language skills, Language Learning Courses provide the perfect fit for a variety of needs. With motivating instructors and lifetime access so you can go back and review anytime, Language Learning Courses provide an unbeatable learning experience that gives anyone the tools they need to become proficient in their chosen language.',
      ],
      linkText: 'Explore Our Listings',
    },

    exercise: {
      productTitle: 'Popular Language Learning Listings in your area',
      title: 'Online Language Learning Courses That Deliver Results!',
    },

    classesNearYou: {
      title: 'Language Learning Online Courses for Beginners',
      description: [
        'Language Learning Courses by Probuddy offer a comprehensive range of online options for beginners. They bring together the best teaching methods and interactive tools, combining practical instruction with engaging activities and conversation opportunities.',
        'This program is designed to improve users’ grammar and pronunciation skills, helping them gain confidence in speaking their new language. Language Learning Courses by Probuddy is an ideal option for those looking for a convenient way to learn a language—all from the comfort of home.',
        'Whether you are just starting out or already have some basics, Language Learning Courses by Probuddy will assist you in reaching your language-learning goals quickly and successfully.',
      ],
    },

    instructors: {
      title: 'Advance Language Learning Online Courses for Professionals',
      description: [
        'Language learning online courses offered by Probuddy are the perfect solution for busy professionals looking to advance their language skills. With a virtual classroom, personalized learning plans, and expert tutors, these courses provide an accessible and comprehensive learning experience.',
        'Language learners can pick up new words, perfect their grammar, and gain confidence interacting in the chosen language - all on a convenient platform designed for maximum versatility. Plus, Probuddy also provides study materials such as audio recordings and quizzes making language learning more intuitive than ever before.',
      ],
    },

    featured: {
      title: 'Top-Rated Language Learning Online Classes',
      description: [
        "If you're looking to expand your Language Learning skills, then Probuddy Language Learning Courses offer the perfect solution. From Spanish to Arabic to Chinese, they have a variety of courses and programs which are suited for any learning level.",
        "These courses strive to give their students a better understanding of each language's culture and help with mastering conversational fluency. Plus, the online format allows for flexibility when it comes to scheduling, allowing you to easily fit the course into your lifestyle. With user-friendly tools such as AI Language Coaches and an interactive virtual world, these Language Learning courses want to make learning Language as enjoyable as possible for their students.",
      ],
    },

    topRatedClasses: {
      title: 'Why Choose Probuddy Education for Language Learning Courses',
      description: [
        "At Probuddy Education, we are dedicated to providing the best online education experience possible. Our courses are affordable, flexible, and taught by experienced instructors who are passionate about helping you succeed. Whether you're just starting out or you're a seasoned professional, we have the courses you need to take your business knowledge and skills to the next level.",
        'Enroll in one of our Language Learning courses today and start your journey to success!',
      ],
    },
  },
  {
    id: 'finance-and-accounting',
    title: 'Finance & Accounting',
    description:
      'Take Finance & Accounting Classes Online and Learn from the Comfort of Your Home | Probuddy Education',
    heroImage:
      'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675404739/probuddy/static/NewEducation/CategoryPage/Finance%20and%20Acountings/pexels-tima-miroshnichenko-6694543_p298ql.jpg',
    section1: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675404733/probuddy/static/NewEducation/CategoryPage/Finance%20and%20Acountings/pexels-mikhail-nilov-7681681_pjalqn.jpg',
      heading: 'Finance & Accounting Courses Online with Probuddy Education',
      description: [
        'Welcome to the Finance & Accounting course category at Probuddy Education! This category is designed to provide comprehensive and in-depth training in the field of finance and accounting.',
        'Our courses are designed to cater to students, professionals, and individuals looking to build a career in finance or accounting, or those who simply wish to expand their knowledge in these areas. Our expert instructors have years of experience in the industry and provide hands-on training using real-life examples and case studies.',
        'With our state-of-the-art online learning platform, you can access our courses anytime, anywhere, and at your own pace. Whether you are a beginner or an experienced professional, our courses are tailored to meet your needs and help you achieve your goals. So, join us now and become a finance and accounting expert!',
      ],
      linkText: 'Book A Class Now',
    },
    becomeSeller: 'Are you a Finance & Accounting?',

    section2: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675404729/probuddy/static/NewEducation/CategoryPage/Finance%20and%20Acountings/pexels-tima-miroshnichenko-6694919_nq0jnd.jpg',
      heading: 'Overview of Online Finance & Accounting Courses',
      description: [
        'Learning about finance and accounting can feel intimidating and make you break a sweat. However, thanks to the availability of various online Finance & Accounting Courses from Probuddy, it has never been easier to understand the basics of these important concepts.',
        'These courses provide a range of lectures, video tutorials and real-world examples that can make even complex topics easy to comprehend. In addition, students also have the option of customizing their curriculum as per their requirements by hand-picking certain courses which they find more relevant to their career objectives.',
        'Once finished with the course, learners will gain valuable skills such as data analysis, budgeting, spread sheet preparation and other financial modeling techniques which can be applied in any professional setting.',
      ],
      linkText: 'Explore Our Listings',
    },

    exercise: {
      productTitle: 'Popular finance and accounting Listings in your area',
      title: 'Online finance and accounting Courses That Deliver Results!',
    },

    classesNearYou: {
      title: 'Online Finance & Accounting Courses for Novice Learners',
      description: [
        'Are you a novice learner trying to learn more about finance and accounting? Probuddy has the perfect online courses for you. Our Finance & Accounting Courses are engaging and comprehensive, giving learners the chance to gain new skills backed with real-life examples.',
        'The courses take you through the fundamentals of understanding financial statements, forecasting budgets, auditing performance, and much more. So whatever your needs or interests may be in Finance &/ or Accounting - our experts have got you covered! Check out the expertise offered through our Finance & Accounting Courses today.',
      ],
    },

    instructors: {
      title: 'Online Finance & Accounting Courses for Experienced Practitioners',
      description: [
        "If you're an experienced practitioner looking to increase your financial understanding and build new skills, Probuddy's Online Finance & Accounting Courses are the perfect fit for you!",
        'Our courses are designed for individuals who already have some knowledge of finance and accounting, and include topics like corporate finance, international finance, auditing and accounting information systems.',
        "With our flexible structure and industry-leading content, you'll be able to become a finance guru in no time at all. Take the initiative to become even better in the field with Probuddy’s Finance & Accounting Courses today!",
      ],
    },

    featured: {
      title: 'Highly Regarded Online Finance & Accounting Courses',
      description: [
        "Probuddy offers a range of highly regarded online finance and accounting courses, setting the benchmark for excellence in financial education. Whether you're a beginner looking to delve into basic concepts such as budgeting & savings or an experienced professional aiming to hone your technical analysis skills, our courses are specially crafted to help you gain a deep understanding of Finance & Accounting fundamentals.",
        'Probuddy Finance & Accounting courses come with comprehensive videos, resources, assessments and downloadable materials to help ensure that you learn at your own pace and effectively retain the knowledge gained.',
        'Our expert instructors are on hand to provide guidance throughout the course and assistance at each stage. If Finance & Accounting is your domain, don’t miss out on these internationally acclaimed Finance & Accounting Courses by Probuddy!',
      ],
    },

    topRatedClasses: {
      title: 'The Benefits of Selecting Probuddy Education for Your Finance & Accounting Studies',
      description: [
        'Probuddy Education offers an array of Finance and Accounting courses that are convenient, comprehensive, and cost-effective. Their experienced educators provide resources for participating students to make learning about Finance & Accounting easier and more accessible than ever before.',
        'You can select from full degree programs or individual classes such as Introduction to Finance & Accounting or Advanced Financial Statement Analysis. In addition to providing practical study materials including lectures, slideshows and webinars, Probuddy also provides one-on-one tutoring sessions with industry professionals when students need extra help.',
        'With the right support system in place and many flexible options to choose from, Probuddy Education is your go-to source for Finance & Accounting studies.',
      ],
    },
  },
  {
    id: 'it-and-software',
    title: 'IT & software',
    description:
      'Take IT & software Classes Online and Learn from the Comfort of Your Home | Probuddy Education',
    heroImage:
      'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675405326/probuddy/static/NewEducation/CategoryPage/IT%20and%20Software/pexels-pixabay-247791_mlvfu7.jpg',
    section1: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675405334/probuddy/static/NewEducation/CategoryPage/IT%20and%20Software/pexels-negative-space-169573_sgoqd7.jpg',
      heading: 'IT & Software Courses Online with Probuddy Education',
      description: [
        'Welcome to the IT & Software course category at Probuddy Education! This category offers a wide range of courses for individuals looking to enhance their skills in the field of IT and software. Our expert instructors provide hands-on training in the latest technologies and techniques.',
        'With our user-friendly online platform, you can access our courses from anywhere, at any time, and at your own pace. Whether you are a beginner or a professional, we have courses to meet your needs and help you reach your goals. Join us now to become a skilled IT and software professional!',
      ],
      linkText: 'Book A Class Now',
    },
    becomeSeller: 'Are you an IT & Software?',

    section2: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675405338/probuddy/static/NewEducation/CategoryPage/IT%20and%20Software/pexels-mizuno-k-12903147_crq3rp.jpg',
      heading: 'A Summary of the Online IT & Software Courses Offered',
      description: [
        'IT & Software Courses by Probuddy offer a wide variety of online courses to help IT and software professionals advance their skillset. Comprehensive courses are available for web design and development, programming, IT security, database management, computer networking, data engineering, and IT project management.',
        'Each course is designed to provide students with expert insight from top instructors in the field so that they can feel confident that they are getting high-caliber instruction from experienced professionals.',
      ],
      linkText: 'Explore Our Listings',
    },

    exercise: {
      productTitle: 'Popular IT & Software Listings in your area',
      title: 'Online IT & Software Courses That Deliver Results!',
    },

    classesNearYou: {
      title: 'Online IT & Software Courses for those Just Starting Out',
      description: [
        'IT & Software Courses by Probuddy have made it easier than ever for those just starting out to gain IT and software development knowledge. By offering a selection of online courses, they are ideal for anyone who is looking to learn IT fundamentals or more advanced concepts from the comfort of their own home.',
        'With courses designed to fit any skill level and budget, Probuddy provides students with the resources needed to join the IT industry, master tech skills and pursue advanced IT & software certifications. With experienced instructors and round-the-clock support, Probuddy courses provide an ideal place for IT & software rookies to begin making progress towards their IT career goals.',
      ],
    },

    instructors: {
      title: 'Advanced IT & Software Online Courses for Experienced Individuals',
      description: [
        'For experienced IT and software individuals looking for comprehensive and reliable online courses to help advance their careers, Probuddy provides IT & Software Courses tailored towards experienced professionals.',
        'With interactive learning modules, instructor support, rigorous assessments, and certification opportunities available through the course experience, IT and software enthusiasts can develop their knowledge base with the necessary foundations they need to master the material.',
        'Often conducted over the comfort of one’s home or office using their computer, Probuddy’s IT & Software Courses are convenient, effective and perfect for IT experts looking to improve or even expand upon their qualifications.',
      ],
    },

    featured: {
      title: 'Highly Regarded Online IT & Software Classes',
      description: [
        'IT and software courses offered by Probuddy are highly regarded for providing expertise from IT professionals from around the globe. With an abundance of options to choose from, aspiring IT/software developers can select both beginner and advanced IT & software courses.',
        "Amongst their training programs, IT professionals can find class topics ranging from software engineering, coding fundamentals, app development, IT architecture, cloud computing and more. By learning these skills under a team of professionals in the IT field, individuals looking to build a career in IT & software will have access to all the support they need with Probuddy's online classes.",
      ],
    },

    topRatedClasses: {
      title: 'The Advantages of Choosing Probuddy Education for Your IT & Software Studies',
      description: [
        'Probuddy Education offers comprehensive IT & Software Courses to help learners master and excel in relevant IT & Software disciplines. For aspiring and current IT professionals, Probuddy Education has certified IT & Software courses that provide in-depth and well-rounded knowledge of various IT & Software models as well as the latest industry trends.',
        'Their strong curriculum and diverse range of IT & Software topics make this educational institute a popular choice among students looking to pursue or enhance their IT & Software skillset. In addition, numerous support services are offered by Probuddy Education to enable students to effectively manage the tasks associated with completing their IT & Software coursework. With experienced instructors and highly interactive learning modules, the advantages of choosing Probuddy Education for your IT & Software studies can be seen quickly and easily.',
      ],
    },
  },
  {
    id: 'office-productivity',
    title: 'Office Productivity',
    description:
      'Take Office Productivity Classes Online and Learn from the Comfort of Your Home | Probuddy Education',
    heroImage:
      'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675406282/probuddy/static/NewEducation/CategoryPage/Office%20Productivity/pexels-cottonbro-studio-4064840_zqvdbc.jpg',
    section1: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675406210/probuddy/static/NewEducation/CategoryPage/Office%20Productivity/pexels-yan-krukau-8837472_djiac8.jpg',
      heading: 'Office Productivity Courses Online with Probuddy Education',
      description: [
        'Welcome to the Office Productivity course category at Probuddy Education! This category is designed to help individuals become more efficient and organized in their professional life. Our courses cover a wide range of office productivity tools and techniques, from time management to project planning.',
        'Our expert instructors provide hands-on training using real-world examples and case studies, ensuring that you can apply what you learn to your own work environment. With our convenient online learning platform, you can access our courses from anywhere, at any time, and at your own pace.',
        "Whether you are looking to improve your personal productivity or boost your team's performance, our courses will help you achieve your goals. Join us now to become a pro in office productivity!",
      ],
      linkText: 'Book A Class Now',
    },
    becomeSeller: 'Are you a Office Productivity Expert?',

    section2: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675406214/probuddy/static/NewEducation/CategoryPage/Office%20Productivity/pexels-cottonbro-studio-4065876_tyzg5e.jpg',
      heading: 'Overview of Office Productivity Courses Online',
      description: [
        "Office Productivity Courses by Probuddy are a great way to get up to date on the latest software and technology being used in the modern workplace. From Microsoft Office Suite to Adobe Premiere Pro, you can quickly become an expert in any number of office tools with comprehensive instruction from Probuddy's experienced instructors. Through their online platform, you'll be guided through all the basics as well as more advanced components of Office productivity and technology use.",
      ],
      linkText: 'Explore Our Listings',
    },

    exercise: {
      productTitle: 'Popular Office Productivity Listings in your area',
      title: 'Online Office Productivity That Deliver Results!',
    },

    classesNearYou: {
      title: 'Office Productivity Online Courses for Beginners',
      description: [
        'Office productivity is an important skill for anyone looking to stay organized and efficient in their work. Fortunately, Office Productivity Courses by Probuddy offer comprehensive programs aimed at guiding new users through a variety of software suites and tools that can help improve organization, efficiency, automation, and more!',
      ],
    },

    instructors: {
      title: 'Advance Office Productivity Online Courses for Professionals',
      description: [
        'Office productivity is a lifesaver for the modern professional, and gaining proficiency in the latest tools is becoming more essential than ever. Probuddy has put together a suite of online Office Productivity courses for professionals which are designed to help users make the most of these tools.',
        'The courses cover topics such as time management, online collaboration and advanced Office functions, providing users with an in-depth look into Office capabilities. Users can also avail of lifetime access to these Office Productivity courses so that they can stay on top of their game and progress in their career with greater ease.',
      ],
    },

    featured: {
      title: 'Top-Rated Office Productivity Online Classes',
      description: [
        "Office productivity can often be an arduous task for many, but with the right course it doesn't have to be. Probuddy offers top-rated Office Productivity Courses with the help of experienced and professional instructors who are there to guide you through the material. These courses will help boost productivity and efficiency in the office, no matter what industry or field you may work in.",
        'With multiple modules structured into each Office Productivity Course from Probuddy, users can easily understand the process behind optimizing their workspace setup for maximum efficiency. Dive into these Office Productivity Courses and discover how you can maximize your work productivity in no time!',
      ],
    },

    topRatedClasses: {
      title: 'Why Choose Probuddy Education for Office Productivity Courses',
      description: [
        'Office productivity courses by Probuddy Education provide a comprehensive approach to boosting workplace efficiency. Through interactive sessions delivered by experienced professionals, learners are taught how to use the Office Suite and various software tools in ways that elevate operational performance.',
        'Probuddy offers personalized coaching for conquering tedious and complex tasks, plus advanced instruction for those who need help rethinking workflows. Their modules provide strategies for organizing workloads, scheduling tasks and leveraging automation technologies to save time and energy.',
        'Combining useful features with an engaging learning experience, you can be sure that Office Productivity Courses by Probuddy will deliver the skills necessary for productive success.',
      ],
    },
  },
  {
    id: 'business-development',
    title: 'Business Development',
    description:
      'Take Business Development Classes Online and Learn from the Comfort of Your Home | Probuddy Education',
    heroImage:
      'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675407915/probuddy/static/NewEducation/CategoryPage/Business%20Development/pexels-fauxels-3184338_jken47.jpg',
    section1: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675407942/probuddy/static/NewEducation/CategoryPage/Business%20Development/pexels-fauxels-3182774_r5ol6y.jpg',
      heading: 'Accelerate Your Business Success: An Online Course in Business Development',
      description: [
        "Are you looking to take your business to the next level? Do you want to grow your skills, stay ahead of the competition, and unlock your full potential as an entrepreneur or business owner? Then it's time to enroll in Probuddy Education's Online Course for Business Development.",
      ],
      linkText: 'Book A Class Now',
    },
    becomeSeller: 'Are you a Business Development Tutor?',

    section2: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675407910/probuddy/static/NewEducation/CategoryPage/Business%20Development/pexels-cottonbro-studio-7437490_nn3qrb.jpg',
      heading: 'Transform Your Business: A Comprehensive Online Course in Business Development',
      description: [
        "With this comprehensive online course, you will transform your business and accelerate your success like never before. Our expert instructors will guide you through the fundamentals of business development and growth, teaching you the key strategies and techniques you need to succeed. Whether you're a seasoned entrepreneur or just starting out, our course will provide you with the knowledge, skills, and confidence you need to achieve your business goals.",
      ],
      linkText: 'Explore Our Listings',
    },

    exercise: {
      productTitle: 'Popular Business Development Listings in your area',
      title: 'Online Business Development That Deliver Results!',
    },

    classesNearYou: {
      title: 'Grow Your Business and Your Skills: An Online Course in Business Development',
      description: [
        "The Online Course for Business Development at Probuddy Education covers everything from the basics of business strategy and marketing to more advanced topics like financial management and team building. You'll learn from a team of experienced business professionals and have access to a wealth of resources, including interactive modules, case studies, and real-world examples. You'll also have the opportunity to network with other business owners and entrepreneurs from around the world, exchanging ideas and insights and growing your professional network.",
      ],
    },

    instructors: {
      title: 'Get Ahead of the Competition: A Results-Driven Online Course in Business Development',
      description: [
        "At Probuddy Education, we believe that anyone can achieve success in business with the right education and support. That's why we've created this comprehensive online course, designed to help you transform your business and reach your full potential. So what are you waiting for? Join the ranks of successful entrepreneurs and enroll in Probuddy Education's Online Course for Business Development today!",
      ],
    },

    featured: {
      title: 'Top-Rated Business Development Online Classes',
      description: [
        "Office productivity can often be an arduous task for many, but with the right course it doesn't have to be. Probuddy offers top-rated Business Development Courses with the help of experienced and professional instructors who are there to guide you through the material. These courses will help boost productivity and efficiency in the office, no matter what industry or field you may work in.",
        'With multiple modules structured into each Business Development Course from Probuddy, users can easily understand the process behind optimizing their workspace setup for maximum efficiency. Dive into these Business Development Courses and discover how you can maximize your work productivity in no time!',
      ],
    },

    topRatedClasses: {
      title: 'Why Choose Probuddy Education for Business Development Courses',
      description: [
        'Office productivity courses by Probuddy Education provide a comprehensive approach to boosting workplace efficiency. Through interactive sessions delivered by experienced professionals, learners are taught how to use the Office Suite and various software tools in ways that elevate operational performance.',
        'Probuddy offers personalized coaching for conquering tedious and complex tasks, plus advanced instruction for those who need help rethinking workflows. Their modules provide strategies for organizing workloads, scheduling tasks and leveraging automation technologies to save time and energy.',
        'Combining useful features with an engaging learning experience, you can be sure that Business Development Courses by Probuddy will deliver the skills necessary for productive success.',
      ],
    },
  },
  {
    id: 'design',
    title: 'Design',
    description:
      'Take Design Classes Online and Learn from the Comfort of Your Home | Probuddy Education',
    heroImage:
      'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675409094/probuddy/static/NewEducation/CategoryPage/Design/pexels-ovan-57690_1_m84apf.jpg',
    section1: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675409049/probuddy/static/NewEducation/CategoryPage/Design/pexels-canva-studio-3153204_1_qiroqo.jpg',
      heading: "Transform Your Design Skills with Probuddy Education's Online Courses",
      description: [
        "If you're looking to enhance your design skills or jumpstart your career in the design industry, Probuddy Education's online courses are the perfect solution. Our courses are designed to provide you with in-depth knowledge and hands-on experience in graphic design, web design, and more. Whether you're a beginner or an experienced designer, our courses are tailored to meet your needs and help you reach your full potential.",
      ],
      linkText: 'Book A Class Now',
    },
    becomeSeller: 'Are you a Design Tutor?',

    section2: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675409044/probuddy/static/NewEducation/CategoryPage/Design/pexels-burst-374598_1_hsz5j7.jpg',
      heading: 'Learn from Industry Experts and Enhance Your Career Opportunities',
      description: [
        "At Probuddy Education, we bring together some of the best minds in the design industry to share their expertise and help you achieve your goals. Our courses are taught by experienced professionals who have a passion for teaching and a wealth of knowledge in their respective fields. By enrolling in our online courses, you'll have the opportunity to learn from these experts and receive personalized feedback and support to help you succeed.",
      ],
      linkText: 'Explore Our Listings',
    },

    exercise: {
      productTitle: 'Popular Design Listings in your area',
      title: 'Online Design Courses That Deliver Results!',
    },

    classesNearYou: {
      title: 'Access a Wide Range of Courses on Graphic Design, Web Design, and More',
      description: [
        "Whether you're interested in learning graphic design basics, advanced web design techniques, or anything in between, we have a course that's right for you. Our courses cover a wide range of topics and are designed to meet the needs of designers at all levels, from beginners to experienced professionals. Our courses are also designed to be engaging and interactive, so you'll have plenty of opportunities to put your newfound knowledge into practice.",
      ],
    },

    instructors: {
      title: 'Get Hands-On Experience and Practice Your Design Skills',
      description: [
        "Our courses are designed to provide you with hands-on experience in design techniques and tools, so you can apply your new skills to real-world projects. You'll get the chance to practice what you learn and develop your portfolio, so you can showcase your skills to the world. This practical approach will help you build confidence and boost your design skills to a new level.",
      ],
    },

    featured: {
      title: "Unlock Your Design Potential with Probuddy Education's Online Courses - Enroll Now!",
      description: [
        "Don't let your design potential go to waste. Enroll in Probuddy Education's online courses today and start unlocking your full potential. With our comprehensive courses, experienced instructors, and engaging learning experience, you'll be on your way to becoming a confident and skilled designer in no time. So why wait? Enroll now and take the first step towards a successful career in design!",
      ],
    },

    topRatedClasses: {
      title: 'Why Choose Probuddy Education for Design Courses',
      description: [
        "At Probuddy Education, we are dedicated to providing the best online education experience possible. Our courses are affordable, flexible, and taught by experienced instructors who are passionate about helping you succeed. Whether you're just starting out or you're a seasoned professional, we have the courses you need to take your Design knowledge and skills to the next level.",
        'Enroll in one of our Design courses today and start your journey to success!',
      ],
    },
  },
  {
    id: 'marketing',
    title: 'Marketing',
    description:
      'Take Marketing Classes Online and Learn from the Comfort of Your Home | Probuddy Education',
    heroImage:
      'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675409738/probuddy/static/NewEducation/CategoryPage/Marketing/pexels-lukas-590016_hikhoz.jpg',
    section1: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675409724/probuddy/static/NewEducation/CategoryPage/Marketing/pexels-artem-podrez-5716016_g5ygkr.jpg',
      heading: "Transform Your Marketing Skills with Probuddy Education's Online Marketing Courses",
      description: [
        "Whether you're interested in learning digital marketing basics, advanced branding techniques, or anything in between, we have a course that's right for you. Our courses cover a wide range of topics and are designed to meet the needs of marketers at all levels, from beginners to experienced professionals. Our courses are also designed to be engaging and interactive, so you'll have plenty of opportunities to put your newfound knowledge into practice.",
      ],
      linkText: 'Book A Class Now',
    },
    becomeSeller: 'Are you a Marketing Tutor?',

    section2: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675409729/probuddy/static/NewEducation/CategoryPage/Marketing/pexels-mikael-blomkvist-6476255_mofaca.jpg',
      heading: 'Learn from Industry Experts and Stay Ahead of the Game',
      description: [
        "At Probuddy Education, we bring together some of the most experienced and knowledgeable marketers in the industry to share their expertise with you. Our courses are taught by experienced professionals who have a passion for teaching and a wealth of knowledge in their respective fields. By enrolling in our courses, you'll have the opportunity to learn from the best and stay ahead of the marketing game.",
      ],
      linkText: 'Explore Our Listings',
    },

    exercise: {
      productTitle: 'Popular Marketing Listings in your area',
      title: 'Online Marketing Courses That Deliver Results!',
    },

    classesNearYou: {
      title: 'Get Hands-On Experience and Practice Your Marketing Skills',
      description: [
        "At Probuddy Education, we believe that the best way to learn is by doing. That's why our courses are designed to provide you with plenty of hands-on experience and practice opportunities. You'll work on real-world projects, receive personalized feedback, and have the chance to showcase your skills to the world. With Probuddy Education, you'll have everything you need to become a confident and skilled marketer.",
      ],
    },

    instructors: {
      title: 'Advance Your Career and Build Your Professional Network',
      description: [
        "Take your career to the next level with Probuddy Education's online marketing courses. Our courses not only provide you with the skills and knowledge you need to succeed, but also give you the opportunity to build your professional network and connect with other marketers from around the world. So why wait? Enroll now and start your marketing journey today!",
      ],
    },

    featured: {
      title:
        "Unlock Your Marketing Potential with Probuddy Education's Online Courses - Enroll Now!",
      description: [
        "Don't let your Marketing potential go to waste. Enroll in Probuddy Education's online courses today and start unlocking your full potential. With our comprehensive courses, experienced instructors, and engaging learning experience, you'll be on your way to becoming a confident and skilled Marketing in no time. So why wait? Enroll now and take the first step towards a successful career in Marketing!",
      ],
    },

    topRatedClasses: {
      title: 'Why Choose Probuddy Education for Marketing Courses',
      description: [
        "At Probuddy Education, we are dedicated to providing the best online education experience possible. Our courses are affordable, flexible, and taught by experienced instructors who are passionate about helping you succeed. Whether you're just starting out or you're a seasoned professional, we have the courses you need to take your Marketing knowledge and skills to the next level.",
        'Enroll in one of our Marketing courses today and start your journey to success!',
      ],
    },
  },
  {
    id: 'health-and-fitness',
    title: 'health & fitness',
    description:
      'Take health & fitness Classes Online and Learn from the Comfort of Your Home | Probuddy Education',
    heroImage:
      'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675410649/probuddy/static/NewEducation/CategoryPage/Healthandfitness/pexels-antoni-shkraba-6599017_z7rzjb.jpg',
    section1: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675410708/probuddy/static/NewEducation/CategoryPage/Healthandfitness/pexels-andrea-piacquadio-868483_xjmnme.jpg',
      heading: 'Transform Your Body and Mind: A Comprehensive Health & Fitness Course',
      description: [
        'Welcome to the Transform Your Body and Mind: A Comprehensive Health & Fitness Course offered by Probuddy Education. Our mission is to help you maximize your potential and achieve optimal health and fitness through our comprehensive online program.',
      ],
      linkText: 'Book A Class Now',
    },
    becomeSeller: 'Are you a Health & Fitness Tutor?',

    section2: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675410675/probuddy/static/NewEducation/CategoryPage/Healthandfitness/pexels-ortal-shushan-6070517_eqxmuw.jpg',
      heading: 'Maximize Your Potential: An Online Course in Health and Fitness',
      description: [
        'This course is designed to help you maximize your potential and achieve your fitness goals. Whether you are a beginner or an experienced fitness enthusiast, our program will provide you with the tools and knowledge you need to transform your body and mind.',
      ],
      linkText: 'Explore Our Listings',
    },

    exercise: {
      productTitle: 'Popular health & fitness Listings in your area',
      title: 'Online health & fitness Courses That Deliver Results!',
    },

    classesNearYou: {
      title: 'Unlock Your Fitness Goals: A Step-by-Step Course to Better Health',
      description: [
        'Our program is structured in a step-by-step format, making it easy for you to follow and achieve your fitness goals. From understanding the basics of fitness to learning advanced techniques, you will be guided through each step of the way.',
      ],
    },

    instructors: {
      title: 'A Team of Health and Fitness Pros at Probuddy Education',
      description: [
        'We are proud to have a team of experienced health and fitness professionals at Probuddy Education. Our instructors are highly qualified and passionate about helping people transform their bodies and minds. You can trust that you are in good hands with our knowledgeable and experienced team.',
      ],
    },

    featured: {
      title: 'Enroll Now and Start Your Journey to Optimal Health and Fitness',
      description: [
        "Don't wait any longer to start your journey to optimal health and fitness. Enroll now and experience the benefits of our comprehensive health and fitness program. Join a community of like-minded individuals who are committed to transforming their bodies and minds. Start your journey today!",
      ],
    },

    topRatedClasses: {
      title: 'Why Choose Probuddy Health and Fitness for Marketing Courses',
      description: [
        "At Probuddy Health and Fitness, we are dedicated to providing the best online Health and Fitness experience possible. Our courses are affordable, flexible, and taught by experienced instructors who are passionate about helping you succeed. Whether you're just starting out or you're a seasoned professional, we have the courses you need to take your Marketing knowledge and skills to the next level.",
        'Enroll in one of our Health and Fitness courses today and start your journey to success!',
      ],
    },
  },
  {
    id: 'music',
    title: 'Music',
    description:
      'Take Music Classes Online and Learn from the Comfort of Your Home | Probuddy Education',
    heroImage:
      'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675413520/probuddy/static/NewEducation/CategoryPage/Music/pexels-any-lane-5727886_ex4yet.jpg',
    section1: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675413538/probuddy/static/NewEducation/CategoryPage/Music/pexels-george-milton-6953871_fqegnk.jpg',
      heading: 'Discover Your Passion for Music: An Online Course for Beginners and Pros',
      description: [
        "Welcome to Probuddy Education's online music course, designed for both beginners and experienced musicians. Our mission is to help you discover your passion for music and unlock your full potential as a musician.",
      ],
      linkText: 'Book A Class Now',
    },
    becomeSeller: 'Are you a Music Guru?',

    section2: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675413512/probuddy/static/NewEducation/CategoryPage/Music/pexels-boris-pavlikovsky-7713994_dnklm6.jpg',
      heading: 'Master the Fundamentals of Music',
      description: [
        "Whether you're a beginner or have years of experience, this course will help you master the fundamentals of music. From understanding music theory to learning new performance techniques, you'll gain a solid foundation in the basics of music.",
      ],
      linkText: 'Explore Our Listings',
    },

    exercise: {
      productTitle: 'Popular Music Listings in your area',
      title: 'Online Music Courses That Deliver Results!',
    },

    classesNearYou: {
      title: 'Enhance Your Creativity and Expression: A Guide to Music Theory and Composition',
      description: [
        "In addition to performance skills, this course will also cover music theory and composition. You'll learn how to enhance your creativity and expression through the composition of original pieces, giving you the ability to write and perform your own music.",
      ],
    },

    instructors: {
      title: 'Learn from a Renowned Music Pro with Our Online Course',
      description: [
        'Our course features a renowned music professional as your instructor, ensuring that you receive the highest quality education. With years of experience and a passion for teaching, our instructor will guide you through each step of the learning process.',
      ],
    },

    featured: {
      title: 'Elevate Your Sound: A Results-Driven Online Music Course',
      description: [
        "This online course is designed to help you elevate your sound and achieve your musical goals. With a results-driven approach, you'll be able to track your progress and see the results of your hard work.",
      ],
    },

    topRatedClasses: {
      title: 'Enroll Now and Start Your Musical Journey with Probuddy Education',
      description: [
        "Don't wait any longer to start your musical journey. Enroll now in Probuddy Education's online music course and unlock your full potential as a musician. Join a community of like-minded individuals who are passionate about music and committed to their musical growth. Start your journey today!",
      ],
    },
  },
  {
    id: 'lifestyle',
    title: 'Lifestyle',
    description:
      'Take Lifestyle Classes Online and Learn from the Comfort of Your Home | Probuddy Education',
    heroImage:
      'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675743748/probuddy/static/NewEducation/CategoryPage/Lifestyle/pexels-destiawan-nur-agustra-1034940_zytxs3.jpg',

    section1: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675743769/probuddy/static/NewEducation/CategoryPage/Lifestyle/pexels-ovan-62689_ahsfeu.jpg',
      heading: "Unlock a Better Lifestyle with Probuddy Education's Online Courses",
      description: [
        "Welcome to the lifestyle course section at Probuddy Education! Here, you will find a comprehensive selection of online courses that are designed to enhance your quality of life. Whether you're looking to improve your physical health, manage stress, or learn time-management techniques, our courses have been crafted to deliver actionable insights and practical tools. Whether you're a busy professional, a stay-at-home parent, or simply seeking personal growth, the lifestyle courses at Probuddy Education will help you take control of your life and live it to the fullest.",
      ],
      linkText: 'Book A Class Now',
    },
    becomeSeller: 'Are you a Lifestyle Tutor?',

    section2: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675743801/probuddy/static/NewEducation/CategoryPage/Lifestyle/pexels-ivan-samkov-5676744_sjxyeg.jpg',
      heading: 'Introduction to Lifestyle Learning for All Levels',
      description: [
        "Lifestyle courses by Probuddy offer education and training opportunities for everyone, regardless of experience level. Whether you are a beginner looking to acquire a new skill, or an experienced professional looking to enhance existing knowledge, we've got the perfect course for you.",
        "Our Lifestyle courses will help you become an expert in your field, whether it's health and wellness, cooking, home improvement or business development - just to name a few.",
      ],
      linkText: 'Explore Our Listings',
    },

    exercise: {
      productTitle: 'Popular Lifestyle Listings in your area',
      title: 'Private or Group Lifestyle Classes Offered by Our Pros',
    },

    classesNearYou: {
      title: 'Expert-Led Lifestyle Courses for Beginners',
      description: [
        "Lifestyle courses for beginners by Probuddy are designed to be comprehensive and comprehensive, taking into account the needs of modern lifestyle. Whether you're looking to learn new skills, get advice from industry professionals or just explore a new area in life, Lifestyle Courses at ProBuddy can offer the perfect starting point.",
        'Led by experts from all over the world, Lifestyle Courses provide detailed knowledge on topics such as digital marketing, mindfulness and project management so that everyone can find the perfect course for their individual growth.',
      ],
    },

    instructors: {
      title: 'Advanced Lifestyle Programs for Experienced Professionals',
      description:
        "Lifestyle Courses by Probuddy make it simpler than ever for experienced professionals to further develop their skill set. Whether it's the latest technology, developing the mental focus of a high-profile executive or advancing your industry know-how, Probuddy has the courses you need to stay ahead of the game.",
    },

    featured: {
      title: 'Highly Rated and Results-Driven Lifestyle Classes',
      description: [
        'Lifestyle courses by Probuddy are highly rated and results-driven. Students benefit from personalized coaching in a range of topics like fitness, nutrition, and mental wellbeing that are tailored to the individual. Moreover, qualified experts provide hands-on guidance throughout the duration of each course. Lifestyle programs at Probuddy empower students to stay motivated and reach their goals in an effective manner.',
      ],
    },

    topRatedClasses: {
      title: 'The Benefits of Choosing Probuddy Education for Your Lifestyle Journey',
      description: [
        'Probuddy Education offers practical, easy-to-understand Lifestyle Courses to help you on your journey. Each Lifestyle Course is built using the latest research and data to give you a step by step plan that can be personalized to fit your needs. Best of all, they are accessible through their online platform and mobile app, so you can learn on the go!',
        'With Probuddy there is no need to worry about not having enough time, or feeling overwhelmed by new information. Instead, you can take control of your success at your own pace and in an environment that feels comfortable for you.',
        'From budgeting and investing to nutrition, mental health and even stress relief - Probuddy Lifestyle Courses offer it all so that you can get exactly what you need and want from the learning experience.',
      ],
    },
  },
  {
    id: 'photography-and-video',
    title: 'Photography and Video',
    description:
      'Take Photography and Video Classes Online and Learn from the Comfort of Your Home | Probuddy Education',
    heroImage:
      'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675746112/probuddy/static/NewEducation/CategoryPage/Photography/pexels-matheus-bertelli-2608519_u5czwx.jpg',
    section1: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675746244/probuddy/static/NewEducation/CategoryPage/Photography/pexels-ricardo-esquivel-4011762_ovnvoe.jpg',
      heading: "Unlock a Better Lifestyle with Probuddy Education's Online Courses",
      description: [
        "Welcome to the lifestyle course section at Probuddy Education! Here, you will find a comprehensive selection of online courses that are designed to enhance your quality of life. Whether you're looking to improve your physical health, manage stress, or learn time-management techniques, our courses have been crafted to deliver actionable insights and practical tools. Whether you're a busy professional, a stay-at-home parent, or simply seeking personal growth, the lifestyle courses at Probuddy Education will help you take control of your life and live it to the fullest.",
      ],
      linkText: 'Book A Class Now',
    },
    becomeSeller: 'Are you a Photography & Video Tutor?',

    section2: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675746180/probuddy/static/NewEducation/CategoryPage/Photography/pexels-kyle-loftus-3379934_ayv3m7.jpg',
      heading: 'Introduction to Lifestyle Learning for All Levels',
      description: [
        "Photography and videography are two of the most popular and in-demand creative arts in today's world. With the rise of social media, online content creation, and the need for visual content in various industries, photography and videography skills are becoming increasingly valuable.",
        'Whether you are a beginner or a professional looking to enhance your skills, Probuddy Education offers a wide range of photography and video courses to help you achieve your goals.',
      ],
      linkText: 'Explore Our Listings',
    },

    exercise: {
      productTitle: 'Popular Photography and videography Listings in your area',
      title: 'Private or Group Photography and videography Classes Offered by Our Pros',
    },

    classesNearYou: {
      title: 'Photography & Video Online Courses for Beginners',
      description: [
        "If you are new to photography or videography, Probuddy Education provides comprehensive courses that cover all the basics and essential techniques. From understanding camera settings, lighting, and composition to editing and post-production, our courses are designed to help you develop a solid foundation and grow your skills. Whether you're interested in photography for personal or professional purposes, our courses will provide you with the tools and knowledge you need to capture stunning images and videos.",
      ],
    },

    instructors: {
      title: 'Advance Photography & Video Online Courses for Professionals',
      description:
        'For those who already have a solid understanding of photography and videography, Probuddy Education offers advanced courses designed to help you take your skills to the next level. Whether you want to specialize in portrait photography, landscape photography, or videography, our courses are taught by experienced professionals and will provide you with the skills and knowledge you need to take your creative vision to the next level.',
    },

    featured: {
      title: 'Top-Rated Photography & Video Online Classes',
      description: [
        "Probuddy Education is known for offering high-quality photography and videography courses that are taught by experienced professionals. Our courses are designed to meet the needs of students at all levels and are backed by a commitment to delivering a superior learning experience. With our top-rated photography and video classes, you can be sure that you're receiving the best education available.",
      ],
    },

    topRatedClasses: {
      title: 'Why Choose Probuddy Education for Photography & Video Courses',
      description: [
        "Probuddy Education is dedicated to providing students with the best possible education experience. From beginner to professional, we have a course that's right for you. Our courses are taught by experienced professionals and are designed to provide you with the skills and knowledge you need to achieve your goals. We understand that every student is unique, and our courses are flexible and accessible, so you can learn at your own pace, from anywhere in the world.",
        "In conclusion, if you're looking to enhance your photography or videography skills, Probuddy Education is the perfect place to start. Whether you're a beginner or a professional, our comprehensive and high-quality courses will help you achieve your goals and take your skills to the next level. So, if you're ready to start your photography and videography journey, sign up for one of our courses today and get started!",
      ],
    },
  },
  {
    id: 'language',
    title: 'Language',
    description:
      'Take Language Classes Online and Learn from the Comfort of Your Home | Probuddy Education',
    heroImage:
      'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675759046/probuddy/static/NewEducation/CategoryPage/Language/lang_ksxwzv.jpg',
    section1: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675759049/probuddy/static/NewEducation/CategoryPage/Language/langu_aulk4i.jpg',
      heading: 'Master a New Language with Our Experienced Tutors',
      description: [
        "Unlock your full language learning potential with our team of experienced tutors. Whether you're a beginner or looking to improve your skills, our personalized approach will help you achieve fluency in no time. Say goodbye to dull and unproductive language classes, and start speaking like a native today with our experienced tutors here at ProBuddy Education!",
      ],
      linkText: 'Book A Class Now',
    },
    becomeSeller: 'Are you a language Expert?',

    section2: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675759054/probuddy/static/NewEducation/CategoryPage/Language/language_nh1lxm.jpg',
      heading: 'Achieving Fluency Has Never Been Easier',
      description: [
        'Achieving fluency in a new language has never been easier with our innovative tutoring program. Our expert tutors will guide you every step of the way, helping you to master the language at your own pace and achieve your goals faster. Say hello to effective and enjoyable language learning with our program, and reach fluency with ease.',
      ],
      linkText: 'Explore Our Listings',
    },

    exercise: {
      productTitle: 'Popular Languages Listings in your area',
      title: 'Private or Group Languages Classes Offered by Our Pros',
    },

    classesNearYou: {
      title: 'Unleash Your Potential to Speak Like a Native',
      description: [
        'Unleash your full potential to speak like a native with our personalized language tutoring program. Our experienced tutors will work with you one-on-one to help you achieve fluency quickly and efficiently. Say goodbye to language barriers and hello to confident and natural language speaking with our program.',
      ],
    },

    instructors: {
      title: 'Advanced Language Programs for Experienced Professionals',
      description:
        "Language Courses by Probuddy make it simpler than ever for experienced professionals to further develop their skill set. Whether it's the latest technology, developing the mental focus of a high-profile executive or advancing your industry know-how, Probuddy has the courses you need to stay ahead of the game.",
    },

    featured: {
      title: 'Transform Your Language Learning Experience',
      description: [
        'Transform your language learning experience with our innovative and personalized tutoring program. Our expert tutors will create a custom learning plan tailored to your individual needs and goals.',
      ],
    },

    topRatedClasses: {
      title:
        'Join the Community of Successful Language Learners with Our Supportive Tutors at ProBuddy Education',
      description: [
        "Join the community of successful language learners with our supportive tutors at ProBuddy Education. Our team of experienced tutors will provide you with the guidance, support and resources you need to achieve fluency in your target language. With our focus on personalized and effective language learning, you'll be speaking like a native in no time! Join the ProBuddy community and take the first step towards fluency today.",
      ],
    },
  },
  {
    id: 'academic',
    title: 'Academics',
    description:
      'Take Academics Classes Online and Learn from the Comfort of Your Home | Probuddy Education',
    heroImage:
      'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675837980/probuddy/static/NewEducation/CategoryPage/Avademics/pexels-pixabay-159740_1_u1ddsw.jpg',
    section1: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675836411/probuddy/static/NewEducation/CategoryPage/Avademics/pexels-keira-burton-6146978_pfbiqm.jpg',
      heading:
        "Explore the World of Knowledge with Probuddy Education's Wide Range of Academic Courses",
      description: [
        "Welcome to Probuddy Education, where we believe that education is the key to unlocking your potential and achieving your goals. Our wide range of academic courses offers something for everyone, whether you're looking to gain new skills, enhance your career prospects, or simply explore the world of knowledge.",
      ],
      linkText: 'Book A Class Now',
    },
    becomeSeller: 'Are you a Academics Tutor?',

    section2: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675836408/probuddy/static/NewEducation/CategoryPage/Avademics/pexels-filipe-sabino-2065490_pbcurk.jpg',
      heading: 'Gain In-Demand Skills and Enhance Your Career Prospects',
      description: [
        "Our academic courses are designed to help you gain in-demand skills that are relevant to today's job market. With a focus on practical, hands-on learning, our courses will give you the knowledge and skills you need to succeed in your chosen field. And with a range of subjects to choose from, you're sure to find a course that matches your interests and career goals.",
      ],
      linkText: 'Explore Our Listings',
    },

    exercise: {
      productTitle: 'Popular Academics Listings in your area',
      title: 'Private or Group Academics Classes Offered by Our Pros',
    },

    classesNearYou: {
      title: 'Study at Your Own Pace with Flexible Online Learning Options',
      description: [
        "At Probuddy Education, we understand the importance of flexible learning options. That's why all of our academic courses are available online, so you can study at your own pace and on your own schedule. Whether you're a busy professional, a full-time student, or simply someone who loves to learn, our online courses are the perfect way to get ahead.",
      ],
    },

    instructors: {
      title: 'Advanced Language Programs for Experienced Professionals',
      description:
        "Language Courses by Probuddy make it simpler than ever for experienced professionals to further develop their skill set. Whether it's the latest technology, developing the mental focus of a high-profile executive or advancing your industry know-how, Probuddy has the courses you need to stay ahead of the game.",
    },

    featured: {
      title: 'Get Support from Experienced Instructors and Dedicated Student Services',
      description: [
        "At Probuddy Education, we're committed to providing you with the support you need to succeed. Our experienced instructors are always available to answer your questions and provide you with personalized feedback, while our dedicated student services team is here to help you every step of the way. With our comprehensive support system, you can be confident that you have all the resources you need to succeed.",
      ],
    },

    topRatedClasses: {
      title: "Start Your Learning Journey Today: Enroll in Probuddy Education's Academic Courses",
      description: [
        "If you're ready to start your learning journey and unlock your potential, it's time to enroll in Probuddy Education's academic courses. Our wide range of subjects and flexible online learning options make it easy for you to find the course that's right for you, so you can get started on your path to success today. Don't miss out on this opportunity to explore the world of knowledge and gain the skills and knowledge you need to succeed. Enroll in Probuddy Education's academic courses today and start your journey towards a brighter future.",
      ],
    },
  },
  {
    id: 'management',
    title: 'Management',
    description:
      'Take Management Classes Online and Learn from the Comfort of Your Home | Probuddy Education',
    heroImage:
      'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675837015/probuddy/static/NewEducation/CategoryPage/Management/pexels-kampus-production-8190804_nnuawf.jpg',
    section1: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675837011/probuddy/static/NewEducation/CategoryPage/Management/pexels-pixabay-416405_fht3fa.jpg',
      heading: "Advance Your Career with Probuddy Education's Top-Notch Management Courses",
      description: [
        "Welcome to Probuddy Education, where we believe that the right education can unlock your potential and help you achieve your professional goals. Our management courses are designed for busy professionals who want to gain a competitive edge in today's rapidly changing business world. Our top-notch course offerings will help you develop the critical management skills you need to succeed, and our experienced professionals and industry experts will guide you every step of the way.",
      ],
      linkText: 'Book A Class Now',
    },
    becomeSeller: 'Are you a Management Expert?',

    section2: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675837006/probuddy/static/NewEducation/CategoryPage/Management/pexels-kampus-production-8190808_tbnsdb.jpg',
      heading: 'Gain a Competitive Edge with Our Industry-Relevant Curriculum',
      description: [
        "Our management courses are carefully crafted to meet the demands of today's business landscape. Our industry-relevant curriculum covers a wide range of topics, from strategic planning and leadership to project management and data analysis. Whether you're just starting your career or looking to take your skills to the next level, our management courses will provide you with the knowledge and skills you need to succeed.",
      ],
      linkText: 'Explore Our Listings',
    },

    exercise: {
      productTitle: 'Popular management Listings in your area',
      title: 'Private or Group management Classes Offered by Our Pros',
    },

    classesNearYou: {
      title: 'Learn from Experienced Professionals and Industry Experts',
      description: [
        "At Probuddy Education, we understand the importance of learning from real-world experience. That's why our management courses are taught by experienced professionals and industry experts who bring their practical knowledge and expertise to the classroom. Our instructors are dedicated to providing you with personalized feedback and guidance, and they're always available to answer your questions and help you achieve your goals.",
      ],
    },

    instructors: {
      title: 'Advanced Language Programs for Experienced Professionals',
      description:
        "Language Courses by Probuddy make it simpler than ever for experienced professionals to further develop their skill set. Whether it's the latest technology, developing the mental focus of a high-profile executive or advancing your industry know-how, Probuddy has the courses you need to stay ahead of the game.",
    },

    featured: {
      title: 'Get Support from Experienced Instructors and Dedicated Student Services',
      description: [
        'One of the best things about taking a management course with Probuddy Education is the opportunity to join a network of high-performing management professionals. Our courses provide an engaging and collaborative learning experience, where you can connect with like-minded individuals from around the world and share your experiences and insights. This supportive community can provide you with valuable networking opportunities, as well as lifelong connections and friendships.',
      ],
    },

    topRatedClasses: {
      title:
        "Invest in Your Professional Growth: Book and Enroll in Probuddy Education's Online Management Courses Today",
      description: [
        "With Probuddy Education, you can learn at your own pace and on your own schedule. Our online courses are flexible and accessible, so you can study whenever and wherever you like. And with 24/7 access to course content, you can learn at a pace that works best for you. Whether you're looking to advance your career, change careers, or simply improve your skills, our online management courses are a great investment in your professional growth.",
        "Don't miss out on this opportunity to take your management career to the next level. Book and enroll in Probuddy Education's online management courses today and start your journey towards a more successful and fulfilling career.",
      ],
    },
  },
  {
    id: 'art',
    title: 'Arts',
    description:
      'Take Arts Classes Online and Learn from the Comfort of Your Home | Probuddy Education',
    heroImage:
      'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1676540007/probuddy/static/NewEducation/CategoryPage/arts/art2_ljsnvn.jpg',

    section1: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1676540003/probuddy/static/NewEducation/CategoryPage/arts/art1_nlllo0.jpg',
      heading: "Hone Your Artistic Skills with Probuddy Education's Online Tutorials",
      description: [
        "Are you interested in exploring your creative side and improving your artistic skills? Do you struggle to find the time or resources to take traditional art classes? If so, then you'll be pleased to know that online art tutorials can be an excellent alternative.",
        "With Probuddy Education's online arts tutorials, you can learn at your own pace and schedule, access a vast range of courses, and receive feedback from expert instructors.",
        "Unleash Your Inner Creativity with Probuddy Education's Online Arts Tutorials",
        "Do you want to learn how to paint, draw, or sculpt, but don't know where to start? Probuddy Education's online arts tutorials are an excellent option for anyone interested in exploring their artistic side.",
      ],
      linkText: 'Book A Class Now',
    },
    becomeSeller: 'Are you a Arts Expert?',

    section2: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1676540012/probuddy/static/NewEducation/CategoryPage/arts/art3_rgezor.jpg',
      heading: 'Introducing Probuddy Education',
      description: [
        'Probuddy Education is a platform that provides online courses across various categories, including arts and crafts. They offer various courses that cater to different skill levels and interests, from watercolor painting to sculpting with clay.',
      ],
      linkText: 'Explore Our Listings',
    },

    exercise: {
      productTitle: 'Popular arts Listings in your area',
      title: 'Private or Group arts Classes Offered by Our Pros',
    },

    classesNearYou: {
      title: "How Probuddy Education's Online Tutorials Work",
      description: [
        "Once you sign up for Probuddy Education's online arts tutorials, you'll have access to a wide range of courses. You can browse the available courses and choose the one that interests you the most.",
        "Each course is broken down into manageable lessons, which you can complete at your own pace. You'll have access to video tutorials, instructional materials, and reference images to help guide you through each lesson.",
        "As you progress through the course, you'll have the opportunity to submit your work for feedback from the instructor. This feedback can help you improve your skills and better understand your artistic style.",
      ],
    },

    instructors: {
      title: 'Advanced Arts Programs for Experienced Professionals',
      description:
        "Arts Courses by Probuddy make it simpler than ever for experienced professionals to further develop their skill set. Whether it's the latest technology, developing the mental focus of a high-profile executive or advancing your industry know-how, Probuddy has the courses you need to stay ahead of the game.",
    },

    featured: {
      title: 'Why Choose Probuddy Education?',
      description: [
        "Probuddy Education's online tutorials' primary advantage is their flexibility. You can learn at your own pace, on your own schedule, and from the comfort of your home. With no strict deadlines or schedules, you can learn and create whenever it suits you best.",
        "Additionally, Probuddy Education's courses are designed to cater to different skill levels. Whether you're a beginner or an experienced artist, you'll find courses that are tailored to your specific needs and interests.",
        "With Probuddy Education's online tutorials, you'll also have access to expert instructors who can offer feedback on your work. This feedback can help you improve your technique and develop your artistic style.",
      ],
    },

    topRatedClasses: {
      title: "Who Can Benefit from Probuddy Education's Online Tutorials?",
      description: [
        "Probuddy Education's online tutorials are an excellent resource for anyone interested in learning or improving their artistic skills. This includes beginners who want to explore their creative side and experienced artists who want to hone their technique.",
        "Whether you're a stay-at-home parent, a full-time worker, or a student, Probuddy Education's online arts tutorials offer a convenient and flexible way to learn and create.",
      ],
    },
  },
  {
    id: 'science',
    title: 'Science',
    description:
      'Take Science Classes Online and Learn from the Comfort of Your Home | Probuddy Education',
    heroImage:
      'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1676874805/probuddy/static/NewEducation/CategoryPage/science/science2_pni1x3.jpg',

    section1: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1676874803/probuddy/static/NewEducation/CategoryPage/science/science1_jiku8q.jpg',
      heading: 'Unlock Your Potential with Cutting-Edge Science Online Courses',
      description: [
        "Are you ready to unlock your potential and take your understanding of science to the next level? Look no further than Probuddy Education's science online courses. Our expertly crafted courses are designed to provide you with cutting-edge knowledge and skills that will help you achieve your personal and professional goals.",
      ],
      linkText: 'Book A Class Now',
    },
    becomeSeller: 'Are you a Science Expert?',

    section2: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1676874810/probuddy/static/NewEducation/CategoryPage/science/science3_kr1fw6.jpg',
      heading: 'Our Science Courses Offer Flexibility and Convenience for Your Busy Lifestyle',
      description: [
        "We understand that you have a busy schedule, which is why we've made our science online courses flexible and convenient. With 24/7 access to course material and resources, you can learn at your own pace and on your own schedule. Whether you're a student, professional, or just interested in science, our courses are designed to fit seamlessly into your life.",
      ],
      linkText: 'Explore Our Listings',
    },

    exercise: {
      productTitle: 'Popular Science Listings in your area',
      title: 'Private or Group arts Classes Offered by Our Pros',
    },

    classesNearYou: {
      title: 'Learn from Experienced Educators and Practitioners in the Science Field',
      description: [
        "Our science online courses are taught by experienced educators and practitioners in the science field. This means that you'll learn from people who have real-world experience and can provide you with valuable insights into the science industry. Our instructors are passionate about sharing their knowledge and helping you achieve your goals.",
      ],
    },

    instructors: {
      title: 'Advanced Arts Programs for Experienced Professionals',
      description:
        "Science Courses by Probuddy make it simpler than ever for experienced professionals to further develop their skill set. Whether it's the latest technology, developing the mental focus of a high-profile executive or advancing your industry know-how, Probuddy has the courses you need to stay ahead of the game.",
    },

    featured: {
      title: 'Join a Community of Lifelong Learners and Science Enthusiasts',
      description: [
        "When you enroll in our science online courses, you'll become part of a vibrant and supportive community of lifelong learners and science enthusiasts. This community is a valuable resource that you can turn to for support, feedback, and inspiration. You'll be able to share your knowledge, ask questions, and connect with like-minded people who share your passion for science.",
      ],
    },

    topRatedClasses: {
      title:
        'Enroll Today and Take the First Step towards a More Fulfilling and Knowledgeable Future',
      description: [
        "Don't wait any longer to unlock your potential and take the first step towards a more fulfilling and knowledgeable future. Enroll in Probuddy Education's science online courses today and start your journey towards success. Our courses are designed to provide you with the knowledge and skills you need to achieve your personal and professional goals. Sign up today and start learning!",
      ],
    },
  },
  {
    id: 'history',
    title: 'History',
    description:
      'Take History Classes Online and Learn from the Comfort of Your Home | Probuddy Education',
    heroImage:
      'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1676876276/probuddy/static/NewEducation/CategoryPage/history/history1_vmpox9.jpg',

    section1: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1676876281/probuddy/static/NewEducation/CategoryPage/history/history3_s4hgw7.jpg',
      heading: 'Explore the Past and Shape Your Future with History Online Courses',
      description: [
        "Discover the fascinating and intricate stories of human history with Probuddy Education's online history courses. Our courses will take you on a journey through time, exploring the ideas, events, and individuals that shaped our world. With our expertly crafted courses, you'll gain a deeper understanding of history and how it shapes our present and future.",
      ],
      linkText: 'Book A Class Now',
    },
    becomeSeller: 'Are you agreat Historian Expert?',

    section2: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1676876271/probuddy/static/NewEducation/CategoryPage/history/history_zglhoc.jpg',
      heading: 'Our History Courses Offer Flexibility and Convenience for Your Busy Lifestyle',
      description: [
        "Our history online courses are designed to be flexible and convenient, fitting into your busy schedule. You can access our course materials and resources 24/7, giving you the freedom to learn at your own pace and on your own schedule. Whether you're a student, a busy professional, or just interested in history, our courses are the perfect way to fit learning into your life.",
      ],
      linkText: 'Explore Our Listings',
    },

    exercise: {
      productTitle: 'Popular History Listings in your area',
      title: 'Private or Group arts Classes Offered by Our Pros',
    },

    classesNearYou: {
      title: 'Join a Community of Lifelong Learners and History Enthusiasts',
      description: [
        "When you enroll in our history online courses, you'll become part of a vibrant and supportive community of lifelong learners and history enthusiasts. You'll be able to share your knowledge, ask questions, and connect with like-minded people who share your passion for history. This community will provide you with a valuable resource to turn to for support, feedback, and inspiration.",
      ],
    },

    instructors: {
      title: 'Advanced History Programs for Experienced Professionals',
      description:
        "History Courses by Probuddy make it simpler than ever for experienced professionals to further develop their skill set. Whether it's the latest technology, developing the mental focus of a high-profile executive or advancing your industry know-how, Probuddy has the courses you need to stay ahead of the game.",
    },

    featured: {
      title: 'Enroll Today and Embark on a Journey of Self-Discovery and Enlightenment',
      description: [
        "Unlock the mysteries of the past and discover the wonders of history with Probuddy Education's online history courses. Our courses are designed to provide you with the knowledge and skills you need to understand and appreciate the past. Enroll today and embark on a journey of self-discovery and enlightenment. With our expert instructors and flexible course design, you'll be well on your way to a deeper understanding of history and how it shapes our world.",
      ],
    },

    topRatedClasses: {
      title:
        'Enroll Today and Take the First Step towards a More Fulfilling and Knowledgeable Future',
      description: [
        "Don't wait any longer to unlock your potential and take the first step towards a more fulfilling and knowledgeable future. Enroll in Probuddy Education's histoty online courses today and start your journey towards success. Our courses are designed to provide you with the knowledge and skills you need to achieve your personal and professional goals. Sign up today and start learning!",
      ],
    },
  },
];

export const categoryTypeDataConfig = [
  {
    id: 'sports',
    title: 'Sports Classes',
    description: 'All Sports Classes & Activities In One Place',
    heroImage:
      'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675340847/probuddy/static/images/CategoriesPage/sports/SportsHeroImage_lpluon.png',

    section1: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675340856/probuddy/static/images/CategoriesPage/sports/SportsActivitiesImage_qxzjr1.png',
      heading: 'All Sports Activities. One Marketplace.',
      description:
        'Bring the entire world of sports classes at your fingertips. Hundreds of daily classes in tennis, boxing, golf, martial arts and more. Complete your workout in the comfort of your own home, on the beach, or at your favorite class studio. Just choose your activity and book it!',
      linkText: 'Book A Class Now',
    },

    section2: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675340865/probuddy/static/images/CategoriesPage/sports/EnjoyClassImage_xsztwa.png',
      heading: 'Enjoy class, in comfort, anytime, anywhere.',
      description:
        "Whether you're an experienced or complete beginner, or you're looking for a more holistic approach to learn sports, there's something for everyone at Probuddy!Whatever your preferences or ability, there's an array of instructor-led classes available. Choose from a wide mix of different workouts, keep fit and healthy or push yourself to the limits.",
      linkText: 'Explore Our Listings',
    },

    exercise: {
      productTitle: 'Popular Sports Listings in your area',
      title: 'Private or Group Sports Classes Offered by Our Pros',
    },

    whyBook: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675483916/probuddy/static/images/CategoriesPage/sports/BookSportsImage_hhxsfe.png',
      heading1: 'Why Choose Us',
      position: 'start',
      containerPosition: 'center',
      description1:
        'We make it easy for you to find, schedule and attend sports classes taught by the Pros through your local sports facility, and at your convenience.',
      heading2: '',
      description2: '',
    },

    classesNearYou: {
      title: "Whatever type of sports classes you're looking for, you'll find it here.",
      description:
        'With our huge range of fun and motivating sports classes, we have something for you, no matter what your personal workout style. Whether you want to get fit and healthy, relax or release stress, we have something for everyone!',
    },

    instructors: {
      title: 'Top-Rated Sports Coaches & Instructors',
      description:
        "Discover sports classes and coaches near you. Learn from the best athletes, trainers and instructors in your area. Probuddy gives you access to your city's most elite coaches who live in your neighborhood.",
      instructorsData: [
        {
          id: '1',
          trainerImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1681967398/probuddy/static/images/CategoriesPage/sports/SportsTrainerImage1_folgnd.png',
          name: 'Tennis Coach',
          type: 'Christo Weise',
          rating: '4.4',
          reviews: '17',
          category: 'sports',
          description:
            "Great with Corporate group programs currently Google's tennis program coach and i have also conducted groups for the Singapore Police and Facebook.",
        },
        {
          id: '2',
          trainerImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1681967398/probuddy/static/images/CategoriesPage/sports/SportsTrainerImage2_xetnnl.png',
          name: 'Agustin Velotti',
          type: 'Tennis Coach',
          rating: '4.8',
          reviews: '16',
          category: 'sports',
          description:
            'Let Agustin help you improve your skills and fitness. Classes are for all levels and ages. Currently world ranked 391 ATP and highest ranked active tennis player in Singapore.',
        },
        {
          id: '3',
          trainerImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1681967399/probuddy/static/images/CategoriesPage/sports/SportsTrainerImage3_kv873p.png',
          name: 'Michel Van De Sanden',
          type: 'Tennis Coach',
          rating: '4.4',
          reviews: '11',
          category: 'sports',
          description:
            'Looking to learn basics of tennis, get your game to another level or to have a hit with high performance coach and former WTA tour coach.',
        },
      ],
    },

    featured: {
      title: 'Top-Rated Sports Companies & Fintess Studios',
      description:
        "Sweat with confidence. Whether you're looking for a new workout buddy or want to deepen your practice, you'll have plenty of options available in your area. Probuddy makes it easy to search and find local fitness classes and sports groups or private classes near you.",
      featuredItems: [
        {
          id: '1',
          companyImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677220313/probuddy/static/images/CategoriesPage/sports/SportsCompanyImage1_n8iwgh.png',
          name: 'SCC Tennis Academy',
          location: 'Connaught Dr., Singapore',
          rating: '4.2',
          reviews: '225',
          category: 'sports',
          description:
            "SCC Tennis Academy is more than just a workout ~ it's a lifestyle that inspires confidence, community and celebration! Whether you are beginning an exercise program, a high level athlete, or just want to tone up, we have a program for you. Mindful movement is our motto and the common thread that runs through our philosophy and approach to living a healthy, happy life.",
        },
        {
          id: '2',
          companyImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677220313/probuddy/static/images/CategoriesPage/sports/SportsCompanyImage1_n8iwgh.png',
          name: 'SCC Tennis Academy',
          location: 'Connaught Dr., Singapore',
          rating: '4.6',
          reviews: '195',
          category: 'sports',
          description:
            "SCC Tennis Academy is more than just a workout ~ it's a lifestyle that inspires confidence, community and celebration! Whether you are beginning an exercise program, a high level athlete, or just want to tone up, we have a program for you. Mindful movement is our motto and the common thread that runs through our philosophy and approach to living a healthy, happy life.",
        },
      ],
    },

    topRatedClasses: {
      title: 'Most Popular Sports Classes in your area',
      description:
        "Find the perfect exercise class near you. Whether you're looking to try a new sport, get fit, stay healthy or have fun with friends - our wide variety of sports classes can help you live well so you can do more of what you love.",
      classesData: [
        {
          id: '1',
          classesImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677220326/probuddy/static/images/CategoriesPage/sports/SportsClassesImage1_a4vij6.png',
          className: 'Christo Wiese Private Coaching (GST Included)',
          instructorName: 'Christo Weise',
          location: 'Bridge Road, Singapore',
          rating: '4.6',
          reviews: '242',
          category: 'sports',
          description:
            'Online experience hosted by Singapore Cricket Club Tennis. Members will have priority to book there preferred weekday classes with tennis director Christo Wiese.',
          bookingTimings: '8.00 Am to 10.00 Am',
          bookingPrice: '125',
        },
        {
          id: '2',
          classesImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677220332/probuddy/static/images/CategoriesPage/sports/SportsClassesImage2_dqggda.png',
          className: 'Group Tennis Classes Kids And Adults',
          instructorName: 'Dion Russell',
          location: 'Singapore',
          rating: '4.2',
          reviews: '229',
          category: 'sports',
          description: 'Book a session with Group tennis classes Kids and Adults',
          bookingTimings: '8.00 Am to 10.00 Am',
          bookingPrice: '120',
        },
        {
          id: '3',
          classesImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677220336/probuddy/static/images/CategoriesPage/sports/SportsClassesImage3_ofv3oi.png',
          className: 'Children Tennis Group Session',
          instructorName: 'Sam Henderson',
          location: 'Singapore',
          rating: '4.6',
          reviews: '168',
          category: 'sports',
          description: 'Learn this game of a lifetime with your friends or in a group setting.',
          bookingTimings: '8.00 Am to 10.00 Am',
          bookingPrice: '100',
        },
      ],
    },

    reviews: [
      {
        name: 'Courtney Henry',
        country: 'Hong Kong',
        imageSrc: ReviewImage1,
        description:
          'The Probuddy platform is a great solution for business owners. We highly recommend it for businesses that need to keep track of everything.',
      },
      {
        name: 'Simon Hart',
        country: 'Texas',
        imageSrc: ReviewImage2,
        description:
          'I have tried many online courses in my life, but none that have been so effective as Probuddy. The course has helped me with my business and gave me the tools to be more successful. The instructors are very knowledgeable and helpful. I have enjoyed the course and look forward to taking more classes.',
      },
      {
        name: 'Hayley Smith',
        country: 'Manchester',
        imageSrc: ReviewImage6,
        description:
          'Taking time out to destress with a tennis session is always a wonderful thing. Using Probuddy makes it even more enjoyable.',
      },
    ],

    features: {
      title: 'Post Your Request And Let Our Pro’s Connect with you right away!',
      description:
        'Match up with verified and certified pros near you with Pro Connect+. Just create a Match Request, and we’ll connect you with a perfect match.',
    },

    sellers: {
      title:
        'Join over 1000 sports, wellness, and adventure Pros that have seen their business grow with Probuddy.',
      linkText: 'Learn More',
    },
  },

  {
    id: 'wellness',
    title: 'Wellness Classes',
    description: 'All Wellness Classes As Flexible As You Are',
    heroImage:
      'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675484166/probuddy/static/images/CategoriesPage/Wellness/WellnessHeroImage_cdct6e.png',

    section1: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675484174/probuddy/static/images/CategoriesPage/Wellness/WellnessActivitiesImage_qqaulw.png',
      heading: 'Wellness classes, activities, and events that are made for you.',
      description:
        "Probuddy's mission is to help people realize their holistic wellness goals. That could be anything from staying healthy, to improving your mental health, or even feeling more energized. Whatever your wellness goals may be, we work with you every step of the way. Our goal is to make it easy for you to find a professional who can help you get healthy, fit, and happy for your body, mind & soul.",
      linkText: 'Book A Class Now',
    },

    section2: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675484188/probuddy/static/images/CategoriesPage/Wellness/FunSocialRelaxingImage_v4tvjr.png',
      heading: 'Find fun, social and relaxing classes and activities that match your schedule.',
      description:
        'We are a team of experienced professionals who have dedicated their lives to helping others reach their holistic wellness goals. Our goal is to motivate and inspire you to take action in your own life! Our program is designed for people looking for transformation or simply for enjoyment. We understand that our bodies are made up of many systems that work together as one.',
      linkText: 'Explore Our Listings',
    },

    exercise: {
      productTitle: 'Popular Wellness Listings in your area',
      title: 'Wellness Classes & Appointments Offered by Our Pros',
      options: [
        {
          name: 'Yoga',
          description:
            'We’ll take you through stress-relieving asanas to relax both your mind and body.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677220467/probuddy/static/images/CategoriesPage/Wellness/WellnessImage1_b7vpk0.png',
        },
        {
          name: 'Pilates',
          description:
            'Work your body, feel the flow. Take your workout to the next level with Pilates training.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677220472/probuddy/static/images/CategoriesPage/Wellness/WellnessImage2_g66sox.png',
        },
        {
          name: 'Traditional Chinese Medicine',
          description:
            'Focus on balance, harmony, and energy in the body and mind to promote overall well-being.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677220476/probuddy/static/images/CategoriesPage/Wellness/WellnessImage3_zjxpqz.png',
        },
        {
          name: 'Health Coach',
          description: 'Work side by side with our health and wellness coaches.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677220480/probuddy/static/images/CategoriesPage/Wellness/WellnessImage4_kt7zll.png',
        },
        {
          name: 'Personal Training',
          description: 'Improve your overall health, mobilize and restore muscle function.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677220484/probuddy/static/images/CategoriesPage/Wellness/WellnessImage5_a4gx4i.png',
        },
        {
          name: 'Massage & Spa',
          description: 'Treat yourself to a relaxing and rejuvenating body massage and spa.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677220488/probuddy/static/images/CategoriesPage/Wellness/WellnessImage6_pieiaz.png',
        },
      ],
    },

    whyBook: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675484195/probuddy/static/images/CategoriesPage/Wellness/BookWellnessImage_jlbz6z.png',
      heading1: 'Why Choose Us',
      position: 'start',
      containerPosition: 'start',
      description1:
        "Probuddy is the easiest way to book the best fitness & wellness classes across the globe - no more waiting or driving around to find the right class for you. Our goal is to help you find the perfect instructor or class in your area so that you can make your next activity one you'll never forget!",
      heading2: '',
      description2: '',
    },

    classesNearYou: {
      title: 'Round-the-World Holistic Wellness Classes & Appointments',
      description:
        'Probuddy gives you worldwide access to thousands of round-the-world holistic yoga classes, fitness gyms, nail salons, and massage & spa—all from one marketplace.',
    },

    instructors: {
      title: 'Top-Rated Wellness Coaches & Instructors',
      description:
        "You deserve a workout that isn't boring, and a community of like-minded people to push you to go further than you've gone before. At Probuddy we believe in the power of wellness and social connection to help you achieve your health and fitness goal.",
      instructorsData: [
        {
          id: '1',
          trainerImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677220787/probuddy/static/images/CategoriesPage/Yoga/YogaTrainerImage1_g1bp2r.png',
          name: 'Frankie Wong',
          type: 'Yoga Instructor',
          rating: '4.6',
          reviews: '22',
          category: 'wellness',
          description:
            'Center your body and mind, stretch the possibilities with personalized guidance from Probuddy yoga instructor Frankie Wong.',
        },
        {
          id: '2',
          trainerImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677220783/probuddy/static/images/CategoriesPage/Yoga/YogaTrainerImage2_ha6997.png',
          name: 'Carl Roberts',
          type: 'Yoga Instructor',
          rating: '4.6',
          reviews: '11',
          category: 'wellness',
          description:
            'Carl is a qualified and accredited yoga teacher and has been teaching yoga since 2015.',
        },
        {
          id: '3',
          trainerImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677220779/probuddy/static/images/CategoriesPage/Yoga/YogaTrainerImage3_vhqjva.png',
          name: 'Jenny Wilson',
          type: 'Yoga Instructor',
          rating: '4.6',
          reviews: '16',
          category: 'wellness',
          description:
            'Jenny embraces that yoga as a lifelong personal practice for the overall wellbeing of mind, body and spirit.',
        },
      ],
    },

    featured: {
      title: 'Most Popular Wellness Companies & Studios',
      description:
        "Whether you're looking for a new workout routine or want to deepen your practice, there are plenty of options available in your space.If you're looking for a new wellness studio and classes booking near you, take a look at these top-rated companies below.",
      featuredItems: [
        {
          id: '1',
          companyImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677220984/probuddy/static/images/CategoriesPage/Wellness/WellnessCompanyImage1_jglgn6.png',
          name: 'SmartFit Pilates',
          location: 'Tanglin Road, Singapore',
          rating: '4.6',
          reviews: '220',
          category: 'wellness',
          description:
            "We're a boutique Pilates studio at Tanglin Place that opened over 9 years ago to help every body live their best life through private, duet and small group Pilates (Reformer and other apparatus) sessions.",
        },
        {
          id: '2',
          companyImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677220984/probuddy/static/images/CategoriesPage/Wellness/WellnessCompanyImage1_jglgn6.png',
          name: 'SCC Tennis Academy',
          location: 'Connaught Dr., Singapore',
          rating: '4.6',
          reviews: '210',
          category: 'wellness',
          description:
            "SCC Tennis Academy is more than just a workout ~ it's a lifestyle that inspires confidence, community and celebration! Whether you are beginning an exercise program, a high level athlete, or just want to tone up, we have a program for you. Mindful movement is our motto and the common thread that runs through our philosophy and approach to living a healthy, happy life.",
        },
      ],
    },

    topRatedClasses: {
      title: 'Featured Wellness Classes & Activities At Your Fingertips',
      description:
        "Become the healthiest and better version of yourself! If you're looking for a yoga classes that can help you achieve the best results, look no further than the following list of top-rated yoga classes near you.",
      classesData: [
        {
          id: '1',
          classesImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677221049/probuddy/static/images/CategoriesPage/Wellness/WellnessClassesImage1_dqlqby.png',
          className: 'Private Yoga Sessions At Your Space Or Mine *Includes Travel',
          instructorName: 'Frankie Wong',
          location: 'Bridge Road, Singapore',
          rating: '4.8',
          reviews: '200',
          category: 'wellness',
          description:
            'Book a session with Private Yoga Sessions at Your Space or Mine *Includes Travel',
          bookingTimings: '8.00 Am to 10.00 Am',
          bookingPrice: '100',
        },
        {
          id: '2',
          classesImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677221049/probuddy/static/images/CategoriesPage/Wellness/WellnessClassesImage2_vkxlgq.png',
          className: 'Moving Our Bodies with PilatesPlus',
          instructorName: 'Pilates Plus',
          location: 'Shenton Way, Singapore',
          rating: '4.5',
          reviews: '202',
          category: 'sports',
          description:
            'At PilatesPlus, we provide unique and innovative ways of teaching Pilates exercise. We are dedicated to guiding our clients to achieve their fitness goal.',
          bookingTimings: '8.00 Am to 10.00 Am',
          bookingPrice: '120',
        },
        {
          id: '3',
          classesImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677221049/probuddy/static/images/CategoriesPage/Wellness/WellnessClassesImage3_reqa1y.png',
          className: 'Thai Oil Massage (Signature Service)',
          instructorName: 'Healing Thai Massage',
          location: 'Singapore',
          rating: '4.6',
          reviews: '230',
          category: 'sports',
          description:
            'Our Focus has always been to excel in traditional, time-tested therapies through a team of carefully selected and experienced, certified Massage Therapists.',
          bookingTimings: '8.00 Am to 10.00 Am',
          bookingPrice: '40',
        },
      ],
    },

    reviews: [
      {
        name: 'Tim McGowan',
        country: 'Sydney',
        imageSrc: ReviewImage1,
        description:
          'Working with Probuddy was a great experience. Their platform covers all of your business needs, and they are easy to work with. I would highly recommend them.',
      },
      {
        name: 'Austin Wade',
        country: 'New York',
        imageSrc: ReviewImage5,
        description:
          'The Probuddy platform is a great solution for business owners. We highly recommend it for businesses that need to keep track of everything.',
      },
      {
        name: 'Michelle Uy',
        country: 'Singapore',
        imageSrc: ReviewImage3,
        description:
          'I have booked a yoga session using Probuddy and it’s the best thing. The instructor is very warm and friendly, thank you very much for that!',
      },
    ],

    features: {
      title: 'Post Your Request And Let Our Pro’s Connect with you right away!',
      description:
        'Match up with verified and certified pros near you with Pro Connect+. Just create a Match Request, and we’ll connect you with a perfect match.',
    },

    sellers: {
      title:
        'Join over 1000 sports, wellness, and adventure Pros that have seen their business grow with Probuddy.',
      linkText: 'Learn More',
    },
  },

  {
    id: 'adventure',
    title: 'Adventure Classes',
    description: 'Quench Your Thirst For Outdoor Adventure',
    heroImage:
      'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677222824/probuddy/static/images/CategoriesPage/Adventure/AdventureHeroImage_mm5ree.png',

    section1: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675484626/probuddy/static/images/CategoriesPage/Adventure/SearchBookFunImage_wzkoyv.svg',
      heading: 'Search. Book. Have Fun Adrenaline Junkie',
      description:
        'Dive into the unknown with our outdoor activities. Swim against the tide, jump to the unknown and immerse yourself in the darkness of caves. Climb beyond your limits and listen to the symphony of nature. Tick off your Bucket List and discover your inner explorer with our outdoor activities. So, search our website for an adventure close to your heart and book it today!',
      linkText: 'Book A Class Now',
    },

    section2: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675484646/probuddy/static/images/CategoriesPage/Adventure/WhereverAndWheneverImage_dcxqdu.svg',
      heading: 'Go Wherever and Whenever You Want To Go',
      description:
        'Let us introduce you to the best of yourself by taking you on an exciting adventure. Get out of your comfort zone and explore the unknown with us. We have a wide variety of outdoor activities in our beautiful natural environment. We offer a variety of experiences from easy strolls along the beach to challenging treks through the jungle, we promise you will never be bored while you are with us.',
      linkText: 'Explore Our Listings',
    },

    exercise: {
      productTitle: 'Popular Adventure Listings in your area',
      title: 'All Recreation & Adventure Activities Hosted by our Pros',
      options: [
        {
          name: 'Rock Climbing',
          description: 'Looking for a fun weekend activity? Book a rock climbing sesh today!',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677223363/probuddy/static/images/CategoriesPage/Adventure/AdventureImage1_tf9apm.png',
        },
        {
          name: 'Surfing',
          description: 'Surf for all levels, meet new friends & make memories for life.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677223363/probuddy/static/images/CategoriesPage/Adventure/AdventureImage2_uupvhy.png',
        },
        {
          name: 'Sky Diving',
          description:
            'Learn to skydive solo and experience the thrill of freefall and deploying your own parachute.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677223363/probuddy/static/images/CategoriesPage/Adventure/AdventureImage3_pixc1j.png',
        },
        {
          name: 'Scuba Diving',
          description: 'Learning to scuba dive involves mastering basic scuba training and skills.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677223364/probuddy/static/images/CategoriesPage/Adventure/AdventureImage4_pbn9gp.png',
        },
        {
          name: 'Hiking',
          description:
            'Learn to hike, explore all types of terrain, basic guidelines, and safety measures.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677223363/probuddy/static/images/CategoriesPage/Adventure/AdventureImage5_iq7qij.png',
        },
        {
          name: 'ATV Off Road',
          description:
            'Bring out the adrenaline junkie in you with an exhilarating off-road ATV adventure.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677223364/probuddy/static/images/CategoriesPage/Adventure/AdventureImage6_ljvztq.png',
        },
      ],
    },

    whyBook: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675484662/probuddy/static/images/CategoriesPage/Adventure/BookAdventureImage_pfrsun.svg',
      heading1: 'Why Choose Us',
      position: 'start',
      containerPosition: 'start',
      description1:
        'Probuddy is a one-stop Marketplace for all things sports, wellness & adventure. It gives you worldwide access to thousands of top-rated fitness instructors, yoga classes, tennis coaches, or rock climbing events. All you need to do is find your ideal workout activity and book it!',
      heading2: '',
      description2: '',
    },

    classesNearYou: {
      title: 'Explore More Adventure & Recreation Activities Near You',
      description:
        "Don't miss out on the adventure! Let us help you plan a fun-filled adventure or recreational trip. Book now!",
    },

    instructors: {
      title: 'Go Beyond Your Limits With Our Top Rated Adventure Pros',
      description:
        'Book your next adventures with our Pros! Our team of experts has been out there and knows what it takes to make sure that your experience is as fulfilling as possible. Skip the hassle and book a tour now!',
      instructorsData: [
        {
          id: '1',
          trainerImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677223364/probuddy/static/images/CategoriesPage/Adventure/AdventureTrainerImage1_yeqhtk.png',
          name: 'Adventure Pro',
          type: 'Phil Harper',
          rating: '4.4',
          reviews: '13',
          category: 'adventure',
          description:
            'Book a hiking activity with adventure pro guide & instructor Phil. Open to beginners and pro hikers.',
        },
        {
          id: '2',
          trainerImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677223364/probuddy/static/images/CategoriesPage/Adventure/AdventureTrainerImage2_t1hxmp.png',
          name: 'Max Arnette',
          type: 'Adventure Pro',
          rating: '4.2',
          reviews: '11',
          category: 'adventure',
          description:
            'Book a wakeboarding classes with world-renowned instructor Max. Open to beginners and pro wakeboarders.',
        },
        {
          id: '3',
          trainerImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677223366/probuddy/static/images/CategoriesPage/Adventure/AdventureTrainerImage3_chkdmd.png',
          name: 'Jenny Soh',
          type: 'Adventure Pro',
          rating: '4.1',
          reviews: '16',
          category: 'adventure',
          description:
            'Book a freediving classes with world-renowned freediving instructor Jenny. Open to beginners and pro freedivers.',
        },
      ],
    },

    featured: {
      title: 'Top-Rated Outdoor Adventure Companies',
      description:
        'Packed with adventure, Probuddy gives you the chance to get out of your comfort zone and explore the unknown. Book your first adventure with us today!',
      featuredItems: [
        {
          id: '1',
          companyImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677223362/probuddy/static/images/CategoriesPage/Adventure/AdventureClassesImage1_exxzti.png',
          name: 'Climb Central Singapore',
          location: 'Central, Singapore',
          rating: '4.6',
          reviews: '230',
          category: 'adventure',
          description:
            "At Climb Central, we believe that climbing is for everyone. We offer a unique experience with easy-to-use safety set-ups for anyone to walk in and climb anytime we're open!",
        },
        {
          id: '2',
          companyImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677223362/probuddy/static/images/CategoriesPage/Adventure/AdventureClassesImage1_exxzti.png',
          name: 'Climb Central Singapore',
          location: 'Central, Singapore',
          rating: '4.7',
          reviews: '258',
          category: 'adventure',
          description:
            "At Climb Central, we believe that climbing is for everyone. We offer a unique experience with easy-to-use safety set-ups for anyone to walk in and climb anytime we're open!",
        },
      ],
    },

    topRatedClasses: {
      title: 'Most Popular Adventure Activities & Events',
      description:
        'Enjoy a fun day out with the family and friends at these adventurous events. See activities. Get the adrenaline pumping at these exciting events.',
      classesData: [
        {
          id: '1',
          classesImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677223362/probuddy/static/images/CategoriesPage/Adventure/AdventureClassesImage1_exxzti.png',
          className: 'Climb Central Singapore',
          instructorName: 'Climb Central',
          location: 'Bridge Road, Singapore',
          rating: '4.2',
          reviews: '250',
          category: 'adventure',
          description:
            'Online experience hosted by Singapore Cricket Club Tennis. Members will have priority to book there preferred weekday classes with tennis director Christo Wiese.',
          bookingTimings: '8.00 Am to 10.00 Am',
          bookingPrice: '100',
        },
        {
          id: '2',
          classesImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677223362/probuddy/static/images/CategoriesPage/Adventure/AdventureClassesImage2_kqgs4k.png',
          className: 'Learn To Skate And Cycle',
          instructorName: 'Learn To Skate and Cycle',
          location: 'Singapore',
          rating: '4.8',
          reviews: '230',
          category: 'adventure',
          description:
            'Don’t let a few unsuccessful attempts dampen your passion to skate and cycle. Everyone is unique and has different background.',
          bookingTimings: '8.00 Am to 10.00 Am',
          bookingPrice: '120',
        },
        {
          id: '3',
          classesImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677223362/probuddy/static/images/CategoriesPage/Adventure/AdventureClassesImage3_faiuqi.png',
          className: 'Learn to Surf at Surf Cove',
          instructorName: 'Surf Cove',
          location: 'Singapore',
          rating: '4.2',
          reviews: '176',
          category: 'adventure',
          description:
            'SURF COVE by Wave House Sentosa (expecting in 2022) occupies close to 15,000 sq feet of space and is a new integrated surfing and lifestyle concept destination.',
          bookingTimings: '8.00 Am to 10.00 Am',
          bookingPrice: '40',
        },
      ],
    },

    reviews: [
      {
        name: 'Sam Sebastian',
        country: 'Melbourne',
        imageSrc: ReviewImage1,
        description:
          'The Probuddy platform is a great solution for business owners. We highly recommend it for businesses that need to keep track of everything.',
      },
      {
        name: 'Courtney Henry',
        country: 'Los Angeles, CA',
        imageSrc: ReviewImage1,
        description:
          'I have tried many online courses in my life, but none that have been so effective as Probuddy. The course has helped me with my business and gave me the tools to be more successful. The instructors are very knowledgeable and helpful. I have enjoyed the course and look forward to taking more classes.',
      },
      {
        name: 'Jeff Yao',
        country: 'Singapore',
        imageSrc: ReviewImage4,
        description:
          'Working with Probuddy was a great experience. Their platform covers all of your business needs, and they are easy to work with. I would highly recommend them.',
      },
    ],

    features: {
      title: 'Post Your Request And Let Our Pro’s Connect with you right away!',
      description:
        'Match up with verified and certified pros near you with Pro Connect+. Just create a Match Request, and we’ll connect you with a perfect match.',
    },

    sellers: {
      title:
        'Join over 1000 sports, wellness, and adventure Pros that have seen their business grow with Probuddy.',
      linkText: 'Learn More',
    },
  },

  {
    id: 'yoga',
    title: 'Yoga Classes',
    description: 'Build Strength and Wellness through Yoga',
    heroImage:
      'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675485809/probuddy/static/images/CategoriesPage/Yoga/YogaHeroImage_bgzyfq.svg',

    section1: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677224089/probuddy/static/images/CategoriesPage/Yoga/workoutProgram_vf8csc.png',
      heading: 'YogaPage.yogaRight',
      description:
        'If you are looking for the best yoga classes in Singapore, then you have come to the right place. We offer a wide range of yoga classes for those who want to learn about their body and mind.  Our yoga teachers are experienced professionals who ensure that each class is not only enjoyable but also beneficial. Whether it’s a group or private session, we provide a safe and friendly environment for everyone to learn how to achieve their health goals.',
      linkText: 'Book A Yoga Class Now',
    },

    section2: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677224089/probuddy/static/images/CategoriesPage/Yoga/certifiedInstructorsImage_uyyhwb.png',
      heading: 'YogaPage.certifiedInstructors',
      description:
        "All instructors have been trained to world-class standards by one of the most respected yoga instructors in the world. All instructors are certified and licensed ensuring that they are well-versed in both the practice and philosophy of yoga. All of our classes are taught by certified yoga instructors, so you can be sure that you're learning from an expert in the field. This ensures that your yoga experience will be safe, effective, and enjoyable.",
      linkText: 'Book A Yoga Class Now',
    },

    exercise: {
      productTitle: 'Popular Yoga Listings in your area',
      title: 'Health Benefits Of Yoga',
      // options: [
      //   {
      //     name: 'Helps Improve Posture',
      //     description:
      //       'Yoga improves posture, which can prevent low-back pain, as well as shoulder and neck pain.',
      //     imageSrc:
      //       'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677220819/probuddy/static/images/CategoriesPage/Yoga/YogaImage1_xb0d2y.png',
      //   },
      //   {
      //     name: 'Effective Brain Development',
      //     description: 'Yoga promotes a stronger and healthier brain.',
      //     imageSrc:
      //       'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677220813/probuddy/static/images/CategoriesPage/Yoga/YogaImage2_f7wtas.png',
      //   },
      //   {
      //     name: 'Reinforces better breathing',
      //     description: 'Yoga helps you to relax and focus on yourself.',
      //     imageSrc:
      //       'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677220810/probuddy/static/images/CategoriesPage/Yoga/YogaImage3_jboany.png',
      //   },
      //   {
      //     name: 'Relief from stress and anxiety',
      //     description: 'It also helps you sleep better and feel good about yourself.',
      //     imageSrc:
      //       'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677220802/probuddy/static/images/CategoriesPage/Yoga/YogaImage4_gh0k3q.png',
      //   },
      //   {
      //     name: 'Reduced blood pressure',
      //     description:
      //       'Yoga can help improve your concentration and focus, which will help you accomplish your goals in life.',
      //     imageSrc:
      //       'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677220798/probuddy/static/images/CategoriesPage/Yoga/YogaImage5_zc12lz.png',
      //   },
      //   {
      //     name: 'Increased flexibility',
      //     description:
      //       'Yoga is great for those who want to become more flexible, strengthen their bodies, or lose weight.',
      //     imageSrc:
      //       'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677220794/probuddy/static/images/CategoriesPage/Yoga/YogaImage6_gcshbg.png',
      //   },
      // ],
      options: [
        {
          name: 'Promote Good Posture',
          description:
            'Yoga promotes good posture by strengthening the core muscles and improving body awareness.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677220819/probuddy/static/images/CategoriesPage/Yoga/YogaImage1_xb0d2y.png',
        },
        {
          name: 'Effective Brain Development',
          description: 'Yoga promotes a stronger and healthier brain.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677220813/probuddy/static/images/CategoriesPage/Yoga/YogaImage2_f7wtas.png',
        },
        {
          name: 'Reinforces better breathing',
          description: 'Yoga helps you to relax and focus on yourself.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677220810/probuddy/static/images/CategoriesPage/Yoga/YogaImage3_jboany.png',
        },
        {
          name: 'Relief from stress and anxiety',
          description: 'It also helps you sleep better and feel good about yourself.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677220802/probuddy/static/images/CategoriesPage/Yoga/YogaImage4_gh0k3q.png',
        },
        {
          name: 'Reduced blood pressure',
          description:
            'Yoga can help improve your concentration and focus, which will help you accomplish your goals in life.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677220798/probuddy/static/images/CategoriesPage/Yoga/YogaImage5_zc12lz.png',
        },
        {
          name: 'Enhanced Range of Motion',
          description:
            'Yoga enhances range of motion by focusing on movements that gently stretch and lengthen the muscles throughout the body.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677220794/probuddy/static/images/CategoriesPage/Yoga/YogaImage6_gcshbg.png',
        },
      ],
    },

    whyBook: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677229881/probuddy/static/images/CategoriesPage/Yoga/BookYogaImage_qctlnk.png',
      heading1: 'One Marketplace for All Things Sports, Events and Adventure',
      position: 'center',
      description1:
        'Probuddy is a one-stop Marketplace for all things sports, wellness & adventure. It gives you worldwide access to thousands of top-rated fitness instructors, yoga classes, tennis coaches, or rock climbing events. All you need to do is find your ideal sportds, wellness, or adventure activity and book it!',
      heading2: 'Why Book on Probuddy?',
      description2:
        "Probuddy is the easiest way to book the best fitness classes across the globe - no more waiting or driving around to find the right class for you. Our goal is to help you find the perfect instructor or class in your area so that you can make your next activity one you'll never forget!",
    },

    classesNearYou: {
      title: 'Book The Best Yoga Classes Near You',
      description:
        'Yoga is a very popular form of exercise and  there are hundreds of yoga classes for beginners, intermediate and advanced practitioners. Our goal is to help you find the right yoga classes near you while keeping your body, mind, and spirit for deeper healing and rejuvenation. Book now and start your yoga journey.',
    },

    instructors: {
      title: 'Client’s Choice Freelance ProYoga Instructors',
      description:
        'Our certified freelance yoga instructors are passionate about helping you grow in body and mind. They are dedicated to spreading positivity and inspiration through their classes. Browse through our offerings and book a class today!',
      instructorsData: [
        {
          id: '1',
          trainerImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677220787/probuddy/static/images/CategoriesPage/Yoga/YogaTrainerImage1_g1bp2r.png',
          name: 'Frankie Wong',
          type: 'Yoga Instructor',
          rating: '4.4',
          reviews: '18',
          category: 'yoga',
          description:
            'Center your body and mind, stretch the possibilities with personalized guidance from Probuddy yoga instructor Frankie Wong.',
        },
        {
          id: '2',
          trainerImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677220783/probuddy/static/images/CategoriesPage/Yoga/YogaTrainerImage2_ha6997.png',
          name: 'Carl Roberts',
          type: 'Yoga Instructor',
          rating: '4.2',
          reviews: '13',
          category: 'yoga',
          description:
            'Carl is a qualified and accredited yoga teacher and has been teaching yoga since 2015.',
        },
        {
          id: '3',
          trainerImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677220779/probuddy/static/images/CategoriesPage/Yoga/YogaTrainerImage3_vhqjva.png',
          name: 'Jenny Wilson',
          type: 'Yoga Instructor',
          rating: '4.6',
          reviews: '12',
          category: 'yoga',
          description:
            'Jenny embraces that yoga as a lifelong personal practice for the overall wellbeing of mind, body and spirit.',
        },
      ],
    },

    featured: {
      title: 'Top-Rated Yoga Studios and Companies',
      description:
        "Whether you're looking for a new workout routine or want to deepen your practice, there are plenty of options available in your area.If you're looking for a new yoga studios and events booking near you, take a look at these best-reviewed companies below.",
      featuredItems: [
        {
          id: '1',
          companyImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677220828/probuddy/static/images/CategoriesPage/Yoga/YogaCompanyImage1_utyly8.png',
          name: 'Barre 2 Barre',
          location: 'Pickering, Singapore',
          rating: '4.7',
          reviews: '210',
          category: 'yoga',
          description:
            "Barre 2 Barre is more than just a workout ~ it's a lifestyle that inspires confidence, community and celebration! Whether you are beginning an exercise program, a high level athlete, or just want to tone up, we have a program for you. Mindful movement is our motto and the common thread that runs through our philosophy and approach to living a healthy, happy life.",
        },
        {
          id: '2',
          companyImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677220828/probuddy/static/images/CategoriesPage/Yoga/YogaCompanyImage1_utyly8.png',
          name: 'Barre 2 Barre',
          location: 'Pickering, Singapore',
          rating: '4.6',
          reviews: '230',
          category: 'yoga',
          description:
            "Barre 2 Barre is more than just a workout ~ it's a lifestyle that inspires confidence, community and celebration! Whether you are beginning an exercise program, a high level athlete, or just want to tone up, we have a program for you. Mindful movement is our motto and the common thread that runs through our philosophy and approach to living a healthy, happy life.",
        },
      ],
    },

    topRatedClasses: {
      title: 'Find More Top-Rated Yoga Classes',
      description:
        "Get ready for a life-changing yoga experience!  Yoga is a wonderful way to relax and de-stress. It improves your body's flexibility, strength, and balance. If you're looking for a yoga classes that can help you achieve the best results, look no further than the following list of top-rated yoga classes near you.",
      classesData: [
        {
          id: '1',
          classesImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677220840/probuddy/static/images/CategoriesPage/Yoga/YogaClassesImage1_ycvwqj.png',
          className: 'Private Yoga Sessions At Your Space Or Mine *Includes Travel',
          instructorName: 'Frankie Wong',
          location: 'Bridge Road, Singapore',
          rating: '4.6',
          reviews: '196',
          category: 'yoga',
          description:
            'Book a session with Private Yoga Sessions at Your Space or Mine *Includes Travel.',
          bookingTimings: '8.00 Am to 10.00 Am',
          bookingPrice: '100',
        },
        {
          id: '2',
          classesImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677220836/probuddy/static/images/CategoriesPage/Yoga/YogaClassesImage2_xvxlui.png',
          className: 'Morning Meditation With Yoga',
          instructorName: 'Dion Russell',
          location: 'Los Angeles, CA',
          rating: '4.8',
          reviews: '230',
          category: 'yoga',
          description:
            'This class focuses on slowing down the body to allow it to calm down, heal, and recover. Recommended for everyone.',
          bookingTimings: '8.00 Am to 10.00 Am',
          bookingPrice: '120',
        },
        {
          id: '3',
          classesImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677220831/probuddy/static/images/CategoriesPage/Yoga/YogaClassesImage3_ardfoj.png',
          className: 'Basics, Core, Zen, Hit Yoga Session',
          instructorName: 'Sam Henderson',
          location: 'Melbourne, AU',
          rating: '4.6',
          reviews: '250',
          category: 'yoga',
          description:
            'A multi-level Vinyasa based class that integrates more effective learning and discerning yoga experience.',
          bookingTimings: '8.00 Am to 10.00 Am',
          bookingPrice: '100',
        },
      ],
    },

    reviews: [
      {
        name: 'Sam Sebastian',
        country: 'Melbourne',
        imageSrc: ReviewImage1,
        description:
          'The Probuddy platform is a great solution for business owners. We highly recommend it for businesses that need to keep track of everything.',
      },
      {
        name: 'Courtney Henry',
        country: 'Los Angeles, CA',
        imageSrc: ReviewImage1,
        description:
          'I have tried many online courses in my life, but none that have been so effective as Probuddy. The course has helped me with my business and gave me the tools to be more successful. The instructors are very knowledgeable and helpful. I have enjoyed the course and look forward to taking more classes.',
      },
      {
        name: 'Jeff Yao',
        country: 'Singapore',
        imageSrc: ReviewImage4,
        description:
          'Working with Probuddy was a great experience. Their platform covers all of your business needs, and they are easy to work with. I would highly recommend them.',
      },
    ],

    features: {
      title: 'Looking for a Yoga Instructor? We can help.',
      description:
        'Match up with verified and certified pros near you with Pro Connect+. Just create a Match Request, and we’ll connect you with a perfect match.',
    },

    sellers: {
      title:
        'Join over 1000 sports, wellness, and adventure Pros that have seen their business grow with Probuddy.',
      linkText: 'Learn More',
    },
  },

  {
    id: 'adventure',
    title: 'Adventure Classes',
    description: 'Quench Your Thirst For Outdoor Adventure',
    heroImage:
      'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677222824/probuddy/static/images/CategoriesPage/Adventure/AdventureHeroImage_mm5ree.png',

    section1: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675484626/probuddy/static/images/CategoriesPage/Adventure/SearchBookFunImage_wzkoyv.svg',
      heading: 'Search. Book. Have Fun Adrenaline Junkie',
      description:
        'Dive into the unknown with our outdoor activities. Swim against the tide, jump to the unknown and immerse yourself in the darkness of caves. Climb beyond your limits and listen to the symphony of nature. Tick off your Bucket List and discover your inner explorer with our outdoor activities. So, search our website for an adventure close to your heart and book it today!',
      linkText: 'Book A Class Now',
    },

    section2: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675484646/probuddy/static/images/CategoriesPage/Adventure/WhereverAndWheneverImage_dcxqdu.svg',
      heading: 'Go Wherever and Whenever You Want To Go',
      description:
        'Let us introduce you to the best of yourself by taking you on an exciting adventure. Get out of your comfort zone and explore the unknown with us. We have a wide variety of outdoor activities in our beautiful natural environment. We offer a variety of experiences from easy strolls along the beach to challenging treks through the jungle, we promise you will never be bored while you are with us.',
      linkText: 'Explore Our Listings',
    },

    exercise: {
      title: 'All Recreation & Adventure Activities Hosted by our Pros',
      options: [
        {
          name: 'Rock Climbing',
          description: 'Looking for a fun weekend activity? Book a rock climbing sesh today!',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677223363/probuddy/static/images/CategoriesPage/Adventure/AdventureImage1_tf9apm.png',
        },
        {
          name: 'Surfing',
          description: 'Surf for all levels, meet new friends & make memories for life.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677223363/probuddy/static/images/CategoriesPage/Adventure/AdventureImage2_uupvhy.png',
        },
        {
          name: 'Sky Diving',
          description:
            'Learn to skydive solo and experience the thrill of freefall and deploying your own parachute.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677223363/probuddy/static/images/CategoriesPage/Adventure/AdventureImage3_pixc1j.png',
        },
        {
          name: 'Scuba Diving',
          description: 'Learning to scuba dive involves mastering basic scuba training and skills.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677223364/probuddy/static/images/CategoriesPage/Adventure/AdventureImage4_pbn9gp.png',
        },
        {
          name: 'Hiking',
          description:
            'Learn to hike, explore all types of terrain, basic guidelines, and safety measures.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677223363/probuddy/static/images/CategoriesPage/Adventure/AdventureImage5_iq7qij.png',
        },
        {
          name: 'ATV Off Road',
          description:
            'Bring out the adrenaline junkie in you with an exhilarating off-road ATV adventure.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677223364/probuddy/static/images/CategoriesPage/Adventure/AdventureImage6_ljvztq.png',
        },
      ],
    },

    whyBook: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675484662/probuddy/static/images/CategoriesPage/Adventure/BookAdventureImage_pfrsun.svg',
      heading1: 'Why Choose Us',
      position: 'start',
      containerPosition: 'start',
      description1:
        'Probuddy is a one-stop Marketplace for all things sports, wellness & adventure. It gives you worldwide access to thousands of top-rated fitness instructors, yoga classes, tennis coaches, or rock climbing events. All you need to do is find your ideal workout activity and book it!',
      heading2: '',
      description2: '',
    },

    classesNearYou: {
      title: 'Explore More Adventure & Recreation Activities Near You',
      description:
        "Don't miss out on the adventure! Let us help you plan a fun-filled adventure or recreational trip. Book now!",
    },

    instructors: {
      title: 'Go Beyond Your Limits With Our Top Rated Adventure Pros',
      description:
        'Book your next adventures with our Pros! Our team of experts has been out there and knows what it takes to make sure that your experience is as fulfilling as possible. Skip the hassle and book a tour now!',
      instructorsData: [
        {
          id: '1',
          trainerImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677223364/probuddy/static/images/CategoriesPage/Adventure/AdventureTrainerImage1_yeqhtk.png',
          name: 'Adventure Pro',
          type: 'Phil Harper',
          rating: '4.2',
          reviews: '15',
          category: 'adventure',
          description:
            'Book a hiking activity with adventure pro guide & instructor Phil. Open to beginners and pro hikers.',
        },
        {
          id: '2',
          trainerImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677223364/probuddy/static/images/CategoriesPage/Adventure/AdventureTrainerImage2_t1hxmp.png',
          name: 'Max Arnette',
          type: 'Adventure Pro',
          rating: '4.3',
          reviews: '15',
          category: 'adventure',
          description:
            'Book a wakeboarding classes with world-renowned instructor Max. Open to beginners and pro wakeboarders.',
        },
        {
          id: '3',
          trainerImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677223366/probuddy/static/images/CategoriesPage/Adventure/AdventureTrainerImage3_chkdmd.png',
          name: 'Jenny Soh',
          type: 'Adventure Pro',
          rating: '4.4',
          reviews: '16',
          category: 'adventure',
          description:
            'Book a freediving classes with world-renowned freediving instructor Jenny. Open to beginners and pro freedivers.',
        },
      ],
    },

    featured: {
      title: 'Top-Rated Outdoor Adventure Companies',
      description:
        'Packed with adventure, Probuddy gives you the chance to get out of your comfort zone and explore the unknown. Book your first adventure with us today!',
      featuredItems: [
        {
          id: '1',
          companyImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677223362/probuddy/static/images/CategoriesPage/Adventure/AdventureClassesImage1_exxzti.png',
          name: 'Climb Central Singapore',
          location: 'Central, Singapore',
          rating: '4.4',
          reviews: '218',
          category: 'adventure',
          description:
            "At Climb Central, we believe that climbing is for everyone. We offer a unique experience with easy-to-use safety set-ups for anyone to walk in and climb anytime we're open!",
        },
        {
          id: '2',
          companyImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677223362/probuddy/static/images/CategoriesPage/Adventure/AdventureClassesImage1_exxzti.png',
          name: 'Climb Central Singapore',
          location: 'Central, Singapore',
          rating: '4.5',
          reviews: '235',
          category: 'adventure',
          description:
            "At Climb Central, we believe that climbing is for everyone. We offer a unique experience with easy-to-use safety set-ups for anyone to walk in and climb anytime we're open!",
        },
      ],
    },

    topRatedClasses: {
      title: 'Most Popular Adventure Activities & Events',
      description:
        'Enjoy a fun day out with the family and friends at these adventurous events. See activities. Get the adrenaline pumping at these exciting events.',
      classesData: [
        {
          id: '1',
          classesImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677223362/probuddy/static/images/CategoriesPage/Adventure/AdventureClassesImage1_exxzti.png',
          className: 'Climb Central Singapore',
          instructorName: 'Climb Central',
          location: 'Bridge Road, Singapore',
          rating: '4.6',
          reviews: '226',
          category: 'adventure',
          description:
            'Online experience hosted by Singapore Cricket Club Tennis. Members will have priority to book there preferred weekday classes with tennis director Christo Wiese.',
          bookingTimings: '8.00 Am to 10.00 Am',
          bookingPrice: '100',
        },
        {
          id: '2',
          classesImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677223362/probuddy/static/images/CategoriesPage/Adventure/AdventureClassesImage2_kqgs4k.png',
          className: 'Learn To Skate And Cycle',
          instructorName: 'Learn To Skate and Cycle',
          location: 'Singapore',
          rating: '4.3',
          reviews: '231',
          category: 'adventure',
          description:
            'Don’t let a few unsuccessful attempts dampen your passion to skate and cycle. Everyone is unique and has different background.',
          bookingTimings: '8.00 Am to 10.00 Am',
          bookingPrice: '120',
        },
        {
          id: '3',
          classesImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677223362/probuddy/static/images/CategoriesPage/Adventure/AdventureClassesImage3_faiuqi.png',
          className: 'Learn to Surf at Surf Cove',
          instructorName: 'Surf Cove',
          location: 'Singapore',
          rating: '4.4',
          reviews: '186',
          category: 'adventure',
          description:
            'SURF COVE by Wave House Sentosa (expecting in 2022) occupies close to 15,000 sq feet of space and is a new integrated surfing and lifestyle concept destination.',
          bookingTimings: '8.00 Am to 10.00 Am',
          bookingPrice: '40',
        },
      ],
    },

    reviews: [
      {
        name: 'Courtney Henry',
        country: 'Hong Kong',
        imageSrc: ReviewImage1,
        description:
          'The Probuddy platform is a great solution for business owners. We highly recommend it for businesses that need to keep track of everything.',
      },
      {
        name: 'Simon Hart',
        country: 'Texas',
        imageSrc: ReviewImage2,
        description:
          'I have tried many online courses in my life, but none that have been so effective as Probuddy. The course has helped me with my business and gave me the tools to be more successful. The instructors are very knowledgeable and helpful. I have enjoyed the course and look forward to taking more classes.',
      },
      {
        name: 'Hayley Smith',
        country: 'Manchester',
        imageSrc: ReviewImage6,
        description:
          'Taking time out to destress with a tennis session is always a wonderful thing. Using Probuddy makes it even more enjoyable.',
      },
    ],

    features: {
      title: 'Post Your Request And Let Our Pro’s Connect with you right away!',
      description:
        'Match up with verified and certified pros near you with Pro Connect+. Just create a Match Request, and we’ll connect you with a perfect match.',
    },

    sellers: {
      title:
        'Join over 1000 sports, wellness, and adventure Pros that have seen their business grow with Probuddy.',
      linkText: 'Learn More',
    },
  },

  {
    id: 'tennis',
    title: 'Tennis Classes',
    description: 'Tennis Classes in Singapore',
    heroImage:
      'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1685082963/probuddy/static/images/CategoriesPage/Tennis/tenis_heroImage_3_gd0ijc.png',

    section1: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677224835/probuddy/static/images/CategoriesPage/Tennis/TennisPlayersImage_rwynjg.png',
      heading: 'Popular Tennis Classes in Singapore',
      description:
        'Find the perfect Tennis Classes in Singapore with Probuddy. We have listings for Tennis Classes all across Singapore. Save time and find the perfect location with a few clicks on Probuddy.',
      linkText: 'Book A Tennis Class Now',
    },

    section2: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677224837/probuddy/static/images/CategoriesPage/Tennis/ProfessionalTennisCoachesImage_fy3jgw.png',
      heading: 'Find the Best Sports Classes in Singapore',
      description: `It's never been easier to find a top-rated class & coaches with one search. Meet our profile of the most popular classes in your area and book on the spot. `,
      linkText: 'Become A Tennis Pro',
    },

    exercise: {
      productTitle: 'Popular Tennis Listings in your area',
      title: 'Types of Tennis Classes Hosted by Pros',
      options: [
        {
          name: 'Kids Tennis Lesson',
          description:
            'Tennis helps build your kids self-confidence and develop good sportsmanship.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677224836/probuddy/static/images/CategoriesPage/Tennis/TennisImage1_d9qywe.png',
        },
        {
          name: 'Private Tennis Class',
          description:
            'A one-to-one private session with an experienced and world-class tennis coach.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677224836/probuddy/static/images/CategoriesPage/Tennis/TennisImage2_khqriu.png',
        },
        {
          name: 'Semi-Private Tennis Lesson',
          description: 'Suitable for tennis beginners and intermediate tennis players.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677224836/probuddy/static/images/CategoriesPage/Tennis/TennisImage3_ha25c2.png',
        },
        {
          name: 'Ladies Tennis Training',
          description: 'Our certified tennis coaches will guide you professionally.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677224836/probuddy/static/images/CategoriesPage/Tennis/TennisImage4_rj2zev.png',
        },
        {
          name: 'Group Tennis Lesson',
          description: 'Learn and spend time together with your family and friends.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677224835/probuddy/static/images/CategoriesPage/Tennis/TennisImage5_pqnxub.png',
        },
        {
          name: 'Corporate Tennis Lesson',
          description:
            'Enjoy team building activities as a company. Build stronger connections with your colleagues.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677224835/probuddy/static/images/CategoriesPage/Tennis/TennisImage6_b2tkaa.png',
        },
      ],
    },

    whyBook: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677224837/probuddy/static/images/CategoriesPage/Tennis/BookTennisImage_uut7me.png',
      heading1: 'Find the Best Sports Classes in Singapore',
      position: 'center',
      containerPosition: 'start',
      description1: `It's never been easier to find a top-rated class & coaches with one search. Meet our profile of the most popular classes in your area and book on the spot.`,
      heading2: 'Why Book on Probuddy for Tennis Classes?',
      description2:
        'Probuddy is the easiest platform to use to book the best classes across Singapore. No more waiting or driving around the city to find the right classes for you. Our goal is to help you find the perfect instructor or class in your area so that you can make your next activity.',
    },

    classesNearYou: {
      title: 'Book The Best Tennis Classes Near You',
      description:
        'Learn tennis with the Pros in your area. Book a class with these world-renowned tennis coaches who are dedicated to improve your skills and abilities.',
    },

    instructors: {
      title: 'Featured Top-Rated Wellness Coaches & Instructors',
      description:
        'Learn tennis with the Pros in your area. Book a class with these world-renowned tennis coaches who are dedicated to improve your skills and abilities.',
      instructorsData: [
        {
          id: '1',
          trainerImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661939156/probuddy/static/images/CategoriesPage/Tennis/TennisTrainer1_qy1dwe.png',
          name: 'Jaime Boliver',
          type: 'Christo Weise',
          rating: '4.4',
          reviews: '13',
          category: 'sports',
          description:
            'Delivering well-structured, customised tennis programs. Offering private tennis lessons for kids, juniors and adults.',
        },
        {
          id: '2',
          trainerImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661939155/probuddy/static/images/CategoriesPage/Tennis/TennisTrainer2_g1hg9j.png',
          name: 'Kris Wintel',
          type: 'Tennis Coach',
          rating: '4.5',
          reviews: '22',
          category: 'sports',
          description:
            'Train with tennis Pro Kris Wintel to develop your fastest and most efficient movement in a private or group tennis classes.',
        },
        {
          id: '3',
          trainerImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661939156/probuddy/static/images/CategoriesPage/Tennis/TennisTrainer3_ggdyyc.png',
          name: 'Carlos Chien',
          type: 'Tennis Coach',
          rating: '4.3',
          reviews: '12',
          category: 'sports',
          description:
            'Looking to learn basics of tennis, get your game to another level or to have a hit with high performance coach Carlos.',
        },
      ],
    },

    featured: {
      title: 'Popular Tennis Classes and Events Bookings',
      description:
        "Whether you're looking for a new workout routine or want to deepen your practice, there are plenty of options available in your area.If you're looking for new tennis lessons and events booking near you, take a look at these top-rated companies below.",
      featuredItems: [
        {
          id: '1',
          companyImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660296657/probuddy/static/images/CategoriesPage/Tennis/TennisCompanyImage1_c1jf8z.svg',
          name: 'Olaso Tennis Program ',
          location: 'ONE°15 Marina Sentosa Cove, Singapore',
          rating: '4.3',
          reviews: '187',
          category: 'boxing',
          description:
            'Olaso Tennis program now available at ONE°15 Marina Sentosa Cove, Singapore, is a waterfront lifestyle destination offering world-class marina facilities replete with a comprehensive range of exclusive club amenities. From the infinity pool, fitness centre, members’ lounge, modern spa, restaurants and bars, to the 26 tastefully appointed rooms with spectacular views, no effort has been spared to make your time here an absolute pleasure.',
        },
        {
          id: '2',
          companyImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660296657/probuddy/static/images/CategoriesPage/Tennis/TennisCompanyImage1_c1jf8z.svg',
          name: 'Olaso Tennis Program ',
          location: 'ONE°15 Marina Sentosa Cove, Singapore',
          rating: '4.6',
          reviews: '185',
          category: 'boxing',
          description:
            'Olaso Tennis program now available at ONE°15 Marina Sentosa Cove, Singapore, is a waterfront lifestyle destination offering world-class marina facilities replete with a comprehensive range of exclusive club amenities. From the infinity pool, fitness centre, members’ lounge, modern spa, restaurants and bars, to the 26 tastefully appointed rooms with spectacular views, no effort has been spared to make your time here an absolute pleasure.',
        },
      ],
    },

    topRatedClasses: {
      title: 'Featured Tennis Classes and Lessons near you',
      description:
        'Let our our world-class tennis coaches take your game to the next level with our featured tennis classes and lessons in your area.',
      classesData: [
        {
          id: '1',
          classesImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661939155/probuddy/static/images/CategoriesPage/Tennis/TennisClasses1_jmbcd4.png',
          className: 'Private  Tennis Classes for Adults',
          instructorName: 'Ricky Lowe',
          location: 'Brooklyn, NY',
          rating: '4.3',
          reviews: '196',
          category: 'boxing',
          description:
            'Private and group lessons available. Perfect for beginners & advanced. Fully guided session. Certified coaches.',
          bookingTimings: '8.00 Am to 10.00 Am',
          bookingPrice: '120',
        },
        {
          id: '2',
          classesImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661939155/probuddy/static/images/CategoriesPage/Tennis/TennisClasses2_r9byro.png',
          className: 'Children Tennis Group Session',
          instructorName: 'Stephen Chao',
          location: 'Singapore',
          rating: '4.8',
          reviews: '270',
          category: 'boxing',
          description:
            'Tennis lessons kids. Learn to play with professional coaches. We offer private tennis lessons for kids in your condo.',
          bookingTimings: '8.00 Am to 10.00 Am',
          bookingPrice: '40',
        },
        {
          id: '3',
          classesImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661939155/probuddy/static/images/CategoriesPage/Tennis/TennisClasses3_d9g6xi.png',
          className: 'Tennis Ladies Training (3 Players)',
          instructorName: 'Jenn Teller',
          location: 'Los Angeles, CA',
          rating: '4.6',
          reviews: '134',
          category: 'boxing',
          description:
            'Learn this game of a lifetime with your friends or in a group setting.  Intensive 4 day programs.',
          bookingTimings: '8.00 Am to 10.00 Am',
          bookingPrice: '150',
        },
      ],
    },

    reviews: [
      {
        name: 'Tim McGowan',
        country: 'Sydney',
        imageSrc: ReviewImage1,
        description:
          'Working with Probuddy was a great experience. Their platform covers all of your business needs, and they are easy to work with. I would highly recommend them.',
      },
      {
        name: 'Austin Wade',
        country: 'New York',
        imageSrc: ReviewImage5,
        description:
          'The Probuddy platform is a great solution for business owners. We highly recommend it for businesses that need to keep track of everything.',
      },
      {
        name: 'Michelle Uy',
        country: 'Singapore',
        imageSrc: ReviewImage3,
        description:
          'I have booked a yoga session using Probuddy and it’s the best thing. The instructor is very warm and friendly, thank you very much for that!',
      },
    ],

    features: {
      title: 'Looking for Tennis Classes? We can help.',
      description:
        'Match up with verified and certified pros near you with Pro Connect+. Just create a Match Request, and we’ll connect you with a perfect match.',
    },

    sellers: {
      title:
        'Join over 1000 sports, wellness, and adventure Pros that have seen their business grow with Probuddy.',
      linkText: 'Learn More',
    },
  },

  {
    id: 'boxing',
    title: 'Boxing Classes',
    description: 'Boxing Classes To Knock You In Good Shape',
    heroImage:
      'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660379691/probuddy/static/images/CategoriesPage/Boxing/BoxingHeroImage_hhxmln.png',

    section1: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677228667/probuddy/static/images/CategoriesPage/Boxing/BoxingTrainingSessionsImage_y13r3f.png',
      heading: 'Tailor Made Boxing Training Sessions',
      description:
        'If you are looking for a fun and easy way to improve your fitness or just want to learn how to box, Probuddy can help. With boxing classes near you, you can train safely in a group or private setting that’s tailored to your needs. Our structured workouts are cater to all levels, beginners to advanced, and our experts will help you reach your fitness goals with ease.',
      linkText: 'Book A Boxing Class Now',
    },

    section2: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677228667/probuddy/static/images/CategoriesPage/Boxing/ProfessionalCoachesImage_xr17e7.png',
      heading: 'Accredited and Professional Coaches to Guide You',
      description:
        'Whether you are experienced or beginner, Probuddy has everything you need to learn the sport. Find your progression, develop your fitness all while improving technique, power, speed and endurance. Our Probuddy team’s certified coaches can help you through every step of your journey. Gloves up! ',
      linkText: 'Become a Boxing Pro',
    },

    exercise: {
      productTitle: 'Popular Boxing Listings in your area',
      title: 'Types of Boxing Classes Hosted by Pros',
      options: [
        {
          name: 'Boxing Training for Beginners',
          description:
            'Highly recommended for beginners and for those who wish to refine their boxing fundamentals.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677228664/probuddy/static/images/CategoriesPage/Boxing/BoxingImage1_mqfipq.png',
        },
        {
          name: 'Boxing Class for Kids',
          description:
            'Our kids boxing also teaches and cultivates discipline and sportsmanship within the kids.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677228665/probuddy/static/images/CategoriesPage/Boxing/BoxingImage2_loh2ag.png',
        },
        {
          name: 'Private Boxing Training',
          description:
            'Tailored to meet your own fitness goals while also improving your form, coordination and stamina.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677228665/probuddy/static/images/CategoriesPage/Boxing/BoxingImage3_bdidcn.png',
        },
        {
          name: 'Strength & Conditioning',
          description:
            "Physical training techniques that boxers do to achieve a boxer's stamina, speed and endurance.",
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677228666/probuddy/static/images/CategoriesPage/Boxing/BoxingImage4_ulchto.png',
        },
        {
          name: 'Competitive Training',
          description:
            'Packed with short and intense bursts of exercises and boxing rounds, this class is for you if you’re looking for fitness boxing.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677228665/probuddy/static/images/CategoriesPage/Boxing/BoxingImage5_u6r4qn.png',
        },
        {
          name: 'Group Training',
          description:
            "You'll learn how to give effective boxing fundamentals. This class is a great way to relieve your stress!",
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677228665/probuddy/static/images/CategoriesPage/Boxing/BoxingImage6_qkrcbq.png',
        },
      ],
    },

    whyBook: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677228664/probuddy/static/images/CategoriesPage/Boxing/BookBoxingImage_x5lqkw.png',
      heading1: 'One Marketplace for All Things Sports, Events and Adventure',
      position: 'center',
      containerPosition: 'start',
      description1:
        'Probuddy is a one-stop Marketplace for all things sports, wellness & adventure. It gives you worldwide access to thousands of top-rated fitness instructors, yoga classes, tennis coaches, or rock climbing events. All you need to do is find your ideal workout activity and book it!',
      heading2: 'Why Book on Probuddy?',
      description2:
        "Probuddy is the easiest way to book the best fitness classes across the globe - no more waiting or driving around to find the right class for you. Our goal is to help you find the perfect instructor or class in your area so that you can make your next activity one you'll never forget!",
    },

    classesNearYou: {
      title: 'Book The Best Boxing Classes and Trainings Near You',
      description:
        'Our certified boxing coaches are passionate about helping you grow. They are dedicated to spreading positivity and inspiration through their classes. Browse through our offerings, book a class today, and feel the difference!',
    },

    instructors: {
      title: 'Top-Rated Wellness Coaches & Instructors',
      description:
        "You deserve a workout that isn't boring, and a community of like-minded people to push you to go further than you've gone before. At Probuddy we believe in the power of wellness and social connection to help you achieve your health and fitness goal.",
      instructorsData: [
        {
          id: '1',
          trainerImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677228666/probuddy/static/images/CategoriesPage/Boxing/BoxingTrainerImage1_rdcyqp.png',
          name: 'Mike Vaughn',
          type: 'Boxing Trainer',
          rating: '4.4',
          reviews: '15',
          category: 'boxing',
          description:
            'Book a session with Private Boxing Sessions at Your Space or Mine *Includes Travel',
        },
        {
          id: '2',
          trainerImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677228666/probuddy/static/images/CategoriesPage/Boxing/BoxingTrainerImage2_f3vhcx.png',
          name: 'Julian Larson',
          type: 'Boxing Coach',
          rating: '4.5',
          reviews: '19',
          category: 'boxing',
          description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ipsum, nec vestibulum lectus pharetra, metus.',
        },
        {
          id: '3',
          trainerImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677228667/probuddy/static/images/CategoriesPage/Boxing/BoxingTrainerImage3_c0dev2.png',
          name: 'Steve Liao',
          type: 'Boxing Instructor',
          rating: '4.7',
          reviews: '13',
          category: 'boxing',
          description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ipsum, nec vestibulum lectus pharetra, metus.',
        },
      ],
    },

    featured: {
      title: 'Featured Boxing Gyms and Events Bookings',
      description:
        "To get the most out of your workouts, it's important to find a gym or trainer that fits your needs and schedule. Take a look at these top-rated profiles and classes near you to find a place that suits you. Book a class today!",
      featuredItems: [
        {
          id: '1',
          companyImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677228665/probuddy/static/images/CategoriesPage/Boxing/BoxingCompanyImage1_vjlxd7.png',
          name: 'The Ring',
          location: 'Kim Yam Road, Singapore',
          rating: '4.1',
          reviews: '225',
          category: 'boxing',
          description:
            'Learn the art of sweet science in this ultra-empowering and stress-reducing workout that helps you kick ass and improve cardiovascular strength. Experience authentic boxing and work out with a combination of a cardio and technical boxing class.',
        },
        {
          id: '2',
          companyImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677228665/probuddy/static/images/CategoriesPage/Boxing/BoxingCompanyImage1_vjlxd7.png',
          name: 'The Ring',
          location: 'Kim Yam Road, Singapore',
          rating: '4.2',
          reviews: '198',
          category: 'boxing',
          description:
            'Learn the art of sweet science in this ultra-empowering and stress-reducing workout that helps you kick ass and improve cardiovascular strength. Experience authentic boxing and work out with a combination of a cardio and technical boxing class.',
        },
      ],
    },

    topRatedClasses: {
      title: 'Find More Top-Rated Boxing Classes',
      description:
        'Book your boxing class to learn the basics of boxing in a beginner-friendly environment or be guided by our experienced certified world-class boxing coaches near you.',
      classesData: [
        {
          id: '1',
          classesImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677228664/probuddy/static/images/CategoriesPage/Boxing/BoxingClassesImage1_ebvid3.png',
          className: 'Boxing Group Class for Kids',
          instructorName: 'Mike Vaughn',
          location: 'Melbourne, AU',
          rating: '4.8',
          reviews: '225',
          category: 'boxing',
          description:
            'Book a session with Private Yoga Sessions at Your Space or Mine *Includes Travel',
          bookingTimings: '8.00 Am to 10.00 Am',
          bookingPrice: '100',
        },
        {
          id: '2',
          classesImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677228664/probuddy/static/images/CategoriesPage/Boxing/BoxingClassesImage2_bwn1oe.png',
          className: '1 on 1 Personal Boxing Training',
          instructorName: 'Dion Russell',
          location: 'Yishun, Singapore',
          rating: '4.8',
          reviews: '201',
          category: 'boxing',
          description:
            'Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text.',
          bookingTimings: '8.00 Am to 10.00 Am',
          bookingPrice: '120',
        },
        {
          id: '3',
          classesImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677228664/probuddy/static/images/CategoriesPage/Boxing/BoxingClassesImage3_fve3vs.png',
          className: 'Advanced Competitive Boxing Class',
          instructorName: 'Dianne Russell',
          location: 'Bukit Batok, SG',
          rating: '4.6',
          reviews: '225',
          category: 'boxing',
          description:
            'Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text.',
          bookingTimings: '8.00 Am to 10.00 Am',
          bookingPrice: '120',
        },
      ],
    },

    reviews: [
      {
        name: 'Courtney Henry',
        country: 'Hong Kong',
        imageSrc: ReviewImage1,
        description:
          'The Probuddy platform is a great solution for business owners. We highly recommend it for businesses that need to keep track of everything.',
      },
      {
        name: 'Simon Hart',
        country: 'Texas',
        imageSrc: ReviewImage2,
        description:
          'I have tried many online courses in my life, but none that have been so effective as Probuddy. The course has helped me with my business and gave me the tools to be more successful. The instructors are very knowledgeable and helpful. I have enjoyed the course and look forward to taking more classes.',
      },
      {
        name: 'Hayley Smith',
        country: 'Manchester',
        imageSrc: ReviewImage6,
        description:
          'Taking time out to destress with a tennis session is always a wonderful thing. Using Probuddy makes it even more enjoyable.',
      },
    ],

    features: {
      title: 'Looking for Tennis Classes? We can help.',
      description:
        'Match up with verified and certified pros near you with Pro Connect+. Just create a Match Request, and we’ll connect you with a perfect match.',
    },

    sellers: {
      title:
        'Join over 1000 sports, wellness, and adventure Pros that have seen their business grow with Probuddy.',
      linkText: 'Learn More',
    },
  },

  {
    id: 'pilates',
    title: 'Pilates Classes',
    description: 'Discover Balance & Harmony With Pilates',
    heroImage:
      'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660825608/probuddy/static/images/CategoriesPage/Pilates/PilatesHeroImage_l4g9vx.png',

    section1: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660825606/probuddy/static/images/CategoriesPage/Pilates/BookPilatesClasses_dmwco9.png',
      heading: 'Book Pilates Classes That Works & Designed For You',
      description:
        "Whether you're looking to work on your core strength, balance, flexibility or even learn new exercises, Probuddy offers hundreds of pilates sessions all over the world. We help hundreds of fitness enthusiasts from around the world find and book the best pilates classes and sessions with accredited, professional coaches near you and designed for you.",
      linkText: 'Book A Pilates Class Now',
    },

    section2: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660825606/probuddy/static/images/CategoriesPage/Pilates/BestPilatesInstructors_qpzlol.png',
      heading: 'Choose from the Best Pilates Instructors',
      description:
        "Whether it’s your first time or you're an experienced fitness enthusiast, stepping into a Pilates class can be intimidating. That’s why Probuddy is here to help! We have the best pilates coaches who will guide you and offer personalized exercises designed specifically for your needs. Our certified professionals will make sure you get the most out of your sessions and always work at your pace to ensure maximum results.",
      linkText: 'Become a Pilates Pro',
    },

    exercise: {
      productTitle: 'Popular Pilates Listings in your area',
      title: 'Types of Pilates Classes Hosted by Pros',
      options: [
        {
          name: 'Pilates Private Sessions',
          description:
            'Looking for some one-on-one attention for a session tailored to your body? Try our private Pilates classes.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660825607/probuddy/static/images/CategoriesPage/Pilates/PilatesImage1_klsj8c.png',
        },
        {
          name: 'Group Classes',
          description: 'Enjoy a fun and unique group class experience on a pilates.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660825607/probuddy/static/images/CategoriesPage/Pilates/PilatesImage2_zey50q.png',
        },
        {
          name: 'Reformer Pilates',
          description: 'Develop a better understanding of your posture, strengths, and weaknesses.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660825607/probuddy/static/images/CategoriesPage/Pilates/PilatesImage3_vlr8zu.png',
        },
        {
          name: 'Prenatal & Postpartum Pilates',
          description:
            'An excellent way to help you prepare for natural childbirth and life after pregnancy.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660825607/probuddy/static/images/CategoriesPage/Pilates/PilatesImage4_swn80v.png',
        },
        {
          name: 'Advanced Pilates',
          description: 'Take your fitness to the next level with this advanced pilates routine.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660825608/probuddy/static/images/CategoriesPage/Pilates/PilatesImage5_fnlqcp.png',
        },
        {
          name: 'Stretching Classes',
          description:
            'Reprogram your body to keep muscles flexible, long and toned with stretch mat Pilates classes.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660825608/probuddy/static/images/CategoriesPage/Pilates/PilatesImage6_twtiqf.png',
        },
      ],
    },

    whyBook: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660825606/probuddy/static/images/CategoriesPage/Pilates/BookPilatesImage_hqptht.png',
      heading1: 'One Marketplace for All Things Sports, Events and Adventure',
      position: 'center',
      containerPosition: 'start',
      description1:
        'Probuddy is a one-stop Marketplace for all things sports, wellness & adventure. It gives you worldwide access to thousands of top-rated fitness instructors, yoga classes, tennis coaches, or rock climbing events. All you need to do is find your ideal workout activity and book it!',
      heading2: 'Why Book on Probuddy?',
      description2:
        "Probuddy is the easiest way to book the best fitness classes across the globe - no more waiting or driving around to find the right class for you. Our goal is to help you find the perfect instructor or class in your area so that you can make your next activity one you'll never forget!",
    },

    classesNearYou: {
      title: 'Book The Best Pilates Classes and Sessions Near You',
      description:
        "The first ever marketplace for the best pilates classes near you, with accredited and professional pilates coaches to guide you. Our goal is to help you  find the best pilates class and session near them. We'll guide you through a ton of options based on your needs and location.",
    },

    instructors: {
      title: 'Featured Top-Rated Pilates Trainers & Coaches',
      description:
        "Get fit while having the motivation and guidance of a professional pilates coaches and trainers. It's easy to book a session from Probuddy with many accredited and professional coaches in your area.",
      instructorsData: [
        {
          id: '1',
          trainerImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660825608/probuddy/static/images/CategoriesPage/Pilates/PilatesTrainer1_mktet5.png',
          name: 'Maggie Yiao',
          type: 'Pilates Trainer',
          rating: '4.4',
          reviews: '15',
          category: 'pilates',
          description:
            'Book a session with private or group Pilates  sessions at Your Space or Mine *Includes Travel',
        },
        {
          id: '2',
          trainerImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660825608/probuddy/static/images/CategoriesPage/Pilates/PilatesTrainer2_pn71pu.png',
          name: 'Carlene Smith',
          type: 'Pilates Coach',
          rating: '4.5',
          reviews: '19',
          category: 'pilates',
          description:
            'Transformative approach to Pilates that will have you feeling stronger, longer, & leaner health.',
        },
        {
          id: '3',
          trainerImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660825608/probuddy/static/images/CategoriesPage/Pilates/PilatesTrainer3_xodhbk.png',
          name: 'Valerie Carter',
          type: 'Pilates Instructor',
          rating: '4.7',
          reviews: '13',
          category: 'pilates',
          description:
            'Join Valerie for beat-driven, muscle-burning, total body sculpting reformer Pilates.',
        },
      ],
    },

    featured: {
      title: 'Featured Pilates Studios and Events Bookings',
      description:
        "To get the most out of your workouts, it's important to find a gym or trainer that fits your needs and schedule. Take a look at these top-rated profiles and classes near you to find a place that suits you. Book a class today!",
      featuredItems: [
        {
          id: '1',
          companyImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660825607/probuddy/static/images/CategoriesPage/Pilates/PilatesCompany1_p2fbfm.png',
          name: 'Pilates Plus',
          location: 'Kim Yam Road, Singapore',
          rating: '4.1',
          reviews: '225',
          category: 'pilates',
          description:
            'At PilatesPlus, we provide unique and innovative ways of teaching Pilates exercise. We are dedicated to guiding our clients to achieve their fitness goal. Pilates Plus Is About Moving Our Bodies. We don’t just stop at Pilates, We enjoy Life through Fitness!',
        },
        {
          id: '2',
          companyImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660825607/probuddy/static/images/CategoriesPage/Pilates/PilatesCompany1_p2fbfm.png',
          name: 'Pilates Plus',
          location: 'Kim Yam Road, Singapore',
          rating: '4.2',
          reviews: '198',
          category: 'pilates',
          description:
            'At PilatesPlus, we provide unique and innovative ways of teaching Pilates exercise. We are dedicated to guiding our clients to achieve their fitness goal. Pilates Plus Is About Moving Our Bodies. We don’t just stop at Pilates, We enjoy Life through Fitness!',
        },
      ],
    },

    topRatedClasses: {
      title: 'Explore More Top-Rated Pilates Classes Near You',
      description:
        'Get into the best shape of your life with certified, professional, and accredited pilates classes. We have the best pilates classes available in your area and certified Pros who will guide you and offer personalized exercises designed specifically for your needs.',
      classesData: [
        {
          id: '1',
          classesImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660825606/probuddy/static/images/CategoriesPage/Pilates/PilatesClasses1_gde4jg.png',
          className: 'Pilates Group Session',
          instructorName: 'Maggie Yao',
          location: 'Melbourne, AU',
          rating: '4.8',
          reviews: '225',
          category: 'pilates',
          description:
            'Book a session with groups Pilates Sessions at Your Space or Mine *Includes Travel',
          bookingTimings: '8.00 Am to 10.00 Am',
          bookingPrice: '100',
        },
        {
          id: '2',
          classesImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660825606/probuddy/static/images/CategoriesPage/Pilates/PilatesClasses2_f43y7f.png',
          className: 'Advanced Group Pilates',
          instructorName: 'Carlene Smith',
          location: 'Yishun, Singapore',
          rating: '4.8',
          reviews: '201',
          category: 'pilates',
          description:
            'Transformative approach to Pilates that will have you feeling stronger, longer, & leaner health.',
          bookingTimings: '8.00 Am to 10.00 Am',
          bookingPrice: '120',
        },
        {
          id: '3',
          classesImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660825606/probuddy/static/images/CategoriesPage/Pilates/PilatesClasses3_whxllb.png',
          className: 'Reformer Pilates Class',
          instructorName: 'Valerie Carter',
          location: 'Bukit Batok, SG',
          rating: '4.6',
          reviews: '225',
          category: 'pilates',
          description:
            'Join Valerie for beat-driven, muscle-burning, total body sculpting reformer Pilates.',
          bookingTimings: '8.00 Am to 10.00 Am',
          bookingPrice: '120',
        },
      ],
    },

    reviews: [
      {
        name: 'Courtney Henry',
        country: 'Hong Kong',
        imageSrc: ReviewImage1,
        description:
          'The Probuddy platform is a great solution for business owners. We highly recommend it for businesses that need to keep track of everything.',
      },
      {
        name: 'Simon Hart',
        country: 'Texas',
        imageSrc: ReviewImage2,
        description:
          'I have tried many online courses in my life, but none that have been so effective as Probuddy. The course has helped me with my business and gave me the tools to be more successful. The instructors are very knowledgeable and helpful. I have enjoyed the course and look forward to taking more classes.',
      },
      {
        name: 'Hayley Smith',
        country: 'Manchester',
        imageSrc: ReviewImage6,
        description:
          'Taking time out to destress with a tennis session is always a wonderful thing. Using Probuddy makes it even more enjoyable.',
      },
    ],

    features: {
      title: 'Ready to start your Pilates journey? We can help.',
      description:
        'Match up with verified and certified pros near you with Pro Connect+. Just create a Match Request, and we’ll connect you with a perfect match.',
    },

    sellers: {
      title:
        'Join over 1000 sports, wellness, and adventure Pros that have seen their business grow with Probuddy.',
      linkText: 'Learn More',
    },
  },

  {
    id: 'pole-fitness',
    title: 'Pole Fitness',
    description: 'Pole Dancing Classes for Fitness and Fun!',
    heroImage:
      'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660892018/probuddy/static/images/CategoriesPage/Pole%20Fitness/PoleFitnessHeroImage_dhjlc4.png',

    section1: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660891906/probuddy/static/images/CategoriesPage/Pole%20Fitness/PoleFitnessClasses_vqb7sj.png',
      heading: 'Pole Fitness Classes for everybody',
      description:
        "With Probuddy, you can have a private pole fitness instructor come to your home or office and teach you how to dance on the pole. Your personalized lessons are tailored towards building upper body strength, boosting self-confidence, and much more! With each class, you'll be aiming to improve on your individual goals. We will help you develop a personalized fitness regimen that is both challenging and fun!",
      linkText: 'Book Your First Pole Dance Class Now',
    },

    section2: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660891903/probuddy/static/images/CategoriesPage/Pole%20Fitness/BuildStrengthImage_ewtipt.png',
      heading: 'Build Strength, Flexibility and Acro-Movement Skills With Our Pros',
      description:
        'With private pole fitness classes, you can learn at your own pace. Your Pros will tailor their teaching to what you need and work with you one-on-one so that you never feel left behind! Learn how to pole dance with a certified pole fitness instructor. Get the basic moves and techniques to help you build upper, body and core strength and boost self-confidence.',
      linkText: 'Become Our Pole Fitness  Pro',
    },

    exercise: {
      productTitle: 'Popular Pole Dance & Fitness Listings in your area',
      title: 'Types of Pole Dance & Fitness Classes Hosted by Pros',
      options: [
        {
          name: 'Private Pole Fitness Sessions',
          description:
            'Looking for some one-on-one attention for a session tailored to your body? Try our private Pole Fitness classes.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660891903/probuddy/static/images/CategoriesPage/Pole%20Fitness/PoleFitness1_mkxozd.png',
        },
        {
          name: 'Pole Dancing Group Classes',
          description: 'Enjoy a fun and unique group class experience on a pole.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660891904/probuddy/static/images/CategoriesPage/Pole%20Fitness/PoleFitness2_d4fwyd.png',
        },
        {
          name: 'Online Pole Fitness Classes',
          description:
            'Our online pole fitness classes are live and taught with the expertise of our incredible instructors.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660891902/probuddy/static/images/CategoriesPage/Pole%20Fitness/PoleFitness3_fwtm4f.png',
        },
        {
          name: 'Drop-in Pole Fitness Classes',
          description:
            'This drop-in pole courses give you your strong pole foundation flexibility and creative movement classes.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660891902/probuddy/static/images/CategoriesPage/Pole%20Fitness/PoleFitness4_udnixf.png',
        },
        {
          name: 'Advanced Pole Fitness',
          description:
            'Take your fitness to the next level with this advanced pole fitness routine.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660891903/probuddy/static/images/CategoriesPage/Pole%20Fitness/PoleFitness5_pvhhvx.png',
        },
        {
          name: 'Flexibility For All Levels',
          description:
            'Learn new pole techniques for every level of fitness/experience absolute beginner to advance.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660891904/probuddy/static/images/CategoriesPage/Pole%20Fitness/PoleFitness6_czvspn.png',
        },
      ],
    },

    whyBook: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660891903/probuddy/static/images/CategoriesPage/Pole%20Fitness/PoleFitnessBookImage_iu4pmq.png',
      heading1: 'One Marketplace for All Things Sports, Events and Adventure',
      position: 'center',
      containerPosition: 'start',
      description1:
        'Probuddy is a one-stop Marketplace for all things sports, wellness & adventure. It gives you worldwide access to thousands of top-rated fitness instructors, yoga classes, tennis coaches, or rock climbing events. All you need to do is find your ideal workout activity and book it!',
      heading2: 'Why Book on Probuddy?',
      description2:
        "Probuddy is the easiest way to book the best fitness classes across the globe - no more waiting or driving around to find the right class for you. Our goal is to help you find the perfect instructor or class in your area so that you can make your next activity one you'll never forget!",
    },

    classesNearYou: {
      title: 'Book The Best Pole Fitness Classes and Pole Dance Lessons Near You',
      description:
        "The first ever marketplace for the best pole fitness classes near you, with accredited and professional pole dance instructors to guide you. We've partnered with some of the most reputable partners in the industry to provide our users with a wide variety of classes at every skill level. Whether you're just starting out or are an elite dancer, Probuddy has something for everyone!",
    },

    instructors: {
      title: 'Featured Top-Rated Pole Dance and Fitness Instructors',
      description:
        'Interested in learning the basics of pole fitness? Our Pros will teach you the basic moves and techniques to help build upper body strength and self confidence. Book a session today and learn how to do spins, climbs, stalls and drops.',
      instructorsData: [
        {
          id: '1',
          trainerImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660891906/probuddy/static/images/CategoriesPage/Pole%20Fitness/PoleFitnessTrainer1_x9tlk6.png',
          name: 'Phil Scotch',
          type: 'Pole Fitness instructor',
          rating: '4.4',
          reviews: '15',
          category: 'pole fitness',
          description:
            'Experienced pole dancing instructor Phil delivers pole dancing lessons to begginers, intermediate and advanced students.',
        },
        {
          id: '2',
          trainerImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660891906/probuddy/static/images/CategoriesPage/Pole%20Fitness/PoleFitnessTrainer2_clhgjl.png',
          name: 'Zarah Ebner',
          type: 'Pole Fitness instructor',
          rating: '4.5',
          reviews: '19',
          category: 'pole fitness',
          description:
            'Pole dancing, pole choreography, pole flow and movement classes for all levels. Online and in-person.',
        },
        {
          id: '3',
          trainerImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660891905/probuddy/static/images/CategoriesPage/Pole%20Fitness/PoleFitnessTrainer3_w6aqww.png',
          name: 'Anne Hatchet',
          type: 'Pole Fitness instructor',
          rating: '4.7',
          reviews: '13',
          category: 'pole fitness',
          description:
            'Certified Pole Dancing & Pole Fitness Instructor with 6 years experience. Availanle in 1-2-1 and group sessions online & in person.',
        },
      ],
    },

    featured: {
      title: 'Featured Pole Fitness Studios and Events Bookings',
      description:
        "We've partnered with fitness experts, trainers, freelancers and other professionals who are passionate about their craft to bring you a curated experience that is fun, fresh and inspiring.  Check out these top-rated pole fitness studios and pole dancing lessons. Book a class today!",
      featuredItems: [
        {
          id: '1',
          companyImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660891906/probuddy/static/images/CategoriesPage/Pole%20Fitness/PoleFitnessCompany1_kjizll.png',
          name: 'Pole Fitness Studio',
          location: 'Melbourne, Australia',
          rating: '4.1',
          reviews: '205',
          category: 'pole fitness',
          description:
            'Pole Fitness Studio helps you to discover exciting new ways to exercise, work out, get fit and healthy. We help women tone up, shape up, feel good and look great. We run pole fitness classes in Melbourne, Australia that are a great way to work out. The classes really get results and not only that but you learn an awesome new skill at the same time.',
        },
        {
          id: '2',
          companyImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660891906/probuddy/static/images/CategoriesPage/Pole%20Fitness/PoleFitnessCompany1_kjizll.png',
          name: 'Pole Fitness Studio',
          location: 'Melbourne, Australia',
          rating: '4.2',
          reviews: '198',
          category: 'pole fitness',
          description:
            'Pole Fitness Studio helps you to discover exciting new ways to exercise, work out, get fit and healthy. We help women tone up, shape up, feel good and look great. We run pole fitness classes in Melbourne, Australia that are a great way to work out. The classes really get results and not only that but you learn an awesome new skill at the same time.',
        },
      ],
    },

    topRatedClasses: {
      title: 'Check Out More Pole Dance & Fitness Classes Availble Near You',
      description:
        'Get into the best shape of your life with certified, professional, and accredited pole fitness classes. We have the best pole fitness classes available in your area and certified Pros who will guide you and offer personalized exercises designed specifically for your needs.',
      classesData: [
        {
          id: '1',
          classesImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660891904/probuddy/static/images/CategoriesPage/Pole%20Fitness/PoleFitnessClasses1_hp49u0.png',
          className: 'Spinning Pole Intermediate/Advance Level',
          instructorName: 'Anne Hatchet',
          location: 'Melbourne, AU',
          rating: '4.8',
          reviews: '225',
          category: 'pilates',
          description:
            'Certified Pole Dancing & Pole Fitness Instructor with 6 years experience. Availanle in 1-2-1 and group sessions online & in person.',
          bookingTimings: '8.00 Am to 10.00 Am',
          bookingPrice: '100',
        },
        {
          id: '2',
          classesImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660891905/probuddy/static/images/CategoriesPage/Pole%20Fitness/PoleFitnessClasses2_vw4jy1.png',
          className: 'Pole Fitness All Levels',
          instructorName: 'Carlene Smith',
          location: 'Yishun, Singapore',
          rating: '4.8',
          reviews: '201',
          category: 'pilates',
          description:
            'Experienced pole dancing instructor Phil delivers pole dancing lessons to begginers, intermediate and advanced students.',
          bookingTimings: '8.00 Am to 10.00 Am',
          bookingPrice: '120',
        },
        {
          id: '3',
          classesImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660891905/probuddy/static/images/CategoriesPage/Pole%20Fitness/PoleFitnessClasses3_nyh6ay.png',
          className: 'Pole Dance Beginners level 1 and 2',
          instructorName: 'Valerie Carter',
          location: 'Bukit Batok, SG',
          rating: '4.6',
          reviews: '225',
          category: 'pilates',
          description:
            'Pole dancing, pole choreography, pole flow and movement classes for all levels. Online and in-person.',
          bookingTimings: '8.00 Am to 10.00 Am',
          bookingPrice: '120',
        },
      ],
    },

    reviews: [
      {
        name: 'Courtney Henry',
        country: 'Hong Kong',
        imageSrc: ReviewImage1,
        description:
          'The Probuddy platform is a great solution for business owners. We highly recommend it for businesses that need to keep track of everything.',
      },
      {
        name: 'Simon Hart',
        country: 'Texas',
        imageSrc: ReviewImage2,
        description:
          'I have tried many online courses in my life, but none that have been so effective as Probuddy. The course has helped me with my business and gave me the tools to be more successful. The instructors are very knowledgeable and helpful. I have enjoyed the course and look forward to taking more classes.',
      },
      {
        name: 'Hayley Smith',
        country: 'Manchester',
        imageSrc: ReviewImage6,
        description:
          'Taking time out to destress with a tennis session is always a wonderful thing. Using Probuddy makes it even more enjoyable.',
      },
    ],

    features: {
      title: 'Ready to start your Pilates journey? We can help.',
      description:
        'Match up with verified and certified pros near you with Pro Connect+. Just create a Match Request, and we’ll connect you with a perfect match.',
    },

    sellers: {
      title:
        'Join over 1000 sports, wellness, and adventure Pros that have seen their business grow with Probuddy.',
      linkText: 'Learn More',
    },
  },

  {
    id: 'kickboxing',
    title: 'KickBoxing Classes',
    description: 'Elevate Your Fitness With Kickboxing',
    heroImage:
      'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660895471/probuddy/static/images/CategoriesPage/Kickboxing/KickboxingHeroImage_dzrpvw.png',

    section1: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660895106/probuddy/static/images/CategoriesPage/Kickboxing/KickboxingClasses_rzvkte.png',
      heading: 'Kickboxing Classes To Keep You More Motivated Than Ever Before',
      description:
        "Our kickboxing classes have you work hard and get in great shape, both physically and mentally. You'll strengthen your body and improve your confidence as you learn how to defend yourself. Kickboxing classes are available at several different levels — from beginner to advanced.",
      linkText: 'Book A Kickboxing Class Now',
    },

    section2: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660895107/probuddy/static/images/CategoriesPage/Kickboxing/KickboxingPros_odwonh.png',
      heading: 'Join our Global Community of Kickboxing Pros',
      description:
        'Our experienced kickboxing instructors will help you find the right program for you, depending on your goals. We have a variety of options for all ages, from kids to adults, men and women.',
      linkText: 'Become Our Kickboxing  Pro',
    },

    exercise: {
      productTitle: 'Popular Kickboxing Listings in your area',
      title: 'Types of Kickboxing Classes Hosted by Pros',
      options: [
        {
          name: 'Kickboxing for Beginners',
          description:
            'Designed for beginners who are new to the sport or looking to start fresh after taking a break from training.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660895105/probuddy/static/images/CategoriesPage/Kickboxing/Kickboxing1_c4f4lw.png',
        },
        {
          name: 'Kickboxing Class for Kids',
          description:
            'Our kids kickboxing also teaches and cultivates discipline and sportsmanship within the kids.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660895105/probuddy/static/images/CategoriesPage/Kickboxing/Kickboxing2_qixbdm.png',
        },
        {
          name: 'Private Kickboxing Class',
          description:
            'Consists of a total body workout that incorporates cardio training, strength training and boxing techniques.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660895105/probuddy/static/images/CategoriesPage/Kickboxing/Kickboxing3_aljwcn.png',
        },
        {
          name: 'Advanced Kickboxing Session',
          description:
            'Designed for those who have been training for a while but are looking for more advanced techniques.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660895105/probuddy/static/images/CategoriesPage/Kickboxing/Kickboxing4_f2kpk4.png',
        },
        {
          name: 'Strength & Conditioning',
          description:
            'Focuses on improving fitness while learning basic techniques that can be applied to other areas of life.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660895105/probuddy/static/images/CategoriesPage/Kickboxing/Kickboxing5_wq6sx8.png',
        },
        {
          name: 'Group Kickboxing Classes',
          description:
            "You'll learn how to give effective boxing fundamentals. This class is a great way to relieve your stress!",
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660895105/probuddy/static/images/CategoriesPage/Kickboxing/Kickboxing6_bo4bj8.png',
        },
      ],
    },

    whyBook: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660895106/probuddy/static/images/CategoriesPage/Kickboxing/KickboxingBookImage_rxtglg.png',
      heading1: 'One Marketplace for All Things Sports, Events and Adventure',
      position: 'center',
      containerPosition: 'start',
      description1:
        'Probuddy is a one-stop Marketplace for all things sports, wellness & adventure. It gives you worldwide access to thousands of top-rated fitness instructors, yoga classes, tennis coaches, or rock climbing events. All you need to do is find your ideal workout activity and book it!',
      heading2: 'Why Book on Probuddy?',
      description2:
        "Probuddy is the easiest way to book the best fitness classes across the globe - no more waiting or driving around to find the right class for you. Our goal is to help you find the perfect instructor or class in your area so that you can make your next activity one you'll never forget!",
    },

    classesNearYou: {
      title: 'Book The Best Kickboxing Classes and Trainings Near You',
      description:
        'Our certified kickboxing coaches will help you maximize your workout to achieve your health and fitness goals. Our classes are designed with the most current fitness trends in mind to help you get the most out of each session. Book a class today, and feel the difference!',
    },

    instructors: {
      title: 'Featured Top-Rated Kickboxing Trainers & Coaches',
      description:
        'Improve your muscular endurance, power, and conflict-resolution skills while you train like the pros with our kickboxing classes. Whatever your fitness goals may be, Probuddy can help you achieve them, with a very experienced and diverse group of kickboxing instructors ready to help.',
      instructorsData: [
        {
          id: '1',
          trainerImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660895106/probuddy/static/images/CategoriesPage/Kickboxing/KickboxingTrainer1_gp1e5o.png',
          name: 'Tyler Sheik',
          type: 'Kickboxing Instructor',
          rating: '4.4',
          reviews: '15',
          category: 'pole fitness',
          description:
            'Coach Tyler has an accomplished kickboxing and MMA career, which he transfers that experience into his teaching.',
        },
        {
          id: '2',
          trainerImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660895106/probuddy/static/images/CategoriesPage/Kickboxing/KickboxingTrainer2_v1z2t6.png',
          name: 'Jane Lowrey',
          type: 'Kickboxing Coach',
          rating: '4.5',
          reviews: '19',
          category: 'pole fitness',
          description:
            'Pole dancing, pole choreography, pole flow and movement classes for all levels. Online and in-person.',
        },
        {
          id: '3',
          trainerImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660895107/probuddy/static/images/CategoriesPage/Kickboxing/KickboxingTrainer3_hvgtng.png',
          name: 'Stanley Crowe',
          type: 'Kickboxing Instructor',
          rating: '4.7',
          reviews: '13',
          category: 'pole fitness',
          description:
            'Certified Pole Dancing & Pole Fitness Instructor with 6 years experience. Availanle in 1-2-1 and group sessions online & in person.',
        },
      ],
    },

    featured: {
      title: 'Featured Kickboxing Gyms and Events Bookings',
      description:
        "To get the most out of your workouts, it's important to find a gym or trainer that fits your needs and schedule. Take a look at these top-rated kickboxing companies and studios near you . Book a class today!",
      featuredItems: [
        {
          id: '1',
          companyImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677228665/probuddy/static/images/CategoriesPage/Boxing/BoxingCompanyImage1_vjlxd7.png',
          name: 'The Ring',
          location: 'Kim Yam Road, Singapore',
          rating: '4.1',
          reviews: '225',
          category: 'boxing',
          description:
            'Learn the art of sweet science in this ultra-empowering and stress-reducing workout that helps you kick ass and improve cardiovascular strength. Experience authentic boxing and work out with a combination of a cardio and technical boxing class.',
        },
        {
          id: '2',
          companyImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677228665/probuddy/static/images/CategoriesPage/Boxing/BoxingCompanyImage1_vjlxd7.png',
          name: 'The Ring',
          location: 'Kim Yam Road, Singapore',
          rating: '4.2',
          reviews: '198',
          category: 'boxing',
          description:
            'Learn the art of sweet science in this ultra-empowering and stress-reducing workout that helps you kick ass and improve cardiovascular strength. Experience authentic boxing and work out with a combination of a cardio and technical boxing class.',
        },
      ],
    },

    topRatedClasses: {
      title: 'Find More Top-Rated Kickboxing Classes',
      description:
        'You are in the right place to book your kickboxing class with Probuddy. Whether you want to learn the basics of kickboxing or want to be guided by our experienced certified Pros near you, we have a class for you. Book your private or group kickboxing class today!',
      classesData: [
        {
          id: '1',
          classesImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677228664/probuddy/static/images/CategoriesPage/Boxing/BoxingClassesImage1_ebvid3.png',
          className: 'Boxing Group Class for Kids',
          instructorName: 'Mike Vaughn',
          location: 'Melbourne, AU',
          rating: '4.8',
          reviews: '225',
          category: 'kickboxing',
          description:
            'Book a session with Private Yoga Sessions at Your Space or Mine *Includes Travel',
          bookingTimings: '8.00 Am to 10.00 Am',
          bookingPrice: '100',
        },
        {
          id: '2',
          classesImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677228664/probuddy/static/images/CategoriesPage/Boxing/BoxingClassesImage2_bwn1oe.png',
          className: '1 on 1 Personal Boxing Training',
          instructorName: 'Dion Russell',
          location: 'Yishun, Singapore',
          rating: '4.8',
          reviews: '201',
          category: 'kickboxing',
          description:
            'Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text.',
          bookingTimings: '8.00 Am to 10.00 Am',
          bookingPrice: '120',
        },
        {
          id: '3',
          classesImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677228664/probuddy/static/images/CategoriesPage/Boxing/BoxingClassesImage3_fve3vs.png',
          className: 'Advanced Competitive Boxing Class',
          instructorName: 'Dianne Russell',
          location: 'Bukit Batok, SG',
          rating: '4.6',
          reviews: '225',
          category: 'kickboxing',
          description:
            'Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text.',
          bookingTimings: '8.00 Am to 10.00 Am',
          bookingPrice: '120',
        },
      ],
    },

    reviews: [
      {
        name: 'Courtney Henry',
        country: 'Hong Kong',
        imageSrc: ReviewImage1,
        description:
          'The Probuddy platform is a great solution for business owners. We highly recommend it for businesses that need to keep track of everything.',
      },
      {
        name: 'Simon Hart',
        country: 'Texas',
        imageSrc: ReviewImage2,
        description:
          'I have tried many online courses in my life, but none that have been so effective as Probuddy. The course has helped me with my business and gave me the tools to be more successful. The instructors are very knowledgeable and helpful. I have enjoyed the course and look forward to taking more classes.',
      },
      {
        name: 'Hayley Smith',
        country: 'Manchester',
        imageSrc: ReviewImage6,
        description:
          'Taking time out to destress with a tennis session is always a wonderful thing. Using Probuddy makes it even more enjoyable.',
      },
    ],

    features: {
      title: 'Ready to start your fitness goal on Kickboxing? We can help.',
      description:
        'Match up with verified and certified pros near you with Pro Connect+. Just create a Match Request, and we’ll connect you with a perfect match.',
    },

    sellers: {
      title:
        'Join over 1000 sports, wellness, and adventure Pros that have seen their business grow with Probuddy.',
      linkText: 'Learn More',
    },
  },

  {
    id: 'swimming',
    title: 'Swimming Classes',
    description: 'Be the Best Swimmer That You Can Be',
    heroImage:
      'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661157771/probuddy/static/images/CategoriesPage/Swimming/SwimmingHeroImage_yfprpx.png',

    section1: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661157768/probuddy/static/images/CategoriesPage/Swimming/PlungeSwimPro_bn6qfy.png',
      heading: 'Take the Plunge. Learn To Swim Like A Pro.',
      description:
        "Strengthen your body as well as your confidence as you master swimming skills — physically and mentally. Our swimming classes are available at several different levels - from beginner to advanced - so you can find one that's perfect for you! You'll feel more at ease in any body of water – no matter how deep or challenging it is!",
      linkText: 'Book A Boxing Class Now',
    },

    section2: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661157773/probuddy/static/images/CategoriesPage/Swimming/SwimmingTechnique_wnsgso.png',
      heading: 'Improve Your Swimming Technique With Our Pros',
      description:
        "Our swimming lessons can help teach kids how to swim as well as improve their muscle memory, making them more comfortable when they are at the pool. At Probuddy, you'll learn how to swim from a professional swimming instructor in one-on-one or small group classes at your own pace and skill level.",
      linkText: 'Become Our Swimming  Pro',
    },

    exercise: {
      productTitle: 'Popular Swimming Listings in your area',
      title: 'Types of Swimming Classes & Lessons Hosted by Pros',
      options: [
        {
          name: 'Swimming Classes for Adults',
          description:
            'Designed for beginners who are new to the sport or looking to start fresh after taking a break from training.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661157768/probuddy/static/images/CategoriesPage/Swimming/Swimming1_bnbrcm.png',
        },
        {
          name: 'Swimming Lessons for Kids',
          description:
            'Swimming lessons  for kids, levels from absolute beginners to technique improvement.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661157768/probuddy/static/images/CategoriesPage/Swimming/Swimming2_exokjt.png',
        },
        {
          name: 'Infant & Toddler Swim Lessons',
          description:
            'A perfect chance to introduce toddlers to the water and prepare them for stage 1.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661157768/probuddy/static/images/CategoriesPage/Swimming/Swimming3_hzdzhx.png',
        },
        {
          name: 'Advance Swimming Classes',
          description:
            'Designed for those who have been training for a while but are looking for more advanced techniques.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661157768/probuddy/static/images/CategoriesPage/Swimming/Swimming4_pypvtp.png',
        },
        {
          name: 'Private Swimming Lessons',
          description:
            'Private 1:1, 2:1 and 3:1 Swimming lessons available at a range of levels and specialities.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661157769/probuddy/static/images/CategoriesPage/Swimming/Swimming5_sce0rq.png',
        },
        {
          name: 'Group Swimming Classes',
          description:
            'Learn effective swimming techniques tailored to your particular range of skills, ability and fitness.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661157769/probuddy/static/images/CategoriesPage/Swimming/Swimming6_gevqma.png',
        },
      ],
    },

    whyBook: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661157768/probuddy/static/images/CategoriesPage/Swimming/BookSwimming_msnjwv.png',
      heading1: 'One Marketplace for All Things Sports, Events and Adventure',
      position: 'center',
      containerPosition: 'start',
      description1:
        'Probuddy is a one-stop Marketplace for all things sports, wellness & adventure. It gives you worldwide access to thousands of top-rated fitness instructors, yoga classes, tennis coaches, or rock climbing events. All you need to do is find your ideal workout activity and book it!',
      heading2: 'Why Book on Probuddy?',
      description2:
        "Probuddy is the easiest way to book the best fitness classes across the globe - no more waiting or driving around to find the right class for you. Our goal is to help you find the perfect instructor or class in your area so that you can make your next activity one you'll never forget!",
    },

    classesNearYou: {
      title: 'Book The Best Swimming Classes and Lessons Near You',
      description:
        'Our certified swimming instructors will help you maximize your workout to achieve your health and fitness goals. Our swimming lessons are designed with the most current fitness trends in mind to help you get the most out of each session. Take the plunge and book  a swimming class today!',
    },

    instructors: {
      title: 'Featured Top-Rated Swimming Instructors',
      description: 'Take the plunge and book  a swimming class today!',
      instructorsData: [
        {
          id: '1',
          trainerImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661157770/probuddy/static/images/CategoriesPage/Swimming/SwimmingTrainer1_kcmnh2.png',
          name: 'Sara Miele',
          type: 'Swimming Instructor',
          rating: '4.4',
          reviews: '15',
          category: 'swimming',
          description:
            'Professional swim lessons for all ages which are convenient, fun and catered to your individual learning needs.',
        },
        {
          id: '2',
          trainerImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661157770/probuddy/static/images/CategoriesPage/Swimming/SwimmingTrainer2_dkwx7r.png',
          name: 'Ellie Bartz',
          type: 'Swimming Instructor',
          rating: '4.5',
          reviews: '19',
          category: 'swimming',
          description:
            'Experienced swimming teacher offering 1:1 & small group lessons to kids and adults (10y+ experience) - FIN/STA Level 2',
        },
        {
          id: '3',
          trainerImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661157770/probuddy/static/images/CategoriesPage/Swimming/SwimmingTrainer3_sdgwjr.png',
          name: 'Andrew Artone',
          type: 'Swimming Instructor',
          rating: '4.7',
          reviews: '13',
          category: 'swimming',
          description:
            'Andrew has 3+ years experience in teaching all ages and abilities, from 0 years all the way up to 50+ year old adults.',
        },
      ],
    },

    featured: {
      title: 'Featured Swimming Companies and Events Bookings',
      description:
        "To get the most out of your workouts, it's important to find a swimming coach or events place that fits your needs and schedule. Take a look at these top-rated swimming companies and events near you. Book a swimming lesson today!",
      featuredItems: [
        {
          id: '1',
          companyImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661157770/probuddy/static/images/CategoriesPage/Swimming/SwimmingCompany1_jw0km6.png',
          name: 'Swim Coach John',
          location: 'York Hill, Singapore',
          rating: '4.1',
          reviews: '225',
          category: 'swimming',
          description:
            'Professional swim lessons for all ages which are convenient, fun and catered to your individual learning needs. We believe that swimming lessons should be accessible, convenient, fulfilling and fun. Whether you aim to be the next Olympic champion or want to learn to swim as a life skill, let us be a part of your swimming journey.',
        },
        {
          id: '2',
          companyImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661157770/probuddy/static/images/CategoriesPage/Swimming/SwimmingCompany1_jw0km6.png',
          name: 'Swim Coach John',
          location: 'York Hill, Singapore',
          rating: '4.2',
          reviews: '198',
          category: 'swimming',
          description:
            'Professional swim lessons for all ages which are convenient, fun and catered to your individual learning needs. We believe that swimming lessons should be accessible, convenient, fulfilling and fun. Whether you aim to be the next Olympic champion or want to learn to swim as a life skill, let us be a part of your swimming journey.',
        },
      ],
    },

    topRatedClasses: {
      title: 'Take A Plunge For More Top-Rated Swimming Classes',
      description:
        'You are in the right place to book your swimming class with Probuddy. Whether you want to learn the basics of swimming or want to be guided by our experienced certified Pros near you, we have a class for you. Book your private or group swimming lesson today!',
      classesData: [
        {
          id: '1',
          classesImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661157769/probuddy/static/images/CategoriesPage/Swimming/SwimmingClasses1_fkerli.png',
          className: 'Adult Swimming Class for Beginners',
          instructorName: 'Coach Jon Swim',
          location: 'Melbourne, AU',
          rating: '4.8',
          reviews: '225',
          category: 'swimming',
          description:
            'Swimming lessons for all ages, cut short your learning boundaries, and acquire an effective life skill from expert swimming instructors.',
          bookingTimings: '8.00 Am to 10.00 Am',
          bookingPrice: '100',
        },
        {
          id: '2',
          classesImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661157769/probuddy/static/images/CategoriesPage/Swimming/SwimmingClasses2_jmhxkj.png',
          className: 'Swimming Class for Kids',
          instructorName: 'Dion Russell',
          location: 'Yishun, Singapore',
          rating: '4.8',
          reviews: '201',
          category: 'swimming',
          description:
            'Help your child develop water confidence and learn how to swim like a fish with kid-friendly swimming lessons.',
          bookingTimings: '8.00 Am to 10.00 Am',
          bookingPrice: '120',
        },
        {
          id: '3',
          classesImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661157769/probuddy/static/images/CategoriesPage/Swimming/SwimmingClasses3_p8geob.png',
          className: 'Advanced Swimming Lessons',
          instructorName: 'Dianne Russell',
          location: 'Bukit Batok, SG',
          rating: '4.6',
          reviews: '225',
          category: 'swimming',
          description:
            'Structured and customizable swimming lessons to suit the needs of individuals from varying swimming skill levels.',
          bookingTimings: '8.00 Am to 10.00 Am',
          bookingPrice: '120',
        },
      ],
    },

    reviews: [
      {
        name: 'Courtney Henry',
        country: 'Hong Kong',
        imageSrc: ReviewImage1,
        description:
          'The Probuddy platform is a great solution for business owners. We highly recommend it for businesses that need to keep track of everything.',
      },
      {
        name: 'Simon Hart',
        country: 'Texas',
        imageSrc: ReviewImage2,
        description:
          'I have tried many online courses in my life, but none that have been so effective as Probuddy. The course has helped me with my business and gave me the tools to be more successful. The instructors are very knowledgeable and helpful. I have enjoyed the course and look forward to taking more classes.',
      },
      {
        name: 'Hayley Smith',
        country: 'Manchester',
        imageSrc: ReviewImage6,
        description:
          'Taking time out to destress with a tennis session is always a wonderful thing. Using Probuddy makes it even more enjoyable.',
      },
    ],

    features: {
      title: 'Ready to start your Swimming journey? We can help.',
      description:
        'Match up with verified and certified pros near you with Pro Connect+. Just create a Match Request, and we’ll connect you with a perfect match.',
    },

    sellers: {
      title:
        'Join over 1000 sports, wellness, and adventure Pros that have seen their business grow with Probuddy.',
      linkText: 'Learn More',
    },
  },

  {
    id: 'dance',
    title: 'Dance Classes',
    description: 'The Fun Way To Master New Moves',
    heroImage:
      'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661235853/probuddy/static/images/CategoriesPage/Dance/DanceHeroImage_hyymgp.png',

    section1: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661235853/probuddy/static/images/CategoriesPage/Dance/DanceYourWay_be2g2v.png',
      heading: 'Dance Your Way Into A Healthier You',
      description:
        "Searching for the best way to exercise? You're in the right place. Discover your body's potential as you progress. Our dance classes are designed for anyone and everyone. Come join us in a fun and engaging way to keep fit. Move your body. Get fit. Burn the calories. Dance like nobody is watching!",
      linkText: 'Book A Dancing Class Now',
    },

    section2: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661235853/probuddy/static/images/CategoriesPage/Dance/LearnDance_h1r1lu.png',
      heading: 'Learn To Dance Like You Never Have Before',
      description:
        "Ready to move? Go ahead and take the first step. You can be fearless. Dancin' is a passion. And so is learning. Why not take a dance class and learn from the best? Whether you're a beginner or a pro, our classes are for you Join a dance class today.",
      linkText: 'See Our Dance Listings',
    },

    exercise: {
      productTitle: 'Popular Dance Listings in your area',
      title: 'Types of Dancing Classes & Lessons Hosted by Pros',
      options: [
        {
          name: 'Weekly Open Dance Classes',
          description:
            'Non-syllabus based and are structured to provide you with an understanding of your bodies, style of dance and technique required.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661235851/probuddy/static/images/CategoriesPage/Dance/Dance1_vponk8.png',
        },
        {
          name: 'Contemporary Dance Lessons',
          description: 'Learn contemporary dance led by professional dancers and instructors.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661235851/probuddy/static/images/CategoriesPage/Dance/Dance2_jscjqu.png',
        },
        {
          name: 'Private Dance Classes',
          description:
            'Looking for a more bespoke, tailored learning experience? Book a private dance class with personalised training at your own convenience!',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661235851/probuddy/static/images/CategoriesPage/Dance/Dance3_t7oguf.png',
        },
        {
          name: 'Kids Dance Classes',
          description:
            "Unleash your child's talent in ballet, hip-hop, K-Pop and jazz with our guide to dance classes for kids.",
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661235851/probuddy/static/images/CategoriesPage/Dance/Dance4_mglchb.png',
        },
        {
          name: 'KPop Dance Classes',
          description:
            'For dance enthusisasts (kids, teens & adults) of various levels and experience incorporated with group transformations.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661235851/probuddy/static/images/CategoriesPage/Dance/Dance5_ftqpoj.png',
        },
        {
          name: 'Ballroom Dance Lessons',
          description:
            'Learn effective dancing techniques tailored to your particular range of skills, ability and fitness.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661235852/probuddy/static/images/CategoriesPage/Dance/Dance6_wlrzsf.png',
        },
      ],
    },

    whyBook: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661235851/probuddy/static/images/CategoriesPage/Dance/BookDanceImage_vt8wgz.png',
      heading1: 'One Marketplace for All Things Sports, Events and Adventure',
      position: 'center',
      containerPosition: 'start',
      description1:
        'Probuddy is a one-stop Marketplace for all things sports, wellness & adventure. It gives you worldwide access to thousands of top-rated fitness instructors, yoga classes, tennis coaches, or rock climbing events. All you need to do is find your ideal workout activity and book it!',
      heading2: 'Why Book on Probuddy?',
      description2:
        "Probuddy is the easiest way to book the best fitness classes across the globe - no more waiting or driving around to find the right class for you. Our goal is to help you find the perfect instructor or class in your area so that you can make your next activity one you'll never forget!",
    },

    classesNearYou: {
      title: 'Book The Best Dance Classes and Lessons Near You',
      description:
        "Book the best dance classes and lessons near you. Our easy-to-use platform lets you book the best dance classes and lessons near you. Find the right studio, instructor and class for your needs. It's as simple as that.",
    },

    instructors: {
      title: 'Featured Top-Rated Dance Instructors & Choreographers',
      description:
        'The Best of the Best. Our dance instructors are certified choreographers and have won awards and competitions, and are recognized as some of the best in the world. At Probuddy, we take pride in offering the best dance instructors available.',
      instructorsData: [
        {
          id: '1',
          trainerImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661235852/probuddy/static/images/CategoriesPage/Dance/DanceTrainer1_om0eqk.png',
          name: 'Srjan Miele',
          type: 'Dance Instructor',
          rating: '4.4',
          reviews: '15',
          category: 'dance',
          description:
            'Srjan trained full-time at Melbourne Australia Conservatoire of Classical Ballet, before pursuing dance teaching as his chosen career.',
        },
        {
          id: '2',
          trainerImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661235853/probuddy/static/images/CategoriesPage/Dance/DanceTrainer2_qdmqfh.png',
          name: 'Ellie Dario',
          type: 'Dance Choreographer',
          rating: '4.5',
          reviews: '19',
          category: 'dance',
          description:
            'Ellie has been teaching for almost 3 years, with students ranging from 4-year olds to adults in Ballet, Jazz, Hip Hop and Contemporary.',
        },
        {
          id: '3',
          trainerImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661235853/probuddy/static/images/CategoriesPage/Dance/DanceTrainer3_ohmxyp.png',
          name: 'Andy Wong',
          type: 'Dance Teacher',
          rating: '4.7',
          reviews: '13',
          category: 'dance',
          description:
            'Under his guidance, many of his students have won awards, scholarships local and international dance competitions.',
        },
      ],
    },

    featured: {
      title: 'Find The Best Dance Studios and Events Bookings',
      description:
        'The most fun you can have while burning calories. Dance is a great way to get in shape and have fun. Probuddy helps you find the best dance studios and events near you. Our features dance studios are curated to provide a unique guide to the best of what dancing class has to offer.',
      featuredItems: [
        {
          id: '1',
          companyImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661235852/probuddy/static/images/CategoriesPage/Dance/DanceCompany1_jhrzgr.png',
          name: 'O School',
          location: 'Orchard Link, Singapore',
          rating: '4.1',
          reviews: '225',
          category: 'dance',
          description:
            'Break, lock and pop like the stunners in Step Up. The school’s repertoire of dance courses will teach you everything from hip hop to the eclectic reggae dancehall. In addition to more structured dance courses, the school also offers open classes that you can jump right into – y’know, if you’re feeling spontaneously footloose like Kevin Bacon.',
        },
        {
          id: '2',
          companyImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661235852/probuddy/static/images/CategoriesPage/Dance/DanceCompany1_jhrzgr.png',
          name: 'O School',
          location: 'Orchard Link, Singapore',
          rating: '4.2',
          reviews: '198',
          category: 'dance',
          description:
            'Break, lock and pop like the stunners in Step Up. The school’s repertoire of dance courses will teach you everything from hip hop to the eclectic reggae dancehall. In addition to more structured dance courses, the school also offers open classes that you can jump right into – y’know, if you’re feeling spontaneously footloose like Kevin Bacon.',
        },
      ],
    },

    topRatedClasses: {
      title: 'Book The Right Dance Classes Near You',
      description:
        "More of everything you want. Dance your way to the top. Get the best dance classes in town. Learn from our best instructors. Our dance classes have it all, and we're confident you'll love them. More top-rated dance classes, more dances to learn, more fun to be had. Start dancing now!",
      classesData: [
        {
          id: '1',
          classesImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661235852/probuddy/static/images/CategoriesPage/Dance/DanceClasses1_unwlri.png',
          className: 'Group Dance Class (All Ages)',
          instructorName: 'O School',
          location: 'Singapore, SG',
          rating: '4.8',
          reviews: '225',
          category: 'dance',
          description:
            'A dance school like no other. Find dance courses in O School, study and learn to dance in a more fun way. Go now and learn the magic of dancing.',
          bookingTimings: '8.00 Am to 10.00 Am',
          bookingPrice: '100',
        },
        {
          id: '2',
          classesImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661235852/probuddy/static/images/CategoriesPage/Dance/DanceClasses2_iz4tah.png',
          className: 'Ballroom Dance Class (Adults)',
          instructorName: 'Dion Russell',
          location: 'London, UK',
          rating: '4.8',
          reviews: '201',
          category: 'dance',
          description:
            'Let your foot do the talking. Your body craves the workout, the fun, and the freedom to really let loose. Book your ballroom dance class now!',
          bookingTimings: '8.00 Am to 10.00 Am',
          bookingPrice: '120',
        },
        {
          id: '3',
          classesImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661235852/probuddy/static/images/CategoriesPage/Dance/DanceClasses3_xykxdd.png',
          className: 'Private Dance Lessons',
          instructorName: 'Sean Kenny',
          location: 'Brooklyn, NY',
          rating: '4.6',
          reviews: '225',
          category: 'dance',
          description:
            'Dancing Together is the Perfect Date. The world is a brighter place when it has more dancing. We have the right music. We have the right moves. Come and join us!',
          bookingTimings: '8.00 Am to 10.00 Am',
          bookingPrice: '120',
        },
      ],
    },

    reviews: [
      {
        name: 'Courtney Henry',
        country: 'Hong Kong',
        imageSrc: ReviewImage1,
        description:
          'The Probuddy platform is a great solution for business owners. We highly recommend it for businesses that need to keep track of everything.',
      },
      {
        name: 'Simon Hart',
        country: 'Texas',
        imageSrc: ReviewImage2,
        description:
          'I have tried many online courses in my life, but none that have been so effective as Probuddy. The course has helped me with my business and gave me the tools to be more successful. The instructors are very knowledgeable and helpful. I have enjoyed the course and look forward to taking more classes.',
      },
      {
        name: 'Hayley Smith',
        country: 'Manchester',
        imageSrc: ReviewImage6,
        description:
          'Taking time out to destress with a tennis session is always a wonderful thing. Using Probuddy makes it even more enjoyable.',
      },
    ],

    features: {
      title: 'Get your groove on. Start dancing today! We can help.',
      description:
        'Match up with verified and certified pros near you with Pro Connect+. Just create a Match Request, and we’ll connect you with a perfect match.',
    },

    sellers: {
      title:
        ' Class, Appointment, Activity booking on the go! Wherever you are, your schedule is right at your fingertips. From the first class to the last, Probuddy has your back!',
      linkText: 'Learn More',
    },
  },

  // {
  //   id: 'volleyball',
  //   title: 'Volleyball Classes',
  //   description: 'Supercharge your Game with Volleyball',
  //   heroImage:
  //     'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661333829/probuddy/static/images/CategoriesPage/Volleyball/VolleyballHeroImage_hijoax.png',

  //   section1: {
  //     imgSrc:
  //       'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661333825/probuddy/static/images/CategoriesPage/Volleyball/BestVolleyballClasses_hf3qmx.png',
  //     heading: 'The Best Volleyball Classes, Now at Your Fingertips.',
  //     description:
  //       'Are you looking for a fun place to play with friends? Want to improve your skills? Volleyball classes are a fun way to get fit and stay healthy and Probuddy has the perfect volleyball classes for you! You can even choose a specific level of play, so you can keep things competitive or just have fun. From beginners to advanced volleyball players, you can find a class that is suited for you.',
  //     linkText: 'Book A Volleyball Class Now',
  //   },

  //   section2: {
  //     imgSrc:
  //       'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661333825/probuddy/static/images/CategoriesPage/Volleyball/BringVolleyballClasses_f2ceob.png',
  //     heading: 'We Bring the Volleyball Class to You',
  //     description:
  //       "Whether you're looking to practice with friends, or to improve your skills, we've surely one volleyclass class that's right for you! Book a voleyball class with Probuddy and get access to all volleyball classes taught by certified volleyball coaches. You can even choose a specific level of play, so you can keep things competitive or just have fun.",
  //     linkText: 'See our Volleyball Listings',
  //   },

  //   exercise: {
  //     productTitle: 'Popular Volleyball Listings in your area',
  //     title: 'Types of Volleyball Classes & Lessons Hosted by Pros',
  //     options: [
  //       {
  //         name: 'Group Volleyball Lessons',
  //         description:
  //           'Recommended for groups looking for a focused training approach to excel and compete on a higher level!',
  //         imageSrc:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661333825/probuddy/static/images/CategoriesPage/Volleyball/Volleyball1_yup8ho.png',
  //       },
  //       {
  //         name: 'Indoor Volleyball Classes',
  //         description:
  //           'Indoor class provide our top coaches with the ability to incorporate drills that reflect game-like situations.',
  //         imageSrc:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661333828/probuddy/static/images/CategoriesPage/Volleyball/Volleyball2_kcvrgj.png',
  //       },
  //       {
  //         name: 'Private/Semi Private Classes',
  //         description: 'Single to small group training with one of our amazing Volleyball coaches!',
  //         imageSrc:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661333826/probuddy/static/images/CategoriesPage/Volleyball/Volleyball3_cwbgmx.png',
  //       },
  //       {
  //         name: 'Beach Volleyball Class',
  //         description:
  //           'Beach training for those who want to get some sun, we are sure to have something for you!',
  //         imageSrc:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661333825/probuddy/static/images/CategoriesPage/Volleyball/Volleyball4_tscnhi.png',
  //       },
  //       {
  //         name: 'Beginner/Kids Classes',
  //         description:
  //           'Your child will learn the game through drills and scrimmages including rules of the game, sportsmanship and fun!',
  //         imageSrc:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661333826/probuddy/static/images/CategoriesPage/Volleyball/Volleyball5_erdjp6.png',
  //       },
  //       {
  //         name: 'Intermediate Class',
  //         description:
  //           'Ideal for players with basic understanding of the game but is looking to take their skills to the next level!',
  //         imageSrc:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661333826/probuddy/static/images/CategoriesPage/Volleyball/Volleyball6_thgnyi.png',
  //       },
  //     ],
  //   },

  //   whyBook: {
  //     imgSrc:
  //       'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661333825/probuddy/static/images/CategoriesPage/Volleyball/BookVolleyballImage_f3xpym.png',
  //     heading1: 'One Marketplace for All Things Sports, Events and Adventure',
  //     position: 'center',
  //     containerPosition: 'start',
  //     description1:
  //       'Probuddy is a one-stop Marketplace for all things sports, wellness & adventure. It gives you worldwide access to thousands of top-rated fitness instructors, yoga classes, tennis coaches, or rock climbing events. All you need to do is find your ideal workout activity and book it!',
  //     heading2: 'Why Book on Probuddy?',
  //     description2:
  //       "Probuddy is the easiest way to book the best fitness classes across the globe - no more waiting or driving around to find the right class for you. Our goal is to help you find the perfect instructor or class in your area so that you can make your next activity one you'll never forget!",
  //   },

  //   classesNearYou: {
  //     title: 'Book The Best Volleyball Classes and Lessons Near You',
  //     description:
  //       "The best volleyball classes are open to everyone and available for all skill levels. Whether you just want to play a few times a week or improve your skills, we've got a class that's right for you. Book your next class with Probuddy today and get access to over 100+ different classes in your area.",
  //   },

  //   instructors: {
  //     title: 'Certified Volleyball Instructors & Coaches. Join the Pros',
  //     description:
  //       "Build confidence with our lessons, gain skills, and learn from our volleyball Pros. We've built a platform that allows volleyball coaches to create fun, engaging lessons and make them available to students. That's how we help you take your skills to the next level.",
  //     instructorsData: [
  //       {
  //         id: '1',
  //         trainerImage:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661333827/probuddy/static/images/CategoriesPage/Volleyball/VolleyballTrainer1_grkymb.png',
  //         name: 'Carl Grand',
  //         type: 'Volleyball Instructor',
  //         rating: '4.4',
  //         reviews: '15',
  //         category: 'volleyball',
  //         description:
  //           'Representing Australia in both the indoor volleyball and beach volleyball and was awarded ‘Australian Volleyball Athlete of the year’.',
  //       },
  //       {
  //         id: '2',
  //         trainerImage:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661333826/probuddy/static/images/CategoriesPage/Volleyball/VolleyballTrainer2_tz9yic.png',
  //         name: 'Ken Schmidt',
  //         type: 'Volleyball Coach',
  //         rating: '4.5',
  //         reviews: '19',
  //         category: 'volleyball',
  //         description:
  //           'Ken has 3+ years experience in teaching volleyball for all ages and abilities, from 12 years all the way up to 40+ year old adults.',
  //       },
  //       {
  //         id: '3',
  //         trainerImage:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661333827/probuddy/static/images/CategoriesPage/Volleyball/VolleyballTrainer3_abulrb.png',
  //         name: 'Tricia Tan',
  //         type: 'Volleyball Coach',
  //         rating: '4.7',
  //         reviews: '13',
  //         category: 'volleyball',
  //         description:
  //           'Coach Tricia competed internationally in the 2020 Olympic Qualification Tournament and Asian & World Championships for over 5 years.',
  //       },
  //     ],
  //   },

  //   featured: {
  //     title: 'Top-Rated Volleyball Courts and Facilities Bookings',
  //     description:
  //       'We know that it can be hard to find the right class for you, but with our help, finding your perfect fit is easier than ever. Probuddy is the ultimate resource for finding the best volleyball courts near you. Our featured top-rated volleyball facilities are curated to provide a unique guide to the best of what class has to offer.',
  //     featuredItems: [
  //       {
  //         id: '1',
  //         companyImage:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661333827/probuddy/static/images/CategoriesPage/Volleyball/VolleyballCompany1_ne7jwu.png',
  //         name: 'Central Park Volleyball Courts',
  //         location: 'New York, NY',
  //         rating: '4.1',
  //         reviews: '225',
  //         category: 'volleyball',
  //         description:
  //           "Volleyball courts for pickup games are available at two locations in Central Park. There are two asphalt courts and two sand courts to the east of Sheep Meadow, and two asphalt courts northeast of the Great Lawn. Courts are available on a first-come, first-served basis. Be sure to bring your own ball. For play on asphalt courts, you'll also need to bring your own net.",
  //       },
  //       {
  //         id: '2',
  //         companyImage:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661333827/probuddy/static/images/CategoriesPage/Volleyball/VolleyballCompany1_ne7jwu.png',
  //         name: 'Central Park Volleyball Courts',
  //         location: 'New York, NY',
  //         rating: '4.2',
  //         reviews: '198',
  //         category: 'volleyball',
  //         description:
  //           "Volleyball courts for pickup games are available at two locations in Central Park. There are two asphalt courts and two sand courts to the east of Sheep Meadow, and two asphalt courts northeast of the Great Lawn.Courts are available on a first-come, first-served basis. Be sure to bring your own ball. For play on asphalt courts, you'll also need to bring your own net.",
  //       },
  //     ],
  //   },

  //   topRatedClasses: {
  //     title: 'Search and Book The Best Volleyball Classes Right Here!',
  //     description:
  //       "You'll always have access to the volleyball experience you need. Find great volleyball classes in your area and book lessons with instructors who are passionate about the game for a more personal experience. Find all the best volleyball classes and lessons on Probuddy.",
  //     classesData: [
  //       {
  //         id: '1',
  //         classesImage:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661333826/probuddy/static/images/CategoriesPage/Volleyball/VolleyballClasses1_hyro5k.png',
  //         className: 'Group Vollleyball Class (Adult Beginner)',
  //         instructorName: 'O School',
  //         location: 'Singapore, SG',
  //         rating: '4.8',
  //         reviews: '225',
  //         category: 'volleyball',
  //         description:
  //           'Volleyball programs for beginners with  fundamental concepts of the game in a fun, supportive, non-competitive environment.',
  //         bookingTimings: '8.00 Am to 10.00 Am',
  //         bookingPrice: '100',
  //       },
  //       {
  //         id: '2',
  //         classesImage:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661333826/probuddy/static/images/CategoriesPage/Volleyball/VolleyballClasses2_xwzrrg.png',
  //         className: 'Volleyball Fundamentals for Kids & Teens',
  //         instructorName: 'Dianne Russell',
  //         location: 'London, UK',
  //         rating: '4.8',
  //         reviews: '201',
  //         category: 'volleyball',
  //         description:
  //           'We  provide an opportunity to learn and enjoy playing volleyball at an early age for development and growth.',
  //         bookingTimings: '8.00 Am to 10.00 Am',
  //         bookingPrice: '120',
  //       },
  //       {
  //         id: '3',
  //         classesImage:
  //           'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661333826/probuddy/static/images/CategoriesPage/Volleyball/VolleyballClasses3_nckrlw.png',
  //         className: 'Private Advanced Volleyball Lessons',
  //         instructorName: 'Sean Kenny',
  //         location: 'Brooklyn, NY',
  //         rating: '4.6',
  //         reviews: '225',
  //         category: 'volleyball',
  //         description:
  //           'All aspects of the game are taught through drills and exercises that focus on passing, setting, hitting and serving.',
  //         bookingTimings: '8.00 Am to 10.00 Am',
  //         bookingPrice: '120',
  //       },
  //     ],
  //   },

  //   reviews: [
  //     {
  //       name: 'Courtney Henry',
  //       country: 'Hong Kong',
  //       imageSrc: ReviewImage1,
  //       description:
  //         'The Probuddy platform is a great solution for business owners. We highly recommend it for businesses that need to keep track of everything.',
  //     },
  //     {
  //       name: 'Simon Hart',
  //       country: 'Texas',
  //       imageSrc: ReviewImage2,
  //       description:
  //         'I have tried many online courses in my life, but none that have been so effective as Probuddy. The course has helped me with my business and gave me the tools to be more successful. The instructors are very knowledgeable and helpful. I have enjoyed the course and look forward to taking more classes.',
  //     },
  //     {
  //       name: 'Hayley Smith',
  //       country: 'Manchester',
  //       imageSrc: ReviewImage6,
  //       description:
  //         'Taking time out to destress with a tennis session is always a wonderful thing. Using Probuddy makes it even more enjoyable.',
  //     },
  //   ],

  //   features: {
  //     title: 'Get your groove on. Start dancing today! We can help.',
  //     description:
  //       'Match up with verified and certified pros near you with Pro Connect+. Just create a Match Request, and we’ll connect you with a perfect match.',
  //   },

  //   sellers: {
  //     title:
  //       ' Class, Appointment, Activity booking on the go! Wherever you are, your schedule is right at your fingertips. From the first class to the last, Probuddy has your back!',
  //     linkText: 'Learn More',
  //   },
  // },

  {
    id: 'rock-climbing',
    title: 'Bouldering & Rock Climbing Classes',
    description: 'The Best Place to Find Your Next Climbing Spot',
    heroImage:
      'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661502112/probuddy/static/images/CategoriesPage/Rock%20Climbing/RockClimbingHeroImage_ud8hhk.png',

    section1: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661502109/probuddy/static/images/CategoriesPage/Rock%20Climbing/ClimbBetterFaster_w0vjth.png',
      heading: 'Climb Better. Faster. Bolder. Stronger.',
      description:
        "Looking for an adrenaline-pumping rock climbing or bouldering adventure? You've come to the right place! We've got the best selection of rock climbing and bouldering spots, so you can find the perfect one for your skills and preferences. At Probuddy,  you can easily search and book the best rock climbing and bouldering experiences in your area. Climb with us today!",
      linkText: 'Book a Rock Climbing or Bouldering Session Now',
    },

    section2: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661502109/probuddy/static/images/CategoriesPage/Rock%20Climbing/MoreRocksRopes_xy8z01.png',
      heading: 'More Rocks, More Ropes, More Fun!',
      description:
        "Looking for an adventurous way to spend your free time? Why not try rock climbing or bouldering? Search and book the best rock climbing and bouldering spots near you, right now!! With so many different locations to choose from, you're sure to find the perfect place to test your skills. Whether you're a beginner or a seasoned pro, we have something for everyone!",
      linkText: 'See Our Rock Climbing Listings',
    },

    exercise: {
      productTitle: 'Popular Rock Climbing & Bouldering Listings in your area',
      title: 'Types of Rock Climbing & Bouldering Hosted by Pros',
      options: [
        {
          name: 'Bouldering Classes',
          description:
            'Keen to learn more about bouldering? Explore our bouldering lessons for beginners and Pros.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661502109/probuddy/static/images/CategoriesPage/Rock%20Climbing/RockClimbing1_qwpo8l.png',
        },
        {
          name: 'Top Rope Climbing Classes',
          description: 'Learn climbing knots, harnesses, belay skills, and climbing commands.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661502109/probuddy/static/images/CategoriesPage/Rock%20Climbing/RockClimbing2_gqaqbq.png',
        },
        {
          name: 'Indoor Rock Climbing',
          description:
            'From beginners to professionals, you can find climbing routes at any skill level and difficulty level.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661502109/probuddy/static/images/CategoriesPage/Rock%20Climbing/RockClimbing3_etutln.png',
        },
        {
          name: 'Outdoor Rock Climbing',
          description:
            'A fun, exhilarating outdoor rock climbing adventure that can be enjoyed by people of all ages and skills.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661502110/probuddy/static/images/CategoriesPage/Rock%20Climbing/RockClimbing4_tq17m6.png',
        },
        {
          name: 'Rock Climbing for Kids',
          description:
            'The best total body workout for children. Improve their core strength, balance, coordination, and flexibility.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661502110/probuddy/static/images/CategoriesPage/Rock%20Climbing/RockClimbing5_fyoiz1.png',
        },
        {
          name: 'Rock Climbing for Beginners',
          description:
            'Ideal for people with basic understanding of the rock climbing and bouldering, who are looking to take their skills in the next level!',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661502110/probuddy/static/images/CategoriesPage/Rock%20Climbing/RockClimbing6_rolkdg.png',
        },
      ],
    },

    whyBook: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661502109/probuddy/static/images/CategoriesPage/Rock%20Climbing/BookRockClimbingImage_gy9ir0.png',
      heading1: 'One Marketplace for All Things Sports, Events and Adventure',
      position: 'center',
      containerPosition: 'start',
      description1:
        'Probuddy is a one-stop Marketplace for all things sports, wellness & adventure. It gives you worldwide access to thousands of top-rated fitness instructors, yoga classes, tennis coaches, or rock climbing events. All you need to do is find your ideal workout activity and book it!',
      heading2: 'Why Book on Probuddy?',
      description2:
        "Probuddy is the easiest way to book the best fitness classes across the globe - no more waiting or driving around to find the right class for you. Our goal is to help you find the perfect instructor or class in your area so that you can make your next activity one you'll never forget!",
    },

    classesNearYou: {
      title: 'Book The Best Rock Climbing & Bouldering Near You',
      description:
        "The rock climbing and bouldering scene is a diverse one, with hundreds of excellent local spots to explore. Whether you're looking for a short loop around the around the city center or a full-day adventure to the west or east side of the city, we've got the perfect rock climbing and bouldering adventures for you. Book your next class with Probuddy today and get access to over 100+ different classes in your area.",
    },

    instructors: {
      title: 'Certified Rock Climbing & Bouldering Instructors. Join the Pros',
      description:
        'Join the Pros at our rock climbing & bouldering adventures! We offer a variety of training options for all ages and skill levels. Our certified rock climbing & bouldering instructors are passionate about helping you become a better climber and can help you improve your climbing technique. Whether you want to climb indoors or outdoors, in gyms, parks or at home, our certified rock climbing & bouldering Pros will make sure you reach your goals safely and efficiently.',
      instructorsData: [
        {
          id: '1',
          trainerImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661502110/probuddy/static/images/CategoriesPage/Rock%20Climbing/RockClimbingTrainer1_ivn2uu.png',
          name: 'Steve Bloom',
          type: 'Rock Climbing Instructor',
          rating: '4.4',
          reviews: '15',
          category: 'rock-climbing',
          description:
            'Steve has been developing his climb skills with us for 3 years. His bright and fun energy keeps all the kids moving when he coaches youth class.',
        },
        {
          id: '2',
          trainerImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661502111/probuddy/static/images/CategoriesPage/Rock%20Climbing/RockClimbingTrainer2_rfdgyd.png',
          name: 'Ryan Cheng',
          type: 'Rock Climbing Instructor',
          rating: '4.5',
          reviews: '19',
          category: 'rock-climbing',
          description:
            'Ryan has climbed at a number of places, indoors and outdoors when he can manage it.',
        },
        {
          id: '3',
          trainerImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661502111/probuddy/static/images/CategoriesPage/Rock%20Climbing/RockClimbingTrainer3_vkvqqm.png',
          name: 'Tanya Collins',
          type: 'Rock Climbing Instructor',
          rating: '4.7',
          reviews: '13',
          category: 'rock-climbing',
          description:
            'Tanya joins us to share her incredible expertise on rock climbing and outdoor adventure sports.',
        },
      ],
    },

    featured: {
      title: 'Top-Rated Rock Climbing & Bouldering Clubs Bookings',
      description:
        "If you're looking for a rock climbing or bouldering club near you, then look no further than our list of the best ones. We have a great range of rock climbing & bouldering clubs in your area to suit all abilities, styles and ages. From beginner to expert, we have the perfect climbing location for you!",
      featuredItems: [
        {
          id: '1',
          companyImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661502111/probuddy/static/images/CategoriesPage/Rock%20Climbing/RockClimbingCompany1_zcb6cc.png',
          name: 'Climb Central Sports Hub',
          location: 'Stadium Place, Singapore',
          rating: '4.1',
          reviews: '225',
          category: 'rock-climbing',
          description:
            "You don’t need prior experience to scale the walls at Kallang Wave Mall's Climb Central Sports Hub, home to the tallest indoor air-conditioned rock face in Singapore which goes up to 16-metres high. Advanced climbing safety systems are in place to ensure even the most inexperienced climbers (as long as you’re above the age of five) can clamber to new heights – there are 45 climbing lanes and 4-metre-high bouldering walls – in a safe and controlled environment.",
        },
        {
          id: '2',
          companyImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661502111/probuddy/static/images/CategoriesPage/Rock%20Climbing/RockClimbingCompany1_zcb6cc.png',
          name: 'Climb Central Sports Hub',
          location: 'Stadium Place, Singapore',
          rating: '4.1',
          reviews: '225',
          category: 'rock-climbing',
          description:
            "You don’t need prior experience to scale the walls at Kallang Wave Mall's Climb Central Sports Hub, home to the tallest indoor air-conditioned rock face in Singapore which goes up to 16-metres high. Advanced climbing safety systems are in place to ensure even the most inexperienced climbers (as long as you’re above the age of five) can clamber to new heights – there are 45 climbing lanes and 4-metre-high bouldering walls – in a safe and controlled environment.",
        },
      ],
    },

    topRatedClasses: {
      title: 'Search and Book The Best Rock Climbing & Bouldering Here!',
      description:
        "Rock climbing and bouldering are fun, exhilarating sports that can be enjoyed by people of all ages and abilities. Whether you're looking for the best bouldering, the best rock climbing gyms or the best walls in your area, we have you covered with our marketplace. We have rock climbing classes and private lessons as well as outdoor climbing and bouldering trips near you and beyond.",
      classesData: [
        {
          id: '1',
          classesImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661502109/probuddy/static/images/CategoriesPage/Rock%20Climbing/RockClimbingClasses1_bmcpgg.png',
          className: 'Group Rock Climbing (Adult Beginner)',
          instructorName: 'Climb Central',
          location: 'Singapore, SG',
          rating: '4.8',
          reviews: '225',
          category: 'rock-climbing',
          description:
            'Looking for an adventurous way to spend your free time? Book the best rock climbing and bouldering spots right here!',
          bookingTimings: '8.00 Am to 10.00 Am',
          bookingPrice: '100',
        },
        {
          id: '2',
          classesImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661502110/probuddy/static/images/CategoriesPage/Rock%20Climbing/RockClimbingClasses2_rjslxb.png',
          className: 'Volleyball Fundamentals for Kids & Teens',
          instructorName: 'Dianne Russell',
          location: 'London, UK',
          rating: '4.8',
          reviews: '201',
          category: 'rock-climbing',
          description:
            'We are excited to offer you the best selection of rock climbing and bouldering options for kinds in your area.',
          bookingTimings: '8.00 Am to 10.00 Am',
          bookingPrice: '120',
        },
        {
          id: '3',
          classesImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661502109/probuddy/static/images/CategoriesPage/Rock%20Climbing/RockClimbingClasses3_k8cpon.png',
          className: 'Private Rock Climbing  Lessons',
          instructorName: 'Sean Kenny',
          location: 'Brooklyn, NY',
          rating: '4.6',
          reviews: '225',
          category: 'rock-climbing',
          description:
            'Looking for the best rock climbing and bouldering in town? Look no further! We have everything you need to get started on your next adventure.',
          bookingTimings: '8.00 Am to 10.00 Am',
          bookingPrice: '120',
        },
      ],
    },

    reviews: [
      {
        name: 'Courtney Henry',
        country: 'Hong Kong',
        imageSrc: ReviewImage1,
        description:
          'The Probuddy platform is a great solution for business owners. We highly recommend it for businesses that need to keep track of everything.',
      },
      {
        name: 'Simon Hart',
        country: 'Texas',
        imageSrc: ReviewImage2,
        description:
          'I have tried many online courses in my life, but none that have been so effective as Probuddy. The course has helped me with my business and gave me the tools to be more successful. The instructors are very knowledgeable and helpful. I have enjoyed the course and look forward to taking more classes.',
      },
      {
        name: 'Hayley Smith',
        country: 'Manchester',
        imageSrc: ReviewImage6,
        description:
          'Taking time out to destress with a tennis session is always a wonderful thing. Using Probuddy makes it even more enjoyable.',
      },
    ],

    features: {
      title: 'Adventure Awaits. Ready to Climb? We can help.',
      description:
        'Match up with verified and certified pros near you with Pro Connect+. Just create a Match Request, and we’ll connect you with a perfect match.',
    },

    sellers: {
      title:
        ' Class, Appointment, Activity booking on the go! Wherever you are, your schedule is right at your fingertips. From the first class to the last, Probuddy has your back!',
      linkText: 'Learn More',
    },
  },

  {
    id: 'surfing',
    title: 'Surfing Classes',
    description: 'Find Your Perfect Wave. Surf’s Up!',
    heroImage:
      'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661947331/probuddy/static/images/CategoriesPage/Surfing/SurfingHeroImage_yq8pda.png',

    section1: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661947328/probuddy/static/images/CategoriesPage/Surfing/HappinessSurfing_bsk5is.png',
      heading: 'Happiness Comes In Waves. Go Surfing!',
      description:
        "Whether you're looking for something new to do on your next vacation, or just want to try out surfing near you, this is the place to start! Our surfing lessons for both beginners and advanced surfers alike. We have surfing instructors who are passionate about teaching you how to stand up paddle, catch waves and get back up on their feet again after being knocked down by a rogue wave. Learning to surf is on top of your bucket list? Book your first surfing class today and be ready to surf until you drop!",
      linkText: 'Book A Surfing Class Now',
    },

    section2: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661947328/probuddy/static/images/CategoriesPage/Surfing/ScorePerfectWave_zq1pm5.png',
      heading: 'Score Perfect Waves & Surfing Classes',
      description:
        'Have you ever wanted to learn how to surf, but didn’t know where to start? Whether you’re a beginner, intermediate or advanced surfer, there are many places and classes that can help you improve your skills. For the ultimate surf-cation, Probuddy has a wide array a surfing classes and lessons for you and your friends. Our experienced instructors will take you through the basics of surfing and bring out your inner surfer. We’ll teach you how to surf in all kinds of conditions, from calm seas to big waves. You’ll have fun from start to finish.',
      linkText: 'See Our Surfing Listings',
    },

    exercise: {
      productTitle: 'Popular Surfing Listings in your area',
      title: 'Types of Surfing Lessons Hosted by Pros',
      options: [
        {
          name: 'Private Surfing Lessons',
          description: 'Learn to ride the waves like a pro with private surf lessons in your area.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661947328/probuddy/static/images/CategoriesPage/Surfing/Surfing1_cbdqol.png',
        },
        {
          name: 'Surfing Group Classes',
          description: 'Learn climbing knots, harnesses, belay skills, and climbing commands.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661947327/probuddy/static/images/CategoriesPage/Surfing/Surfing2_ykvcej.png',
        },
        {
          name: 'Kids & Adults Surf Camp',
          description:
            "Great for after school programs, women's programs and surfing lessons for everyone.",
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661947327/probuddy/static/images/CategoriesPage/Surfing/Surfing3_aq5wvb.png',
        },
        {
          name: 'Indoor Surfing Lessons',
          description:
            'A fun, exhilarating outtdoor indoor surfing lessons that can be enjoyed by people of all ages.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661947328/probuddy/static/images/CategoriesPage/Surfing/Surfing4_wp4jmv.png',
        },
        {
          name: 'Surfing Lessons for Beginners',
          description:
            'Perfect for the beginner to intermediate surfer with a guarantee that you will be standing up before you leave!',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661947328/probuddy/static/images/CategoriesPage/Surfing/Surfing5_d5u6ed.png',
        },
        {
          name: 'Outdoor Surfing Lessons',
          description:
            'Advanced surf lessons are tailored to those who are already experienced surfers mastering maneuvers.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661947328/probuddy/static/images/CategoriesPage/Surfing/Surfing6_juina3.png',
        },
      ],
    },

    whyBook: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661947328/probuddy/static/images/CategoriesPage/Surfing/BookSurfingImage_yncwjj.png',
      heading1: 'One Marketplace for All Things Sports, Events and Adventure',
      position: 'center',
      containerPosition: 'start',
      description1:
        'Probuddy is a one-stop Marketplace for all things sports, wellness & adventure. It gives you worldwide access to thousands of top-rated fitness instructors, yoga classes, tennis coaches, or rock climbing events. All you need to do is find your ideal workout activity and book it!',
      heading2: 'Why Book on Probuddy?',
      description2:
        "Probuddy is the easiest way to book the best fitness classes across the globe - no more waiting or driving around to find the right class for you. Our goal is to help you find the perfect instructor or class in your area so that you can make your next activity one you'll never forget!",
    },

    classesNearYou: {
      title: 'Book The Best Surfing Classes & Lessons Near You',
      description:
        'If you’re ready to take your love for the water a step further and learn how to surf, we have some great news for you! Probuddy has every surfing classes and lessons available that can get you acquainted with this exhilarating pastime in no time at all. Whether you want to learn as a beginner or master the art as an intermediate surfer, these classes will help you get started on the right foot. Book your next class today and get access to over hundreds of different classes in your area.',
    },

    instructors: {
      title: 'Certified Surf Instructors. Join the Pros',
      description:
        'Looking to get into the water and catch a few waves? No matter your skill level, there is a surfing class near you that will meet your needs and skill level and teach you everything from fundamentals to more advanced techniques our certified surfing instructors in your area. Our Pros know that every student has different abilities and learning styles, so they tailor each class to suit everyone’s needs.',
      instructorsData: [
        {
          id: '1',
          trainerImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661947329/probuddy/static/images/CategoriesPage/Surfing/SurfingTrainer1_hvfkno.png',
          name: 'Chad Davis',
          type: 'Surf Instructor',
          rating: '4.4',
          reviews: '15',
          category: 'surfing',
          description:
            'Steve has been developing his climb skills with us for 3 years. His bright and fun energy keeps all the kids moving when he coaches youth class,',
        },
        {
          id: '2',
          trainerImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661947329/probuddy/static/images/CategoriesPage/Surfing/SurfingTrainer2_ovb5bc.png',
          name: 'Jeremy Bagg',
          type: 'Surf Instructor',
          rating: '4.5',
          reviews: '19',
          category: 'surfing',
          description:
            'Ryan has climbed at a number of places, indoors and outdoors when he can manage it.',
        },
        {
          id: '3',
          trainerImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661947330/probuddy/static/images/CategoriesPage/Surfing/SurfingTrainer3_nuhto6.png',
          name: 'Maine Chua',
          type: 'Surf Instructor',
          rating: '4.7',
          reviews: '13',
          category: 'surfing',
          description:
            'Tanya joins us to share her incredible expertise on rock climbing and outdoor adventure sports.',
        },
      ],
    },

    featured: {
      title: 'Top-Rated Surfing Activity & Events  Bookings',
      description:
        "If you're looking for a surfing schools near you, then look no further than our list of the best ones. We have a wide range of surfing activities, schools and upcoming events in your area to suit all abilities, styles and ages. From beginner to expert, we have the right one for you!",
      featuredItems: [
        {
          id: '1',
          companyImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661947329/probuddy/static/images/CategoriesPage/Surfing/SurfingCompany1_cfoeui.png',
          name: 'Stingray®',
          location: 'Stadium Boulevard Kallang, Singapore',
          rating: '4.1',
          reviews: '225',
          category: 'surfing',
          description:
            'Water you doing this weekend? Come ride some waves with us on the Stingray®, book the most favorite sun and surf activity that promises a splashing good time - no prior surfing experience needed!',
        },
        {
          id: '2',
          companyImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661947329/probuddy/static/images/CategoriesPage/Surfing/SurfingCompany1_cfoeui.png',
          name: 'Stingray®',
          location: 'Stadium Boulevard Kallang, Singapore',
          rating: '4.1',
          reviews: '225',
          category: 'surfing',
          description:
            'Water you doing this weekend? Come ride some waves with us on the Stingray®, book the most favorite sun and surf activity that promises a splashing good time - no prior surfing experience needed!',
        },
      ],
    },

    topRatedClasses: {
      title: 'Search and Book The Best Surfing Classes & Lessons Here!',
      description:
        'Want to learn how to surf? Or are you ready to take your surfing skills to the next level? There’s no better time to start than now with Probuddy! And with so many surf lessons popping up everywhere, we want to make your search as easy as possible. If you want to learn how to surf this summer or any other time of year, here’s where you can find different surf classes available. Book a surfing lesson today!',
      classesData: [
        {
          id: '1',
          classesImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661947329/probuddy/static/images/CategoriesPage/Surfing/SurfingClasses1_ubgue4.png',
          className: 'Beginner Group Surf Lesson',
          instructorName: 'Surfs Up',
          location: 'Singapore, SG',
          rating: '4.8',
          reviews: '225',
          category: 'rock-climbing',
          description:
            'Looking for an adventurous way to spend your free time? Book the best surfing classes and lessons right here!',
          bookingTimings: '8.00 Am to 10.00 Am',
          bookingPrice: '100',
        },
        {
          id: '2',
          classesImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661947329/probuddy/static/images/CategoriesPage/Surfing/SurfingClasses2_mh13i8.png',
          className: 'Beginner Private Surf Lesson',
          instructorName: 'Douglas Bagg',
          location: 'London, UK',
          rating: '4.8',
          reviews: '201',
          category: 'rock-climbing',
          description:
            'We are excited to offer you the best selection of surfing lesons and indoor surfing options for kinds in your area.',
          bookingTimings: '8.00 Am to 10.00 Am',
          bookingPrice: '120',
        },
        {
          id: '3',
          classesImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661947329/probuddy/static/images/CategoriesPage/Surfing/SurfingClasses3_ucckbo.png',
          className: 'Intermediate Private Surf Lesson',
          instructorName: 'Sean Kenny',
          location: 'Brooklyn, NY',
          rating: '4.6',
          reviews: '225',
          category: 'rock-climbing',
          description:
            'Looking for the best surfing lessons in town? Look no further! We have everything you need to get started on your next adventure.',
          bookingTimings: '8.00 Am to 10.00 Am',
          bookingPrice: '120',
        },
      ],
    },

    reviews: [
      {
        name: 'Courtney Henry',
        country: 'Hong Kong',
        imageSrc: ReviewImage1,
        description:
          'The Probuddy platform is a great solution for business owners. We highly recommend it for businesses that need to keep track of everything.',
      },
      {
        name: 'Simon Hart',
        country: 'Texas',
        imageSrc: ReviewImage2,
        description:
          'I have tried many online courses in my life, but none that have been so effective as Probuddy. The course has helped me with my business and gave me the tools to be more successful. The instructors are very knowledgeable and helpful. I have enjoyed the course and look forward to taking more classes.',
      },
      {
        name: 'Hayley Smith',
        country: 'Manchester',
        imageSrc: ReviewImage6,
        description:
          'Taking time out to destress with a tennis session is always a wonderful thing. Using Probuddy makes it even more enjoyable.',
      },
    ],

    features: {
      title: 'The Fun Doesn’t Stop. Book A Surfing Class Today! ',
      description:
        'Match up with verified and certified pros near you with Pro Connect+. Just create a Match Request, and we’ll connect you with a perfect match.',
    },

    sellers: {
      title:
        ' Class, Appointment, Activity booking on the go! Wherever you are, your schedule is right at your fingertips. From the first class to the last, Probuddy has your back!',
      linkText: 'Learn More',
    },
  },

  {
    id: 'golf',
    title: 'Golf Lessons',
    description: 'Enjoy Golf Lessons & Coachings at Your Own Pace, Anytime.',
    heroImage:
      'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1662124080/probuddy/static/images/CategoriesPage/Golf/GolfHeroImage_xfgdm3.png',

    section1: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1662124076/probuddy/static/images/CategoriesPage/Golf/GolfLessons_nye3ip.png',
      heading: "We'll help you shave strokes off your game.",
      description:
        "Whether you're looking for something new to do on your next vacation, or just want to try out surfing near you, this is the place to start! Our surfing lessons for both beginners and advanced surfers alike. We have surfing instructors who are passionate about teaching you how to stand up paddle, catch waves and get back up on their feet again after being knocked down by a rogue wave. Learning to surf is on top of your bucket list? Book your first surfing class today and be ready to surf until you drop!",
      linkText: 'Book A Golf Lesson Now',
    },

    section2: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1662124075/probuddy/static/images/CategoriesPage/Golf/StrokesGame_j3yxup.png',
      heading: 'Get golf lessons from top instructors, without the travel.',
      description:
        'Have you ever wanted to learn how to surf, but didn’t know where to start? Whether you’re a beginner, intermediate or advanced surfer, there are many places and classes that can help you improve your skills. For the ultimate surf-cation, Probuddy has a wide array a surfing classes and lessons for you and your friends. Our experienced instructors will take you through the basics of surfing and bring out your inner surfer. We’ll teach you how to surf in all kinds of conditions, from calm seas to big waves. You’ll have fun from start to finish.',
      linkText: 'See Our Golf Listings',
    },

    exercise: {
      productTitle: 'Popular Golf Listings in your area',
      title: 'Types of Golf Lessons Hosted by Pros',
      options: [
        {
          name: 'Private Golf Lessons',
          description: 'One-on-one golf lessons for a fun and effective way to learn the game.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663745809/probuddy/static/images/CategoriesPage/Golf/Golf1_h2tlzj.png',
        },
        {
          name: 'Golf Lessons for Beginners',
          description:
            'Start to learn golf the proper way and get the right technique from the start.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663745810/probuddy/static/images/CategoriesPage/Golf/Golf2_pxu0mm.png',
        },
        {
          name: 'Golf Lessons for Kids',
          description:
            'Fun and enjoyable golf games, tkids will learn basic skills, etiquette and safety when playing.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663745810/probuddy/static/images/CategoriesPage/Golf/Golf3_s0mwly.png',
        },
        {
          name: 'PGA Golf Coaching',
          description: 'Learn to play the game of golf from a certified PGA member.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663745810/probuddy/static/images/CategoriesPage/Golf/Golf4_cgyjxd.png',
        },
        {
          name: 'Group Golf Lessons',
          description:
            'Learn how to play golf with your family & friends, then group golf lessons are the way to go!',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663745809/probuddy/static/images/CategoriesPage/Golf/Golf5_okk3mi.png',
        },
        {
          name: 'Corporate Golf Lessons',
          description:
            'Gel your team together and build uni-tee with our corporate team building golf lessons.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663745809/probuddy/static/images/CategoriesPage/Golf/Golf6_flx5el.png',
        },
      ],
    },

    whyBook: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1662124075/probuddy/static/images/CategoriesPage/Golf/BookGolfImage_cvd9se.png',
      heading1: 'One Marketplace for All Things Sports, Events and Adventure',
      position: 'center',
      containerPosition: 'start',
      description1:
        'Probuddy is a one-stop Marketplace for all things sports, wellness & adventure. It gives you worldwide access to thousands of top-rated fitness instructors, yoga classes, tennis coaches, or rock climbing events. All you need to do is find your ideal workout activity and book it!',
      heading2: 'Why Book on Probuddy?',
      description2:
        "Probuddy is the easiest way to book the best fitness classes across the globe - no more waiting or driving around to find the right class for you. Our goal is to help you find the perfect instructor or class in your area so that you can make your next activity one you'll never forget!",
    },

    classesNearYou: {
      title: 'Book The Best Golf Lessons Near You',
      description:
        'If you’re ready to take your love for the water a step further and learn how to surf, we have some great news for you! Probuddy has every surfing classes and lessons available that can get you acquainted with this exhilarating pastime in no time at all. Whether you want to learn as a beginner or master the art as an intermediate surfer, these classes will help you get started on the right foot. Book your next class today and get access to over hundreds of different classes in your area.',
    },

    instructors: {
      title: 'Certified Golf Instructors & Coaches. Join the Pros',
      description:
        'Looking to get into the water and catch a few waves? No matter your skill level, there is a surfing class near you that will meet your needs and skill level and teach you everything from fundamentals to more advanced techniques our certified surfing instructors in your area. Our Pros know that every student has different abilities and learning styles, so they tailor each class to suit everyone’s needs.',
      instructorsData: [
        {
          id: '1',
          trainerImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663746470/probuddy/static/images/CategoriesPage/Golf/GolfTrainer1_fzsnyt.png',
          name: 'Courtney Chen',
          type: 'Golf Instructor',
          rating: '4.6',
          reviews: '12',
          category: 'golf',
          description: `Courtney’s goal and heart is to help every golfer to reach their personal best while still creating a fun learning environment on the game.`,
        },
        {
          id: '2',
          trainerImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663746469/probuddy/static/images/CategoriesPage/Golf/GolfTrainer2_cztzhr.png',
          name: 'Marvin Meyer',
          type: 'Golf Instructor',
          rating: '4.6',
          reviews: '12',
          category: 'golf',
          description:
            'With his years of playing experience, PGA coach Marvin made a commitment to share his golf insights and help others.',
        },
        {
          id: '3',
          trainerImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663746471/probuddy/static/images/CategoriesPage/Golf/GolfTrainer3_cimtm6.png',
          name: 'Raj Patel',
          type: 'Golf Instructor',
          rating: '4.6',
          reviews: '12',
          category: 'golf',
          description:
            'Raj began his teaching career back in 2017 but decided to chase his dreams of overseas golfing experience and taught golf in Texas for 2 years.',
        },
      ],
    },

    featured: {
      title: 'Top-Rated Golf Courses, Lessons & Events  Bookings',
      description:
        "Finding a golf course, lessons, or event near you can be difficult. Luckily we've done all the hard work for you! Whether you're an avid golfer or just starting out on the course, we have a wide range of courses that will suit your needs.  We rank the best golf courses, golf lessons, and golf events available in your area.",
      featuredItems: [
        {
          id: '1',
          companyImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663746771/probuddy/static/images/CategoriesPage/Golf/GolfCompany_qgixzu.png',
          name: 'LA Golf Academy',
          location: 'Los Angeles, California',
          rating: '4.8',
          reviews: '230',
          category: 'golf',
          description:
            'We offer golf lessons for beginners, intermediate and advanced players. All students taught by PGA Professionals. All equipment provided, including clubs if needed.',
        },
        {
          id: '2',
          companyImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663746771/probuddy/static/images/CategoriesPage/Golf/GolfCompany_qgixzu.png',
          name: 'LA Golf Academy',
          location: 'Los Angeles, California',
          rating: '4.8',
          reviews: '230',
          category: 'golf',
          description:
            'We offer golf lessons for beginners, intermediate and advanced players. All students taught by PGA Professionals. All equipment provided, including clubs if needed.',
        },
      ],
    },

    topRatedClasses: {
      title: 'Search and Book The Best Golf Lessons & Coaching Here!',
      description:
        'Are you ready to learn how to play golf? Or are you ready to take your golf skills to the next level? Probuddy is the largest marketplace for golf lessons. We provide comprehensive listings of all types of golf classes in your area that include everything from beginners to advanced classes. If you are looking for a golf class, we can help you find the perfect one. Whether you are a beginner or an expert golfer, we have something for everyone!',
      classesData: [
        {
          id: '1',
          classesImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663747314/probuddy/static/images/CategoriesPage/Golf/GolfClasses1_qlmyfy.png',
          className: 'Adult Group Golf Lessons',
          instructorName: 'Courtney Chen',
          location: 'Singapore, SG',
          rating: '4.8',
          reviews: '230',
          category: 'golf',
          description:
            'We offer lessons for beginners, intermediate and advanced players. All lessons taught by certified PGA coaches.',
          bookingTimings: '8.00 Am to 10.00 Am',
          bookingPrice: '100',
        },
        {
          id: '2',
          classesImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663747314/probuddy/static/images/CategoriesPage/Golf/GolfClasses2_w9ufne.png',
          className: 'Private Golf Lessons for Beginners',
          instructorName: 'Raj Patel',
          location: 'London, UK',
          rating: '4.8',
          reviews: '230',
          category: 'golf',
          description:
            'Get personal, one on one beginner golf lessons. If you’re serious about making progress, one on one lessons are the best way to become a better golfer.',
          bookingTimings: '8.00 Am to 10.00 Am',
          bookingPrice: '120',
        },
        {
          id: '3',
          classesImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663747314/probuddy/static/images/CategoriesPage/Golf/GolfClasses3_tn7abj.png',
          className: 'PGA Golf Coaching',
          instructorName: 'Marvin meyer',
          location: 'Brooklyn, NY',
          rating: '4.8',
          reviews: '230',
          category: 'golf',
          description:
            'We will teach you the basics of the stance and set up , whether you have never played before or are an experienced player it always starts with good golf fundamentals.',
          bookingTimings: '8.00 Am to 10.00 Am',
          bookingPrice: '120',
        },
      ],
    },

    reviews: [
      {
        name: 'Courtney Henry',
        country: 'Hong Kong',
        imageSrc: ReviewImage1,
        description:
          'The Probuddy platform is a great solution for business owners. We highly recommend it for businesses that need to keep track of everything.',
      },
      {
        name: 'Simon Hart',
        country: 'Texas',
        imageSrc: ReviewImage2,
        description:
          'I have tried many online courses in my life, but none that have been so effective as Probuddy. The course has helped me with my business and gave me the tools to be more successful. The instructors are very knowledgeable and helpful. I have enjoyed the course and look forward to taking more classes.',
      },
      {
        name: 'Hayley Smith',
        country: 'Manchester',
        imageSrc: ReviewImage6,
        description:
          'Taking time out to destress with a tennis session is always a wonderful thing. Using Probuddy makes it even more enjoyable.',
      },
    ],

    features: {
      title: 'Get to grips with the basics. Book A Golf Lesson Faster!',
      description:
        'Match up with verified and certified pros near you with Pro Connect+. Just create a Match Request, and we’ll connect you with a perfect match.',
    },

    sellers: {
      title: `Never miss a thing! Book any class, appointment, or activity, in seconds. It's never been easier to find the perfect activity for you and your friends with Probuddy.`,
      linkText: 'Learn More',
    },
  },

  {
    id: 'personal-trainer',
    title: 'Personal Training Classes',
    description: 'Personalized, Results-Driven, World-Class Personal Training',
    heroImage:
      'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663847133/probuddy/static/images/CategoriesPage/Personal%20Trainer/PTHeroImage_fu6hom.png',

    section1: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663847729/probuddy/static/images/CategoriesPage/Personal%20Trainer/PTLesson_tpjwmw.png',
      heading: 'Tried and tested personal training programs, proven by our client results.',
      description:
        "Get personal training programs that are based on the latest research and with the most effective methods to help you get fit and stay healthy. Our certified personal trainers will work with you one-on-one, ensuring that you receive personalized attention and guidance. Our personal training classes are designed to help you achieve your fitness goals, whether you're a beginner or advanced fitness pro.",
      linkText: 'Book A Personal Training',
    },

    section2: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663848118/probuddy/static/images/CategoriesPage/Personal%20Trainer/PTLesson2_pqmhag.png',
      heading: 'Your goals. Your schedule. Our Mission',
      description: `Need to find a personal trainier in your area? We’ll connect you with the best and certified personal trainers near you in seconds! Our Pros can help you achieve your goals by providing expert guidance and assistance for every fitness level. Whether it's a total body workout that challenges every muscle group or a lifestyle and wellness training sessions that helps improve flexibility and balance, we have what you need to through each step of the journey towards your fitness goals.`,
      linkText: 'See Our Personal Training Listings',
    },

    exercise: {
      productTitle: 'Popular Personal Training Listings in your area',
      title: 'Types of Personal Training Classes & Programs Hosted by Pros',
      options: [
        {
          name: 'Private Training Sessions',
          description:
            'One-on-one personal training with a goal in mind- getting fit, improving your flexibility, strength & endurance levels.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663848427/probuddy/static/images/CategoriesPage/Personal%20Trainer/PT1_onjwrp.png',
        },
        {
          name: 'Bootcamp/Crossfit Trainer',
          description:
            'Take on the most exciting workout with a results-driven Bootcamp/Crossfit personal trainier.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663848427/probuddy/static/images/CategoriesPage/Personal%20Trainer/PT2_ratmk1.png',
        },
        {
          name: 'Physical Fitness Trainer',
          description:
            'Our personal training will whip you into shape as you circulate between cardio and strength training.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663848427/probuddy/static/images/CategoriesPage/Personal%20Trainer/PT3_r4jhye.png',
        },
        {
          name: 'Personal Training for Athletes',
          description:
            'Wide range of science-based training programs that allow athletes to reach their goals more quickly.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663848427/probuddy/static/images/CategoriesPage/Personal%20Trainer/PT4_hnulmw.png',
        },
        {
          name: 'Group Fitness Training Classes',
          description:
            'Personal trainers can also teach group classes and lead groups at gyms or other locations.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663848428/probuddy/static/images/CategoriesPage/Personal%20Trainer/PT5_omctoe.png',
        },
        {
          name: 'Lifestyle Personal Trainer',
          description:
            'Your personal training sessions are developed to the highest standards & fully customised for your lifestyle.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663848427/probuddy/static/images/CategoriesPage/Personal%20Trainer/PT6_mguwva.png',
        },
      ],
    },

    whyBook: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663846877/probuddy/static/images/CategoriesPage/Personal%20Trainer/BookPersonalTrainerImage_bw9rmc.png',
      heading1: 'One Marketplace for All Things Sports, Events and Adventure',
      position: 'center',
      containerPosition: 'start',
      description1:
        'Probuddy is a one-stop Marketplace for all things sports, wellness & adventure. It gives you worldwide access to thousands of top-rated fitness instructors, yoga classes, tennis coaches, or rock climbing events. All you need to do is find your ideal workout activity and book it!',
      heading2: 'Why Book on Probuddy?',
      description2:
        "Probuddy is the easiest way to book the best fitness classes across the globe - no more waiting or driving around to find the right class for you. Our goal is to help you find the perfect instructor or class in your area so that you can make your next activity one you'll never forget!",
    },

    classesNearYou: {
      title: 'Book the Best Personal Training Classes Near You',
      description: `Whether you’re looking to improve your body or just looking to tighten up your routine and get into shape, Probuddy is here to help. We offer a wide variety of personal training classes around the world, so whether you need more cardio or want to focus on staying healthy, we’ve got something for everyone.  With Probuddy, you can find a class that's suited to your current level and needs, including beginner classes and advanced classes if you're ready to start taking it up a notch. Book your next session right now!`,
    },

    instructors: {
      title: 'Unlock your Potential with Our Certified Personal Trainers',
      description:
        'If you’re looking for a personal trainer in your area, we can help. Our certified personal trainers can help you reach your fitness goals and get in the best shape of your life. Whether you want to get in shape, stay healthy or build muscle, our network of personal trainers has the knowledge and experience to help you reach your goals. We’ll match you with the right Pro based on your specific needs and goals, so you can start getting the results and a positive change to your health and fitness.',
      instructorsData: [
        {
          id: '1',
          trainerImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663848839/probuddy/static/images/CategoriesPage/Personal%20Trainer/CPT1_oqeq92.png',
          name: 'Brian Denver',
          type: 'Certified Personal Trainer',
          rating: '4.6',
          reviews: '12',
          category: 'personal-trainer',
          description: `Coach Brian’s mission is to empower others in the same state to discover how fitness can be fun and sustainable through personal training.`,
        },
        {
          id: '2',
          trainerImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663848840/probuddy/static/images/CategoriesPage/Personal%20Trainer/CPT2_pifkaq.png',
          name: 'Xian Chao',
          type: 'Certified Personal Trainer',
          rating: '4.6',
          reviews: '12',
          category: 'personal-trainer',
          description:
            'Specialising in strength and conditioning training, Xian can help you to work on an personalized training plan to attain your fitness goals.',
        },
        {
          id: '3',
          trainerImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663848840/probuddy/static/images/CategoriesPage/Personal%20Trainer/CPT3_y09khz.png',
          name: 'Kelly Jacobs',
          type: 'Certified Personal Trainer',
          rating: '4.6',
          reviews: '12',
          category: 'personal-trainer',
          description:
            'Kelly has helped hundreds of men and women become better, healthier versions of themselves through personal training,',
        },
      ],
    },

    featured: {
      title: 'Top-Rated Personal Training Studios and Classes Bookings',
      description:
        'If you’re looking for a new gym, studio or trainer, check out our list of top-rated personal training studios in your area that cater to all fitness levels. Probuddy’s goal is to help you achieve your fitness goals faster by making it easy for you to find the best personal training classes around. We know that it can be hard to find the right class or facility for you, but with our help, finding your perfect fit is easier than ever!',
      featuredItems: [
        {
          id: '1',
          companyImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663849071/probuddy/static/images/CategoriesPage/Personal%20Trainer/PTClasses_fnds84.png',
          name: 'Level Singapore',
          location: 'Telok Ayer Street, Singapore',
          rating: '4.8',
          reviews: '230',
          category: 'personal-trainer',
          description: `Level offers personal training and functional strength-based classes in two boutique studios located at Telok Ayer and Robinson Road in the CBD, Singapore.
Our coaches comprise of specialists across a wide range of disciplines including Olympic Weightlifting, Hand Balancing, Powerlifting, Strength and Conditioning and Obstacle Course Racing.`,
        },
        {
          id: '2',
          companyImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663849071/probuddy/static/images/CategoriesPage/Personal%20Trainer/PTClasses_fnds84.png',
          name: 'Level Singapore',
          location: 'Telok Ayer Street, Singapore',
          rating: '4.8',
          reviews: '230',
          category: 'personal-trainer',
          description: `Level offers personal training and functional strength-based classes in two boutique studios located at Telok Ayer and Robinson Road in the CBD, Singapore.
Our coaches comprise of specialists across a wide range of disciplines including Olympic Weightlifting, Hand Balancing, Powerlifting, Strength and Conditioning and Obstacle Course Racing.`,
        },
      ],
    },

    topRatedClasses: {
      title: 'Search and Book The Best Personal Training Classes Right Here!',
      description: `If you're looking for a high-energy personal training prorams, or want to feel healthier, stronger, and happier with effective workouts, there's no better place than a booking a personal training class with Probuddy! Unlocok lifelong result with a result-driven listings that are  designed for everyone from beginners to seasoned athletes who want to take their fitness and health to the next level. Whether you're looking for a personal training studios in your area or want to try hire certified a personal trainer, we've got the perfect class for you.`,
      classesData: [
        {
          id: '1',
          classesImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663849506/probuddy/static/images/CategoriesPage/Personal%20Trainer/PTClasses1_wyfwsa.png',
          className: 'Private Muscle Gain Personal Training',
          instructorName: 'O School',
          location: 'Singapore, SG',
          rating: '4.8',
          reviews: '230',
          category: 'personal-trainer',
          description:
            'Team up with our certified  personal  trainers that can deliver effective results for you. Get to know your own strength, Book now!',
          bookingTimings: '8.00 Am to 10.00 Am',
          bookingPrice: '100',
        },
        {
          id: '2',
          classesImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663849506/probuddy/static/images/CategoriesPage/Personal%20Trainer/PTClasses2_e9er27.png',
          className: 'Sports Performance Personal Training',
          instructorName: 'Dianne Russell',
          location: 'London, UK',
          rating: '4.8',
          reviews: '230',
          category: 'personal-trainer',
          description: `Want to level up your health and fitness regime? We've got a list of the personal training classes and sessions with the best personal trainers in your area!`,
          bookingTimings: '8.00 Am to 10.00 Am',
          bookingPrice: '120',
        },
        {
          id: '3',
          classesImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663849507/probuddy/static/images/CategoriesPage/Personal%20Trainer/PTClasses3_sxc7us.png',
          className: 'Corrective Exercise Personal Training',
          instructorName: 'Sean Kenny',
          location: 'Brooklyn, NY',
          rating: '4.8',
          reviews: '230',
          category: 'personal-trainer',
          description:
            'Looking for a personal trainer near you? We offer weight loss, muscle and body building training, correcrtive excersise for injuries. Book now.',
          bookingTimings: '8.00 Am to 10.00 Am',
          bookingPrice: '120',
        },
      ],
    },

    reviews: [
      {
        name: 'Courtney Henry',
        country: 'Hong Kong',
        imageSrc: ReviewImage1,
        description:
          'The Probuddy platform is a great solution for business owners. We highly recommend it for businesses that need to keep track of everything.',
      },
      {
        name: 'Simon Hart',
        country: 'Texas',
        imageSrc: ReviewImage2,
        description: `I've ticked off a fitness  goal I never thought I would have, simply because I thought it was never achievable. Probuddy has helped me reach my goals by bringing discipline back into my life. I’ve achieved a much better state of mental wellbeing and physical appearance, giving me back confidence in myself.`,
      },
      {
        name: 'Hayley Smith',
        country: 'Manchester',
        imageSrc: ReviewImage6,
        description:
          'Taking time out to destress with a yoga session is always a wonderful thing. Using Probuddy makes it even more enjoyable.',
      },
    ],

    features: {
      title: 'Connect with the Best Personal Trainer and Become a Stronger, Healthier You!',
      description:
        'Match up with verified and certified pros near you with Pro Connect+. Just create a Match Request, and we’ll connect you with a perfect match.',
    },

    sellers: {
      title: `Find the right class, appointment, or acitivity at the right time, on the right place.
Level up your exercise! today!`,
      linkText: 'Learn More',
    },
  },

  {
    id: 'indoor-cycling',
    title: 'Indoor Cycling & Spin Classes',
    description: 'A Bike Ride Away From A Better You',
    heroImage:
      'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663851013/probuddy/static/images/CategoriesPage/Indoor%20Cycling/ICHeroImage_baqpuk.png',

    section1: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663851492/probuddy/static/images/CategoriesPage/Indoor%20Cycling/ICLesson1_owmgyn.png',
      heading: 'Burn calories, boost fitness, and have fun with indoor cycling!',
      description:
        ' If you’re looking for a fun, challenging workout that also helps you burn calories, get fit, and stay healthy, then book an indoor cycling or spin classes! At Probuddy, we offer several different kinds of indoor cycling classes, so you can find the one that fits your needs best. We have classes for beginners who want to learn basic techniques, as well as advanced riders who want more challenging workouts.',
      linkText: 'Book An Indoor Cycling Today',
    },

    section2: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663851492/probuddy/static/images/CategoriesPage/Indoor%20Cycling/ICLesson2_knsojy.png',
      heading: 'Take Your Cycling to the Next Level!',
      description: `Indoor cycling classes are a great way to get some cardio and strength training in, while you ride your bike to some top tunes. Probuddy's indoor cycling classes are great for anyone from beginners to advanced riders. Our pros will guide you through each exercise as they go along so that everyone can participate equally. They'll help keep you motivated as well as keep track of your progress throughout the program so that when you leave class, you'll know how far along on your journey towards fitness it is time to take another step forward.`,
      linkText: 'See Our Spinning & Indoor Cycling Listings',
    },

    exercise: {
      productTitle: 'Popular Spinning & Indoor Cycling Listings in your area',
      title: 'Types of Indoor Cycling Hosted by Pros',
      options: [
        {
          name: 'Private Indoor Cycling Classes',
          description:
            'Get ready for one-on-one cycling sessions and work on strength, flexibility, endurance & more.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663851936/probuddy/static/images/CategoriesPage/Indoor%20Cycling/IC1_ug6vnf.png',
        },
        {
          name: 'Group Indoor Cycling',
          description:
            'Inspire riders of all kinds with an exciting indoor ride powered by sound, lights, and real-time metrics with your friends.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663851936/probuddy/static/images/CategoriesPage/Indoor%20Cycling/IC2_ywngax.png',
        },
        {
          name: 'Spin Class Sessions',
          description:
            'Our personal training will whip you into shape as you circulate between cardio and strength training.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663851937/probuddy/static/images/CategoriesPage/Indoor%20Cycling/IC3_l0ydmh.png',
        },
        {
          name: 'Skillbike Indoor Cycling Class',
          description:
            'With a focus on personal attention, these classes are perfect for riders who want a personalized workout experience.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663851936/probuddy/static/images/CategoriesPage/Indoor%20Cycling/IC4_grerjd.png',
        },
        {
          name: 'Indoor Cycling for Adults',
          description:
            'Make fitness easy at your own pace and improve your health, fitness and mental well-being.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663851937/probuddy/static/images/CategoriesPage/Indoor%20Cycling/IC5_sjsfw6.png',
        },
        {
          name: 'Advanced Cycling Classes',
          description:
            'Take an advanced indoor cycling class from a Pro who will bring out the best cyclist in you!',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663851937/probuddy/static/images/CategoriesPage/Indoor%20Cycling/IC6_kqjodp.png',
        },
      ],
    },

    whyBook: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663850891/probuddy/static/images/CategoriesPage/Indoor%20Cycling/BookIndoorCyclingImage_ixyoho.png',
      heading1: 'One Marketplace for All Things Sports, Events and Adventure',
      position: 'center',
      containerPosition: 'start',
      description1:
        'Probuddy is a one-stop Marketplace for all things sports, wellness & adventure. It gives you worldwide access to thousands of top-rated fitness instructors, yoga classes, tennis coaches, or rock climbing events. All you need to do is find your ideal workout activity and book it!',
      heading2: 'Why Book on Probuddy?',
      description2:
        "Probuddy is the easiest way to book the best fitness classes across the globe - no more waiting or driving around to find the right class for you. Our goal is to help you find the perfect instructor or class in your area so that you can make your next activity one you'll never forget!",
    },

    classesNearYou: {
      title: 'Book the Best Indoor Cycling & Spin Classes Near You',
      description: `The demand for spin classes has never been greater. With more and more people taking up cycling as a hobby, it's no surprise that there are now so many fitness studios opening up all over the country. If you are looking for the best indoor cycling or spin classes near you, then you have come to the right place. We have compiled a list of some of the top indoor cycling classes in your area. Book a class today!`,
    },

    instructors: {
      title: 'Get Fit, Stay Fit with Our Certified Indoor Cycling & Spin Instructors',
      description:
        'If you are looking to get fit, stay fit and have fun with your fitness routine, then you need to look no further than our certified indoor cycling instructors. Our Pros can help you get started on your fitness journey by providing you with the best workouts and advice. They are  committed to helping people improve their overall health and well-being through cycling. They provide a safe and fun workout that allows people of all ages and fitness levels to burn calories, strengthen your legs, increase cardio endurance and reduce stress.',
      instructorsData: [
        {
          id: '1',
          trainerImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663852393/probuddy/static/images/CategoriesPage/Indoor%20Cycling/ICT1_zz2lyu.png',
          name: 'Michelle Teng',
          type: 'Indoor Cycling Instructor',
          rating: '4.9',
          reviews: '12',
          category: 'indoor-cycling',
          description: `Take your cycling to a new level with energetic and fun cycling instructor, Michelle. Open for beginners to national level athletes.`,
        },
        {
          id: '2',
          trainerImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663852394/probuddy/static/images/CategoriesPage/Indoor%20Cycling/ICT2_ucoeh4.png',
          name: 'Zac Lawson',
          type: 'Indoor Cycling Instructor',
          rating: '4.8',
          reviews: '12',
          category: 'indoor-cycling',
          description:
            'Zac can coach & motivate you from beginners to fitness junkies, and guarantee each session will be different to the last. ',
        },
        {
          id: '3',
          trainerImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663852393/probuddy/static/images/CategoriesPage/Indoor%20Cycling/ICT3_f9uxin.png',
          name: 'Cherie Mugler',
          type: 'Indoor Cycling Instructor',
          rating: '4.8',
          reviews: '12',
          category: 'indoor-cycling',
          description:
            'Coach Cherie provides the support and guidance to enable you to reach your potential through indoor cycling.',
        },
      ],
    },

    featured: {
      title: 'Top-Rated Indoor Cycling & Spinning Class Studios Bookings',
      description:
        'Probuddy is a one-stop shop for all your fitness needs. Our goal is to help you find the best indoor cycling studios in your area and make a booking with them easy and convenient. We have partnered with each studio to provide their most popular classes, trainers, and facilities so that you can find your perfect fit. We know that it can be hard to find the right class or facility for you, but with Probuddy, finding the right indoor cycling studios is easier than ever! Check out our list of top-rated indoor cycling studios booking in your area that cater to all fitness levels.',
      featuredItems: [
        {
          id: '1',
          companyImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663852659/probuddy/static/images/CategoriesPage/Indoor%20Cycling/ICClasses_xemmj0.png',
          name: 'Sync Cycle',
          location: 'Yio Chu Kang Road, Singapore',
          rating: '4.8',
          reviews: '230',
          category: 'indoor-cycling',
          description: `Sync Cycle is a indoor cycling studio and our classes are all about mashing up fun, fitness and music. Let today be your day one. We are here to help get you started. From our entire team at Sync Cycle, we are excited to help you discover your full potential on the bike. Simply click on the link and follow the steps to get started. We can’t wait to ride with you.`,
        },
        {
          id: '2',
          companyImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663852659/probuddy/static/images/CategoriesPage/Indoor%20Cycling/ICClasses_xemmj0.png',
          name: 'Sync Cycle',
          location: 'Yio Chu Kang Road, Singapore',
          rating: '4.8',
          reviews: '230',
          category: 'indoor-cycling',
          description: `Sync Cycle is a indoor cycling studio and our classes are all about mashing up fun, fitness and music. Let today be your day one. We are here to help get you started. From our entire team at Sync Cycle, we are excited to help you discover your full potential on the bike. Simply click on the link and follow the steps to get started. We can’t wait to ride with you.`,
        },
      ],
    },

    topRatedClasses: {
      title: 'Search and Book The Best Indoor Cycling Classes Right Here!',
      description: `If you love riding bikes and want to improve your cardiovascular health, then this is the right time to join our indoor cycling classes. We have the best instructors who will help you achieve your fitness goals in no time. Our instructors are certified in cycling and can help you get started on an effective path of building a strong cardiovascular system. They also have years of experience in teaching people how to ride their bikes correctly so that they can reach their full potential as athletes.`,
      classesData: [
        {
          id: '1',
          classesImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663852850/probuddy/static/images/CategoriesPage/Indoor%20Cycling/ICClasses1_fy1qmr.png',
          className: 'Group Indoor Cycling [Beginners]',
          instructorName: 'Sync Cycle',
          location: 'Singapore, SG',
          rating: '4.8',
          reviews: '230',
          category: 'personal-trainer',
          description:
            'Reserve your spot at group indoor cycling class. With a focus on personal attention, these classes are perfect for riders who want a personalized workout experience.',
          bookingTimings: '8.00 Am to 10.00 Am',
          bookingPrice: '100',
        },
        {
          id: '2',
          classesImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663852851/probuddy/static/images/CategoriesPage/Indoor%20Cycling/ICClasses2_mlajvq.png',
          className: 'Fun Indoor Cycling for Adults',
          instructorName: 'Brian Osted',
          location: 'London, UK',
          rating: '4.8',
          reviews: '230',
          category: 'personal-trainer',
          description: `Want to level up your health and fitness regime? We've got a list of the personal training classes and sessions with the best personal trainers in your area!`,
          bookingTimings: '8.00 Am to 10.00 Am',
          bookingPrice: '120',
        },
        {
          id: '3',
          classesImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663852851/probuddy/static/images/CategoriesPage/Indoor%20Cycling/ICClasses3_tlgdrd.png',
          className: 'Advanced Indoor Spin Class',
          instructorName: 'Miranda Lee',
          location: 'Brooklyn, NY',
          rating: '4.8',
          reviews: '230',
          category: 'personal-trainer',
          description:
            'Whether you want to lose weight or just want to improve your flexibility and strength, our advanced indoor cycling classes offers a results-drive session designed just for you.',
          bookingTimings: '8.00 Am to 10.00 Am',
          bookingPrice: '120',
        },
      ],
    },

    reviews: [
      {
        name: 'Courtney Henry',
        country: 'Hong Kong',
        imageSrc: ReviewImage1,
        description:
          'The Probuddy platform is a great solution for business owners. We highly recommend it for businesses that need to keep track of everything.',
      },
      {
        name: 'Simon Hart',
        country: 'Texas',
        imageSrc: ReviewImage2,
        description: `I've ticked off a fitness  goal I never thought I would have, simply because I thought it was never achievable. Probuddy has helped me reach my goals by bringing discipline back into my life. I’ve achieved a much better state of mental wellbeing and physical appearance, giving me back confidence in myself.`,
      },
      {
        name: 'Hayley Smith',
        country: 'Manchester',
        imageSrc: ReviewImage6,
        description:
          'Taking time out to destress with a yoga session is always a wonderful thing. Using Probuddy makes it even more enjoyable.',
      },
    ],

    features: {
      title: 'Learn how to speed up your cycling skills. We’re here to help.',
      description:
        'Match up with verified and certified pros near you with Pro Connect+. Just create a Match Request, and we’ll connect you with a perfect match.',
    },

    sellers: {
      title: `Find the right class, appointment, or acitivity at the right time, on the right place.
Level up your exercise! today!`,
      linkText: 'Learn More',
    },
  },

  {
    id: 'massage-spa',
    title: 'Massage & Spa Treatments',
    description: 'Restore Balance and Renew Your Energy',
    heroImage:
      'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663853444/probuddy/static/images/CategoriesPage/Massage%20and%20Spa/MassageHeroImage_kccuey.png',

    section1: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663853663/probuddy/static/images/CategoriesPage/Massage%20and%20Spa/MassageLesson1_cg3q3e.png',
      heading: `We give you the relaxation you deserve. Get a massage today!`,
      description: `Get your day off to a great start with a luxurious massage or spa treatment. Probuddy features the most comprehensive listings of massage therapists near you, so you can easily find the perfect therapist to fit your needs. Whether you need a full-body massage or are looking for something more targeted, we have you covered. If you're feeling stressed out, our therapists can help you unwind and relax.`,
      linkText: 'Book An Appointment Today',
    },

    section2: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663853664/probuddy/static/images/CategoriesPage/Massage%20and%20Spa/MassageLesson2_sumnw2.png',
      heading: `Relax, Recharge and Rejuvenate. Give yourself a treat.`,
      description: `A massage is a great way to unwind and rejuvenate your mind, body and spirit. We’ve partnered with freelance massage therapists that can help you relax, soothe away pain and stress, and even boost your immune system. Take the time today to treat yourself to a soothing massage or spa treatment. Let the expert hands of Probuddy’s certified massage therapist or licensed esthetician soothe you and relax your mind. You deserve it!`,
      linkText: 'See Our Massage & Spa Listings',
    },

    exercise: {
      productTitle: 'Popular Massage & Spa Listings in your area',
      title: 'Types of Massage & Spa Treatments & Services',
      options: [
        {
          name: 'Thai Massage',
          description:
            'Relax and recover with a wide variety of healing massages. A true holistic journey to tranquil peace.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663853880/probuddy/static/images/CategoriesPage/Massage%20and%20Spa/Massage1_skuv6q.png',
        },
        {
          name: 'Swedish Massage',
          description: `Deep, specific pressure combined with stretching that will help relieve tension and increase your body's mobility.`,
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663853880/probuddy/static/images/CategoriesPage/Massage%20and%20Spa/Massage2_emwgh5.png',
        },
        {
          name: 'Shiatsu Massage',
          description:
            'Long rhythmic strokes to ease the mind for deep relaxation, restful sleep and jet lag recovery.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663853881/probuddy/static/images/CategoriesPage/Massage%20and%20Spa/Massage3_df2mks.png',
        },
        {
          name: 'Spa Massage',
          description:
            'Healing herbs and warm poultice clear the mind of negativity and naturally detoxify your face and body.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663853881/probuddy/static/images/CategoriesPage/Massage%20and%20Spa/Massage4_jflpgd.png',
        },
        {
          name: 'Hot Stone Massage',
          description:
            'Relax and ease tense muscles, joints and damaged soft tissues throughout your body.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663853881/probuddy/static/images/CategoriesPage/Massage%20and%20Spa/Massage5_yfmil0.png',
        },
        {
          name: 'Head and Scalp Massage',
          description:
            'Take a break from your busy day to unwind and recharge with focused work on the head, neck and shoulders.',
          imageSrc:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663853881/probuddy/static/images/CategoriesPage/Massage%20and%20Spa/Massage6_pzora6.png',
        },
      ],
    },

    whyBook: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663853267/probuddy/static/images/CategoriesPage/Massage%20and%20Spa/BookMassageImage_yxxxcl.png',
      heading1: 'One Marketplace for All Things Sports, Events and Adventure',
      position: 'center',
      containerPosition: 'start',
      description1:
        'Probuddy is a one-stop Marketplace for all things sports, wellness & adventure. It gives you worldwide access to thousands of top-rated fitness instructors, yoga classes, tennis coaches, or rock climbing events. All you need to do is find your ideal workout activity and book it!',
      heading2: 'Why Book on Probuddy?',
      description2:
        "Probuddy is the easiest way to book the best fitness classes across the globe - no more waiting or driving around to find the right class for you. Our goal is to help you find the perfect instructor or class in your area so that you can make your next activity one you'll never forget!",
    },

    classesNearYou: {
      title: 'Book the Best Massage & Spa Treatments Near You',
      description: `The demand for spin classes has never been greater. With more and more people taking up cycling as a hobby, it's no surprise that there are now so many fitness studios opening up all over the country. If you are looking for the best indoor cycling or spin classes near you, then you have come to the right place. We have compiled a list of some of the top indoor cycling classes in your area. Book a class today!`,
    },

    instructors: {
      title: 'Most Popular Freelance Massage and Spa Therapists',
      description: `Whether you're looking for a therapist who can relieve your back pain, a masseuse who can help relax and indulge in some pampering, our marketplace helps you find the right match. Search through our list of hundreds of therapists and spas across the country. We have listings from all types of massage therapists from deep tissue, Thai massage, Swedish and Sports massage therapists. If you're looking for a professional massage therapist for your next vacation, look no further than Probuddy. Book an appointment today!`,
      instructorsData: [
        {
          id: '1',
          trainerImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663854193/probuddy/static/images/CategoriesPage/Massage%20and%20Spa/MassageInstructor1_nwqjei.png',
          name: 'Millie Von',
          type: 'Massage Therapist',
          rating: '4.6',
          reviews: '12',
          category: 'massage-spa',
          description: `Millie is  passionate, dedicated massage therapist who always seeks to give her best to helping her patients achieve their desired outcomes.`,
        },
        {
          id: '2',
          trainerImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663854194/probuddy/static/images/CategoriesPage/Massage%20and%20Spa/MassageInstructor2_aid2s5.png',
          name: 'Gavin Deby',
          type: 'Massage Therapist',
          rating: '4.6',
          reviews: '12',
          category: 'massage-spa',
          description:
            'Specializing in Swedish and Thai massage  including work-related and post-operative conditions for athletes.',
        },
        {
          id: '3',
          trainerImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663854194/probuddy/static/images/CategoriesPage/Massage%20and%20Spa/MassageInstructor3_mt2uek.png',
          name: 'Emily Shaulz',
          type: 'Massage Therapist',
          rating: '4.6',
          reviews: '12',
          category: 'massage-spa',
          description:
            'With her strong belief in a holistic approach towards her treatment approaches, she develops an individualized massage treatment for her clients.',
        },
      ],
    },

    featured: {
      title: 'Top-Rated Massage & Spa Companies & Treatments Bookings',
      description:
        'Book a massage or spa treatment at some of the top-rated massage and spa companies near you. Choose from a wide range of massage and spa treatments, including hot stone massages and aromatherapy massages. From the most popular treatments like Swedish massages to more exclusive treatments such as reflexology, we have all the best deals to keep you feeling relaxed, pampered and rejuvenated. These companies offer a range of packages that suit every budget and customer needs.',
      featuredItems: [
        {
          id: '1',
          companyImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663854390/probuddy/static/images/CategoriesPage/Massage%20and%20Spa/MassageClasses_rqkrjq.png',
          name: 'Sabaai Sabaai ',
          location: 'Boat Quay, Singapore',
          rating: '4.8',
          reviews: '230',
          category: 'massage-spa',
          description: `Treat yourself to an UNESCO recognised Thai massage in Singapore. Experience our back cracking combination steps to ease stiff muscle aches yet relaxing, Sabaai Sabaai. We are an established Thai massage boutique specializing in traditional Thai massage, located in the mid of the bustling city with a great environment, river view & one of the must-visit tourist spots in Singapore. Started in 2009 & has since grown and is reputed for our skill`,
        },
        {
          id: '2',
          companyImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663854390/probuddy/static/images/CategoriesPage/Massage%20and%20Spa/MassageClasses_rqkrjq.png',
          name: 'Sabaai Sabaai ',
          location: 'Boat Quay, Singapore',
          rating: '4.8',
          reviews: '230',
          category: 'massage-spa',
          description: `Treat yourself to an UNESCO recognised Thai massage in Singapore. Experience our back cracking combination steps to ease stiff muscle aches yet relaxing, Sabaai Sabaai. We are an established Thai massage boutique specializing in traditional Thai massage, located in the mid of the bustling city with a great environment, river view & one of the must-visit tourist spots in Singapore. Started in 2009 & has since grown and is reputed for our skill`,
        },
      ],
    },

    topRatedClasses: {
      title: 'Search and Book The Best Massage & Spa Treatments Right Here!',
      description: `Whether you're looking for a relaxing weekend away or an escape from the stresses of everyday life, there's no denying that a massage is a perfect way to unwind. No matter what kind of massage you're looking for, we've got the top-rated massages & spa treatments and certificed massage therapists and licensed masseuses near you. No matter what kind of treatment you want, Probuddy helps you get back on track with their unique approach to healing and relaxation. Book a massage and spa treatment today!`,
      classesData: [
        {
          id: '1',
          classesImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663854635/probuddy/static/images/CategoriesPage/Massage%20and%20Spa/MassageClasses1_zemr7n.png',
          className: 'Private Muscle Gain Personal Training',
          instructorName: 'Take Five Group',
          location: 'Singapore, SG',
          rating: '4.8',
          reviews: '230',
          category: 'massage-spa',
          description:
            'Team up with our certified  personal  trainers that can deliver effective results for you. Get to know your own strength, Book now!',
          bookingTimings: '8.00 Am to 10.00 Am',
          bookingPrice: '100',
        },
        {
          id: '2',
          classesImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663854636/probuddy/static/images/CategoriesPage/Massage%20and%20Spa/MassageClasses2_mmiybw.png',
          className: 'Sports Performance Personal Training',
          instructorName: 'Extreme Outdoor Club',
          location: 'London, UK',
          rating: '4.8',
          reviews: '230',
          category: 'massage-spa',
          description: `Want to level up your health and fitness regime? We've got a list of the personal training classes and sessions with the best personal trainers in your area!`,
          bookingTimings: '8.00 Am to 10.00 Am',
          bookingPrice: '120',
        },
        {
          id: '3',
          classesImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663854636/probuddy/static/images/CategoriesPage/Massage%20and%20Spa/MassageClasses3_dllp0w.png',
          className: 'Corrective Exercise Personal Training',
          instructorName: 'Sean Kenny',
          location: 'Brooklyn, NY',
          rating: '4.8',
          reviews: '230',
          category: 'massage-spa',
          description:
            'Looking for a personal trainer near you? We offer weight loss, muscle and body building training, correcrtive excersise for injuries. Book now.',
          bookingTimings: '8.00 Am to 10.00 Am',
          bookingPrice: '120',
        },
      ],
    },

    reviews: [
      {
        name: 'Courtney Henry',
        country: 'Hong Kong',
        imageSrc: ReviewImage1,
        description:
          'The Probuddy platform is a great solution for business owners. We highly recommend it for businesses that need to keep track of everything.',
      },
      {
        name: 'Simon Hart',
        country: 'Texas',
        imageSrc: ReviewImage2,
        description: `I've ticked off a fitness  goal I never thought I would have, simply because I thought it was never achievable. Probuddy has helped me reach my goals by bringing discipline back into my life. I’ve achieved a much better state of mental wellbeing and physical appearance, giving me back confidence in myself.`,
      },
      {
        name: 'Hayley Smith',
        country: 'Manchester',
        imageSrc: ReviewImage6,
        description:
          'Taking time out to destress with a yoga session is always a wonderful thing. Using Probuddy makes it even more enjoyable.',
      },
    ],

    features: {
      title: 'Indulge in a Relaxing Massage and Spa Treatment. We can help!',
      description:
        'Match up with verified and certified pros near you with Pro Connect+. Just create a Match Request, and we’ll connect you with a perfect match.',
    },

    sellers: {
      title: `Search and book the right class, appointment, or acitivity at the right time, on the right place.
A day of beauty, relaxation, and indulgence awaits you at Probuddy.`,
      linkText: 'Learn More',
    },
  },
];

export const featureOptionsConfig = [
  {
    title: '20+ Classes and',
    title2: 'Activities on-the-go',
    description:
      'We have what you need, whether you want to learn the basics or want guidance from a certified Pro.',
    imgSrc: YogaIcon,
  },
  {
    title: '80+ cities',
    title2: 'Where Classes Available',
    description:
      'Being able to take sports, wellness classes and outdoor adventures anytime, anywhere.',
    imgSrc: BuildingIcon,
  },
  {
    title: '140+ Pros',
    title2: 'In the City',
    description:
      '140+ freelance Pros, sports and fitness companies who feel accomplished with Probuddy!',
    imgSrc: UserIcon,
  },
  {
    title: '100+ Members',
    title2: 'Booked Class',
    description:
      'We offer classes, activities and events that are customized for every experience level.',
    imgSrc: MultipleUserIcon,
  },
];

export const featureOptionsConfigEducation = [
  {
    title: '20+ Classes and',
    title2: 'Activities on-the-go',
    description:
      'We have what you need, whether you want to learn the basics or want guidance from a certified Pro.',
    imgSrc: YogaIcon,
  },
  {
    title: '80+ cities',
    title2: 'Where Courses Available',
    description:
      'Being able to take academic, management, languages and lifestyle courses anytime, anywhere.',
    imgSrc: BuildingIcon,
  },
  {
    title: '140+ Pros',
    title2: 'In the City',
    description:
      '140+ freelance Pros, languages and management academies who feel accomplished with Probuddy!',
    imgSrc: UserIcon,
  },
  {
    title: '100+ Members',
    title2: 'Booked Class',
    description:
      'We offer classes, activities and events that are customized for every experience level.',
    imgSrc: MultipleUserIcon,
  },
];

export const categoriesMediumOptionsConfig = [
  {
    title: 'Sports',
    page: 'AllSportsPage',
    params: { slug: 'sports' },
    imgSrc:
      'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1672294805/probuddy/static/images/CategoriesPage/Rectangle_7022_fsmnpo.png',
    bgColor: '#14336A',
  },
  {
    title: 'Wellness',
    imgSrc:
      'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1672294481/probuddy/static/images/CategoriesPage/wellness_nxqqxg.png',
    page: 'AllSportsPage',
    params: { slug: 'wellness' },
    bgColor: '#8EAEEA',
  },
  // {
  //   title: 'Adventure',
  //   imgSrc:
  //     'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1672288558/probuddy/static/images/CategoriesPage/Adventure_kpqcno.png',
  //   page: 'AllSportsPage',
  //   params: { slug: 'adventure' },
  //   bgColor: '#509563',
  // },
];

export const educationCategoriesMediumOptionsConfig = [
  {
    title: 'Languages',
    page: 'AllAcademicsPage',
    params: { slug: 'language' },
    imgSrc:
      'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675422990/probuddy/static/NewEducation/CategoryPage/Language_Learning_jcv4vm.png',
    bgColor: '#14336A',
  },
  {
    title: 'Academics',
    imgSrc:
      'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675422454/probuddy/static/NewEducation/CategoryPage/design_tx2ekf.png',
    page: 'AllAcademicsPage',
    params: { slug: 'academic' },
    bgColor: '#8EAEEA',
  },
  {
    title: 'Management',
    imgSrc:
      'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675403614/probuddy/static/NewEducation/CategoryPage/Bussiness/pexels-andrea-piacquadio-3756679_zjmxhm.jpg',
    page: 'AllAcademicsPage',
    params: { slug: 'management' },
    bgColor: '#509563',
  },
  {
    title: 'Arts',
    imgSrc:
      'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675409094/probuddy/static/NewEducation/CategoryPage/Design/pexels-ovan-57690_1_m84apf.jpg',
    page: 'AllAcademicsPage',
    params: { slug: 'art' },
    bgColor: '#509563',
  },
];

export const specialListings = [
  {
    id: '6348c913-743c-49f1-a950-1d341b1f2eac',
    title: 'ListingPage.specialTitleRiverVally',
    description: 'ListingPage.riverVallyDescription',
    script: [
      {
        '@context': 'https://schema.org/',
        '@type': 'Organization',
        name: 'Rivervalley Tennis Academy',
        url:
          'https://www.probuddy.io/l/river-valley-tennis-and-fitness/6348c913-743c-49f1-a950-1d341b1f2eac',
        logo: 'https://www.probuddy.io/static/media/ProbuddylogoWhite.fe243b96.svg',
      },
    ],
  },
  {
    id: '5fbe5034-3ad8-4b75-875a-b4455a7143f7',
    title: 'ListingPage.sscTennisTitle',
    description: 'ListingPage.sscTennisDescription',
    script: [
      {
        '@context': 'https://schema.org/',
        '@type': 'Organization',
        name: 'Singapore Cricket Club Tennis',
        url: 'https://www.probuddy.io/l/scc-tennis-academy/5fbe5034-3ad8-4b75-875a-b4455a7143f7',
        logo: 'https://www.probuddy.io/static/media/ProbuddylogoWhite.fe243b96.svg',
      },
    ],
  },
];

export const tennisScript = [
  {
    '@context': 'https://schema.org/',
    '@type': 'Organization',
    name: 'Tennis Classes For All Levels Of Players Regardless Of Age',
    url: 'https://www.probuddy.io/tennis',
    logo: 'https://www.probuddy.io/static/media/ProbuddylogoWhite.fe243b96.svg',
  },
];

export const padelScript = [
  {
    '@context': 'https://schema.org/',
    '@type': 'Organization',
    name: 'Padel Tennis Classes For All Levels Of Players Regardless Of Age',
    url: 'https://www.probuddy.io/padel',
    logo: 'https://www.probuddy.io/static/media/ProbuddylogoWhite.fe243b96.svg',
  },
];

export const searchableCategory = param => {
  const categoriesList = {
    'massage-spa': 'massage',
    'rock-climbing': 'rock climbing',
    kickboxing: 'kick boxing',
    'pole-fitness': 'dance',
    'personal-training': 'personal training',
    'padel-tennis': 'padel tennis',
    'pickle-ball': 'pickle ball',
  };

  const foundCategory = categoriesList[param];

  if (foundCategory) return foundCategory;
  return param;
};

export const jobsBoard = {
  heroImg:
    'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1687926830/probuddy/static/images/CategoriesPage/JobsBoard/JobsBoard_Hero-Img_lnfsnm.png',
};
export const matchactivity = {
  heroImg:
    'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1687926830/probuddy/static/images/CategoriesPage/JobsBoard/JobsBoard_Hero-Img_lnfsnm.png',
};
