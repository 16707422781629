import React, { useState } from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './FAQ.css';

const FAQ = props => {
  const { rootClassName, className, data } = props;
  const classes = classNames(rootClassName || css.root, className);
  const [showQuestionIndex, setQuestionIndex] = useState([]);

  const onClickQuestionHandler = value => {
    if (showQuestionIndex.includes(value)) {
      const updateValue = showQuestionIndex;
      updateValue.splice(updateValue.indexOf(value), 1);
      setQuestionIndex([...updateValue]);
    } else setQuestionIndex(prevState => [...prevState, value]);
  };

  return (
    <div className={classes}>
      {data &&
        data.map(({ question, answer }, index) => {
          const isSelected = showQuestionIndex.length !== 0 && showQuestionIndex.includes(index);
          return (
            <div key={index} className={css.FAQItem}>
              <div className={css.FAQItemWrapper} onClick={() => onClickQuestionHandler(index)}>
                <div>
                  <label className={css.question}>{question}</label>
                </div>

                <div>
                  {!isSelected ? (
                    <div className={css.plus}>
                      <span>+</span>
                    </div>
                  ) : (
                    <div className={css.minus}>
                      <span></span>
                    </div>
                  )}
                </div>
              </div>
              {isSelected ? (
                <div>
                  <span className={css.answers}>{answer}</span>
                </div>
              ) : null}
            </div>
          );
        })}
    </div>
  );
};

FAQ.defaultProps = {
  rootClassName: null,
  className: null,
};

FAQ.propTypes = {
  rootClassName: string,
  className: string,
};

export default FAQ;
