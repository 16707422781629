import React from 'react';
import moment from 'moment';
import cns from 'classnames';
import { UserDisplayName, Avatar, NamedLink } from 'components/index';
import { FormattedMessage } from 'util/reactIntl';
import { createSlug, stringify } from 'util/urlHelpers';

import css from './EnquiryItem.css';

const createListingLink = (listing, otherUser, searchParams = {}, className = '') => {
  const listingId = listing.id && listing.id.uuid;
  const label = listing.attributes.title;
  const listingDeleted = listing.attributes.deleted;

  if (!listingDeleted) {
    const params = { id: listingId, slug: createSlug(label) };
    const to = { search: stringify(searchParams) };
    return (
      <NamedLink className={className} name="ListingPage" params={params} to={to}>
        <Avatar user={otherUser} disableProfileLink />
      </NamedLink>
    );
  } else {
    return <FormattedMessage id="TransactionPanel.deletedListingOrderTitle" />;
  }
};

const EnquiryItem = props => {
  const { tx, type, intl, onClick, selectedTx } = props;

  const { customer, provider, listing, messages } = tx;

  const isOrder = type === 'order';
  const otherUser = isOrder ? provider : customer;
  const listingName = listing && listing.attributes.title;
  const messageContent =
    messages && messages.length > 0 ? messages[messages.length - 1].attributes.content : null;
  const messageCreationTime = messageContent
    ? messages[messages.length - 1].attributes.createdAt
    : null;
  const formattedMessageTime = moment(messageCreationTime).format('ddd');

  const isTodayEnquiry = tx.attributes.lastTransitionedAt
    ? moment().format('YYYY-MM-DD') ===
      moment(tx.attributes.lastTransitionedAt).format('YYYY-MM-DD')
    : false;
  // eslint-disable-next-line
  const rowNotificationDot = isTodayEnquiry ? <div className={css.notificationDot} /> : null;

  const otherUserDisplayName = <UserDisplayName user={otherUser ? otherUser : null} intl={intl} />;
  // eslint-disable-next-line
  const listingLink = listing ? createListingLink(listing, otherUser) : null;
  const selectedCardItem = tx && selectedTx ? tx.id.uuid === selectedTx.id.uuid : false;
  return (
    <div
      className={cns(css.enquireCardRoot, { [css.selectedEnquiriesCardRoot]: selectedCardItem })}
      onClick={onClick}
    >
      <div className={css.userAvatar}>
        <Avatar
          className={cns(css.avatarUser, { [css.selectAvatarUser]: selectedCardItem })}
          user={otherUser}
        />
      </div>
      <div className={css.authorInfo}>
        <div className={css.pastGuest}>
          <div className={css.pastName}>
            <span>{otherUserDisplayName}</span>
          </div>
          <div className={css.pastMessage}>
            <div className={css.pastDay}>
              <span>{formattedMessageTime}</span>
            </div>
          </div>
        </div>
        <div className={css.pastGuest}>
          <div className={css.pastTitle}>
            <span>{listingName}</span>
          </div>
          <NamedLink
            className={css.itemLink}
            name={isOrder ? 'OrderDetailsPage' : 'SaleDetailsPage'}
            params={{ id: tx.id.uuid }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="3"
              viewBox="0 0 15 3"
              fill="none"
            >
              <circle cx="1.5" cy="1.5" r="1.5" fill="black" />
              <circle cx="7.5" cy="1.5" r="1.5" fill="black" />
              <circle cx="13.5" cy="1.5" r="1.5" fill="black" />
            </svg>
          </NamedLink>
        </div>
        <div>
          <p className={css.pastParagraph}>{messageContent}</p>
        </div>
      </div>
    </div>
  );
};

export default EnquiryItem;
