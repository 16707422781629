import React from 'react';
import { oneOf } from 'prop-types';
import classNames from 'classnames';

import { Avatar, NamedLink, UserDisplayName, BookingInfoMaybe } from 'components/index';
import { propTypes } from 'util/types';
import {
  txIsRescheduled,
  txIsRescheduledByCustomer,
  txIsRescheduledByProvider,
} from 'util/transaction';
import { FormattedMessage, intlShape } from 'util/reactIntl';
import { createSlug, stringify } from 'util/urlHelpers';

import css from './InboxItem.css';

const createListingLink = (listing, otherUser, searchParams = {}, className = '') => {
  const listingId = listing.id && listing.id.uuid;
  const label = listing.attributes.title;
  const listingDeleted = listing.attributes.deleted;

  if (!listingDeleted) {
    const params = { id: listingId, slug: createSlug(label) };
    const to = { search: stringify(searchParams) };
    return (
      <NamedLink className={className} name="ListingPage" params={params} to={to}>
        <Avatar user={otherUser} disableProfileLink />
      </NamedLink>
    );
  } else {
    return <FormattedMessage id="TransactionPanel.deletedListingOrderTitle" />;
  }
};

const InboxItem = props => {
  const { unitType, type, tx, intl, stateData, currentUser } = props;
  const { customer, provider, listing } = tx;
  const isOrder = type === 'order';

  const otherUser = isOrder ? provider : customer;
  const listingName = listing && listing.attributes.title;
  const otherUserDisplayName = <UserDisplayName user={otherUser ? otherUser : null} intl={intl} />;
  const isOtherUserBanned = otherUser.attributes.banned;

  const linkClasses = classNames(css.itemLink, {
    [css.bannedUserLink]: isOtherUserBanned,
  });

  const listingLink = listing ? createListingLink(listing, otherUser) : null;
  return (
    <div className={css.item}>
      <div className={css.itemAvatar}>
        {isOrder && listing ? listingLink : <Avatar className={css.avatarUser} user={otherUser} />}
      </div>
      <NamedLink
        className={linkClasses}
        name={isOrder ? 'OrderDetailsPage' : 'SaleDetailsPage'}
        params={{ id: tx.id.uuid }}
      >
        <div className={css.itemInfo}>
          <div className={css.infoWrapper}>
            <div className={css.itemUsername}>
              <div className={css.clientBookListingWrapper}>
                <div className={css.userDisplayNameWrapper}>{otherUserDisplayName}</div>
                <div className={css.listingNameWrapper}>{listingName}</div>
              </div>
            </div>
            <div className={css.infoDivider} />
            <div className={css.stateDetail}>
              <BookingInfoMaybe
                bookingClassName={stateData.bookingClassName}
                intl={intl}
                isOrder={isOrder}
                tx={tx}
                unitType={unitType}
              />
            </div>
          </div>
          <div
            className={classNames(
              css.stateName,
              {
                [css.stateDelivered]: stateData.state === 'Delivered',
                [css.stateAccepted]: stateData.state === 'Accepted',
                [css.stateCancelled]: stateData.state === 'Cancelled',
              },
              stateData.stateClassName
            )}
          >
            {stateData.state === 'Delivered' && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
              >
                <circle
                  cx="5.89023"
                  cy="5.89023"
                  r="5.23576"
                  stroke="#31D296"
                  strokeWidth="1.30894"
                />
                <path
                  d="M3.92725 6.03641L5.23619 7.52715L8.50854 4.58203"
                  stroke="#31D296"
                  strokeWidth="1.30894"
                />
              </svg>
            )}
            {stateData.state}
            {txIsRescheduled(tx) ? (
              <div className={classNames(css.nameEmphasized, css.stateActionNeeded)}>
                {(currentUser &&
                  currentUser.id &&
                  provider &&
                  provider.id &&
                  txIsRescheduledByProvider(tx) &&
                  currentUser.id.uuid === provider.id.uuid) ||
                (currentUser &&
                  currentUser.id &&
                  customer &&
                  customer.id &&
                  txIsRescheduledByCustomer(tx) &&
                  currentUser.id.uuid === customer.id.uuid) ? (
                  <FormattedMessage id={'InboxPage.rescheduledByYou'} />
                ) : (
                  <FormattedMessage
                    id={'InboxPage.rescheduledByOther'}
                    values={{ otherUserDisplayName }}
                  />
                )}
              </div>
            ) : null}
          </div>
        </div>
      </NamedLink>
    </div>
  );
};

InboxItem.propTypes = {
  unitType: propTypes.bookingUnitType.isRequired,
  type: oneOf(['order', 'sale']).isRequired,
  tx: propTypes.transaction.isRequired,
  intl: intlShape.isRequired,
};

export default InboxItem;
