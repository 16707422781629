export const MAX_MOBILE_SCREEN_WIDTH = 768;

export const LISTING_TYPES = {
  LISTING: 'listing',
  ENQUIRY: 'enquiry',
  PRODUCT: 'product',
  MEMBERSHIP: 'membership',
  COMPANY: 'company',
  FREELANCER: 'freelancer',
  CLASS: 'class',
  FACILITY: 'facility',
  EVENT: 'event',
  TUTOR: 'tutor',
  ACADEMY: 'academy',
  COURSES: 'courses',
  REMOTE_TUTOR: 'remote tutor',
  HOURLY_SESSION: 'sessions',
  EDUCATION_COURSES: 'education courses',
  PLAYER_PROFILE: 'player-profile',
};

export const PAYMENT_TYPES = {
  card: 'card',
  direct: 'direct',
  membership: 'membership',
  cash: 'cash',
  hitpay: 'hitpay',
  requestOnly: 'requestOnly',
};

// EditWizardTabs
export const AVAILABILITY = 'availability';
export const DESCRIPTION = 'description';
export const FEATURES = 'features';
export const POLICY = 'policy';
export const LOCATION = 'location';
export const PRICING = 'pricing';
export const PHOTOS = 'photos';
export const FAQ = 'FAQ';
export const BLOGS = 'blogs';
export const CERTIFICATES = 'certificates';
export const EDUCATION = 'education';
export const EXPECTATIONS = 'expectations';
export const SERVICES = 'services';
export const SOCIALS = 'socials';
export const DETAILS = 'details';
export const PRICESTOCK = 'Pricing & Stock';
export const DELIVERY = 'delivery';
export const INTODUCTORY = 'intoductory video';
