import React from 'react';

import css from './LocationAutocompleteInput.css';

const IconHourGlass = () => (
  <svg
    className={css.iconSvg}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.5 11.5L15 15"
      stroke="#0058fa"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1 6.99992C1 10.3136 3.68626 12.9998 6.99992 12.9998C8.6596 12.9998 10.162 12.326 11.2482 11.2369C12.3307 10.1516 12.9998 8.6539 12.9998 6.99992C12.9998 3.68626 10.3136 1 6.99992 1C3.68626 1 1 3.68626 1 6.99992Z"
      stroke="#0058fa"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="transparent"
    />
  </svg>
);

export default IconHourGlass;
