import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NamedLink } from '../../components';

import css from './TabNav.css';

const Tab = props => {
  const { className, id, disabled, text, selected, svgIcon, isBlack, linkProps, onClick } = props;

  const linkClasses = classNames(css.link, {
    [css.selectedLink]: selected,
    [css.disabled]: disabled,
    [css.blackLink]: isBlack,
    [css.blackSelectedLink]: isBlack && selected,
  });

  return (
    <div id={id} className={className}>
      {!onClick ? (
        <NamedLink className={linkClasses} {...linkProps}>
          {svgIcon}
          {text}
        </NamedLink>
      ) : (
        <div className={linkClasses} onClick={onClick}>
          {text}
        </div>
      )}
    </div>
  );
};

Tab.defaultProps = { className: null, disabled: false, selected: false };

const { arrayOf, bool, node, object, string } = PropTypes;

Tab.propTypes = {
  id: string.isRequired,
  className: string,
  text: node.isRequired,
  disabled: bool,
  selected: bool,
  linkProps: object,
};

const TabNav = props => {
  const { className, rootClassName, tabRootClassName, tabs } = props;
  const classes = classNames(rootClassName || css.root, className);
  const tabClasses = tabRootClassName || css.tab;

  return (
    <nav className={classes}>
      {/* {title && <h1 className={css.navTitle}>{title}</h1>} */}
      <div className={css.tabWrapper}>
        <div className={css.tabContents}>
          {tabs.map((tab, index) => {
            const id = typeof tab.id === 'string' ? tab.id : `${index}`;
            return <Tab key={id} id={id} isBlack={props.isBlack} className={tabClasses} {...tab} />;
          })}
        </div>
      </div>
    </nav>
  );
};

TabNav.defaultProps = {
  className: null,
  rootClassName: null,
  tabRootClassName: null,
  tabClassName: null,
};

TabNav.propTypes = {
  className: string,
  rootClassName: string,
  tabRootClassName: string,
  tabs: arrayOf(object).isRequired,
};

export default TabNav;
