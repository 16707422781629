import React, { useState, useEffect } from 'react';
import { IconSpinner, Page, LayoutWrapperMain, LayoutWrapperTopbar } from 'components';
import { HeaderContainer } from '../../../containers';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { createResourceLocatorString } from 'util/routes';
import routeConfiguration from '../../../routeConfiguration';
import { injectIntl } from '../../../util/reactIntl';
import { isScrollingDisabled } from '../../../ducks/UI.duck';
import { logout } from '../../../ducks/Auth.duck';
import { withViewport } from '../../../util/contextHelpers';
import { queryPromotedListings } from '../../HomePage/HomePage.duck';
import {
  MAX_MOBILE_SCREEN_WIDTH,
  hostServiceTypes,
  hostTypes,
  getImgSource,
  getNewPageName,
  educationServiceType,
  tennisServiceType,
  educationHostTypes,
} from './ListingType.data';

import cns from 'classnames';
import css from './ListingType.css';

const ListingTypeComponent = props => {
  const {
    currentUserHasCompanyOrFreelancer,
    currentUserHasTutorOrAcademy,
    currentUserListingFetched,
    scrollingDisabled,
  } = props;

  const isEducationSite =
    typeof window !== 'undefined' && window?.location?.pathname?.includes('edu');

  const isTennisSite = typeof window !== 'undefined' && window?.location?.pathname?.includes('ten');

  const isMobileLayout = props.viewport.width < MAX_MOBILE_SCREEN_WIDTH;

  const [selectedListingType, setSelectedListingType] = useState('');
  const [listingType, setListingType] = useState([]);
  const [imageText, setImageText] = useState('');
  const [errorMessage, setErrorMessage] = useState(false);

  //Header
  const [state, setState] = useState({
    queryParams: null,
    isOpenSearchBar: false,
    openLeftBar: false,
    currentTab: '',
    selectedSubCategory: null,
    disableFilterScroll: false,
    isScrolling: false,
  });

  const [showOpacity, setShowOpacity] = useState('');

  // useEffect(() => {
  //   if (typeof window !== 'undefined') {
  //     window.onscroll = () => {
  //       let currentScrollPos = window.pageYOffset;
  //       let maxScroll = document.body.scrollHeight - (window.innerHeight - 50);
  //       if (currentScrollPos > 0 && currentScrollPos < maxScroll) {
  //         setIsScrolling(true);
  //       } else {
  //         setIsScrolling(false);
  //       }
  //     };
  //   }
  // }, []);

  useEffect(() => {
    if (isEducationSite) {
      if (currentUserHasTutorOrAcademy) {
        setListingType(educationServiceType);
        setImageText('Select Services');
      } else {
        setListingType(educationHostTypes);
        setImageText('Are You A Tutor or Academy ?');
      }
    } else if (isTennisSite) {
      if (currentUserHasCompanyOrFreelancer) {
        setListingType(tennisServiceType);
        setImageText('Are you a Player?');
      } else {
        setListingType(hostTypes);
        setImageText('Are You A Pro or Company ?');
      }
    } else {
      if (currentUserHasCompanyOrFreelancer) {
        setListingType(hostServiceTypes);
        setImageText('Are you a Player?');
      } else {
        setListingType(hostTypes);
        setImageText('Are You A Pro or Company ?');
      }
    }
  }, [
    currentUserHasCompanyOrFreelancer,
    currentUserHasTutorOrAcademy,
    isEducationSite,
    isTennisSite,
  ]);

  const onSearch = searchquery => {
    props.history.push(
      createResourceLocatorString(
        isEducationSite ? 'EducationPage' : isTennisSite ? 'TennisPage' : 'HomePage',
        routeConfiguration(),
        {}
      )
    );
    setState({ ...state, queryParams: searchquery });
    setTimeout(() => {
      props.onQueryPromotedListings(searchquery);
    }, 500);
  };

  const onTabChangeSearch = searchquery => {
    setState({ ...state, queryParams: searchquery });
    props.onQueryPromotedListings(searchquery);
  };

  const handleHeaderSearchBar = value => {
    setState({ ...state, isOpenSearchBar: value });
  };

  const handleOpacityChange = value => {
    setShowOpacity(value);
  };

  if (!currentUserListingFetched) {
    return (
      <Page title="Listing Type" scrollingDisabled={scrollingDisabled}>
        <LayoutWrapperTopbar>
          <HeaderContainer
            onSearch={onSearch}
            onTabChangeSearch={onTabChangeSearch}
            handleHeaderSearchBar={handleHeaderSearchBar}
            handleOpacityChange={handleOpacityChange}
            isMobileLayout={isMobileLayout}
            currentTab={() => {}}
            currentUser={props.currentUser}
            disableFilterScroll={value => setState({ ...state, disableFilterScroll: value })}
            showOpacity={showOpacity}
            isScrolling={state.isScrolling}
          />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <IconSpinner />
        </LayoutWrapperMain>
      </Page>
    );
  }

  return (
    <Page title="Listing Type" scrollingDisabled={scrollingDisabled}>
      <LayoutWrapperTopbar>
        <HeaderContainer
          onSearch={onSearch}
          onTabChangeSearch={onTabChangeSearch}
          handleHeaderSearchBar={handleHeaderSearchBar}
          handleOpacityChange={handleOpacityChange}
          openLeftBar={() => setState({ ...state, openLeftBar: true })}
          isMobileLayout={isMobileLayout}
          currentTab={tab => {
            setState({ ...state, currentTab: tab });
          }}
          currentUser={props.currentUser}
          onChangeActivity={value => setState({ ...state, selectedSubCategory: value })}
          hideBottomHeader={true}
          disableFilterScroll={value => setState({ ...state, disableFilterScroll: value })}
          showOpacity={showOpacity}
          isScrolling={state.isScrolling}
          isAuthenticated={props.isAuthenticated}
          logoutInProgress={props.logoutInProgress}
          logout={props.onLogout}
          isEducationSite={isEducationSite}
          // isTennisSite={isTennisSite}
        />
      </LayoutWrapperTopbar>
      <LayoutWrapperMain>
        <div className={css.ListingTypeWrapper}>
          <div className={css.listingTypeImg}>
            <img
              className={css.listingImg}
              src={getImgSource(selectedListingType, isEducationSite, isTennisSite)}
              alt="ListingType"
            />
            <div className={css.listingImgText}>
              <h3 className={css.imgText}>{imageText}</h3>
              <div className={css.authorDescription}>
                <div className={css.authorInfo}>
                  <span className={css.authorName}>Sarah J</span>
                  <span className={css.category}>
                    {isEducationSite ? '( Tutor )' : '(Yoga Trainer)'}
                  </span>
                </div>
                <p className={css.authorAddress}>New Orleans, Louisiana, USA</p>
              </div>
            </div>
          </div>
          <div className={css.selectListingWrapper}>
            <div className={css.selectListing}>
              {listingType.map(type => (
                <div
                  className={cns(css.buttonMain, selectedListingType === type.key && css.selected)}
                >
                  <div
                    onClick={() => {
                      setSelectedListingType(type.key);
                      setErrorMessage(false);
                    }}
                    className={css.buttonWrapper}
                  >
                    <div>{type.text}</div>
                    <div className={css.svgWrapper}>{type.svg}</div>
                  </div>
                  <div className={css.checkMark}>
                    <svg
                      width="12"
                      height="10"
                      viewBox="0 0 12 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 5.8L3.85714 9L11 1"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        fill="transparent"
                      />
                    </svg>
                  </div>
                </div>
              ))}
              <div className={css.nextButtonWrapper}>
                <button
                  onClick={() => {
                    selectedListingType
                      ? props.history.push(
                          createResourceLocatorString(
                            getNewPageName(selectedListingType),
                            routeConfiguration(),
                            {}
                          )
                        )
                      : setErrorMessage(true);
                  }}
                  className={css.nextButton}
                >
                  Next
                </button>
              </div>
              {errorMessage && <p className={css.errorMessage}>*Please select type first!!</p>}
            </div>
          </div>
        </div>
      </LayoutWrapperMain>
    </Page>
  );
};

const mapStateToProps = state => {
  const { isAuthenticated, logoutInProgress } = state.Auth;
  const {
    currentUserHasCompanyOrFreelancer,
    currentUserHasTutorOrAcademy,
    currentUserListingFetched,
    currentUser,
  } = state.user;
  return {
    currentUserHasCompanyOrFreelancer,
    currentUserHasTutorOrAcademy,
    currentUserListingFetched,
    scrollingDisabled: isScrollingDisabled(state),
    isAuthenticated,
    logoutInProgress,
    currentUser,
  };
};

const mapDispatchToProps = dispatch => ({
  onQueryPromotedListings: searchquery => dispatch(queryPromotedListings(searchquery)),
  onLogout: historyPush => dispatch(logout(historyPush)),
});

const ListingType = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withViewport
)(injectIntl(ListingTypeComponent));

export default ListingType;
