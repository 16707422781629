import React from 'react';
import { uid } from 'react-uid';
import PropTypes from 'prop-types';
import CertificationItem from 'components/CertificationItem/CertificationItem';
import css from '../../containers/ListingPage/ListingPage.css';

function SectionCertification(props) {
  const { data } = props;

  return (
    <div className={css.authorDescriptionsContainer}>
      <h1 className={css.accoladesTitle}>Accolades Achieved</h1>
      <div className={css.authorDescriptions}>
        {data.map(certification => (
          <CertificationItem key={uid(certification)} certification={certification} />
        ))}
      </div>
    </div>
  );
}

SectionCertification.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
};

export default SectionCertification;
