import React, { useState, useEffect } from 'react';
import PropTypes, { bool, func, object, number, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { NamedLink, Button, OutSideCloseCard, Modal } from '../../components';
import HeaderSearch from '../../containers/HeaderContainer/HeaderSearchComponent';
import logoImage from './logo.png';
import { createResourceLocatorString } from 'util/routes';
import routeConfiguration from '../../routeConfiguration';
import css from './TopbarDesktop.css';
import { MobileHeaderContainer } from '../../containers';

const TopbarDesktop = props => {
  const {
    className,
    rootClassName,
    menuList,
    isDarkMode,
    params,
    onMainWrapperBlur,
    isMainWrapperBlur,
    onManageDisableScrolling,
    viewport,
    history,
    searchActivity,
  } = props;
  const [state, setState] = useState({
    selectedSubCategory: searchActivity,
    disableFilterScroll: false,
    showFilter: false,
    modalOpen: false,
  });
  const [isScrolling, setIsScrolling] = useState(false);
  const [isOpenSearchBar, setOpenSearchBar] = useState(false);
  const [showOpacity, setShowOpacity] = useState('');
  const [currentTab, setCurrentTab] = useState('');
  const [activeSearchTab, setActiveSearchTab] = useState('');
  const [isOpen, setOpen] = useState(false);

  const scrollTOLocation = () => {
    if (history.location.hash) {
      const el = document.querySelector(history.location.hash);
      if (el) {
        el.scrollIntoView({
          block: 'start',
        });
        if (typeof window !== 'undefined') {
          window.scrollBy(0, -140);
        }
      }
    }
  };

  useEffect(() => {
    scrollTOLocation();
    if (typeof window !== 'undefined') {
      window.onscroll = () => {
        let currentScrollPos = window.pageYOffset;
        let maxScroll = document.body.scrollHeight - (window.innerHeight - 50);
        if (currentScrollPos > 0 && currentScrollPos < maxScroll) {
          setIsScrolling(true);
        } else {
          setIsScrolling(false);
        }
      };
    }
  }, [scrollTOLocation]);

  useEffect(() => {
    if (isMainWrapperBlur === false) scrollTOLocation();
  }, [isOpen, setOpen, isMainWrapperBlur]);
  const classes = classNames(rootClassName || css.root, { [css.darkRoot]: isDarkMode }, className);
  const showMenuList =
    viewport.width > 1600 ||
    (viewport.width > 1340 && viewport.width <= 1600 && isOpenSearchBar === false);

  const handleHeaderSearchBar = value => {
    setOpenSearchBar(value);
  };

  const handleOpacityChange = value => {
    setShowOpacity(value);
    onMainWrapperBlur(value);
  };

  const onSearch = searchquery => {
    props.OnQueryPromotedListings(searchquery);
    history.push(
      createResourceLocatorString(
        'HomePage',
        routeConfiguration(),
        {},
        { listing_type: currentTab }
      )
    );
  };

  const onTabChange = searchquery => {
    props.OnQueryPromotedListings(searchquery);
  };

  const menuIcon = (
    <div
      className={classNames(css.menuWrapper, {
        [css.menuMobileActiveTabWrapper]: activeSearchTab !== '',
      })}
      onClick={() => setOpen(prevState => !prevState)}
    >
      <svg
        width="38"
        height="38"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="20" cy="20" r="19" stroke="white" strokeWidth="1.5" />
        <path d="M14 15H23" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
        <path d="M14 20.1426H26" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
        <path d="M14 25.2852H19.1429" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
      </svg>
    </div>
  );
  return (
    <>
      <OutSideCloseCard
        show={true}
        onClickOutside={() => {
          setActiveSearchTab('');
          handleHeaderSearchBar(false);
        }}
      >
        {viewport.width >= 1024 ? (
          <>
            <nav className={classes}>
              <div className={css.arrowButtonWraper}>
                <NamedLink name="HomePage" className={css.arrowLink}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="7"
                    height="11"
                    viewBox="0 0 7 11"
                    fill="none"
                  >
                    <path
                      d="M6 1L0.999999 5.5L6 10"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </NamedLink>
                <Button className={css.arrowLink}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="7"
                    height="11"
                    viewBox="0 0 7 11"
                    fill="none"
                  >
                    <path
                      d="M0.999999 10L6 5.5L1 1"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </Button>
              </div>

              <div className={css.menuWrapper}>{!showMenuList ? menuIcon : null}</div>
              <div>
                <HeaderSearch
                  onSearch={onSearch}
                  handleHeaderSearchBar={handleHeaderSearchBar}
                  handleOpacityChange={handleOpacityChange}
                  activeSearchTab={activeSearchTab}
                  handleActiveSearchTab={setActiveSearchTab}
                  currentTab={tab => {
                    setCurrentTab(tab);
                  }}
                  showOpacity={showOpacity}
                  viewport={viewport}
                />
              </div>

              <div className={css.menuWrapper}>
                {showMenuList ? (
                  <>
                    {menuList.map(link => (
                      <NamedLink
                        name="ListingPage"
                        params={{ ...params }}
                        to={{ hash: link.key }}
                        className={css.menuLink}
                      >
                        <span className={css.login}>
                          <FormattedMessage id={link.textId} />
                        </span>
                      </NamedLink>
                    ))}
                  </>
                ) : null}
              </div>

              <div id={'topbarNavWrap'} className={css.navWrap}>
                <NamedLink className={css.logoLink} name="LandingPage">
                  <img src={logoImage} alt="logo" />
                </NamedLink>
              </div>
            </nav>
          </>
        ) : (
          <nav className={classes}>
            <MobileHeaderContainer
              onSearch={onSearch}
              onTabChangeSearch={onTabChange}
              resetPage={() => setState({ ...state, selectedSubCategory: '' })}
              handleOpacityChange={handleOpacityChange}
              handleHeaderSearchBar={handleHeaderSearchBar}
              isScrolling={isScrolling}
              onChangeActivity={value => setState({ ...state, selectedSubCategory: value })}
              searchModalOpen={value => setState({ ...state, modalOpen: value })}
              disableFilterScroll={value => setState({ ...state, disableFilterScroll: value })}
              currentTab={tab => {
                setCurrentTab(tab);
              }}
              rootClassName={css.rootClassName}
              hideOverlay={true}
            />
          </nav>
        )}
      </OutSideCloseCard>

      {isOpen ? (
        <>
          <div onClick={() => setOpen(false)} className={css.overlayBody} />
          <Modal
            scrollLayerClassName={css.scrollLayer}
            containerClassName={css.modalContainer}
            closeButtonClassName={css.closeButtonStyle}
            openClassName={css.modalOpen}
            isOpen={isOpen}
            onClose={() => {
              setOpen(false);
            }}
            onManageDisableScrolling={onManageDisableScrolling}
          >
            <div className={css.modalLogoWrapper}>
              <NamedLink className={css.logoLink} name="LandingPage">
                <img src={logoImage} alt="logo" />
              </NamedLink>
            </div>

            <div className={css.modalMenu}>
              {menuList.map((menu, index) => (
                <NamedLink
                  key={index}
                  name="ListingPage"
                  params={{ ...params }}
                  to={{ hash: menu.key }}
                  className={classNames(css.modalMenuLink, {
                    [css.modalSelectedMenuLink]: `#${menu.key}` === history.location.hash,
                  })}
                >
                  {menu.icon}
                  <span className={css.login}>
                    <FormattedMessage id={menu.textId} />
                  </span>
                </NamedLink>
              ))}
            </div>
          </Modal>
        </>
      ) : null}
    </>
  );
};

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  currentUserListing: null,
  currentUserListingFetched: false,
  isDarkMode: false,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUserListing: PropTypes.oneOfType([propTypes.ownListing, propTypes.listing]),
  currentUserListingFetched: bool,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
  isDarkMode: bool,
};

export default TopbarDesktop;
