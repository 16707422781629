import React, { memo } from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import classNames from 'classnames';
import { propTypes } from 'util/types';
import { FormattedMessage, injectIntl, intlShape } from 'util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import { Button, Form, FieldTextInput } from '../../components';
import { composeValidators, required } from 'util/validators';

import css from '../EditListingFAQForm/EditListingFAQForm.css';

export const EditListingEducationForm = props => {
  const titleRequiredMessage = <FormattedMessage id="EditListingEducationForm.educationRequired" />;
  const experienceRequired = <FormattedMessage id="EditListingEducationForm.experianceRequired" />;

  return (
    <FinalForm {...props} initialValues={props.initialValues}>
      {formRenderProps => {
        const classes = classNames(css.root, formRenderProps.className);
        const { updateListingError, showListingsError } = formRenderProps.fetchErrors || {};

        const submitInProgress = formRenderProps.updateInProgress;
        const submitReady = formRenderProps.updated || formRenderProps.ready;
        const submitDisabled =
          formRenderProps.invalid ||
          formRenderProps.disabled ||
          formRenderProps.submitInProgress ||
          formRenderProps.pristine;

        return (
          <Form onSubmit={formRenderProps.handleSubmit} className={classes}>
            {updateListingError ? (
              <p className={css.error}>
                <FormattedMessage id="EditListingPricingForm.updateFailed" />
              </p>
            ) : null}
            {showListingsError ? (
              <p className={css.error}>
                <FormattedMessage id="EditListingPricingForm.showListingFailed" />
              </p>
            ) : null}

            <div className={css.mutateBtns}>
              <FieldTextInput
                id="education"
                name={'education'}
                type="textarea"
                rows="3"
                className={css.headerInput}
                autoFocus
                label={<FormattedMessage id={'EditListingEducation.label'} />}
                validate={composeValidators(required(titleRequiredMessage))}
              />
            </div>
            <div className={css.mutateBtns}>
              <FieldTextInput
                id="work_experience"
                name={'work_experience'}
                type="textarea"
                rows="3"
                className={css.headerInput}
                autoFocus
                label={<FormattedMessage id={'EditListingEducation.work'} />}
                validate={composeValidators(required(experienceRequired))}
              />
            </div>
            <div className={css.submitButtonContainer}>
              <Button
                className={css.submitButton}
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
                ready={submitReady}
              >
                {formRenderProps.saveActionMsg}
              </Button>
            </div>
          </Form>
        );
      }}
    </FinalForm>
  );
};

EditListingEducationForm.defaultProps = { fetchErrors: null };

EditListingEducationForm.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(memo(EditListingEducationForm));
