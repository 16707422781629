import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';

import css from 'components/SectionHeadImagesSlider/styles.css';

const settings = {
  appendDots: dots => (
    <div
      style={{
        backgroundColor: "transparent",
        borderRadius: "10px",
        padding: "10px"
      }}
    >
      <ul style={{ margin: "0px" }}> {dots} </ul>
    </div>
  ),
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  swipeToSlide: true,
  swipe: true,
  autoplay: true,
  autoplaySpeed: 4000,
  dots: true,
  dotsClass: "slick-dots slick-thumb",
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToScroll: 1,
        slidesToShow: 1,
      },
    },
  ],
  nextArrow: <></>,
  prevArrow: <></>,
};

function SectionHeadImagesSlider(props) {
  const { images,rootClassName } = props;

  return (
    <div className={rootClassName || css.sliderWrapper}>
      <Slider className={css.carousel} {...settings} infinite={images.length > 2}>
        {props.children}
      </Slider>
    </div>
  );
}

SectionHeadImagesSlider.propTypes = {
  images: PropTypes.arrayOf(PropTypes.object),
};

export default SectionHeadImagesSlider;
