import React from 'react';
import PropTypes from 'prop-types';

import css from './styles.css';

function CertificationItem(props) {
  const { certification } = props;
  return (
    <div className={css.certificateContainer}>
      <div className={css.certificate}>
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
          >
            <g clipPath="url(#clip0)">
              <path
                d="M22.8594 3.50866C22.8168 3.2027 22.5967 2.95102 22.2991 2.8683L12.7614 0.215883C12.6255 0.178039 12.4819 0.178039 12.3458 0.215883L2.80815 2.8683C2.51056 2.95102 2.29042 3.2026 2.24782 3.50866C2.1925 3.90644 0.930506 13.3051 4.16745 17.9806C7.40056 22.6506 12.1701 23.8474 12.3715 23.8961C12.4314 23.9105 12.4924 23.9177 12.5536 23.9177C12.6148 23.9177 12.6758 23.9104 12.7357 23.8961C12.9372 23.8474 17.7068 22.6506 20.9398 17.9806C24.1767 13.3052 22.9147 3.90655 22.8594 3.50866ZM18.7042 8.99569L12.1985 15.5013C12.0472 15.6527 11.8486 15.7285 11.6502 15.7285C11.4518 15.7285 11.2533 15.6528 11.1019 15.5013L7.07951 11.479C6.93402 11.3336 6.85234 11.1363 6.85234 10.9306C6.85234 10.725 6.93413 10.5277 7.07951 10.3823L7.87817 9.58363C8.18103 9.28087 8.67208 9.28077 8.97483 9.58363L11.6502 12.259L16.8088 7.10027C16.9542 6.95478 17.1515 6.8731 17.3572 6.8731C17.5628 6.8731 17.7601 6.95478 17.9055 7.10027L18.7042 7.89893C19.007 8.20179 19.007 8.69283 18.7042 8.99569Z"
                fill="#FFAA00"
              />
            </g>
            <defs>
              <clipPath id="clip0">
                <rect
                  width="23.7303"
                  height="23.7303"
                  fill="white"
                  transform="translate(0.688416 0.1875)"
                />
              </clipPath>
            </defs>
          </svg>
        </div>

        <div className={css.content}>
          <div className={css.header}>{certification.header?.trim() || 'Accolade'}</div>
          <div className={css.text}>{certification.text?.trim()}</div>
        </div>
      </div>
    </div>
  );
}

CertificationItem.propTypes = {
  certification: PropTypes.object,
};

export default CertificationItem;
