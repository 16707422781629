import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconStar.css';

const IconStar = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="22"
      viewBox="0 0 24 22"
      fill="none"
    >
      <path
        fill={props.colorName}
        d="M11.1221 0.609786C11.5011 -0.085155 12.4989 -0.0851548 12.8779 0.609786L15.8226 6.00913C15.9664 6.27285 16.2212 6.45795 16.5164 6.51325L22.5614 7.64527C23.3395 7.79097 23.6478 8.73999 23.104 9.31519L18.8789 13.7842C18.6725 14.0025 18.5752 14.302 18.6139 14.5999L19.4053 20.6988C19.5071 21.4838 18.6998 22.0704 17.9847 21.7309L12.4288 19.0936C12.1575 18.9647 11.8425 18.9647 11.5712 19.0936L6.01526 21.7309C5.30017 22.0704 4.49288 21.4838 4.59474 20.6988L5.38614 14.5999C5.42479 14.302 5.32748 14.0025 5.12111 13.7842L0.895968 9.31519C0.352157 8.73999 0.660512 7.79097 1.43856 7.64527L7.48358 6.51325C7.77884 6.45795 8.03361 6.27286 8.17744 6.00913L11.1221 0.609786Z"
      />
    </svg>
  );
};

IconStar.defaultProps = {
  rootClassName: null,
  className: null,
  fillColor: null,
};

IconStar.propTypes = {
  rootClassName: string,
  className: string,
  fillColor: string,
};

export default IconStar;
