import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import * as validators from '../../util/validators';
import {
  Form,
  CustomFieldTextInput,
  LocationAutocompleteInputField,
  IconClose,
} from '../../components';

import css from './SignupForm.css';
import { PrimaryButton2 } from 'components/Button/Button';
import { city, subCategoriesFilterOptions } from 'marketplace-custom-config';

const KEY_CODE_ENTER = 13;
const identity = v => v;

const SignupFormComponent = props => {
  const [userType, setUserType] = useState('');
  const [cityData, setCityData] = useState('');
  const [categories, setCategories] = useState([]);

  const handelCategory = e => {
    const value = e?.target?.value;
    if (value) {
      const checked = !categories.includes(value);
      if (checked) {
        setCategories([...categories, value]);
      } else {
        setCategories(categories.filter(item => item !== value));
      }
    } else {
      setCategories(categories.filter(item => item !== e));
    }
  };
  const disableButton =
    userType === 'customer' ? true : !!userType && !!cityData && categories.length !== 0;
  const onSubmit = e => {
    const data = props?.normalSignUp ? { userType, city: cityData, category: categories, ...e } : e;
    props.onSubmit(data);
  };

  return (
    <FinalForm
      {...props}
      onSubmit={onSubmit}
      render={fieldRenderProps => {
        const {
          rootClassName,
          className,
          formId,
          handleSubmit,
          inProgress,
          invalid,
          intl,
          onOpenTermsOfService,
          values,
        } = fieldRenderProps;

        // email
        const emailLabel = intl.formatMessage({
          id: 'SignupForm.emailLabel',
        });
        const emailPlaceholder = intl.formatMessage({
          id: 'SignupForm.emailPlaceholder',
        });
        const emailRequiredMessage = intl.formatMessage({
          id: 'SignupForm.emailRequired',
        });
        const emailRequired = validators.required(emailRequiredMessage);
        const emailInvalidMessage = intl.formatMessage({
          id: 'SignupForm.emailInvalid',
        });
        const emailValid = validators.emailFormatValid(emailInvalidMessage);

        // password
        const passwordLabel = intl.formatMessage({
          id: 'SignupForm.passwordLabel',
        });
        const passwordPlaceholder = intl.formatMessage({
          id: 'SignupForm.passwordPlaceholder',
        });
        const passwordRequiredMessage = intl.formatMessage({
          id: 'SignupForm.passwordRequired',
        });
        const passwordMinLengthMessage = intl.formatMessage(
          {
            id: 'SignupForm.passwordTooShort',
          },
          {
            minLength: validators.PASSWORD_MIN_LENGTH,
          }
        );
        const passwordMaxLengthMessage = intl.formatMessage(
          {
            id: 'SignupForm.passwordTooLong',
          },
          {
            maxLength: validators.PASSWORD_MAX_LENGTH,
          }
        );
        const passwordMinLength = validators.minLength(
          passwordMinLengthMessage,
          validators.PASSWORD_MIN_LENGTH
        );
        const passwordMaxLength = validators.maxLength(
          passwordMaxLengthMessage,
          validators.PASSWORD_MAX_LENGTH
        );
        const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);
        const passwordValidators = validators.composeValidators(
          passwordRequired,
          passwordMinLength,
          passwordMaxLength
        );

        // firstName
        const firstNameLabel = intl.formatMessage({
          id: 'SignupForm.firstNameLabel',
        });
        const firstNamePlaceholder = intl.formatMessage({
          id: 'SignupForm.firstNamePlaceholder',
        });
        const firstNameRequiredMessage = intl.formatMessage({
          id: 'SignupForm.firstNameRequired',
        });
        const firstNameRequired = validators.required(firstNameRequiredMessage);

        // lastName
        const lastNameLabel = intl.formatMessage({
          id: 'SignupForm.lastNameLabel',
        });
        const lastNamePlaceholder = intl.formatMessage({
          id: 'SignupForm.lastNamePlaceholder',
        });
        const lastNameRequiredMessage = intl.formatMessage({
          id: 'SignupForm.lastNameRequired',
        });
        const lastNameRequired = validators.required(lastNameRequiredMessage);

        const classes = classNames(rootClassName || css.root, className);
        const submitInProgress = inProgress;
        const submitDisabled = invalid || submitInProgress;

        const handleTermsKeyUp = e => {
          // Allow click action with keyboard like with normal links
          if (e.keyCode === KEY_CODE_ENTER) {
            onOpenTermsOfService();
          }
        };
        const termsLink = (
          <span
            className={css.termsLink}
            onClick={onOpenTermsOfService}
            role="button"
            tabIndex="0"
            onKeyUp={handleTermsKeyUp}
          >
            <FormattedMessage id="SignupForm.termsAndConditionsLinkText" />
          </span>
        );

        const addressPlaceholderMessage = intl.formatMessage({
          id: 'EditListingLocationForm.addressPlaceholder',
        });

        const addressRequiredMessage = intl.formatMessage({
          id: 'EditListingLocationForm.addressRequired',
        });

        const addressNotRecognizedMessage = intl.formatMessage({
          id: 'EditListingLocationForm.addressNotRecognized',
        });

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <div>
              <CustomFieldTextInput
                type="email"
                id={formId ? `${formId}.email` : 'email'}
                name="email"
                autoComplete="email"
                label={emailLabel}
                placeholder={emailPlaceholder}
                validate={validators.composeValidators(emailRequired, emailValid)}
              />
              <div className={css.name}>
                <CustomFieldTextInput
                  className={css.firstNameRoot}
                  type="text"
                  id={formId ? `${formId}.fname` : 'fname'}
                  name="fname"
                  autoComplete="given-name"
                  label={firstNameLabel}
                  placeholder={firstNamePlaceholder}
                  validate={firstNameRequired}
                />
                <CustomFieldTextInput
                  className={css.lastNameRoot}
                  type="text"
                  id={formId ? `${formId}.lname` : 'lname'}
                  name="lname"
                  autoComplete="family-name"
                  label={lastNameLabel}
                  placeholder={lastNamePlaceholder}
                  validate={lastNameRequired}
                />
              </div>
              <CustomFieldTextInput
                className={css.password}
                type="password"
                id={formId ? `${formId}.password` : 'password'}
                name="password"
                autoComplete="new-password"
                label={passwordLabel}
                placeholder={passwordPlaceholder}
                validate={passwordValidators}
              />
              {/* //citys */}
              <label>City:</label>

              <select
                className={css.selectCity}
                name="city"
                id="city"
                value={cityData}
                onChange={e => setCityData(e.target.value)}
              >
                <option value={''}>select city</option>;
                {city.map(val => {
                  return (
                    <option key={val.key} value={val.key}>
                      {val.label}
                    </option>
                  );
                })}
              </select>
              {props?.addCity ? (
                <div className={css.locationWrapper}>
                  <lable className={css.locationLable}>Your City</lable>
                  <LocationAutocompleteInputField
                    className={css.locationAddress}
                    rootClassName={css.rootClass}
                    inputClassName={css.locationInput}
                    iconClassName={css.locationAutocompleteInputIcon}
                    predictionsClassName={css.predictionsRoot}
                    autoFocus
                    name="location"
                    placeholder={addressPlaceholderMessage}
                    useDefaultPredictions={false}
                    valueFromForm={values.location}
                    format={identity}
                    validate={validators.composeValidators(
                      validators.autocompleteSearchRequired(addressRequiredMessage),
                      validators.autocompletePlaceSelected(addressNotRecognizedMessage)
                    )}
                  />
                </div>
              ) : null}
              {props?.normalSignUp && (
                <div className={css.userData}>
                  <label>Account Type:</label>
                  <div className={css.card}>
                    <div className={css.form}>
                      <label>
                        <input
                          type="radio"
                          className={classNames(css['input-radio'], css.blueCheck)}
                          name="userType"
                          checked={userType === 'customer'}
                          value={'customer'}
                          onClick={() => setUserType('customer')}
                        />
                        Customer
                      </label>
                      <label>
                        <input
                          type="radio"
                          className={classNames(css['input-radio'], css.orgCheck)}
                          checked={userType === 'vendor'}
                          name="userType"
                          value={'vendor'}
                          onClick={() => setUserType('vendor')}
                        />
                        Vendor
                      </label>
                    </div>
                  </div>

                  {userType === 'vendor' && (
                    <>
                      <label>Category:</label>
                      <div className={css.categoryInputWrapper}>
                        {categories.length > 0 && (
                          <div className={css.showCategory}>
                            {categories.map((val, i) => (
                              <span
                                key={i}
                                className={css.tagLable}
                                onClick={() => handelCategory(val)}
                              >
                                {val} <IconClose rootClassName={css.svgClose} />
                              </span>
                            ))}
                          </div>
                        )}
                        <select
                          className={css.selectCategory}
                          name="category"
                          id="category"
                          // value={this.state.categories}
                          onChange={handelCategory}
                        >
                          <option value={''}>select category</option>;
                          {subCategoriesFilterOptions?.map((data, i) => {
                            return (
                              <>
                                <option
                                  key={data.key}
                                  value={data.label}
                                  className={categories.includes(data.label) && css.multipal}
                                >
                                  {data.label}
                                </option>
                              </>
                            );
                          })}
                        </select>
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>

            <div className={css.bottomWrapperText}>
              <p className={css.bottomWrapperText}>
                <span className={css.termsText}>
                  <FormattedMessage
                    id="SignupForm.termsAndConditionsAcceptText"
                    values={{ termsLink }}
                  />
                </span>
              </p>
              {props?.normalSignUp ? (
                <PrimaryButton2
                  type="submit"
                  inProgress={submitInProgress}
                  disabled={!disableButton || submitDisabled}
                >
                  <FormattedMessage id="SignupForm.signUp" />
                </PrimaryButton2>
              ) : (
                <PrimaryButton2
                  type="submit"
                  inProgress={submitInProgress}
                  disabled={submitDisabled}
                >
                  <FormattedMessage id="SignupForm.signUp" />
                </PrimaryButton2>
              )}
            </div>
          </Form>
        );
      }}
    />
  );
};

SignupFormComponent.defaultProps = { inProgress: false };

const { bool, func } = PropTypes;

SignupFormComponent.propTypes = {
  inProgress: bool,

  onOpenTermsOfService: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SignupForm = compose(injectIntl)(SignupFormComponent);
SignupForm.displayName = 'SignupForm';

export default SignupForm;
