import classNames from 'classnames';
import LazyLoadingImages from 'components/LazyLoadingImages/LazyLoadingImages';
import NamedLink from 'components/NamedLink/NamedLink';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import css from './SportsCategoriesCard.css';

const SportsCategoriesCard = props => {
  const {
    svgIcon = '',
    image = '=',
    links = '',
    title = '',
    searchPageLink = '',
    isOtherLinks = true,
    serviceTitleSectionClass,
    serviceSectionClass,
  } = props;

  const serviceTitleSection = classNames(serviceTitleSectionClass || css.serviceTitleSection);
  const serviceSectionContainer = classNames(serviceSectionClass || css.serviceItem);

  return (
    <div className={serviceSectionContainer}>
      <div className={css.imageWrapper}>
        <div className={css.imageSize}>
          <LazyLoadingImages src={image} alt="joinSession6" />
        </div>
        <div className={serviceTitleSection}>
          <h2>{title}</h2>
        </div>
      </div>
      {!!isOtherLinks && (
        <div className={css.linkSection}>
          {Object.keys(links).map(link => (
            <NamedLink
              name={'BookListingPromo'}
              to={{
                search: links[link]?.link,
              }}
              className={css.icon_link}
            >
              <span className={css.linkLabel}>{links[link]?.linkTitle}</span>
              <span>{svgIcon}</span>
            </NamedLink>
          ))}
        </div>
      )}
      {!!isOtherLinks && (
        <div className={css.searchAllButtomWrapper}>
          <NamedLink
            name="SearchPage"
            to={{
              search: searchPageLink,
            }}
            className={css.searchAllButtom}
          >
            <FormattedMessage id="SectionTopIcon.viewAll" /> <span>{svgIcon}</span>
          </NamedLink>
        </div>
      )}
    </div>
  );
};

export default React.memo(SportsCategoriesCard);
