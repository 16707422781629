import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { SectionBanner, Button, ProductListing } from '../../components';
import css from './SectionPromotedListings.css';

const SectionPromotedListings = props => {
  const {
    rootClassName,
    className,
    intl,
    promotedListings,
    preHighlightTitle,
    highlightTitle,
    postHighlightTitle,
    subTitle,
    showAll,
    allRelatedQuery,
    bannerClass,
    isListingPage,
    allListingReview,
    viewport,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const getNumberOfProductListings = () => {
    const width = viewport.width;
    if (width <= 550) return 4;
    else if (width >= 551 && width <= 1199) return 4;
    else if (width >= 1200 && width <= 1550) return 6;
    else return 12;
  };
  const [showAllProduct, setShowAllProduct] = useState(false);

  return (
    <div className={classes}>
      <div className={isListingPage ? css.newheader : css.header}>
        <div className={css.title}>
          {preHighlightTitle && <FormattedMessage id={preHighlightTitle} />}
          {highlightTitle && (
            <span className={css.titleHighlight}>
              <FormattedMessage id={highlightTitle} />
            </span>
          )}
          {postHighlightTitle && <FormattedMessage id={postHighlightTitle} />}
        </div>
      </div>

      {subTitle && (
        <p className={css.subTitle}>
          <FormattedMessage id={subTitle} />
        </p>
      )}

      {bannerClass ? <SectionBanner className={bannerClass} /> : null}

      <div className={css.productsMobileWrapper}>
        <div className={css.autorListingWrapper}>
          {promotedListings
            ? promotedListings
                .slice(0, showAllProduct ? promotedListings.length : getNumberOfProductListings())
                .map(pl => (
                  <ProductListing
                    key={pl.id.uuid}
                    pl={pl}
                    intl={intl}
                    reviews={allListingReview ? allListingReview[pl.id.uuid] : []}
                  />
                ))
            : null}
          {promotedListings && promotedListings.length > getNumberOfProductListings() && (
            <Button
              className={css.productsButton}
              onClick={() => setShowAllProduct(prevState => !prevState)}
            >
              {showAllProduct ? (
                <FormattedMessage id="CompanyPage.viewProductsLess" />
              ) : (
                <FormattedMessage
                  id="CompanyPage.viewProductsMore"
                  values={{
                    count: promotedListings.length,
                  }}
                />
              )}
            </Button>
          )}
        </div>
      </div>

      {allRelatedQuery && showAll && (
        <div className={css.buttonWrap}>
          <a href={`/s?pub_category=${allRelatedQuery}`}>
            <p className={css.showAll}>
              <FormattedMessage id={showAll} />
            </p>
          </a>
        </div>
      )}
    </div>
  );
};

SectionPromotedListings.defaultProps = {
  rootClassName: null,
  className: null,
  isListingPage: false,
};

const { string } = PropTypes;

SectionPromotedListings.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionPromotedListings;
