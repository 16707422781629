import React, { useState } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import moment from 'moment';
import config from '../../config';
import { Avatar, Button } from '../../components';
import yellowStar from '../../assets/CompanyPage/yellowStar.svg';
import batch from '../../assets/CompanyPage/batch.svg';

import css from './SectionReviews.css';

const Reviews = props => {
  const { reviews } = props;
  return (
    reviews &&
    reviews.map((review, index) => {
      const reviewContent =
        review.attributes?.content &&
        review.attributes?.content !== config.dummyReviewContent &&
        review.attributes.content;
      return (
        <div key={index} className={css.reviewCard}>
          <div className={css.reviewCardWapper}>
            <div className={css.reviewAuthorDetails}>
              <div className={css.reviewProfileImageWrapper}>
                <Avatar className={css.avatar} user={review.author} disableProfileLink />
              </div>
              <div className={css.reviewAuthorInfo}>
                <h4 className={css.reviewAuthorName}>
                  {review.author.attributes?.profile?.displayName}
                </h4>
                <span className={css.reviewDate}>
                  {moment(review.attributes.createdAt).format('MMMM D, YYYY')}
                </span>
              </div>
            </div>

            <div className={css.reviewRatingWrapper}>
              <span className={css.ratingWrapper}>
                <svg
                  className={css.star}
                  viewBox="0 0 18 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9 0L12.0153 4.84974L17.5595 6.21885L13.8789 10.5853L14.2901 16.2812L9 14.13L3.70993 16.2812L4.12108 10.5853L0.440492 6.21885L5.98466 4.84974L9 0Z"
                    fill="white"
                  />
                </svg>
                <span className={css.ratingNumber}> {review.attributes.rating.toFixed(2)}</span>
              </span>
            </div>
          </div>
          <p className={css.reviewContent}>{reviewContent}</p>
        </div>
      );
    })
  );
};

const SectionReviews = props => {
  const { reviews, isEnquiry } = props;
  const [showAll, setShowAll] = useState(false);

  return (
    <div className={props.rootReviewClassName || css.sectionReviews}>
      <div className={css.reviewWrap}>
        <img src={batch} alt="batch" />
        <img src={yellowStar} alt={'star'} />
        <div>{reviews?.length} Reviews</div>
      </div>
      <Reviews reviews={reviews?.slice(0, showAll ? reviews?.length : isEnquiry ? 1 : 6)} />

      {reviews?.length > 4 && (
        <div className={css.viewMoreButtonWrapper}>
          <Button onClick={() => setShowAll(prevState => !prevState)}>
            {!showAll ? (
              <FormattedMessage id="ListingPage.viewMore" />
            ) : (
              <FormattedMessage id="ListingPage.viewLess" />
            )}
          </Button>
        </div>
      )}
    </div>
  );
};

export default SectionReviews;
