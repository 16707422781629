import React from 'react';
import classNames from 'classnames';
import config from '../../config';
import { DATE_TYPE_DATE, DATE_TYPE_DATETIME } from '../../util/types';
import { ensureBooking, ensureListing } from '../../util/data';
import { BookingBreakdown, OrderBreakdown } from '../../components';

import css from './TransactionPanel.css';

// Functional component as a helper to build BookingBreakdown
const BreakdownMaybe = props => {
  const { className, rootClassName, breakdownClassName, transaction, transactionRole } = props;
  const isProductBooking = transaction?.attributes?.protectedData?.productBooking;
  const loaded = isProductBooking
    ? transaction?.id && transaction.attributes.lineItems?.length > 0
    : transaction && transaction.id && transaction.booking && transaction.booking.id;
  const listingAttributes = ensureListing(transaction.listing).attributes;
  const timeZone =
    loaded && listingAttributes.availabilityPlan
      ? listingAttributes.availabilityPlan.timezone
      : 'Etc/UTC';

  const unit =
    transaction?.attributes?.lineItems &&
    transaction?.attributes?.lineItems[0]?.code === config.bookingCustomAmount
      ? config.bookingCustomAmount
      : transaction?.attributes?.lineItems[0]?.code === config.bookingVoucherAmount
      ? config.bookingVoucherAmount
      : config.bookingUnitType;

  const classes = classNames(rootClassName || css.breakdownMaybe, className);
  const breakdownClasses = classNames(breakdownClassName || css.breakdown);

  const txBookingMaybe = transaction.booking?.id
    ? { booking: ensureBooking(transaction.booking), dateType: DATE_TYPE_DATE }
    : {};

  return loaded ? (
    <div className={classes}>
      {!!isProductBooking ? (
        <OrderBreakdown
          className={breakdownClasses}
          userRole={transactionRole}
          unitType={unit}
          transaction={transaction}
          {...txBookingMaybe}
        />
      ) : (
        <BookingBreakdown
          className={breakdownClasses}
          userRole={transactionRole}
          unitType={unit}
          transaction={transaction}
          booking={transaction.booking}
          dateType={DATE_TYPE_DATETIME}
          timeZone={timeZone}
        />
      )}
    </div>
  ) : null;
};

export default BreakdownMaybe;
