import React from 'react';
import { types as sdkTypes } from '../../util/sdkLoader';

import ReviewImage1 from '../../assets/CategoriesPage/ReviewImage1.png';
import ReviewImage3 from '../../assets/CategoriesPage/ReviewImage3.png';
import ReviewImage5 from '../../assets/CategoriesPage/ReviewImage5.png';
import config from 'config';

export const MAX_MOBILE_SCREEN_WIDTH = 1024;

export const { LatLng, LatLngBounds } = sdkTypes;

export const sliderSettings = {
  appendDots: dots => (
    <div
      style={{
        backgroundColor: 'transparent',
        borderRadius: '10px',
        padding: '10px',
        marginTop: '2rem',
      }}
    >
      <ul style={{ margin: '-15px' }}> {dots} </ul>
    </div>
  ),
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  swipeToSlide: true,
  swipe: false,
  dots: true,
  responsive: [
    {
      breakpoint: 550,
      settings: {
        slidesToScroll: 1,
        swipe: true,
        arrows: false,
        dots: true,
      },
    },
  ],
};

export const getSchemaId = param => {
  switch (param) {
    case 'sports':
      return 'CategoriesPage.schemaTitleSports';
    case 'wellness':
      return 'CategoriesPage.schemaTitleWellness';
    case 'adventure':
      return 'CategoriesPage.schemaTitleAdventure';
    case 'yoga':
      return 'CategoriesPage.schemaTitleYoga';
    case 'pilates':
      return 'CategoriesPage.schemaTitlePilates';
    case 'pole-fitness':
      return 'CategoriesPage.schemaTitlePoleFitness';
    case 'rock-climbing':
      return 'CategoriesPage.schemaTitleRockClimbing';
    case 'kickboxing':
      return 'CategoriesPage.schemaTitleKickboxing';
    case 'tennis':
      return 'CategoriesPage.schemaTitleTennis';
    case 'golf':
      return 'CategoriesPage.schemaTitleGolf';
    case 'dance':
      return 'CategoriesPage.schemaTitleDance';
    case 'volleyball':
      return 'CategoriesPage.schemaTitleVolleyball';
    case 'surfing':
      return 'CategoriesPage.schemaTitleSurfing';

    default:
      return 'CategoriesPage.schemaTitleSports';
  }
};

export const getSchemaDescription = param => {
  switch (param) {
    case 'sports':
      return 'CategoriesPage.schemaDescriptionSports';
    case 'wellness':
      return 'CategoriesPage.schemaDescriptionWellness';
    case 'adventure':
      return 'CategoriesPage.schemaDescriptionAdventure';
    case 'yoga':
      return 'CategoriesPage.schemaDescriptionYoga';
    case 'pilates':
      return 'CategoriesPage.schemaDescriptionPilates';
    case 'pole-fitness':
      return 'CategoriesPage.schemaDescriptionPoleFitness';
    case 'rock-climbing':
      return 'CategoriesPage.schemaDescriptionRockClimbing';
    case 'kickboxing':
      return 'CategoriesPage.schemaDescriptionKickboxing';
    case 'tennis':
      return 'CategoriesPage.schemaDescriptionTennis';
    case 'golf':
      return 'CategoriesPage.schemaDescriptionGolf';
    case 'dance':
      return 'CategoriesPage.schemaDescriptionDance';
    case 'volleyball':
      return 'CategoriesPage.schemaDescriptionVolleyball';
    case 'surfing':
      return 'CategoriesPage.schemaDescriptionSurfing';

    default:
      return 'CategoriesPage.schemaDescriptionSports';
  }
};

export const categoryCityReviews = [
  {
    name: 'Tim McGowan',
    country: 'Sydney',
    imageSrc: ReviewImage1,
    description:
      'Working with Probuddy was a great experience. Their platform covers all of your business needs, and they are easy to work with. I would highly recommend them.',
  },
  {
    name: 'Austin Wade',
    country: 'New York',
    imageSrc: ReviewImage5,
    description:
      'The Probuddy platform is a great solution for business owners. We highly recommend it for businesses that need to keep track of everything.',
  },
  {
    name: 'Michelle Uy',
    country: 'Singapore',
    imageSrc: ReviewImage3,
    description:
      'I have booked a yoga session using Probuddy and it’s the best thing. The instructor is very warm and friendly, thank you very much for that!',
  },
];

export const whyBook = {
  imgSrc:
    'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660296657/probuddy/static/images/CategoriesPage/Tennis/BookTennisImage_q9zxkj.svg',
  heading1: 'One Marketplace for All Things Sports, Events and Adventure',
  position: 'center',
  containerPosition: 'start',
  description1:
    'Probuddy is a one-stop Marketplace for all things sports, wellness & adventure. It gives you worldwide access to thousands of top-rated fitness instructors, yoga classes, tennis coaches, or rock climbing events. All you need to do is find your ideal workout activity and book it!',
  heading2: 'Why Book on Probuddy?',
  description2:
    "Probuddy is the easiest way to book the best fitness classes across the globe - no more waiting or driving around to find the right class for you. Our goal is to help you find the perfect instructor or class in your area so that you can make your next activity one you'll never forget!",
};

export const sellers = {
  title:
    'Join over 1000 sports, wellness, and adventure Pros that have seen their business grow with Probuddy.',
  linkText: 'Learn More',
};

export const getCityName = city => {
  switch (city) {
    case '':
      return 'Singapore';
    case 'sydney':
      return 'Sydney';
    case 'new-york':
      return 'New York';
    case 'london':
      return 'London';
    case 'melbourne':
      return 'Melbourne';
    case 'chicago':
      return 'Chicago';
    case 'seattle':
      return 'Seattle';
    case 'manchester':
      return 'Manchester';
    case 'san-francisco':
      return 'San Francisco';
    case 'houston':
      return 'Houston';

    default:
      return 'Singapore';
  }
};

export const getHeroImages = category => {
  switch (category) {
    case 'yoga':
      return 'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675485809/probuddy/static/images/CategoriesPage/Yoga/YogaHeroImage_bgzyfq.svg';
    case 'tennis':
      return 'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1685082963/probuddy/static/images/CategoriesPage/Tennis/tenis_heroImage_3_gd0ijc.png';
    case 'pilates':
      return 'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660825608/probuddy/static/images/CategoriesPage/Pilates/PilatesHeroImage_l4g9vx.png';
    case 'golf':
      return 'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1662124080/probuddy/static/images/CategoriesPage/Golf/GolfHeroImage_xfgdm3.png';
    case 'boxing':
      return 'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660379691/probuddy/static/images/CategoriesPage/Boxing/BoxingHeroImage_hhxmln.png';
    case 'personal-training':
      return 'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663847133/probuddy/static/images/CategoriesPage/Personal%20Trainer/PTHeroImage_fu6hom.png';
    default:
      return false;
  }
};

export const getSection1Img = category => {
  switch (category) {
    case 'yoga':
      return 'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677224089/probuddy/static/images/CategoriesPage/Yoga/workoutProgram_vf8csc.png';
    case 'tennis':
      return 'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677224835/probuddy/static/images/CategoriesPage/Tennis/TennisPlayersImage_rwynjg.png';
    case 'pilates':
      return 'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660825606/probuddy/static/images/CategoriesPage/Pilates/BookPilatesClasses_dmwco9.png';
    case 'golf':
      return 'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1662124076/probuddy/static/images/CategoriesPage/Golf/GolfLessons_nye3ip.png';
    case 'boxing':
      return 'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677228667/probuddy/static/images/CategoriesPage/Boxing/BoxingTrainingSessionsImage_y13r3f.png';
    case 'personal-training':
      return 'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663847729/probuddy/static/images/CategoriesPage/Personal%20Trainer/PTLesson_tpjwmw.png';
    default:
      return false;
  }
};

export const getSection2Img = category => {
  switch (category) {
    case 'yoga':
      return 'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677224089/probuddy/static/images/CategoriesPage/Yoga/certifiedInstructorsImage_uyyhwb.png';
    case 'tennis':
      return 'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677224837/probuddy/static/images/CategoriesPage/Tennis/ProfessionalTennisCoachesImage_fy3jgw.png';
    case 'pilates':
      return 'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660825606/probuddy/static/images/CategoriesPage/Pilates/BestPilatesInstructors_qpzlol.png';
    case 'golf':
      return 'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1662124075/probuddy/static/images/CategoriesPage/Golf/StrokesGame_j3yxup.png';
    case 'boxing':
      return 'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677228667/probuddy/static/images/CategoriesPage/Boxing/ProfessionalCoachesImage_xr17e7.png';
    case 'personal-training':
      return 'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1663848118/probuddy/static/images/CategoriesPage/Personal%20Trainer/PTLesson2_pqmhag.png';
    default:
      return false;
  }
};

export const getFeatures = (category, params) => {
  const city = getCityName(params);
  return {
    title: `Looking for ${category} Classes in ${city}? We can help.`,
    description: `Match up with verified and certified ${category} Pros in ${city} with Pro Connect+. Just create a Match Request, and well connect you with a prefect match.`,
  };
};

export const topRatedClassesData = category => [
  {
    id: '1',
    classesImage:
      'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661939155/probuddy/static/images/CategoriesPage/Tennis/TennisClasses1_jmbcd4.png',
    className: `Private  ${category} Classes for Adults`,
    instructorName: 'Ricky Lowe',
    location: 'Brooklyn, NY',
    rating: '4.3',
    reviews: '196',
    category: category,
    description:
      'Private and group lessons available. Perfect for beginners & advanced. Fully guided session. Certified coaches.',
    bookingTimings: '8.00 Am to 10.00 Am',
    bookingPrice: '120',
  },
  {
    id: '2',
    classesImage:
      'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661939155/probuddy/static/images/CategoriesPage/Tennis/TennisClasses2_r9byro.png',
    className: `Children ${category} Group Session`,
    instructorName: 'Stephen Chao',
    location: 'Houston',
    rating: '4.8',
    reviews: '270',
    category: category,
    description: `${category} lessons kids. Learn to play with professional coaches. We offer private tennis lessons for kids in your condo.`,
    bookingTimings: '8.00 Am to 10.00 Am',
    bookingPrice: '40',
  },
  {
    id: '3',
    classesImage:
      'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661939155/probuddy/static/images/CategoriesPage/Tennis/TennisClasses3_d9g6xi.png',
    className: `${category} Ladies Training (3 Players)`,
    instructorName: 'Jenn Teller',
    location: 'Los Angeles, CA',
    rating: '4.6',
    reviews: '134',
    description:
      'Learn this game of a lifetime with your friends or in a group setting.  Intensive 4 day programs.',
    bookingTimings: '8.00 Am to 10.00 Am',
    bookingPrice: '150',
  },
];

export const featuredItemsData = category => [
  {
    id: '1',
    companyImage:
      'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660296657/probuddy/static/images/CategoriesPage/Tennis/TennisCompanyImage1_c1jf8z.svg',
    name: `Olaso ${category} Program`,
    rating: '4.3',
    reviews: '190',
    category: category,
    description: `Olaso ${category} program now available at ONE°15 Marina Sentosa Cove, Singapore, is a waterfront lifestyle destination offering world-class marina facilities replete with a comprehensive range of exclusive club amenities. From the infinity pool, fitness centre, members’ lounge, modern spa, restaurants and bars, to the 26 tastefully appointed rooms with spectacular views, no effort has been spared to make your time here an absolute pleasure.`,
  },
  {
    id: '2',
    companyImage:
      'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660296657/probuddy/static/images/CategoriesPage/Tennis/TennisCompanyImage1_c1jf8z.svg',
    name: `Olaso ${category} Program`,
    rating: '4.6',
    reviews: '185',
    category: category,
    description: `Olaso ${category} program now available at ONE°15 Marina Sentosa Cove, Singapore, is a waterfront lifestyle destination offering world-class marina facilities replete with a comprehensive range of exclusive club amenities. From the infinity pool, fitness centre, members’ lounge, modern spa, restaurants and bars, to the 26 tastefully appointed rooms with spectacular views, no effort has been spared to make your time here an absolute pleasure.`,
  },
];

export const instructorsData = category => [
  {
    id: '1',
    trainerImage:
      'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677228666/probuddy/static/images/CategoriesPage/Boxing/BoxingTrainerImage1_rdcyqp.png',
    name: 'Mike Vaughn',
    type: `${category} Trainer`,
    rating: '4.4',
    reviews: '15',
    category: 'boxing',
    description:
      'Book a session with Private Boxing Sessions at Your Space or Mine *Includes Travel',
  },
  {
    id: '2',
    trainerImage:
      'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677228666/probuddy/static/images/CategoriesPage/Boxing/BoxingTrainerImage2_f3vhcx.png',
    name: 'Julian Larson',
    type: `${category} Coach`,
    rating: '4.5',
    reviews: '19',
    category: 'boxing',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ipsum, nec vestibulum lectus pharetra, metus.',
  },
  {
    id: '3',
    trainerImage:
      'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677228667/probuddy/static/images/CategoriesPage/Boxing/BoxingTrainerImage3_c0dev2.png',
    name: 'Steve Liao',
    type: `${category} Instructor`,
    rating: '4.7',
    reviews: '13',
    category: 'boxing',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ipsum, nec vestibulum lectus pharetra, metus.',
  },
];

export const tennisCityData = [
  {
    id: 'singapore',
    title: 'singapore',
    heroVideo:
      'https://res.cloudinary.com/movementbuddy-pte-ltd/video/upload/v1687173237/probuddy/static/images/CategoriesPage/Tennis/Video/TENNIS_v7_hydl3g.mp4',
    section1: {
      heading: 'The Best Tennis Classes in Singapore',
      description: [
        "Singapore is known for its excellent tennis academies, and Probuddy's The Best Tennis Classes in Singapore are the culmination of years of experience to deliver top-notch tennis instruction. The classes feature expertly made tennis drills and exercises designed to improve your technical prowess while also giving a total body cardio workout.",
        'The coaches are highly knowledgeable and certified from renowned academies, so you can rest assured your skills will be well fed with guidance from some of the best in the business. The Best Tennis Classes in Singapore by Probuddy will have you playing like a pro in no time!',
      ],
      linkText: 'Book A Tennis Class Now',
    },

    section2: {
      heading: 'Find Your Perfect Tennis Coach in Singapore',
      description: [
        'Do you need help taking your tennis game to the next level? With Probuddy, finding the perfect tennis coach in Singapore is now easier than ever. At Probuddy, we understand that finding a great coach can be a daunting experience - which is why we make it easy for you to research and book certified coaches.',
        'Probuddy offers an extensive range of experienced, knowledgeable coaches with the skills to help both beginner and professional athletes reach their goals. Find the coach who best suits your style and skill level and take your performance to the highest heights. Find your perfect tennis coach in Singapore with Probuddy today!',
      ],
      linkText: 'Become A Tennis Pro',
    },

    classesForYou: {
      title: 'Book Your Tennis Classes in Singapore',
      description: [
        'Book Your Tennis Classes in Singapore with Probuddy – the only real-time platform that connects coaches in the city with aspiring tennis players. Book your sessions easily and quickly in just a few clicks.',
        'With Probuddy you can view coaches’ photo, contact info, bios, availability and also read reviews from other users. Sessions can be at any courts near you or could even be held virtually. Find any sort of coaching including beginner, intermediate or advanced techniques by experienced coaches in Singapore. Booking an appointment is convenient and tailored to fit your own schedule!',
      ],
    },

    whyBook: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660296657/probuddy/static/images/CategoriesPage/Tennis/BookTennisImage_q9zxkj.svg',
      heading1: 'One Marketplace for All Things Sports, Events and Adventure',
      position: 'center',
      containerPosition: 'start',
      description1:
        'Probuddy is a one-stop Marketplace for all things sports, wellness & adventure. It gives you worldwide access to thousands of top-rated fitness instructors, yoga classes, tennis coaches, or rock climbing events. All you need to do is find your ideal workout activity and book it!',
      heading2: 'Why Book on Probuddy?',
      description2:
        "Probuddy is the easiest way to book the best fitness classes across the globe - no more waiting or driving around to find the right class for you. Our goal is to help you find the perfect instructor or class in your area so that you can make your next activity one you'll never forget!",
    },

    instructors: {
      title: 'Featured Top-Rated Tennis Coaches in Singapore',
      description: [
        'Probuddy is proud to present some of the most highly rated Tennis Coaches in Singapore. From beginner to intermediate and even advanced levels, Probuddy takes into account the feedback from clients to identify Top-Rated coaches that are able to cater to various skill levels. ',
        "With their in-depth knowledge of the game and their ability to customize a training program unique to each player's needs, these Top-Rated Tennis Coaches in Singapore are sure to provide an enjoyable learning experience. So if you're looking for someone who can help you take your game to the next level, look no further than Probuddy's Top-Rated Tennis Coaches in Singapore.",
      ],
      instructorsData: [
        {
          id: '1',
          trainerImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677228666/probuddy/static/images/CategoriesPage/Boxing/BoxingTrainerImage1_rdcyqp.png',
          name: 'Mike Vaughn',
          type: 'Boxing Trainer',
          rating: '4.4',
          reviews: '15',
          category: 'boxing',
          description:
            'Book a session with Private Boxing Sessions at Your Space or Mine *Includes Travel',
        },
        {
          id: '2',
          trainerImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677228666/probuddy/static/images/CategoriesPage/Boxing/BoxingTrainerImage2_f3vhcx.png',
          name: 'Julian Larson',
          type: 'Boxing Coach',
          rating: '4.5',
          reviews: '19',
          category: 'boxing',
          description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ipsum, nec vestibulum lectus pharetra, metus.',
        },
        {
          id: '3',
          trainerImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677228667/probuddy/static/images/CategoriesPage/Boxing/BoxingTrainerImage3_c0dev2.png',
          name: 'Steve Liao',
          type: 'Boxing Instructor',
          rating: '4.7',
          reviews: '13',
          category: 'boxing',
          description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ipsum, nec vestibulum lectus pharetra, metus.',
        },
      ],
    },

    featured: {
      title: 'Popular Tennis Classes and Events in Singapore',
      description: [
        'Tennis is a highly popular sport in Singapore, and if you’re looking to take your game up a level, Probuddy is right here to help. Probuddy offers Tennis classes and Events in the vibrant and beautiful city of Singapore that offer beginners as well as regular players an opportunity to hone their skills and play the game they love.',
        "From one-on-one lessons with Pro Tennis Pros available through our booking system, to Tennis socials, Corporate Tennis Events and Tennis Tournaments across the city - no matter what level you're at or what kind of event it is you’re after – there’s something for everyone at Probuddy!",
      ],
      featuredItems: [
        {
          id: '1',
          companyImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660296657/probuddy/static/images/CategoriesPage/Tennis/TennisCompanyImage1_c1jf8z.svg',
          name: 'Olaso Tennis Program ',
          location: 'ONE°15 Marina Sentosa Cove, Singapore',
          rating: '4.3',
          reviews: '187',
          category: 'boxing',
          description:
            'Olaso Tennis program now available at ONE°15 Marina Sentosa Cove, Singapore, is a waterfront lifestyle destination offering world-class marina facilities replete with a comprehensive range of exclusive club amenities. From the infinity pool, fitness centre, members’ lounge, modern spa, restaurants and bars, to the 26 tastefully appointed rooms with spectacular views, no effort has been spared to make your time here an absolute pleasure.',
        },
        {
          id: '2',
          companyImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1660296657/probuddy/static/images/CategoriesPage/Tennis/TennisCompanyImage1_c1jf8z.svg',
          name: 'Olaso Tennis Program ',
          location: 'ONE°15 Marina Sentosa Cove, Singapore',
          rating: '4.6',
          reviews: '185',
          category: 'boxing',
          description:
            'Olaso Tennis program now available at ONE°15 Marina Sentosa Cove, Singapore, is a waterfront lifestyle destination offering world-class marina facilities replete with a comprehensive range of exclusive club amenities. From the infinity pool, fitness centre, members’ lounge, modern spa, restaurants and bars, to the 26 tastefully appointed rooms with spectacular views, no effort has been spared to make your time here an absolute pleasure.',
        },
      ],
    },

    topRatedClasses: {
      title: 'Featured Tennis Classes in Singapore',
      description: [
        "If you want to experience world-leading tennis coaching and training, look no further than Probuddy's Featured Tennis Classes in Singapore! Our expert coaches employ groundbreaking techniques to help athletes of all ages and abilities to improve their game.",
        'We offer comprehensive sessions covering everything from mechanics and technique to strategy and psychological reinforcement, designed to help participants maximize their potential both on and off the court. Our curriculum is updated regularly with new tips and methods that have been proven effective by successful players, so you can rest assured that investing in one of our Featured Tennis Classes in Singapore will give you the edge you need to succeed!',
      ],
      classesData: [
        {
          id: '1',
          classesImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661939155/probuddy/static/images/CategoriesPage/Tennis/TennisClasses1_jmbcd4.png',
          className: 'Private  Tennis Classes for Adults',
          instructorName: 'Ricky Lowe',
          location: 'Brooklyn, NY',
          rating: '4.3',
          reviews: '196',
          category: 'boxing',
          description:
            'Private and group lessons available. Perfect for beginners & advanced. Fully guided session. Certified coaches.',
          bookingTimings: '8.00 Am to 10.00 Am',
          bookingPrice: '120',
        },
        {
          id: '2',
          classesImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661939155/probuddy/static/images/CategoriesPage/Tennis/TennisClasses2_r9byro.png',
          className: 'Children Tennis Group Session',
          instructorName: 'Stephen Chao',
          location: 'Singapore',
          rating: '4.8',
          reviews: '270',
          category: 'boxing',
          description:
            'Tennis lessons kids. Learn to play with professional coaches. We offer private tennis lessons for kids in your condo.',
          bookingTimings: '8.00 Am to 10.00 Am',
          bookingPrice: '40',
        },
        {
          id: '3',
          classesImage:
            'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661939155/probuddy/static/images/CategoriesPage/Tennis/TennisClasses3_d9g6xi.png',
          className: 'Tennis Ladies Training (3 Players)',
          instructorName: 'Jenn Teller',
          location: 'Los Angeles, CA',
          rating: '4.6',
          reviews: '134',
          category: 'boxing',
          description:
            'Learn this game of a lifetime with your friends or in a group setting.  Intensive 4 day programs.',
          bookingTimings: '8.00 Am to 10.00 Am',
          bookingPrice: '150',
        },
      ],
    },

    features: {
      title: 'Looking for Tennis Classes in Singapore? We can help.',
      description:
        'Match up with verified and certified Tennis Pros in Singapore with Pro Connect+. Just create a Match Request, and well connect you with a prefect match.',
    },
    sellers: {
      title:
        'Join over 1000 sports, wellness, and adventure Pros that have seen their business grow with Probuddy.',
      linkText: 'Learn More',
    },
  },
  {
    id: 'sydney',
    title: 'sydney',
    heroVideo:
      'https://res.cloudinary.com/movementbuddy-pte-ltd/video/upload/v1687173237/probuddy/static/images/CategoriesPage/Tennis/Video/TENNIS_v7_hydl3g.mp4',
    section1: {
      heading: 'The Best Tennis Classes in Sydney',
      description: [
        'If you’re looking for tennis classes in Sydney with expert coaches, Probuddy is the ultimate destination. Not only do they have over 20 highly experienced tennis instructors, they offer excellent tennis facilities that are perfect to help their student’s develop their tennis skills. Plus, all of their tennis classes in Sydney are tailored to your skill level and goals.',
        'Whether it’s tennis basics or improving footwork, Probuddy tennis has it all and will ensure fun and enjoyable tennis lessons for each and every one of their students.',
      ],
      linkText: 'Book A Tennis Class Now',
    },

    section2: {
      heading: 'Find Your Perfect Tennis Coach in Sydney',
      description: [
        "Whether you're looking for tennis lessons to improve your backhand or set a goals that will help you reach the next level, finding the perfect tennis coach in Sydney can be tough. That's why Probuddy is here to make your tennis journey easier. We bring tennis coaches and tennis students together, helping tennis players of all levels, from beginner to pro, find their best tennis fit.",
      ],
      linkText: 'Become A Tennis Pro',
    },

    classesForYou: {
      title: 'Book Your Tennis Classes in Sydney',
      description: [
        "Sydney tennis players, rejoice! With the help of Probuddy, you can easily book tennis classes online and start honing your skills with certified tennis coaches. Whether you're a complete beginner or a tennis pro, there's something for everyone! Simply select a tennis class that fits into your schedule and book instantly.",
      ],
    },

    instructors: {
      title: 'Featured Top-Rated Tennis Coaches in Sydney',
      description: [
        'Finding qualified, experienced and talented coaches is essential when it comes to learning new or fine-tuning your existing tennis skills. Now you don’t have to search endlessly for quality tennis coaches in Sydney; Probuddy features Top-Rated Tennis Coaches who are skilled professionals and mentors on our field. ',
        'Not only do they help learners grasp the basics of playing tennis but also focus on developing advanced techniques. With some of the most renowned tennis champions on their team, learners can easily find the right coach to nurture their individual capabilities with tailored strategies.',
      ],
      instructorsData: instructorsData('Tennis'),
    },

    featured: {
      title: 'Popular Tennis Classes and Events in Sydney',
      description: [
        'Probuddy offers popular tennis classes and events in Sydney that make an ideal way to stay active, meet new people and learn the ropes. Whether you are a beginner looking to learn the basics or an experienced player wanting to refine your skills, Probuddy has something for everyone.',
        'Our comprehensive range of programs include private lessons, group sessions, competition opportunities and exclusive social events – giving you some great options for improving your game. Expert trainers guide you through each class, ensuring that your progress is consistent and that you reach your maximum potential.',
      ],
    },

    topRatedClasses: {
      title: 'Featured Tennis Classes in Sydney',
      description: [
        "Professional tennis coaching can be hard to come by. It's now easy to get the best coaches in Sydney with Probuddy’s Featured Tennis Classes. With Probuddy, you can enjoy professional and engaging group classes that are perfect for beginners and intermediate players.",
        'Personalised one-on-one training with the best pro coaches can take your skills to the next level, while sophisticated tracking and monitoring of your performance keeps you motivated to achieve your goals. ',
      ],
    },
  },
  {
    id: 'new-york',
    title: 'new-york',
    heroVideo:
      'https://res.cloudinary.com/movementbuddy-pte-ltd/video/upload/v1687173237/probuddy/static/images/CategoriesPage/Tennis/Video/TENNIS_v7_hydl3g.mp4',
    section1: {
      heading: 'The Best Tennis Classes in New York',
      description: [
        'Probuddy offers the best tennis classes in New York. Whether you are looking to learn the game, improve your skills, or experience a fun and dynamic workout session, Probuddy has it all. Our experienced instructors have developed specialized classes that focus on detailed strokes and fundamentals for beginners as well as a more advanced program that covers an array of topics including strategy, court positioning and tactics.',
        'Whatever your skill level - from amateur to professional - we provide everything you need to become an even better player. With our range of tennis lessons and programs tailored to different ages and skill sets, you can always find the perfect fit for your needs.',
      ],
      linkText: 'Book A Tennis Class Now',
    },

    section2: {
      heading: 'Find Your Perfect Tennis Coach in New York',
      description: [
        'If you’re looking for a perfect tennis coach in New York, Probuddy is the best solution. Break out of your comfort zone and take your tennis game to the next level with expert advice and tips catered to you by certified coaches.',
        'With Probuddy, you can easily find certified pros with experience across all levels of skill, including coaching juniors and adults alike. Peruse profiles on the platform to find a coach whose qualifications meet your goals and budget considerations. Your perfect New York-based tennis coach awaits - get ready to hit those courts!',
      ],
      linkText: 'Become A Tennis Pro',
    },

    classesForYou: {
      title: 'Book Your Tennis Classes in New York',
      description: [
        'Booking your tennis classes in New York has never been easier. Probuddy allows you to easily find the perfect courts, schedule and coaches that suit your needs. With Probuddy, you can reserve courts at hundreds of locations across the city and check out class times that fit into your schedule.',
        "Booking classes with Probuddy gives you access to a wide range of coaches and training resources to help you reach your goals in the game of tennis. Whether you're a beginner or an experienced player, there are tons of options available for you on Probuddy. Book your tennis classes in New York today and take your game to new heights!",
      ],
    },

    instructors: {
      title: 'Featured Top-Rated Tennis Coaches in New York',
      description: [
        "New York City is home to some of the world's most esteemed tennis coaches. Probuddy is proud to feature their top-rated tennis coaches in New York. Each coach has extensive experience and knowledge of all skill levels needed to become a successful tennis player. They understand the importance of mastering technique, footwork, hand-eye coordination, and total body control. ",
        'With their expertise, they will help you reach your goals, whether you are aiming to play in tournaments, join a college team or just learn how to hit backhand for fun. Have confidence that Probuddy has identified the highest quality coaching resources in New York City for your specific needs.',
      ],
      instructorsData: instructorsData('Tennis'),
    },

    featured: {
      title: 'Popular Tennis Classes and Events in New York',
      description: [
        'Probuddy is one of the leading providers of Popular Tennis Classes and Events in New York City. Whether you are looking for beginner classes to learn basic skills, or advanced instruction to master the game, Probuddy offers something for everyone. With experienced instructors, up-to-date equipment, and affordable packages, we make it easy for tennis enthusiasts to enjoy their favorite sport.',
        "Our classes include singles drills and match practice, as well as full court games to hone each student's technique. Events such as group tournaments also provide athletes with an opportunity to put their improved skills into action. All ages are welcome at Probuddy events, so join us today and get ready to hit the courts!",
      ],
    },

    topRatedClasses: {
      title: 'Featured Tennis Classes in New York',
      description: [
        'Probuddy is proud to present its Featured Tennis Classes in New York! These classes have been tailored for aspiring professionals, amateur players and those looking to pick up their first racquet. Delivered by experienced instructors, the Featured Tennis Classes offer something for everyone - from drills and technique training, to match play scenarios.',
        'Improve your footwork, sharpen your strokes in small group sessions and get access to individualized feedback. Whatever your goals may be, Probuddy’s Featured Tennis Classes in New York can help you reach them with ease. Sign up today and discover your full potential!',
      ],
    },
  },
  {
    id: 'london',
    title: 'london',
    heroVideo:
      'https://res.cloudinary.com/movementbuddy-pte-ltd/video/upload/v1687173237/probuddy/static/images/CategoriesPage/Tennis/Video/TENNIS_v7_hydl3g.mp4',
    section1: {
      heading: 'The Best Tennis Classes in London',
      description: [
        'Are you on the look for The Best Tennis Classes in London? Look no further than Probuddy! With great certified professional coaches and comprehensive classes, Probuddy enables its clients to improve their tennis skills considerably. The classes are a mix of theoretical and practical components and they involve tactics, technique training and skill drills. ',
        "The fun group or individual lessons can be tailored to each person's level while focusing on strategy, improving precision and consistency in shots. Whether you’re an absolute beginner or a seasoned player looking to improve your game, the one-on-one tutoring provided by The Best Tennis Classes in London offered via Probuddy is second to none.",
      ],
      linkText: 'Book A Tennis Class Now',
    },

    section2: {
      heading: 'Find Your Perfect Tennis Coach in London',
      description: [
        'If you’re looking to improve your game and be coached by the best in London, look no further than ProBuddy! Find Your Perfect Tennis Coach in London with ProBuddy - the premier online provider of tennis coaching services. With access to a huge variety of vetted coaches, you can find exactly the coach that meets your needs and goals. ',
        'Find one-on-one or group classes based on your availability, skill level, and budget. Take action today and join the ranks of other tennis players who have used ProBuddy to maximize their potential!',
      ],
      linkText: 'Become A Tennis Pro',
    },

    classesForYou: {
      title: 'Book Your Tennis Classes in London',
      description: [
        'Booking your tennis classes in London has never been easier with Probuddy! Whether you’re in the market for a professional coach or wanting to join a group class with friends, Probuddy has all the options you need. With hundreds of coaches to choose from and classes at facilities across the city, there is something for everyone. ',
        'With their simple and easy booking system, you can book your session with just a few clicks at the price that works best for your budget. Book your next outdoor or indoor tennis class today with Probuddy!',
      ],
    },

    instructors: {
      title: 'Featured Top-Rated Tennis Coaches in London',
      description: [
        'Probuddy, the premier destination for finding local coaches and tutors, has been home to Featured Top-Rated Tennis Coaches in London since it began. With a rigorous application process in place, these Featured Coaches have been approved by Probuddy after being handpicked based on their qualifications and certifications. ',
        'They also boast a wealth of experience with exceptional success rates working with students of all ages and abilities. As Featured Top-Rated Tennis Coaches in London, they provide quality lessons and guidance to help improve the skills of any aspiring or existing tennis player. So if you’re looking for an awesome tennis coach, check out Featured Top-Rated Tennis Coaches in London at Probuddy!',
      ],
      instructorsData: instructorsData('Tennis'),
    },

    featured: {
      title: 'Popular Tennis Classes and Events in London',
      description: [
        "Popular tennis classes and events in London are becoming increasingly popular. Probuddy offers exclusive tennis courses for all levels, whether you're an advanced player or just starting to pick up a racquet. Their amazing classes and events give the opportunity to take part in highly competitive tournaments while gaining knowledge under professional tutelage.",
        "The net results? You’ll not only stay fit but also improve every aspect of your game as they'd be taken through intensive drills, unique match play simulations, as well as fitness exercises tailored to each individual's level. Training with Probuddy is sure to make it a kickstart to seeing great improvement in your game.",
      ],
    },

    topRatedClasses: {
      title: 'Featured Tennis Classes in London',
      description: [
        'Probuddy offers Featured Tennis Classes in London. Their experienced and passionate coaches are committed to providing a range of themed drills, techniques and games designed to bring out the best in every player.',
        'More than just honing their tennis skills, these classes focus on building confidence, teamwork, discipline and respect for both the game and the players’ opponents. With classes tailored for everyone from beginners to advanced players, you can start enjoying all the great benefits tennis has to offer today!',
      ],
    },
  },
  {
    id: 'los-angeles',
    title: 'los-angeles',
    heroVideo:
      'https://res.cloudinary.com/movementbuddy-pte-ltd/video/upload/v1687173237/probuddy/static/images/CategoriesPage/Tennis/Video/TENNIS_v7_hydl3g.mp4',
    section1: {
      heading: 'The Best Tennis Classes in Los Angeles',
      description: [
        "If you're looking for the best tennis classes in Los Angeles, then look no further than Probuddy. The top-notch training they provide allows one to develop the skills needed to become a confident player. From individualized lessons tailored to each student’s skill level, to group classes and on-site play options, you are sure to find a class option that fits your needs.",
        'The experienced coaching  trainors has a wealth of knowledge about the game and is keen on setting realistic goals for each participant. With professionalism and enthusiastic instruction as touchstones, enrolling in a Probuddy tennis class could be just what you need to take your game to the next level.',
      ],
      linkText: 'Book A Tennis Class Now',
    },

    section2: {
      heading: 'Find Your Perfect Tennis Coach in Los Angeles',
      description: [
        'Whether you’re a seasoned veteran or new to the court, everyone needs a good coach to turn potential into performance. That’s why finding your perfect Tennis Coach in Los Angeles is simpler with Probuddy! We specialized in connecting experienced Tennis Coaches with clients of all skill levels. ',
        'Our coaches are background checked and ready to help keep players at the top of their game! Our simple online platform makes scheduling and payments easy, so you can find the right Tennis Coach in Los Angeles whenever you need it. Take your game to a whole new level today—check out Probuddy today to find your Tennis Coach in Los Angeles!',
      ],
      linkText: 'Become A Tennis Pro',
    },

    classesForYou: {
      title: 'Book Your Tennis Classes in Los Angeles',
      description: [
        'Probuddy is the ultimate destination to book Tennis Classes in Los Angeles at the most competitive rates. Professionals from all over the city are here to ensure that you get your personalized Tennis class that best fits according to your skills and needs.',
        'Enjoy your Tennis classes with friends and family or get coaching for free for certified Tennis players at some of the best Tennis clubs in town! Book now with Probuddy and be prepared to show off your Tennis skills in no time!',
      ],
    },

    instructors: {
      title: 'Featured Top-Rated Tennis Coaches in Los Angeles',
      description: [
        'Los Angeles is home to some of the best tennis coaches in the world and Probuddy has identified them. Top-Rated Tennis Coaches in Los Angeles who are included in Probuddy have been thoroughly evaluated and are associated with years of experience and professional coaching ability making them invaluable assets when it comes to helping improve your game.',
        'Whether you’re a new or experienced tennis player, Probuddy is the place to connect with Top-Rated Tennis Coaches in Los Angeles who are committed to creating the best learning environment that produces results enhancing your overall playing abilities.',
      ],
      instructorsData: instructorsData('Tennis'),
    },

    featured: {
      title: 'Popular Tennis Classes and Events in Los Angeles',
      description: [
        "If you're looking to learn tennis or upgrade your skills in Los Angeles, you're in luck. Probuddy is the premier provider of popular tennis classes and events in the city, offering a range of activities at various levels. Whether you’re a complete beginner seeking hands-on instruction from experienced pros or an advanced player looking for practice partners, there’s something for everyone.",
        'Courses range from private one-on-one lessons to round robin tournaments and doubles clinics, ensuring that you can join with colleagues and make new friends while working on your backhand or mastering groundstrokes. Plus, when you sign up with Probuddy, you get access to exclusive clubs and discounts for future activities and events. Take your game to the next level with Popular Tennis Classes and Events in Los Angeles by Probuddy!',
      ],
    },

    topRatedClasses: {
      title: 'Featured Tennis Classes in Los Angeles',
      description: [
        'If tennis is your passion and you’re looking for action-packed tennis classes to sharpen up your skills, then the Featured Tennis Classes offered in Los Angeles via Probuddy has exactly what you’re looking for. From beginner level to advanced, these classes have something for players of all levels - perfect if you want to learn new skills or are looking for an opportunity to refine existing ones. ',
        'Moreover, a panel of certified coaches are available on Probuddy to help you get ahead on the court. Schedule your sessions today to explore the world of tennis and make sure to ace that winning shot!',
      ],
    },
  },
  {
    id: 'melbourne',
    title: 'melbourne',
    heroVideo:
      'https://res.cloudinary.com/movementbuddy-pte-ltd/video/upload/v1687173237/probuddy/static/images/CategoriesPage/Tennis/Video/TENNIS_v7_hydl3g.mp4',
    section1: {
      heading: 'The Best Tennis Classes in Melbourne',
      description: [
        "Melbourne is known for its excellent tennis academies, and Probuddy's The Best Tennis Classes in Melbourne are the culmination of years of experience to deliver top-notch tennis instruction. The classes feature expertly made tennis drills and exercises designed to improve your technical prowess while also giving a total body cardio workout.",
        'The coaches are highly knowledgeable and certified from renowned academies, so you can rest assured your skills will be well fed with guidance from some of the best in the business. The Best Tennis Classes in Melbourne by Probuddy will have you playing like a pro in no time!',
      ],
      linkText: 'Book A Tennis Class Now',
    },

    section2: {
      heading: 'Find Your Perfect Tennis Coach in Melbourne',
      description: [
        'Do you need help taking your tennis game to the next level? With Probuddy, finding the perfect tennis coach in Melbourne is now easier than ever. At Probuddy, we understand that finding a great coach can be a daunting experience - which is why we make it easy for you to research and book certified coaches. ',
        'Probuddy offers an extensive range of experienced, knowledgeable coaches with the skills to help both beginner and professional athletes reach their goals. Find the coach who best suits your style and skill level and take your performance to the highest heights. Find your perfect tennis coach in Melbourne with Probuddy today!',
      ],
      linkText: 'Become A Tennis Pro',
    },

    classesForYou: {
      title: 'Book Your Tennis Classes in Melbourne',
      description: [
        'Book Your Tennis Classes in Melbourne with Probuddy – the only real-time platform that connects coaches in the city with aspiring tennis players. Book your sessions easily and quickly in just a few clicks.',
        'With Probuddy you can view coaches’ photo, contact info, bios, availability and also read reviews from other users. Sessions can be at any courts near you or could even be held virtually. Find any sort of coaching including beginner, intermediate or advanced techniques by experienced coaches in Melbourne. Booking an appointment is convenient and tailored to fit your own schedule!',
      ],
    },

    instructors: {
      title: 'Featured Top-Rated Tennis Coaches in Melbourne',
      description: [
        'Probuddy is proud to present some of the most highly rated Tennis Coaches in Melbourne. From beginner to intermediate and even advanced levels, Probuddy takes into account the feedback from clients to identify Top-Rated coaches that are able to cater to various skill levels. ',
        "With their in-depth knowledge of the game and their ability to customize a training program unique to each player's needs, these Top-Rated Tennis Coaches in Melbourne are sure to provide an enjoyable learning experience. So if you're looking for someone who can help you take your game to the next level, look no further than Probuddy's Top-Rated Tennis Coaches in Melbourne.",
      ],
      instructorsData: instructorsData('Tennis'),
    },

    featured: {
      title: 'Popular Tennis Classes and Events in Melbourne',
      description: [
        'Tennis is a highly popular sport in Melbourne, and if you’re looking to take your game up a level, Probuddy is right here to help. Probuddy offers Tennis classes and Events in the vibrant and beautiful city of Melbourne that offer beginners as well as regular players an opportunity to hone their skills and play the game they love.',
        "From one-on-one lessons with Pro Tennis Pros available through our booking system, to Tennis socials, Corporate Tennis Events and Tennis Tournaments across the city - no matter what level you're at or what kind of event it is you’re after – there’s something for everyone at Probuddy!",
      ],
    },

    topRatedClasses: {
      title: 'Featured Tennis Classes in Melbourne',
      description: [
        "If you want to experience world-leading tennis coaching and training, look no further than Probuddy's Featured Tennis Classes in Melbourne! Our expert coaches employ groundbreaking techniques to help athletes of all ages and abilities to improve their game.",
        'We offer comprehensive sessions covering everything from mechanics and technique to strategy and psychological reinforcement, designed to help participants maximize their potential both on and off the court. Our curriculum is updated regularly with new tips and methods that have been proven effective by successful players, so you can rest assured that investing in one of our Featured Tennis Classes in Melbourne will give you the edge you need to succeed!',
      ],
    },
  },
  {
    id: 'chicago',
    title: 'chicago',
    heroVideo:
      'https://res.cloudinary.com/movementbuddy-pte-ltd/video/upload/v1687173237/probuddy/static/images/CategoriesPage/Tennis/Video/TENNIS_v7_hydl3g.mp4',
    section1: {
      heading: 'The Best Tennis Classes in Chicago',
      description: [
        'Are you looking for The Best Tennis Classes in Chicago? Look no further than Probuddy. Their experienced and knowledgeable instructors are committed to delivering quality instruction and making every session a great experience. The classes cover all areas related to the sport of tennis so that you can attain the highest level of performance possible. ',
        'Whether you have just picked up a racket or been hitting the court for years, their different classes are tailored to your individual skill level and goals. Take control of your game today and book The Best Tennis Classes in Chicago with Probuddy!',
      ],
      linkText: 'Book A Tennis Class Now',
    },

    section2: {
      heading: 'Find Your Perfect Tennis Coach in Chicago',
      description: [
        'Tennis is a popular sport in the city of Chicago and the perfect coach can help you take your skills to the next level. At Probuddy, we make finding Tennis coaches in the windy city easy. With our online platform, you can quickly find a Tennis Coach who will work with your busy schedule and match your preferred style of learning.',
        "You can select from various certified Tennis Coaches whose credentials have been verified, or sort by location or skill level. So don't waste any more time—find your perfect Tennis Coach and hit the courts today!",
      ],
      linkText: 'Become A Tennis Pro',
    },

    classesForYou: {
      title: 'Book Your Tennis Classes in Chicago',
      description: [
        'Have you ever wanted to learn the game of Tennis? Look no further than Probuddy, your go-to for Tennis Classes in Chicago. From private and group lessons to classes specifically designed for children, Probuddy can help you find the Tennis Classes that are perfect for your skill level and budget.',
        "With experienced coaches on hand and flexible class schedules to fit any lifestyle, you’ll be ready to hit the courts in no time. So don't wait - book your Tennis Classes at Probuddy today and start playing Tennis like a pro!",
      ],
    },

    instructors: {
      title: 'Featured Top-Rated Tennis Coaches in Chicago',
      description: [
        'Probuddy is proud to present some of the most highly rated Tennis Coaches in Chicago. From beginner to intermediate and even advanced levels, Probuddy takes into account the feedback from clients to identify Top-Rated coaches that are able to cater to various skill levels.',
        "With their in-depth knowledge of the game and their ability to customize a training program unique to each player's needs, these Top-Rated Tennis Coaches in Chicago are sure to provide an enjoyable learning experience. So if you're looking for someone who can help you take your game to the next level, look no further than Probuddy's Top-Rated Tennis Coaches in Chicago.",
      ],
      instructorsData: instructorsData('Tennis'),
    },

    featured: {
      title: 'Popular Tennis Classes and Events in Chicago',
      description: [
        "Tennis classes and events in Chicago are always highly popular, drawing people of all ages and skill levels. From private lessons to tournaments and more, there is always something to keep tennis fans engaged. Whether you're an experienced player or a beginner looking to learn the basics, Chicago Tennis Academy offers a variety of courses under the instruction of professional coaches. ",
        "Probuddy also offer group camps throughout the city for those looking for an intense week of training. On top of these classes, top-rated events like the Windy City Open bring tennis lovers from all over for the chance to witness world-class play and meet their favorite pros. Tennis has long been one of Chicago's favorite team sports, and it's easy to see why when you consider all that can be experienced in one city.",
      ],
    },

    topRatedClasses: {
      title: 'Featured Tennis Classes in Chicago',
      description: [
        "If you want to experience world-leading tennis coaching and training, look no further than Probuddy's Featured Tennis Classes in Chicago! Our expert coaches employ groundbreaking techniques to help athletes of all ages and abilities to improve their game.",
        'We offer comprehensive sessions covering everything from mechanics and technique to strategy and psychological reinforcement, designed to help participants maximize their potential both on and off the court. Our curriculum is updated regularly with new tips and methods that have been proven effective by successful players, so you can rest assured that investing in one of our Featured Tennis Classes in Chicago will give you the edge you need to succeed!',
      ],
    },
  },
  {
    id: 'seattle',
    title: 'seattle',
    heroVideo:
      'https://res.cloudinary.com/movementbuddy-pte-ltd/video/upload/v1687173237/probuddy/static/images/CategoriesPage/Tennis/Video/TENNIS_v7_hydl3g.mp4',
    section1: {
      heading: 'The Best Tennis Classes in Seattle',
      description: [
        "Probuddy offers the best Tennis Classes in Seattle for anyone wanting to take up this classic sport. Whether you're a beginner or a seasoned pro looking for helps on your technique, Probuddy Tennis Classes provide personalized training and instruction from experienced coaches. ",
        'All classes are designed with student-driven objectives in mind, giving participants an excellent learning experience while achieving their fitness and sports goals. With top-of-the-line equipment and lessons adaptable to different skill levels, these Tennis Classes in Seattle give you everything needed to hone your game like a pro!',
      ],
      linkText: 'Book A Tennis Class Now',
    },

    section2: {
      heading: 'Find Your Perfect Tennis Coach in Seattle',
      description: [
        'With Probuddy, anyone in the city looking for a Tennis Coach in Seattle can quickly and easily find the perfect fit. Our Tennis Coaches in Seattle come certified with years of experience to give you the confidence that you’re learning from the very best in the business. ',
        'Probuddy allows you to refine your search based on budget, availability, and expertise so that your Tennis Coach suits all your requirements. Whether if it’s improving your technical skills or building strategy, our Tennis Coaches are here to help you reach peak performance at each levels of the game. At Probuddy, get ready for an incredible Tennis journey!',
      ],
      linkText: 'Become A Tennis Pro',
    },

    classesForYou: {
      title: 'Book Your Tennis Classes in Seattle',
      description: [
        'Book Your Tennis Classes in Seattle with Probuddy for the ultimate tennis experience! Whether you’re a beginner or a seasoned player, you can find classes that match your level of skill. You will gain invaluable strategy and technique from experienced instructors who are dedicated to helping you further your game.',
        "You can easily book private or group lessons and learn in the comfort of your own home or at any of our spacious courts located throughout Seattle. Booking is easy, so what's stopping you from getting out on the court? With Probuddy, you can take your skills to the next level!",
      ],
    },

    instructors: {
      title: 'Featured Top-Rated Tennis Coaches in Seattle',
      description: [
        "Seattle is renowned for its premier tennis scene, and identifying the top-rated coaches can be quite a challenge. That's why Probuddy has created a list of the best Tennis Coaches in Seattle to help you find the right pro for your needs. Through our thorough vetting process, we make sure each coach has plenty of experience and an understanding of the sport that will take your game to the next level.",
        'From teaching beginners about basic techniques and rules, to helping experienced players hone their skills—our Tennis Coaches in Seattle have what it takes to help you champion your own journey towards personal excellence.',
      ],
      instructorsData: instructorsData('Tennis'),
    },

    featured: {
      title: 'Popular Tennis Classes and Events in Seattle',
      description: [
        'Probuddy offers some of the most popular tennis classes and events in Seattle. From weekly clinics to one-on-one private lessons, they offer a range of services for all levels of players, young and old alike. You can also find demonstrations, performing artists and special clinics that bring unique perspectives and teaching methods to every lesson.',
        'Geared towards both recreational and competitive players with varying skill levels, Probuddy makes sure that everyone attending their events leaves with something new - whether it be improved skillsets or simply a better appreciation for the sport.',
      ],
    },

    topRatedClasses: {
      title: 'Featured Tennis Classes in Seattle',
      description: [
        'If you’re looking for a great way to brush up on your tennis skills and explore the evergreen city of Seattle, then consider checking out Featured Tennis Classes in Seattle by Probuddy. Whether you are an experienced player or someone just starting out, you will find something tailored to your abilities and ambitions. We provide complete training and guidance, as well as court access - allowing you to practice your skills year-round. ',
        'Moreover, each class is run by certified and experienced coaches who can help your game reach the next level with their expertise. So if you’re searching for an enriching experience that combines professional coaching with beautiful natural surroundings, Featured Tennis Classes in Seattle by Probuddy should be at the top of your list.',
      ],
    },
  },
  {
    id: 'manchester',
    title: 'manchester',
    heroVideo:
      'https://res.cloudinary.com/movementbuddy-pte-ltd/video/upload/v1687173237/probuddy/static/images/CategoriesPage/Tennis/Video/TENNIS_v7_hydl3g.mp4',
    section1: {
      heading: 'The Best Tennis Classes in Manchester',
      description: [
        "If you're looking for the best tennis classes in Manchester, look no further than Probuddy. Our expert coaches will help you improve your game and reach your full potential. We offer a wide range of classes for all levels, from beginner to advanced. And our flexible scheduling means you can find a class that fits your busy lifestyle.",
        "Whether you're a seasoned player or just starting out, we have a class for you. Our beginner classes are designed to teach the basics of the game and get you comfortable on the court. We'll cover everything from grip and stance to strokes and footwork. And our advanced classes are perfect for those who want to take their game to the next level. We'll work on your technique, strategy, and mental toughness, so you can compete at your highest level.",
        'Probuddy is the leading provider of tennis instruction in Manchester. We have a team of experienced and dedicated coaches who are passionate about helping players succeed. Contact us today to learn more about our programs and how we can help you reach your goals.',
      ],
      linkText: 'Book A Tennis Class Now',
    },

    section2: {
      heading: 'Find Your Perfect Tennis Coach in Manchester',
      description: [
        "If you're looking for a perfect tennis coach in Manchester, look no further than Probuddy. Our experienced and certified coaches can help you improve your game and reach your full potential.",
        "We'll work with you to tailor a training plan that fits your needs and goals, and we'll always be there to support and encourage you. Contact us today to learn more about our services and how we can help you take your game to the next level.",
      ],
      linkText: 'Become A Tennis Pro',
    },

    classesForYou: {
      title: 'Book Your Tennis Classes in Manchester',
      description: [
        'Book Your Tennis Classes in Manchester with Probuddy When you are looking for a great way to improve your tennis game, there is no better way than to book your tennis classes in Manchester with Probuddy. We offer private, 1-on-1 tennis coaching sessions that are tailored to your specific needs and goals. Our coaches are all highly experienced and qualified, and they will work with you to help you improve your skills and technique.',
        'We understand that everyone learns at their own pace, so we offer a flexible approach that allows you to progress at your own rate. Whether you are a beginner or a more experienced player, we have the perfect class for you. We also offer group classes if you would prefer to learn with other people.',
        'Classes can be booked online or over the phone, and we offer a free consultation so that we can assess your level and what you hope to achieve from the classes. So why wait? Get in touch today and start improving your game!',
      ],
    },

    instructors: {
      title: 'Featured Top-Rated Tennis Coaches in Manchester',
      description: [
        "Whether you're a beginner or an experienced player, our coaches can help you take your game to the next level. They'll work with you to identify your strengths and weaknesses, and then tailor a training program that's specifically designed to help you improve.",
        "With Probuddy, you can be sure you're getting the best possible coaching available. Our coaches are all highly experienced and knowledgeable, and they're dedicated to helping their students succeed. Contact us today to learn more about our featured coaches and to find the perfect match for your needs.",
      ],
      instructorsData: instructorsData('Tennis'),
    },

    featured: {
      title: 'Popular Tennis Classes and Events in Manchester',
      description: [
        'There are many popular tennis classes and events in Manchester. Probuddy is a great place to start your search for the perfect class or event. They offer a wide range of classes, from beginner to advanced, as well as special events such as tournaments and camps.',
        "Whether you're looking to improve your skills or just have some fun, Probuddy has something for everyone. Their experienced and friendly staff will help you find the right class or event to suit your needs. So why not check them out today and start enjoying tennis in Manchester?",
      ],
    },

    topRatedClasses: {
      title: 'Featured Tennis Classes in Manchester',
      description: [
        'In Manchester, one of the most popular recreational activities is playing tennis. With that in mind, Probuddy is proud to feature some of the best tennis classes in the city.',
        "Whether you're a beginner or a seasoned player, our classes are designed to help you improve your skills and enjoyment of the game. Our experienced and certified coaches will work with you to develop a customized training plan that fits your needs and goals.",
        "Our classes take place at convenient locations around Manchester, and we offer a variety of class times to fit your schedule. We also have a wide range of class offerings, so you can choose the type of class that's right for you.",
        "If you're looking for a fun and challenging way to improve your tennis game, sign up for one of our featured classes today!",
      ],
    },
  },
  {
    id: 'san-francisco',
    title: 'san-francisco',
    heroVideo:
      'https://res.cloudinary.com/movementbuddy-pte-ltd/video/upload/v1687173237/probuddy/static/images/CategoriesPage/Tennis/Video/TENNIS_v7_hydl3g.mp4',
    section1: {
      heading: 'The Best Tennis Classes in San Francisco',
      description: [
        "There are plenty of great places to take tennis classes in San Francisco, but Probuddy is the best. Our highly experienced and certified staff will help you improve your game, whether you're a beginner or a pro.",
        "We offer a variety of classes, both group and private, to suit your needs and schedule. And our prices are very competitive. If you're looking for the best tennis classes in San Francisco, look no further than Probuddy!",
      ],
      linkText: 'Book A Tennis Class Now',
    },

    section2: {
      heading: 'Find Your Perfect Tennis Coach in San Francisco',
      description: [
        "Finding the right tennis coach in San Francisco can be a daunting task. With so many options to choose from, it's important to know what you're looking for in a coach before you start your search.",
        "Probuddy is here to help! We've curated a list of the best tennis coaches in San Francisco, based on your specific needs and preferences. Whether you're looking for someone to help you improve your technique, or you need someone who can provide customized training programs, we can help you find the perfect coach for you.",
        'Not sure where to start? Check out our blog post on How to Find a Tennis Coach in San Francisco.',
      ],
      linkText: 'Become A Tennis Pro',
    },

    classesForYou: {
      title: 'Book Your Tennis Classes in San Francisco',
      description: [
        "Book your tennis classes in San Francisco with ProBuddy and reap the benefits of convenience and tailored instruction. Our expert coaches know what's best for your skill level and desired progression, so you won't be wasting time on court or pulling strokes that don't add value.",
        "Booking is simple - choose a court location, a coach, and one or several lesson packages - it's really as easy as that! See why ProBuddy is the go-to source for personalized tennis classes in San Francisco today.",
      ],
    },

    instructors: {
      title: 'Featured Top-Rated Tennis Coaches in San Francisco',
      description: [
        'Probuddy is excited to offer a list of the top-rated tennis coaches in San Francisco! These featured coaches have been rated by their peers and have a proven track record of success. They are committed to helping their students improve their game and reach their full potential.Whether you are a beginner or an experienced player, these coaches can help you take your game to the next level.',
        'They will work with you to develop a personalized plan that fits your needs and goals. With their guidance and support, you will be able to reach your true potential as a tennis player.If you are looking for a coach who can help you achieve your dreams, then check out our list of featured coaches today!',
      ],
      instructorsData: instructorsData('Tennis'),
    },

    featured: {
      title: 'Popular Tennis Classes and Events in San Francisco',
      description: [
        'Popular activities in San Francisco include tennis classes and events provided by Probuddy. Whether you’re new to the game or an experienced pro, the high-quality resources from Probuddy have something for everyone. Their classes and events are led by certified coaches who will help you hone your skills and learn about the game of tennis.',
        'Aside from classes, Probuddy also offers tennis socials, tournaments, exhibitions, and more. Join Probuddy’s network of players to experience Popular Tennis Classes and Events in San Francisco.',
      ],
    },

    topRatedClasses: {
      title: 'Featured Tennis Classes in San Francisco',
      description: [
        'Probuddy is excited to feature tennis classes in San Francisco! Our tennis classes are perfect for all levels of players, from beginners to advanced. Our experienced and certified instructors will help you improve your skills and technique, and have a lot of fun in the process.',
        "We offer a variety of class types to choose from, so you can find the perfect fit for your schedule and level of interest. If you're looking for a great workout, our cardio tennis classes are a great option. If you want to focus on improving your game, our clinics and private lessons are ideal. And if you just want to enjoy some friendly competition, our social tournaments are perfect.",
        "No matter what your goals are, we have a tennis class that's right for you. So come join us on the court and start improving your game today!",
      ],
    },
  },
  {
    id: 'houston',
    title: 'houston',
    heroVideo:
      'https://res.cloudinary.com/movementbuddy-pte-ltd/video/upload/v1687173237/probuddy/static/images/CategoriesPage/Tennis/Video/TENNIS_v7_hydl3g.mp4',
    section1: {
      heading: 'The Best Tennis Classes in Houston',
      description: [
        "Finding the best tennis classes in Houston can be a daunting task. There are so many options to choose from and it can be hard to know which one is right for you. That's why Probuddy has put together a list of the best tennis classes in Houston, so you can find the perfect one for your needs.",
        "Whether you're a beginner or a seasoned pro, there's a tennis class in Houston that's right for you. For beginners, we recommend the Intro to Tennis class at the River Oaks Country Club. This class is designed to teach the basics of tennis in a fun and supportive environment. If you're looking for something more advanced, we recommend the Adult Intermediate Tennis class at Memorial Park. This class is perfect for players who are looking to take their game to the next level.",
        "No matter what your skill level or goals, there's a tennis class in Houston that's perfect for you. So get out there and start exploring all that this great city has to offer!",
      ],
      linkText: 'Book A Tennis Class Now',
    },

    section2: {
      description: [
        'Whether you’re a tennis enthusiast looking to improve your skills, or are just starting out and need help getting off the ground, Probuddy can help you Find Your Perfect Tennis Coach in Houston with ease. Our database contains some of the most qualified tennis coaches in the city and all it takes is a few clicks to get connected with one who fits your needs.',
        'With personalized feedback that’s designed for both beginners and experienced players alike, Probuddy provides the means for anyone to reach their goals at any stage of their tennis journey. Find your coach today and get ready to find your perfect fit!',
      ],
      linkText: 'Become A Tennis Pro',
    },

    classesForYou: {
      title: 'Book Your Tennis Classes in Houston',
      description: [
        "If you're looking to get fit and have some fun while doing it, tennis is the perfect sport for you. And what better place to play tennis than in Houston, Texas - one of the most vibrant and exciting cities in the country?",
        "At Probuddy, we make it easy for you to find and book tennis classes in Houston. We work with the best tennis instructors in the city to ensure that you get top-quality instruction at a time and location that's convenient for you.",
        "Whether you're a beginner or an experienced player, we have a class that's just right for you. Our classes are small and personal, so you'll get plenty of individual attention from your instructor. And our competitive pricing means that getting started with tennis is more affordable than ever.",
      ],
    },

    instructors: {
      title: 'Featured Top-Rated Tennis Coaches in Houston',
      description: [
        "Probuddy is excited to feature some of the top-rated tennis coaches in Houston! If you're looking for high-quality instruction and guidance on your journey to becoming a great tennis player, look no further than these talented coaches.",
        "Whether you're just starting out or you're a seasoned pro, these coaches can help you take your game to the next level. They specialize in different areas of the game, so you can choose a coach that best suits your needs.",
        "If you're serious about becoming a top tennis player, don't hesitate to reach out to one of these featured coaches. They have the knowledge and experience to help you reach your goals. Contact Probuddy today to get started!",
      ],
      instructorsData: instructorsData('Tennis'),
    },

    featured: {
      title: 'Popular Tennis Classes and Events in Houston',
      description: [
        "Probuddy's Popular Tennis Classes and Events in Houston are quickly becoming the go-to for anyone interested in developing their tennis skills. Our exciting range of available classes and events, tailored for different levels of experience and ages, offer something for everyone who loves to play.",
        "At Probuddy, we're passionate about bringing the joys of a fun game to everyone and strive to create an enjoyable environment where players can discover their potential. With plenty of instructor support and quality equipment provided with every event or class, you'll be sure to have a great time while brushing up on your tennis skills!",
      ],
    },

    topRatedClasses: {
      title: 'Featured Tennis Classes in Houston',
      description: [
        "Looking for a fun and active way to spend your weekends? Check out Probuddy's featured tennis classes in Houston! Our highly experienced and certified instructors will teach you all the basics, from proper grip and swings to scoring and serving. You'll also get to practice your new skills with other class participants in friendly matches.",
        "Whether you're a complete beginner or looking to brush up on your game, our classes are perfect for all levels. And with our convenient scheduling, you can easily find a class that fits into your busy schedule. So come on down and have some fun!",
      ],
    },
  },
];

export const yogaCityData = [
  {
    id: 'singapore',
    title: 'singapore',
    description: 'Yoga Classes in Singapore',

    section1: {
      heading: 'The Best Yoga Classes in Singapore',
      description: [
        'If you’re looking for The Best Yoga Classes in Singapore, Probuddy has you covered. With hundreds of classes available, they could be just the right spot to help hone your practice. The instructors are highly experienced and passionate about helping their students to release stress and build skills.',
        'Combine that with the variety of styles and levels of experience, and you’ve got yourself one of the finest yoga experiences available in Singapore. Sign up today and get started in finding The Best Yoga Classes with Probuddy!',
      ],
      linkText: 'Book A Yoga Class Now',
    },

    section2: {
      heading: 'Find Your Perfect Yoga Teacher in Singapore',
      description: [
        'Looking for a yoga teacher in Singapore with the perfect skills and personality to match your own? Find it with Probuddy! Using our platform, you can search through many yoga instructors in Singapore who have been professionally vetted and possess the right experience, qualifications, and ratings to make them suitable teachers.',
        'Find someone who will give you the best guidance that matches your individual needs, so you can deepen your practice of yoga in no time. Find Your Perfect Yoga Teacher in Singapore with Probuddy today!',
      ],
      linkText: 'Become A Yoga Pro',
    },

    classesForYou: {
      title: 'Book Your Yoga Classes in Singapore',
      description: [
        'Booking a yoga class in Singapore has never been easier with Probuddy. Our platform is designed with convenience and reliability in mind, making it a breeze to find and book yoga classes, no matter the location or time of day. ',
        'Whether you are looking for one-on-one sessions with experienced instructors or yoga classes that take place in private studios and venues across Singapore, Probuddy can help you find exactly what you need. Book your yoga classes today with Probuddy - the trusted online platform for booking all your fitness needs!',
      ],
    },

    instructors: {
      title: 'Featured Top-Rated Yoga Instructor in Singapore',
      description: [
        'Yoga has become increasingly popular in Singapore and Probuddy is proud to present a Yoga Instructor that is not only highly rated but also featured in their app. Their top-rated Yoga Instructor brings years of experience, skill, and enthusiasm for the practice of Yoga.',
        'Whether it’s your first time or hundredth, the Yoga Instructor has a class and challenge for everyone. Combining knowledge from her traditional Yoga guru with her own style, she seeks to inspire minds to open up to something bigger than the mundane day-to-day life. With Probuddy and Featured Top-Rated Yoga Instructor in Singapore, be inspired with creative flows and guided breathing while calming body and mind.',
      ],
      instructorsData: instructorsData('Yoga'),
    },

    featured: {
      title: 'Popular Yoga Classes and Events in Singapore',
      description: [
        'Popular Yoga Classes and Events in Singapore are offered exclusively through Probuddy. By using their user-friendly app, customers can easily browse and book a wide range of yoga classes, ranging from Vinyasa flow to Yin and Hatha. Not only that, but Probuddy also offers unique experiences such as aerial yoga, an exciting and fun way to practice yoga.',
        'Popular classes come with beginner-friendly instruction for those who are new to the practice. For those looking for a bigger experience, Probuddy offers events such as group retreats and teacher trainings that provide further support and guidance as you embark on your yoga journey. Whether you’re looking for occasional classes or comprehensive events, Popular Yoga Classes and Events in Singapore through Probuddy offer something for everyone!',
      ],
    },

    topRatedClasses: {
      title: 'Featured Yoga Classes in Singapore',
      description: [
        'If you are looking for an amazing yoga class to join that can give you the best experience, Probuddy has Featured Yoga Classes in Singapore that bring together a variety of styles and practices from all over the world. From Beginner Vinyasa Flow, to Experimental Sequencing, to Restorative Yoga, Probuddy has it all.',
        'Their classes are taught by experienced professionals who will make sure you get the most out of every practice while creating special moments that turn into lifelong memories. All of their Featured Yoga Classes in Singapore follow strict safety guidelines and offer both live-streamed videos and in-person sessions – taking your workout experience to a new level!',
      ],
    },
  },
  {
    id: 'sydney',
    title: 'sydney',

    section1: {
      heading: 'The Best Yoga Classes in Sydney',
      description: [
        "If you're looking for The Best Yoga Classes in Sydney, then you should check out Probuddy! The site allows you to choose from among a whole range of yoga classes, from beginner to advanced. The classes are taught by experienced teachers who have the quality and knowledge necessary for the safety and success of each student. ",
        'The classes also allow for variations in pace to suit different abilities, and the teachers can even create customised programmes based on your learning needs. With these unique and varied offerings available, Probuddy will ensure that The Best Yoga Classes in Sydney are just a few clicks away.',
      ],
      linkText: 'Book A Yoga Class Now',
    },

    section2: {
      heading: 'Find Your Perfect Yoga Teacher in Sydney',
      description: [
        'Find Your Perfect Yoga Teacher in Sydney with Probuddy - the modern way to find an experienced yoga teacher who can offer you private or group classes that meet your needs. With an easy-to-use interface, you can quickly browse through profiles and find a yoga teacher that is tailored to you.',
        'Find teachers with qualifications ranging from beginner classes all the way up to advanced instruction in a variety of postures and techniques. Our innovative platform also allows you to read reviews from others so you can make sure your perfect yoga teacher’s style is suitable for your goals. Use Probuddy today to discover the ideal Yoga Teacher in Sydney for you!',
      ],
      linkText: 'Become A Yoga Pro',
    },

    classesForYou: {
      title: 'Book Your Yoga Classes in Sydney with Probuddy',
      description: [
        'Finding the right yoga class in Sydney has never been easier than with Probuddy. The app allows you to quickly and easily search, book, and pay for a variety of yoga classes - all within minutes! ',
        'Booking yoga classes is made effortless with intuitive filters that allow you to search for classes by type, level, location and even time of day. Plus, at-a-glance displays of the reviews from previous users means you know exactly what you’re getting before even stepping foot through the doors. Book your next yoga session today with Probuddy – helping you find the perfect class every time.',
      ],
    },

    instructors: {
      title: 'Featured Top-Rated Yoga Instructor in Sydney',
      description: [
        'If you are looking for the perfect Featured Top-Rated Yoga Instructor in Sydney to break your fitness goals and turn them into reality, look no further than Probuddy app! With our extensive database of Featured Top-Rated Yoga Instructors, finding the best fit for you will be a breeze. Our Featured Top-Rated Instructors are certified professionals with an impressive resume of experience and knowledge.',
        "All of them provide personalized instruction combined with motivation and positive energy to ensure maximum progress during each session. No matter what skill level or physical goals you have, these Featured Top Rated Instructors will unshackle the limits and help you reach your potential. Take one step towards achieving your desired target today and start training with a Featured Top Rated Instructor that's right for you!",
      ],
      instructorsData: instructorsData('Yoga'),
    },

    featured: {
      title: 'Popular Yoga Classes and Events in Sydney',
      description: [
        "If you're looking for Popular Yoga Classes and Events in Sydney, Probuddy has got you covered! There is a variety of classes and events every week to suit all types of yoga levels, styles and preferences. Popular classes range from Vinyasa Flow, restorative therapeutic yoga, meditation sessions, workshops and more. ",
        'Those who attend these weekly sessions can always expect to challenge themselves while having fun at the same time. Regular guests can also feel free to explore new classes and deepen their understanding on mindful living when they attend the various events that are held by Probuddy. Come join us now at Probuddy for an amazing yoga journey in Sydney!',
      ],
    },

    topRatedClasses: {
      title: 'Featured Yoga Classes in Sydney',
      description: [
        'Sydney, Australia is home to a thriving yoga community. With the Probuddy app, you can easily explore Featured Yoga Classes in Sydney that offer a variety of styles, skill levels and pricing options. From Hatha and Vinyasa to Yin and restorative classes taught by experienced teachers, Sydney has something for everyone.',
        "You'll feel empowered as you deepen your yoga practice and gain insight from experienced instructors who are committed to helping each individual find their path on the mat. Whether you're new to yoga or an advanced practitioner, there's a Featured Yoga Class in Sydney for you.",
      ],
    },
  },
  {
    id: 'new-york',
    title: 'new-york',

    section1: {
      heading: 'The Best Yoga Classes in New York',
      description: [
        'The Best Yoga Classes in New York can be easily found and registered for on the Probuddy app. The app is designed to make finding quality classes convenient and hassle-free. Once you have downloaded the app, you can search through a variety of classes based on location, time of day, length of class and more.',
        'The classes use skill level filters so that you can choose the class that is right for your experience level. The friendly user interface also allows users to read comprehensive reviews of past classes before registering. With The Best Yoga Classes in New York provided on the Probuddy app, it has never been easier to find yoga classes that suit your needs.',
      ],
      linkText: 'Book A Yoga Class Now',
    },

    section2: {
      heading: 'Find Your Perfect Yoga Teacher in New York',
      description: [
        'Looking to find the perfect yoga teacher in New York? Look no further than Probuddy! With a variety of talented and experienced yoga teachers, Probuddy aims to match you with someone who is suited to your practice needs. From newbies getting started on their journey to yogis looking for a more advanced challenge, every yogi will find a teacher that meets their specific goals and expectations.',
        'Find your perfect match here - there’s never been an easier way to connect with exceptional yoga instructors around the city. With Probuddy, get ready for a transformative experience and true peace of mind!',
      ],
      linkText: 'Become A Yoga Pro',
    },

    classesForYou: {
      title: 'Book Your Yoga Classes in New York with Probuddy',
      description: [
        'Booking your yoga classes is now easier and more convenient with Probuddy in New York. Probuddy provides an extensive directory of yoga classes for all levels, from beginner to advanced. With their user-friendly platform, you can browse through hundreds of different exercise classes, find one that best suits your goals and book a spot quickly and easily.',
        "Whether it’s early morning sun salutations, gentle flow or vigorous vinyasa, Probuddy enables you to easily find something to fit your schedule, no matter what type of yoga you're interested in practicing. Booking your yoga classes has never been easier; enjoy the convenience and flexibility of taking classes all over New York through Probuddy today!",
      ],
    },

    instructors: {
      title: 'Featured Top-Rated Yoga Instructor in New York',
      description: [
        "If you're looking to find a Featured Top-Rated Yoga Instructor in New York, then look no further than Probuddy. Finding an instructor with the expertise and knowledge of yoga can be difficult, and can require dedicated time researching different instructors and classes. But with Probuddy, that search has just become hassle free.",
        'What makes Probuddy so special? Their Featured Top-Rated Yoga Instructors possess numerous years of experience practicing, teaching, and using their expertise to improve your overall yoga practice from basic stretches to perfecting challenging poses. With an ever expanding database of Featured Top-Rated Yoga Instructors across New York City, it’s never been easier to find the perfect match for you!',
      ],
      instructorsData: instructorsData('Yoga'),
    },

    featured: {
      title: 'Popular Yoga Classes and Events in New York',
      description: [
        'Probuddy is a great way to discover popular yoga classes and events that are happening in New York. No matter your experience level, you can quickly find the class or event of your choice by filtering through their extensive list of options, including traditional forms like Hatha, Vinyasa Flow and Ashtanga; meditation-focused classes such as Kundalini and Yin; or even more specialized classes such as Rocket Yoga and AcroYoga.',
        "Best of all, Probuddy’s booking system is easy, convenient and secure. So don't worry about missing out on these popular activities — sign up today!",
      ],
    },

    topRatedClasses: {
      title: 'Featured Yoga Classes in New York',
      description: [
        "If you’re looking to take your Yoga practice to the next level, check out the Featured Yoga Classes in New York in Probuddy. With options for beginners and experts alike, explore what New York has to offer in terms of relaxed yet enlightening sessions. Whether you're looking for some mental clarity and spiritual guidance or a fast-paced dynamic flow of poses.",
        "Probuddy's Featured Yoga Classes have it all. Take your pick from classes by top Yoga teachers where you can connect with like-minded individuals on the same journey. Book your class today and receive daily doses of empowerment and healing!",
      ],
    },
  },
  {
    id: 'london',
    title: 'london',

    section1: {
      heading: 'The Best Yoga Classes in London',
      description: [
        'The Probuddy is the go-to resource for finding the best yoga classes in London. With an intuitive search feature, users will easily be able to access a list of only the most highly rated classes according to customer reviews.',
        'Probuddy also provides detailed information on class times and location, making it easy to book a study session that works best for your schedule. The Probuddy App is more than just a directory – it’s the ultimate resource for anyone who wants to find the best yoga classes in all of London.',
      ],
      linkText: 'Book A Yoga Class Now',
    },

    section2: {
      heading: 'Find Your Perfect Yoga Teacher in London',
      description: [
        "Find Your Perfect Yoga Teacher in London with Probuddy. Find the perfect yoga teacher for you with Probuddy's specialized search engine for experienced and certified yoga trainers. Find teachers across multiple disciplines such as Vinyasa, Kundalini, Ashtanga, Jivamukti, Iyengar or Restorative Yoga and filter your search based on your own specific needs - from budget, location to style.",
        'With a continually growing list of yoga teachers in London easily accessible, Probuddy guarantees that you will be able to find one that suits your individual requirements and preferences. Take the stress out of searching and make finding the perfect yoga teacher convenient, easy and effortless!',
      ],
      linkText: 'Become A Yoga Pro',
    },

    classesForYou: {
      title: 'Book Your Yoga Classes in London',
      description: [
        "Booking a yoga class in London just got easier with Probuddy! At Probuddy, we understand everyone's busy schedule and make it simpler to book classes that suit you. Booking a session is fast and simple - all you need to do is browse through our selection of 2,000+ yoga classes in London and pick the one that best suits your needs.",
        'With Probuddy, you can easily choose date, time and location - so no need to worry about missing out or planning well in advance. And with our stellar customer reviews, you know your session will be top quality. Booking yoga has never been smoother - Book Your Yoga Classes in London with Probuddy today!',
      ],
    },

    instructors: {
      title: 'Featured Top-Rated Yoga Instructor in London',
      description: [
        "Probuddy's Featured Top-Rated Yoga Instructor in London is the perfect way to take your yoga practice to the next level. With years of experience teaching classes for people looking to improve their overall health and well-being, our Featured Top-Rated Yoga Instructor offers personalized guidance tailored specifically to your needs.",
        "Whether you want to focus on improving balance, posture or flexibility; increasing strength, focus and endurance; or simply feeling more relaxed at the end of each session - our Featured Top-Rated Yoga Instructor can help you achieve your own unique goals. So make sure you take advantage of this opportunity and join Probuddy's Featured Top-Rated Yoga Instructor in London today!",
      ],
      instructorsData: instructorsData('Yoga'),
    },

    featured: {
      title: 'Popular Yoga Classes and Events in London',
      description: [
        'If you are looking for popular yoga classes and events in London, Probuddy is the solution. Probuddy provides the user with helpful updates about local yoga classes and events taking place. From beginner basics to intermediate poses, Probuddy can provide you with all the information you need to join or attend yoga events.',
        'You can easily sign up for classes, schedule time slots, read ratings and feedback of other users, and connect with teachers meant to guide your practice. Whether it’s a one-time class or an ongoing event, all of your preferences will match any of the activities offered by Probuddy. Get ready to exercise your mind and body with Popular Yoga Classes and Events in London made easier by Probudd!',
      ],
    },

    topRatedClasses: {
      title: 'Featured Yoga Classes in London',
      description: [
        'Featured Yoga Classes in London by Probuddy are the perfect answer for those looking to try something new, stay fit and relax. Offering a variety of yoga classes to suit different interests and abilities, this comprehensive app is designed to make it easy and enjoyable to discover more about yoga and its numerous benefits. ',
        'With inspiring classes like Yin Yoga and Dynamic Vinyasa, you can practice meditation, balance and flexibility while gaining a deeper understanding of the physical demands of yoga. Get ready to grow your practice in a comfortable, encouraging environment that will have you feeling energized!',
      ],
    },
  },
  {
    id: 'los-angeles',
    title: 'los-angeles',

    section1: {
      heading: 'The Best Yoga Classes in Los Angeles',
      description: [
        'The Probuddy has made it easier than ever to find The Best Yoga Classes in Los Angeles. Probuddy allows users to compare the features, class types, and rankings of the different classes before they purchase a membership, helping them to make an informed decision about where to practice yoga.',
        'The intuitive interface of Probuddy also allows users to customize their search even further - filtering by location, length of class, and level of expertise. With The Best Yoga Classes in Los Angeles at your fingertips, you are sure to find a yoga class that fits perfectly into your lifestyle.',
      ],
      linkText: 'Book A Yoga Class Now',
    },

    section2: {
      heading: 'Find Your Perfect Yoga Teacher Coach in Los Angeles',
      description: [
        "If you're looking for the perfect yoga instructor in Los Angeles, then Probuddy is for you! Our database of experienced professionals can help you find an instructor that fits your specific preferences. Not only do you have access to certified teachers in your area, but also tailored recommendations based on your location and schedule availability.",
        'Find classes for any skill level and choose from a wide range of styles including beginner yoga, pranayama breathing, ashtanga vinyasa, and more! With Probuddy, finding your ideal yoga teacher in Los Angeles has never been easier.',
      ],
      linkText: 'Become A Yoga Pro',
    },

    classesForYou: {
      title: 'Book Your Yoga Classes in Los Angeles with Probuddy',
      description: [
        "Booking yoga classes in Los Angeles is easy with Probuddy. Whether you're looking for an energizing Hatha yoga class, a Vinyasa flow class to get your heart rate up, or a restorative Yin class to unwind, Probuddy has every option you need to keep your practices fresh and interesting.",
        "With clear information about each teacher and timeslot available at the click of a button, you'll be able to find exactly what works for your schedule and goals. Booking with Probuddy makes it easy to finally make time for those classes that have been on your wishlist!",
      ],
    },

    instructors: {
      title: 'Featured Top-Rated Yoga Instructor in Los Angeles',
      description: [
        'Our Featured Top-Rated Yoga Instructor in Los Angeles on the Probuddy has a unique approach to helping students meet their health and wellness goals. With expertise in a range of styles, including vinyasa flow, restorative yoga, meditation, pranayama breath work, alignment based yoga and more, they offer classes perfect for beginners, intermediate and advanced yogis alike.',
        'Their personalized approach to teaching helps ensure you get the most out of each class and find peace and balance in all aspects of your life on and off your mat.',
      ],
      instructorsData: instructorsData('Yoga'),
    },

    featured: {
      title: 'Popular Yoga Classes and Events in Los Angeles',
      description: [
        "Popular yoga classes and events in Los Angeles are easier than ever to access with Probuddy, an innovative platform allowing users to discover the best places in their city's yoga scene. With Probuddy, you can browse a comprehensive list of local yoga classes and events all in one place.",
        "Whether you're looking for indoor or outdoor classes, weekend retreats, or workshops, probuddy provides up-to-date information on these types of experiences that can engage yogis at any level – from beginner to advanced. Their filters help you find what's most suitable for your goals without having to sift through page after page of search results. So if you're looking for Popular Yoga Classes and Events in Los Angeles make sure to check out Probuddy!",
      ],
    },

    topRatedClasses: {
      title: 'Featured Yoga Classes in Los Angeles',
      description: [
        'Los Angeles is a great place to start yoga classes. Thanks to the Probuddy, you can join Featured Yoga Classes in Los Angeles and explore a range of options near you. These classes offer a professional environment for beginners, as well as more advanced classes for experienced yogis.',
        "With personalized programs tailored to each individual's level and goals, there is something here for everyone — regardless of your skill level or experience with yoga. You can even take advantage of beginners' classes or teacher-led retreats, so you can jumpstart your journey into health and wellness.",
      ],
    },
  },
  {
    id: 'melbourne',
    title: 'melbourne',

    section1: {
      heading: 'The Best Yoga Classes in Melbourne',
      description: [
        'The Probuddy is a must have for anyone looking to take one of The Best Yoga Classes in Melbourne. It offers a range of classes from traditional to more boutique styles and allows you to quickly and easily book the spot that works best for you. The instructors are all experienced, friendly, and highly trained to give you the most reward for your practice.',
        "Probuddy also allows members to track their session history, performance metrics and their general yoga journey, making it an ideal way to stay on top of progress and get the most out of each practice. So if you're looking for The Best Yoga Classes in Melbourne, there's no easier way than Probuddy.",
      ],
      linkText: 'Book A Yoga Class Now',
    },

    section2: {
      heading: 'Find Your Perfect Yoga Teacher in Melbourne',
      description: [
        'Find the perfect yoga teachers in Melbourne with the help of Probuddy. Find classes, workshops and events that are best tailored to your fitness and skill level by searching based on the type of yoga practice you prefer. With the help of Probuddy, you can also customize your next experience based on location, price-range and special offers.',
        'Probuddy also allows you to select a teacher who matches your preference in terms of age, gender, ethnic background and more - so you can find the ideal teacher just around the corner. Get into shape with Australian certified teachers quickly, efficiently and safely today!',
      ],
      linkText: 'Become A Yoga Pro',
    },

    classesForYou: {
      title: 'Book Your Yoga Classes in Melbourne with Probuddy',
      description: [
        'Booking yoga classes in Melbourne has never been easier with Probuddy - the newest marketplace to get you started with your favorite activities. You can book your classes quickly and easily, choosing from a wide range of options for yoga in Melbourne and the surrounding region, no matter what level or style that suits you best.',
        "Booking your classes ahead of time mean you don't miss out when places are full, plus you can manage payments in Probuddy to make it totally effortless. With so many experienced instructors available on Probuddy, it's as simple as finding what works for you and getting moving! So why not take the leap today and discover the amazing opportunities that are right at your fingertips?",
      ],
    },

    instructors: {
      title: 'Featured Top-Rated Yoga Instructor in Melbourne',
      description: [
        'With the Probuddy, you have the opportunity to find a Featured Top-Rated Yoga Instructor in Melbourne who is sure to meet your needs. Whether you are new to yoga or a seasoned practitioner, you can access highly rated instructors in your area who offer a range of classes and sessions tailored specifically for your goals.',
        'With such an array of certified professionals at your fingertips, there is sure to be someone to help guide you on your journey – no matter where it takes you!',
      ],
      instructorsData: instructorsData('Yoga'),
    },

    featured: {
      title: 'Popular Yoga Classes and Events in Melbourne',
      description: [
        'Popular yoga classes and events in Melbourne have never been easier to find thanks to Probuddy, a revolutionary app designed solely for discovering local opportunities in the wellness sphere. With Probuddy, users can curate their perfect yoga day out based on their available time, interests and location.',
        'Popular classes include hot yoga and mindfulness, with events including workshop series’ instructing a complete lifestyle makeover. Best of all, results can easily be filtered according to budget to accommodate people at all stages of their journey. Developed with ease as its primary tenet, Probuddy is quickly becoming the go-to app for yogis in Melbourne looking to explore what their city has to offer.',
      ],
    },

    topRatedClasses: {
      title: 'Featured Yoga Classes in Melbourne',
      description: [
        "Melbourne is a great place to practice yoga, and now it's even easier with Featured Yoga Classes in Melbourne, brought to you by Probuddy. From full-body workouts to meditation classes and breathing techniques, Probuddy has all the resources you need to enhance your yoga practice.",
        "You can learn traditional postures, advanced asanas and sun salutations with experienced instructors. With Featured Yoga Classes in Melbourne, your yoga lessons are tailored specifically to help you find balance and grow your skills. Whether you're looking for a quiet and calming class or something more intense, Probuddy has everything you need for a fulfilling yoga journey in Melbourne.",
      ],
    },
  },
  {
    id: 'chicago',
    title: 'chicago',

    section1: {
      heading: 'The Best Yoga Classes in Chicago',
      description: [
        'The Best Yoga Classes in Chicago have never been easier to find than with Probuddy. The app connects users with knowledgeable instructors who provide innovative, physical and virtual yoga classes for all levels. The app helps users hone in on their practice, explore new techniques and styles, and awaken the internal energy of the body and mind.',
        "The vast selection of expert-level yoga classes offered by Probuddy ensure that each user can find the class that's perfect for them. Whether you are a beginner looking for instruction or an experienced practitioner seeking to deepen your practice, The Best Yoga Classes in Chicago are just a few clicks away with Probuddy App.",
      ],
      linkText: 'Book A Yoga Class Now',
    },

    section2: {
      heading: 'Find Your Perfect Yoga Teacher in Chicago',
      description: [
        "Are you looking to challenge your fitness journey with yoga in Chicago? Well, the search ends here! Probuddy will help you Find Your Perfect Yoga Teacher. With a wide selection of studios, gyms and instructors, you'll be able to find just what you’re looking for!",
        "From beginners to experts, personalized practice plans are available as well as customized advice from each instructor. Yup, it's that simple! So don't let your search for the ideal teacher stall your yoga plans. Visit Probuddy App and Find Your Perfect Yoga Teacher in Chicago today!",
      ],
      linkText: 'Become A Yoga Pro',
    },

    classesForYou: {
      title: 'Book Your Yoga Classes in Chicago with Probuddy',
      description: [
        'Booking yoga classes in Chicago just became a lot easier and more convenient with Probuddy! With this revolutionary app, users have access to one of the most comprehensive collections of yoga classes with the best teachers in all of Chicago. Anyone can easily search for the class they want and book it directly from their smartphone.',
        'There are a variety of yoga classes available including major styles like Vinyasa Flow or Hatha Yoga and even specialty classes such as Prenatal Yoga or Restorative Yoga and many more! Booking these classes with Probuddy guarantees that you get your perfect practice every time. Try it out today and Book Your Yoga Classes in Chicago with Probuddy!',
      ],
    },

    instructors: {
      title: 'Featured Top-Rated Yoga Instructor in Chicago',
      description: [
        'Featured Top-Rated Yoga Instructor in Chicago by Probuddy is the perfect choice for anyone looking to up their yoga game. With years of experience and expertise in the practice, this instructor has a unique skill set that allows them to design classes that not only meet individual needs but make it fun at the same time.',
        'This Featured Top-Rated Yoga Instructor also attends yoga conventions across the country and always stays up-to-date with all the latest yoga trends and advances. With this Featured Top Rated Yoga Instructor, you can be sure that you are getting the best of what yoga offers.',
      ],
      instructorsData: instructorsData('Yoga'),
    },

    featured: {
      title: 'Popular Yoga Classes and Events in Chicago',
      description: [
        'Probuddy App makes it easier than ever to find popular yoga classes and events in Chicago! With its extensive list of classes from beginners to the experienced yogi, Probuddy allows users to find a class tailored for their needs and schedule. In addition to weekly classes',
        'Probuddy also offers various yoga-related workshops, seminars and retreats around the Chicago area that enable users to dive deeper into their practice. Whether you’re looking for a stress-relieving hour of relaxation, or a comprehensive exploration of the benefits of yoga, Probuddy’s line up of Popular Yoga Classes and Events in Chicago has something for everyone!',
      ],
    },

    topRatedClasses: {
      title: 'Featured Yoga Classes in Chicago',
      description: [
        "If you're looking for popular yoga classes branded by experts, look no further than the Featured Yoga Classes in Chicago offered on Probuddy. With Featured Yoga Classes found in all parts of the city, you can be sure to find instructors and classes that are suitable to your level and preferred style.",
        "You'll get to choose from serene Beginners classes or fast-paced Power Vinyasa Flow. As part of their introductory offer they also have free trials available, so don't miss out!",
      ],
    },
  },
  {
    id: 'seattle',
    title: 'seattle',

    section1: {
      heading: 'The Best Yoga Classes in Seattle',
      description: [
        'The Best Yoga Classes in Seattle can be found through the Probuddy. Probuddy features reviews from real customers as well as class descriptions which allows users to book confidently. Probuddy also offers a wide variety of yoga classes for all levels, ranging from beginner sessions to advanced practicers.',
        'Additionally, the instructors featured are certified and highly regarded by current and previous participants. With the convenience of smartphones and tablets, finding The Best Yoga Classes in Seattle is now easier than ever. Visit Probuddy today and see for yourself!',
      ],
      linkText: 'Book A Yoga Class Now',
    },

    section2: {
      heading: 'Find Your Perfect Yoga Teacher in Seattle',
      description: [
        'Finding the perfect yoga teacher in Seattle just got easier with the Probuddy. Looking to try a new style or deepen your existing practice? Search through thousands of talented yogis offering different types of yoga and find what works best for you.',
        'With detailed profiles, ratings, classes listed, and pricing available, you can easily find the right instructor to match your needs. Get started today! Find your perfect yoga teacher in Seattle in the Probuddy.',
      ],
      linkText: 'Become A Yoga Pro',
    },

    classesForYou: {
      title: 'Book Your Yoga Classes in Seattle with Probuddy',
      description: [
        'Booking your yoga classes in Seattle just got a lot easier with Probuddy. Probuddy provides a convenient platform to find, compare and book your perfect class in the city. You can explore everything from restorative yoga for deep relaxation or challenge yourself with fly high classes, all booked through one convenient website.',
        'Booking multiple classes? Bulk bookings are easy too! Each class comes with Probuddy reminders and offers, making it even easier to stay motivated and reach your goals. Book your yoga classes today and benefit from the convenience of Probuddy - because we believe wellness should be accessible to all!',
      ],
    },

    instructors: {
      title: 'Featured Top-Rated Yoga Instructor in Seattle',
      description: [
        'Probuddy is proud to offer a Featured Top-Rated Yoga Instructor in Seattle - the perfect resource for anytime you want to find quality yoga instruction in the region. Our Featured Top-Rated Yoga Instructor has years of experience and advanced certification to ensure that every session is safe, effective, and inspirational.',
        "Whether you're an experienced yogi or a curious beginner, our Featured Top-Rated Yoga Instructor can customize sessions tailored to your goals, offering plenty of guidance and support throughout. Get ready for an amazing yoga experience with the Probuddy Featured Top-Rated Yoga Instructor in Seattle!",
      ],
      instructorsData: instructorsData('Yoga'),
    },

    featured: {
      title: 'Popular Yoga Classes and Events in Seattle',
      description: [
        'Probuddy is a great resource for those living in Seattle and looking to try popular yoga classes and events. With top-notch instructors, Probuddy makes it easy to find the perfect class and event according to your preferences and needs. Popular offerings include everything from hatha yoga classes to Yin yoga to Kundalini meditation - all designed to help you reach your personal goals.',
        'From beginning practitioners to experienced yogis, everyone in Seattle can find something of interest that they can attend with friends or alone! For the most reliable information on local yoga events and classes available, look no further than Probuddy.',
      ],
    },

    topRatedClasses: {
      title: 'Featured Yoga Classes in Seattle',
      description: [
        "If you're in the Seattle area and looking for yoga classes, Probuddy has you covered! Featured classes are growing daily, providing users with a wide range of teaching styles and skill levels (including beginners) to suit every body's needs. From Ashtanga to Yin and Hatha,",
        'Probuddy provides complete class descriptions, instructors’ profiles and reviews from other users so that you are sure to pick the perfect class for your experience level - no sweat! All it takes is one click to book a featured yoga class in Seattle through the Probuddy. Make your practice count!',
      ],
    },
  },
  {
    id: 'manchester',
    title: 'manchester',

    section1: {
      heading: 'The Best Yoga Classes in Manchester',
      description: [
        'The Best Yoga Classes in Manchester are now available to you through Probuddy. Are you looking for an experienced teacher with an eye-catching teaching style? The Probuddy has got you covered! The yoga classes available in Probuddy provides an engaging atmosphere with plenty of individualized attention, making sure that everyone can keep up, no matter their skill level.',
        " A variety of classes - ranging from beginner to advanced – is provided, allowing the student's flexibility to choose a class that best suits their practice and goals. The teachers create a comfortable learning environment which encourages the students to strive further and try something new. With The Best Yoga Classes in Manchester made easy via the Probuddy, anyone can find the perfect classes for themselves either on mobile or desktop.",
      ],
      linkText: 'Book A Yoga Class Now',
    },

    section2: {
      heading: 'Find Your Perfect Yoga Teacher in Manchester',
      description: [
        "If you're looking for a yoga teacher in Manchester, look no further than Probuddy! Probuddy is an online platform that offers tailored fitness courses to help you find the perfect yoga teacher for your individual needs. With Probuddy, you can browse through hundreds of trainers and choose the person best suited to your experience level and desired goals.",
        "You'll also receive helpful and expert guidance to help you get the most out of every session with a reliable and experienced instructor. Find Your Perfect Yoga Teacher in Manchester with Probuddy today, and unlock the power of yoga!",
      ],
      linkText: 'Become A Yoga Pro',
    },

    classesForYou: {
      title: 'Book Your Yoga Classes in Manchester with Probuddy',
      description: [
        'Looking for a way to stay fit and relaxed in Manchester? Book your yoga classes with Probuddy! Our platform connects you with the best yoga teachers across the city. We offer both online and in-person classes so you can work on your practice wherever is most convenient to you.',
        'Whether you are interested in dynamic vinyasa flows, gentle hatha instruction, or restorative yin practices; we have something for everyone. Book your yoga classes with Probuddy today and experience the benefits of improved flexibility, mental clarity and overall wellbeing!',
      ],
    },

    instructors: {
      title: 'Featured Top-Rated Yoga Instructor in Manchester',
      description: [
        'Manchester just got a bit stretchier thanks to Probuddy newly featured Top-Rated Yoga Instructor! With over ten years of experience in transforming lives with the power of mind and body alignment through yoga technique, this instructor aims to help clear physical and mental blockages, ultimately enhancing your overall wellbeing.',
        "With a calming presence and ability to build relationships based on trust, you'll be sure to leave your classes feeling relaxed, centered, balanced and more connected with yourself. So if you're ready for your journey towards inner peace - let Probuddy bring you closer to meeting your yoga goals!",
      ],
      instructorsData: instructorsData('Yoga'),
    },

    featured: {
      title: 'Popular Yoga Classes and Events in Manchester',
      description: [
        "Popular Yoga Classes and Events in Manchester by Probuddy are the perfect way to improve your wellbeing and reach your wellness goals. Whether you're a beginner looking for an introductory experience or a veteran of yoga looking to challenge yourself, these classes have something for everyone. With experienced teachers, flexible scheduling, and convenient locations, you'll have easy access to quality yoga sessions all over Manchester.",
        "Popular classes include Vinyasa Flow, Hatha and Yin Yoga as well as Power Yoga and Meditation courses. Popular events such as mindful mornings, evening classes and specialty workshops are also available so that you can deepen your practice with exciting new experiences. With Probuddy Popular Yoga Classes and Events in Manchester series, it's never been easier to find the perfect fit for your lifestyle.",
      ],
    },

    topRatedClasses: {
      title: 'Featured Yoga Classes in Manchester',
      description: [
        'Probuddy offers a variety of Featured Yoga Classes in Manchester. Our expert instructors bring a range of knowledge and experience to each class, teaching many different styles of yoga to suit different individual needs and goals. We take into account the season and time of the day, tailoring our classes to make sure they’re the most beneficial for our participants.',
        'From gentle restorative classes to dynamic vinyasa flows, we have something for everybody! Our Featured Yoga Classes are not only strengthening, calming and empowering; but also create a social atmosphere - giving people the opportunity to meet new people in an informal setting.',
      ],
    },
  },
  {
    id: 'san-francisco',
    title: 'san-francisco',

    section1: {
      heading: 'The Best Yoga Classes in San Francisco',
      description: [
        'The Best Yoga Classes in San Francisco can be found at Probuddy! At Probuddy, we believe that Yoga should be accessible to everyone and to offer varieties of classes ranging from beginner’s group classes to yoga therapy for those who have special needs. The skilled teachers specialise in various kinds of styles such as alignment-based, restorative, yin, hatha & vinyasa yoga.',
        'Our signature practice is an exquisite balance between vigorous and restoration postures. By using both Iyengar-style alignment principles and intelligent sequencing of poses, you will be guided into breathing deeply while being encouraged to move mindfully in each pose. The combination of a fun flow with therapeutic benefits makes our classes unique. The cozy studio atmosphere and pleasant staff will help ensure your practice is enjoyable and life-changing.',
      ],
      linkText: 'Book A Yoga Class Now',
    },

    section2: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677224089/probuddy/static/images/CategoriesPage/Yoga/certifiedInstructorsImage_uyyhwb.png',
      heading: 'Find Your Perfect Yoga Teacher in San Francisco',
      description: [
        'Whether you’re perfecting your crow pose and need expert advice, or just starting out on your yoga journey — ProBuddy has got you covered! Find your perfect yoga teacher in San Francisco right here at ProBuddy. Our teachers bring years of personal experience to their classes and will guide you through a practice that best serves your learning level.',
        'Explore some new poses, or learn breathing techniques to help with everyday stress. Find the class and teacher that’s the best fit for you and take your yoga practice to the next level!',
      ],
      linkText: 'Become A Yoga Pro',
    },

    classesForYou: {
      title: 'Book Your Yoga Classes in San Francisco with Probuddy',
      description: [
        'Booking your yoga classes in San Francisco with Probuddy is a great way to take control of your health and well-being. With Probuddy, you can find certified instructors offering classes on the day and time that work best for your needs.',
        "Whether you're looking to make a fitness transformation or just need some extra help staying active, Probuddy's platform makes it easy to locate instructors near you quickly and efficiently. Booking yoga classes has never been simpler – try Book Your Yoga Classes in San Francisco with Probuddy today!",
      ],
    },

    instructors: {
      title: 'Featured Top-Rated Yoga Instructor in San Francisco',
      description: [
        "If you're looking to get a high quality yoga experience in San Francisco, Probuddy has featured one of the top-rated yoga instructors to provide exceptional service. Located in the downtown area, this instructor is dedicated to helping their students progress from a basic level to more advanced classes that focus on postures and breathing techniques.",
        'With their unique method which combines breathwork with intuitive movement, clients are sure to see results even after their first class. In addition to personalized instructions, the Featured Top-Rated Yoga Instructor also provides guidance for meditative practices and lifestyle tips so that you can take your yoga practice wherever you go.',
      ],
      instructorsData: instructorsData('Yoga'),
    },

    featured: {
      title: 'Popular Yoga Classes and Events in San Francisco',
      description: [
        'Popular yoga classes and events in San Francisco have never been easier to find! One of the top providers, Probuddy offers a wide variety of options for yogis and fitness enthusiasts alike. Whether you’re looking for dynamic vinyasa or a more relaxing guided meditation, Probuddy has it all. Choose from many specialty classes such as couples yoga, candlelight workouts, and even outdoor events – whatever suits your needs!',
        'Popular yoga instructors from around the city come together to share the joys and benefits of the practice. No matter what class you choose, you are guaranteed to leave feeling energized and stress free. From beginners to experienced folks alike, make sure to check out Popular Yoga Classes and Events in San Francisco by Probuddy today!',
      ],
    },

    topRatedClasses: {
      title: 'Featured Yoga Classes in San Francisco',
      description: [
        "If you're looking for a great exercise class to help you relax and stay in shape, check out the Featured Yoga Classes in San Francisco! With classes for both experienced and beginner yogis, you'll be able to find a course that's perfect for your needs.",
        "Featured classes offer special practices such as Aerial Yoga -- which combines traditional yoga poses with acrobatics -- as well as outdoor Yoga to help you get closer to nature while still enjoying all of the benefits of yoga practice. Experienced instructors are on hand to make sure that everyone can enjoy their classes with the right level of instruction and guidance. Don't miss out on this opportunity to nurture body and soul – sign up today!",
      ],
    },
  },
  {
    id: 'houston',
    title: 'houston',

    section1: {
      heading: 'The Best Yoga Classes in Houston',
      description: [
        'The Best Yoga Classes in Houston can be found in the Probuddy. The app allows you to easily find classes with experienced instructors that offer everything from Vinyasa Yoga to Restorative Yoga. The user friendly interface guides you quickly to a variety of classes, so that even busy professionals can take some time out of their day for a relaxing stretching session or an energizing flow class.',
        'The yoga community in Houston is supportive and inviting, helping yogis of all experience levels improve their practice and cultivate a healthier lifestyle. Join hundreds of like-minded people on the path to discovering the best version of themselves with The Best Yoga Classes in Houston through Probuddy App!',
      ],
      linkText: 'Book A Yoga Class Now',
    },

    section2: {
      imgSrc:
        'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1677224089/probuddy/static/images/CategoriesPage/Yoga/certifiedInstructorsImage_uyyhwb.png',
      heading: 'Find Your Perfect Yoga Teacher in Houston',
      description: [
        'Find Your Perfect Yoga Teacher in Houston with Probuddy - the best way to get customized guidance! probuddy is an innovative platform that connects users to certified yoga experts for personalized support and advice. Find the perfect instructor for your needs through our online directory of instructors from across Houston or speak directly to a member of our team to discuss which teacher might be the best fit for you.',
        "With Probuddy, you have access to the most qualified and experienced practitioners so you can feel confident that you'll receive quality instruction. Get started today and find your perfect yoga teacher in Houston with Probuddy!",
      ],
      linkText: 'Become A Yoga Pro',
    },

    classesForYou: {
      title: 'Book Your Yoga Classes in Houston with Probuddy',
      description: [
        'Booking a yoga class in Houston has never been so easy! Probuddy is an online platform that allows you to easily find and book the right yoga class for your needs. Whether you are looking for a restorative, heated vinyasa, or hot power class,',
        'Probuddy helps connect users with the best nearby studio and instructor in order to get you into downward dog as soon as possible. Book Your Yoga Classes in Houston with Probuddy to experience the comprehensive and convenient way to get sweat on your mat!',
      ],
    },

    instructors: {
      title: 'Featured Top-Rated Yoga Instructor in Houston',
      description: [
        'At Probuddy, we are proud to be featuring the top-rated yoga instructor in Houston! Our careful selection of this experienced teacher reflects our commitment to providing the highest quality yoga instruction for our patrons. Whether you are a beginner looking for a gentle introduction to the practice or an advanced yogi looking for challenging poses and sequences, this instructor has something to offer everyone.',
        'With experience teaching in multiple studios throughout Houston over the past several years, this instructor is sure to provide an outstanding class that will leave you feeling energized and ready to tackle your day. Join us at Probuddy and meet our Featured Top-Rated Yoga Instructor in Houston today!',
      ],
      instructorsData: instructorsData('Yoga'),
    },

    featured: {
      title: 'Popular Yoga Classes and Events in Houston',
      description: [
        'If you’re looking for Popular Yoga Classes and Events in Houston, the Probuddy is your one-stop shop. With a comprehensive selection of events to choose from, you are guaranteed to find the perfect class for whatever type of yoga experience you’re after.',
        'Be it an energizing vinyasa class or something more restorative like yin or meditation, you can take advantage of all the options available on the app. And if that wasn’t enough, Probuddy offers some of the best deals around so that you don’t have to worry about breaking the bank to stay active and benefit from yoga!',
      ],
    },

    topRatedClasses: {
      title: 'Featured Yoga Classes in Houston',
      description: [
        'Featured Yoga Classes in Houston, offered by the Probuddy, offer a huge variety of styles and methods to suit your individual goals. Whether you are looking for something basic and meditative, a challenging workout session or something in between, Houston classes will suit you perfectly.',
        'Depending on what works best for you, choose classes that concentrate on breath-work, relaxation, postures or energy-level balancing - all of which are crafted by experienced and certified professionals. Unlock the power of yoga in Houston with Probuddy today.',
      ],
    },
  },
];

export const pilatesCityData = [
  {
    id: 'singapore',
    title: 'singapore',

    section1: {
      heading: 'The Best Pilates Classes in Singapore',
      description: [
        'The Best Pilates Classes in Singapore can be easily found with the Probuddy! This platform offers a variety of fantastic classes that are guaranteed to help you build strength, gain flexibility and achieve overall better health. The program also caters to your specific needs and goals based on your activity level with classes designed for beginners, slow or fast paced sessions and even workshops delivered by knowledgeable professionals. ',
        'Probuddy can also recommend qualified instructors for those who require specialised coaching. With the selection of the Best Pilates Classes in Singapore on offer through Probudd, you no longer have to worry about getting stuck in an overcrowded class that does not meet your expectations – sign up now to start your journey towards a healthier life!',
      ],
      linkText: 'Book A Pilates Class Now',
    },

    section2: {
      heading: 'Find Your Perfect Pilates Teacher in Singapore',
      description: [
        "Are you looking for Pilates teachers in Singapore? With Probuddy, it's never been easier to find your perfect teacher! The platform allows you to browse through from a wide range of exciting and experienced teachers who specialise in various methods of Pilates.",
        'With different class selections, find one that suits your need and budget. Set up a session at a time and place of your convenience - no more late-night searches online for studios or schools near you. Get Pilates training now with Probuddy App and take charge of your health and wellbeing today!',
      ],
      linkText: 'Become A Pilates Pro',
    },

    classesForYou: {
      title: 'Book Your Pilates Classes in Singapore with Probuddy',
      description: [
        'Booking Pilates classes in Singapore has never been easier than with Probuddy! Book your classes quickly and easily on the user-friendly platform. Get to know certified trainers, their qualifications and reviews before committing to a class, or join one of the probuddy community classes -- all designed for different levels and age groups.',
        'With Probuddy, you can find the perfect Pilates class for your needs, from beginner to advanced! Get fit while being guided by professionals in one of the most vibrant cities in Asia. Book with Probuddy today!',
      ],
    },

    instructors: {
      title: 'Featured Top-Rated Pilates Instructor in Singapore',
      description: [
        'Probuddy has featured a top-rated Pilates instructor in Singapore to help those looking for accessible and effective ways to stay fit during this challenging time. This instructor is well-known for their comprehensive classes that cater to both the physical and lifestyle needs of students with an emphasis on flowing moves.',
        'As this renowned instructor guides their students through Pilates exercises, they are also educating them on key principles such as building core strength, regaining muscle balance, and improving posture. With expert guidance, high-quality support practices, and optimal results, the Featured Top-Rated Pilates Instructor in Singapore on Probuddy is sure to assist you in reaching your wellness goals.',
      ],
      instructorsData: instructorsData('Pilates'),
    },

    featured: {
      title: 'Popular Pilates Classes and Events in Singapore',
      description: [
        'Singapore is becoming more health-conscious, and Probuddy has risen to meet the demand with its Popular Pilates Classes and Events. Whether you’re a fan of traditional Pilates or looking for something unique, they have it all! With classes on flexibility, strength, posture and breathing techniques, their enthusiastic instructors will guide you through the lessons in an exciting learning environment.',
        'Besides the regular classes and one-on-one sessions, Probuddy also offers fun events like health retreats aimed at helping individuals get fit while having a great time. Visit Probuddy and see what Popular Pilates Classes and Events Singapore has to offer!',
      ],
    },

    topRatedClasses: {
      title: 'Featured Pilates Classes in Singapore',
      description: [
        "If you're interested in bringing your Pilates game to the next level, look no further than Probuddy. This Featured Pilates classes in Singapore offer a great variety of options that cater to all sorts of experience levels and needs.",
        "ProBuddy provides the perfect platform for you to learn and explore the different practice styles of this empowering exercise. With experienced instructors more than eager to guide and coach you along your journey, get ready for an exhilarating takeaway that you'll definitely feel for days after!",
      ],
    },
  },
  {
    id: 'sydney',
    title: 'sydney',

    section1: {
      heading: 'The Best Pilates Classes in Sydney',
      description: [
        'The Best Pilates Classes in Sydney can now be easily found on Probuddy. Probuddy offers users a wide range of classes from different venues, including well-known studios and smaller boutique locations. ',
        'Each class is rated by previous participants to ensure that you are signing up for the highest quality Pilates experience available in Sydney. Try out a class today and revolutionize the way you approach your fitness goals!',
      ],
      linkText: 'Book A Pilates Class Now',
    },

    section2: {
      heading: 'Find Your Perfect Pilates Teacher in Sydney',
      description: [
        'Find Your Perfect Pilates Teacher in Sydney with Probuddy! Find the teacher who meets your needs and choose from a variety of instructors. Dive into your practice with educators who are knowledgeable, patient, and passionate about helping others reach their goals. ',
        'With Probuddy you can easily find teachers who specialize in individual Pilates classes that inspire personal growth and technical proficiency. Everyone’s journey is different – so end yours with a teacher who can guide you through it. Find Your Perfect Pilates Teacher in Sydney today with Probuddy!',
      ],
      linkText: 'Become A Pilates Pro',
    },

    classesForYou: {
      title: 'Book Your Pilates Classes in Sydney with Probuddy',
      description: [
        'Book your next Pilates session with Probuddy and experience the benefits of a total body workout. Our platform allows you to easily search, book, and pay for Pilates classes throughout Sydney.',
        'Our booking process is simple and straightforward, while also guaranteeing that you have access to the best selection and prices in town. Book your Pilates classes in Sydney with Probuddy and enjoy the convenience of online buying so you can focus on what really matters - your health and well-being.',
      ],
    },

    instructors: {
      title: 'Featured Top-Rated Pilates Instructor in Sydney',
      description: [
        "Introducing the Featured Top-Rated Pilates Instructor in Sydney from Probuddy! With an increasing demand for reliable professionals and the need for new, creative ways to keep engagement levels up and ensure that instructional quality remains high, we have sourced some of Sydney's best Pilates instructors and featured them on Probuddy.",
        'Each instructor has been rigorously vetted and given a detailed evaluation to guarantee delivery of effective lessons through hands-on guidance, clear demonstration, and excellent communication skills. Regardless of your skill level or experience, our Featured Top-Rated Pilates Instructor in Sydney is sure to provide a high quality course with an engaging atmosphere. Make sure not to miss out and book with our Featured Top-Rated Pilates Instructor in Sydney today!',
      ],
      instructorsData: instructorsData('Pilates'),
    },

    featured: {
      title: 'Popular Pilates Classes and Events in Sydney',
      description: [
        'Probuddy is your ultimate go-to when it comes to Popular Pilates Classes and Events in Sydney. Offering you a wide range of suitability to choose from according to your needs, Probuddy has emerged top of the line when it comes to pilates classes and events in Sydney. ',
        'From friendly pilates groups for seniors, to advanced sculpt and sweat courses with professional trainers, Probuddy has something for everyone! So join today and experience the thrill of Popular Pilates Classes and Events designed with you in mind.',
      ],
    },

    topRatedClasses: {
      title: 'Featured Pilates Classes in Sydney',
      description: [
        "Probuddy offers a wide selection of Featured Pilates Classes in Sydney, providing something for everyone no matter their skill level. From some of the city's best-known studios, to small classes with intimate settings, here you'll find a myriad of opportunities to flex your muscles and relax your mind.",
        'With experienced instructors on deck and an intuitive app that will guide you through the booking process, Probuddy is now the number one destination for fitness enthusiasts looking to connect with Featured Pilates Classes in Sydney. So what are you waiting for? Get started today and find the perfect class for you!',
      ],
    },
  },
  {
    id: 'new-york',
    title: 'new-york',

    section1: {
      heading: 'The Best Pilates Classes in New York',
      description: [
        'The Probuddy is your go to source for finding The Best Pilates Classes in New York City. The app provides real-time listings of Pilates classes around the city that are rated by experienced practitioners. The user reviews rate each class from beginner level instruction through to advanced classes and provide snapshots of the instructor, allowing you to make an informed decision about where to book your class. ',
        'Probuddy also makes managing your Pilates routine easier with its streamlined memberships and payment options which save the user time and money. With The Probuddy, enjoying The Best Pilates Classes in New York City is just a few clicks away!',
      ],
      linkText: 'Book A Pilates Class Now',
    },

    section2: {
      heading: 'Find Your Perfect Pilates Teacher in New York',
      description: [
        'With the help of Probuddy’s incredibly user-friendly platform, finding a Pilates teacher in New York has never been easier. Our intuitive interface allows you to quickly search for our database of teachers and view their qualifications, experience, rates, and availability.',
        'Find your perfect Pilates teacher with Probuddy at the touch of a button; you can even access contact information without leaving the site. Get moving with Probuddy and find your perfect teacher today!',
      ],
      linkText: 'Become A Pilates Pro',
    },

    classesForYou: {
      title: 'Book Your Pilates Classes in New York with Probuddy',
      description: [
        'Booking Pilates classes has never been easier! Probuddy is the perfect solution for all New York fitness enthusiasts, providing a convenient way to book and manage your Pilates classes at any time. With Probuddy, you can easily search for, book and track classes online in just a few simple steps.',
        'Our user-friendly system ensures an optimal experience throughout the booking process, providing you with quick and hassle-free access to quality Pilates classes in New York. Book your Pilates classes in New York today with Probuddy – because your health and wellness matters!',
      ],
    },

    instructors: {
      title: 'Featured Top-Rated Pilates Instructor in New York',
      description: [
        'The newest Probuddy feature offers users the chance to stay connected with Featured Top-Rated Pilates Instructors in New York. With many of these top-rated professionals having years of experience, this app makes finding the right teacher easier than ever.',
        'Get ready to embark on your journey with a Pilates Instructor who will challenge you and help you find balance and serenity. Check out the Featured Top-Rated Pilates Instructors in New York today with Probuddy!',
      ],
      instructorsData: instructorsData('Pilates'),
    },

    featured: {
      title: 'Popular Pilates Classes and Events in New York',
      description: [
        "If you're looking to stay on top of the fitness game in New York, then the Popular Pilates Classes and Events in New York featured on the Probuddy are just what you need. The classes provide an opportunity for yoga enthusiasts and fitness nuts alike to become a part of this hot new trend.",
        "Popular Pilates moves have been adjusted to be accessible to people of all skills levels so everyone can join in on the fun. And if large classes aren't your thing, you can take private sessions or small group sessions instead. With options ranging from beginner classes to advanced practice sessions, there's something for everyone at Popular Pilates Classes and Events in New York with Probuddy!",
      ],
    },

    topRatedClasses: {
      title: 'Featured Pilates Classes in New York',
      description: [
        "Are you wanting to learn Pilates and join Classes but don't know where to start? Look no further than Probuddy. We Featured this Pilates Classes in New York available to book within the Probuddy. As a leading sport classes booking system, we guarantee that each Featured Class has been vetted for quality so you can be assured of a brilliant learning experience. Start your journey in dynamic fitness today with Featured Pilates Classes in New York in the Probuddy App!",
      ],
    },
  },
  {
    id: 'london',
    title: 'london',

    section1: {
      heading: 'The Best Pilates Classes in London',
      description: [
        'The Best Pilates Classes in London are just a click away with Probuddy! Probuddy offers comprehensive, hassle-free and certified Pilates classes for all levels. From active sessions to restorative relaxation, Probuddy provides the best options in town with detailed live maps and locations.',
        'The system is easy to use, simply pick and choose your favorite class and book them right away. The classes have never been more accessible; no matter what your schedule or budget may be – take control of your fitness regime with The Best Pilates Classes in London through the Probuddy!',
      ],
      linkText: 'Book A Pilates Class Now',
    },

    section2: {
      heading: 'Find Your Perfect Pilates Teacher in London',
      description: [
        'With the help of Probuddy’s incredibly user-friendly platform, finding a Pilates teacher in London has never been easier. Our intuitive interface allows you to quickly search for our database of teachers and view their qualifications, experience, rates, and availability.',
        'Find your perfect Pilates teacher with Probuddy at the touch of a button; you can even access contact information without leaving the site. Get moving with Probuddy and find your perfect teacher today!',
      ],
      linkText: 'Become A Pilates Pro',
    },

    classesForYou: {
      title: 'Book Your Pilates Classes in London with Probuddy',
      description: [
        'Booking Pilates classes has never been easier! Probuddy is the perfect solution for all London fitness enthusiasts, providing a convenient way to book and manage your Pilates classes at any time. With Probuddy, you can easily search for, book and track classes online in just a few simple steps.',
        'Our user-friendly system ensures an optimal experience throughout the booking process, providing you with quick and hassle-free access to quality Pilates classes in London. Book your Pilates classes in London today with Probuddy – because your health and wellness matters!',
      ],
    },

    instructors: {
      title: 'Featured Top-Rated Pilates Instructor in London',
      description: [
        'The newest Probuddy feature offers users the chance to stay connected with Featured Top-Rated Pilates Instructors in London. With many of these top-rated professionals having years of experience, this app makes finding the right teacher easier than ever.',
        'Get ready to embark on your journey with a Pilates Instructor who will challenge you and help you find balance and serenity. Check out the Featured Top-Rated Pilates Instructors in London today with Probuddy!',
      ],
      instructorsData: instructorsData('Pilates'),
    },

    featured: {
      title: 'Popular Pilates Classes and Events in London',
      description: [
        "If you're looking to stay on top of the fitness game in London, then the Popular Pilates Classes and Events in London featured on the Probuddy are just what you need. The classes provide an opportunity for yoga enthusiasts and fitness nuts alike to become a part of this hot new trend.",
        "Popular Pilates moves have been adjusted to be accessible to people of all skills levels so everyone can join in on the fun. And if large classes aren't your thing, you can take private sessions or small group sessions instead. With options ranging from beginner classes to advanced practice sessions, there's something for everyone at Popular Pilates Classes and Events in London with Probuddy!",
      ],
    },

    topRatedClasses: {
      title: 'Featured Pilates Classes in London',
      description: [
        "Are you wanting to learn Pilates and join Classes but don't know where to start? Look no further than Probuddy. We Featured this Pilates Classes in London available to book within the Probuddy. As a leading sport classes booking system, we guarantee that each Featured Class has been vetted for quality so you can be assured of a brilliant learning experience. Start your journey in dynamic fitness today with Featured Pilates Classes in London in the Probuddy App!",
      ],
    },
  },
  {
    id: 'los-angeles',
    title: 'los-angeles',

    section1: {
      heading: 'The Best Pilates Classes in Los Angeles',
      description: [
        'Pilates classes in Los Angeles can be a great way to stay fit and healthy, but finding the best Pilates class for your needs can seem daunting. Luckily, with ProBuddy you have plenty of options for Pilates classes taught by certified professionals who are dedicated to helping you reach your Pilates goals.',
        "Whether you're looking for Pilates Pilates mat classes or private Pilates training sessions tailored to your unique fitness needs, ProBuddy has the resources to get you started in no time.",
        "With their broad selection of Pilates classes from beginner to advanced levels, you'll be sure to find the perfect Pilates class for your body and lifestyle. With the help of ProBuddy's expertise, you'll be well on your way to a happier, healthier life through Pilates!",
      ],
      linkText: 'Book A Pilates Class Now',
    },

    section2: {
      heading: 'Find Your Perfect Pilates Teacher in Los Angeles',
      description: [
        'Pilates is one of the best ways to stay active and fit, and Los Angeles offers some of the best Pilates classes around. With ProBuddy, you can quickly and easily find Pilates classes with certified Pilates instructors that are tailored for your individual needs.',
        "Whether you're a beginner or a pro looking for the next Pilates challenge, you'll undoubtedly find your perfect Pilates teacher with ProBuddy in Los Angeles. Get started today and experience the incredible benefits Pilates has to offer.",
      ],
      linkText: 'Become A Pilates Pro',
    },

    classesForYou: {
      title: 'Book Your Pilates Classes in Los Angeles with Probuddy',
      description: [
        'Pilates offers many benefits for mind, body, and soul alike. So why not take advantage of Pilates classes in Los Angeles with ProBuddy? At ProBuddy, we are committed to giving our clients the best Pilates experience out there by offering small class sizes and experienced instructors.',
        "Our Pilates classes in Los Angeles are tailored to each individual's unique goals and needs. Whether you'll be joining us for Pilates Mat classes or a personalized Pilates reformer session, you can rest assured that you'll be getting a high-quality Pilates experience backed by friendly and professional staff who go above and beyond to make every workout enjoyable. Book today at ProBuddy Pilates!",
      ],
    },

    instructors: {
      title: 'Featured Top-Rated Pilates Instructor in Los Angeles',
      description: [
        'Our Featured Top-Rated Pilates Instructor in Los Angeles on the Probuddy has a unique approach to helping students meet their health and wellness goals. With expertise in a range of styles, including vinyasa flow, restorative Pilates, meditation, pranayama breath work, alignment based Pilates and more, they offer classes perfect for beginners, intermediate and advanced yogis alike.',
        'Their personalized approach to teaching helps ensure you get the most out of each class and find peace and balance in all aspects of your life on and off your mat.',
      ],
      instructorsData: instructorsData('Pilates'),
    },

    featured: {
      title: 'Popular Pilates Classes and Events in Los Angeles',
      description: [
        "Popular Pilates classes and events in Los Angeles are easier than ever to access with Probuddy, an innovative platform allowing users to discover the best places in their city's Pilates scene. With Probuddy, you can browse a comprehensive list of local Pilates classes and events all in one place.",
        "Whether you're looking for indoor or outdoor classes, weekend retreats, or workshops, probuddy provides up-to-date information on these types of experiences that can engage yogis at any level – from beginner to advanced. Their filters help you find what's most suitable for your goals without having to sift through page after page of search results. So if you're looking for Popular Pilates Classes and Events in Los Angeles make sure to check out Probuddy!",
      ],
    },

    topRatedClasses: {
      title: 'Featured Pilates Classes in Los Angeles',
      description: [
        'Los Angeles is a great place to start Pilates classes. Thanks to the Probuddy, you can join Featured Pilates Classes in Los Angeles and explore a range of options near you. These classes offer a professional environment for beginners, as well as more advanced classes for experienced yogis.',
        "With personalized programs tailored to each individual's level and goals, there is something here for everyone — regardless of your skill level or experience with Pilates. You can even take advantage of beginners' classes or teacher-led retreats, so you can jumpstart your journey into health and wellness.",
      ],
    },
  },
  {
    id: 'melbourne',
    title: 'melbourne',

    section1: {
      heading: 'The Best Pilates Classes in Melbourne',
      description: [
        'The Best Pilates Classes in Melbourne can now be easily found on Probuddy. Probuddy offers users a wide range of classes from different venues, including well-known studios and smaller boutique locations.',
        'Each class is rated by previous participants to ensure that you are signing up for the highest quality Pilates experience available in Melbourne. Try out a class today and revolutionize the way you approach your fitness goals!',
      ],
      linkText: 'Book A Pilates Class Now',
    },

    section2: {
      heading: 'Find Your Perfect Pilates Teacher in Melbourne',
      description: [
        'Find Your Perfect Pilates Teacher in Melbourne with Probuddy! Find the teacher who meets your needs and choose from a variety of instructors. Dive into your practice with educators who are knowledgeable, patient, and passionate about helping others reach their goals.',
        'With Probuddy you can easily find teachers who specialize in individual Pilates classes that inspire personal growth and technical proficiency. Everyone’s journey is different – so end yours with a teacher who can guide you through it. Find Your Perfect Pilates Teacher in Melbourne today with Probuddy!',
      ],
      linkText: 'Become A Pilates Pro',
    },

    classesForYou: {
      title: 'Book Your Pilates Classes in Melbourne with Probuddy',
      description: [
        'Book your next Pilates session with Probuddy and experience the benefits of a total body workout. Our platform allows you to easily search, book, and pay for Pilates classes throughout Melbourne.',
        'Our booking process is simple and straightforward, while also guaranteeing that you have access to the best selection and prices in town. Book your Pilates classes in Melbourne with Probuddy and enjoy the convenience of online buying so you can focus on what really matters - your health and well-being.',
      ],
    },

    instructors: {
      title: 'Featured Top-Rated Pilates Instructor in Melbourne',
      description: [
        "Introducing the Featured Top-Rated Pilates Instructor in Melbourne from Probuddy! With an increasing demand for reliable professionals and the need for new, creative ways to keep engagement levels up and ensure that instructional quality remains high, we have sourced some of Melbourne's best Pilates instructors and featured them on Probuddy.",
        'Each instructor has been rigorously vetted and given a detailed evaluation to guarantee delivery of effective lessons through hands-on guidance, clear demonstration, and excellent communication skills. Regardless of your skill level or experience, our Featured Top-Rated Pilates Instructor in Melbourne is sure to provide a high quality course with an engaging atmosphere. Make sure not to miss out and book with our Featured Top-Rated Pilates Instructor in Melbourne today!',
      ],
      instructorsData: instructorsData('Pilates'),
    },

    featured: {
      title: 'Popular Pilates Classes and Events in Melbourne',
      description: [
        'Probuddy is your ultimate go-to when it comes to Popular Pilates Classes and Events in Melbourne. Offering you a wide range of suitability to choose from according to your needs, Probuddy has emerged top of the line when it comes to pilates classes and events in Melbourne.',
        'From friendly pilates groups for seniors, to advanced sculpt and sweat courses with professional trainers, Probuddy has something for everyone! So join today and experience the thrill of Popular Pilates Classes and Events designed with you in mind.',
      ],
    },

    topRatedClasses: {
      title: 'Featured Pilates Classes in Melbourne',
      description: [
        "Probuddy offers a wide selection of Featured Pilates Classes in Melbourne, providing something for everyone no matter their skill level. From some of the city's best-known studios, to small classes with intimate settings, here you'll find a myriad of opportunities to flex your muscles and relax your mind.",
        'With experienced instructors on deck and an intuitive app that will guide you through the booking process, Probuddy is now the number one destination for fitness enthusiasts looking to connect with Featured Pilates Classes in Melbourne. So what are you waiting for? Get started today and find the perfect class for you!',
      ],
    },
  },
  {
    id: 'chicago',
    title: 'chicago',

    section1: {
      heading: 'The Best Pilates Classes in Chicago',
      description: [
        'The Probuddy makes it easier than ever to find The Best Pilates Classes in Chicago. The classes we recommend provide users with the perfect strength-building workout, and are tailored to fit every skill level. Our highly trained instructors guarantee an effective but enjoyable experience for each individual participant.',
        "The schedules of these classes are flexible and can accommodate a range of busy lifestyles, so you're sure to find one that fits your routine. With The Best Pilates Classes in Chicago, you'll be able to unlock beneficial results as quickly as possible. Join us today and begin on your journey towards holistic wellness!",
      ],
      linkText: 'Book A Pilates Class Now',
    },

    section2: {
      heading: 'Find Your Perfect Pilates Teacher in Chicago',
      description: [
        "If you're looking for a Pilates teacher in Chicago, the Probuddy has you covered. Find your perfect match with our intuitive search tools, or use the reviews from other users to narrow down your options. With a huge selection of some of the best teachers around,",
        "Probuddy guarantees an experience that leaves you feeling energized and balanced. Whether it's a single session or recurring class structure, we have the perfect fit for everyone who wants to get better at Pilates! Find your ideal yoga instructor today and see how it can benefit your life!",
      ],
      linkText: 'Become A Pilates Pro',
    },

    classesForYou: {
      title: 'Book Your Pilates Classes in Chicago with Probuddy',
      description: [
        'Booking the perfect Pilates Class in Chicago is now easier than ever thanks to Probuddy. Probuddy offers a wide selection of classes, instructors and locations for you to choose from. With options for beginner and experienced practitioners alike, as well as group or private sessions, Probuddy provides all of your Pilates needs in one convenient place.',
        'Booking your class with Probuddy guarantees a quality experience backed by stellar customer service. Book today and start enjoying the many benefits that Pilates offers.',
      ],
    },

    instructors: {
      title: 'Featured Top-Rated Pilates Instructor in Chicago',
      description: [
        'Featured Top-Rated Pilates Instructor in Chicago on Probuddy is the go-to source for obtaining one of the most enjoyable and beneficial forms of physical exercise. Our instructor offers a unique and creative approach to traditional Pilates exercises, offering an immersive learning experience through a variety of poses, stretches, and more in a fun and exciting setting.',
        'From beginner level classes all the way up to advanced trainings, Featured Top-Rated Pilates Instructor in Chicago boasts some of the best fitness instruction available in the city, designed to help you reach your goals quickly and efficiently. Whether you’re looking for a complete workout or specific skillset, Featured Top-Rated Pilates Instructor in Chicago has something for everyone on Probuddy!',
      ],
      instructorsData: instructorsData('Pilates'),
    },

    featured: {
      title: 'Popular Pilates Classes and Events in Chicago',
      description: [
        'Are you looking for a popular Pilates class or event to attend in the Chicago area? If so, Probuddy is your go-to source. Popular Pilates classes and events in Chicago can be found on the seamless Probuddy platform.',
        'With the easy search filters and comprehensive information, users are sure to find just what they are looking for without having to do any of the heavy lifting. Whether you want to take a stretching Pilates session or join a yoga retreat, using Probuddy makes it easy to find what you need.',
      ],
    },

    topRatedClasses: {
      title: 'Featured Pilates Classes in Chicago',
      description: [
        "If you're looking to add Pilates into your fitness routine, Probuddy is the place to go! Our Featured Pilates Classes in Chicago offer a variety of classes ranging from beginner to advanced level. Every one of our classes is led by top educators in the field and every group or private class provides tailored instruction focusing on proper form and alignment.",
        "You'll leave each session feeling refreshed, invigorated and energized! Take the stress out of your day-to-day with Featured Pilates Classes in Chicago – book a class with Probuddy today for an unforgettable experience!",
      ],
    },
  },
  {
    id: 'seattle',
    title: 'seattle',

    section1: {
      heading: 'The Best Pilates Classes in Seattle',
      description: [
        'The Best Pilates Classes in Seattle can be easily found with the Probuddy! This platform offers a variety of fantastic classes that are guaranteed to help you build strength, gain flexibility and achieve overall better health. The program also caters to your specific needs and goals based on your activity level with classes designed for beginners, slow or fast paced sessions and even workshops delivered by knowledgeable professionals.',
        'Probuddy can also recommend qualified instructors for those who require specialised coaching. With the selection of the Best Pilates Classes in Seattle on offer through Probudd, you no longer have to worry about getting stuck in an overcrowded class that does not meet your expectations – sign up now to start your journey towards a healthier life!',
      ],
      linkText: 'Book A Pilates Class Now',
    },

    section2: {
      heading: 'Find Your Perfect Pilates Teacher in Seattle',
      description: [
        "Are you looking for Pilates teachers in Seattle? With Probuddy, it's never been easier to find your perfect teacher! The platform allows you to browse through from a wide range of exciting and experienced teachers who specialise in various methods of Pilates.",
        'With different class selections, find one that suits your need and budget. Set up a session at a time and place of your convenience - no more late-night searches online for studios or schools near you. Get Pilates training now with Probuddy App and take charge of your health and wellbeing today!',
      ],
      linkText: 'Become A Pilates Pro',
    },

    classesForYou: {
      title: 'Book Your Pilates Classes in Seattle with Probuddy',
      description: [
        'Booking Pilates classes in Seattle has never been easier than with Probuddy! Book your classes quickly and easily on the user-friendly platform. Get to know certified trainers, their qualifications and reviews before committing to a class, or join one of the probuddy community classes -- all designed for different levels and age groups.',
        'With Probuddy, you can find the perfect Pilates class for your needs, from beginner to advanced! Get fit while being guided by professionals in one of the most vibrant cities in Asia. Book with Probuddy today!',
      ],
    },

    instructors: {
      title: 'Featured Top-Rated Pilates Instructor in Seattle',
      description: [
        'Probuddy has featured a top-rated Pilates instructor in Seattle to help those looking for accessible and effective ways to stay fit during this challenging time. This instructor is well-known for their comprehensive classes that cater to both the physical and lifestyle needs of students with an emphasis on flowing moves.',
        'As this renowned instructor guides their students through Pilates exercises, they are also educating them on key principles such as building core strength, regaining muscle balance, and improving posture. With expert guidance, high-quality support practices, and optimal results, the Featured Top-Rated Pilates Instructor in Seattle on Probuddy is sure to assist you in reaching your wellness goals.',
      ],
      instructorsData: instructorsData('Pilates'),
    },

    featured: {
      title: 'Popular Pilates Classes and Events in Seattle',
      description: [
        'Seattle is becoming more health-conscious, and Probuddy has risen to meet the demand with its Popular Pilates Classes and Events. Whether you’re a fan of traditional Pilates or looking for something unique, they have it all! With classes on flexibility, strength, posture and breathing techniques, their enthusiastic instructors will guide you through the lessons in an exciting learning environment.',
        'Besides the regular classes and one-on-one sessions, Probuddy also offers fun events like health retreats aimed at helping individuals get fit while having a great time. Visit Probuddy and see what Popular Pilates Classes and Events Seattle has to offer!',
      ],
    },

    topRatedClasses: {
      title: 'Featured Pilates Classes in Seattle',
      description: [
        "If you're interested in bringing your Pilates game to the next level, look no further than Probuddy. This Featured Pilates classes in Seattle offer a great variety of options that cater to all sorts of experience levels and needs.",
        "ProBuddy provides the perfect platform for you to learn and explore the different practice styles of this empowering exercise. With experienced instructors more than eager to guide and coach you along your journey, get ready for an exhilarating takeaway that you'll definitely feel for days after!",
      ],
    },
  },
  {
    id: 'manchester',
    title: 'manchester',

    section1: {
      heading: 'The Best Pilates Classes in Manchester',
      description: [
        'Probuddy offers The Best Pilates Classes in Manchester! Our experienced instructors will help you to develop a program tailored to your needs, with classes that are suitable for all ages and abilities. Our staff are knowledgeable, friendly and trained to ensure that each class planned encourages balance, suppleness and stability.',
        "With Probuddy you can trust that you're in safe hands when it comes to Pilates classes; our team have the expertise needed to provide enjoyable classes for both beginners, who will be guided through the basics of Pilates, as well as those more familiar with the practice. The Best Pilates Classes in Manchester by Probuddy!",
      ],
      linkText: 'Book A Pilates Class Now',
    },

    section2: {
      heading: 'Find Your Perfect Pilates Teacher in Manchester',
      description: [
        "The Probuddy marketplace offers some of the best Pilates classes in Manchester. The experienced Pilates teachers listed on the website are hand-picked to bring you high quality, professional instruction at an affordable price. With a range of schedules and classes tailored to your individual needs, you're sure to find your perfect Pilates teacher with Probuddy.",
        "The platform also includes reviews, ratings and other helpful information to ensure that you have all the necessary facts before signing up for a class. Whether you're new to this type of exercise or a seasoned veteran, Probuddy will help you find the ideal instructor to help you reach your fitness goals.",
      ],
      linkText: 'Become A Pilates Pro',
    },

    classesForYou: {
      title: 'Book Your Pilates Classes in Manchester with Probuddy',
      description: [
        'Booking your pilates classes with Probuddy is an easy and rewarding experience. Located in Manchester, Probuddy offers an extensive selection of top-rated pilates classes that are suitable for any skill level. Not only do they provide a variety of class types, such as restorative, core and power, but they also have highly experienced instructors who are dedicated to helping you reach your fitness goals. ',
        'Booking online is simple and their staff will be available to answer any questions you may have about the services that they offer. Book your Pilates classes in Manchester today with Probuddy and step closer to achieving optimal health!',
      ],
    },

    instructors: {
      title: 'Featured Top-Rated Pilates Instructor in Manchester',
      description: [
        "If you're looking for a Featured Top-Rated Pilates Instructor in Manchester, then your search ends at Probuddy marketplace. Our marketplace is the destination of choice when it comes to finding quality Pilates instructors. With an extensive collection of Featured Top-Rated instructors, we are sure that you will find an instructor that fits your needs.",
        'All our Featured Top-Rated Instructors have undergone rigorous training and can offer you the best Pilates teachings out there. Join us today and experience a top-notch Pilates session with one of our Featured Top Rated Instructors in Manchester!',
      ],
      instructorsData: instructorsData('Pilates'),
    },

    featured: {
      title: 'Popular Pilates Classes and Events in Manchester',
      description: [
        'Pilates classes are becoming increasingly popular among Manchester residents, due to their wide array of health benefits. Probuddy Marketplace is where many local people go for exciting Pilates-related experiences. On this online website, you can easily find Popular Pilates Classes and Events in Manchester, offering something different from the usual weekday sessions.',
        "Whether you wish to join a group fitness class or develop your own personal self-care routine, Probuddy Marketplace has top-tier programs and events that cater to all levels of fitness and commitment. What’s more, by signing up on Probuddy Marketplace's website, you’ll be able to stay up to date on the latest Pilates initiatives available in the city.",
      ],
    },

    topRatedClasses: {
      title: 'Featured Pilates Classes in Manchester',
      description: [
        "If you're interested in bringing your Pilates game to the next level, look no further than Probuddy. This Featured Pilates classes in Manchester offer a great variety of options that cater to all sorts of experience levels and needs.",
        "ProBuddy provides the perfect platform for you to learn and explore the different practice styles of this empowering exercise. With experienced instructors more than eager to guide and coach you along your journey, get ready for an exhilarating takeaway that you'll definitely feel for days after!",
      ],
    },
  },
  {
    id: 'san-francisco',
    title: 'san-francisco',

    section1: {
      heading: 'The Best Pilates Classes in San Francisco',
      description: [
        'The Best Pilates Classes in San Francisco can be easily found with the Probuddy! This platform offers a variety of fantastic classes that are guaranteed to help you build strength, gain flexibility and achieve overall better health. The program also caters to your specific needs and goals based on your activity level with classes designed for beginners, slow or fast paced sessions and even workshops delivered by knowledgeable professionals.',
        'Probuddy can also recommend qualified instructors for those who require specialised coaching. With the selection of the Best Pilates Classes in San Francisco on offer through Probudd, you no longer have to worry about getting stuck in an overcrowded class that does not meet your expectations – sign up now to start your journey towards a healthier life!',
      ],
      linkText: 'Book A Pilates Class Now',
    },

    section2: {
      heading: 'Find Your Perfect Pilates Teacher in San Francisco',
      description: [
        "Are you looking for Pilates teachers in San Francisco? With Probuddy, it's never been easier to find your perfect teacher! The platform allows you to browse through from a wide range of exciting and experienced teachers who specialise in various methods of Pilates.",
        'With different class selections, find one that suits your need and budget. Set up a session at a time and place of your convenience - no more late-night searches online for studios or schools near you. Get Pilates training now with Probuddy App and take charge of your health and wellbeing today!',
      ],
      linkText: 'Become A Pilates Pro',
    },

    classesForYou: {
      title: 'Book Your Pilates Classes in San Francisco with Probuddy',
      description: [
        'Booking Pilates classes in San Francisco has never been easier than with Probuddy! Book your classes quickly and easily on the user-friendly platform. Get to know certified trainers, their qualifications and reviews before committing to a class, or join one of the probuddy community classes -- all designed for different levels and age groups.',
        'With Probuddy, you can find the perfect Pilates class for your needs, from beginner to advanced! Get fit while being guided by professionals in one of the most vibrant cities in Asia. Book with Probuddy today!',
      ],
    },

    instructors: {
      title: 'Featured Top-Rated Pilates Instructor in San Francisco',
      description: [
        'Probuddy has featured a top-rated Pilates instructor in San Francisco to help those looking for accessible and effective ways to stay fit during this challenging time. This instructor is well-known for their comprehensive classes that cater to both the physical and lifestyle needs of students with an emphasis on flowing moves.',
        'As this renowned instructor guides their students through Pilates exercises, they are also educating them on key principles such as building core strength, regaining muscle balance, and improving posture. With expert guidance, high-quality support practices, and optimal results, the Featured Top-Rated Pilates Instructor in San Francisco on Probuddy is sure to assist you in reaching your wellness goals.',
      ],
      instructorsData: instructorsData('Pilates'),
    },

    featured: {
      title: 'Popular Pilates Classes and Events in San Francisco',
      description: [
        'San Francisco is becoming more health-conscious, and Probuddy has risen to meet the demand with its Popular Pilates Classes and Events. Whether you’re a fan of traditional Pilates or looking for something unique, they have it all! With classes on flexibility, strength, posture and breathing techniques, their enthusiastic instructors will guide you through the lessons in an exciting learning environment.',
        'Besides the regular classes and one-on-one sessions, Probuddy also offers fun events like health retreats aimed at helping individuals get fit while having a great time. Visit Probuddy and see what Popular Pilates Classes and Events San Francisco has to offer!',
      ],
    },

    topRatedClasses: {
      title: 'Featured Pilates Classes in San Francisco',
      description: [
        "If you're interested in bringing your Pilates game to the next level, look no further than Probuddy. This Featured Pilates classes in San Francisco offer a great variety of options that cater to all sorts of experience levels and needs.",
        "ProBuddy provides the perfect platform for you to learn and explore the different practice styles of this empowering exercise. With experienced instructors more than eager to guide and coach you along your journey, get ready for an exhilarating takeaway that you'll definitely feel for days after!",
      ],
    },
  },
  {
    id: 'houston',
    title: 'houston',

    section1: {
      heading: 'The Best Pilates Classes in Houston',
      description: [
        'The Best Pilates Classes in Houston can be found in the Probuddy. The app allows you to easily find classes with experienced instructors that offer everything from Vinyasa Yoga to Restorative Yoga. The user friendly interface guides you quickly to a variety of classes, so that even busy professionals can take some time out of their day for a relaxing stretching session or an energizing flow class.',
        'The Pilates community in Houston is supportive and inviting, helping yogis of all experience levels improve their practice and cultivate a healthier lifestyle. Join hundreds of like-minded people on the path to discovering the best version of themselves with The Best Yoga Classes in Houston through Probuddy App!',
      ],
      linkText: 'Book A Pilates Class Now',
    },

    section2: {
      heading: 'Find Your Perfect Pilates Teacher in Houston',
      description: [
        "Pilates classes in Houston have never been easier to find than with ProBuddy! Whether you're a Pilates veteran or a beginner, ProBuddy's Pilates coaches in Houston are the perfect people to get you on the right track.",
        "They'll ensure that all of your Pilates exercises are done safely and effectively so you can experience the full range of benefits Pilates has to offer. With experienced Pilates teachers and studios all across Houston, it has never been simpler to find the perfect Pilates teacher for your needs.",
        'Take some time to browse our coaches today and discover why Pilates is such a popular form of exercise for everyone!',
      ],
      linkText: 'Become A Pilates Pro',
    },

    classesForYou: {
      title: 'Book Your Pilates Classes in Houston with Probuddy',
      description: [
        "Pilates classes in Houston are now more accessible than ever thanks to ProBuddy! With one easy and convenient booking system, you'll be able to secure the Pilates class of your choice quickly and easily.",
        "You can check the schedule for your favourite Pilates studio and even book short-term or long-term classes with just a few clicks. With unlimited Pilates classes in Houston at your fingertips, it's never been easier to stay on track with all of your Pilates goals! Plus, access expert Pilates instruction quickly and easily from wherever you are.",
        'Booking Pilates classes has never been simpler or more efficient than with ProBuddy – get started today!',
      ],
    },

    instructors: {
      title: 'Featured Top-Rated Pilates Instructor in Houston',
      description: [
        "ProBuddy Pilates offers Houstonians the opportunity to take classes with one of the city's best Pilates instructors, featured in numerous magazines and newspaper reviews. The instructor draws from many years of Pilates knowledge and experience to make Pilates classes exciting and rewarding for ever level.",
        'ProBuddy Pilates puts individual goals at the top of its priority list for both client satisfaction and successful results. Not only does this instructor provide a full Pilates program but also offers different Pilates adaptations that fit individuals needs while still meeting the same Pilates standards.',
        'With ProBuddy Pilates, you have guaranteed access to this top-rated pilates instructor who is committed to help reaching your fitness goals!',
      ],
      instructorsData: instructorsData('Pilates'),
    },

    featured: {
      title: 'Popular Pilates Classes and Events in Houston',
      description: [
        'Pilates is a popular form of exercise which improves strength, flexibility, balance and coordination. Pilates classes in Houston are becoming increasingly popular due to the convenient access to a range of studios and instructors offering Pilates programs.',
        'Pilates classes available through ProBuddy offer students the opportunity to get fit while having fun in an environment that is comfortable, motivating and stress-free. In addition to Pilates group classes, there are Pilates retreats and events hosted throughout the year in Houston that help build community spirit, team bonding and support knowledge sharing around Pilates activities.',
        "With ProBuddy's wide range of Pilates options, it can be hard to choose just one event – so why not try them all?",
      ],
    },

    topRatedClasses: {
      title: 'Featured Pilates Classes in Houston',
      description: [
        'Featured Pilates Classes in Houston, offered by the Probuddy, offer a huge variety of styles and methods to suit your individual goals. Whether you are looking for something basic and meditative, a challenging workout session or something in between, Houston classes will suit you perfectly.',
        'Depending on what works best for you, choose classes that concentrate on breath-work, relaxation, postures or energy-level balancing - all of which are crafted by experienced and certified professionals. Unlock the power of yoga in Houston with Probuddy today.',
      ],
    },
  },
];

// export const golfCityData = [
//   {
//     id: 'singapore',
//     title: 'singapore',

//     section1: {
//       heading: 'Top Golf Classes in Singapore',
//       description: [
//         'If golf has been on your to-do list but you have no idea where to start, then Top Golf Classes in Singapore by Probuddy is the course for you. This intense 6-week program provides everything from fundamental concepts and best practices to step-by-step instructions through a balanced combination of lessons and hands-on practice.',
//         'With experienced professionals in the field as instructors, Top Golf Classes offers participants the chance to learn at their own pace in a comprehensive environment. Plus, with fun activities such as chipping, putting and swing competitions included, Top Golf Classes can give players the impetus they need to take their game to the next level.',
//       ],
//       linkText: 'Book A Golf Class Now',
//     },

//     section2: {
//       heading: 'Discover the Ideal Golf Instructor in Singapore',
//       description: [
//         "Golf can be a hugely rewarding sport to learn and no matter where you are playing in the world, having the right instructor is key to improving your golf game. If you're looking for the ideal golf instructor in Singapore, then Top Golf Classes by Probuddy should be your first choice.",
//         "Probuddy prides itself on providing quality and experienced instructors who are passionate about teaching the fundamentals of golf to all levels of golfers. As well as this, Top Golf Classes by Probuddy provides lessons designed with every player's needs in mind that include customised training packages based on the individual golfer's skill set and areas of improvement.",
//         'With Top Golf Classes by Probuddy, you can be sure that you are getting the best instruction available in Singapore so you can focus on honing your skills and achieving your golfing goals!',
//       ],
//       linkText: 'Become A Golf Pro',
//     },

//     classesForYou: {
//       title: 'Reserve Your Golf Sessions in Singapore Through Probuddy',
//       description: [
//         'If you are thinking of mastering the game of golf and want to learn more in Singapore, then Probuddy is the place for you. Top golf classes in Singapore by trusted professionals can be easily booked through Probuddy.',
//         "From one-on-one sessions to tutorials for groups, Probuddy has everything that you need for perfecting your golf playing skills. Book these reserve sessions with ease and enjoy your time playing and learning some amazing new moves. Don't wait if golfing is your passion; book today and let Probuddy help you express it on the green!",
//       ],
//     },

//     instructors: {
//       title: 'Highlighted Exceptional Golf Teacher in Singapore with Top Ratings',
//       description: [
//         "Probuddy, a platform for connecting teaching professionals with those looking to learn, has highlighted one exceptional golf teacher in Singapore: Jamal. Top reviews from some of his past students demonstrate why he is considered one of the top golf teachers in the country, and with thousands of satisfied customers under his belt, it's no surprise that Probuddy has given him the highest rating - five stars.",
//         "Through Probuddy's courses, Jamal offers his expertise as a PGA-certified coach, sharing valuable practice tips along the way to help participants improve their game. With Jamal at the helm, those looking to hone their skills have access not only to some of the Top Golf Classes in Singapore but also to personalized coaching and mentorship.",
//       ],
//       instructorsData: instructorsData('Golf'),
//     },

//     featured: {
//       title: 'In search of Golf Classes in Singapore? Allow us to assist.',
//       description: [
//         "If you're planning to sign up for golf classes in Singapore, Probuddy is here to help! Singapore has a number of institutions that conduct excellent golf classes, but at Probuddy, we've put together the Top Golf Classes in Singapore.",
//         "From individual private tutoring sessions and on-course practice drills to group sessions with highly experienced coaches - choosing Probuddy's Top Golf Classes would be beneficial for individuals of all levels. Whether you're looking to learn basic fundamentals or refine more advanced techniques, we'll have the perfect class for you!",
//       ],
//     },

//     topRatedClasses: {
//       title: 'Featured Golf Classes in Singapore',
//       description: [
//         "If you're interested in bringing your Golf game to the next level, look no further than Probuddy. This Featured Golf classes in Singapore offer a great variety of options that cater to all sorts of experience levels and needs.",
//         "ProBuddy provides the perfect platform for you to learn and explore the different practice styles of this empowering exercise. With experienced instructors more than eager to guide and coach you along your journey, get ready for an exhilarating takeaway that you'll definitely feel for days after!",
//       ],
//     },
//   },
//   {
//     id: 'sydney',
//     title: 'sydney',

//     section1: {
//       heading: 'The Best Golf Classes in Sydney',
//       description: [
//         "Golf is a beloved pastime of Sydneysiders, and so it's no wonder there has been a surge in the number of Golf Classes available. Whether you're looking to improve your handicap, or just have some fun on the green, Probuddy in Sydney has you covered.",
//         'With Golf Classes led by PGA Golf Professionals that provide tailored instruction plans according to each individual’s needs and goals, you can be sure to make the most out of your Golf experience while enjoying the beautiful Golf Courses across Sydney.',
//         'From beginner classes to advanced classes, there is something for everyone at Probuddy Golf Classes in Sydney! Book today and experience Golf like never before!',
//       ],
//       linkText: 'Book A Golf Class Now',
//     },

//     section2: {
//       heading: 'Discover the Ideal Golf Instructor in Sydney',
//       description: [
//         'Golf is a popular pastime for many Sydneysiders, so why not take your game to the next level? With Golf Classes in Sydney by Probuddy, you can now find the perfect golf coach to help you reach new heights in your game.',
//         'Probuddy handpicks knowledgeable and passionate coaches from around Sydney to deliver exceptional Golf Classes tailored to your specific needs. Whether you are a beginner or an experienced player, the team at Probuddy will make sure that you have access to all of the necessary knowledge, skills and equipment to take your game to a whole new level.',
//         "So if you are looking for your perfect Golf Coach in Sydney don't hesitate – join Golf Classes in Sydney by Probuddy today!",
//       ],
//       linkText: 'Become A Golf Pro',
//     },

//     classesForYou: {
//       title: 'Reserve Your Golf Sessions in Sydney Through Probuddy',
//       description: [
//         "Golf is a great way to have some fun and stay active. Whether you're a beginner hitting the links for the first time or an experienced golfer trying to sharpen up your skills, Golf Classes in Sydney by Probuddy can provide you with all the education and practice that you need.",
//         'Our experienced instructors make sure that each Golf Class provides players of all levels with an enjoyable experience that is designed to aid them in reaching their goals. Golf classes cover all the basics, from fundamentals to more advanced techniques so that you can get out on the course as soon as possible with confidence and pride. Book now to join Golf Classes in Sydney by Probuddy and improve your game!',
//       ],
//     },

//     instructors: {
//       title: 'Highlighted Exceptional Golf Teacher in Sydney with Top Ratings',
//       description: [
//         "Probuddy, a platform for connecting teaching professionals with those looking to learn, has highlighted one exceptional golf teacher in Sydney: Jamal. Top reviews from some of his past students demonstrate why he is considered one of the top golf teachers in the country, and with thousands of satisfied customers under his belt, it's no surprise that Probuddy has given him the highest rating - five stars.",
//         "Through Probuddy's courses, Jamal offers his expertise as a PGA-certified coach, sharing valuable practice tips along the way to help participants improve their game. With Jamal at the helm, those looking to hone their skills have access not only to some of the Top Golf Classes in Sydney but also to personalized coaching and mentorship.",
//       ],
//       instructorsData: instructorsData('Golf'),
//     },

//     featured: {
//       title: 'In search of Golf Classes in Sydney? Allow us to assist.',
//       description: [
//         "If you're planning to sign up for golf classes in Sydney, Probuddy is here to help! Sydney has a number of institutions that conduct excellent golf classes, but at Probuddy, we've put together the Top Golf Classes in Sydney.",
//         "From individual private tutoring sessions and on-course practice drills to group sessions with highly experienced coaches - choosing Probuddy's Top Golf Classes would be beneficial for individuals of all levels. Whether you're looking to learn basic fundamentals or refine more advanced techniques, we'll have the perfect class for you!",
//       ],
//     },

//     topRatedClasses: {
//       title: 'Featured Golf Classes in Sydney',
//       description: [
//         "If you're interested in bringing your Golf game to the next level, look no further than Probuddy. This Featured Golf classes in Sydney offer a great variety of options that cater to all sorts of experience levels and needs.",
//         "ProBuddy provides the perfect platform for you to learn and explore the different practice styles of this empowering exercise. With experienced instructors more than eager to guide and coach you along your journey, get ready for an exhilarating takeaway that you'll definitely feel for days after!",
//       ],
//     },
//   },
//   {
//     id: 'new-york',
//     title: 'new-york',

//     section1: {
//       heading: 'The Best Golf Classes in New York',
//       description: [
//         "Golfing is one of the most popular pastimes in America, and it's no wonder why New Yorkers have taken to it. Golfers across the city are eager to take their game to the next level, and with Probuddy Golf's top-notch golf classes offered throughout New York, they can finally do so.",
//         'Probuddy Golf provides seasoned golf professionals to provide personalized instruction for all skill levels, ensuring that everyone from amateurs to competitive players can experience improvement on their game.',
//         "With classes also conveniently located in parts of Brooklyn and Queens, there are more opportunities than ever before to strengthen your skills as a golfer in New York. So if you're looking to sharpen your technique and refine your swing, check out Probuddy Golf's amazing golf classes today!",
//       ],
//       linkText: 'Book A Golf Class Now',
//     },

//     section2: {
//       heading: 'Discover the Ideal Golf Instructor in New York',
//       description: [
//         'Golf classes in New York by Probuddy offer the ideal golf instructor for all your needs. Golfers of any age or skill level can find a qualified coach to fit their individual needs, as our coaching staff are composed of certified and experienced professionals.',
//         'We believe in providing maximum benefits to our clients with minimum effort; hence, all our golf classes are conducted online with lessons tailored to suit your specific requirements. Our certified coaches will guide you carefully through the fundamentals and help perfect your technique while having fun! ',
//         'Not only do they provide quality instruction with top-notch amenities but also make sure that each student feels comfortable, taking pride in their achievements no matter where they begin from. Try Probuddy for an unforgettable golfing experience and discover the ideal golf instructor in New York!',
//       ],
//       linkText: 'Become A Golf Pro',
//     },

//     classesForYou: {
//       title: 'Reserve Your Golf Sessions in New York Through Probuddy',
//       description: [
//         'Golfing in New York City has always been a treat. With picturesque courses located across the city, who wouldn’t want to spend the perfect day practicing one of the most popular sports around? Well, Probuddy is now making it easier for any golfer to receive professional Golf coaching in New York.',
//         'Reserve your Golf sessions with expert Golf coaches through Probuddy and experience Golf with a different level of professionalism. Benefit from custom tailored classes that are available throughout the week and get step-by-step guidance in improving your Golf swing! Experience Golf like never before and book your classes today.',
//       ],
//     },

//     instructors: {
//       title: 'Highlighted Exceptional Golf Teacher in New York with Top Ratings',
//       description: [
//         "Probuddy, a platform for connecting teaching professionals with those looking to learn, has highlighted one exceptional golf teacher in New York: Jamal. Top reviews from some of his past students demonstrate why he is considered one of the top golf teachers in the country, and with thousands of satisfied customers under his belt, it's no surprise that Probuddy has given him the highest rating - five stars.",
//         "Through Probuddy's courses, Jamal offers his expertise as a PGA-certified coach, sharing valuable practice tips along the way to help participants improve their game. With Jamal at the helm, those looking to hone their skills have access not only to some of the Top Golf Classes in New York but also to personalized coaching and mentorship.",
//       ],
//       instructorsData: instructorsData('Golf'),
//     },

//     featured: {
//       title: 'In search of Golf Classes in New York? Allow us to assist.',
//       description: [
//         'Golfing is a great way to stay active outdoors, and with Probuddy you can easily book Golf Classes in New York. Our Golf Classes are perfect for beginners and experienced players alike, giving you access to professional instructors and different course styles in the city.',
//         "Whether you're looking for one-on-one lessons or group classes, we have it all - making it quick and easy for you to master your golf game! With our Golf Classes in New York, you can conveniently practice your swing any day of the week. Enjoy improved performance as well as an unforgettable golfing experience. Book your Golf Classes in New York today with Probuddy - stay active and have fun on the green!",
//       ],
//     },

//     topRatedClasses: {
//       title: 'Featured Golf Classes in New York',
//       description: [
//         "If you're interested in bringing your Golf game to the next level, look no further than Probuddy. This Featured Golf classes in New York offer a great variety of options that cater to all sorts of experience levels and needs.",
//         "ProBuddy provides the perfect platform for you to learn and explore the different practice styles of this empowering exercise. With experienced instructors more than eager to guide and coach you along your journey, get ready for an exhilarating takeaway that you'll definitely feel for days after!",
//       ],
//     },
//   },
//   {
//     id: 'london',
//     title: 'london',

//     section1: {
//       heading: 'Best Golf Classes in London',
//       description: [
//         'Golfing is growing in popularity in London and for those looking to take their game up a notch, the best way to do it is by attending Golf Classes in London. Probuddy provides some of the best Golf Classes in London, offering experienced pros to provide high-quality instruction for players of all levels from novice to advanced.',
//         "The classes are taught on unique grounds across the city and conveniently available both online and in person. Golfers can look forward to learning proper fundamentals like swing posture and club grip, along with advanced techniques for driving, putting and more. With Probuddy's Golf Classes, golfers are sure to become masters of their game in no time!",
//       ],
//       linkText: 'Book A Golf Class Now',
//     },

//     section2: {
//       heading: 'Find Your Perfect Golf Coach in London',
//       description: [
//         "Golf is a great way to get active and have fun, but it can be intimidating if you don't feel like you know what you're doing! Golf Classes in London by Probuddy provide the perfect solution for learning the best techniques and strategies for playing golf.",
//         'With experienced teachers who are dedicated to helping you improve your game, from beginners to advanced golfers, finding your perfect Golf Coach London has never been easier. Gain confidence in your game and take your skills to the next level with Golf Classes in London by Probuddy!',
//       ],
//       linkText: 'Become A Golf Pro',
//     },

//     classesForYou: {
//       title: 'Reserve Your Golf Sessions in London Through Probuddy',
//       description: [
//         "Golfing in London is a great way to unwind and relax. But finding the best golf courses to visit can be quite difficult and time consuming. With Probuddy, discovering golf classes in London has never been easier! This one-stop-shop offers access to top quality sports and leisure activities, such as golf lessons in some of the city's premier golf courses.",
//         'Apart from amazing deals and discounts, Probuddy also lets you reserve your sessions easily, making sure that your spot will be waiting for you at the golf course of your choice! So why wait any longer? Reserve your Golf Sessions in London with Probuddy today!',
//       ],
//     },

//     instructors: {
//       title: 'Highlighted Exceptional Golf Teacher in London with Top Ratings',
//       description: [
//         "Probuddy, a platform for connecting teaching professionals with those looking to learn, has highlighted one exceptional golf teacher in London: Jamal. Top reviews from some of his past students demonstrate why he is considered one of the top golf teachers in the country, and with thousands of satisfied customers under his belt, it's no surprise that Probuddy has given him the highest rating - five stars.",
//         "Through Probuddy's courses, Jamal offers his expertise as a PGA-certified coach, sharing valuable practice tips along the way to help participants improve their game. With Jamal at the helm, those looking to hone their skills have access not only to some of the Top Golf Classes in London but also to personalized coaching and mentorship.",
//       ],
//       instructorsData: instructorsData('Golf'),
//     },

//     featured: {
//       title: 'In search of Golf Classes in London? Allow us to assist.',
//       description: [
//         'Golf is an enjoyable and exciting sport that can both be played recreationally with friends or as a professional for competition. Golf also offers vast health benefits such as strengthening the core muscles, improving posture and balance, and enhancing joint flexibility.',
//         "Are you looking to add golfing to your repertoire? If so, Golf Classes in London by Probuddy are the perfect solution. You can learn from our highly trained instructors who have years of experience on the golf field. They will guide you through each step of playing, no matter what level you're starting from - beginner, amateur, or skilled player. Our Golf Classes will help hone your skills and make sure you have a great time on the course!",
//       ],
//     },

//     topRatedClasses: {
//       title: 'Featured Golf Classes in London',
//       description: [
//         "If you're interested in bringing your Golf game to the next level, look no further than Probuddy. This Featured Golf classes in London offer a great variety of options that cater to all sorts of experience levels and needs.",
//         "ProBuddy provides the perfect platform for you to learn and explore the different practice styles of this empowering exercise. With experienced instructors more than eager to guide and coach you along your journey, get ready for an exhilarating takeaway that you'll definitely feel for days after!",
//       ],
//     },
//   },
//   {
//     id: 'los-angeles',
//     title: 'los-angeles',

//     section1: {
//       heading: 'Best Golf Classes in Los Angeles',
//       description: [
//         'Golfers in Los Angeles are in luck! Golf Classes in Los Angeles by Probuddy have been highly acclaimed as the best for beginners, with expert instruction and access to top of the line practice facilities. From introductory classes to more advanced techniques, Golf Classes in Los Angeles by Probuddy will have you swinging like a pro in no time.',
//         'Golfers can also book private lessons and join Golf Group Sessions, perfect for anyone looking to improve their game on the golf course. Whether it’s your first time or you’re trying to refine existing skills, Golf Classes in Los Angeles by Probuddy offer an unbeatable golf learning experience.',
//       ],
//       linkText: 'Book A Golf Class Now',
//     },

//     section2: {
//       heading: 'Find Your Perfect Golf Coach in Los Angeles',
//       description: [
//         'For residents of Los Angeles looking to take their golf game to the next level, Golf Classes in Los Angeles by Probuddy can help you find the perfect coach. With an extensive network of highly qualified instructors, Probuddy makes it easy to find the perfect person to help you refine your swing or work on a difficult challenge.',
//         'Professional instructors have access to modern tools, ensuring that they can put together a comprehensive lesson plan specific to your needs and goals. Golfers of all levels can take advantage of convenient online scheduling or text support, allowing them to practise and improve their game without ever leaving home.',
//         'Golf Classes in Los Angeles by Probuddy are guaranteed to provide unbeatable lessons for any aspiring golfer.',
//       ],
//       linkText: 'Become A Golf Pro',
//     },

//     classesForYou: {
//       title: 'Reserve Your Golf Sessions in Los Angeles Through Probuddy',
//       description: [
//         'Golfing is a sport enjoyed by many, but it can be difficult to find courses and mentoring sessions that fit into our schedules. At Probuddy, we understand this challenge, which is why we make it easier to reserve your Golf Sessions in the Los Angeles area.',
//         "Our teachers are knowledgeable and passionate about Golf, so they will provide you with quality classes in the convenient location of your choice. Don't worry if you're lacking some Golf experience - we cater classes to both professional players as well beginners who want to improve their Golf skills. So don't wait any longer, start reserving Golf Classes in Los Angeles through Probuddy today",
//       ],
//     },

//     instructors: {
//       title: 'Highlighted Exceptional Golf Teacher in Los Angeles with Top Ratings',
//       description: [
//         "Probuddy, a platform for connecting teaching professionals with those looking to learn, has highlighted one exceptional golf teacher in Los Angeles: Jamal. Top reviews from some of his past students demonstrate why he is considered one of the top golf teachers in the country, and with thousands of satisfied customers under his belt, it's no surprise that Probuddy has given him the highest rating - five stars.",
//         "Through Probuddy's courses, Jamal offers his expertise as a PGA-certified coach, sharing valuable practice tips along the way to help participants improve their game. With Jamal at the helm, those looking to hone their skills have access not only to some of the Top Golf Classes in Los Angeles but also to personalized coaching and mentorship.",
//       ],
//       instructorsData: instructorsData('Golf'),
//     },

//     featured: {
//       title: 'In search of Golf Classes in Los Angeles? Allow us to assist.',
//       description: [
//         'Golf is an enjoyable and exciting sport that can both be played recreationally with friends or as a professional for competition. Golf also offers vast health benefits such as strengthening the core muscles, improving posture and balance, and enhancing joint flexibility.',
//         "Are you looking to add golfing to your repertoire? If so, Golf Classes in Los Angeles by Probuddy are the perfect solution. You can learn from our highly trained instructors who have years of experience on the golf field. They will guide you through each step of playing, no matter what level you're starting from - beginner, amateur, or skilled player. Our Golf Classes will help hone your skills and make sure you have a great time on the course!",
//       ],
//     },

//     topRatedClasses: {
//       title: 'Featured Golf Classes in Los Angeles',
//       description: [
//         "If you're interested in bringing your Golf game to the next level, look no further than Probuddy. This Featured Golf classes in Los Angeles offer a great variety of options that cater to all sorts of experience levels and needs.",
//         "ProBuddy provides the perfect platform for you to learn and explore the different practice styles of this empowering exercise. With experienced instructors more than eager to guide and coach you along your journey, get ready for an exhilarating takeaway that you'll definitely feel for days after!",
//       ],
//     },
//   },
//   {
//     id: 'melbourne',
//     title: 'melbourne',
//     section1: {
//       heading: 'Top Golf Classes in Melbourne',
//       description: [
//         'Golf classes in Melbourne offer an excellent opportunity to refine your swing, develop your technique and perfect your playing style. Many Golfers look to Melbourne for golf lessons as the city has great teaching options with experienced coaches.',
//         'Probuddy offers a range of Golf Classes in Melbourne that provide guidance and instruction on any level, from amateur to Professional Golfers looking to improve their game. With courses tailored specifically for the individual, each Golf lesson emphasizes areas of personal interest and improvement, allowing you to truly reach your potential.',
//         'The Golf Classes in Melbourne provided by Probuddy are ideal for all Golf enthusiasts who want to take their game up a notch and hone their skills on the Green.',
//       ],
//       linkText: 'Book A Golf Class Now',
//     },

//     section2: {
//       heading: 'Find Your Perfect Golf Coach in Melbourne',
//       description: [
//         'Golf is a great recreational sport, and enjoying it to the fullest requires having optimal technique and skill. Probuddy provides Golf Classes in Melbourne for golfers of all levels, from beginners to advanced players.',
//         'With experienced coaches ready to assist you on your golfing journey and tailor classes to suit all skill levels, you can find your perfect Golf Coach in Melbourne with ease! Our Golf Coaches are extremely knowledgeable and can offer advice on local courses as well as tips on improving technique, enabling you to strengthen your skill sets with much confidence so that the next time you step on the course, you are at the very top of your game.',
//       ],
//       linkText: 'Become A Golf Pro',
//     },

//     classesForYou: {
//       title: 'Reserve Your Golf Sessions in Melbourne Through Probuddy',
//       description: [
//         "Golf is one of the most popular sports around the world and in Australia, it's no different. If you're looking to learn or enjoyed playing Golf then look no further than Probuddy Golf Classes in Melbourne. We provide Golf classes conducted by experienced professionals.",
//         'Our Golf instructors will guide you through Golf fundamentals to help improve your knowledge and technique. With our convenient online portal, you can easily book your Golf session and view all the available Golf instructor schedules in Melbourne. Reserve your Golf sessions today with Probuddy and experience great Golf coaching!',
//       ],
//     },

//     instructors: {
//       title: 'Highlighted Exceptional Golf Teacher in Melbourne with Top Ratings',
//       description: [
//         "Probuddy, a platform for connecting teaching professionals with those looking to learn, has highlighted one exceptional golf teacher in Melbourne: Jamal. Top reviews from some of his past students demonstrate why he is considered one of the top golf teachers in the country, and with thousands of satisfied customers under his belt, it's no surprise that Probuddy has given him the highest rating - five stars.",
//         "Through Probuddy's courses, Jamal offers his expertise as a PGA-certified coach, sharing valuable practice tips along the way to help participants improve their game. With Jamal at the helm, those looking to hone their skills have access not only to some of the Top Golf Classes in Melbourne but also to personalized coaching and mentorship.",
//       ],
//       instructorsData: instructorsData('Golf'),
//     },

//     featured: {
//       title: 'Book Your Golf Classes in Melbourne',
//       description: [
//         "Golf is a great way to spend quality time outdoors and sharpen your skills. Golf enthusiasts living in or visiting Melbourne, Australia now have the opportunity to book Golf Classes with Probuddy! Probuddy's Golf Classes provide instruction from a range of certified professionals that can suit golfers of all levels looking to up their game.",
//         'Golf Classes are available for one-on-ones in addition to Group Golf classes, so you will no longer have to worry about competing for tee times! By booking Golf Classes through Probuddy, you can enjoy an enriching experience, with quality coaching offered by experienced professionals. So why wait? Visit probuddy.com today and book yourself Golf Classes in Melbourne!',
//       ],
//     },

//     topRatedClasses: {
//       title: 'Featured Golf Classes in Melbourne',
//       description: [
//         "If you're interested in bringing your Golf game to the next level, look no further than Probuddy. This Featured Golf classes in Melbourne offer a great variety of options that cater to all sorts of experience levels and needs.",
//         "ProBuddy provides the perfect platform for you to learn and explore the different practice styles of this empowering exercise. With experienced instructors more than eager to guide and coach you along your journey, get ready for an exhilarating takeaway that you'll definitely feel for days after!",
//       ],
//     },
//   },
//   {
//     id: 'chicago',
//     title: 'chicago',
//     section1: {
//       heading: 'Best Golf Classes in Chicago',
//       description: [
//         "Golf classes in the Chicago area have been gaining popularity, and for good reason! Golf is a great way to improve coordination, focus and balance. If you're looking for an opportunity to learn the basics of golf or sharpen your skills as a seasoned golfer, look no further than Golf Classes in Chicago by Probuddy.",
//         'Their instructors are experienced professionals with years of teaching experience. The classes provide detailed explanation on lessons and guaranteed progress tracking that ensures individual needs are being met. Whether you are a beginner or expert at playing, Golf Classes in Chicago by Probuddy is the perfect place for you to start reaching your goals.',
//       ],
//       linkText: 'Book A Golf Class Now',
//     },

//     section2: {
//       heading: 'Looking for the Best Golf Instructor in Chicago?',
//       description: [
//         'Golf Classes in Chicago with Probuddy are for everyone! Take lessons with certified professional and Master Golf Instructors who guarantee to help you improve your game. In addition to guidance from the best instructors, get access to world-class practice facilities, experienced caddies, on-course playing opportunities, and valuable golf intelligence through state-of-the-art technology—all integrated into a fun learning experience that makes it easy to get started and keep improving.',
//         "With Golf Classes in Chicago by Probuddy, there's no limit to what aspiring golfers can accomplish.",
//       ],
//       linkText: 'Become A Golf Pro',
//     },

//     classesForYou: {
//       title: 'Reserve Your Golf Sessions in Chicago Through Probuddy',
//       description: [
//         "Golf is one of the most popular sports around the world and in Australia, it's no different. If you're looking to learn or enjoyed playing Golf then look no further than Probuddy Golf Classes in Chicago. We provide Golf classes conducted by experienced professionals.",
//         'Our Golf instructors will guide you through Golf fundamentals to help improve your knowledge and technique. With our convenient online portal, you can easily book your Golf session and view all the available Golf instructor schedules in Chicago. Reserve your Golf sessions today with Probuddy and experience great Golf coaching!',
//       ],
//     },

//     instructors: {
//       title: 'Highlighted Exceptional Golf Teacher in Chicago with Top Ratings',
//       description: [
//         'Golfing is a sport enjoyed by many, but proper technique and capability comes from having a good teacher. Golf Classes in Chicago by ProBuddy offers individuals with just that – an exceptional golf teacher within the area who has nothing short of top ratings.',
//         'Golf classes are offered by someone who has vast knowledge and experience when it comes to this unique sport, making them perfect for beginners or those who seek to hone their skills to the next level.',
//         'The teacher understands how to properly convey information about the game of golf in a way that is entertaining and engaging, yet still provides useful tips for improvement and success. If you’re looking for naturally-talented experienced Golf Professional, Golf Classes in Chicago by ProBuddy may be your ideal choice!',
//       ],
//       instructorsData: instructorsData('Golf'),
//     },

//     featured: {
//       title: 'Reserve Your Golf Sessions in Chicago Through Probuddy',
//       description: [
//         "Golfing is one of the most popular outdoor activities, but it's not always easy to find a good course. That's why Probuddy offers a great solution: Golf Classes in Chicago. Its user-friendly interface provides a simple and efficient way for you to book Golf Sessions with experienced instructors so you can hone your skills and have a blast on the course.",
//         "All you need to do is sign up on their website, choose the day, time and Golf Course that suit your needs, and reserve your Golf Session. Best of all, they provide Golf Clubs and Golf Balls if needed, so all you need to bring is yourself! Reserve your Golf Sessions in Chicago through Probuddy today - trust us, you won't be disappointed!",
//       ],
//     },

//     topRatedClasses: {
//       title: 'Featured Golf Classes in Chicago',
//       description: [
//         "If you're interested in bringing your Golf game to the next level, look no further than Probuddy. This Featured Golf classes in Chicago offer a great variety of options that cater to all sorts of experience levels and needs.",
//         "ProBuddy provides the perfect platform for you to learn and explore the different practice styles of this empowering exercise. With experienced instructors more than eager to guide and coach you along your journey, get ready for an exhilarating takeaway that you'll definitely feel for days after!",
//       ],
//     },
//   },
//   {
//     id: 'seattle',
//     title: 'seattle',

//     section1: {
//       heading: 'Best Golf Classes in Seattle',
//       description: [
//         'Golf Classes in Seattle by Probuddy are some of the best in the business. Golfers of all levels can benefit from their courses that provide expert insights and hands-on coaching. With a dedicated instructor on hand, students develop their technique and master the fundamentals to drive better performance on the course.',
//         'Each session includes video analysis, strategy guidance, and professional tips to further carve out strokes from players’ games. The classes also incorporate real-time data to hone skills such as putting accuracy, ball trajectory control and more.',
//         'Golf Classes in Seattle by Probuddy bring out the finest qualities of players without breaking the bank.',
//       ],
//       linkText: 'Book A Golf Class Now',
//     },

//     section2: {
//       heading: 'Discover the Ideal Golf Instructor in Seattle',
//       description: [
//         'Golf Classes in Seattle by Probuddy are the ideal way to sharpen your golf game! If you are looking for a reputable and experienced coach, their instructors can provide personalized attention along with detailed analysis to help reach your golf goals.',
//         'With simple drills, exercises, and step-by-step instructions from their team of certified professionals, anyone from beginner to expert levels can benefit from Golf Classes in Seattle by Probuddy. Their unparalleled enthusiasm and knowledge makes learning something new fun and rewarding.',
//         "Whether you're looking for private one-on-one instruction or group lessons for yourself or friends, Golf Classes in Seattle can help you discover the perfect instructor for any skill level!",
//       ],
//       linkText: 'Become A Golf Pro',
//     },

//     classesForYou: {
//       title: 'Reserve Your Golf Sessions in Seattle Through Probuddy',
//       description: [
//         'Golfers in Seattle, get ready to hit the greens! Probuddy is your one-stop shop for Golf sessions in the city. Golfing novices and pros alike can choose from several classes right here in Seattle with the help of this platform. ',
//         "It’s easy to navigate and you can book a class on the go - all it takes is a couple of clicks, so don't hesitate and reserve your Golf lessons through Probuddy today!  ",
//       ],
//     },

//     instructors: {
//       title: 'Highlighted Exceptional Golf Teacher in Seattle with Top Ratings',
//       description: [
//         'Golfing is a sport enjoyed by many, but proper technique and capability comes from having a good teacher. Golf Classes in Seattle by ProBuddy offers individuals with just that – an exceptional golf teacher within the area who has nothing short of top ratings.',
//         'Golf classes are offered by someone who has vast knowledge and experience when it comes to this unique sport, making them perfect for beginners or those who seek to hone their skills to the next level.',
//         'The teacher understands how to properly convey information about the game of golf in a way that is entertaining and engaging, yet still provides useful tips for improvement and success. If you’re looking for naturally-talented experienced Golf Professional, Golf Classes in Seattle by ProBuddy may be your ideal choice!',
//       ],
//       instructorsData: instructorsData('Golf'),
//     },

//     featured: {
//       title: 'In search of Golf Classes in Seattle? Allow us to assist',
//       description: [
//         'Are you in search of Golf Classes in Seattle? Look no further, because Probuddy is here to help! We offer Golf Classes in the Seattle area, taught by experienced and certified Golf Professionals. Our classes provide valuable insights into Golf fundamentals and give developing golfers access to the knowledge and skills they need to become better Golfers.',
//         'Each class focuses on a course concept or club proficiency that allows you to gain the confidence needed to maximize playing skills. Book your Golf Class with Probuddy today and start enjoying the game with our professional Golf Instructors!',
//       ],
//     },

//     topRatedClasses: {
//       title: 'Popular Golf Classes and Events in Seattle',
//       description: [
//         'Golf has become increasingly popular in Seattle, and a great way to get involved is with Golf Classes in Seattle offered by Probuddy. Probuddy provides classes for players of all levels, from beginners wanting to learn the basics of golf to experienced players looking to improve their swing. They even offer courses such as Golf Psychology & Peak Performance that focuses on mental aspects of the game.',
//         'Participants can expect expert instruction, along with useful guidance on equipment and tips on how to break through plateauing performance. Golfers can also get involved in events involving other Golf Classes in Seattle participants, such as discounted rounds at various local golf courses or friendly competitions with prizes for top finishers.',
//         "Whether you're completely new to the game or decades deep into it, there are plenty of ways to enhance your knowledge and skills through Golf Classes in Seattle by Probuddy.",
//       ],
//     },
//   },
//   {
//     id: 'manchester',
//     title: 'manchester',

//     section1: {
//       heading: 'Top Golf Classes in Manchester',
//       description: [
//         "Golf classes in Manchester have never been easier, thanks to Probuddy. With professional coach Wes Golfing and his comprehensive teaching program, you'll have had the chance to learn the basics of the game and develop your skills even further. Golfers of all levels are welcome – whether you're just starting out or an experienced player wishing to take your game to the next level, Probuddy has something for everyone.",
//         "Their golf classes cover topics such as rules and regulations, techniques for every kind of shot, course management, equipment selection, and more. Plus, with their convenient Manchester location and excellent customer service on offer, there's no reason not to take advantage of these top-class classes!",
//       ],
//       linkText: 'Book A Golf Class Now',
//     },

//     section2: {
//       heading: 'Find Your Perfect Golf Coach in Manchester',
//       description: [
//         'Golfers in Manchester have the perfect opportunity to advance their game with Golf Classes offered by Probuddy. Golf is a complex sport that requires dedication, determination and knowledge in order to reach peak performance - this is why having the right coach is so important.',
//         "With Golf Classes by Probuddy, you can find a coach whose style and approach matches your own, pushing you to maximize your potential and enjoy playing golf even more. Whether you're looking for guidance with technique or want to take your mental game further, Golf Classes in Manchester will help you make strides towards perfecting your golfing journey.",
//       ],
//       linkText: 'Become A Golf Pro',
//     },

//     classesForYou: {
//       title: 'Reserve Your Golf Sessions in Manchester Through Probuddy',
//       description: [
//         'Golf is an exciting game for all ages to enjoy. If you’re a keen golfer based in Manchester, then look no further than Probuddy – the leading online platform for reserving Golf sessions with experienced instructors. From tee-offs to playing on sand bunkers, there’s something for every need and each Golf class is tailored to your skill level and preferred timeslot.',
//         'With hassle free reservations, top quality golf instructors and support from the Probuddy team, Golf Classes in Manchester by Probuddy are second to none – come join us!',
//       ],
//     },

//     instructors: {
//       title: 'Highlighted Exceptional Golf Teacher in Manchester with Top Ratings',
//       description: [
//         'Golfers looking to improve their game in Manchester now have the opportunity to work with a top-rated golf instructor. With Golf Classes in Manchester by Probuddy, golfers can enlist the assistance of an exceptional and highly qualified teacher, who has achieved stellar reviews from their satisfied customers.',
//         'Whether a novice player ready to learn the basics or an experienced golf lover wanting to hone their skills, the extraordinary golf teacher at Golf Classes in Manchester will help individuals reach new heights of success on the course. With expertise and enthusiasm for the sport, Golf Classes in Manchester is a premier destination for all players.',
//       ],
//       instructorsData: instructorsData('Golf'),
//     },

//     featured: {
//       title: 'Book Your Golf Classes in Manchester',
//       description: [
//         'Golf classes in Manchester are becoming a popular way to stay both physically and mentally active. Golf is a relaxing and enjoyable sport that can be played regardless of age or physical condition. Thanks to Probuddy, now you can access golf classes and lessons in Manchester from the comfort of your own home.',
//         "Whether you're an expert golfer just looking for extra help or a novice who's never even picked up a club - Probuddy offers golf training for all levels. Their courses provide guidance from expert professionals, making it easy for you to learn the basics and improve your form. Book your golf classes with Probuddy today and get ready to join the fun!",
//       ],
//     },

//     topRatedClasses: {
//       title: 'Popular Golf Classes and Events in Manchester',
//       description: [
//         'Golf has become increasingly popular in Manchester, and a great way to get involved is with Golf Classes in Manchester offered by Probuddy. Probuddy provides classes for players of all levels, from beginners wanting to learn the basics of golf to experienced players looking to improve their swing. They even offer courses such as Golf Psychology & Peak Performance that focuses on mental aspects of the game.',
//         'Participants can expect expert instruction, along with useful guidance on equipment and tips on how to break through plateauing performance. Golfers can also get involved in events involving other Golf Classes in Manchester participants, such as discounted rounds at various local golf courses or friendly competitions with prizes for top finishers.',
//         "Whether you're completely new to the game or decades deep into it, there are plenty of ways to enhance your knowledge and skills through Golf Classes in Manchester by Probuddy.",
//       ],
//     },
//   },
//   {
//     id: 'san-francisco',
//     title: 'san-francisco',

//     section1: {
//       heading: 'Best Golf Classes in San Francisco',
//       description: [
//         'Golf is a wonderful game for all ages. Golf classes in San Francisco can help you develop your skills so that each round brings satisfaction and personal growth. Probuddy offers excellent golf classes tailored to beginner, intermediate, and advanced players.',
//         "Golf professionals with decades of experience provide comprehensive instruction that takes into account each student's individual goals. Golfers will benefit from the latest techniques, tips, drills, equipment options, and more - everything necessary for an enjoyable round of golf.",
//         'Classes take place outdoors in the beautiful terrain surrounding San Francisco and are available year-round in three different packages sponsored by Probuddy. Whether you’re hoping to become a pro or simply gain improved perspective on the game of golf, these courses offer something for everyone.',
//       ],
//       linkText: 'Book A Golf Class Now',
//     },

//     section2: {
//       heading: 'Find Your Perfect Golf Coach in San Francisco',
//       description: [
//         'Looking to improve your golf game? Golf classes in San Francisco by Probuddy provide the perfect opportunity! At Probuddy, we take a highly personalized approach when it comes to golf coaching. Our team of professionals is dedicated to helping you reach your golfing goals.',
//         'We specialize in a range of topics, such as course strategy, ball control, trajectory management, and more. With Golf Classes in San Francisco With Probuddy, you will find the perfect Golf Coach for you - committed to providing you with the best tools and guidance for improvement. Develop your skills and form from the comfort of your own home with Golf Classes in San Francisco by ProBuddy!',
//       ],
//       linkText: 'Become A Golf Pro',
//     },

//     classesForYou: {
//       title: 'Reserve Your Golf Sessions in San Francisco Through Probuddy',
//       description: [
//         'Golfers looking for the perfect place to tee off in San Francisco need look no further than Probuddy. With its unique Golf Classes, you can easily reserve exclusive golf sessions and venture out on the green that suits your interest and skill level best.',
//         "Whether you're a novice seeking to learn from top golf pros or an experienced player hoping to challenge yourself on a great course, Probuddy has it all with Golf Classes available across multiple golf courses in the city.",
//         'With Probuddy, you get access to world class greens, stunning landscapes and amazing experiences that will stay with you far beyond a round of golf. So swing away and experience San Francisco Golf at its finest - reserve your Golf Class with Probuddy today!',
//       ],
//     },

//     instructors: {
//       title: 'Highlighted Exceptional Golf Teacher in San Francisco with Top Ratings',
//       description: [
//         'Golfers looking to improve their game in San Francisco now have the opportunity to work with a top-rated golf instructor. With Golf Classes in San Francisco by Probuddy, golfers can enlist the assistance of an exceptional and highly qualified teacher, who has achieved stellar reviews from their satisfied customers.',
//         'Whether a novice player ready to learn the basics or an experienced golf lover wanting to hone their skills, the extraordinary golf teacher at Golf Classes in San Francisco will help individuals reach new heights of success on the course. With expertise and enthusiasm for the sport, Golf Classes in San Francisco is a premier destination for all players.',
//       ],
//       instructorsData: instructorsData('Golf'),
//     },

//     featured: {
//       title: 'Book Your Golf Classes in San Francisco',
//       description: [
//         'Golfing in San Francisco can be an exciting and challenging experience. For those looking to refine their skills, Probuddy Golf Classes are the perfect option. With a variety of classes ranging from beginner to advanced levels and professional instructors, students can gain valuable insight into golfing fundamentals.',
//         'Whether you’re an occasional golfer or an avid enthusiast, a course at Probuddy Golf will help take your game to the next level. Book your Golf Classes in San Francisco today and unlock your potential with the help of experienced instructors!',
//       ],
//     },

//     topRatedClasses: {
//       title: 'Popular Golf Classes and Events in San Francisco',
//       description: [
//         'Golf has become increasingly popular in San Francisco, and a great way to get involved is with Golf Classes in San Francisco offered by Probuddy. Probuddy provides classes for players of all levels, from beginners wanting to learn the basics of golf to experienced players looking to improve their swing. They even offer courses such as Golf Psychology & Peak Performance that focuses on mental aspects of the game.',
//         'Participants can expect expert instruction, along with useful guidance on equipment and tips on how to break through plateauing performance. Golfers can also get involved in events involving other Golf Classes in San Francisco participants, such as discounted rounds at various local golf courses or friendly competitions with prizes for top finishers.',
//         "Whether you're completely new to the game or decades deep into it, there are plenty of ways to enhance your knowledge and skills through Golf Classes in San Francisco by Probuddy.",
//       ],
//     },
//   },
//   {
//     id: 'houston',
//     title: 'houston',

//     section1: {
//       heading: 'Best Golf Classes in Houston',
//       description: [
//         "Golf Classes in Houston by Probuddy are some of the best available. Whether you're a beginner looking to get into the sport or an experienced player striving to perfect your swing, Probuddy has classes designed to improve your game. With highly trained professionals and access to top-of-the-line practice facilities, Golf Classes in Houston by Probuddy offer an experience unmatched by the competition.",
//         'Everything from your fundamentals and skill set practice to comprehensive course management strategies can be found at these unique classes. Golfers of all levels can benefit from these in-depth courses and leave feeling like they have taken their game to the next level.',
//       ],
//       linkText: 'Book A Golf Class Now',
//     },

//     section2: {
//       heading: 'Find Your Perfect Golf Coach in Houston',
//       description: [
//         "Probuddy Golf classes in Houston offer golfers of every level the opportunity to hone their skills with experienced golf coaches. With personalized lessons and group instruction, Probuddy Golf puts its students first, helping them find their perfect golf coach who can provide attentive individualized attention and customize drills suited to each student's specific needs. Whether you're a beginner or a professional looking for improvement, now is the perfect time to sign up for the game-changing Golf Classes in Houston by Probuddy.",
//       ],
//       linkText: 'Become A Golf Pro',
//     },

//     classesForYou: {
//       title: 'Reserve Your Golf Sessions in Houston Through Probuddy',
//       description: [
//         'Golf is an exciting game for all ages to enjoy. If you’re a keen golfer based in Houston, then look no further than Probuddy – the leading online platform for reserving Golf sessions with experienced instructors. From tee-offs to playing on sand bunkers, there’s something for every need and each Golf class is tailored to your skill level and preferred timeslot.',
//         'With hassle free reservations, top quality golf instructors and support from the Probuddy team, Golf Classes in Houston by Probuddy are second to none – come join us!',
//       ],
//     },

//     instructors: {
//       title: 'Highlighted Exceptional Golf Teacher in Houston with Top Ratings',
//       description: [
//         'Golfers looking to improve their game in Houston now have the opportunity to work with a top-rated golf instructor. With Golf Classes in Houston by Probuddy, golfers can enlist the assistance of an exceptional and highly qualified teacher, who has achieved stellar reviews from their satisfied customers.',
//         'Whether a novice player ready to learn the basics or an experienced golf lover wanting to hone their skills, the extraordinary golf teacher at Golf Classes in Houston will help individuals reach new heights of success on the course. With expertise and enthusiasm for the sport, Golf Classes in Houston is a premier destination for all players.',
//       ],
//       instructorsData: instructorsData('Golf'),
//     },

//     featured: {
//       title: 'Book Your Golf Classes in Houston',
//       description: [
//         'Golfing is an incredibly popular sport around the world, but even devoted golfers don’t always have a lot of time to focus on their skills. If you’re looking to start golfing, or want to improve your game without breaking the bank, Golf Classes in Houston by Probuddy provide a great opportunity! With classes ranging from beginner basics to intensive strategy and technique courses, these classes can be tailored to fit your needs.',
//         'Golfing with peers in a relaxed atmosphere with experienced instructors provides an ideal learning environment for any golfer. Golf Classes in Houston by Probuddy is your best chance for improving your game quickly and effectively!',
//       ],
//     },

//     topRatedClasses: {
//       title: 'Popular Golf Classes and Events in Houston',
//       description: [
//         'Golf classes and events in Houston are becoming increasingly popular. Golfers of all levels often look for ways to improve their skills, hone their technique, and learn from experts. Fortunately, there are many golf classes and events available in the Houston area that can help you do just that.',
//         'Probuddy Golf is a great example of such a service, offering comprehensive golf classes taught by experienced instructors who will help boost your game. With experienced lessons for all skill levels, Probuddy Golf allows any golfer to take their game to the next level and enjoy the game more than ever before.',
//       ],
//     },
//   },
// ];

export const boxingCityData = [
  {
    id: 'singapore',
    title: 'singapore',

    section1: {
      heading: 'The Best Boxing Classes in Singapore',
      description: [
        'The Best Boxing Classes in Singapore are now just a tap away! The Probuddy marketplace has scoured the entire country to bring you the best classes and trainers currently available. In no time, you’ll be learning cutting-edge techniques that will help you become a better fighter, or get closer towards your body goals.',
        "Whether you’re looking for one-on-one coaching sessions or an intense group class, you’ll find all that and more when you search through the vast range of courses available on Probuddy marketplace. So don't wait any longer - start your journey today with The Best Boxing Classes in Singapore!",
      ],
      linkText: 'Book A Boxing Class Now',
    },

    section2: {
      heading: 'Find Your Perfect Boxing Coach in Singapore',
      description: [
        "Are you looking for a boxing coach in Singapore to help take your boxing skills to the next level? Look no further– Probuddy's marketplace makes it easy for you to connect with experienced, certified coaches who can help guide and motivate you! Find your perfect coach by refining your search parameters such as rates or location.",
        "Find the right contact for any boxing-related questions; all coaches have detailed profiles so users can get an accurate understanding of their coaching style. Don't wait any longer: Find Your Perfect Boxing Coach in Singapore in Probuddy's marketplace today!",
      ],
      linkText: 'Become A Boxing Pro',
    },

    classesForYou: {
      title: 'Book Your Boxing Classes in Singapore',
      description: [
        'If you are looking for boxing classes in Singapore, then the Probuddy Marketplace has you covered. Probuddy is an online marketplace where individuals can find and book a variety of fitness classes and workshops, including boxing.',
        "With dozens of certified instructors available, you can be sure to find a class that fits your skill level and budget. Booking is quick and easy - simply select a class and payment method from our website, and you're good to go! Book your boxing classes in Singapore today on Probuddy Marketplace and start working towards your fitness goals!",
      ],
    },

    instructors: {
      title: 'Featured Top-Rated Boxing Coaches in Singapore',
      description: [
        'Probuddy is proud to present some of the most highly rated Boxing Coaches in Singapore. From beginner to intermediate and even advanced levels, Probuddy takes into account the feedback from clients to identify Top-Rated coaches that are able to cater to various skill levels. ',
        "With their in-depth knowledge of the game and their ability to customize a training program unique to each player's needs, these Top-Rated Boxing Coaches in Singapore are sure to provide an enjoyable learning experience. So if you're looking for someone who can help you take your game to the next level, look no further than Probuddy's Top-Rated Boxing Coaches in Singapore.",
      ],
      instructorsData: instructorsData('Boxing'),
    },

    featured: {
      title: 'Popular Boxing Classes and Events in Singapore',
      description: [
        "If you're looking for Popular Boxing Classes and Events in Singapore, Probuddy Marketplace is your go-to resource. Whether you're interested in boxing classes as a way to stay fit and healthy, or searching for professional boxing tournaments, Probuddy Marketplace has plenty of options that cater to different preferences.",
        'The variety of courses and events on offer can keep you engaged and motivated while learning new skills or competing at the highest level. Its user-friendly interface makes it easy to sort through reviews, rates, and descriptions for quality Popular Boxing Classes & Events in Singapore so you can be confident in making informed decisions about your purchase.',
      ],
    },

    topRatedClasses: {
      title: 'Featured Boxing Classes in Singapore',
      description: [
        'Featured boxing classes in Singapore can be found on Probuddy Marketplace. With a team of professionals, Probuddy Marketplace has curated a selection of the highest quality boxing classes that have been recommended by locals and tourists alike.',
        "Featured boxing classes are designed to perfectly balance fun and intensity while building strength and improving health, allowing you to get the most out of every session. Whether you're new to the sport or have years of experience, with Probuddy Marketplace you'll find reliable trainers and great classes for a unique fitness experience.",
      ],
    },
  },
  {
    id: 'sydney',
    title: 'sydney',

    section1: {
      heading: 'The Best Boxing Classes in Sydney',
      description: [
        'Finding the best boxing classes in Sydney can be a daunting task, given the array of options available. Fortunately, Probuddy Marketplace offers a unique platform that curates only the finest instructors and locations for your boxing needs. The selection process is rigorous, geared towards quality and excellence, giving users confidence that they are training with the very best in the sport.',
        'Whether you’re looking for group classes or one-on-one sessions, Probuddy Marketplace is sure to have an option to fit your goals and desired training atmosphere. The best boxing classes in Sydney are now just a few clicks away!',
      ],
      linkText: 'Book A Boxing Class Now',
    },

    section2: {
      heading: 'Find Your Perfect Boxing Coach in Sydney',
      description: [
        'ProBuddy Marketplace is the perfect place to Find Your Perfect Boxing Coach in Sydney. Our coaches come from all walks of life, with a diverse range of skills and experiences. You’ll find qualified professionals, as well as people who are passionate and have deep knowledge in boxing.',
        'With every coach, we guarantee a personalised experience tailored to your goals and training needs — whatever they may be. Every coach believes in positive reinforcement to help you find success. Find your perfect Boxing Coach right here in Sydney through ProBuddy Marketplace Today!',
      ],
      linkText: 'Become A Boxing Pro',
    },

    classesForYou: {
      title: 'Search for the Best Boxing in Sydney',
      description: [
        'Searching for experts in Boxing in Sydney can be difficult and time consuming, but especially now when everyone is busier than ever. If you are looking for the best Sydney has to offer in Boxing, then consider Probuddy Marketplace as your one-stop shop.',
        'Our platform connects you with qualified coaches who are passionate and certified in the sport. With precise search filters such as location, availability, or certification level, you can easily find the right coach who matches your goals and needs. Search for the best Boxing Professionals in Sydney today at Probuddy Marketplace!',
      ],
    },

    instructors: {
      title: 'Featured Top-Rated Boxing Coaches in Sydney',
      description: [
        'Probuddy is proud to present some of the most highly rated Boxing Coaches in Sydney. From beginner to intermediate and even advanced levels, Probuddy takes into account the feedback from clients to identify Top-Rated coaches that are able to cater to various skill levels. ',
        "With their in-depth knowledge of the game and their ability to customize a training program unique to each player's needs, these Top-Rated Boxing Coaches in Sydney are sure to provide an enjoyable learning experience. So if you're looking for someone who can help you take your game to the next level, look no further than Probuddy's Top-Rated Boxing Coaches in Sydney.",
      ],
      instructorsData: instructorsData('Boxing'),
    },

    featured: {
      title: 'Popular Boxing in Sydney',
      description: [
        'Popular Boxing in Sydney is right at your fingertips through Probuddy Marketplace. Whether you are a beginner looking to dive into the world of boxing, or a seasoned veteran interested in finding new and challenging workouts, there is something for everyone in our inclusive marketplace.',
        'With access to highly trained instructors and some of the best facilities around, Popular Boxing in Sydney has never been easier or more convenient than it is with Probuddy Marketplace. Get started today and see what Popular Boxing can do for you!',
      ],
    },

    topRatedClasses: {
      title: 'Book Online on Probuddy for Boxing',
      description: [
        'Booking an online boxing class that fits your schedule and lifestyle can be a daunting task. Now, with Probuddy, you can easily book online for a boxing class that’s right for you. Whether you’re interested in learning the fundamentals or ready to take your skills to the next level, Probuddy has options for everyone.',
        'Simply create a profile and browse classes available at your convenience, day or night. Booking is easy - just select the class you want and pay quickly without hassle. Embrace your inner athlete by booking online on Probuddy today!',
      ],
    },
  },
  {
    id: 'new-york',
    title: 'new-york',

    section1: {
      heading: "New York's Best Boxing Classes",
      description: [
        "New York City is known for having some of the best boxing classes in the country, and now they're more accessible than ever thanks to Probuddy Marketplace. Newbies to the sport and experienced boxers alike can find options that suit their needs, whether they want small private classes or larger group sessions. With Boxing and movement classes available as well, Probuddy Marketplace has New York City's go-to spot for all of your fitness needs.",
      ],
      linkText: 'Book A Boxing Class Now',
    },

    section2: {
      heading: 'Finding The Perfect Boxing Coach In New York City',
      description: [
        'Finding the perfect boxing coach in New York City can be difficult and intimidating. Finding the right coach who understands your goals and has the experience to prepare you for success is key, but how do you know who to trust?',
        "Thankfully, Probuddy Marketplace offers a fast and easy way to find the boxing coach you need - no more aimless web searches or massive phone calls. Just quickly search listings by location, skill level, and specialty in order to find a boxing coach that fits your needs. With Probuddy Marketplace there's no longer any excuse not to reach your fitness goals!",
      ],
      linkText: 'Become A Boxing Pro',
    },

    classesForYou: {
      title: 'Book Your Boxing Classes in New York',
      description: [
        'Booking your boxing classes in New York has never been easier than with Probuddy Marketplace. The marketplace is brimming with experienced and skilled boxing instructors, all ready to guide you through the basics of the sport. Whether you’re a boxer looking to train harder or someone completely new to the sport, Probuddy Marketplace can help you find the right coach for your level.',
        "Enjoy a range of training styles and locations that'll give you the opportunity to further hone in on your skills and push yourself hard at the same time. Book your classes today and bring out your inner fighter!",
      ],
    },

    instructors: {
      title: 'Featured Top-Rated Boxing Coaches in New York',
      description: [
        "If you're looking to train with the best boxing coaches in New York City, then look no further than Probuddy Marketplace. Featured top-rated Boxing Coaches provide one-on-one or group classes and can customise a training plan to meet any individual's unique needs.",
        'Working with a reputable and experienced Boxing coach is the key to making your fitness goals achievable; whether you want to learn basic techniques, improve your technique or become fight ready, this is the perfect place for you! Featured Top-Rated Boxing Coaches in New York can offer unbeatable advice and provide that extra motivation needed for success. With their comprehensive knowledge of training techniques, these coaches are guaranteed to help you reach the next level in the ring.',
      ],
      instructorsData: instructorsData('Boxing'),
    },

    featured: {
      title: 'Popular Boxing Classes and Events in New York',
      description: [
        "If you're looking for Popular Boxing Classes and Events in New York, then the Probuddy Marketplace is the place for you. Our curated list of Popular Boxing Classes and Events in New York features some of the top-rated centers and experience to take on challenging physical fitness missions.",
        'With a full selection of boxing classes tailored to your goals, you can easily find the perfect one. From beginner courses to intermediate fitness classes and elite tournaments, we provide all the resources you need for a full-fledged boxing experience in New York. Sign up with Probuddy today to explore Popular Boxing Classes in New York!',
      ],
    },

    topRatedClasses: {
      title: 'Featured Boxing Classes in New York',
      description: [
        'Probuddy Marketplace is thrilled to feature exclusive boxing classes in New York City! Perfect for fitness enthusiats and those looking to start their boxing career, these sessions offer the advanced instruction needed to learn proper technique and fill you in on what it means to be part of the sport.',
        "With top notch instructors, you can rest assured that your training will be tailored to your specific needs and goals so that you can continue developing your boxing skills with confidence. So don't wait any longer - check out the Featured Boxing Classes ProBuddy Marketplace has available today in New York City!",
      ],
    },
  },
  {
    id: 'london',
    title: 'london',
    section1: {
      heading: 'Best Boxing Classes in London',
      description: [
        'Are you looking for the best boxing classes in London? Look no further than Probuddy Marketplace! With a team of highly experienced and passionate coaches, we provide high-quality training that focuses on proper technique, conditioning and sparring. Our sessions are tailored to fit your needs whether if be individual or group coaching.',
        'Our wide range of programs and options make it easy to find the most suitable class for you - no matter what your experience level is. We strive to create results-oriented training that allows each person to reach their own personal objectives. So, join us today and unleash your inner boxing champion!',
      ],
      linkText: 'Book A Boxing Class Now',
    },

    section2: {
      heading: 'Find Your Perfect Boxing Coach in London',
      description: [
        'Find Your Perfect Boxing Coach in London in Probuddy Marketplace. Are you new to boxing and looking for a qualified coach? Or are you an experienced fighter and seeking improvements? No matter what level you are, investing in private boxing coaching will greatly enhance your performance.',
        'With the vast selection of coaches from different backgrounds and qualifications available at Probuddy Marketplace, you will be able to find a perfect coach who can provide the training that meets your needs. Be sure to book ahead and make the most of this amazing opportunity!',
      ],
      linkText: 'Become A Boxing Pro',
    },

    classesForYou: {
      title: 'Book Your Boxing Sessions in London Through Probuddy',
      description: [
        "Booking boxing sessions in London just got easier with Probuddy. Whether you are a professional fighter or someone looking to try the sport for the first time, Probuddy's marketplace can help you find the perfect options for your budget and location.",
        'With an array of available boxing gyms, hobbyists and trainers in London to choose from, booking your next boxing session has never been simpler. BookYour Boxing Sessions in London Through Probuddy Marketplace today!',
      ],
    },

    instructors: {
      title: 'Featured Top-Rated Boxing Coaches in London',
      description: [
        "If you're looking to train with the best boxing coaches in London City, then look no further than Probuddy Marketplace. Featured top-rated Boxing Coaches provide one-on-one or group classes and can customise a training plan to meet any individual's unique needs.",
        'Working with a reputable and experienced Boxing coach is the key to making your fitness goals achievable; whether you want to learn basic techniques, improve your technique or become fight ready, this is the perfect place for you! Featured Top-Rated Boxing Coaches in London can offer unbeatable advice and provide that extra motivation needed for success. With their comprehensive knowledge of training techniques, these coaches are guaranteed to help you reach the next level in the ring.',
      ],
      instructorsData: instructorsData('Boxing'),
    },

    featured: {
      title: 'In search of Boxing Classes in London? Allow us to assist.',
      description: [
        'Looking to improve your boxing skills in London? Look no further than the Probuddy Marketplace. Regardless of whether you are a complete beginner searching for a new sport to take up, or if you are an experienced fighter looking to brush up on your technique, our range of boxing classes have something for everyone.',
        'With knowledgeable and passionate coaches who will tailor their teachings to suit your individual needs and abilities, why search any further? Check out the Probuddy Marketplace and find yourself the perfect class today!',
      ],
    },

    topRatedClasses: {
      title: 'Featured Boxing Classes in London',
      description: [
        'Probuddy Marketplace is thrilled to feature exclusive boxing classes in London City! Perfect for fitness enthusiats and those looking to start their boxing career, these sessions offer the advanced instruction needed to learn proper technique and fill you in on what it means to be part of the sport.',
        "With top notch instructors, you can rest assured that your training will be tailored to your specific needs and goals so that you can continue developing your boxing skills with confidence. So don't wait any longer - check out the Featured Boxing Classes ProBuddy Marketplace has available today in London City!",
      ],
    },
  },
  {
    id: 'los-angeles',
    title: 'los-angeles',

    section1: {
      heading: 'The Best Boxing Classes in Los Angeles',
      description: [
        'The Probuddy Marketplace offers some of The Best Boxing Classes in Los Angeles, making it the perfect choice for anyone looking to stay fit and challenge their body. The classes are taught by well-trained instructors who create tailored plans based on the needs and goals of each student.',
        "Whether you're a beginner or an expert boxer, there is something for everyone at the Probuddy Marketplace. The classes offer quality instruction as well as skilled sparring partners, ensuring that you get a total workout experience with guaranteed results!",
      ],
      linkText: 'Book A Boxing Class Now',
    },

    section2: {
      heading: 'Find Your Perfect Boxing Coach in Los Angeles',
      description: [
        "Finding the perfect boxing coach in Los Angeles shouldn't have to be a challenge. That's why the Probuddy Marketplace exists - to make it easy for those looking for boxing coaches in LA to find exactly what they need.",
        'With our platform, you can easily browse available coaches and athletes, read reviews from other customers, and even chat with coaches before booking any sessions. Find Your Perfect Boxing Coach in Los Angeles today with Probuddy Marketplace!',
      ],
      linkText: 'Become A Boxing Pro',
    },

    classesForYou: {
      title: 'Book Your Boxing Classes in Los Angeles',
      description: [
        "If you're looking to get fit, then Los Angeles is the place for you! Book your boxing classes in Los Angeles with Probuddy Marketplace and you won't be disappointed. Benefit from their wide variety of lessons so you can work out on your own schedule.",
        "With certified instructors and top-notch equipment, you'll get a great boxing experience in Los Angeles like no other. Don't wait, book your classes now and start getting the most out of your fitness journey!",
      ],
    },

    instructors: {
      title: 'Featured Top-Rated Boxing Coaches in Los Angeles',
      description: [
        'The Probuddy Marketplace is delighted to present Featured Top-Rated Boxing Coaches in Los Angeles. These trained professionals have demonstrated success in teaching boxing and training their clients, offering an outstanding experience for participants at all levels of practice.',
        'Working with these Featured Top-Rated Boxing Coaches ensures that members are getting a well-rounded boxing education with access to the best techniques, tips and advice. We are proud to take part in this network of dedicated professionals who offer the highest quality instruction available in Los Angeles.',
      ],
      instructorsData: instructorsData('Boxing'),
    },

    featured: {
      title: 'Popular Boxing Classes and Events in Los Angeles',
      description: [
        'Are you in the Los Angeles area looking to get into boxing? Probuddy Marketplace is here to help you get started. Popular boxing classes and events in Los Angeles are now readily available on Probuddy. Whether you’re a professional fighter, a beginner or a casual recreationalboxer, the instructors on Probuddy have your needs covered with easy booking and hassle-free payments. With every purchase, customers can also take advantage of exclusive discounts and deals on unique boxing experiences that await them. Sign up now to find the perfect trainer or event that suits your level of experience!',
      ],
    },

    topRatedClasses: {
      title: 'Featured Boxing Classes in Los Angeles',
      description: [
        "The Probuddy Marketplace is now filled with Featured Boxing Classes in Los Angeles! These boxing classes are top-notch and the perfect way to add a little extra health and fitness into one's life. Not only do you get excellent coaching, but also the opportunity to learn different techniques from professional instructors who have worked in the area for years.",
        "Featured Boxing Classes in Los Angeles are modified according to your skill level and provide an enriching physical experience combined with a fun class atmosphere. So if you're looking to stay fit, increase your strength and improve your agility, head on over to the Probuddy Marketplace for Featured Boxing Classes in Los Angeles!",
      ],
    },
  },
  {
    id: 'melbourne',
    title: 'melbourne',

    section1: {
      heading: 'The Best Boxing Classes in Melbourne',
      description: [
        "Melbourne is an exciting city for fitness fanatics and those looking to develop their boxing skills. The Best Boxing Classes in Melbourne can be found in Probuddy Marketplace, where the vast selection of classes are tailored to suit all levels of experience - from rookie to pro! Whatever your individual goals may be, you'll be sure to find a class that fits perfectly thanks to Probuddy Marketplace's unbeatable selection.",
        "Whether you're looking for one-on-one sessions or group classes, you'll be able to refine results with the flexible search function and comprehensive ratings system until you've found an instructor whose skills and approach appeal to you. The Best Boxing Classes in Melbourne await at Probuddy Marketplace, so get ready for an unforgettable workout experience down under!",
      ],
      linkText: 'Book A Boxing Class Now',
    },

    section2: {
      heading: 'Find Your Perfect Boxing Coach in Melbourne',
      description: [
        'Are you looking to find your perfect boxing coach in Melbourne? Then look no further than the Probuddy Marketplace. Our website provides a comprehensive list of professional boxing coaches in Melbourne, so that you can pick the best fit for you quickly and easily. All our coaches have been carefully chosen for their experience and qualifications, ensuring that you will learn from the best and reach any goals that you have set.',
        'Search through detailed profiles and reviews from other members to make an informed decision about which coach would be able to give you exactly what you need. Find Your Perfect Boxing Coach in Melbourne today with Probuddy Marketplace!',
      ],
      linkText: 'Become A Boxing Pro',
    },

    classesForYou: {
      title: 'Book Your Boxing Classes in Melbourne',
      description: [
        'Booking your boxing classes in Melbourne with Probuddy Marketplace has never been easier! Our platform makes it fast and simple to secure the perfect class for you in no time. With easy navigation, you can find all the amenities and facilities available near you, as well as prices that easily fit into your budget.',
        'At Probuddy Marketplace, customer safety is our top priority; we make sure to verify all instructors beforehand so you can have a worry-free workout experience. Booking your boxing classes today has never been more convenient - try out Probuddy Marketplace and see how simple it can be!',
      ],
    },

    instructors: {
      title: 'Featured Top-Rated Boxing Coaches in Melbourne',
      description: [
        'The Probuddy Marketplace has Featured Top-Rated Boxing Coaches in Melbourne, providing boxing enthusiasts with an amazing opportunity to learn from some of the most experienced and knowledgeable coaches around. All Featured Top-Rated Boxing Coaches on the marketplace have been rigorously evaluated for their skills and knowledge through a quality assurance process.',
        'With access to these Featured Top-Rated Boxing Coaches, you can be sure that your boxing regimen will be tailored to meet your individual needs, so that you can reach your goals faster and more easily. Whether you’re looking for basic skills training or advanced boxing techniques from experienced professionals, the Probuddy Marketplace has Featured Top-Rated Boxing Coaches in Melbourne who can help you achieve success with your fitness journey.',
      ],
      instructorsData: instructorsData('Boxing'),
    },

    featured: {
      title: 'Popular Boxing Classes and Events in Melbourne',
      description: [
        'Are you looking for Popular Boxing Classes and Events in Melbourne? The Probuddy Marketplace has a wide variety of boxing classes, events and gyms to choose from all across the greater Melbourne area. Whether you are looking for the latest fad in HIIT boxing classes or a fun evening event with sparring competition, the Probuddy Marketplace is your go-to source.',
        "With dozens of different gyms located in convenient locations all around Melbourne, it's easy to find the perfect class for any skill level. Don't miss out on Popular Boxing Classes and Events in Melbourne - browse the Probuddy Marketplace now to see what's available near you!",
      ],
    },

    topRatedClasses: {
      title: 'Featured Boxing Classes in Melbourne',
      description: [
        'Featured boxing classes in Melbourne are quickly becoming the latest fitness trend. Offering an exciting, high-intensity workout that blends cardio and strength training, these boxing classes accommodate any level of expertise.',
        "Whether you're looking to get fit while having fun or you need an extra push on the way to reaching your peak boxing form, these comprehensive sessions are sure to provide the perfect solution. Now featured on Probuddy Marketplace, one could not be closer to achieving their health and wellness goals.",
      ],
    },
  },
  {
    id: 'chicago',
    title: 'chicago',

    section1: {
      heading: 'The Best Boxing Classes in Chicago',
      description: [
        "If you're looking for The Best Boxing Classes in Chicago, the Probuddy Marketplace is where you should begin your search. The Probuddy Marketplace has a comprehensive list of professional boxing classes and gyms that have been meticulously picked to ensure high-quality services.",
        "The classes offer convenient locations and affordable prices so that everyone can reap the benefits of boxing's excellent physical and mental health advantages. The coaches and trainers are certified, dedicated professionals who are passionate about helping students reach their fitness goals. Selecting The Best Boxing Classes in Chicago may seem daunting, but utilizing the Probuddy Marketplace makes finding the perfect class easy.",
      ],
      linkText: 'Book A Boxing Class Now',
    },

    section2: {
      heading: 'Find Your Perfect Boxing Coach in Chicago',
      description: [
        'With the Probuddy Marketplace, individuals in Chicago looking for a perfect boxing coach have come to the right place. Find your ideal match for the perfect boxing coach to help give you tips and tricks to achieve your most ambitious goals. Make the most of access on our platform to connect with experienced coaches from around Chicago who can provide high-quality guidance and direction.',
        'Find your boxing dreams come true through this unique marketplace! Our extensive range of coaches have impressive qualifications and experience ready to help you on your journey. Get started now and make use of this fantastic opportunity with Probuddy Marketplace.',
      ],
      linkText: 'Become A Boxing Pro',
    },

    classesForYou: {
      title: 'Book Your Boxing Classes in Chicago',
      description: [
        "Whether you're a beginner or an experienced athlete, boxing classes in Chicago can be an amazing way to get in shape and become more active. With Probuddy Marketplace, booking your boxing classes has never been easier! You can easily find certified trainers in your area and book them according to your schedule and availability.",
        'Booking through Probuddy Marketplace also helps ensure you have access to the best instructors, as all those in the marketplace are certified professionals. Book your next boxing class with seasonal packages today and begin reaping the many rewards of improved health and physical fitness.',
      ],
    },

    instructors: {
      title: 'Featured Top-Rated Boxing Coaches in Chicago',
      description: [
        'With the Probuddy marketplace, you can now get connected to Featured Top-Rated Boxing Coaches in Chicago easily and quickly. With over 100 Featured Top-Rated Boxing Coaches located throughout the city, you will be able to find one close by, no matter your skill level.',
        "Whether you're looking for someone to help you learn the basics or want to brush up on some advanced techniques, the Featured Top-Rated Boxing Coaches in Chicago have been instructed and trained in a variety of boxing styles to ensure that each individual's needs are met. Finding a Featured Top-Rated Boxing Coach in Chicago is easy with the Probuddy marketplace!",
      ],
      instructorsData: instructorsData('Boxing'),
    },

    featured: {
      title: 'Popular Boxing Classes and Events in Chicago',
      description: [
        "For those looking to fuel their workout and boxing ambitions in Chicago, Probuddy Marketplace has the answer. Popular classes and events focused on boxing are hosted weekly, making it easier than ever to get involved in the world of boxing. Whether you're looking to improve your technical skills, gain strength or endurance, or network with amateur and professional fighters, these Premier Clubs are the way to go.",
        'With experienced trainers at hand and top of the line equipment, many have discovered that they are able to reach their fitness goals faster and more effectively through these different courses. Get ready for your next boxing adventure by visiting Probuddy Marketplace today!',
      ],
    },

    topRatedClasses: {
      title: 'Featured Boxing Classes in Chicago',
      description: [
        'Featured Boxing Classes in Chicago by Probuddy Marketplace provide an unparalleled fitness opportunity. For those looking to take their workout regimen up to the next level, boxing classes are an excellent choice. Featured at Probuddy Marketplace, these classes feature experienced boxing coaches that hail from all parts of the city, giving members a chance to learn training methods from multiple cultures around Chicago.',
        'Not only does this create an exceptional learning opportunity but it also creates a unique diversity in a single class. Featured boxing classes from Probuddy Marketplace are an outstanding way for individuals in Chicago to gain insight into the sport and stay active.',
      ],
    },
  },
  {
    id: 'seattle',
    title: 'seattle',

    section1: {
      heading: 'The Best Boxing Classes in Seattle',
      description: [
        'The Best Boxing Classes in Seattle can be found using the Probuddy Marketplace. The platform allows you to compare a variety of classes side-by-side, helping you find the perfect class for your goals and fitness level. The marketplace also offers information on boxing instructors, prices, locations and ratings from other users - giving you comprehensive insight into each program. The Probuddy Marketplace is your go-to destination for finding The Best Boxing Classes in Seattle.',
      ],
      linkText: 'Book A Boxing Class Now',
    },

    section2: {
      heading: 'Find Your Perfect Boxing Coach in Seattle',
      description: [
        'Seattle has a fantastic selection of boxing coaches who can help you progress rapidly in the sport or just learn some basic skills. With Probuddy, finding your perfect boxing coach in Seattle can be easy and stress-free. Search through local coaches that fit your budget, ability, and experience level.',
        "Find one with the right philosophy on teaching and one that's focused on helping you reach your boxing dreams. Start your journey towards becoming a better boxer today with a high quality, experienced coach from Probuddy - Find Your Perfect Boxing Coach in Seattle.",
      ],
      linkText: 'Become A Boxing Pro',
    },

    classesForYou: {
      title: 'Book Your Boxing Classes in Seattle',
      description: [
        "Booking boxing classes in Seattle has never been so easy! With Probuddy Marketplace, find the perfect match for you—from professional instructors to group workshops and more. Whether you're just starting out or a seasoned pro at the sport, there's something for everyone. With our search tool and filters, finding the perfect class is a breeze. Book your boxing classes in Seattle today with Probuddy Marketplace!",
      ],
    },

    instructors: {
      title: 'Featured Top-Rated Boxing Coaches in Seattle',
      description: [
        'Looking for a Featured Top-Rated Boxing Coach in Seattle? Look no further than Probuddy Market Place! Here, you can explore and compare different Featured Top-Rated Boxing Coaches in the Seattle area to find the best fit for your training needs.',
        'With dozens of Featured Top-Rated Boxing Coaches to choose from, Probuddy Market Place makes it easy to search and book your desired coach with ease. Start your journey toward success now and book a Featured Top-Rated Boxing Coach available in Seattle today!',
      ],
      instructorsData: instructorsData('Boxing'),
    },

    featured: {
      title: 'Popular Boxing Classes and Events in Seattle',
      description: [
        "Popular Boxing classes and events in Seattle are easily accessible via Probuddy Marketplace. Our platform offers personal connections to the best professionals available in the city, so no matter what level of boxing you're interested in, there's something for everyone.",
        "Whether you're an experienced fighter looking for a new challenge or are just starting out, Probuddy makes finding the perfect class or event fast and easy. With succinct descriptions and reviews from previous attendees, you can always be sure your experience is exactly what you need. So if Popular Boxing Classes and Events in Seattle sound like something that interests you, take a look at Probuddy today!",
      ],
    },

    topRatedClasses: {
      title: 'Featured Boxing Classes in Seattle',
      description: [
        'At Probuddy Marketplace, we are proud to feature a range of boxing classes in Seattle. With an ever-evolving selection, these classes provide all the benefits and thrills of boxing, tailored for any level.',
        'Our instructors bring passion, knowledge and creativity to their classes—providing a dynamic learning opportunity within a fun and supportive community environment. Whether you’re looking to throw a punch or simply improve your hand-eye coordination and fitness, our Featured Boxing Classes in Seattle have something to offer. Check them out today!',
      ],
    },
  },
  {
    id: 'manchester',
    title: 'manchester',

    section1: {
      heading: 'The Best Boxing Classes in Manchester',
      description: [
        "The Best Boxing Classes in Manchester can be found through Probuddy Marketplace. Our marketplace offers boxing enthusiasts access to a range of stimulating classes like Sparring, Power Bag, and Technical Clinics. Our instructors have a wealth of experience and knowledge to help improve form, technique and knowledge. You can choose from courses that are tailored to meet the you're skill level and goals as a boxer. The Marketplace also offers personal trainers for specialised coaching to upskill. The choice is yours so start exploring The Best Boxing Classes in Manchester today through Probuddy Market Place!",
      ],
      linkText: 'Book A Boxing Class Now',
    },

    section2: {
      heading: 'Find Your Perfect Boxing Coach in Manchester',
      description: [
        "Finding the right boxing coach for you can be a daunting task, especially in Manchester where there is an abundance of coaches offering their services. Probuddy's Marketplace has made this process easier than ever.",
        "Find Your Perfect Boxing Coach in Manchester with Probuddy Marketplace by searching through their database of reputable coaches who specialize in boxing and many other disciplines. Whether you're a seasoned fighter or just starting out on your boxing journey, Probuddy can help find the perfect coach to help reach your full potential!",
      ],
      linkText: 'Become A Boxing Pro',
    },

    classesForYou: {
      title: 'Book Your Boxing Classes in Manchester',
      description: [
        'Booking your boxing classes in Manchester with Probuddy Marketplace is the perfect way to get fit in a fun and exciting manner. Our platform connects you with top-notch boxing teachers so that you can have the best learning experience available right in the comfort of your home.',
        'Booking your classes is easy and the teachers come equipped with all necessary equipment, so all you have to do is focus on improving your skill! Book now and get ready to be fit and fabulous.',
      ],
    },

    instructors: {
      title: 'Featured Top-Rated Boxing Coaches in Manchester',
      description: [
        "Probuddy Marketplace is proud to feature the top-rated boxing coaches in Manchester. These individuals have shaped many aspiring and accomplished athletes' training and have led them to success in the competitive sport of boxing.",
        'Each featured coach has demonstrated their expertise through experiences, qualifications, and verifiable contributions to the field of boxing, allowing them to be honored as some of the best teachers and mentors in Manchester. Whether you are a beginner or looking to improve your existing skills, these Featured Top-Rated Boxing Coaches are sure to provide the best guidance available.',
      ],
      instructorsData: instructorsData('Boxing'),
    },

    featured: {
      title: 'Popular Boxing Classes and Events in Manchester',
      description: [
        'The Probuddy Marketplace holds a variety of popular boxing classes and events in Manchester. From beginners to experienced boxers, everyone will find something to enjoy as they learn the basics of boxing or perfect their current skills. The experienced boxes are split into separate classes so that each person can learn at the pace that best suits them.',
        "They also offer short or long-term sessions depending on what time constraints they may have. Whether someone is looking for a fun work out or increased fitness and technique, there is a class available to fit your needs. So if you're looking for something fun and active in Manchester then head on over to Probuddy marketplace for their amazing boxing classes and events!",
      ],
    },

    topRatedClasses: {
      title: 'Featured Boxing Classes in Manchester',
      description: [
        "Get ready for a different kind of fitness journey that's sure to leave you feeling empowered and confident! Featured boxing classes in Manchester in the Probuddy Marketplace are designed to help you gain strength and tone up at the same time. Take on world-class coaching offered by certified professionals with expertise in both traditional and mixed martial arts styles.",
        "Meet likeminded people, develop advanced boxing skills and learn the most current techniques used by pros across the globe. Manchester's Premier Boxing Classes are guaranteed to provide an unforgettable experience that will have you coming back for more!",
      ],
    },
  },
  {
    id: 'san-francisco',
    title: 'san-francisco',

    section1: {
      heading: 'The Best Boxing Classes in San Francisco',
      description: [
        'The Best Boxing Classes in San Francisco are now available on the Probuddy Marketplace! The Probuddy Marketplace is home to hundreds of trainers and instructors offering a variety of workout classes, including boxing. The classes offered cover everything from basic boxing technique to advanced levels of form, power, and speed. With so many options to choose from, it’s easy to find classes that suit all fitness levels and goals.',
        'The experienced trainers ensure that your experience is safe and enjoyable, ensuring you get the most out of each class. Get ready to take your routine to the next level with The Best Boxing Classes in San Francisco available on the Probuddy Marketplace!',
      ],
      linkText: 'Book A Boxing Class Now',
    },

    section2: {
      heading: 'Find Your Perfect Boxing Coach in San Francisco',
      description: [
        'Find the perfect boxing coach for you in San Francisco with the Probuddy Marketplace. Browse verified coaches, read reviews, and book your training sessions online. Elevate your boxing game and reach your fitness goals with Probuddy.',
      ],
      linkText: 'Become A Boxing Pro',
    },

    classesForYou: {
      title: 'Book Your Boxing Classes in San Francisco',
      description: [
        'Looking to take your boxing skills to the next level? Look no further than Probuddy Marketplace. Our platform connects you with the best boxing coaches in San Francisco. Our coaches are verified, experienced and have the skills to help you reach your fitness and boxing goals.',
        'With our easy to use platform, you can browse coaches, read reviews and book your classes online. No more calling around or searching for a coach, Probuddy has you covered. So, book your boxing classes in San Francisco today and start your journey to become a better boxer',
      ],
    },

    instructors: {
      title: 'Featured Top-Rated Boxing Coaches in San Francisco',
      description: [
        'Probuddy Marketplace is proud to feature top-rated boxing coaches in San Francisco. Our coaches are handpicked and verified, ensuring that you are getting the best training possible. They are experts in the field and have the skills and experience to help you reach your boxing goals.',
        "Whether you're a beginner looking to learn the basics or a seasoned boxer looking to take your skills to the next level, our coaches have the knowledge and expertise to help you succeed. With our platform, you can easily browse through our featured coaches, read reviews, and book your training sessions online. Take your boxing to the next level with Probuddy's top-rated coaches in San Francisco.",
      ],
      instructorsData: instructorsData('Boxing'),
    },

    featured: {
      title: 'Popular Boxing Classes and Events in San Francisco',
      description: [
        'Probuddy Marketplace is your one-stop destination for all things boxing in San Francisco. Our platform offers a wide variety of popular boxing classes and events for all skill levels. From beginner classes that teach the basics of boxing to advanced classes that focus on technique and conditioning, we have something for everyone.',
        "We also offer events such as boxing tournaments, seminars, and workshops. Our platform is updated regularly, so you can always find the latest classes and events happening in the city. With Probuddy, you'll never miss out on the best boxing experiences San Francisco has to offer.",
      ],
    },

    topRatedClasses: {
      title: '"Featured Boxing Classes in San Francisco',
      description: [
        "Probuddy Marketplace is proud to feature some of the best boxing classes in San Francisco. Our platform offers a wide range of classes that cater to different skill levels and goals. Whether you're a beginner looking to learn the basics or a seasoned boxer looking to take your skills to the next level, we have something for you.",
        "Our featured classes are taught by experienced and verified coaches who are passionate about helping you reach your goals. From group classes to private lessons, our classes are designed to help you improve your technique, strength, and fitness. With our user-friendly platform, you can easily browse through our featured classes, read reviews, and book your spot online. Get ready to take your boxing skills to the next level with Probuddy's featured classes in San Francisco.",
      ],
    },
  },
  {
    id: 'houston',
    title: 'houston',

    section1: {
      heading: 'The Best Boxing Classes in Houston',
      description: [
        'The Best Boxing Classes in Houston can be found in the Probuddy. The app allows you to easily find classes with experienced instructors that offer everything from Vinyasa Boxing to Restorative Boxing. The user friendly interface guides you quickly to a variety of classes, so that even busy professionals can take some time out of their day for a relaxing stretching session or an energizing flow class.',
        'The Boxing community in Houston is supportive and inviting, helping yogis of all experience levels improve their practice and cultivate a healthier lifestyle. Join hundreds of like-minded people on the path to discovering the best version of themselves with The Best Boxing Classes in Houston through Probuddy App!',
      ],
      linkText: 'Book A Boxing Class Now',
    },

    section2: {
      heading: 'Find Your Perfect Boxing Teacher in Houston',
      description: [
        'Find Your Perfect Boxing Teacher in Houston with Probuddy - the best way to get customized guidance! probuddy is an innovative platform that connects users to certified Boxing experts for personalized support and advice. Find the perfect instructor for your needs through our online directory of instructors from across Houston or speak directly to a member of our team to discuss which teacher might be the best fit for you.',
        "With Probuddy, you have access to the most qualified and experienced practitioners so you can feel confident that you'll receive quality instruction. Get started today and find your perfect Boxing teacher in Houston with Probuddy!",
      ],
      linkText: 'Become A Boxing Pro',
    },

    classesForYou: {
      title: 'Book Your Boxing Classes in Houston with Probuddy',
      description: [
        'Booking a Boxing class in Houston has never been so easy! Probuddy is an online platform that allows you to easily find and book the right Boxing class for your needs. Whether you are looking for a restorative, heated vinyasa, or hot power class,',
        'Probuddy helps connect users with the best nearby studio and instructor in order to get you into downward dog as soon as possible. Book Your Boxing Classes in Houston with Probuddy to experience the comprehensive and convenient way to get sweat on your mat!',
      ],
    },

    instructors: {
      title: 'Featured Top-Rated Boxing Instructor in Houston',
      description: [
        'At Probuddy, we are proud to be featuring the top-rated Boxing instructor in Houston! Our careful selection of this experienced teacher reflects our commitment to providing the highest quality Boxing instruction for our patrons. Whether you are a beginner looking for a gentle introduction to the practice or an advanced yogi looking for challenging poses and sequences, this instructor has something to offer everyone.',
        'With experience teaching in multiple studios throughout Houston over the past several years, this instructor is sure to provide an outstanding class that will leave you feeling energized and ready to tackle your day. Join us at Probuddy and meet our Featured Top-Rated Boxing Instructor in Houston today!',
      ],
      instructorsData: instructorsData('Boxing'),
    },

    featured: {
      title: 'Popular Boxing Classes and Events in Houston',
      description: [
        'If you’re looking for Popular Boxing Classes and Events in Houston, the Probuddy is your one-stop shop. With a comprehensive selection of events to choose from, you are guaranteed to find the perfect class for whatever type of Boxing experience you’re after.',
        'Be it an energizing vinyasa class or something more restorative like yin or meditation, you can take advantage of all the options available on the app. And if that wasn’t enough, Probuddy offers some of the best deals around so that you don’t have to worry about breaking the bank to stay active and benefit from Boxing!',
      ],
    },

    topRatedClasses: {
      title: 'Featured Boxing Classes in Houston',
      description: [
        'Featured Boxing Classes in Houston, offered by the Probuddy, offer a huge variety of styles and methods to suit your individual goals. Whether you are looking for something basic and meditative, a challenging workout session or something in between, Houston classes will suit you perfectly.',
        'Depending on what works best for you, choose classes that concentrate on breath-work, relaxation, postures or energy-level balancing - all of which are crafted by experienced and certified professionals. Unlock the power of Boxing in Houston with Probuddy today.',
      ],
    },
  },
];
// need to change it
export const physicalTherapyData = [
  {
    id: 'singapore',
    title: 'singapore',
    section1: {
      heading: 'Popular Physical Therapist in Singapore',
      description: [
        "Finding Physical Therapists in Singapore can be difficult, especially with the multitude of options available. That's why Probuddy is the best choice for Physical Therapy - we provide secure and accessible Physical Therapy services by connecting you directly to certified Physical Therapists in your area! Our Physical Therapists come from all over Singapore, so you're guaranteed to find a Physical Therapy that fits your needs and style.",
        "The Physical Therapists on Probuddy have varying levels of experience and knowledge catering to any ailment or injury. With their expertise and insight, you can rest assured that you'll be receiving the best Physical Therapy possible. Sign up now to get connected with Physical Therapists in Singapore today!",
      ],
      linkText: 'Book A physical therapy class',
    },

    section2: {
      heading: 'Search for the Best Physical Therapist in Singapore',
      description: [
        'Physical therapy is a highly beneficial treatment for many injuries, aches and illnesses. However, not all Physical Therapists are equal and finding the best Physical Therapist can sometimes be a daunting task. Finding the perfect Physical Therapist for you just got easier with Probuddy, which offers Physical Therapy in Singapore with ease.',
        'Probuddy provides comprehensive Physical Therapy services to meet your individual needs and goals, as well as offering access to more than 600 Physical Therapists who are experienced and professional in their field.',
        "Get started on your Physical Therapy journey today with Probuddy's online search system to find the best Physical Therapist suited for your needs!",
      ],
      linkText: 'See Our physical therapy Listings',
    },

    classesForYou: {
      title: 'Book Online on Probuddy for Physical Therapist',
      description: [
        'Physical therapy is a great way to help with chronic pain, injury rehabilitation, and preventive care. With Probuddy, you can easily book Physical Therapy appointments online with certified Physical Therapists in Singapore. ',
        'At Probuddy we believe that Physical Therapists should have convenient access to as many resources as possible so they are able to offer their services to people in need. With our reliable booking technology and customer service assistance, we strive to make Physical Therapy easier for Physical Therapists so they can focus on providing the best care possible.',
        'Not only do we make Physical Therapy more accessible, but we also guarantee a customer experience unlike any other by offering affordable rates and flexible hours. Book your Physical Therapy appointment online with Probuddy today!',
      ],
    },
  },
  {
    id: 'sydney',
    title: 'sydney',

    section1: {
      heading: 'Leading Physical Therapist in Sydney',
      description: [
        'Physical therapists are gaining much popularity in Sydney nowadays. Many people are seeking out the expertise of physical therapists to help heal their ailments and injuries. Physical therapy involves using specific exercises, stretches, and techniques to help improve mobility, strength and overall wellness.',
        "Probuddy is one of the most popular Physical Therapists in Sydney as they provide excellent treatments based on each individual's needs. Their experienced team is highly trained in Physical Therapy and uses evidence-based practices that are tailored towards a patient's lifestyle.",
        'From general pain relief to more serious orthopedic conditions, Probuddy Physical Therapists can provide reliable care that helps patients feel better quickly.',
      ],
      linkText: 'Book A physical therapy class',
    },

    section2: {
      heading: 'Looking for the Best Physical Therapist in Sydney',
      description: [
        'For those looking for the best physical therapist in Sydney, look no further than Probuddy. Probuddy is a web-based platform dedicated to connecting health professionals and patients.',
        'Physical therapists are categorised according to their specialties so potential patients can have an easier time finding local practitioners with expertise in specific areas. Each Physical Therapist has their own profile page where they can view their skills, qualifications and a list of helpful resources they can access if they need it.',
        'For anyone desiring Physical Therapy in Sydney, Probuddy provides you with the means to research and make an informed decision about who you will consult.',
      ],
      linkText: 'See Our physical therapy Listings',
    },

    classesForYou: {
      title: 'Book Online on Probuddy for Physical Therapist',
      description: [
        'Physical therapy offers a great way to reduce pain, strengthen muscles and improve mobility - and no one understands that better than Probuddy. Our Physical Therapists in Sydney offer the best care possible, with an easy online booking system that puts you in control of your health.',
        'With Probuddy you can book Physical Therapy for yourself or for a loved one with confidence knowing you have access to the highest quality Physical Therapist available. Take the first step towards better health today – book Physical Therapy in Sydney with Probuddy!',
        'Not only do we make Physical Therapy more accessible, but we also guarantee a customer experience unlike any other by offering affordable rates and flexible hours. Book your Physical Therapy appointment online with Probuddy today!',
      ],
    },
  },
  {
    id: 'new-york',
    title: 'new-york',

    section1: {
      heading: 'Popular Physical Therapist in New York',
      description: [
        "Physical therapy is an important part of many people's healthcare, and it's no wonder that physical therapists in New York have become so popular. One Physical Therapist that stands out in the city is Probuddy Physical Therapy.",
        'With years of experience and a team of well-trained therapists, they are ready to help you recover quickly from any injury or pain you may be experiencing. They provide effective and compassionate physical therapy solutions tailored to the individual.',
        'Whether needing help with balance, sports-specific training or rehabilitation after an accident, their professional staff can help get you back on track. If you are looking for Physical Therapy in New York, Probuddy Physical Therapy is the best choice for quality care that gives you long lasting results.',
      ],
      linkText: 'Book A physical therapy class',
    },

    section2: {
      heading: 'Find the Best Physical Therapist in New York',
      description: [
        'Finding a physical therapist in New York can be a daunting task. With so many options to choose from, it can be difficult to navigate the process of finding the right care. With Probuddy Physical Therapy in New York, you can find the perfect provider to meet your needs.',
        'ProBuddy evaluates experienced Physical Therapists in New York and only accepts those who pass a rigorous evaluation process based on experience and skill level. Once accepted into the network, Physical Therapists must adhere to strict quality guidelines and standards established by ProBuddy Physical Therapy. All Physical Therapists are also monitored for patient feedback to ensure ongoing satisfaction with their services.',
        'With ProBuddy Physical Therapy in New York, you can rest assured that you will receive high quality Physical Therapy that is tailored to your individual needs from a highly trained professional who will work with you to ensure lasting results and improved overall health.',
      ],
      linkText: 'See Our physical therapy Listings',
    },

    classesForYou: {
      title: 'Book Online on Probuddy for Physical Therapist',
      description: [
        'Physical therapy can be an invaluable service for people struggling with injuries and chronic pain. Finding experienced physical therapists nearby is not always easy, however. Fortunately, Probuddy makes it simpler than ever to book Physical Therapy in New York fast.',
        'Probuddy takes out the guesswork of finding a Physical Therapist quality and reliability you can count on. Make sure to check Probuddy for Physical Therapist today – whether you’re dealing with an injury or just trying to stay healthy!',
      ],
    },
  },
  {
    id: 'london',
    title: 'london',

    section1: {
      heading: 'Best Physical Therapist in London',
      description: [
        'If you are in search for the best Physical Therapist in London, then look no further than Probuddy! Physical Therapy with Probuddy is not only the most trusted but highly recommended service throughout London and its outskirts.',
        'With their expertise Physical Therapists, they are confident to meet your physical needs and help you reach your goals faster. Probuddy Physical Therapy is equipped with the latest technology, so you know that you’ll be getting top-notch treatment with results that will last.',
        'Moreover, what sets them apart from other Physical Therapy services in London is their commitment to customer satisfaction — prospects can expect nothing but compassionate care from each team member. With Probuddy Physical Therapy, you’ll get back to doing your favorite activities in a healthier state!',
      ],
      linkText: 'Book A physical therapy class',
    },

    section2: {
      heading: 'Look for the Best Physical Therapist in London',
      description: [
        "If you're searching for the best Physical Therapist in London, look no further than Probuddy Health. Their Physical Therapists are carefully chosen by experts to ensure that their clients get only the best treatment.",
        'With decades of experience, they offer comprehensive Physical Therapy solutions designed to address your injury or factors impacting your overall well-being. Whether you need rehabilitation from an injury, help managing chronic pain, or just want to improve your movement and flexibility, their Physical Therapists can provide the therapy you need in a comfortable setting.',
        "With careful and personalized attention for each individual's needs, Probuddy Health is sure to have a Physical Therapist that will exceed your expectations.",
      ],
      linkText: 'See Our physical therapy Listings',
    },

    classesForYou: {
      title: 'Book Online on Probuddy for Physical Therapist',
      description: [
        "Physical therapy is a great way to heal the body from injuries caused by sports, accidents or lifestyle disorders. With Probuddy’s convenient online booking system for Physical Therapist in London, it's now easier than ever to schedule Physical Therapy appointments.",
        'Probuddy takes the hassle out of finding quality Physical Therapy services and makes planning your sessions simple and stress-free. All it takes is registering on the platform and you can browse through verified Physical Therapists in London that fit your needs, book an appointment duration according to your convenience, and even get help with scheduling follow-up treatments.',
        'Physical therapy has proved its worth time and again in helping people feel better, faster -- so make sure you take advantage of Probuddy’s unrivalled online booking services for Physical Therapists in London!',
      ],
    },
  },
  {
    id: 'los-angeles',
    title: 'los-angeles',

    section1: {
      heading: 'Popular Los Angeles Physical Therapist',
      description: [
        "Physical therapy in Los Angeles has become a popular way of treating a variety of health issues and maintaining overall body health. Fortunately, there's Probuddy Physical Therapists to help you find the best healing solutions through Physical Therapy.",
        'With expertise, experience, and knowledge gained from working with top-notch Physical Therapists in Los Angeles, Probuddy Physical Therapists have become well known as one of the most reliable Physical Therapy service providers in the city.',
        "So if you're looking for the latest treatments and excellent Physical Therapy services, start by making an appointment with Probuddy Physical Therapists today!",
      ],
      linkText: 'Book A physical therapy class',
    },

    section2: {
      heading: 'Looking for the Best Physical Therapist in Los Angeles',
      description: [
        "If you’re in search of the best Physical Therapist in Los Angeles, look no further than Probuddy. Physical Therapy has never been easier to find; with our comprehensive directory of Physical Therapists across Los Angeles and surrounding areas you'll be matched with skilled professional perfectly suited for your needs.",
        'Our Physical Therapists are well-trained individuals dedicated to ensuring the best level of care and capable of providing personalized support throughout your recovery journey. Trust Probuddy to help you make a well informed decision when it comes to finding top-notch Physical Therapy in the Los Angeles area.',
      ],
      linkText: 'See Our physical therapy Listings',
    },

    classesForYou: {
      title: 'Book a Physical Therapist appointment online with Probuddy',
      description: [
        'Physical therapy can be vital in helping to correct musculoskeletal pains and weaknesses, and with Probuddy in the Los Angeles area, you can book online with ease. ',
        'Physical Therapy appointments are tailored physiological treatments delivered by Physical Therapists that help restore function, improve movement, relieve pain, provide education or training, and promote overall wellbeing.',
        "Probuddy's Physical Therapy services offer you access to top-notch Physical Therapists skilled at resetting your body back to optimum shape. Log in or register on their website to maximize your physical therapy goals and expedite your rehabilitation. You'll surely find convenience through their online tool's ability to book Physical Therapy appointment times that work around your hectic schedule!",
      ],
    },
  },
  {
    id: 'melbourne',
    title: 'melbourne',

    section1: {
      heading: "Melbourne's Best Physical Therapist",
      description: [
        'Physical therapy is a multifaceted and ever-evolving approach to healthcare that utilizes techniques such as manual therapy, exercise prescription, and lifestyle modifications for rehabilitation.',
        'If you’re looking for the best Physical Therapist in Melbourne, look no further than Probuddy! The professionals at this Physical Therapy practice are highly trained and specialize in providing comprehensive care packages tailored to each patient’s individual needs.',
        'Whether you’ve been injured in an accident or simply want to improve your overall mobility, Probuddy Physical Therapists are here to provide necessary treatments with a safety-first environment that allows patients to relax and heal at their own pace. Get back on track with Physical Therapy in Melbourne with Probuddy today!',
      ],
      linkText: 'Book A physical therapy class',
    },

    section2: {
      heading: 'Search for the Best Physical Therapist in Melbourne',
      description: [
        "If you're looking for the best Physical Therapist in Melbourne, Probuddy is here to help. We understand finding a Physical Therapist can be difficult, so we've made it simpler than ever to have Physical Therapy in Melbourne with Probuddy. Our extensive network of Physical Therapists and Rehabilitation Providers ensures that you will find the Physiotherapist that's right for your individual needs.",
        'Whether you are an athlete or injured patient, our Physical Therapists will dedicate their knowledge to ensure you regain movement and reduce pain quickly and efficiently. With more than 10 years of experience, you can trust Probuddy to provide only the highest quality Physical Therapy services in Melbourne.',
      ],
      linkText: 'See Our physical therapy Listings',
    },

    classesForYou: {
      title: 'Book a Physical Therapist Appointment Online with Probuddy',
      description: [
        'Physical therapy is an important part of health care, and at Probuddy we make it easy to access Physical Therapy services in Melbourne with our online appointment booking tool. Our Physical Therapists are all expertly trained and are ready to help you on your path to recovery.',
        "With Probuddy, you can easily book an appointment for Physical Therapy with the comfort of knowing that you're getting the best treatment options in Melbourne. All Physical Therapists we work with are highly qualified and experienced in the field, ensuring that your Physical Therapy treatments will be effective and safe. So stop searching and start healing today, book your Physical Therapy appointment online with Probuddy!",
      ],
    },
  },
  {
    id: 'chicago',
    title: 'chicago',

    section1: {
      heading: 'Leading Physical Therapist in Chicago',
      description: [
        'A Physical Therapist in Chicago with Probuddy can be the perfect solution for your physical health needs. Probuddy Physical Therapists provide high quality and advanced care tailored to your specific needs.',
        'Our Physical Therapists are experienced professionals who specialize in providing a comprehensive understanding of each individual’s needs and then develop an effective plan to help them reach their lifestyle goals. With a Physical Therapist from Probuddy, you can work on identifying the root causes of your issues and develop personalized plans for achieving long-term recovery.',
        'Whether you have chronic pain, sports injury, joint stiffness, post-operative rehabilitation or any other physical issues, our Physical Therapist in Chicago has the right expertise to ensure you get the best possible treatment.',
      ],
      linkText: 'Book A physical therapy class',
    },

    section2: {
      heading: 'Looking for the Best Physical Therapist in Chicago',
      description: [
        'If you’re looking to find the best Physical Therapist in Chicago, look no further than Probuddy. Physical therapy plays an integral role in improving your physical health and well-being, and it’s important to choose a Physical Therapist with credentials, experience, and expertise.',
        'Probuddy is the leading Physical Therapy provider in Chicago, working with patients to provide holistic treatments for a range of issues. From post-operative recovery and diagnostic assessments to muscular rehabilitation and sports performance enhancement - Probuddy has it all! ',
        'With patients from all walks of life, Probuddy ensures that each individual receives customized attention tailored to their wellbeing needs. Stop wasting time – book your Physical Therapy appointment today with Probuddy and get back on track!',
      ],
      linkText: 'See Our physical therapy Listings',
    },

    classesForYou: {
      title: 'Book Online on Probuddy for Physical Therapist',
      description: [
        'Physical Therapy in Chicago just got a lot easier with Probuddy. Comfortably book an appointment for Physical Therapy online, from the comfort of your home. With Probuddy, Physical Therapists are certified and have years of experience in various specialties such as sports-related injuries and chronic pain conditions.',
        'You can search Physical Therapists near you by specialty, distance, or any other criteria that matters most to you. Featuring convenient scheduling options and transparent pricing policies, our Physical Therapist network will help make Physical Therapy more accessible in Chicago. Explore our database of PhysicalTherapists today to conveniently find and book the right one for you!',
      ],
    },
  },
  {
    id: 'seattle',
    title: 'seattle',

    section1: {
      heading: 'Looking for the Best Physical Therapist in Manchester',
      description: [
        'Physical Therapy in Manchester with Probuddy is the top choice for anyone looking to take their physical therapy goals to the next level. With a team of experienced and specialized Physical Therapists, we strive to provide top-notch results as well as personalized treatment plans.',
        'All sessions are conducted one-on-one within our safe, comfortable and welcoming environment. We prioritize customer satisfaction, offering a wide range of services such as sports injuries, orthopedic rehabilitation, work-related injuries and geriatric Physical Therapy.',
        'Our Physical Therapists are masters of their craft who combine experience and modern exercise practices to ensure that our clients reach their fullest potential while minimizing pain in the process. Come and visit us today - Physical Therapy with Probuddy in Manchester is guaranteed to be an enjoyable experience!',
      ],
      linkText: 'Book A physical therapy class',
    },

    section2: {
      heading: 'Searching for the Best Seattle Physical Therapist?',
      description: [
        'Everyone wants to get the best physical therapy treatment possible, but it can be hard to find the right Physical Therapist in Seattle. Fortunately, with Probuddy, you can easily find Physical Therapists in your area who provide top-notch Physical Therapy services.',
        "At Probuddy, we offer a wide range of Physical Therapy options from highly experienced Physical Therapists who are located in Seattle. You can also read feedback from fellow patients about the Physical Therapists and their experience with them at Probuddy. With all these resources for finding the best Physical Therapist for your needs, you'll be sure to get excellent Physical Therapy treatment with Probuddy.",
      ],
      linkText: 'See Our physical therapy Listings',
    },

    classesForYou: {
      title: 'Book a Physical Therapist Appointment Online in Seattle with Probuddy',
      description: [
        "Physical therapy can help you recover from injuries, manage pain and relieve discomfort. If you're looking for Physical Therapy in Seattle, Probuddy makes it easy to find the perfect Physical Therapist for your needs and conveniently book an appointment online.",
        'At Probuddy, we offer a wide range of Physical Therapy services including sports rehabilitation, pre- and post-operative care and chronic injury management services. With our experienced team of Physical Therapists who have years of experience in advanced motion therapies, our aim is to provide utmost satisfaction through personalised treatment plans tailored specifically to meet your short and long-term recovery goals. So book now with us and let us help you get back on track!',
      ],
    },
  },
  {
    id: 'manchester',
    title: 'manchester',

    section1: {
      heading: 'Best Physical Therapist in Manchester',
      description: [
        'Physical Therapy in Manchester with Probuddy is the top choice for anyone looking to take their physical therapy goals to the next level. With a team of experienced and specialized Physical Therapists, we strive to provide top-notch results as well as personalized treatment plans.',
        'All sessions are conducted one-on-one within our safe, comfortable and welcoming environment. We prioritize customer satisfaction, offering a wide range of services such as sports injuries, orthopedic rehabilitation, work-related injuries and geriatric Physical Therapy.',
        'Our Physical Therapists are masters of their craft who combine experience and modern exercise practices to ensure that our clients reach their fullest potential while minimizing pain in the process. Come and visit us today - Physical Therapy with Probuddy in Manchester is guaranteed to be an enjoyable experience!',
      ],
      linkText: 'Book A physical therapy class',
    },

    section2: {
      heading: 'Looking for the Best Physical Therapist in Manchester',
      description: [
        "If you're looking for top-notch Physical Therapy in Manchester, Probuddy is the place to go. Our Physical Therapists are registered healthcare professionals that specialize in treating musculoskeletal injuries and promoting physical health.",
        'With their expertise and experience combined with our cutting edge technology and personalized treatment plans, we can assist you in getting back to your best self - quickly and safely.',
        'We also provide customized Physical Therapy sessions by expert Physical Therapists, who understand your individual needs and goals so you get the most out of your treatments. So come join us at Probuddy to get the best Physical Therapy in Manchester!',
      ],
      linkText: 'See Our physical therapy Listings',
    },

    classesForYou: {
      title: 'Book Online on Probuddy for Physical Therapist in Manchester',
      description: [
        'If you are looking for Physical Therapy in Manchester, Probuddy is the place to book online! At Probuddy, you will find professional Physical Therapists from the comfort of your own home. ',
        'With step-by-step guidance, making physical therapy appointments has never been easier! Booking Physical Therapy with Probuddy means that you are getting the best Physical Therapy services for your needs.',
        'No matter what kind of Physical Therapy you need, Probuddy is here to help you! So why wait? Find the perfect Physical Therapist for your needs and book online today!',
      ],
    },
  },
  {
    id: 'san-francisco',
    title: 'san-francisco',

    section1: {
      heading: 'Best Physical Therapist in San Francisco',
      description: [
        'Physical therapy is an important part of the recovery process when dealing with a physical injury or health issue. With Physical Therapy in San Francisco with Probuddy, patients have access to the best Physical Therapists in town.',
        'Their Physical Therapists are certified professionals with experience helping individuals achieve their full potential. They work with each patient to develop a plan that fits their individual needs and creates realistic goals for long-term success.',
        'With Physical Therapy in San Francisco with Probuddy, you can rest assured knowing you are being well cared for by experienced professionals.',
      ],
      linkText: 'Book A physical therapy class',
    },

    section2: {
      heading: 'Searching for the Best Physical Therapist in San Francisco?',
      description: [
        'Physical therapy is a great way to increase mobility and improve any issues you may have with your physical health. In San Francisco, Physical Therapy with ProBuddy gives you access to the best Physical Therapists in the area all through one platform.',
        'Not only are Physical Therapists able to give consultations and advice, depending on a diagnosis from your primary care giver - they are also incredibly helpful in providing rehabilitative exercises that can ensure your body gets back in its best condition. Physical Therapy with ProBuddy makes searching for the perfect Physical Therapist for your needs easy - by simply entering information about what problem you need help with and adding some details about yourself, you can find a Physical Therapist that caters specifically to your individual level of acuity.',
        'There’s no need to search endlessly through reviews or try to narrow down your choices - let Physical Therapy with ProBuddy do the work for you!',
      ],
      linkText: 'See Our physical therapy Listings',
    },

    classesForYou: {
      title: 'Probuddy in San Francisco allows you to Book Physical Therapist Appointments Online',
      description: [
        "Physical therapy is an important part of a healthy lifestyle which can alleviate pain and help you become more active. If you're in San Francisco, you can now book physical therapy appointments online with Probuddy - a convenient service to get the support you deserve. With Probuddy, you have access to experienced Physical Therapists who are highly educated in their specialized field.",
        'They can assist with injury recovery and give lifestyle advice to keeping you healthy and free of pain. Book now and start feeling the benefits of Physical Therapy with Probuddy!',
      ],
    },
  },
  {
    id: 'houston',
    title: 'houston',
    section1: {
      heading: 'Leading Physical Therapist in Houston',
      description: [
        "Physical therapy is one of the most beneficial forms of rehabilitation, and Probuddy Physical Therapy in Houston provides exceptional care for all types of injuries. Their leading physical therapist has worked with clients ranging from post-operative trauma to chronic pain and offers individualized assessment and treatment plans tailored specifically towards each patient's goals.",
        'With extensive prior experience in many medical disciplines, this physical therapist excels in addressing musculoskeletal conditions using cutting-edge research & evidence based practice. Overall, Probuddy Physical Therapy is a great choice for anyone seeking reliable Physical Therapy treatments in Houston.',
      ],
      linkText: 'Book A physical therapy class',
    },

    section2: {
      heading: 'Looking for the Best Physical Therapist in Houston?',
      description: [
        'Physical therapy is essential for recovering from an injury, reducing chronic pain, and improving overall physical strength and mobility. When it comes to finding the best Physical Therapist in Houston, look no further than Probuddy. At Probuddy Physical Therapy, our Physical Therapists provide an individualized treatment plan that suits your specific needs as well as goals and lifestyle.',
        'We use evidence-based treatment methods and specialize in a wide range of therapies including manual therapy, joint mobilization, exercise programs, neuromuscular re-education, functional integration, aquatic rehabilitation and more. Each Physical Therapist is committed to providing quality care that is compassionate, comprehensive and client-centered.',
        "With years of experience providing Physical Therapy in Houston you can rest assured you're receiving quality care with encouraging results.",
      ],
      linkText: 'See Our physical therapy Listings',
    },

    classesForYou: {
      title: 'Book Online on Probuddy for Physical Therapist in Houston',
      description: [
        'Physical therapy can be both a challenge and a blessing to find in Houston. Thankfully, with Probuddy you can easily access Physical Therapist located in the heart of the great city of Houston. Just visit the Probuddy website, browse through the Physical Therapy services available and book your desired session online! From pain management to sports injury rehabilitation and orthopedic injuries, Probuddy connects you with experienced Physical Therapists who are conveniently located in Houston. ',
        'With exhaustive sessions and flexible timings, you won’t have to worry about waiting days just to get an appointment. So book your Physical Therapy today with Probuddy for an efficient and reliable way of getting back on track!',
      ],
    },
  },
];

export const getCategoriesData = param => {
  switch (param) {
    case 'yoga':
      return yogaCityData;
    case 'tennis':
      return tennisCityData;
    case 'pilates':
      return pilatesCityData;
    // case 'golf':
    //   return golfCityData;
    case 'boxing':
      return boxingCityData;
    case 'personal-training':
      return physicalTherapyData;

    default:
      return config.custom.categoriesDataConfig;
  }
};
