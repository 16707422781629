import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconUsers.css';

const IconUsers = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg className={classes} xmlns="http://www.w3.org/2000/svg" width="71" height="71" viewBox="0 0 71 71" fill="none">
      <path d="M60.7506 37.9019H55.2598C55.8194 39.4342 56.1252 41.0878 56.1252 42.8114V63.5637C56.1252 64.2823 56.0001 64.972 55.7726 65.6135H64.8502C68.2411 65.6135 70.9997 62.8548 70.9997 59.464V48.151C70.9999 42.4997 66.4021 37.9019 60.7506 37.9019Z" fill="#0095B3" />
      <path d="M14.8747 42.8116C14.8747 41.0879 15.1804 39.4343 15.7401 37.902H10.2493C4.59781 37.902 0 42.4998 0 48.1513V59.4643C0 62.8551 2.75861 65.6138 6.14955 65.6138H15.2273C14.9997 64.9721 14.8747 64.2824 14.8747 63.5638V42.8116Z" fill="#0095B3" />
      <path d="M41.7763 32.5623H29.2234C23.5719 32.5623 18.9741 37.1601 18.9741 42.8115V63.5638C18.9741 64.6958 19.8919 65.6137 21.024 65.6137H49.9757C51.1078 65.6137 52.0255 64.696 52.0255 63.5638V42.8115C52.0255 37.1601 47.4277 32.5623 41.7763 32.5623Z" fill="#0095B3" />
      <path d="M35.5003 5.38602C28.7037 5.38602 23.1743 10.9154 23.1743 17.7122C23.1743 22.3223 25.7188 26.3487 29.4766 28.4626C31.2589 29.4652 33.3137 30.0382 35.5003 30.0382C37.6869 30.0382 39.7418 29.4652 41.5241 28.4626C45.282 26.3487 47.8263 22.3222 47.8263 17.7122C47.8263 10.9156 42.2969 5.38602 35.5003 5.38602Z" fill="#0095B3" />
      <path d="M13.8559 16.8749C8.7729 16.8749 4.6377 21.0101 4.6377 26.0931C4.6377 31.1761 8.7729 35.3113 13.8559 35.3113C15.1453 35.3113 16.3731 35.0443 17.4884 34.5642C19.4168 33.7339 21.0068 32.2643 21.9907 30.4234C22.6813 29.1314 23.0742 27.6575 23.0742 26.0931C23.0742 21.0102 18.939 16.8749 13.8559 16.8749Z" fill="#0095B3" />
      <path d="M57.1445 16.8749C52.0615 16.8749 47.9263 21.0101 47.9263 26.0931C47.9263 27.6576 48.3191 29.1316 49.0097 30.4234C49.9936 32.2644 51.5836 33.7341 53.512 34.5642C54.6273 35.0443 55.8551 35.3113 57.1445 35.3113C62.2275 35.3113 66.3627 31.1761 66.3627 26.0931C66.3627 21.0101 62.2275 16.8749 57.1445 16.8749Z" fill="#0095B3" />
    </svg>
  );
};

IconUsers.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconUsers.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconUsers;
