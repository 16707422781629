import { memoize } from 'lodash/function';

export const getRatingFromReviews = memoize(reviews => {
  if (!reviews || !reviews.length) {
    return 0;
  }

  const reviewsSum = reviews.reduce((acc, review) => acc + review.attributes.rating, 0);
  return reviewsSum / reviews.length;
});

export const getRatingFromMetadata = (metadata) => {
  const rating = metadata && metadata.rating;
  if (rating && typeof rating == 'number' && rating > 0) {
    return metadata.rating;
  } else {
    return 0
  }
};