import React from 'react';
import FacilitiesInlineListingCard from './FacilitiesInlineListingCard';

const FacilitiesComponent = props => {
  const { className, listings, selectedDate, allClassListingReviews, showAll } = props;

  return (
    <div>
      {listings.map(l => (
        <FacilitiesInlineListingCard
          className={className}
          key={l.id.uuid}
          listing={l}
          review={allClassListingReviews ? allClassListingReviews[l.id.uuid] : []}
          selectedDate={selectedDate}
          showAll={showAll}
        />
      ))}
    </div>
  );
};

export default FacilitiesComponent;
