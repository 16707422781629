import React, { useMemo, useState } from 'react';
import { injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import {
  Footer,
  FooterBottomMenu,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  LeftBar,
  Modal,
  NamedLink,
  Page,
} from 'components';
import TopbarContainer from 'containers/TopbarContainer/TopbarContainer';
import { ShareForm } from 'forms';
import logo from 'assets/companyLogo.svg';
import { compose } from 'redux';
import { withViewport } from 'util/contextHelpers';
import config from 'config';
import { IMAGES } from 'util/imagesPath';
import { isScrollingDisabled } from 'ducks/UI.duck';
import { data as siteData } from 'containers/BlogPage/BlogPage.data';
import classNames from 'classnames';
import css from 'containers/BlogPage/BlogPage.css';

const CategoryBlogPage = ({ isTennisSite, isEducationSite, viewport, params, intl, ...props }) => {
  const scrollingDisabled = useSelector(state => isScrollingDisabled(state));

  const [isOpenShareForm, setIsOpenShareForm] = useState(false);
  const [activeFooterTab, setActiveFooterTab] = useState('');
  const [showBlog, setShowBlog] = useState(null);

  const viewportWidth = viewport.width;
  const MAX_MOBILE_SCREEN_WIDTH = 1024;
  const isMobileLayout = useMemo(
    () => (!!viewportWidth ? viewportWidth < MAX_MOBILE_SCREEN_WIDTH : false),
    [viewportWidth]
  );
  const schemaTitle = intl.formatMessage(
    { id: isEducationSite ? 'EducationBlogPage.schemaTitle' : 'BlogPage.schemaTitle' },
    { siteTitle: config?.siteTitle }
  );
  const schemaDescription = intl.formatMessage({
    id: isEducationSite ? 'Page.schemaDescription' : 'HomePage.schemaDescription',
  });
  const schemaImage = `${config.canonicalRootURL}${IMAGES.facebookImage}`;

  const url = typeof window !== 'undefined' && window.location.href;

  // if (!siteData[params?.slug]) {
  //   return <NotFoundPage isEducationSite={isEducationSite} />;
  // }

  console.log(siteData[params?.slug]);

  return (
    <Page
      scrollingDisabled={scrollingDisabled}
      isBottomMenuRequire={false}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: IMAGES.facebookImage, width: 1200, height: 630 }]}
      twitterImages={[
        {
          url: `${config.canonicalRootURL}${IMAGES.twitterImage}`,
          width: 600,
          height: 314,
        },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer
            setIsOpenShareForm={setIsOpenShareForm}
            isEducationSite={isEducationSite}
            // isTennisSite={isTennisSite}
          />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain>
          <div className={classNames(css.main)}>
            {!isMobileLayout && (
              <LeftBar
                history={props.history}
                // onShowEnquiryTab={onSearch}
                onClose={() => setActiveFooterTab('search')}
                isEducationSite={isEducationSite}
              />
            )}
            <div className={css.cardContainer}>
              <div className={css.breadCrumbWrapper}>
                <NamedLink name={isEducationSite ? 'EducationPage' : 'HomePage'}>Home</NamedLink>
                <span className={css.breadcrumbArrow}>&gt;</span>
                <NamedLink
                  name={isEducationSite ? 'EducationBlogPage' : 'BlogPage'}
                  className={css.currentListingTypeName}
                >
                  Blogs
                </NamedLink>
                <span className={css.breadcrumbArrow}>&gt;</span>
                <NamedLink
                  name={isEducationSite ? 'EduCategoryBlogPage' : 'CategoryBlogPage'}
                  params={{
                    slug: params?.slug,
                  }}
                  className={css.currentListingTypeName}
                >
                  {params?.slug}
                </NamedLink>
              </div>
              {!siteData[params?.slug] ? (
                <p>No Blogs Available</p>
              ) : (
                <div className={css.cardWrap} style={{ alignItems: 'flex-start' }}>
                  <div style={{ display: 'flex', gap: '10px' }}>
                    <img src={logo} alt="logo" />
                    <div className={css.blog}>{siteData[params?.slug]?.title}</div>
                  </div>
                  <div>{siteData[params?.slug]?.description}</div>
                  <div className={css.cardWrapper}>
                    {siteData[params?.slug]?.children?.length ? (
                      siteData[params?.slug]?.children.map((ele, ind) => (
                        <div className={css.card} key={`${ele?.title} ${ind}`}>
                          <img src={ele.img} />
                          <h3 className={css.cardTitle}>{ele?.title}</h3>
                          {ele?.slug ? (
                            <NamedLink
                              name={isEducationSite ? 'EduShowBlogPage' : 'ShowBlogPage'}
                              params={{
                                slug: params?.slug,
                                blogSlug: ele?.slug,
                              }}
                              className={css.currentListingTypeName}
                            >
                              Read More
                            </NamedLink>
                          ) : (
                            <a
                              onClick={e => {
                                e.preventDefault();
                                setShowBlog(ind);
                              }}
                            >
                              Read More
                            </a>
                          )}
                        </div>
                      ))
                    ) : (
                      <p>No Blogs Available</p>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </LayoutWrapperMain>
        {!isMobileLayout ? (
          <LayoutWrapperFooter>
            <Footer isEducationSite={isEducationSite} />
          </LayoutWrapperFooter>
        ) : (
          <LayoutWrapperFooter
            className={classNames(css.layoutFooter, {
              [css.activeleftbarFooter]: activeFooterTab === 'leftbar',
              [css.createFooterTab]:
                activeFooterTab === 'create' || activeFooterTab === 'activitySearch',
            })}
          >
            {!!isMobileLayout && !(activeFooterTab === 'leftbar') && (
              <FooterBottomMenu
                activeFooterTab={activeFooterTab}
                changeActivieFooterTab={value => setActiveFooterTab(value)} // setState({ activeFooterTab: value })}
                // handleProviderQueryChange={handleProviderQueryChange}
                history={props.history}
                // onSearch={activity => onkeywordSearch(activity)}
                isEducationSite={isEducationSite}
              />
            )}
          </LayoutWrapperFooter>
        )}
      </LayoutSingleColumn>
      <Modal
        isOpen={!!(activeFooterTab === 'leftbar')}
        onClose={() => setActiveFooterTab('search')}
        id="filterModal"
        onManageDisableScrolling={() => {}}
        className={css.modalLeftBar}
      >
        <LeftBar
          isMobileLayout={isMobileLayout}
          history={props.history}
          onShowEnquiryTab={() => {}}
          onLogoClick={() => setActiveFooterTab('search')}
          onClose={() => setActiveFooterTab('search')}
          isEducationSite={isEducationSite}
        />
      </Modal>
      <Modal
        id="AllSportsPage.shareForm"
        className={css.shareFormModal}
        isOpen={isOpenShareForm}
        onClose={() => setIsOpenShareForm(false)}
        onManageDisableScrolling={() => {}}
      >
        <ShareForm url={url} />
      </Modal>
      <Modal
        id="categoryblogs"
        className={css.blogModal}
        isOpen={showBlog !== null}
        onClose={() => setShowBlog(null)}
        onManageDisableScrolling={() => {}}
        containerClassName={css.blogModalContainer}
        // contentClassName={css.blogContent}
      >
        <>
          {siteData[params?.slug]?.children[showBlog]?.blogUrl?.map((url, i) => (
            <img
              src={url}
              alt={`${siteData[params?.slug]?.children[showBlog]?.title}_${i}`}
              width="auto"
              height="auto"
            />
          ))}
        </>
      </Modal>
    </Page>
  );
};

export default compose(withViewport, injectIntl)(CategoryBlogPage);
