import { 
  COMPLETE_TRANSITIONS,
  TRANSITION_ACCEPT,
  TRANSITION_RESCHEDULE_BY_PROVIDER,
  TRANSITION_RESCHEDULE_BY_CUSTOMER,
  TRANSITION_RESCHEDULE_BY_PROVIDER_AFTER_READY_TO_COMPLETE,
} from 'util/transaction';
import { storableError } from 'util/errors';
import { denormalisedEntities, entityRefs, getAllItems, updatedEntities } from 'util/data';
import { addMarketplaceEntities } from 'ducks/marketplaceData.duck';
import { resetMarketplaceEntities } from 'ducks/marketplaceData.duck';
import { transformRecentTransactions, PERIODS } from 'containers/BookingPlanPage/helpers';

// ================ Action types ================ //

export const SET_INITIAL_STATE = 'app/ProfilePage/SET_INITIAL_STATE';

export const FETCH_TRANSACTIONS_REQUEST = 'app/BookingPlanPage/FETCH_TRANSACTIONS_REQUEST';
export const FETCH_TRANSACTIONS_SUCCESS = 'app/BookingPlanPage/FETCH_TRANSACTIONS_SUCCESS';
export const FETCH_TRANSACTIONS_ERROR = 'app/BookingPlanPage/FETCH_TRANSACTIONS_ERROR';

export const FETCH_RECENT_TRANSACTIONS_REQUEST =
  'app/BookingPlanPage/FETCH_RECENT_TRANSACTIONS_REQUEST';
export const FETCH_RECENT_TRANSACTIONS_SUCCESS =
  'app/BookingPlanPage/FETCH_RECENT_TRANSACTIONS_SUCCESS';

// ================ Reducer ================ //

const initialState = {
  transactionRefs: [],
  recentTransactions: [],
  isLoading: false,
  error: null,
};

export default function bookingPlanReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_STATE:
      return initialState;

    case FETCH_TRANSACTIONS_REQUEST:
      return { ...state, isLoading: true };
    case FETCH_RECENT_TRANSACTIONS_REQUEST:
      return { ...state, isLoading: true, recentTransactions: [] };
    case FETCH_TRANSACTIONS_SUCCESS:
      return { ...state, transactionRefs: payload, isLoading: false };
    case FETCH_RECENT_TRANSACTIONS_SUCCESS:
      return { ...state, recentTransactions: payload, isLoading: false };
    case FETCH_TRANSACTIONS_ERROR:
      return { ...state, isLoading: false, error: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //
const setInitialState = () => ({ type: SET_INITIAL_STATE });

const fetchTransactionsRequest = () => ({ type: FETCH_TRANSACTIONS_REQUEST });
const fetchTransactionsSuccess = payload => ({ type: FETCH_TRANSACTIONS_SUCCESS, payload });
const fetchTransactionsError = payload => ({ type: FETCH_TRANSACTIONS_ERROR, payload });

const fetchRecentTransactionsRequest = () => ({ type: FETCH_RECENT_TRANSACTIONS_REQUEST });
const fetchRecentTransactionsSuccess = payload => ({
  type: FETCH_RECENT_TRANSACTIONS_SUCCESS,
  payload,
});

// ================ Thunks ================ //

export const fetchTransactions = () => async (dispatch, getState, sdk) => {
  try {
    dispatch(resetMarketplaceEntities());
    dispatch(fetchTransactionsRequest());

    const responses = await getAllItems(sdk.transactions.query, {
      only: 'sale',
      lastTransitions: [TRANSITION_ACCEPT, TRANSITION_RESCHEDULE_BY_PROVIDER,
        TRANSITION_RESCHEDULE_BY_CUSTOMER, TRANSITION_RESCHEDULE_BY_PROVIDER_AFTER_READY_TO_COMPLETE],
      include: ['provider', 'customer', 'booking', 'listing'],
    });

    const refs = responses.reduce((acc, res) => [...acc, ...entityRefs(res.data.data)], []);

    dispatch(fetchTransactionsSuccess(refs));
    responses.forEach(res => dispatch(addMarketplaceEntities(res)));
  } catch (e) {
    dispatch(fetchTransactionsError(storableError(e)));
  }
};

export const fetchRecentTransactions = () => async (dispatch, getState, sdk) => {
  try {
    dispatch(fetchRecentTransactionsRequest());
    dispatch(resetMarketplaceEntities());

    const response = await getAllItems(sdk.transactions.query, {
      only: 'sale',
      lastTransitions: COMPLETE_TRANSITIONS,
      include: ['provider', 'customer', 'booking', 'listing'],
    });

    const txs = response.reduce((acc, res) => {
      const refs = entityRefs(res.data.data);
      const stateEntities = updatedEntities({}, res.data);
      return [...acc, ...denormalisedEntities(stateEntities, refs)];
    }, []);

    const transformedTxs = transformRecentTransactions(txs);

    dispatch(fetchRecentTransactionsSuccess(transformedTxs));
  } catch (e) {
    dispatch(fetchTransactionsError(storableError(e)));
  }
};

export const loadData = (params, search) => async (dispatch, getState, sdk) => {
  const { period } = params;
  // Clear state so that previously loaded data is not visible
  // in case this page load fails.
  dispatch(setInitialState());

  switch (period) {
    case PERIODS.ACTIVE:
      dispatch(fetchTransactions());
      break;
    case PERIODS.RECENT:
      dispatch(fetchRecentTransactions());
      break;
    default:
      dispatch(fetchRecentTransactions());
      dispatch(fetchTransactions());
  }
};
