import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import {
  LINE_ITEM_NIGHT,
  LINE_ITEM_DAY,
  LINE_ITEM_CUSTOM_AMOUNT,
  propTypes,
  LINE_ITEM_VOUCHER_AMOUNT,
} from '../../util/types';

import css from './BookingBreakdown.css';
import classNames from 'classnames';
import { ListingType } from 'containers';
import { LISTING_TYPES } from 'util/constants';

const LineItemUnitPriceMaybe = props => {
  const { transaction, unitType, intl, listingType } = props;
  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;
  const isCustomAmount = unitType === LINE_ITEM_CUSTOM_AMOUNT;
  const isVoucherAmount = unitType === LINE_ITEM_VOUCHER_AMOUNT;
  const translationKey =
    listingType === LISTING_TYPES.PRODUCT
      ? 'BookingBreakdown.basePerProduct'
      : isNightly
      ? 'BookingBreakdown.pricePerNight'
      : isDaily
      ? 'BookingBreakdown.pricePerDay'
      : isCustomAmount
      ? 'BookingBreakdown.customAmountPerQuantity'
      : isVoucherAmount
      ? 'BookingBreakdown.voucherAmountPerQuantity'
      : 'BookingBreakdown.pricePerQuantity';

  const unitPurchase = transaction.attributes.lineItems.find(
    item => item.code === unitType && !item.reversal
  );

  const formattedUnitPrice = unitPurchase ? formatMoney(intl, unitPurchase.unitPrice) : null;

  return formattedUnitPrice ? (
    <div className={css.lineItem}>
      <span className={classNames(css.priceLabel, isVoucherAmount && css.voucherAmount)}>
        <FormattedMessage id={translationKey} />
      </span>
      <span className={css.itemValue}>{formattedUnitPrice}</span>
    </div>
  ) : null;
};

LineItemUnitPriceMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  unitType: propTypes.bookingUnitType.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemUnitPriceMaybe;
