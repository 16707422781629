import React, { useEffect, useMemo, useRef, useState } from 'react';
import css from '../JobsBoard.css';
import { toTitleCase } from 'util/stringHelper';
import JobsCardReplyModel from './JobsCardReplyModel';
import moment from 'moment';
import classNames from 'classnames';
import { getBannerImg } from 'components/GetQuoteModal/GetQuoteModal.data';

const JobsBoardCard = props => {
  const { jobsBoardData, history, isEducationSite } = props;

  const [JobsBoardInfo, setJobsBoardInfo] = useState('');
  const [showDesc, setShowDesc] = useState(false);
  const [showLocation, setShowLocation] = useState(false);

  // reply model function's
  const onOpenModal = data => setJobsBoardInfo(data);
  const onCloseJObsreplyModal = () =>
    setJobsBoardInfo({ email: '', id: '', sub_category: '', city: '' });
  const cityRef = useRef();
  const replyRef = useRef();

  const jobsModel = useMemo(() => {
    return (
      <JobsCardReplyModel
        JobsBoardInfo={JobsBoardInfo}
        onCloseJObsreplyModal={onCloseJObsreplyModal}
      />
    );
  }, [JobsBoardInfo]);

  const handleClickOutside = event => {
    if (
      cityRef.current &&
      !cityRef.current.contains(event.target) &&
      replyRef.current &&
      !replyRef.current.contains(event.target)
    ) {
      history &&
        history.push(`${isEducationSite ? '/education' : ''}/jobs-board/${jobsBoardData.id}`);
    }
  };
  const { publicData, title, createdAt, id, description } = jobsBoardData;
  const { que_ans, location, email, session_type, category, sub_category } = publicData;
  const sessionType = que_ans.find(val => val.answer === 'Once off');
  const isActive = typeof publicData?.is_active !== 'undefined' ? publicData?.is_active : true;
  const time = createdAt
    ? moment(createdAt)
        .startOf('day')
        .fromNow()
    : moment(createdAt).format('DD/MM/YYYY');
  const heroImg = getBannerImg(sub_category[0]);
  return (
    <>
      <div key={id} id={id} className={css.jobCardMain} onClick={isActive && handleClickOutside}>
        <div className={classNames(css.jobCardBG, !isActive && css.isActive)}></div>
        {!isActive && (
          <div className={css.JobFilled}>
            {/* <h1>Job has been Filled</h1> */}
            <img
              src="https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1689858462/probuddy/static/Black_White_Minimalist_Business_Logo_olmtal.png"
              alt="Job has been Filled"
            />
          </div>
        )}
        <div className={css.jobCardWrap}>
          <div className={css.jobCardWrapBox}>
            <div className={css.jobCardHead}>
              <img
                className={css.jobCardImg}
                src={
                  heroImg ||
                  'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1689403886/probuddy/static/avatar/companyLogo_cbv71g.png'
                }
              />
              <div className={css.jobCardHeadWrap}>
                <div className={css.jobCardButtons}>
                  <button className={classNames(css.BtnBlue, css.btn)}>
                    {toTitleCase(sub_category[0].replaceAll('-', ' '))}
                  </button>
                  {location?.city && (
                    <div ref={cityRef} className={css.toggellocation}>
                      <button
                        onClick={() => setShowLocation(!showLocation)}
                        className={classNames(css.BtnOrange, css.btn)}
                      >
                        {toTitleCase(location?.city.replaceAll('-', ' '))}

                        <div className={css.locationIcon}>
                          {showLocation ? (
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
                              <path d="m12 6.586-8.707 8.707 1.414 1.414L12 9.414l7.293 7.293 1.414-1.414L12 6.586z" />
                            </svg>
                          ) : (
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
                              <path d="M12 17.414 3.293 8.707l1.414-1.414L12 14.586l7.293-7.293 1.414 1.414L12 17.414z" />
                            </svg>
                          )}
                        </div>
                      </button>
                      <div className={classNames(css.fullloaction, !showLocation && css.hide)}>
                        {location.prefered_area}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className={css.jobCardDetail}>
              <h2 className={classNames(css.detailHead, history && css.pointer)}>
                {title}{' '}
                {
                  <b
                    className={classNames(
                      css.JobsBoardBage,
                      session_type
                        ? session_type === 'yes'
                          ? css.Blue
                          : css.Yellow
                        : sessionType
                        ? css.Blue
                        : css.Yellow
                    )}
                  >
                    {session_type
                      ? session_type === 'yes'
                        ? 'Once Off'
                        : 'Recurring'
                      : sessionType
                      ? 'Once Off'
                      : 'Recurring'}
                  </b>
                }
              </h2>
              <p
                onClick={() => setShowDesc(!showDesc)}
                className={classNames(css.detailDesc, !showDesc && css.detailDescHide)}
              >
                {description}
              </p>
            </div>
            <div className={css.jobCardBoday}>
              {que_ans?.map(val => {
                return (
                  <p className={css.jobCardText}>
                    <span>{val.question}</span>:{' '}
                    {Array.isArray(val.answer) ? val.answer.join(', ') : val.answer}
                  </p>
                );
              })}
              <p className={css.jobCardText}>
                <span>Address</span>: {location.prefered_area}
              </p>
            </div>
          </div>
          <div className={css.jobsBoardReply}>
            <p className={css.jobsBoardTime}>
              Job Posted <strong>{time}</strong>
            </p>
            <button
              ref={replyRef}
              disabled={!isActive}
              onClick={() =>
                onOpenModal({ email, id, sub_category, heroImg, city: location?.city })
              }
              className={css.BtnReply}
            >
              Reply
            </button>
          </div>
        </div>
      </div>
      {jobsModel}
    </>
  );
};

export default JobsBoardCard;
