import React, { useState, useEffect, useCallback, useMemo } from 'react';
import css from './SectionUpcomingClasses.css';
import PropTypes from 'prop-types';
import { propTypes } from 'util/types';
import moment from 'moment';
import { IconSpinner, UpcomingCalendar, Button, ClassesComponent } from 'components';
import { FormattedMessage } from 'util/reactIntl';
import Switch from 'react-switch';
import classNames from 'classnames';

const DEFAULT_DATE = {
  start: moment()
    .set({ hour: 0, minute: 0, second: 0 })
    .toISOString(),
  end: moment()
    .set({ hour: 23, minute: 59, second: 59 })
    .toISOString(),
};

function SectionUpcomingClasses({
  listing,
  fetchAuthorClasses,
  authorClasses,
  authorClassesIsLoading,
  allClassListingReviews,
  isEducationSite,
}) {
  const [date, setDate] = useState(DEFAULT_DATE);
  const [selectedDate, setSelectedDate] = useState();
  const [showAll, setShowAll] = useState(false);
  const [showAllWeek, setShowAllWeek] = useState(true);

  useEffect(() => {
    const authorId = listing.author.id.uuid;
    const DEFAULT_DATE = {
      start: date?.start,
      end: moment(date?.start)
        ?.add(7, 'd')
        ?.set({ hour: 23, minute: 59, second: 59 })
        ?.toISOString(),
    };
    fetchAuthorClasses(authorId, DEFAULT_DATE);
    /* eslint-disable-next-line */
  }, [date, listing?.author?.id?.uuid]);

  const dateChangeHandler = useCallback(d => {
    setSelectedDate(d);
    setShowAllWeek(false);
    const dateToSet = {
      start: d
        .clone()
        .set({ hour: 0, minute: 0, second: 0 })
        .toISOString(),
      end: d
        .clone()
        .set({ hour: 23, minute: 59, second: 59 })
        .toISOString(),
    };

    setDate(dateToSet);
  }, []);

  const onChangeMode = () => {
    const showAll = showAllWeek;
    setShowAllWeek(!showAllWeek);
    const dateToSet = {
      start: moment()
        .clone()
        .set({ hour: 0, minute: 0, second: 0 })
        .toISOString(),
      end: moment()
        .add(7, 'd')
        .clone()
        .set({ hour: 0, minute: 0, second: 0 })
        .toISOString(),
    };
    !showAll ? setDate(dateToSet) : setDate(DEFAULT_DATE);
  };

  const upcomingCalender = useMemo(() => {
    return (
      <UpcomingCalendar
        onDateSelect={dateChangeHandler}
        totalItems={authorClasses.length}
        isCompanyPage={true}
        numberOfDays={30}
        initialDate={showAllWeek ? null : !!selectedDate ? moment(selectedDate) : moment()}
        changeDate={date => {
          setDate(date);
        }}
      />
    );
  }, [authorClasses.length, selectedDate]);

  return (
    <div>
      <div className={css.classesHead}>
        <div
          className={classNames(css.showClasses, {
            [css.uncheckedShowClasses]: !showAllWeek,
          })}
        >
          <h4>Show All</h4>
          <Switch
            uncheckedIcon={false}
            checkedIcon={false}
            onChange={onChangeMode}
            checked={showAllWeek}
          />
        </div>
        {upcomingCalender}
      </div>
      <div className={css.classesContainer}>
        {/* <div className={css.mobileResultHead}>
            <span className={css.totalClasses}>
              <FormattedMessage
                id={'SearchFilters.listingsFound'}
                values={{ count: authorClasses.length }}
              />
            </span>
          </div> */}

        {authorClassesIsLoading ? (
          <IconSpinner className={css.spinner} />
        ) : !!authorClasses.length ? (
          <ClassesComponent
            className={css.classCard}
            listings={authorClasses.slice(0, showAll ? authorClasses.length : 4)}
            allClassListingReviews={allClassListingReviews}
            showAll={showAllWeek}
            min={0}
            max={24}
            selectedDate={moment(date.start)}
          />
        ) : (
          <div className={css.noListings}>{isEducationSite ? 'No Courses' : 'No Classes'}</div>
        )}

        {authorClasses && authorClasses.length > 3 && (
          <div className={css.viewMoreButtonWrapper}>
            <Button onClick={() => setShowAll(prevState => !prevState)}>
              {!showAll ? (
                <FormattedMessage id="ListingPage.viewMore" />
              ) : (
                <FormattedMessage id="ListingPage.viewLess" />
              )}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

SectionUpcomingClasses.propTypes = {
  listing: propTypes.listing,
  fetchAuthorClasses: PropTypes.func,
  authorClasses: PropTypes.arrayOf(propTypes.listing),
  authorClassesIsLoading: PropTypes.bool,
};

export default SectionUpcomingClasses;
