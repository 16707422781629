import React, { useState, useEffect } from 'react';
import cns from 'classnames';
import { compose } from 'redux';
import { uniqBy } from 'lodash';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';
import { FormattedMessage } from '../../util/reactIntl';
import css from './SubCategoryFilter.css';
import { parse } from '../../util/urlHelpers';
import DropdownButton from '../ModifiedSearchFilters/Components/DropdownButton/DropdownButton';
import FilterDropdown from '../ModifiedSearchFilters/Components/FilterDropdown/FilterDropdown';
import { withViewport } from '../../util/contextHelpers';

const Level_Of_Seller = [
  {
    key: 'new',
    label: 'New Arrival',
    isSortable: false,
  },
  {
    key: 'pub_total_bookings',
    label: 'Most Bookings',
    isSortable: true,
  },
  {
    key: 'pro',
    label: 'Pro Verified',
    isSortable: false,
  },
  {
    key: 'master_verified',
    label: 'Master Verified',
    isSortable: false,
  },
  {
    key: 'pub_total_ratings',
    label: 'Best Rated',
    isSortable: true,
  },
  {
    key: 'pub_last_active',
    label: 'Last Online',
    isSortable: true,
  },
];

const SubCategoryFilter = props => {
  const {
    urlQueryParams,
    isMobileLayout,
    categoryFilter,
    priceFilter,
    languageFilter,
    redirectPageName,
    history,
    viewport,
  } = props;

  const [selectedSellerLevels, setSelectedSellerLevels] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('sports');
  const [subCategories, setSubCategories] = useState([]);
  const [selectedSubCategories, setSelectedSubCategories] = useState(['all_sports']);

  const { width } = viewport;
  const numSum = width && width > 768 ? Math.round((width - 500) / 120 - 1) : 6;

  useEffect(() => {
    const subCategoryData = filterSubCategoriesByCategory();
    const uniqueSubCategory = uniqBy(subCategoryData, 'key');
    setSubCategories(uniqueSubCategory);
    /* eslint-disable-next-line */
  }, [urlQueryParams]);

  const redirectToUrl = (redirectPageName, queryParams) => {
    return history.push(createResourceLocatorString(redirectPageName, routeConfiguration(), {}, queryParams));
  };

  const initialStateData = () => {
    const urlParams = parse(history.location.search);
    if (urlParams && (urlParams.meta_level || urlParams.sort)) {
      const selectedLevels = urlParams.meta_level
        ? Array.isArray(urlParams.meta_level)
          ? urlParams.meta_level
          : urlParams.meta_level.split(',')
        : [];

      const selectedSorts = urlParams.sort
        ? Array.isArray(urlParams.sort)
          ? urlParams.sort
          : urlParams.sort.split(',')
        : [];

      setSelectedSellerLevels([...selectedLevels, ...selectedSorts]);
    }
    if (urlParams && urlParams.pub_category) {
      setSelectedCategory(urlParams && urlParams.pub_category);
    }
    if (urlParams && urlParams.pub_sub_category) {
      const subCategoriesData = Array.isArray(urlParams.pub_sub_category)
        ? urlParams.pub_sub_category
        : urlParams.pub_sub_category.split(',');
      setSelectedSubCategories(subCategoriesData);

    }
  };
  useEffect(() => {
    initialStateData();
    // eslint-disable-next-line
  }, [urlQueryParams]);

  const filterSubCategoriesByCategory = () => {
    const initialcategories = selectedCategory;
    if (initialcategories.length) {
      let childSubCategories = [];
      const categories = categoryFilter.options.filter(
        category => initialcategories === category.key
      );
      categories &&
        categories.forEach(category => {
          category &&
            category.subCategories.length &&
            category.subCategories.forEach(sb => {
              sb.options.forEach(childCategory => {
                childSubCategories.push(childCategory);
              });
            });
        });
      return childSubCategories;
    } else {
      return [];
    }
  };

  const onSelectSellerLevelHandler = value => {
    const updateValue = selectedSellerLevels;
    const urlParams = parse(history.location.search);
    const fetchLevel = Level_Of_Seller.find(level => level.key === value);

    let queryParams = { ...urlParams };
    if (fetchLevel.isSortable) {
      const sortData = urlParams.sort
        ? Array.isArray(urlParams.sort)
          ? urlParams.sort
          : urlParams.sort.split(',')
        : [];
      if (updateValue.includes(value)) {
        sortData.splice(sortData.indexOf(value), 1);
        queryParams = { ...urlParams, sort: sortData };
      } else {
        sortData.push(value);
        queryParams = { ...urlParams, sort: sortData };
      }
    } else {
      const metaData = urlParams.meta_level
        ? Array.isArray(urlParams.meta_level)
          ? urlParams.meta_level
          : urlParams.meta_level.split(',')
        : [];
      if (updateValue.includes(value)) {
        metaData.splice(metaData.indexOf(value), 1);
        queryParams = { ...urlParams, meta_level: metaData };
      } else {
        metaData.push(value);
        queryParams = { ...urlParams, meta_level: metaData };
      }
    }

    if (updateValue.includes(value)) {
      updateValue.splice(updateValue.indexOf(value), 1);
    } else {
      updateValue.push(value);
    }

    redirectToUrl(redirectPageName, queryParams);
    setSelectedSellerLevels(updateValue);
  };

  const onSelectSubCategoryHandler = value => {
    let updateValue = selectedSubCategories;
    const urlParams = parse(history.location.search);

    if (updateValue.includes('all_sports')) {
      updateValue.splice(updateValue.indexOf('all_sports'), 1);
    }

    if (updateValue.includes(value)) {
      updateValue.splice(updateValue.indexOf(value), 1);
    } else {
      updateValue.push(value);
    }

    setSelectedSubCategories(updateValue);
    let queryParams = { ...urlParams, pub_sub_category: updateValue };
    return redirectToUrl(redirectPageName, queryParams);
  };

  const onSelectAllSubCategoryHandler = value => {
    const urlParams = parse(history.location.search);
    setSelectedSubCategories([value]);
    let queryParams = { ...urlParams, pub_sub_category: null };
    return redirectToUrl(redirectPageName, queryParams);
  };

  return (
      <section className={css.root}>
          <div className={css.advanceFilterContainer}>
            <div className={css.advanceFilterWrapper}>
              <div className={css.rightSideFilters}>
                <DropdownButton
                  labelClassName={css.labelSellerLevels}
                  label={<FormattedMessage id={'ModifiedSearchFilters.sellerLevel'} />}
                  inProgress={false}
                  selectedOption={selectedSellerLevels}
                  direction={'right'}
                  actions={Level_Of_Seller.map(level => {
                    return {
                      label: level.label,
                      key: level.key,
                      handler: () => onSelectSellerLevelHandler(level.key),
                    };
                  })}
                />

                <FilterDropdown
                  urlQueryParams={urlQueryParams}
                  languageFilter={languageFilter}
                  priceFilter={priceFilter}
                  redirectPageName={redirectPageName}
                  history={history}
                  isMobileLayout={isMobileLayout}
                />
              </div>

              <div className={css.subCategoryItemsWrap}>
                <div
                  onClick={() => onSelectAllSubCategoryHandler('all_sports')}
                  className={cns(css.subCategoryItem, {
                    [css.selectedSubCategoryItem]: selectedSubCategories.includes('all_sports'),
                  })}
                >
                  All Sports
                </div>

                {subCategories.slice(0, numSum).map(sub => {
                  return (
                    <div
                      key={sub.key}
                      onClick={() => onSelectSubCategoryHandler(sub.key)}
                      className={cns(css.subCategoryItem, {
                        [css.selectedSubCategoryItem]: selectedSubCategories.includes(sub.key),
                      })}
                    >
                      {sub.label}
                    </div>
                  );
                })}

                {subCategories.length > numSum + 1 && (
                  <DropdownButton
                    labelClassName={css.subCategoryMoreItems}
                    label={<FormattedMessage id={'ModifiedSearchFilters.more'} />}
                    inProgress={false}
                    selectedOption={selectedSubCategories}
                    actions={subCategories.slice(numSum, subCategories.length).map(sub => {
                      return {
                        label: sub.label,
                        key: sub.key,
                        handler: () => onSelectSubCategoryHandler(sub.key),
                      };
                    })}
                  />
                )}
              </div>
            </div>
          </div>
      </section>
  );
};

export default compose(withViewport)(SubCategoryFilter);
