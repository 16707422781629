import classNames from 'classnames';
import { func, string } from 'prop-types';
import React from 'react';

import { LazyLoadingImages, SectionSearch } from '../../components';
import css from './SectionHero.css';
import { IMAGES } from '../../util/imagesPath';

const SectionHero = props => {
  const { rootClassName, className, suggestedListings, handleProviderQueryChange } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.heroContent}>
        <div className={css.halfSectionWrap}>
          <div className={css.sectionSearchContainer}>
            <SectionSearch
              intl={props.intl}
              history={props.history}
              suggestedListings={suggestedListings}
              handleProviderQueryChange={handleProviderQueryChange}
            />
          </div>
        </div>

        <div className={classNames(css.halfSectionWrapWithImages, css.withBackground)}>
          <div className={css.imageContainerWrapper}>
            <div className={css.imageHomeBannerContainer}>
              <img src={IMAGES.homePageBanner} alt="promoimage" />

              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="757"
                height="533"
                viewBox="0 0 757 533"
                fill="none"
              >
                <path
                  d="M670.019 258.413C860.258 179.031 716.227 -58.5256 451.147 13.5006C186.068 85.5269 -1.08472 -112.58 0.308685 143.971C1.70209 400.521 115.215 543.742 303.737 532.149C492.258 520.556 479.78 337.794 670.019 258.413Z"
                  fill="url(#paint0_linear_4747_60)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_4747_60"
                    x1="753.827"
                    y1="275.034"
                    x2="-2.76315"
                    y2="255.849"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop offset="0.648683" stopColor="#0095B3" />
                    <stop offset="1" stopColor="#10D6EB" />
                  </linearGradient>
                </defs>
              </svg>
            </div>
          </div>
          <div className={css.bottomImageTopbar}>
            <LazyLoadingImages src={IMAGES.topbarMobileBottom} alt="topbarMobile" />
          </div>
        </div>
      </div>
    </div>
  );
};

SectionHero.defaultProps = { rootClassName: null, className: null };

SectionHero.propTypes = {
  rootClassName: string,
  className: string,
  handleSuggestionChange: func,
};

export default React.memo(SectionHero);
