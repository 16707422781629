import React, { useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { withRouter } from 'react-router-dom';
import { StaticPage, TopbarContainer } from '../../containers';
import config from '../../config';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  GenericMessage,
  NamedLink,
} from '../../components';
import { IconRoundCheckWhite } from '../../components/IconBecomeVendor/IconBecomeVendor';
import classNames from 'classnames';

import css from './SubscriptionPage.css';

const SubscriptionPageComponent = props => {
  useEffect(() => {
    const el = document.createElement('script');
    el.onload = () => {
      if (typeof window !== 'undefined') {
        window.Chargebee.init({
          site: config.cbSite,
        });
        window.Chargebee.registerAgain();
      }
    };

    el.setAttribute('src', 'https://js.chargebee.com/v2/chargebee.js');

    document.body.appendChild(el);
  }, []);

  const { canCreateListing, createListingNotAllowedMessage, params } = props;
  const becomeHostOrMember = params.name;
  return (
    <StaticPage
      title="Subscription"
      schema={{
        '@context': 'http://schema.org',
        '@type': 'SubscriptionPage',
        description: 'Probuddy subscriptions',
        name: 'Subscription page',
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain className={css.staticPageWrapper}>
          {!canCreateListing && createListingNotAllowedMessage ? (
            <GenericMessage message={createListingNotAllowedMessage} show={true} />
          ) : null}
          <section className={css.planGreyWrap}>
            <div className={css.selectPricesection}>
              <div className={css.pricingHeading}>
                <h2>Simple pricing for Every Team</h2>
                <span>
                  From Freelancers to fitness studio’s. We help you Generate more bookings , Manage
                  your client payments, Packages and ultimately increase your Revenue.
                </span>
              </div>
              <div className={css.planContainer}>
                <div className={css.planSection2}>
                  <div className={classNames(css.planHeading, css.planHeadingWhite)}>
                    <div>
                      <h3>Pro Plan</h3>
                      <span>Full Access</span>
                    </div>
                    <div className={css.planPriceWrapper}>
                      <h4>
                        {becomeHostOrMember === 'become_vendor' ||
                        becomeHostOrMember === 'become_member'
                          ? '$9'
                          : '$99'}
                      </h4>
                      <span>
                        {becomeHostOrMember === 'become_vendor' ||
                        becomeHostOrMember === 'become_member'
                          ? '/Month'
                          : '/Year'}
                      </span>
                    </div>
                  </div>
                  <div className={classNames(css.planLists, css.planListsWhite)}>
                    <h3>Features</h3>
                    <span>
                      <IconRoundCheckWhite /> One Company page listing
                    </span>
                    <span>
                      <IconRoundCheckWhite /> Up to 10 host Listings
                    </span>
                    <span>
                      <IconRoundCheckWhite /> Multiple Class listings
                    </span>
                    <span>
                      <IconRoundCheckWhite /> direct User messaging & Booking Request inbox
                    </span>
                    <span>
                      <IconRoundCheckWhite /> Booking Management Tools
                    </span>
                    <span>
                      <IconRoundCheckWhite /> New Lead Matching
                    </span>
                    <span>
                      <IconRoundCheckWhite /> Social media reach
                    </span>
                    <span>
                      <IconRoundCheckWhite /> Instagram feed embedded
                    </span>
                    <span>
                      <IconRoundCheckWhite /> 0% commission
                    </span>
                  </div>
                  <div className={css.action}>
                    <div className={css.primaryActionBtn}>
                      {/* eslint-disable-next-line */}
                      {props.isAuthenticated ? (
                        <a
                          href="javascript:void(0)"
                          data-cb-type="checkout"
                          data-cb-item-0={
                            becomeHostOrMember === 'become_vendor' ||
                            becomeHostOrMember === 'become_member'
                              ? 'Premium-Plan-Monthly-USD-Monthly'
                              : 'Annual-Plan-USD-Yearly'
                          }
                        >
                          Get Pro Plan
                        </a>
                      ) : (
                        <NamedLink name="LoginPage"> Get Pro Plan</NamedLink>
                      )}
                    </div>
                    <span className={classNames(css.billedMonthly, css.whiteText)}>
                      {becomeHostOrMember === 'become_vendor' ||
                      becomeHostOrMember === 'become_member'
                        ? 'Billed Monthly'
                        : 'Billed Yearly'}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

const mapStateToProps = state => {
  const { canCreateListing, createListingNotAllowedMessage } = state.EditListingPage;
  const { isAuthenticated } = state.Auth;

  return {
    scrollingDisabled: isScrollingDisabled(state),
    canCreateListing,
    createListingNotAllowedMessage,
    isAuthenticated,
  };
};

const SubscriptionPage = compose(
  withRouter,
  connect(mapStateToProps)
)(injectIntl(SubscriptionPageComponent));

export default SubscriptionPage;
